<script setup lang="ts">
type FlipProperty1DefaultProps = {
  class: string
}
const props = defineProps<FlipProperty1DefaultProps>()
const emit = defineEmits<{
  switch: [MouseEvent]
}>()
const handleClick = (event: MouseEvent) => {
  emit('switch', event)
}
</script>

<template>
  <div
    :class="['group', 'flip-property-1-default', 'property-1-default', props.class]"
    role="button"
    tabindex="0"
    aria-label="Switch assets"
    @click="handleClick"
  >
    <svg
      class="arrow-down-up"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 12L5.25 15L8.25 12"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M5.25 15V3" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
      <path
        opacity="0.5"
        d="M15.75 6L12.75 3L9.75 6"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.5"
        d="M12.75 3V15"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style scoped>
.flip-property-1-default,
.flip-property-1-default * {
  box-sizing: border-box;
}
.flip-property-1-default {
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.arrow-down-up {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  position: relative;
  overflow: visible;
}
</style>
