import { z } from 'zod'
import { AssetValue, Chain } from '@swapkit/helpers'
import BigNumber from 'bignumber.js'
import { Alchemy } from 'alchemy-sdk'
import { AlchemyProvider, type BigNumberish, type TransactionRequest, formatUnits } from 'ethers'
import { useEvm } from './evm'
import { getAssetId } from '~/utils/main'

const maxApproval = BigNumber(
  '57896044618658097711785492504343953926634992332820282019728792003956559819967',
)

const tokenAllowanceSchema = z.object({
  result: z.string().regex(/\d+/),
})
export type TokenAllowanceSchema = z.infer<typeof tokenAllowanceSchema>

export const useAlchemy = () => {
  const { providerConfigs, getProviderUrl } = useEvm()

  const estimateGas = async ({
    asset,
    tx: { value, ...txRequest },
  }: {
    asset: { address?: string; chain: Chain.Arbitrum | Chain.Ethereum; ticker: string }
    tx: Omit<TransactionRequest, 'value'> & { value: BigNumberish }
  }) => {
    const config = providerConfigs[asset.chain]
    const provider = new AlchemyProvider(config.network, config.apiKey)
    const gwei = await provider.estimateGas({
      ...txRequest,
      /**
       * guarantee that the value is a hex string
       */
      value: '0x' + BigInt(value).toString(16),
    })
    return AssetValue.from({
      asset: getAssetId(asset.chain, asset.ticker, asset.address),
      value: formatUnits(gwei, 'gwei'),
    })
  }
  const getTransactionCount = ({
    address,
    blockTag = 'pending',
    chain,
  }: {
    address: string
    blockTag?: number | string | 'pending'
    chain: Chain.Arbitrum | Chain.Ethereum
  }) => {
    const config = providerConfigs[chain]
    const alchemySdk = new Alchemy({ network: config.alchemyNetwork, apiKey: config.apiKey })
    return alchemySdk.core.getTransactionCount(address, blockTag)
  }

  const getTokenAllowance = async ({
    chain,
    contractAddress,
    ownerAddress,
    spenderAddress,
  }: {
    chain: Chain.Arbitrum | Chain.Ethereum
    contractAddress: string
    ownerAddress: string
    spenderAddress: string
  }) => {
    try {
      const response = await fetch(getProviderUrl(chain), {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          method: 'alchemy_getTokenAllowance',
          params: [
            {
              contract: contractAddress,
              owner: ownerAddress,
              spender: spenderAddress,
            },
          ],
        }),
      })
      const json = await response.json()
      return tokenAllowanceSchema.parse(json)
    } catch {
      throw new Error('Failed to get token allowance')
    }
  }
  const hasAllowance = async ({
    amount,
    ...props
  }: Parameters<typeof getTokenAllowance>[0] & { amount: BigNumber.Value }): Promise<boolean> => {
    const allowance = await getTokenAllowance(props)
    return BigNumber(allowance.result).gte(amount)
  }
  const hasMaxAllowance = async (
    props: Parameters<typeof getTokenAllowance>[0],
  ): Promise<boolean> => {
    const allowance = await getTokenAllowance(props)
    return BigNumber(allowance.result).gte(maxApproval)
  }

  return {
    estimateGas,
    getTransactionCount,
    token: { getTokenAllowance, hasAllowance, hasMaxAllowance },
  }
}
