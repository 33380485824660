<template>
  <div v-if="!props.editing" class="flex items-center gap-2.5 text-base text-white/50">
    <div class="">Recipient:</div>
    <div
      :class="['group flex items-center gap-2.5', { 'cursor-pointer': !props.editing }]"
      :title="props.editing || !props.address ? undefined : 'Change'"
      role="button"
      tabindex="0"
      @click="onEdit"
    >
      <div :class="{ 'group-hover:text-white': !props.editing }">
        {{ !props.address && props.editing ? '' : address }}
      </div>
      <div v-if="!props.editing" class="flex items-center gap-2.5 group-hover:text-[#C99D29]">
        <Icon icon="fluent:edit-28-regular" />
      </div>
    </div>
  </div>
  <div v-else class="mt-4 flex flex-col gap-2.5 text-base">
    <div class="flex items-center justify-between">
      <label for="recipient-input" class="text-white/50">Recipient Address:</label>
      <a
        class="cursor-pointer rounded-full bg-white/5 px-2.5 py-1 text-xs text-white/70"
        @click="onCancel"
        >Cancel</a
      >
    </div>
    <div class="rounded-2xl bg-white/5 px-5 py-4" :class="'bg-[#E84142]/20'">
      <input
        id="recipient-input"
        type="text"
        class="w-full outline-none"
        :value="props.value"
        @input="onInput"
      />
    </div>
    <div v-if="props.error" class="flex items-center gap-2.5 leading-none text-[#E84142]">
      <Icon icon="lucide:info" />
      {{ props.error }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { computed } from 'vue'
import { formatAddress } from '~/utils/utils'

const props = defineProps<{
  address: string | undefined
  editing: boolean
  value: string | null
  error: string | undefined
}>()
const address = computed<string>(() => {
  if (!props.address) {
    return 'Set an address'
  }
  return formatAddress(props.address)
})
const emits = defineEmits<{ input: [string]; edit: []; cancel: [] }>()
const onEdit = () => {
  emits('edit')
}
const onInput = (e: Event) => {
  if (!(e.target instanceof HTMLInputElement)) {
    return
  }
  emits('input', e.target.value)
}
const onCancel = () => {
  emits('cancel')
}
</script>
<style></style>
