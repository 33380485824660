<template>
  <div
    class="box-border flex w-[120px] cursor-pointer items-center gap-3 border-b border-solid border-[#363636] px-5 py-3.5 text-sm md:w-[160px]"
    :class="
      selected
        ? 'bg-[#423922] text-[#C99D29]'
        : 'bg-[#202020] text-white/70 hover:bg-white/10 hover:text-white'
    "
    role="treeitem"
    :aria-expanded="selected ?? false"
    :aria-owns="`asset-select-${chain.toLowerCase()}`"
    :aria-label="chain"
  >
    <img
      v-if="!skeleton"
      class="aspect-square w-5 rounded-full"
      :src="getLogoForChain(props.chain)"
      alt=""
      @error="(e) => ((e.target as HTMLImageElement).src = '/tokens/unknown.png')"
    />
    <div v-else class="toba-skeleton aspect-square w-5 rounded-full" />
    <span v-if="!skeleton" :class="selected ? 'font-bold' : 'font-medium'">{{
      chain === 'GAIA' ? 'COSMOS' : chain
    }}</span>
    <span v-else class="toba-skeleton flex-1" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { getLogoForChain } from '~/utils/swapkit.ts'

const props = defineProps<{
  chain: string
  selected?: boolean
  skeleton?: boolean
}>()

onMounted(() => {
  // console.log('props NetworkItem', props)
  // console.log('chain', props.chain)
  // console.log('getLogoForChain(chain)', getLogoForChain(props.chain))
})
</script>
