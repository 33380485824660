export const allTokensRango = [
  {
    "blockchain": "ZKSYNC",
    "symbol": "USDT",
    "name": "Tether USD",
    "isPopular": false,
    "chainId": "324",
    "address": "0x493257fd37edb34451f62edf8d2a0c418852ba4c",
    "decimals": 6,
    "image": "https://rango.vip/i/AgqpZ7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.998955,
    "supportedSwappers": [
      "OrbiterV2",
      "Across",
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "USDT",
    "name": "TetherToken",
    "isPopular": true,
    "chainId": "43114",
    "address": "0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7",
    "decimals": 6,
    "image": "https://rango.vip/i/GJxbOP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.999615,
    "supportedSwappers": [
      "XY Finance",
      "ThorChain",
      "ParaSwap Avalanche",
      "PangolinSwap",
      "Bridgers",
      "AvaxChainV3",
      "ThorChainStreamingSwap",
      "stargate",
      "AllBridge",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "USDT",
    "name": "Tether USD",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x80da25da4d783e57d2fcda0436873a193a4beccf",
    "decimals": 6,
    "image": "https://rango.vip/i/6235ek",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 1.0026317745590656,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "USDT",
    "name": "Tether USD (Celer)",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x81ecac0d6be0550a00ff064a4f9dd2400585fe9c",
    "decimals": 6,
    "image": "https://rango.vip/i/GfmBIH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.9996990448197455,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": true,
    "chainId": "1",
    "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
    "decimals": 6,
    "image": "https://rango.vip/i/r3Oex6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999615,
    "supportedSwappers": [
      "ThorChain",
      "Arbitrum Bridge",
      "Hyphen",
      "ThorChainStreamingSwap",
      "MayaProtocol",
      "stargate",
      "Optimism Bridge",
      "Across",
      "Polygon Bridge",
      "OneInchEth",
      "XY Finance",
      "Synapse Swapper",
      "OrbiterV2",
      "Rainbow Bridge",
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "AllBridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": true,
    "chainId": "10",
    "address": "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58",
    "decimals": 6,
    "image": "https://rango.vip/i/K9XI45",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.998955,
    "supportedSwappers": [
      "XY Finance",
      "OpenOceanOptimism",
      "OrbiterV2",
      "Hyphen",
      "Bridgers",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge",
      "Across",
      "AllBridge"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": true,
    "chainId": "25",
    "address": "0x66e428c3f67a68878562e79a0234c1f83c208770",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDT.E.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.998483,
    "supportedSwappers": [
      "XY Finance",
      "CronaSwap",
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": true,
    "chainId": "56",
    "address": "0x55d398326f99059ff775485246999027b3197955",
    "decimals": 18,
    "image": "https://rango.vip/i/6837hX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.999554,
    "supportedSwappers": [
      "ThorChain",
      "OneInchBsc",
      "BSCPancakeV3",
      "Hyphen",
      "PancakeSwapBsc",
      "ThorChainStreamingSwap",
      "stargate",
      "XY Finance",
      "ParaSwap Bsc",
      "Synapse Swapper",
      "OrbiterV2",
      "Bridgers",
      "AllBridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": true,
    "chainId": "137",
    "address": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    "decimals": 6,
    "image": "https://rango.vip/i/88Ln2L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.999912,
    "supportedSwappers": [
      "Hyphen",
      "PolygonUniSwapV3",
      "stargate",
      "Across",
      "Polygon Bridge",
      "XY Finance",
      "Synapse Swapper",
      "PolygonCurveFinance",
      "OrbiterV2",
      "ParaSwap Polygon",
      "Bridgers",
      "QuickSwap",
      "OneInchPolygon",
      "AllBridge"
    ]
  },
  {
    "blockchain": "XLAYER",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": true,
    "chainId": "196",
    "address": "0x1e4a5963abfd975d8c9021ce480b42188849d41d",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDT.E.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/XLAYER/icon.svg",
    "usdPrice": 0.999565,
    "supportedSwappers": [
      "XY Finance",
      "PotatoSwap"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": true,
    "chainId": "42161",
    "address": "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    "decimals": 6,
    "image": "https://rango.vip/i/3K95Tl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3",
      "Hyphen",
      "MayaProtocol",
      "stargate",
      "Across",
      "XY Finance",
      "ParaSwap Arbitrum",
      "Synapse Swapper",
      "OrbiterV2",
      "Bridgers",
      "SushiArbitrum",
      "AllBridge",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "USDT",
    "name": null,
    "isPopular": true,
    "chainId": "728126428",
    "address": "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
    "decimals": 6,
    "image": "https://rango.vip/i/pPaPM7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.999615,
    "supportedSwappers": [
      "Symbiosis",
      "Bridgers",
      "AllBridge",
      "SunSwap"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "USDT",
    "name": "Tether USD",
    "isPopular": true,
    "chainId": "59144",
    "address": "0xa219439258ca9da29e9cc4ce5596924745e12b93",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDT.E.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "OrbiterV2",
      "LineaPancakeV3",
      "Across",
      "LineaKyberV3"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "USDT",
    "name": "Tether USD",
    "isPopular": true,
    "chainId": "34443",
    "address": "0xf0f161fda2712db8b566946122a5af183995e2ed",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDT.E.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 0.9996990448197455,
    "supportedSwappers": [
      "SupSwapModeV3",
      "SwapMode",
      "Across"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": true,
    "chainId": "SN_MAIN",
    "address": "0x68f5c6a61780768455de69077e07e89787839bf8166decfbf92b645209c0fb8",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDT.E.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 0.999346,
    "supportedSwappers": [
      "Avnu",
      "OrbiterV2",
      "MySwap",
      "10KSwap"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "USDT",
    "name": "Tether USD",
    "isPopular": true,
    "chainId": "534352",
    "address": "0xf55bec9cafdbe8730f096aa55dad6d22d44099df",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDT.E.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 1.005,
    "supportedSwappers": [
      "OrbiterV2",
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "USDT",
    "name": "TetherUS",
    "isPopular": true,
    "chainId": "1313161554",
    "address": "0x4988a896b1227218e4a686fde5eabdcabd91571f",
    "decimals": 6,
    "image": "https://rango.vip/i/m85wFd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.998621,
    "supportedSwappers": [
      "Rainbow Bridge"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "USDT",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/4abbef4c8926dddb320ae5188cfd63267abbcefc0583e4ae05d6e5aa2401ddab",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDT.E.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.998955,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "USDT",
    "name": "Tether",
    "isPopular": true,
    "chainId": "1285",
    "address": "0xb44a9b6905af7c801311e8f4e76932ee959c663c",
    "decimals": 6,
    "image": "https://rango.vip/i/wAe2lj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.073051,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDT",
    "name": "Tether USD",
    "isPopular": false,
    "chainId": "56",
    "address": "0x524bc91dc82d6b90ef29f76a3ecaabafffd490bc",
    "decimals": 6,
    "image": "https://rango.vip/i/mZTSbe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.999229,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "POLYGONZK",
    "symbol": "USDT",
    "name": "Polygon Hermez Bridged USDT  Polygon zk",
    "isPopular": true,
    "chainId": "1101",
    "address": "0x1e4a5963abfd975d8c9021ce480b42188849d41d",
    "decimals": 6,
    "image": "https://rango.vip/i/r7Oqqs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGONZK/icon.svg",
    "usdPrice": 0.998713,
    "supportedSwappers": [
      "PolygonPancakeV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "USDT",
    "name": "L2 Standard Bridged USDT  Base ",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfde4c96c8593536e31f229ea8f37b2ada2699bb2",
    "decimals": 6,
    "image": "https://rango.vip/i/KazLFN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.999181,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "USDT",
    "name": "Tether USD",
    "isPopular": true,
    "chainId": "1284",
    "address": "0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73",
    "decimals": 6,
    "image": "https://rango.vip/i/27UlnR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.24491865262970078,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": true,
    "chainId": "66",
    "address": "0x382bb369d343125bfb2117af9c149795c6c65c50",
    "decimals": 18,
    "image": "https://rango.vip/i/2tR5IC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.998955,
    "supportedSwappers": [
      "CherrySwap",
      "OkcSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
    "decimals": 6,
    "image": "https://rango.vip/i/UkuhlZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.999615,
    "supportedSwappers": [
      "Jupiter",
      "Bridgers"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USDT",
    "name": "Tether USD",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9417669fbf23357d2774e9d421307bd5ea1006d2",
    "decimals": 6,
    "image": "https://rango.vip/i/mZTSbe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.999229,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3553f861dec0257bada9f8ed268bf0d74e45e89c",
    "decimals": 6,
    "image": "https://rango.vip/i/ICZdda",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.988617,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "USDT",
    "name": "USDT",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf0ff231e3f1a50f83136717f287adab862f89431",
    "decimals": 6,
    "image": "https://rango.vip/i/ICZdda",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.988617,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "USDT",
    "name": "Heco-Peg USDT Token",
    "isPopular": true,
    "chainId": "128",
    "address": "0xa71edc38d189767582c38a3145b5873052c3e47a",
    "decimals": 18,
    "image": "https://rango.vip/i/P32K3W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1.002862097857592,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "USDT",
    "name": "Tether USD",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x88eec49252c8cbc039dcdb394c0c2ba2f1637ea0",
    "decimals": 6,
    "image": "https://rango.vip/i/8PTBJA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 1.9191227790569945,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "USDT",
    "name": null,
    "isPopular": true,
    "chainId": "osmosis-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/ATrAs1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 1.0004889887304116,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BNB",
    "name": "Binance",
    "isPopular": true,
    "chainId": "43114",
    "address": "0x264c1383ea520f73dd837f915ef3a732e204a493",
    "decimals": 18,
    "image": "https://rango.vip/i/SLh08X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 500.74,
    "supportedSwappers": [
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "BNB",
    "name": "Binance | Multichain",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xc9baa8cfdde8e328787e29b4b078abf2dadc2055",
    "decimals": 18,
    "image": "https://rango.vip/i/KW2KK6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 332.9387778527243,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BNB",
    "name": "BNB",
    "isPopular": true,
    "chainId": "56",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/BNB.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 500.74,
    "supportedSwappers": [
      "ThorChain",
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "Satellite",
      "PancakeSwapBsc",
      "ThorChainStreamingSwap"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "BNB",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/f4a070a6d78496d53127ea85c094a9ec87dfc1f36071b8ccddbd020f933d213d",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/BNB.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 504.3682754967,
    "supportedSwappers": [
      "Satellite",
      "Osmosis"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "BNB",
    "name": "Binance",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x2bf9b864cdc97b08b6d79ad4663e71b8ab65c45c",
    "decimals": 18,
    "image": "https://rango.vip/i/mirFg7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 500.74,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BNB",
    "name": "Binance Coin (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa",
    "decimals": 8,
    "image": "https://rango.vip/i/gNOCsf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 502.28,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "BNB",
    "name": "Binance Coin",
    "isPopular": false,
    "chainId": "59144",
    "address": "0xf5c6825015280cdfd0b56903f9f8b5a2233476f5",
    "decimals": 18,
    "image": "https://rango.vip/i/5i16Uh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 500.74,
    "supportedSwappers": [
      "EchoDex"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BNB",
    "name": "BNB",
    "isPopular": false,
    "chainId": "128",
    "address": "0x4f99d10e16972ff2fe315eee53a95fc5a5870ce3",
    "decimals": 18,
    "image": "https://rango.vip/i/lRHgK3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 64.07078057574562,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": true,
    "chainId": "43114",
    "address": "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
    "decimals": 6,
    "image": "https://rango.vip/i/j9eYAa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "Symbiosis",
      "ThorChain",
      "ParaSwap Avalanche",
      "Circle CCTP",
      "ThorChainStreamingSwap",
      "stargate",
      "AvalancheCurveFinance",
      "XY Finance",
      "DeBridge",
      "PangolinSwap",
      "Bridgers",
      "AvaxChainV3",
      "AllBridge"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "USDC",
    "name": "USDC",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x368ebb46aca6b8d0787c96b2b20bd3cc3f2c45f7",
    "decimals": 6,
    "image": "https://rango.vip/i/70ybz2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.9973818771426741,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": true,
    "chainId": "1284",
    "address": "0x8f552a71efe5eefc207bf75485b356a0b3f01ec9",
    "decimals": 6,
    "image": "https://rango.vip/i/4X1z8U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.122221,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDC",
    "name": "USDC",
    "isPopular": true,
    "chainId": "1",
    "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    "decimals": 6,
    "image": "https://rango.vip/i/nMyvER",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "Symbiosis",
      "Arbitrum Bridge",
      "ThorChain",
      "Chainflip",
      "Circle CCTP",
      "Hyphen",
      "Satellite",
      "ThorChainStreamingSwap",
      "MayaProtocol",
      "stargate",
      "Optimism Bridge",
      "Polygon Bridge",
      "OneInchEth",
      "XY Finance",
      "Synapse Swapper",
      "OrbiterV2",
      "Rainbow Bridge",
      "EthereumUniswapV3",
      "DeBridge",
      "Bridgers",
      "EthUniSwapV2",
      "AllBridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "USDC",
    "name": "USDC.e",
    "isPopular": true,
    "chainId": "10",
    "address": "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
    "decimals": 6,
    "image": "https://rango.vip/i/qCQYZR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "XY Finance",
      "Symbiosis",
      "OpenOceanOptimism",
      "OrbiterV2",
      "Hyphen",
      "Bridgers",
      "OptimismUniSwapV3",
      "stargate",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "USDC",
    "name": "USDC",
    "isPopular": true,
    "chainId": "25",
    "address": "0xc21223249ca28397b4b6541dffaecc539bff0c59",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.999438,
    "supportedSwappers": [
      "XY Finance",
      "CronaSwap",
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDC",
    "name": "USDC",
    "isPopular": true,
    "chainId": "56",
    "address": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    "decimals": 18,
    "image": "https://rango.vip/i/e4x0s8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.99852,
    "supportedSwappers": [
      "Symbiosis",
      "ThorChain",
      "OneInchBsc",
      "BSCPancakeV3",
      "Hyphen",
      "PancakeSwapBsc",
      "ThorChainStreamingSwap",
      "XY Finance",
      "ParaSwap Bsc",
      "Synapse Swapper",
      "OrbiterV2",
      "DeBridge",
      "Bridgers"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USDC",
    "name": "USDC.e",
    "isPopular": true,
    "chainId": "137",
    "address": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "decimals": 6,
    "image": "https://rango.vip/i/mh9zko",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.999972,
    "supportedSwappers": [
      "Symbiosis",
      "XY Finance",
      "Synapse Swapper",
      "PolygonCurveFinance",
      "ParaSwap Polygon",
      "Hyphen",
      "PolygonUniSwapV3",
      "QuickSwap",
      "stargate",
      "OneInchPolygon",
      "AllBridge",
      "Polygon Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USDC",
    "name": "USDC.e",
    "isPopular": true,
    "chainId": "42161",
    "address": "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    "decimals": 6,
    "image": "https://rango.vip/i/Tr7k3p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "XY Finance",
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "Synapse Swapper",
      "ArbitrumUniSwapV3",
      "OrbiterV2",
      "Hyphen",
      "stargate",
      "SushiArbitrum",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "USDC",
    "name": "USDC",
    "isPopular": true,
    "chainId": "59144",
    "address": "0x176211869ca2b568f2a7d4ee941e073a821ee1ff",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "XY Finance",
      "Symbiosis",
      "OrbiterV2",
      "DeBridge",
      "LineaPancakeV3",
      "LineaKyberV3"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "USDC",
    "name": "USDC",
    "isPopular": true,
    "chainId": "534352",
    "address": "0x06efdbff2a14a7c8e15944d1f4a48f9f95f663a4",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 1.003,
    "supportedSwappers": [
      "XY Finance",
      "OrbiterV2",
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "BOBA",
    "symbol": "USDC",
    "name": null,
    "isPopular": true,
    "chainId": "288",
    "address": "0x66a2a913e447d6b4bf33efbec43aaef87890fbbc",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BOBA/icon.svg",
    "usdPrice": 0.999719,
    "supportedSwappers": [
      "Symbiosis"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USDC",
    "name": null,
    "isPopular": true,
    "chainId": "42161",
    "address": "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
    "decimals": 6,
    "image": "https://rango.vip/i/iICQ6P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "Symbiosis",
      "ParaSwap Arbitrum",
      "ArbitrumUniSwapV3",
      "DeBridge",
      "Circle CCTP",
      "Bridgers",
      "MayaProtocol",
      "SushiArbitrum",
      "AllBridge"
    ]
  },
  {
    "blockchain": "POLYGONZK",
    "symbol": "USDC",
    "name": null,
    "isPopular": true,
    "chainId": "1101",
    "address": "0xa8ce8aee21bc2a48a5ef670afcc9274c7bbbc035",
    "decimals": 6,
    "image": "https://rango.vip/i/yueAA6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGONZK/icon.svg",
    "usdPrice": 0.995354,
    "supportedSwappers": [
      "Symbiosis",
      "PolygonPancakeV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "USDC",
    "name": null,
    "isPopular": true,
    "chainId": "10",
    "address": "0x0b2c639c533813f4aa9d7837caf62653d097ff85",
    "decimals": 6,
    "image": "https://rango.vip/i/cY81Jl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "DeBridge",
      "Circle CCTP",
      "Bridgers",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "AllBridge"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "USDC",
    "name": null,
    "isPopular": true,
    "chainId": "8453",
    "address": "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
    "decimals": 6,
    "image": "https://rango.vip/i/074QYQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "BaseCurveFinance",
      "DeBridge",
      "SushiBase",
      "Circle CCTP",
      "AlienBase",
      "OneInchBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USDC",
    "name": null,
    "isPopular": true,
    "chainId": "137",
    "address": "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
    "decimals": 6,
    "image": "https://rango.vip/i/Pu7SFw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OrbiterV2",
      "DeBridge",
      "ParaSwap Polygon",
      "Circle CCTP",
      "PolygonUniSwapV3",
      "Bridgers",
      "QuickSwap",
      "OneInchPolygon",
      "AllBridge"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "USDC",
    "name": "USDC",
    "isPopular": true,
    "chainId": "SN_MAIN",
    "address": "0x53c91253bc9682c04929ca02ed00b3e423f6710d2ee7e0d5ebb06f3ecf368a8",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 0.999479,
    "supportedSwappers": [
      "Avnu",
      "OrbiterV2",
      "MySwap",
      "10KSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    "decimals": 6,
    "image": "https://rango.vip/i/aNVfBL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "Jupiter",
      "DeBridge",
      "Bridgers",
      "AllBridge"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": true,
    "chainId": "1313161554",
    "address": "0xb12bfca5a55806aaf64e99521918a4bf0fc40802",
    "decimals": 6,
    "image": "https://rango.vip/i/7YUUp2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.99913,
    "supportedSwappers": [
      "Rainbow Bridge"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "USDC",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/498a0751c798a0d9a389aa3691123dada57daa4fe165d5c75894505b876ba6e4",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.999719,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "ibc/eac38d55372f38f1afd68df7fe9ef762dcf69f26520643cf3f9d292a738d8034",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "WYNDDex",
      "Satellite",
      "IBC"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": true,
    "chainId": "34443",
    "address": "0xd988097fb8612cc24eec14542bc03424c656005f",
    "decimals": 6,
    "image": "https://rango.vip/i/0BjDMb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 0.9999164067433328,
    "supportedSwappers": [
      "SupSwapModeV3",
      "SwapMode"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": true,
    "chainId": "1285",
    "address": "0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d",
    "decimals": 6,
    "image": "https://rango.vip/i/ZVjX5G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.9999164067433328,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb04906e95ab5d797ada81508115611fee694c2b3",
    "decimals": 6,
    "image": "https://rango.vip/i/Cntotl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.997379,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "USDC",
    "name": "USDC",
    "isPopular": true,
    "chainId": "42220",
    "address": "0xceba9300f2b948710d2653dd7b07f33a8b32118c",
    "decimals": 6,
    "image": "https://rango.vip/i/QOv3iT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": true,
    "chainId": "1284",
    "address": "0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b",
    "decimals": 6,
    "image": "https://rango.vip/i/By4M4u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.074949,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x931715fee2d06333043d11f658c8ce934ac61d0c",
    "decimals": 6,
    "image": "https://rango.vip/i/3B3Dxs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 13.425938230137234,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "USDC",
    "name": "USDC",
    "isPopular": true,
    "chainId": "66",
    "address": "0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85",
    "decimals": 18,
    "image": "https://rango.vip/i/C2zkO5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.999719,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4318cb63a2b8edf2de971e2f17f77097e499459d",
    "decimals": 6,
    "image": "https://rango.vip/i/Cntotl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.997379,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "137",
    "address": "0x576cf361711cd940cd9c397bb98c4c896cbd38de",
    "decimals": 6,
    "image": "https://rango.vip/i/M4gWuP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.9999164067433328,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "USDC",
    "name": "USDC",
    "isPopular": false,
    "chainId": "324",
    "address": "0x1d17cbcf0d6d143135ae902365d2e5e2a16538d4",
    "decimals": 6,
    "image": "https://rango.vip/i/muT5GV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb24ca28d4e2742907115fecda335b40dbda07a4c",
    "decimals": 6,
    "image": "https://rango.vip/i/Cntotl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.997379,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x0950fc1ad509358daead5eb8020a3c7d8b43b9da",
    "decimals": 6,
    "image": "https://rango.vip/i/M4gWuP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.9999164067433328,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "USDC",
    "name": "USDC",
    "isPopular": true,
    "chainId": "128",
    "address": "0x9362bbef4b8313a8aa9f0c9808b80577aa26b73b",
    "decimals": 6,
    "image": "https://rango.vip/i/cBbgNS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.9999164067433328,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "42220",
    "address": "0xef4229c8c3250c675f21bcefa42f58efbff6002a",
    "decimals": 6,
    "image": "https://rango.vip/i/jvzILD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 1.0285978729217657,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "XLAYER",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": true,
    "chainId": "196",
    "address": "0x74b7f16337b8972027f6196a17a631ac6de26d22",
    "decimals": 6,
    "image": "https://rango.vip/i/p2Mini",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/XLAYER/icon.svg",
    "usdPrice": 0.999441,
    "supportedSwappers": [
      "PotatoSwap"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "USDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8",
    "decimals": 6,
    "image": "https://rango.vip/i/yffJxI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "NOBLE",
    "symbol": "USDC",
    "name": null,
    "isPopular": true,
    "chainId": "noble-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/rn8Puu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/NOBLE/icon.svg",
    "usdPrice": 0.9995497118159904,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "TRX",
    "name": "TRX",
    "isPopular": true,
    "chainId": "728126428",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/TRX.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.14920215884881757,
    "supportedSwappers": [
      "Bridgers",
      "SunSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TRX",
    "name": "TRON",
    "isPopular": false,
    "chainId": "56",
    "address": "0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b",
    "decimals": 18,
    "image": "https://rango.vip/i/sJnSkq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.14920215884881757,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TRX",
    "name": "TRON",
    "isPopular": false,
    "chainId": "56",
    "address": "0xce7de646e7208a4ef112cb6ed5038fa6cc6b12e3",
    "decimals": 6,
    "image": "https://rango.vip/i/OI7BEP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.149561,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRX",
    "name": "TRON",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1be5d3f34e89de342ee97e6e90d405884da6c67",
    "decimals": 6,
    "image": "https://rango.vip/i/xV0pAp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.14920215884881757,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRX",
    "name": "TRON",
    "isPopular": false,
    "chainId": "1",
    "address": "0x50327c6c5a14dcade707abad2e27eb517df87ab5",
    "decimals": 6,
    "image": "https://rango.vip/i/vAjBHr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.149111,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "TRX",
    "name": "TRON",
    "isPopular": false,
    "chainId": "10",
    "address": "0xb1ae0a34dc3976d98c1741c92b798daf8e0e1e11",
    "decimals": 6,
    "image": "https://rango.vip/i/moiIuf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.14920215884881757,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ADA",
    "name": "ADA Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x0e517979c2c1c1522ddb0c73905e0d39b3f990c0",
    "decimals": 6,
    "image": "https://rango.vip/i/p4vcQs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.318391,
    "supportedSwappers": [
      "CronaSwap",
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ADA",
    "name": "Cardano Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
    "decimals": 18,
    "image": "https://rango.vip/i/Sw12Eo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.320952,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ADA",
    "name": "Ada",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E4Q5pLaEiejwEQHcM9GeYSQfMyGy8DJ4bPWgeYthn24v",
    "decimals": 9,
    "image": "https://rango.vip/i/MtEHkB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00130474,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "ADA",
    "name": "ADA",
    "isPopular": false,
    "chainId": "128",
    "address": "0x843af718ef25708765a8e0942f89edeae1d88df0",
    "decimals": 6,
    "image": "https://rango.vip/i/pIgPxX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1.6737459612964307,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AVAX",
    "name": "AVAX",
    "isPopular": false,
    "chainId": "43114",
    "address": null,
    "decimals": 18,
    "image": "https://api.rango.exchange/tokens/AVAX/AVAX.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 21.5,
    "supportedSwappers": [
      "ThorChain",
      "ParaSwap Avalanche",
      "PangolinSwap",
      "Hyphen",
      "Bridgers",
      "Satellite",
      "AvaxChainV3",
      "ThorChainStreamingSwap",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "AVAX",
    "name": "Avalanche",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844",
    "decimals": 18,
    "image": "https://rango.vip/i/3NbIfL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 2.8718671938496176,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "AVAX",
    "name": "Avalanche | Multichain",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x4792c1ecb969b036eb51330c63bd27899a13d84e",
    "decimals": 18,
    "image": "https://rango.vip/i/RGkJWi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 21.5,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "AVAX",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/6f62f01d913e3ffe472a38c78235b8f021b511bc6596adff02615c8f83d3b373",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/AVAX.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 21.5504014237,
    "supportedSwappers": [
      "Satellite",
      "Osmosis"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "AVAX",
    "name": "AVAX",
    "isPopular": false,
    "chainId": "25",
    "address": "0x8d58088d4e8ffe75a8b6357ba5ff17b93b912640",
    "decimals": 9,
    "image": "https://rango.vip/i/XooUdm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 21.577081182469545,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "AVAX",
    "name": "Avalanche",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x14a0243c333a5b238143068dc3a7323ba4c30ecb",
    "decimals": 18,
    "image": "https://rango.vip/i/sII5kz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 21.5,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AVAX",
    "name": "AVAX (Allbridge from Avalanche)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AUrMpCDYYcPuHhyNX8gEEqbmDPFUpBpHrNW3vPeCFn5Z",
    "decimals": 9,
    "image": "https://rango.vip/i/XkfowS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 21.57563606637287,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AVAX",
    "name": "AVAX (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE",
    "decimals": 8,
    "image": "https://rango.vip/i/K2bR2B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 21.46,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AVAX",
    "name": "Avalanche Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b",
    "decimals": 18,
    "image": "https://rango.vip/i/hJoPoC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 21.5,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AVAX",
    "name": "AVAX",
    "isPopular": true,
    "chainId": "56",
    "address": "0x1ce0c2827e2ef14d5c4f29a091d735a204794041",
    "decimals": 18,
    "image": "https://rango.vip/i/Y9UsM4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 21.57,
    "supportedSwappers": [
      "OneInchBsc",
      "Bridgers"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "AVAX",
    "name": "Allbridge AVAX",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x8e3670fd7b0935d3fe832711debfe13bb689b690",
    "decimals": 18,
    "image": "https://rango.vip/i/enZ2pM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 21.483721305679442,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "BCH",
    "symbol": "BCH",
    "name": null,
    "isPopular": true,
    "chainId": "",
    "address": null,
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/BCH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BCH/icon.svg",
    "usdPrice": 309.423137677657,
    "supportedSwappers": [
      "ThorChain",
      "ThorChainStreamingSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "BCH",
    "name": "Bitcoin Cash",
    "isPopular": false,
    "chainId": "25",
    "address": "0x7589b70abb83427bb7049e08ee9fc6479ccb7a23",
    "decimals": 8,
    "image": "https://rango.vip/i/xouOtQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 309.4631106200653,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BCH",
    "name": "Bitcoin Cash Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8ff795a6f4d97e7887c79bea79aba5cc76444adf",
    "decimals": 18,
    "image": "https://rango.vip/i/drR4Hv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 307.41,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "LINK",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/d3327a763c23f01ec43d1f0db3cefec390c362569b6fd191f40a5192f8960049",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/LINK.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 9.91,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LINK",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x514910771af9ca656af840dff83e8264ecf986ca",
    "decimals": 18,
    "image": "https://rango.vip/i/9MVlyi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.91,
    "supportedSwappers": [
      "ThorChain",
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "Bridgers",
      "ThorChainStreamingSwap",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LINK",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
    "decimals": 18,
    "image": "https://rango.vip/i/3PRSP3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 9.91,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3",
      "MayaProtocol",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "LINK",
    "name": "Chainlink",
    "isPopular": false,
    "chainId": "25",
    "address": "0xbc6f24649ccd67ec42342accdceccb2efa27c9d9",
    "decimals": 18,
    "image": "https://rango.vip/i/aXzAgD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 9.929136734865713,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "LINK",
    "name": "ChainLink Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6",
    "decimals": 18,
    "image": "https://rango.vip/i/oWKRkL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 9.91,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "LINK",
    "name": "Chainlink",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x0dcb0cb0120d355cde1ce56040be57add0185baa",
    "decimals": 18,
    "image": "https://rango.vip/i/1miSgf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 9.929357490265007,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LINK",
    "name": "ChainLink Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39",
    "decimals": 18,
    "image": "https://rango.vip/i/ps0ehq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 9.91,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "PolygonUniSwapV3",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LINK",
    "name": "ChainLink Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
    "decimals": 18,
    "image": "https://rango.vip/i/ZcQ37s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 9.91,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "LINK",
    "name": "LINK",
    "isPopular": false,
    "chainId": "128",
    "address": "0x9e004545c59d359f6b7bfb06a26390b087717b42",
    "decimals": 18,
    "image": "https://rango.vip/i/88X0jG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 9.91,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "DAI",
    "name": "Dai Stablecoin",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xe3520349f477a5f6eb06107066048508498a291b",
    "decimals": 18,
    "image": "https://rango.vip/i/NMILhp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.994282,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "DAI",
    "name": "DAI Stablecoin | Multichain",
    "isPopular": true,
    "chainId": "1284",
    "address": "0x765277eebeca2e31912c9946eae1021199b39c61",
    "decimals": 18,
    "image": "https://rango.vip/i/YV9OqX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.19715350929040187,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAI",
    "name": "Dai Stablecoin",
    "isPopular": true,
    "chainId": "1",
    "address": "0x6b175474e89094c44da98b954eedeac495271d0f",
    "decimals": 18,
    "image": "https://rango.vip/i/KlMakr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999859,
    "supportedSwappers": [
      "ThorChain",
      "Arbitrum Bridge",
      "Satellite",
      "ThorChainStreamingSwap",
      "stargate",
      "Optimism Bridge",
      "Across",
      "Polygon Bridge",
      "OneInchEth",
      "Synapse Swapper",
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "DAI",
    "name": "Dai Stablecoin",
    "isPopular": true,
    "chainId": "10",
    "address": "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    "decimals": 18,
    "image": "https://rango.vip/i/JBvU2A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "stargate",
      "ParaSwap Optimism",
      "Optimism Bridge",
      "Across"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DAI",
    "name": "Dai Stablecoin",
    "isPopular": true,
    "chainId": "137",
    "address": "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
    "decimals": 18,
    "image": "https://rango.vip/i/biAR5Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.999589,
    "supportedSwappers": [
      "Synapse Swapper",
      "PolygonCurveFinance",
      "ParaSwap Polygon",
      "PolygonUniSwapV3",
      "Bridgers",
      "QuickSwap",
      "stargate",
      "OneInchPolygon",
      "Across",
      "Polygon Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DAI",
    "name": "Dai Stablecoin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    "decimals": 18,
    "image": "https://rango.vip/i/Z58Mn8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.002,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3",
      "MayaProtocol",
      "SushiArbitrum",
      "Across"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "DAI",
    "name": "Dai Stablecoin",
    "isPopular": false,
    "chainId": "324",
    "address": "0x4b9eb6c0b6ea15176bbf62841c6b2a8a398cb656",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/DAI.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.997175,
    "supportedSwappers": [
      "Across"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DAI",
    "name": "Dai Stablecoin",
    "isPopular": true,
    "chainId": "8453",
    "address": "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
    "decimals": 18,
    "image": "https://rango.vip/i/zPmURd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.999896,
    "supportedSwappers": [
      "Across",
      "OneInchBase",
      "AlienBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "DAI",
    "name": "Dai Stablecoin",
    "isPopular": false,
    "chainId": "59144",
    "address": "0x4af15ec2a0bd43db75dd04e62faa3b8ef36b00d5",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/DAI.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "LineaPancakeV3",
      "Across",
      "LineaKyberV3"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "DAI",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/0cd3a0285e1341859b5e86b6ab7682f023d03e97607ccc1dc95706411d866df7",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/DAI.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 1.00052253101312,
    "supportedSwappers": [
      "Satellite",
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "DAI",
    "name": null,
    "isPopular": true,
    "chainId": "SN_MAIN",
    "address": "0xda114221cb83fa859dbdb4c44beeaa0bb37c7537ad5ae66fe5e0efd20e6eb3",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/DAI.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 0.999416,
    "supportedSwappers": [
      "10KSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "DAI",
    "name": "Dai Stablecoin",
    "isPopular": true,
    "chainId": "25",
    "address": "0xf2001b145b43032aaf5ee2884e456ccd805f677d",
    "decimals": 18,
    "image": "https://rango.vip/i/dO71vO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 1.015,
    "supportedSwappers": [
      "CronaSwap",
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "DAI",
    "name": "Dai",
    "isPopular": true,
    "chainId": "1285",
    "address": "0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844",
    "decimals": 18,
    "image": "https://rango.vip/i/jHHuFw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.555465,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "DAI",
    "name": "Dai Stablecoin",
    "isPopular": true,
    "chainId": "534352",
    "address": "0xca77eb3fefe3725dc33bccb54edefc3d9f764f97",
    "decimals": 18,
    "image": "https://rango.vip/i/ZyOWqa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 0.999926077701459,
    "supportedSwappers": [
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DAI",
    "name": "Binance Pegged DAI",
    "isPopular": true,
    "chainId": "56",
    "address": "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
    "decimals": 18,
    "image": "https://rango.vip/i/qZquQP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.999421,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGONZK",
    "symbol": "DAI",
    "name": "Polygon zkEVM Bridged DAI  Polygon zkEV",
    "isPopular": true,
    "chainId": "1101",
    "address": "0xc5015b9d9161dca7e18e32f6f25c4ad850731fd4",
    "decimals": 18,
    "image": "https://rango.vip/i/EzsnVL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGONZK/icon.svg",
    "usdPrice": 0.99734,
    "supportedSwappers": [
      "PolygonPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DAI",
    "name": "DAI (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o",
    "decimals": 8,
    "image": "https://rango.vip/i/PjnNe5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.999926077701459,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "DAI",
    "name": "Dai Stablecoin",
    "isPopular": false,
    "chainId": "SN_MAIN",
    "address": "0x5574eb6b8789a91466f902c380d978e472db68170ff82a5b650b95a58ddf4ad",
    "decimals": 18,
    "image": "https://rango.vip/i/KlMakr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 0.999926077701459,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DAI",
    "name": "DAI",
    "isPopular": true,
    "chainId": "128",
    "address": "0x3d760a45d0887dfd89a2f5385a236b29cb46ed2a",
    "decimals": 18,
    "image": "https://rango.vip/i/0xZb9J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 3.1625636000820605,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "DAI",
    "name": "Optics v2 DAI",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x90ca507a5d4458a4c6c6249d186b6dcb02a5bccd",
    "decimals": 18,
    "image": "https://rango.vip/i/gKmP4N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 1.119313558012652,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "LTC",
    "symbol": "LTC",
    "name": null,
    "isPopular": true,
    "chainId": "",
    "address": null,
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/LTC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LTC/icon.svg",
    "usdPrice": 65.31129691737058,
    "supportedSwappers": [
      "ThorChain",
      "ThorChainStreamingSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "LTC",
    "name": "Litecoin",
    "isPopular": false,
    "chainId": "25",
    "address": "0x9d97be214b68c7051215bb61059b4e299cd792c3",
    "decimals": 8,
    "image": "https://rango.vip/i/2fbMSI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 65.37589877730753,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LTC",
    "name": "Litecoin Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4338665cbb7b2485a8855a139b75d5e34ab0db94",
    "decimals": 18,
    "image": "https://rango.vip/i/3r7gBx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 65.19,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "LTC",
    "name": "Litecoin",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TR3DLthpnDdCGabhVDbD3VMsiJoCXY3bZd",
    "decimals": 8,
    "image": "https://rango.vip/i/Lf3Pc8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 65.24094214487448,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "NEAR",
    "name": "NEAR",
    "isPopular": true,
    "chainId": "1313161554",
    "address": "0xc42c30ac6cc15fac9bd938618bcaa1a1fae8501d",
    "decimals": 24,
    "image": "https://rango.vip/i/V2Fo9H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 3.64,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora",
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "NEAR",
    "name": "NEAR",
    "isPopular": false,
    "chainId": "25",
    "address": "0xafe470ae215e48c144c7158eae3ccf0c451cb0cb",
    "decimals": 24,
    "image": "https://rango.vip/i/7skYcY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 3.633811386672868,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NEAR",
    "name": "NEAR (Allbridge from Near)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BYPsjxa3YuZESQz1dKuBw1QSFCSpecsm8nCQhY5xbU1Z",
    "decimals": 9,
    "image": "https://rango.vip/i/GSAnqU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.644345594484973,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NEAR",
    "name": "NEAR",
    "isPopular": false,
    "chainId": "137",
    "address": "0x72bd80445b0db58ebe3e8db056529d4c5faf6f2f",
    "decimals": 18,
    "image": "https://rango.vip/i/NknQCS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 3.644345594484973,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEAR",
    "name": "NEAR Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85f17cf997934a597031b2e18a9ab6ebd4b9f6a4",
    "decimals": 24,
    "image": "https://rango.vip/i/dhUwGz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.64,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NEAR",
    "name": "NEAR Protocol",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1fa4a73a3f0133f0025378af00236f3abdee5d63",
    "decimals": 18,
    "image": "https://rango.vip/i/9gdUW1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.64,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAS",
    "name": "Wrapped Kaspa",
    "isPopular": false,
    "chainId": "1",
    "address": "0x112b08621e27e10773ec95d250604a041f36c582",
    "decimals": 8,
    "image": "https://rango.vip/i/o292do",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.145236,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ICP",
    "name": "Internet Computer",
    "isPopular": false,
    "chainId": "25",
    "address": "0x8bf3e654075e269c1c415e4889c12d9837452be0",
    "decimals": 8,
    "image": "https://rango.vip/i/Q1g3vl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 7.1329538555306415,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "FET",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/5d1f516200ee8c6b2354102143b78a2deda25ede771ac0f8dc3c1837c8fd4447",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/FET.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 1.055,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FET",
    "name": "Fetch ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaea46a60368a7bd060eec7df8cba43b7ef41ad85",
    "decimals": 18,
    "image": "https://rango.vip/i/QQe5jh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.055,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FET",
    "name": "FetchToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0x031b41e504677879370e9dbcf937283a8691fa7f",
    "decimals": 18,
    "image": "https://rango.vip/i/E5P2xh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.055,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FET",
    "name": "Fetch ai",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4be87c766a7ce11d5cc864b6c3abb7457dcc4cc9",
    "decimals": 18,
    "image": "https://rango.vip/i/PcXrUI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.0572796625561713,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "XLM",
    "name": "XLM",
    "isPopular": false,
    "chainId": "25",
    "address": "0x747d6c858168b8cd6e537160320b5de58fd3367c",
    "decimals": 7,
    "image": "https://rango.vip/i/HVYOEL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0894198087522186,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ETC",
    "name": "Ethereum Classic",
    "isPopular": false,
    "chainId": "25",
    "address": "0xd9ce64c200721a98103102f9ea8e894e347ea287",
    "decimals": 18,
    "image": "https://rango.vip/i/Yn8mCf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 17.545402565345604,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ETC",
    "name": "Ethereum Classic",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3d6545b08693dae087e957cb1180ee38b9e3c25e",
    "decimals": 18,
    "image": "https://rango.vip/i/Zki1Wb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 17.581943785173458,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FDUSD",
    "name": "First Digital USD",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc5f0f7b66764f6ec8c8dff7ba683102295e16409",
    "decimals": 18,
    "image": "https://rango.vip/i/fk8a8s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.997944,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FDUSD",
    "name": "First Digital USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc5f0f7b66764f6ec8c8dff7ba683102295e16409",
    "decimals": 18,
    "image": "https://rango.vip/i/kqgPV9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997944,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "OKB",
    "name": "OKB",
    "isPopular": false,
    "chainId": "66",
    "address": "0xdf54b6c6195ea4d948d03bfd818d365cf175cfc2",
    "decimals": 18,
    "image": "https://rango.vip/i/Lf5FRJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 36.07,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OKB",
    "name": "OKB",
    "isPopular": false,
    "chainId": "1",
    "address": "0x75231f58b43240c9718dd58b4967c5114342a86c",
    "decimals": 18,
    "image": "https://rango.vip/i/hy38PQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 36.07,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "XLAYER",
    "symbol": "OKB",
    "name": "OKB",
    "isPopular": true,
    "chainId": "196",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/PIVMde",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/XLAYER/icon.svg",
    "usdPrice": 36.06,
    "supportedSwappers": [
      "PotatoSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POL",
    "name": "Polygon Ecosystem Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x455e53cbb86018ac2b8092fdcd39d8444affc3f6",
    "decimals": 18,
    "image": "https://rango.vip/i/ptUjLX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.37004,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "CRO",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/e6931f78057f7cc5da0fd6cef82ff39373a6e0452bf1fd76910b93292cf356c1",
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/CRO.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.076413,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRO",
    "name": "Cronos",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
    "decimals": 8,
    "image": "https://rango.vip/i/LqX0G2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.076427,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CRO",
    "name": "Cronos",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8ea3156f834a0dfc78f1a5304fac2cda676f354c",
    "decimals": 8,
    "image": "https://rango.vip/i/pmpAyu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.07647575520967295,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CRO",
    "name": "CRO",
    "isPopular": true,
    "chainId": "25",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/S3tFKF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.076413,
    "supportedSwappers": [
      "CronaSwap",
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRYPTO_ORG",
    "symbol": "CRO",
    "name": null,
    "isPopular": true,
    "chainId": "crypto-org-chain-mainnet-1",
    "address": null,
    "decimals": 8,
    "image": "https://rango.vip/i/m0Whw4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRYPTO_ORG/icon.svg",
    "usdPrice": 0.07636140783982151,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "FIL",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/18fb5c09d9d2371f659d4846a956fa56225e377ee3c3652a2bf3542bf809159d",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/FIL.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "FIL",
    "name": "Filecoin",
    "isPopular": false,
    "chainId": "25",
    "address": "0x7d7130b0b4733d603cea12628b52067ce8458058",
    "decimals": 18,
    "image": "https://rango.vip/i/vKbsPE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 3.323968700930664,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FIL",
    "name": "Filecoin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153",
    "decimals": 18,
    "image": "https://rango.vip/i/epM89A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.35,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAVE",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
    "decimals": 18,
    "image": "https://rango.vip/i/h4K78m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 126.07,
    "supportedSwappers": [
      "ThorChain",
      "EthereumUniswapV3",
      "Bridgers",
      "ThorChainStreamingSwap",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "AAVE",
    "name": "Aave",
    "isPopular": false,
    "chainId": "25",
    "address": "0xe657b115bc45c0786274c824f83e3e02ce809185",
    "decimals": 18,
    "image": "https://rango.vip/i/rVjcDm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 127.29744323662929,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "AAVE",
    "name": "Aave",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xf27ee99622c3c9b264583dacb2cce056e194494f",
    "decimals": 18,
    "image": "https://rango.vip/i/J3qlZS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 126.51283444632418,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "AAVE",
    "name": "Aave",
    "isPopular": false,
    "chainId": "10",
    "address": "0x76fb31fb4af56892a25e32cfc43de717950c9278",
    "decimals": 18,
    "image": "https://rango.vip/i/41AAQu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 126.07,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AAVE",
    "name": "Aave",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd6df932a45c0f255f85145f286ea0b292b21c90b",
    "decimals": 18,
    "image": "https://rango.vip/i/1qCXGa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 126.07,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "PolygonUniSwapV3",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AAVE",
    "name": "Aave",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfb6115445bff7b52feb98650c87f44907e58f802",
    "decimals": 18,
    "image": "https://rango.vip/i/SHhxeO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 126.07,
    "supportedSwappers": [
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AAVE",
    "name": "Aave",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xba5ddd1f9d7f570dc94a51479a000e3bce967196",
    "decimals": 18,
    "image": "https://rango.vip/i/EgNwWL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 126.51283444632418,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "AAVE",
    "name": "AAVE",
    "isPopular": false,
    "chainId": "128",
    "address": "0x202b4936fe1a82a4965220860ae46d7d3939bb25",
    "decimals": 18,
    "image": "https://rango.vip/i/bLHryq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 126.07,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "IMX",
    "name": "Immutable X",
    "isPopular": false,
    "chainId": "25",
    "address": "0x8f27db0e597b67f33d5fb58d5eecd6a3cc780942",
    "decimals": 18,
    "image": "https://rango.vip/i/HYeWwY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 1.172161416334415,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IMX",
    "name": "Immutable X",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf57e7e7c23978c3caec3c3548e3d615c346e79ff",
    "decimals": 18,
    "image": "https://rango.vip/i/yrLHzN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.16,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IMX",
    "name": "Impermax",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7b35ce522cb72e4077baeb96cb923a5529764a00",
    "decimals": 18,
    "image": "https://rango.vip/i/g6OtAS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.16813320213526,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "IMX",
    "name": "Impermax",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9c67ee39e3c4954396b9142010653f17257dd39c",
    "decimals": 18,
    "image": "https://rango.vip/i/g6OtAS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.16813320213526,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "IMX",
    "name": "Immutable X",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3cfd99593a7f035f717142095a3898e3fca7783e",
    "decimals": 18,
    "image": "https://rango.vip/i/WFWsY6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.16813320213526,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RENDER",
    "name": "Render Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof",
    "decimals": 8,
    "image": "https://rango.vip/i/QiMGH1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.68,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "MATIC",
    "name": "Polygon",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8",
    "decimals": 18,
    "image": "https://rango.vip/i/lg5GlB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.8892357976751738,
    "supportedSwappers": [
      "TrisolarisSwap",
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "MATIC",
    "name": "Polygon | Multichain",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x3405a1bd46b85c5c029483fbecf2f3e611026e45",
    "decimals": 18,
    "image": "https://rango.vip/i/uOZ4Gh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.36968,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "MATIC",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/ab589511ed0dd5fa56171a39978afbf1371db986ec1c3526ce138a16377e39bb",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/MATIC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.3684147699,
    "supportedSwappers": [
      "Satellite",
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MATIC",
    "name": null,
    "isPopular": true,
    "chainId": "1",
    "address": "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    "decimals": 18,
    "image": "https://rango.vip/i/WZubx1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.36968,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Hyphen",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MATIC",
    "name": null,
    "isPopular": true,
    "chainId": "137",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/cFz5tK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.368925,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "ParaSwap Polygon",
      "Hyphen",
      "Bridgers",
      "PolygonUniSwapV3",
      "Satellite",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "MATIC",
    "name": "Matic Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xf78a326acd53651f8df5d8b137295e434b7c8ba5",
    "decimals": 18,
    "image": "https://rango.vip/i/1D1quj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.3685191215040465,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "MATIC",
    "name": "Polygon",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x682f81e57eaa716504090c3ecba8595fb54561d8",
    "decimals": 18,
    "image": "https://rango.vip/i/PEj4Gq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.36968,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MATIC",
    "name": "Polygon",
    "isPopular": true,
    "chainId": "56",
    "address": "0xcc42724c6683b7e57334c4e856f4c9965ed682bd",
    "decimals": 18,
    "image": "https://rango.vip/i/2oPqea",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.36968,
    "supportedSwappers": [
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "POLYGONZK",
    "symbol": "MATIC",
    "name": "Matic Token",
    "isPopular": true,
    "chainId": "1101",
    "address": "0xa2036f0538221a77a3937f1379699f44945018d0",
    "decimals": 18,
    "image": "https://rango.vip/i/vFYLXj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGONZK/icon.svg",
    "usdPrice": 0.368925,
    "supportedSwappers": [
      "PolygonPancakeV3"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "MATIC",
    "name": "Matic Token",
    "isPopular": false,
    "chainId": "59144",
    "address": "0x265b25e22bcd7f10a5bd6e6410f10537cc7567e8",
    "decimals": 18,
    "image": "https://rango.vip/i/QsiCC7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 0.368925,
    "supportedSwappers": [
      "EchoDex"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "HBAR",
    "name": "Hedera",
    "isPopular": false,
    "chainId": "25",
    "address": "0xe0c7226a58f54db71edc6289ba2dc80349b41974",
    "decimals": 8,
    "image": "https://rango.vip/i/wCN8nk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.04763607337857469,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "VET",
    "name": "VET",
    "isPopular": false,
    "chainId": "25",
    "address": "0xc9b5b981e7ffd630f456614d75230022f46fd03d",
    "decimals": 18,
    "image": "https://rango.vip/i/rgoXn8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.02057792925816029,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VET",
    "name": "VeChain",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6fdcdfef7c496407ccb0cec90f9c5aaa1cc8d888",
    "decimals": 18,
    "image": "https://rango.vip/i/8a3Zhn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.020471637600063874,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "INJ",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/64ba6e31fe887d66c6f8f31c7b1a80c7ca179239677b4088bb55f5ea07dbe273",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/INJ.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 16.73594558910208,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "INJ",
    "name": "Injective",
    "isPopular": false,
    "chainId": "25",
    "address": "0x4e05f3c7ee6155e3add224470e1c4583d4f424a3",
    "decimals": 18,
    "image": "https://rango.vip/i/qTdcQc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 16.879392135231303,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INJ",
    "name": "Injective",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe28b3b32b6c345a34ff64674606124dd5aceca30",
    "decimals": 18,
    "image": "https://rango.vip/i/Hxq5vd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 16.74,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "INJ",
    "name": "Injective Protocol",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa2b726b1145a4773f68593cf171187d8ebe4d495",
    "decimals": 18,
    "image": "https://rango.vip/i/hYQiRa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 16.74,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "INJ",
    "name": "Injective",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2a2053cb633cad465b4a8975ed3d7f09df608f80",
    "decimals": 18,
    "image": "https://rango.vip/i/uEa4Z6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 16.777224718027455,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "INJECTIVE",
    "symbol": "INJ",
    "name": null,
    "isPopular": true,
    "chainId": "injective-1",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/Yx5e24",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/INJECTIVE/icon.svg",
    "usdPrice": 16.75232910186385,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "OP",
    "name": "Optimism",
    "isPopular": false,
    "chainId": "10",
    "address": "0x4200000000000000000000000000000000000042",
    "decimals": 18,
    "image": "https://rango.vip/i/berGHG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.33,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "Bridgers",
      "OptimismUniSwapV3",
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "MKR",
    "name": "Maker",
    "isPopular": false,
    "chainId": "25",
    "address": "0xab9cf8c5a9b6cf5215c82d088d37d04bb146704a",
    "decimals": 18,
    "image": "https://rango.vip/i/yDCYIt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 1617.6399262578668,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MKR",
    "name": "Maker",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
    "decimals": 18,
    "image": "https://rango.vip/i/jHmtsb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1605.67,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MKR",
    "name": "Maker",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6f7c932e7684666c9fd1d44527765433e01ff61d",
    "decimals": 18,
    "image": "https://rango.vip/i/jHmtsb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1605.67,
    "supportedSwappers": [
      "PolygonUniSwapV3",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MKR",
    "name": "Maker",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2e9a6df78e42a30712c10a9dc4b1c8656f8f2879",
    "decimals": 18,
    "image": "https://rango.vip/i/HOeFk9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1610.2703060972558,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MKR",
    "name": "Maker",
    "isPopular": false,
    "chainId": "10",
    "address": "0xab7badef82e9fe11f6f33f87bc9bc2aa27f2fcb5",
    "decimals": 18,
    "image": "https://rango.vip/i/VQJlSL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1610.2703060972558,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "MKR",
    "name": "MKR",
    "isPopular": false,
    "chainId": "128",
    "address": "0x34d75515090902a513f009f4505a750efaad63b0",
    "decimals": 18,
    "image": "https://rango.vip/i/OZWEGx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 8402.936862305198,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "AR",
    "name": "Arweave",
    "isPopular": false,
    "chainId": "25",
    "address": "0xeb6b3aeda7a2705fac5e2350fa4d71a64b393b37",
    "decimals": 12,
    "image": "https://rango.vip/i/TWS6kU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 21.116912154857378,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BGB",
    "name": "BitgetToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x54d2252757e1672eead234d27b1270728ff90581",
    "decimals": 18,
    "image": "https://rango.vip/i/d7logV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.951293,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "HNT",
    "name": "Helium",
    "isPopular": false,
    "chainId": "25",
    "address": "0x61426c150207abf8a215f3377a0819ddca842af3",
    "decimals": 8,
    "image": "https://rango.vip/i/Y39LSx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 8.262298058502395,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HNT",
    "name": "Helium Network Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux",
    "decimals": 8,
    "image": "https://rango.vip/i/ZGQ8vr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 8.21,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "GRT",
    "name": "The Graph",
    "isPopular": false,
    "chainId": "25",
    "address": "0x4c523222cd0de11616f7ad685f24145b9faf7996",
    "decimals": 18,
    "image": "https://rango.vip/i/Pfvvml",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.13398354433693346,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRT",
    "name": "The Graph",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc944e90c64b2c07662a292be6244bdf05cda44a7",
    "decimals": 18,
    "image": "https://rango.vip/i/2okpYd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.133002,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GRT",
    "name": "The Graph",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5fe2b58c013d7601147dcdd68c143a77499f5531",
    "decimals": 18,
    "image": "https://rango.vip/i/lFbDFT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.133002,
    "supportedSwappers": [
      "PolygonUniSwapV3",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GRT",
    "name": "The Graph",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9623063377ad1b27544c965ccd7342f7ea7e88c7",
    "decimals": 18,
    "image": "https://rango.vip/i/DRLDGe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.133002,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "THETA",
    "name": "Theta Network",
    "isPopular": false,
    "chainId": "25",
    "address": "0x73b6fcf8ed6daefe3775bc38949f115305047c0d",
    "decimals": 18,
    "image": "https://rango.vip/i/U3fohT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 1.1137721517446189,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "FTM",
    "name": "Fantom | Multichain",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xc19281f22a075e0f10351cd5d6ea9f0ac63d4327",
    "decimals": 18,
    "image": "https://rango.vip/i/zSNL65",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.13110464883707126,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "FTM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/5e2dfdf1734137302129ea1c1ba21a580f96f778d4f021815ea4f6db378da1a4",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/FTM.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.3818895558,
    "supportedSwappers": [
      "Satellite",
      "Osmosis"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "FTM",
    "name": "Fantom Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x63888bafc5975630e4e5cf50c3845a3250115f64",
    "decimals": 18,
    "image": "https://rango.vip/i/0iuTdT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.386888086914845,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FTM",
    "name": "Fantom Token",
    "isPopular": true,
    "chainId": "1",
    "address": "0x4e15361fd6b4bb609fa63c81a2be19d873717870",
    "decimals": 18,
    "image": "https://rango.vip/i/Ojtuus",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.383305,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "FTM",
    "name": "Fantom",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xad12dab5959f30b9ff3c2d6709f53c335dc39908",
    "decimals": 18,
    "image": "https://rango.vip/i/d1f9ok",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.383305,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FTM",
    "name": "Fantom",
    "isPopular": false,
    "chainId": "56",
    "address": "0xad29abb318791d579433d831ed122afeaf29dcfe",
    "decimals": 18,
    "image": "https://rango.vip/i/wg0Gme",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.383305,
    "supportedSwappers": [
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FTM",
    "name": "FTM (Allbridge from Fantom)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EsPKhGTMf3bGoy4Qm7pCv3UCcWqAmbC1UGHBTDxRjjD4",
    "decimals": 9,
    "image": "https://rango.vip/i/Id2tKk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.3845632823667278,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FTM",
    "name": "Fantom",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd42785d323e608b9e99fa542bd8b1000d4c2df37",
    "decimals": 18,
    "image": "https://rango.vip/i/CThBfq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.3845632823667278,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "FTM",
    "name": "Anyswap FTM",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x218c3c3d49d0e7b37aff0d8bb079de36ae61a4c0",
    "decimals": 18,
    "image": "https://rango.vip/i/RWiTsN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.3021070771013817,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ALGO",
    "name": "Algorand",
    "isPopular": false,
    "chainId": "25",
    "address": "0x2fefe47989214c2e74a6319076c138d395681407",
    "decimals": 6,
    "image": "https://rango.vip/i/BIJOe6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.11655882859451883,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALGO",
    "name": "Algowave",
    "isPopular": false,
    "chainId": "1",
    "address": "0x29ffeffcd2154824c6e645afeacca4bd95c893d2",
    "decimals": 18,
    "image": "https://rango.vip/i/8JOKjP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03698109,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PYTH",
    "name": "Pyth Network",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3",
    "decimals": 6,
    "image": "https://rango.vip/i/2o8tM2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.25732,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PYUSD",
    "name": "PayPal USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c3ea9036406852006290770bedfcaba0e23a0e8",
    "decimals": 6,
    "image": "https://rango.vip/i/0fC45P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999719,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PYUSD",
    "name": "PayPal USD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo",
    "decimals": 6,
    "image": "https://rango.vip/i/axdNfU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.999719,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "JASMY",
    "name": "JasmyCoin",
    "isPopular": false,
    "chainId": "25",
    "address": "0x227edf65f866255a0ed4b5b453fe43a41182ec3a",
    "decimals": 18,
    "image": "https://rango.vip/i/XsKhyk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.01792860590089277,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JASMY",
    "name": "JasmyCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7420b4b9a0110cdc71fb720908340c03f9bc03ec",
    "decimals": 18,
    "image": "https://rango.vip/i/5hVm39",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01781602,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "JASMY",
    "name": "JasmyCoin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x15669cf161946c09a8b207650bfbb00e3d8a2e3e",
    "decimals": 18,
    "image": "https://rango.vip/i/p4Mhu0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01788972137168323,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "JASMY",
    "name": "JasmyCoin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x25f05699548d3a0820b99f93c10c8bb573e27083",
    "decimals": 18,
    "image": "https://rango.vip/i/kAqHZl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01788972137168323,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "SEI",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/71f11bc0af8e526b80e44172eba9d3f0a8e03950bb882325435691ebc9450b1d",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/SEI.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.2680928718978048,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SEI",
    "name": "Solanium Ecosystem Index",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M",
    "decimals": 6,
    "image": "https://rango.vip/i/kMftl9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.2670537255051638,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "LDO",
    "name": "Lido DAO Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xa37b89c9fcdee564b95d5ce6ce6d90ad1038ee92",
    "decimals": 18,
    "image": "https://rango.vip/i/xzI3fh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.9554106133090875,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LDO",
    "name": "Lido DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5a98fcbea516cf06857215779fd812ca3bef1b32",
    "decimals": 18,
    "image": "https://rango.vip/i/NJiY9q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.956001,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "LDO",
    "name": "Lido DAO Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xfdb794692724153d1488ccdbe0c56c252596735f",
    "decimals": 18,
    "image": "https://rango.vip/i/P38Tj8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.956001,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LDO",
    "name": "Lido DAO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x13ad51ed4f1b7e9dc168d8a00cb3f4ddd85efa60",
    "decimals": 18,
    "image": "https://rango.vip/i/dbtci0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.956001,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LDO",
    "name": "Lido DAO (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p",
    "decimals": 8,
    "image": "https://rango.vip/i/xtIYbL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.97454,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LDO",
    "name": "Lido DAO Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc3c7d422809852031b44ab29eec9f1eff2a58756",
    "decimals": 18,
    "image": "https://rango.vip/i/dUb4D1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.956001,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "LDO",
    "name": "Lido DAO Token",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x6ccf12b480a99c54b23647c995f4525d544a7e72",
    "decimals": 18,
    "image": "https://rango.vip/i/HuCvQC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 1.3279748614449225,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "FLOW",
    "name": "Flow",
    "isPopular": false,
    "chainId": "25",
    "address": "0x22ef9d73ea90e774cfb21faddf84b37bd54fe7a6",
    "decimals": 8,
    "image": "https://rango.vip/i/MQpIlE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.5231310061187042,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONDO",
    "name": "Ondo Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfaba6f8e4a5e8ab82f62fe7c39859fa577269be3",
    "decimals": 18,
    "image": "https://rango.vip/i/9juLsL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.569955,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ONDO",
    "name": "Ondo Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa2d52a05b8bead5d824df54dd1aa63188b37a5e7",
    "decimals": 18,
    "image": "https://rango.vip/i/UQl85I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.5708555414645846,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "OM",
    "name": "MANTRA DAO (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea",
    "decimals": 18,
    "image": "https://rango.vip/i/j34eqE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.918778,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OM",
    "name": "MANTRA DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3593d125a4f7849a1b059e64f4517a86dd60c95d",
    "decimals": 18,
    "image": "https://rango.vip/i/Qs3bYR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.918778,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "OM",
    "name": "MANTRA DAO",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf78d2e7936f5fe18308a3b2951a93b6c4a41f5e2",
    "decimals": 18,
    "image": "https://rango.vip/i/4r9Fla",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.918778,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "USDD",
    "name": "Decentralized USD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xcf799767d366d789e8b446981c2d578e241fa25c",
    "decimals": 18,
    "image": "https://rango.vip/i/IGbyRm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.9984160314085729,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDD",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6",
    "decimals": 18,
    "image": "https://rango.vip/i/cEAZ41",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.998545,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "stargate",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDD",
    "name": null,
    "isPopular": false,
    "chainId": "56",
    "address": "0xd17479997f34dd9156deef8f95a52d81d265be9c",
    "decimals": 18,
    "image": "https://rango.vip/i/KAA5Gm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.998545,
    "supportedSwappers": [
      "OneInchBsc",
      "stargate"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USDD",
    "name": "USDD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x680447595e8b7b3aa1b43beb9f6098c79ac2ab3f",
    "decimals": 18,
    "image": "https://rango.vip/i/VKqxdP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.998545,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "USDD",
    "name": "USDD",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TPYmHEhy5n8TCEfYGqW2rPxsghSfzghPDn",
    "decimals": 18,
    "image": "https://rango.vip/i/ACtDMR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.998545,
    "supportedSwappers": [
      "Bridgers",
      "SunSwap"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "USDD",
    "name": "Decentralized USD",
    "isPopular": false,
    "chainId": "10",
    "address": "0x7113370218f31764c1b6353bdf6004d86ff6b9cc",
    "decimals": 18,
    "image": "https://rango.vip/i/POAbKK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.9984160314085729,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "QNT",
    "name": "Quant",
    "isPopular": false,
    "chainId": "25",
    "address": "0x7d54f4e05f273a9317f723997612ed64ef53c900",
    "decimals": 18,
    "image": "https://rango.vip/i/q3wHa7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 60.23432759023315,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QNT",
    "name": "Quant",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4a220e6096b25eadb88358cb44068a3248254675",
    "decimals": 18,
    "image": "https://rango.vip/i/HX0jg8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 59.65,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "QNT",
    "name": "Quant",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc7557c73e0eca2e1bf7348bb6874aee63c7eff85",
    "decimals": 18,
    "image": "https://rango.vip/i/rFCRUO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 59.71960875763902,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "EOS",
    "name": "EOS",
    "isPopular": false,
    "chainId": "25",
    "address": "0xa37caa841072a305a0799718afa16cd504c52118",
    "decimals": 4,
    "image": "https://rango.vip/i/TUj0eN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.45838660553994787,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "EOS",
    "name": "EOS Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x56b6fb708fc5732dec1afc8d8556423a2edccbd6",
    "decimals": 18,
    "image": "https://rango.vip/i/Olw08f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.460413,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "EOS",
    "name": "EOS",
    "isPopular": false,
    "chainId": "128",
    "address": "0xae3a94a6dc7fce46b40d63bbf355a3ab2aa2a588",
    "decimals": 4,
    "image": "https://rango.vip/i/XfBTRS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 2.03097679285511,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GT",
    "name": "Gate",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe66747a101bff2dba3697199dcce5b743b454759",
    "decimals": 18,
    "image": "https://rango.vip/i/TOEfDi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.29,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "EGLD",
    "name": "Elrond",
    "isPopular": false,
    "chainId": "25",
    "address": "0x5c54cc9a7abed2dd102361c142417756fb157292",
    "decimals": 18,
    "image": "https://rango.vip/i/yYT8jv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 24.725783381813983,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "EGLD",
    "name": "Elrond",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe",
    "decimals": 18,
    "image": "https://rango.vip/i/vuOHBm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 24.64,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "AXS",
    "name": "Axie Infinity Shards",
    "isPopular": false,
    "chainId": "25",
    "address": "0xe27753e456aba584f10acef0b4e367ef38f01e14",
    "decimals": 18,
    "image": "https://rango.vip/i/K6JuLK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 4.344147592275374,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AXS",
    "name": "Axie Infinity",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
    "decimals": 18,
    "image": "https://rango.vip/i/WdtwSw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.39,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AXS",
    "name": "Axie Infinity Shard",
    "isPopular": false,
    "chainId": "56",
    "address": "0x715d400f88c167884bbcc41c5fea407ed4d2f8a0",
    "decimals": 18,
    "image": "https://rango.vip/i/GlmTEp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4.39,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AXS",
    "name": "Axie Infinity",
    "isPopular": false,
    "chainId": "137",
    "address": "0x61bdd9c7d4df4bf47a4508c0c8245505f2af5b7b",
    "decimals": 18,
    "image": "https://rango.vip/i/XLDSwI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 4.400275009726513,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "NEO",
    "name": "Neo",
    "isPopular": false,
    "chainId": "25",
    "address": "0xb3fc0777738168ce33b228f1831eebd5a81aadb3",
    "decimals": 0,
    "image": "https://rango.vip/i/O2zrZm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 9.580369883089558,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NEO",
    "name": "NEO3D TOKEN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "NEo3D6MXRXf2iAfaqvZYqSmFkfutLvNjm86xmfGWNh5",
    "decimals": 9,
    "image": "https://rango.vip/i/urBVaF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 9.258093555652042,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "XTZ",
    "name": "Tezos",
    "isPopular": false,
    "chainId": "25",
    "address": "0x9d5a7d02d51dc523197e62c2865907dbb53642af",
    "decimals": 6,
    "image": "https://rango.vip/i/9UslJr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.6179296213993236,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XTZ",
    "name": "Tezos Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x16939ef78684453bfdfb47825f8a5f714f12623a",
    "decimals": 18,
    "image": "https://rango.vip/i/Ge2lKw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.6163670514398046,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "GALA",
    "name": "GALA",
    "isPopular": false,
    "chainId": "25",
    "address": "0x7a887d4f8a3221e1aafa2f4435b774d51429a3e1",
    "decimals": 8,
    "image": "https://rango.vip/i/ITXsd0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0173294971915593,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GALA",
    "name": "Gala",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1d2eb1b1e90b638588728b4130137d262c87cae",
    "decimals": 8,
    "image": "https://rango.vip/i/FNRaPq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01736709,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GALA",
    "name": "GALA",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2a676eead159c4c8e8593471c6d666f02827ff8c",
    "decimals": 8,
    "image": "https://rango.vip/i/lC2lAI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.017414817071241484,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "WLD",
    "name": "Worldcoin",
    "isPopular": false,
    "chainId": "10",
    "address": "0xdc6ff44d5d932cbd77b52e5612ba0529dc6226f1",
    "decimals": 18,
    "image": "https://rango.vip/i/R7JRQX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.38,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WLD",
    "name": "Worldcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x163f8c2467924be0ae7b5347228cabf260318753",
    "decimals": 18,
    "image": "https://rango.vip/i/5sbKGN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.38,
    "supportedSwappers": [
      "Bridgers",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SAND",
    "name": "The Sandbox",
    "isPopular": false,
    "chainId": "25",
    "address": "0x9097ea65b55dfc7383a7efb465e8ffc18d46e784",
    "decimals": 18,
    "image": "https://rango.vip/i/yFBBsn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.24533076318757882,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAND",
    "name": "The Sandbox",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3845badade8e6dff049820680d1f14bd3903a5d0",
    "decimals": 18,
    "image": "https://rango.vip/i/bJ0q8O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.240958,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SAND",
    "name": "The Sandbox",
    "isPopular": false,
    "chainId": "56",
    "address": "0x67b725d7e342d7b611fa85e859df9697d9378b2e",
    "decimals": 18,
    "image": "https://rango.vip/i/YIZOEQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.24185851711230308,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SAND",
    "name": "The Sandbox (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "49c7WuCZkQgc3M4qH8WuEUNXfgwupZf1xqWkDQ7gjRGt",
    "decimals": 8,
    "image": "https://rango.vip/i/O8xbOi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.245847,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SAND",
    "name": "SAND",
    "isPopular": false,
    "chainId": "137",
    "address": "0xbbba073c31bf03b8acf7c28ef0738decf3695683",
    "decimals": 18,
    "image": "https://rango.vip/i/OdlNdR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.240958,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ENS",
    "name": "Ethereum Name Service",
    "isPopular": false,
    "chainId": "25",
    "address": "0xa0913e0d7a85954e89452e7ccb8d1235db74c330",
    "decimals": 18,
    "image": "https://rango.vip/i/Jx3GV3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 16.625381537398997,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ENS",
    "name": "Ethereum Name Service",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc18360217d8f7ab5e7c516566761ea12ce7f9d72",
    "decimals": 18,
    "image": "https://rango.vip/i/RZIF3i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 16.47,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ENS",
    "name": "Ethereum Name Service",
    "isPopular": false,
    "chainId": "137",
    "address": "0xbd7a5cf51d22930b8b3df6d834f9bcef90ee7c4f",
    "decimals": 18,
    "image": "https://rango.vip/i/qQgiZM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 16.549245750703815,
    "supportedSwappers": [
      "PolygonUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ENS",
    "name": "Ethereum Name Service",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xfea31d704deb0975da8e77bf13e04239e70d7c28",
    "decimals": 18,
    "image": "https://rango.vip/i/qQgiZM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 16.549245750703815,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ENS",
    "name": "Ethereum Name Service",
    "isPopular": false,
    "chainId": "10",
    "address": "0x65559aa14915a70190438ef90104769e5e890a00",
    "decimals": 18,
    "image": "https://rango.vip/i/Gm5AMR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 16.549245750703815,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "DYDX",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/831f0b1bbb1d08a2b75311892876d71565478c532967545476df4c2d7492e48c",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/DYDX.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.858658436539868,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "DYDX",
    "name": "dYdX",
    "isPopular": false,
    "chainId": "25",
    "address": "0x4442c740cc5b47f032983106e66e3c9dc945676c",
    "decimals": 18,
    "image": "https://rango.vip/i/Rq7ZME",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.8695902805634398,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DYDX",
    "name": "dYdX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x92d6c1e31e14520e676a687f0a93788b716beff5",
    "decimals": 18,
    "image": "https://rango.vip/i/aFxQ1w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.85592,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DYDX",
    "name": "dYdX (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4Hx6Bj56eGyw8EJrrheM6LBQAvVYRikYCWsALeTrwyRU",
    "decimals": 8,
    "image": "https://rango.vip/i/qZ2sRx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.851395,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DYDX",
    "name": "dYdX",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x51863cb90ce5d6da9663106f292fa27c8cc90c5a",
    "decimals": 18,
    "image": "https://rango.vip/i/eqkv1o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.858658436539868,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "DYDX",
    "symbol": "DYDX",
    "name": null,
    "isPopular": true,
    "chainId": "dydx-mainnet-1",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/qa6PZN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/DYDX/icon.svg",
    "usdPrice": 0.85766557980895,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NEXO",
    "name": "Nexo",
    "isPopular": false,
    "chainId": "137",
    "address": "0x41b3966b4ff7b427969ddf5da3627d6aeae9a48e",
    "decimals": 18,
    "image": "https://rango.vip/i/nj8hLp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.957909,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEXO",
    "name": "Nexo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206",
    "decimals": 18,
    "image": "https://rango.vip/i/81ZRBL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.957909,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "W",
    "name": "Wormhole",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb0ffa8000886e57f86dd5264b9582b2ad87b2b91",
    "decimals": 18,
    "image": "https://rango.vip/i/GvTrtE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.197617,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "W",
    "name": "Wormhole",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xb0ffa8000886e57f86dd5264b9582b2ad87b2b91",
    "decimals": 18,
    "image": "https://rango.vip/i/GvTrtE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.197617,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "W",
    "name": "Wormhole Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ",
    "decimals": 6,
    "image": "https://rango.vip/i/ZVwO3b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.197617,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "W",
    "name": "Wormhole",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb0ffa8000886e57f86dd5264b9582b2ad87b2b91",
    "decimals": 18,
    "image": "https://rango.vip/i/GvTrtE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.197617,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RON",
    "name": "President Ron DeSantis",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3267c5b73cc15e253b1a90c01366b17d560bc6fb",
    "decimals": 9,
    "image": "https://rango.vip/i/OVy7mr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001728,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POPCAT",
    "name": "Popcat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr",
    "decimals": 9,
    "image": "https://rango.vip/i/O8vsRM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.514859,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "TUSD",
    "name": "TrueUSD",
    "isPopular": false,
    "chainId": "25",
    "address": "0x87efb3ec1576dec8ed47e58b832bedcd86ee186e",
    "decimals": 18,
    "image": "https://rango.vip/i/7Jf8S8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.995861,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TUSD",
    "name": "TrueUSD",
    "isPopular": false,
    "chainId": "56",
    "address": "0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9",
    "decimals": 18,
    "image": "https://rango.vip/i/oEi3Lu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.998901,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TUSD",
    "name": "Bridged TrueUSD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4d15a3a2286d883af0aa1b3f21367843fac63e07",
    "decimals": 18,
    "image": "https://rango.vip/i/1BeV7i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.995861,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TUSD",
    "name": "TrueUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0000000000085d4780b73119b644ae5ecd22b376",
    "decimals": 18,
    "image": "https://rango.vip/i/3YrTv9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.998901,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "Bridgers",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TUSD",
    "name": "TrueUSD (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2e1ad108ff1d8c782fcbbb89aad783ac49586756",
    "decimals": 18,
    "image": "https://rango.vip/i/TVLFYj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.995861,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "TUSD",
    "name": "TUSD",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4",
    "decimals": 18,
    "image": "https://rango.vip/i/IK2G9H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.998901,
    "supportedSwappers": [
      "Bridgers",
      "SunSwap"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "TUSD",
    "name": "TrueUSD",
    "isPopular": false,
    "chainId": "10",
    "address": "0xcb59a0a753fdb7491d5f3d794316f1ade197b21e",
    "decimals": 18,
    "image": "https://rango.vip/i/PSjWmQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.995861,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "TUSD",
    "name": "TUSD",
    "isPopular": false,
    "chainId": "128",
    "address": "0x5ee41ab6edd38cdfb9f6b4e6cf7f75c87e170d98",
    "decimals": 18,
    "image": "https://rango.vip/i/bQtivH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1.001721523216345,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "MANA",
    "name": "Decentraland",
    "isPopular": false,
    "chainId": "25",
    "address": "0x6ed8c99e5c6b2c551e012e4272d8f3d1df23a71a",
    "decimals": 18,
    "image": "https://rango.vip/i/eGYqqM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.25660173333334957,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MANA",
    "name": "Decentraland",
    "isPopular": false,
    "chainId": "56",
    "address": "0x26433c8127d9b4e9b71eaa15111df99ea2eeb2f8",
    "decimals": 18,
    "image": "https://rango.vip/i/Bjc2Wt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.2565598260390108,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MANA",
    "name": "Decentraland (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7dgHoN8wBZCc5wbnQ2C47TDnBMAxG4Q5L3KjP67z8kNi",
    "decimals": 8,
    "image": "https://rango.vip/i/1RGEp2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.256991,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MANA",
    "name": "Decentraland",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x442d24578a564ef628a65e6a7e3e7be2a165e231",
    "decimals": 18,
    "image": "https://rango.vip/i/hnhyVi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.2565598260390108,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "PAXG",
    "name": "PAX Gold",
    "isPopular": false,
    "chainId": "25",
    "address": "0x81749e7258f9e577f61f49abeeb426b70f561b89",
    "decimals": 18,
    "image": "https://rango.vip/i/uk9RUq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 2495.7757536235526,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAXG",
    "name": "PAX Gold",
    "isPopular": false,
    "chainId": "1",
    "address": "0x45804880de22913dafe09f4980848ece6ecbaf78",
    "decimals": 18,
    "image": "https://rango.vip/i/2sgjWB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2522.22,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PAXG",
    "name": "PAX Gold",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xfeb4dfc8c4cf7ed305bb08065d08ec6ee6728429",
    "decimals": 18,
    "image": "https://rango.vip/i/M6oGRR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2520.920420928551,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ZEC",
    "name": "Zcash Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb",
    "decimals": 18,
    "image": "https://rango.vip/i/dPExd2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 27.695108728418912,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CHZ",
    "name": "Chiliz",
    "isPopular": false,
    "chainId": "25",
    "address": "0x4e4f362170bfb88d3c9378ff7818d93fc2fbd257",
    "decimals": 18,
    "image": "https://rango.vip/i/4P3HSb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.049681238868095674,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHZ",
    "name": "Chiliz",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3506424f91fd33084466f402d5d97f05f8e3b4af",
    "decimals": 18,
    "image": "https://rango.vip/i/DRqZrz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04952467,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFT",
    "name": "NFT Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb8d1260f9c92a3a545d409466280ffdd7af7042",
    "decimals": 18,
    "image": "https://rango.vip/i/Gt1EO4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00513364,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFT",
    "name": "ApePudgyCloneXAzukiMiladyMferInu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8edc62648236c20e528adb675ad3f3047697186f",
    "decimals": 18,
    "image": "https://rango.vip/i/7DGxD7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00022069,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFT",
    "name": "APENFT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x198d14f2ad9ce69e76ea330b374de4957c3f850a",
    "decimals": 6,
    "image": "https://rango.vip/i/fYQ3OW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.42413e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NFT",
    "name": "APENFT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x20ee7b720f4e4c4ffcb00c4065cdae55271aecca",
    "decimals": 6,
    "image": "https://rango.vip/i/z8FbsS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4.42413e-7,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "NFT",
    "name": "APENFT",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TFczxzPhnThNSqr5by8tvxsdCFRRz6cPNq",
    "decimals": 6,
    "image": "https://rango.vip/i/gicRXu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 4.42413e-7,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "CAKE",
    "name": "PancakeSwap Token",
    "isPopular": false,
    "chainId": "324",
    "address": "0x3a287a06c66f9e95a56327185ca2bdf5f031cecd",
    "decimals": 18,
    "image": "https://rango.vip/i/PRL1yY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 1.63,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CAKE",
    "name": "PancakeSwap Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    "decimals": 18,
    "image": "https://rango.vip/i/wdRgfZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.63,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGONZK",
    "symbol": "CAKE",
    "name": "PancakeSwap",
    "isPopular": false,
    "chainId": "1101",
    "address": "0x0d1e753a25ebda689453309112904807625befbe",
    "decimals": 18,
    "image": "https://rango.vip/i/ZQsFXi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGONZK/icon.svg",
    "usdPrice": 1.63,
    "supportedSwappers": [
      "PolygonPancakeV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CAKE",
    "name": "PancakeSwap Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x3055913c90fcc1a6ce9a358911721eeb942013a1",
    "decimals": 18,
    "image": "https://rango.vip/i/Pz8cwl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.63,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAKE",
    "name": "PancakeSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x152649ea73beab28c5b49b26eb48f7ead6d4c898",
    "decimals": 18,
    "image": "https://rango.vip/i/ZQsFXi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.63,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "LUNC",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/0ef15df2f02480ade0bb6e85d9ebb5daea2836d3860e9f97f9aade4f57a31aa0",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/LUNC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0000759605251874304,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "PENDLE",
    "name": "Pendle",
    "isPopular": false,
    "chainId": "25",
    "address": "0x49c3bbb239f4fb44327073510f4ba72d207a81d6",
    "decimals": 18,
    "image": "https://rango.vip/i/AfMq2H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 2.6791577895490772,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PENDLE",
    "name": "Pendle",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb3ed0a426155b79b898849803e3b36552f7ed507",
    "decimals": 18,
    "image": "https://rango.vip/i/v8lXL2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.67,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PENDLE",
    "name": "Pendle",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x0c880f6761f1af8d9aa9c466984b80dab9a8c9e8",
    "decimals": 18,
    "image": "https://rango.vip/i/wijOIt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2.67,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PENDLE",
    "name": "Pendle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x808507121b80c02388fad14726482e061b8da827",
    "decimals": 18,
    "image": "https://rango.vip/i/nOZ88q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.67,
    "supportedSwappers": [
      "Bridgers",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "PENDLE",
    "name": "Pendle",
    "isPopular": false,
    "chainId": "10",
    "address": "0xbc7b1ff1c6989f006a1185318ed4e7b5796e66e1",
    "decimals": 18,
    "image": "https://rango.vip/i/PtS8NM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2.67,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DEXE",
    "name": "DeXe",
    "isPopular": false,
    "chainId": "56",
    "address": "0x039cb485212f996a9dbb85a9a75d898f94d38da6",
    "decimals": 18,
    "image": "https://rango.vip/i/qO5cEq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 7.286475040874302,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DEXE",
    "name": "Dexe",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6e88056e8376ae7709496ba64d37fa2f8015ce3e",
    "decimals": 18,
    "image": "https://rango.vip/i/lqRSxm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 7.27,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEXE",
    "name": "Dexe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde4ee8057785a7e8e800db58f9784845a5c2cbd6",
    "decimals": 18,
    "image": "https://rango.vip/i/JYnBpI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.27,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNX",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
    "decimals": 18,
    "image": "https://rango.vip/i/TbGZrr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.26,
    "supportedSwappers": [
      "ThorChain",
      "EthereumUniswapV3",
      "ThorChainStreamingSwap",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SNX",
    "name": "Synthetix Network Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x9aa8f72dd2c06eaff0113bd0380255e1fbdaeac4",
    "decimals": 18,
    "image": "https://rango.vip/i/2JoHGA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 1.2719558147598735,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SNX",
    "name": "Synthetix Network Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x8700daec35af8ff88c16bdf0418774cb3d7599b4",
    "decimals": 18,
    "image": "https://rango.vip/i/SwmMit",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.26,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SNX",
    "name": "Synthetix Network Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x50b728d8d964fd00c2d0aad81718b71311fef68a",
    "decimals": 18,
    "image": "https://rango.vip/i/gRxHNx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.26,
    "supportedSwappers": [
      "PolygonUniSwapV3",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SNX",
    "name": "Synthetix",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9ac983826058b8a9c7aa1c9171441191232e8404",
    "decimals": 18,
    "image": "https://rango.vip/i/kUTUUz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.2666421527739042,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SNX",
    "name": "Synthetix Network Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xcba56cd8216fcbbf3fa6df6137f3147cbca37d60",
    "decimals": 18,
    "image": "https://rango.vip/i/TbGZrr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.2666421527739042,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SNX",
    "name": "SNX",
    "isPopular": false,
    "chainId": "128",
    "address": "0x777850281719d5a96c29812ab72f822e0e09f3da",
    "decimals": 18,
    "image": "https://rango.vip/i/pxVili",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1.26,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "AIOZ",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/bb0afe2afbd6e883690dae4b9168eac2b306bcc9c9292dacbb4152bbb08db25f",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIOZ",
    "name": "AIOZ Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x626e8036deb333b408be468f951bdb42433cbf18",
    "decimals": 18,
    "image": "https://rango.vip/i/NRtuZu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.367133,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AIOZ",
    "name": "AIOZ Network",
    "isPopular": false,
    "chainId": "56",
    "address": "0x33d08d8c7a168333a85285a68c0042b39fc3741d",
    "decimals": 18,
    "image": "https://rango.vip/i/XRw4bs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.367133,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AIOZ",
    "name": "AIOZ Network",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xec76e8fe6e2242e6c2117caa244b9e2de1569923",
    "decimals": 18,
    "image": "https://rango.vip/i/WcsBEb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.3685620869559871,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "IOTA",
    "symbol": "IOTA",
    "name": "IOTA",
    "isPopular": true,
    "chainId": "8822",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/Wejdob",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/IOTA/icon.svg",
    "usdPrice": 0.11987506787934138,
    "supportedSwappers": [
      "IOTAMagicSeaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ENA",
    "name": "Ethena",
    "isPopular": false,
    "chainId": "1",
    "address": "0x57e114b691db790c35207b2e685d4a43181e6061",
    "decimals": 18,
    "image": "https://rango.vip/i/BotOeA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.211641,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ENA",
    "name": "Ethena",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xdf8f0c63d9335a0abd89f9f752d293a98ea977d8",
    "decimals": 18,
    "image": "https://rango.vip/i/l1tTgv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.21250983595408773,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOME",
    "name": "BOOK OF MEME",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82",
    "decimals": 6,
    "image": "https://rango.vip/i/g0qnWV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00576326,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LPT",
    "name": "Livepeer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x58b6a8a3302369daec383334672404ee733ab239",
    "decimals": 18,
    "image": "https://rango.vip/i/tXOAqw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 11.2,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LPT",
    "name": "Livepeer",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x289ba1701c2f088cf0faf8b3705246331cb8a839",
    "decimals": 18,
    "image": "https://rango.vip/i/cyoA0w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 11.2,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RAY",
    "name": "Raydium",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
    "decimals": 6,
    "image": "https://rango.vip/i/fKDsWJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.43,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAY",
    "name": "Raydium",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5245c0249e5eeb2a0838266800471fd32adb1089",
    "decimals": 6,
    "image": "https://rango.vip/i/3N1W5l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.4362942872710514,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GNO",
    "name": "Gnosis Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6810e776880c02933d47db1b9fc05908e5386b96",
    "decimals": 18,
    "image": "https://rango.vip/i/llbtgl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 146.24,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GNO",
    "name": "Gnosis Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa0b862f60edef4452f25b4160f177db44deb6cf1",
    "decimals": 18,
    "image": "https://rango.vip/i/hXZNSB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 146.24,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SFP",
    "name": "SafePal Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb",
    "decimals": 18,
    "image": "https://rango.vip/i/L3cMl1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.766637,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SFP",
    "name": "SafePal",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12e2b8033420270db2f3b328e32370cb5b2ca134",
    "decimals": 18,
    "image": "https://rango.vip/i/gYbd25",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.766637,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ZK",
    "name": "ZKsync",
    "isPopular": false,
    "chainId": "324",
    "address": "0x5a7d6b2f92c77fad6ccabd7ee0624e64907eaf3e",
    "decimals": 18,
    "image": "https://rango.vip/i/cgmr0Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.099473,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "COMP",
    "name": "Compound",
    "isPopular": false,
    "chainId": "25",
    "address": "0x4fb1af9d09db3fbbda96071eae0aeae6e871f9ac",
    "decimals": 18,
    "image": "https://rango.vip/i/kOoq78",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 41.654424826588226,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COMP",
    "name": "Compound",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc00e94cb662c3520282e6f5717214004a7f26888",
    "decimals": 18,
    "image": "https://rango.vip/i/XSXrZc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 41.65,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "COMP",
    "name": "Compound",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c",
    "decimals": 18,
    "image": "https://rango.vip/i/XSXrZc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 41.65,
    "supportedSwappers": [
      "PolygonUniSwapV3",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "COMP",
    "name": "Compound Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x52ce071bd9b1c4b00a0b92d298c512478cad67e8",
    "decimals": 18,
    "image": "https://rango.vip/i/7eJrCx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 41.65,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "COMP",
    "name": "Compound",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x354a6da3fcde098f8389cad84b0182725c6c91de",
    "decimals": 18,
    "image": "https://rango.vip/i/oKdu8z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 41.65,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "COMP",
    "name": "COMP",
    "isPopular": false,
    "chainId": "128",
    "address": "0xce0a5ca134fb59402b723412994b30e02f083842",
    "decimals": 18,
    "image": "https://rango.vip/i/dvgYux",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 165.60147881295958,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MX",
    "name": "MX Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9f882567a62a5560d147d64871776eea72df41d3",
    "decimals": 18,
    "image": "https://rango.vip/i/HRQZ9G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.662585966415511,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MX",
    "name": "MX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x11eef04c884e24d9b7b4760e7476d06ddf797f36",
    "decimals": 18,
    "image": "https://rango.vip/i/5xEjX7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.67,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "MX",
    "name": "MX",
    "isPopular": false,
    "chainId": "128",
    "address": "0x8d854e603dc777337134286f5b3408261736a88f",
    "decimals": 18,
    "image": "https://rango.vip/i/CGlyXS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 12.178928594169156,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ZRO",
    "name": "LayerZero",
    "isPopular": false,
    "chainId": "10",
    "address": "0x6985884c4392d348587b19cb9eaaf157f13271cd",
    "decimals": 18,
    "image": "https://rango.vip/i/puNjGX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 3.21,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ZRO",
    "name": "LayerZero",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x6985884c4392d348587b19cb9eaaf157f13271cd",
    "decimals": 18,
    "image": "https://rango.vip/i/puNjGX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 3.21,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZRO",
    "name": "LayerZero",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6985884c4392d348587b19cb9eaaf157f13271cd",
    "decimals": 18,
    "image": "https://rango.vip/i/ZpovYr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.21,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ZRO",
    "name": "LayerZero",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6985884c4392d348587b19cb9eaaf157f13271cd",
    "decimals": 18,
    "image": "https://rango.vip/i/ZpovYr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 3.21,
    "supportedSwappers": [
      "PolygonUniSwapV3",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ZRO",
    "name": "LayerZero",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6985884c4392d348587b19cb9eaaf157f13271cd",
    "decimals": 18,
    "image": "https://rango.vip/i/C6wdiE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.21,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ZRO",
    "name": "LayerZero",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6985884c4392d348587b19cb9eaaf157f13271cd",
    "decimals": 18,
    "image": "https://rango.vip/i/puNjGX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 3.21,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ZRO",
    "name": "LayerZero",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6985884c4392d348587b19cb9eaaf157f13271cd",
    "decimals": 18,
    "image": "https://rango.vip/i/puNjGX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 3.21,
    "supportedSwappers": [
      "OneInchBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MEW",
    "name": "cat in a dogs world",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "MEW1gQWJ3nEXg2qgERiKu7FAFj79PHvQVREQUzScPP5",
    "decimals": 5,
    "image": "https://rango.vip/i/fVNIEE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00394488,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CKB",
    "name": "Nervos Network",
    "isPopular": false,
    "chainId": "25",
    "address": "0x5deed46f39c485ba03b61d83763d0f6357dc4737",
    "decimals": 8,
    "image": "https://rango.vip/i/K1XNYI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.007746343471539076,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TWT",
    "name": "Trust Wallet",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4b0f1812e5df2a09796481ff14017e6005508003",
    "decimals": 18,
    "image": "https://rango.vip/i/8roGJh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.814926,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TWT",
    "name": "Trust Wallet (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD",
    "decimals": 8,
    "image": "https://rango.vip/i/rgCzx6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.814837423340162,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CRV",
    "name": "Curve DAO Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xfef44a0c77eca218f443382d3128a7a251a8c542",
    "decimals": 18,
    "image": "https://rango.vip/i/O7s4aW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.275786389598059,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRV",
    "name": "Curve DAO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd533a949740bb3306d119cc777fa900ba034cd52",
    "decimals": 18,
    "image": "https://rango.vip/i/X8istt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.276039,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "CRV",
    "name": "Curve DAO Token",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
    "decimals": 18,
    "image": "https://rango.vip/i/ObNAKt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.276940044650513,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "CRV",
    "name": "Curve DAO",
    "isPopular": false,
    "chainId": "10",
    "address": "0x0994206dfe8de6ec6920ff4d779b0d950605fb53",
    "decimals": 18,
    "image": "https://rango.vip/i/9hKsZw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.276039,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CRV",
    "name": "Curve DAO Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x172370d5cd63279efa6d502dab29171933a610af",
    "decimals": 18,
    "image": "https://rango.vip/i/I0UzSw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.276039,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "ParaSwap Polygon",
      "PolygonUniSwapV3",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CRV",
    "name": "Curve DAO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x11cdb42b0eb46d95f990bedd4695a6e3fa034978",
    "decimals": 18,
    "image": "https://rango.vip/i/rIwXQn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.276039,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3",
      "SushiArbitrum",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CRV",
    "name": "CRV",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8ee73c484a26e0a5df2ee2a4960b789967dd0415",
    "decimals": 18,
    "image": "https://rango.vip/i/GHPNkQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.276940044650513,
    "supportedSwappers": [
      "BaseCurveFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "1INCH",
    "name": "1INCH Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xeea900fe18f77593c7d7c105fba9bd714164ac95",
    "decimals": 18,
    "image": "https://rango.vip/i/IiHIoo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.2486217344111369,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "1INCH",
    "name": "1inch",
    "isPopular": false,
    "chainId": "1",
    "address": "0x111111111117dc0aa78b770fa6a738034120c302",
    "decimals": 18,
    "image": "https://rango.vip/i/3sZmlK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.247195,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "1INCH",
    "name": "1inch",
    "isPopular": false,
    "chainId": "56",
    "address": "0x111111111117dc0aa78b770fa6a738034120c302",
    "decimals": 18,
    "image": "https://rango.vip/i/fswc6x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.247195,
    "supportedSwappers": [
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "1INCH",
    "name": "1inch",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xc5fecc3a29fb57b5024eec8a2239d4621e111cbe",
    "decimals": 18,
    "image": "https://rango.vip/i/L2fMUB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.24774160368682185,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "1INCH",
    "name": "1INCH Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xad42d013ac31486b73b6b059e748172994736426",
    "decimals": 18,
    "image": "https://rango.vip/i/zK88Cc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.24774160368682185,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "KAVA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/57aa1a70a4bc9769c525ebf6386f7a21536e04a79d62e1981efcef9428ebb205",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/KAVA.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.2857952100613376,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KAVA",
    "name": "KAVA",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5f88ab06e8dfe89df127b2430bba4af600866035",
    "decimals": 6,
    "image": "https://rango.vip/i/qJ5kt4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.2864521380870089,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IOTX",
    "name": "IoTeX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6fb3e0a217407efff7ca062d46c26e5d60a14d69",
    "decimals": 18,
    "image": "https://rango.vip/i/4jE11K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03244567,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "IOTX",
    "name": "IoTeX Network",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9678e42cebeb63f23197d726b29b1cb20d0064e5",
    "decimals": 18,
    "image": "https://rango.vip/i/DPrJZB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03258445,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "IOTX",
    "name": "IoTeX",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xbcbaf311cec8a4eac0430193a528d9ff27ae38c1",
    "decimals": 18,
    "image": "https://rango.vip/i/uvJkqC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.03244567,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMP",
    "name": "Amp",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff20817765cb7f73d4bde2e66e067e58d11095c2",
    "decimals": 18,
    "image": "https://rango.vip/i/Qvp2Pj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00367439,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLUR",
    "name": "Blur",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5283d291dbcf85356a21ba090e6db59121208b44",
    "decimals": 18,
    "image": "https://rango.vip/i/YoBpG9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.155182,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BLUR",
    "name": "Blur",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xef171a5ba71348eff16616fd692855c2fe606eb2",
    "decimals": 18,
    "image": "https://rango.vip/i/BxR7y1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.15579085268566248,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "DASH",
    "symbol": "DASH",
    "name": null,
    "isPopular": true,
    "chainId": "",
    "address": null,
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/DASH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/DASH/icon.svg",
    "usdPrice": 23.890300763328163,
    "supportedSwappers": [
      "MayaProtocol"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "JST",
    "name": "JST",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9",
    "decimals": 18,
    "image": "https://rango.vip/i/zExccy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.02816865,
    "supportedSwappers": [
      "Bridgers",
      "SunSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GMT",
    "name": "Green Metaverse Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
    "decimals": 8,
    "image": "https://rango.vip/i/FD9z1u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.115896,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GMT",
    "name": "GMT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx",
    "decimals": 9,
    "image": "https://rango.vip/i/emyq2z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.115896,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GMT",
    "name": "GoMining Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ddc52c4de30e94be3a6a0a2b259b2850f421989",
    "decimals": 18,
    "image": "https://rango.vip/i/5Gj7dN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.339577,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GMT",
    "name": "GreenMetaverseToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe3c408bd53c31c085a1746af401a4042954ff740",
    "decimals": 8,
    "image": "https://rango.vip/i/jSp4l1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.115896,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GMT",
    "name": "GoMining Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7ddc52c4de30e94be3a6a0a2b259b2850f421989",
    "decimals": 18,
    "image": "https://rango.vip/i/WnvdMP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.339577,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOG",
    "name": "Mog Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaaee1a9723aadb7afa2810263653a34ba2c21c7a",
    "decimals": 18,
    "image": "https://rango.vip/i/2VUsXW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.56208e-7,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MOG",
    "name": "Mog Coin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x96c42662820f6ea32f0a61a06a38a72b206aabac",
    "decimals": 18,
    "image": "https://rango.vip/i/VhWunN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 7.624750907094163e-7,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AEVO",
    "name": "Aevo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb528edbef013aff855ac3c50b381f253af13b997",
    "decimals": 18,
    "image": "https://rango.vip/i/fglWiW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.308431,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AEVO",
    "name": "Aevo",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x377c1fc73d4d0f5600cd943776ced07c2b9783cd",
    "decimals": 18,
    "image": "https://rango.vip/i/HmniPn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.3099514695063827,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GLM",
    "name": "Golem",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429",
    "decimals": 18,
    "image": "https://rango.vip/i/hIF5UP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.265191,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "KSM",
    "name": "Kusama",
    "isPopular": false,
    "chainId": "25",
    "address": "0x0bd48a8a9565385649d9d7f1c945d1d0c4543e26",
    "decimals": 12,
    "image": "https://rango.vip/i/7OapC5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 17.169619290925354,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "SUN",
    "name": "SUN",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TSSMHYeV2uE9qYH95DqyoCuNCzEL1NvU3S",
    "decimals": 18,
    "image": "https://rango.vip/i/OiuI6v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.02637896,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANT",
    "name": "Aragon Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa117000000f279d81a1d3cc75430faa017fa5a2e",
    "decimals": 18,
    "image": "https://rango.vip/i/8m3wFM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.02,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANT",
    "name": "Aragon Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x960b236a07cf122663c4303350609a66a7b288c0",
    "decimals": 18,
    "image": "https://rango.vip/i/OYWrhX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.028924506444579,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "WOO",
    "name": "WOO Network",
    "isPopular": false,
    "chainId": "25",
    "address": "0x3ff6184f97104323b5fd0d186f8b8be91a91ce27",
    "decimals": 18,
    "image": "https://rango.vip/i/Uxnw4N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.14082790384147625,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOO",
    "name": "WOO Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4691937a7508860f876c9c0a2a617e7d9e945d4b",
    "decimals": 18,
    "image": "https://rango.vip/i/gYYddQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.138619,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WOO",
    "name": "Wootrade",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4691937a7508860f876c9c0a2a617e7d9e945d4b",
    "decimals": 18,
    "image": "https://rango.vip/i/cx5iQQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.138619,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WOO",
    "name": "Wootrade Network",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xcafcd85d8ca7ad1e1c6f82f651fa15e33aefd07b",
    "decimals": 18,
    "image": "https://rango.vip/i/9R4Te5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.138619,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WOO",
    "name": "Wootrade Network",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603",
    "decimals": 18,
    "image": "https://rango.vip/i/SN2QWu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.138619,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "WOO",
    "name": "Wootrade Network",
    "isPopular": false,
    "chainId": "324",
    "address": "0x9e22d758629761fc5708c171d06c2fabb60b5159",
    "decimals": 18,
    "image": "https://rango.vip/i/ZczzZY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.138619,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "WOO",
    "name": "Wootrade Network",
    "isPopular": false,
    "chainId": "10",
    "address": "0x871f2f2ff935fd1ed867842ff2a7bfd051a5e527",
    "decimals": 18,
    "image": "https://rango.vip/i/70som0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.13897852289888143,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "WOO",
    "name": "WOO",
    "isPopular": false,
    "chainId": "128",
    "address": "0x3befb2308bce92da97264077faf37dcd6c8a75e6",
    "decimals": 18,
    "image": "https://rango.vip/i/eXcdxr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.138619,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JTO",
    "name": "JITO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL",
    "decimals": 9,
    "image": "https://rango.vip/i/Iq6LFt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.98,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RSR",
    "name": "Reserve Rights",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xca5ca9083702c56b481d1eec86f1776fdbd2e594",
    "decimals": 18,
    "image": "https://rango.vip/i/iGjt7e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00482349,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RSR",
    "name": "Reserve Rights",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xab36452dbac151be02b16ca17d8919826072f64a",
    "decimals": 18,
    "image": "https://rango.vip/i/FMqw4P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00482349,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RSR",
    "name": "Reserve Rights",
    "isPopular": false,
    "chainId": "1",
    "address": "0x320623b8e4ff03373931769a31fc52a4e78b5d70",
    "decimals": 18,
    "image": "https://rango.vip/i/H6JMNa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00482349,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "DYM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/9a76cdf0cbcef37923f32518fa15e5dc92b9f56128292bc4d63c4aea76cbb110",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 1.2112851784063998,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ZIL",
    "name": "Ziliqa",
    "isPopular": false,
    "chainId": "25",
    "address": "0xc70ed252e55d68a7020a754fb92fa5c68e3c199f",
    "decimals": 12,
    "image": "https://rango.vip/i/AJOvDy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.01299078638223007,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ZIL",
    "name": "Zilliqa",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb86abcb37c3a4b64f74f59301aff131a1becc787",
    "decimals": 12,
    "image": "https://rango.vip/i/N3sJxS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01294517,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRIME",
    "name": "Prime",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb23d80f5fefcddaa212212f028021b41ded428cf",
    "decimals": 18,
    "image": "https://rango.vip/i/L03Hg6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.61,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PRIME",
    "name": "DeltaPrime",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3de81ce90f5a27c5e6a5adb04b54aba488a6d14e",
    "decimals": 18,
    "image": "https://rango.vip/i/11xqCq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.975883,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PRIME",
    "name": "Echelon Prime",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfa980ced6895ac314e7de34ef1bfae90a5add21b",
    "decimals": 18,
    "image": "https://rango.vip/i/GO3ieL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 6.61,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PRIME",
    "name": "SolanaPrime",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PRiME7gDoiG1vGr95a3CRMv9xHY7UGjd4JKvfSkmQu2",
    "decimals": 9,
    "image": "https://rango.vip/i/4iw8iW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00445868,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PRIME",
    "name": "Prime",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8d8e1b6ffc6832e8d2ef0de8a3d957cae7ac5067",
    "decimals": 18,
    "image": "https://rango.vip/i/CzdmUk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 6.611122749772488,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "PRIME",
    "name": "DeltaPrime",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x33c8036e99082b0c395374832fecf70c42c7f298",
    "decimals": 18,
    "image": "https://rango.vip/i/Eer5TX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.975883,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MANTA",
    "name": "Manta",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8581cc815e40615998f4561f3e24e68066293595",
    "decimals": 18,
    "image": "https://rango.vip/i/T4w12i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.6273347653577921,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "BAT",
    "name": "Basic Attention Token",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x2b9025aecc5ce7a8e6880d3e9c6e458927ecba04",
    "decimals": 18,
    "image": "https://rango.vip/i/7EFWba",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.08582575808173064,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "BAT",
    "name": "Basic Attention Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x2f712e0a6f92e3f865eaeb86f07bafc67974d26c",
    "decimals": 18,
    "image": "https://rango.vip/i/xmB6xE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.15708785247365928,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAT",
    "name": "Basic Attention Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
    "decimals": 18,
    "image": "https://rango.vip/i/lFag75",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.155993,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BAT",
    "name": "Basic Attention Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x101d82428437127bf1608f699cd651e6abf9766e",
    "decimals": 18,
    "image": "https://rango.vip/i/tHan5W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.1564999977769495,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BAT",
    "name": "Basic Attention Token (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EPeUFDgHRxs9xxEPVaL6kfGQvCon7jmAWKVUHuux1Tpz",
    "decimals": 8,
    "image": "https://rango.vip/i/ggsnKw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.155993,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BAT",
    "name": "Basic Attention Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3cef98bb43d732e2f285ee605a8158cde967d219",
    "decimals": 18,
    "image": "https://rango.vip/i/H8CTep",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.155993,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GMX",
    "name": "GMX",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    "decimals": 18,
    "image": "https://rango.vip/i/Q7ATxP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 23.88,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "ArbitrumUniSwapV3",
      "Bridgers",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GMX",
    "name": "GMX",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x62edc0692bd897d2295872a9ffcac5425011c661",
    "decimals": 18,
    "image": "https://rango.vip/i/OF5khA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 23.88,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZRX",
    "name": "0x Protocol Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe41d2489571d322189246dafa5ebde1f4699f498",
    "decimals": 18,
    "image": "https://rango.vip/i/hHwewM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.269198,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ZRX",
    "name": "0x Protocol Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xbd591bd4ddb64b77b5f76eab8f03d02519235ae2",
    "decimals": 18,
    "image": "https://rango.vip/i/hHwewM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.26959170350824624,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ZRX",
    "name": "0x Protocol Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xd1917629b3e6a72e6772aab5dbe58eb7fa3c2f33",
    "decimals": 18,
    "image": "https://rango.vip/i/QffHaf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.26959170350824624,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ENJ",
    "name": "Enjin",
    "isPopular": false,
    "chainId": "25",
    "address": "0x0a92ea8a197919acb9bc26660ed0d43d01ed26b7",
    "decimals": 18,
    "image": "https://rango.vip/i/ZL5zp3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.13419102557897025,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ENJ",
    "name": "Enjin Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c",
    "decimals": 18,
    "image": "https://rango.vip/i/epUNxV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.133404,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "CELO",
    "name": "Celo  Wormhole ",
    "isPopular": false,
    "chainId": "10",
    "address": "0x9b88d293b7a791e40d36a39765ffd5a1b9b5c349",
    "decimals": 18,
    "image": "https://rango.vip/i/yS0ZHc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.409986,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CELO",
    "name": "Celo native asset",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3294395e62f4eb6af3f1fcf89f5602d90fb3ef69",
    "decimals": 18,
    "image": "https://rango.vip/i/KJC8yE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.409986,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CELO",
    "name": "Celo  Wormhole ",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4e51ac49bc5e2d87e0ef713e9e5ab2d71ef4f336",
    "decimals": 18,
    "image": "https://rango.vip/i/yS0ZHc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.409986,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "CELO",
    "name": "CELO",
    "isPopular": true,
    "chainId": "42220",
    "address": "0x471ece3750da237f93b8e339c536989b8978a438",
    "decimals": 18,
    "image": "https://rango.vip/i/jGL4m6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 2351.69,
    "supportedSwappers": [
      "UbeSwap",
      "Satellite",
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CELO",
    "name": "Celo native asset",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e33dba7cd47e79c4385ba39442a693b910a0a8a",
    "decimals": 18,
    "image": "https://rango.vip/i/x6qM16",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.4122477614101457,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "QTUM",
    "name": "Qtum",
    "isPopular": false,
    "chainId": "25",
    "address": "0x32529346958711b3bef92b96507c14821e50c9c8",
    "decimals": 8,
    "image": "https://rango.vip/i/fYOjXx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 2.149812826876058,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "LUNA",
    "name": "Axelar Wrapped LUNA",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x120ad3e5a7c796349e591f1570d9f7980f4ea9cb",
    "decimals": 6,
    "image": "https://rango.vip/i/1XqcJq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.326572297011748,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "LUNA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/785afec6b3741100d15e7af01374e3c4c36f24888e96479b1c33f5c71f364ef9",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/LUNA.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.326129,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LUNA",
    "name": "LUNA (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W",
    "decimals": 6,
    "image": "https://rango.vip/i/vjzHzQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00007416,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUNA",
    "name": "Wrapped LUNA Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2877702675e6ceb975b4a1dff9fb7baf4c91ea9",
    "decimals": 18,
    "image": "https://rango.vip/i/GHOmQN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007172,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUNA",
    "name": "Luna28",
    "isPopular": false,
    "chainId": "1",
    "address": "0x416cdaf616a82d7dd46e0dbf36e7d6fe412bc40e",
    "decimals": 18,
    "image": "https://rango.vip/i/dHwTUs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000013,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUNA",
    "name": "LUNA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd31ea8212119f94a611fa969881cba3ea06fa3d",
    "decimals": 6,
    "image": "https://rango.vip/i/YCt7Hi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007416,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LUNA",
    "name": "Wrapped LUNA Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x24834bbec7e39ef42f4a75eaf8e5b6486d3f0e57",
    "decimals": 18,
    "image": "https://rango.vip/i/0XIBdE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00007172,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "TERRA",
    "symbol": "LUNA",
    "name": null,
    "isPopular": true,
    "chainId": "phoenix-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/iebQV6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TERRA/icon.svg",
    "usdPrice": 0.32713615111973954,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ANKR",
    "name": "Ankr Network",
    "isPopular": false,
    "chainId": "25",
    "address": "0x1fe0f470736548794b47afe5613d3a309d964d3c",
    "decimals": 18,
    "image": "https://rango.vip/i/02OopL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.022477906391965896,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANKR",
    "name": "Ankr",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8290333cef9e6d528dd5618fb97a76f268f3edd4",
    "decimals": 18,
    "image": "https://rango.vip/i/oByxNH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02249404,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ANKR",
    "name": "Ankr Network",
    "isPopular": false,
    "chainId": "10",
    "address": "0xaeaeed23478c3a4b798e4ed40d8b7f41366ae861",
    "decimals": 18,
    "image": "https://rango.vip/i/wAkRV4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.02249404,
    "supportedSwappers": [
      "OpenOceanOptimism"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ANKR",
    "name": "Ankr",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf307910a4c7bbc79691fd374889b36d8531b08e3",
    "decimals": 18,
    "image": "https://rango.vip/i/Pa5VIJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02249404,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ANKR",
    "name": "Ankr (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x101a023270368c0d50bffb62780f4afd4ea79c35",
    "decimals": 18,
    "image": "https://rango.vip/i/1jUvjE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02249404,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ANKR",
    "name": "Ankr",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1bfc5d35bf0f7b9e15dc24c78b8c02dbc1e95447",
    "decimals": 18,
    "image": "https://rango.vip/i/f0y7lX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02250925863733089,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "ANKR",
    "name": "Ankr Network",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x3580ac35bed2981d6bdd671a5982c2467d301241",
    "decimals": 18,
    "image": "https://rango.vip/i/BHPPhP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.02249404,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHFI",
    "name": "Ether.fi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe0c30065b384f05761f15d0cc899d4f9f9cc0eb",
    "decimals": 18,
    "image": "https://rango.vip/i/WKJfsz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.26,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ETHFI",
    "name": "Ether.fi",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x07d65c18cecba423298c0aeb5d2beded4dfd5736",
    "decimals": 18,
    "image": "https://rango.vip/i/VDzUjJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.2626328281971664,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARKM",
    "name": "Arkham",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e2a43be0b1d33b726f0ca3b8de60b3482b8b050",
    "decimals": 18,
    "image": "https://rango.vip/i/rJ7tR0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.93731,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ARKM",
    "name": "Arkham",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xdac5094b7d59647626444a4f905060fcda4e656e",
    "decimals": 18,
    "image": "https://rango.vip/i/eOi0jn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.9396065985147898,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RPL",
    "name": "Rocket Pool",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb766039cc6db368759c1e56b79affe831d0cc507",
    "decimals": 18,
    "image": "https://rango.vip/i/dGZwwX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 9.71,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RPL",
    "name": "Rocket Pool Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd33526068d116ce69f19a9ee46f0bd304f21a51f",
    "decimals": 18,
    "image": "https://rango.vip/i/tRMzxQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.71,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "RPL",
    "name": "Rocket Pool Protocol",
    "isPopular": false,
    "chainId": "324",
    "address": "0x1cf8553da5a75c20cdc33532cb19ef7e3bfff5bc",
    "decimals": 18,
    "image": "https://rango.vip/i/JmsQJc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 9.720711631870355,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "RPL",
    "name": "Rocket Pool Protocol",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc81d1f0eb955b0c020e5d5b264e1ff72c14d1401",
    "decimals": 18,
    "image": "https://rango.vip/i/wUkO4J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 9.720711631870355,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "T",
    "name": "Threshold Network Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4Njvi3928U3figEF5tf8xvjLC5GqUN33oe4XTJNe7xXC",
    "decimals": 8,
    "image": "https://rango.vip/i/y1dv32",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.020129631112899365,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "T",
    "name": "Threshold Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcdf7028ceab81fa0c6971208e83fa7872994bee5",
    "decimals": 18,
    "image": "https://rango.vip/i/SpIWdi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02005637,
    "supportedSwappers": [
      "Bridgers",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "T",
    "name": "Threshold Network",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x0945cae3ae47cb384b2d47bc448dc6a9dec21f55",
    "decimals": 18,
    "image": "https://rango.vip/i/AtiXgs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.020129631112899365,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "T",
    "name": "Threshold Network Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x747e42eb0591547a0ab429b3627816208c734ea7",
    "decimals": 18,
    "image": "https://rango.vip/i/ZxLm3m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.020129631112899365,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SSV",
    "name": "SSV Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d65ff81a3c488d585bbfb0bfe3c7707c7917f54",
    "decimals": 18,
    "image": "https://rango.vip/i/X9DoE4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 17.93,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVX",
    "name": "Convex Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
    "decimals": 18,
    "image": "https://rango.vip/i/QunWs5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.96,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CVX",
    "name": "Convex Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xaafcfd42c9954c6689ef1901e03db742520829c5",
    "decimals": 18,
    "image": "https://rango.vip/i/uchj0i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.9627142539351952,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLUX",
    "name": "FLUX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x469eda64aed3a3ad6f868c44564291aa415cb1d9",
    "decimals": 18,
    "image": "https://rango.vip/i/wS9sNN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04995734,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLUX",
    "name": "Flux Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7645ddfeeceda57e41f92679c4acd83c56a81d14",
    "decimals": 18,
    "image": "https://rango.vip/i/JdJOaQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02650351,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FLUX",
    "name": "Flux",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf80d589b3dbe130c270a69f1a69d050f268786df",
    "decimals": 18,
    "image": "https://rango.vip/i/izn8Y9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.04995734,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FLUX",
    "name": "Flux Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2338a5d62e9a766289934e8d2e83a443e8065b83",
    "decimals": 18,
    "image": "https://rango.vip/i/JdJOaQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.5425389955890544,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FLUX",
    "name": "FLUX",
    "isPopular": false,
    "chainId": "56",
    "address": "0xaff9084f2374585879e8b434c399e29e80cce635",
    "decimals": 8,
    "image": "https://rango.vip/i/RaMcTA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.5425389955890544,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "IO",
    "name": "IO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BZLbGTNCSFfoth2GYDtwr7e4imWzpR5jqcUuGEwr646K",
    "decimals": 8,
    "image": "https://rango.vip/i/GQjBxp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.5,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IO",
    "name": "Ideal Opportunities",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7468d234a8db6f1085dbf4e403553bfed41df95c",
    "decimals": 18,
    "image": "https://rango.vip/i/JbEEHA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001961,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "EDU",
    "name": "EDU Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbdeae1ca48894a1759a8374d63925f21f2ee2639",
    "decimals": 18,
    "image": "https://rango.vip/i/hOyCjS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.532649,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EDU",
    "name": "Open Campus",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26aad156ba8efa501b32b42ffcdc8413f90e9c99",
    "decimals": 18,
    "image": "https://rango.vip/i/ktK2LG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.532649,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "METIS",
    "name": "Metis",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e32b13ce7f2e80a01932b42553652e053d6ed8e",
    "decimals": 18,
    "image": "https://rango.vip/i/ApOeu8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 29.39,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "METIS",
    "name": "METIS",
    "isPopular": true,
    "chainId": "1088",
    "address": "0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000",
    "decimals": 18,
    "image": "https://rango.vip/i/M8JHtm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 29.56,
    "supportedSwappers": [
      "Netswap"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USDe",
    "name": "USDe",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x5d3a1ff2b6bab83b63cd9ad0787074081a52ef34",
    "decimals": 18,
    "image": "https://rango.vip/i/hwnm0J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.999454,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZBU",
    "name": "Zeebu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f9b4525681f3ea6e43b8e0a57bfff86c0a1dd2e",
    "decimals": 18,
    "image": "https://rango.vip/i/eXXhDS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.61,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLZ",
    "name": "Fellaz",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e964e35a76103af4c7d7318e1b1a82c682ae296",
    "decimals": 18,
    "image": "https://rango.vip/i/QS0G7a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.23,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FTN",
    "name": "Fasttoken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaedf386b755465871ff874e3e37af5976e247064",
    "decimals": 18,
    "image": "https://rango.vip/i/q0VfrL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.52,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "FRAX",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/0e43ede2e2a3afa36d0cd38bddc0b49feca64fa426a82e102f304e430ecf46ee",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/FRAX.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.9245750019292464,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "FRAX",
    "name": "Frax",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xd24c2ad096400b6fbcd2ad8b24e7acbc21a1da64",
    "decimals": 18,
    "image": "https://rango.vip/i/7rLwHL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.996425,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3",
      "stargate"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRAX",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x853d955acef822db058eb8505911ed77f175b99e",
    "decimals": 18,
    "image": "https://rango.vip/i/djZKOn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.996425,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "stargate",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FRAX",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
    "decimals": 18,
    "image": "https://rango.vip/i/tkQZoX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.996425,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "stargate",
      "SushiArbitrum",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "FRAX",
    "name": null,
    "isPopular": false,
    "chainId": "10",
    "address": "0x2e3d870790dc77a83dd1d18184acc7439a53f475",
    "decimals": 18,
    "image": "https://rango.vip/i/pj7dhZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.996425,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "stargate",
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "FRAX",
    "name": "Frax",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xda2585430fef327ad8ee44af8f1f989a2a91a3d2",
    "decimals": 18,
    "image": "https://rango.vip/i/gGFiup",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.9969874239758064,
    "supportedSwappers": [
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "FRAX",
    "name": "Frax",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xe4b9e004389d91e4134a28f19bd833cba1d994b6",
    "decimals": 18,
    "image": "https://rango.vip/i/rDdO48",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.9969874239758064,
    "supportedSwappers": [
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "FRAX",
    "name": "Frax",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x1a93b23281cc1cde4c4741353f3064709a16197d",
    "decimals": 18,
    "image": "https://rango.vip/i/QkfuF7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.996425,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FRAX",
    "name": "Frax",
    "isPopular": false,
    "chainId": "56",
    "address": "0x90c97f71e18723b0cf0dfa30ee176ab653e89f40",
    "decimals": 18,
    "image": "https://rango.vip/i/OCtmBC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.996425,
    "supportedSwappers": [
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FRAX",
    "name": "Frax (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FR87nWEUxVgerFGhZM8Y4AggKGLnaXswr1Pd8wZ4kZcp",
    "decimals": 8,
    "image": "https://rango.vip/i/NoI7Mf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.996425,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FRAX",
    "name": "Frax",
    "isPopular": false,
    "chainId": "137",
    "address": "0x45c32fa6df82ead1e2ef74d17b76547eddfaff89",
    "decimals": 18,
    "image": "https://rango.vip/i/wAOJFo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.996425,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FRAX",
    "name": "Frax (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x104592a158490a9228070e0a8e5343b499e125d0",
    "decimals": 18,
    "image": "https://rango.vip/i/1nD43z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.9969874239758064,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FRAX",
    "name": "Frax",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7468a5d8e02245b00e8c0217fce021c70bc51305",
    "decimals": 18,
    "image": "https://rango.vip/i/OCtmBC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.9969874239758064,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORBR",
    "name": "Orbler",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda30f261a962d5aae94c9ecd170544600d193766",
    "decimals": 18,
    "image": "https://rango.vip/i/ncSvcz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.374732,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDY",
    "name": "Ondo US Dollar Yield",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A1KLoBrKBde8Ty9qtNQUtq3C2ortoC3u7twggz7sEto6",
    "decimals": 6,
    "image": "https://rango.vip/i/mRFw5I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.053,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDY",
    "name": "Ondo US Dollar Yield",
    "isPopular": false,
    "chainId": "1",
    "address": "0x96f6ef951840721adbf46ac996b59e0235cb985c",
    "decimals": 18,
    "image": "https://rango.vip/i/GnN5Nb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.053,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDY",
    "name": "USDy",
    "isPopular": false,
    "chainId": "56",
    "address": "0x45d486479ed1db8b164d1386b3c89c05cee8ca86",
    "decimals": 18,
    "image": "https://rango.vip/i/xzy8aQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.0507481246520414,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IPV",
    "name": "IPVERSE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e2c956b4ab4807b2f942235c9074d9bd069e9f0",
    "decimals": 18,
    "image": "https://rango.vip/i/fc96Ud",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.91,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPECOIN",
    "name": "PepeCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa9e8acf069c58aec8825542845fd754e41a9489a",
    "decimals": 18,
    "image": "https://rango.vip/i/XOzE9g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AVAIL",
    "name": "Avail",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeeb4d8400aeefafc1b2953e0094134a887c76bd8",
    "decimals": 18,
    "image": "https://rango.vip/i/CyeDOu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.138899,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TURBO",
    "name": "Turbo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa35923162c49cf95e6bf26623385eb431ad920d3",
    "decimals": 18,
    "image": "https://rango.vip/i/DSZvvm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00360739,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TURBO",
    "name": "Turbo",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x5c816d4582c857dcadb1bb1f62ad6c9dede4576a",
    "decimals": 18,
    "image": "https://rango.vip/i/DS8DQW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0036134988479211167,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RLB",
    "name": "Rollbit Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a",
    "decimals": 2,
    "image": "https://rango.vip/i/h5dBVt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.103402,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RLB",
    "name": "Rollbit Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x046eee2cc3188071c02bfc1745a6b17c656e3f3d",
    "decimals": 18,
    "image": "https://rango.vip/i/JV8nRY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.076879,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRIBE",
    "name": "Tribe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc7283b66eb1eb5fb86327f08e1b5816b0720212b",
    "decimals": 18,
    "image": "https://rango.vip/i/lVKE6q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.39137,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TRIBE",
    "name": "Tribe",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xbfae6fecd8124ba33cbb2180aab0fe4c03914a5a",
    "decimals": 18,
    "image": "https://rango.vip/i/cxB7HE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.3784024007276134,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "OSAK",
    "name": "Osaka Protocol",
    "isPopular": false,
    "chainId": "10",
    "address": "0xbfd5206962267c7b4b4a8b3d76ac2e1b2a5c4d5e",
    "decimals": 18,
    "image": "https://rango.vip/i/eSJnVp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2.59541e-7,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OSAK",
    "name": "Osaka Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xbfd5206962267c7b4b4a8b3d76ac2e1b2a5c4d5e",
    "decimals": 18,
    "image": "https://rango.vip/i/eSJnVp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2.59541e-7,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "OSAK",
    "name": "Osaka Protocol",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xbfd5206962267c7b4b4a8b3d76ac2e1b2a5c4d5e",
    "decimals": 18,
    "image": "https://rango.vip/i/eSJnVp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2.59541e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OSAK",
    "name": "Osaka Protocol (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GoxLaNFQiqnV97p7aRGP4ghvLZ4GwJN9NUNPpozvJZCV",
    "decimals": 4,
    "image": "https://rango.vip/i/thl0zM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.591714730281716e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OSAK",
    "name": "Osaka Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa21af1050f7b26e0cff45ee51548254c41ed6b5c",
    "decimals": 18,
    "image": "https://rango.vip/i/1dmjwA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.59541e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "OSAK",
    "name": "Osaka Protocol",
    "isPopular": false,
    "chainId": "56",
    "address": "0x11cd72f7a4b699c67f225ca8abb20bc9f8db90c7",
    "decimals": 18,
    "image": "https://rango.vip/i/rVSVa5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.59541e-7,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "OSAK",
    "name": "Osaka Protocol",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x96e1056a8814de39c8c3cd0176042d6cecd807d7",
    "decimals": 18,
    "image": "https://rango.vip/i/DLv3vu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 2.59541e-7,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UMA",
    "name": "UMA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x04fa0d235c4abf4bcf4787af4cf447de572ef828",
    "decimals": 18,
    "image": "https://rango.vip/i/GPLhTF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.09,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "Across",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "UMA",
    "name": "UMA",
    "isPopular": false,
    "chainId": "10",
    "address": "0xe7798f023fc62146e8aa1b36da45fb70855a77ea",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/UMA.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2.093782363139557,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge",
      "Across"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UMA",
    "name": "UMA",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3066818837c5e6ed6601bd5a91b0762877a6b731",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/UMA.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2.093782363139557,
    "supportedSwappers": [
      "OneInchPolygon",
      "Across"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "UMA",
    "name": "UMA",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd693ec944a85eeca4247ec1c3b130dca9b0c3b22",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/UMA.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2.093782363139557,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Across"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "UMA",
    "name": "UMA",
    "isPopular": false,
    "chainId": "25",
    "address": "0x33564807cc70c6422124d867b344d7b90bf21a76",
    "decimals": 18,
    "image": "https://rango.vip/i/B5xiTy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 2.085321671412908,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BICO",
    "name": null,
    "isPopular": false,
    "chainId": "56",
    "address": "0x06250a4962558f0f3e69fc07f4c67bb9c9eac739",
    "decimals": 18,
    "image": "https://rango.vip/i/hyrkJU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.20273609373108645,
    "supportedSwappers": [
      "Hyphen"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BICO",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0xf17e65822b568b3903685a7c9f496cf7656cc6c2",
    "decimals": 18,
    "image": "https://rango.vip/i/R5O2k6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.202187,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Hyphen",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BICO",
    "name": null,
    "isPopular": false,
    "chainId": "137",
    "address": "0x91c89a94567980f0e9723b487b0bed586ee96aa7",
    "decimals": 18,
    "image": "https://rango.vip/i/hyrkJU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.20273609373108645,
    "supportedSwappers": [
      "Hyphen"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BICO",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa68ec98d7ca870cf1dd0b00ebbb7c4bf60a8e74d",
    "decimals": 18,
    "image": "https://rango.vip/i/hyrkJU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.202187,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Hyphen"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BICO",
    "name": null,
    "isPopular": false,
    "chainId": "10",
    "address": "0xd6909e9e702024eb93312b989ee46794c0fb1c9d",
    "decimals": 18,
    "image": "https://rango.vip/i/hyrkJU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.20273609373108645,
    "supportedSwappers": [
      "Hyphen"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ILV",
    "name": "ILV",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4d5ac5cc4f8abdf2ec2cb986c00c382369f787d4",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 35.9529887493643,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ILV",
    "name": "Illuvium",
    "isPopular": false,
    "chainId": "1",
    "address": "0x767fe9edc9e0df98e07454847909b5e959d7ca0e",
    "decimals": 18,
    "image": "https://rango.vip/i/euEdLE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 35.84,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ILV",
    "name": "Illuvium",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x61ca9d186f6b9a793bc08f6c79fd35f205488673",
    "decimals": 18,
    "image": "https://rango.vip/i/1aD7m5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 35.9529887493643,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABT",
    "name": "Arcblock",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb98d4c97425d9908e66e53a6fdf673acca0be986",
    "decimals": 18,
    "image": "https://rango.vip/i/KiaMTF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.72,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HONEY",
    "name": "HONEY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy",
    "decimals": 9,
    "image": "https://rango.vip/i/zgWIJA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.065067,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HONEY",
    "name": "Honey token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfa363022816abf82f18a9c2809dcd2bb393f6ac5",
    "decimals": 18,
    "image": "https://rango.vip/i/LLfuFA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.06509563905738897,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKL",
    "name": "SKALE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7",
    "decimals": 18,
    "image": "https://rango.vip/i/kZ0OLJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02976523,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "LRC",
    "name": "Loopring",
    "isPopular": false,
    "chainId": "25",
    "address": "0xaf760de3201feed80feea59fb16a8360c8c4d1a2",
    "decimals": 18,
    "image": "https://rango.vip/i/r5iPnP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.11929092956874301,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LRC",
    "name": "LoopringCoin V2",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbbbbca6a901c926f240b89eacb641d8aec7aeafd",
    "decimals": 18,
    "image": "https://rango.vip/i/dyUEF3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.118232,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LRC",
    "name": "LoopringCoin V2",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x46d0ce7de6247b0a95f67b43b589b4041bae7fbe",
    "decimals": 18,
    "image": "https://rango.vip/i/Av5mup",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.118232,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "LRC",
    "name": "LoopringCoin V2",
    "isPopular": false,
    "chainId": "10",
    "address": "0xfeaa9194f9f8c1b65429e31341a103071464907e",
    "decimals": 18,
    "image": "https://rango.vip/i/6qsh21",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.11860724957047097,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "YFI",
    "name": "yearn.finance",
    "isPopular": false,
    "chainId": "25",
    "address": "0x7bdf81a86f4aa8b442ca05670cbf296bb22bc7bb",
    "decimals": 18,
    "image": "https://rango.vip/i/IHfx81",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 4855.05649797569,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YFI",
    "name": "yearn finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e",
    "decimals": 18,
    "image": "https://rango.vip/i/OgT34Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4843.38,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "YFI",
    "name": "yearn.finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e",
    "decimals": 18,
    "image": "https://rango.vip/i/Q0NIM0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4852.441671352947,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "YFI",
    "name": "yearn.finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x82e3a8f066a6989666b031d916c43672085b1582",
    "decimals": 18,
    "image": "https://rango.vip/i/GlWGLi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 4843.38,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "YFI",
    "name": "yearn.finance",
    "isPopular": false,
    "chainId": "10",
    "address": "0x9046d36440290ffde54fe0dd84db8b1cfee9107b",
    "decimals": 18,
    "image": "https://rango.vip/i/Gb3jPk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 4843.38,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "BAND",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/f867ae2112efe646ec71a25cd2dfabb8927126ac1e19f1bbf0ff693a4eca05de",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/BAND.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 1.023004607507635,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAND",
    "name": "Band Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba11d00c5f74255f56a5e366f4f77f5a186d7f55",
    "decimals": 18,
    "image": "https://rango.vip/i/Uh0gaL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.019,
    "supportedSwappers": [
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BAND",
    "name": "BAND Protocol Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18",
    "decimals": 18,
    "image": "https://rango.vip/i/I5meZL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.0236426928384992,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BANDCHAIN",
    "symbol": "BAND",
    "name": null,
    "isPopular": true,
    "chainId": "laozi-mainnet",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/AaHGcq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BANDCHAIN/icon.svg",
    "usdPrice": 1.0236996556814557,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DSYNC",
    "name": "Destra Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf94e7d0710709388bce3161c32b4eea56d3f91cc",
    "decimals": 18,
    "image": "https://rango.vip/i/nBi2OD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.157956,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUDIO",
    "name": "Audius",
    "isPopular": false,
    "chainId": "1",
    "address": "0x18aaa7115705e8be94bffebde57af9bfc265b998",
    "decimals": 18,
    "image": "https://rango.vip/i/COLXL3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.120497,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AUDIO",
    "name": "Audius (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9LzCMqDgTKYz9Drzqnpgee3SGa89up3a247ypMj2xrqM",
    "decimals": 8,
    "image": "https://rango.vip/i/QvIC0U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.120699,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AUDIO",
    "name": "Audius",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5eb8d998371971d01954205c7afe90a7af6a95ac",
    "decimals": 18,
    "image": "https://rango.vip/i/EDJZhn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.12128475268910785,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "H2O",
    "name": "Defrost Finance H2O",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x026187bdbc6b751003517bcb30ac7817d5b766f8",
    "decimals": 18,
    "image": "https://rango.vip/i/52Xiy1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.1810563732482585,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRB",
    "name": "Tellor",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0",
    "decimals": 18,
    "image": "https://rango.vip/i/kSfsm2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 56.65,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TRB",
    "name": "Tellor",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd58d345fd9c82262e087d2d0607624b410d88242",
    "decimals": 18,
    "image": "https://rango.vip/i/4Kexxa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 56.65,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "TRB",
    "name": "Tellor",
    "isPopular": false,
    "chainId": "10",
    "address": "0xaf8ca653fa2772d58f4368b0a71980e9e3ceb888",
    "decimals": 18,
    "image": "https://rango.vip/i/Mr49zT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 56.65,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SUSHI",
    "name": "SushiSwap",
    "isPopular": false,
    "chainId": "25",
    "address": "0xdb3de0aac8a39490932fa19c2e32f179368ab840",
    "decimals": 18,
    "image": "https://rango.vip/i/bJoScz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.569746552199862,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUSHI",
    "name": "Sushi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
    "decimals": 18,
    "image": "https://rango.vip/i/YG6gfs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.567192,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "SUSHI",
    "name": "Sushi",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xf390830df829cf22c53c8840554b98eafc5dcbc2",
    "decimals": 18,
    "image": "https://rango.vip/i/N37Zak",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.568724255366225,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SUSHI",
    "name": "SushiToken",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd4d42f0b6def4ce0383636770ef773390d85c61a",
    "decimals": 18,
    "image": "https://rango.vip/i/ASVEeV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.567192,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SUSHI",
    "name": "SushiToken (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ChVzxWRmrTeSgwd3Ui3UumcN8KX7VK3WaD4KGeSKpypj",
    "decimals": 8,
    "image": "https://rango.vip/i/4wx3Xp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.567192,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SUSHI",
    "name": "SushiToken",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a",
    "decimals": 18,
    "image": "https://rango.vip/i/Uczk2Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.567192,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SUSHI",
    "name": "SushiToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0x947950bcc74888a40ffa2593c5798f11fc9124c4",
    "decimals": 18,
    "image": "https://rango.vip/i/TeqrUf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.567192,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SUSHI",
    "name": "SushiToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0x986cdf0fd180b40c4d6aeaa01ab740b996d8b782",
    "decimals": 18,
    "image": "https://rango.vip/i/DzO2WS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.568724255366225,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SUSHI",
    "name": "SushiToken",
    "isPopular": false,
    "chainId": "10",
    "address": "0x3eaeb77b03dbc0f6321ae1b72b2e9adb0f60112b",
    "decimals": 18,
    "image": "https://rango.vip/i/hd4gke",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.568724255366225,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "SUSHI",
    "name": "Optics v2 SUSHI",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x29dfce9c22003a4999930382fd00f9fd6133acd1",
    "decimals": 18,
    "image": "https://rango.vip/i/FjqKYk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.6960151395887286,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALPH",
    "name": "Alephium",
    "isPopular": false,
    "chainId": "1",
    "address": "0x590f820444fa3638e022776752c5eef34e2f89a6",
    "decimals": 18,
    "image": "https://rango.vip/i/wHBvG1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.69,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ONT",
    "name": "Ontology Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfd7b3a77848f1c2d67e05e54d78d174a0c850335",
    "decimals": 18,
    "image": "https://rango.vip/i/SA4PbU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.160331,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "USDJ",
    "name": "JUST Stablecoin v1.0",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT",
    "decimals": 18,
    "image": "https://rango.vip/i/tDBIYW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 1.13,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "VTHO",
    "name": "VeThor Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x3f2cec6455f874edc9d86e48312787ccb19e0f72",
    "decimals": 18,
    "image": "https://rango.vip/i/di58nj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0017797645341435131,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "FXS",
    "name": "Frax Share",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454",
    "decimals": 18,
    "image": "https://rango.vip/i/y1UHiq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.747687139219983,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "FXS",
    "name": "Frax Share",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x214db107654ff987ad859f34125307783fc8e387",
    "decimals": 18,
    "image": "https://rango.vip/i/iBQzxT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.74,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FXS",
    "name": "Frax Share",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0",
    "decimals": 18,
    "image": "https://rango.vip/i/ee2ncg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.74,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "FXS",
    "name": "Frax Share",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x6f1d1ee50846fcbc3de91723e61cb68cfa6d0e98",
    "decimals": 18,
    "image": "https://rango.vip/i/tQUQ6e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 1.74,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FXS",
    "name": "Frax Share",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe48a3d7d0bc88d552f730b62c006bc925eadb9ee",
    "decimals": 18,
    "image": "https://rango.vip/i/CnT20j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.74,
    "supportedSwappers": [
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FXS",
    "name": "Frax Share",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9d2f299715d94d8a7e6f5eaa8e654e8c74a988a7",
    "decimals": 18,
    "image": "https://rango.vip/i/cdRlvu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.74,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FXS",
    "name": "Frax Share",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1a3acf6d19267e2d3e7f898f42803e90c9219062",
    "decimals": 18,
    "image": "https://rango.vip/i/MmCzVw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.74,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FXS",
    "name": "Frax Share (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3e121107f6f22da4911079845a470757af4e1a1b",
    "decimals": 18,
    "image": "https://rango.vip/i/LWbPCK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.747687139219983,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FXS",
    "name": "Frax Share",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd9f9d2ee2d3efe420699079f16d9e924afffdea4",
    "decimals": 18,
    "image": "https://rango.vip/i/aIybDO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.747687139219983,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "FXS",
    "name": "Frax Share",
    "isPopular": false,
    "chainId": "10",
    "address": "0x67ccea5bb16181e7b4109c9c2143c24a1c2205be",
    "decimals": 18,
    "image": "https://rango.vip/i/FlTUfi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.747687139219983,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BORG",
    "name": "Cyborg Apes BORG",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CFbdjaKonbBQTYG2GC6CmB7exofgDYGCDR8tp8KVGS7T",
    "decimals": 6,
    "image": "https://rango.vip/i/BIVFtX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00085434,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BORG",
    "name": "SwissBorg Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3dQTr7ror2QPKQ3GbBCokJUmjErGg8kTJzdnYjNfvi3Z",
    "decimals": 9,
    "image": "https://rango.vip/i/0p8NOU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.143153,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BORG",
    "name": "SwissBorg",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64d0f55cd8c7133a9d7102b13987235f486f2224",
    "decimals": 18,
    "image": "https://rango.vip/i/TRXXVS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.143153,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EURS",
    "name": "STASIS EURS Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe111178a87a3bff0c8d18decba5798827539ae99",
    "decimals": 2,
    "image": "https://rango.vip/i/3Bz6ZH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EURS",
    "name": "STASIS EURS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb25f211ab05b1c97d595516f45794528a807ad8",
    "decimals": 2,
    "image": "https://rango.vip/i/BFByaq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "EURS",
    "name": "EURS",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd22a58f79e9481d1a88e00c343885a588b34b68b",
    "decimals": 2,
    "image": "https://rango.vip/i/OO0rHD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CSPR",
    "name": "Casper",
    "isPopular": false,
    "chainId": "25",
    "address": "0x15a9e70f166bcaaa7bff094d865ce5aaa73a2a58",
    "decimals": 9,
    "image": "https://rango.vip/i/YMaN2p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0110489515357439,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "GLMR",
    "name": "GLMR",
    "isPopular": false,
    "chainId": "25",
    "address": "0x268b344bf8bbcd9dd4e4fa68264309b05f15820a",
    "decimals": 18,
    "image": "https://rango.vip/i/zEdm0Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.1521279318887549,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "GLMR",
    "name": "GLMR",
    "isPopular": false,
    "chainId": "1284",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/Ra0xKh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.150133,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "YGG",
    "name": "Yield Guild Games Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xff9620d9f80f80056cbe4bb84403a9e9c5174213",
    "decimals": 18,
    "image": "https://rango.vip/i/cwfubz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.3572359117263681,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YGG",
    "name": "Yield Guild Games Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x25f8087ead173b73d6e8b84329989a8eea16cf73",
    "decimals": 18,
    "image": "https://rango.vip/i/cVYWNg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.357543,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COTI",
    "name": "COTI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xddb3422497e61e13543bea06989c0789117555c5",
    "decimals": 18,
    "image": "https://rango.vip/i/U9OLoY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.07766,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "COTI",
    "name": "COTI Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6fe14d3cc2f7bddffba5cdb3bbe7467dd81ea101",
    "decimals": 18,
    "image": "https://rango.vip/i/chRgd7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.07785989614250344,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "SAGA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/094fb70c3006906f67f5d674073d2dafafb41537e7033098f5c752f211e7b6c2",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 1.32,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACH",
    "name": "Alchemy Pay",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed04915c23f00a313a544955524eb7dbd823143d",
    "decimals": 8,
    "image": "https://rango.vip/i/R4BVLo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01640696,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ACH",
    "name": "Alchemy Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbc7d6b50616989655afd682fb42743507003056d",
    "decimals": 8,
    "image": "https://rango.vip/i/bwy1Bu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01640696,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VANRY",
    "name": "Vanar Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8de5b80a0c1b02fe4976851d030b36122dbb8624",
    "decimals": 18,
    "image": "https://rango.vip/i/bEjUhb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.085938,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STORJ",
    "name": "Storj Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac",
    "decimals": 8,
    "image": "https://rango.vip/i/84Dp0o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.33248,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "STORJ",
    "name": "Storj Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe6320ebf209971b4f4696f7f0954b8457aa2fcc2",
    "decimals": 8,
    "image": "https://rango.vip/i/84Dp0o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.3328462053207707,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PONKE",
    "name": "PONKE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5z3EqYQo9HiCEs3R84RCDMu2n7anpDMxRhdK8PSWmrRC",
    "decimals": 9,
    "image": "https://rango.vip/i/5llW6g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.22346,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZIG",
    "name": "ZIG Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ANqY8h3sRSwkC29FvGJenAh7VGRABVVx7Ls6Mq4BuGT",
    "decimals": 9,
    "image": "https://rango.vip/i/10OQbj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.092411,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZIG",
    "name": "Zignaly",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2617246d0c6c0087f18703d576831899ca94f01",
    "decimals": 18,
    "image": "https://rango.vip/i/YI4tPO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.085818,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAAL",
    "name": "PAAL AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x14fee680690900ba0cccfc76ad70fd1b95d10e16",
    "decimals": 9,
    "image": "https://rango.vip/i/64TCwA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.150172,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ICX",
    "name": "ICON",
    "isPopular": false,
    "chainId": "25",
    "address": "0xf6726cebd173cf30926b69087179e18489183422",
    "decimals": 18,
    "image": "https://rango.vip/i/3JDxlv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.11705468474469352,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TEL",
    "name": "Telcoin",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdf7837de1f2fa4631d716cf2502f8b230f1dcc32",
    "decimals": 2,
    "image": "https://rango.vip/i/5HIn2X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00128521,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TEL",
    "name": "Telcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x467bccd9d29f223bce8043b84e8c8b282827790f",
    "decimals": 2,
    "image": "https://rango.vip/i/5gMKo5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00128521,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SXP",
    "name": "Swipe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9",
    "decimals": 18,
    "image": "https://rango.vip/i/92M5cs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.188915,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SXP",
    "name": "Swipe",
    "isPopular": false,
    "chainId": "56",
    "address": "0x47bead2563dcbf3bf2c9407fea4dc236faba485a",
    "decimals": 18,
    "image": "https://rango.vip/i/H3M8iy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.188915,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZEN",
    "name": "Zenith",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3bbbb6a231d0a1a12c6b79ba5bc2ed6358db5160",
    "decimals": 18,
    "image": "https://rango.vip/i/xNAJPD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02171661,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "API3",
    "name": "API3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b38210ea11411557c13457d4da7dc6ea731b88a",
    "decimals": 18,
    "image": "https://rango.vip/i/gpSpj0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.29,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "API3",
    "name": "API3",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf01db12f50d0cdf5fe360ae005b9c52f92ca7811",
    "decimals": 18,
    "image": "https://rango.vip/i/26CqEj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.2922188277554112,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVER",
    "name": "Everscale",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1ffefd8036409cb6d652bd610de465933b226917",
    "decimals": 9,
    "image": "https://rango.vip/i/A0OxB3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.055718,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WAVES",
    "name": "Playground Waves",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4uRn7vxRPWYP4HuAa4UNXwEPLRL8oQ71YByMhr6yBnL4",
    "decimals": 2,
    "image": "https://rango.vip/i/L57ADo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.125614,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAVES",
    "name": "Waves",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1cf4592ebffd730c7dc92c1bdffdfc3b9efcf29a",
    "decimals": 18,
    "image": "https://rango.vip/i/iFE6H1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.953883,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SURE",
    "name": "inSure DeFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb86c6a22cb56b6cf40cafedb06ba0df188a416e",
    "decimals": 18,
    "image": "https://rango.vip/i/nIM61r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0040362,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PRO",
    "name": "Propy",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x18dd5b087bca9920562aff7a0199b96b9230438b",
    "decimals": 18,
    "image": "https://rango.vip/i/LPDJwY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.086,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRO",
    "name": "Prodigy Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd7e92cf6f857be8541fca6abfb72aef8e16c307",
    "decimals": 18,
    "image": "https://rango.vip/i/ESd5HT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.216949,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAL",
    "name": "Balancer",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba100000625a3754423978a60c9317c58a424e3d",
    "decimals": 18,
    "image": "https://rango.vip/i/V6UXTP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.78,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BAL",
    "name": "Balancer",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3",
    "decimals": 18,
    "image": "https://rango.vip/i/paieki",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.78,
    "supportedSwappers": [
      "PolygonUniSwapV3",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BAL",
    "name": "Balancer",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x040d1edc9569d4bab2d15287dc5a4f10f56a56b8",
    "decimals": 18,
    "image": "https://rango.vip/i/nGZCJj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.78,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BAL",
    "name": "Balancer",
    "isPopular": false,
    "chainId": "10",
    "address": "0xfe8b128ba8c78aabc59d4c64cee7ff28e9379921",
    "decimals": 18,
    "image": "https://rango.vip/i/zbEWh2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.78,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LSK",
    "name": "Lisk",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6033f7f88332b8db6ad452b7c6d5bb643990ae3f",
    "decimals": 18,
    "image": "https://rango.vip/i/P0eqaE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.728385,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "VVS",
    "name": "VVS",
    "isPopular": false,
    "chainId": "25",
    "address": "0x2d03bece6747adc00e1a131bba1469c15fd11e03",
    "decimals": 18,
    "image": "https://rango.vip/i/1EdKSS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00000224,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NPC",
    "name": "Non Playable Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ed97a637a790be1feff5e888d43629dc05408f6",
    "decimals": 18,
    "image": "https://rango.vip/i/rH6kjg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01389311,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RSS3",
    "name": "RSS3",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc98d64da73a6616c42117b582e832812e7b8d57f",
    "decimals": 18,
    "image": "https://rango.vip/i/lFxFNa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.102946,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAIKO",
    "name": "Taiko",
    "isPopular": false,
    "chainId": "1",
    "address": "0x10dea67478c5f8c5e2d90e5e9b26dbe60c54d800",
    "decimals": 18,
    "image": "https://rango.vip/i/GcSYoR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.33,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAXP",
    "name": "WAX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a79324c19ef2b89ea98b23bc669b7e7c9f8a517",
    "decimals": 8,
    "image": "https://rango.vip/i/dhgby9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02902926,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RLC",
    "name": "iExec RLC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x607f4c5bb672230e8672085532f7e901544a7375",
    "decimals": 9,
    "image": "https://rango.vip/i/qJvgeu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.4,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LON",
    "name": "Tokenlon",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x55678cd083fcdc2947a0df635c93c838c89454a3",
    "decimals": 18,
    "image": "https://rango.vip/i/ajjPiy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.879167,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LON",
    "name": "Tokenlon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0000000000095413afc295d19edeb1ad7b71c952",
    "decimals": 18,
    "image": "https://rango.vip/i/ZTCkpD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.879167,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XVS",
    "name": "Venus Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
    "decimals": 18,
    "image": "https://rango.vip/i/nfKWE1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 6.15,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "XVS",
    "name": "Venus",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc1eb7689147c81ac840d4ff0d298489fc7986d52",
    "decimals": 18,
    "image": "https://rango.vip/i/cmegIW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 6.15,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XVS",
    "name": "Venus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd3cc9d8f3689b83c91b7b59cab4946b063eb894a",
    "decimals": 18,
    "image": "https://rango.vip/i/cmegIW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTSI",
    "name": "Cartesi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d",
    "decimals": 18,
    "image": "https://rango.vip/i/pWNnOa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.119498,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CTSI",
    "name": "Cartesi",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x319f865b287fcc10b30d8ce6144e8b6d1b476999",
    "decimals": 18,
    "image": "https://rango.vip/i/ozKuSH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.119498,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "CTSI",
    "name": "Cartesi Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xec6adef5e1006bb305bb1975333e8fc4071295bf",
    "decimals": 18,
    "image": "https://rango.vip/i/eIJ2Pr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.119498,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IOST",
    "name": "IOST",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa1a856cfa3409cfa145fa4e20eb270df3eb21ab",
    "decimals": 18,
    "image": "https://rango.vip/i/3FzPYd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.004623769779795517,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POND",
    "name": "Marlin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x57b946008913b82e4df85f501cbaed910e58d26c",
    "decimals": 18,
    "image": "https://rango.vip/i/wm0edx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01202107,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "POND",
    "name": "Marlin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xda0a57b710768ae17941a9fa33f8b720c8bd9ddd",
    "decimals": 18,
    "image": "https://rango.vip/i/W7KE1b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01202107,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIAT",
    "name": "AI Analysis Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0501b9188436e35bb10f35998c40adc079003866",
    "decimals": 18,
    "image": "https://rango.vip/i/eNy8ul",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.663858,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DUSK",
    "name": "Dusk",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c",
    "decimals": 18,
    "image": "https://rango.vip/i/XZ0lzJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.207399,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DUSK",
    "name": "Dusk",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DMbb79fgxR3fKKVKWcbetMCuxNNtJnfjY3qqAE4G4wJf",
    "decimals": 3,
    "image": "https://rango.vip/i/xkBbCf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.20731194621738433,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUSK",
    "name": "Dusk Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x940a2db1b7008b6c776d4faaca729d6d4a4aa551",
    "decimals": 18,
    "image": "https://rango.vip/i/LfaCTi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.207399,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NMR",
    "name": "Numeraire",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1776e1f26f98b1a5df9cd347953a26dd3cb46671",
    "decimals": 18,
    "image": "https://rango.vip/i/CdqDF1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 13.09,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SLP",
    "name": "Smooth Love Potion",
    "isPopular": false,
    "chainId": "25",
    "address": "0xc00dcfbc7ac19b7210fa9c73b5f1d2e0f7e62711",
    "decimals": 0,
    "image": "https://rango.vip/i/ZH9ct7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.002277548086683973,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SLP",
    "name": "Smooth Love Potion",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25",
    "decimals": 0,
    "image": "https://rango.vip/i/RdOxKL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.002295,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PIXEL",
    "name": "Pixel",
    "isPopular": false,
    "chainId": "137",
    "address": "0x61e9c2f3501889f6167921087bd6ea306002904a",
    "decimals": 18,
    "image": "https://rango.vip/i/KfF8UI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.122336752623281,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIXEL",
    "name": "PIXEL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3429d03c6f7521aec737a0bbf2e5ddcef2c3ae31",
    "decimals": 18,
    "image": "https://rango.vip/i/SZbJMP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.121928,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIXEL",
    "name": "PixelVerse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65e6b60ea01668634d68d0513fe814679f925bad",
    "decimals": 18,
    "image": "https://rango.vip/i/KZZCqI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00054113,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIXEL",
    "name": "Pixel",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1590abe3612be1cb3ab5b0f28874d521576e97dc",
    "decimals": 18,
    "image": "https://rango.vip/i/V7DoYm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.122336752623281,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DRIFT",
    "name": "Drift",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DriFtupJYLTosbwoN8koMbEYSx54aFAVLddWsbksjwg7",
    "decimals": 6,
    "image": "https://rango.vip/i/kJa7sv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.418537,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DRIFT",
    "name": "Drift Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb7cffebb06621287c7850ffefb22c30252e78e6b",
    "decimals": 18,
    "image": "https://rango.vip/i/pLQQGF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007836,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GUSD",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x056fd409e1d7a124bd7017459dfea2f387b6d5cd",
    "decimals": 2,
    "image": "https://rango.vip/i/OKBM67",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999328,
    "supportedSwappers": [
      "ThorChain",
      "EthereumUniswapV3",
      "ThorChainStreamingSwap",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RDNT",
    "name": "Radiant",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3082cc23568ea640225c2467653db90e9250aaa0",
    "decimals": 18,
    "image": "https://rango.vip/i/zrWSXC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.083177,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RDNT",
    "name": "Radiant",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x0c4681e6c0235179ec3d4f4fc4df3d14fdd96017",
    "decimals": 18,
    "image": "https://rango.vip/i/b8pU0i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0831074618751282,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RDNT",
    "name": "Radiant Capital",
    "isPopular": false,
    "chainId": "1",
    "address": "0x137ddb47ee24eaa998a535ab00378d6bfa84f893",
    "decimals": 18,
    "image": "https://rango.vip/i/xT8TLn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.083177,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RDNT",
    "name": "Radiant",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf7de7e8a6bd59ed41a4b5fe50278b3b7f31384df",
    "decimals": 18,
    "image": "https://rango.vip/i/uvdMna",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.083177,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POWR",
    "name": "Power Ledger",
    "isPopular": false,
    "chainId": "1",
    "address": "0x595832f8fc6bf59c85c527fec3740a1b7a361269",
    "decimals": 6,
    "image": "https://rango.vip/i/TzyQzb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.176892,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "POWR",
    "name": "Power Ledger",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4e91f2af1ee0f84b529478f19794f5afd423e4a6",
    "decimals": 6,
    "image": "https://rango.vip/i/n0YESH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.17723100931037394,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PYR",
    "name": "Vulcan Forged",
    "isPopular": false,
    "chainId": "1",
    "address": "0x430ef9263e76dae63c84292c3409d61c598e9682",
    "decimals": 18,
    "image": "https://rango.vip/i/GTFaio",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.41,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PYR",
    "name": "PYR Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x430ef9263e76dae63c84292c3409d61c598e9682",
    "decimals": 18,
    "image": "https://rango.vip/i/SJUZeX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2.41,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUNDIX",
    "name": "Pundi X Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0fd10b9899882a6f2fcb5c371e17e70fdee00c38",
    "decimals": 18,
    "image": "https://rango.vip/i/eoRQlT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.355341,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOBILE",
    "name": "Helium Mobile",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "mb1eu7TzEc71KxDpsmsKoucSSuuoGLv1drys1oP2jh6",
    "decimals": 6,
    "image": "https://rango.vip/i/5b3X9j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00107298,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEEP",
    "name": "KEEP Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85eee30c52b0b379b046fb0f85f4f3dc3009afec",
    "decimals": 18,
    "image": "https://rango.vip/i/IMf4lR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.096511,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEEP",
    "name": "Keep",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbf40440703a3f7092b2e73c2f7868727275dbbda",
    "decimals": 18,
    "image": "https://rango.vip/i/VXELi5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.09657960062106061,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LCX",
    "name": "LCX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x037a54aab062628c9bbae1fdb1583c195585fe41",
    "decimals": 18,
    "image": "https://rango.vip/i/U3B1CE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.119368,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MPLX",
    "name": "Metaplex Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m",
    "decimals": 6,
    "image": "https://rango.vip/i/uBT3G9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.248318,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ORCA",
    "name": "OrcaDAO",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x8b1d98a91f853218ddbb066f20b8c63e782e2430",
    "decimals": 18,
    "image": "https://rango.vip/i/xD3uXP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0000903973412572094,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ORCA",
    "name": "Orca",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
    "decimals": 6,
    "image": "https://rango.vip/i/tF2eSy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.72,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NOS",
    "name": "Nosana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7",
    "decimals": 6,
    "image": "https://rango.vip/i/k2bGyY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.87,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTRST",
    "name": "Braintrust",
    "isPopular": false,
    "chainId": "1",
    "address": "0x799ebfabe77a6e34311eeee9825190b9ece32824",
    "decimals": 18,
    "image": "https://rango.vip/i/WR7XjH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.38559,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BTRST",
    "name": "BTRST",
    "isPopular": false,
    "chainId": "10",
    "address": "0xed50ace88bd42b45cb0f49be15395021e141254e",
    "decimals": 18,
    "image": "https://rango.vip/i/TiDgpB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.3857815938171553,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PROM",
    "name": "Prom",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc82bb4ba86045af6f327323a46e80412b91b27d",
    "decimals": 18,
    "image": "https://rango.vip/i/5e1OWL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.89,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PROM",
    "name": "Prometeus",
    "isPopular": false,
    "chainId": "56",
    "address": "0xaf53d56ff99f1322515e54fdde93ff8b3b7dafd5",
    "decimals": 18,
    "image": "https://rango.vip/i/PCOR8y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4.89,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UPC",
    "name": "UPCX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x487d62468282bd04ddf976631c23128a425555ee",
    "decimals": 5,
    "image": "https://rango.vip/i/Jphli2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.84,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BONE",
    "name": "BONE Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6bb45ceac714c52342ef73ec663479da35934bf7",
    "decimals": 18,
    "image": "https://rango.vip/i/hyCmxf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02214942,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BONE",
    "name": "BONE SHIBASWAP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9813037ee2218799597d83d4a5b6f3b6778218d9",
    "decimals": 18,
    "image": "https://rango.vip/i/dFUXnR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.385769,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "C98",
    "name": "Coin98",
    "isPopular": false,
    "chainId": "56",
    "address": "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
    "decimals": 18,
    "image": "https://rango.vip/i/MJX4um",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.106316,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "C98",
    "name": "Coin98",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9",
    "decimals": 6,
    "image": "https://rango.vip/i/IeKZMm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.106316,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "C98",
    "name": "Coin98",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae12c5930881c53715b369cec7606b70d8eb229f",
    "decimals": 18,
    "image": "https://rango.vip/i/yNxNWF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.106316,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "COQ",
    "name": "COQINU",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x420fca0121dc28039145009570975747295f2329",
    "decimals": 18,
    "image": "https://rango.vip/i/isyiXS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00000126,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POLY",
    "name": "Polymath",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec",
    "decimals": 18,
    "image": "https://rango.vip/i/zqKeCp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.097175,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDX",
    "name": "Hex Trust USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a486f809c952a6f8dec8cb0ff68173f2b8ed56c",
    "decimals": 6,
    "image": "https://rango.vip/i/LOuc5m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.98837,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDX",
    "name": "USDX",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1203355742e76875154c0d13eb81dcd7711dc7d9",
    "decimals": 6,
    "image": "https://rango.vip/i/QbSKGc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.7788477463835531,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZENT",
    "name": "Zentry",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdbb7a34bf10169d6d2d0d02a6cbb436cf4381bfa",
    "decimals": 18,
    "image": "https://rango.vip/i/YKBdV5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01548456,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZENT",
    "name": "ZENTU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e985250cb137fc1ff55922116934c5982d29f85",
    "decimals": 18,
    "image": "https://rango.vip/i/qi6yvq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00853853,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "IQ",
    "name": "Everipedia IQ (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb9638272ad6998708de56bbc0a290a1de534a578",
    "decimals": 18,
    "image": "https://rango.vip/i/j9dan8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00446833,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IQ",
    "name": "Everipedia IQ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x579cea1889991f68acc35ff5c3dd0621ff29b0c9",
    "decimals": 18,
    "image": "https://rango.vip/i/lU4gSs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00446833,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MVL",
    "name": "MVL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa849eaae994fb86afa73382e9bd88c2b6b18dc71",
    "decimals": 18,
    "image": "https://rango.vip/i/Q5Fx4C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00334888,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MAGIC",
    "name": "Magic",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x539bde0d7dbd336b79148aa742883198bbf60342",
    "decimals": 18,
    "image": "https://rango.vip/i/vDsMbv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.316273,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAGIC",
    "name": "MAGIC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb0c7a3ba49c7a6eaba6cd4a96c55a1391070ac9a",
    "decimals": 18,
    "image": "https://rango.vip/i/ARqMQv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.316273,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUCTION",
    "name": "Bounce Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096",
    "decimals": 18,
    "image": "https://rango.vip/i/wGQldZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 12.98,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TLOS",
    "name": "Telos",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb6c53431608e626ac81a9776ac3e999c5556717c",
    "decimals": 18,
    "image": "https://rango.vip/i/IQFSal",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.221696,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TLOS",
    "name": "pTokens TLOS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7825e833d495f3d1c28872415a4aee339d26ac88",
    "decimals": 18,
    "image": "https://rango.vip/i/cKg3Cr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.221696,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TLOS",
    "name": "Telos",
    "isPopular": false,
    "chainId": "1",
    "address": "0x193f4a4a6ea24102f49b931deeeb931f6e32405d",
    "decimals": 18,
    "image": "https://rango.vip/i/bUlmvs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.22239,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHEX",
    "name": "Chintai",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6dKCoWjpj5MFU5gWDEFdpUUeBasBLK3wLEwhUzQPAa1e",
    "decimals": 8,
    "image": "https://rango.vip/i/Fa4eAa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.084089,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHEX",
    "name": "CHEX Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ce84f6a69986a83d92c324df10bc8e64771030f",
    "decimals": 18,
    "image": "https://rango.vip/i/qDHdlY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.084089,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIGH",
    "name": "Highstreet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x71ab77b7dbb4fa7e017bc15090b2163221420282",
    "decimals": 18,
    "image": "https://rango.vip/i/vVvScQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.22,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HIGH",
    "name": "Highstreet Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5f4bde007dc06b867f86ebfe4802e34a1ffeed63",
    "decimals": 18,
    "image": "https://rango.vip/i/4j0gF0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.22,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HIGH",
    "name": "Highstreet",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd12eeb0142d4efe7af82e4f29e5af382615bceea",
    "decimals": 18,
    "image": "https://rango.vip/i/cUpYJe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.2276431527566922,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CELR",
    "name": "Celer Network",
    "isPopular": false,
    "chainId": "25",
    "address": "0xfa0235fef8644c107f7e531fa9cfe0613fbe8909",
    "decimals": 18,
    "image": "https://rango.vip/i/Y6dkoC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.010457065703018142,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CELR",
    "name": "Celer Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4f9254c83eb525f9fcf346490bbb3ed28a81c667",
    "decimals": 18,
    "image": "https://rango.vip/i/h3W6yD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01045426,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CELR",
    "name": "Celer Network",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3a8b787f78d775aecfeea15706d4221b40f345ab",
    "decimals": 18,
    "image": "https://rango.vip/i/5e6gpL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01045426,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIGTIME",
    "name": "Big Time",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64bc2ca1be492be7185faa2c8835d9b824c8a194",
    "decimals": 18,
    "image": "https://rango.vip/i/afTacU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.075645,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WMT",
    "name": "World Mobile Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x263b6b028f3e4ed8c4329eb2b5f409ee38d97296",
    "decimals": 6,
    "image": "https://rango.vip/i/9kklSc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.163059,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PHA",
    "name": "Phala Network",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0112e557d400474717056c4e6d40edd846f38351",
    "decimals": 18,
    "image": "https://rango.vip/i/IgGc62",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.10670574413938266,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PHA",
    "name": "Phala",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c5ba91642f10282b576d91922ae6448c9d52f4e",
    "decimals": 18,
    "image": "https://rango.vip/i/SpkpYc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.106503,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "MOVR",
    "name": "Moonriver",
    "isPopular": true,
    "chainId": "1285",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/Errpuu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 8.73,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZANO",
    "name": "Zano",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb85f6685950e285b1e611037bebe5b34e2b7d78",
    "decimals": 18,
    "image": "https://rango.vip/i/zQPkig",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.92,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "ISLM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/69110ff673d70b39904ff056cfdfd58a90bec3194303f45c32cb91b8b0a738ea",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.07282300015195468,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "KNC",
    "name": "Kyber Network Crystal",
    "isPopular": false,
    "chainId": "25",
    "address": "0xd73ebf885c4157d3e88c6d87ad3b8018b4a32fef",
    "decimals": 18,
    "image": "https://rango.vip/i/kan3rr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.42264126289610604,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KNC",
    "name": "Kyber Network Crystal",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd974d5c2e2928dea5f71b9825b8b646686bd200",
    "decimals": 18,
    "image": "https://rango.vip/i/114C6j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.420718,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "KNC",
    "name": "Kyber Network Crystal",
    "isPopular": false,
    "chainId": "10",
    "address": "0xa00e3a3511aac35ca78530c85007afcd31753819",
    "decimals": 18,
    "image": "https://rango.vip/i/Lm2dPh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.420978,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "KNC",
    "name": "Kyber Network Crystal v2",
    "isPopular": false,
    "chainId": "534352",
    "address": "0x608ef9a3bffe206b86c3108218003b3cfbf99c84",
    "decimals": 18,
    "image": "https://rango.vip/i/3A8gtl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 0.4219825118930684,
    "supportedSwappers": [
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KNC",
    "name": "Kyber Network Crystal",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe4dddfe67e7164b0fe14e218d80dc4c08edc01cb",
    "decimals": 18,
    "image": "https://rango.vip/i/9FtFee",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.420978,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "KNC",
    "name": "Kyber Network Crystal",
    "isPopular": false,
    "chainId": "59144",
    "address": "0x3b2f62d42db19b30588648bf1c184865d4c3b1d6",
    "decimals": 18,
    "image": "https://rango.vip/i/iJpjPK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 0.420978,
    "supportedSwappers": [
      "LineaKyberV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "KNC",
    "name": "Kyber Network Crystal v2 (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c",
    "decimals": 18,
    "image": "https://rango.vip/i/Pb6neG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.420978,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KNC",
    "name": "Kyber Network Crystal v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202",
    "decimals": 18,
    "image": "https://rango.vip/i/dMNcEu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.420978,
    "supportedSwappers": [
      "Bridgers",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KNC",
    "name": "Kyber Network Crystal",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfe56d5892bdffc7bf58f2e84be1b2c32d21c308b",
    "decimals": 18,
    "image": "https://rango.vip/i/D3sZaM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.420978,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "KNC",
    "name": "Kyber Network Crystal v2",
    "isPopular": false,
    "chainId": "324",
    "address": "0x6ee46cb7cd2f15ee1ec9534cf29a5b51c83283e6",
    "decimals": 18,
    "image": "https://rango.vip/i/fwz2jb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.420978,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKJ",
    "name": "Polyhedra Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc71b5f631354be6853efe9c3ab6b9590f8302e81",
    "decimals": 18,
    "image": "https://rango.vip/i/Hpw2iD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.17,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STPT",
    "name": "STP",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde7d85157d9714eadf595045cc12ca4a5f3e2adb",
    "decimals": 18,
    "image": "https://rango.vip/i/mMeAhg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03878971,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STMX",
    "name": "StormX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe9375c6a420d2eeb258962efb95551a5b722803",
    "decimals": 18,
    "image": "https://rango.vip/i/wecU0o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00625142698414933,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STMX",
    "name": "StormX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa62cc35625b0c8dc1faea39d33625bb4c15bd71c",
    "decimals": 18,
    "image": "https://rango.vip/i/3bxrc1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00620547,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SDEX",
    "name": "SmarDex",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xabd587f2607542723b17f14d00d99b987c29b074",
    "decimals": 18,
    "image": "https://rango.vip/i/XZwX8m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00878551,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDEX",
    "name": "SmarDex",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5de8ab7e27f6e7a1fff3e5b337584aa43961beef",
    "decimals": 18,
    "image": "https://rango.vip/i/2vRIae",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00878551,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SPELL",
    "name": "Spell Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xce1bffbd5374dac86a2893119683f4911a2f7814",
    "decimals": 18,
    "image": "https://rango.vip/i/rHH25t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00050437,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SPELL",
    "name": "Spell Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xb37e6457c17a370b2eaeb40849d20dcaea5f7e92",
    "decimals": 18,
    "image": "https://rango.vip/i/ytt4nW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0005162211749806022,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPELL",
    "name": "Spell Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x090185f2135308bad17527004364ebcc2d37e5f6",
    "decimals": 18,
    "image": "https://rango.vip/i/sOHmAv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00050437,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SPELL",
    "name": "Spell",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3e6648c5a70a150a88bce65f4ad4d506fe15d2af",
    "decimals": 18,
    "image": "https://rango.vip/i/E8lS6p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00050437,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SPELL",
    "name": "Spell",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9fe28d11ce29e340b7124c493f59607cbab9ce48",
    "decimals": 18,
    "image": "https://rango.vip/i/QpRLn4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0005057029878249964,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DKA",
    "name": "dKargo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5dc60c4d5e75d22588fa17ffeb90a63e535efce0",
    "decimals": 18,
    "image": "https://rango.vip/i/2Z95Q8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01736227,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUL",
    "name": "Euler",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd9fcd98c322942075a5c3860693e9f4f03aae07b",
    "decimals": 18,
    "image": "https://rango.vip/i/oRFBji",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.73,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BZR",
    "name": "Bazaars",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8d96b4ab6c741a4c8679ae323a100d74f085ba8f",
    "decimals": 18,
    "image": "https://rango.vip/i/ERO8nw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.4,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYN",
    "name": "Synapse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f2d719407fdbeff09d87557abb7232601fd9f29",
    "decimals": 18,
    "image": "https://rango.vip/i/KC0e0X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.39822,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SYN",
    "name": "Synapse",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa4080f1778e69467e905b8d6f72f6e441f9e9484",
    "decimals": 18,
    "image": "https://rango.vip/i/cCXBj6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.39822,
    "supportedSwappers": [
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SYN",
    "name": "Synapse",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x080f6aed32fc474dd5717105dba5ea57268f46eb",
    "decimals": 18,
    "image": "https://rango.vip/i/1NcFTj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.39822,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SYN",
    "name": "Synapse",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x1f1e7c893855525b303f99bdf5c3c05be09ca251",
    "decimals": 18,
    "image": "https://rango.vip/i/pSoe8O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.39822,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SYN",
    "name": "SYN",
    "isPopular": false,
    "chainId": "128",
    "address": "0xc0ea346e4a6ea25a92975b268183eb2628585f19",
    "decimals": 18,
    "image": "https://rango.vip/i/FRFEs9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00034065770009175065,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REQ",
    "name": "Request",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f8221afbb33998d8584a2b05749ba73c37a938a",
    "decimals": 18,
    "image": "https://rango.vip/i/JXQ5To",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.096571,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "REQ",
    "name": "Request",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb25e20de2f2ebb4cffd4d16a55c7b395e8a94762",
    "decimals": 18,
    "image": "https://rango.vip/i/dxUmTl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.096571,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GNS",
    "name": "Gains Network",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x18c11fd286c5ec11c3b683caa813b77f5163a122",
    "decimals": 18,
    "image": "https://rango.vip/i/BryGJg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2.19,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GNS",
    "name": "Gains Network",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe5417af564e4bfda1c483642db72007871397896",
    "decimals": 18,
    "image": "https://rango.vip/i/mE553d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2.19,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORAI",
    "name": "Oraichain Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c11249814f11b9346808179cf06e71ac328c1b5",
    "decimals": 18,
    "image": "https://rango.vip/i/D8I9Z1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.27,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AITECH",
    "name": "AITECH",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2d060ef4d6bf7f9e5edde373ab735513c0e4f944",
    "decimals": 18,
    "image": "https://rango.vip/i/L1cvjB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.073384,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HT",
    "name": "HuobiToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6f259637dcd74c767781e37bc6133cd6a68aa161",
    "decimals": 18,
    "image": "https://rango.vip/i/jZWUgH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.450813,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HT",
    "name": "",
    "isPopular": true,
    "chainId": "128",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/9uAYzf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 2.7476476970200165,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "HT",
    "name": "HuobiToken",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TDyvndWuvX5xTBwHPYJi7J3Yq8pq8yh62h",
    "decimals": 18,
    "image": "https://rango.vip/i/pOJhrs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.45187680718558765,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DENT",
    "name": "Dent",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3597bfd533a99c9aa083587b074434e61eb0a258",
    "decimals": 8,
    "image": "https://rango.vip/i/0Onnfd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00071899,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "MED",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/3bccc93ad5df58d11a6f8a05fa8bc801cba0ba61a981f57e91b8b598bf8061cb",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/MED.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.007689125570634239,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ORBS",
    "name": "Orbs",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x3ab1c9adb065f3fca0059652cd7a52b05c98f9a9",
    "decimals": 18,
    "image": "https://rango.vip/i/82MS3z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.02214927,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ORBS",
    "name": "Orbs (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x614389eaae0a6821dc49062d56bda3d9d45fa2ff",
    "decimals": 18,
    "image": "https://rango.vip/i/3jlJon",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02214927,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORBS",
    "name": "Orbs",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa",
    "decimals": 18,
    "image": "https://rango.vip/i/eYIJ34",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02214927,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ORBS",
    "name": "Orbs",
    "isPopular": false,
    "chainId": "56",
    "address": "0x43a8cab15d06d3a5fe5854d714c37e7e9246f170",
    "decimals": 18,
    "image": "https://rango.vip/i/Fvhc7h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02214927,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLEX",
    "name": "FLEX Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfcf8eda095e37a41e002e266daad7efc1579bc0a",
    "decimals": 18,
    "image": "https://rango.vip/i/cm0ets",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.174269,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLEX",
    "name": "FlexMeme",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb076bda1abc154ddb4ccd9be45542a823aee290e",
    "decimals": 18,
    "image": "https://rango.vip/i/1XQjXD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.43178e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "BUSD",
    "name": "Binance-Peg BUSD Token",
    "isPopular": true,
    "chainId": "1313161554",
    "address": "0x5d9ab5522c64e1f6ef5e3627eccc093f56167818",
    "decimals": 18,
    "image": "https://rango.vip/i/FzolCb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.392069357775304,
    "supportedSwappers": [
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "BUSD",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/6329dd8cf31a334dd5be3f68c846c9fe313281362b37686a62343bac1eb1546d",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/BUSD.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.9510900019845733,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BUSD",
    "name": "BUSD Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x9c9e5fd8bbc25984b178fdce6117defa39d2db39",
    "decimals": 18,
    "image": "https://rango.vip/i/PsiPay",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.996546,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BUSD",
    "name": "Binance USD",
    "isPopular": true,
    "chainId": "56",
    "address": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    "decimals": 18,
    "image": "https://rango.vip/i/tY13sD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.996546,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "Synapse Swapper",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc",
      "stargate"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "BUSD",
    "name": "Binance USD",
    "isPopular": false,
    "chainId": "25",
    "address": "0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8",
    "decimals": 18,
    "image": "https://rango.vip/i/XLyS6L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.34341399034657166,
    "supportedSwappers": [
      "CronaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUSD",
    "name": "Binance USD",
    "isPopular": true,
    "chainId": "1",
    "address": "0x4fabb145d64652a948d72533023f6e7a623c7c53",
    "decimals": 18,
    "image": "https://rango.vip/i/WoLvsC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997947,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "BUSD",
    "name": "Binance USD",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x5d9ab5522c64e1f6ef5e3627eccc093f56167818",
    "decimals": 18,
    "image": "https://rango.vip/i/nZLaOw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.058961,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "BUSD",
    "name": "Binance-Peg BUSD Token",
    "isPopular": true,
    "chainId": "1284",
    "address": "0xa649325aa7c5093d12d6f98eb4378deae68ce23f",
    "decimals": 18,
    "image": "https://rango.vip/i/A1fGcy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.1721028803815588,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "BUSD",
    "name": "BUSD",
    "isPopular": true,
    "chainId": "66",
    "address": "0x332730a4f6e03d9c55829435f10360e13cfa41ff",
    "decimals": 18,
    "image": "https://rango.vip/i/msMGrU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.433799035304943,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BUSD",
    "name": "BUSD Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9c9e5fd8bbc25984b178fdce6117defa39d2db39",
    "decimals": 18,
    "image": "https://rango.vip/i/NyYs4n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.996546,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "BUSD",
    "name": "Binance USD",
    "isPopular": false,
    "chainId": "59144",
    "address": "0x7d43aabc515c356145049227cee54b608342c0ad",
    "decimals": 18,
    "image": "https://rango.vip/i/0LYYn6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 0.982113,
    "supportedSwappers": [
      "EchoDex"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BUSD",
    "name": "Binance USD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x31190254504622cefdfa55a7d3d272e6462629a2",
    "decimals": 18,
    "image": "https://rango.vip/i/3PTCCY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.9999680254733028,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HOOK",
    "name": "Hook Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa260e12d2b924cb899ae80bb58123ac3fee1e2f0",
    "decimals": 18,
    "image": "https://rango.vip/i/F8S7BR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.377716,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "NYM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/37cb3078432510ee57b9afa8dbe028b33ae3280a144826feac5f2334cf2c5539",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.08429099186788072,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NYM",
    "name": "Nym",
    "isPopular": false,
    "chainId": "1",
    "address": "0x525a8f6f3ba4752868cde25164382bfbae3990e1",
    "decimals": 6,
    "image": "https://rango.vip/i/CrSnvR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.085081,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BAKE",
    "name": "Bakery Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe02df9e3e622debdd69fb838bb799e3f168902c5",
    "decimals": 18,
    "image": "https://rango.vip/i/gAr1hG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.233543,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAKE",
    "name": "404 Bakery",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44face2e310e543f6d85867eb06fb251e3bfe1fc",
    "decimals": 9,
    "image": "https://rango.vip/i/BwUD0O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00057168,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEVER",
    "name": "LeverFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b5f49487ea7b3609b1ad05459be420548789f1f",
    "decimals": 18,
    "image": "https://rango.vip/i/88BvjP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00202481,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "CTK",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/7ed954cfffc06ee8419387f3fc688837ff64ef264de14219935f724eeedbf8d3",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/CTK.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.4881422248933376,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CTK",
    "name": "CertiK Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929",
    "decimals": 6,
    "image": "https://rango.vip/i/AvDOlq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.4888629211395021,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NAKA",
    "name": "Nakamoto.Games",
    "isPopular": false,
    "chainId": "137",
    "address": "0x311434160d7537be358930def317afb606c0d737",
    "decimals": 18,
    "image": "https://rango.vip/i/N8W4qP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.734507,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NAKA",
    "name": "NakaChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfae30394a76796dc3df37c2714f5fc12083dfdb0",
    "decimals": 18,
    "image": "https://rango.vip/i/a1OscT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00078821,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MPL",
    "name": "Maple Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33349b282065b0284d756f0577fb39c158f935e6",
    "decimals": 18,
    "image": "https://rango.vip/i/c5L0R0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 15.15,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MPL",
    "name": "Maple",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x29024832ec3babf5074d4f46102aa988097f0ca0",
    "decimals": 18,
    "image": "https://rango.vip/i/C9J8PX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 15.102682856099046,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PORTAL",
    "name": "Portal",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1bbe973bef3a977fc51cbed703e8ffdefe001fed",
    "decimals": 18,
    "image": "https://rango.vip/i/qfTYVh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.237116,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LQTY",
    "name": "Liquity",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d",
    "decimals": 18,
    "image": "https://rango.vip/i/HlarbT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.699698,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "LQTY",
    "name": "LQTY",
    "isPopular": false,
    "chainId": "324",
    "address": "0xf755cf4f0887279a8bcbe5e39ee062a5b7188401",
    "decimals": 18,
    "image": "https://rango.vip/i/w86o3w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.7006315779595869,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LQTY",
    "name": "Liquity",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xfb9e5d956d889d91a82737b9bfcdac1dce3e1449",
    "decimals": 18,
    "image": "https://rango.vip/i/b34GxJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.699698,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SFUND",
    "name": "SeedifyFund",
    "isPopular": false,
    "chainId": "56",
    "address": "0x477bc8d23c634c154061869478bce96be6045d12",
    "decimals": 18,
    "image": "https://rango.vip/i/5h0mf1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.046,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SFUND",
    "name": "Seedify fund",
    "isPopular": false,
    "chainId": "1",
    "address": "0x560363bda52bc6a44ca6c8c9b4a5fadbda32fa60",
    "decimals": 18,
    "image": "https://rango.vip/i/WynKaf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.046,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OXT",
    "name": "Orchid",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4575f41308ec1483f3d399aa9a2826d74da13deb",
    "decimals": 18,
    "image": "https://rango.vip/i/qtkugD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.065859,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CGPT",
    "name": "ChainGPT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9840652dc04fb9db2c43853633f0f62be6f00f98",
    "decimals": 18,
    "image": "https://rango.vip/i/c6QzKm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.115457,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CGPT",
    "name": "ChainGPT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x25931894a86d47441213199621f1f2994e1c39aa",
    "decimals": 18,
    "image": "https://rango.vip/i/1BRO0i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.115457,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POKT",
    "name": "Pocket Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x764a726d9ced0433a8d7643335919deb03a9a935",
    "decimals": 6,
    "image": "https://rango.vip/i/eLTzkw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03877958,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PORK",
    "name": "PepeFork",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9f599ce614feb2e1bbe58f180f370d05b39344e",
    "decimals": 18,
    "image": "https://rango.vip/i/EH9vFc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.53049e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SLERF",
    "name": "SLERF",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3",
    "decimals": 9,
    "image": "https://rango.vip/i/hpM8T9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.127832,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CPOOL",
    "name": "Clearpool",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66761fa41377003622aee3c7675fc7b5c1c2fac5",
    "decimals": 18,
    "image": "https://rango.vip/i/pgcRNz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.104609,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AEUR",
    "name": "Anchored Coins AEUR",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa40640458fbc27b6eefedea1e9c9e17d4cee7a21",
    "decimals": 18,
    "image": "https://rango.vip/i/KcMdze",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "XPLA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/95c9b5870f95e21a242e6af9adcb1f212ee4a8855087226c36fbe43fc41a77b8",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.093167,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANYONE",
    "name": "ANyONe Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfeac2eae96899709a43e252b6b92971d32f9c0f9",
    "decimals": 18,
    "image": "https://rango.vip/i/PYJ5iN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.709499,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIBTC",
    "name": "Shibabitcoin  OLD ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d303e548728c8c472fa23955afd2d6301db427d",
    "decimals": 20,
    "image": "https://rango.vip/i/dSjdeH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.73,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "EURC",
    "name": "EURC",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc891eb4cbdeff6e073e859e987815ed1505c2acd",
    "decimals": 6,
    "image": "https://rango.vip/i/VrUwOV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "EURC",
    "name": "EURC",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x60a3e35cc302bfa44cb288bc5a4f316fdb1adb42",
    "decimals": 6,
    "image": "https://rango.vip/i/VrUwOV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EURC",
    "name": "EURC",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HzwqbKZw8HxMN6bF2yFZNrht3c2iXXzpKcFu7uBEDKtr",
    "decimals": 6,
    "image": "https://rango.vip/i/xzSksQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "EURC",
    "name": "Euro Coin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x863708032b5c328e11abcbc0df9d79c71fc52a48",
    "decimals": 6,
    "image": "https://rango.vip/i/tDqQHv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.1103503574712394,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "CUDOS",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/e09ed39f390ec51fa9f3f69bea08b5bbe6a48b3057b2b1c3467faae9e58b021b",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00866063036416,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUDOS",
    "name": "CudosToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x817bbdbc3e8a1204f3691d14bb44992841e3db35",
    "decimals": 18,
    "image": "https://rango.vip/i/IUci8N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00880941,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HFT",
    "name": "Hashflow",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb3999f658c0391d94a37f7ff328f3fec942bcadc",
    "decimals": 18,
    "image": "https://rango.vip/i/6Vkxqz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.132558,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HFT",
    "name": "Hashflow",
    "isPopular": false,
    "chainId": "56",
    "address": "0x44ec807ce2f4a6f2737a92e985f318d035883e47",
    "decimals": 18,
    "image": "https://rango.vip/i/VJiE0y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.132558,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LISTA",
    "name": "Lista DAO",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfceb31a79f71ac9cbdcf853519c1b12d379edc46",
    "decimals": 18,
    "image": "https://rango.vip/i/ryI5GK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.318861,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UDS",
    "name": "Undeads Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x712bd4beb54c6b958267d9db0259abdbb0bff606",
    "decimals": 18,
    "image": "https://rango.vip/i/Lm6zsn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.33,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "QKC",
    "name": "QuarkChain Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa1434f1fc3f437fa33f7a781e041961c0205b5da",
    "decimals": 18,
    "image": "https://rango.vip/i/9pboJX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.008228145931506505,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QKC",
    "name": "QuarkChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664",
    "decimals": 18,
    "image": "https://rango.vip/i/xSAL3a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00814736,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "XYO",
    "name": "XY Oracle",
    "isPopular": false,
    "chainId": "25",
    "address": "0x211153266f15f9314b214a7dd614d90f850a8d6a",
    "decimals": 18,
    "image": "https://rango.vip/i/zKUNNL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.004268643323913273,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XYO",
    "name": "XYO Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x55296f69f40ea6d20e478533c15a6b08b654e758",
    "decimals": 18,
    "image": "https://rango.vip/i/q70ABE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00427511,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "XYO",
    "name": "XY Oracle",
    "isPopular": false,
    "chainId": "10",
    "address": "0x9db118d43069b73b8a252bf0be49d50edbd81fc8",
    "decimals": 18,
    "image": "https://rango.vip/i/JMTDTg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.0042752730497625565,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "LMWR",
    "name": "LimeWire",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xe997017e0cb0ceb503565f181e9ea922cd979c35",
    "decimals": 18,
    "image": "https://rango.vip/i/6FefGW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.197088,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LMWR",
    "name": "LimeWire",
    "isPopular": false,
    "chainId": "1",
    "address": "0x628a3b2e302c7e896acc432d2d0dd22b6cb9bc88",
    "decimals": 18,
    "image": "https://rango.vip/i/thwnq7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.197088,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "PNG",
    "name": "Pangolin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x60781c2586d68229fde47564546784ab3faca982",
    "decimals": 18,
    "image": "https://rango.vip/i/7iTnJp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.260155,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "Bridgers"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "FX",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/2b30802a0b03f91e4e16d6175c9b70f2911377c1cae9e50ff011c821465463f9",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.13695964728064,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FX",
    "name": "Function X",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c15ef5b4b21951d50e53e4fbda8298ffad25057",
    "decimals": 18,
    "image": "https://rango.vip/i/MzjJcI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.13662,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTL",
    "name": "Metal",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf433089366899d83a9f26a773d59ec7ecf30355e",
    "decimals": 8,
    "image": "https://rango.vip/i/OsuHo4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.843036,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STRD",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/a8ca5ee328fa10c9519df6057da1f69682d28f7d0f5ccc7ecb72e3dca2d157a4",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/STRD.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.640584,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "STRIDE",
    "symbol": "STRD",
    "name": null,
    "isPopular": true,
    "chainId": "stride-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/gqRnUY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STRIDE/icon.svg",
    "usdPrice": 0.6374522412456275,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REZ",
    "name": "Renzo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b50805453023a91a8bf641e279401a0b23fa6f9",
    "decimals": 18,
    "image": "https://rango.vip/i/uWltYI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03433342,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BNT",
    "name": "Bancor Network Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7a24159672b83ed1b89467c9d6a99556ba06d073",
    "decimals": 18,
    "image": "https://rango.vip/i/hVdxdC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.449694070344756,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WILD",
    "name": "Wilder",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a3bff78b79a009976eea096a51a948a3dc00e34",
    "decimals": 18,
    "image": "https://rango.vip/i/718iUa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.212707,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "SCRT",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/0954e1c28eb7af5b72d24f3bc2b47bbb2fdf91bddfd57b74b99e133aed40972a",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/SCRT.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.191376000162432,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "SECRET",
    "symbol": "SCRT",
    "name": null,
    "isPopular": true,
    "chainId": "secret-4",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/Z8Avyk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SECRET/icon.svg",
    "usdPrice": 0.1915633460834775,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STG",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6",
    "decimals": 18,
    "image": "https://rango.vip/i/XyeZYH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.268789,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "stargate",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "STG",
    "name": null,
    "isPopular": false,
    "chainId": "56",
    "address": "0xb0d502e938ed5f4df2e681fe6e419ff29631d62b",
    "decimals": 18,
    "image": "https://rango.vip/i/JRZdF5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.268789,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "stargate"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "STG",
    "name": null,
    "isPopular": false,
    "chainId": "43114",
    "address": "0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590",
    "decimals": 18,
    "image": "https://rango.vip/i/vGCQ8Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.268789,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "stargate"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "STG",
    "name": null,
    "isPopular": false,
    "chainId": "137",
    "address": "0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590",
    "decimals": 18,
    "image": "https://rango.vip/i/MWU2Ul",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.268789,
    "supportedSwappers": [
      "PolygonUniSwapV3",
      "stargate",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "STG",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6694340fc020c5e6b96567843da2df01b2ce1eb6",
    "decimals": 18,
    "image": "https://rango.vip/i/JWk9j0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.268789,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "stargate",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "STG",
    "name": null,
    "isPopular": false,
    "chainId": "10",
    "address": "0x296f55f8fb28e498b858d0bcda06d955b2cb3f97",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/STG.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.268789,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "stargate"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "STG",
    "name": null,
    "isPopular": false,
    "chainId": "8453",
    "address": "0xe3b53af74a4bf62ae5511055290838050bf764df",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/STG.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.268789,
    "supportedSwappers": [
      "stargate"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "STG",
    "name": "StargateToken",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe018c7a3d175fb0fe15d70da2c874d3ca16313ec",
    "decimals": 18,
    "image": "https://rango.vip/i/eqKr6c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.26946172866667584,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "VELO",
    "name": "VelodromeV2",
    "isPopular": false,
    "chainId": "10",
    "address": "0x9560e827af36c94d2ac33a39bce1fe78631088db",
    "decimals": 18,
    "image": "https://rango.vip/i/ixUZvB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.069195,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "VELO",
    "name": "Velodrome",
    "isPopular": false,
    "chainId": "10",
    "address": "0x3c8b650257cfb5f272f799f5e2b4e65093a11a05",
    "decimals": 18,
    "image": "https://rango.vip/i/FFYIZs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.01666497318218049,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIONE",
    "name": "Dione",
    "isPopular": false,
    "chainId": "1",
    "address": "0x89b69f2d1adffa9a253d40840b6baa7fc903d697",
    "decimals": 9,
    "image": "https://rango.vip/i/tdAQbY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0056624,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIFI",
    "name": "Hifi Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b9278b94a1112cad404048903b8d343a810b07e",
    "decimals": 18,
    "image": "https://rango.vip/i/3OV9cE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.400127,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GPU",
    "name": "NodeAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1258d60b224c0c5cd888d37bbf31aa5fcfb7e870",
    "decimals": 18,
    "image": "https://rango.vip/i/spwxxM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.578205,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOOM",
    "name": "LoomToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0",
    "decimals": 18,
    "image": "https://rango.vip/i/oQqNIj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04188706,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOOM",
    "name": "Loom Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42476f744292107e34519f9c357927074ea3f75d",
    "decimals": 18,
    "image": "https://rango.vip/i/PjQS4K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04190452,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFP",
    "name": "New Frontier Presents",
    "isPopular": false,
    "chainId": "1",
    "address": "0x299698b4b44bd6d023981a7317798dee12860834",
    "decimals": 9,
    "image": "https://rango.vip/i/4zCl24",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.18465885285870476,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NFP",
    "name": "NFPrompt Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x75e8ddb518bb757b4282cd5b83bb70d4101d12fb",
    "decimals": 18,
    "image": "https://rango.vip/i/tnuvEr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.18465885285870476,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "AURORA",
    "name": "Aurora",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x8bec47865ade3b172a928df8f990bc7f2a3b9f79",
    "decimals": 18,
    "image": "https://rango.vip/i/BusuPh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.105681,
    "supportedSwappers": [
      "TrisolarisSwap",
      "Rainbow Bridge",
      "OpenOceanAurora",
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AURORA",
    "name": "Aurora",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaaaaaa20d9e0e2461697782ef11675f668207961",
    "decimals": 18,
    "image": "https://rango.vip/i/BLHxG6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.105681,
    "supportedSwappers": [
      "Rainbow Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "AURORA",
    "name": "Aurora",
    "isPopular": false,
    "chainId": "25",
    "address": "0xb4f1d9a65816da7e112f11c9a2900b8c5beed525",
    "decimals": 18,
    "image": "https://rango.vip/i/Chqk0U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.10627052875615185,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AURORA",
    "name": "AURORA",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0c8c8ae8bc3a69dc8482c01ceacfb588bb516b01",
    "decimals": 18,
    "image": "https://rango.vip/i/pwA4kf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00106406,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALEX",
    "name": "ALEX Lab",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa831a4e181f25d3b35949e582ff27cc44e703f37",
    "decimals": 18,
    "image": "https://rango.vip/i/596h5u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.088119,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "AGLD",
    "name": "Adventure Gold",
    "isPopular": false,
    "chainId": "25",
    "address": "0xd3bb5c6961157eb4eb03658dc5d4144808828168",
    "decimals": 18,
    "image": "https://rango.vip/i/KVAGvj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.6965433041192028,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGLD",
    "name": "Adventure Gold",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32353a6c91143bfd6c7d363b546e62a9a2489a20",
    "decimals": 18,
    "image": "https://rango.vip/i/iXopDI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.688419,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AGLD",
    "name": "Adventure Gold",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb7910e8b16e63efd51d5d1a093d56280012a3b9c",
    "decimals": 18,
    "image": "https://rango.vip/i/PIoib3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.6909058115030066,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MBOX",
    "name": "Mobox",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377",
    "decimals": 18,
    "image": "https://rango.vip/i/s26CVT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.135316,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AL",
    "name": "ArchLoot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x046bad07658f3b6cad9a396cfcbc1243af452ec1",
    "decimals": 18,
    "image": "https://rango.vip/i/4ktiGJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.107565,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWEAT",
    "name": "SWEAT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb4b9dc1c77bdbb135ea907fd5a08094d98883a35",
    "decimals": 18,
    "image": "https://rango.vip/i/I9ZBVK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.007602,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ACS",
    "name": "Access Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5MAYDfq5yxtudAhtfyuMBuHZjgAbaS9tbEyEQYAhDS5y",
    "decimals": 6,
    "image": "https://rango.vip/i/AtjNoN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00147661,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ACS",
    "name": "ACryptoS",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4197c6ef3879a08cd51e5560da5064b773aa1d29",
    "decimals": 18,
    "image": "https://rango.vip/i/pyHQul",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.240678,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOCUS",
    "name": "Locus Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc64500dd7b0f1794807e67802f8abbf5f8ffb054",
    "decimals": 18,
    "image": "https://rango.vip/i/V5lwBJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02521881,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAD",
    "name": "Radicle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31c8eacbffdd875c74b94b077895bd78cf1e64a3",
    "decimals": 18,
    "image": "https://rango.vip/i/FHQ1OD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.006,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RAD",
    "name": "RAD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "B6aJ3TGfme3SMnLSouHXqWXjVFqYyqj7czzhzr8WJFAi",
    "decimals": 4,
    "image": "https://rango.vip/i/PkMzIf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00022919,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BADGER",
    "name": "Badger DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3472a5a71965499acd81997a54bba8d852c6e53d",
    "decimals": 18,
    "image": "https://rango.vip/i/GSpjV3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.54,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BADGER",
    "name": "Badger",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xbfa641051ba0a0ad1b0acf549a89536a0d76472e",
    "decimals": 18,
    "image": "https://rango.vip/i/C2B0SO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2.54,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CET",
    "name": "CoinEx Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x081f67afa0ccf8c7b17540767bbe95df2ba8d97f",
    "decimals": 18,
    "image": "https://rango.vip/i/mXdUeJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.072875,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CET",
    "name": "Carbon Earth Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x430bf5e78c1a2f2644d93235e2f0269407b7eb79",
    "decimals": 18,
    "image": "https://rango.vip/i/5APJQ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00032986,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ARPA",
    "name": "ARPA Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xee800b277a96b0f490a1a732e1d6395fad960a26",
    "decimals": 18,
    "image": "https://rango.vip/i/F99IyV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03511244,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARPA",
    "name": "ARPA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba50933c268f567bdc86e1ac131be072c6b0b71a",
    "decimals": 18,
    "image": "https://rango.vip/i/Dlk8ZW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03511244,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ARPA",
    "name": "ARPA",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6f769e65c14ebd1f68817f5f1dcdb61cfa2d6f7e",
    "decimals": 18,
    "image": "https://rango.vip/i/JLsdW2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03511244,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ARPA",
    "name": "ARPA Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x334cc734866e97d8452ae6261d68fd9bc9bfa31e",
    "decimals": 18,
    "image": "https://rango.vip/i/rFvlyE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.03519369703877031,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "FORT",
    "name": "FortuneDAO",
    "isPopular": false,
    "chainId": "25",
    "address": "0xca558149225fb386b9c26716e8c35a650c74d35e",
    "decimals": 9,
    "image": "https://rango.vip/i/uTFXFW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 3.9258006736421334,
    "supportedSwappers": [
      "CronaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FORT",
    "name": "Forta",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41545f8b9472d758bb669ed8eaeeecd7a9c4ec29",
    "decimals": 18,
    "image": "https://rango.vip/i/KlnsFY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.120461,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FORT",
    "name": "Forta",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3a1429d50e0cbbc45c997af600541fe1cc3d2923",
    "decimals": 18,
    "image": "https://rango.vip/i/XS2v9j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.12047675983145154,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GODS",
    "name": "Gods Unchained",
    "isPopular": false,
    "chainId": "1",
    "address": "0xccc8cb5229b0ac8069c51fd58367fd1e622afd97",
    "decimals": 18,
    "image": "https://rango.vip/i/LBR36T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.160517,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MBX",
    "name": "MARBLEX on BNB",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf95a5532d67c944dfa7eddd2f8c358fe0dc7fac2",
    "decimals": 18,
    "image": "https://rango.vip/i/88FXoX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.371294,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MBX",
    "name": "MetaBlox",
    "isPopular": false,
    "chainId": "1",
    "address": "0x144805be43c48ef85435c94e0da4cb4efb1ab4f3",
    "decimals": 18,
    "image": "https://rango.vip/i/LFlMOb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00469956,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NKN",
    "name": "NKN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5cf04716ba20127f1e2297addcf4b5035000c9eb",
    "decimals": 18,
    "image": "https://rango.vip/i/2GDAcz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.063604,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NKN",
    "name": "NKN",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xbe06ca305a5cb49abf6b1840da7c42690406177b",
    "decimals": 18,
    "image": "https://rango.vip/i/QnwIu2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0637819317791682,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "BLZ",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/63cdd51098fd99e04e5f5610a3882cbe7614c441607ba6fcd7f3a3c1cd5325f8",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/BLZ.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.06711509414004439,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BLZ",
    "name": "BLZ",
    "isPopular": false,
    "chainId": "56",
    "address": "0x935a544bf5816e3a7c13db2efe3009ffda0acda2",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/BLZ.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.113311,
    "supportedSwappers": [
      "BSCPancakeV3",
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLZ",
    "name": "Bluzelle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5732046a883704404f284ce41ffadd5b007fd668",
    "decimals": 18,
    "image": "https://rango.vip/i/Aw7CzO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.113311,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "KUJIRA",
    "symbol": "KUJI",
    "name": null,
    "isPopular": true,
    "chainId": "kaiyo-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/KUJI.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/KUJIRA/icon.svg",
    "usdPrice": 0.40194627100435665,
    "supportedSwappers": [
      "MayaProtocol",
      "IBC",
      "FinKujira"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "KUJI",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/bb6bcdb515050bae97516111873ccd7bcf1fd0ccb723cc12f3c4f704d6c646ce",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/KUJI.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.401951,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KUJI",
    "name": "Kujira native asset",
    "isPopular": false,
    "chainId": "56",
    "address": "0x073690e6ce25be816e68f32dca3e11067c9fb5cc",
    "decimals": 6,
    "image": "https://rango.vip/i/z14OET",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.401951,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KUJI",
    "name": "Kujira",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3a18dcc9745edcd1ef33ecb93b0b6eba5671e7ca",
    "decimals": 6,
    "image": "https://rango.vip/i/cmvojs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.401951,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "OGN",
    "name": "Origin Protocol",
    "isPopular": false,
    "chainId": "25",
    "address": "0x78e9974a74d6c980de4e3f8039248320c5a2d714",
    "decimals": 18,
    "image": "https://rango.vip/i/HgebWL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.08117976922983154,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OGN",
    "name": "Origin Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8207c1ffc5b6804f6024322ccf34f29c3541ae26",
    "decimals": 18,
    "image": "https://rango.vip/i/CjzZ6m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.080413,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OGN",
    "name": "Origin Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6feb262feb0f775b5312d2e009923f7f58ae423e",
    "decimals": 18,
    "image": "https://rango.vip/i/Qqrky4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.08057579028651206,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TKO",
    "name": "Tokocrypto Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9f589e3eabe42ebc94a44727b3f3531c0c877809",
    "decimals": 18,
    "image": "https://rango.vip/i/2eQuyJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.287786,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TET",
    "name": "Tectum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68a47fe1cf42eba4a030a10cd4d6a1031ca3ca0a",
    "decimals": 8,
    "image": "https://rango.vip/i/BG7jKC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.57,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RACA",
    "name": "Radio Caca V2",
    "isPopular": false,
    "chainId": "56",
    "address": "0x12bb890508c125661e03b09ec06e404bc9289040",
    "decimals": 18,
    "image": "https://rango.vip/i/UeOJdS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00013004,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RACA",
    "name": "Radio Caca V2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12bb890508c125661e03b09ec06e404bc9289040",
    "decimals": 18,
    "image": "https://rango.vip/i/cpEAhO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013004,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHFL",
    "name": "Shuffle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8881562783028f5c1bcb985d2283d5e170d88888",
    "decimals": 18,
    "image": "https://rango.vip/i/MqNBHa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.335146,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOKEN",
    "name": "TokenFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4507cef57c46789ef8d1a19ea45f4216bae2b528",
    "decimals": 9,
    "image": "https://rango.vip/i/nQ7PjW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04701681,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TOKEN",
    "name": "TokenFi",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4507cef57c46789ef8d1a19ea45f4216bae2b528",
    "decimals": 9,
    "image": "https://rango.vip/i/ZMQqBH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.04701681,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "VCNT",
    "name": "ViciCoin",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc6bdfc4f2e90196738873e824a9efa03f7c64176",
    "decimals": 18,
    "image": "https://rango.vip/i/kFlbmo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 20.7,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "VCNT",
    "name": "ViciCoin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc6bdfc4f2e90196738873e824a9efa03f7c64176",
    "decimals": 18,
    "image": "https://rango.vip/i/kFlbmo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 20.7,
    "supportedSwappers": [
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "VCNT",
    "name": "ViciCoin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x60bf4e7cf16ff34513514b968483b54beff42a81",
    "decimals": 18,
    "image": "https://rango.vip/i/kFlbmo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 20.7,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "VCNT",
    "name": "ViciCoin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xdcf5130274753c8050ab061b1a1dcbf583f5bfd0",
    "decimals": 18,
    "image": "https://rango.vip/i/kFlbmo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 20.7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UQC",
    "name": "Uquid Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8806926ab68eb5a7b909dcaf6fdbe5d93271d6e2",
    "decimals": 18,
    "image": "https://rango.vip/i/NMHvGc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.41,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WRX",
    "name": "WazirX",
    "isPopular": false,
    "chainId": "137",
    "address": "0x72d6066f486bd0052eefb9114b66ae40e0a6031a",
    "decimals": 8,
    "image": "https://rango.vip/i/byqnJA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.121881,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TLM",
    "name": "Alien Worlds",
    "isPopular": false,
    "chainId": "1",
    "address": "0x888888848b652b3e3a0f34c96e00eec0f3a23f72",
    "decimals": 4,
    "image": "https://rango.vip/i/Z8DbY8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01000715,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TLM",
    "name": "Alien Worlds Trilium",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2222227e22102fe3322098e4cbfe18cfebd57c95",
    "decimals": 4,
    "image": "https://rango.vip/i/dgxceD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01000715,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZCX",
    "name": "ZEN Exchange Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc52c326331e9ce41f04484d3b5e5648158028804",
    "decimals": 18,
    "image": "https://rango.vip/i/E4nfNp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.066085,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KIN",
    "name": "KIN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6",
    "decimals": 5,
    "image": "https://rango.vip/i/NlLxDi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001656,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GHST",
    "name": "Aavegotchi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f382dbd960e3a9bbceae22651e88158d2791550",
    "decimals": 18,
    "image": "https://rango.vip/i/NSUDQo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.838779,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GHST",
    "name": "Aavegotchi GHST Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7",
    "decimals": 18,
    "image": "https://rango.vip/i/HR9txr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.838779,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LTO",
    "name": "LTO Network",
    "isPopular": false,
    "chainId": "56",
    "address": "0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd",
    "decimals": 18,
    "image": "https://rango.vip/i/aborEF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.101222,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LTO",
    "name": "LTO Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd01409314acb3b245cea9500ece3f6fd4d70ea30",
    "decimals": 8,
    "image": "https://rango.vip/i/KGanfK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.101222,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAPO",
    "name": "MAP Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66d79b8f60ec93bfce0b56f5ac14a2714e509a99",
    "decimals": 18,
    "image": "https://rango.vip/i/EWbpG1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00900022,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MBL",
    "name": "MovieBloc",
    "isPopular": false,
    "chainId": "56",
    "address": "0x18e37f96628db3037d633fe4d469fb1933a63c5b",
    "decimals": 18,
    "image": "https://rango.vip/i/XoepX7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0024084202965710916,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDL",
    "name": "Lift Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbdc7c08592ee4aa51d06c27ee23d5087d65adbcd",
    "decimals": 18,
    "image": "https://rango.vip/i/CLxuos",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999557,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GHX",
    "name": "GamerCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x728f30fa2f100742c7949d1961804fa8e0b1387d",
    "decimals": 18,
    "image": "https://rango.vip/i/OFdebR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.064925,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CBK",
    "name": "Cobak Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd85a6ae55a7f33b0ee113c234d2ee308edeaf7fd",
    "decimals": 18,
    "image": "https://rango.vip/i/rKQOKH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.478009,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CBK",
    "name": "Coinback",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e19067cbeb38d6554d31a1a83aefc4018a1688a",
    "decimals": 18,
    "image": "https://rango.vip/i/J3X9pN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02247618,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCTA",
    "name": "OctaSpace",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa704148d516b209d52c2d75f239274c8f8eaf1a",
    "decimals": 18,
    "image": "https://rango.vip/i/eXYgOP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.29,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "OCTA",
    "name": "Octans",
    "isPopular": false,
    "chainId": "56",
    "address": "0x86c3e4ffacdb3af628ef985a518cd6ee22a22b28",
    "decimals": 9,
    "image": "https://rango.vip/i/Sc5XhL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.2930454604647692,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NULS",
    "name": "Nuls",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8cd6e29d3686d24d3c2018cee54621ea0f89313b",
    "decimals": 8,
    "image": "https://rango.vip/i/DzF4j9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.3646776197575257,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "NULS",
    "name": "Heco NULS Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0xd938e45680da19ad36646ae8d4c671b2b1270f39",
    "decimals": 8,
    "image": "https://rango.vip/i/q46RGt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 2.491399973302227,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DATA",
    "name": "Streamr",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3a9a81d576d83ff21f26f325066054540720fc34",
    "decimals": 18,
    "image": "https://rango.vip/i/ENuYq4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03782305,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DATA",
    "name": "DATA Economy Index (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1d607faa0a51518a7728580c238d912747e71f7a",
    "decimals": 18,
    "image": "https://rango.vip/i/L7JDkw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03795485054244707,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DATA",
    "name": "Streamr",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f693ca8d21b157107184d29d398a8d082b38b76",
    "decimals": 18,
    "image": "https://rango.vip/i/u267CV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03782305,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DATA",
    "name": "Data bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb551b43af192965f74e3dfaa476c890b403cad95",
    "decimals": 9,
    "image": "https://rango.vip/i/LGJ1w6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00070413,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DATA",
    "name": "DATA Economy Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33d63ba1e57e54779f7ddaeaa7109349344cf5f1",
    "decimals": 18,
    "image": "https://rango.vip/i/CVg0ga",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03795485054244707,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DATA",
    "name": "Streamr",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0864c156b3c5f69824564dec60c629ae6401bf2a",
    "decimals": 18,
    "image": "https://rango.vip/i/ENbVz5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03782305,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAMP",
    "name": "RAMP DEFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f",
    "decimals": 18,
    "image": "https://rango.vip/i/NpEgYT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.083211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RAMP",
    "name": "RAMP DEFI",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8519ea49c997f50ceffa444d240fb655e89248aa",
    "decimals": 18,
    "image": "https://rango.vip/i/4oJMt3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.083211,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ATA",
    "name": "Automata",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa2120b9e674d3fc3875f415a7df52e382f141225",
    "decimals": 18,
    "image": "https://rango.vip/i/bIdT98",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.073761,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATA",
    "name": "Automata",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2120b9e674d3fc3875f415a7df52e382f141225",
    "decimals": 18,
    "image": "https://rango.vip/i/p55QuC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.073761,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RARI",
    "name": "Rarible",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfca59cd816ab1ead66534d82bc21e7515ce441cf",
    "decimals": 18,
    "image": "https://rango.vip/i/C0w8mA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.65,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RARI",
    "name": "Rarible",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xcf8600347dc375c5f2fdd6dab9bb66e0b6773cd7",
    "decimals": 18,
    "image": "https://rango.vip/i/eaDmou",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.6563481155973292,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MLN",
    "name": "Melon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xec67005c4e498ec7f55e092bd1d35cbc47c91892",
    "decimals": 18,
    "image": "https://rango.vip/i/Hy8fcs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 14.81,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HASHAI",
    "name": "HashAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x292fcdd1b104de5a00250febba9bc6a5092a0076",
    "decimals": 18,
    "image": "https://rango.vip/i/YEj5ns",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0004628,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HYPC",
    "name": "HyperCycle",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea7b7dc089c9a4a916b5a7a37617f59fd54e37e4",
    "decimals": 6,
    "image": "https://rango.vip/i/B9S2Cz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.249648,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RBN",
    "name": "Ribbon Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6123b0049f904d730db3c36a31167d9d4121fa6b",
    "decimals": 18,
    "image": "https://rango.vip/i/V8xF4a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.308185,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TNSR",
    "name": "Tensor",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "TNSRxcUxoT9xBG3de7PiJyTDYu7kskLqcpddxnEJAS6",
    "decimals": 9,
    "image": "https://rango.vip/i/U3o2N4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.309839,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACX",
    "name": "Across Protocol Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44108f0223a3c3028f5fe7aec7f9bb2e66bef82f",
    "decimals": 18,
    "image": "https://rango.vip/i/slgmcb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.280124,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ACX",
    "name": "Across Protocol Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf328b73b6c685831f238c30a23fc19140cb4d8fc",
    "decimals": 18,
    "image": "https://rango.vip/i/cFwoyP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.280124,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ACX",
    "name": "Across Protocol Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x53691596d1bce8cea565b84d4915e69e03d9c99d",
    "decimals": 18,
    "image": "https://rango.vip/i/slgmcb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.280124,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ACX",
    "name": "Across Protocol Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xff733b2a3557a7ed6697007ab5d11b79fdd1b76b",
    "decimals": 18,
    "image": "https://rango.vip/i/REjipe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.280124,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AIDOGE",
    "name": "AIDOGE",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x09e18590e8f76b6cf471b3cd75fe1a1a9d2b2c2b",
    "decimals": 6,
    "image": "https://rango.vip/i/8XlCu8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2.1901e-10,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAVIA",
    "name": "Heroes of Mavia",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24fcfc492c1393274b6bcd568ac9e225bec93584",
    "decimals": 18,
    "image": "https://rango.vip/i/si0kSV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CLV",
    "name": "Clover Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x09e889bb4d5b474f561db0491c38702f367a4e4d",
    "decimals": 18,
    "image": "https://rango.vip/i/q7vFhO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03802309,
    "supportedSwappers": [
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLV",
    "name": "Clover",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80c62fe4487e1351b47ba49809ebd60ed085bf52",
    "decimals": 18,
    "image": "https://rango.vip/i/RJC9LQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03802309,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MANEKI",
    "name": "MANEKI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "25hAyBQfoDhfWx9ay6rarbgvWGwDdNqcHsXS3jQ3mTDJ",
    "decimals": 5,
    "image": "https://rango.vip/i/dcaKOc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00451936,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FORTH",
    "name": "Ampleforth Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77fba179c79de5b7653f68b5039af940ada60ce0",
    "decimals": 18,
    "image": "https://rango.vip/i/zH6Tq4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.67,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DPI",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b",
    "decimals": 18,
    "image": "https://rango.vip/i/d5y4IT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 68.76,
    "supportedSwappers": [
      "ThorChain",
      "EthereumUniswapV3",
      "ThorChainStreamingSwap",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DPI",
    "name": "DefiPulse Index",
    "isPopular": false,
    "chainId": "137",
    "address": "0x85955046df4668e1dd369d2de9f3aeb98dd2a369",
    "decimals": 18,
    "image": "https://rango.vip/i/x0G1c9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 68.76,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEXT",
    "name": "DEXTools",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb7b4564402e5500db5bb6d63ae671302777c75a",
    "decimals": 18,
    "image": "https://rango.vip/i/QYG1LO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.436963,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AERGO",
    "name": "Aergo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x91af0fbb28aba7e31403cb457106ce79397fd4e6",
    "decimals": 18,
    "image": "https://rango.vip/i/qYogot",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.083217,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BWB",
    "name": "Bitget Wallet Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x619c4bbbd65f836b78b36cbe781513861d57f39d",
    "decimals": 18,
    "image": "https://rango.vip/i/6pR4d3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.274741,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BWB",
    "name": "Bitget Wallet Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6FVyLVhQsShWVUsCq2FJRr1MrECGShc3QxBwWtgiVFwK",
    "decimals": 8,
    "image": "https://rango.vip/i/e8cFAa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.274741,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BWB",
    "name": "Bitget Wallet Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac6db8954b73ebf10e84278ac8b9b22a781615d9",
    "decimals": 18,
    "image": "https://rango.vip/i/6pR4d3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.274741,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOOKS",
    "name": "LooksRare Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf4d2888d29d722226fafa5d9b24f9164c092421e",
    "decimals": 18,
    "image": "https://rango.vip/i/ikXVkL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03674164,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LOOKS",
    "name": "LooksRare",
    "isPopular": false,
    "chainId": "56",
    "address": "0x590d11c0696b0023176f5d7587d6b2f502a08047",
    "decimals": 18,
    "image": "https://rango.vip/i/2uFZP6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.036890274502130976,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIA",
    "name": "DIAToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419",
    "decimals": 18,
    "image": "https://rango.vip/i/dUifaz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.331628,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DIA",
    "name": "DIA",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xca642467c6ebe58c13cb4a7091317f34e17ac05e",
    "decimals": 18,
    "image": "https://rango.vip/i/QPyUCd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.3327326561860729,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDEX",
    "name": "IDEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb705268213d593b8fd88d3fdeff93aff5cbdcfae",
    "decimals": 18,
    "image": "https://rango.vip/i/oZmzM6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04534432,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOKA",
    "name": "League of Kingdoms",
    "isPopular": false,
    "chainId": "1",
    "address": "0x61e90a50137e1f645c9ef4a0d3a4f01477738406",
    "decimals": 18,
    "image": "https://rango.vip/i/mVhnBA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.204665,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELA",
    "name": "Elastos",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe6fd75ff38adca4b97fbcd938c86b98772431867",
    "decimals": 18,
    "image": "https://rango.vip/i/k0E2kp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.62,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "ELA",
    "name": "ELA",
    "isPopular": false,
    "chainId": "128",
    "address": "0xa1ecfc2bec06e4b43ddd423b94fef84d0dbc8f5c",
    "decimals": 18,
    "image": "https://rango.vip/i/shD2U0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1.62,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ATR",
    "name": "Artrade Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ATRLuHph8dxnPny4WSNW7fxkhbeivBrtWbY6BfB4xpLj",
    "decimals": 9,
    "image": "https://rango.vip/i/7oDiHH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0209258,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATR",
    "name": "Aternos Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf6aeaf0fe66cf2ef2e738ba465fb531ffe39b4e2",
    "decimals": 9,
    "image": "https://rango.vip/i/GAWopG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01205924,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DNT",
    "name": "district0x",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0abdace70d3790235af448c88547603b945604ea",
    "decimals": 18,
    "image": "https://rango.vip/i/OxRwQe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03569103,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DNT",
    "name": "district0x",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe3696a02b2c9557639e29d829e9c45efa49ad47a",
    "decimals": 18,
    "image": "https://rango.vip/i/HO3klV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.03583199417458291,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIDT",
    "name": "VIDT DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3be7bf1a5f23bd8336787d0289b70602f1940875",
    "decimals": 18,
    "image": "https://rango.vip/i/xBrOfC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04241855,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORN",
    "name": "Orion Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a",
    "decimals": 8,
    "image": "https://rango.vip/i/OZ6C0F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.034,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ORN",
    "name": "Orion Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1bdcc2075d5370293e248cab0173ec3e551e6218",
    "decimals": 8,
    "image": "https://rango.vip/i/3jKzLQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.0362306241322725,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PERP",
    "name": "Perpetual Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc396689893d065f41bc2c6ecbee5e0085233447",
    "decimals": 18,
    "image": "https://rango.vip/i/WC0Nxq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.534221,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "PERP",
    "name": "Perpetual Protocol",
    "isPopular": false,
    "chainId": "10",
    "address": "0x9e1028f5f1d5ede59748ffcee5532509976840e0",
    "decimals": 18,
    "image": "https://rango.vip/i/zUAU9E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.534221,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PERP",
    "name": "Perpetual Protocol",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4e7f408be2d4e9d60f49a64b89bb619c84c7c6f5",
    "decimals": 18,
    "image": "https://rango.vip/i/dR0l04",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.534221,
    "supportedSwappers": [
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PERP",
    "name": "Perpetual",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x753d224bcf9aafacd81558c32341416df61d3dac",
    "decimals": 18,
    "image": "https://rango.vip/i/KbY1VJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.534221,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PERP",
    "name": "PerpeTraders",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EBQ6gWBQNxA2zB4twR5GWP6CkeAhqZZZeDgeP7BTtdM3",
    "decimals": 2,
    "image": "https://rango.vip/i/ffqFPP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.5350119224604346,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "PERP",
    "name": "Perpetual",
    "isPopular": false,
    "chainId": "324",
    "address": "0x42c1c56be243c250ab24d2ecdcc77f9ccaa59601",
    "decimals": 18,
    "image": "https://rango.vip/i/XWt05D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.5350119224604346,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "REN",
    "name": "Ren",
    "isPopular": false,
    "chainId": "25",
    "address": "0x2b4ee166a125e01aba019885932c944cefed2932",
    "decimals": 18,
    "image": "https://rango.vip/i/Xh1tzN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.07125555323906194,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REN",
    "name": "Republic Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x408e41876cccdc0f92210600ef50372656052a38",
    "decimals": 18,
    "image": "https://rango.vip/i/bMR0BV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03507172,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "REN",
    "name": "Republic Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9fa891e1db0a6d1eeac4b929b5aae1011c79a204",
    "decimals": 18,
    "image": "https://rango.vip/i/bMR0BV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.03518572572286834,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "XPRT",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/a0cc0cf735bfb30e730c70019d4218a1244ff383503ff7579c9201ab93ca9293",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/XPRT.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.18639,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XPRT",
    "name": "Persistence One",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd454b59f16d42667be2fa55292d16647e27f40c4",
    "decimals": 6,
    "image": "https://rango.vip/i/u7xA2s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.18639,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "PERSISTENCE",
    "symbol": "XPRT",
    "name": null,
    "isPopular": true,
    "chainId": "core-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/w8HttS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/PERSISTENCE/icon.svg",
    "usdPrice": 0.185483278555993,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "DYDX",
    "symbol": "XPRT",
    "name": null,
    "isPopular": false,
    "chainId": "dydx-mainnet-1",
    "address": "ibc/a0cc0cf735bfb30e730c70019d4218a1244ff383503ff7579c9201ab93ca9293",
    "decimals": 6,
    "image": "https://rango.vip/i/w8HttS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/DYDX/icon.svg",
    "usdPrice": 0.185483278555993,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WHITE",
    "name": "Whiteheart Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44",
    "decimals": 18,
    "image": "https://rango.vip/i/HvgJQH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4234.83,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIMO",
    "name": "DIMO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5fab9761d60419c9eeebe3915a8fa1ed7e8d2e1b",
    "decimals": 18,
    "image": "https://rango.vip/i/pnQaxf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.145987,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOBA",
    "name": "Boba Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42bbfa2e77757c645eeaad1655e0911a7553efbc",
    "decimals": 18,
    "image": "https://rango.vip/i/wj3RXk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.19788,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOBA",
    "name": "BOBA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x337c8a3f0cd0580b29e9ee5d7829645709c8f6d2",
    "decimals": 9,
    "image": "https://rango.vip/i/G6ovkV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.4851e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BOBA",
    "name": "Boba Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x07ad578ff86b135be19a12759064b802cb88854d",
    "decimals": 18,
    "image": "https://rango.vip/i/DBWDCu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.19778478598132987,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUN",
    "name": "FunFair",
    "isPopular": false,
    "chainId": "1",
    "address": "0x419d0d8bdd9af5e606ae2232ed285aff190e711b",
    "decimals": 8,
    "image": "https://rango.vip/i/08lRt4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00311315,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TT",
    "name": "ETH-Peg Thunder Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e053d89e08c24aa2ce5c5b4206744dc2d7bd8f5",
    "decimals": 18,
    "image": "https://rango.vip/i/TBkrJc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0030471,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TT",
    "name": "ThunderCore",
    "isPopular": false,
    "chainId": "56",
    "address": "0x990e7154bb999faa9b2fa5ed29e822703311ea85",
    "decimals": 18,
    "image": "https://rango.vip/i/k6CCH0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0030468439108361986,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KARRAT",
    "name": "KarratCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xacd2c239012d17beb128b0944d49015104113650",
    "decimals": 18,
    "image": "https://rango.vip/i/D94RRA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.30085,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOTHER",
    "name": "MOTHER IGGY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3S8qX1MsMqRbiwKg2cQyx7nis1oHMgaCuc9c4VfvVdPN",
    "decimals": 6,
    "image": "https://rango.vip/i/qi3d9P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03338301,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RETARDIO",
    "name": "RETARDIO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx",
    "decimals": 6,
    "image": "https://rango.vip/i/FGn6dj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03378409,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TOSHI",
    "name": "Toshi",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xac1bd2486aaf3b5c0fc3fd868558b082a531b2b4",
    "decimals": 18,
    "image": "https://rango.vip/i/J09HrG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00007991,
    "supportedSwappers": [
      "SushiBase",
      "OneInchBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOSHI",
    "name": "Toshi Tools",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77a90b04d64189d4d09508612c09219bc6816bdc",
    "decimals": 9,
    "image": "https://rango.vip/i/X5Jk8I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.3117e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROG",
    "name": "ROGin AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d43b66da68706d39f6c97f7f1415615672b446b",
    "decimals": 18,
    "image": "https://rango.vip/i/luuJNm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.488986,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALEPH",
    "name": "Aleph im",
    "isPopular": false,
    "chainId": "1",
    "address": "0x27702a26126e0b3702af63ee09ac4d1a084ef628",
    "decimals": 18,
    "image": "https://rango.vip/i/ubCeQa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.132403,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ALEPH",
    "name": "Aleph.im (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3UCMiSnkcnkPE1pgQ5ggPCBv6dXgVUy16TmMUe1WpG9x",
    "decimals": 8,
    "image": "https://rango.vip/i/3fntdi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.133943,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ALEPH",
    "name": "Aleph im",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe7dcd50836d0a28c959c72d72122fedb8e245a6c",
    "decimals": 18,
    "image": "https://rango.vip/i/ZSphnG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.13247158122461083,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ALEPH",
    "name": "Aleph.im v2 SuperToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc0fbc4967259786c743361a5885ef49380473dcf",
    "decimals": 18,
    "image": "https://rango.vip/i/le1w6S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.132403,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HUNT",
    "name": "Hunter Diamond",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CTYiHf58UGShfHtpkTwx7vjPDA779dd6iVaeD281fEVx",
    "decimals": 9,
    "image": "https://rango.vip/i/eezmoF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.2585811650057223,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HUNT",
    "name": "HuntToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9aab071b4129b083b01cb5a0cb513ce7eca26fa5",
    "decimals": 18,
    "image": "https://rango.vip/i/6SLsS5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.257407,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ISIKC",
    "name": "Isiklar Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42726d074bba68ccc15200442b72afa2d495a783",
    "decimals": 4,
    "image": "https://rango.vip/i/hS8ZU3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.326729,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "BOSON",
    "name": "Boson Protocol",
    "isPopular": false,
    "chainId": "25",
    "address": "0x813effe620c71bd1b522a8e6fa74c0e46c80da2e",
    "decimals": 8,
    "image": "https://rango.vip/i/htR1hK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.24278425644949603,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOSON",
    "name": "Boson Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc477d038d5420c6a9e0b031712f61c5120090de9",
    "decimals": 18,
    "image": "https://rango.vip/i/ngzjek",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.244683,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VR",
    "name": "Victoria VR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d5121505149065b562c789a0145ed750e6e8cdd",
    "decimals": 18,
    "image": "https://rango.vip/i/bzEEUq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00597624,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "FIS",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/01d2f0c4739c871bfbee7e786709e6904a55559dc1483dd92ed392ef12247862",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.3035335634877375,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FIS",
    "name": "Stafi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d",
    "decimals": 18,
    "image": "https://rango.vip/i/sWehyB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.301638,
    "supportedSwappers": [
      "Bridgers",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FIS",
    "name": "Stafi",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x849b40ab2469309117ed1038c5a99894767c7282",
    "decimals": 18,
    "image": "https://rango.vip/i/r3i9JE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.30389525455103555,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "FIS",
    "name": "StaFi Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xd8737ca46aa6285de7b8777a8e3db232911bad41",
    "decimals": 18,
    "image": "https://rango.vip/i/QbL5nh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.30389525455103555,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRISE",
    "name": "Bitgert",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf2b2f7b47715256ce4ea43363a867fdce9353e3a",
    "decimals": 9,
    "image": "https://rango.vip/i/WIkfle",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.99e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "STRK",
    "name": null,
    "isPopular": true,
    "chainId": "SN_MAIN",
    "address": "0x4718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/STRK.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 0.388538,
    "supportedSwappers": [
      "Avnu",
      "MySwap",
      "10KSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STRK",
    "name": "StarkNet Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xca14007eff0db1f8135f4c25b34de49ab0d42766",
    "decimals": 18,
    "image": "https://rango.vip/i/r0GOUJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.388538,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STRK",
    "name": "Strike Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x74232704659ef37c08995e386a2e26cc27a8d7b1",
    "decimals": 18,
    "image": "https://rango.vip/i/xvUZ1f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 10.27,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QUICK",
    "name": "Quickswap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c28aef8977c9b773996d0e8376d2ee379446f2f",
    "decimals": 18,
    "image": "https://rango.vip/i/uz6kMC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 43.49,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "QUICK",
    "name": "QuickSwap",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb5c064f955d8e7f38fe0460c556a72987494ee17",
    "decimals": 18,
    "image": "https://rango.vip/i/3ATHPC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0428695,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "QUICK",
    "name": "Quickswap",
    "isPopular": false,
    "chainId": "137",
    "address": "0x831753dd7087cac61ab5644b308642cc1c33dc13",
    "decimals": 18,
    "image": "https://rango.vip/i/x6KqPA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 43.49,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QUICK",
    "name": "QuickSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2ba23de8a19316a638dc1e7a9adda1d74233368",
    "decimals": 18,
    "image": "https://rango.vip/i/x7PI1O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0428695,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FEG",
    "name": "FEG Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3c7cecf8cbc3066f9a87b310cebe198d00479ac",
    "decimals": 18,
    "image": "https://rango.vip/i/payaDa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00038334,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UOS",
    "name": "Ultra Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c",
    "decimals": 4,
    "image": "https://rango.vip/i/0vl9Wl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.07894,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "FLIX",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/cee970bb3d26f4b907097b6b660489f13f3b0da765b83cc7d9a0bc0ce220fa6f",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/FLIX.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.121333,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "VIRTUAL",
    "name": "Virtual Protocol",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x0b3e328455c4059eeb9e3f84b5543f74e24e7e1b",
    "decimals": 18,
    "image": "https://rango.vip/i/Ku02Si",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.03007077,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIRTUAL",
    "name": "Virtuals Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44ff8620b8ca30902395a7bd3f2407e1a091bf73",
    "decimals": 18,
    "image": "https://rango.vip/i/Ku02Si",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03007077,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DEP",
    "name": "DEAPCOIN",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xd4d026322c88c2d49942a75dff920fcfbc5614c1",
    "decimals": 18,
    "image": "https://rango.vip/i/YCKKtO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00110443,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEP",
    "name": "DEAPCOIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a3496c18d558bd9c6c8f609e1b129f67ab08163",
    "decimals": 18,
    "image": "https://rango.vip/i/nmG1ad",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00110443,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DEP",
    "name": "DEP",
    "isPopular": false,
    "chainId": "128",
    "address": "0x48c859531254f25e57d1c1a8e030ef0b1c895c27",
    "decimals": 18,
    "image": "https://rango.vip/i/Y6zPOc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0000014707455396574622,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALCX",
    "name": "Alchemix",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdbdb4d16eda451d0503b854cf79d55697f90c8df",
    "decimals": 18,
    "image": "https://rango.vip/i/Rthcx7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 12.82,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RLY",
    "name": "Rally",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b",
    "decimals": 18,
    "image": "https://rango.vip/i/9pucJL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00567323,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FIDA",
    "name": "Bonfida",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp",
    "decimals": 6,
    "image": "https://rango.vip/i/iErXAv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.211014,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GAFI",
    "name": "GameFi",
    "isPopular": false,
    "chainId": "56",
    "address": "0x89af13a10b32f1b2f8d1588f93027f69b6f4e27e",
    "decimals": 18,
    "image": "https://rango.vip/i/4pb3ja",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.65,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RSV",
    "name": "Reserve",
    "isPopular": false,
    "chainId": "1",
    "address": "0x196f4727526ea7fb1e17b2071b3d8eaa38486988",
    "decimals": 18,
    "image": "https://rango.vip/i/jeMLBx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.9988147898156717,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DEGO",
    "name": "Dego.Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3fda9383a84c05ec8f7630fe10adf1fac13241cc",
    "decimals": 18,
    "image": "https://rango.vip/i/HVzxk7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.3537610062712129,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PROPS",
    "name": "Props",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6fe56c0bcdd471359019fcbc48863d6c3e9d4f41",
    "decimals": 18,
    "image": "https://rango.vip/i/cXANrd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00179066,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMG",
    "name": "OMG Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd26114cd6ee289accf82350c8d8487fedb8a0c07",
    "decimals": 18,
    "image": "https://rango.vip/i/YvJVFR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.201154,
    "supportedSwappers": [
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRAC",
    "name": "OriginTrail",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f",
    "decimals": 18,
    "image": "https://rango.vip/i/Ihuruu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.494376,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DMTR",
    "name": "Dimitra",
    "isPopular": false,
    "chainId": "1",
    "address": "0x51cb253744189f11241becb29bedd3f1b5384fdb",
    "decimals": 18,
    "image": "https://rango.vip/i/b2oAHP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.057417,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XSGD",
    "name": "XSGD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70e8de73ce538da2beed35d14187f6959a8eca96",
    "decimals": 6,
    "image": "https://rango.vip/i/TOfy30",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.771378,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "XSGD",
    "name": "XSGD",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdc3326e71d45186f113a2f448984ca0e8d201995",
    "decimals": 6,
    "image": "https://rango.vip/i/914MSJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.771378,
    "supportedSwappers": [
      "PolygonUniSwapV3",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "XSGD",
    "name": "XSGD (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x769434dca303597c8fc4997bf3dab233e961eda2",
    "decimals": 6,
    "image": "https://rango.vip/i/p2cuDl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.7683351570665685,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "XSGD",
    "name": "XSGD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa05245ade25cc1063ee50cf7c083b4524c1c4302",
    "decimals": 6,
    "image": "https://rango.vip/i/HH8e0n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.7683351570665685,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AMPL",
    "name": "Ampleforth",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x027dbca046ca156de9622cd1e2d907d375e53aa7",
    "decimals": 9,
    "image": "https://rango.vip/i/AiXtEj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.990161,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMPL",
    "name": "Ampleforth",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd46ba6d942050d489dbd938a2c909a5d5039a161",
    "decimals": 9,
    "image": "https://rango.vip/i/SBd8Hd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.990161,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AMPL",
    "name": "AMPL secured by Meter Passport",
    "isPopular": false,
    "chainId": "56",
    "address": "0xdb021b1b247fe2f1fa57e0a87c748cc1e321f07f",
    "decimals": 9,
    "image": "https://rango.vip/i/cuNus0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.990161,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FCT",
    "name": "Firmachain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1bad922f84b198a08292fb600319300ae32471b",
    "decimals": 18,
    "image": "https://rango.vip/i/1WPJ1R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03103478,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NXRA",
    "name": "AllianceBlock Nexera",
    "isPopular": false,
    "chainId": "1",
    "address": "0x644192291cc835a93d6330b24ea5f5fedd0eef9e",
    "decimals": 18,
    "image": "https://rango.vip/i/CWl9BN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0335822,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FARM",
    "name": "FARM Reward Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0246c9032bc3a600820415ae600c6388619a14d",
    "decimals": 18,
    "image": "https://rango.vip/i/WyS3H4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 40.07,
    "supportedSwappers": [
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FARM",
    "name": "FarmBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x443f9ff91a72f1482d13dfd40eec107bb84ca1e5",
    "decimals": 18,
    "image": "https://rango.vip/i/Tl54iW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009578,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FARM",
    "name": "TaxFarm ing",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6db6fdb5182053eecec778afec95e0814172a474",
    "decimals": 18,
    "image": "https://rango.vip/i/z2dYBd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00290259,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FARM",
    "name": "Harvest Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8553d254cb6934b16f87d2e486b64bbd24c83c70",
    "decimals": 18,
    "image": "https://rango.vip/i/P5BdCK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 40.09062316108443,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEASH",
    "name": "DOGE KILLER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x27c70cd1946795b66be9d954418546998b546634",
    "decimals": 18,
    "image": "https://rango.vip/i/ZqJu3Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 259.42,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CCD",
    "name": "Copycat DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd8684adc4664bc2a0c78ddc8657dc005e804af15",
    "decimals": 18,
    "image": "https://rango.vip/i/V2ZETR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01098301,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "IOT",
    "name": "Helium IOT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "iotEVVZLEywoTn1QdwNPddxPWszn3zFhEot3MfL9fns",
    "decimals": 6,
    "image": "https://rango.vip/i/Lgw8gv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00145025,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "VOXEL",
    "name": "VOXEL",
    "isPopular": false,
    "chainId": "25",
    "address": "0x5fdbfe38e050829374001630b8710bdd05ea55c0",
    "decimals": 18,
    "image": "https://rango.vip/i/B9lIhi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.14537640372371732,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "VOXEL",
    "name": "Voxies",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f",
    "decimals": 18,
    "image": "https://rango.vip/i/rCJnNz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.143123,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "PolygonUniSwapV3",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EURI",
    "name": "Eurite",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d1a7a3191102e9f900faa10540837ba84dcbae7",
    "decimals": 18,
    "image": "https://rango.vip/i/9gzE1z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PDA",
    "name": "PlayDapp",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d3cbed3f69ee050668adf3d9ea57241cba33a2b",
    "decimals": 18,
    "image": "https://rango.vip/i/IMZRzO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04585113,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "REEF",
    "name": "Reef.finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e",
    "decimals": 18,
    "image": "https://rango.vip/i/UNHsZM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00117883,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REEF",
    "name": "Reef.finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe3e6a25e6b192a42a44ecddcd13796471735acf",
    "decimals": 18,
    "image": "https://rango.vip/i/75QIvI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00117883,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PSG",
    "name": "Piggy Sol Gang",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DM8YLBujra6arvKuZ23d4ukqLdzbiGuhyWM4dUZnTnFA",
    "decimals": 2,
    "image": "https://rango.vip/i/Hfk62U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.235694411079414,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PSG",
    "name": "Paris Saint-Germain",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbc5609612b7c44bef426de600b5fd1379db2ecf1",
    "decimals": 2,
    "image": "https://rango.vip/i/Cvgg8P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.235694411079414,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NUM",
    "name": "NUM Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3496b523e5c00a4b4150d6721320cddb234c3079",
    "decimals": 18,
    "image": "https://rango.vip/i/0FdLMp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0390244,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NUM",
    "name": "NUM Token [via ChainPort.io]",
    "isPopular": false,
    "chainId": "56",
    "address": "0xeceb87cf00dcbf2d4e2880223743ff087a995ad9",
    "decimals": 18,
    "image": "https://rango.vip/i/SJnPUj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0390244,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CEEK",
    "name": "CEEK Smart VR",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb056c38f6b7dc4064367403e26424cd2c60655e1",
    "decimals": 18,
    "image": "https://rango.vip/i/eAN4BT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02768223,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CEEK",
    "name": "CEEK",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe0f94ac5462997d2bc57287ac3a3ae4c31345d66",
    "decimals": 18,
    "image": "https://rango.vip/i/LHEQJ6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02768223,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "DORA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/672406ade4edfd8c5ea7a0d0dd0c37e431da7bd8393a15cd2cfde3364917eb2a",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.02188995215104,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DORA",
    "name": "Dorayaki",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc4171f45ef0ef66e76f979df021a34b46dcc81d",
    "decimals": 18,
    "image": "https://rango.vip/i/d67ANH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04819365,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DORA",
    "name": "Dorayaki",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4957805230831401caad5b690aa138143b711358",
    "decimals": 18,
    "image": "https://rango.vip/i/JzgWz6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04809632,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DORA",
    "name": "DORA",
    "isPopular": false,
    "chainId": "128",
    "address": "0x7ab088fedae4fa8ada4df638c07cef6c23aff002",
    "decimals": 18,
    "image": "https://rango.vip/i/1VasCF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.048330721325295124,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AVA",
    "name": "AVA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa6c0c097741d55ecd9a3a7def3a8253fd022ceb9",
    "decimals": 18,
    "image": "https://rango.vip/i/oIwOVd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.475158,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AVA",
    "name": "Avatly",
    "isPopular": false,
    "chainId": "56",
    "address": "0x83b79f74f225e8f9a29fc67cb1678e7909d7d73d",
    "decimals": 18,
    "image": "https://rango.vip/i/b0Can8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.4754610439955013,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KISHU",
    "name": "Kishu Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2b4c0af19cc16a6cfacce81f192b024d625817d",
    "decimals": 9,
    "image": "https://rango.vip/i/YjXNv9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.7629e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POLS",
    "name": "Polkastarter",
    "isPopular": false,
    "chainId": "1",
    "address": "0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa",
    "decimals": 18,
    "image": "https://rango.vip/i/9gwhM3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.254272,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "POLS",
    "name": "Polkastarter",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7e624fa0e1c4abfd309cc15719b7e2580887f570",
    "decimals": 18,
    "image": "https://rango.vip/i/O78hpE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.254272,
    "supportedSwappers": [
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "POLS",
    "name": "Polkastarter",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xeeeb5eac2db7a7fc28134aa3248580d48b016b64",
    "decimals": 18,
    "image": "https://rango.vip/i/O78hpE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.2570065629389274,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEAR",
    "name": "Gearbox",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba3335588d9403515223f109edc4eb7269a9ab5d",
    "decimals": 18,
    "image": "https://rango.vip/i/JeaFom",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00444485,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GEAR",
    "name": "MetaGear Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb4404dab7c0ec48b428cf37dec7fb628bcc41b36",
    "decimals": 18,
    "image": "https://rango.vip/i/lvVVvw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.004448109863144251,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VRA",
    "name": "VERA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf411903cbc70a74d22900a5de66a2dda66507255",
    "decimals": 18,
    "image": "https://rango.vip/i/Rucr3c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00246413,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ALPACA",
    "name": "Alpaca",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8f0528ce5ef7b51152a59745befdd91d97091d2f",
    "decimals": 18,
    "image": "https://rango.vip/i/9F0IX4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.162369,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SUSD",
    "name": "Synth sUSD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa970af1a584579b618be4d69ad6f73459d112f95",
    "decimals": 18,
    "image": "https://rango.vip/i/ijY8et",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.992424,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SIDUS",
    "name": "SIDUS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x549020a9cb845220d66d3e9c6d9f9ef61c981102",
    "decimals": 18,
    "image": "https://rango.vip/i/2Z0Jxg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00259836,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SAUCE",
    "name": "SAUCE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "427xvZVKbFj7ZyfFoYS9iFpNuNsrijm6T9VP8znfko9j",
    "decimals": 9,
    "image": "https://rango.vip/i/BLwqFR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004513,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EDLC",
    "name": "Edelcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc47ef9b19c3e29317a50f5fbe594eba361dada4a",
    "decimals": 6,
    "image": "https://rango.vip/i/4VKIeR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LINA",
    "name": "Linear Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e9bc21c9b189c09df3ef1b824798658d5011937",
    "decimals": 18,
    "image": "https://rango.vip/i/FREWWX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00364487,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LINA",
    "name": "LINA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc05d14442a510de4d3d71a3d316585aa0ce32b50",
    "decimals": 18,
    "image": "https://rango.vip/i/TT2dL0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009405,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LINA",
    "name": "Linear Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x762539b45a1dcce3d36d080f74d1aed37844b878",
    "decimals": 18,
    "image": "https://rango.vip/i/3vbS1x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00364487,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BFG",
    "name": "BFG Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbb46693ebbea1ac2070e59b4d043b47e2e095f86",
    "decimals": 18,
    "image": "https://rango.vip/i/w773Ku",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02142957,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "PSTAKE",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/8061a06d3bd4d52c4a28ffecf7150d370393af0ba661c3776c54ff32836c3961",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/PSTAKE.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.054566522452480004,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PSTAKE",
    "name": "pSTAKE Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb5c6815ca3ac72ce9f5006869ae67f18bf77006",
    "decimals": 18,
    "image": "https://rango.vip/i/1JE9dq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.054081,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PSTAKE",
    "name": "pStake Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4c882ec256823ee773b25b414d36f92ef58a7c0c",
    "decimals": 18,
    "image": "https://rango.vip/i/1N2aa4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.054081,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "PSTAKE",
    "name": "pSTAKE Finance",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xcbf7b47e9da345812e3bd732e3ee369a7203b5ae",
    "decimals": 16,
    "image": "https://rango.vip/i/1TltTR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.054081,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRTS",
    "name": "Cratos",
    "isPopular": false,
    "chainId": "1",
    "address": "0x678e840c640f619e17848045d23072844224dd37",
    "decimals": 18,
    "image": "https://rango.vip/i/BIlnhC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00048302,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHX",
    "name": "Stronghold SHx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee7527841a932d2912224e20a405e1a1ff747084",
    "decimals": 7,
    "image": "https://rango.vip/i/QLzit5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00406937,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASD",
    "name": "AscendEx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff742d05420b6aca4481f635ad8341f81a6300c2",
    "decimals": 18,
    "image": "https://rango.vip/i/v01Tz6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03569287,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UPP",
    "name": "Sentinel Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc86d054809623432210c107af2e3f619dcfbf652",
    "decimals": 18,
    "image": "https://rango.vip/i/f9jEY9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04773136,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "COREUM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/f3166f4d31d6ba1ec6c9f5536f5dddd4cc93dba430f7419e7cdc41c497944a65",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.061374633685683196,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "USDK",
    "name": "USDK",
    "isPopular": false,
    "chainId": "66",
    "address": "0xdcac52e001f5bd413aa6ea83956438f29098166b",
    "decimals": 18,
    "image": "https://rango.vip/i/GFi8b7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.8221978312223006,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDK",
    "name": "USDK (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "43m2ewFV5nDepieFjT9EmAQnc1HRtAF247RBpLGFem5F",
    "decimals": 8,
    "image": "https://rango.vip/i/YrMLj4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.8121285753243968,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDK",
    "name": "USDK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c48f86ae57291f7686349f12601910bd8d470bb",
    "decimals": 18,
    "image": "https://rango.vip/i/e6pik1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.8121285753243968,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LNQ",
    "name": "LinqAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd4f4d0a10bcae123bb6655e8fe93a30d01eebd04",
    "decimals": 18,
    "image": "https://rango.vip/i/NoO0O7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.121,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KOKO",
    "name": "KOALA AI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FsA54yL49WKs7rWoGv9sUcbSGWCWV756jTD349e6H2yW",
    "decimals": 6,
    "image": "https://rango.vip/i/2N1GjP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000248,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "COIN",
    "name": "Coin on Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8e16d46cb2da01cdd49601ec73d7b0344969ae33",
    "decimals": 18,
    "image": "https://rango.vip/i/4BsDU9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0000026,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COIN",
    "name": "BrianArmstrongTrumpYellenGTA6",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e70f6806171873d17d4bfc984a6f9d20f5a9018",
    "decimals": 18,
    "image": "https://rango.vip/i/2KeN2J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010067,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "COIN",
    "name": "DJENN",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xb73d8850510f2001fd4655c3baf98f3dfb00c0cc",
    "decimals": 18,
    "image": "https://rango.vip/i/R4EykN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00678157,
    "supportedSwappers": [
      "ThrusterV2.1"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CSWAP",
    "name": "ChainSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae41b275aaaf484b541a5881a2dded9515184cca",
    "decimals": 18,
    "image": "https://rango.vip/i/CBme79",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02397519,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CSWAP",
    "name": "CrossSwap.com",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0b0c16038845bed3fcf70304d3e167df81ce225",
    "decimals": 18,
    "image": "https://rango.vip/i/o7Z28l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00839242,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BURGER",
    "name": "Burger Swap",
    "isPopular": false,
    "chainId": "56",
    "address": "0xae9269f27437f0fcbc232d39ec814844a51d6b8f",
    "decimals": 18,
    "image": "https://rango.vip/i/jRAwBe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.389175,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "IRIS",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/7c4d60aa95e5a7558b0a364860979ca34b7ff8aaf255b87af9e879374470cec0",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/IRIS.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.01365922,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "IRIS",
    "name": "Iris",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdab35042e63e93cc8556c9bae482e5415b5ac4b1",
    "decimals": 18,
    "image": "https://rango.vip/i/ORSPT3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00377788,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "IRIS",
    "symbol": "IRIS",
    "name": null,
    "isPopular": true,
    "chainId": "irishub-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/kLcFdx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/IRIS/icon.svg",
    "usdPrice": 0.013642802066682802,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEL",
    "name": "Gelato Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05",
    "decimals": 18,
    "image": "https://rango.vip/i/eHKHLL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.199395,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GEOD",
    "name": "Geodnet Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xac0f66379a6d7801d7726d5a943356a172549adb",
    "decimals": 18,
    "image": "https://rango.vip/i/ZezXNE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.148794,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PROPC",
    "name": "Propchain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ff58067bd8d239000010c154c6983a325df138e",
    "decimals": 18,
    "image": "https://rango.vip/i/D5iI3y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.989996,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDAO",
    "name": "Singularity Dao",
    "isPopular": false,
    "chainId": "1",
    "address": "0x993864e43caa7f7f12953ad6feb1d1ca635b875f",
    "decimals": 18,
    "image": "https://rango.vip/i/sHhBX7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.261402,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SDAO",
    "name": "Singularity Dao",
    "isPopular": false,
    "chainId": "56",
    "address": "0x90ed8f1dc86388f14b64ba8fb4bbd23099f18240",
    "decimals": 18,
    "image": "https://rango.vip/i/lUdkmQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.261402,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COPI",
    "name": "Cornucopias",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42baf1f659d765c65ade5bb7e08eb2c680360d9d",
    "decimals": 18,
    "image": "https://rango.vip/i/MSmPrH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02515595,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOC",
    "name": "Mossland",
    "isPopular": false,
    "chainId": "1",
    "address": "0x865ec58b06bf6305b886793aa20a2da31d034e68",
    "decimals": 18,
    "image": "https://rango.vip/i/8W74xL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.051463,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SAMO",
    "name": "Samoyed Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU",
    "decimals": 9,
    "image": "https://rango.vip/i/SquXXI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00582162,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VITE",
    "name": "Vite",
    "isPopular": false,
    "chainId": "1",
    "address": "0xadd5e881984783dd432f80381fb52f45b53f3e70",
    "decimals": 18,
    "image": "https://rango.vip/i/otSY73",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0191421,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMB",
    "name": "AirDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf4fb9bf10e489ea3edb03e094939341399587b0c",
    "decimals": 18,
    "image": "https://rango.vip/i/029CjF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00683878,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RSC",
    "name": "ResearchCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd101dcc414f310268c37eeb4cd376ccfa507f571",
    "decimals": 18,
    "image": "https://rango.vip/i/DrgMTO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.225169,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SLND",
    "name": "Solend",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp",
    "decimals": 6,
    "image": "https://rango.vip/i/WprHcf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.516765,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HARD",
    "name": "HARD",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf79037f6f6be66832de4e7516be52826bc3cbcc4",
    "decimals": 6,
    "image": "https://rango.vip/i/KhnKN3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.15381937092378084,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNFI",
    "name": "Unifi Protocol DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x441761326490cacf7af299725b6292597ee822c2",
    "decimals": 18,
    "image": "https://rango.vip/i/Uz7Jny",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.4,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "UNFI",
    "name": "UNFI",
    "isPopular": false,
    "chainId": "56",
    "address": "0x728c5bac3c3e370e372fc4671f9ef6916b814d8b",
    "decimals": 18,
    "image": "https://rango.vip/i/sMsriG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.4,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XCHNG",
    "name": "Chainge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb712d62fe84258292d1961b5150a19bc4ab49026",
    "decimals": 18,
    "image": "https://rango.vip/i/NeUHwi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04383238,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UTK",
    "name": "Utrust Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c",
    "decimals": 18,
    "image": "https://rango.vip/i/4G5Q3j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0414739,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AQT",
    "name": "Alpha Quark",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a9bdcff37ab68b95a53435adfd8892e86084f93",
    "decimals": 18,
    "image": "https://rango.vip/i/Ybah4B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.762493,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOG",
    "name": "Guild of Guardians",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62",
    "decimals": 18,
    "image": "https://rango.vip/i/ykDW2k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03271526,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ADX",
    "name": "Ambire AdEx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xade00c28244d5ce17d72e40330b1c318cd12b7c3",
    "decimals": 18,
    "image": "https://rango.vip/i/2brGao",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.141034,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ADX",
    "name": "AdEx Network",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6bff4fb161347ad7de4a625ae5aa3a1ca7077819",
    "decimals": 18,
    "image": "https://rango.vip/i/kpYcwq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.141034,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DF",
    "name": "dForce",
    "isPopular": false,
    "chainId": "1",
    "address": "0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0",
    "decimals": 18,
    "image": "https://rango.vip/i/PhQaXB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02898801,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DF",
    "name": "dForce",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xae6aab43c4f3e0cea4ab83752c278f8debaba689",
    "decimals": 18,
    "image": "https://rango.vip/i/Rp6sJr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02898801,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "DF",
    "name": "dForce",
    "isPopular": false,
    "chainId": "10",
    "address": "0x9e5aac1ba1a2e6aed6b32689dfcf62a509ca96f3",
    "decimals": 18,
    "image": "https://rango.vip/i/QlStLN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.029041443639752133,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAGA",
    "name": "Crypto Asset Governance Alliance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbddc20ed7978b7d59ef190962f441cd18c14e19f",
    "decimals": 18,
    "image": "https://rango.vip/i/5MHYSd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00030588,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUBI",
    "name": "Multibit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38e382f74dfb84608f3c1f10187f6bef5951de93",
    "decimals": 18,
    "image": "https://rango.vip/i/Slx7CM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02070004,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MUBI",
    "name": "MUBI",
    "isPopular": false,
    "chainId": "56",
    "address": "0x38e382f74dfb84608f3c1f10187f6bef5951de93",
    "decimals": 18,
    "image": "https://rango.vip/i/5wzcxB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02070004,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "CHEQ",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/7a08c6f11ef0f59eb841b9f788a87ec9f2361c7d9703157ec13d940dc53031fa",
    "decimals": 9,
    "image": "https://rango.vip/tokens/ALL/CHEQ.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.03118324723359744,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHEQ",
    "name": "CHEQD Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70edf1c215d0ce69e7f16fd4e6276ba0d99d4de7",
    "decimals": 9,
    "image": "https://rango.vip/i/H69LBk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03115721,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UFT",
    "name": "UniLend Finance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0202be363b8a4820f3f4de7faf5224ff05943ab1",
    "decimals": 18,
    "image": "https://rango.vip/i/ZeQe7N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.253421,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UFT",
    "name": "Unilend Finance",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5b4cf2c120a9702225814e18543ee658c5f8631e",
    "decimals": 18,
    "image": "https://rango.vip/i/ZeQe7N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.253421,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGRS",
    "name": "Agoras Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x738865301a9b7dd80dc3666dd48cf034ec42bdda",
    "decimals": 8,
    "image": "https://rango.vip/i/ZZ1gzY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "HAI",
    "name": "Let s Get HAI",
    "isPopular": false,
    "chainId": "10",
    "address": "0x10398abc267496e49106b07dd6be13364d10dc71",
    "decimals": 18,
    "image": "https://rango.vip/i/V0J5FC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.979514,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HAI",
    "name": "Hacken",
    "isPopular": false,
    "chainId": "56",
    "address": "0xaa9e582e5751d703f85912903bacaddfed26484c",
    "decimals": 8,
    "image": "https://rango.vip/i/Wv834C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03078114,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAI",
    "name": "HapticAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x89deb6c8918a42457bd6ddbcaaf979216c4d774c",
    "decimals": 18,
    "image": "https://rango.vip/i/ahSvIw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0011124,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAI",
    "name": "Hacken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x05fb86775fd5c16290f1e838f5caaa7342bd9a63",
    "decimals": 8,
    "image": "https://rango.vip/i/fVctUA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03078114,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XPR",
    "name": "Proton",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7efb00d12c2c13131fd319336fdf952525da2af",
    "decimals": 4,
    "image": "https://rango.vip/i/KvsKSr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00074938,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROOT",
    "name": "RootKit",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb5f72d37685c3d5ad0bb5f982443bc8fcdf570e",
    "decimals": 18,
    "image": "https://rango.vip/i/NOo68p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.019032519952206253,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROOT",
    "name": "The Root Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa3d4bee77b05d4a0c943877558ce21a763c4fa29",
    "decimals": 6,
    "image": "https://rango.vip/i/Lq0AJk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01897168,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FRONT",
    "name": "Frontier Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x928e55dab735aa8260af3cedada18b5f70c72f1b",
    "decimals": 18,
    "image": "https://rango.vip/i/eQXThO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.678989,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRONT",
    "name": "Frontier Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf8c3527cc04340b208c854e985240c02f7b7793f",
    "decimals": 18,
    "image": "https://rango.vip/i/y7p79o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.678989,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FRONT",
    "name": "Frontier",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa3ed22eee92a3872709823a6970069e12a4540eb",
    "decimals": 18,
    "image": "https://rango.vip/i/p2BHiQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.678989,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SD",
    "name": "Stader",
    "isPopular": false,
    "chainId": "1",
    "address": "0x30d20208d987713f46dfd34ef128bb16c404d10f",
    "decimals": 18,
    "image": "https://rango.vip/i/PzoNjl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.480663,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SD",
    "name": "Shark Dog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8rEEAvq6kujW2ukjYHfutH5eToAwsK4aaJ8p1ApoMs9g",
    "decimals": 6,
    "image": "https://rango.vip/i/CdWqYa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.4804261758280979,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SD",
    "name": "Stader (Wormhole)",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3bc5ac0dfdc871b365d159f728dd1b9a0b5481e8",
    "decimals": 18,
    "image": "https://rango.vip/i/fIuSSc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.480663,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SD",
    "name": "Stader",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1629c4112952a7a377cb9b8d7d8c903092f34b63",
    "decimals": 18,
    "image": "https://rango.vip/i/PzoNjl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.4804261758280979,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SD",
    "name": "Stader",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1d734a02ef1e1f5886e66b0673b71af5b53ffa94",
    "decimals": 18,
    "image": "https://rango.vip/i/5ryNEJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.480663,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PAID",
    "name": "PAID Network",
    "isPopular": false,
    "chainId": "56",
    "address": "0xad86d0e9764ba90ddd68747d64bffbd79879a238",
    "decimals": 18,
    "image": "https://rango.vip/i/Q2ohR7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.04489404,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAID",
    "name": "PAID Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1614f18fc94f47967a3fbe5ffcd46d4e7da3d787",
    "decimals": 18,
    "image": "https://rango.vip/i/SLcbxF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04489404,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PDT",
    "name": "ParagonsDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x375abb85c329753b1ba849a601438ae77eec9893",
    "decimals": 18,
    "image": "https://rango.vip/i/d4jPa1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.118967,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CERE",
    "name": "Cere Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2da719db753dfa10a62e140f436e1d67f2ddb0d6",
    "decimals": 10,
    "image": "https://rango.vip/i/ME0k8Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00277357,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NEON",
    "name": "Neon EVM",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "NeonTjSjsuo3rexg9o6vHuMXw62f9V7zvmu8M8Zut44",
    "decimals": 9,
    "image": "https://rango.vip/i/CL0aHL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.330831,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZUSD",
    "name": "Zerogoki USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x76417e660df3e5c90c0361674c192da152a806e4",
    "decimals": 18,
    "image": "https://rango.vip/i/cuhBBL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.9998455543125006,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ZUSD",
    "name": "Z.com USD",
    "isPopular": false,
    "chainId": "10",
    "address": "0x6e4cc0ab2b4d2edafa6723cfa1582229f1dd1be1",
    "decimals": 6,
    "image": "https://rango.vip/i/lc5gXZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.9998455543125006,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZUSD",
    "name": "Z.com USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc56c2b7e71b54d38aab6d52e94a04cbfa8f604fa",
    "decimals": 6,
    "image": "https://rango.vip/i/1EJlqW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.998201,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MCB",
    "name": "MUX Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4e352cf164e64adcbad318c3a1e222e9eba4ce42",
    "decimals": 18,
    "image": "https://rango.vip/i/IYBXre",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 4.99,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCB",
    "name": "MUX Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e352cf164e64adcbad318c3a1e222e9eba4ce42",
    "decimals": 18,
    "image": "https://rango.vip/i/1Pj5nT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.99,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MCB",
    "name": "MCDEX",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5fe80d2cd054645b9419657d3d10d26391780a7b",
    "decimals": 18,
    "image": "https://rango.vip/i/hsQMqL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4.99,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TRADE",
    "name": "Polytrade",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe22c452bd2ade15dfc8ad98286bc6bdf0c9219b7",
    "decimals": 18,
    "image": "https://rango.vip/i/fLUP2a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.504889,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRADE",
    "name": "UniTrade",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6f87d756daf0503d08eb8993686c7fc01dc44fb1",
    "decimals": 18,
    "image": "https://rango.vip/i/gvi88k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0049265,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRADE",
    "name": "Polytrade",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e5970dbd6fc7eb1f29c6d2edf2bc4c36124c0c1",
    "decimals": 18,
    "image": "https://rango.vip/i/fLUP2a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.504889,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TRADE",
    "name": "UniTrade",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7af173f350d916358af3e218bdf2178494beb748",
    "decimals": 18,
    "image": "https://rango.vip/i/jOYHBi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0049265,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TRADE",
    "name": "Polytrade",
    "isPopular": false,
    "chainId": "137",
    "address": "0x692ac1e363ae34b6b489148152b12e2785a3d8d6",
    "decimals": 18,
    "image": "https://rango.vip/i/hzl96l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.504889,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "A8",
    "name": "Ancient8",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e5a19c91266ad8ce2477b91585d1856b84062df",
    "decimals": 18,
    "image": "https://rango.vip/i/hVsupH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.09325,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDRT",
    "name": "Rupiah Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x998ffe1e43facffb941dc337dd0468d52ba5b48a",
    "decimals": 2,
    "image": "https://rango.vip/i/edRJCw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006483,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KP3R",
    "name": "Keep3rV1",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44",
    "decimals": 18,
    "image": "https://rango.vip/i/hNrp6v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 43.63,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MNW",
    "name": "Morpheus.Network (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3c59798620e5fec0ae6df1a19c6454094572ab92",
    "decimals": 18,
    "image": "https://rango.vip/i/Y0dpCx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.387394,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNW",
    "name": "Morpheus.Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd3e4ba569045546d09cf021ecc5dfe42b1d7f6e4",
    "decimals": 18,
    "image": "https://rango.vip/i/bHDcAb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.387394,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MXC",
    "name": "MXC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ca381bbfb58f0092df149bd3d243b08b9a8386e",
    "decimals": 18,
    "image": "https://rango.vip/i/Y459oO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00687463,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PRCL",
    "name": "Parcl",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4LLbsb5ReP3yEtYzmXewyGjcir5uXtKFURtaEUVC2AHs",
    "decimals": 6,
    "image": "https://rango.vip/i/iFIJI6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.125015,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VXV",
    "name": "VectorspaceAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d29a64504629172a429e64183d6673b9dacbfce",
    "decimals": 18,
    "image": "https://rango.vip/i/couHpP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.366569,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AKRO",
    "name": "Akropolis",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7",
    "decimals": 18,
    "image": "https://rango.vip/i/Gjpze4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00407253,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "IDIA",
    "name": "Impossible Finance Launchpad",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x2d28aa28fa1e5e6bf121cf688309bf3faaae3c70",
    "decimals": 18,
    "image": "https://rango.vip/i/Rs3e2l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.02646525,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "IDIA",
    "name": "Impossible Decentralized Incubator",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0b15ddf19d47e6a86a56148fb4afffc6929bcb89",
    "decimals": 18,
    "image": "https://rango.vip/i/ZL0tzR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02646525,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MATH",
    "name": "MATH Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x08d967bb0134f2d07f7cfb6e246680c53927dd30",
    "decimals": 18,
    "image": "https://rango.vip/i/ZJmCg3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.156944,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MATH",
    "name": "MATH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x99f40b01ba9c469193b360f72740e416b17ac332",
    "decimals": 18,
    "image": "https://rango.vip/i/r7dQxh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.156944,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MATH",
    "name": "MATH",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9e81f6495ba29a6b4d48bddd042c0598fa8abc9f",
    "decimals": 18,
    "image": "https://rango.vip/i/r7dQxh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.156944,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MATH",
    "name": "MATH Token (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CaGa7pddFXS65Gznqwp42kBhkJQdceoFVT7AQYo8Jr8Q",
    "decimals": 8,
    "image": "https://rango.vip/i/ZWtImb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.156944,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MATH",
    "name": "MATH Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf218184af829cf2b0019f8e6f0b2423498a36983",
    "decimals": 18,
    "image": "https://rango.vip/i/H6Ne0o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.156944,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "OORT",
    "name": "OORT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5651fa7a726b9ec0cad00ee140179912b6e73599",
    "decimals": 18,
    "image": "https://rango.vip/i/vOO7CJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.084802,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ZTX",
    "name": "ZTX",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1c43d05be7e5b54d506e3ddb6f0305e8a66cd04e",
    "decimals": 18,
    "image": "https://rango.vip/i/fwVuuG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00487236,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOPR",
    "name": "HOPR",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5581dfefd8fb0e4aec526be659cfab1f8c781da",
    "decimals": 18,
    "image": "https://rango.vip/i/tCg1Pu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.051836,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HOPR",
    "name": "HOPR",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x177f394a3ed18faa85c1462ae626438a70294ef7",
    "decimals": 18,
    "image": "https://rango.vip/i/pVET2B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.05183897744365701,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AVINOC",
    "name": "AVINOC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1ca9cb74685755965c7458528a36934df52a3ef",
    "decimals": 18,
    "image": "https://rango.vip/i/oF6UUX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04547905,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HEZ",
    "name": "Hermez Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeef9f339514298c6a857efcfc1a762af84438dee",
    "decimals": 18,
    "image": "https://rango.vip/i/s20a3i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.77,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "TONIC",
    "name": "Tectonic",
    "isPopular": false,
    "chainId": "25",
    "address": "0xdd73dea10abc2bff99c60882ec5b2b81bb1dc5b2",
    "decimals": 18,
    "image": "https://rango.vip/i/3W4LFu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 7.1084e-8,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLT",
    "name": "Fluence",
    "isPopular": false,
    "chainId": "1",
    "address": "0x236501327e701692a281934230af0b6be8df3353",
    "decimals": 18,
    "image": "https://rango.vip/i/ZdbLtt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.201057,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAH",
    "name": "Moon Tropica",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e0e57dcb1ce8d9091df38ec1bfc3b224529754a",
    "decimals": 18,
    "image": "https://rango.vip/i/4NJtRb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.76,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EL",
    "name": "ELYSIA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2781246fe707bb15cee3e5ea354e2154a2877b16",
    "decimals": 18,
    "image": "https://rango.vip/i/P00zTq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00428875,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "USDV",
    "name": "Verified USD",
    "isPopular": false,
    "chainId": "10",
    "address": "0x323665443cef804a3b5206103304bd4872ea4253",
    "decimals": 6,
    "image": "https://rango.vip/i/DnC85B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.009,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDV",
    "name": "USDV",
    "isPopular": false,
    "chainId": "56",
    "address": "0x323665443cef804a3b5206103304bd4872ea4253",
    "decimals": 6,
    "image": "https://rango.vip/i/Sp6ybC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.009,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USDV",
    "name": "Verified USD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x323665443cef804a3b5206103304bd4872ea4253",
    "decimals": 6,
    "image": "https://rango.vip/i/DnC85B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.009,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USDV",
    "name": "USDV",
    "isPopular": false,
    "chainId": "137",
    "address": "0x323665443cef804a3b5206103304bd4872ea4253",
    "decimals": 6,
    "image": "https://rango.vip/i/gP7aOT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.009,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDV",
    "name": "USDV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e573ce2736dd9637a0b21058352e1667925c7a8",
    "decimals": 6,
    "image": "https://rango.vip/i/DImAiw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.009,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDV",
    "name": "USDV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20b3b07e9c0e37815e2892ab09496559f57c3603",
    "decimals": 18,
    "image": "https://rango.vip/i/z7slKw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.988985,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "USDV",
    "name": "USDV",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x323665443cef804a3b5206103304bd4872ea4253",
    "decimals": 6,
    "image": "https://rango.vip/i/fGIkdG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.009,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRQ",
    "name": "PARSIQ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x362bc847a3a9637d3af6624eec853618a43ed7d2",
    "decimals": 18,
    "image": "https://rango.vip/i/1YN9bM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.064587,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PRQ",
    "name": "PARSIQ",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x82164a8b646401a8776f9dc5c8cba35dcaf60cd2",
    "decimals": 18,
    "image": "https://rango.vip/i/VD5sUo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.06471517253542376,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SABAI",
    "name": "Sabai Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb5d730d442e1d5b119fb4e5c843c48a64202ef92",
    "decimals": 18,
    "image": "https://rango.vip/i/cCo9T0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04710137,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SIX",
    "name": "Solana Eco Index",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HWSqJdwemji7TNiKQPudUj86LXyF3vGAtWm5ePk5KzgD",
    "decimals": 9,
    "image": "https://rango.vip/i/PYPHRo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01949384596783149,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EXRD",
    "name": "E-RADIX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6468e79a80c0eab0f9a2b574c8d5bc374af59414",
    "decimals": 18,
    "image": "https://rango.vip/i/XGBDcd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02196905,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GMEE",
    "name": "GAMEE",
    "isPopular": false,
    "chainId": "56",
    "address": "0x84e9a6f9d240fdd33801f7135908bfa16866939a",
    "decimals": 18,
    "image": "https://rango.vip/i/2CfGch",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01323596,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GMEE",
    "name": "GAMEE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd9016a907dc0ecfa3ca425ab20b6b785b42f2373",
    "decimals": 18,
    "image": "https://rango.vip/i/ztbb0H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01323596,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRAINLET",
    "name": "BRAINLET",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0000bdaa645097ef80f9d475f341d0d107a45b3a",
    "decimals": 18,
    "image": "https://rango.vip/i/3z3aLJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002548,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NATIX",
    "name": "NATIX Network",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FRySi8LPkuByB7VPSCCggxpewFUeeJiwEGRKKuhwpKcX",
    "decimals": 6,
    "image": "https://rango.vip/i/kAF0v9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00099558,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WNCG",
    "name": "WrappedNCG",
    "isPopular": false,
    "chainId": "56",
    "address": "0x52242cbab41e290e9e17ccc50cc437bb60020a9d",
    "decimals": 18,
    "image": "https://rango.vip/i/fsfQXh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.04318221,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WNCG",
    "name": "Wrapped NCG",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf203ca1769ca8e9e8fe1da9d147db68b6c919817",
    "decimals": 18,
    "image": "https://rango.vip/i/LPux7q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04318221,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YFII",
    "name": "DFI money",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1d0e215a23d7030842fc67ce582a6afa3ccab83",
    "decimals": 18,
    "image": "https://rango.vip/i/FnWy1P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 405.5,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "YFII",
    "name": "YFII.finance Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7f70642d88cf1c4a3a7abb072b53b929b653eda5",
    "decimals": 18,
    "image": "https://rango.vip/i/OSd1k2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 404.0048359654568,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "YFII",
    "name": "YFII.finance (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb8cb8a7f4c2885c03e57e973c74827909fdc2032",
    "decimals": 18,
    "image": "https://rango.vip/i/GQ5LoS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 404.0048359654568,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SMOG",
    "name": "Smog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FS66v5XYtJAFo14LiPz5HT93EUMAHmYipCfQhLpU4ss8",
    "decimals": 6,
    "image": "https://rango.vip/i/tyuiql",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02003179,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMOG",
    "name": "Smog",
    "isPopular": false,
    "chainId": "1",
    "address": "0x89e8e084cc60e6988527f0904b4be71656e8bfa9",
    "decimals": 6,
    "image": "https://rango.vip/i/Whs00U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02003179,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAL",
    "name": "Galxe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5faa989af96af85384b8a938c2ede4a7378d9875",
    "decimals": 18,
    "image": "https://rango.vip/i/SonDKb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.89,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GAL",
    "name": "Galxe",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe4cc45bb5dbda06db6183e8bf016569f40497aa5",
    "decimals": 18,
    "image": "https://rango.vip/i/IxxIuf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.89,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "KWENTA",
    "name": "Kwenta",
    "isPopular": false,
    "chainId": "10",
    "address": "0x920cf626a271321c151d027030d5d08af699456b",
    "decimals": 18,
    "image": "https://rango.vip/i/exeaI8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 35.25,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MNGO",
    "name": "Mango",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac",
    "decimals": 6,
    "image": "https://rango.vip/i/twWP4D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01514178,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOTTO",
    "name": "Botto",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9dfad1b7102d46b1b197b90095b5c4e9f5845bba",
    "decimals": 18,
    "image": "https://rango.vip/i/XpoZJg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.314176,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XCAD",
    "name": "XCAD Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa026ad2ceda16ca5fc28fd3c72f99e2c332c8a26",
    "decimals": 18,
    "image": "https://rango.vip/i/tyTRRg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.314014,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "XCAD",
    "name": "XCAD Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa55870278d6389ec5b524553d03c04f5677c061e",
    "decimals": 18,
    "image": "https://rango.vip/i/dngMT4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.314014,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XCAD",
    "name": "XCAD Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7659ce147d0e714454073a5dd7003544234b6aa0",
    "decimals": 18,
    "image": "https://rango.vip/i/7jLQOf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.314014,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XCAD",
    "name": "Chainport.io-Peg XCAD Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x431e0cd023a32532bf3969cddfc002c00e98429d",
    "decimals": 18,
    "image": "https://rango.vip/i/GN5zJy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.31417147833674686,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AURY",
    "name": "Aurory",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP",
    "decimals": 9,
    "image": "https://rango.vip/i/wqepcj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.242131,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SQUIDGROW",
    "name": "SquidGrow",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd8fa690304d2b2824d918c0c7376e2823704557a",
    "decimals": 9,
    "image": "https://rango.vip/i/3QEJcn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.2994e-8,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIDO",
    "name": "Shido",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed354cae598148846aa9694254c854fc7051163c",
    "decimals": 18,
    "image": "https://rango.vip/i/Mi6i1I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.44654e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIDO",
    "name": "Shido",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe2512a2f19f0388ad3d7a5263eaa82acd564827b",
    "decimals": 18,
    "image": "https://rango.vip/i/x6DJPt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00083409,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOND",
    "name": "BarnBridge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0391d2021f89dc339f60fff84546ea23e337750f",
    "decimals": 18,
    "image": "https://rango.vip/i/8d6Pt6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.86,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BOND",
    "name": "BarnBridge Governance Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x0d81e50bc677fa67341c44d7eaa9228dee64a4e1",
    "decimals": 18,
    "image": "https://rango.vip/i/gZWoyt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.86,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BOND",
    "name": "BarnBridge Governance Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa041544fe2be56cce31ebb69102b965e06aace80",
    "decimals": 18,
    "image": "https://rango.vip/i/jx0GsO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.866464220800562,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BOND",
    "name": "BarnBridge Governance Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x3e7ef8f50246f725885102e8238cbba33f276747",
    "decimals": 18,
    "image": "https://rango.vip/i/sJyTv2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.86,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "KYVE",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/613bf0bf2f2146ae9941e923725745e931676b2c14e9768cd609fa0849b2ae13",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.021927839413031815,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AZUR",
    "name": "Azuro",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e6be44cc1236eef7e1f197418592d363bedcd5a",
    "decimals": 18,
    "image": "https://rango.vip/i/MLnAV0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.090174,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AZUR",
    "name": "AZUR Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf161cdb9aa33b2f48be273dae3f3bbb2330ad3e5",
    "decimals": 18,
    "image": "https://rango.vip/i/lAJF0H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00188999,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GYEN",
    "name": "GYEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc08512927d12348f6620a698105e1baac6ecd911",
    "decimals": 6,
    "image": "https://rango.vip/i/9DJGxM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00695349,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GYEN",
    "name": "GYEN",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x589d35656641d6ab57a545f08cf473ecd9b6d5f7",
    "decimals": 6,
    "image": "https://rango.vip/i/Ehp7Pe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00695349,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "GYEN",
    "name": "GMO JPY",
    "isPopular": false,
    "chainId": "10",
    "address": "0x589d35656641d6ab57a545f08cf473ecd9b6d5f7",
    "decimals": 6,
    "image": "https://rango.vip/i/K01IhW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00695374903710252,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DVF",
    "name": "DeversiFi Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdddddd4301a082e62e84e43f474f044423921918",
    "decimals": 18,
    "image": "https://rango.vip/i/zEtKk5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.619094,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DVF",
    "name": "DeversiFi Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa7aa2921618e3d63da433829d448b58c9445a4c3",
    "decimals": 18,
    "image": "https://rango.vip/i/Dqwgaj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.619094,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "DVF",
    "name": "DeversiFi Token",
    "isPopular": false,
    "chainId": "324",
    "address": "0xbbd1ba24d589c319c86519646817f2f153c9b716",
    "decimals": 18,
    "image": "https://rango.vip/i/iUDTpG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.6029061009130221,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INV",
    "name": "Inverse Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68",
    "decimals": 18,
    "image": "https://rango.vip/i/M0fqV3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 24.35,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAI",
    "name": "Trace AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x267eb2a9a13dc304a9deff4277abe850d0852c5f",
    "decimals": 8,
    "image": "https://rango.vip/i/cYSp3d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00034501,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "DVPN",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/9712dbb13b9631edfa9bf61b55f1b2d290b2adb67e3a4eb3a875f3b6081b3b84",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/DVPN.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0007367,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DVPN",
    "name": "DVPN Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x22994fdb3f8509cf6a729bbfa93f939db0b50d06",
    "decimals": 18,
    "image": "https://rango.vip/i/sUUwkH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00019163,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SENTINEL",
    "symbol": "DVPN",
    "name": null,
    "isPopular": true,
    "chainId": "sentinelhub-2",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/5imoDx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SENTINEL/icon.svg",
    "usdPrice": 0.000736189359268605,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TPT",
    "name": "TokenPocket Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xeca41281c24451168a37211f0bc2b8645af45092",
    "decimals": 4,
    "image": "https://rango.vip/i/0rL1Db",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00412307,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "TPT",
    "name": "TPT",
    "isPopular": false,
    "chainId": "66",
    "address": "0x2cec1c3f71db54c99d6df687d8ac968cce80cc85",
    "decimals": 4,
    "image": "https://rango.vip/i/gx9Y8k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.0039191338448003555,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TPT",
    "name": "TokenPocket Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4161725d019690a3e0de50f6be67b07a86a9fae1",
    "decimals": 4,
    "image": "https://rango.vip/i/zQb4ka",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00412307,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "TPT",
    "name": "TPT",
    "isPopular": false,
    "chainId": "128",
    "address": "0x9ef1918a9bee17054b35108bd3e2665e2af1bb1b",
    "decimals": 4,
    "image": "https://rango.vip/i/ThShdc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00412307,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEAI",
    "name": "De Layer",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd849882983f1ba8a3c23b16b65bb0173a7f63b63",
    "decimals": 18,
    "image": "https://rango.vip/i/av7tm0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00140298,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEAI",
    "name": "Zero1 Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1495bc9e44af1f8bcb62278d2bec4540cf0c05ea",
    "decimals": 18,
    "image": "https://rango.vip/i/WbVPXu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.155353,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SANTOS",
    "name": "FC Santos Fan Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa64455a4553c9034236734faddaddbb64ace4cc7",
    "decimals": 8,
    "image": "https://rango.vip/i/wcNhfd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.6,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BZZ",
    "name": "Swarm",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19062190b1925b5b6689d7073fdfc8c2976ef8cb",
    "decimals": 16,
    "image": "https://rango.vip/i/etdHQy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.266421,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DMAIL",
    "name": "Dmail Network",
    "isPopular": false,
    "chainId": "56",
    "address": "0xcc6f1e1b87cfcbe9221808d2d85c501aab0b5192",
    "decimals": 18,
    "image": "https://rango.vip/i/iwdsrE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.237093,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKML",
    "name": "zKML",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe92344b4edf545f3209094b192e46600a19e7c2d",
    "decimals": 18,
    "image": "https://rango.vip/i/3kmVdF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.150088,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COMAI",
    "name": "Commune AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc78b628b060258300218740b1a7a5b3c82b3bd9f",
    "decimals": 18,
    "image": "https://rango.vip/i/mH3c3k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.137855,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ALU",
    "name": "Altura",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8263cd1601fe73c066bf49cc09841f35348e3be0",
    "decimals": 18,
    "image": "https://rango.vip/i/474jHT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01925834,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GLQ",
    "name": "GraphLinq",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f9c8ec3534c3ce16f928381372bfbfbfb9f4d24",
    "decimals": 18,
    "image": "https://rango.vip/i/bGuFgx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04066315,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPUL",
    "name": "OpulousToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80d55c03180349fff4a229102f62328220a96444",
    "decimals": 18,
    "image": "https://rango.vip/i/dKPakE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04860465,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ZERC",
    "name": "zkRace",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe1b3eb06806601828976e491914e3de18b5d6b28",
    "decimals": 18,
    "image": "https://rango.vip/i/1vcgh2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.113379,
    "supportedSwappers": [
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZERC",
    "name": "zkRace",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf8428a5a99cb452ea50b6ea70b052daa3df4934f",
    "decimals": 18,
    "image": "https://rango.vip/i/0Bpx1c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.113379,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNCX",
    "name": "UniCrypt",
    "isPopular": false,
    "chainId": "1",
    "address": "0xadb2437e6f65682b85f814fbc12fec0508a7b1d0",
    "decimals": 18,
    "image": "https://rango.vip/i/p6E1rT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 289.47,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "UNCX",
    "name": "UniCrypt on xDai on BSC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x09a6c44c3947b69e2b45f4d51b67e6a39acfb506",
    "decimals": 18,
    "image": "https://rango.vip/i/RDFQ49",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 417.02,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STOS",
    "name": "Stratos Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x08c32b0726c5684024ea6e141c50ade9690bbdcc",
    "decimals": 18,
    "image": "https://rango.vip/i/1zZHFl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.350127,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LIME",
    "name": "Lime Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "H3QMCaMh5LxtS9oGDwaMaRXPSPSiXVqnY4YsfrQMRjqD",
    "decimals": 6,
    "image": "https://rango.vip/i/mtVeWr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 7.34604e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LIME",
    "name": "iMe Lab",
    "isPopular": false,
    "chainId": "137",
    "address": "0x7f67639ffc8c93dd558d452b8920b28815638c44",
    "decimals": 18,
    "image": "https://rango.vip/i/xIxNmG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03141864,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LIME",
    "name": "iMe Lab",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7bc75e291e656e8658d66be1cc8154a3769a35dd",
    "decimals": 18,
    "image": "https://rango.vip/i/ByQRoX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03141864,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLENDR",
    "name": "Blendr Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x84018071282d4b2996272659d9c01cb08dd7327f",
    "decimals": 18,
    "image": "https://rango.vip/i/7U74qq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.334273,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "KRL",
    "name": "KRL",
    "isPopular": false,
    "chainId": "25",
    "address": "0x62e622fa4e180c391f2e089fc1d5ea7adcb96575",
    "decimals": 18,
    "image": "https://rango.vip/i/ICuE1d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.3469223532013548,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KRL",
    "name": "KRYLL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x464ebe77c293e473b48cfe96ddcf88fcf7bfdac0",
    "decimals": 18,
    "image": "https://rango.vip/i/JJtB3R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.350007,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MTRG",
    "name": "MTRG",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbd2949f67dcdc549c6ebe98696449fa79d988a9f",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.429666,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "MTRG",
    "name": "Meter Governance",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x1e24ec84f66cd26dad607d81796dbeb13cb22692",
    "decimals": 18,
    "image": "https://rango.vip/i/8H2PG4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.4306585438590316,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKL",
    "name": "zkLink",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc385a1df85660a7e041423db512f779070fcede",
    "decimals": 18,
    "image": "https://rango.vip/i/gy1LDE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.097927,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WIFI",
    "name": "WiFi Map",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe238ecb42c424e877652ad82d8a939183a04c35f",
    "decimals": 18,
    "image": "https://rango.vip/i/h0XOKh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02919953,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVZ",
    "name": "Electric Vehicle Zone",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a939bb714fd2a48ebeb1e495aa9aaa74ba9fa68",
    "decimals": 18,
    "image": "https://rango.vip/i/m94NWF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00634538,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PANDORA",
    "name": "Pandora",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e9fbde7c7a83c43913bddc8779158f1368f0413",
    "decimals": 18,
    "image": "https://rango.vip/i/OLti0h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1331.78,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBF",
    "name": "Bubblefong",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde075d9adbd0240b4462f124af926452ad0bac91",
    "decimals": 18,
    "image": "https://rango.vip/i/eJ4z7z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.070153,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "BCD",
    "name": "BlockChat",
    "isPopular": false,
    "chainId": "1088",
    "address": "0xc029adf62cf8f91985c49e1fde6900affcba0424",
    "decimals": 18,
    "image": "https://rango.vip/i/oGa1hK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 0.00461848231180132,
    "supportedSwappers": [
      "Netswap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIB",
    "name": "Viberate",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c974b2d0ba1716e644c1fc59982a89ddd2ff724",
    "decimals": 18,
    "image": "https://rango.vip/i/Q62y1u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.066015,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QTCON",
    "name": "Quiztok",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1bf7fd22709733ccd7c45ab27dd02c7ec8e50078",
    "decimals": 18,
    "image": "https://rango.vip/i/V3z53L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00133544,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "OG",
    "name": "OG",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf05e45ad22150677a017fbd94b84fbb63dc9b44c",
    "decimals": 2,
    "image": "https://rango.vip/i/4JmpON",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.710840650011626,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTX",
    "name": "Cryptex",
    "isPopular": false,
    "chainId": "1",
    "address": "0x321c2fe4446c7c963dc41dd58879af648838f98d",
    "decimals": 18,
    "image": "https://rango.vip/i/HA6yEG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.96,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CTX",
    "name": "Cryptex Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x84f5c2cfba754e76dd5ae4fb369cfc920425e12b",
    "decimals": 18,
    "image": "https://rango.vip/i/ZiL4NS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.9554530076876622,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HEGE",
    "name": "Hege",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ULwSJmmpxmnRfpu6BjnK6rprKXqD5jXUmPpS1FxHXFy",
    "decimals": 9,
    "image": "https://rango.vip/i/FNrMrR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01240095760748766,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZEON",
    "name": "ZEON Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5b826ca2ca02f09c1725e9bd98d9a8874c30532",
    "decimals": 18,
    "image": "https://rango.vip/i/IOvjvR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040619,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHPING",
    "name": "Shping",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c84e62859d0715eb77d1b1c4154ecd6abb21bec",
    "decimals": 18,
    "image": "https://rango.vip/i/R6UjVU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00535691,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HEGIC",
    "name": "Hegic",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x431402e8b9de9aa016c743880e04e517074d8cec",
    "decimals": 18,
    "image": "https://rango.vip/i/HccH5q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01735892,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HEGIC",
    "name": "Hegic",
    "isPopular": false,
    "chainId": "1",
    "address": "0x584bc13c7d411c00c01a62e8019472de68768430",
    "decimals": 18,
    "image": "https://rango.vip/i/LRWrjg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01735892,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "JEWEL",
    "name": "Jewels",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x4f60a160d8c2dddaafe16fcc57566db84d674bd6",
    "decimals": 18,
    "image": "https://rango.vip/i/Gf8Pcs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.044932193036723876,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "JEWEL",
    "name": "JEWEL",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x997ddaa07d716995de90577c123db411584e5e46",
    "decimals": 18,
    "image": "https://rango.vip/i/uoJc6o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.101053,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOIA",
    "name": "NOIA Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa8c8cfb141a3bb59fea1e2ea6b79b5ecbcd7b6ca",
    "decimals": 18,
    "image": "https://rango.vip/i/LlKHnS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03895313,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PIT",
    "name": "Pitbull",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa57ac35ce91ee92caefaa8dc04140c8e232c2e50",
    "decimals": 9,
    "image": "https://rango.vip/i/fNgnZ8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.94896e-10,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HTM",
    "name": "HTM",
    "isPopular": false,
    "chainId": "128",
    "address": "0x80c66d460e2bb9d31a8de54b4016fca986d0811f",
    "decimals": 18,
    "image": "https://rango.vip/i/v7OH0L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0012408607307923707,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GTAI",
    "name": "GT Protocol",
    "isPopular": false,
    "chainId": "56",
    "address": "0x003d87d02a2a01e9e8a20f507c83e15dd83a33d1",
    "decimals": 18,
    "image": "https://rango.vip/i/KUu1tj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.338656,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DUKO",
    "name": "Duko",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HLptm5e6rTgh4EKgDpYFrnRHbjpkMyVdEeREEa2G7rf9",
    "decimals": 6,
    "image": "https://rango.vip/i/RV7ddN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00120722,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VLX",
    "name": "Velas",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe9c803f48dffe50180bd5b01dc04da939e3445fc",
    "decimals": 18,
    "image": "https://rango.vip/i/jHsfEI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00443408,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LMR",
    "name": "Lumerin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b1d0b9f081468d780ca1d5d79132b64301085d1",
    "decimals": 8,
    "image": "https://rango.vip/i/jIrz0I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01900861,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ASR",
    "name": "AS Roma",
    "isPopular": false,
    "chainId": "56",
    "address": "0x80d5f92c2c8c682070c95495313ddb680b267320",
    "decimals": 2,
    "image": "https://rango.vip/i/GspTn9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.838935391086679,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOKE",
    "name": "Tokemak",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e9d63788249371f1dfc918a52f8d799f4a38c94",
    "decimals": 18,
    "image": "https://rango.vip/i/0Yay90",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.703025,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TOKE",
    "name": "Mycelium McToken",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AmgUMQeqW8H74trc8UkKjzZWtxBdpS496wh4GLy2mCpo",
    "decimals": 3,
    "image": "https://rango.vip/i/nQrGQD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.7061389506038539,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PNK",
    "name": "Kleros",
    "isPopular": false,
    "chainId": "1",
    "address": "0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d",
    "decimals": 18,
    "image": "https://rango.vip/i/2BQmKX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01479112,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAST",
    "name": "Castello Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3fab0bbaa03bceaf7c49e2b12877db0142be65fc",
    "decimals": 8,
    "image": "https://rango.vip/i/FDAYnL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.055344,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "JUV",
    "name": "Juventus",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc40c9a843e1c6d01b7578284a9028854f6683b1b",
    "decimals": 2,
    "image": "https://rango.vip/i/xAON5R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.5488498588379043,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AVT",
    "name": "Aventus",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d88ed6e74bbfd96b831231638b66c05571e824f",
    "decimals": 18,
    "image": "https://rango.vip/i/LG5yxF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.91,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TORN",
    "name": "Tornado Cash",
    "isPopular": false,
    "chainId": "137",
    "address": "0x23fe1ee2f536427b7e8ac02fb037a7f867037fe8",
    "decimals": 18,
    "image": "https://rango.vip/i/S3b6y9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2.1493239140839386,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TORN",
    "name": "TORN Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77777feddddffc19ff86db637967013e6c6a116c",
    "decimals": 18,
    "image": "https://rango.vip/i/sJRmXm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CREDI",
    "name": "Credefi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae6e307c3fe9e922e5674dbd7f830ed49c014c6b",
    "decimals": 18,
    "image": "https://rango.vip/i/BcV0rn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0151801,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SC",
    "name": "Shark Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6D7NaB2xsLd7cauWu1wKk6KBsJohJmP2qZH9GEfVi5Ui",
    "decimals": 6,
    "image": "https://rango.vip/i/RTHkVL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01119482,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SC",
    "name": "STAR CAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A2GHnfpZvyeZX5Pr63jdMmo9uYbpaaKqHHuPD5xD2n6v",
    "decimals": 9,
    "image": "https://rango.vip/i/RA4gdQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0001476,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NWC",
    "name": "Numerico",
    "isPopular": false,
    "chainId": "1",
    "address": "0x968f6f898a6df937fc1859b323ac2f14643e3fed",
    "decimals": 18,
    "image": "https://rango.vip/i/rmbHaZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.073331,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NWC",
    "name": "Newscrypto",
    "isPopular": false,
    "chainId": "137",
    "address": "0x968f6f898a6df937fc1859b323ac2f14643e3fed",
    "decimals": 18,
    "image": "https://rango.vip/i/SEgBND",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0733394910914421,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SLIM",
    "name": "Solanium",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW",
    "decimals": 6,
    "image": "https://rango.vip/i/JwevHV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.109921,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HELLO",
    "name": "Hello Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4h49hPGphLNJNDRyiBwzvKoasR3rw1WJCEv19PhUbSS4",
    "decimals": 9,
    "image": "https://rango.vip/i/tCXXhZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01743034,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HELLO",
    "name": "HELLO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x411099c0b413f4feddb10edf6a8be63bd321311c",
    "decimals": 18,
    "image": "https://rango.vip/i/K9tQnz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01743034,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARIA20",
    "name": "ARIANEE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xedf6568618a00c6f0908bf7758a16f76b6e04af9",
    "decimals": 18,
    "image": "https://rango.vip/i/lbQMn5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.102465,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "OOKI",
    "name": "OOKI",
    "isPopular": false,
    "chainId": "137",
    "address": "0xcd150b1f528f326f5194c012f32eb30135c7c2c9",
    "decimals": 18,
    "image": "https://rango.vip/i/d6ONwJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0012407887317313297,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OOKI",
    "name": "OOKI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0de05f6447ab4d22c8827449ee4ba2d5c288379b",
    "decimals": 18,
    "image": "https://rango.vip/i/AyGxlD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00123915,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DVI",
    "name": "Dvision",
    "isPopular": false,
    "chainId": "56",
    "address": "0x758fb037a375f17c7e195cc634d77da4f554255b",
    "decimals": 18,
    "image": "https://rango.vip/i/BhP3xd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01386185570596139,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DVI",
    "name": "Dvision",
    "isPopular": false,
    "chainId": "1",
    "address": "0x10633216e7e8281e33c86f02bf8e565a635d9770",
    "decimals": 18,
    "image": "https://rango.vip/i/UzlvOG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01368463,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWFTC",
    "name": "SWFTCOIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0bb217e40f8a5cb79adf04e1aab60e5abd0dfc1e",
    "decimals": 8,
    "image": "https://rango.vip/i/t1E0Lx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00271151,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SWFTC",
    "name": "SWFTCOIN",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe64e30276c2f826febd3784958d6da7b55dfbad3",
    "decimals": 18,
    "image": "https://rango.vip/i/Sn6kcW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00271151,
    "supportedSwappers": [
      "BSCPancakeV3",
      "Bridgers"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SWFTC",
    "name": "SWFTC",
    "isPopular": false,
    "chainId": "128",
    "address": "0x329dda64cbc4dfd5fa5072b447b3941ce054ebb3",
    "decimals": 8,
    "image": "https://rango.vip/i/NnknAM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.011351389414637288,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BREED",
    "name": "BreederDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x94e9eb8b5ab9fd6b9ea3169d55ffade62a01702e",
    "decimals": 18,
    "image": "https://rango.vip/i/RTk8eH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01649249,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWAP",
    "name": "TrustSwap Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcc4304a31d09258b0029ea7fe63d032f52e44efe",
    "decimals": 18,
    "image": "https://rango.vip/i/BPXPbX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.104548,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SWAP",
    "name": "SatoshiSwap",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe0f7c8682f865b417aeb80bf237025b4cb5ebaef",
    "decimals": 18,
    "image": "https://rango.vip/i/ufK1GN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.10465590361200165,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PORTO",
    "name": "FC Porto Fan Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x49f2145d6366099e13b10fbf80646c0f377ee7f6",
    "decimals": 8,
    "image": "https://rango.vip/i/lI6ysV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.35,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OAX",
    "name": "OAX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x701c244b988a513c945973defa05de933b23fe1d",
    "decimals": 18,
    "image": "https://rango.vip/i/aHrfP4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.13538,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "LOBO",
    "name": "LOBO",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x4e98b3917310b0e1f0d53c0619f87fe48deb804b",
    "decimals": 18,
    "image": "https://rango.vip/i/P8t5Ra",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00044685,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUKU",
    "name": "SUKU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0763fdccf1ae541a5961815c0872a8c5bc6de4d7",
    "decimals": 18,
    "image": "https://rango.vip/i/EEZlTF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.057622,
    "supportedSwappers": [
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SUKU",
    "name": "SUKU",
    "isPopular": false,
    "chainId": "10",
    "address": "0xef6301da234fc7b0545c6e877d3359fe0b9e50a4",
    "decimals": 18,
    "image": "https://rango.vip/i/GysPhu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.05761165165884788,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOTKEY",
    "name": "HotKeySwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x018dd3a0dd7f213cc822076b3800816d3ce1ed86",
    "decimals": 18,
    "image": "https://rango.vip/i/EP17my",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.243792,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$WAFFLES",
    "name": "WAFFLES",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8doS8nzmgVZEaACxALkbK5fZtw4UuoRp4Yt8NEaXfDMb",
    "decimals": 6,
    "image": "https://rango.vip/i/479bEX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01146718,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PSP",
    "name": "ParaSwap (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x42d61d766b85431666b39b89c43011f24451bff6",
    "decimals": 18,
    "image": "https://rango.vip/i/9IooTr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01364941,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PSP",
    "name": "ParaSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcafe001067cdef266afb7eb5a286dcfd277f3de5",
    "decimals": 18,
    "image": "https://rango.vip/i/DK83pt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01364941,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "PSP",
    "name": "ParaSwap",
    "isPopular": false,
    "chainId": "10",
    "address": "0xd3594e879b358f430e20f82bea61e83562d49d48",
    "decimals": 18,
    "image": "https://rango.vip/i/QzGMRC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.01364941,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SWCH",
    "name": "SwissCheese Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3ce1327867077b551ae9a6987bf10c9fd08edce1",
    "decimals": 18,
    "image": "https://rango.vip/i/OlIMMO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.266117,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LAZIO",
    "name": "FC Lazio Fan Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x77d547256a2cd95f32f67ae0313e450ac200648d",
    "decimals": 8,
    "image": "https://rango.vip/i/tXH07b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.2,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AE",
    "name": "Aeternity",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ca9a71b1d01849c0a95490cc00559717fcf0d1d",
    "decimals": 18,
    "image": "https://rango.vip/i/5bZQkc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02648051,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WALLET",
    "name": "Ambire Wallet",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x0bbbead62f7647ae8323d2cb243a0db74b7c2b80",
    "decimals": 18,
    "image": "https://rango.vip/i/iVyBKT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.01623125,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WALLET",
    "name": "Ambire Wallet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88800092ff476844f74dc2fc427974bbee2794ae",
    "decimals": 18,
    "image": "https://rango.vip/i/xJlJy8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01623125,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PACE",
    "name": "3SPACE ART",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8bc2bcb1b1896291942c36f3cca3c1afa0aaa7fd",
    "decimals": 18,
    "image": "https://rango.vip/i/TFHUpA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.142975,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JESUS",
    "name": "Jesus Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba386a4ca26b85fd057ab1ef86e3dc7bdeb5ce70",
    "decimals": 18,
    "image": "https://rango.vip/i/WnT8ZF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.4627e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BKN",
    "name": "Brickken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a638f07acc6969abf392bb009f216d22adea36d",
    "decimals": 18,
    "image": "https://rango.vip/i/ERUlI7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.155338,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WRLD",
    "name": "NFT Worlds",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd5d86fc8d5c0ea1ac1ac5dfab6e529c9967a45e9",
    "decimals": 18,
    "image": "https://rango.vip/i/Qwbsja",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01363228,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DACXI",
    "name": "Dacxi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xefab7248d36585e2340e5d25f8a8d243e6e3193f",
    "decimals": 18,
    "image": "https://rango.vip/i/Wxv7og",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00106057,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BLOK",
    "name": "BLOK",
    "isPopular": false,
    "chainId": "137",
    "address": "0x229b1b6c23ff8953d663c4cbb519717e323a0a84",
    "decimals": 18,
    "image": "https://rango.vip/i/n3ymbP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00053916,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAROT",
    "name": "Tarot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x837d904a3799c0769079be9ecbaddf1abd4ccd6e",
    "decimals": 18,
    "image": "https://rango.vip/i/ZTLzWU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01000964,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAROT",
    "name": "Tarot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa10bf0aba0c7953f279c4cb8192d3b5de5ea56e8",
    "decimals": 18,
    "image": "https://rango.vip/i/4k1dg4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.148602,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "CLY",
    "name": "Colony Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xec3492a2508ddf4fdc0cd76f31f340b30d1793e6",
    "decimals": 18,
    "image": "https://rango.vip/i/AGWtHt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.086344,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KATA",
    "name": "Katana Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e85ae1c47602f7927bcabc2ff99c40aa222ae15",
    "decimals": 18,
    "image": "https://rango.vip/i/ja7NKN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00034369,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPECTRE",
    "name": "Spectre AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9cf0ed013e67db12ca3af8e7506fe401aa14dad6",
    "decimals": 18,
    "image": "https://rango.vip/i/TFcAJP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.013,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CENNZ",
    "name": "CENNZnet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1122b6a0e00dce0563082b6e2953f3a943855c1f",
    "decimals": 18,
    "image": "https://rango.vip/i/eCx0b3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00764025,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BST",
    "name": "BlocksquareToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x509a38b7a1cc0dcd83aa9d06214663d9ec7c7f4a",
    "decimals": 18,
    "image": "https://rango.vip/i/0vkcbY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.183953,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SIS",
    "name": "Symbiosis",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf98b660adf2ed7d9d9d9daacc2fb0cace4f21835",
    "decimals": 18,
    "image": "https://rango.vip/i/85J3DA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.148514,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SIS",
    "name": "Symbiosis",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd38bb40815d2b0c2d2c866e0c72c5728ffc76dd9",
    "decimals": 18,
    "image": "https://rango.vip/i/5czsUw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.148514,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "SIS",
    "name": "Symbiosis",
    "isPopular": false,
    "chainId": "324",
    "address": "0xdd9f72afed3631a6c85b5369d84875e6c42f1827",
    "decimals": 18,
    "image": "https://rango.vip/i/HEsKtD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.148514,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SPS",
    "name": "SPS",
    "isPopular": false,
    "chainId": "25",
    "address": "0x1b554fda68ba95924e5bbd0baf8e769f039e775b",
    "decimals": 18,
    "image": "https://rango.vip/i/vUavAS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.006681670742090604,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SPS",
    "name": "Splintershards",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1633b7157e7638c4d6593436111bf125ee74703f",
    "decimals": 18,
    "image": "https://rango.vip/i/vcopBv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00681201,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPS",
    "name": "Splintershards",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00813e3421e1367353bfe7615c7f7f133c89df74",
    "decimals": 18,
    "image": "https://rango.vip/i/jFU8Ri",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.006814884333723607,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ASTRAFER",
    "name": "Astrafer",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdfce1e99a31c4597a3f8a8945cbfa9037655e335",
    "decimals": 18,
    "image": "https://rango.vip/i/pVFDMR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.058081,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASTRAFER",
    "name": "Astrafer",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb51b97dd5569fab69495316b5a065cccff4b829d",
    "decimals": 18,
    "image": "https://rango.vip/i/cWQbA2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.058081,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASTRAFER",
    "name": "Astrafer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97bbbc5d96875fb78d2f14b7ff8d7a3a74106f17",
    "decimals": 18,
    "image": "https://rango.vip/i/03j4p3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.389345,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAGMIGAMES",
    "name": "WAGMI Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b604747ad1720c01ded0455728b62c0d2f100f0",
    "decimals": 18,
    "image": "https://rango.vip/i/HPc8gd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000489,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "KASTA",
    "name": "KastaToken",
    "isPopular": false,
    "chainId": "137",
    "address": "0x235737dbb56e8517391473f7c964db31fa6ef280",
    "decimals": 18,
    "image": "https://rango.vip/i/jrEgjO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01407815,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIOT",
    "name": "Bio Passport",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc07a150ecadf2cc352f5586396e344a6b17625eb",
    "decimals": 9,
    "image": "https://rango.vip/i/35CfSX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00140046,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SCLP",
    "name": "Scallop",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf2c96e402c9199682d5ded26d3771c6b192c01af",
    "decimals": 18,
    "image": "https://rango.vip/i/ZcdRRc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.136921,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAITO",
    "name": "SAITO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa14fa6958401314851a17d6c5360ca29f74b57b",
    "decimals": 18,
    "image": "https://rango.vip/i/iXzLtj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.004474,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SAITO",
    "name": "SAITO",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3c6dad0475d3a1696b359dc04c99fd401be134da",
    "decimals": 18,
    "image": "https://rango.vip/i/bw8HjB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.004474,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SCP",
    "name": "SCP",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5sMyPtYRcrEVt27DW3xhGVVha3zCXLv4caVt88PXjBgV",
    "decimals": 9,
    "image": "https://rango.vip/i/vrNm64",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.181286,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POOH",
    "name": "POOH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb69753c06bb5c366be51e73bfc0cc2e3dc07e371",
    "decimals": 18,
    "image": "https://rango.vip/i/VotrKP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.0973e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CREO",
    "name": "Creo Engine",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe636f94a71ec52cc61ef21787ae351ad832347b7",
    "decimals": 18,
    "image": "https://rango.vip/i/bcvUdV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03017508,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "KLIMA",
    "name": "Klima DAO",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4e78011ce80ee02d2c3e649fb657e45898257815",
    "decimals": 9,
    "image": "https://rango.vip/i/m0fizk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.092,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASTX",
    "name": "Asterix",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0000000000ca73a6df4c58b84c5b4b847fe8ff39",
    "decimals": 18,
    "image": "https://rango.vip/i/a6pfHt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 869.64,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RVF",
    "name": "Rocket Vault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc8af07a7861bedd104b8093ae3e9376fc8596d2",
    "decimals": 18,
    "image": "https://rango.vip/i/Qfmga6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.09876499812807805,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RVF",
    "name": "Rocket Vault RocketX",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2ce13e4199443fdfff531abb30c9b6594446bbc7",
    "decimals": 18,
    "image": "https://rango.vip/i/Qfmga6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.445251,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RVF",
    "name": "RocketX Exchange V2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2fb652314c3d850e9049057bbe9813f1eee882d3",
    "decimals": 18,
    "image": "https://rango.vip/i/3tJaml",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.090752,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RVF",
    "name": "Rocket Vault RocketX",
    "isPopular": false,
    "chainId": "56",
    "address": "0x872a34ebb2d54af86827810eebc7b9dc6b2144aa",
    "decimals": 18,
    "image": "https://rango.vip/i/Qfmga6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.445251,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHFT",
    "name": "Shyft Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb17c88bda07d28b3838e0c1de6a30eafbcf52d85",
    "decimals": 18,
    "image": "https://rango.vip/i/20RAcW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00383576,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LA",
    "name": "LA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe50365f5d679cb98a1dd62d6f6e58e59321bcddf",
    "decimals": 18,
    "image": "https://rango.vip/i/mlvQOv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02263838,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARIX",
    "name": "Arix",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb6cf73a00f480d0951ba979a7606857cdde626b",
    "decimals": 18,
    "image": "https://rango.vip/i/ajt57D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.915148,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DCK",
    "name": "DexCheck",
    "isPopular": false,
    "chainId": "56",
    "address": "0x16faf9daa401aa42506af503aa3d80b871c467a3",
    "decimals": 18,
    "image": "https://rango.vip/i/RVCSbI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02480982,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DCK",
    "name": "DexCheck AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x672f4fa517894496b8a958b4b3fca068ce513a39",
    "decimals": 18,
    "image": "https://rango.vip/i/Ru7qXm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02480982,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ADS",
    "name": "Adshares (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x598e49f01befeb1753737934a5b11fea9119c796",
    "decimals": 11,
    "image": "https://rango.vip/i/CyZv2A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.211398,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ADS",
    "name": "Adshares",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a",
    "decimals": 11,
    "image": "https://rango.vip/i/FKsUwv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.211398,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ADS",
    "name": "Adshares",
    "isPopular": false,
    "chainId": "56",
    "address": "0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a",
    "decimals": 11,
    "image": "https://rango.vip/i/FKsUwv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.211398,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTM",
    "name": "Bytom",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb97e65f07da24d46bcdd078ebebd7c6e6e3d750",
    "decimals": 8,
    "image": "https://rango.vip/i/Q7GDZM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.005028126366507411,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MCADE",
    "name": "Metacade",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xc48823ec67720a04a9dfd8c7d109b2c3d6622094",
    "decimals": 18,
    "image": "https://rango.vip/i/6SPXij",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00617853,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PALLA",
    "name": "Pallapay",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8f49733210700d38098d7375c221c7d02f700cc8",
    "decimals": 18,
    "image": "https://rango.vip/i/aq5k57",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.011006598298053177,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OTK",
    "name": "Octokn",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "octo82drBEdm8CSDaEKBymVn86TBtgmPnDdmE64PTqJ",
    "decimals": 9,
    "image": "https://rango.vip/i/KTQb99",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02669719,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HUSD",
    "name": "HUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf574c24545e5ffecb9a659c229253d4111d87e1",
    "decimals": 8,
    "image": "https://rango.vip/i/1hOVrN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02444802,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HUSD",
    "name": "Heco-Peg HUSD Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0x0298c2b32eae4da002a15f36fdf7615bea3da047",
    "decimals": 8,
    "image": "https://rango.vip/i/wRTUNZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.02444802,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "VEGA",
    "name": "Vega Protocol",
    "isPopular": false,
    "chainId": "1088",
    "address": "0x2136d8017ac5f4db99a7f9e23f15116b9c98be4e",
    "decimals": 18,
    "image": "https://rango.vip/i/rHVk5e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 0.000509264076132454,
    "supportedSwappers": [
      "Netswap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VEGA",
    "name": "VEGA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb84d72e61e383767c4dfeb2d8ff7f4fb89abc6e",
    "decimals": 18,
    "image": "https://rango.vip/i/E52xNO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.130638,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTA",
    "name": "Cross The Ages",
    "isPopular": false,
    "chainId": "1",
    "address": "0x90685e300a4c4532efcefe91202dfe1dfd572f47",
    "decimals": 18,
    "image": "https://rango.vip/i/C4Vr9u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.120545,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LM",
    "name": "LeisureMeta",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc064f4f215b6a1e4e7f39bd8530c4de0fc43ee9d",
    "decimals": 18,
    "image": "https://rango.vip/i/mYNSxJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00387362,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VCHF",
    "name": "VNX Swiss Franc",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AhhdRu5YZdjVkKR3wbnUDaymVQL2ucjMQ63sZ3LFHsch",
    "decimals": 9,
    "image": "https://rango.vip/i/6qq5CU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.18,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VCHF",
    "name": "VNX Swiss Franc",
    "isPopular": false,
    "chainId": "1",
    "address": "0x79d4f0232a66c4c91b89c76362016a1707cfbf4f",
    "decimals": 18,
    "image": "https://rango.vip/i/SGydeE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SPX",
    "name": "SPX6900",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "J3NKxxXZcnNiMjKw9hYb2K4LUxgwB6t1FtPtQVsv3KFr",
    "decimals": 8,
    "image": "https://rango.vip/i/rSWXOX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00838129,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPX",
    "name": "SPX6900",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0f63a424a4439cbe457d80e4f4b51ad25b2c56c",
    "decimals": 8,
    "image": "https://rango.vip/i/p9CIco",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00838129,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAGAA",
    "name": "MAGA Again",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ebb0895bd9c7c9dfab0d8d877c66ba613ac98ea",
    "decimals": 18,
    "image": "https://rango.vip/i/YeT4n5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00792345,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EFI",
    "name": "Efinity Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x656c00e1bcd96f256f224ad9112ff426ef053733",
    "decimals": 18,
    "image": "https://rango.vip/i/6weEwb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03903236,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BXX",
    "name": "Baanx",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b1a8f210ec6b7b6643cea3583fb0c079f367898",
    "decimals": 18,
    "image": "https://rango.vip/i/DI32Fw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04051137,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEVVE",
    "name": "DevvE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8248270620aa532e4d64316017be5e873e37cc09",
    "decimals": 18,
    "image": "https://rango.vip/i/CZb1R8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.15068,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHO",
    "name": "Choise ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbba39fd2935d5769116ce38d46a71bde9cf03099",
    "decimals": 18,
    "image": "https://rango.vip/i/13C1vM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02727068,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKEY",
    "name": "SmartKey",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06a01a4d579479dd5d884ebf61a31727a3d8d442",
    "decimals": 8,
    "image": "https://rango.vip/i/n8vs85",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04115719,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GET",
    "name": "GET Protocol (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdb725f82818de83e99f1dac22a9b5b51d3d04dd4",
    "decimals": 18,
    "image": "https://rango.vip/i/p1Hwrc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.651996,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GET",
    "name": "GET",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a854288a5976036a725879164ca3e91d30c6a1b",
    "decimals": 18,
    "image": "https://rango.vip/i/DJ2WrB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.651996,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PERI",
    "name": "PERI Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d30ad9c6374bf925d0a75454fa327aacf778492",
    "decimals": 18,
    "image": "https://rango.vip/i/bEnFgY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.884432,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BOO",
    "name": "BOO",
    "isPopular": false,
    "chainId": "10",
    "address": "0x135c78d7f52aab6e9f17bcf4a9e8627aa233d050",
    "decimals": 18,
    "image": "https://rango.vip/i/OdLmQU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.0000332,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BOO",
    "name": "BOO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x28514bd097d5f9ecea778cc7a4ca4bac5fedb0b6",
    "decimals": 18,
    "image": "https://rango.vip/i/OdLmQU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0000332,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BOO",
    "name": "BOO",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xec1df7edfcdc2e2042c63252c1cef480f64f9189",
    "decimals": 18,
    "image": "https://rango.vip/i/OdLmQU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0000332,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOO",
    "name": "Boo Network",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "boooCKXQn9YTK2aqN5pWftQeb9TH7cj7iUKuVCShWQx",
    "decimals": 9,
    "image": "https://rango.vip/i/AFU9rI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00029331,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOO",
    "name": "Boo Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32e7c8a6e920a3cf224b678112ac78fdc0fb09d1",
    "decimals": 18,
    "image": "https://rango.vip/i/OdLmQU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOO",
    "name": "SpookyToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x55af5865807b196bd0197e0902746f31fbccfa58",
    "decimals": 18,
    "image": "https://rango.vip/i/Txs50C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.795312,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BOO",
    "name": "BOO",
    "isPopular": false,
    "chainId": "128",
    "address": "0xff96dccf2763d512b6038dc60b7e96d1a9142507",
    "decimals": 18,
    "image": "https://rango.vip/i/83tOM0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0002800851255748678,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CATE",
    "name": "Cash Tech",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa42f266684ac2ad6ecb00df95b1c76efbb6f136c",
    "decimals": 18,
    "image": "https://rango.vip/i/GgXo1a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.6730779956281341e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CATE",
    "name": "CateCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x051fb509e4a775fabd257611eea1efaed8f91359",
    "decimals": 9,
    "image": "https://rango.vip/i/iObfKi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.6619e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "G3",
    "name": "GAM3S GG",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc24a365a870821eb83fd216c9596edd89479d8d7",
    "decimals": 18,
    "image": "https://rango.vip/i/jGG9YE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.04224029,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "G3",
    "name": "GAM3S GG",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcf67815cce72e682eb4429eca46843bed81ca739",
    "decimals": 18,
    "image": "https://rango.vip/i/jGG9YE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04224029,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "CULT",
    "name": "Chimera",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x59821011487236ade7f9823f028fc6d5f3758877",
    "decimals": 9,
    "image": "https://rango.vip/i/utN5HG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00099942,
    "supportedSwappers": [
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CULT",
    "name": "Cult DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0f9d895aca5c8678f706fb8216fa22957685a13",
    "decimals": 18,
    "image": "https://rango.vip/i/0H7jtL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000171,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CULT",
    "name": "Cult",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7434a5066dc317fa5b4d31aaded5088b9c54d667",
    "decimals": 18,
    "image": "https://rango.vip/i/UHjXKt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.8254e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OVR",
    "name": "OVR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x21bfbda47a0b4b5b1248c767ee49f7caa9b23697",
    "decimals": 18,
    "image": "https://rango.vip/i/Wn4vDl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.142691,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OVR",
    "name": "OVR",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x55704a0e9e2eb59e176c5b69655dbd3dcdcfc0f0",
    "decimals": 18,
    "image": "https://rango.vip/i/Ke2ZMp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.14349543365495127,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ADP",
    "name": "Adappter",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc314b0e758d5ff74f63e307a86ebfe183c95767b",
    "decimals": 18,
    "image": "https://rango.vip/i/HMmVaa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00204896,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IZI",
    "name": "iZUMi Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ad37205d608b8b219e6a2573f922094cec5c200",
    "decimals": 18,
    "image": "https://rango.vip/i/8b4Yc9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00903629,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFTX",
    "name": "NFTX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x87d73e916d7057945c9bcd8cdd94e42a6f47f776",
    "decimals": 18,
    "image": "https://rango.vip/i/y0wGYH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 11.6,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAN",
    "name": "BitKan",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1410434b0346f5be678d0fb554e5c7ab620f8f4a",
    "decimals": 18,
    "image": "https://rango.vip/i/gYQN1J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00075489,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEV",
    "name": "MEVerse",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb3cb8d5aeff0f4d1f432f353309f47b885e404e3",
    "decimals": 18,
    "image": "https://rango.vip/i/VLZy7F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00415542,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBL",
    "name": "BlockBlend",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a44a7ccea34a7563ba1d45a5f757d0b02281124",
    "decimals": 18,
    "image": "https://rango.vip/i/qDTlps",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00422133,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBL",
    "name": "beoble",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd979c468a68062e7bdff4ba6df7842dfd3492e0f",
    "decimals": 18,
    "image": "https://rango.vip/i/aJvDxQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00861331,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HNS",
    "name": "Handshake",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa771b49064da011df051052848477f18dba1d2ac",
    "decimals": 6,
    "image": "https://rango.vip/i/4CRVbc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01131822,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOLVE",
    "name": "SOLVE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x446c9033e7516d820cc9a2ce2d0b7328b579406f",
    "decimals": 8,
    "image": "https://rango.vip/i/WTQBJs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01049435,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FANC",
    "name": "fanC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb126042235e6bd38b17744cb31a8bf4a206c045",
    "decimals": 18,
    "image": "https://rango.vip/i/p3FdXm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00793376,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOW",
    "name": "ChangeNOW",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9a95d175a5f4c9369f3b74222402eb1b837693b",
    "decimals": 8,
    "image": "https://rango.vip/i/RWFrRi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.082072,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ML",
    "name": "Mintlayer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x059956483753947536204e89bfad909e1a434cc6",
    "decimals": 18,
    "image": "https://rango.vip/i/h131Vw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.109061,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HAMMY",
    "name": "SAD HAMSTER",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "26KMQVgDUoB6rEfnJ51yAABWWJND8uMtpnQgsHQ64Udr",
    "decimals": 6,
    "image": "https://rango.vip/i/cd0lhQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00743835,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CWEB",
    "name": "Coinweb",
    "isPopular": false,
    "chainId": "1",
    "address": "0x505b5eda5e25a67e1c24a2bf1a527ed9eb88bf04",
    "decimals": 18,
    "image": "https://rango.vip/i/hAZjmo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00306156,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EQUAD",
    "name": "Quadrant Protocol",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdab625853c2b35d0a9c6bd8e5a097a664ef4ccfb",
    "decimals": 18,
    "image": "https://rango.vip/i/Mf8r97",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.006795790689387063,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EQUAD",
    "name": "Quadrant Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc28e931814725bbeb9e670676fabbcb694fe7df2",
    "decimals": 18,
    "image": "https://rango.vip/i/faPPj8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00391718,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRVL",
    "name": "TRVL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd47bdf574b4f76210ed503e0efe81b58aa061f3d",
    "decimals": 18,
    "image": "https://rango.vip/i/IU9LEe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02050833,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DC",
    "name": "DONALDCAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FaUKsgcuqAd9sCaFz3if7ia1DasaxVoNPTXWFs2GScWN",
    "decimals": 6,
    "image": "https://rango.vip/i/A1GGeb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006652,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DC",
    "name": "Dogechain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7b4328c127b85369d9f82ca0503b000d09cf9180",
    "decimals": 18,
    "image": "https://rango.vip/i/xP269Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00022392,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VGX",
    "name": "Voyager Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c4b6e6e1ea3d4863700d7f76b36b7f3d3f13e3d",
    "decimals": 8,
    "image": "https://rango.vip/i/KM4NBw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01971243,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUY",
    "name": "Burency",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31fdd1c6607f47c14a2821f599211c67ac20fa96",
    "decimals": 18,
    "image": "https://rango.vip/i/HOsxwm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00049421,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QORPO",
    "name": "QORPO WORLD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x22514ffb0d7232a56f0c24090e7b68f179faa940",
    "decimals": 18,
    "image": "https://rango.vip/i/aAetNl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.078973,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FEI",
    "name": "Fei USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x956f47f50a910163d8bf957cf5846d573e7f87ca",
    "decimals": 18,
    "image": "https://rango.vip/i/MJLMQX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.990978,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "FEI",
    "name": "FEI",
    "isPopular": false,
    "chainId": "128",
    "address": "0x485cdbff08a4f91a16689e73893a11ae8b76af6d",
    "decimals": 18,
    "image": "https://rango.vip/i/HJa7b7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.5126918664901149,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "KOMPETE",
    "name": "KOMPETE",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8f019931375454fe4ee353427eb94e2e0c9e0a8c",
    "decimals": 10,
    "image": "https://rango.vip/i/6JDxjC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00887227,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KOMPETE",
    "name": "KOMPETE Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e0b2992079b620aa13a7c2e7c88d2e1e18e46e9",
    "decimals": 10,
    "image": "https://rango.vip/i/6JDxjC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00887227,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "FITFI",
    "name": "STEP.APP",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x714f020c54cc9d104b6f4f6998c63ce2a31d1888",
    "decimals": 18,
    "image": "https://rango.vip/i/LecgdL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00262251,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "FITFI",
    "name": "Step App",
    "isPopular": false,
    "chainId": "25",
    "address": "0xcd7d4cb8a8b4810fb740a42e344513d49e0ac11f",
    "decimals": 18,
    "image": "https://rango.vip/i/haJXcy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.002649477180038867,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "THREE",
    "name": "ThreeCoin",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9b034262e0095210ab9ddec60199741a8a1fbfe7",
    "decimals": 18,
    "image": "https://rango.vip/i/UJripX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.07835613860426775,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THREE",
    "name": "Three Protocol Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa059b81568fee88791de88232e838465826cf419",
    "decimals": 9,
    "image": "https://rango.vip/i/wSKJOX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.07835613860426775,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "GRG",
    "name": "RigoBlock",
    "isPopular": false,
    "chainId": "10",
    "address": "0xecf46257ed31c329f204eb43e254c609dee143b3",
    "decimals": 18,
    "image": "https://rango.vip/i/PaH3t3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2.36,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GRG",
    "name": "RigoBlock",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7f4638a58c0615037decc86f1dae60e55fe92874",
    "decimals": 18,
    "image": "https://rango.vip/i/PaH3t3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2.36,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "GRG",
    "name": "RigoBlock",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x09188484e1ab980daef53a9755241d759c5b7d60",
    "decimals": 18,
    "image": "https://rango.vip/i/PaH3t3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2.36,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRG",
    "name": "RigoBlock",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4fbb350052bca5417566f188eb2ebce5b19bc964",
    "decimals": 18,
    "image": "https://rango.vip/i/Yunoew",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.36,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EPIC",
    "name": "EPICBOTS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x680c89c40de9d14aa608a1122363cad18783f837",
    "decimals": 18,
    "image": "https://rango.vip/i/A9itC8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00298973,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TADA",
    "name": "TheADA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeaa260c1d247135cec29ec10fb6acedf2a55dc6b",
    "decimals": 9,
    "image": "https://rango.vip/i/oyVno9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033589,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STARL",
    "name": "StarLink",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e6cd950ad6ba651f6dd608dc70e5886b1aa6b24",
    "decimals": 18,
    "image": "https://rango.vip/i/dVxNgI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.63063e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LRDS",
    "name": "BLOCKLORDS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd0a6053f087e87a25dc60701ba6e663b1a548e85",
    "decimals": 18,
    "image": "https://rango.vip/i/gWuGIE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.690702,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCUT",
    "name": "bitsCrunch Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbef26bd568e421d6708cca55ad6e35f8bfa0c406",
    "decimals": 18,
    "image": "https://rango.vip/i/WBBBta",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02782301,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMU",
    "name": "Amulet Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x174c47d6a4e548ed2b7d369dc0ffb2e60a6ac0f8",
    "decimals": 9,
    "image": "https://rango.vip/i/dhAP2Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01445124,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOMI",
    "name": "tomiNet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4385328cc4d643ca98dfea734360c0f596c83449",
    "decimals": 18,
    "image": "https://rango.vip/i/V4JNcS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04299509,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NCDT",
    "name": "nuco cloud",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0c8b298db4cffe05d1bea0bb1ba414522b33c1b",
    "decimals": 18,
    "image": "https://rango.vip/i/BCssKq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.198022,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OGV",
    "name": "Origin Dollar Governance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9c354503c38481a7a7a51629142963f98ecc12d0",
    "decimals": 18,
    "image": "https://rango.vip/i/JC4sy7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00752862,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CSIX",
    "name": "Carbon Browser",
    "isPopular": false,
    "chainId": "1",
    "address": "0x345887cdb19e12833ed376bbf8b8b38269f5f5c8",
    "decimals": 18,
    "image": "https://rango.vip/i/WLHFCr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01627056,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CSIX",
    "name": "Carbon",
    "isPopular": false,
    "chainId": "56",
    "address": "0x04756126f044634c9a0f0e985e60c88a51acc206",
    "decimals": 18,
    "image": "https://rango.vip/i/CzJzS0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01627056,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TEMCO",
    "name": "TEMCO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2fc246aa66f0da5bb1368f688548ecbbe9bdee5d",
    "decimals": 18,
    "image": "https://rango.vip/i/8sAaio",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00159299,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAPI",
    "name": "HAPI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54",
    "decimals": 18,
    "image": "https://rango.vip/i/uyYYWh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMON",
    "name": "AmonD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00059ae69c1622a7542edc15e8d17b060fe307b6",
    "decimals": 18,
    "image": "https://rango.vip/i/4OaVyq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00088556,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$MONG",
    "name": "MongCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1ce270557c1f68cfb577b856766310bf8b47fd9c",
    "decimals": 18,
    "image": "https://rango.vip/i/M9CWcu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.0784e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLAMA",
    "name": "Solama",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AVLhahDcDQ4m4vHM4ug63oh7xc8Jtk49Dm5hoe9Sazqr",
    "decimals": 6,
    "image": "https://rango.vip/i/z7YheQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00977889,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GMM",
    "name": "Gamium [via ChainPort.io]",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b19c70da4c6fa4baa0660825e889d2f7eabc279",
    "decimals": 18,
    "image": "https://rango.vip/i/uarxFO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012984,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONOPOLY",
    "name": "Meta Monopoly",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d4a7be025652995364e0e232063abd9e8d65e6e",
    "decimals": 18,
    "image": "https://rango.vip/i/tnTaiO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00626187,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOFI",
    "name": "RAI Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb49fa25978abf9a248b8212ab4b87277682301c0",
    "decimals": 18,
    "image": "https://rango.vip/i/O7i0TL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01181804,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPARKLET",
    "name": "Upland",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0bc37bea9068a86c221b8bd71ea6228260dad5a2",
    "decimals": 18,
    "image": "https://rango.vip/i/I4fQXc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04389333,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OUSD",
    "name": "Origin Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a8e1e676ec238d8a992307b495b45b3feaa5e86",
    "decimals": 18,
    "image": "https://rango.vip/i/Tgb953",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.998623,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUEL",
    "name": "DUEL Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x943af2ece93118b973c95c2f698ee9d15002e604",
    "decimals": 18,
    "image": "https://rango.vip/i/LKhDFA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00204717,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUBE",
    "name": "Somnium Space CUBEs",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf801468a808a32656d2ed2d2d80b72a129739f4",
    "decimals": 8,
    "image": "https://rango.vip/i/IrhGBg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.485152,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STBU",
    "name": "Stobox",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa6422e3e219ee6d4c1b18895275fe43556fd50ed",
    "decimals": 18,
    "image": "https://rango.vip/i/etJhhY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.060461,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKB",
    "name": "ZKBase",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbbbbbbbb46a1da0f0c3f64522c275baa4c332636",
    "decimals": 18,
    "image": "https://rango.vip/i/DeqwFC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03045846,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REVV",
    "name": "REVV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x557b933a7c2c45672b610f8954a3deb39a51a8ca",
    "decimals": 18,
    "image": "https://rango.vip/i/L5kx5e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00479461,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "REVV",
    "name": "REVV",
    "isPopular": false,
    "chainId": "56",
    "address": "0x833f307ac507d47309fd8cdd1f835bef8d702a93",
    "decimals": 18,
    "image": "https://rango.vip/i/nMOaRR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00479461,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "SWTH",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/8fefae6aecf6e2a255585617f781f35a8d5709a545a804482a261c0c9548a9d3",
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/SWTH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00328475,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SWTH",
    "name": "Carbon Protocol",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc0ecb8499d8da2771abcbf4091db7f65158f1468",
    "decimals": 8,
    "image": "https://rango.vip/i/YLjSfS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00328475,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SWASH",
    "name": "Swash Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xba3cb8329d442e6f9eb70fafe1e214251df3d275",
    "decimals": 18,
    "image": "https://rango.vip/i/UDGqKi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00601141,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWASH",
    "name": "Swash",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa130e3a33a4d84b04c3918c4e5762223ae252f80",
    "decimals": 18,
    "image": "https://rango.vip/i/l6FNpB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00601141,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TKP",
    "name": "TOKPIE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd31695a1d35e489252ce57b129fd4b1b05e6acac",
    "decimals": 18,
    "image": "https://rango.vip/i/g9Rmza",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.512027,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NRG",
    "name": "Energi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1416946162b1c2c871a73b07e932d2fb6c932069",
    "decimals": 18,
    "image": "https://rango.vip/i/Omp00Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.072077,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRU",
    "name": "CRUST",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32a7c02e79c4ea1008dd6564b35f131428673c41",
    "decimals": 18,
    "image": "https://rango.vip/i/D0nSp2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.415988,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "CRU",
    "name": "CRU",
    "isPopular": false,
    "chainId": "128",
    "address": "0x926875854917c871ebbd44b75ac33ac8386d4ed5",
    "decimals": 18,
    "image": "https://rango.vip/i/LDN7PI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.4118654725109047,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DPR",
    "name": "Deeper Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3ae5d769e153ef72b4e3591ac004e89f48107a1",
    "decimals": 18,
    "image": "https://rango.vip/i/6SGXt5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00211421,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAHA",
    "name": "MahaDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x745407c86df8db893011912d3ab28e68b62e49b0",
    "decimals": 18,
    "image": "https://rango.vip/i/Z187w9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.31,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MAHA",
    "name": "MahaDAO",
    "isPopular": false,
    "chainId": "137",
    "address": "0xedd6ca8a4202d4a36611e2fff109648c4863ae19",
    "decimals": 18,
    "image": "https://rango.vip/i/Qlh09O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.3183892103075676,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORB",
    "name": "Ordible",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb53b9e28b98c47e87acfd5a85eeb44a0940ecb12",
    "decimals": 9,
    "image": "https://rango.vip/i/AklS1G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008259,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORB",
    "name": "OrbCity",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c917054e03485808137eb306eafa8da0ab695cd",
    "decimals": 18,
    "image": "https://rango.vip/i/TXBI0h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01781327,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "ORB",
    "name": "ORB Token",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x4eeaa1fd27c50c64e77272bcdde68c28f0a3c3d7",
    "decimals": 18,
    "image": "https://rango.vip/i/tx7krB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.09886908118686875,
    "supportedSwappers": [
      "BeamSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MDAO",
    "name": "MarsDAO",
    "isPopular": false,
    "chainId": "56",
    "address": "0x60322971a672b81bcce5947706d22c19daecf6fb",
    "decimals": 18,
    "image": "https://rango.vip/i/P3Yrgn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.080439,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "KIMBO",
    "name": "KIMBO Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x184ff13b3ebcb25be44e860163a5d8391dd568c1",
    "decimals": 18,
    "image": "https://rango.vip/i/Xr0rrK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00008242,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FUSE",
    "name": "Fuse Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6b021b3f68491974be6d4009fee61a4e3c708fd6",
    "decimals": 18,
    "image": "https://rango.vip/i/DJgS8u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02537254,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUSE",
    "name": "Fuse Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d",
    "decimals": 18,
    "image": "https://rango.vip/i/kDo147",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02537254,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FUSE",
    "name": "Fuse Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xbdef0e9ef12e689f366fe494a7a7d0dad25d9286",
    "decimals": 18,
    "image": "https://rango.vip/i/ugm4ew",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.025460559048186324,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FUSE",
    "name": "Fuse Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6b021b3f68491974be6d4009fee61a4e3c708fd6",
    "decimals": 18,
    "image": "https://rango.vip/i/7zEMoJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02537254,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FUSE",
    "name": "Fuse Token on BSC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5857c96dae9cf8511b08cb07f85753c472d36ea3",
    "decimals": 18,
    "image": "https://rango.vip/i/I58cpw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02537254,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CAPS",
    "name": "Capsule Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xffba7529ac181c2ee1844548e6d7061c9a597df4",
    "decimals": 18,
    "image": "https://rango.vip/i/PTNypj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00434263,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAPS",
    "name": "Capsule Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03be5c903c727ee2c8c4e9bc0acc860cca4715e2",
    "decimals": 18,
    "image": "https://rango.vip/i/C2YXfY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00434263,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PSPS",
    "name": "BobaCat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03049b395147713ae53c0617093675b4b86dde78",
    "decimals": 18,
    "image": "https://rango.vip/i/oTHwmg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00949258,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNIBOT",
    "name": "Unibot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf819d9cb1c2a819fd991781a822de3ca8607c3c9",
    "decimals": 18,
    "image": "https://rango.vip/i/r7Idlp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.45,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ID",
    "name": "SPACE ID Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2dff88a56767223a5529ea5960da7a3f5f766406",
    "decimals": 18,
    "image": "https://rango.vip/i/ntiJKa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.319958,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ID",
    "name": "Everest ID",
    "isPopular": false,
    "chainId": "1",
    "address": "0xebd9d99a3982d547c5bb4db7e3b1f9f14b67eb83",
    "decimals": 18,
    "image": "https://rango.vip/i/vd6myj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04593145,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ID",
    "name": "SPACE ID",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2dff88a56767223a5529ea5960da7a3f5f766406",
    "decimals": 18,
    "image": "https://rango.vip/i/fKY6pj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.319958,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIPAD",
    "name": "AIPad",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe55d97a97ae6a17706ee281486e98a84095d8aaf",
    "decimals": 18,
    "image": "https://rango.vip/i/qWQ8sm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04681358,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIVI",
    "name": "Divi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x246908bff0b1ba6ecadcf57fb94f6ae2fcd43a77",
    "decimals": 8,
    "image": "https://rango.vip/i/zA7Msl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00133085,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CEL",
    "name": "Celsius",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd85d1e945766fea5eda9103f918bd915fbca63e6",
    "decimals": 4,
    "image": "https://rango.vip/i/JLJpTQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.140664,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XZK",
    "name": "Mystiko",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe8fc52b1bb3a40fd8889c0f8f75879676310ddf0",
    "decimals": 18,
    "image": "https://rango.vip/i/fAQAuS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02504088,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "REF",
    "name": "Ref Finance Token",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x221292443758f63563a1ed04b547278b05845fcb",
    "decimals": 18,
    "image": "https://rango.vip/i/juZImg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.15002171800036765,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REF",
    "name": "REFLECT AUDIT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd62baa6f3c579316b2798262a219b367c549c7dc",
    "decimals": 9,
    "image": "https://rango.vip/i/9KZajs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006168,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ISK",
    "name": "ISKRA Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xd85eff20288ca72ea9eecffb428f89ee5066ca5c",
    "decimals": 18,
    "image": "https://rango.vip/i/xQ74jx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.03748559,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ISK",
    "name": "ISKRA Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x17d2628d30f8e9e966c9ba831c9b9b01ea8ea75c",
    "decimals": 18,
    "image": "https://rango.vip/i/xQ74jx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03748559,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEX",
    "name": "KIRA Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16980b3b4a3f9d89e33311b5aa8f80303e5ca4f8",
    "decimals": 6,
    "image": "https://rango.vip/i/NlYC3Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02517938,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APM",
    "name": "apM Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc8c424b91d8ce0137bab4b832b7f7d154156ba6c",
    "decimals": 18,
    "image": "https://rango.vip/i/bqPHTi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00340324,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GBYTE",
    "name": "Obyte",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31f69de127c8a0ff10819c0955490a4ae46fcc2a",
    "decimals": 18,
    "image": "https://rango.vip/i/tNJvMz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VALOR",
    "name": "Smart Valor",
    "isPopular": false,
    "chainId": "1",
    "address": "0x297e4e5e59ad72b1b0a2fd446929e76117be0e0a",
    "decimals": 18,
    "image": "https://rango.vip/i/1clS4Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.100433,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XTM",
    "name": "Torum",
    "isPopular": false,
    "chainId": "56",
    "address": "0xcd1faff6e578fa5cac469d2418c95671ba1a62fe",
    "decimals": 18,
    "image": "https://rango.vip/i/ry21qv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.026057353438120334,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HEMULE",
    "name": "Hemule",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeaa63125dd63f10874f99cdbbb18410e7fc79dd3",
    "decimals": 18,
    "image": "https://rango.vip/i/NWyQaX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00512772,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FRM",
    "name": "Ferrum Network Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9f6abbf0ba6b5bfa27f4deb6597cc6ec20573fda",
    "decimals": 18,
    "image": "https://rango.vip/i/49uiqj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01881049,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRM",
    "name": "Ferrum Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5caef4af8780e59df925470b050fb23c43ca68c",
    "decimals": 6,
    "image": "https://rango.vip/i/zMEwuA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01881049,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FRM",
    "name": "Ferrum Network Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc",
    "decimals": 18,
    "image": "https://rango.vip/i/azGEk5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01881049,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FRM",
    "name": "Ferrum Network Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd99bafe5031cc8b345cb2e8c80135991f12d7130",
    "decimals": 18,
    "image": "https://rango.vip/i/XD3WBQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01881049,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "M87",
    "name": "MESSIER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80122c6a83c8202ea365233363d3f4837d13e888",
    "decimals": 18,
    "image": "https://rango.vip/i/SqWWka",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000561,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOGE",
    "name": "hoge.finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfad45e47083e4607302aa43c65fb3106f1cd7607",
    "decimals": 9,
    "image": "https://rango.vip/i/LQ90mn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000123,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "HOGE",
    "name": "Blast Hoge",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x548a6fe792015dd2a7827659d3feb8cf88cf1c79",
    "decimals": 18,
    "image": "https://rango.vip/i/hJP4GT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.0000021872350818539237,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MLT",
    "name": "Media Licensing Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9506d37f70eb4c3d79c398d326c871abbf10521d",
    "decimals": 18,
    "image": "https://rango.vip/i/FACStW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03968216,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MLT",
    "name": "Media Licensing Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb72a20c7b8bd666f80ac053b0f4de20a787080f5",
    "decimals": 18,
    "image": "https://rango.vip/i/xSmnIB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03968216,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEAM",
    "name": "Beam",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62d0a8458ed7719fdaf978fe5929c6d342b0bfce",
    "decimals": 18,
    "image": "https://rango.vip/i/O0SAnT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01187146,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BEAM",
    "name": "Beam",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x62d0a8458ed7719fdaf978fe5929c6d342b0bfce",
    "decimals": 18,
    "image": "https://rango.vip/i/VFBgY6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01190109568781409,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PIP",
    "name": "PIP",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HHjoYwUp5aU6pnrvN4s2pwEErwXNZKhxKGYjRJMoBjLw",
    "decimals": 9,
    "image": "https://rango.vip/i/5MA3pw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03017912,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GSWIFT",
    "name": "GameSwift",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x580e933d90091b9ce380740e3a4a39c67eb85b4c",
    "decimals": 18,
    "image": "https://rango.vip/i/Jh68ge",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.08,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GSWIFT",
    "name": "GameSwift",
    "isPopular": false,
    "chainId": "1",
    "address": "0x580e933d90091b9ce380740e3a4a39c67eb85b4c",
    "decimals": 18,
    "image": "https://rango.vip/i/ZkQLCZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.08,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UBT",
    "name": "UniBright",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e",
    "decimals": 8,
    "image": "https://rango.vip/i/S6DdD5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03291832,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "UBT",
    "name": "UniBright",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2ad62674a64e698c24831faf824973c360430140",
    "decimals": 8,
    "image": "https://rango.vip/i/ALFJYl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.031873373552744194,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIX",
    "name": "VIXCO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0b68eb52c89e3fffa62d78012ac8b661bfaa323",
    "decimals": 18,
    "image": "https://rango.vip/i/0iGkMd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00605603,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RPK",
    "name": "RepubliK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x313cae7ad4454aac7b208c1f089da2b0e5825e46",
    "decimals": 18,
    "image": "https://rango.vip/i/RtHLj6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00597157,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WITCH",
    "name": "Witch Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc524e3c6910257744c1f93cf15e9f472b5bd236",
    "decimals": 18,
    "image": "https://rango.vip/i/wDTX29",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.170022,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "WITCH",
    "name": "WITCH",
    "isPopular": false,
    "chainId": "128",
    "address": "0xf71a7d20c6fc34a3bf362780aee0fc9ee3ece5fe",
    "decimals": 18,
    "image": "https://rango.vip/i/NHibFk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.11273313053027434,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GENE",
    "name": "Genopets",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz",
    "decimals": 9,
    "image": "https://rango.vip/i/EFhNFO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.085973,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GENE",
    "name": "GenomesDAO (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x34667ed7c36cbbbf2d5d5c5c8d6eb76a094edb9f",
    "decimals": 18,
    "image": "https://rango.vip/i/h96Fsz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00040983,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GENE",
    "name": "GenomesDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x21413c119b0c11c5d96ae1bd328917bc5c8ed67e",
    "decimals": 18,
    "image": "https://rango.vip/i/Xo1ZIt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040983,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SQR",
    "name": "Magic Square",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2b72867c32cf673f7b02d208b26889fed353b1f8",
    "decimals": 8,
    "image": "https://rango.vip/i/IZkTeP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.058807,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QOM",
    "name": "Shiba Predator",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa71d0588eaf47f12b13cf8ec750430d21df04974",
    "decimals": 18,
    "image": "https://rango.vip/i/ZQAFmu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.919e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "IMGNAI",
    "name": "imgnAI",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x18e692c03de43972fe81058f322fa542ae1a5e2c",
    "decimals": 9,
    "image": "https://rango.vip/i/RoHEGv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00600336,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IMGNAI",
    "name": "imgnAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa735a3af76cc30791c61c10d585833829d36cbe0",
    "decimals": 9,
    "image": "https://rango.vip/i/RoHEGv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00600336,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLANET",
    "name": "Planet Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2ad9addd0d97ec3cdba27f92bf6077893b76ab0b",
    "decimals": 18,
    "image": "https://rango.vip/i/ED3ONI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000538,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PLANET",
    "name": "PLANET",
    "isPopular": false,
    "chainId": "56",
    "address": "0xca6d678e74f553f0e59cccc03ae644a3c2c5ee7d",
    "decimals": 18,
    "image": "https://rango.vip/i/d8HQXe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00000538,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLU",
    "name": "Pluton",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd8912c10681d8b21fd3742244f44658dba12264e",
    "decimals": 18,
    "image": "https://rango.vip/i/KiBvMQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.45,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PTU",
    "name": "Pintu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc229c69eb3bb51828d0caa3509a05a51083898dd",
    "decimals": 18,
    "image": "https://rango.vip/i/n8FzDN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.19264,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLY",
    "name": "Blocery",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf8ad7dfe656188a23e89da09506adf7ad9290d5d",
    "decimals": 18,
    "image": "https://rango.vip/i/qMBnlD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00495503,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DIO",
    "name": "Decimated",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD",
    "decimals": 9,
    "image": "https://rango.vip/i/xTiLpW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01001971,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MANC",
    "name": "Mancium",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0c05ec44775e4ad62cdc2eecdf337aa7a143363",
    "decimals": 2,
    "image": "https://rango.vip/i/NvEwU2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.500901,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "XDEFI",
    "name": "XDEFI",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x180f7cf38805d1be95c7632f653e26b0838e2969",
    "decimals": 18,
    "image": "https://rango.vip/i/ElZtL9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.04973045,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XDEFI",
    "name": "XDEFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72b886d09c117654ab7da13a14d603001de0b777",
    "decimals": 18,
    "image": "https://rango.vip/i/o0vTKp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04973045,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DOME",
    "name": "Everdome",
    "isPopular": false,
    "chainId": "56",
    "address": "0x475bfaa1848591ae0e6ab69600f48d828f61a80e",
    "decimals": 18,
    "image": "https://rango.vip/i/TM62J2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00022703,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TSUKA",
    "name": "Dejitaru Tsuka",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc5fb36dd2fb59d3b98deff88425a3f425ee469ed",
    "decimals": 9,
    "image": "https://rango.vip/i/TbsMIe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00432003,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OOKS",
    "name": "Onooks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69d9905b2e5f6f5433212b7f3c954433f23c1572",
    "decimals": 18,
    "image": "https://rango.vip/i/zsnA2r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.382146,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAX",
    "name": "BABB",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf920e4f3fbef5b3ad0a25017514b769bdc4ac135",
    "decimals": 18,
    "image": "https://rango.vip/i/0tRPpI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005758,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ELIX",
    "name": "Elixir Games",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5cbq1HriesW4zHpFEk9Gc8UT4ccmfHcBTDCa2XcBduTo",
    "decimals": 9,
    "image": "https://rango.vip/i/QyrN95",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01959878,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPTI",
    "name": "Optimus AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x562e362876c8aee4744fc2c6aac8394c312d215d",
    "decimals": 9,
    "image": "https://rango.vip/i/t3044P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04441659,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MARSH",
    "name": "UnmarshalToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37",
    "decimals": 18,
    "image": "https://rango.vip/i/62Cw8g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.075232,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MARSH",
    "name": "UnmarshalToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2fa5daf6fe0708fbd63b1a7d1592577284f52256",
    "decimals": 18,
    "image": "https://rango.vip/i/gc6yXr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.075232,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ZF",
    "name": "zkSwap Finance",
    "isPopular": false,
    "chainId": "324",
    "address": "0x31c2c031fdc9d33e974f327ab0d9883eae06ca4a",
    "decimals": 18,
    "image": "https://rango.vip/i/KxXrRl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00972229,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOA",
    "name": "BOSagora",
    "isPopular": false,
    "chainId": "1",
    "address": "0x746dda2ea243400d5a63e0700f190ab79f06489e",
    "decimals": 7,
    "image": "https://rango.vip/i/NDDb5d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01347245,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LBL",
    "name": "LABEL AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2162f572b25f7358db9376ab58a947a4e45cede1",
    "decimals": 18,
    "image": "https://rango.vip/i/VKMU6G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00218474,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DFL",
    "name": "DeFi Land",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh",
    "decimals": 9,
    "image": "https://rango.vip/i/pSyZsg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00058636,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DFL",
    "name": "DeFIL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x09ce2b746c32528b7d864a1e3979bd97d2f095ab",
    "decimals": 18,
    "image": "https://rango.vip/i/EGUdZx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00082859,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CATBOY",
    "name": "Catboy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0dcee5f694e492f0dd842a7fbe5bed4c6e4665a6",
    "decimals": 18,
    "image": "https://rango.vip/i/dTYY0s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02184801,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NETVR",
    "name": "Netvrk",
    "isPopular": false,
    "chainId": "1",
    "address": "0x52498f8d9791736f1d6398fe95ba3bd868114d10",
    "decimals": 18,
    "image": "https://rango.vip/i/7YZYK4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.050003,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MKUSD",
    "name": "Prisma mkUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4591dbff62656e7859afe5e45f6f47d3669fbb28",
    "decimals": 18,
    "image": "https://rango.vip/i/PHLIoY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997446,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNC",
    "name": "SunContract",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf4134146af2d511dd5ea8cdb1c4ac88c57d60404",
    "decimals": 18,
    "image": "https://rango.vip/i/Riec36",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.05619,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BLS",
    "name": "BloodLoop Shard",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x46b9144771cb3195d66e4eda643a7493fadcaf9d",
    "decimals": 18,
    "image": "https://rango.vip/i/b6JjfQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.1748702777230931,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "RADAR",
    "name": "DappRadar",
    "isPopular": false,
    "chainId": "25",
    "address": "0xa58e3aeaea3292c3e260378e55e9684c59e7a27a",
    "decimals": 18,
    "image": "https://rango.vip/i/RkE11v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.004313283295977085,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RADAR",
    "name": "DappRadar",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdcb72ae4d5dc6ae274461d57e65db8d50d0a33ad",
    "decimals": 18,
    "image": "https://rango.vip/i/fjBxbo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00423812,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RADAR",
    "name": "Radar",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8",
    "decimals": 18,
    "image": "https://rango.vip/i/KxaYdd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00290327,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RADAR",
    "name": "DappRadar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44709a920fccf795fbc57baa433cc3dd53c44dbe",
    "decimals": 18,
    "image": "https://rango.vip/i/wnlbFt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00423812,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CBG",
    "name": "Chainbing",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1900e8b5619a3596745f715d0427fe617c729ba9",
    "decimals": 18,
    "image": "https://rango.vip/i/NfGSmf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.119175,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DHT",
    "name": "dHedge DAO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xca1207647ff814039530d7d35df0e1dd2e91fa84",
    "decimals": 18,
    "image": "https://rango.vip/i/1rS91Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.075882,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DHT",
    "name": "dHedge DAO Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8038f3c971414fd1fc220ba727f2d4a0fc98cb65",
    "decimals": 18,
    "image": "https://rango.vip/i/YkMCnF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.075882,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DHT",
    "name": "dHedge DAO Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8c92e38eca8210f4fcbf17f0951b198dd7668292",
    "decimals": 18,
    "image": "https://rango.vip/i/nfxc26",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.075882,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "DHT",
    "name": "dHEDGE DAO Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xaf9fe3b5ccdae78188b1f8b9a49da7ae9510f151",
    "decimals": 18,
    "image": "https://rango.vip/i/Fi2SqZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.075882,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DEFIT",
    "name": "Digital Fitness",
    "isPopular": false,
    "chainId": "137",
    "address": "0x428360b02c1269bc1c79fbc399ad31d58c1e8fda",
    "decimals": 18,
    "image": "https://rango.vip/i/I8HX2k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.137998,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PAJAMAS",
    "name": "The First Youtube Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FvER7SsvY5GqAMawf7Qfb5MnUUmDdbPNPg4nCa4zHoLw",
    "decimals": 9,
    "image": "https://rango.vip/i/pzurji",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00403884,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "BEPRO",
    "name": "BEPRO Network",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xcb4a593ce512d78162c58384f0b2fd6e802c2c47",
    "decimals": 18,
    "image": "https://rango.vip/i/7T1RV9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.0003970765540986719,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEPRO",
    "name": "BetProtocolToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcf3c8be2e2c42331da80ef210e9b1b307c03d36a",
    "decimals": 18,
    "image": "https://rango.vip/i/tIq2YF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00039763,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MSI",
    "name": "Matrix Solana Index",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd",
    "decimals": 6,
    "image": "https://rango.vip/i/6t02fY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000013247956799365083,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MSI",
    "name": "Martin Shkreli Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4c75f2a0cb1a9acc33929512dc9733ea1fd6fde",
    "decimals": 18,
    "image": "https://rango.vip/i/di9wnk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.71616e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MSI",
    "name": "Monkey Shit Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2be8e422cb4a5a7f217a8f1b0658952a79132f28",
    "decimals": 18,
    "image": "https://rango.vip/i/0KZkdi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000141,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRACY",
    "name": "Gracy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c95e7ad2b349dc2f82d0f1117a44b561fa2699a",
    "decimals": 18,
    "image": "https://rango.vip/i/p4Fo1v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03179051,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGLA",
    "name": "Angola",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f7e5118521b550bb1a9b435727c003eb033fc51",
    "decimals": 18,
    "image": "https://rango.vip/i/Fe2b6M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00807551,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RIO",
    "name": "Realio",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf21661d0d1d76d3ecb8e1b9f1c923dbfffae4097",
    "decimals": 18,
    "image": "https://rango.vip/i/O2C2Qt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.587003,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RISE",
    "name": "EverRise",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc17c30e98541188614df99239cabd40280810ca3",
    "decimals": 18,
    "image": "https://rango.vip/i/ZqTLRb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0000644,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RISE",
    "name": "EverRise",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc17c30e98541188614df99239cabd40280810ca3",
    "decimals": 18,
    "image": "https://rango.vip/i/dcbZgw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000644,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RISE",
    "name": "EverRise",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc17c30e98541188614df99239cabd40280810ca3",
    "decimals": 18,
    "image": "https://rango.vip/i/sx6fSi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0000644,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STAT",
    "name": "STAT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4fc15c91a9c4a9efb404174464687e8e128730c2",
    "decimals": 18,
    "image": "https://rango.vip/i/jI4TJX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.074948,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCRT",
    "name": "MagicCraft",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde16ce60804a881e9f8c4ebb3824646edecd478d",
    "decimals": 9,
    "image": "https://rango.vip/i/1tU5vc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0008802,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MCRT",
    "name": "MagicCraft",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4b8285ab433d8f69cb48d5ad62b415ed1a221e4f",
    "decimals": 9,
    "image": "https://rango.vip/i/6iGuAS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0008802,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MASQ",
    "name": "MASQ (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xee9a352f6aac4af1a5b9f467f6a93e0ffbe9dd35",
    "decimals": 18,
    "image": "https://rango.vip/i/hlNfY1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.110448,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MASQ",
    "name": "MASQ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06f3c323f0238c72bf35011071f2b5b7f43a054c",
    "decimals": 18,
    "image": "https://rango.vip/i/aostZw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.110448,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QDFI",
    "name": "Qudefi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x405919920765e8d30822db9342b62bb0f92976e8",
    "decimals": 18,
    "image": "https://rango.vip/i/iEtQdn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04967513,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SENATE",
    "name": "SENATE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34be5b8c30ee4fde069dc878989686abe9884470",
    "decimals": 18,
    "image": "https://rango.vip/i/xTdWiE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03149181,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CQT",
    "name": "Covalent Query Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd417144312dbf50465b1c641d016962017ef6240",
    "decimals": 18,
    "image": "https://rango.vip/i/IPRyRH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0044474,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CQT",
    "name": "Covalent",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x69b937db799a9becc9e8a6f0a5d36ea3657273bf",
    "decimals": 18,
    "image": "https://rango.vip/i/kH2xzq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.004419144861900577,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BSCPAD",
    "name": "BSCPAD.com",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5a3010d4d8d3b5fb49f8b6e57fb9e48063f16700",
    "decimals": 18,
    "image": "https://rango.vip/i/SLXg9i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.04774451,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CPU",
    "name": "CPUcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d52dfefb16bb9cdc78bfca09061e44574886626",
    "decimals": 18,
    "image": "https://rango.vip/i/2TR6ak",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00860258,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CPU",
    "name": "ComputingAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x994bf19e32584255d82896d1477488e87012f209",
    "decimals": 9,
    "image": "https://rango.vip/i/G2SEXX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00055402,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SMILEK",
    "name": "Smilek",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7x4FgiFfeqzs1tiUNvJzpt47GtLXAJ8Jfn8G1Hyyu6JH",
    "decimals": 6,
    "image": "https://rango.vip/i/iULW6p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000191,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "OOE",
    "name": "OpenOcean",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x0ebd9537a25f56713e34c45b38f421a1e7191469",
    "decimals": 18,
    "image": "https://rango.vip/i/8FJHPi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.012791414914234897,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KCT",
    "name": "Konnect",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c601dc69affb0d4fc8de1ac303705e432a4a27e",
    "decimals": 18,
    "image": "https://rango.vip/i/KLJS1j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00168302,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HDN",
    "name": "Hydranet",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3404149e9ee6f17fb41db1ce593ee48fbdcd9506",
    "decimals": 18,
    "image": "https://rango.vip/i/RMa7z2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02346693,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "XRT",
    "name": "XRT",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TLMgXeSPVQsBXQjUf6QXn575h8SEK782oV",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.00000491,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XRT",
    "name": "Robonomics",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7de91b204c1c737bcee6f000aaa6569cf7061cb7",
    "decimals": 9,
    "image": "https://rango.vip/i/LlxPxa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.17,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "NOM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/b9606d347599f0f2fdf82ba3ee339000673b7d274ea50f59494dc51efcd42163",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/NOM.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.02772912,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "NOM",
    "name": "Nomad",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6776caccfdcd0dfd5a38cb1d0b3b39a4ca9283ce",
    "decimals": 18,
    "image": "https://rango.vip/i/TBXjFb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0000102,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUSE",
    "name": "Muse DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81",
    "decimals": 18,
    "image": "https://rango.vip/i/zwkxxP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.2,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "MUSE",
    "name": "MUSE",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1p8x807f6h222ur0vssqy3qk6mcpa40gw2pchquz5atl935t7kvyq894ne3",
    "decimals": 6,
    "image": "https://rango.vip/i/QJcUeW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 7.154214363635037,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NHT",
    "name": "Neighbourhoods",
    "isPopular": false,
    "chainId": "1",
    "address": "0x84342e932797fc62814189f01f0fb05f52519708",
    "decimals": 18,
    "image": "https://rango.vip/i/0fe35i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00045474,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUSH",
    "name": "Ethereum Push Notification Service",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf418588522d5dd018b425e472991e52ebbeeeeee",
    "decimals": 18,
    "image": "https://rango.vip/i/ZUExw0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.059506,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PUSH",
    "name": "Ethereum Push Notification Service",
    "isPopular": false,
    "chainId": "137",
    "address": "0x58001cc1a9e17a20935079ab40b1b8f4fc19efd1",
    "decimals": 18,
    "image": "https://rango.vip/i/I2ooer",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.059506,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EBULL",
    "name": "ETHEREUM IS GOOD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x71297312753ea7a2570a5a3278ed70d9a75f4f44",
    "decimals": 9,
    "image": "https://rango.vip/i/nCokQY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033527,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAI",
    "name": "Rai Reflex Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03ab458634910aad20ef5f1c8ee96f1d6ac54919",
    "decimals": 18,
    "image": "https://rango.vip/i/IP0VKI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.98,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "RAI",
    "name": "Rai Reflex Index",
    "isPopular": false,
    "chainId": "10",
    "address": "0x7fb688ccf682d58f86d7e38e03f9d22e7705448b",
    "decimals": 18,
    "image": "https://rango.vip/i/DTAnaW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2.98,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RAI",
    "name": "Rai Reflex Index",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xaef5bbcbfa438519a5ea80b4c7181b4e78d419f2",
    "decimals": 18,
    "image": "https://rango.vip/i/NnA5W1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2.969131151797245,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RAI",
    "name": "Rai Reflex Index (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x00e5646f60ac6fb446f621d146b6e1886f002905",
    "decimals": 18,
    "image": "https://rango.vip/i/RlyeWu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2.98,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FRA",
    "name": "France Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F9mv7XXbrXZb1sP2JUoswbCB3WHQM4QGMFDTVfnRZMnP",
    "decimals": 6,
    "image": "https://rango.vip/i/9ZO7Ug",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003079,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CUMMIES",
    "name": "CumRocket",
    "isPopular": false,
    "chainId": "56",
    "address": "0x27ae27110350b98d564b9a3eed31baebc82d878d",
    "decimals": 18,
    "image": "https://rango.vip/i/EZnI8q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00270003,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JOB",
    "name": "Jobchain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdfbc9050f5b01df53512dcc39b4f2b2bbacd517a",
    "decimals": 8,
    "image": "https://rango.vip/i/DTeUdh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00035975,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GSWAP",
    "name": "gameswap.org",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaac41ec512808d64625576eddd580e7ea40ef8b2",
    "decimals": 18,
    "image": "https://rango.vip/i/j8orco",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.284573,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FSN",
    "name": "FUSION",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9afc975edb8a0b57f066e8e0a72a5e2adbdcb605",
    "decimals": 18,
    "image": "https://rango.vip/i/pcJ1bZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04417253,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESE",
    "name": "eesee",
    "isPopular": false,
    "chainId": "1",
    "address": "0x908ddb096bfb3acb19e2280aad858186ea4935c4",
    "decimals": 18,
    "image": "https://rango.vip/i/euYrqq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01890603,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "ESE",
    "name": "Eesee",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x491e6de43b55c8eae702edc263e32339da42f58c",
    "decimals": 18,
    "image": "https://rango.vip/i/euYrqq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.01890603,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DICE",
    "name": "Party Dice",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xaeeba475edc438f8eeb6bfbc3164c1c7716fb304",
    "decimals": 18,
    "image": "https://rango.vip/i/VLKpgk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.010767427171535206,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DICE",
    "name": "Dice.finance Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x748ad98b14c814b28812eb42ad219c8672909879",
    "decimals": 18,
    "image": "https://rango.vip/i/QTWl2A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.010767427171535206,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHKN",
    "name": "Chickencoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd55210bb6898c021a19de1f58d27b71f095921ee",
    "decimals": 18,
    "image": "https://rango.vip/i/8B3Cdi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.293e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "BOOT",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/fe2cd1e6828ec0fab8af39bac45bc25b965ba67ccbc50c13a14bd610b0d1e2c4",
    "decimals": 0,
    "image": "https://rango.vip/tokens/ALL/BOOT.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 5.780802373027461e-9,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COVAL",
    "name": "Circuits of Value",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d658390460295fb963f54dc0899cfb1c30776df",
    "decimals": 8,
    "image": "https://rango.vip/i/vpiyc9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00189388,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PERC",
    "name": "Perion Credits",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60be1e1fe41c1370adaf5d8e66f07cf1c2df2268",
    "decimals": 18,
    "image": "https://rango.vip/i/koxwM9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.253894,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOCX",
    "name": "BLOCX ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4ff57e25eeb7affbbb060e0bad2e1759efc8bec4",
    "decimals": 18,
    "image": "https://rango.vip/i/Jok9BA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.060433,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "FER",
    "name": "Ferro",
    "isPopular": false,
    "chainId": "25",
    "address": "0x39bc1e38c842c60775ce37566d03b41a7a66c782",
    "decimals": 18,
    "image": "https://rango.vip/i/NFnmLQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00362987,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MV",
    "name": "Metaverse (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa3c322ad15218fbfaed26ba7f616249f7705d945",
    "decimals": 18,
    "image": "https://rango.vip/i/20OSP8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00982287,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MV",
    "name": "GensoKishi Metaverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae788f80f2756a86aa2f410c651f2af83639b95b",
    "decimals": 18,
    "image": "https://rango.vip/i/x46afK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00982287,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MV",
    "name": "GensoKishi Metaverse",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x5445972e76c5e4cedd12b6e2bcef69133e15992f",
    "decimals": 18,
    "image": "https://rango.vip/i/PrVUAe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00990219082637917,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HVH",
    "name": "HAVAH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd076c4ba62c57b3fa10800bcfd8da66742110e0e",
    "decimals": 18,
    "image": "https://rango.vip/i/DQM9G2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00732052,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHR",
    "name": "Share",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd98f75b1a3261dab9eed4956c93f33749027a964",
    "decimals": 2,
    "image": "https://rango.vip/i/tGn0Bw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00139001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KDAG",
    "name": "King DAG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95e40e065afb3059dcabe4aaf404c1f92756603a",
    "decimals": 18,
    "image": "https://rango.vip/i/6RjKQN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.105926,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VMINT",
    "name": "VoluMint",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7b2c1a7f3c67fb0ea57a7ef29bc1f18d7be3195",
    "decimals": 18,
    "image": "https://rango.vip/i/lI68C6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00589832,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RMRK",
    "name": "RMRK",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x524d524b4c9366be706d3a90dcf70076ca037ae3",
    "decimals": 18,
    "image": "https://rango.vip/i/KBZshP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.337917,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RMRK",
    "name": "RMRK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x524d524b4c9366be706d3a90dcf70076ca037ae3",
    "decimals": 18,
    "image": "https://rango.vip/i/KBZshP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.337917,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EML",
    "name": "EML Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03dde9e5bb31ee40a471476e2fccf75c67921062",
    "decimals": 18,
    "image": "https://rango.vip/i/FcInST",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.080977,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BTCST",
    "name": "Standard BTC Hashrate Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x78650b139471520656b9e7aa7a5e9276814a38e9",
    "decimals": 17,
    "image": "https://rango.vip/i/v575nK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.264414,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROOBEE",
    "name": "Roobee",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa31b1767e09f842ecfd4bc471fe44f830e3891aa",
    "decimals": 18,
    "image": "https://rango.vip/i/yU485W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00077503,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "5IRE",
    "name": "5ire",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3bd7d4f524d09f4e331577247a048d56e4b67a7f",
    "decimals": 18,
    "image": "https://rango.vip/i/6KJbVu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00562878,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ACA",
    "name": "ACA",
    "isPopular": false,
    "chainId": "25",
    "address": "0x213e6fb02009c13692baa23c63fde8d623d22705",
    "decimals": 12,
    "image": "https://rango.vip/i/nm8PBX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.057060593104056645,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WEFI",
    "name": "Wefi",
    "isPopular": false,
    "chainId": "137",
    "address": "0xffa188493c15dfaf2c206c97d8633377847b6a52",
    "decimals": 18,
    "image": "https://rango.vip/i/0XW0EW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.074655,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WEFI",
    "name": "Wefi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xffa188493c15dfaf2c206c97d8633377847b6a52",
    "decimals": 18,
    "image": "https://rango.vip/i/vbJed7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.074655,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YOM",
    "name": "YOM",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "yomFPUqz1wJwYSfD5tZJUtS3bNb8xs8mx9XzBv8RL39",
    "decimals": 9,
    "image": "https://rango.vip/i/mD77gy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03000712,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NVIR",
    "name": "NvirWorld",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d71ce49ab8a0e6d2a1e7bfb89374c9392fd6804",
    "decimals": 18,
    "image": "https://rango.vip/i/uhgPzn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0029209,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LAMB",
    "name": "LAMB by OPNX",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xdb13df2ea134e7df2208d74b96db063837db5b5c",
    "decimals": 18,
    "image": "https://rango.vip/i/C60LYn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0140567,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LAMB",
    "name": "Lambda",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8971f9fd7196e5cee2c1032b50f656855af7dd26",
    "decimals": 18,
    "image": "https://rango.vip/i/dBZN4j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00186534,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "LAMB",
    "name": "LAMB by OPNX",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xdb13df2ea134e7df2208d74b96db063837db5b5c",
    "decimals": 18,
    "image": "https://rango.vip/i/C60LYn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.0140567,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GARI",
    "name": "Gari",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CKaKtYvz6dKPyMvYq9Rh3UBrnNqYZAyd7iF4hJtjUvks",
    "decimals": 9,
    "image": "https://rango.vip/i/JHS2SD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00646623,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "HAM",
    "name": "HAM",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xd3a61a3a9b8e0dd8160c9bf5ea79159e3e2452b8",
    "decimals": 18,
    "image": "https://rango.vip/i/0ne1Ov",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.000036000719917944385,
    "supportedSwappers": [
      "SushiBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LSS",
    "name": "Lossless Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b9be07d622accaed78f479bc0edabfd6397e320",
    "decimals": 18,
    "image": "https://rango.vip/i/bLi2Ke",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.052464,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PMPY",
    "name": "Prometheum Prodigy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1123d17fcf93ed2b41440317503346a0fdfe3ed7",
    "decimals": 18,
    "image": "https://rango.vip/i/bwKQ7q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0059454,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HI",
    "name": "hi Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4f6e93aeddc11dc22268488465babcaf09399ac",
    "decimals": 18,
    "image": "https://rango.vip/i/a8ivSZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00048657,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HI",
    "name": "hi Dollar",
    "isPopular": false,
    "chainId": "56",
    "address": "0x77087ab5df23cfb52449a188e80e9096201c2097",
    "decimals": 18,
    "image": "https://rango.vip/i/Ry2mOT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00048657,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PKF",
    "name": "PolkaFoundry",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b39b70e39aa811b69365398e0aace9bee238aeb",
    "decimals": 18,
    "image": "https://rango.vip/i/06ScYb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02465783,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LNDX",
    "name": "LandX Governance Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe10d4a4255d2d35c9e23e2c4790e073046fbaf5c",
    "decimals": 6,
    "image": "https://rango.vip/i/XkL5Ox",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.331927,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LNDX",
    "name": "LandX Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x08a1c30bbb26425c1031ee9e43fa0b9960742539",
    "decimals": 6,
    "image": "https://rango.vip/i/XkL5Ox",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.331927,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CGV",
    "name": "Cogito Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaef420fd77477d9dc8b46d704d44dd09d6c27866",
    "decimals": 6,
    "image": "https://rango.vip/i/Txucvx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02866528,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OBI",
    "name": "Orbofi AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb3a8fd6ec4bf0fdc6cd2739b1e41192d12b1873",
    "decimals": 18,
    "image": "https://rango.vip/i/zoAJwY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00672047,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPH",
    "name": "Spheroid",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0cf46eb152656c7090e769916eb44a138aaa406",
    "decimals": 18,
    "image": "https://rango.vip/i/FIgRHx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00140339,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ELON",
    "name": "Dogelon Mars",
    "isPopular": false,
    "chainId": "25",
    "address": "0x02dccaf514c98451320a9365c5b46c61d3246ff3",
    "decimals": 18,
    "image": "https://rango.vip/i/0tB1KF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 1.21647e-7,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELON",
    "name": "Dogelon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3",
    "decimals": 18,
    "image": "https://rango.vip/i/rXJyxQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.21647e-7,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ELON",
    "name": "Dogelon Mars",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7bd6fabd64813c48545c9c0e312a0099d9be2540",
    "decimals": 18,
    "image": "https://rango.vip/i/PvWGF1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.21647e-7,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ELON",
    "name": "Dogelon (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7ZCm8WBN9aLa3o47SoYctU6iLdj7wkGG5SV2hE5CgtD5",
    "decimals": 4,
    "image": "https://rango.vip/i/UMs8Iq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.21647e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ELON",
    "name": "Dogelon",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe0339c80ffde91f3e20494df88d4206d86024cdf",
    "decimals": 18,
    "image": "https://rango.vip/i/8JZUue",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.21647e-7,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELON",
    "name": "Elon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69420e3a3aa9e17dea102bb3a9b3b73dcddb9528",
    "decimals": 9,
    "image": "https://rango.vip/i/fnqkse",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001178,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ELON",
    "name": "Dogelon Mars",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3e4cff6e50f37f731284a92d44ae943e17077fd4",
    "decimals": 18,
    "image": "https://rango.vip/i/pkiz5h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.2264886861659443e-7,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RJV",
    "name": "Rejuve Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1f410f13b6007fca76833ee7eb58478d47bc5ef",
    "decimals": 6,
    "image": "https://rango.vip/i/kpXGIa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01113693,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAD",
    "name": "DAD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5b322514ff727253292637d9054301600c2c81e8",
    "decimals": 9,
    "image": "https://rango.vip/i/ekL3hr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00389756,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAAS",
    "name": "BaaSid",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d929aa919e489505ccaad8a199619c6dca0c2de",
    "decimals": 18,
    "image": "https://rango.vip/i/yrrZ51",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00034839,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VSP",
    "name": "VesperToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1b40183efb4dd766f11bda7a7c3ad8982e998421",
    "decimals": 18,
    "image": "https://rango.vip/i/PuJWoH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.294957,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SIN",
    "name": "sinDAO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9",
    "decimals": 6,
    "image": "https://rango.vip/i/dmfANv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 8.4793e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SLN",
    "name": "Smart Layer Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb82c0d91e057e05600c8f8dc836beb41da6df14",
    "decimals": 18,
    "image": "https://rango.vip/i/8DOQ5Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.116885,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRG",
    "name": "Bridge Oracle",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc9c4fd7579133701fa2769b6955e7e56bb386db1",
    "decimals": 18,
    "image": "https://rango.vip/i/zs8vGx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033504,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLCEX",
    "name": "SolCex",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AMjzRn1TBQwQfNAjHFeBb7uGbbqbJB7FzXAnGgdFPk6K",
    "decimals": 6,
    "image": "https://rango.vip/i/CusRR2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00535566,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RMV",
    "name": "RealityMetaverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x423352f2c6e0e72422b69af03aba259310146d90",
    "decimals": 18,
    "image": "https://rango.vip/i/Fgk1hw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01035258,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHEEMS",
    "name": "Cheems",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc",
    "decimals": 4,
    "image": "https://rango.vip/i/8Xf87j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00101174,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VEUR",
    "name": "VNX Euro",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "C4Kkr9NZU3VbyedcgutU6LKmi6MKz81sx6gRmk5pX519",
    "decimals": 9,
    "image": "https://rango.vip/i/W4AZHt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VEUR",
    "name": "VNX EURO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6ba75d640bebfe5da1197bb5a2aff3327789b5d3",
    "decimals": 18,
    "image": "https://rango.vip/i/qhxUYT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WWY",
    "name": "WeWay",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ab70e92319f0b9127df78868fd3655fb9f1e322",
    "decimals": 18,
    "image": "https://rango.vip/i/QnxrNq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00176019,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WWY",
    "name": "WeWay Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9ab70e92319f0b9127df78868fd3655fb9f1e322",
    "decimals": 18,
    "image": "https://rango.vip/i/zCqnrS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00176019,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MULTI",
    "name": "Multichain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65ef703f5594d2573eb71aaf55bc0cb548492df4",
    "decimals": 18,
    "image": "https://rango.vip/i/vvXmoy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.466977,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MULTI",
    "name": "Multichain",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
    "decimals": 18,
    "image": "https://rango.vip/i/owvK1a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.159294,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BF",
    "name": "Bitforex",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5b71bee9d961b1b848f8485eec8d8787f80217f5",
    "decimals": 18,
    "image": "https://rango.vip/i/45oQuy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00106522,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELAND",
    "name": "Etherland",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33e07f5055173cf8febede8b21b12d1e2b523205",
    "decimals": 18,
    "image": "https://rango.vip/i/aRzpuH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.061244,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HSF",
    "name": "Hillstone Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba6b0dbb2ba8daa8f5d6817946393aef8d3a4487",
    "decimals": 18,
    "image": "https://rango.vip/i/H9EUsW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.079064,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOZ",
    "name": "MOZFIRE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0b0af14d5eecc2a7d7d723208593379e349a25c",
    "decimals": 9,
    "image": "https://rango.vip/i/N4y0ME",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001161,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOWER",
    "name": "TOWER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c9922314ed1415c95b9fd453c3818fd41867d0b",
    "decimals": 18,
    "image": "https://rango.vip/i/Vlr7kK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00135727,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TOWER",
    "name": "TOWER",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe7c9c6bc87b86f9e5b57072f907ee6460b593924",
    "decimals": 18,
    "image": "https://rango.vip/i/1onHQr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00135727,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SENSO",
    "name": "SENSO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc19b6a4ac7c7cc24459f08984bbd09664af17bd1",
    "decimals": 0,
    "image": "https://rango.vip/i/pU7Po1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0322468,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PMT",
    "name": "PMT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x68ae2f202799be2008c89e2100257e66f77da1f3",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.055299,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVERY",
    "name": "Everyworld",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9afa9999e45484adf5d8eed8d9dfe0693bacd838",
    "decimals": 18,
    "image": "https://rango.vip/i/Yc0MEl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00268945,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTLX",
    "name": "Mettalex",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14",
    "decimals": 18,
    "image": "https://rango.vip/i/DHNMiZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.95,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "IDLE",
    "name": "Idle",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc25351811983818c9fe6d8c580531819c8ade90f",
    "decimals": 18,
    "image": "https://rango.vip/i/kYlcNS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.250955,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDLE",
    "name": "Idle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x875773784af8135ea0ef43b5a374aad105c5d39e",
    "decimals": 18,
    "image": "https://rango.vip/i/6OB9v6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.250955,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MVP",
    "name": "MAGA VP",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x58cd93c4a91c3940109fa27d700f5013b18b5dc2",
    "decimals": 18,
    "image": "https://rango.vip/i/S39lBY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.052961,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MVP",
    "name": "MAGA VP (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "55qMv1HtV8fqRjnFwDb9yDi9tBCeV8xwfgrPKgiJk5DN",
    "decimals": 8,
    "image": "https://rango.vip/i/R9uebG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.052961,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MVP",
    "name": "MAGA VP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x766d2fcece1e3eef32aae8711ab886ee95fd5b2a",
    "decimals": 18,
    "image": "https://rango.vip/i/S39lBY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.052961,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PEEL",
    "name": "Meta Apes Peel",
    "isPopular": false,
    "chainId": "56",
    "address": "0x734548a9e43d2d564600b1b2ed5be9c2b911c6ab",
    "decimals": 18,
    "image": "https://rango.vip/i/q48ORW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01429363,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COOKIE",
    "name": "Cookie",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0041ef357b183448b235a8ea73ce4e4ec8c265f",
    "decimals": 18,
    "image": "https://rango.vip/i/S4Qmvi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02909646,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TPY",
    "name": "Thrupenny",
    "isPopular": false,
    "chainId": "1",
    "address": "0x968cbe62c830a0ccf4381614662398505657a2a9",
    "decimals": 8,
    "image": "https://rango.vip/i/TCQLPI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02473679,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IMPT",
    "name": "IMPT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x04c17b9d3b29a78f7bd062a57cf44fc633e71f85",
    "decimals": 18,
    "image": "https://rango.vip/i/yihB5d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00161548,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOZX",
    "name": "EFFORCE IEO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34950ff2b487d9e5282c5ab342d08a2f712eb79f",
    "decimals": 18,
    "image": "https://rango.vip/i/YUOguw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00406963,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XED",
    "name": "Exeedme",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee573a945b01b788b9287ce062a0cfc15be9fd86",
    "decimals": 18,
    "image": "https://rango.vip/i/RiPcl6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02234999,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XED",
    "name": "Exeedme",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5621b5a3f4a8008c4ccdd1b942b121c8b1944f1f",
    "decimals": 18,
    "image": "https://rango.vip/i/r611ia",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02234999,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEST",
    "name": "NEST",
    "isPopular": false,
    "chainId": "1",
    "address": "0x04abeda201850ac0124161f037efd70c74ddc74c",
    "decimals": 18,
    "image": "https://rango.vip/i/WWUTh3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0006865756538546649,
    "supportedSwappers": [
      "EthUniSwapV2"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEST",
    "name": "NEST",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd6926193308d3b371fdd6a6219067e550000000",
    "decimals": 18,
    "image": "https://rango.vip/i/A5XtOn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00073829,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MORRA",
    "name": "Morra",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd9adfb67381d392c6e9671f64cdd9014bfcd74f2",
    "decimals": 18,
    "image": "https://rango.vip/i/NzI3oI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00564631,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BONDLY",
    "name": "Bondly (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x64ca1571d1476b7a21c5aaf9f1a750a193a103c0",
    "decimals": 18,
    "image": "https://rango.vip/i/j6oLdm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00216407,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BONDLY",
    "name": "Bondly",
    "isPopular": false,
    "chainId": "1",
    "address": "0x91dfbee3965baaee32784c2d546b7a0c62f268c9",
    "decimals": 18,
    "image": "https://rango.vip/i/GCUkEv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00216407,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BONDLY",
    "name": "Bondly Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5d0158a5c3ddf47d4ea4517d8db0d76aa2e87563",
    "decimals": 18,
    "image": "https://rango.vip/i/Xrzm9u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00216407,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHI",
    "name": "Project Paradise - CHI Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ARg9wfeLN4qZTxgYTYeuGtGFMmYdk5zFhBuSnTfXXUvb",
    "decimals": 9,
    "image": "https://rango.vip/i/DyRrnH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.038277243296579645,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHI",
    "name": "CHI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b21418081528845a6df4e970bd2185545b712ba",
    "decimals": 18,
    "image": "https://rango.vip/i/ycFDgx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01003317,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "CHI",
    "name": "Chi USD",
    "isPopular": false,
    "chainId": "10",
    "address": "0xca0e54b636db823847b29f506bffee743f57729d",
    "decimals": 18,
    "image": "https://rango.vip/i/032gXX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.038277243296579645,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHI",
    "name": "Chi USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc2189d367d8d3d7cca1af43ff6169197dc7351e",
    "decimals": 18,
    "image": "https://rango.vip/i/032gXX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.038277243296579645,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DERI",
    "name": "Deri Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x21e60ee73f17ac0a411ae5d690f908c3ed66fe12",
    "decimals": 18,
    "image": "https://rango.vip/i/SU8a7I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01685781,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DERI",
    "name": "Deri Protocol",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3d1d2afd191b165d140e3e8329e634665ffb0e5e",
    "decimals": 18,
    "image": "https://rango.vip/i/ow87JD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01685781,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DERI",
    "name": "Deri",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa487bf43cf3b10dffc97a9a744cbb7036965d3b9",
    "decimals": 18,
    "image": "https://rango.vip/i/e4ibm7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01685781,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DERI",
    "name": "Deri",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe60eaf5a997dfae83739e035b005a33afdcc6df5",
    "decimals": 18,
    "image": "https://rango.vip/i/PpTNer",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01685781,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "DERI",
    "name": "Deri",
    "isPopular": false,
    "chainId": "324",
    "address": "0x140d5bc5b62d6cb492b1a475127f50d531023803",
    "decimals": 18,
    "image": "https://rango.vip/i/sXCrfR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.016973527458375118,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOVI",
    "name": "GOVI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107",
    "decimals": 18,
    "image": "https://rango.vip/i/to2o1l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.076502,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GOVI",
    "name": "GOVI",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x07e49d5de43dda6162fa28d24d5935c151875283",
    "decimals": 18,
    "image": "https://rango.vip/i/oHQOlg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.076502,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "GOVI",
    "name": "GOVI",
    "isPopular": false,
    "chainId": "324",
    "address": "0xd63ef5e9c628c8a0e8984cdfb7444aee44b09044",
    "decimals": 18,
    "image": "https://rango.vip/i/94tZc5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.076502,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GOVI",
    "name": "GOVI",
    "isPopular": false,
    "chainId": "137",
    "address": "0x43df9c0a1156c96cea98737b511ac89d0e2a1f46",
    "decimals": 18,
    "image": "https://rango.vip/i/Bd4MQi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.076502,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MBD",
    "name": "MBD Financials",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaaf449bf8a33a32575c31ba8cbb90612dd95acfa",
    "decimals": 18,
    "image": "https://rango.vip/i/DBFGXt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008199,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KINE",
    "name": "Kine Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcbfef8fdd706cde6f208460f2bf39aa9c785f05d",
    "decimals": 18,
    "image": "https://rango.vip/i/GJA19d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.10345,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WOMBAT",
    "name": "Wombat",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd",
    "decimals": 18,
    "image": "https://rango.vip/i/rKOAPq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00046646,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOMBAT",
    "name": "Wombat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd",
    "decimals": 18,
    "image": "https://rango.vip/i/MABWpW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00046646,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GG",
    "name": "Reboot",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x000000000026839b3f4181f2cf69336af6153b99",
    "decimals": 18,
    "image": "https://rango.vip/i/AvuShe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0129752,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAINS",
    "name": "GAINS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x056c1d42fb1326f57da7f19ebb7dda4673f1ff55",
    "decimals": 18,
    "image": "https://rango.vip/i/kM5HgH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.05348,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XAR",
    "name": "Arcana Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5027fc44a7ba114b8f494b1e4970900c6652fedf",
    "decimals": 18,
    "image": "https://rango.vip/i/VX7OD3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02747076,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHEZMU",
    "name": "Shezmu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5fe72ed557d8a02fff49b3b826792c765d5ce162",
    "decimals": 18,
    "image": "https://rango.vip/i/sFsZkf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.48,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STRP",
    "name": "Strips Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97872eafd79940c7b24f7bcc1eadb1457347adc9",
    "decimals": 18,
    "image": "https://rango.vip/i/fST0ej",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.274804,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "STRP",
    "name": "Strips Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x326c33fd1113c1f29b35b4407f3d6312a8518431",
    "decimals": 18,
    "image": "https://rango.vip/i/fST0ej",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.274804,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTV",
    "name": "MultiVAC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6226e00bcac68b0fe55583b90a1d727c14fab77f",
    "decimals": 18,
    "image": "https://rango.vip/i/kbHy7h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00055479,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UMB",
    "name": "Umbrella",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6fc13eace26590b80cccab1ba5d51890577d83b2",
    "decimals": 18,
    "image": "https://rango.vip/i/dIiT7r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0067682,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUSH",
    "name": "Lush AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc247546a6551117c8ea82db2cc0ad6e048e5f6e",
    "decimals": 18,
    "image": "https://rango.vip/i/UeQpd6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0004079,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NVT",
    "name": "NerveNetwork",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf0e406c49c63abf358030a299c0e00118c4c6ba5",
    "decimals": 8,
    "image": "https://rango.vip/i/VIlniL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.007109958623376604,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHRP",
    "name": "Chirpley",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70bc0dc6414eb8974bc70685f798838a87d8cce4",
    "decimals": 18,
    "image": "https://rango.vip/i/M9n2F0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00473514,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYLO",
    "name": "Sylo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4",
    "decimals": 18,
    "image": "https://rango.vip/i/WHv7te",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00079944,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ECLD",
    "name": "Ethernity CLOUD",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc6920888988caceea7acca0c96f2d65b05ee22ba",
    "decimals": 18,
    "image": "https://rango.vip/i/PJguvc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00415938,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "JMPT",
    "name": "JumpToken",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x1d18d0386f51ab03e7e84e71bda1681eba865f1f",
    "decimals": 18,
    "image": "https://rango.vip/i/4uhFFd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.999867,
    "supportedSwappers": [
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JMPT",
    "name": "JumpToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x420a24c9c65bd44c48bfb1cc8d6cd1ea8b1ac840",
    "decimals": 18,
    "image": "https://rango.vip/i/yjmgVe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999867,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "JMPT",
    "name": "JumpToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0x88d7e9b65dc24cf54f5edef929225fc3e1580c25",
    "decimals": 18,
    "image": "https://rango.vip/i/zdq2J8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.999867,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PBR",
    "name": "PolkaBridge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x298d492e8c1d909d3f63bc4a36c66c64acb3d695",
    "decimals": 18,
    "image": "https://rango.vip/i/UOqTsR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02514184,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PBR",
    "name": "PolkaBridge",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0d6ae2a429df13e44a07cd2969e085e4833f64a0",
    "decimals": 18,
    "image": "https://rango.vip/i/YpqkUI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02514184,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAPPX",
    "name": "dAppstore",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00d8318e44780edeefcf3020a5448f636788883c",
    "decimals": 18,
    "image": "https://rango.vip/i/FbQgBZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00329897,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HUND",
    "name": "HUND",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2XPqoKfJitk8YcMDGBKy7CMzRRyF2X9PniZeCykDUZev",
    "decimals": 9,
    "image": "https://rango.vip/i/1J41vW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00492799,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ISP",
    "name": "Ispolink",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc8807f0f5ba3fa45ffbdc66928d71c5289249014",
    "decimals": 18,
    "image": "https://rango.vip/i/3rShvN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00068163,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTA",
    "name": "Meta",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2",
    "decimals": 18,
    "image": "https://rango.vip/i/Hl6a7i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02983707,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MTA",
    "name": "Meta",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x5298ee77a8f9e226898403ebac33e68a62f770a0",
    "decimals": 18,
    "image": "https://rango.vip/i/9QQ8ka",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.030149951418872504,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MTA",
    "name": "mStable Governance Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf501dd45a1198c2e1b5aef5314a68b9006d842e0",
    "decimals": 18,
    "image": "https://rango.vip/i/1G6pXf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02983707,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MTA",
    "name": "Meta",
    "isPopular": false,
    "chainId": "10",
    "address": "0x929b939f8524c3be977af57a4a0ad3fb1e374b50",
    "decimals": 18,
    "image": "https://rango.vip/i/78SADg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.02983707,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUBBLE",
    "name": "Imaginary Ones",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9689028ede16c2fdfe3d11855d28f8e3fc452a3",
    "decimals": 18,
    "image": "https://rango.vip/i/Ni6l23",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00222429,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WSI",
    "name": "WeSendit",
    "isPopular": false,
    "chainId": "56",
    "address": "0x837a130aed114300bab4f9f1f4f500682f7efd48",
    "decimals": 18,
    "image": "https://rango.vip/i/NybM1N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00493281,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SYL",
    "name": "SYL",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7e52a123ed6db6ac872a875552935fbbd2544c86",
    "decimals": 6,
    "image": "https://rango.vip/i/GjdJT8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00023438,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LITH",
    "name": "Lithium Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x188e817b02e635d482ae4d81e25dda98a97c4a42",
    "decimals": 18,
    "image": "https://rango.vip/i/fFoSnc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033933,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RFOX",
    "name": "RFOX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1d6df714f91debf4e0802a542e13067f31b8262",
    "decimals": 18,
    "image": "https://rango.vip/i/Vi4RMP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00136998,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RFOX",
    "name": "RFOX",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0a3a21356793b49154fd3bbe91cbc2a16c0457f5",
    "decimals": 18,
    "image": "https://rango.vip/i/uxOBud",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00136998,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARKI",
    "name": "ArkiTech",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd3210f246ae54c5a45a7b4a83315bf718f591bfc",
    "decimals": 9,
    "image": "https://rango.vip/i/Rj6SpL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02981487,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAIT",
    "name": "Hourglass",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2559813bbb508c4c79e9ccce4703bcb1f149edd7",
    "decimals": 9,
    "image": "https://rango.vip/i/WGVw7Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01840024,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FYN",
    "name": "Affyn",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3b56a704c01d650147ade2b8cee594066b3f9421",
    "decimals": 18,
    "image": "https://rango.vip/i/BBPzP3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00708439,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIDYA",
    "name": "Vidya",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d3d35bb9bec23b06ca00fe472b50e7a4c692c30",
    "decimals": 18,
    "image": "https://rango.vip/i/ByIxHH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04692419,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KIBSHI",
    "name": "KiboShib",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf525e73bdeb4ac1b0e741af3ed8a8cbb43ab0756",
    "decimals": 18,
    "image": "https://rango.vip/i/t8KMOD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00000179,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "KIBSHI",
    "name": "KiboShib",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xc7dcca0a3e69bd762c8db257f868f76be36c8514",
    "decimals": 18,
    "image": "https://rango.vip/i/t8KMOD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000179,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KIBSHI",
    "name": "KiboShib",
    "isPopular": false,
    "chainId": "1",
    "address": "0x02e7f808990638e9e67e1f00313037ede2362361",
    "decimals": 18,
    "image": "https://rango.vip/i/t8KMOD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000179,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RGT",
    "name": "Rari Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd291e7a03283640fdc51b121ac401383a46cc623",
    "decimals": 18,
    "image": "https://rango.vip/i/arUTeC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.141268,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "RGT",
    "name": "Rari Governance Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xb548f63d4405466b36c0c0ac3318a22fdcec711a",
    "decimals": 18,
    "image": "https://rango.vip/i/mlGJDV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.1434447866798345,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RGT",
    "name": "Rari Governance Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xef888bca6ab6b1d26dbec977c455388ecd794794",
    "decimals": 18,
    "image": "https://rango.vip/i/ol7wvF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.141268,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SFI",
    "name": "Spice",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xd13eb71515dc48a8a367d12f844e5737bab415df",
    "decimals": 18,
    "image": "https://rango.vip/i/6GfxG0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 59.070319615140185,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SFI",
    "name": "Spice",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb753428af26e81097e7fd17f40c88aaa3e04902c",
    "decimals": 18,
    "image": "https://rango.vip/i/C2QpvC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 20.09,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEEQ",
    "name": "Geeq",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b9f031d718dded0d681c20cb754f97b3bb81b78",
    "decimals": 18,
    "image": "https://rango.vip/i/kzhxQZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04438422,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GQ",
    "name": "Galactic Quadrant",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf700d4c708c2be1463e355f337603183d20e0808",
    "decimals": 18,
    "image": "https://rango.vip/i/1v2GmP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00028593,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FCON",
    "name": "Space Falcon",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HovGjrBGTfna4dvg6exkMxXuexB3tUfEZKcut8AWowXj",
    "decimals": 4,
    "image": "https://rango.vip/i/4iVX9W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00009663,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "THG",
    "name": "Thetan Gem",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9fd87aefe02441b123c3c32466cd9db4c578618f",
    "decimals": 18,
    "image": "https://rango.vip/i/ywYK5Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01492856,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CA",
    "name": "Coupon Assets",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ee8c380e1926730ad89e91665ff27063b13c90a",
    "decimals": 18,
    "image": "https://rango.vip/i/fG3cZu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.175325,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAY",
    "name": "TenX Pay Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb97048628db6b661d4c2aa833e95dbe1a905b280",
    "decimals": 18,
    "image": "https://rango.vip/i/5qAzkF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00753952,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCUBE",
    "name": "B cube ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x93c9175e26f57d2888c7df8b470c9eea5c0b0a93",
    "decimals": 18,
    "image": "https://rango.vip/i/m0Qpz7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.062226,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YAI",
    "name": " ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x477a3d269266994f15e9c43a8d9c0561c4928088",
    "decimals": 18,
    "image": "https://rango.vip/i/ckRLk1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0179669,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RIDE",
    "name": "holoride",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf97e2a78f1f3d1fd438ff7cc3bb7de01e5945b83",
    "decimals": 18,
    "image": "https://rango.vip/i/r1Pa5g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00191589,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UFI",
    "name": "PureFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcda4e840411c00a614ad9205caec807c7458a0e3",
    "decimals": 18,
    "image": "https://rango.vip/i/d3KJUv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02793838,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "UFI",
    "name": "PureFi Token",
    "isPopular": false,
    "chainId": "324",
    "address": "0xa0c1bc64364d39c7239bd0118b70039dbe5bbdae",
    "decimals": 18,
    "image": "https://rango.vip/i/QnDg09",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.027946221508267494,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRWL",
    "name": "Blockchain Brawlers",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4086e77c5e993fdb90a406285d00111a974f877a",
    "decimals": 4,
    "image": "https://rango.vip/i/jViVjs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00165549,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ECO",
    "name": "ECO TOKEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x191557728e4d8caa4ac94f86af842148c0fa8f7e",
    "decimals": 8,
    "image": "https://rango.vip/i/gN32C3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000237,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ECO",
    "name": "ECO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8dbf9a4c99580fc7fd4024ee08f3994420035727",
    "decimals": 18,
    "image": "https://rango.vip/i/kKxAze",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00852295,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ECO",
    "name": "Eco",
    "isPopular": false,
    "chainId": "10",
    "address": "0xe7bc9b3a936f122f08aac3b1fac3c3ec29a78874",
    "decimals": 18,
    "image": "https://rango.vip/i/4R7TJy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.0007251432696789106,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LL",
    "name": "LightLink",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0921799cb1d702148131024d18fcde022129dc73",
    "decimals": 18,
    "image": "https://rango.vip/i/kcQZq0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01972508,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DX",
    "name": "DxChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x973e52691176d36453868d9d86572788d27041a9",
    "decimals": 18,
    "image": "https://rango.vip/i/B4vLge",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003103,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHOPX",
    "name": "SPLYT SHOPX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7bef710a5759d197ec0bf621c3df802c2d60d848",
    "decimals": 18,
    "image": "https://rango.vip/i/JFwWpu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00332211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PDEX",
    "name": "Polkadex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf59ae934f6fe444afc309586cc60a84a0f89aaea",
    "decimals": 18,
    "image": "https://rango.vip/i/a7tY3J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.1829,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "KOM",
    "name": "Kommunitas",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc004e2318722ea2b15499d6375905d75ee5390b8",
    "decimals": 8,
    "image": "https://rango.vip/i/M8HSV7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00121109,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KNINE",
    "name": "K9 Finance DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x91fbb2503ac69702061f1ac6885759fc853e6eae",
    "decimals": 18,
    "image": "https://rango.vip/i/yVzc4b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001152,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DPET",
    "name": "My DeFi Pet",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb62ae373aca027177d1c18ee0862817f9080d08",
    "decimals": 18,
    "image": "https://rango.vip/i/X2qSax",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03736473,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DPET",
    "name": "My DeFi Pet Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfb62ae373aca027177d1c18ee0862817f9080d08",
    "decimals": 18,
    "image": "https://rango.vip/i/KSfxWE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03736473,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XELS",
    "name": "XELS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x397deb686c72384fad502a81f4d7fdb89e1f1280",
    "decimals": 8,
    "image": "https://rango.vip/i/TcOkQd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.078483,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WIWI",
    "name": "Wiggly Willy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x181f2cbda1ad44de56baacbb41c8fe448a2036fe",
    "decimals": 18,
    "image": "https://rango.vip/i/vrGuwz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000227,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCG",
    "name": "MetalCore",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc9e503562d0db0a2629288a5d3b1c94ea7741869",
    "decimals": 18,
    "image": "https://rango.vip/i/ml0nUR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00344023,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "FB",
    "name": "FB",
    "isPopular": false,
    "chainId": "66",
    "address": "0x770f030fdbf63ebf1c939de8bcff8943c2c2d454",
    "decimals": 2,
    "image": "https://rango.vip/i/866Alu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 4.826851567262966,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FB",
    "name": "Fenerbah e",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb19075d77a0f111796fb259819830f4780f1429",
    "decimals": 6,
    "image": "https://rango.vip/i/LLvnW6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.681598,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OLE",
    "name": "OpenLeverage Token V2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1b6e9c73bee68102d9dd4a2627f97bff4183ab0a",
    "decimals": 18,
    "image": "https://rango.vip/i/a4OWsO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00784395,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "OLE",
    "name": "OpenLeverage",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa865197a84e780957422237b5d152772654341f3",
    "decimals": 18,
    "image": "https://rango.vip/i/L7xTy7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.007881413817655563,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "OLE",
    "name": "OpenLeverage",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x73c369f61c90f03eb0dd172e95c90208a28dc5bc",
    "decimals": 18,
    "image": "https://rango.vip/i/vUKBax",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.007881413817655563,
    "supportedSwappers": [
      "ThrusterV3",
      "ThrusterV2.03",
      "ThrusterV2.1"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NBT",
    "name": "NeuralByte",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70715e133f3b98af9dcb4897657cd4608d395d49",
    "decimals": 9,
    "image": "https://rango.vip/i/yCx4gt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00164007,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NBT",
    "name": "NanoByte Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x446f2a8a39cc730ef378be759a3c57f1a3fe824c",
    "decimals": 18,
    "image": "https://rango.vip/i/ZGtzOn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00150916,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NBT",
    "name": "NanoByte Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1d3437e570e93581bd94b2fd8fbf202d4a65654a",
    "decimals": 18,
    "image": "https://rango.vip/i/uPEC3J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00150916,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HTD",
    "name": "HeroesTD",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5e2689412fae5c29bd575fbe1d5c1cd1e0622a8f",
    "decimals": 18,
    "image": "https://rango.vip/i/vACAxT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00649757,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CDT",
    "name": "CheckDot",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x0cbd6fadcf8096cc9a43d90b45f65826102e3ece",
    "decimals": 18,
    "image": "https://rango.vip/i/lFqA5b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.187734,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CDT",
    "name": "CheckDot",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ak3ovnWQnAxPSFoSNCoNYJLnJtQDCKRBH4HwhWkb6hFm",
    "decimals": 8,
    "image": "https://rango.vip/i/i7edHz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.187734,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CDT",
    "name": "CoinDash Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x177d39ac676ed1c67a2b268ad7f1e58826e5b0af",
    "decimals": 18,
    "image": "https://rango.vip/i/gphGAT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.188052,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CDT",
    "name": "CheckDot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcdb37a4fbc2da5b78aa4e41a432792f9533e85cc",
    "decimals": 18,
    "image": "https://rango.vip/i/lFqA5b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.187734,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CDT",
    "name": "CheckDot",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0cbd6fadcf8096cc9a43d90b45f65826102e3ece",
    "decimals": 18,
    "image": "https://rango.vip/i/lW2dNq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.187734,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRPT",
    "name": "Crypterium",
    "isPopular": false,
    "chainId": "1",
    "address": "0x08389495d7456e1951ddf7c3a1314a4bfb646d8b",
    "decimals": 18,
    "image": "https://rango.vip/i/ZYTuTC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0018309,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JGN",
    "name": "Juggernaut DeFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73374ea518de7addd4c2b624c0e8b113955ee041",
    "decimals": 18,
    "image": "https://rango.vip/i/7oMYJU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00952891,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "JGN",
    "name": "Juggernaut DeFi",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc13b7a43223bb9bf4b69bd68ab20ca1b79d81c75",
    "decimals": 18,
    "image": "https://rango.vip/i/hQVCek",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00952891,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DECHAT",
    "name": "CHAT",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd69ee2e508363fed57f89917d5ca03e715ee5519",
    "decimals": 18,
    "image": "https://rango.vip/i/njsSB6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.314735,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVP",
    "name": "Concentrated Voting Power",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1",
    "decimals": 18,
    "image": "https://rango.vip/i/kL7CRN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03489057,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CVP",
    "name": "Concentrated Voting Power",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5ec3adbdae549dce842e24480eb2434769e22b2e",
    "decimals": 18,
    "image": "https://rango.vip/i/tdeEis",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.034064710510860394,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DFYN",
    "name": "DFYN Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9695e0114e12c0d3a3636fab5a18e6b737529023",
    "decimals": 18,
    "image": "https://rango.vip/i/ipePno",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00816436,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DFYN",
    "name": "DFYN Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1d54aa7e322e02a0453c0f2fa21505ce7f2e9e93",
    "decimals": 18,
    "image": "https://rango.vip/i/ipePno",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.008157002759613702,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DFYN",
    "name": "DFYN Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97",
    "decimals": 18,
    "image": "https://rango.vip/i/5oOGdO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00816436,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XWG",
    "name": "X World Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a77ef9bf662d62fbf9ba4cf861eaa83f9cc4fec",
    "decimals": 18,
    "image": "https://rango.vip/i/PKzTWh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040607,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XWG",
    "name": "X World Games",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6b23c89196deb721e6fd9726e6c76e4810a464bc",
    "decimals": 18,
    "image": "https://rango.vip/i/1progw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00040607,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ERTHA",
    "name": "Ertha Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x62823659d09f9f9d2222058878f89437425eb261",
    "decimals": 18,
    "image": "https://rango.vip/i/vJS1de",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0008122,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CBX",
    "name": "CropBytes",
    "isPopular": false,
    "chainId": "1",
    "address": "0x37fc4b48ce93469dbea9918468993c735049642a",
    "decimals": 18,
    "image": "https://rango.vip/i/XV6Q2I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0071151,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "UNB",
    "name": "Unbound",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf915fdda4c882731c0456a4214548cd13a822886",
    "decimals": 18,
    "image": "https://rango.vip/i/EsiyGr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0004254711164713726,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNB",
    "name": "Unbound Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8db253a1943dddf1af9bcf8706ac9a0ce939d922",
    "decimals": 18,
    "image": "https://rango.vip/i/ZdJOJS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00042541,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UNB",
    "name": "Unbound",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd81f558b71a5323e433729009d55159955f8a7f9",
    "decimals": 18,
    "image": "https://rango.vip/i/Debimr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0004254711164713726,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKR",
    "name": "Saakuru",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe2dca969624795985f2f083bcd0b674337ba130a",
    "decimals": 18,
    "image": "https://rango.vip/i/LkXnMn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00958804,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAMA",
    "name": "Tamadoge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12b6893ce26ea6341919fe289212ef77e51688c8",
    "decimals": 18,
    "image": "https://rango.vip/i/fPyvFS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00730005,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATT",
    "name": "Attila",
    "isPopular": false,
    "chainId": "1",
    "address": "0x89fb927240750c1b15d4743cd58440fc5f14a11c",
    "decimals": 18,
    "image": "https://rango.vip/i/uY3ylQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00118335,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PNP",
    "name": "Penpie Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5012c90f14d190607662ca8344120812aaa2639d",
    "decimals": 18,
    "image": "https://rango.vip/i/hGK1hp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.886476,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PNP",
    "name": "Penpie",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2ac2b254bc18cd4999f64773a966e4f4869c34ee",
    "decimals": 18,
    "image": "https://rango.vip/i/aLExJt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.886476,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PNP",
    "name": "Penpie",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7dedbce5a2e31e4c75f87fea60bf796c17718715",
    "decimals": 18,
    "image": "https://rango.vip/i/aLExJt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.886476,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AEG",
    "name": "Aether Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0187b76be05c1fcaa24f39c0a3aab4434099c4f",
    "decimals": 18,
    "image": "https://rango.vip/i/HrqZIx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01096717,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "URUS",
    "name": "Aurox Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b",
    "decimals": 18,
    "image": "https://rango.vip/i/BMDAxy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.56,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "URUS",
    "name": "Aurox Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b",
    "decimals": 18,
    "image": "https://rango.vip/i/BMDAxy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.56,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XTP",
    "name": "Tap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6368e1e18c4c419ddfc608a0bed1ccb87b9250fc",
    "decimals": 18,
    "image": "https://rango.vip/i/7E5x9N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00106214,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MARS4",
    "name": "MARS4",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16cda4028e9e872a38acb903176719299beaed87",
    "decimals": 18,
    "image": "https://rango.vip/i/H4yTS8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00052301,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POWSCHE",
    "name": "POWSCHE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8CkiSHHJDHJV4LUoiRMLUhqG58cUkbyJRtcP4Z3mCXNf",
    "decimals": 9,
    "image": "https://rango.vip/i/gAYnzi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.013650306126738,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTI",
    "name": "ClinTex CTI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb8fb2438a805664cd8c3e640b85ac473da5be87",
    "decimals": 18,
    "image": "https://rango.vip/i/duV5Yp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01030236,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTI",
    "name": "CogniTechAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ab902bd4de7521a565f3058fb43d6d01d0d2670",
    "decimals": 9,
    "image": "https://rango.vip/i/GkO6L3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00685167,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MCHC",
    "name": "MCHCoin (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xee7666aacaefaa6efeef62ea40176d3eb21953b9",
    "decimals": 18,
    "image": "https://rango.vip/i/a7wY1z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.04633095,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCHC",
    "name": "MCH Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd69f306549e9d96f183b1aeca30b8f4353c2ecc3",
    "decimals": 18,
    "image": "https://rango.vip/i/mIAjY4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04633095,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOOV",
    "name": "dotmoovs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4116f14b6d462b32a1c10f98049e4b1765e34fa9",
    "decimals": 18,
    "image": "https://rango.vip/i/LJzlNs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00167642,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTCN",
    "name": "Contracoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd6c31bb6f05fc8db64f4b740ab758605c271fd8",
    "decimals": 18,
    "image": "https://rango.vip/i/xgWwS6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02400397,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "KROM",
    "name": "Kromatika",
    "isPopular": false,
    "chainId": "10",
    "address": "0xf98dcd95217e15e05d8638da4c91125e59590b07",
    "decimals": 18,
    "image": "https://rango.vip/i/A6gZxB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.01516602,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KROM",
    "name": "Kromatika",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x55ff62567f09906a85183b866df84bf599a4bf70",
    "decimals": 18,
    "image": "https://rango.vip/i/MlVdOq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01516602,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KROM",
    "name": "Kromatika",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3af33bef05c2dcb3c7288b77fe1c8d2aeba4d789",
    "decimals": 18,
    "image": "https://rango.vip/i/2IINWc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01516602,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ARV",
    "name": "ARIVA",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6679eb24f59dfe111864aec72b443d1da666b360",
    "decimals": 8,
    "image": "https://rango.vip/i/5h4CzN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00001671,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COK",
    "name": "cok the cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Dnb9dLSXxAarXVexehzeH8W8nFmLMNJSuGoaddZSwtog",
    "decimals": 6,
    "image": "https://rango.vip/i/wlyeqn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000121,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WAM",
    "name": "WAM (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7hdeo5QciUF8S2vfsx6uRJkdNVADBU3DDcXW4zjDcMin",
    "decimals": 8,
    "image": "https://rango.vip/i/QqKXlT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0025880830230227653,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PXP",
    "name": "PointPay",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15fa5d3dbd11a831b72b92c1705bc9f801e233cb",
    "decimals": 18,
    "image": "https://rango.vip/i/bj7NCQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03966619,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BBS",
    "name": "BBS [via ChainPort.io]",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa477a79a118a84a0d371a53c8f46f8ce883ec1dd",
    "decimals": 18,
    "image": "https://rango.vip/i/We46jS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03170738,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCO2",
    "name": "Moss Carbon Credit",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc98e825a2264d890f9a1e68ed50e1526abccacd",
    "decimals": 18,
    "image": "https://rango.vip/i/D15nmq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.416772,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MCO2",
    "name": "Moss Carbon Credit",
    "isPopular": false,
    "chainId": "137",
    "address": "0xaa7dbd1598251f856c12f63557a4c4397c253cea",
    "decimals": 18,
    "image": "https://rango.vip/i/oAEfTl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.41823998516273675,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POLC",
    "name": "Polka City",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa8330fb2b4d5d07abfe7a72262752a8505c6b37",
    "decimals": 18,
    "image": "https://rango.vip/i/LXAevM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00625582,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "POLC",
    "name": "Polka City",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6ae9701b9c423f40d54556c9a443409d79ce170a",
    "decimals": 18,
    "image": "https://rango.vip/i/0AK1ci",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00625582,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEND",
    "name": "Bend Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d02755a5700414b26ff040e1de35d337df56218",
    "decimals": 18,
    "image": "https://rango.vip/i/G0ucmo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00058061,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GST",
    "name": "GST",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB",
    "decimals": 9,
    "image": "https://rango.vip/i/LDPLIY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.011889949891906686,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GST",
    "name": "Gemstone Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x444a0e0c139cac67e8f9be945c6dfe01a2766ed1",
    "decimals": 18,
    "image": "https://rango.vip/i/1BFdOE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.011889949891906686,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GF",
    "name": "GuildFi Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaaef88cea01475125522e117bfe45cf32044e238",
    "decimals": 18,
    "image": "https://rango.vip/i/AgbEIP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03782949,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAKKA",
    "name": "Hakka Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd",
    "decimals": 18,
    "image": "https://rango.vip/i/WI7472",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00215515,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HAKKA",
    "name": "Hakka Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1d1eb8e8293222e1a29d2c0e4ce6c0acfd89aaac",
    "decimals": 18,
    "image": "https://rango.vip/i/ZK6Erg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00236430152674112,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UBDN",
    "name": "UBD Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd624e5c89466a15812c1d45ce1533be1f16c1702",
    "decimals": 18,
    "image": "https://rango.vip/i/Cd6BHk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.092,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEFI",
    "name": "Befi Labs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b9b95292f890df47fff5ac9cbe93d5fc242bd51",
    "decimals": 18,
    "image": "https://rango.vip/i/GRUGfU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02409609,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRV",
    "name": "GroveCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf33893de6eb6ae9a67442e066ae9abd228f5290c",
    "decimals": 8,
    "image": "https://rango.vip/i/8yvUk6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01199699,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CWAR",
    "name": "Cryowar Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo",
    "decimals": 9,
    "image": "https://rango.vip/i/5tseTT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0043562,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "K21",
    "name": "k21.kanon.art",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9d99c33ea2d86ec5ec6b8a4dd816ebba64404af",
    "decimals": 18,
    "image": "https://rango.vip/i/sEuhfi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.061455,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RBLS",
    "name": "Rebel Bots Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe26cda27c13f4f87cffc2f437c5900b27ebb5bbb",
    "decimals": 8,
    "image": "https://rango.vip/i/4JihoI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00917647,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ROCO",
    "name": "ROCO",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb2a85c5ecea99187a977ac34303b80acbddfa208",
    "decimals": 18,
    "image": "https://rango.vip/i/sZ0D1O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.064711,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BSCS",
    "name": "BSCS Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d",
    "decimals": 18,
    "image": "https://rango.vip/i/943s8s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00437463,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GORILLA",
    "name": "Gorilla",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33c04bed4533e31f2afb8ac4a61a48eda38c4fa0",
    "decimals": 9,
    "image": "https://rango.vip/i/ENh0zY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00129634,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CIV",
    "name": "Civilization",
    "isPopular": false,
    "chainId": "1",
    "address": "0x37fe0f067fa808ffbdd12891c0858532cfe7361d",
    "decimals": 18,
    "image": "https://rango.vip/i/XoNpYC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0037327,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MOBY",
    "name": "MOBY",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x51b75da3da2e413ea1b8ed3eb078dc712304761c",
    "decimals": 18,
    "image": "https://rango.vip/i/2s6TMR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00011133,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOBY",
    "name": "Moby",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40a7df3df8b56147b781353d379cb960120211d7",
    "decimals": 18,
    "image": "https://rango.vip/i/n9E4tM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03791186,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TUP",
    "name": "Tenup",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7714f320adca62b149df2579361afec729c5fe6a",
    "decimals": 18,
    "image": "https://rango.vip/i/GsLtcU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01037819,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VEXT",
    "name": "Veloce",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2492e97a68a6e4b9e9a11b99f6c42e5accd38c7",
    "decimals": 18,
    "image": "https://rango.vip/i/SpiPdQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00600648,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "VEXT",
    "name": "Veloce",
    "isPopular": false,
    "chainId": "137",
    "address": "0x27842334c55c01ddfe81bf687425f906816c5141",
    "decimals": 18,
    "image": "https://rango.vip/i/XDqKDR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00600648,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "STND",
    "name": "Standard",
    "isPopular": false,
    "chainId": "137",
    "address": "0x08be454de533509e8832b257116c5506e55b0b64",
    "decimals": 18,
    "image": "https://rango.vip/i/8jHFdk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.012784683172952595,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STND",
    "name": "Standard",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9040e237c3bf18347bb00957dc22167d0f2b999d",
    "decimals": 18,
    "image": "https://rango.vip/i/thYJmC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01277516,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WECAN",
    "name": "Wecan",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea60cd69f2b9fd6eb067bddbbf86a5bdeffbbc55",
    "decimals": 18,
    "image": "https://rango.vip/i/Bb2N4U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00128293,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "POLK",
    "name": "Polkamarkets",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x8b29344f368b5fa35595325903fe0eaab70c8e1f",
    "decimals": 18,
    "image": "https://rango.vip/i/lM92Hb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.011743335933886954,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POLK",
    "name": "Polkamarkets",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd478161c952357f05f0292b56012cd8457f1cfbf",
    "decimals": 18,
    "image": "https://rango.vip/i/OdYap3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0116279,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOY",
    "name": "Soyjak",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad78d154baec2e9b4e78182d02388981b5093f80",
    "decimals": 18,
    "image": "https://rango.vip/i/RrUPcP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002242,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NABOX",
    "name": "Nabox Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x755f34709e369d37c6fa52808ae84a32007d1155",
    "decimals": 18,
    "image": "https://rango.vip/i/JB4ZYG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00000503,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NABOX",
    "name": "Nabox Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03d1e72765545729a035e909edd9371a405f77fb",
    "decimals": 18,
    "image": "https://rango.vip/i/cx2wDJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000503,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CRWNY",
    "name": "Crowny token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CRWNYkqdgvhGGae9CKfNka58j6QQkaD5bLhKXvUYqnc1",
    "decimals": 6,
    "image": "https://rango.vip/i/LFeDSP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00225063,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "XTAG",
    "name": "xHashtag Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5gs8nf4wojB5EXgDUWNLwXpknzgV2YWDhveAeBZpVLbp",
    "decimals": 6,
    "image": "https://rango.vip/i/xVlfqe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01564783,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AM",
    "name": "Animated",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xddf98aad8180c3e368467782cd07ae2e3e8d36a5",
    "decimals": 18,
    "image": "https://rango.vip/i/UH3nbW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00001382,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAZOR",
    "name": "RAZOR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd",
    "decimals": 18,
    "image": "https://rango.vip/i/vKZ58X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00314911,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "RAZOR",
    "name": "RAZOR",
    "isPopular": false,
    "chainId": "10",
    "address": "0xf8397db1eedb800fdbce9e62ad83a28059cbb068",
    "decimals": 18,
    "image": "https://rango.vip/i/AT91zL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.0031506511470086158,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RAZOR",
    "name": "Razor",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc91c06db0f7bffba61e2a5645cc15686f0a8c828",
    "decimals": 18,
    "image": "https://rango.vip/i/bqCu3p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00314911,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABOND",
    "name": "ApeBond",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe6828d65bf5023ae1851d90d8783cc821ba7eee1",
    "decimals": 18,
    "image": "https://rango.vip/i/3raTmV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00711795,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ABOND",
    "name": "ApeBond",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe6828d65bf5023ae1851d90d8783cc821ba7eee1",
    "decimals": 18,
    "image": "https://rango.vip/i/rbJKQZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00711795,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALY",
    "name": "Ally",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf2cdf38e24738ba379ffa38d47bc88a941df5627",
    "decimals": 2,
    "image": "https://rango.vip/i/TL6TsV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00044392,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DDX",
    "name": "DerivaDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a880652f47bfaa771908c07dd8673a787daed3a",
    "decimals": 18,
    "image": "https://rango.vip/i/mVYIsm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03986108,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEMEAI",
    "name": "Meme AI Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x695d38eb4e57e0f137e36df7c1f0f2635981246b",
    "decimals": 9,
    "image": "https://rango.vip/i/6RpeIe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00137964,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "STFX",
    "name": "STFX",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7dd747d63b094971e6638313a6a2685e80c7fb2e",
    "decimals": 18,
    "image": "https://rango.vip/i/GtelUE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00571489,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STFX",
    "name": "STFX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9343e24716659a3551eb10aff9472a2dcad5db2d",
    "decimals": 18,
    "image": "https://rango.vip/i/GtelUE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00571489,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NIZA",
    "name": "Niza Global",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb58e26ac9cc14c0422c2b419b0ca555ee4dcb7cb",
    "decimals": 9,
    "image": "https://rango.vip/i/uQJlP5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016715,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DSLA",
    "name": "DSLA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe",
    "decimals": 18,
    "image": "https://rango.vip/i/rV73Nn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017827,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKEB",
    "name": "Skeb",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d614686550b9e1c1df4b2cd8f91c9d4df66c810",
    "decimals": 18,
    "image": "https://rango.vip/i/2LoRgo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0008192,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIST",
    "name": "Bistroo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e8908cfa881c9f6f2c64d3436e7b80b1bf0093f",
    "decimals": 18,
    "image": "https://rango.vip/i/YK4DrN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01658844,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABYSS",
    "name": "Abyss",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6",
    "decimals": 18,
    "image": "https://rango.vip/i/qRUjpj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00622455,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "L2",
    "name": "Leverj Gluon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbbff34e47e559ef680067a6b1c980639eeb64d24",
    "decimals": 18,
    "image": "https://rango.vip/i/Y0Jy26",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00242798,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LAYER",
    "name": "Unilayer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ff6ffcfda92c53f615a4a75d982f399c989366b",
    "decimals": 18,
    "image": "https://rango.vip/i/wwgxPX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03324512,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GALAXIS",
    "name": "GALAXIS Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x423071774c43c0aaf4210b439e7cda8c797e2f26",
    "decimals": 18,
    "image": "https://rango.vip/i/cbiO7d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00282907,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LYM",
    "name": "Lympo tokens",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc690f7c7fcffa6a82b79fab7508c466fefdfc8c5",
    "decimals": 18,
    "image": "https://rango.vip/i/eO1lMc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00108825,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WNT",
    "name": "Wicrypt Network Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xad4b9c1fbf4923061814dd9d5732eb703faa53d4",
    "decimals": 18,
    "image": "https://rango.vip/i/WXqbzI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.03485047,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WNT",
    "name": "Wicrypt Network Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689",
    "decimals": 18,
    "image": "https://rango.vip/i/SKKXnH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03485047,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XRUNE",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x69fa0fee221ad11012bab0fdb45d444d3d2ce71c",
    "decimals": 18,
    "image": "https://rango.vip/i/6GXler",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01076528,
    "supportedSwappers": [
      "ThorChain",
      "ThorChainStreamingSwap",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USDs",
    "name": "Sperax USD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd74f5255d557944cf7dd0e45ff521520002d5748",
    "decimals": 18,
    "image": "https://rango.vip/i/Lrj07T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MONI",
    "name": "Monsta Infinite Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9573c88ae3e37508f87649f87c4dd5373c9f31e0",
    "decimals": 18,
    "image": "https://rango.vip/i/Tan9Hp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00568469,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUTER",
    "name": "Suterusu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa2ce7ae64066175e0b90497ce7d9c190c315db4",
    "decimals": 18,
    "image": "https://rango.vip/i/1mibi2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00023031,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SUTER",
    "name": "Suterusu",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4cfbbdfbd5bf0814472ff35c72717bd095ada055",
    "decimals": 18,
    "image": "https://rango.vip/i/OWkFC5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00023030310863244598,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "DTORO",
    "name": "DexToro",
    "isPopular": false,
    "chainId": "10",
    "address": "0x1cef2d62af4cd26673c7416957cc4ec619a696a7",
    "decimals": 18,
    "image": "https://rango.vip/i/DeHEAP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.314599,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CGG",
    "name": "ChainGuardians Governance Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2ab4f9ac80f33071211729e45cfc346c1f8446d5",
    "decimals": 18,
    "image": "https://rango.vip/i/48L4P3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0139248,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CGG",
    "name": "ChainGuardians Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1fe24f25b1cf609b9c4e7e12d802e3640dfa5e43",
    "decimals": 18,
    "image": "https://rango.vip/i/94FxmM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0139248,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CGG",
    "name": "pTokens CGG",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1613957159e9b0ac6c80e824f7eea748a32a0ae2",
    "decimals": 18,
    "image": "https://rango.vip/i/Bro12S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0139248,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "STM",
    "name": "Streamity",
    "isPopular": false,
    "chainId": "56",
    "address": "0x90df11a8cce420675e73922419e3f4f3fe13cccb",
    "decimals": 18,
    "image": "https://rango.vip/i/jqXanW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.024138163536827004,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ALGB",
    "name": "Algebra",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0169ec1f8f639b32eec6d923e24c2a2ff45b9dd6",
    "decimals": 18,
    "image": "https://rango.vip/i/lSIphp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00242823,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CWS",
    "name": "Crowns",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac0104cca91d167873b8601d2e71eb3d4d8c33e0",
    "decimals": 18,
    "image": "https://rango.vip/i/NbO3Ce",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.17669228814728374,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "CWS",
    "name": "Crowns",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x6fc9651f45b262ae6338a701d563ab118b1ec0ce",
    "decimals": 18,
    "image": "https://rango.vip/i/RDDAWi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.025517741187396418,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIPENGUINS",
    "name": "hiPENGUINS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x669db4c47f89f21554ebd825a744888725fd9491",
    "decimals": 18,
    "image": "https://rango.vip/i/emRHCQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02005884,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELMT",
    "name": "Element",
    "isPopular": false,
    "chainId": "1",
    "address": "0x600d601d8b9eb5de5ac90fefc68d0d08801bfd3f",
    "decimals": 8,
    "image": "https://rango.vip/i/4XA4K3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00056332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "PEAK",
    "name": "PEAK",
    "isPopular": false,
    "chainId": "1088",
    "address": "0x1f5550a0f5f659e07506088a7919a88dff37218f",
    "decimals": 18,
    "image": "https://rango.vip/i/unUBos",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 0.1105991171431483,
    "supportedSwappers": [
      "Netswap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEAK",
    "name": "PEAKDEFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x630d98424efe0ea27fb1b3ab7741907dffeaad78",
    "decimals": 8,
    "image": "https://rango.vip/i/sisi7b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00050962,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PEAK",
    "name": "PEAKDEFI",
    "isPopular": false,
    "chainId": "56",
    "address": "0x630d98424efe0ea27fb1b3ab7741907dffeaad78",
    "decimals": 8,
    "image": "https://rango.vip/i/lIRhFG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00050962,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AX",
    "name": "AthleteX",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5617604ba0a30e0ff1d2163ab94e50d8b6d0b0df",
    "decimals": 18,
    "image": "https://rango.vip/i/BwutPM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.2943088041803684,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AX",
    "name": "AurusX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb0d82f4dfa503c9e3b8abc7a3caa01175b2da39",
    "decimals": 18,
    "image": "https://rango.vip/i/VF1zEO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.293694,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EXM",
    "name": "EXMO Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x83869de76b9ad8125e22b857f519f001588c0f62",
    "decimals": 8,
    "image": "https://rango.vip/i/vPgvlO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00747611,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLR",
    "name": "Pillar",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe3818504c1b32bf1557b16c238b2e01fd3149c17",
    "decimals": 18,
    "image": "https://rango.vip/i/V5iofe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00323804,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KINGSHIB",
    "name": "KING SHIBA",
    "isPopular": false,
    "chainId": "56",
    "address": "0x84f4f7cdb4574c9556a494dab18ffc1d1d22316c",
    "decimals": 9,
    "image": "https://rango.vip/i/1MF5kV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0024635,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHA",
    "name": "Safe Haven",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40fed5691e547885cabd7a2990de719dcc8497fc",
    "decimals": 18,
    "image": "https://rango.vip/i/xHgRh1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027299,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SOS",
    "name": "SOS (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8c059898ca6274750b6bf1cf38f2848347c490cc",
    "decimals": 18,
    "image": "https://rango.vip/i/NmRitO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 8.25834370334858e-9,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOS",
    "name": "SOS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b484b82567a09e2588a13d54d032153f0c0aee0",
    "decimals": 18,
    "image": "https://rango.vip/i/0w8Y1x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.255e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KZEN",
    "name": "Kaizen Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4550003152f12014558e5ce025707e4dd841100f",
    "decimals": 18,
    "image": "https://rango.vip/i/kHTBeh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00190295,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VNXAU",
    "name": "VNX Gold",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9TPL8droGJ7jThsq4momaoz6uhTcvX2SeMqipoPmNa8R",
    "decimals": 9,
    "image": "https://rango.vip/i/pLMtmF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 80.77,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VNXAU",
    "name": "VNX Gold",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d57b2e05f26c26b549231c866bdd39779e4a488",
    "decimals": 18,
    "image": "https://rango.vip/i/KOqLlE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 80.77,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAFI",
    "name": "DAFI Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc979087305a826c2b2a0056cfaba50aad3e6439",
    "decimals": 18,
    "image": "https://rango.vip/i/V56eho",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00068015,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CONE",
    "name": "BitCone",
    "isPopular": false,
    "chainId": "137",
    "address": "0xba777ae3a3c91fcd83ef85bfe65410592bdd0f7c",
    "decimals": 18,
    "image": "https://rango.vip/i/nJZsvK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000142,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "SUNPEPE",
    "name": "SUNPEPE",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TAzpJHxxgJd8f5AKjzndEndH3S9pzNUM5W",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.00078355,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BIRD",
    "name": "SolBird",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7y2KpMzsxmjCN8ogA3PSfwZvmYUgMuWo7NWY4Ct5jRHp",
    "decimals": 6,
    "image": "https://rango.vip/i/dWePir",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 8.21062818085893,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIRD",
    "name": "Bird.Money",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70401dfd142a16dc7031c56e862fc88cb9537ce0",
    "decimals": 18,
    "image": "https://rango.vip/i/qVxdJM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.22,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BIRD",
    "name": "BIRD",
    "isPopular": false,
    "chainId": "128",
    "address": "0xed6a80032336bdba650d581f07ac64aec58b0f3d",
    "decimals": 18,
    "image": "https://rango.vip/i/VH4CUp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.000006342017163641517,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEFROGS",
    "name": "DeFrogs",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd555498a524612c67f286df0e0a9a64a73a7cdc7",
    "decimals": 18,
    "image": "https://rango.vip/i/Hm2rui",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 77.93,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AUTO",
    "name": "AUTOv2",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa184088a740c695e156f91f5cc086a06bb78b827",
    "decimals": 18,
    "image": "https://rango.vip/i/AgGFc7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 10.08,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WLKN",
    "name": "Walken",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EcQCUYv57C4V6RoPxkVUiDwtX1SP8y8FP5AEToYL8Az",
    "decimals": 9,
    "image": "https://rango.vip/i/CqNZhX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0008594,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONA",
    "name": "Monavale",
    "isPopular": false,
    "chainId": "1",
    "address": "0x275f5ad03be0fa221b4c6649b8aee09a42d9412a",
    "decimals": 18,
    "image": "https://rango.vip/i/Yqp3p8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 76.27,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MONA",
    "name": "Monavale",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6968105460f67c3bf751be7c15f92f5286fd0ce5",
    "decimals": 18,
    "image": "https://rango.vip/i/1XocyU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 76.27,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FEAR",
    "name": "FEAR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88a9a52f944315d5b4e917b9689e65445c401e83",
    "decimals": 18,
    "image": "https://rango.vip/i/JIegSp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0434967,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VES",
    "name": "Vestate",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f557fb2aa33dce484902695ca1374f413875519",
    "decimals": 18,
    "image": "https://rango.vip/i/4Kml0m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0052888,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLT",
    "name": "Bloom",
    "isPopular": false,
    "chainId": "1",
    "address": "0x107c4504cd79c5d2696ea0030a8dd4e92601b82e",
    "decimals": 18,
    "image": "https://rango.vip/i/2Kgmhk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00500121,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WELT",
    "name": "FABWELT",
    "isPopular": false,
    "chainId": "137",
    "address": "0x23e8b6a3f6891254988b84da3738d2bfe5e703b9",
    "decimals": 18,
    "image": "https://rango.vip/i/PQZ0zo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00321979,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRUH",
    "name": "Bruh",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7cfdb3cdc33dbeb9e9a4c95b61953cf12a008b3",
    "decimals": 18,
    "image": "https://rango.vip/i/K7iSM8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00073034,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUSSY",
    "name": "Pussy Financial",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9196e18bc349b1f64bc08784eae259525329a1ad",
    "decimals": 18,
    "image": "https://rango.vip/i/haEtUt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000304,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MDAI",
    "name": "MindAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb549116ac57b47c1b365a890e1d04fd547dfff97",
    "decimals": 18,
    "image": "https://rango.vip/i/VChC0N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEURA",
    "name": "Neurahub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d1c949a761c11e4cc50c3ae6bdb0f24fd7a39da",
    "decimals": 18,
    "image": "https://rango.vip/i/Ran8Ws",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00867323,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "0xBTC",
    "name": "0xBitcoin Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7cb16cb78ea464ad35c8a50abf95dff3c9e09d5d",
    "decimals": 8,
    "image": "https://rango.vip/i/yzTEin",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0392479,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0xBTC",
    "name": "0xBitcoin Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb6ed7644c69416d67b522e20bc294a9a9b405b31",
    "decimals": 8,
    "image": "https://rango.vip/i/suI4kL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0392479,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "0xBTC",
    "name": "0xBitcoin",
    "isPopular": false,
    "chainId": "10",
    "address": "0xe0bb0d3de8c10976511e5030ca403dbf4c25165b",
    "decimals": 8,
    "image": "https://rango.vip/i/mOL0Yx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.0392479,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RBW",
    "name": "Rainbow Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x431cd3c9ac9fc73644bf68bf5691f4b83f9e104f",
    "decimals": 18,
    "image": "https://rango.vip/i/vO17fa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0034495,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VEMP",
    "name": "vEmpire Gamer Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcfeb09c3c5f0f78ad72166d55f9e6e9a60e96eec",
    "decimals": 18,
    "image": "https://rango.vip/i/exz5mx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00275733,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOTCROSS",
    "name": "Hot Cross",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4297394c20800e8a38a619a243e9bbe7681ff24e",
    "decimals": 18,
    "image": "https://rango.vip/i/66IcLk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00615652,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HOTCROSS",
    "name": "Hot Cross Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4fa7163e153419e0e1064e418dd7a99314ed27b6",
    "decimals": 18,
    "image": "https://rango.vip/i/0SBfOo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00615652,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TROG",
    "name": "Thug Frog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2cpCdyVCpxzwYWFhFqgMJqxnMgEMDGohHUQuf4ppWuAH",
    "decimals": 6,
    "image": "https://rango.vip/i/EaXwit",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000016430734157143895,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TROG",
    "name": "Trog",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2077d81d0c5258230d5a195233941547cb5f0989",
    "decimals": 9,
    "image": "https://rango.vip/i/HUqr37",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000162,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VLXPAD",
    "name": "VelasPad",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8e3bb633f7276cc17735d86154e0ad5ec9928c0",
    "decimals": 18,
    "image": "https://rango.vip/i/AhkmHO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00301149,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LBC",
    "name": "LifeBankChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf6801c3e4108ead0deb216a3295338a582734c24",
    "decimals": 18,
    "image": "https://rango.vip/i/mJV677",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0010541113572028623,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "HOD",
    "name": "HOD",
    "isPopular": false,
    "chainId": "25",
    "address": "0x68d43230470c67beb61e00a6e8ec869f947365fd",
    "decimals": 18,
    "image": "https://rango.vip/i/t5LYRW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0013821786193663477,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "PLASTIK",
    "name": "PLASTIK Token",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x27cd006548df7c8c8e9fdc4a67fa05c2e3ca5cf9",
    "decimals": 9,
    "image": "https://rango.vip/i/3MdZOj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.00446412,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "JULD",
    "name": "JulSwap",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5a41f637c3f7553dba6ddc2d3ca92641096577ea",
    "decimals": 18,
    "image": "https://rango.vip/i/xuhn57",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0011346846120061076,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "DONUT",
    "name": "DONUT Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x2258459c937d0b4cb58289718c900b43c9c4813a",
    "decimals": 18,
    "image": "https://rango.vip/i/r7rBsh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0005612546776921662,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DONUT",
    "name": "DONUT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9",
    "decimals": 18,
    "image": "https://rango.vip/i/nolZ46",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00554849,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVRY",
    "name": "Evrynet",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7dcd9b99787c619b4d57979521258d1a7267ad7",
    "decimals": 18,
    "image": "https://rango.vip/i/zZaTtj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01648299,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DPS",
    "name": "DEEPSPACE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb7b1570e26315baad369b8ea0a943b7f140db9eb",
    "decimals": 9,
    "image": "https://rango.vip/i/5zYUQJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01253151,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DPS",
    "name": "DEEPSPACE",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf275e1ac303a4c9d987a2c48b8e555a77fec3f1c",
    "decimals": 9,
    "image": "https://rango.vip/i/hcgpYH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01253151,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMI",
    "name": "SafeMoon Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd7492db29e2ab436e819b249452ee1bbdf52214",
    "decimals": 8,
    "image": "https://rango.vip/i/rlwN2o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.36819e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "HAN",
    "name": "HanChain",
    "isPopular": false,
    "chainId": "10",
    "address": "0x50bce64397c75488465253c0a034b8097fea6578",
    "decimals": 18,
    "image": "https://rango.vip/i/3RQqT6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00776285,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAN",
    "name": "HanChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c90c57aaf95a3a87eadda6ec3974c99d786511f",
    "decimals": 18,
    "image": "https://rango.vip/i/K4ec67",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00776285,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TKAI",
    "name": "TAIKAI",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x1287a235474e0331c0975e373bdd066444d1bd35",
    "decimals": 18,
    "image": "https://rango.vip/i/CVhxd0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00868485,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TKAI",
    "name": "TAIKAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c5b267ed81009aa7374b5ca7e5137da47045ba8",
    "decimals": 18,
    "image": "https://rango.vip/i/CVhxd0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00868485,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AOA",
    "name": "Aurora Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ab165d795019b6d8b3e971dda91071421305e5a",
    "decimals": 18,
    "image": "https://rango.vip/i/KAkUzy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012865,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ITGR",
    "name": "Integral",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd502f487e1841fdc805130e13eae80c61186bc98",
    "decimals": 18,
    "image": "https://rango.vip/i/X5n9wP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00298668,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SECOND",
    "name": "Second",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x7979871595b80433183950ab6c6457752b585805",
    "decimals": 18,
    "image": "https://rango.vip/i/pb8p8e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00047951,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IOEN",
    "name": "Internet of Energy Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e4e46b7bf03ece908c88ff7cc4975560010893a",
    "decimals": 18,
    "image": "https://rango.vip/i/YqdupI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00202656,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "NOGS",
    "name": "Noggles",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x13741c5df9ab03e7aa9fb3bf1f714551dd5a5f8a",
    "decimals": 15,
    "image": "https://rango.vip/i/o1mcJM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00002203,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOGS",
    "name": "Noggles",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5fa20d59d2a907e5fed9fb80b4a8d9f0d069a48d",
    "decimals": 18,
    "image": "https://rango.vip/i/o1mcJM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002203,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "INSUR",
    "name": "INSUR Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x544c42fbb96b39b21df61cf322b5edc285ee7429",
    "decimals": 18,
    "image": "https://rango.vip/i/ica5fa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01446432,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "INSUR",
    "name": "InsurAce (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8a0e8b4b0903929f47c3ea30973940d4a9702067",
    "decimals": 18,
    "image": "https://rango.vip/i/TxqvpG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01446432,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INSUR",
    "name": "InsurAce",
    "isPopular": false,
    "chainId": "1",
    "address": "0x544c42fbb96b39b21df61cf322b5edc285ee7429",
    "decimals": 18,
    "image": "https://rango.vip/i/MFR0QG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01446432,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "INSUR",
    "name": "Bsc-Peg INSUR Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3192ccddf1cdce4ff055ebc80f3f0231b86a7e30",
    "decimals": 18,
    "image": "https://rango.vip/i/SqWIAq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01446432,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MAPS",
    "name": "MAPS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb",
    "decimals": 6,
    "image": "https://rango.vip/i/NJyYWY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01420368,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "POR",
    "name": "Portuma",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9000cac49c3841926baac5b2e13c87d43e51b6a4",
    "decimals": 18,
    "image": "https://rango.vip/i/JlROMV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00046682,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ZEE",
    "name": "ZeroSwapToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x44754455564474a89358b2c2265883df993b12f0",
    "decimals": 18,
    "image": "https://rango.vip/i/ro6L0L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01021011,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZEE",
    "name": "ZeroSwapToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5",
    "decimals": 18,
    "image": "https://rango.vip/i/h0xC3M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01021011,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ZEE",
    "name": "ZeroSwapToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0x44754455564474a89358b2c2265883df993b12f0",
    "decimals": 18,
    "image": "https://rango.vip/i/ypKXIo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01021011,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ZEE",
    "name": "ZeroSwap",
    "isPopular": false,
    "chainId": "137",
    "address": "0xfd4959c06fbcc02250952daebf8e0fb38cf9fd8c",
    "decimals": 18,
    "image": "https://rango.vip/i/wmerJC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01021011,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NFTB",
    "name": "NFTB",
    "isPopular": false,
    "chainId": "56",
    "address": "0xde3dbbe30cfa9f437b293294d1fd64b26045c71a",
    "decimals": 18,
    "image": "https://rango.vip/i/nEhsYf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0025408,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKYA",
    "name": "Sekuya Multiverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x623cd3a3edf080057892aaf8d773bbb7a5c9b6e9",
    "decimals": 18,
    "image": "https://rango.vip/i/xOBhaX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00468342,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DEFI",
    "name": "DeFi",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6d106c0b8d2f47c5465bdbd58d1be253762cbbc1",
    "decimals": 18,
    "image": "https://rango.vip/i/iiyX46",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02097526,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DEFI",
    "name": "DegenerateFinance69Inu",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fefecfi5DhahY51XqQTP2qjFGhnuoSjrVivL6k9Ercw6",
    "decimals": 4,
    "image": "https://rango.vip/i/kzH0Mi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02083899907138901,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEFI",
    "name": "DeFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b0faca7ba905a86f221ceb5ca404f605e5b3131",
    "decimals": 18,
    "image": "https://rango.vip/i/EzTAnO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02097526,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SRK",
    "name": "SparkPoint",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0488401c3f535193fa8df029d9ffe615a06e74e6",
    "decimals": 18,
    "image": "https://rango.vip/i/zxDs7t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005533,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "O3",
    "name": "O3 Swap Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xee9801669c6138e84bd50deb500827b776777d28",
    "decimals": 18,
    "image": "https://rango.vip/i/swpmZO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01088458,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "O3",
    "name": "O3 Swap Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee9801669c6138e84bd50deb500827b776777d28",
    "decimals": 18,
    "image": "https://rango.vip/i/LTpUB9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01088458,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "O3",
    "name": "O3 Swap Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xee9801669c6138e84bd50deb500827b776777d28",
    "decimals": 18,
    "image": "https://rango.vip/i/LNEgZR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01088458,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "O3",
    "name": "O3 Swap Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xee9801669c6138e84bd50deb500827b776777d28",
    "decimals": 18,
    "image": "https://rango.vip/i/PSKMFG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01088458,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "O3",
    "name": "O3",
    "isPopular": false,
    "chainId": "128",
    "address": "0xee9801669c6138e84bd50deb500827b776777d28",
    "decimals": 18,
    "image": "https://rango.vip/i/zsqmfJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.01088458,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "VPP",
    "name": "Virtue Player Points",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xf578ad8809f13dabf921bdd3fcfbe194d0ab5628",
    "decimals": 18,
    "image": "https://rango.vip/i/ouuvW1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00576121,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VPP",
    "name": "Virtue Poker Points",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5eeaa2dcb23056f4e8654a349e57ebe5e76b5e6e",
    "decimals": 18,
    "image": "https://rango.vip/i/ouuvW1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00576121,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ORARE",
    "name": "One Rare Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xff2382bd52efacef02cc895bcbfc4618608aa56f",
    "decimals": 18,
    "image": "https://rango.vip/i/4S3ZiY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01190571,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GRLC",
    "name": "Garlic",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "88YqDBWxYhhwPbExF966EdaCYBKP51xVm1oGBcbWzcf2",
    "decimals": 9,
    "image": "https://rango.vip/i/FDguQi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00892785617482905,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRLC",
    "name": "Garlicoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x58f7345b5295e43aa454911571f13be186655be9",
    "decimals": 8,
    "image": "https://rango.vip/i/pCZap0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00835262,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "1ART",
    "name": "OneArt",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd3c325848d7c6e29b574cb0789998b2ff901f17e",
    "decimals": 18,
    "image": "https://rango.vip/i/KtyeH0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00417239,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "COINYE",
    "name": "Coinye West",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x0028e1e60167b48a938b785aa5292917e7eaca8b",
    "decimals": 18,
    "image": "https://rango.vip/i/5EgFUr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00074071,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "POLX",
    "name": "Polylastic",
    "isPopular": false,
    "chainId": "137",
    "address": "0x187ae45f2d361cbce37c6a8622119c91148f261b",
    "decimals": 18,
    "image": "https://rango.vip/i/o1ZwtK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0000088,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ODDZ",
    "name": "OddzToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb0a6e056b587d0a85640b39b1cb44086f7a26a1e",
    "decimals": 18,
    "image": "https://rango.vip/i/PWwKCn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00589159,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ODDZ",
    "name": "OddzToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd2828fc4d8e8a0ede91bb38cf64b1a81de65bf6",
    "decimals": 18,
    "image": "https://rango.vip/i/SYTQx2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00589159,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ODDZ",
    "name": "Oddz",
    "isPopular": false,
    "chainId": "56",
    "address": "0xcd40f2670cf58720b694968698a5514e924f742d",
    "decimals": 18,
    "image": "https://rango.vip/i/TXO8CW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00589159,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KEKE",
    "name": "Freaky KEKE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GgKDdEJ9n2NCHHonE5qSxVgPKGQNsbeXEkr1SHDLapPv",
    "decimals": 7,
    "image": "https://rango.vip/i/ktb4kp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.50442e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEKE",
    "name": "KEK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf7168c8abb0ff80116413a8d95396bbdc318a3ff",
    "decimals": 7,
    "image": "https://rango.vip/i/oOfrX3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.848e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEKE",
    "name": "Freaky KEKE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x28e67eb7aaa8f5dd9cb7be2b2e3dad6b25edb1ab",
    "decimals": 18,
    "image": "https://rango.vip/i/e3jHNB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.50442e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XMON",
    "name": "XMON",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3aada3e213abf8529606924d8d1c55cbdc70bf74",
    "decimals": 18,
    "image": "https://rango.vip/i/TH39ry",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 385.79,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ETHO",
    "name": "Etho Protocol",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8b52f46a52d86c131222ee14167da6a847bdb84a",
    "decimals": 18,
    "image": "https://rango.vip/i/MUytUV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00643,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHO",
    "name": "Etho Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b5326da634f9270fb84481dd6f94d3dc2ca7096",
    "decimals": 18,
    "image": "https://rango.vip/i/MUytUV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00643,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SLICE",
    "name": "Tranche Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0aee8703d34dd9ae107386d3eff22ae75dd616d1",
    "decimals": 18,
    "image": "https://rango.vip/i/a8jw1u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02885748,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DREP",
    "name": "DREP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ab6ed69ef663bd986ee59205ccad8a20f98b4c2",
    "decimals": 18,
    "image": "https://rango.vip/i/JekQm3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00580475,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "EGO",
    "name": "Paysenger EGO",
    "isPopular": false,
    "chainId": "56",
    "address": "0x44a21b3577924dcd2e9c81a3347d204c36a55466",
    "decimals": 18,
    "image": "https://rango.vip/i/HjdbDD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.010013067034875317,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EGO",
    "name": "Shear Ego Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2zjmVX4KDWtNWQyJhbNXgpMmsAoXwftB7sGV4qvV18xa",
    "decimals": 9,
    "image": "https://rango.vip/i/js3Yi6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.010013067034875317,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HGOLD",
    "name": "HollyGold",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c93b616933b0cd03b201b29cd8a22681dd9e0d9",
    "decimals": 8,
    "image": "https://rango.vip/i/Tf0wT8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.120189,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTH",
    "name": "Monetha",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf4dce16da2877f8c9e00544c93b62ac40631f16",
    "decimals": 5,
    "image": "https://rango.vip/i/cmRRtA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00152981,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CONV",
    "name": "Convergence",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc834fa996fa3bec7aad3693af486ae53d8aa8b50",
    "decimals": 18,
    "image": "https://rango.vip/i/PcMX2L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013593,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UPDOG",
    "name": "What's Updog?",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HJ39rRZ6ys22KdB3USxDgNsL7RKiQmsC3yL8AS3Suuku",
    "decimals": 9,
    "image": "https://rango.vip/i/CGIc1i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0005339344448078857,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIRATE",
    "name": "Pirate Nation Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7613c48e0cd50e42dd9bf0f6c235063145f6f8dc",
    "decimals": 18,
    "image": "https://rango.vip/i/95oSLJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.179044,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIRATE",
    "name": "PirateCash",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb990d93c308a31c737aa91839e8ba8eaf4017d7a",
    "decimals": 8,
    "image": "https://rango.vip/i/MDKTH6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01956716,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LBP",
    "name": "LaunchBlock",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa23c1194d421f252b4e6d5edcc3205f7650a4ebe",
    "decimals": 18,
    "image": "https://rango.vip/i/Q4TnSX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015035,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRAVA",
    "name": "Trava Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x186d0ba3dfc3386c464eecd96a61fbb1e2da00bf",
    "decimals": 18,
    "image": "https://rango.vip/i/P48DkD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014427,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FWT",
    "name": "Freeway",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20e7125677311fca903a8897042b9983f22ea295",
    "decimals": 18,
    "image": "https://rango.vip/i/Be9lyv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005264,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JAM",
    "name": "Geojam",
    "isPopular": false,
    "chainId": "1",
    "address": "0x23894dc9da6c94ecb439911caf7d337746575a72",
    "decimals": 18,
    "image": "https://rango.vip/i/jD9qbS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00103664,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROOK",
    "name": "Rook",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa5047c9c78b8877af97bdcb85db743fd7313d4a",
    "decimals": 18,
    "image": "https://rango.vip/i/TOeJUX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.821339,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XCV",
    "name": "XCarnival",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4be63a9b26ee89b9a3a13fd0aa1d0b2427c135f8",
    "decimals": 18,
    "image": "https://rango.vip/i/K4rYe6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00063467,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZELIX",
    "name": "ZELIX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0257ffd7ea2ebba4aaa090c7adbdd032a08c1f74",
    "decimals": 18,
    "image": "https://rango.vip/i/eqxKgy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007377,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NAVI",
    "name": "Atlas Navi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc1c93a2507975e98b9d0e9260ded61a00152bf1",
    "decimals": 18,
    "image": "https://rango.vip/i/wrHqTA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.113622,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BELT",
    "name": "BELT Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe0e514c71282b6f4e823703a39374cf58dc3ea4f",
    "decimals": 18,
    "image": "https://rango.vip/i/bXBkGo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.052987,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDEA",
    "name": "Ideaology",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d3a4f62124498092ce665f865e0b38ff6f5fbea",
    "decimals": 18,
    "image": "https://rango.vip/i/h5UmF9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00075969,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EPIKO",
    "name": "Epiko Regal (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6ep1sNB9UWni3nBZUX84USsRBhKRzMAxEmcia479cWTh",
    "decimals": 8,
    "image": "https://rango.vip/i/Wp2u21",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00325722,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EPIKO",
    "name": "Epiko",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb62e24b747eaa41454857cf6011832117df59cb8",
    "decimals": 18,
    "image": "https://rango.vip/i/qXgE3F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00325722,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HORD",
    "name": "Hord",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb1bc21f748ae2be95674876710bc6d78235480e0",
    "decimals": 18,
    "image": "https://rango.vip/i/lE4tye",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00532372,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HORD",
    "name": "HORD Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x43a96962254855f16b925556f9e97be436a43448",
    "decimals": 18,
    "image": "https://rango.vip/i/iwutJa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00532372,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CEP",
    "name": "CEREAL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4168bbc34baea34e55721809911bca5baaef6ba6",
    "decimals": 18,
    "image": "https://rango.vip/i/T6X3wB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00252409,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHILL",
    "name": "Project SEED Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd",
    "decimals": 6,
    "image": "https://rango.vip/i/kJ0khk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00131864,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TUNE",
    "name": "Bitune",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1fac00ccee478eced6a120a50ed2ab28ee7fe32b",
    "decimals": 18,
    "image": "https://rango.vip/i/T6cEbm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01499838,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPO",
    "name": "Spores Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcbe771323587ea16dacb6016e269d7f08a7acc4e",
    "decimals": 18,
    "image": "https://rango.vip/i/M9eo3C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00043776,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OLT",
    "name": "OneLedger",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64a60493d888728cf42616e034a0dfeae38efcf0",
    "decimals": 18,
    "image": "https://rango.vip/i/8QyujN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00092776,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SPORE",
    "name": "Spore",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x6e7f5c0b9f4432716bdd0a77a3601291b9d9e985",
    "decimals": 9,
    "image": "https://rango.vip/i/aqTMaY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.4299e-11,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SPORE",
    "name": "Spore",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6Cw88QKMxS7LzAucXbYDcUEXPq1TvsojYPV9xWwJPt7r",
    "decimals": 8,
    "image": "https://rango.vip/i/PNm0iN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.4721653668624e-11,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BMON",
    "name": "Binamon",
    "isPopular": false,
    "chainId": "56",
    "address": "0x08ba0619b1e7a582e0bce5bbe9843322c954c340",
    "decimals": 18,
    "image": "https://rango.vip/i/wgZz8d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00399001,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YLD",
    "name": "Yield",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf94b5c5651c888d928439ab6514b93944eee6f48",
    "decimals": 18,
    "image": "https://rango.vip/i/CUcm9f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00211906,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AART",
    "name": "ALL ART",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B",
    "decimals": 6,
    "image": "https://rango.vip/i/SMxpMZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00017415,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ADAPAD",
    "name": "ADAPad",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb0170e2d0c1cc1b2e7a90313d9b9afa4f250289",
    "decimals": 18,
    "image": "https://rango.vip/i/toIOxd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00409966,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THN",
    "name": "Throne",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e95cea14dd384429eb3c4331b776c4cfbb6fcd9",
    "decimals": 18,
    "image": "https://rango.vip/i/JHfleE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00115805,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHPAD",
    "name": "ETHPAD.network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8db1d28ee0d822367af8d220c0dc7cb6fe9dc442",
    "decimals": 18,
    "image": "https://rango.vip/i/fdBe2B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0022477,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ETHPAD",
    "name": "ETHPAD.network",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8db1d28ee0d822367af8d220c0dc7cb6fe9dc442",
    "decimals": 18,
    "image": "https://rango.vip/i/dtrKFr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0022477,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FWC",
    "name": "FWC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6d3a160b86edcd46d8f9bba25c2f88cccade19fc",
    "decimals": 9,
    "image": "https://rango.vip/i/E4XKOx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.0779e-11,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RFUEL",
    "name": "Rio Fuel Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf9f549774ecedbd0966c52f250acc548d3f36e5",
    "decimals": 18,
    "image": "https://rango.vip/i/1peTQw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00132527,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HMQ",
    "name": "Humaniq",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcbcc0f036ed4788f63fc0fee32873d6a7487b908",
    "decimals": 8,
    "image": "https://rango.vip/i/zLAesb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00198508,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WOM",
    "name": "Wombat Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xad6742a35fb341a9cc6ad674738dd8da98b94fb1",
    "decimals": 18,
    "image": "https://rango.vip/i/g4jYZa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00752754,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WOM",
    "name": "Wombat Exchange",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7b5eb3940021ec0e8e463d5dbb4b7b09a89ddf96",
    "decimals": 18,
    "image": "https://rango.vip/i/Fv6kxU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00752754,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOM",
    "name": "WOM Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd356a39bff2cada8e9248532dd879147221cf76",
    "decimals": 18,
    "image": "https://rango.vip/i/O6V1fE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02147188,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOM",
    "name": "Wombat Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0b314a8c08637685fc3dafc477b92028c540cfb",
    "decimals": 18,
    "image": "https://rango.vip/i/Fv6kxU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00752754,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOV",
    "name": "Shib Original Vision",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c5bc2ba3614fd27fcc7022ea71d9172e2632c16",
    "decimals": 18,
    "image": "https://rango.vip/i/lpepXw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.821e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "BCMC",
    "name": "Blockchain Monster Coin",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x8ece0d14d619fe26e2c14c4a92c2f9e8634a039e",
    "decimals": 18,
    "image": "https://rango.vip/i/56rqLR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.027361045285833323,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BCMC",
    "name": "Blockchain Monster Coin",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc10358f062663448a3489fc258139944534592ac",
    "decimals": 18,
    "image": "https://rango.vip/i/w6BdRw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00180179,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCMC",
    "name": "Blockchain Monster Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2ba8349123de45e931a8c8264c332e6e9cf593f9",
    "decimals": 18,
    "image": "https://rango.vip/i/Nqyd8P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00180179,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BCMC",
    "name": "Blockchain Monster Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc10358f062663448a3489fc258139944534592ac",
    "decimals": 18,
    "image": "https://rango.vip/i/5fz0QS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00180179,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MMA",
    "name": "mma",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "MMAx26JtJgSWv6yH48nEHCGZcVvRbf9Lt9ALa7jSipe",
    "decimals": 9,
    "image": "https://rango.vip/i/h5HhpY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.005038747490103658,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MMA",
    "name": "Meme Alliance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b3a8159e119eb09822115ae08ee1526849e1116",
    "decimals": 9,
    "image": "https://rango.vip/i/xhKOvL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00503508,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NORD",
    "name": "Nord Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e9730ecffbed43fd876a264c982e254ef05a0de",
    "decimals": 18,
    "image": "https://rango.vip/i/Dx1eiz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.052546,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NORD",
    "name": "NORD",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf6f85b3f9fd581c2ee717c404f7684486f057f95",
    "decimals": 18,
    "image": "https://rango.vip/i/Zv3PcT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.052546,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNB",
    "name": "Coinsbit Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc538143202f3b11382d8606aae90a96b042a19db",
    "decimals": 18,
    "image": "https://rango.vip/i/eDEQz0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009979,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UBX",
    "name": "UBIX Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5b5efc906513b4344ebabcf47a04901f99f09f3",
    "decimals": 0,
    "image": "https://rango.vip/i/OmYLzv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000947,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CIRUS",
    "name": "Cirus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa01199c61841fce3b3dafb83fefc1899715c8756",
    "decimals": 18,
    "image": "https://rango.vip/i/w1Xcub",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00680739,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CIRUS",
    "name": "Cirus",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2a82437475a60bebd53e33997636fade77604fc2",
    "decimals": 18,
    "image": "https://rango.vip/i/SfQdZT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.006810710401560198,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PNT",
    "name": "pNetwork Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed",
    "decimals": 18,
    "image": "https://rango.vip/i/5cptRc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00474419,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PNT",
    "name": "PNT",
    "isPopular": false,
    "chainId": "56",
    "address": "0xdaacb0ab6fb34d24e8a67bfa14bf4d95d4c7af92",
    "decimals": 18,
    "image": "https://rango.vip/i/6ctUnZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00474419,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOCG",
    "name": "LOCG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60eb57d085c59932d5faa6c6026268a4386927d0",
    "decimals": 18,
    "image": "https://rango.vip/i/pD3eaU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.003129785191641541,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "XY",
    "name": "XY Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x55555555a687343c6ce28c8e1f6641dc71659fad",
    "decimals": 18,
    "image": "https://rango.vip/i/PuRDAe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0462082,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XY",
    "name": "XY Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77777777772cf0455fb38ee0e75f38034dfa50de",
    "decimals": 18,
    "image": "https://rango.vip/i/9QmydH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0462082,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CSM",
    "name": "Cricket Star Manager",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EzfnjRUKtc5vweE1GCLdHV4MkDQ3ebSpQXLobSKgQ9RB",
    "decimals": 6,
    "image": "https://rango.vip/i/IwmZl2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00080839,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CSM",
    "name": "Crust Shadow",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2620638eda99f9e7e902ea24a285456ee9438861",
    "decimals": 18,
    "image": "https://rango.vip/i/GUTJZl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00401283,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NSBT",
    "name": "Neutrino System Base Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d79d5b61de59d882ce90125b18f74af650acb93",
    "decimals": 6,
    "image": "https://rango.vip/i/0173Ea",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.142564,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PRISM",
    "name": "PRISM",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x",
    "decimals": 6,
    "image": "https://rango.vip/i/mhfYNj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00071134,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZAP",
    "name": "ZAP Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HxPoEHMt1vKeqjKCePcqTj6yYgn6Xqq1fKTY3Pjx4YrX",
    "decimals": 8,
    "image": "https://rango.vip/i/Ow9qDD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0017264,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZAP",
    "name": "Zap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6781a0f84c7e9e846dcb84a9a5bd49333067b104",
    "decimals": 18,
    "image": "https://rango.vip/i/UOsHC3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0017264,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NAFT",
    "name": "Nafter",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd7730681b1dc8f6f969166b29d8a5ea8568616a3",
    "decimals": 18,
    "image": "https://rango.vip/i/fKpjGH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00040134,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COBE",
    "name": "Castle Of Blackwater",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc61edb127f58f42f47a8be8aebe83cf602a53878",
    "decimals": 18,
    "image": "https://rango.vip/i/IJ9N1a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.05766,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PVU",
    "name": "Plant vs Undead Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x31471e0791fcdbe82fbf4c44943255e923f1b794",
    "decimals": 18,
    "image": "https://rango.vip/i/lUjA9l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00136114,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUMLX",
    "name": "PUMLx",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c088775e4139af116ac1fa6f281bbf71e8c1c73",
    "decimals": 18,
    "image": "https://rango.vip/i/VCoYlm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00301259,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GLCH",
    "name": "Glitch",
    "isPopular": false,
    "chainId": "1",
    "address": "0x038a68ff68c393373ec894015816e33ad41bd564",
    "decimals": 18,
    "image": "https://rango.vip/i/HPD1sa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0057154,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "ODIN",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/c360ef34a86d334f625e4cbb7da3223aea97174b61f35bb3758081a8160f7d9b",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.021879212909701122,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOMS",
    "name": "TomTomCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe9ab37a414c517b2be2bfa5945665bb07379054",
    "decimals": 18,
    "image": "https://rango.vip/i/f4h3B6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013779,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LOWB",
    "name": "loser coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x843d4a358471547f51534e3e51fae91cb4dc3f28",
    "decimals": 18,
    "image": "https://rango.vip/i/UmK8wx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00000532,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PLOT",
    "name": "PLOT",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe82808eaa78339b06a691fd92e1be79671cad8d3",
    "decimals": 18,
    "image": "https://rango.vip/i/EOjk7d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00558844,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLOT",
    "name": "PLOT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72f020f8f3e8fd9382705723cd26380f8d0c66bb",
    "decimals": 18,
    "image": "https://rango.vip/i/oGVoMK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00558844,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNFT",
    "name": "SeedSwap Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa3c4dc4a9ce2a6b40b57f25f8b50decc2c64dec2",
    "decimals": 18,
    "image": "https://rango.vip/i/BKIk3l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.016290473077790767,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AVG",
    "name": "Avocado DAO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa41f142b6eb2b164f8164cae0716892ce02f311f",
    "decimals": 18,
    "image": "https://rango.vip/i/Sl41YV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01469151,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RODAI",
    "name": "ROD.AI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GdbyLsNKHKLXTZVEo8QrGKVmrexEeZUYvhpSfuZ9TdkC",
    "decimals": 5,
    "image": "https://rango.vip/i/ViqZ3K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 6.711e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MSTR",
    "name": "MSTR Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xe397784960f814ba35c9ee0bc4c9dffdf86925f9",
    "decimals": 18,
    "image": "https://rango.vip/i/N9SzBS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.02071307,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "MSTR",
    "name": "MSTR",
    "isPopular": false,
    "chainId": "66",
    "address": "0xe397784960f814ba35c9ee0bc4c9dffdf86925f9",
    "decimals": 18,
    "image": "https://rango.vip/i/Uj4eKV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.05186558608581395,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NFTART",
    "name": "NFTArt.Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf7844cb890f4c339c497aeab599abdc3c874b67a",
    "decimals": 9,
    "image": "https://rango.vip/i/rBC312",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.4406e-11,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EDEN",
    "name": "Eden",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1559fa1b8f28238fd5d76d9f434ad86fd20d1559",
    "decimals": 18,
    "image": "https://rango.vip/i/ZSa9AN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02743081,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PMON",
    "name": "Polkamon",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1796ae0b0fa4862485106a0de9b654efe301d0b2",
    "decimals": 18,
    "image": "https://rango.vip/i/7os2S5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.104308,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PMON",
    "name": "Polkamon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1796ae0b0fa4862485106a0de9b654efe301d0b2",
    "decimals": 18,
    "image": "https://rango.vip/i/w1s7wB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.104308,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AOG",
    "name": "AgeOfGods",
    "isPopular": false,
    "chainId": "56",
    "address": "0x40c8225329bd3e28a043b029e0d07a5344d2c27c",
    "decimals": 18,
    "image": "https://rango.vip/i/0D7fWc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00338615,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AOG",
    "name": "smARTOFGIVING",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8578530205cecbe5db83f7f29ecfeec860c297c2",
    "decimals": 18,
    "image": "https://rango.vip/i/JDDwyL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.680883,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GMAC",
    "name": "Gemach",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd96e84ddbc7cbe1d73c55b6fe8c64f3a6550deea",
    "decimals": 18,
    "image": "https://rango.vip/i/OmJOF0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00068411,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GMAC",
    "name": "GMAC",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xdc8b6b6beab4d5034ae91b7a1cf7d05a41f0d239",
    "decimals": 18,
    "image": "https://rango.vip/i/qBnBYD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00068411,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "GMAC",
    "name": "Gemach",
    "isPopular": false,
    "chainId": "10",
    "address": "0x53ed36b1d07a5f4b01e5f872fd054f8439335460",
    "decimals": 18,
    "image": "https://rango.vip/i/gfC4xI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00068411,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MRX",
    "name": "Maars",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8yQuj5v4s72UqZi3sYZL5rAD4NPV4ueUwBKzChBDWMVf",
    "decimals": 9,
    "image": "https://rango.vip/i/qPXS36",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000021114120044562383,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HGET",
    "name": "Hedget",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7968bc6a03017ea2de509aaa816f163db0f35148",
    "decimals": 6,
    "image": "https://rango.vip/i/0unJON",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.19738,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HGET",
    "name": "Hedget",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc7d8d35eba58a0935ff2d5a33df105dd9f071731",
    "decimals": 6,
    "image": "https://rango.vip/i/FiQsAK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.19874016845286813,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "XNL",
    "name": "Chronicle",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x7ca1c28663b76cfde424a9494555b94846205585",
    "decimals": 18,
    "image": "https://rango.vip/i/dBIV7k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.00722543,
    "supportedSwappers": [
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XNL",
    "name": "Chronicle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06a00715e6f92210af9d7680b584931faf71a833",
    "decimals": 18,
    "image": "https://rango.vip/i/c2r65V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00722543,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KABY",
    "name": "Kaby Arena",
    "isPopular": false,
    "chainId": "56",
    "address": "0x02a40c048ee2607b5f5606e445cfc3633fb20b58",
    "decimals": 18,
    "image": "https://rango.vip/i/uxPEdL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00036065,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RWA",
    "name": "Xend Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3096e7bfd0878cc65be71f8899bc4cfb57187ba3",
    "decimals": 18,
    "image": "https://rango.vip/i/nuffTP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.03975951,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RWA",
    "name": "Rug World Assets",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x928a6a9fc62b2c94baf2992a6fba4715f5bb0066",
    "decimals": 18,
    "image": "https://rango.vip/i/bconT7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.03457026,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RWA",
    "name": "RWA AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfcf7985661d2c3f62208970cbe25e70bcce73e7c",
    "decimals": 18,
    "image": "https://rango.vip/i/sPxlCd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00025263,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RWA",
    "name": "Xend Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4563554284aa7148d6e6d0351519e954ba3b6e02",
    "decimals": 18,
    "image": "https://rango.vip/i/nuffTP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03975951,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYC",
    "name": "Cyclone Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8861cff2366c1128fd699b68304ad99a0764ef9a",
    "decimals": 18,
    "image": "https://rango.vip/i/jp13xs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 12.47,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CYC",
    "name": "Cyclone Protocol",
    "isPopular": false,
    "chainId": "56",
    "address": "0x810ee35443639348adbbc467b33310d2ab43c168",
    "decimals": 18,
    "image": "https://rango.vip/i/bWp7In",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 12.47,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OIN",
    "name": "oinfinance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9aeb50f542050172359a0e1a25a9933bc8c01259",
    "decimals": 8,
    "image": "https://rango.vip/i/aTKTUh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.009863664677758814,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "OIN",
    "name": "Oin Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x658e64ffcf40d240a43d52ca9342140316ae44fa",
    "decimals": 8,
    "image": "https://rango.vip/i/XmSikV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.009863664677758814,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OLY",
    "name": "Olyseum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6595b8fd9c920c81500dca94e53cdc712513fb1f",
    "decimals": 18,
    "image": "https://rango.vip/i/58BCO1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00019988,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRIN",
    "name": "Fringe Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc9fe6e1c76210be83dc1b5b20ec7fd010b0b1d15",
    "decimals": 18,
    "image": "https://rango.vip/i/R0CekA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00049361,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHOOT",
    "name": "Mars Battle",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc61e13ca6830fc7f035fd0e90a01cd08be6dcaa",
    "decimals": 18,
    "image": "https://rango.vip/i/VHy6A1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00345598,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KONO",
    "name": "Konomi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x850aab69f0e0171a9a49db8be3e71351c8247df4",
    "decimals": 18,
    "image": "https://rango.vip/i/ASPD96",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00571066,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIGG",
    "name": "Digg",
    "isPopular": false,
    "chainId": "1",
    "address": "0x798d1be841a82a273720ce31c822c61a67a601c3",
    "decimals": 9,
    "image": "https://rango.vip/i/h6ogJv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 517.76,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALLIN",
    "name": "All In",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b2b931d6ab97b6a887b2c5d8529537e6fe73ebe",
    "decimals": 9,
    "image": "https://rango.vip/i/rUnsYn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.397562,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WFAI",
    "name": "WaifuAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a001303158670e284950565164933372807cd48",
    "decimals": 18,
    "image": "https://rango.vip/i/eIrcGK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.633e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCTO",
    "name": "Octo.fi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7240ac91f01233baaf8b064248e80feaa5912ba3",
    "decimals": 18,
    "image": "https://rango.vip/i/AdM2tz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.323254,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OCTO",
    "name": "Octo.fi",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x52f5d9b3a2bb89d3aec5829a3415c21115acd633",
    "decimals": 18,
    "image": "https://rango.vip/i/VvZgWa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.35581160900924436,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KTN",
    "name": "Kattana",
    "isPopular": false,
    "chainId": "1",
    "address": "0x491e136ff7ff03e6ab097e54734697bb5802fc1c",
    "decimals": 18,
    "image": "https://rango.vip/i/G1m8F7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03074871,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KTN",
    "name": "Kattana",
    "isPopular": false,
    "chainId": "56",
    "address": "0xdae6c2a48bfaa66b43815c5548b10800919c993e",
    "decimals": 18,
    "image": "https://rango.vip/i/SRQUPr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03074871,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VRT",
    "name": "Venus Reward Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5f84ce30dc3cf7909101c69086c50de191895883",
    "decimals": 18,
    "image": "https://rango.vip/i/F9zl8m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00001288,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SOULS",
    "name": "The Unfettered Souls",
    "isPopular": false,
    "chainId": "137",
    "address": "0xefcfece12a99d1dbbf6f3264ee97f8c045e97f1f",
    "decimals": 18,
    "image": "https://rango.vip/i/8TZy1K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00671016,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYNC",
    "name": "SYNC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32",
    "decimals": 18,
    "image": "https://rango.vip/i/Z0oC4r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00170716,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYNC",
    "name": "Syncus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa41d2f8ee4f47d3b860a149765a7df8c3287b7f0",
    "decimals": 9,
    "image": "https://rango.vip/i/UbQiYO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001277,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CATHEON",
    "name": "Catheon Gaming",
    "isPopular": false,
    "chainId": "137",
    "address": "0x7e7737c40878e720b32e7bc9cd096259f876d69f",
    "decimals": 9,
    "image": "https://rango.vip/i/lT4UMW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00028366,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XRP",
    "name": "XRP Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe",
    "decimals": 18,
    "image": "https://rango.vip/i/0rFE8w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.535396,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XRP",
    "name": "WarioXrpDumbledoreYugioh69Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb1c064c3f2908f741c9dea4afc5773238b53e6cc",
    "decimals": 9,
    "image": "https://rango.vip/i/cStafG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012295,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XRP",
    "name": "HarryPotterObamaPacMan8Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07e0edf8ce600fb51d44f51e3348d77d67f298ae",
    "decimals": 8,
    "image": "https://rango.vip/i/KFh4Sf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0033173,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "XRP",
    "name": "XRP",
    "isPopular": false,
    "chainId": "128",
    "address": "0xa2f3c2446a3e20049708838a779ff8782ce6645a",
    "decimals": 6,
    "image": "https://rango.vip/i/XwBHyZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1.1143667359463818,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAPI",
    "name": "PAPI  ETH ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6dc469a3ef387ad9619df7774388ae26439ac8d4",
    "decimals": 9,
    "image": "https://rango.vip/i/4TDdpp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.3491e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UDO",
    "name": "Unido",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea3983fc6d0fbbc41fb6f6091f68f3e08894dc06",
    "decimals": 18,
    "image": "https://rango.vip/i/Xix0p8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00269827,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCONA",
    "name": "Arcona Distribution Contract",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f71b8de197a1c84d31de0f1fa7926c365f052b3",
    "decimals": 18,
    "image": "https://rango.vip/i/P5uDlq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01144299,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RB",
    "name": "RunesBridge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe91598331a36a78f7fefe277ce7c1915da0afb93",
    "decimals": 18,
    "image": "https://rango.vip/i/1lSuKA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00434347,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SATT",
    "name": "SaTT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf49c9f599a0a9049d97cff34d0c30e468987389",
    "decimals": 18,
    "image": "https://rango.vip/i/YkrzV4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007745,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TKING",
    "name": "Tiger King",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24e89bdf2f65326b94e36978a7edeac63623dafa",
    "decimals": 18,
    "image": "https://rango.vip/i/XHKH9t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.10525e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LABS",
    "name": "LABS Group",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b0e42f366ba502d787bb134478adfae966c8798",
    "decimals": 18,
    "image": "https://rango.vip/i/xccFTx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011787471507004229,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLANK",
    "name": "GoBlank Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41a3dba3d677e573636ba691a70ff2d606c29666",
    "decimals": 18,
    "image": "https://rango.vip/i/mL3BBS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01374888,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BLANK",
    "name": "GoBlank Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa5ec9d64b64b8b9e94feaa7538c084b38117e7ba",
    "decimals": 18,
    "image": "https://rango.vip/i/1v5YqJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.013366781189313356,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BLANK",
    "name": "GoBlank Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf4c83080e80ae530d6f8180572cbbf1ac9d5d435",
    "decimals": 18,
    "image": "https://rango.vip/i/cOQNDY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01374888,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DXL",
    "name": "Dexlab",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z",
    "decimals": 6,
    "image": "https://rango.vip/i/MmE3NT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00480659,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KALM",
    "name": "Kalmar Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4ba0057f784858a48fe351445c672ff2a3d43515",
    "decimals": 18,
    "image": "https://rango.vip/i/8DsT3p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02943012,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SKILL",
    "name": "Skill Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x483416eb3afa601b9c6385f63cec0c82b6abf1fb",
    "decimals": 18,
    "image": "https://rango.vip/i/mrmb0y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.28067851415318795,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SKILL",
    "name": "CryptoBlades Skill Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab",
    "decimals": 18,
    "image": "https://rango.vip/i/UQdJ1N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.281143,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPIUM",
    "name": "Opium Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x888888888889c00c67689029d7856aac1065ec11",
    "decimals": 18,
    "image": "https://rango.vip/i/PM0o9W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.060115,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PAINT",
    "name": "MS PAINT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8x9c5qa4nvakKo5wHPbPa5xvTVMKmS26w4DRpCQLCLk3",
    "decimals": 9,
    "image": "https://rango.vip/i/1jQgUw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00007216,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAINT",
    "name": "Paint",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042",
    "decimals": 18,
    "image": "https://rango.vip/i/K8rirr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000268,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HYPER",
    "name": "Hyper",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe2cfd7a01ec63875cd9da6c7c1b7025166c2fa2f",
    "decimals": 18,
    "image": "https://rango.vip/i/FhpD5u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000396,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRP",
    "name": "Grape",
    "isPopular": false,
    "chainId": "1",
    "address": "0xec70ff4a5b09110e4d20ada4f2db4a86ec61fac6",
    "decimals": 18,
    "image": "https://rango.vip/i/F07twa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.923184,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIENS3",
    "name": "hiENS3",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa88842ae47dbe87116cf7001dddd1b246fcd8262",
    "decimals": 18,
    "image": "https://rango.vip/i/EGflPj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00674759,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PTF",
    "name": "PowerTrade Fuel Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc57d533c50bc22247d49a368880fb49a1caa39f7",
    "decimals": 18,
    "image": "https://rango.vip/i/thIiDg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01033631,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRKL",
    "name": "Brokoli",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4674a4f24c5f63d53f22490fb3a08eaaad739ff8",
    "decimals": 18,
    "image": "https://rango.vip/i/M9OdsR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00450592,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STRONG",
    "name": "Strong",
    "isPopular": false,
    "chainId": "1",
    "address": "0x990f341946a3fdb507ae7e52d17851b87168017c",
    "decimals": 18,
    "image": "https://rango.vip/i/v3PbvX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.71,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WATCH",
    "name": "yieldwatch",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0",
    "decimals": 18,
    "image": "https://rango.vip/i/oADoGx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0120652,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PERL",
    "name": "Perlin X",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0f9e4d49f25de22c2202af916b681fbb3790497b",
    "decimals": 18,
    "image": "https://rango.vip/i/MUySne",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00047623,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PERL",
    "name": "Perlin (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb121fcd122daaa153bb8a102754127b2682645cb",
    "decimals": 18,
    "image": "https://rango.vip/i/34f6DP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00047623,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PERL",
    "name": "Perlin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeca82185adce47f39c684352b0439f030f860318",
    "decimals": 18,
    "image": "https://rango.vip/i/SReJ78",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00047623,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACQ",
    "name": "Acquire Fi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4bdcb66b968060d9390c1d12bd29734496205581",
    "decimals": 18,
    "image": "https://rango.vip/i/V5duVw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00671478,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "HUSH",
    "name": "Hush",
    "isPopular": false,
    "chainId": "324",
    "address": "0x6d27b6252b980ba4350a1e349a3b684237d5e986",
    "decimals": 18,
    "image": "https://rango.vip/i/mN1qcW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.019529685382438548,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOKO",
    "name": "Tokoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0f0546eb5e3ee7e8cfc5da12e5949f3ae622675",
    "decimals": 18,
    "image": "https://rango.vip/i/PfEJVk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00047102,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SATA",
    "name": "Signata",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ebb4a4e91ad83be51f8d596533818b246f4bee1",
    "decimals": 18,
    "image": "https://rango.vip/i/9fRRJJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01612314,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HYVE",
    "name": "Hyve",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd794dd1cada4cf79c9eebaab8327a1b0507ef7d4",
    "decimals": 18,
    "image": "https://rango.vip/i/f2sf3Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00907484,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WZRD",
    "name": "Wizardia Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfa40d8fc324bcdd6bbae0e086de886c571c225d4",
    "decimals": 18,
    "image": "https://rango.vip/i/spBenx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00177825,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JENNER",
    "name": "Caitlyn Jenner",
    "isPopular": false,
    "chainId": "1",
    "address": "0x482702745260ffd69fc19943f70cffe2cacd70e9",
    "decimals": 18,
    "image": "https://rango.vip/i/Gnh6Go",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00026906,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KMON",
    "name": "Kryptomon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4170fd71eced3c80badca77f4e12e8aac1e3436",
    "decimals": 18,
    "image": "https://rango.vip/i/gZd3Wo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00118225,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KMON",
    "name": "KmonCoin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc732b6586a93b6b7cf5fed3470808bc74998224d",
    "decimals": 18,
    "image": "https://rango.vip/i/ojSw9Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00118225,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QWAN",
    "name": "The QWAN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeee0fe52299f2de8e2ed5111cd521ab67dcf0faf",
    "decimals": 18,
    "image": "https://rango.vip/i/yxUxWh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0050185,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COT",
    "name": "CosplayToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5cac718a3ae330d361e39244bf9e67ab17514ce8",
    "decimals": 18,
    "image": "https://rango.vip/i/GwaINF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00802471,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COT",
    "name": "CoTrader",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5c872500c00565505f3624ab435c222e558e9ff8",
    "decimals": 18,
    "image": "https://rango.vip/i/jJ1Nyg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001117,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHI",
    "name": "Shirtum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad996a45fd2373ed0b10efa4a8ecb9de445a4302",
    "decimals": 18,
    "image": "https://rango.vip/i/LrYbun",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00125877,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHI",
    "name": "Shina Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x243cacb4d5ff6814ad668c3e225246efa886ad5a",
    "decimals": 18,
    "image": "https://rango.vip/i/wOA2KU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.76788e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SHI",
    "name": "Shirtum",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7269d98af4aa705e0b1a5d8512fadb4d45817d5a",
    "decimals": 18,
    "image": "https://rango.vip/i/jMJV1f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00125877,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NUX",
    "name": "NUX Peanut.trade",
    "isPopular": false,
    "chainId": "1",
    "address": "0x89bd2e7e388fab44ae88bef4e1ad12b4f1e0911c",
    "decimals": 18,
    "image": "https://rango.vip/i/Ry5dy3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0043727,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HANDY",
    "name": "Handy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8bbe1a2961b41340468d0548c2cd5b7dfa9b684c",
    "decimals": 18,
    "image": "https://rango.vip/i/Hfq39t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00022624,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPELLFIRE",
    "name": "Spellfire",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a0b022f32b3191d44e5847da12dc0b63fb07c91",
    "decimals": 18,
    "image": "https://rango.vip/i/dIPlic",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00049473,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HELMET",
    "name": "Helmet.insure Governance Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x948d2a81086a075b3130bac19e4c6dee1d2e3fe8",
    "decimals": 18,
    "image": "https://rango.vip/i/QGzue0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00494019,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NSFW",
    "name": "Pleasure Coin",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19",
    "decimals": 18,
    "image": "https://rango.vip/i/qwOdKw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000854,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NSFW",
    "name": "Pleasure Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f006d1e1d9dc6c98996f50a4c810f17a47fbf19",
    "decimals": 18,
    "image": "https://rango.vip/i/N2vlvS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000854,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MSU",
    "name": "MetaSoccer Universe",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe8377a076adabb3f9838afb77bee96eac101ffb1",
    "decimals": 18,
    "image": "https://rango.vip/i/2so5eu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00180405,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAMEX",
    "name": "Damex Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00e679ba63b509182c349f5614f0a07cdd0ce0c5",
    "decimals": 18,
    "image": "https://rango.vip/i/EIZ5Rm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00405028,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BSHIB",
    "name": "Based Shiba Inu",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfea9dcdc9e23a9068bf557ad5b186675c61d33ea",
    "decimals": 18,
    "image": "https://rango.vip/i/8ckuCX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00002299,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WTC",
    "name": "Waltonchain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb7cb1c96db6b22b0d3d9536e0108d062bd488f74",
    "decimals": 18,
    "image": "https://rango.vip/i/xNx0MH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00053173,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "S4F",
    "name": "S4FE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaec7d1069e3a914a3eb50f0bfb1796751f2ce48a",
    "decimals": 18,
    "image": "https://rango.vip/i/Sbt4L2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005334,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WSG",
    "name": "Wall Street Games",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xef04804e1e474d3f9b73184d7ef5d786f3fce930",
    "decimals": 18,
    "image": "https://rango.vip/i/e3fR6y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00143083,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WSG",
    "name": "Wall Street Games",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa58950f05fea2277d2608748412bf9f802ea4901",
    "decimals": 18,
    "image": "https://rango.vip/i/YOVioj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.91837e-10,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DDIM",
    "name": "DuckDaoDime",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfbeea1c75e4c4465cb2fccc9c6d6afe984558e20",
    "decimals": 18,
    "image": "https://rango.vip/i/8DdXP0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00498373,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OXY",
    "name": "Oxygen Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M",
    "decimals": 6,
    "image": "https://rango.vip/i/uHSeiX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0246742,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OXY",
    "name": "Oxygen",
    "isPopular": false,
    "chainId": "1",
    "address": "0x965697b4ef02f0de01384d0d4f9f782b1670c163",
    "decimals": 6,
    "image": "https://rango.vip/i/EPUcYY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0246742,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BIN",
    "name": "Binemon",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe56842ed550ff2794f010738554db45e60730371",
    "decimals": 18,
    "image": "https://rango.vip/i/IWiHfE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00046709,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OGGY",
    "name": "Oggy Inu  ETH ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7e877b99897d514da01bd1d177e693ec639961af",
    "decimals": 9,
    "image": "https://rango.vip/i/T09eWX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.53274e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OJA",
    "name": "Ojamu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0aa7efe4945db24d95ca6e117bba65ed326e291a",
    "decimals": 18,
    "image": "https://rango.vip/i/BkFKaU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00192831,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PCNT",
    "name": "Playcent",
    "isPopular": false,
    "chainId": "1",
    "address": "0x657b83a0336561c8f64389a6f5ade675c04b0c3b",
    "decimals": 18,
    "image": "https://rango.vip/i/VlCPqP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0037576,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "STEP",
    "name": "Step",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
    "decimals": 9,
    "image": "https://rango.vip/i/qFK373",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.04286658,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STEP",
    "name": "1Step.finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x50026ad58b338cf3eccc2b422deb8faa725f377f",
    "decimals": 8,
    "image": "https://rango.vip/i/0pxHV6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04289390322562136,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KART",
    "name": "Klausen Art",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GDzfemoYR5GkbK4YupYpyq3E8Du9fSfKXxKDpkdrqGjs",
    "decimals": 6,
    "image": "https://rango.vip/i/QdZrP0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.004559269059666084,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KART",
    "name": "Dragon Kart",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8bdd8dbcbdf0c066ca5f3286d33673aa7a553c10",
    "decimals": 18,
    "image": "https://rango.vip/i/f9Ph72",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.004559269059666084,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESD",
    "name": "Empty Set Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36f3fd68e7325a35eb768f1aedaae9ea0689d723",
    "decimals": 18,
    "image": "https://rango.vip/i/bFtyVJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0004238058603646429,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BMI",
    "name": "Bridge Mutual",
    "isPopular": false,
    "chainId": "1",
    "address": "0x725c263e32c72ddc3a19bea12c5a0479a81ee688",
    "decimals": 18,
    "image": "https://rango.vip/i/GgETyG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0028078,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CO",
    "name": "CO",
    "isPopular": false,
    "chainId": "56",
    "address": "0x936b6659ad0c1b244ba8efe639092acae30dc8d6",
    "decimals": 6,
    "image": "https://rango.vip/i/UVt78e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0015208,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUFFI",
    "name": "Bufficorn",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c1b1302220d7de5c22b495e78b72f2dd2457d45",
    "decimals": 9,
    "image": "https://rango.vip/i/lXH6v8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.30053e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BLKC",
    "name": "BlackHat Coin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8626264b6a1b4e920905efd381002aba52ea0eea",
    "decimals": 8,
    "image": "https://rango.vip/i/P3eGK5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01698475,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLKC",
    "name": "BlackHat Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8bcd06492416a749c9369009b3429861b7f27f6e",
    "decimals": 8,
    "image": "https://rango.vip/i/P3eGK5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01698475,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GFI",
    "name": "Goldfinch",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdab396ccf3d84cf2d07c4454e10c8a6f5b008d2b",
    "decimals": 18,
    "image": "https://rango.vip/i/ligIgm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.16,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GFI",
    "name": "GroceryFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde9804cc479164fa9e9cb59ad4e65012a12aa827",
    "decimals": 9,
    "image": "https://rango.vip/i/kkzcUs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011783,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WNDR",
    "name": "Wonderman Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xdfd7b0dd7bf1012dfdf3307a964c36b972300ac8",
    "decimals": 8,
    "image": "https://rango.vip/i/9TG6ht",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01284873,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IOI",
    "name": "IOI Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b3870df408ff4d7c3a26df852d41034eda11d81",
    "decimals": 6,
    "image": "https://rango.vip/i/anDBBL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01595974,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DOGEMOB",
    "name": "DOGEMOB",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc44f8508e1de753e7c523f98639132eef2ad8ea5",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00036002,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIST",
    "name": "Alchemist",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88acdd2a6425c3faae4bc9650fd7e27e0bebb7ab",
    "decimals": 18,
    "image": "https://rango.vip/i/mJsQvJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.603054,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MIST",
    "name": "Mist",
    "isPopular": false,
    "chainId": "56",
    "address": "0x68e374f856bf25468d365e539b700b648bf94b67",
    "decimals": 18,
    "image": "https://rango.vip/i/U5W1ST",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00315658,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KANGAL",
    "name": "Kangal",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e765d26388a17a6e86c49a8e41df3f58abcd337",
    "decimals": 18,
    "image": "https://rango.vip/i/cQtENe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000177,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MMAI",
    "name": "MetamonkeyAi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa0c5b3567fd1bac8a2a11eb16c3f81a49eea90f",
    "decimals": 7,
    "image": "https://rango.vip/i/bmiDak",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002932,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TXL",
    "name": "Tixl Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8eef5a82e6aa222a60f009ac18c24ee12dbf4b41",
    "decimals": 18,
    "image": "https://rango.vip/i/AukLh3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00320517,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TXL",
    "name": "Tixl",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1ffd0b47127fdd4097e54521c9e2c7f0d66aafc5",
    "decimals": 18,
    "image": "https://rango.vip/i/O3jKl2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00320517,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHACK",
    "name": "Shack",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f018e73c185ab23647c82bd039e762813877f0e",
    "decimals": 18,
    "image": "https://rango.vip/i/6V77yl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011328,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PKR",
    "name": "POLKER",
    "isPopular": false,
    "chainId": "137",
    "address": "0x140a4e80dd8184536acc45f1c452d7540472e6e1",
    "decimals": 18,
    "image": "https://rango.vip/i/pAqKDS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00086246,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVT",
    "name": "concertVR-Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24b47299e756af0571f512232a3629e0dabb52ed",
    "decimals": 18,
    "image": "https://rango.vip/i/NvLHgm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00026996,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVT",
    "name": "CyberVeinToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe428c3867f05dea2a89fc76a102b544eac7f772",
    "decimals": 18,
    "image": "https://rango.vip/i/lQsw2q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015434,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DUCKIES",
    "name": "Yellow Duckies",
    "isPopular": false,
    "chainId": "137",
    "address": "0x18e73a5333984549484348a94f4d219f4fab7b81",
    "decimals": 8,
    "image": "https://rango.vip/i/ysQ2Lk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0034151,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUCKIES",
    "name": "Yellow Duckies",
    "isPopular": false,
    "chainId": "1",
    "address": "0x90b7e285ab6cf4e3a2487669dba3e339db8a3320",
    "decimals": 8,
    "image": "https://rango.vip/i/Kx8p8L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0034151,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "COMBO",
    "name": "Furucombo",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6ddb31002abc64e1479fc439692f7ea061e78165",
    "decimals": 18,
    "image": "https://rango.vip/i/X5Vlnq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00407549,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COMBO",
    "name": "Furucombo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xffffffff2ba8f66d4e51811c5190992176930278",
    "decimals": 18,
    "image": "https://rango.vip/i/nb3cpc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00407549,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COMBO",
    "name": "ComboToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc03fbf20a586fa89c2a5f6f941458e1fbc40c661",
    "decimals": 18,
    "image": "https://rango.vip/i/hCQdbM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.364916,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "COMBO",
    "name": "Furucombo",
    "isPopular": false,
    "chainId": "324",
    "address": "0xc2b13bb90e33f1e191b8aa8f44ce11534d5698e3",
    "decimals": 18,
    "image": "https://rango.vip/i/apV3Vo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.3651758580148089,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BALPHA",
    "name": "bAlpha",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a5ce6abd131ea6b148a022cb76fc180ae3315a6",
    "decimals": 18,
    "image": "https://rango.vip/i/Eh0rld",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.55,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0XDEV",
    "name": "DEVAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf7498c98789957f4ee53b3e37ff5b7ef8a6cfc7b",
    "decimals": 18,
    "image": "https://rango.vip/i/eL4wB9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.185898,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALVA",
    "name": "Alvara Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e729198d1c59b82bd6bba579310c40d740a11c2",
    "decimals": 18,
    "image": "https://rango.vip/i/Jhw0oQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.054397,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PACOCA",
    "name": "Pacoca",
    "isPopular": false,
    "chainId": "56",
    "address": "0x55671114d774ee99d653d6c12460c780a67f1d18",
    "decimals": 18,
    "image": "https://rango.vip/i/YRAelu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00044704,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FEVR",
    "name": "RealFevr",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9fb83c0635de2e815fd1c21b3a292277540c2e8d",
    "decimals": 18,
    "image": "https://rango.vip/i/ExPEuo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001394,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZAI",
    "name": "Zeal AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc2aeedc081d4cb6797a681e9403a82211f97b308",
    "decimals": 9,
    "image": "https://rango.vip/i/Nybrpv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011129,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZAI",
    "name": "ZAIHO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x67037abcfefd566aeff31c240168c25d65a0754d",
    "decimals": 18,
    "image": "https://rango.vip/i/fw2XA4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.200129,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZAI",
    "name": "ZAIBOT.io",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68449870eea84453044bd430822827e21fd8f101",
    "decimals": 18,
    "image": "https://rango.vip/i/Z4jPh2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02576773,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "ZAI",
    "name": "Zaibot",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x68449870eea84453044bd430822827e21fd8f101",
    "decimals": 18,
    "image": "https://rango.vip/i/Z4jPh2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.02576773,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SALD",
    "name": "Salad",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5582a479f0c403e207d2578963ccef5d03ba636f",
    "decimals": 18,
    "image": "https://rango.vip/i/O5P8Wj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00192996,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLZILLA",
    "name": "Solzilla",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "31iQsahfa4CMiirU7REygBzuAWg4R4ah7Y4aDu9ZfXJP",
    "decimals": 4,
    "image": "https://rango.vip/i/Y1p8M4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.23953e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MF",
    "name": "Make Frens",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f88cde14d2d5a551618b1a7506e6137249d83ad",
    "decimals": 18,
    "image": "https://rango.vip/i/mPdyur",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00236248,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FARA",
    "name": "FaraCrystal",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1",
    "decimals": 18,
    "image": "https://rango.vip/i/1HwSor",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00613298,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "NFTD",
    "name": "NFTrade Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x9e3ca00f2d4a9e5d4f0add0900de5f15050812cf",
    "decimals": 18,
    "image": "https://rango.vip/i/SB2SXE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0041553,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFTD",
    "name": "NFTrade",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e0fe2947752be0d5acf73aae77362daf79cb379",
    "decimals": 18,
    "image": "https://rango.vip/i/lZsaL6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0041553,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NFTD",
    "name": "NFTrade Token [via ChainPort.io]",
    "isPopular": false,
    "chainId": "56",
    "address": "0xac83271abb4ec95386f08ad2b904a46c61777cef",
    "decimals": 18,
    "image": "https://rango.vip/i/yBYrmz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0041553,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDV",
    "name": "Idavoll DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x45448e05020576929fcdeabc228e35b420098840",
    "decimals": 18,
    "image": "https://rango.vip/i/AwqgU7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015613,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BTR",
    "name": "Bittrue",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5a16e8ce8ca316407c6e6307095dc9540a8d62b3",
    "decimals": 18,
    "image": "https://rango.vip/i/yvjMq6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02990411396759016,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTR",
    "name": "Bitrue Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd433138d12beb9929ff6fd583dc83663eea6aaa5",
    "decimals": 18,
    "image": "https://rango.vip/i/AqePte",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02988763,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAZE",
    "name": "Raze Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5eaa69b29f99c84fe5de8200340b4e9b4ab38eac",
    "decimals": 18,
    "image": "https://rango.vip/i/ma4wxJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00116521,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VALUE",
    "name": "VALUE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x49e833337ece7afe375e44f4e3e8481029218e5c",
    "decimals": 18,
    "image": "https://rango.vip/i/R8ou6K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0219054,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GOB",
    "name": "Goons of Balatroon",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa2f9ecf83a48b86265ff5fd36cdbaaa1f349916c",
    "decimals": 18,
    "image": "https://rango.vip/i/67Wueq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00063895,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOB",
    "name": "Goons of Balatroon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x830eb1204380e9c44434db8700257025358707c6",
    "decimals": 18,
    "image": "https://rango.vip/i/67Wueq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00063895,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGATA",
    "name": "Agatech",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5824d908411fb531ffb47e05950eb194f41e7da1",
    "decimals": 18,
    "image": "https://rango.vip/i/s9b8zR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01671155,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DDOS",
    "name": "disBalancer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7fbec0bb6a7152e77c30d005b5d49cbc08a602c3",
    "decimals": 18,
    "image": "https://rango.vip/i/6Pm1Ev",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01931689,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INXT",
    "name": "Internxt",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4a8f5f96d5436e43112c2fbc6a9f70da9e4e16d4",
    "decimals": 8,
    "image": "https://rango.vip/i/yxv7Du",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.120309,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RUFF",
    "name": "Ruff",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf278c1ca969095ffddded020290cf8b5c424ace2",
    "decimals": 18,
    "image": "https://rango.vip/i/nNsFis",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013287,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOR",
    "name": "The Force Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1fcdce58959f536621d76f5b7ffb955baa5a672f",
    "decimals": 18,
    "image": "https://rango.vip/i/wyCEyK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00390457,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FOR",
    "name": "The Force Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3816e40c1eb106c8fb7c05f901cfd58c7292d051",
    "decimals": 18,
    "image": "https://rango.vip/i/vsyfbY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0032160868406147245,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FOR",
    "name": "The Force Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x658a109c5900bc6d2357c87549b651670e5b0539",
    "decimals": 18,
    "image": "https://rango.vip/i/GmhAzF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00390457,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SAITAMA",
    "name": "One Punch Man",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CVRwdbkwSube1ifeNLFDdZSTmVEnLcyDuKXTdaZX1ua5",
    "decimals": 9,
    "image": "https://rango.vip/i/0Mk5Qx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00019419333822999808,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAITAMA",
    "name": "SAITAMA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce3f08e664693ca792cace4af1364d5e220827b2",
    "decimals": 9,
    "image": "https://rango.vip/i/P1TM3X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0020852,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEMECUP",
    "name": "MEME CUP",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2b8e02ce95b54362f8db7273015478dd725d7e7",
    "decimals": 8,
    "image": "https://rango.vip/i/yYZEwe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014506,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEL",
    "name": "Propel",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa75e7928d3de682e3f44da60c26f33117c4e6c5c",
    "decimals": 18,
    "image": "https://rango.vip/i/shAhgz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0033293,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAWN",
    "name": "Dawn Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x580c8520deda0a441522aeae0f9f7a5f29629afa",
    "decimals": 18,
    "image": "https://rango.vip/i/y3WqJo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00169908,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MITH",
    "name": "Mithril",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3893b9422cd5d70a81edeffe3d5a1c6a978310bb",
    "decimals": 18,
    "image": "https://rango.vip/i/bngTqa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012392,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GMAT",
    "name": "GoWithMi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb13de094cc5cee6c4cc0a3737bf0290166d9ca5d",
    "decimals": 18,
    "image": "https://rango.vip/i/jPA2Lg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004525,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NSO",
    "name": "neverSURRENDERone's",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HgMfSGndLq6vgLxCw4J33nJrwV2zTh81iEJNVwK9kcHD",
    "decimals": 9,
    "image": "https://rango.vip/i/adaZT9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00019082613377775578,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DFA",
    "name": "DeFine",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62959c699a52ec647622c91e79ce73344e4099f5",
    "decimals": 18,
    "image": "https://rango.vip/i/YYAVuk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00047481,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POS",
    "name": "PengyOS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "B8vV6An7xFF3bARB1cmU7TMfKNjjes2WvY7jWqiRc6K6",
    "decimals": 6,
    "image": "https://rango.vip/i/7ZEz0P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00012274,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DXGM",
    "name": "DexGame",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66f73d0fd4161cfad4302dc145ff994375c13475",
    "decimals": 18,
    "image": "https://rango.vip/i/gwaG4w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001862,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAC",
    "name": "BAC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a",
    "decimals": 18,
    "image": "https://rango.vip/i/CtCH5r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00210614,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BAC",
    "name": "BAC",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6f67043201c903bbcbc129750cb3b328dd56a0a5",
    "decimals": 18,
    "image": "https://rango.vip/i/9A1pe9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0019734364879352775,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNTR",
    "name": "Centaur",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03042482d64577a7bdb282260e2ea4c8a89c064b",
    "decimals": 18,
    "image": "https://rango.vip/i/m012ua",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002349,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COR",
    "name": "Coreto",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9c2dc0c3cc2badde84b0025cf4df1c5af288d835",
    "decimals": 18,
    "image": "https://rango.vip/i/yYI4Wr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00049056,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "COR",
    "name": "Coreto",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4fdce518fe527439fe76883e6b51a1c522b61b7c",
    "decimals": 18,
    "image": "https://rango.vip/i/zPsoO9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00049056,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YF-DAI",
    "name": "YfDAI.finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577",
    "decimals": 18,
    "image": "https://rango.vip/i/9JUaOp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 27.6,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FTG",
    "name": "OnGo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a4cab3dcfab144fe7eb2b5a3e288cc03dc07659",
    "decimals": 18,
    "image": "https://rango.vip/i/SyK3DT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00101077,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TFBX",
    "name": "Truefeedback",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4057db5bd9f67a566aa10e5587b1a964affc6a16",
    "decimals": 18,
    "image": "https://rango.vip/i/Ax1Hbk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003077,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "TFBX",
    "name": "TrueFeedBack New",
    "isPopular": false,
    "chainId": "42220",
    "address": "0xbdd31effb9e9f7509feaac5b4091b31645a47e4b",
    "decimals": 18,
    "image": "https://rango.vip/i/XWv9rd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.00003077,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TIDAL",
    "name": "Tidal Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x29cbd0510eec0327992cd6006e63f9fa8e7f33b7",
    "decimals": 18,
    "image": "https://rango.vip/i/BW97sc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001299,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FINA",
    "name": "Defina Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x426c72701833fddbdfc06c944737c6031645c708",
    "decimals": 18,
    "image": "https://rango.vip/i/pzyGxj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00165263,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZLW",
    "name": "Zelwin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5319e86f0e41a06e49eb37046b8c11d78bcad68c",
    "decimals": 18,
    "image": "https://rango.vip/i/lEqoZQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0016047,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SBABE",
    "name": "SNOOPYBABE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "D9mFkgnZHnQGRtZKvnJ44yvoLtJmfBZRahXiUKcAzRE4",
    "decimals": 6,
    "image": "https://rango.vip/i/hbKSMc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000011,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ALL",
    "name": "AllDomains",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BaoawH9p2J8yUK9r5YXQs3hQwmUJgscACjmTkh8rMwYL",
    "decimals": 6,
    "image": "https://rango.vip/i/64vXqa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00091021,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "KLO",
    "name": "Kalao Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb27c8941a7df8958a1778c0259f76d1f8b711c35",
    "decimals": 18,
    "image": "https://rango.vip/i/YEdUNm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00054718,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "JRT",
    "name": "Jarvis Reward Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x596ebe76e2db4470966ea395b0d063ac6197a8c5",
    "decimals": 18,
    "image": "https://rango.vip/i/QSt9z2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0035842,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JRT",
    "name": "Jarvis Reward Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a9c67fee641579deba04928c4bc45f66e26343a",
    "decimals": 18,
    "image": "https://rango.vip/i/eLQOXW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0035842,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XNO",
    "name": "Xeno",
    "isPopular": false,
    "chainId": "1",
    "address": "0x05bbe7240de66f6480c9aeda77c1376b13393f83",
    "decimals": 18,
    "image": "https://rango.vip/i/BcAdyf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008897,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIENS4",
    "name": "hiENS4",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00a7ec2f2b451cb0233e8adbf4c9a951027c2b02",
    "decimals": 18,
    "image": "https://rango.vip/i/jMcFDk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017714,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NRV",
    "name": "Nerve",
    "isPopular": false,
    "chainId": "56",
    "address": "0x42f6f551ae042cbe50c739158b4f0cac0edb9096",
    "decimals": 18,
    "image": "https://rango.vip/i/zlSYoj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00228261,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWINGBY",
    "name": "Swingby",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8287c7b963b405b7b8d467db9d79eec40625b13a",
    "decimals": 18,
    "image": "https://rango.vip/i/tVVB6L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000975,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SWINGBY",
    "name": "Swingby",
    "isPopular": false,
    "chainId": "56",
    "address": "0x71de20e0c4616e7fcbfdd3f875d568492cbe4739",
    "decimals": 18,
    "image": "https://rango.vip/i/LsbYdx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0000975,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TULIP",
    "name": "Tulip",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs",
    "decimals": 6,
    "image": "https://rango.vip/i/t4c2Y9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.125372,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWRV",
    "name": "Swerve DAO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8baa0e4287890a5f79863ab62b7f175cecbd433",
    "decimals": 18,
    "image": "https://rango.vip/i/H9A1X3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00591343,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRL",
    "name": "Triall",
    "isPopular": false,
    "chainId": "1",
    "address": "0x58f9102bf53cf186682bd9a281d3cd3c616eec41",
    "decimals": 18,
    "image": "https://rango.vip/i/ILZ2tS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00241734,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLS",
    "name": "Coldstack",
    "isPopular": false,
    "chainId": "1",
    "address": "0x675bbc7514013e2073db7a919f6e4cbef576de37",
    "decimals": 18,
    "image": "https://rango.vip/i/V2idQi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.066487,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KTON",
    "name": "Darwinia Commitment",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f284e1337a815fe77d2ff4ae46544645b20c5ff",
    "decimals": 18,
    "image": "https://rango.vip/i/hfoiYf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.92,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORION",
    "name": "Orion Money",
    "isPopular": false,
    "chainId": "1",
    "address": "0x727f064a78dc734d33eec18d5370aef32ffd46e4",
    "decimals": 18,
    "image": "https://rango.vip/i/9bdPhk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00096817,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAKE",
    "name": "SakeToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x066798d9ef0833ccc719076dab77199ecbd178b0",
    "decimals": 18,
    "image": "https://rango.vip/i/pxlkRz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00096372,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SAKE",
    "name": "SakeToken",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x552e4e96a0ce6d36d161b63984848c8dac471ea2",
    "decimals": 18,
    "image": "https://rango.vip/i/noU29m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0009630710836386272,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TKB",
    "name": "TokenBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16594930d16f3970e1a4317c6016555cb2e7b7fc",
    "decimals": 18,
    "image": "https://rango.vip/i/9ovk04",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016501,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIBEANZ",
    "name": "hiBEANZ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x23ddbd36547d43627afa9b42d4e9fb0515f48b09",
    "decimals": 18,
    "image": "https://rango.vip/i/W4b4Ah",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00058759,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "EM",
    "name": "EMERALD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x97e7044ab98b7891546485f5f66edcc2f58be106",
    "decimals": 18,
    "image": "https://rango.vip/i/QrD2my",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00006493189686,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EM",
    "name": "Eminer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x35b08722aa26be119c1608029ccbc976ac5c1082",
    "decimals": 8,
    "image": "https://rango.vip/i/QFgacY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006495,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TME",
    "name": "Tate Stop",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9c4cf40b5b5c3a58761683e65a87902130eb1b7c",
    "decimals": 9,
    "image": "https://rango.vip/i/IiscQc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003827,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DOSE",
    "name": "DOSE",
    "isPopular": false,
    "chainId": "137",
    "address": "0x81382e9693de2afc33f69b70a6c12ca9b3a73f47",
    "decimals": 18,
    "image": "https://rango.vip/i/jBgh9M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0001061,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POLI",
    "name": "Polinate",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1a36d3537bbe375cc9694795f663ddc8d516db9",
    "decimals": 18,
    "image": "https://rango.vip/i/C6vSH1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0005798,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CRP",
    "name": "CRO Predict",
    "isPopular": false,
    "chainId": "25",
    "address": "0x7b8ad6d7560facd1959cfb4b4163d7d297c4bfc0",
    "decimals": 18,
    "image": "https://rango.vip/i/Oy8Y7d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00030691844019675827,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CRP",
    "name": "CropperFinance",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz",
    "decimals": 9,
    "image": "https://rango.vip/i/odctnI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00317451,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOMA",
    "name": "Mochi Market",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd1848e1491d4308ad18287a745dd4db2a4bd55b",
    "decimals": 18,
    "image": "https://rango.vip/i/q6OFU7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00219581,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIFRIENDS",
    "name": "hiFRIENDS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb2f5a4e1441df330670dc7b0cf4eac0f7ab5fa5",
    "decimals": 18,
    "image": "https://rango.vip/i/uHLBhB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00032548,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FIN",
    "name": "DeFiner",
    "isPopular": false,
    "chainId": "1",
    "address": "0x054f76beed60ab6dbeb23502178c52d6c5debe40",
    "decimals": 18,
    "image": "https://rango.vip/i/BDhI7l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00051595,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SLRS",
    "name": "Solrise Finance",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr",
    "decimals": 6,
    "image": "https://rango.vip/i/OhDusK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00055997,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "APYS",
    "name": "APYSwap",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5JnZ667P3VcjDinkJFysWh2K2KtViy63FZ3oL5YghEhW",
    "decimals": 9,
    "image": "https://rango.vip/i/MvwktH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00460686,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APYS",
    "name": "APYSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf7413489c474ca4399eee604716c72879eea3615",
    "decimals": 18,
    "image": "https://rango.vip/i/goC6HR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00460686,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "APYS",
    "name": "APYSwap",
    "isPopular": false,
    "chainId": "56",
    "address": "0x37dfacfaeda801437ff648a1559d73f4c40aacb7",
    "decimals": 18,
    "image": "https://rango.vip/i/1FynAV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00465181051802516,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "APYS",
    "name": "APYS",
    "isPopular": false,
    "chainId": "128",
    "address": "0x90e8896b12a92d51cd213b681c2cad83a9a6bd49",
    "decimals": 18,
    "image": "https://rango.vip/i/UGOjaD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00465181051802516,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVY",
    "name": "EveryCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeed3ae7b0f8b5b9bb8c035a9941382b1822671cd",
    "decimals": 12,
    "image": "https://rango.vip/i/oP10Dx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000998,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOF",
    "name": "Golff.finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x488e0369f9bc5c40c002ea7c1fe4fd01a198801c",
    "decimals": 18,
    "image": "https://rango.vip/i/vw7jR9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00805809,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OBX",
    "name": "Obama6900",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c5095bb2dae81bb9a21ee9f1b7815cd710194e5",
    "decimals": 18,
    "image": "https://rango.vip/i/lHI6YT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001132,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OBX",
    "name": "OpenBlox Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x188fb5f5ae5bbe4154d5778f2bbb2fb985c94d25",
    "decimals": 18,
    "image": "https://rango.vip/i/PLYA4r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003315,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DTX",
    "name": "Data Exchange Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3f717919def69f81d17b80839bf8af35697ccbfa",
    "decimals": 18,
    "image": "https://rango.vip/i/spuE12",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0009998045524820965,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DTX",
    "name": "DaTa eXchange DTX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x765f0c16d1ddc279295c1a7c24b0883f62d33f75",
    "decimals": 18,
    "image": "https://rango.vip/i/Z2dhe4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00096781,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRAXX",
    "name": "Traxx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd43be54c1aedf7ee4099104f2dae4ea88b18a249",
    "decimals": 18,
    "image": "https://rango.vip/i/NZFrFP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00186982,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XLAB",
    "name": "XCELTOKEN PLUS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c4e7f814d40f8929f9112c5d09016f923d34472",
    "decimals": 18,
    "image": "https://rango.vip/i/CDB63l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000124,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APN",
    "name": "Apron",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd4342a57ecf2fe7ffa37c33cb8f63b1500e575e6",
    "decimals": 18,
    "image": "https://rango.vip/i/7NtPNL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00089038,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GDAO",
    "name": "Governor",
    "isPopular": false,
    "chainId": "1",
    "address": "0x515d7e9d75e2b76db60f8a051cd890eba23286bc",
    "decimals": 18,
    "image": "https://rango.vip/i/gh1nRz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02826747,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOWS",
    "name": "Shadows Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x661ab0ed68000491d98c796146bcf28c20d7c559",
    "decimals": 18,
    "image": "https://rango.vip/i/7HcnGq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0030597749501330056,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FXF",
    "name": "FINXFLO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a40c222996f9f3431f63bf80244c36822060f12",
    "decimals": 18,
    "image": "https://rango.vip/i/MPTezq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00088988,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNOW",
    "name": "SnowSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe9a29ab92522d14fc65880d817214261d8479ae",
    "decimals": 18,
    "image": "https://rango.vip/i/yYaM0s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.159281,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRR",
    "name": "Terran Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x490e3f4af13e1616ec97a8c6600c1061a8d0253e",
    "decimals": 18,
    "image": "https://rango.vip/i/WIcVPd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.003573,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GS",
    "name": "Gen Shards",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0b9a2c3e9f40cf74b2c7f591b2b0cca055c3112",
    "decimals": 18,
    "image": "https://rango.vip/i/RlIugP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00688718,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REAP",
    "name": "ReapChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1fc5ef0337aea85c5f9198853a6e3a579a7a6987",
    "decimals": 18,
    "image": "https://rango.vip/i/kxDdB8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005529,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "IGG",
    "name": "IGG",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce",
    "decimals": 6,
    "image": "https://rango.vip/i/Vmq1vj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.000008679818128750038,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AXIS",
    "name": "AXIS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xecc0f1f860a82ab3b442382d93853c02d6384389",
    "decimals": 18,
    "image": "https://rango.vip/i/aG5Zac",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0363251,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AXIS",
    "name": "AXIS Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0c5831ec3da15f3696b4dad8b21c7ce2f007f28",
    "decimals": 8,
    "image": "https://rango.vip/i/1cOKr2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00433955,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARES",
    "name": "Ares Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x358aa737e033f34df7c54306960a38d09aabd523",
    "decimals": 18,
    "image": "https://rango.vip/i/btYhxx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00031478,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TCP",
    "name": "The Crypto Prophecies",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06576eb3b212d605b797dc15523d9dc9f4f66db4",
    "decimals": 18,
    "image": "https://rango.vip/i/e5rqKC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00060505,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KOK",
    "name": "KOK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b9647431632af44be02ddd22477ed94d14aacaa",
    "decimals": 18,
    "image": "https://rango.vip/i/B2qgq6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0002896,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VDR",
    "name": "Vodra",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed3d4e446a96dc3b181b64b75c3c70da41dc3cbe",
    "decimals": 18,
    "image": "https://rango.vip/i/QZzmVb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00049197,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEYFI",
    "name": "KeyFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8647e90c0645152fccf4d9abb6b59eb4aa99052",
    "decimals": 18,
    "image": "https://rango.vip/i/vrhmZY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02553585,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SENC",
    "name": "Sentinel Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa13f0743951b4f6e3e3aa039f682e17279f52bc3",
    "decimals": 18,
    "image": "https://rango.vip/i/27d2nC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00034193,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALN",
    "name": "Aluna",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8185bc4757572da2a610f887561c32298f1a5748",
    "decimals": 18,
    "image": "https://rango.vip/i/p7ME39",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00176416,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ALN",
    "name": "Aluna",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9b3fa2a7c3eb36d048a5d38d81e7fafc6bc47b25",
    "decimals": 18,
    "image": "https://rango.vip/i/s8WFsg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00176416,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "NGM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/1dc495fcefda068a3820f903edbd78b942fbd204d7e93d3ba2b432e9669d1a59",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/NGM.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00332126,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NGM",
    "name": "e Money",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed0d5747a9ab03a75fbfec3228cd55848245b75d",
    "decimals": 6,
    "image": "https://rango.vip/i/KO9z4z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00332126,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "EMONEY",
    "symbol": "NGM",
    "name": null,
    "isPopular": true,
    "chainId": "emoney-3",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/gKNaK2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/EMONEY/icon.svg",
    "usdPrice": 0.00337278444348989,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRE8",
    "name": "Creaticles",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc36b4311b21fc0c2ead46f1ea6ce97c9c4d98d3d",
    "decimals": 18,
    "image": "https://rango.vip/i/FiqNII",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0008436,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "EZ",
    "name": "EZ Pepe",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7e1a118070bcea660a96a1063a39e0fa93080220",
    "decimals": 18,
    "image": "https://rango.vip/i/mvqRJY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00059582,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EZ",
    "name": "EasyFi V2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00aba6fe5557de1a1d565658cbddddf7c710a1eb",
    "decimals": 18,
    "image": "https://rango.vip/i/ghobeB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0102733,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "EZ",
    "name": "Easy V2",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5512014efa6cd57764fa743756f7a6ce3358cc83",
    "decimals": 18,
    "image": "https://rango.vip/i/Y4Zbcx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0102733,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EZ",
    "name": "EASY V2",
    "isPopular": false,
    "chainId": "137",
    "address": "0x34c1b299a74588d6abdc1b85a53345a48428a521",
    "decimals": 18,
    "image": "https://rango.vip/i/7YQD7w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0102733,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FCL",
    "name": "Fractal Protocol Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf4d861575ecc9493420a3f5a14f85b13f0b50eb3",
    "decimals": 18,
    "image": "https://rango.vip/i/oPbUIK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00404841,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DHV",
    "name": "DeHive.finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62dc4817588d53a056cbbd18231d91ffccd34b2a",
    "decimals": 18,
    "image": "https://rango.vip/i/w1xoZf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0150093,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNM",
    "name": "SONM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x46d0dac0926fa16707042cadc23f1eb4141fe86b",
    "decimals": 18,
    "image": "https://rango.vip/i/ISP0BJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00189832,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNM",
    "name": "SnailMoon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77571a64342667f7818520ef004b2b91f47a266b",
    "decimals": 18,
    "image": "https://rango.vip/i/DpI7sk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000514,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROCKI",
    "name": "Rocki",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff44b937788215eca197baaf9af69dbdc214aa04",
    "decimals": 18,
    "image": "https://rango.vip/i/aAjN8s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00705779,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZUM",
    "name": "ZUM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0b9bcd54bf8a730ea5d3f1ffce0885e911a502c",
    "decimals": 8,
    "image": "https://rango.vip/i/SjUbms",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000667,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCUG",
    "name": "Blockchain Cuties Universe Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x14da7b27b2e0fedefe0a664118b0c9bc68e2e9af",
    "decimals": 18,
    "image": "https://rango.vip/i/5EIcPy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03958371,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BCUG",
    "name": "BCUG",
    "isPopular": false,
    "chainId": "128",
    "address": "0xf73b911837a3d7001f472cb99bfa0a5a7667d08e",
    "decimals": 18,
    "image": "https://rango.vip/i/wZNtOF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.06842630298412478,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "404A",
    "name": "404Aliens",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd0d19f52ad8705e60ff31df75a7aca8f1399a69e",
    "decimals": 18,
    "image": "https://rango.vip/i/wiJmpL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.16,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ARGON",
    "name": "ArgonToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x1fe4751d9bdabac8d90067056cb45ab6823d2c12",
    "decimals": 18,
    "image": "https://rango.vip/i/tDam6d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.000517656221008478,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRCY",
    "name": "PRivaCY Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdfc3829b127761a3218bfcee7fc92e1232c9d116",
    "decimals": 8,
    "image": "https://rango.vip/i/wcBKcI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00271188,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BHC",
    "name": "Billion Happiness",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4",
    "decimals": 18,
    "image": "https://rango.vip/i/EdVxkQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.939626,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BDP",
    "name": "BDPToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3dcbc6d72a4e1892f7917b7c43b74131df8480e",
    "decimals": 18,
    "image": "https://rango.vip/i/bgPiFA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.096942,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NERD",
    "name": "NerdBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed1273928ba97eed7b49e82c2f39d512d7591112",
    "decimals": 18,
    "image": "https://rango.vip/i/HrX121",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0036107,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SHARD",
    "name": "Landtorn Shard",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x52c45d3068c937cb1e6b4a7f2c2a66b85056dd24",
    "decimals": 8,
    "image": "https://rango.vip/i/TbRQwm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00259253,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UPI",
    "name": "Pawtocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70d2b7c19352bb76e4409858ff5746e500f2b67c",
    "decimals": 18,
    "image": "https://rango.vip/i/7DqXJ7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016617,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YIELD",
    "name": "Yield Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa8b61cff52564758a204f841e636265bebc8db9b",
    "decimals": 18,
    "image": "https://rango.vip/i/kP7LwQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00128839,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YIELD",
    "name": "YieldStone",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c19f7854b27758ddffdcdc8908f22bf55e00736",
    "decimals": 18,
    "image": "https://rango.vip/i/Kx4a9h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.116432,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "YIELD",
    "name": "Crypto Valleys YIELD Token",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x67fa2887914fa3729e9eed7630294fe124f417a0",
    "decimals": 18,
    "image": "https://rango.vip/i/64nhfY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00478199,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XCUR",
    "name": "Curate",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1c7e30c42c24582888c758984f6e382096786bd",
    "decimals": 8,
    "image": "https://rango.vip/i/BA3X8J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00518114,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DNXC",
    "name": "DinoX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20a8cec5fffea65be7122bcab2ffe32ed4ebf03a",
    "decimals": 18,
    "image": "https://rango.vip/i/4sA5vS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00126581,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FXDX",
    "name": "FXDX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x30b593f8c3ab37615359b4e0e6df2e06d55bb55d",
    "decimals": 18,
    "image": "https://rango.vip/i/jIil9m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00110764,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUSY",
    "name": "Busy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5cb3ce6d081fb00d5f6677d196f2d70010ea3f4a",
    "decimals": 18,
    "image": "https://rango.vip/i/sgsKVU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033976,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPCT",
    "name": "Opacity",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb05ea0877a2622883941b939f0bb11d1ac7c400",
    "decimals": 18,
    "image": "https://rango.vip/i/sR76Vb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00126711,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EKTA",
    "name": "Ekta",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f75113b13d136f861d212fa9b572f2c79ac81c4",
    "decimals": 18,
    "image": "https://rango.vip/i/F36kPW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00115403,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BRY",
    "name": "Berry Tributes",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf859bf77cbe8699013d6dbc7c2b926aaf307f830",
    "decimals": 18,
    "image": "https://rango.vip/i/csPs7c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01979574,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SYP",
    "name": "Sypool",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ",
    "decimals": 9,
    "image": "https://rango.vip/i/7v8Nzr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00059287,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BTT",
    "name": "BitTorrent",
    "isPopular": false,
    "chainId": "56",
    "address": "0x352cb5e19b12fc216548a2677bd0fce83bae434b",
    "decimals": 18,
    "image": "https://rango.vip/i/Qxfidu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 7.84858e-7,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTT",
    "name": "BitTorrent",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc669928185dbce49d2230cc9b0979be6dc797957",
    "decimals": 18,
    "image": "https://rango.vip/i/BzCjVM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.84858e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BTT",
    "name": "BitTorrent",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8595f9da7b868b1822194faed312235e43007b49",
    "decimals": 18,
    "image": "https://rango.vip/i/rkWvee",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00158896,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "BTT",
    "name": "BitTorrent",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TAFjULxiVgT4qWk6UZwjqwZXTSaGaqnVp4",
    "decimals": 18,
    "image": "https://rango.vip/i/DCK09I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 7.84858e-7,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIMOONBIRDS",
    "name": "hiMOONBIRDS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a7b89e66a1dc16633abdfd132bae05827d3bfa5",
    "decimals": 18,
    "image": "https://rango.vip/i/hYYfcg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00082171,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NFTY",
    "name": "NFTY Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8623e66bea0dce41b6d47f9c44e806a115babae0",
    "decimals": 18,
    "image": "https://rango.vip/i/Qxs9Gq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00013809680667827096,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFTY",
    "name": "NFTY Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1d7c7a4596b038ced2a84bf65b8647271c53208",
    "decimals": 18,
    "image": "https://rango.vip/i/zPfkBz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013689,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CATMAN",
    "name": "CATMAN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EavJDLh8cYTAnt3QDitpKGMsPL2hq1My5g9R2P6at6Lc",
    "decimals": 9,
    "image": "https://rango.vip/i/XRydn1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003692,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASW",
    "name": "AdaSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x56694577564fdd577a0abb20fe95c1e2756c2a11",
    "decimals": 18,
    "image": "https://rango.vip/i/f9Rbt1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000542,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "VSO",
    "name": "VersoToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x846d50248baf8b7ceaa9d9b53bfd12d7d7fbb25a",
    "decimals": 18,
    "image": "https://rango.vip/i/nXd4yM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00036909,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAPP",
    "name": "LiquidApps",
    "isPopular": false,
    "chainId": "1",
    "address": "0x939b462ee3311f8926c047d2b576c389092b1649",
    "decimals": 4,
    "image": "https://rango.vip/i/FSrBFa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000047,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POLIS",
    "name": "Star Atlas DAO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk",
    "decimals": 8,
    "image": "https://rango.vip/i/5KuG8b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.147773,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AD",
    "name": "ADreward",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe973e453977195422b48e1852a207b7ee9c913c7",
    "decimals": 18,
    "image": "https://rango.vip/i/5HBKdW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000377,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PPAY",
    "name": "Plasma",
    "isPopular": false,
    "chainId": "1",
    "address": "0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2",
    "decimals": 18,
    "image": "https://rango.vip/i/PIEdgL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00050662,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MSB",
    "name": "Misbloc",
    "isPopular": false,
    "chainId": "1",
    "address": "0x84c722e6f1363e8d5c6db3ea600bef9a006da824",
    "decimals": 18,
    "image": "https://rango.vip/i/dSBkcA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001551,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHA",
    "name": "ETHA Lend",
    "isPopular": false,
    "chainId": "1",
    "address": "0x59e9261255644c411afdd00bd89162d09d862e38",
    "decimals": 18,
    "image": "https://rango.vip/i/nqZdtx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00331358,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRARE",
    "name": "Polkarare",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c2f7e7c5604d162d75641256b80f1bf6f4dc796",
    "decimals": 18,
    "image": "https://rango.vip/i/0hnaXv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00270085,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BPS",
    "name": "Base Pro Shops",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xda761a290e01c69325d12d82ac402e5a73d62e81",
    "decimals": 18,
    "image": "https://rango.vip/i/YapL0W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00009648,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BPS",
    "name": "Bandwidth AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x46947241be767bd05dc843755a991265170dac27",
    "decimals": 18,
    "image": "https://rango.vip/i/DhTa8G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01478264,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RGP",
    "name": "Rigel Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4af5ff1a60a6ef6c7c8f9c4e304cd9051fca3ec0",
    "decimals": 18,
    "image": "https://rango.vip/i/l2SJxK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00888318,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HAWK",
    "name": "Hawksight",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BKipkearSqAUdNKa1WDstvcMjoPsSKBuNyvKDQDDu9WE",
    "decimals": 6,
    "image": "https://rango.vip/i/DmsoDB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00007522,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SABLE",
    "name": "SABLE",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1ee098cbaf1f846d5df1993f7e2d10afb35a878d",
    "decimals": 18,
    "image": "https://rango.vip/i/VUhGmF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00543857,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SAO",
    "name": "Sator",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2HeykdKjzHKGm2LKHw8pDYwjKPiFEoXAz74dirhUgQvq",
    "decimals": 9,
    "image": "https://rango.vip/i/1sQasa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00048988,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAO",
    "name": "Sator",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ef389f264e07fff3106a3926f2a166d1393086f",
    "decimals": 9,
    "image": "https://rango.vip/i/ayiA0c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00048988,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MILK2",
    "name": "MilkyWay Token by SpaceSwap v2",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x721c299e6bf7d6a430d9bea3364ea197314bce09",
    "decimals": 18,
    "image": "https://rango.vip/i/RGzh0G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00357288,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MILK2",
    "name": "MilkyWay Token by SpaceSwap v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de",
    "decimals": 18,
    "image": "https://rango.vip/i/Eg82y7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00357288,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MILK2",
    "name": "MilkyWay Token by SpaceSwap v2",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4a5a34212404f30c5ab7eb61b078fa4a55adc5a5",
    "decimals": 18,
    "image": "https://rango.vip/i/Gr4u8P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00357288,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LKR",
    "name": "Lokr",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80ce3027a70e0a928d9268994e9b85d03bd4cdcf",
    "decimals": 18,
    "image": "https://rango.vip/i/5aOa3O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00100065,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HERO",
    "name": "Metahero",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd40bedb44c081d2935eeba6ef5a3c8a31a1bbe13",
    "decimals": 18,
    "image": "https://rango.vip/i/28RD7o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0019657,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HERO",
    "name": "StepHero",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe8176d414560cfe1bf82fd73b986823b89e4f545",
    "decimals": 18,
    "image": "https://rango.vip/i/bAzj1R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00129555,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AIRT",
    "name": "AirNFT Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x016cf83732f1468150d87dcc5bdf67730b3934d3",
    "decimals": 18,
    "image": "https://rango.vip/i/OsOF5M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00021586,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMTY",
    "name": "Smoothy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbf776e4fca664d791c4ee3a71e2722990e003283",
    "decimals": 18,
    "image": "https://rango.vip/i/TrFzM7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00473327,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORAO",
    "name": "ORAO Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee1cea7665ba7aa97e982edeaecb26b59a04d035",
    "decimals": 18,
    "image": "https://rango.vip/i/ItztTB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00060085,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEL",
    "name": "Melalie",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed0889f7e1c7c7267407222be277e1f1ef4d4892",
    "decimals": 18,
    "image": "https://rango.vip/i/CkLNkV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0010878173870215373,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "NTRN",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/126da09104b71b164883842b769c0e9ec1486c0887d27a9999e395c2c8fb5682",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/NTRN.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.331692,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "NEUTRON",
    "symbol": "NTRN",
    "name": null,
    "isPopular": true,
    "chainId": "neutron-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/DomY2z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/NEUTRON/icon.svg",
    "usdPrice": 0.33255917220782305,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HISEALS",
    "name": "hiSEALS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x286f851b049ccce1419e09b6468dc3297f86a703",
    "decimals": 18,
    "image": "https://rango.vip/i/kuI3Xz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00070775,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SNY",
    "name": "Synthetify",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y",
    "decimals": 6,
    "image": "https://rango.vip/i/eyFYKy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00362628,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STAK",
    "name": "Jigstack",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f8a626883d7724dbd59ef51cbd4bf1cf2016d13",
    "decimals": 18,
    "image": "https://rango.vip/i/qw0bCo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000094,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHOE",
    "name": "Laughing Shoe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8XVXzmsMMw7ufa8RC21fHcDP6TGti5y3ZidQinnYurqr",
    "decimals": 6,
    "image": "https://rango.vip/i/WJugf3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003879,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHOE",
    "name": "ShoeFy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0fd67b4ceb9b607ef206904ec73459c4880132c9",
    "decimals": 18,
    "image": "https://rango.vip/i/lpXkSF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0015091,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SHOE",
    "name": "Shoe404",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x096d19b58cab84a2f0ff0e81c08291bffaa62848",
    "decimals": 18,
    "image": "https://rango.vip/i/VXwSHI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 2.58,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SNOB",
    "name": "Snowball",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc38f41a296a4493ff429f1238e030924a1542e50",
    "decimals": 18,
    "image": "https://rango.vip/i/Re8nSX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0036030711849107063,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPUME",
    "name": "Spume",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa16a609ff4e1a15b6ccb469e7a5dd14e89305283",
    "decimals": 18,
    "image": "https://rango.vip/i/79crgj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00039476,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIA",
    "name": "Shiba Saga",
    "isPopular": false,
    "chainId": "1",
    "address": "0x43d7e65b8ff49698d9550a7f315c87e67344fb59",
    "decimals": 18,
    "image": "https://rango.vip/i/tPO98W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00034596,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "1EARTH",
    "name": "EarthFund",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e04f519b094f5f8210441e285f603f4d2b50084",
    "decimals": 18,
    "image": "https://rango.vip/i/qtnZDi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00025661,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MHUNT",
    "name": "MetaShooter",
    "isPopular": false,
    "chainId": "137",
    "address": "0x61f95bd637e3034133335c1baa0148e518d438ad",
    "decimals": 18,
    "image": "https://rango.vip/i/x0YpK2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00054352,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MHUNT",
    "name": "MetaShooter",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2c717059b366714d267039af8f59125cadce6d8c",
    "decimals": 18,
    "image": "https://rango.vip/i/eRWkSL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00054352,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CORX",
    "name": "CorionX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26a604dffe3ddab3bee816097f81d3c4a2a4cf97",
    "decimals": 8,
    "image": "https://rango.vip/i/vsMjDn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013426,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "THOR",
    "symbol": "RUNE",
    "name": null,
    "isPopular": true,
    "chainId": "thorchain-mainnet-v1",
    "address": null,
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/RUNE.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/THOR/icon.svg",
    "usdPrice": 3.5645970621679637,
    "supportedSwappers": [
      "ThorChain",
      "ThorChainStreamingSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "RUNE",
    "name": "THORChain",
    "isPopular": false,
    "chainId": "25",
    "address": "0x171c8aaa57d0107d0187f54ccf4cc03406e76a4e",
    "decimals": 8,
    "image": "https://rango.vip/i/uNxSok",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 3.5666166753479933,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RUNE",
    "name": "THORChain ETH.RUNE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3155ba85d5f96b2d030a4966af206230e46849cb",
    "decimals": 18,
    "image": "https://rango.vip/i/R7Vn15",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.565455304804892,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RUNE",
    "name": "Runebound",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae35dc90bb0bb9139f25fba6282b43aa02a9b9ca",
    "decimals": 18,
    "image": "https://rango.vip/i/ErqJTt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.04,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMA",
    "name": "MrWeb Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39162d9f64c0f9d64d14eac3b6d5a15d3d569eea",
    "decimals": 18,
    "image": "https://rango.vip/i/5iGCLi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024507,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AMA",
    "name": "AMAUROT",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe9cd2668fb580c96b035b6d081e5753f23fe7f46",
    "decimals": 18,
    "image": "https://rango.vip/i/xw1CKX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0002448702834315985,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KFT",
    "name": "Knit Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xef53462838000184f35f7d991452e5f25110b207",
    "decimals": 18,
    "image": "https://rango.vip/i/6WSlIq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00359886,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTB",
    "name": "Bitball",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06e0feb0d74106c7ada8497754074d222ec6bcdf",
    "decimals": 18,
    "image": "https://rango.vip/i/eXArkK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002801,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WQT",
    "name": "Work Quest",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06677dc4fe12d3ba3c7ccfd0df8cd45e4d4095bf",
    "decimals": 18,
    "image": "https://rango.vip/i/0zMYKB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015785,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NSURE",
    "name": "Nsure Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20945ca1df56d237fd40036d47e866c7dccd2114",
    "decimals": 18,
    "image": "https://rango.vip/i/9B66ok",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00261786,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LGCY",
    "name": "LGCY Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae697f994fc5ebc000f8e22ebffee04612f98a0d",
    "decimals": 18,
    "image": "https://rango.vip/i/Kr1ydz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000108,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TXA",
    "name": "TXA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4463e6a3ded0dbe3f6e15bc8420dfc55e5fea830",
    "decimals": 18,
    "image": "https://rango.vip/i/9NG4x0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00207129,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NNI",
    "name": "NeoNomad",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "buMnhMd5xSyXBssTQo15jouu8VhuEZJCfbtBUZgRcuW",
    "decimals": 6,
    "image": "https://rango.vip/i/85Ddf3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0030721064046571934,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGES",
    "name": "Dogeswap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb4fbed161bebcb37afb1cb4a6f7ca18b977ccb25",
    "decimals": 18,
    "image": "https://rango.vip/i/0rgBJx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.851065,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INNBC",
    "name": "Innovative Bioresearch Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb67718b98d52318240c52e71a898335da4a28c42",
    "decimals": 6,
    "image": "https://rango.vip/i/kYmQ3A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.575e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "UNI",
    "name": "Uniswap",
    "isPopular": false,
    "chainId": "25",
    "address": "0x16ad43896f7c47a5d9ee546c44a22205738b329c",
    "decimals": 18,
    "image": "https://rango.vip/i/SKWP90",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 6.192331977969627,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "UNI",
    "name": "Uniswap",
    "isPopular": false,
    "chainId": "10",
    "address": "0x6fd9d7ad17242c41f7131d257212c54a0e816691",
    "decimals": 18,
    "image": "https://rango.vip/i/PkkDtB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 6.19,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "UNI",
    "name": "Uniswap",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbf5140a22578168fd562dccf235e5d43a02ce9b1",
    "decimals": 18,
    "image": "https://rango.vip/i/4ZlNKp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 6.19,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "UNI",
    "name": "Uniswap",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0",
    "decimals": 18,
    "image": "https://rango.vip/i/ezqwHV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 6.19,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UNI",
    "name": "Uniswap (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8FU95xFJhUUkyyCLU13HSzDLs7oC4QZdXQHL6SCeab36",
    "decimals": 8,
    "image": "https://rango.vip/i/uJNLZU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 6.34,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNI",
    "name": "UNICORN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2730d6fdc86c95a74253beffaa8306b40fedecbb",
    "decimals": 8,
    "image": "https://rango.vip/i/WKwOmn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.21,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "UNI",
    "name": "Uniswap",
    "isPopular": false,
    "chainId": "SN_MAIN",
    "address": "0x49210ffc442172463f3177147c1aeaa36c51d152c1b0630f2364c300d4f48ee",
    "decimals": 18,
    "image": "https://rango.vip/i/DOLhyN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 6.1890324279317595,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "UNI",
    "name": "UNI",
    "isPopular": false,
    "chainId": "128",
    "address": "0x22c54ce8321a4015740ee1109d9cbc25815c46e6",
    "decimals": 18,
    "image": "https://rango.vip/i/6jX54w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 6.19,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NRFB",
    "name": "NuriFootBall",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00281dfce4cfd72c0b6fda2aaaf077258743f9e8",
    "decimals": 0,
    "image": "https://rango.vip/i/AWkZFG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005517,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALPHR",
    "name": "Alphr",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa99199d1e9644b588796f3215089878440d58e0",
    "decimals": 18,
    "image": "https://rango.vip/i/V5rtwQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00640765,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAYB",
    "name": "PayB",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdcb5645eda1ed34c5641d81b927d33ebae9cf2a4",
    "decimals": 18,
    "image": "https://rango.vip/i/NaWq6K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.329411,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FIU",
    "name": "beFITTER Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xef7d50069406a2f5a53806f7250a6c0f17ad9dcd",
    "decimals": 18,
    "image": "https://rango.vip/i/Hvpzi6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00022061,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HTZ",
    "name": "Hertz Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x9c8e99eb130aed653ef90fed709d9c3e9cc8b269",
    "decimals": 18,
    "image": "https://rango.vip/i/debYVJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.000001885737856269287,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEO",
    "name": "GeoDB Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x147faf8de9d8d8daae129b187f0d02d819126750",
    "decimals": 18,
    "image": "https://rango.vip/i/qt1lzR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013948,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MOWA",
    "name": "Moniwar",
    "isPopular": false,
    "chainId": "56",
    "address": "0x411ec510c85c9e56271bf4e10364ffa909e685d9",
    "decimals": 18,
    "image": "https://rango.vip/i/dy0qul",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0008507384804017664,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BYN",
    "name": "NBX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4bb3205bf648b7f59ef90dee0f1b62f6116bc7ca",
    "decimals": 18,
    "image": "https://rango.vip/i/HgInvM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00101278,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ISA",
    "name": "Islander",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x3eefb18003d033661f84e48360ebecd181a84709",
    "decimals": 18,
    "image": "https://rango.vip/i/DtYgl2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00001642412109726215,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKYRIM",
    "name": "Skyrim Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2610f0bfc21ef389fe4d03cfb7de9ac1e6c99d6e",
    "decimals": 18,
    "image": "https://rango.vip/i/YT4qpg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00043252,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VAL",
    "name": "Sora Validator Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe88f8313e61a97cec1871ee37fbbe2a8bf3ed1e4",
    "decimals": 18,
    "image": "https://rango.vip/i/SDy24S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02118675,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VAL",
    "name": "Valeria",
    "isPopular": false,
    "chainId": "1",
    "address": "0x011e128ec62840186f4a07e85e3ace28858c5606",
    "decimals": 18,
    "image": "https://rango.vip/i/sM9bhZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.119341,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GAJ",
    "name": "Gaj Finance",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x595c8481c48894771ce8fade54ac6bf59093f9e8",
    "decimals": 18,
    "image": "https://rango.vip/i/hHG8vf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00415564,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GAJ",
    "name": "PolyGaj Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf4b0903774532aee5ee567c02aab681a81539e92",
    "decimals": 18,
    "image": "https://rango.vip/i/Hb5fuM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00415564,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAJ",
    "name": "Gaj Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9fda7ceec4c18008096c2fe2b85f05dc300f94d0",
    "decimals": 18,
    "image": "https://rango.vip/i/CihFwA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00415564,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "PHNX",
    "name": "Phenix Finance",
    "isPopular": false,
    "chainId": "25",
    "address": "0x57d06bb1e3b60c875cd3a4445a53217f9b44d390",
    "decimals": 18,
    "image": "https://rango.vip/i/ni1zc7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0007319021408269798,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PHNX",
    "name": "Phoenix",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "JAzesW3tU2VLDx99pwgkDMca2DTNsEhSWG8sj21B1niz",
    "decimals": 6,
    "image": "https://rango.vip/i/lCMyx2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008927603477892698,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PHNX",
    "name": "PhoenixDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38a2fdc11f526ddd5a607c1f251c065f40fbf2f7",
    "decimals": 18,
    "image": "https://rango.vip/i/ROGmSn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021757,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GXT",
    "name": "Gem Exchange and Trading",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4674672bcddda2ea5300f5207e1158185c944bc0",
    "decimals": 18,
    "image": "https://rango.vip/i/iIh4PP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008171,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUZE",
    "name": "FUZE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x187d1018e8ef879be4194d6ed7590987463ead85",
    "decimals": 18,
    "image": "https://rango.vip/i/siAuMZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.35,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RENA",
    "name": "RENA Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x56de8bc61346321d4f2211e3ac3c0a7f00db9b76",
    "decimals": 18,
    "image": "https://rango.vip/i/yDIMaj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.062005,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBANK",
    "name": "blockbank",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf4b5470523ccd314c6b9da041076e7d79e0df267",
    "decimals": 18,
    "image": "https://rango.vip/i/9SOKur",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002868,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SWG",
    "name": "SWGToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe792f64c582698b8572aaf765bdc426ac3aefb6b",
    "decimals": 18,
    "image": "https://rango.vip/i/zm0NSx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00049958750931072,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SWG",
    "name": "Swirge",
    "isPopular": false,
    "chainId": "137",
    "address": "0x043a3aa319b563ac25d4e342d32bffb51298db7b",
    "decimals": 18,
    "image": "https://rango.vip/i/Bhrqu9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00049958750931072,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FORM",
    "name": "Formation FI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x21381e026ad6d8266244f2a583b35f9e4413fa2a",
    "decimals": 18,
    "image": "https://rango.vip/i/Eju6QI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006681,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FORM",
    "name": "Formation Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x25a528af62e56512a19ce8c3cab427807c28cc19",
    "decimals": 18,
    "image": "https://rango.vip/i/YkfQUI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00006681,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNN",
    "name": "SeChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5717f5df41ea67ef67dfd3c1d02f9940bcf5d08",
    "decimals": 3,
    "image": "https://rango.vip/i/bcvSGl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000393,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTRS",
    "name": "Bitball Treasure",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73c9275c3a2dd84b5741fd59aebf102c91eb033f",
    "decimals": 18,
    "image": "https://rango.vip/i/GAxXI6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01200645,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SPWN",
    "name": "Bitspawn Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5U9QqCPhqXAJcEv9uyzFJd5zhN93vuPk1aNNkXnUfPnt",
    "decimals": 9,
    "image": "https://rango.vip/i/Xy1k2E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000077,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPWN",
    "name": "Bitspawn",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe516d78d784c77d479977be58905b3f2b1111126",
    "decimals": 18,
    "image": "https://rango.vip/i/NvtBeb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000077,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NDX",
    "name": "Indexed",
    "isPopular": false,
    "chainId": "1",
    "address": "0x86772b1409b61c639eaac9ba0acfbb6e238e5f83",
    "decimals": 18,
    "image": "https://rango.vip/i/htHEPI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00458646,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NDX",
    "name": "Indexed",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb965029343d55189c25a7f3e0c9394dc0f5d41b1",
    "decimals": 18,
    "image": "https://rango.vip/i/xabjSI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00458646,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UFARM",
    "name": "UniFarm",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40986a85b4cfcdb054a6cbfb1210194fee51af88",
    "decimals": 18,
    "image": "https://rango.vip/i/RBeyZ7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013391,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UFARM",
    "name": "Unifarm Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa7305ae84519ff8be02484cda45834c4e7d13dd6",
    "decimals": 18,
    "image": "https://rango.vip/i/AoV7bi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00013391,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SXS",
    "name": "SphereSXS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x259ce0cb3581995d40cbb03fd4badeaaba1edaff",
    "decimals": 18,
    "image": "https://rango.vip/i/19wAmp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021174,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AXE",
    "name": "Axe Cap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x070e984fda37dd942f5c953f6b2375339adac308",
    "decimals": 18,
    "image": "https://rango.vip/i/NEy0BL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.417294,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MATE",
    "name": "Virtumate",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62126ec407eae34393ab88b1f5d57e8566e570be",
    "decimals": 18,
    "image": "https://rango.vip/i/1ggwBk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00130955,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CARE",
    "name": "CareCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x329cf160f30d21006bcd24b67eade561e54cde4c",
    "decimals": 18,
    "image": "https://rango.vip/i/qagqaO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.46959e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNTM",
    "name": "Connectome",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e5f00da8aaef196a719d045db89b5da8f371b32",
    "decimals": 18,
    "image": "https://rango.vip/i/iYNe80",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00133936,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVS",
    "name": "YVS.Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xec681f28f4561c2a9534799aa38e0d36a83cf478",
    "decimals": 18,
    "image": "https://rango.vip/i/1GQ6vu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.061241,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STAKE",
    "name": "STAKE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ae055097c6d159879521c384f1d2123d1f195e6",
    "decimals": 18,
    "image": "https://rango.vip/i/T0kQhG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.108502,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRMX",
    "name": "PREMA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe4dae00bc1c46ea2f44ae71b1beb8b171c15d812",
    "decimals": 18,
    "image": "https://rango.vip/i/XCVIdU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000106,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SFG",
    "name": "Stable Finance Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a6aca71a218301c7081d4e96d64292d3b275ce0",
    "decimals": 18,
    "image": "https://rango.vip/i/HqOhEr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00268481,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARNX",
    "name": "Aeron",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c37bcf456bc661c14d596683325623076d7e283",
    "decimals": 18,
    "image": "https://rango.vip/i/mRq2TI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000026,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WSPP",
    "name": "WolfSafePoorPeople",
    "isPopular": false,
    "chainId": "137",
    "address": "0x46d502fac9aea7c5bc7b13c8ec9d02378c33d36f",
    "decimals": 18,
    "image": "https://rango.vip/i/k1S9B8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 9.499e-9,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WSPP",
    "name": "WolfSafePoorPeople",
    "isPopular": false,
    "chainId": "56",
    "address": "0x46d502fac9aea7c5bc7b13c8ec9d02378c33d36f",
    "decimals": 0,
    "image": "https://rango.vip/i/zWcyjy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.2883e-11,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HXA",
    "name": "HXAcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15c1cab705b9ddb9ffeeea608ed8bafcdd4c0396",
    "decimals": 18,
    "image": "https://rango.vip/i/FrfIob",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001353,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RUSH",
    "name": "RushCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x382a1667c9062f0621362f49076ef6e4fe4c9ec7",
    "decimals": 18,
    "image": "https://rango.vip/i/XZxHsG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.602e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RNBW",
    "name": "HaloDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe94b97b6b43639e238c851a7e693f50033efd75c",
    "decimals": 18,
    "image": "https://rango.vip/i/26NnKe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.424e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JLP",
    "name": "Jupiter Perps",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4",
    "decimals": 6,
    "image": "https://rango.vip/i/FURs1Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.06,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HTX",
    "name": "HTX DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x61ec85ab89377db65762e234c946b5c25a56e99e",
    "decimals": 18,
    "image": "https://rango.vip/i/bGJPlY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000113,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "HTX",
    "name": "HTX",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TUPM7K8REVzD2UdV4R5fe5M8XbnR2DdoJ6",
    "decimals": 18,
    "image": "https://rango.vip/i/GUfQu6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.00000113,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORDER",
    "name": "Orderly Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xabd4c63d2616a5201454168269031355f4764337",
    "decimals": 18,
    "image": "https://rango.vip/i/W7LXw1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.162331,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAK",
    "name": "MetaCene",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc283c54df1d858570071a053057806ae73cb6a64",
    "decimals": 18,
    "image": "https://rango.vip/i/GDWJ9Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.122871,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOCA",
    "name": "Moca Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf944e35f95e819e752f3ccb5faf40957d311e8c5",
    "decimals": 18,
    "image": "https://rango.vip/i/BzWWGq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.094438,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOCA",
    "name": "Museum of Crypto Art",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ac07635ddbde5db18648c360defb00f5f22537e",
    "decimals": 18,
    "image": "https://rango.vip/i/uDseYw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.088563,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MOCA",
    "name": "Museum of Crypto Art",
    "isPopular": false,
    "chainId": "137",
    "address": "0xce899f26928a2b21c6a2fddd393ef37c61dba918",
    "decimals": 18,
    "image": "https://rango.vip/i/XkRfrG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.088563,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "USD+",
    "name": "USD+",
    "isPopular": false,
    "chainId": "10",
    "address": "0x73cb180bf0521828d8849bc8cf2b920918e23032",
    "decimals": 6,
    "image": "https://rango.vip/i/DJ7Zyn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USD+",
    "name": "Overnight fi USD   Arbitrum One ",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe80772eaf6e2e18b651f160bc9158b2a5cafca65",
    "decimals": 6,
    "image": "https://rango.vip/i/mZvCLy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "USD+",
    "name": "USD+",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xb79dd08ea68a908a97220c76d19a6aa9cbde4376",
    "decimals": 6,
    "image": "https://rango.vip/i/Dcp2zM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.999221,
    "supportedSwappers": [
      "BaseCurveFinance",
      "OneInchBase",
      "AlienBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USD+",
    "name": "USD+",
    "isPopular": false,
    "chainId": "137",
    "address": "0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f",
    "decimals": 6,
    "image": "https://rango.vip/i/by2LHi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USD+",
    "name": "USD+",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe80772eaf6e2e18b651f160bc9158b2a5cafca65",
    "decimals": 6,
    "image": "https://rango.vip/i/hPHXak",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "USD+",
    "name": "USD+",
    "isPopular": false,
    "chainId": "324",
    "address": "0x8e86e46278518efc1c5ced245cba2c7e3ef11557",
    "decimals": 6,
    "image": "https://rango.vip/i/oq3MyK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "USD+",
    "name": "USD+",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x4fee793d435c6d2c10c135983bb9d6d4fc7b9bbd",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HUGE",
    "name": "HugeWin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xec12ba5ac0f259e9ac6fc9a3bc23a76ad2fde5d9",
    "decimals": 18,
    "image": "https://rango.vip/i/m77ctl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0008483,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDAI",
    "name": "Savings Dai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x83f20f44975d03b1b09e64809b757c47f942beea",
    "decimals": 18,
    "image": "https://rango.vip/i/i4QCQO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIXFI",
    "name": "Pixelverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd795eb12034c2b77d787a22292c26fab5f5c70aa",
    "decimals": 18,
    "image": "https://rango.vip/i/8mfPab",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00750895,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MASA",
    "name": "Masa Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x944824290cc12f31ae18ef51216a223ba4063092",
    "decimals": 18,
    "image": "https://rango.vip/i/iccU8n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.04227324,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MASA",
    "name": "Masa",
    "isPopular": false,
    "chainId": "1",
    "address": "0x944824290cc12f31ae18ef51216a223ba4063092",
    "decimals": 18,
    "image": "https://rango.vip/i/dGi1Xk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04227324,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "SUNCAT",
    "name": "SUNCAT",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TAwAg9wtQzTMFsijnSFotJrpxhMm3AqW1d",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.00978428,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XT",
    "name": "XT com",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4be10da47a07716af28ad199fbe020501bddd7af",
    "decimals": 18,
    "image": "https://rango.vip/i/zORzc1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSTUSDT",
    "name": "Wrapped Staked USDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x572975ff6d5136c81c8d7448b6361ef9eefe1ab0",
    "decimals": 18,
    "image": "https://rango.vip/i/L1curl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.05,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "1CAT",
    "name": "Bitcoin Cats",
    "isPopular": false,
    "chainId": "1",
    "address": "0x508e00d5cef397b02d260d035e5ee80775e4c821",
    "decimals": 18,
    "image": "https://rango.vip/i/I6UarO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00153416,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AUSD",
    "name": "Avaware USD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x783c08b5f26e3daf8c4681f3bf49844e425b6393",
    "decimals": 18,
    "image": "https://rango.vip/i/YJ5o0v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.0002587095416025,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUSD",
    "name": "AUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00000000efe302beaa2b3e6e1b18d08d69a9012a",
    "decimals": 6,
    "image": "https://rango.vip/i/CWk8aR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AUSD",
    "name": "AUSD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x00000000efe302beaa2b3e6e1b18d08d69a9012a",
    "decimals": 6,
    "image": "https://rango.vip/i/zB8fvb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APES",
    "name": "ApeScreener",
    "isPopular": false,
    "chainId": "1",
    "address": "0x09675e24ca1eb06023451ac8088eca1040f47585",
    "decimals": 18,
    "image": "https://rango.vip/i/ywbOU2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00817304,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASTO",
    "name": "Altered State Machine Utility Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x823556202e86763853b40e9cde725f412e294689",
    "decimals": 18,
    "image": "https://rango.vip/i/LFdvDr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01031625,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GUMMY",
    "name": "GUMMY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "H7bTHGb5Cvo5fGe5jBDNDPUv8KykQnzyZA3qZ8sH7yxw",
    "decimals": 9,
    "image": "https://rango.vip/i/qXXafZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00664003,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MSN",
    "name": "Meson Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa247c0d81b83812e1abf8bab078e4540d87e3fb",
    "decimals": 18,
    "image": "https://rango.vip/i/aNS6ov",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.10315,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAI",
    "name": "SyntheticAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ea49ff332b7ad99c486347c1c2bcc73d1e22b9b",
    "decimals": 18,
    "image": "https://rango.vip/i/OYFmUu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018309,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAI",
    "name": "StudioAi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeac32927cf2785cbe0d515298cab448538a8cdd7",
    "decimals": 9,
    "image": "https://rango.vip/i/q1Mmkv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027889,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAI",
    "name": "Sign AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2ca93093e05366dbc2bf88c3bb189a5141fb6f6",
    "decimals": 9,
    "image": "https://rango.vip/i/esVG6Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02300295,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAI",
    "name": "Sharpe AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3567aa22cd3ab9aef23d7e18ee0d7cf16974d7e6",
    "decimals": 18,
    "image": "https://rango.vip/i/HCxej9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02905006,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JUP",
    "name": "Jupiter",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8",
    "decimals": 18,
    "image": "https://rango.vip/i/dSxmFH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00049809,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JUP",
    "name": "Jupiter",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN",
    "decimals": 6,
    "image": "https://rango.vip/i/Kp7n1x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.70288,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "P2PS",
    "name": "P2P solutions foundation",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4527a3b4a8a150403090a99b87effc96f2195047",
    "decimals": 8,
    "image": "https://rango.vip/i/SWSvpf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 151.88,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "L3",
    "name": "Layer3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88909d489678dd17aa6d9609f89b0419bf78fd9a",
    "decimals": 18,
    "image": "https://rango.vip/i/Qf2Mom",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03953074,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BPLC",
    "name": "BlackPearl",
    "isPopular": false,
    "chainId": "1",
    "address": "0x426fc8be95573230f6e6bc4af91873f0c67b21b4",
    "decimals": 18,
    "image": "https://rango.vip/i/VWQC4B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.683e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GHO",
    "name": "GHO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7dff72693f6a4149b17e7c6314655f6a9f7c8b33",
    "decimals": 18,
    "image": "https://rango.vip/i/GsmyHI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.003,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GHO",
    "name": "Gho Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40d16fc0246ad3160ccc09b8d0d3a2cd28ae6c2f",
    "decimals": 18,
    "image": "https://rango.vip/i/tchDhG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.003,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KMNO",
    "name": "Kamino",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS",
    "decimals": 6,
    "image": "https://rango.vip/i/KCNTyE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.04249186,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMILEY",
    "name": "Smiley Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c6e2c495b974b8d4220e370f23c7e0e1da9b644",
    "decimals": 9,
    "image": "https://rango.vip/i/G61pEj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.708e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TYBENG",
    "name": "TYBENG",
    "isPopular": false,
    "chainId": "1",
    "address": "0xca5b0ae1d104030a9b8f879523508efd86c14483",
    "decimals": 18,
    "image": "https://rango.vip/i/2hBLmG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017874,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FMB",
    "name": "FlappyMoonbird",
    "isPopular": false,
    "chainId": "1",
    "address": "0x52284158e02425290f6b627aeb5fff65edf058ad",
    "decimals": 18,
    "image": "https://rango.vip/i/gXXXUj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.018082327621617756,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VISTA",
    "name": "Ethervista",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc9bca88b04581699fab5aa276ccaff7df957cbbf",
    "decimals": 18,
    "image": "https://rango.vip/i/63d18U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 24.42,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FASTAI",
    "name": "Fast And AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf986408a1788ceb3bb4b4be4aa96a43914168554",
    "decimals": 18,
    "image": "https://rango.vip/i/1edg16",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00072549,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SAROS",
    "name": "Saros",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SarosY6Vscao718M4A778z4CGtvcwcGef5M9MEH1LGL",
    "decimals": 6,
    "image": "https://rango.vip/i/QBCDyy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00172457,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OETH",
    "name": "Origin Ether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x856c4efb76c1d1ae02e20ceb03a2a6a08b0b8dc3",
    "decimals": 18,
    "image": "https://rango.vip/i/j3ijt1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2340.95,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UB",
    "name": "Utopia Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1783d3979038d986f3959adf7dbfc527f42c4269",
    "decimals": 18,
    "image": "https://rango.vip/i/6hB03q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012091,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYNT",
    "name": "Synternet",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda987c655ebc38c801db64a8608bc1aa56cd9a31",
    "decimals": 18,
    "image": "https://rango.vip/i/M6NppP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0366952,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "MODE",
    "name": "Mode",
    "isPopular": false,
    "chainId": "34443",
    "address": "0xdfc7c877a950e49d2610114102175a06c2e3167a",
    "decimals": 18,
    "image": "https://rango.vip/i/eVIiEN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 0.011374441281931826,
    "supportedSwappers": [
      "SwapMode"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "KEYCAT",
    "name": "Keyboard Cat",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9a26f5433671751c3276a065f57e5a02d2817973",
    "decimals": 18,
    "image": "https://rango.vip/i/ZVdw2l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00074202,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KEYCAT",
    "name": "Keyboard Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9pPE1q9EW1bMQWbHmffrzUCfRr7S82UoxNUFfA6mAZC6",
    "decimals": 9,
    "image": "https://rango.vip/i/vet2yG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0012099,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WBT",
    "name": "WhiteBIT Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x925206b8a707096ed26ae47c84747fe0bb734f59",
    "decimals": 8,
    "image": "https://rango.vip/i/YF7lkV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 10.77,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "XEN",
    "name": "XEN Crypto",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc0c5aa69dbe4d6dddfbc89c0957686ec60f24389",
    "decimals": 18,
    "image": "https://rango.vip/i/qzYmec",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 9.13e-8,
    "supportedSwappers": [
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "XEN",
    "name": "Xena",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x981d41c115a2d48cb1215d13bda8f989d407c9c5",
    "decimals": 18,
    "image": "https://rango.vip/i/9wkc3F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00039315,
    "supportedSwappers": [
      "SushiBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XEN",
    "name": "XEN Crypto",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06450dee7fd2fb8e39061434babcfc05599a6fb8",
    "decimals": 18,
    "image": "https://rango.vip/i/wpe8R6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.13e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORDS",
    "name": "Ordiswap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ab2ff0116a279a99950c66a12298962d152b83c",
    "decimals": 18,
    "image": "https://rango.vip/i/EyCArP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01120133,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PDX",
    "name": "PDXCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a8fee0e33cb65a7e8d21badca62e87639ef74b3",
    "decimals": 18,
    "image": "https://rango.vip/i/S3D3nJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 191.01,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PDX",
    "name": "Paradox",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b8aa7777e18408c07b0ed52ca3dd5bdab34eb7e",
    "decimals": 18,
    "image": "https://rango.vip/i/kHaW6J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.69,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "XPET",
    "name": "xPet tech",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x00cbcf7b3d37844e44b888bc747bdd75fcf4e555",
    "decimals": 18,
    "image": "https://rango.vip/i/ZeQOJa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.055183,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "FRXETH",
    "name": "Frax Ether",
    "isPopular": false,
    "chainId": "10",
    "address": "0x6806411765af15bddd26f8f544a34cc40cb9838b",
    "decimals": 18,
    "image": "https://rango.vip/i/h6Mkkd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2343.18,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRXETH",
    "name": "Frax Ether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5e8422345238f34275888049021821e8e08caa1f",
    "decimals": 18,
    "image": "https://rango.vip/i/IHEzZn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2343.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FRXETH",
    "name": "FRXETH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x178412e79c25968a32e89b11f63b33f733770c2a",
    "decimals": 18,
    "image": "https://rango.vip/i/gRQdz7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2343.18,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TURT",
    "name": "TurtSat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0800394f6e23dd539929c8b77a3d45c96f76aefc",
    "decimals": 18,
    "image": "https://rango.vip/i/ON0tZm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00282884,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BASEDAI",
    "name": "basedAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44971abf0251958492fee97da3e5c5ada88b9185",
    "decimals": 18,
    "image": "https://rango.vip/i/gpRdqP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.02,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PEW",
    "name": "PEW for BOME and PEPE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5zgTYTDK836G2Fc4ZLQp4rsyi78pAbuDr4qaQUE1pump",
    "decimals": 6,
    "image": "https://rango.vip/i/tHnVD8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00044857,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEW",
    "name": "pepe in a memes world",
    "isPopular": false,
    "chainId": "1",
    "address": "0x382ea807a61a418479318efd96f1efbc5c1f2c21",
    "decimals": 18,
    "image": "https://rango.vip/i/7MCiwS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000187,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDP",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e870d67f660d95d5be530380d0ec0bd388289e1",
    "decimals": 18,
    "image": "https://rango.vip/i/GVSuNE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.99963,
    "supportedSwappers": [
      "ThorChain",
      "EthereumUniswapV3",
      "ThorChainStreamingSwap",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDP",
    "name": "USDP Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1456688345527be1f37e9e627da0837d6f08c925",
    "decimals": 18,
    "image": "https://rango.vip/i/IbrJE6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.868053,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USDP",
    "name": "Pax Dollar",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x78df3a6044ce3cb1905500345b967788b699df8f",
    "decimals": 18,
    "image": "https://rango.vip/i/D9tKGs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.0007539633228484,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LMF",
    "name": "Lamas Finance",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LMFzmYL6y1FX8HsEmZ6yNKNzercBmtmpg2ZoLwuUboU",
    "decimals": 9,
    "image": "https://rango.vip/i/MIOakB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03874502,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TITANX",
    "name": "TitanX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf19308f923582a6f7c465e5ce7a9dc1bec6665b1",
    "decimals": 18,
    "image": "https://rango.vip/i/tzwMF2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.79949e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUSIC",
    "name": "Gala Music",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd8c0b13b551718b808fc97ead59499d5ef862775",
    "decimals": 8,
    "image": "https://rango.vip/i/SQwL2P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03171938,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZERO",
    "name": "ZERO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "93RC484oMK5T9H89rzT5qiAXKHGP9jscXfFfrihNbe57",
    "decimals": 9,
    "image": "https://rango.vip/i/M7nSoa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.120174,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZERO",
    "name": "Zero.Exchange Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0939011a9bb95c3b791f0cb546377ed2693a574",
    "decimals": 18,
    "image": "https://rango.vip/i/gqhWip",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013231,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZERO",
    "name": "ZERO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ec78ed49c2d27b315d462d43b5bab94d2c79bf8",
    "decimals": 18,
    "image": "https://rango.vip/i/c5hP5j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00773956,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZERO",
    "name": "Zero Money",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66d592cc979d441a3e4dbb6e043c3bdad241dab7",
    "decimals": 18,
    "image": "https://rango.vip/i/1lp07T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001908575868686437,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TENET",
    "name": "TENET",
    "isPopular": false,
    "chainId": "1",
    "address": "0x788d86e00ab31db859c3d6b80d5a9375801d7f2a",
    "decimals": 18,
    "image": "https://rango.vip/i/VQMhOv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0062577,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DMAGA",
    "name": "Dark MAGA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump",
    "decimals": 6,
    "image": "https://rango.vip/i/WnOSAe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00496489,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNM",
    "name": "UNIUM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6570ffe19da7e2b425329b157d9109b87f18304b",
    "decimals": 18,
    "image": "https://rango.vip/i/uRlelg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.26,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SRC",
    "name": "Simracer Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16587cf43f044aba0165ffa00acf412631194e4b",
    "decimals": 18,
    "image": "https://rango.vip/i/P6HYgN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01057409,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "DOLA",
    "name": "DOLA",
    "isPopular": false,
    "chainId": "10",
    "address": "0x8ae125e8653821e851f12a49f7765db9a9ce7384",
    "decimals": 18,
    "image": "https://rango.vip/i/FE3NHD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.993171,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DOLA",
    "name": "Dola USD Stablecoin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x4621b7a9c75199271f773ebd9a499dbd165c3191",
    "decimals": 18,
    "image": "https://rango.vip/i/FVR5UL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.993171,
    "supportedSwappers": [
      "OneInchBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOLA",
    "name": "Dola USD Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x865377367054516e17014ccded1e7d814edc9ce4",
    "decimals": 18,
    "image": "https://rango.vip/i/EITDIB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.993171,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWGT",
    "name": "SmartWorld Global Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc8bf8bc34874e07f6a0d4abc8be22ba9e372631b",
    "decimals": 8,
    "image": "https://rango.vip/i/3EUu1w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.216088,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LBK",
    "name": "LBK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9cb1aeafcc8a9406632c5b084246ea72f62d37b6",
    "decimals": 8,
    "image": "https://rango.vip/i/KWM4nK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00745641,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MELD",
    "name": "MELD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x333000333b26ee30214b4af6419d9ab07a450400",
    "decimals": 18,
    "image": "https://rango.vip/i/miZjUk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01118219,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MELD",
    "name": "MELD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x333000333b26ee30214b4af6419d9ab07a450400",
    "decimals": 18,
    "image": "https://rango.vip/i/rFLcup",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01118219,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$NAP",
    "name": "SNAP",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4G86CMxGsMdLETrYnavMFKPhQzKTvDBYGMRAdVtr72nu",
    "decimals": 6,
    "image": "https://rango.vip/i/4Br6C6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000426,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "00",
    "name": "00 Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x881ba05de1e78f549cc63a8f6cabb1d4ad32250d",
    "decimals": 18,
    "image": "https://rango.vip/i/VrbyB7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03927221,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "WELL",
    "name": "Moonwell",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x511ab53f793683763e5a8829738301368a2411e3",
    "decimals": 18,
    "image": "https://rango.vip/i/mnfHwv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.01612627,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WELL",
    "name": "WELL3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x63696fc66795b51d02c1590b536484a41fbddf9a",
    "decimals": 18,
    "image": "https://rango.vip/i/bviIim",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00075736,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WELL",
    "name": "BitWell Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf07a32eb035b786898c00bb1c64d8c6f8e7a46d5",
    "decimals": 18,
    "image": "https://rango.vip/i/1q6OTv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.015936469754014092,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APFC",
    "name": "APF coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ed955a2b7d2c3a17a9d05daca95e01818f8c11e",
    "decimals": 18,
    "image": "https://rango.vip/i/SUYFg4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.714101,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSH",
    "name": "White Yorkshire",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12996c7b23c4012149bf9f5663ff9aa08a9cf2e4",
    "decimals": 18,
    "image": "https://rango.vip/i/zwdRnW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000022,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELG",
    "name": "Escoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2085073878152ac3090ea13d1e41bd69e60dc99",
    "decimals": 18,
    "image": "https://rango.vip/i/ESqIno",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.68927,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZUZALU",
    "name": "Zuzalu Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1f17b7a6bff962659ed608bcd6d318bb5fbb249",
    "decimals": 18,
    "image": "https://rango.vip/i/q5PmDa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00090554,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DRGN",
    "name": "Dragonchain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x419c4db4b9e25d6db2ad9691ccb832c8d9fda05e",
    "decimals": 18,
    "image": "https://rango.vip/i/aAvSo5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01299166,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SELFIE",
    "name": "SelfieDogCoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9WPTUkh8fKuCnepRWoPYLH3aK9gSjPHFDenBq2X1Czdp",
    "decimals": 6,
    "image": "https://rango.vip/i/NXM2YE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.011853,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PHIL",
    "name": "Phil",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc328a59e7321747aebbc49fd28d1b32c1af8d3b2",
    "decimals": 18,
    "image": "https://rango.vip/i/q9nQCS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01882463,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NEXT",
    "name": "Everclear",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x58b9cb810a68a7f3e1e4f8cb45d1b9b3c79705e8",
    "decimals": 18,
    "image": "https://rango.vip/i/VAyiun",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.126604,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEXT",
    "name": "Connext",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe67a4450907459c3e1fff623aa927dd4e28c67a",
    "decimals": 18,
    "image": "https://rango.vip/i/VAyiun",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.126604,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WHALES",
    "name": "Catalina Whale Mixer Floor Index",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DSXWF79VQ3RzFBB67WeNfCzfzAQq5X6m97zi85bq1TRx",
    "decimals": 2,
    "image": "https://rango.vip/i/8goHux",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.35701,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WHALES",
    "name": "Whales Market",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GTH3wG3NErjwcf7VGCoXEXkgXSHvYhx5gtATeeM5JAS1",
    "decimals": 6,
    "image": "https://rango.vip/i/H9pJVB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.148906,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MOEW",
    "name": "donotfomoew",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x15ac90165f8b45a80534228bdcb124a011f62fee",
    "decimals": 18,
    "image": "https://rango.vip/i/WRGDph",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.000403,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GRAIL",
    "name": "Camelot token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3d9907f9a368ad0a51be60f7da3b97cf940982d8",
    "decimals": 18,
    "image": "https://rango.vip/i/jBwImO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 928.68,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PORT3",
    "name": "Port3 Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb4357054c3da8d46ed642383f03139ac7f090343",
    "decimals": 18,
    "image": "https://rango.vip/i/ckLKPv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03447677,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JUNGLE",
    "name": "Jungle",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi",
    "decimals": 9,
    "image": "https://rango.vip/i/lYtRSZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01269021,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MAW",
    "name": "MawCAT",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x435f49a87df832a582a1657421f083a0529edf7c",
    "decimals": 9,
    "image": "https://rango.vip/i/c3hno4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00003204,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JSM",
    "name": "Joseon Mun",
    "isPopular": false,
    "chainId": "1",
    "address": "0x04c618cdbc1d59142dfeb4b9864835a06983ec2d",
    "decimals": 18,
    "image": "https://rango.vip/i/FTLcsQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01225635,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BABY",
    "name": "Baby Samo Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Uuc6hiKT9Y6ASoqs2phonGGw2LAtecfJu9yEohppzWH",
    "decimals": 9,
    "image": "https://rango.vip/i/szVwOq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000599,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BABY",
    "name": "Baby",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5hmf8Jt9puwoqiFQTb3vr22732ZTKYRLRw9Vo7tN3rcz",
    "decimals": 6,
    "image": "https://rango.vip/i/a7va1c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 6.96884e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABY",
    "name": "Dancing Baby",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff931a7946d2fa11cf9123ef0dc6f6c7c6cb60c4",
    "decimals": 9,
    "image": "https://rango.vip/i/topfLl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00099947,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABY",
    "name": "meta baby",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1ccf27211e8bf052f6255329ed641b4e94e80603",
    "decimals": 18,
    "image": "https://rango.vip/i/fzaZH2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00050482,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BABY",
    "name": "BabySwap Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x53e562b9b7e5e94b81f10e96ee70ad06df3d2657",
    "decimals": 18,
    "image": "https://rango.vip/i/B1IHvX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00296263,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FAC",
    "name": "Flying Avocado Cat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a3a8cf347b2bf5890d3d6a1b981c4f4432c8661",
    "decimals": 18,
    "image": "https://rango.vip/i/R1siwu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.32834,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAR",
    "name": "Gold Standard",
    "isPopular": false,
    "chainId": "1",
    "address": "0x777be1c6075c20184c4fd76344b7b0b7c858fe6b",
    "decimals": 18,
    "image": "https://rango.vip/i/Oxh3pc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XODEX",
    "name": "Xodex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb81408a1cc2f4be70a6a3178d351ca95a77c5a06",
    "decimals": 18,
    "image": "https://rango.vip/i/EElTys",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017285,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WUSD",
    "name": "Wrapped USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x068e3563b1c19590f822c0e13445c4fa1b9eefa5",
    "decimals": 18,
    "image": "https://rango.vip/i/Runenb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.99964,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WUSD",
    "name": "Worldwide USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb6667b04cb61aa16b59617f90ffa068722cf21da",
    "decimals": 6,
    "image": "https://rango.vip/i/O2lbJ3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999647,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ELU",
    "name": "Elumia Crowns",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4tJZhSdGePuMEfZQ3h5LaHjTPsw1iWTRFTojnZcwsAU6",
    "decimals": 9,
    "image": "https://rango.vip/i/vvN53r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00397518,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OLAS",
    "name": "Autonolas",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0001a500a6b18995b03f44bb040a5ffc28e45cb0",
    "decimals": 18,
    "image": "https://rango.vip/i/j5fHKj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.728229,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "OLAS",
    "name": "Autonolas",
    "isPopular": false,
    "chainId": "10",
    "address": "0xfc2e6e6bcbd49ccf3a5f029c79984372dcbfe527",
    "decimals": 18,
    "image": "https://rango.vip/i/mXbhpf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.724438568573156,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USD0",
    "name": "Usual USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73a15fed60bf67631dc6cd7bc5b6e8da8190acf5",
    "decimals": 18,
    "image": "https://rango.vip/i/OxCyDo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999133,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PENG",
    "name": "Peng",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A3eME5CetyZPBoWbRUwY3tSe25S6tb18ba9ZPbWk9eFJ",
    "decimals": 6,
    "image": "https://rango.vip/i/klczFU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.103163,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LTD",
    "name": "Living the Dream",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1fdb29ad49330b07ae5a87483f598aa6b292039e",
    "decimals": 18,
    "image": "https://rango.vip/i/UUV1bM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001545,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RETIK",
    "name": "Retik Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26ebb8213fb8d66156f1af8908d43f7e3e367c1d",
    "decimals": 18,
    "image": "https://rango.vip/i/wJUI2s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00736092,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FURY",
    "name": "Engines of Fury Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0203d275d2a65030889af45ed91d472be3948b92",
    "decimals": 18,
    "image": "https://rango.vip/i/3V7PjQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.175886,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FJO",
    "name": "Fjord Foundry",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69457a1c9ec492419344da01daf0df0e0369d5d0",
    "decimals": 18,
    "image": "https://rango.vip/i/7fzhqx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.24029,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WBC",
    "name": "WorldBrain Coin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7ae9ab13fc8945323b778b3f8678145e80ec2efb",
    "decimals": 6,
    "image": "https://rango.vip/i/p2u9sX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.19621,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SPEC",
    "name": "Spectral",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x96419929d7949d6a801a6909c145c8eef6a40431",
    "decimals": 18,
    "image": "https://rango.vip/i/5X2Emv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 5.56,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPEC",
    "name": "Speculate",
    "isPopular": false,
    "chainId": "1",
    "address": "0xab0ceb816ad51168ea61545316ee0b3387122243",
    "decimals": 18,
    "image": "https://rango.vip/i/aE438g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00107601,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPEC",
    "name": "Spectral Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xadf7c35560035944e805d98ff17d58cde2449389",
    "decimals": 18,
    "image": "https://rango.vip/i/5X2Emv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.56,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPEC",
    "name": "Spectrum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19a71179fd41c19f8dfb1f38343482bec0271e3c",
    "decimals": 18,
    "image": "https://rango.vip/i/nRBm4B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00330456,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VOW",
    "name": "Vow",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1bbf25e71ec48b84d773809b4ba55b6f4be946fb",
    "decimals": 18,
    "image": "https://rango.vip/i/iLepg5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.120543,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$WATER",
    "name": "WATER",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "B6h248NJkAcBAkaCnji889a26tCiGXGN8cxhEJ4dX391",
    "decimals": 6,
    "image": "https://rango.vip/i/9HFZRw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00005543,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "MAV",
    "name": "Maverick Token",
    "isPopular": false,
    "chainId": "324",
    "address": "0x787c09494ec8bcb24dcaf8659e7d5d69979ee508",
    "decimals": 18,
    "image": "https://rango.vip/i/98h9NA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.180837,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAV",
    "name": "Maverick Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7448c7456a97769f6cd04f1e83a4a23ccdc46abd",
    "decimals": 18,
    "image": "https://rango.vip/i/R9Wna7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.180837,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0DOG",
    "name": "Bitcoin Dogs",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa6624d7363ef8284aa8ce4e18146ded5f421b2c",
    "decimals": 18,
    "image": "https://rango.vip/i/OezF20",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00936948,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XFI",
    "name": "Xfinance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5befbb272290dd5b8521d4a938f6c4757742c430",
    "decimals": 18,
    "image": "https://rango.vip/i/IG0nXK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 61.5,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZEX",
    "name": "Zeta",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ZEXy1pqteRu3n13kdyh4LwPQknkFk3GzmMYMuNadWPo",
    "decimals": 6,
    "image": "https://rango.vip/i/UJlLnV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.04353796,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JASON",
    "name": "Jason Derulo",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6SUryVEuDz5hqAxab6QrGfbzWvjN8dC7m29ezSvDpump",
    "decimals": 6,
    "image": "https://rango.vip/i/KfW7bj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00123192,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JASON",
    "name": "Jason  Eth ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4d243e8f511045f0d5f9d0288bc628737b10c079",
    "decimals": 9,
    "image": "https://rango.vip/i/twr5Xt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002741,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CELL",
    "name": "Cellframe Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26c8afbbfe1ebaca03c2bb082e69d0476bffe099",
    "decimals": 18,
    "image": "https://rango.vip/i/nXmuj2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.276877,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CELL",
    "name": "CellMates",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f59b64c6ee521c0d7e590cf8e4b843caa236f96",
    "decimals": 0,
    "image": "https://rango.vip/i/jbxLyt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 447.3,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BORING",
    "name": "BoringDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc19712feb3a26080ebf6f2f7849b417fdd792ca",
    "decimals": 18,
    "image": "https://rango.vip/i/8yUYvz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00178776,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BORING",
    "name": "BoringDAO",
    "isPopular": false,
    "chainId": "56",
    "address": "0xffeecbf8d7267757c2dc3d13d730e97e15bfdf7f",
    "decimals": 18,
    "image": "https://rango.vip/i/Gvo2XI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00178776,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WSDM",
    "name": "Wisdomise",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5f2f8818002dc64753daedf4a6cb2ccb757cd220",
    "decimals": 6,
    "image": "https://rango.vip/i/X409lc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02669453,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LAI",
    "name": "LayerAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x168e209d7b2f58f1f24b8ae7b7d35e662bbf11cc",
    "decimals": 18,
    "image": "https://rango.vip/i/8SO9Rj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00996022,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RTF",
    "name": "Ready to Fight",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa89e2871a850e0e6fd8f0018ec1fc62fa75440d4",
    "decimals": 18,
    "image": "https://rango.vip/i/e5bHA2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04392252,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BSSB",
    "name": "BitStable Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda31d0d1bc934fc34f7189e38a413ca0a5e8b44f",
    "decimals": 18,
    "image": "https://rango.vip/i/gpbyu7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.1196847076803678,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NLK",
    "name": "NuLink",
    "isPopular": false,
    "chainId": "1",
    "address": "0x744030ad4e6c10faf5483b62473d88a254d62261",
    "decimals": 18,
    "image": "https://rango.vip/i/w6e2xM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01409702,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDZ",
    "name": "ZAI Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69000405f9dce69bd4cbf4f2865b79144a69bfe0",
    "decimals": 18,
    "image": "https://rango.vip/i/FP4LBv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDZ",
    "name": "USDZ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8baef8c9568c21b1a2b2fd048f8b4da835691fd0",
    "decimals": 18,
    "image": "https://rango.vip/i/2mxdQq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.3966e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDZ",
    "name": "USDz",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa469b7ee9ee773642b3e93e842e5d9b5baa10067",
    "decimals": 18,
    "image": "https://rango.vip/i/9D1RoX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "USDZ",
    "name": "Anzen USDz",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x52056ed29fe015f4ba2e3b079d10c0b87f46e8c6",
    "decimals": 18,
    "image": "https://rango.vip/i/9D1RoX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MMX",
    "name": "MMX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x614da3b37b6f66f7ce69b4bbbcf9a55ce6168707",
    "decimals": 18,
    "image": "https://rango.vip/i/Kf0Kmi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.64,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOOE",
    "name": "Book of Ethereum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x289ff00235d2b98b0145ff5d4435d3e92f9540a6",
    "decimals": 18,
    "image": "https://rango.vip/i/yiMTlv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.081892,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PT",
    "name": "Phemex Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbbb32f99e6f2cb29337eebaa43c5069386de6e6c",
    "decimals": 18,
    "image": "https://rango.vip/i/CznaeK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DOGA",
    "name": "DOGAMI",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2f3e306d9f02ee8e8850f9040404918d0b345207",
    "decimals": 5,
    "image": "https://rango.vip/i/5LhaAy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00368878,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BOOMER",
    "name": "Boomer",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xcde172dc5ffc46d228838446c57c1227e0b82049",
    "decimals": 18,
    "image": "https://rango.vip/i/2Wi7CU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0029328,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOOMER",
    "name": "Boomers on Sol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7jmaTFBooHkaSrBJDftu3LcK85KPtqWTCaFZCDxQV7ZW",
    "decimals": 6,
    "image": "https://rango.vip/i/b2qtZy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00059354,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHEF",
    "name": "Chefdotfun",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f2bf2f59cdf7be4aee71500b9419623202b8636",
    "decimals": 18,
    "image": "https://rango.vip/i/H9CE9I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03068529,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FP",
    "name": "Forgotten Playland",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeeee2a2e650697d2a8e8bc990c2f3d04203be06f",
    "decimals": 18,
    "image": "https://rango.vip/i/OKXkO9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00287891,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "XETA",
    "name": "XETA",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x827eb4bada6cb76c90f887969b3fe5fad585ffe3",
    "decimals": 18,
    "image": "https://rango.vip/i/lG0ytQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.003858375610301975,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XETA",
    "name": "XANA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x967fb0d760ed3ce53afe2f0a071674cccae73550",
    "decimals": 18,
    "image": "https://rango.vip/i/07mUMT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00386032,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XETA",
    "name": "XANA",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbc7370641ddcf16a27eea11230af4a9f247b61f9",
    "decimals": 18,
    "image": "https://rango.vip/i/GdWzEL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00386032,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DOG",
    "name": "Base Dog",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x3b916b8f6a710e9240ff08c1dd646dd8e8ed9e1e",
    "decimals": 8,
    "image": "https://rango.vip/i/Lthw9W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 351.84,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOG",
    "name": "The Doge NFT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbaac2b4491727d78d2b78815144570b9f2fe8899",
    "decimals": 18,
    "image": "https://rango.vip/i/T7IvUr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00338693,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DOG",
    "name": "The Doge NFT",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4425742f1ec8d98779690b5a3a6276db85ddc01a",
    "decimals": 18,
    "image": "https://rango.vip/i/sYABWH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00338693,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOG",
    "name": "dog",
    "isPopular": false,
    "chainId": "1",
    "address": "0x162bb2bb5fb03976a69dd25bb9afce6140db1433",
    "decimals": 9,
    "image": "https://rango.vip/i/avd8sT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007018,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DOG",
    "name": "Dogeswap Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0x099626783842d35c221e5d01694c2b928eb3b0ad",
    "decimals": 18,
    "image": "https://rango.vip/i/NQjfeC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0009452751625500517,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DOG",
    "name": "Dog Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0xb3863e02d6930762933f672ca134c1ccecd0d413",
    "decimals": 18,
    "image": "https://rango.vip/i/ZT9vY6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 8.201817570797569e-10,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZACK",
    "name": "ZACK MORRIS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8vCAUbxejdtaxn6jnX5uaQTyTZLmXALg9u1bvFCAjtx7",
    "decimals": 6,
    "image": "https://rango.vip/i/xDMA92",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00826007,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROUTE",
    "name": "Route",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4",
    "decimals": 18,
    "image": "https://rango.vip/i/P7k2tx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.56,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ROUTE",
    "name": "Route",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x5298060a95205be6dd4abc21910a4bb23d6dcd8b",
    "decimals": 18,
    "image": "https://rango.vip/i/g7gUIC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.07318140315652212,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROUTE",
    "name": "Route",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60f67e1015b3f069dd4358a78c38f83fe3a667a9",
    "decimals": 18,
    "image": "https://rango.vip/i/5euUEm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04644171,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PANDO",
    "name": "Pando",
    "isPopular": false,
    "chainId": "1",
    "address": "0x252b9f56359901a0bde52d0675b1f1130d86f471",
    "decimals": 18,
    "image": "https://rango.vip/i/UvW2PH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027583,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CKP",
    "name": "Cakepie",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2b5d9adea07b590b638ffc165792b2c610eda649",
    "decimals": 18,
    "image": "https://rango.vip/i/GutzPU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.9,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VISTADOG",
    "name": "VISTADOG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07e98f367aade7f81ddc90189d5d045c78e611d5",
    "decimals": 18,
    "image": "https://rango.vip/i/RM4gQm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00480724,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RCH",
    "name": "RCH Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x57b96d4af698605563a4653d882635da59bf11af",
    "decimals": 18,
    "image": "https://rango.vip/i/nb1nG6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.443545,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TSUJI",
    "name": "Tsutsuji Doge s Sister",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e6a60492fb5b58f5b5d08c7cafc75e740e6dc8e",
    "decimals": 9,
    "image": "https://rango.vip/i/vQ84KW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014387,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "USDM",
    "name": "Mountain Protocol USDM",
    "isPopular": false,
    "chainId": "10",
    "address": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
    "decimals": 18,
    "image": "https://rango.vip/i/uYhHKB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.998512,
    "supportedSwappers": [
      "OpenOceanOptimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDM",
    "name": "Mountain Protocol USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
    "decimals": 18,
    "image": "https://rango.vip/i/mxfygZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.998512,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDM",
    "name": "USD Mapped Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbbaec992fc2d637151daf40451f160bf85f3c8c1",
    "decimals": 6,
    "image": "https://rango.vip/i/iBCdpJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHEEKS",
    "name": "CHEEKS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6e6rViDzavLRv56nvZye5UofrKDg36mf6dTQrMCoPTW9",
    "decimals": 6,
    "image": "https://rango.vip/i/i8LuvX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00571423,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TYBG",
    "name": "Base God",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x0d97f261b1e88845184f678e2d1e7a98d9fd38de",
    "decimals": 18,
    "image": "https://rango.vip/i/eBC2IX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00002897,
    "supportedSwappers": [
      "SushiBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "ATOM",
    "name": "Cosmos",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x27292cf0016e5df1d8b37306b2a98588acbd6fca",
    "decimals": 6,
    "image": "https://rango.vip/i/icKPsP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 52.1434281247181,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "ATOM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/27394fb092d2eccd56123c74f36e4c1f926001ceada9ca97ea622b25f41e5eb2",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/ATOM.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 3.83,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ATOM",
    "name": "ATOM Coin",
    "isPopular": false,
    "chainId": "25",
    "address": "0xb888d8dd1733d72681b30c00ee76bde93ae7aa93",
    "decimals": 6,
    "image": "https://rango.vip/i/eYyBBj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 3.905343617463872,
    "supportedSwappers": [
      "CronaSwap",
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ATOM",
    "name": "Cosmos Token",
    "isPopular": true,
    "chainId": "56",
    "address": "0x0eb3a705fc54725037cc9e008bdede697f62f335",
    "decimals": 18,
    "image": "https://rango.vip/i/bvze4U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.83,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "ATOM",
    "name": "ATOM",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "ibc/c4cff46fd6de35ca4cf4ce031e643c8fdc9ba4b99ae598e9b0ed98fe3a2319f9",
    "decimals": 6,
    "image": "https://rango.vip/i/fSVgDB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 3.8398564362785024,
    "supportedSwappers": [
      "WYNDDex",
      "IBC"
    ]
  },
  {
    "blockchain": "COSMOS",
    "symbol": "ATOM",
    "name": null,
    "isPopular": true,
    "chainId": "cosmoshub-4",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/TGPAp1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/COSMOS/icon.svg",
    "usdPrice": 3.8436154313464654,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "TERRA",
    "symbol": "ATOM",
    "name": null,
    "isPopular": false,
    "chainId": "phoenix-1",
    "address": "ibc/27394fb092d2eccd56123c74f36e4c1f926001ceada9ca97ea622b25f41e5eb2",
    "decimals": 6,
    "image": "https://rango.vip/i/TGPAp1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TERRA/icon.svg",
    "usdPrice": 3.8436154313464654,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOG",
    "name": "FOGnet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x503cd987998824192578d0d7950148445667287c",
    "decimals": 18,
    "image": "https://rango.vip/i/jU3IHx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.081034,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LHINU",
    "name": "Love Hate Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcedefe438860d2789da6419b3a19cece2a41038d",
    "decimals": 18,
    "image": "https://rango.vip/i/3kcspw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000131,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "IRL",
    "name": "Rebase",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa80221d067603e30060f75e2458aa361f8ee5402",
    "decimals": 18,
    "image": "https://rango.vip/i/2Rkt8C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.010877595557626057,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "OVN",
    "name": "OVN",
    "isPopular": false,
    "chainId": "56",
    "address": "0x259b30c916e440fb79747cd559207ffdabbae057",
    "decimals": 18,
    "image": "https://rango.vip/i/Aj9aVN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 43.43,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COLON",
    "name": "Colon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd09eb9099fac55edcbf4965e0a866779ca365a0c",
    "decimals": 9,
    "image": "https://rango.vip/i/hvG3X0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00238777,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "UNA",
    "name": "Unagi Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x24569d33653c404f90af10a2b98d6e0030d3d267",
    "decimals": 18,
    "image": "https://rango.vip/i/FShVZS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00796666,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNA",
    "name": "Unagi Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b6f3ea2814f3fff804ba5d5c237aebbc364fba9",
    "decimals": 18,
    "image": "https://rango.vip/i/5YukAT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00796666,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZEDXION",
    "name": "Zedxion",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbafdabadcf19d0cfbbe0ab9c69cf050d86ff888c",
    "decimals": 18,
    "image": "https://rango.vip/i/M2Bcmk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.240762,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CAW",
    "name": "crow with knife (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9niFQK8MsParjSxhYQ3Ys2a6zHmsEuKSB1M7wwdvZ7bj",
    "decimals": 0,
    "image": "https://rango.vip/i/80ipof",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.6482e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAW",
    "name": "A Hunters Dream",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3b9569f82b18aef890de263b84189bd33ebe452",
    "decimals": 18,
    "image": "https://rango.vip/i/QVVF5h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.3595e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MGG",
    "name": "MetaGaming Guild",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7237c0b30b1355f1b76355582f182f6f04b08740",
    "decimals": 18,
    "image": "https://rango.vip/i/govyyz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00103463,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAJ",
    "name": "Cajutel",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c6a7ab47b5f058be0e7c7fe1a4b7925b8aca40e",
    "decimals": 18,
    "image": "https://rango.vip/i/fswUOR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.613334,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLASTUP",
    "name": "BlastUP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x901a020915bc3577d85d29f68024b4c5e240b8cd",
    "decimals": 18,
    "image": "https://rango.vip/i/lLeTXl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03913304,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "BLASTUP",
    "name": "BlastUP",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x59c159e5a4f4d1c86f7abdc94b7907b7473477f6",
    "decimals": 18,
    "image": "https://rango.vip/i/lLeTXl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.03913304,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PBX",
    "name": "Paribus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd528cf2e081f72908e086f8800977df826b5a483",
    "decimals": 18,
    "image": "https://rango.vip/i/QzvSZ9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00062788,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCEAN",
    "name": "Ocean Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x967da4048cd07ab37855c090aaf366e4ce1b9f48",
    "decimals": 18,
    "image": "https://rango.vip/i/U8OSL6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.457784,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OCEAN",
    "name": "Ocean Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x933d31561e470478079feb9a6dd2691fad8234df",
    "decimals": 18,
    "image": "https://rango.vip/i/zD3VBK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.4603852374672346,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "OCEAN",
    "name": "Ocean Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x2561aa2bb1d2eb6629edd7b0938d7679b8b49f9e",
    "decimals": 18,
    "image": "https://rango.vip/i/YIh9nX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.457784,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FAKT",
    "name": "MEDIFAKT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0262e9374e95b9667b78136c3897cb4e4ef7f0c2",
    "decimals": 18,
    "image": "https://rango.vip/i/wbmmis",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00089693,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QANX",
    "name": "QANplatform",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaaa9214f675316182eaa21c85f0ca99160cc3aaa",
    "decimals": 18,
    "image": "https://rango.vip/i/HpLRiG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02944221,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEKKO",
    "name": "GEKKO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf017d3690346eb8234b85f74cee5e15821fee1f4",
    "decimals": 18,
    "image": "https://rango.vip/i/7STnLP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000181,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUPPIES",
    "name": "I love puppies",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcf91b70017eabde82c9671e30e5502d312ea6eb2",
    "decimals": 9,
    "image": "https://rango.vip/i/4bbuyJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.4092e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DOGINME",
    "name": "doginme",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6921b130d297cc43754afba22e5eac0fbf8db75b",
    "decimals": 18,
    "image": "https://rango.vip/i/8gltOB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00007658,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRIAS",
    "name": "TriasLab",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a856d4effa670c54585a5d523e96513e148e95d",
    "decimals": 18,
    "image": "https://rango.vip/i/NI5fuB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.4,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TRIAS",
    "name": "Trias Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa4838122c683f732289805fc3c207febd55babdd",
    "decimals": 18,
    "image": "https://rango.vip/i/rb2NFi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 7.99,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "TRIAS",
    "name": "TRIAS",
    "isPopular": false,
    "chainId": "128",
    "address": "0xa4838122c683f732289805fc3c207febd55babdd",
    "decimals": 18,
    "image": "https://rango.vip/i/j45oHt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 3.862269087644882,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAVM",
    "name": "SatoshiVM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15e6e0d4ebeac120f9a97e71faa6a0235b85ed12",
    "decimals": 18,
    "image": "https://rango.vip/i/nk0Vag",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.935792,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "lisUSD",
    "name": "Lista USD",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5",
    "decimals": 18,
    "image": "https://rango.vip/i/yPVKjB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.998567,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEKEC",
    "name": "The Balkan Dwarf",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c7ac134ed985367eadc6f727d79e8295e11435c",
    "decimals": 18,
    "image": "https://rango.vip/i/r2iN96",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004820173317811424,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NSTR",
    "name": "Nostra",
    "isPopular": false,
    "chainId": "1",
    "address": "0x610dbd98a28ebba525e9926b6aaf88f9159edbfd",
    "decimals": 18,
    "image": "https://rango.vip/i/2KeP18",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.061104,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "NSTR",
    "name": "Nostra",
    "isPopular": false,
    "chainId": "SN_MAIN",
    "address": "0xc530f2c0aa4c16a0806365b0898499fba372e5df7a7172dc6fe9ba777e8007",
    "decimals": 18,
    "image": "https://rango.vip/i/XLl1jr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 0.061104,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PARAM",
    "name": "Param",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69a1e699f562d7af66fc6cc473d99f4430c3acd2",
    "decimals": 18,
    "image": "https://rango.vip/i/pdwbiw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00821561,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "VELA",
    "name": "Vela Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x088cd8f5ef3652623c22d48b1605dcfe860cd704",
    "decimals": 18,
    "image": "https://rango.vip/i/LdeXSj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.147061,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWITCH",
    "name": "Switch",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb10cc888cb2cce7036f4c7ecad8a57da16161338",
    "decimals": 8,
    "image": "https://rango.vip/i/uNHk4z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00191165,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIT",
    "name": "BitDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5",
    "decimals": 18,
    "image": "https://rango.vip/i/lRdkcQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.720503,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BIT",
    "name": "BitDAO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x406c8db506653d882295875f633bec0beb921c2a",
    "decimals": 18,
    "image": "https://rango.vip/i/RcmSQm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.7275999308010791,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZAM",
    "name": "Zam io",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd373576a9e738f37dc6882328358ff69c4caf4c6",
    "decimals": 18,
    "image": "https://rango.vip/i/IwuVT7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0002385,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WTK",
    "name": "WadzPay",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4cff49d0a19ed6ff845a9122fa912abcfb1f68a6",
    "decimals": 18,
    "image": "https://rango.vip/i/BCnim8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01580782,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GYD",
    "name": "Gyro Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe07f9d810a48ab5c3c914ba3ca53af14e4491e8a",
    "decimals": 18,
    "image": "https://rango.vip/i/SRtLvS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999665,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KHAI",
    "name": "Kitten Haimer",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3TWgDvYBL2YPET2LxnWAwsMeoA8aL4DutNuwat2pKCjC",
    "decimals": 8,
    "image": "https://rango.vip/i/rTD5Jk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.255176,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TUBES",
    "name": "Tubes",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf8c4a95c92b0d0121d1d20f4575073b37883d663",
    "decimals": 18,
    "image": "https://rango.vip/i/Pr8sA5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.499826,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEUSD",
    "name": "Elixir deUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15700b564ca08d9439c58ca5053166e8317aa138",
    "decimals": 18,
    "image": "https://rango.vip/i/2Upx2n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGETH",
    "name": "Kelp Gain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1b4d34e8754600962cd944b535180bd758e6c2e",
    "decimals": 18,
    "image": "https://rango.vip/i/mSYfeN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2403.21,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "SOMM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/9bba9a1c257e971e38c1422780ce6f0b0686f0a3085e2d61118d904bfe0f5f5e",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/SOMM.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.021984841189690883,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GGMT",
    "name": "GG MetaGame",
    "isPopular": false,
    "chainId": "1",
    "address": "0x76aab5fd2243d99eac92d4d9ebf23525d3ace4ec",
    "decimals": 18,
    "image": "https://rango.vip/i/wYro8U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00943656,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IVEX",
    "name": "IVEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x663962c0d2b624776d6fd1bf6ba41236761e76a9",
    "decimals": 21,
    "image": "https://rango.vip/i/d84tMU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.408218,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHS",
    "name": "ETH Stable",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa9ad6830180f9c150349f2cecadd710586e35cb7",
    "decimals": 18,
    "image": "https://rango.vip/i/pI2u2l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.933534121389784,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "X2Y2",
    "name": "X2Y2Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e4ede388cbc9f4b5c79681b7f94d36a11abebc9",
    "decimals": 18,
    "image": "https://rango.vip/i/JJ6Yfl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0043043,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RGOAT",
    "name": "RealGOAT",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xf0268c5f9aa95baf5c25d646aabb900ac12f0800",
    "decimals": 8,
    "image": "https://rango.vip/i/ak6p4w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000199,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SKOP",
    "name": "Skull of Pepe Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6d3b8c76c5396642960243febf736c6be8b60562",
    "decimals": 18,
    "image": "https://rango.vip/i/o8sg2L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.03203824,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GAU",
    "name": "GAU Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xca8ebfb8e1460aaac7c272cb9053b3d42412aac2",
    "decimals": 18,
    "image": "https://rango.vip/i/a8bD5a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.073896,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SEAM",
    "name": "Seamless Protocol",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x1c7a460413dd4e964f96d8dfc56e7223ce88cd85",
    "decimals": 18,
    "image": "https://rango.vip/i/597Gnb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.28,
    "supportedSwappers": [
      "SushiBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SEAM",
    "name": "Seamless Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b66ccd1340c479b07b390d326eadcbb84e726ba",
    "decimals": 18,
    "image": "https://rango.vip/i/597Gnb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.28,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JUM",
    "name": "Jum Cromer",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "43eeGP8k9AZKBSyd9iahhThNtE3vcZvBWMaHNd2akM5s",
    "decimals": 6,
    "image": "https://rango.vip/i/iFJlMd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00619823010395072,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DZOO",
    "name": "DegenZoo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc97d6c52f3add91fa1c5287a453d7444aecbca83",
    "decimals": 18,
    "image": "https://rango.vip/i/P6z5HA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00465417,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLIP",
    "name": "FLIP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x826180541412d574cf1336d22c0c0a287822678a",
    "decimals": 18,
    "image": "https://rango.vip/i/P6ZBvI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.086,
    "supportedSwappers": [
      "Chainflip",
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MNDE",
    "name": "Marinade",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey",
    "decimals": 9,
    "image": "https://rango.vip/i/l4z1Rs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.089697,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CANTO",
    "name": "Canto",
    "isPopular": false,
    "chainId": "1",
    "address": "0x56c03b8c4fa80ba37f5a7b60caaaef749bb5b220",
    "decimals": 18,
    "image": "https://rango.vip/i/MkZLMr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01793121,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LIF3",
    "name": "Lif3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7138eb0d563f3f6722500936a11dcae99d738a2c",
    "decimals": 18,
    "image": "https://rango.vip/i/QC5Kaz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00307892,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "NLS",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/d9afcecdd361d38302aa66eb3bac23b95234832c51d12489dc451fa2b7c72782",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/NLS.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.01492719,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NLS",
    "name": "Nolus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb34e17562e4f1f63a2d4cf684ed8bc124e519771",
    "decimals": 6,
    "image": "https://rango.vip/i/JBLrxP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01492719,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "SUNMAGA",
    "name": "SUNMAGA",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TWsdQJBvSmTCNKmp7pJ3XCZBfmiNMtdSGb",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.0003342532869472293,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOCK",
    "name": "Meridian Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95172ccbe8344fecd73d0a30f54123652981bd6f",
    "decimals": 18,
    "image": "https://rango.vip/i/WxERrJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.28028023908322447,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOCK",
    "name": "Houdini Swap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x922d8563631b03c2c4cf817f4d18f6883aba0109",
    "decimals": 18,
    "image": "https://rango.vip/i/XP8W5Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.279634,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZYGO",
    "name": "Zygo The Frog",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee2b9b7e168b5b2d40c507b891c7cfb13a6aaf2b",
    "decimals": 18,
    "image": "https://rango.vip/i/04w5KM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00028547,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOON",
    "name": "SoonVerse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x574d22e2555cac0ce71e44778f6de2e7487ae229",
    "decimals": 18,
    "image": "https://rango.vip/i/JMfgEf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00490036,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "VDA",
    "name": "Verida",
    "isPopular": false,
    "chainId": "137",
    "address": "0x683565196c3eab450003c964d4bad1fd3068d4cc",
    "decimals": 18,
    "image": "https://rango.vip/i/OVQJMz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01717365,
    "supportedSwappers": [
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMO",
    "name": "Amino",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a2eb478fa07125c9935a77b3c03a82470801e30",
    "decimals": 18,
    "image": "https://rango.vip/i/ZMBOp4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012351,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMIKAMI",
    "name": "AMATERASU OMIKAMI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e18d5bab2fa94a6a95f509ecb38f8f68322abd3",
    "decimals": 9,
    "image": "https://rango.vip/i/j5PoEv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03627095,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TRUNK",
    "name": "Elephant Money Stable (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9mV4WUukVsva5wYcYW4veo34CNDiF44sh3Ji65JNdvh5",
    "decimals": 8,
    "image": "https://rango.vip/i/MxLqKn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.320122,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ECOTERRA",
    "name": "Ecoterra",
    "isPopular": false,
    "chainId": "1",
    "address": "0x982b50e55394641ca975a0eec630b120b671391a",
    "decimals": 9,
    "image": "https://rango.vip/i/Q88scT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00051681,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BMAX",
    "name": "BMAX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x116c4b65e14449947bc6fa1bbe844cb16a162d53",
    "decimals": 18,
    "image": "https://rango.vip/i/RnSdHo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.107203,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEAS",
    "name": "Peapods Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x02f92800f57bcd74066f5709f1daa1a4302df875",
    "decimals": 18,
    "image": "https://rango.vip/i/vpviNJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.02,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BORK",
    "name": "Bork",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4jZXkSNgTQKCDb36ECZ6a2aNzcUniGcDeXgTdtM2HxAX",
    "decimals": 9,
    "image": "https://rango.vip/i/Zf7b1g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00019712,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNLEASH",
    "name": "UnleashClub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e9cc0f7e550bd43bd2af2214563c47699f96479",
    "decimals": 18,
    "image": "https://rango.vip/i/2BsMbO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004619,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SYK",
    "name": "Stryke",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xacc51ffdef63fb0c014c882267c3a17261a5ed50",
    "decimals": 18,
    "image": "https://rango.vip/i/dwcQrZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.089579,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DRAGGY",
    "name": "Draggy CTO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd12a99dbc40036cec6f1b776dccd2d36f5953b94",
    "decimals": 9,
    "image": "https://rango.vip/i/z4Tl6W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.79e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ECHO",
    "name": "Echo Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2b46578b7f06f2b373ad0e0c9b28f800dcc80bf3",
    "decimals": 9,
    "image": "https://rango.vip/i/FN4KXp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003661,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SBAE",
    "name": "Salt Bae For The People",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BWWWurbodjGbovFetc3FC6oSbqkdoE62E1XqZ7X4pump",
    "decimals": 6,
    "image": "https://rango.vip/i/AqHvlc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0010889,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGWIFHAT",
    "name": "dogwifhat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8aec4bbdcfb451aa289bfbd3c2f4e34a44ada1be",
    "decimals": 9,
    "image": "https://rango.vip/i/dNECKI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00640158,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUDO",
    "name": "Sudo Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3446dd70b2d52a6bf4a5a192d9b0a161295ab7f9",
    "decimals": 18,
    "image": "https://rango.vip/i/x2aB5M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.090546,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNSY",
    "name": "Sensay",
    "isPopular": false,
    "chainId": "1",
    "address": "0x82a605d6d9114f4ad6d5ee461027477eeed31e34",
    "decimals": 18,
    "image": "https://rango.vip/i/MABhsf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00088211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JCC",
    "name": "JC Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03a9d7c8caf836de35666c5f7e317306b54fdd4e",
    "decimals": 18,
    "image": "https://rango.vip/i/dos0wF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000126,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "GOCHU",
    "name": "Gochujangcoin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9aaae745cf2830fb8ddc6248b17436dc3a5e701c",
    "decimals": 18,
    "image": "https://rango.vip/i/bny5G6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000123,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VITA",
    "name": "VitaDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x81f8f0bb1cb2a06649e51913a151f0e7ef6fa321",
    "decimals": 18,
    "image": "https://rango.vip/i/I96MFT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.04,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "VITA",
    "name": "VitaDAO Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x7d14206c937e70e19e3a5b94011faf0d5b3928e2",
    "decimals": 18,
    "image": "https://rango.vip/i/HeK24y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.0413337377423808,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FREL",
    "name": "Freela",
    "isPopular": false,
    "chainId": "1",
    "address": "0x29ceddcf0da3c1d8068a7dfbd0fb06c2e438ff70",
    "decimals": 18,
    "image": "https://rango.vip/i/8EWCvO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018016,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCOTTY",
    "name": "ScottyTheAi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0db17bc219c5ca8746c29ee47862ee3ad742f4a",
    "decimals": 18,
    "image": "https://rango.vip/i/Y65P3j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00161562,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOBAOPPA",
    "name": "Boba Oppa",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "bobaM3u8QmqZhY1HwAtnvze9DLXvkgKYk3td3t8MLva",
    "decimals": 6,
    "image": "https://rango.vip/i/PlVAmY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00079931,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NIOX",
    "name": "Autonio",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc813ea5e3b48bebeedb796ab42a30c5599b01740",
    "decimals": 4,
    "image": "https://rango.vip/i/r7M6yQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00119214,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NIOX",
    "name": "Autonio Foundation",
    "isPopular": false,
    "chainId": "137",
    "address": "0xad684e79ce4b6d464f2ff7c3fd51646892e24b96",
    "decimals": 4,
    "image": "https://rango.vip/i/VqBqF3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00119214,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "OKT",
    "name": "",
    "isPopular": true,
    "chainId": "66",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/fs3h4q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 6.228474908279536,
    "supportedSwappers": [
      "CherrySwap",
      "OkcSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XNK",
    "name": "Kinka",
    "isPopular": false,
    "chainId": "1",
    "address": "0x722a89f1b925fe41883978219c2176aecc7d6699",
    "decimals": 18,
    "image": "https://rango.vip/i/0bv01Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2519.3,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AST",
    "name": "AirSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x27054b13b1b798b345b591a4d22e6562d47ea75a",
    "decimals": 4,
    "image": "https://rango.vip/i/TI85tt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.077357,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AURA",
    "name": "Aura",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0c293ce456ff0ed870add98a0828dd4d2903dbf",
    "decimals": 18,
    "image": "https://rango.vip/i/XzCDhj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.464397,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "AURA",
    "name": "Aura",
    "isPopular": false,
    "chainId": "10",
    "address": "0x1509706a6c66ca549ff0cb464de88231ddbe213b",
    "decimals": 18,
    "image": "https://rango.vip/i/FwE886",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.46519108236004253,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EPIK",
    "name": "TEH EPIK DUCK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3BgwJ8b7b9hHX4sgfZ2KJhv9496CoVfsMK2YePevsBRw",
    "decimals": 6,
    "image": "https://rango.vip/i/SILy2o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00789406,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EPIK",
    "name": "Epik Prime",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4da0c48376c277cdbd7fc6fdc6936dee3e4adf75",
    "decimals": 18,
    "image": "https://rango.vip/i/HVc9YI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0039429,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HIP",
    "name": "HIPPOP",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa0995d43901551601060447f9abf93ebc277cec2",
    "decimals": 18,
    "image": "https://rango.vip/i/JTFpZ3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00023826,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZEND",
    "name": "zkLend",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2606492712d311be8f41d940afe8ce742a52d44",
    "decimals": 18,
    "image": "https://rango.vip/i/GKVhNy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.169894,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "ZEND",
    "name": "zkLend Token",
    "isPopular": false,
    "chainId": "SN_MAIN",
    "address": "0x585c32b625999e6e5e78645ff8df7a9001cf5cf3eb6b80ccdd16cb64bd3a34",
    "decimals": 18,
    "image": "https://rango.vip/i/36Fid4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 0.16732771654177025,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMT",
    "name": "Emanate",
    "isPopular": false,
    "chainId": "1",
    "address": "0x893700a1a86ee68b92536bf6fd4d3200d7369f7d",
    "decimals": 18,
    "image": "https://rango.vip/i/H8K8UB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.019984163019607204,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KERN",
    "name": "Kernel",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7fc610f6595b3aa6e24466b5ca166d10a0fbdcb",
    "decimals": 18,
    "image": "https://rango.vip/i/q32PC0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.44,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORC",
    "name": "Orbit Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x662b67d00a13faf93254714dd601f5ed49ef2f51",
    "decimals": 18,
    "image": "https://rango.vip/i/KBUtrK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0042939,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SGB",
    "name": "SGB",
    "isPopular": false,
    "chainId": "128",
    "address": "0x7f8ab1593f84b0c450385a3f70e921cb796401ba",
    "decimals": 6,
    "image": "https://rango.vip/i/PmjfXe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.004448158178250295,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "A4M",
    "name": "AlienForm",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf92d62ed69242d655e685c96b98f32f1409c3262",
    "decimals": 9,
    "image": "https://rango.vip/i/iU3TWY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003968,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RENQ",
    "name": "Renq Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff8c479134a18918059493243943150776cf8cf2",
    "decimals": 18,
    "image": "https://rango.vip/i/cvkZkb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00735128,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FTRB",
    "name": "Faith Tribe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2596825a84888e8f24b747df29e11b5dd03c81d7",
    "decimals": 18,
    "image": "https://rango.vip/i/8j0bUK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00380169,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CORGIAI",
    "name": "CorgiAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b431b8a964bfcf28191b07c91189ff4403957d0",
    "decimals": 18,
    "image": "https://rango.vip/i/X3KvD6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00057398,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CROWN",
    "name": "CROWN Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GDfnEsia2WLAW5t8yx2X5j2mkfA74i5kwGdDuZHt7XmG",
    "decimals": 9,
    "image": "https://rango.vip/i/YA1yiN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.245721,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CROWN",
    "name": "Crown Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3bb9f16677f2b86efd1dfca1c141a99783fde58",
    "decimals": 18,
    "image": "https://rango.vip/i/Rfd2iC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.042,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FDM",
    "name": "Freedom",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60d91f6d394c5004a782e0d175e2b839e078fb83",
    "decimals": 18,
    "image": "https://rango.vip/i/s7PRZL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02181221,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FI",
    "name": "Fideum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1294f4183763743c7c9519bec51773fb3acd78fd",
    "decimals": 18,
    "image": "https://rango.vip/i/f7Wteo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00313222,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRL",
    "name": "Greelance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa067237f8016d5e3770cf08b20e343ab9ee813d5",
    "decimals": 9,
    "image": "https://rango.vip/i/pxGFd9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0010871874703690318,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QTO",
    "name": "Qtoken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea01906843ea8d910658a2c485ffce7c104ab2b6",
    "decimals": 18,
    "image": "https://rango.vip/i/8E08gH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.118542,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORBK",
    "name": "Ordibank",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce6e54daa1ea95fb3530859d69d4bdb978dd821b",
    "decimals": 18,
    "image": "https://rango.vip/i/e77rIZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00419226,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MELOS",
    "name": "Melos Studio",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1afb69dbc9f54d08dab1bd3436f8da1af819e647",
    "decimals": 18,
    "image": "https://rango.vip/i/4qcdZg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00143012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNIETH",
    "name": "Universal ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1376bcef0f78459c0ed0ba5ddce976f1ddf51f4",
    "decimals": 18,
    "image": "https://rango.vip/i/iwjK6i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2503.98,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "IRON",
    "name": "IRON Stablecoin",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd86b5923f3ad7b585ed81b448170ae026c65ae9a",
    "decimals": 18,
    "image": "https://rango.vip/i/cF6zg2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00017742,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAP",
    "name": "Kapital DAO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9625ce7753ace1fa1865a47aae2c5c2ce4418569",
    "decimals": 18,
    "image": "https://rango.vip/i/Ijz3FO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01608238,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "KAP",
    "name": "KAP Games",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x15d24de366f69b835be19f7cf9447e770315dd80",
    "decimals": 18,
    "image": "https://rango.vip/i/Cpy3dN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.01608238,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHILL",
    "name": "LumiChill",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BzpqoPc44oaHu9SB5hek1GRNjzc4UwFcL4oWusWozm3N",
    "decimals": 3,
    "image": "https://rango.vip/i/e5h3Kw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.4579e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HBB",
    "name": "Hubble Protocol Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6",
    "decimals": 6,
    "image": "https://rango.vip/i/yMt4on",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.079871,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FLUXB",
    "name": "FluxBot",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FLUXBmPhT3Fd1EDVFdg46YREqHBeNypn1h4EbnTzWERX",
    "decimals": 5,
    "image": "https://rango.vip/i/TfotXr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02965904,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOWELI",
    "name": "Towelie",
    "isPopular": false,
    "chainId": "1",
    "address": "0x420698cfdeddea6bc78d59bc17798113ad278f9d",
    "decimals": 18,
    "image": "https://rango.vip/i/CqNJCO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033633,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KARATE",
    "name": "Karate Combat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80008bcd713c38af90a9930288d446bc3bd2e684",
    "decimals": 18,
    "image": "https://rango.vip/i/vWDX9h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00049014,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TAKI",
    "name": "Taki",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Taki7fi3Zicv7Du1xNAWLaf6mRK7ikdn77HeGzgwvo4",
    "decimals": 9,
    "image": "https://rango.vip/i/QctKl6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01011817,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TAKI",
    "name": "TakiToken",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe78aee6ccb05471a69677fb74da80f5d251c042b",
    "decimals": 18,
    "image": "https://rango.vip/i/oBGr7x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01011817,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VPAD",
    "name": "VLaunch",
    "isPopular": false,
    "chainId": "1",
    "address": "0x51fe2e572e97bfeb1d719809d743ec2675924edc",
    "decimals": 18,
    "image": "https://rango.vip/i/COoLrO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.009934859893203104,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IXS",
    "name": "Ixs Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73d7c860998ca3c01ce8c808f5577d94d545d1b4",
    "decimals": 18,
    "image": "https://rango.vip/i/2bwzVt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.374638,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ECOREAL",
    "name": "ECOREAL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ecbb21346c501fd07eb165e406120fa32381c16",
    "decimals": 18,
    "image": "https://rango.vip/i/l0bY9k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.166559,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOM",
    "name": "TOM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf7970499814654cd13cb7b6e7634a12a7a8a9abc",
    "decimals": 18,
    "image": "https://rango.vip/i/sExDub",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.920029,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOM",
    "name": "Big Tom",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeeecd285f60e802ecb6d8d8d37790c887f9a4b33",
    "decimals": 9,
    "image": "https://rango.vip/i/wHWQ3L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.27426e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FLAME",
    "name": "FireStarter",
    "isPopular": false,
    "chainId": "137",
    "address": "0x22e3f02f86bc8ea0d73718a2ae8851854e62adc5",
    "decimals": 18,
    "image": "https://rango.vip/i/1m848J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02414252,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RFRM",
    "name": "Reform DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea3eed8616877f5d3c4aebf5a799f2e8d6de9a5e",
    "decimals": 18,
    "image": "https://rango.vip/i/i7h7La",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.241988,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HPO",
    "name": "Hippopotamus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0ed3c520dc0632657ad2eaaf19e26c4fd431a84",
    "decimals": 18,
    "image": "https://rango.vip/i/M1E8z7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00409522,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HPO",
    "name": "Hippocrat",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe39c384d702914127a005523f9915addb9bd59b",
    "decimals": 18,
    "image": "https://rango.vip/i/R3Fcdz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04928805,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEOPLE",
    "name": "PEOPLE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a58c0be72be218b41c608b7fe7c5bb630736c71",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.059037,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PEOPLE",
    "name": "ConstitutionDAO",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2c44b726adf1963ca47af88b284c06f30380fc78",
    "decimals": 18,
    "image": "https://rango.vip/i/TE22Ds",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.05952966468123349,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PEOPLE",
    "name": "ConstitutionDAO (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA",
    "decimals": 8,
    "image": "https://rango.vip/i/KyYU9K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.060892,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FRIEND",
    "name": "Friend tech",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x0bd4887f7d41b35cd75dff9ffee2856106f86670",
    "decimals": 18,
    "image": "https://rango.vip/i/i9Idcr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.098041,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UGOLD",
    "name": "UGOLD Inc ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa6089dbfed19d1bcd43146bbdca2b8f9d9f84a9a",
    "decimals": 18,
    "image": "https://rango.vip/i/m2Dk1F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2614.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COST",
    "name": "Costco Hot Dog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Av6qVigkb7USQyPXJkUvAEm4f599WTRvd75PUWBA9eNm",
    "decimals": 9,
    "image": "https://rango.vip/i/XVO5QL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00328113,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHINU",
    "name": "Chinu",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FLrgwxXaX8q8ECF18weDf3PLAYorXST5orpY34d8jfbm",
    "decimals": 9,
    "image": "https://rango.vip/i/G2fbbU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0001701,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "OMI",
    "name": "ECOMI",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x3792dbdd07e87413247df995e692806aa13d3299",
    "decimals": 18,
    "image": "https://rango.vip/i/Wnodnt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0003887,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMI",
    "name": "ECOMI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed35af169af46a02ee13b9d79eb57d6d68c1749e",
    "decimals": 18,
    "image": "https://rango.vip/i/F0G5Nc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0003887,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZYN",
    "name": "ZynCoin (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd",
    "decimals": 8,
    "image": "https://rango.vip/i/O1bFC9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0202078,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZYN",
    "name": "ZynCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x58cb30368ceb2d194740b144eab4c2da8a917dcb",
    "decimals": 18,
    "image": "https://rango.vip/i/bwMXqm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0202078,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ZYN",
    "name": "Zynergy",
    "isPopular": false,
    "chainId": "324",
    "address": "0x0231b3de40b6b3bdd28dcef037f1b7a3fcf5a95a",
    "decimals": 18,
    "image": "https://rango.vip/i/MqJ1D5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00031299,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "EVO",
    "name": "EVO",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x42006ab57701251b580bdfc24778c43c9ff589a1",
    "decimals": 18,
    "image": "https://rango.vip/i/d6pTOz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00041424,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EVO",
    "name": "EVO",
    "isPopular": false,
    "chainId": "137",
    "address": "0x42006ab57701251b580bdfc24778c43c9ff589a1",
    "decimals": 18,
    "image": "https://rango.vip/i/tnVHwe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0005601655956047527,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "HIGHER",
    "name": "higher",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x0578d8a44db98b23bf096a382e016e29a5ce0ffe",
    "decimals": 18,
    "image": "https://rango.vip/i/wH7CKN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00310727,
    "supportedSwappers": [
      "OneInchBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIGHER",
    "name": "HIgher IMO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b802513d4aa6f349b197a4ea4c26563cd6fd5b2",
    "decimals": 18,
    "image": "https://rango.vip/i/Fx4269",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00133636,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HIGHER",
    "name": "AVAX GO HIGHER",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x7a842a6f4580edd3df41c1f31e0395044de6bc75",
    "decimals": 18,
    "image": "https://rango.vip/i/OHV2x1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.003096236871742791,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "XDAO",
    "name": "XDAO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x71eeba415a523f5c952cc2f06361d5443545ad28",
    "decimals": 18,
    "image": "https://rango.vip/i/6aGfBM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.288962,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XDAO",
    "name": "XDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x71eeba415a523f5c952cc2f06361d5443545ad28",
    "decimals": 18,
    "image": "https://rango.vip/i/6aGfBM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.288962,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XCM",
    "name": "Coinmetro",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36ac219f90f5a6a3c77f2a7b660e3cc701f68e25",
    "decimals": 18,
    "image": "https://rango.vip/i/lybAym",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03286264,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LAB",
    "name": "LAB",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2Pp6ebUvEL9YRTauUTmGTwYZKRfyQXGM9jE4S8WPDtEy",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00271291,
    "supportedSwappers": [
      "Jupiter",
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "4CHAN",
    "name": "4Chan",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0a458bf4acf353cb45e211281a334bb1d837885",
    "decimals": 9,
    "image": "https://rango.vip/i/g5POgK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.9697e-14,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCONTENT",
    "name": "MContent",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd3c51de3e6dd9b53d7f37699afb3ee3bf9b9b3f4",
    "decimals": 6,
    "image": "https://rango.vip/i/eIOKru",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.55979e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MCONTENT",
    "name": "MContent",
    "isPopular": false,
    "chainId": "56",
    "address": "0x799e1cf88a236e42b4a87c544a22a94ae95a6910",
    "decimals": 6,
    "image": "https://rango.vip/i/PGzGaX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 8.55979e-10,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BS",
    "name": "Blacksmith Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5eca7b975e34567d9460fa613013a7a6993ad185",
    "decimals": 18,
    "image": "https://rango.vip/i/dnpuJt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00067293,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "USTC",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/be1bb42d4be3c30d50b68d7c41db4dfce9678e8ef8c539f6e6a9345048894fcc",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USTC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.015431344132968958,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FACTR",
    "name": "Defactor",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0bceef36f3a6efdd5eebfacd591423f8549b9d5",
    "decimals": 18,
    "image": "https://rango.vip/i/WfwIgH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03338644,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WINR",
    "name": "WINR Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd77b108d4f6cefaa0cae9506a934e825becca46e",
    "decimals": 18,
    "image": "https://rango.vip/i/xP8oiF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.03345498,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WINR",
    "name": "WINR (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CsxCtA8usvWKdRhe7KhLU5GgwzYaLkoHsz1MKBVZ4W3M",
    "decimals": 8,
    "image": "https://rango.vip/i/e00gE9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03345498,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BSL",
    "name": "BankSocial",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0af55d5ff28a3269d69b98680fd034f115dd53ac",
    "decimals": 8,
    "image": "https://rango.vip/i/QBlqdl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00039415,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ITA",
    "name": "Italian Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GuVoE2qAS3DHaAGSeuZfBkbLjFXfP46DFbogbrVJNHfN",
    "decimals": 6,
    "image": "https://rango.vip/i/lAKm3x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001275,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "1EX",
    "name": "1ex",
    "isPopular": false,
    "chainId": "1",
    "address": "0x668d78571f124415581b38d32fa9a16f1aaa8417",
    "decimals": 18,
    "image": "https://rango.vip/i/ozZdqA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00415538,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PATEX",
    "name": "Patex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd4f2caf941b6d737382dce420b368de3fc7f2d4",
    "decimals": 18,
    "image": "https://rango.vip/i/pfD0ab",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.091,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GUAC",
    "name": "Guacamole",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR",
    "decimals": 5,
    "image": "https://rango.vip/i/vkoZv0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.4788e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGIX",
    "name": "SingularityNET Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5b7533812759b45c2b44c19e320ba2cd2681b542",
    "decimals": 8,
    "image": "https://rango.vip/i/nfFUUt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.468745,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CBY",
    "name": "Carbify",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb6a5ae40e79891e4deadad06c8a7ca47396df21c",
    "decimals": 18,
    "image": "https://rango.vip/i/ydsXtm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.922684,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CBY",
    "name": "Cloud Binary",
    "isPopular": false,
    "chainId": "1",
    "address": "0x109548dc14c0b8d7908a168202a4ab08bb449613",
    "decimals": 18,
    "image": "https://rango.vip/i/Kp3p0w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00192581,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CBY",
    "name": "Carbify",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9d27bc093ed0a3b7c18366266704cfe5e7af77b",
    "decimals": 18,
    "image": "https://rango.vip/i/jYk1Tq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.922684,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ENO",
    "name": "ENO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2b41806cbf1ffb3d9e31a9ece6b738bf9d6f645f",
    "decimals": 18,
    "image": "https://rango.vip/i/fY7BqF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.550844,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ENO",
    "name": "ENO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c3d163219bb74f430411b95d66b72056f366ec1",
    "decimals": 18,
    "image": "https://rango.vip/i/fY7BqF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.550844,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PCH",
    "name": "Peach",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1cad79759243934db8e36c605261f37f9b141bd",
    "decimals": 18,
    "image": "https://rango.vip/i/9C4NKK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00127373,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "LOOT",
    "name": "LOOT",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x7f041ce89a2079873693207653b24c15b5e6a293",
    "decimals": 18,
    "image": "https://rango.vip/i/Y7oJJP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.10551648131550159,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOOT",
    "name": "LOOT Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x721a1b990699ee9d90b6327faad0a3e840ae8335",
    "decimals": 18,
    "image": "https://rango.vip/i/m2PdSl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.015239,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOOT",
    "name": "Looted Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe4129c7b229812212f88d1bd6a223c45622e6b85",
    "decimals": 9,
    "image": "https://rango.vip/i/qKy1lX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.197116,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PONZIO",
    "name": "PonzioTheCat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x873259322be8e50d80a4b868d186cc5ab148543a",
    "decimals": 18,
    "image": "https://rango.vip/i/BLlzDC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 50827,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DITH",
    "name": "Dither",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E1kvzJNxShvvWTrudokpzuc789vRiDXfXG3duCuY6ooE",
    "decimals": 9,
    "image": "https://rango.vip/i/IsQHcS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.04990777,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "HEART",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/35cecc330d11dd00facb555d07687631e0bc7d226260cc5f015f6d7980819533",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00745332,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HEART",
    "name": "Humans ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8fac8031e079f409135766c7d5de29cf22ef897c",
    "decimals": 18,
    "image": "https://rango.vip/i/A1TyCr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00745332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZIK",
    "name": "Ziktalk",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88303fed02b31db9c7a9eafb711da9ef4a03e5d3",
    "decimals": 18,
    "image": "https://rango.vip/i/XSPeiy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00316662,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZIK",
    "name": "ZIK coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd4419c2d3daa986dc30444fa333a846be44fd1eb",
    "decimals": 18,
    "image": "https://rango.vip/i/MT3Skc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001024,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WEXO",
    "name": "Wexo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf31698ddad0d11160fe85c500397a470cd3d492e",
    "decimals": 18,
    "image": "https://rango.vip/i/AAKYNA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.45,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAZZE",
    "name": "MAZZE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4a029f7bcf33acb03547d8fa7be840347973e24e",
    "decimals": 18,
    "image": "https://rango.vip/i/GO5U3W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00322369,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUBF",
    "name": "Super Best Friends",
    "isPopular": false,
    "chainId": "1",
    "address": "0x564a80d0123bdd750fb6a9993834968fc595c09a",
    "decimals": 18,
    "image": "https://rango.vip/i/z7x4zS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.498e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VERA",
    "name": "Vera",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7f0cc50ad69408ae58be033f4f85d2367c2e468",
    "decimals": 18,
    "image": "https://rango.vip/i/CsuiZ7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00026491,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOLZ",
    "name": "DOLZ io",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe939f011a3d8fc0aa874c97e8156053a903d7176",
    "decimals": 18,
    "image": "https://rango.vip/i/d6Gu03",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00933522,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WALTER",
    "name": "walter",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FV56CmR7fhEyPkymKfmviKV48uPo51ti9kAxssQqTDLu",
    "decimals": 6,
    "image": "https://rango.vip/i/YnwJ90",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00133795,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APED",
    "name": "Aped",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0151763455a8a021e64880c238ba1cff3787ff0",
    "decimals": 18,
    "image": "https://rango.vip/i/0L6Oe8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.231285,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VELAR",
    "name": "Velar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x033bbde722ea3cdcec73cffea6581df9f9c257de",
    "decimals": 6,
    "image": "https://rango.vip/i/N5O3N7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.055569,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DOUG",
    "name": "Doug The Duck",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BavuJ8bntC79A8aHTxQi1EUhcCnqvEU8KSBE4sVCAaHc",
    "decimals": 6,
    "image": "https://rango.vip/i/KmX3Ic",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00137987,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOUG",
    "name": "Doug",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc9a1f104fbbda8b8752946f7d56d59d28284037f",
    "decimals": 18,
    "image": "https://rango.vip/i/bZZsVh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.09382e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEMS",
    "name": "The Nemesis",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb435a47ecea7f5366b2520e45b9bed7e01d2ffae",
    "decimals": 18,
    "image": "https://rango.vip/i/KdYXar",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.013214,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XX",
    "name": "XXCOIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x642ac912a58428767fa14a26a749f9a1b001ba92",
    "decimals": 9,
    "image": "https://rango.vip/i/Od6JBh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.265e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "GNUS",
    "name": "GENIUS AI",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x614577036f0a024dbc1c88ba616b394dd65d105a",
    "decimals": 18,
    "image": "https://rango.vip/i/fjjFKx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 3.29,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GNUS",
    "name": "Genius Token & NFT Collections",
    "isPopular": false,
    "chainId": "137",
    "address": "0x127e47aba094a9a87d084a3a93732909ff031419",
    "decimals": 18,
    "image": "https://rango.vip/i/nVUlUZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 3.29,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GNUS",
    "name": "Genius Token & NFT Collections",
    "isPopular": false,
    "chainId": "1",
    "address": "0x614577036f0a024dbc1c88ba616b394dd65d105a",
    "decimals": 18,
    "image": "https://rango.vip/i/fjjFKx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.29,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "APTR",
    "name": "Aperture Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1c986661170c1834db49c3830130d4038eeeb866",
    "decimals": 6,
    "image": "https://rango.vip/i/aYZdri",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00697072,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APTR",
    "name": "Aperture Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbeef01060047522408756e0000a90ce195a70000",
    "decimals": 6,
    "image": "https://rango.vip/i/aYZdri",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00697072,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FON",
    "name": "INOFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa4be4cdc552891a6702e1ae9645ef445179a4463",
    "decimals": 18,
    "image": "https://rango.vip/i/0pjTQ6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.37855,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAIL",
    "name": "Rail",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe76c6c83af64e4c60245d8c7de953df673a7a33d",
    "decimals": 18,
    "image": "https://rango.vip/i/FSKnxv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.693533,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STORE",
    "name": "Bit Store",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31ea0de8119307aa264bb4b38727aab4e36b074f",
    "decimals": 18,
    "image": "https://rango.vip/i/bWQ3Fn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00359742,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WUF",
    "name": "Wuffi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "73xsLcBnLnc9bh81cqVKqj8uEyiarXng5ZwJuTbnVebG",
    "decimals": 4,
    "image": "https://rango.vip/i/oRId2x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 5.40499e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GTA",
    "name": "GTA",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x73db621cc8f0a767bed0e5c0a2920f480d77b765",
    "decimals": 18,
    "image": "https://rango.vip/i/epLcoI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.8610039622516852,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "UNICORN",
    "name": "UNICORN Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe3e1fabeabd48491bd6902b0c32fdeee8d2ff12b",
    "decimals": 18,
    "image": "https://rango.vip/i/ABzJoT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00033028662796523076,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEB",
    "name": "AndusChain Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1e770be76bde604f8ebb66f640250a787b9422b",
    "decimals": 18,
    "image": "https://rango.vip/i/sYQoxR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00668759,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TKX",
    "name": "Tokenize Emblem",
    "isPopular": false,
    "chainId": "1",
    "address": "0x667102bd3413bfeaa3dffb48fa8288819e480a88",
    "decimals": 8,
    "image": "https://rango.vip/i/L4SSnO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.96,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DPX",
    "name": "Dopex Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeec2be5c91ae7f8a338e1e5f3b5de49d07afdc81",
    "decimals": 18,
    "image": "https://rango.vip/i/naMswC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.99,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DPX",
    "name": "Dopex Governance Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6c2c06790b3e3e3c38e12ee22f8183b37a13ee55",
    "decimals": 18,
    "image": "https://rango.vip/i/nokAxJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 8.99,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "EVMOS",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/6ae98883d4d5d5ff9e50d7130f1305da2ffa0c652d1dd9c123657c6b4eb2df8a",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/EVMOS.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.016954929792,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVMOS",
    "name": "Evmos",
    "isPopular": false,
    "chainId": "1",
    "address": "0x93581991f68dbae1ea105233b67f7fa0d6bdee7b",
    "decimals": 18,
    "image": "https://rango.vip/i/Q790Mn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01695819,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGG",
    "name": "AGG",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb0489e9753b045ddb35e39c6b0cc02ec6b99ac5",
    "decimals": 18,
    "image": "https://rango.vip/i/pvF88O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002857,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "STASH",
    "name": "STASH INU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EWMfSJgDCE7CXDAYz3hbCaA7NsFHTnddySXx3shco2Hs",
    "decimals": 6,
    "image": "https://rango.vip/i/sblhz9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00017089,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$BULL",
    "name": "Bull Run",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7ZYeCVdsXRFfh7TC5JRfBaZxQ6UhF5nNNdNtRzrdsDYF",
    "decimals": 9,
    "image": "https://rango.vip/i/TyjlxI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00477324,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LFGO",
    "name": "Lets Fuckin Go",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2u98MM7DMtVmNG4iAKRNMtynjmkzgD6fXAzB3wVfhQvg",
    "decimals": 6,
    "image": "https://rango.vip/i/MUc5rO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00253428,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FT",
    "name": "Fracton Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee9e7bb7e55bbc86414047b61d65c9c0d91ffbd0",
    "decimals": 18,
    "image": "https://rango.vip/i/6EwTwd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.378753,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RFD",
    "name": "REFUND",
    "isPopular": false,
    "chainId": "1",
    "address": "0x955d5c14c8d4944da1ea7836bd44d54a8ec35ba1",
    "decimals": 18,
    "image": "https://rango.vip/i/uCELnH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000345,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "XAVA",
    "name": "Avalaunch",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xd1c3f94de7e5b45fa4edbba472491a9f4b166fc4",
    "decimals": 18,
    "image": "https://rango.vip/i/N1AFEa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.3571,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOAI",
    "name": "Self Operating AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe8196181c5fe192b83c5ca34be910d93d691d935",
    "decimals": 18,
    "image": "https://rango.vip/i/8ziwzL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01844909,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAS",
    "name": "Wasder",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c572544a4ee47904d54aaa6a970af96b6f00e1b",
    "decimals": 18,
    "image": "https://rango.vip/i/PVXHg5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00239117,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIVER",
    "name": "DivergenceProtocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb782396c9b20e564a64896181c7ac8d8979d5f4",
    "decimals": 18,
    "image": "https://rango.vip/i/lJipiH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00823278,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FALX",
    "name": "FalconX",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Afo4NumBNHDXc7m7p6qjZ1pF3LbqYfG5k1CNrGve8rVu",
    "decimals": 9,
    "image": "https://rango.vip/i/JApZVM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00052604,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BORED",
    "name": "Bored Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2047ab3072b52561596ce5e0131bdbb7c848538d",
    "decimals": 9,
    "image": "https://rango.vip/i/GeZ0ys",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0008657662114311124,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INDI",
    "name": "IndiGG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3392d8a60b77f8d3eaa4fb58f09d835bd31add29",
    "decimals": 18,
    "image": "https://rango.vip/i/g81CM8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00233808,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TZU",
    "name": "Sun Tzu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x13dbd5394c2c7e4bdb85b1838286faa66532a262",
    "decimals": 18,
    "image": "https://rango.vip/i/p47ExC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.6308e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEEF",
    "name": "PepeBull",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbeef698bd78139829e540622d5863e723e8715f1",
    "decimals": 9,
    "image": "https://rango.vip/i/7t1LcP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.923e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SAFLE",
    "name": "Safle",
    "isPopular": false,
    "chainId": "137",
    "address": "0x04b33078ea1aef29bf3fb29c6ab7b200c58ea126",
    "decimals": 18,
    "image": "https://rango.vip/i/gAt5Fl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00457465,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZCHF",
    "name": "Frankencoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb58e61c3098d85632df34eecfb899a1ed80921cb",
    "decimals": 18,
    "image": "https://rango.vip/i/66BjTu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.19,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOPH",
    "name": "SophiaVerse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73fbd93bfda83b111ddc092aa3a4ca77fd30d380",
    "decimals": 18,
    "image": "https://rango.vip/i/v2rUpI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01957005,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JAN",
    "name": "Storm Warfare",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5a9261b023692405f2f680240c6b010638e416dd",
    "decimals": 18,
    "image": "https://rango.vip/i/nJ6ykJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0046795,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "EUROE",
    "name": "EUROe Stablecoin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xcf985aba4647a432e60efceeb8054bbd64244305",
    "decimals": 6,
    "image": "https://rango.vip/i/gqxQcx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EUROE",
    "name": "EUROe Stablecoin",
    "isPopular": false,
    "chainId": "137",
    "address": "0x820802fa8a99901f52e39acd21177b0be6ee2974",
    "decimals": 6,
    "image": "https://rango.vip/i/4Czr6W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUROE",
    "name": "EUROe Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x820802fa8a99901f52e39acd21177b0be6ee2974",
    "decimals": 6,
    "image": "https://rango.vip/i/G7Q0RC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NAO",
    "name": "Nettensor",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72f713d11480dcf08b37e1898670e736688d218d",
    "decimals": 18,
    "image": "https://rango.vip/i/ZyDcd4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01785239,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TST",
    "name": "Teleport System Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0828096494ad6252f0f853abfc5b6ec9dfe9fdad",
    "decimals": 18,
    "image": "https://rango.vip/i/acSKo2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00626952,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TST",
    "name": "TaoStack",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6eef75b8f067b5853a36da5561f52a8f22faa9af",
    "decimals": 9,
    "image": "https://rango.vip/i/pWg7Yc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011299,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MEAN",
    "name": "MEAN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD",
    "decimals": 6,
    "image": "https://rango.vip/i/CivmDm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01716494,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEAN",
    "name": "Mean DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b25889c493ae6df34ceef1ecb10d77c1ba73318",
    "decimals": 6,
    "image": "https://rango.vip/i/FPUh2b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01716494,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UP",
    "name": "TonUP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40a32606a4ce9b4f350421642ebf65c052d5389b",
    "decimals": 18,
    "image": "https://rango.vip/i/cs4RPV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.125823,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOLC",
    "name": "GOLCOIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x095797fd4297fb79883cc912a5ba6313b15c445d",
    "decimals": 18,
    "image": "https://rango.vip/i/B1xt9Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01046663,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOON",
    "name": "Pontoon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaee433adebe0fbb88daa47ef0c1a513caa52ef02",
    "decimals": 18,
    "image": "https://rango.vip/i/1xkp3j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00060508,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDO",
    "name": "Idexo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf9c53268e9de692ae1b2ea5216e24e1c3ad7cb1e",
    "decimals": 18,
    "image": "https://rango.vip/i/Hp5eNn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02599072,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIPP",
    "name": "El Hippo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7b744eea1deca2f1b7b31f15ba036fa1759452d7",
    "decimals": 18,
    "image": "https://rango.vip/i/nCVpsZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.19e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARNC",
    "name": "Arnoya classic",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7495e5cc8f27e0bd5bd4cb86d17f0d841ca58ee4",
    "decimals": 18,
    "image": "https://rango.vip/i/b56lwB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00771572,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "LOVELY",
    "name": "Lovely Inu Finance",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x4e719699e4197f4bf4370c49acd3e3b8de11974f",
    "decimals": 18,
    "image": "https://rango.vip/i/cpcxSQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00567416,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOVELY",
    "name": "Lovely Inu Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f6d4d4643a514132f84f4a270946db3c7cb701c",
    "decimals": 18,
    "image": "https://rango.vip/i/cpcxSQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00567416,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEGION",
    "name": "Legion",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2a25f7d864636e44bc1bf7a316897652bf07463",
    "decimals": 18,
    "image": "https://rango.vip/i/o7mjI3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00190864,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WOOF",
    "name": "WOOF",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE",
    "decimals": 6,
    "image": "https://rango.vip/i/uFCMQa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002268,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOOF",
    "name": "WoofWork io",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6bc08509b36a98e829dffad49fde5e412645d0a3",
    "decimals": 18,
    "image": "https://rango.vip/i/BcBxNO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015609,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BDROP",
    "name": "BlockDrop",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DcJLACAUR25RujgxBVhZtcWPCTjzSw6YDM8E7oipiT3k",
    "decimals": 6,
    "image": "https://rango.vip/i/fCFfr8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0493451,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACM",
    "name": "acmFinance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1bd9abf284e893705104e64b564b414620b722f1",
    "decimals": 18,
    "image": "https://rango.vip/i/ov5kSE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.094945,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIN",
    "name": "AI Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a810ff7211b40c4fa76205a14efe161615d0385",
    "decimals": 18,
    "image": "https://rango.vip/i/DYO800",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01068585,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHONKY",
    "name": "CHONKY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "H7ed7UgcLp3ax4X1CQ5WuWDn6d1pprfMMYiv5ejwLWWU",
    "decimals": 6,
    "image": "https://rango.vip/i/t8jAsD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001088,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MBS",
    "name": "MonkeyBucks",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fm9rHUTF5v3hwMLbStjZXqNBBoZyGriQaFM6sTFz3K8A",
    "decimals": 6,
    "image": "https://rango.vip/i/fX0YYJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00626806,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FORE",
    "name": "FORE Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xcbe94d75ec713b7ead84f55620dc3174beeb1cfe",
    "decimals": 18,
    "image": "https://rango.vip/i/JGJyzL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0075677,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FORE",
    "name": "FORE Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2ee0adbe0ef1281025d0676511bb1df14600f4d",
    "decimals": 18,
    "image": "https://rango.vip/i/JGJyzL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0075677,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AJNA",
    "name": "Ajna Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9a96ec9b57fb64fbc60b423d1f4da7691bd35079",
    "decimals": 18,
    "image": "https://rango.vip/i/yBjMHN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00925803,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TRUF",
    "name": "Truflation",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb59c8912c83157a955f9d715e556257f432c35d7",
    "decimals": 15,
    "image": "https://rango.vip/i/qDNfYl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.077516,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TRUF",
    "name": "Truflation",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xb59c8912c83157a955f9d715e556257f432c35d7",
    "decimals": 18,
    "image": "https://rango.vip/i/qDNfYl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.077516,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUF",
    "name": "Truflation",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38c2a4a7330b22788374b8ff70bba513c8d848ca",
    "decimals": 18,
    "image": "https://rango.vip/i/1TRTPk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.077516,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PORT",
    "name": "Port Finance Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y",
    "decimals": 6,
    "image": "https://rango.vip/i/BL1cLP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00313039,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PNDC",
    "name": "PondCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x423f4e6138e475d85cf7ea071ac92097ed631eea",
    "decimals": 18,
    "image": "https://rango.vip/i/RaOUxn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.61595e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LAN",
    "name": "Lanify",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc01b1979e2244dc94e67891df0af4f7885e57fd4",
    "decimals": 18,
    "image": "https://rango.vip/i/X6vlnn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00311028,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOLLARS",
    "name": "MollarsToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x385d65ed9241e415cfc689c3e0bcf5ab2f0505c2",
    "decimals": 9,
    "image": "https://rango.vip/i/s2BDLt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.107082,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HLG",
    "name": "Holograph",
    "isPopular": false,
    "chainId": "1",
    "address": "0x740df024ce73f589acd5e8756b377ef8c6558bab",
    "decimals": 18,
    "image": "https://rango.vip/i/UPGazk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00179551,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UBU",
    "name": "Ubuntu",
    "isPopular": false,
    "chainId": "137",
    "address": "0x78445485a8d5b3be765e3027bc336e3c272a23c9",
    "decimals": 18,
    "image": "https://rango.vip/i/M4pFNG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03869697,
    "supportedSwappers": [
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "UBU",
    "name": "UBUToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd2ddfba7bb12f6e70c2aab6b6bf9edaef42ed22f",
    "decimals": 18,
    "image": "https://rango.vip/i/C62U5A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03870210972521035,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PTH",
    "name": "PlasticHero",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ab98330473101309db94b625f9997366a518223",
    "decimals": 18,
    "image": "https://rango.vip/i/6ShT2w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.139987,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "TORI",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/eb7fb9c8b425f289b63703413327c2051030e848ce4eaaea2e51199d6d39d3ec",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.004269839047372698,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TORI",
    "name": "Tori the Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "D8F1FvrUhwg8WBjMqABopFYo13WwymGnVhsL3d7dRexP",
    "decimals": 5,
    "image": "https://rango.vip/i/AwuvNy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00428245,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAZI",
    "name": "MaziMatic",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5b8650cd999b23cf39ab12e3213fbc8709c7f5cb",
    "decimals": 18,
    "image": "https://rango.vip/i/O65Bfk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00060906,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CCASH",
    "name": "C Cash",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe925aa77d51746b865e5c05165a879820cb4b720",
    "decimals": 18,
    "image": "https://rango.vip/i/FuQ8GH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00172821,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BRZ",
    "name": "BRZ",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x491a4eb4f1fc3bff8e1d2fc856a6a46663ad556f",
    "decimals": 4,
    "image": "https://rango.vip/i/wbtcZK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.177062,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BRZ",
    "name": "BRZ",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FtgGSFADXBtroxq8VCausXRr2of47QBf5AS1NtZCu4GD",
    "decimals": 4,
    "image": "https://rango.vip/i/itnEua",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.177062,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRZ",
    "name": "Brazilian Digital",
    "isPopular": false,
    "chainId": "1",
    "address": "0x01d33fd36ec67c6ada32cf36b31e88ee190b1839",
    "decimals": 18,
    "image": "https://rango.vip/i/sk6lnw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.177062,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUMPCOIN",
    "name": "MAGA  Fight for Trump",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03dcee0d21ab39614c768dab67bfc33b0fc0a047",
    "decimals": 18,
    "image": "https://rango.vip/i/O9cLrj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00328992,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HODL",
    "name": "Hold On for Dear Life",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe2f98dd7506807ef82d1988aa77c320bc52f8df4",
    "decimals": 9,
    "image": "https://rango.vip/i/ckyu6I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.4659e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HODL",
    "name": "HODL",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0e9766df73973abcfedde700497c57110ee5c301",
    "decimals": 9,
    "image": "https://rango.vip/i/QsgILP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.579e-9,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EJS",
    "name": "Enjinstarter",
    "isPopular": false,
    "chainId": "1",
    "address": "0x96610186f3ab8d73ebee1cf950c750f3b1fb79c2",
    "decimals": 18,
    "image": "https://rango.vip/i/1v60Aj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00174326,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEURAL",
    "name": "NeuralBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf2041be4ea84599818799eed882389a8a30d2226",
    "decimals": 9,
    "image": "https://rango.vip/i/FDXJ3w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00023786,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEURAL",
    "name": "NEURALAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32b053f2cba79f80ada5078cb6b305da92bde6e1",
    "decimals": 18,
    "image": "https://rango.vip/i/ahxiij",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.59,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ARKER",
    "name": "ARKER",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9c67638c4fa06fd47fb8900fc7f932f7eab589de",
    "decimals": 18,
    "image": "https://rango.vip/i/PhhwMZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00009899,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "HUAHUA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/b9e0a1a524e98bb407d3ced8720efefd186002f90c1b1b7964811dd0ccc12228",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/HUAHUA.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00004841,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HUAHUA",
    "name": "Chihuahua native asset",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9fd470124903957f299a1c90feda9043a4619cc6",
    "decimals": 6,
    "image": "https://rango.vip/i/QqK1Z1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00004844410305679638,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "CHIHUAHUA",
    "symbol": "HUAHUA",
    "name": null,
    "isPopular": true,
    "chainId": "chihuahua-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/KBRwye",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CHIHUAHUA/icon.svg",
    "usdPrice": 0.000048319608444789,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "GIV",
    "name": "Giveth",
    "isPopular": false,
    "chainId": "10",
    "address": "0x528cdc92eab044e1e39fe43b9514bfdab4412b98",
    "decimals": 18,
    "image": "https://rango.vip/i/xQ6WU0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00563774,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GIV",
    "name": "Giveth Token (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3Xi3EhKjnKAk2KTChzybUSWcLW6eAgTHyotHH1U6sJE1",
    "decimals": 8,
    "image": "https://rango.vip/i/DxpMrt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00563774,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GIV",
    "name": "Giveth",
    "isPopular": false,
    "chainId": "1",
    "address": "0x900db999074d9277c5da2a43f252d74366230da0",
    "decimals": 18,
    "image": "https://rango.vip/i/xQ6WU0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00563774,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FNCT",
    "name": "Financie Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8af78f0c818302164f73b2365fe152c2d1fe80e1",
    "decimals": 18,
    "image": "https://rango.vip/i/IC1DqI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00207753,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JUICE",
    "name": "Juice",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde5d2530a877871f6f0fc240b9fce117246dadae",
    "decimals": 18,
    "image": "https://rango.vip/i/m9RA7N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.228711,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "JUICE",
    "name": "Juice Finance",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x818a92bc81aad0053d72ba753fb5bc3d0c5c0923",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00569707,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PPT",
    "name": "PAPA Trump",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fb2DefbdjKFPfmTnq4xnuZ9xdtVyXGsEAetoFmGLZQcc",
    "decimals": 8,
    "image": "https://rango.vip/i/aIggdH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00067101,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PPT",
    "name": "Populous",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd4fa1460f537bb9085d22c7bccb5dd450ef28e3a",
    "decimals": 8,
    "image": "https://rango.vip/i/yVP4hK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03994614,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "BLD",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/2da9c149e9ad2bd27fefa635458fb37093c256c1a940392634a16bea45262604",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/BLD.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.051947,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLD",
    "name": "Blackder AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95fe3151105ad90c6dc30dc51fdf038ae7bb7f77",
    "decimals": 9,
    "image": "https://rango.vip/i/n4IFMP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014161,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YOURAI",
    "name": "YOUR AI (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FjK6rqU6QzUeNtmK6QQ78cCuS5AHAhcm4HgJkdsvXaep",
    "decimals": 8,
    "image": "https://rango.vip/i/3NsrKK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00773572,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YOURAI",
    "name": "YOUR AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc5ba042bf8832999b17c9036e8212f49dce0501a",
    "decimals": 18,
    "image": "https://rango.vip/i/ITBVdr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00773572,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GURU",
    "name": "Guru",
    "isPopular": false,
    "chainId": "137",
    "address": "0x057e0bd9b797f9eeeb8307b35dbc8c12e534c41e",
    "decimals": 9,
    "image": "https://rango.vip/i/k79iuY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0031718244270623453,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GURU",
    "name": "GURU Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x525574c899a7c877a11865339e57376092168258",
    "decimals": 18,
    "image": "https://rango.vip/i/zSan93",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00317587,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "IMARO",
    "name": "IMARO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3Gjckk5jXnJffBruUS2EEYhpiDEN6z5TPXLkFVHkSkkg",
    "decimals": 6,
    "image": "https://rango.vip/i/AaLW0Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00247698,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAME",
    "name": "GAME Credits",
    "isPopular": false,
    "chainId": "1",
    "address": "0x63f88a2298a5c4aee3c216aa6d926b184a4b2437",
    "decimals": 18,
    "image": "https://rango.vip/i/1eDv4J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00066744,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAME",
    "name": "Game Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd567b5f02b9073ad3a982a099a23bf019ff11d1c",
    "decimals": 5,
    "image": "https://rango.vip/i/tqpNQy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.070147,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAME",
    "name": "GameBuild",
    "isPopular": false,
    "chainId": "1",
    "address": "0x825459139c897d769339f295e962396c4f9e4a4d",
    "decimals": 18,
    "image": "https://rango.vip/i/xWtwVV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00328575,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GAME",
    "name": "Game Coin [via ChainPort.io]",
    "isPopular": false,
    "chainId": "56",
    "address": "0x66109633715d2110dda791e64a7b2afadb517abb",
    "decimals": 5,
    "image": "https://rango.vip/i/HX5ibs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.070147,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MDT",
    "name": "Measurable Data Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26",
    "decimals": 18,
    "image": "https://rango.vip/i/eaYSd8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03610409,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MDT",
    "name": "Measurable Data Token (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8Wqbst4qAN2FqBCCh5gdXq2WJ7vTNWEY4oNLrpUg7Tya",
    "decimals": 8,
    "image": "https://rango.vip/i/kWLwyp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.036049032521390856,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MDT",
    "name": "MDT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x668db7aa38eac6b40c9d13dbe61361dc4c4611d1",
    "decimals": 18,
    "image": "https://rango.vip/i/X38kZ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.036049032521390856,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TKST",
    "name": "TokenSight",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7cdbfc86a0bfa20f133748b0cf5cea5b787b182c",
    "decimals": 18,
    "image": "https://rango.vip/i/bCfm6n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03882193,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CETI",
    "name": "Tao Ce i",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1bfce574deff725a3f483c334b790e25c8fa9779",
    "decimals": 18,
    "image": "https://rango.vip/i/bTI08q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEL",
    "name": "Bella Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa91ac63d040deb1b7a5e4d4134ad23eb0ba07e14",
    "decimals": 18,
    "image": "https://rango.vip/i/7N9bLW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.479015,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BEL",
    "name": "Bella Protocol",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8443f091997f06a61670b735ed92734f5628692f",
    "decimals": 18,
    "image": "https://rango.vip/i/2B6CXb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.479015,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "BEL",
    "name": "Bella",
    "isPopular": false,
    "chainId": "324",
    "address": "0xb83cfb285fc8d936e8647fa9b1cc641dbaae92d9",
    "decimals": 18,
    "image": "https://rango.vip/i/Xasus0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.47979417033911353,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "SOURCE",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/e7905742ce2ea4ea5d592527dc89220c59b617de803939fe7293805a64b484d7",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.010867477466496191,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOURCE",
    "name": "Source",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7118057ff0f4fd0994fb9d2d94de8231d5cca79e",
    "decimals": 18,
    "image": "https://rango.vip/i/dKXRM3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01026865,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "SOURCE",
    "name": "Source",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x74c0c58b99b68cf16a717279ac2d056a34ba2bfe",
    "decimals": 18,
    "image": "https://rango.vip/i/xD084h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.01026865,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KANG",
    "name": "Kangamoon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb1c9d42fa4ba691efe21656a7e6953d999b990c4",
    "decimals": 18,
    "image": "https://rango.vip/i/0db8Vo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00381632,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INDEX",
    "name": "Index Cooperative",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0954906da0bf32d5479e25f46056d22f08464cab",
    "decimals": 18,
    "image": "https://rango.vip/i/fgoBgf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.26,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ARBI",
    "name": "Arbipad",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x07dd5beaffb65b8ff2e575d500bdf324a05295dc",
    "decimals": 18,
    "image": "https://rango.vip/i/5rDaGC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00010227,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DOGEVERSE",
    "name": "DogeVerse",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x004aa1586011f3454f487eac8d0d5c647d646c69",
    "decimals": 18,
    "image": "https://rango.vip/i/ORZh0G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00002895,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGEVERSE",
    "name": "DogeVerse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62f03b52c377fea3eb71d451a95ad86c818755d1",
    "decimals": 18,
    "image": "https://rango.vip/i/ORZh0G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002895,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SALMAN",
    "name": "Mohameme Bit Salman",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69bb12b8ee418e4833b8debe4a2bb997ab9ce18e",
    "decimals": 9,
    "image": "https://rango.vip/i/SvLrvw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.1798e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XB",
    "name": "XBANKING",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33e80a92a9ea73dd02f6e732d1702d58c68388ca",
    "decimals": 2,
    "image": "https://rango.vip/i/IHVBRh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.195069,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MXM",
    "name": "MXM",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "H53UGEyBrB9easo9ego8yYk7o4Zq1G5cCtkxD3E3hZav",
    "decimals": 6,
    "image": "https://rango.vip/i/9K6dOO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00975005,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LENDS",
    "name": "Lends",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c06ba9e7f0daccbc1f6a33ea67e85bb68fbee3a",
    "decimals": 18,
    "image": "https://rango.vip/i/1rsN3r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00525448,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GENOME",
    "name": "GenomesDAO GENOME",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ae4f8885f6cfa41a692cb9da3789cfa6a83e9f2",
    "decimals": 18,
    "image": "https://rango.vip/i/KvXa0o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00655929,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "PLQ",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/b1e0166ea0d759fdf4b207d1f5f12210d8bfe36f2345cefc76948ce2b36dfbaf",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00932141,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "PICA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/56d7c03b8f6a07ad322eee1bef3ae996e09d1c1e34c27cf37e0d4a0ac5972516",
    "decimals": 12,
    "image": "https://rango.vip/tokens/ALL/PICA.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00035352,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PICA",
    "name": "Picasso",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "966vsqwoS3ZBrHesTyAvE7esFV2kaHaDFLLXs4asPdLJ",
    "decimals": 9,
    "image": "https://rango.vip/i/iVNLgS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00035352,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PICA",
    "name": "Picasso",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb63a9b64a80e9338b8ea298c51765e57c4f159c",
    "decimals": 12,
    "image": "https://rango.vip/i/0CGMYM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00035352,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLC",
    "name": "Flooring Lab Credit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x102c776ddb30c754ded4fdcc77a19230a60d4e4f",
    "decimals": 18,
    "image": "https://rango.vip/i/xPQLye",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00322857,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PSL",
    "name": "Pastel",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc775c0c30840cb9f51e21061b054ebf1a00acc29",
    "decimals": 5,
    "image": "https://rango.vip/i/FvmgLt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014866,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HBT",
    "name": "HyperBC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32fd949e1953b21b7a8232ef4259cd708b4e0847",
    "decimals": 18,
    "image": "https://rango.vip/i/5q0T8h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000257,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BMX",
    "name": "BitMart",
    "isPopular": false,
    "chainId": "1",
    "address": "0x986ee2b944c42d017f52af21c4c69b84dbea35d8",
    "decimals": 18,
    "image": "https://rango.vip/i/QbNNwL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.309547,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRAZY",
    "name": "Crazy Frog Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad5fdc8c3c18d50315331fca7f66efe5033f6c4c",
    "decimals": 18,
    "image": "https://rango.vip/i/ok5LeB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008762,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ICELAND",
    "name": "ICE LAND on ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x420fbb6006fb251318414ffa530590c3d7618e33",
    "decimals": 9,
    "image": "https://rango.vip/i/8Xc1ZB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.05184e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KURO",
    "name": "Kurobi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn",
    "decimals": 6,
    "image": "https://rango.vip/i/JNQjFL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003134,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MELLOW",
    "name": "Mellow Man",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6942040b6d25d6207e98f8e26c6101755d67ac89",
    "decimals": 9,
    "image": "https://rango.vip/i/NKKEw8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.118145,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SEXY",
    "name": "Settled EthXY Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc52fafdc900cb92ae01e6e4f8979af7f436e2eb2",
    "decimals": 18,
    "image": "https://rango.vip/i/EqWLEP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.099211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "STYLE",
    "name": "STYLE Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9500ba777560daf9d3ab148ea1cf1ed39df9ebdb",
    "decimals": 18,
    "image": "https://rango.vip/i/L5YIEE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00171235,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "STYLE",
    "name": "STYLE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF",
    "decimals": 9,
    "image": "https://rango.vip/i/OZvXaf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00142692,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STYLE",
    "name": "STYLE Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e91f79070926a191e41367d40ad582686f9e66d",
    "decimals": 18,
    "image": "https://rango.vip/i/L5YIEE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00171235,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EQ9",
    "name": "EQ9",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c444197d64e079323a1eb8d40655910b052f85a",
    "decimals": 18,
    "image": "https://rango.vip/i/caCKjt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00232986,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNEE",
    "name": "MNEE USD Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ccedbae4916b79da7f3f612efb2eb93a2bfd6cf",
    "decimals": 18,
    "image": "https://rango.vip/i/GiURxJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997144,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCALE",
    "name": "Scalia Infrastructure",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a0a9b663693a22235b896f70a229c4a22597623",
    "decimals": 18,
    "image": "https://rango.vip/i/CCeHqj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00252153,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "POLYDOGE",
    "name": "PolyDoge",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8a953cfe442c5e8855cc6c61b1293fa648bae472",
    "decimals": 18,
    "image": "https://rango.vip/i/pzB7LY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.0312e-8,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRYN",
    "name": "CRYN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc31cebf8f9e825d1d1244d73d0a65e44bd5210db",
    "decimals": 8,
    "image": "https://rango.vip/i/qyisgX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.49,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REL",
    "name": "Relevant",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb6c4267c4877bb0d6b1685cfd85b0fbe82f105ec",
    "decimals": 18,
    "image": "https://rango.vip/i/QaDMkg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000889944926961565,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REL",
    "name": "Relation Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe45dfc26215312edc131e34ea9299fbca53275ca",
    "decimals": 18,
    "image": "https://rango.vip/i/6M0oAM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00103441,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GXE",
    "name": "PROJECT XENO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x510975eda48a97e0ca228dd04d1217292487bea6",
    "decimals": 18,
    "image": "https://rango.vip/i/YBX5vc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01597912,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MATT",
    "name": "Matt Furie",
    "isPopular": false,
    "chainId": "1",
    "address": "0x790814cd782983fab4d7b92cf155187a865d9f18",
    "decimals": 9,
    "image": "https://rango.vip/i/Gdjlgj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000172,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MATT",
    "name": "MATT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7087c92ec764e75e7be7701eba15cd95d90f501f",
    "decimals": 18,
    "image": "https://rango.vip/i/zlNwUC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.56183e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$PELF",
    "name": "PELFORT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BgJW7U1u2RY5XJk9uYb5AqFRzjMtqE7pw3kaf9iw9Ntz",
    "decimals": 6,
    "image": "https://rango.vip/i/26SmB7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00012981,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DCM",
    "name": "Ducky City",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x3eeec801cef575b876d253ab06d75251f67d827d",
    "decimals": 18,
    "image": "https://rango.vip/i/jNzGdq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00003818,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "HEX",
    "name": "HEXX",
    "isPopular": false,
    "chainId": "137",
    "address": "0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c",
    "decimals": 8,
    "image": "https://rango.vip/i/Dsu04h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.001084,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HEX",
    "name": "HEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39",
    "decimals": 8,
    "image": "https://rango.vip/i/KXU77S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.001084,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTOK",
    "name": "Codyfight",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1acfb5d95bc090bc55d8ae58a8df4081d73e009",
    "decimals": 18,
    "image": "https://rango.vip/i/CXR1ub",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00701436,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DORKL",
    "name": "ᗪOᖇK ᒪOᖇᗪ",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BgqjQdnnjRtcELAgkYpfKAxWRqSReWercDdRTH6uLoer",
    "decimals": 6,
    "image": "https://rango.vip/i/9S4ZiC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0002806723488635976,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DORKL",
    "name": "DORK LORD  ETH ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x94be6962be41377d5beda8dfe1b100f3bf0eacf3",
    "decimals": 18,
    "image": "https://rango.vip/i/4vid08",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00028194,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NGL",
    "name": "Gold Fever Native Gold",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2653891204f463fb2a2f4f412564b19e955166ae",
    "decimals": 18,
    "image": "https://rango.vip/i/vKKP6o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04746127,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NGL",
    "name": "Entangle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12652c6d93fdb6f4f37d48a8687783c782bb0d10",
    "decimals": 18,
    "image": "https://rango.vip/i/FVU7cc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.085211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DBD",
    "name": "Day By Day",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa5f1dbb0e55bc31f32c6d032bee330288490e722",
    "decimals": 18,
    "image": "https://rango.vip/i/nFQoCN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00229178,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "BBT",
    "name": "BlueBit Token",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x4148d2ce7816f0ae378d98b40eb3a7211e1fcf0d",
    "decimals": 18,
    "image": "https://rango.vip/i/vhX1ck",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.000006717793828376863,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BBT",
    "name": "BitBook",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd48474e7444727bf500a32d5abe01943f3a59a64",
    "decimals": 8,
    "image": "https://rango.vip/i/zdB0fd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00082012,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GGAVAX",
    "name": "GoGoPool ggAVAX",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xa25eaf2906fa1a3a13edac9b9657108af7b703e3",
    "decimals": 18,
    "image": "https://rango.vip/i/Ycmgo9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 23.04,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MODEX",
    "name": "Modex",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4bcea5e4d0f6ed53cf45e7a28febb2d3621d7438",
    "decimals": 18,
    "image": "https://rango.vip/i/gI2yzZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04192941,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUCKYSLP",
    "name": "LuckysLeprecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x357c915d7c12dc506d13332bb06c932af13e99a0",
    "decimals": 18,
    "image": "https://rango.vip/i/DV9C0V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.298e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIBDOGE",
    "name": "ShibaDoge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6adb2e268de2aa1abf6578e4a8119b960e02928f",
    "decimals": 9,
    "image": "https://rango.vip/i/dNYRSe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.6e-17,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JIZZLORD",
    "name": "JizzLord",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "69SEPKGCzFzJLoSawb3xPfcqnZdiVm21Xu28AWZB5pzk",
    "decimals": 6,
    "image": "https://rango.vip/i/zwDaWS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0015110035339270785,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NYXC",
    "name": "Nyxia Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HrLx8MLKegpbmbmWePZiuvf3AbJNx1CJyjBwUHwicEgW",
    "decimals": 7,
    "image": "https://rango.vip/i/P7ukVN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02223547,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GPTPLUS",
    "name": "GPTPlus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed9f6aa6532869576211fd6367e3c328810fbeb3",
    "decimals": 18,
    "image": "https://rango.vip/i/64XgPR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016532,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "MNTL",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/cba34207e969623d95d057d9b11b0c8b32b89a71f170577d982fdde623813ffc",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/MNTL.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0008063061790863359,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNTL",
    "name": "AssetMantle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c4f1df9c7de0c59778936c9b145ff56813f3295",
    "decimals": 6,
    "image": "https://rango.vip/i/Oy5FO9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00080362,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TATSU",
    "name": "Tatsu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x92f419fb7a750aed295b0ddf536276bf5a40124f",
    "decimals": 18,
    "image": "https://rango.vip/i/eK3Csx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.83,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TAP",
    "name": "TapToken",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2c650dab03a59332e2e0c0c4a7f726913e5028c1",
    "decimals": 18,
    "image": "https://rango.vip/i/gGbTDB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.53,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TAP",
    "name": "Tapcaster",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x03a20484c558866b5ba576193e78cacd58bd4115",
    "decimals": 18,
    "image": "https://rango.vip/i/F09bph",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000513,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TAP",
    "name": "TAP Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CejQBkdRPN8Jyod2jVUYTfHwU9hPHvM3rD9prvQiffDU",
    "decimals": 9,
    "image": "https://rango.vip/i/FGqRrc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.518883241620962,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMZ",
    "name": "Open Meta City",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7d9babf56a66daff2ac5dc96f7e886c05124676",
    "decimals": 18,
    "image": "https://rango.vip/i/Ingf61",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.222256,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DETF",
    "name": "Decentralized ETF",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5516ac1aaca7bb2fd5b7bdde1549ef1ea242953d",
    "decimals": 18,
    "image": "https://rango.vip/i/iJ2NoI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03846887,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LBT",
    "name": "Lyfebloc",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ac83bfbfcebb3397a40fd259dbe7a4be04237d3",
    "decimals": 18,
    "image": "https://rango.vip/i/Hcf2Zc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.10007181822093149,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCH",
    "name": "Orchai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19373ecbb4b8cc2253d70f2a246fa299303227ba",
    "decimals": 18,
    "image": "https://rango.vip/i/OAxYlR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.24981,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAHA",
    "name": "HAHA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd87d72248093597df8d56d2a53c1ab7c1a0cc8da",
    "decimals": 18,
    "image": "https://rango.vip/i/IyYJ2d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000417,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDCRV",
    "name": "Stake DAO CRV",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1b5651e55d4ceed36251c61c50c889b36f6abb5",
    "decimals": 18,
    "image": "https://rango.vip/i/3Jc9rb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.273671,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCT",
    "name": "Octopus Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5cfbc74057c610c8ef151a439252680ac68c6dc",
    "decimals": 18,
    "image": "https://rango.vip/i/A7sMTP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.130359,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ROBO",
    "name": "RoboHero",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb3886b3aaa6087b3d185daeb89ac113d195b5eb9",
    "decimals": 18,
    "image": "https://rango.vip/i/6iSJOF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00183671,
    "supportedSwappers": [
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROBO",
    "name": "Robotrade",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc1c146e09640fe9e5d670288cc43c6269949d9a1",
    "decimals": 18,
    "image": "https://rango.vip/i/dGKjdM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012099,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FAKEAI",
    "name": "DeepFakeAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5aef5bba19e6a1644805bd4f5c93c8557b87c62c",
    "decimals": 18,
    "image": "https://rango.vip/i/pDg96I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00330958,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MATICX",
    "name": "Liquid Staking Matic (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xfa68fb4628dff1028cfec22b4162fccd0d45efb6",
    "decimals": 18,
    "image": "https://rango.vip/i/DDIt7O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.413811,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MATICX",
    "name": "Stader MaticX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf03a7eb46d01d9ecaa104558c732cf82f6b6b645",
    "decimals": 18,
    "image": "https://rango.vip/i/NATahv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.413811,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLC",
    "name": "SolCard",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DLUNTKRQt7CrpqSX1naHUYoBznJ9pvMP65uCeWQgYnRK",
    "decimals": 6,
    "image": "https://rango.vip/i/pj4Acc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03166102,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLC",
    "name": "Solcubator",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT",
    "decimals": 9,
    "image": "https://rango.vip/i/GZy1Lk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03157846548959336,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WVTRS",
    "name": "Vitreus",
    "isPopular": false,
    "chainId": "1",
    "address": "0x74950fc112473caba58193c6bf6412a6f1e4d7d2",
    "decimals": 18,
    "image": "https://rango.vip/i/XsvLX8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03911748,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KNTO",
    "name": "Kento",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc740181345c65552333e1edc797e03f11852b1c8",
    "decimals": 18,
    "image": "https://rango.vip/i/2QsLBL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.864e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLUG",
    "name": "PL Gnet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x47da5456bc2e1ce391b645ce80f2e97192e4976a",
    "decimals": 18,
    "image": "https://rango.vip/i/sNBgsR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000698,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AXGT",
    "name": "AxonDAO Governance Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe0ee18eacafddaeb38f8907c74347c44385578ab",
    "decimals": 18,
    "image": "https://rango.vip/i/RM16IK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.098074,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AXGT",
    "name": "AxonDAO Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd66781d0e9a08d4fbb5ec7bac80b691be27f21d",
    "decimals": 18,
    "image": "https://rango.vip/i/RM16IK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.098074,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "NETT",
    "name": "Netswap Token",
    "isPopular": true,
    "chainId": "1088",
    "address": "0x90fe084f877c65e1b577c7b2ea64b8d8dd1ab278",
    "decimals": 18,
    "image": "https://rango.vip/i/OWJFkL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 0.138964,
    "supportedSwappers": [
      "Netswap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRU",
    "name": "TrueFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c19596f5aaff459fa38b0f7ed92f11ae6543784",
    "decimals": 8,
    "image": "https://rango.vip/i/gTzpK8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.079822,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRU",
    "name": "Truebit",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf65b5c5104c4fafd4b709d9d60a185eae063276c",
    "decimals": 18,
    "image": "https://rango.vip/i/WNj7ER",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.123764,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QMALL",
    "name": "Qmall",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2217e5921b7edfb4bb193a6228459974010d2198",
    "decimals": 18,
    "image": "https://rango.vip/i/qauaMB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01623456,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NGC",
    "name": "NAGA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72dd4b6bd852a3aa172be4d6c5a6dbec588cf131",
    "decimals": 18,
    "image": "https://rango.vip/i/g480Ff",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02092779,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "xcDOT",
    "name": "xcDOT",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xffffffff1fcacbd218edc0eba20fc2308c778080",
    "decimals": 10,
    "image": "https://rango.vip/i/UxvjU6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 3.9852810641173946,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap",
      "Satellite"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIUS",
    "name": "Arbius",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8afe4055ebc86bd2afb3940c0095c9aca511d852",
    "decimals": 18,
    "image": "https://rango.vip/i/aBXYUG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 29.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIUS",
    "name": "Arbius",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe3dbc4f88eaa632ddf9708732e2832eeaa6688ab",
    "decimals": 18,
    "image": "https://rango.vip/i/ML7RRq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 29.148164244153985,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "DARC",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/346786ea82f41fe55fad14bf69ad8ba9b36985406e43f3cb23e6c45a285a9593",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/DARC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.000523607737522944,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DARC",
    "name": "DARC Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CpFE715P5DnDoJj9FbCRcuyHHeTXNdRnvzNkHvq1o23U",
    "decimals": 8,
    "image": "https://rango.vip/i/XOjj0P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00046622,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "KONSTELLATION",
    "symbol": "DARC",
    "name": null,
    "isPopular": true,
    "chainId": "darchub",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/nVXtr3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/KONSTELLATION/icon.svg",
    "usdPrice": 0.0005241203189008051,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LMEOW",
    "name": "lmeow",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1ae7e1d0ce06364ced9ad58225a1705b3e5db92b",
    "decimals": 9,
    "image": "https://rango.vip/i/CWjNBk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00464323,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RIZO",
    "name": "Rizo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x74e9fee3fcb56bccac22e726cce7a78ca90185e1",
    "decimals": 18,
    "image": "https://rango.vip/i/7moMfm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.8872e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNDL",
    "name": "Candle AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6efb32bc7893b793603e39643d86594ce3638157",
    "decimals": 18,
    "image": "https://rango.vip/i/c9rwPT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00475704,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNDL",
    "name": "Candle",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc138bd20c98186cc0342c8e380953af0cb48ba8",
    "decimals": 18,
    "image": "https://rango.vip/i/LueG7v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.004390198817353144,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ALOT",
    "name": "Dexalot Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x093783055f9047c2bff99c4e414501f8a147bc69",
    "decimals": 18,
    "image": "https://rango.vip/i/lKAsKl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.498447,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EQX",
    "name": "EQIFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd3de9a069648c84d27d74d701c9fa3253098b15",
    "decimals": 18,
    "image": "https://rango.vip/i/ujuVBH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00262047,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CIX",
    "name": "Centurion Invest",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d60a8dfb16d09f67d46fcd36a0cd310078257ca",
    "decimals": 18,
    "image": "https://rango.vip/i/NerfKE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00383899,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VUSD",
    "name": "VUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x677ddbd918637e5f2c79e164d402454de7da8619",
    "decimals": 18,
    "image": "https://rango.vip/i/h6Yk4k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997625,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VUSD",
    "name": "Virtual USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0bffdd787c83235f6f0afa0faed42061a4619b7a",
    "decimals": 6,
    "image": "https://rango.vip/i/W5lpOS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999982,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LEO",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0x93864d81175095dd93360ffa2a529b8642f76a6e",
    "decimals": 3,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02543179238814015,
    "supportedSwappers": [
      "MayaProtocol"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEO",
    "name": "LEO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3",
    "decimals": 18,
    "image": "https://rango.vip/i/pFJafa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.53,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVXCRV",
    "name": "Convex CRV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62b9c7356a2dc64a1969e19c23e4f579f9810aa7",
    "decimals": 18,
    "image": "https://rango.vip/i/Gkglsr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.260085,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GIDDY",
    "name": "Giddy Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6",
    "decimals": 18,
    "image": "https://rango.vip/i/EUSTfv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00148694,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIEPK",
    "name": "EpiK Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac5b038058bcd0424c9c252c6487c25f032e5ddc",
    "decimals": 18,
    "image": "https://rango.vip/i/Vh2D04",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00362389,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TAONU",
    "name": "TAO INU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6ZT7hoeMNfYua5oJ67EQJbFJHUBVLuFBbCKduRuk1rXr",
    "decimals": 9,
    "image": "https://rango.vip/i/A7idLz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0035211024190410856,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAONU",
    "name": "TAO INU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e9fcd48af4738e3bf1382009dc1e93ebfce698f",
    "decimals": 18,
    "image": "https://rango.vip/i/2iUGfi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00360674,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEOX",
    "name": "LEOX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa444ec96ee01bb219a44b285de47bf33c3447ad5",
    "decimals": 18,
    "image": "https://rango.vip/i/Y5bKss",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.154694,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHARK",
    "name": "White Shark Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8JF4ekdgw7VTdrrdCABAKAxLc55mGu9ptasNiamih4a9",
    "decimals": 9,
    "image": "https://rango.vip/i/NCWqBb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.05008879740934585,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHARK",
    "name": "Sharky",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SHARKSYJjqaNyxVfrpnBN9pjgkhwDhatnMyicWPnr1s",
    "decimals": 6,
    "image": "https://rango.vip/i/kF0oGs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.050204,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GGTK",
    "name": "GG",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa99a87b14b02e2240c79240c5a20f945ca5ef76",
    "decimals": 18,
    "image": "https://rango.vip/i/4JUNAR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01995316,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SONNE",
    "name": "Sonne Finance",
    "isPopular": false,
    "chainId": "10",
    "address": "0x1db2466d9f5e10d7090e7152b68d62703a2245f0",
    "decimals": 18,
    "image": "https://rango.vip/i/mCKUc0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00642772,
    "supportedSwappers": [
      "OpenOceanOptimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEFX",
    "name": "DeFinity",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f474906637bdcda05f29c74653f6962bb0f8eda",
    "decimals": 18,
    "image": "https://rango.vip/i/Kku5h2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03462854,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QRO",
    "name": "Querio",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfea156a736dee69da8740185f7d38e14f2d99ae7",
    "decimals": 18,
    "image": "https://rango.vip/i/6lvTHS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02211211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LUXY",
    "name": "LUXY",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd4945a3d0de9923035521687d4bf18cc9b0c7c2a",
    "decimals": 18,
    "image": "https://rango.vip/i/2YBfJH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.004809067381717237,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BGUY",
    "name": "The Big Guy",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8931ee05ec111325c1700b68e5ef7b887e00661d",
    "decimals": 9,
    "image": "https://rango.vip/i/3JyPrV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00003428,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUELX",
    "name": "Fuel",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcc54ac31164b5b3c39db4eef26d89275c468ec9d",
    "decimals": 18,
    "image": "https://rango.vip/i/vJSnY2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00196917,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCM",
    "name": "ScamFari",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8353b92201f19b4812eee32efd325f7ede123718",
    "decimals": 18,
    "image": "https://rango.vip/i/SoHPqY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000914,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SCM",
    "name": "Scamfari",
    "isPopular": false,
    "chainId": "10",
    "address": "0x61ccfe71d8ee67780320b0d990a02d702d99c271",
    "decimals": 18,
    "image": "https://rango.vip/i/DeNtN9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.000009130706197181566,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MMVG",
    "name": "MEMEVENGERS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xddf688e96cb2531a69bf6347c02f069266c1aa81",
    "decimals": 18,
    "image": "https://rango.vip/i/c0zxYb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.32648e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UCX",
    "name": "UCX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d3af44cf092a49280e316f09c8f20ecf97bc933",
    "decimals": 18,
    "image": "https://rango.vip/i/hNmjEU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04000093,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CALI",
    "name": "CaliCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8fa12f8409da31a4fc43d15c4c78c33d8213b9b",
    "decimals": 18,
    "image": "https://rango.vip/i/32zHC1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01434902,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TGT",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x108a850856db3f85d0269a2693d896b394c80325",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02002342,
    "supportedSwappers": [
      "ThorChain",
      "ThorChainStreamingSwap",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TGT",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0x429fed88f10285e61b12bdf00848315fbdfcc341",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02002342,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "MayaProtocol"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "21X",
    "name": "21X",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6r4PCVaX4rYN9WdbXwVWAQL4djFoUaeBMsq8Cxc6NApZ",
    "decimals": 9,
    "image": "https://rango.vip/i/HJHeEv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000847,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "JONES",
    "name": "Jones DAO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x10393c20975cf177a3513071bc110f7962cd67da",
    "decimals": 18,
    "image": "https://rango.vip/i/fUfOiw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.345043,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JONES",
    "name": " JONES",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f5e508182e1cbd23ea5ef65d1d6c342beb7d6d3",
    "decimals": 9,
    "image": "https://rango.vip/i/MepHFd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.0636e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALT",
    "name": "AltLayer Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8457ca5040ad67fdebbcc8edce889a335bc0fbfb",
    "decimals": 18,
    "image": "https://rango.vip/i/KVmLhc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.077264,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HARAMBEAI",
    "name": "Harambe AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5484581038cbf8ef33b7f6daec7a2f01f71db3c2",
    "decimals": 18,
    "image": "https://rango.vip/i/4Rp7cC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04812794,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DYNMT",
    "name": "Dynamite",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b7f247f21bf3a07088c2d3423f64233d4b069f7",
    "decimals": 2,
    "image": "https://rango.vip/i/j4hqnc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00184771,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QDT",
    "name": "QChain QDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e432a62733a7ee38ad2e16b3cc0731457ea5b55",
    "decimals": 4,
    "image": "https://rango.vip/i/35mVl9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0019314,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ASTRADAO",
    "name": "Astra DAO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd3188e0df68559c0b63361f6160c57ad88b239d8",
    "decimals": 17,
    "image": "https://rango.vip/i/XHuTd2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.0516e-8,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RST",
    "name": "Raini Studios Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe03e60757f21f4b6fc8f16676ad9d5b1002e512",
    "decimals": 18,
    "image": "https://rango.vip/i/d82zIA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00597072,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "RST",
    "name": "Raini Studios Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x23675ba5d0a8075da5ba18756554e7633cea2c85",
    "decimals": 18,
    "image": "https://rango.vip/i/ZKIgQ5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00597072,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DMCK",
    "name": "Diamond castle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2242328a9e9a2dea3b9ef5952b9614f45c7585d6",
    "decimals": 18,
    "image": "https://rango.vip/i/XGnplX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01916875,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHIPPY",
    "name": "FISH N CHIPS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Bz7Nx1F3Mti1BVS7ZAVDLSKGEaejufxvX2DPdjpf8PqT",
    "decimals": 6,
    "image": "https://rango.vip/i/AcBLRo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00199947,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NSDX",
    "name": "NASDEX Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe8d17b127ba8b9899a160d9a07b69bca8e08bfc6",
    "decimals": 18,
    "image": "https://rango.vip/i/fffhVq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02714799,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "TOMB",
    "name": "Tomb",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb84527d59b6ecb96f433029ecc890d4492c5dce1",
    "decimals": 18,
    "image": "https://rango.vip/i/8BorIH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00443971,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HERMES",
    "name": "HERMES",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb15f02f9da8cd1f99e9dd375f21dc96d25ddd82c",
    "decimals": 18,
    "image": "https://rango.vip/i/lwWVTE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.011342752953776394,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HERMES",
    "name": "Hermes Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x00000000000451f49c692bfc24971cacea2db678",
    "decimals": 18,
    "image": "https://rango.vip/i/YYEly3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0114216,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NMD",
    "name": "NOVAMIND",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9cb6dd255a6bbe32130ce645ec45c0a3e9d87a4a",
    "decimals": 18,
    "image": "https://rango.vip/i/HUg13m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017909,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STF",
    "name": "Structure Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f4cb968b76931c494ff92ed80ccb169ad641cb1",
    "decimals": 18,
    "image": "https://rango.vip/i/m85Bkn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00278201,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LAKE",
    "name": "Data Lake",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf9ca9523e5b5a42c3018c62b084db8543478c400",
    "decimals": 18,
    "image": "https://rango.vip/i/qfZSG9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00297915,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USEDCAR",
    "name": "A Gently Used 2001 Honda Civic",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9gwTegFJJErDpWJKjPfLr2g2zrE3nL1v5zpwbtsk3c6P",
    "decimals": 9,
    "image": "https://rango.vip/i/vOIAeO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00327941,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MOR",
    "name": "MorpheusAI",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x092baadb7def4c3981454dd9c0a0d7ff07bcfc86",
    "decimals": 18,
    "image": "https://rango.vip/i/CuMuni",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 19.79,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOR",
    "name": "MorpheusAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcbb8f1bda10b9696c57e13bc128fe674769dcec0",
    "decimals": 18,
    "image": "https://rango.vip/i/CuMuni",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 19.79,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "KUJIRA",
    "symbol": "USK",
    "name": null,
    "isPopular": true,
    "chainId": "kaiyo-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USK.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/KUJIRA/icon.svg",
    "usdPrice": 0.9629099728033708,
    "supportedSwappers": [
      "MayaProtocol",
      "IBC",
      "FinKujira"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "USK",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/44492eab24b72e3fb59b9fa619a22337fb74f95d8808fe6bc78cc0e6c18dc2ec",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USK.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GCAKE",
    "name": "Pancake Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f944b0c4315cb7c3a846b025ab4045da44abf6c",
    "decimals": 18,
    "image": "https://rango.vip/i/yYUInV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.918e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ROND",
    "name": "ROND Coin",
    "isPopular": false,
    "chainId": "137",
    "address": "0x204820b6e6feae805e376d2c6837446186e57981",
    "decimals": 18,
    "image": "https://rango.vip/i/pnhJ2X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00158803,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYBA",
    "name": "Cybria",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1063181dc986f76f7ea2dd109e16fc596d0f522a",
    "decimals": 9,
    "image": "https://rango.vip/i/6sGc4m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00075873,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CFXQ",
    "name": "CFX Quantum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0557e0d15aec0b9026dd17aa874fdf7d182a2ceb",
    "decimals": 6,
    "image": "https://rango.vip/i/MuIVE6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00456787,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CONAN",
    "name": "Conan",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85d19fb57ca7da715695fcf347ca2169144523a7",
    "decimals": 9,
    "image": "https://rango.vip/i/GexSQ0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000226,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "NORMIE",
    "name": "Normie",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x47b464edb8dc9bc67b5cd4c9310bb87b773845bd",
    "decimals": 9,
    "image": "https://rango.vip/i/EIz0Cs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00593495,
    "supportedSwappers": [
      "SushiBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "NORMIE",
    "name": "Normie",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7f12d13b34f5f4f0a9449c16bcd42f0da47af200",
    "decimals": 9,
    "image": "https://rango.vip/i/7heOyZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.005858877887546959,
    "supportedSwappers": [
      "AlienBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRAI",
    "name": "Grai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15f74458ae0bfdaa1a96ca1aa779d715cc1eefe4",
    "decimals": 18,
    "image": "https://rango.vip/i/HjinM7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.982243,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMT",
    "name": "SmartMesh Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x21f15966e07a10554c364b988e91dab01d32794a",
    "decimals": 18,
    "image": "https://rango.vip/i/SVcWea",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00084157,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMT",
    "name": "Swarm Markets",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb17548c7b510427baac4e267bea62e800b247173",
    "decimals": 18,
    "image": "https://rango.vip/i/aAhNUw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.284741,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SMT",
    "name": "Swarm Markets",
    "isPopular": false,
    "chainId": "10",
    "address": "0x8eb99a441b04576282e9069977f6de6053ef6a97",
    "decimals": 18,
    "image": "https://rango.vip/i/ChVJyn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.2845710784475515,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "KOI",
    "name": "Koi",
    "isPopular": false,
    "chainId": "324",
    "address": "0xa995ad25ce5eb76972ab356168f5e1d9257e4d05",
    "decimals": 18,
    "image": "https://rango.vip/i/Djauv7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00957634,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KOI",
    "name": "KOI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6fdCC8xfrXNy6PmNaVcxdEY5XNCTAha2V54zYYnmBCey",
    "decimals": 6,
    "image": "https://rango.vip/i/rssFkN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008747,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KOI",
    "name": "Koi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d14bce1daddf408d77295bb1be9b343814f44de",
    "decimals": 18,
    "image": "https://rango.vip/i/KC5dQe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00957634,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QSWAP",
    "name": "Quantum Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a8b188fadbe8b52a2c23ea2d0df74f8956e7730",
    "decimals": 18,
    "image": "https://rango.vip/i/SQOIzK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000428,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TWIF",
    "name": "Tomwifhat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62760e76dce6b500349ec5f6119228d047913350",
    "decimals": 9,
    "image": "https://rango.vip/i/wFZlNm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00072278,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KNS",
    "name": "Kenshi",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf1264873436a0771e440e2b28072fafcc5eebd01",
    "decimals": 18,
    "image": "https://rango.vip/i/FmDfxQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.03173104,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KNS",
    "name": "Kenshi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1264873436a0771e440e2b28072fafcc5eebd01",
    "decimals": 18,
    "image": "https://rango.vip/i/FmDfxQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03173104,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAO",
    "name": "HistoryDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c4008eca800ec1283e4cf500e68d06bfabc00a8",
    "decimals": 18,
    "image": "https://rango.vip/i/1daaVC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0007174,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MILKBAG",
    "name": "MILKBAG",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2ubuHGFS4VJVxSEpvV3kDwz6JiuXdaAoGMwrwYC87tp8",
    "decimals": 9,
    "image": "https://rango.vip/i/3vn0cE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00332151,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GTCOIN",
    "name": "Game Tree",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d5e244660ca05c42073c9a526616d99f2c99516",
    "decimals": 18,
    "image": "https://rango.vip/i/8VXNa1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00066076,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SILO",
    "name": "Silo Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x0341c0c0ec423328621788d4854119b97f44e391",
    "decimals": 18,
    "image": "https://rango.vip/i/ZvnZX9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02642919,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SILO",
    "name": "Silo Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6f80310ca7f2c654691d1383149fa1a57d8ab1f8",
    "decimals": 18,
    "image": "https://rango.vip/i/G9UQDM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02642919,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "EQU",
    "name": "Equation",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x87aaffdf26c6885f6010219208d5b161ec7609c0",
    "decimals": 18,
    "image": "https://rango.vip/i/ZoE16E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.39,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MESA",
    "name": "metavisa",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5afff9876c1f98b7d2b53bcb69eb57e92408319f",
    "decimals": 18,
    "image": "https://rango.vip/i/u8cPfX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008197,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AEGIS",
    "name": "Aegis Ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x55a8f6c6b3aa58ad6d1f26f6afeded78f32e19f4",
    "decimals": 9,
    "image": "https://rango.vip/i/MLACCt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00731534,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UBSN",
    "name": "Silent Notary",
    "isPopular": false,
    "chainId": "1",
    "address": "0x86efc496dca70bcfd92d19194290e8457a375773",
    "decimals": 0,
    "image": "https://rango.vip/i/oyRngw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000537,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OME",
    "name": "O MEE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd89b8d708809e7022135313683663911826977e",
    "decimals": 18,
    "image": "https://rango.vip/i/SPGcuH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004769,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAD",
    "name": "Caduceus Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4349929808e515936a68903f6085f5e2b143ff3d",
    "decimals": 18,
    "image": "https://rango.vip/i/PFxNAo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.35922079791136935,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAD",
    "name": "Cadence Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x912529007bc0d2a5464a6a211ebfe217dfb75dff",
    "decimals": 18,
    "image": "https://rango.vip/i/hwl1U5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.117444,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XBLAZE",
    "name": "Trailblaze",
    "isPopular": false,
    "chainId": "1",
    "address": "0x989fa855ce126275bc269e0ec8f04a57b4af02b4",
    "decimals": 18,
    "image": "https://rango.vip/i/zriI3K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00377224,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORAIX",
    "name": "OraiDEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2d869ae129e308f94cc47e66eaefb448cee0d03e",
    "decimals": 18,
    "image": "https://rango.vip/i/nkrfIJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0054463,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HXD",
    "name": "Honeyland",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3dgCCb15HMQSA4Pn3Tfii5vRk7aRqTH95LJjxzsG2Mug",
    "decimals": 9,
    "image": "https://rango.vip/i/kt3HnE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02043284,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHART",
    "name": "ChartEx",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1d37986f252d0e349522ea6c3b98cb935495e63e",
    "decimals": 18,
    "image": "https://rango.vip/i/FefF40",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03889239709155355,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHART",
    "name": "Nchart Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf62ac0fcae17f9195280ced4de978313effe2daa",
    "decimals": 18,
    "image": "https://rango.vip/i/qvLOqD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03889487,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CHART",
    "name": "ChartEx",
    "isPopular": false,
    "chainId": "137",
    "address": "0x083c56d87ead73d6231c165ec450c6e28f3399c9",
    "decimals": 18,
    "image": "https://rango.vip/i/XV7jtR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03889239709155355,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "EURA",
    "name": "EURA (previously agEUR)",
    "isPopular": false,
    "chainId": "56",
    "address": "0x12f31b73d812c6bb0d735a218c086d44d5fe5f89",
    "decimals": 18,
    "image": "https://rango.vip/i/uhzycN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "EURA",
    "name": "EURA (previously agEUR)",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xfa5ed56a203466cbbc2430a43c66b9d8723528e7",
    "decimals": 18,
    "image": "https://rango.vip/i/jJUrbt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EURA",
    "name": "EURA (previously agEUR)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe0b52e49357fd4daf2c15e02058dce6bc0057db4",
    "decimals": 18,
    "image": "https://rango.vip/i/7tb0hk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EURA",
    "name": "EURA (previously agEUR)",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a7e4e63778b4f12a199c062f3efdd288afcbce8",
    "decimals": 18,
    "image": "https://rango.vip/i/cC2LVn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BIC",
    "name": "BicOnBase",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xef80422317dfe797ef7cf51b043226987877d34a",
    "decimals": 18,
    "image": "https://rango.vip/i/OcerZY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.01214829,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IONX",
    "name": "Charged Particles",
    "isPopular": false,
    "chainId": "1",
    "address": "0x02d3a27ac3f55d5d91fb0f52759842696a864217",
    "decimals": 18,
    "image": "https://rango.vip/i/AOYS1B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00577012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GIKO",
    "name": "Giko Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3WPep4ufaToK1aS5s8BL9inzeUrt4DYaQCiic6ZkkC1U",
    "decimals": 9,
    "image": "https://rango.vip/i/E2aeb9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.27553,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PROB",
    "name": "Probit",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb559ce67ff522ec0b9ba7f5dc9dc7ef6c139803",
    "decimals": 18,
    "image": "https://rango.vip/i/nYu6N2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.090447,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLH",
    "name": "ClearDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7d8f3b8bc8bc48d3acc37879eaba7b85889fa52",
    "decimals": 18,
    "image": "https://rango.vip/i/zoCc2a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00031609,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LPOOL",
    "name": "Launchpool token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6149c26cd2f7b5ccdb32029af817123f6e37df5b",
    "decimals": 18,
    "image": "https://rango.vip/i/jp1eQJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.212163,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PRIMAL",
    "name": "PRIMAL Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xcb5327ed4649548e0d73e70b633cdfd99af6da87",
    "decimals": 18,
    "image": "https://rango.vip/i/BE4MvH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0001196,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRIMAL",
    "name": "PRIMAL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd13dedecebda566322195bc4451d672a148752c",
    "decimals": 18,
    "image": "https://rango.vip/i/gT5NOH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001196,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GUA",
    "name": "GUA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x393f1d49425d94f47b26e591a9d111df5cd61065",
    "decimals": 18,
    "image": "https://rango.vip/i/iKVvhz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024138,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ECTE",
    "name": "EurocoinToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9fa21e671bcfb04e6868784b89c19d5aa2424ea",
    "decimals": 18,
    "image": "https://rango.vip/i/0Wuy2y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.076663,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAT",
    "name": "Super Athletes Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5abf88cf3444611d13f6d1b39f3f3ee8575c91a2",
    "decimals": 18,
    "image": "https://rango.vip/i/Cytf8y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00204027,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TBANK",
    "name": "TaoBank",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95ccffae3eb8767d4a941ec43280961dde89f4de",
    "decimals": 18,
    "image": "https://rango.vip/i/LUtCQL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.095847,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCANS",
    "name": "0xScans",
    "isPopular": false,
    "chainId": "1",
    "address": "0x10703ca5e253306e2ababd68e963198be8887c81",
    "decimals": 18,
    "image": "https://rango.vip/i/hx5TH6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0067886729113534345,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BTCMT",
    "name": "BTCMT",
    "isPopular": false,
    "chainId": "128",
    "address": "0x410a56541bd912f9b60943fcb344f1e3d6f09567",
    "decimals": 18,
    "image": "https://rango.vip/i/Fkc4dv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.540849,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "YAY",
    "name": "YAY Games",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x01c2086facfd7aa38f69a6bd8c91bef3bb5adfca",
    "decimals": 18,
    "image": "https://rango.vip/i/RJCsx1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00140604,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "YAY",
    "name": "YAY Games",
    "isPopular": false,
    "chainId": "56",
    "address": "0x524df384bffb18c0c8f3f43d012011f8f9795579",
    "decimals": 18,
    "image": "https://rango.vip/i/jk7dzg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00140604,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOUTH",
    "name": "DeepSouth AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd24ba0e3364233ee9301c1d608a14753c8739c5",
    "decimals": 18,
    "image": "https://rango.vip/i/oDtRqZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DCI",
    "name": "Decentralized Cloud Infrastructure",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc6221ac4e99066ea5443acd67d6108f874e2533d",
    "decimals": 18,
    "image": "https://rango.vip/i/HkjUAv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.175553,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDH",
    "name": "USDH Hubble Stablecoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX",
    "decimals": 6,
    "image": "https://rango.vip/i/ESFidu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.972242,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOOCAT",
    "name": "MooCat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FuLH3f9TPxiLNojPBZxfaT5Rb5VFfia8hMzLo9e9c6CG",
    "decimals": 9,
    "image": "https://rango.vip/i/yYnwjw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00048512,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NRN",
    "name": "Neuron",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xdadeca1167fe47499e53eb50f261103630974905",
    "decimals": 18,
    "image": "https://rango.vip/i/7a3rWj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02410263,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNP",
    "name": "Unipoly Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x23d7ff057c696fee679c60cef61fee6614218f04",
    "decimals": 18,
    "image": "https://rango.vip/i/K3nW95",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02003348,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DFI",
    "name": "DeFiChain Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8fc8f8269ebca376d046ce292dc7eac40c8d358a",
    "decimals": 8,
    "image": "https://rango.vip/i/H6clKI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02092259,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DFI",
    "name": "DFI (DefiChain)",
    "isPopular": false,
    "chainId": "56",
    "address": "0x361c60b7c2828fcab80988d00d1d542c83387b50",
    "decimals": 18,
    "image": "https://rango.vip/i/jnsLxP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02092259,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AKITAX",
    "name": "Akitavax",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xe06fba763c2104db5027f57f6a5be0a0d86308af",
    "decimals": 18,
    "image": "https://rango.vip/i/WHJFRg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00001439,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MONKEY",
    "name": "Monkey",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "921MoB1U7VprQfWw5D37a38LCBgB3nareT7rNffk66BG",
    "decimals": 4,
    "image": "https://rango.vip/i/zuW1ai",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 9.94482e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "COOK",
    "name": "Poly-Peg COOK",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x637afeff75ca669ff92e4570b14d6399a658902f",
    "decimals": 18,
    "image": "https://rango.vip/i/7PZKd2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00010437,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COOK",
    "name": "Let Him Cook",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G8Vy25NzjRmuQtnN35xF7j3X2Z1TrV39XijZu8Mg4w8n",
    "decimals": 6,
    "image": "https://rango.vip/i/2H3Ego",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00169847,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COOK",
    "name": "Cook Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff75ced57419bcaebe5f05254983b013b0646ef5",
    "decimals": 18,
    "image": "https://rango.vip/i/hvXdRz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010437,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "COOK",
    "name": "Poly-Peg COOK",
    "isPopular": false,
    "chainId": "56",
    "address": "0x965b0df5bda0e7a0649324d78f03d5f7f2de086a",
    "decimals": 18,
    "image": "https://rango.vip/i/rQ1nUw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00010437,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "COOK",
    "name": "COOK",
    "isPopular": false,
    "chainId": "128",
    "address": "0x74189862b069e2be5f7c8e6ff08ea8e1b1948519",
    "decimals": 18,
    "image": "https://rango.vip/i/EBdewX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00010437,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XIDR",
    "name": "XIDR",
    "isPopular": false,
    "chainId": "1",
    "address": "0xebf2096e01455108badcbaf86ce30b6e5a72aa52",
    "decimals": 6,
    "image": "https://rango.vip/i/P8fOY1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006498,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MOCHI",
    "name": "Mochi",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xf6e932ca12afa26665dc4dde7e27be02a7c02e50",
    "decimals": 18,
    "image": "https://rango.vip/i/1XUwyF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000701,
    "supportedSwappers": [
      "SushiBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MOCHI",
    "name": "Mochi",
    "isPopular": false,
    "chainId": "10",
    "address": "0x77d40cbc27f912dcdbf4348caf87b427c4d02486",
    "decimals": 18,
    "image": "https://rango.vip/i/EWNM2m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.000007083411654084431,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOCHI",
    "name": "Mochi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf134519cbe2042b06ee7ce20df51d09b55559896",
    "decimals": 18,
    "image": "https://rango.vip/i/EWNM2m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0160009,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONKEYS",
    "name": "Tails from the list",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ce07410673206c693bcec9b07710767637a564c",
    "decimals": 9,
    "image": "https://rango.vip/i/K36gNX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.75337e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONKEYS",
    "name": "Monkeys",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf2a22b900dde3ba18ec2aef67d4c8c1a0dab6aac",
    "decimals": 9,
    "image": "https://rango.vip/i/sjikJm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.20345e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAGE",
    "name": "KAGE NETWORK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64945165255bcb83f2ef9f31a575975832ca4db4",
    "decimals": 18,
    "image": "https://rango.vip/i/8kme2h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01299,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WELD",
    "name": "WELD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd2a36ae937bc134ea694d77fc7e2e36f5d86de0",
    "decimals": 18,
    "image": "https://rango.vip/i/K6DAYY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00347578,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOMI",
    "name": "Domi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x45c2f8c9b4c0bdc76200448cc26c48ab6ffef83f",
    "decimals": 18,
    "image": "https://rango.vip/i/h8Y6oO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00831071,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DOMI",
    "name": "Domi",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbbca42c60b5290f2c48871a596492f93ff0ddc82",
    "decimals": 18,
    "image": "https://rango.vip/i/d20fvx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00831071,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DOMI",
    "name": "Domi",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xfc6da929c031162841370af240dec19099861d3b",
    "decimals": 18,
    "image": "https://rango.vip/i/G8cT99",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00831071,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INFRA",
    "name": "Bware",
    "isPopular": false,
    "chainId": "1",
    "address": "0x013062189dc3dcc99e9cee714c513033b8d99e3c",
    "decimals": 18,
    "image": "https://rango.vip/i/Zex6VS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.216364,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INFRA",
    "name": "infraX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9eccde9d26fcbb5e93f536cfc4510a7f46274f8",
    "decimals": 9,
    "image": "https://rango.vip/i/BW2wtk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.42,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FFM",
    "name": "Florence Finance Medici",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3269a3c00ab86c753856fd135d97b87facb0d848",
    "decimals": 18,
    "image": "https://rango.vip/i/38PCAI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01749317,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FFM",
    "name": "Florence Finance Medici",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xb4e1b230dd0476238fc64c99ff9d6ccdfdb2258d",
    "decimals": 18,
    "image": "https://rango.vip/i/38PCAI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.01749317,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKAI",
    "name": "SKAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcf078da6e85389de507ceede0e3d217e457b9d49",
    "decimals": 18,
    "image": "https://rango.vip/i/m5fIfS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.065638,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ANUS",
    "name": "URANUS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9hjZ8UTNrNWt3YUTHVpvzdQjNbp64NbKSDsbLqKR6BZc",
    "decimals": 9,
    "image": "https://rango.vip/i/RAwQC6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00020293,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRGPT",
    "name": "CryptoGPT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x50739bd5b6aff093ba2371365727c48a420a060d",
    "decimals": 18,
    "image": "https://rango.vip/i/q8lEUq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.063854,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MANDOX",
    "name": "Mandox",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a8adcf432ebcc2311b955d176ee4bfed13bb9a7",
    "decimals": 9,
    "image": "https://rango.vip/i/WRGOTv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.0141e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LGX",
    "name": "Legion Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9096b4309224d751fcb43d7eb178dcffc122ad15",
    "decimals": 18,
    "image": "https://rango.vip/i/24R2N1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00113813,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNAKE",
    "name": "Pepe Predator",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1032abe2902a23ddcbab085c20e0e69c33ceb8fa",
    "decimals": 18,
    "image": "https://rango.vip/i/uYtZl0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00055158,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MOXIE",
    "name": "Moxie",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8c9037d1ef5c6d1f6816278c7aaf5491d24cd527",
    "decimals": 18,
    "image": "https://rango.vip/i/E7BOjU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0026103,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DFNDR",
    "name": "Defender Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f57c35633cb29834bb7577ba8052eab90f52a02",
    "decimals": 18,
    "image": "https://rango.vip/i/qkgMR3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01924691,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDYP",
    "name": "iDypius",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd100d061e120b2c67a24453cf6368e63f1be056",
    "decimals": 18,
    "image": "https://rango.vip/i/lzwCuH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0006595,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPOOL",
    "name": "Spool",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40803cea2b2a32bda1be61d3604af6a814e70976",
    "decimals": 18,
    "image": "https://rango.vip/i/hwczTY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.129452,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "MNTA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/51d893f870b7675e507e91da8db0b22ea66333207e4f5c0708757f08ee059b0b",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.1367880984574464,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNTA",
    "name": "Moneta",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5b342f03d126314d925fa57a45654f92905e6451",
    "decimals": 18,
    "image": "https://rango.vip/i/NtEQmP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.252419,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "KUJIRA",
    "symbol": "MNTA",
    "name": null,
    "isPopular": true,
    "chainId": "kaiyo-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/mVyn2M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/KUJIRA/icon.svg",
    "usdPrice": 0.136922006012583,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OLEA",
    "name": "Olea Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc6cc3d07c705e39d11c7f60d8836c7c78d4ac5f1",
    "decimals": 18,
    "image": "https://rango.vip/i/ElLlQj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00031244,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROAR",
    "name": "Roaring Kitty",
    "isPopular": false,
    "chainId": "1",
    "address": "0x569d0e52c3dbe95983bcc2434cb9f69d905be919",
    "decimals": 9,
    "image": "https://rango.vip/i/wGp6Ze",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010643,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROAR",
    "name": "Roaring Kitty",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd8c978de79e12728e38aa952a6cb4166f891790f",
    "decimals": 18,
    "image": "https://rango.vip/i/lopQYt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0016987598313664256,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UXD",
    "name": "UXD Stablecoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT",
    "decimals": 6,
    "image": "https://rango.vip/i/BZfoCG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.002,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JAY",
    "name": "Jaypeggers",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda7c0810ce6f8329786160bb3d1734cf6661ca6e",
    "decimals": 18,
    "image": "https://rango.vip/i/0hZWe5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.33,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TGC",
    "name": "TG Casino",
    "isPopular": false,
    "chainId": "1",
    "address": "0x25b4f5d4c314bcd5d7962734936c957b947cb7cf",
    "decimals": 18,
    "image": "https://rango.vip/i/u5sDdf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.169254,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMART",
    "name": "Smart Game Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce20bb92ccf9bbf5091ef85649e71e552819ad8c",
    "decimals": 18,
    "image": "https://rango.vip/i/mDgcvA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006554,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMART",
    "name": "Smart AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe7290b932cd0d5aec29c57394e87cdaa41cc054",
    "decimals": 9,
    "image": "https://rango.vip/i/4lEDeL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.082468,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMART",
    "name": "SmartHub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b68f1d0246320d5caf8cd9828faab28d66ba749",
    "decimals": 9,
    "image": "https://rango.vip/i/itt7Xg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.123959,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SBET",
    "name": "Sports Bet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2ed2cc2c858a8a8219fd2f2d9e170285dbd02756",
    "decimals": 18,
    "image": "https://rango.vip/i/g1Ayrs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003669,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BNX",
    "name": "BinaryX",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5b1f874d0b0c5ee17a495cbb70ab8bf64107a3bd",
    "decimals": 18,
    "image": "https://rango.vip/i/atTYWJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.12,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BNX",
    "name": "BinaryX",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8c851d1a123ff703bd1f9dabe631b69902df5f97",
    "decimals": 18,
    "image": "https://rango.vip/i/t0Mf0g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.509864,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SVPN",
    "name": "Shadow Node",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc668695dcbcf682de106da94bde65c9bc79362d3",
    "decimals": 18,
    "image": "https://rango.vip/i/dgjr61",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00214471,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "APX",
    "name": "ApolloX Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x78f5d389f5cdccfc41594abab4b0ed02f31398b3",
    "decimals": 18,
    "image": "https://rango.vip/i/pyk3EQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.04490692,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APX",
    "name": "AstroPepeX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed4e879087ebd0e8a77d66870012b5e0dffd0fa4",
    "decimals": 18,
    "image": "https://rango.vip/i/sIe176",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010434,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OBOT",
    "name": "Obortech",
    "isPopular": false,
    "chainId": "1",
    "address": "0xedadeb5faa413e6c8623461849dfd0b7c3790c32",
    "decimals": 18,
    "image": "https://rango.vip/i/W95FDb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01369484,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEMAGX",
    "name": "Meta Masters Guild Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x857de36f92330e1b9a21e8745c692f2ce13866cb",
    "decimals": 18,
    "image": "https://rango.vip/i/BhM7WV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00148165,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MYTH",
    "name": "Mythos",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba41ddf06b7ffd89d1267b5a93bfef2424eb2003",
    "decimals": 18,
    "image": "https://rango.vip/i/pv7oxo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.187419,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SAIL",
    "name": "SAIL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y",
    "decimals": 6,
    "image": "https://rango.vip/i/L2E7rQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0035510259473249305,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAIL",
    "name": "Clipper SAIL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd8f1460044925d2d5c723c7054cd9247027415b7",
    "decimals": 18,
    "image": "https://rango.vip/i/gZREzm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00355118,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SAIL",
    "name": "SAIL Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x7a1263ec3bf0a19e25c553b8a2c312e903262c5e",
    "decimals": 18,
    "image": "https://rango.vip/i/z0nJ0H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00355118,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WXT",
    "name": "WXT Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xfcde4a87b8b6fa58326bb462882f1778158b02f1",
    "decimals": 18,
    "image": "https://rango.vip/i/0XMlDj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00544947,
    "supportedSwappers": [
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WXT",
    "name": "Wirex Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa02120696c7b8fe16c09c749e4598819b2b0e915",
    "decimals": 18,
    "image": "https://rango.vip/i/lVledh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00544947,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HABIBI",
    "name": "HABIBI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "864YJRb3JAVARC4FNuDtPKFxdEsYRbB39Nwxkzudxy46",
    "decimals": 6,
    "image": "https://rango.vip/i/qIMsM5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01038467,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HABIBI",
    "name": "Habibi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8526be2379e853d5cf02f9823bb9690e1a6ff9e2",
    "decimals": 18,
    "image": "https://rango.vip/i/No6JAt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.3925e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SQT",
    "name": "SubQueryToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x09395a2a58db45db0da254c7eaa5ac469d8bdc85",
    "decimals": 18,
    "image": "https://rango.vip/i/pEme3l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00437966,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIRAI",
    "name": "MIRAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42c83a91b3a79de5488cd9280a4df564e13a79ee",
    "decimals": 18,
    "image": "https://rango.vip/i/RtLfDw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00184608,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RANKER",
    "name": "RankerDao",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6431fa4b812a2dcc062a38cb55cc7d18135adead",
    "decimals": 18,
    "image": "https://rango.vip/i/1Z0rIL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00030598,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPC",
    "name": "SpaceChainV2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x86ed939b500e121c0c5f493f399084db596dad20",
    "decimals": 18,
    "image": "https://rango.vip/i/31VVVP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.018252,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MVX",
    "name": "Metavault Trade",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7",
    "decimals": 18,
    "image": "https://rango.vip/i/sdax6i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.619049,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "MVX",
    "name": "Metavault Trade",
    "isPopular": false,
    "chainId": "324",
    "address": "0xc8ac6191cdc9c7bf846ad6b52aaaa7a0757ee305",
    "decimals": 18,
    "image": "https://rango.vip/i/OHwWiH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.619049,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FUNGI",
    "name": "Fungi",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7d9ce55d54ff3feddb611fc63ff63ec01f26d15f",
    "decimals": 9,
    "image": "https://rango.vip/i/Pxmv9X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00350579,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZZ",
    "name": "ZigZag",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc91a71a1ffa3d8b22ba615ba1b9c01b2bbbf55ad",
    "decimals": 18,
    "image": "https://rango.vip/i/RgopsW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00326502,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ZZ",
    "name": "ZigZag",
    "isPopular": false,
    "chainId": "324",
    "address": "0x1ab721f531cab4c87d536be8b985eafce17f0184",
    "decimals": 18,
    "image": "https://rango.vip/i/O3ahav",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.003312065285888649,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WYNN",
    "name": "Anita Max Wynn",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4vqYQTjmKjxrWGtbL2tVkbAU1EVAz9JwcYtd2VE3PbVU",
    "decimals": 6,
    "image": "https://rango.vip/i/1jeZW6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00088465,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NST",
    "name": "Ninja Squad Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x88a269df8fe7f53e590c561954c52fccc8ec0cfb",
    "decimals": 18,
    "image": "https://rango.vip/i/eHFobh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.95,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NST",
    "name": "Ninja Squad Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70bef3bb2f001da2fddb207dae696cd9faff3f5d",
    "decimals": 18,
    "image": "https://rango.vip/i/KXmBCk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.95,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GUUFY",
    "name": "GUUFY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "inLbkByUvP9WGsLfMy1w9QX5pvaab5mkvLcz48DbkmF",
    "decimals": 9,
    "image": "https://rango.vip/i/GgTXpl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002516,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AMC",
    "name": "AMC",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfdc944fb59201fb163596ee5e209ebc8fa4dcdc5",
    "decimals": 18,
    "image": "https://rango.vip/i/F6Xjes",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00002368,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AMC",
    "name": "AMC",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9jaZhJM6nMHTo4hY9DGabQ1HNuUWhJtm7js1fmKMVpkN",
    "decimals": 9,
    "image": "https://rango.vip/i/8vSLbN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00084586,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMC",
    "name": "AMC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x426a688ee72811773eb64f5717a32981b56f10c1",
    "decimals": 18,
    "image": "https://rango.vip/i/Vd5Nd5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.807e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WEWE",
    "name": "WEWECOIN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G3Mu6gYiqeEH5vz3qChGB8CV4sW4oUxAqRM67nUVXH1H",
    "decimals": 8,
    "image": "https://rango.vip/i/xuW9sH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008455658410235346,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WEWE",
    "name": "WEWECOIN",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6b9bb36519538e0c073894e964e90172e1c0b41f",
    "decimals": 18,
    "image": "https://rango.vip/i/CT4XzS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00008322,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFM",
    "name": "NFMart",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9df03fba103491fffde4fbc5fea15efaa43c67a5",
    "decimals": 18,
    "image": "https://rango.vip/i/7f2l1I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000129,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GOME",
    "name": "GAME OF MEMES",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8ULCkCTUa3XXrNXaDVzPcja2tdJtRdxRr8T4eZjVKqk",
    "decimals": 6,
    "image": "https://rango.vip/i/UziQWS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00084745,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NKCLC",
    "name": "NKCL Classic",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1b624f07a4d9b3e3746e33cb58f42df079b5444",
    "decimals": 18,
    "image": "https://rango.vip/i/ZKwDZM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.28,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BANX",
    "name": "Banx",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BANXbTpN8U2cU41FjPxe2Ti37PiT5cCxLUKDQZuJeMMR",
    "decimals": 9,
    "image": "https://rango.vip/i/YdfRXi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00016129,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SLNV2",
    "name": "SLNV2",
    "isPopular": false,
    "chainId": "128",
    "address": "0x4e252342cf35ff02c4cca9bc655129f5b4a2f901",
    "decimals": 18,
    "image": "https://rango.vip/i/BElYCy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00013073992443253627,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GNX",
    "name": "Genaro Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6ec8a24cabdc339a06a172f8223ea557055adaa5",
    "decimals": 9,
    "image": "https://rango.vip/i/azGgot",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00038923,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "IXT",
    "name": "PlanetIX",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe06bd4f5aac8d0aa337d13ec88db6defc6eaeefe",
    "decimals": 18,
    "image": "https://rango.vip/i/lBxdQF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.101095,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IXT",
    "name": "iXledger",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfca47962d45adfdfd1ab2d972315db4ce7ccf094",
    "decimals": 8,
    "image": "https://rango.vip/i/6V4UYB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00166791,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GOHM",
    "name": "Governance OHM",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x321e7092a180bb43555132ec53aaa65a5bf84251",
    "decimals": 18,
    "image": "https://rango.vip/i/ckL5vZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 3827.48,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GOHM",
    "name": "Governance OHM",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8d9ba570d6cb60c7e3e0f31343efe75ab8e65fb1",
    "decimals": 18,
    "image": "https://rango.vip/i/nrgYiB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 3827.48,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GOHM",
    "name": "Governance OHM",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd8ca34fd379d9ca3c6ee3b3905678320f5b45195",
    "decimals": 18,
    "image": "https://rango.vip/i/Fe22Q0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 3827.48,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOHM",
    "name": "Governance OHM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ab87046fbb341d058f17cbc4c1133f25a20a52f",
    "decimals": 18,
    "image": "https://rango.vip/i/T45JRr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3827.48,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNG",
    "name": "Changer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5c1d9aa868a30795f92fae903edc9eff269044bf",
    "decimals": 18,
    "image": "https://rango.vip/i/C0D35Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00836791,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "CNG",
    "name": "Changer",
    "isPopular": false,
    "chainId": "10",
    "address": "0x1d015bf7d8a6fb0fc30959f3e12355530086b2a5",
    "decimals": 18,
    "image": "https://rango.vip/i/ZVAfCN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 3.3500426065226283,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GIGACHAD",
    "name": "GigaChad",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf43f21384d03b5cbbddd58d2de64071e4ce76ab0",
    "decimals": 18,
    "image": "https://rango.vip/i/2GLKcS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.57889e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVXFXS",
    "name": "Convex FXS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfeef77d3f69374f66429c91d732a244f074bdf74",
    "decimals": 18,
    "image": "https://rango.vip/i/JuYAOI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "KRIDA",
    "name": "Krida Fans",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3c5a5885f6ee4acc2597069fe3c19f49c6efba96",
    "decimals": 18,
    "image": "https://rango.vip/i/LPs1AL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0063243,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "METANO",
    "name": "Metano",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d9e399e5385e2b9a58d4f775a1e16441b571afb",
    "decimals": 18,
    "image": "https://rango.vip/i/VsQkNH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016414,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AVI",
    "name": "Aviator",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2bdaaf2b9cc6981fd273dcb7c04023bfbe0a7fe",
    "decimals": 18,
    "image": "https://rango.vip/i/YaawfY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00210162,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DINU",
    "name": "Dogey-Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb1ee07d6c7baeb702949904080eb61f5d5e7732",
    "decimals": 18,
    "image": "https://rango.vip/i/YhrtfK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.03999e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EARLY",
    "name": "EarlyFans",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7135b32e9903bdb4e19a8b1d22fc2038964b8451",
    "decimals": 18,
    "image": "https://rango.vip/i/fEsczv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00078916,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "EARLY",
    "name": "EarlyFans",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x7135b32e9903bdb4e19a8b1d22fc2038964b8451",
    "decimals": 18,
    "image": "https://rango.vip/i/fEsczv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00078916,
    "supportedSwappers": [
      "ThrusterV3",
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CHOMP",
    "name": "ChompCoin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xebff2db643cf955247339c8c6bcd8406308ca437",
    "decimals": 18,
    "image": "https://rango.vip/i/EKkRVo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.066994,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FORWARD",
    "name": "Forward",
    "isPopular": false,
    "chainId": "1",
    "address": "0x01824357d7d7eaf4677bc17786abd26cbdec9ad7",
    "decimals": 18,
    "image": "https://rango.vip/i/bMFtuJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00109474,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONI",
    "name": "Anonify",
    "isPopular": false,
    "chainId": "1",
    "address": "0x378878af3228d23891de306cb2b123d837d0dcf1",
    "decimals": 18,
    "image": "https://rango.vip/i/EeKnyJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00542444,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONI",
    "name": "Onigiri",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7777cec341e7434126864195adef9b05dcc3489c",
    "decimals": 18,
    "image": "https://rango.vip/i/gs8cS4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.73044e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TALES",
    "name": "Tales of Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16950673c9817537e7cda10b482b90c0584c9101",
    "decimals": 18,
    "image": "https://rango.vip/i/2vgIsk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.15259e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TPRO",
    "name": "TPRO Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3540abe4f288b280a0740ad5121aec337c404d15",
    "decimals": 18,
    "image": "https://rango.vip/i/qRH2hz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00802757,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAGA",
    "name": "Gaga  Pepe ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb29dc1703facd2967bb8ade2e392385644c6dca9",
    "decimals": 18,
    "image": "https://rango.vip/i/3t515j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.7162e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAGATRUMP",
    "name": "MAGA Trump",
    "isPopular": false,
    "chainId": "1",
    "address": "0x225e5b78f289c6d7d7757ad2b9d23b6ab31a5eea",
    "decimals": 18,
    "image": "https://rango.vip/i/GDUGcI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027982,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DXN",
    "name": "DBXen",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80f0c1c49891dcfdd40b6e0f960f84e6042bcb6f",
    "decimals": 18,
    "image": "https://rango.vip/i/72aTpB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.653142,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BARS",
    "name": "Banksters",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5189688ac92a1eba1710bcba94ab25c695a4dfa2",
    "decimals": 6,
    "image": "https://rango.vip/i/SLNEVJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00489144,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BARS",
    "name": "Silver Standard",
    "isPopular": false,
    "chainId": "1",
    "address": "0x777b6d4730a8a890dc64bf202514ce03ab001c02",
    "decimals": 18,
    "image": "https://rango.vip/i/HgTjv6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.116955,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GBTC",
    "name": "Green Bitcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc9cb148ecb70876db0abeb92f515a5e1dc9f580",
    "decimals": 18,
    "image": "https://rango.vip/i/1xPVNO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.113801,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEND",
    "name": "EthLend",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80fb784b7ed66730e8b1dbd9820afd29931aab03",
    "decimals": 18,
    "image": "https://rango.vip/i/taGDir",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.058863,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LEND",
    "name": "EthLend Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x313d009888329c9d1cf4f75ca3f32566335bd604",
    "decimals": 18,
    "image": "https://rango.vip/i/FhI81X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03770713329158278,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COLLE",
    "name": "Colle AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc36983d3d9d379ddfb306dfb919099cb6730e355",
    "decimals": 18,
    "image": "https://rango.vip/i/0Z5tZN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00439685,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "1SOL",
    "name": "1sol.io (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4ThReWAbAVZjNVgs5Ui9Pk3cZ5TYaD9u6Y89fp6EFzoF",
    "decimals": 8,
    "image": "https://rango.vip/i/oR46kG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.007132344535705053,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "1SOL",
    "name": "1Sol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x009178997aff09a67d4caccfeb897fb79d036214",
    "decimals": 18,
    "image": "https://rango.vip/i/900sV1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00713108,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PKN",
    "name": "Poken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf09a216fac5adc3e640db418c0b956076509503",
    "decimals": 18,
    "image": "https://rango.vip/i/t4QVMU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033432,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XLS",
    "name": "ELIS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c862f803ff42a97d4a483ab761256ad8c90f4f8",
    "decimals": 18,
    "image": "https://rango.vip/i/LFUmCB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.099992,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BSPT",
    "name": "Blocksport",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa350da05405cc204e551c4eed19c3039646528d5",
    "decimals": 18,
    "image": "https://rango.vip/i/qlVLeR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024039,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OX",
    "name": "OX Coin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xba0dda8762c24da9487f5fa026a9b64b695a07ea",
    "decimals": 18,
    "image": "https://rango.vip/i/8lq6eM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00319847,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "OX",
    "name": "OX Coin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xba0dda8762c24da9487f5fa026a9b64b695a07ea",
    "decimals": 18,
    "image": "https://rango.vip/i/pWKmNy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00319847,
    "supportedSwappers": [
      "SushiBase",
      "OneInchBase"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "OX",
    "name": "OX Coin",
    "isPopular": false,
    "chainId": "137",
    "address": "0xba0dda8762c24da9487f5fa026a9b64b695a07ea",
    "decimals": 18,
    "image": "https://rango.vip/i/FIxuiR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00319847,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OX",
    "name": "Open Exchange Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x78a0a62fba6fb21a83fe8a3433d44c73a4017a6f",
    "decimals": 18,
    "image": "https://rango.vip/i/T6sdOv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00452189,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OX",
    "name": "OX Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba0dda8762c24da9487f5fa026a9b64b695a07ea",
    "decimals": 18,
    "image": "https://rango.vip/i/FIxuiR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00319847,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BUBBA",
    "name": "BUBBA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BMt3pq4g8ggWWBnd6DJ1jhVyTkHfWjAfJwWW6sRCbQJv",
    "decimals": 9,
    "image": "https://rango.vip/i/NgXK8d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00017242,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RING",
    "name": "Darwinia Network",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9e523234d36973f9e38642886197d023c88e307e",
    "decimals": 18,
    "image": "https://rango.vip/i/v9gpmW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00175065,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RING",
    "name": "RING",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7SEsxCsiNiYqCpYG16wx4c9u2YGLZphnEFTAU9ENAizD",
    "decimals": 9,
    "image": "https://rango.vip/i/PDuk0A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.001743193895352805,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RING",
    "name": "Darwinia Network Native Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9469d013805bffb7d3debe5e7839237e535ec483",
    "decimals": 18,
    "image": "https://rango.vip/i/drhQW3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00175065,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RING",
    "name": "Ring AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc092a137df3cf2b9e5971ba1874d26487c12626d",
    "decimals": 18,
    "image": "https://rango.vip/i/QBsWiH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03836865,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "RING",
    "name": "RING",
    "isPopular": false,
    "chainId": "128",
    "address": "0x15e65456310ecb216b51efbd8a1dbf753353dcf9",
    "decimals": 18,
    "image": "https://rango.vip/i/3PzW0I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.004564915007238732,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VRO",
    "name": "VeraOne",
    "isPopular": false,
    "chainId": "1",
    "address": "0x10bc518c32fbae5e38ecb50a612160571bd81e44",
    "decimals": 8,
    "image": "https://rango.vip/i/eH6vDd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 76.8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRISMA",
    "name": "Prisma Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda47862a83dac0c112ba89c6abc2159b95afd71c",
    "decimals": 18,
    "image": "https://rango.vip/i/ZX1Yr4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02221044,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SBR",
    "name": "Saber Protocol Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1",
    "decimals": 6,
    "image": "https://rango.vip/i/VpJduA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00153851,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MMC",
    "name": "MoveMoveCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88ee7a3537667958d040216d9dc1752d1274d838",
    "decimals": 6,
    "image": "https://rango.vip/i/6aZxYH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00046518,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USPEPE",
    "name": "American Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07040971246a73ebda9cf29ea1306bb47c7c4e76",
    "decimals": 9,
    "image": "https://rango.vip/i/gF5sKD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.01106e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGT",
    "name": "Antfarm Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0bf43350076f95e0d16120b4d6bdfa1c9d50bdbd",
    "decimals": 18,
    "image": "https://rango.vip/i/YqwlEu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01954443,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHP",
    "name": "Crypto Health Plus",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DHVUoxNqv3D7EgktBxUsxFF2Wx83hVDmD2wBBpUaw3jn",
    "decimals": 9,
    "image": "https://rango.vip/i/bU8gVm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.057822956720023744,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CHP",
    "name": "CoinPoker Chips (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x59b5654a17ac44f3068b3882f298881433bb07ef",
    "decimals": 18,
    "image": "https://rango.vip/i/EWXt1K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00020409,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHP",
    "name": "CoinPoker Chips",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a6e18fb2842855c3af925310b0f50a4bfa17909",
    "decimals": 18,
    "image": "https://rango.vip/i/bZ5fHQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020409,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIGI",
    "name": "Digipolis",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41aa9df60c41b07bf4f4cc1b8b3e6c9e25669a9e",
    "decimals": 9,
    "image": "https://rango.vip/i/M8gAPU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002047,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SAMA",
    "name": "Moonsama",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x63228048121877a9e0f52020834a135074e8207c",
    "decimals": 18,
    "image": "https://rango.vip/i/55H8TU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00513104,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RDDT",
    "name": "Rddt",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9edbe853ddccb4baaf49201be6c39ee1816e120",
    "decimals": 18,
    "image": "https://rango.vip/i/Ehec9V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00187557,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HEM",
    "name": "HEM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd0054b65b683dbdd324b51f5f1f16aadeb99a74b",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00046601,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RCG",
    "name": "Recharge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe74be071f3b62f6a4ac23ca68e5e2a39797a3c30",
    "decimals": 18,
    "image": "https://rango.vip/i/8ohwE9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00429267,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HMT",
    "name": "HUMAN Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1ba9bac957322d6e8c07a160a3a8da11a0d2867",
    "decimals": 18,
    "image": "https://rango.vip/i/9a9rPl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03785017,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BPT",
    "name": "Bold Point Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x1111111111182587795ef1098ac7da81a108c97a",
    "decimals": 18,
    "image": "https://rango.vip/i/6GHxfS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00002342276598712434,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BPT",
    "name": "BlackPool Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ec9f76202a7061eb9b3a7d6b59d36215a7e37da",
    "decimals": 18,
    "image": "https://rango.vip/i/CZm47p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03819435,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARTEM",
    "name": "Artem",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b83f827928abdf18cf1f7e67053572b9bceff3a",
    "decimals": 18,
    "image": "https://rango.vip/i/5or2hU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00372283,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SHOG",
    "name": "SHOG",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6a4f69da1e2fb2a9b11d1aad60d03163fe567732",
    "decimals": 18,
    "image": "https://rango.vip/i/8GsKbV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00677726,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHOG",
    "name": "SHOG",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc8388e437031b09b2c61fc4277469091382a1b13",
    "decimals": 18,
    "image": "https://rango.vip/i/8GsKbV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00677726,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MK",
    "name": "Meme Kombat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9fdfb933ee990955d3219d4f892fd1f786b47c9b",
    "decimals": 18,
    "image": "https://rango.vip/i/5Oy0RJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.067206,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DYP",
    "name": "Dypius",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17",
    "decimals": 18,
    "image": "https://rango.vip/i/CEHIeZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.208745,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DYP",
    "name": "DeFiYieldProtocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17",
    "decimals": 18,
    "image": "https://rango.vip/i/0UuKuI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.208745,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DYP",
    "name": "Dypius",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39b46b212bdf15b42b166779b9d1787a68b9d0c3",
    "decimals": 18,
    "image": "https://rango.vip/i/DGxznk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02300045,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNDEAD",
    "name": "Undead Blocks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x310c8f00b9de3c31ab95ea68feb6c877538f7947",
    "decimals": 18,
    "image": "https://rango.vip/i/hhjYde",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.070285,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "EXTRA",
    "name": "Extra Finance",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x2dad3a13ef0c6366220f989157009e501e7938f8",
    "decimals": 18,
    "image": "https://rango.vip/i/bvWWcX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.066636,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DRC",
    "name": "Dracula Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb78b3320493a4efaa1028130c5ba26f0b6085ef8",
    "decimals": 18,
    "image": "https://rango.vip/i/jllm4C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014319685725699188,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DRC",
    "name": "Digital Reserve Currency",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa150db9b1fa65b44799d4dd949d922c0a33ee606",
    "decimals": 0,
    "image": "https://rango.vip/i/vLe1yn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013378,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNIBBU",
    "name": "Snibbu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaee9ba9ce49fe810417a36408e34d9962b653e78",
    "decimals": 9,
    "image": "https://rango.vip/i/Z2qXFY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0014856,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOOM",
    "name": "Bomb Shelter Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c73c1c8c95de5674d53604b15d968485414cb32",
    "decimals": 18,
    "image": "https://rango.vip/i/WtdNU2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009755,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOOM",
    "name": "BOOM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda8a1f5eccabc80c26ec9ab493715d5b9ce8fef9",
    "decimals": 18,
    "image": "https://rango.vip/i/wEfhs4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.301616,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LFNTY",
    "name": "Lifinity",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp",
    "decimals": 6,
    "image": "https://rango.vip/i/UfvX93",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.934109,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "JKL",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/8e697bdabe97ace8773c6df7402b2d1d5104dd1eeabe12608e3469b7f64c15ba",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/JKL.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.147109,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "DAR",
    "name": "Mines of Dalarnia",
    "isPopular": false,
    "chainId": "25",
    "address": "0x5893915fe3d15e4004f7232c80036bfa92aca564",
    "decimals": 6,
    "image": "https://rango.vip/i/PcIJ1T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.13277227337349584,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAR",
    "name": "Dalarnia",
    "isPopular": false,
    "chainId": "1",
    "address": "0x081131434f93063751813c619ecca9c4dc7862a3",
    "decimals": 6,
    "image": "https://rango.vip/i/DVcjOo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.129895,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DAR",
    "name": "Mines of Dalarnia",
    "isPopular": false,
    "chainId": "56",
    "address": "0x23ce9e926048273ef83be0a3a8ba9cb6d45cd978",
    "decimals": 6,
    "image": "https://rango.vip/i/jCbpCh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.129895,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAR",
    "name": "Digital Asset Rights Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1db11e86fa9b9a87813a4dd3f747eef12ed55a55",
    "decimals": 18,
    "image": "https://rango.vip/i/IohUUP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020604,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VSG",
    "name": "Vitalik Smart Gas",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a5fa016ffb20c70e2ef36058c08547f344677aa",
    "decimals": 18,
    "image": "https://rango.vip/i/CYs6xA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000713,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IX",
    "name": "illumineX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x45e82579792dddf08cb3a037086604c262d78065",
    "decimals": 18,
    "image": "https://rango.vip/i/wmK2sA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.129092,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "SWAY",
    "name": "Standard Weighted Adalian Yield",
    "isPopular": false,
    "chainId": "SN_MAIN",
    "address": "0x4878d1148318a31829523ee9c6a5ee563af6cd87f90a30809e5b0d27db8a9b",
    "decimals": 6,
    "image": "https://rango.vip/i/U6eeLR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 0.0019337353442254986,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AABL",
    "name": "Abble",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ENoD8J2J6wNHkcJkvVBkwq5JMiR1oNBfBZRkoHCQogyT",
    "decimals": 6,
    "image": "https://rango.vip/i/1pig3n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00096655,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "HOP",
    "name": "Hop Protocol",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc",
    "decimals": 18,
    "image": "https://rango.vip/i/ZeXQ4T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.01852106,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HOP",
    "name": "Hop Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc",
    "decimals": 18,
    "image": "https://rango.vip/i/ZeXQ4T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01852106,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOP",
    "name": "Hop",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc",
    "decimals": 18,
    "image": "https://rango.vip/i/xhjl09",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01852106,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "HOP",
    "name": "Hop",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc",
    "decimals": 18,
    "image": "https://rango.vip/i/eoEl4z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01852106,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TFS",
    "name": "Fairspin Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf4bea2c219eb95c6745983b68185c7340c319d9e",
    "decimals": 18,
    "image": "https://rango.vip/i/PYwRvG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.004602923289203862,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ANGLE",
    "name": "ANGLE",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xe6b9d092223f39013656702a40dbe6b7decc5746",
    "decimals": 18,
    "image": "https://rango.vip/i/oe6XPV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.019640118686802155,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANGLE",
    "name": "ANGLE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31429d1856ad1377a8a0079410b297e1a9e214c2",
    "decimals": 18,
    "image": "https://rango.vip/i/u4Eu5o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01965439,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COVN",
    "name": "Covenant",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19ac2659599fd01c853de846919544276ad26f50",
    "decimals": 18,
    "image": "https://rango.vip/i/9lzSks",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.094966,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "SPACE",
    "name": "SPACE",
    "isPopular": false,
    "chainId": "324",
    "address": "0x47260090ce5e83454d5f05a0abbb2c953835f777",
    "decimals": 18,
    "image": "https://rango.vip/i/xEethZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.01516034,
    "supportedSwappers": [
      "SpaceFi",
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SPACE",
    "name": "Space Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x1d1498166ddceee616a6d99868e1e0677300056f",
    "decimals": 18,
    "image": "https://rango.vip/i/I2Hst2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00855394,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SPACE",
    "name": "Space Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1d1498166ddceee616a6d99868e1e0677300056f",
    "decimals": 18,
    "image": "https://rango.vip/i/I2Hst2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00855394,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPACE",
    "name": "Spacelens",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcc7ab8d78dba187dc95bf3bb86e65e0c26d0041f",
    "decimals": 18,
    "image": "https://rango.vip/i/fUBRVQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00206922,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KNDX",
    "name": "Kondux",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ca5af5ba3472af6049f63c1abc324475d44efc1",
    "decimals": 9,
    "image": "https://rango.vip/i/JpQc4V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02523146,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAY",
    "name": "HayCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa3e941d1f6b7b10ed84a0c211bfa8aee907965e",
    "decimals": 18,
    "image": "https://rango.vip/i/ZViToF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 101281,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HNB",
    "name": "HNB Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e0615a03ed9527a6013fcd5b556e36ef4dab1ff",
    "decimals": 18,
    "image": "https://rango.vip/i/lSgU2z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00692683,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGE20",
    "name": "Doge 2 0",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf2ec4a773ef90c58d98ea734c0ebdb538519b988",
    "decimals": 9,
    "image": "https://rango.vip/i/52owaZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.493e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGE20",
    "name": "Dogecoin20",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2541a36be4cd39286ed61a3e6afc2307602489d6",
    "decimals": 18,
    "image": "https://rango.vip/i/oT6SU0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPONGE",
    "name": "Sponge  OLD ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x25722cd432d02895d9be45f5deb60fc479c8781e",
    "decimals": 18,
    "image": "https://rango.vip/i/Do4ZF6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003428,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EAVE",
    "name": "EaveAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x974d796e0bea47038f39c3f98b1aa2c5240b5495",
    "decimals": 18,
    "image": "https://rango.vip/i/6l7WFX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.270604,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GP",
    "name": "Graphite",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "31k88G5Mq7ptbRDf3AM13HAq6wRQHXHikR8hik7wPygk",
    "decimals": 9,
    "image": "https://rango.vip/i/etB7AL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.086271,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCP",
    "name": "PieDAO Balanced Crypto Pie",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe4f726adc8e89c6a6017f01eada77865db22da14",
    "decimals": 18,
    "image": "https://rango.vip/i/1brfwg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.017791410997988626,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WASSIE",
    "name": "WASSIE",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xa067436db77ab18b1a315095e4b816791609897c",
    "decimals": 18,
    "image": "https://rango.vip/i/A7IpB4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0000018,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WASSIE",
    "name": "WASSIE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160",
    "decimals": 18,
    "image": "https://rango.vip/i/dn9geT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000018,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LOOP",
    "name": "LoopNetwork",
    "isPopular": false,
    "chainId": "56",
    "address": "0xce186ad6430e2fe494a22c9edbd4c68794a28b35",
    "decimals": 18,
    "image": "https://rango.vip/i/L9pbTV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02538108,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CRYSTAL",
    "name": "Crystal",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd07d35368e04a839dee335e213302b21ef14bb4a",
    "decimals": 18,
    "image": "https://rango.vip/i/WHazKW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 69.63,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "CRYSTAL",
    "name": "Crystal",
    "isPopular": false,
    "chainId": "66",
    "address": "0xbd87c06d865a3717a16041acd8d33922e60e85ce",
    "decimals": 18,
    "image": "https://rango.vip/i/6KWGlg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.0012588733174470888,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGECOIN",
    "name": "RagingElonMarsCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf257a2783f6633a149b5966e32432b5bb3462c96",
    "decimals": 8,
    "image": "https://rango.vip/i/pmGSCx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.8091e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KPOP",
    "name": "K-Pop",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DcUoGUeNTLhhzyrcz49LE7z3MEFwca2N9uSw1xbVi1gm",
    "decimals": 9,
    "image": "https://rango.vip/i/lRcRI4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002542,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KPOP",
    "name": "KPOP Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7b7983967409fce461ea8bbdf9ed37631b1d59c9",
    "decimals": 18,
    "image": "https://rango.vip/i/DAEdID",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004789,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GROYPER",
    "name": "Groyper",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6942806d1b2d5886d95ce2f04314ece8eb825833",
    "decimals": 18,
    "image": "https://rango.vip/i/9BC1iq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01418818,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USSD",
    "name": "Autonomous Secure Dollar",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x33c88d4cac6ac34f77020915a2a88cd0417dc069",
    "decimals": 6,
    "image": "https://rango.vip/i/uLUwwy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.054,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HYDRA",
    "name": "Hydra",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4501a82790ef2587dfeb93dc038541228e516597",
    "decimals": 18,
    "image": "https://rango.vip/i/CpYYrt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000459,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CIG",
    "name": "Cigarette Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb56b52316041a62b6b5d0583dce4a8ae7a3c629",
    "decimals": 18,
    "image": "https://rango.vip/i/2iOnIP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00026033,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "JUNO",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/46b44899322f3cd854d2d46deef881958467cdd4b3b10086da49296bbed94bed",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/JUNO.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0849194914372352,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "JUNO",
    "name": "Juno",
    "isPopular": true,
    "chainId": "juno-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/vG90hZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 0.0849194914372352,
    "supportedSwappers": [
      "WYNDDex",
      "IBC"
    ]
  },
  {
    "blockchain": "UMEE",
    "symbol": "JUNO",
    "name": null,
    "isPopular": false,
    "chainId": "umee-1",
    "address": "ibc/46b44899322f3cd854d2d46deef881958467cdd4b3b10086da49296bbed94bed",
    "decimals": 6,
    "image": "https://rango.vip/i/kTFEDK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/UMEE/icon.svg",
    "usdPrice": 0.0850026226570565,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VEST",
    "name": "DAO Invest",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f19f83fc9a25f3c861260143e36c17706257986",
    "decimals": 18,
    "image": "https://rango.vip/i/EQ9jwH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00242113,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "NOCHILL",
    "name": "AVAX HAS NO CHILL",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xacfb898cff266e53278cc0124fc2c7c94c8cb9a5",
    "decimals": 18,
    "image": "https://rango.vip/i/HJKyy3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00359465,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OBT",
    "name": "Oobit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07f9702ce093db82dfdc92c2c6e578d6ea8d5e22",
    "decimals": 18,
    "image": "https://rango.vip/i/CihnV3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03513143,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FROGLIC",
    "name": "Pink Hood Froglicker",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4abd5745f326932b1b673bfa592a20d7bb6bc455",
    "decimals": 18,
    "image": "https://rango.vip/i/9Z535q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006976,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JFIN",
    "name": "JFIN Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x940bdcb99a0ee5fb008a606778ae87ed9789f257",
    "decimals": 18,
    "image": "https://rango.vip/i/MkWDXy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.144882,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PESHI",
    "name": "PESHI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5LwseQRo8fsz4S3y7jbqqe5C7tZTz5PwhXNCHj13jLBi",
    "decimals": 6,
    "image": "https://rango.vip/i/a14Brt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000122,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MGP",
    "name": "Magpie Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd06716e1ff2e492cc5034c2e81805562dd3b45fa",
    "decimals": 18,
    "image": "https://rango.vip/i/tuhgOm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.060038,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REACH",
    "name": "Reach",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b12bd54ca9b2311960057c8f3c88013e79316e3",
    "decimals": 18,
    "image": "https://rango.vip/i/KbD2hf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01797502214847434,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JOWNES",
    "name": "alux jownes",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8m9fjYycXAFva1kScttQgsESVZT7yELhjZASqfHBuMa5",
    "decimals": 6,
    "image": "https://rango.vip/i/df5Pw9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00034032,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOS",
    "name": "TONStarter",
    "isPopular": false,
    "chainId": "1",
    "address": "0x409c4d8cd5d2924b9bc5509230d16a61289c8153",
    "decimals": 18,
    "image": "https://rango.vip/i/JxVHhI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0006708084515727312,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOS",
    "name": "Cryptos",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68ccaca9adf1552b3316d6067690ec27397c8ea8",
    "decimals": 18,
    "image": "https://rango.vip/i/JynbUl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0006708084515727312,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "USDGLO",
    "name": "Glo Dollar",
    "isPopular": false,
    "chainId": "10",
    "address": "0x4f604735c1cf31399c6e711d5962b2b3e0225ad3",
    "decimals": 18,
    "image": "https://rango.vip/i/HgYBKt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.004,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USDGLO",
    "name": "Glo Dollar",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4f604735c1cf31399c6e711d5962b2b3e0225ad3",
    "decimals": 18,
    "image": "https://rango.vip/i/ngemXd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.004,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "USDGLO",
    "name": "Glo Dollar",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x4f604735c1cf31399c6e711d5962b2b3e0225ad3",
    "decimals": 18,
    "image": "https://rango.vip/i/EnHaEd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 1.004,
    "supportedSwappers": [
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "USDGLO",
    "name": "Glo Dollar",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x4f604735c1cf31399c6e711d5962b2b3e0225ad3",
    "decimals": 18,
    "image": "https://rango.vip/i/zl4daa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.004,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDGLO",
    "name": "Glo Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4f604735c1cf31399c6e711d5962b2b3e0225ad3",
    "decimals": 18,
    "image": "https://rango.vip/i/vn7WEq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.004,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WBX",
    "name": "Wibx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb97e381f1d1e94ffa2a5844f6875e6146981009",
    "decimals": 18,
    "image": "https://rango.vip/i/7Wbxsa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00153487,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CHAMP",
    "name": "NFT Champions",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8f9e8e833a69aa467e42c46cca640da84dd4585f",
    "decimals": 8,
    "image": "https://rango.vip/i/O2Oml0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00156205,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CHAMP",
    "name": "Ultimate Champions Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7e9ab560d37e62883e882474b096643cab234b65",
    "decimals": 18,
    "image": "https://rango.vip/i/GeUcec",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0015623583273356996,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MYRA",
    "name": "Myra",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "H1aN3vcvB68eaFPbMkoAss3vnfi4AhP5C2dpnrZzdBc7",
    "decimals": 9,
    "image": "https://rango.vip/i/15dnEa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00050136,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NITRO",
    "name": "Nitro (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x695fc8b80f344411f34bdbcb4e621aa69ada384b",
    "decimals": 18,
    "image": "https://rango.vip/i/UW0KO5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0006214,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NITRO",
    "name": "Nitro",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0335a7610d817aeca1bebbefbd392ecc2ed587b8",
    "decimals": 18,
    "image": "https://rango.vip/i/PaJ9Sz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0006214,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUA",
    "name": "Brillion",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c249b6f6492864d914361308601a7abb32e68f8",
    "decimals": 18,
    "image": "https://rango.vip/i/W3qDne",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01549418,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OTSEA",
    "name": "OTSea",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5da151b95657e788076d04d56234bd93e409cb09",
    "decimals": 18,
    "image": "https://rango.vip/i/x47uM6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00748249,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GUILD",
    "name": "BlockchainSpace",
    "isPopular": false,
    "chainId": "1",
    "address": "0x83e9f223e1edb3486f876ee888d76bfba26c475a",
    "decimals": 18,
    "image": "https://rango.vip/i/5dIIFV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00248325,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRESTLE",
    "name": "TRESTLE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde8cd13b812bcd82218754a740b27e76ec1e86ad",
    "decimals": 18,
    "image": "https://rango.vip/i/NdkLeL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00101517,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "THALES",
    "name": "Thales",
    "isPopular": false,
    "chainId": "10",
    "address": "0x217d47011b23bb961eb6d93ca9945b7501a5bb11",
    "decimals": 18,
    "image": "https://rango.vip/i/e51Dag",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.176026,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "THALES",
    "name": "Thales",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe85b662fe97e8562f4099d8a1d5a92d4b453bf30",
    "decimals": 18,
    "image": "https://rango.vip/i/DcDr40",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.176026,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "THALES",
    "name": "Thales",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xf34e0cff046e154cafcae502c7541b9e5fd8c249",
    "decimals": 18,
    "image": "https://rango.vip/i/DcDr40",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.176026,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THALES",
    "name": "Thales",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8947da500eb47f82df21143d0c01a29862a8c3c5",
    "decimals": 18,
    "image": "https://rango.vip/i/DcDr40",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.176026,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "GLORY",
    "name": "GLORY",
    "isPopular": false,
    "chainId": "66",
    "address": "0xc3bdfee6186849d5509601045af4af567a001c94",
    "decimals": 18,
    "image": "https://rango.vip/i/HhnBal",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.0001986234816160593,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GLORY",
    "name": "Eternity GLORY Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x078c4adf3fee52eb77f6018d9805dfc69e911d39",
    "decimals": 18,
    "image": "https://rango.vip/i/n0qWrk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00098031,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "GLORY",
    "name": "Sekai Glory",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xd582879453337bd149ae53ec2092b0af5281d1d7",
    "decimals": 18,
    "image": "https://rango.vip/i/BnEKAF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00086085,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XOT",
    "name": "Okuru",
    "isPopular": false,
    "chainId": "1",
    "address": "0x22b48e1f20043d1db5f2a11cbf1d520a4f20b198",
    "decimals": 18,
    "image": "https://rango.vip/i/NQLFrm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.24,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TMNG",
    "name": "Technology Metal Network Global",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d3d07439069c9bbc8d626397cf98cb343ac0a72",
    "decimals": 18,
    "image": "https://rango.vip/i/r8bEwI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01579486,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOPIA",
    "name": "HYCHAIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xccccb68e1a848cbdb5b60a974e07aae143ed40c3",
    "decimals": 18,
    "image": "https://rango.vip/i/RT1Htl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01265557,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEVA",
    "name": "Neva",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ada3bf9a5c5c59523d6193381c0d14787070e54",
    "decimals": 18,
    "image": "https://rango.vip/i/nY592M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0111034,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAUSS",
    "name": "Gauss0x",
    "isPopular": false,
    "chainId": "1",
    "address": "0x622984873c958e00aa0f004cbdd2b5301cf0b132",
    "decimals": 9,
    "image": "https://rango.vip/i/M3Uoap",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00149444,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EDUM",
    "name": "EDUM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac9518ba93eeb2336a03137d254d8cc2e4d0fa38",
    "decimals": 18,
    "image": "https://rango.vip/i/NJKNpy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.548299,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DROPS",
    "name": "Drops",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa562912e1328eea987e04c2650efb5703757850c",
    "decimals": 18,
    "image": "https://rango.vip/i/ksrHBl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.264466,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IZE",
    "name": "Galvan",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf7e945fce8f19302aacc7e1418b0a0bdef89327b",
    "decimals": 8,
    "image": "https://rango.vip/i/CQ7VvD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021231,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AMBO",
    "name": "AMBO",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9b8b6a1298d34b3c4bbddce8a7ff0149121592c1",
    "decimals": 18,
    "image": "https://rango.vip/i/LutYbp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00799128,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOTG",
    "name": "Got Guaranteed",
    "isPopular": false,
    "chainId": "1",
    "address": "0xceeb07dd26b36287b6d109f0b06d7e8202ce8c1d",
    "decimals": 18,
    "image": "https://rango.vip/i/9t9n8r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00718047,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HADES",
    "name": "Hades",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BWXrrYFhT7bMHmNBFoQFWdsSgA3yXoAnMhDK6Fn1eSEn",
    "decimals": 9,
    "image": "https://rango.vip/i/BJSf2V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03485134,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HADES",
    "name": "Hades Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd40c688da9df74e03566eaf0a7c754ed98fbb8cc",
    "decimals": 18,
    "image": "https://rango.vip/i/sy5HC6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00092019,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DIP",
    "name": "doginthpool",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3XxvmED354933DwSPJuzB7SE9uiWpD1ErydDuhmbFRMk",
    "decimals": 6,
    "image": "https://rango.vip/i/ggMKPg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00060334,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIP",
    "name": "Decentralized Insurance Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc719d010b63e5bbf2c0551872cd5316ed26acd83",
    "decimals": 18,
    "image": "https://rango.vip/i/sMQLLE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00983887,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPEN",
    "name": "OPEN Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69e8b9528cabda89fe846c67675b5d73d463a916",
    "decimals": 18,
    "image": "https://rango.vip/i/HlE8px",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001102,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WIT",
    "name": "What in Tarnation?",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Adq3wnAvtaXBNfy63xGV1YNkDiPKadDT469xF9uZPrqE",
    "decimals": 6,
    "image": "https://rango.vip/i/XpaKpM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00066361,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "IQT",
    "name": "IQ Protocol Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x41084fdc569099d9e527ccf126e12d9c7c688ec3",
    "decimals": 18,
    "image": "https://rango.vip/i/6ALd7L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00525564,
    "supportedSwappers": [
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IQT",
    "name": "IQ Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6dda263994aab33f5ed612294e26f2a13df0da05",
    "decimals": 18,
    "image": "https://rango.vip/i/yuMOYA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00525564,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCC",
    "name": "OCC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f109021afe75b949429fe30523ee7c0d5b27207",
    "decimals": 18,
    "image": "https://rango.vip/i/yFFfxu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.075886,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ISME",
    "name": "Root Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x87c22db324b8b0637c8f09d2670ae7777651dbb8",
    "decimals": 18,
    "image": "https://rango.vip/i/gEAI5G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0018609,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTN",
    "name": "Medicalchain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41dbecc1cdc5517c6f76f6a6e836adbee2754de3",
    "decimals": 18,
    "image": "https://rango.vip/i/hKWetY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00060346,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RSTK",
    "name": "Restake Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12ef10a4fc6e1ea44b4ca9508760ff51c647bb71",
    "decimals": 18,
    "image": "https://rango.vip/i/r21IGF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01522123,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SONAR",
    "name": "Sonar Watch",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE",
    "decimals": 9,
    "image": "https://rango.vip/i/h4FG9v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00718927,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SONAR",
    "name": "Sonar Systems",
    "isPopular": false,
    "chainId": "1",
    "address": "0x990204dc24fa22c8d590870a7cc7fbb25e4e35dc",
    "decimals": 9,
    "image": "https://rango.vip/i/kDQafm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00436831,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VPS",
    "name": "VPS Ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00b78238925c320159023c2ac9ef89da8f16d007",
    "decimals": 18,
    "image": "https://rango.vip/i/Xb9JnZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00972999,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOE",
    "name": "MOE Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcba78d126f0b1feda0c538bcaf4c852a7a171099",
    "decimals": 18,
    "image": "https://rango.vip/i/z4HI1Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.3193e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOE",
    "name": "Moe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d3b4f8d5dd14a0c263c4bee7be434c15e188d3e",
    "decimals": 18,
    "image": "https://rango.vip/i/BuStYn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020888,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CV",
    "name": "carVertical",
    "isPopular": false,
    "chainId": "1",
    "address": "0x50bc2ecc0bfdf5666640048038c1aba7b7525683",
    "decimals": 18,
    "image": "https://rango.vip/i/7EnXBo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002426,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BTCPX",
    "name": "BTC Proxy",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9c32185b81766a051e08de671207b34466dd1021",
    "decimals": 8,
    "image": "https://rango.vip/i/F2RnSk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 55830,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTCPX",
    "name": "BTC Proxy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9c32185b81766a051e08de671207b34466dd1021",
    "decimals": 8,
    "image": "https://rango.vip/i/43ESCF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55830,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "OKAYEG",
    "name": "Okayeg",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xdb6e0e5094a25a052ab6845a9f1e486b9a9b3dde",
    "decimals": 18,
    "image": "https://rango.vip/i/fuept4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 9.85478e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DVINCI",
    "name": "Davinci Jeremie",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5jFJCvNgg8ytGWBygoquaUC6bMZyr7C5jmGHECBzrxR5",
    "decimals": 6,
    "image": "https://rango.vip/i/7ADjH3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00016693,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GRELF",
    "name": "Grelf Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x945ca41d03ec19b6a6ebf2ef0f4d0a50b23e4f2c",
    "decimals": 8,
    "image": "https://rango.vip/i/95w6fB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.240139,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SIGN",
    "name": "Sign Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb06b8186cc008a79fd6722b1eefad07c14e97da0",
    "decimals": 18,
    "image": "https://rango.vip/i/MwbIqF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00099668,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MFET",
    "name": "Multi Functional Environmental Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6d23970ce32cb0f1929bece7c56d71319e1b4f01",
    "decimals": 8,
    "image": "https://rango.vip/i/KXi4fs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0009197,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BYTES",
    "name": "BYTES",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa19f5264f7d7be11c451c093d8f92592820bea86",
    "decimals": 18,
    "image": "https://rango.vip/i/akgRyG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.07,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CRODIE",
    "name": "Crodie",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GvcNXdSehfNSNyhDVDj27kc459LzFqWozt9CSJywMy6r",
    "decimals": 9,
    "image": "https://rango.vip/i/nOWh5c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0009418,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NITEFEEDER",
    "name": "Nitefeeder",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85f7cfe910393fb5593c65230622aa597e4223f1",
    "decimals": 9,
    "image": "https://rango.vip/i/MuiMgw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.306e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "XSWAP",
    "name": "XSwap",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8fe815417913a93ea99049fc0718ee1647a2a07c",
    "decimals": 18,
    "image": "https://rango.vip/i/qbjPcp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.03719795,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XSWAP",
    "name": "XSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8fe815417913a93ea99049fc0718ee1647a2a07c",
    "decimals": 18,
    "image": "https://rango.vip/i/X0PFNU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03719795,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DV",
    "name": "Dreamverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a03a891add2dc6d0f7b94419086630ba5cb65b6",
    "decimals": 18,
    "image": "https://rango.vip/i/dQEzhm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00085393,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONKAS",
    "name": "Monkas",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc7ac5d5d4a9c3b5d8f3183058a92776dc12f4f3",
    "decimals": 9,
    "image": "https://rango.vip/i/zQljqD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.70095e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PANO",
    "name": "PanoVerse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3850952491606a0e420eb929b1a2e1a450d013f1",
    "decimals": 18,
    "image": "https://rango.vip/i/qJoPVk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.055702,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CMCX",
    "name": "CORE MultiChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5b685863494c33f344081f75e5430c260c224a32",
    "decimals": 18,
    "image": "https://rango.vip/i/95mAol",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000177,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LARIX",
    "name": "Larix",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC",
    "decimals": 6,
    "image": "https://rango.vip/i/Ugr4jK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00016441,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WIK",
    "name": "Jon Wik",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "wikkR42C9DC4KukAQXcZESnYcRcrQXPEYULk1qpcgeK",
    "decimals": 9,
    "image": "https://rango.vip/i/McNWeB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03525648072952786,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WIK",
    "name": "WickedBet Casino",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa9049425b938c46ac3e312d4cdaeccb26282aeb2",
    "decimals": 18,
    "image": "https://rango.vip/i/EGgvDZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03522397,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LIGMA",
    "name": "LIGMA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LigMAx75tZtD9NyxF3VwYQuEsf4uWPxoHgXkeiA2TPu",
    "decimals": 8,
    "image": "https://rango.vip/i/zgQeoi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.003366785833315712,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GUARD",
    "name": "Guardian",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf606bd19b1e61574ed625d9ea96c841d4e247a32",
    "decimals": 18,
    "image": "https://rango.vip/i/kLvpuY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.167394,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOOL",
    "name": "Wolf Game Wool",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8355dbe8b0e275abad27eb843f3eaf3fc855e525",
    "decimals": 18,
    "image": "https://rango.vip/i/uCpKo0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00243804,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "WOOL",
    "name": "Wolf Game Wool",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xb5a86030b64afaa75c42c0d28f8d5ce5f9f61401",
    "decimals": 18,
    "image": "https://rango.vip/i/uCpKo0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00243804,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SPDR",
    "name": "SpiderSwap",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AT79ReYU9XtHUTF5vM6Q4oa9K8w7918Fp5SU7G1MDMQY",
    "decimals": 9,
    "image": "https://rango.vip/i/MFOoC2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01167085,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BFT",
    "name": "BF Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x01ff50f8b7f74e4f00580d9596cd3d0d6d6e326f",
    "decimals": 18,
    "image": "https://rango.vip/i/tfVKLv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00149837,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOLAV",
    "name": "SOLAV",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc5e4ed56153b57aa8ef769eba3e79e58e19be93",
    "decimals": 18,
    "image": "https://rango.vip/i/8WWuCi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009697,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIX",
    "name": "AIgentX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40e9187078032afe1a30cfcf76e4fe3d7ab5c6c5",
    "decimals": 18,
    "image": "https://rango.vip/i/SzvQA5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03310118,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VCAT",
    "name": "Vibing Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "VP9UaBXLM4KYRvyjisu65rz8BU5xNAbewU7LVmyU2x4",
    "decimals": 9,
    "image": "https://rango.vip/i/9NMTdr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.889055,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASIA",
    "name": "Asia Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf519381791c03dd7666c142d4e49fd94d3536011",
    "decimals": 18,
    "image": "https://rango.vip/i/NrbO8h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.085814,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGII",
    "name": "AGII",
    "isPopular": false,
    "chainId": "1",
    "address": "0x75d86078625d1e2f612de2627d34c7bc411c18b8",
    "decimals": 18,
    "image": "https://rango.vip/i/L2OOvl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009623,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MUZKI",
    "name": "MUZKI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "C1kzNkFfgdtP8VF1pFYA4S32RLPqk5KPaurCaQJwxfWb",
    "decimals": 6,
    "image": "https://rango.vip/i/FYt6NI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00018666,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "THX",
    "name": "THX Network (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2934b36ca9a4b31e633c5be670c8c8b28b6aa015",
    "decimals": 18,
    "image": "https://rango.vip/i/NN7fKn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00719096,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THX",
    "name": "THX Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe632ea2ef2cfd8fc4a2731c76f99078aef6a4b31",
    "decimals": 18,
    "image": "https://rango.vip/i/SBmCNl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00719096,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USX",
    "name": "dForce USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a5e677a6a24b2f1a2bf4f3bffc443231d2fdec8",
    "decimals": 18,
    "image": "https://rango.vip/i/zg9Jo5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.989515,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USX",
    "name": "dForce USD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xcd14c3a2ba27819b352aae73414a26e2b366dc50",
    "decimals": 18,
    "image": "https://rango.vip/i/zg9Jo5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.9985364602699216,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USX",
    "name": "dForce USD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x641441c631e2f909700d2f41fd87f0aa6a6b4edb",
    "decimals": 18,
    "image": "https://rango.vip/i/EfuWh0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.989515,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "USX",
    "name": "dForce USD",
    "isPopular": false,
    "chainId": "10",
    "address": "0xbfd291da8a403daaf7e5e9dc1ec0aceacd4848b9",
    "decimals": 18,
    "image": "https://rango.vip/i/Tb5xre",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.989515,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CXO",
    "name": "CargoX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb6ee9668771a79be7967ee29a63d4184f8097143",
    "decimals": 18,
    "image": "https://rango.vip/i/3b1mzi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.228086,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MPRO",
    "name": "Metapro",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd88611a629265c9af294ffdd2e7fa4546612273e",
    "decimals": 18,
    "image": "https://rango.vip/i/2KBtLb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00706673,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOVU",
    "name": "DOVU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2aeabde1ab736c59e9a19bed67681869eef39526",
    "decimals": 8,
    "image": "https://rango.vip/i/62fnFq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00047984,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MYT",
    "name": "MYSO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5fde99e121f3ac02e7d6acb081db1f89c1e93c17",
    "decimals": 18,
    "image": "https://rango.vip/i/NBa5ei",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.10337,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STIMA",
    "name": "STIMA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2e5decc08a80be6538f89f9ab8ff296e2f724df",
    "decimals": 6,
    "image": "https://rango.vip/i/0Kh6gb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KOIN",
    "name": "Kittekoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7b0df1cd724ec34ec9bc4bd19749b01afb490761",
    "decimals": 9,
    "image": "https://rango.vip/i/EqIUIr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.111625,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KOIN",
    "name": "Koinos",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed11c9bcf69fdd2eefd9fe751bfca32f171d53ae",
    "decimals": 8,
    "image": "https://rango.vip/i/Tq6vz5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.182289,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ASCN",
    "name": "AlphaScan AI",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1b8d516e2146d7a32aca0fcbf9482db85fd42c3a",
    "decimals": 18,
    "image": "https://rango.vip/i/zHvAAR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02368086,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASCN",
    "name": "AlphaScan AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x378e97d19cf319eb311748ff4d9971dc349c8ad4",
    "decimals": 18,
    "image": "https://rango.vip/i/zHvAAR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02368086,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASAN",
    "name": "ASAN VERSE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f1f11a3dd7a0c39da1baa3c7b8585b52a77f435",
    "decimals": 18,
    "image": "https://rango.vip/i/wsXJwC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003109,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOBL",
    "name": "NobleBlocks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88b9f5c66342ebaf661b3e2836b807c8cb1b3195",
    "decimals": 18,
    "image": "https://rango.vip/i/HuI7Yy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00490069,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPCT",
    "name": "Spectra Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x02020595e6a34a03a8e9c1f5624b1b7713810083",
    "decimals": 18,
    "image": "https://rango.vip/i/hmhqHy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00044931,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASM",
    "name": "Assemble Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2565ae0385659badcada1031db704442e1b69982",
    "decimals": 18,
    "image": "https://rango.vip/i/GZ81PW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01702764,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLXM",
    "name": "bloXmove",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38d9eb07a7b8df7d86f440a4a5c4a4c1a27e1a08",
    "decimals": 18,
    "image": "https://rango.vip/i/SW85xm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02579734,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RBIF",
    "name": "Robo Inu Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b",
    "decimals": 9,
    "image": "https://rango.vip/i/AjwH88",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.4671e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CHAIN",
    "name": "Chain Games",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd55fce7cdab84d84f2ef3f99816d765a2a94a509",
    "decimals": 18,
    "image": "https://rango.vip/i/bovhV7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02015223,
    "supportedSwappers": [
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHAIN",
    "name": "Chain Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4c2614e694cf534d407ee49f8e44d125e4681c4",
    "decimals": 18,
    "image": "https://rango.vip/i/chJtIG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02015223,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TON",
    "name": "Tokamak Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2be5e8c109e2197d077d13a82daead6a9b3433c5",
    "decimals": 18,
    "image": "https://rango.vip/i/ZiAz1s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.947409,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TON",
    "name": "TONToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a6c2ada3ce053561c2fbc3ee211f23d9b8c520a",
    "decimals": 18,
    "image": "https://rango.vip/i/YKGnIv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02237988,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XERS",
    "name": "X Project ERC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c10d1611a5a95cb967e4bcab5791fd101194949",
    "decimals": 18,
    "image": "https://rango.vip/i/wePZUw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00042989,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ERN",
    "name": "@EthernityChain $ERN Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbbc2ae13b23d715c30720f079fcd9b4a74093505",
    "decimals": 18,
    "image": "https://rango.vip/i/whJZmt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.99,
    "supportedSwappers": [
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ERN",
    "name": "Ethos Reserve Note",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc5b001dc33727f8f26880b184090d3e252470d45",
    "decimals": 18,
    "image": "https://rango.vip/i/kyxZh9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.984059,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ERN",
    "name": "Ethos Reserve Note",
    "isPopular": false,
    "chainId": "1",
    "address": "0x91a69021b0baef3445e51726458a0ce601471846",
    "decimals": 18,
    "image": "https://rango.vip/i/kyxZh9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.984059,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ERN",
    "name": "Ethernity Chain",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2354c8e9ea898c751f1a15addeb048714d667f96",
    "decimals": 18,
    "image": "https://rango.vip/i/OcPWSK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.9928783750012304,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ERN",
    "name": "Ethernity Chain",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0e50bea95fe001a370a4f1c220c49aedcb982dec",
    "decimals": 18,
    "image": "https://rango.vip/i/TUAv8b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.99,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TRIVIA",
    "name": "Trivian Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb465f3cb6aba6ee375e12918387de1eac2301b05",
    "decimals": 3,
    "image": "https://rango.vip/i/xargpe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00037574,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "3CRV",
    "name": "Curve.fi DAI/USDC/USDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c3f90f043a72fa612cbac8115ee7e52bde6e490",
    "decimals": 18,
    "image": "https://rango.vip/i/V7JDR2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.035,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPO",
    "name": "Peepo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1b89856d82f978d049116eba8b7f9df2f342ff3",
    "decimals": 9,
    "image": "https://rango.vip/i/AJWoeX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.09584e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBUSD",
    "name": "BounceBit USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77776b40c3d75cb07ce54dea4b2fd1d07f865222",
    "decimals": 18,
    "image": "https://rango.vip/i/uALs4Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999218,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DGI",
    "name": "DGI Game",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe453c3409f8ad2b1fe1ed08e189634d359705a5b",
    "decimals": 18,
    "image": "https://rango.vip/i/InSQrg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00090403,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COLT",
    "name": "Collateral Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a3cbda3853494acab67648ee59afeb7ec3e9334",
    "decimals": 18,
    "image": "https://rango.vip/i/kx0gFk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006406,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLIPS",
    "name": "Clips",
    "isPopular": false,
    "chainId": "1",
    "address": "0xecbee2fae67709f718426ddc3bf770b26b95ed20",
    "decimals": 18,
    "image": "https://rango.vip/i/9O7KKB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.92374e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SFL",
    "name": "Sunflower Land",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd1f9c58e33933a993a3891f8acfe05a68e1afc05",
    "decimals": 18,
    "image": "https://rango.vip/i/4EC0GD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.06093,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MINT",
    "name": "Public Mint",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0cdf9acd87e940837ff21bb40c9fd55f68bba059",
    "decimals": 18,
    "image": "https://rango.vip/i/p9ESJn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00131154,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIMR",
    "name": "MeromAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcfd16933cb1579eee9fe6031686534e87353b148",
    "decimals": 18,
    "image": "https://rango.vip/i/05UFlH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00249214,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFAI",
    "name": "Not Financial Advice",
    "isPopular": false,
    "chainId": "1",
    "address": "0x17c50d62e6e8d20d2dc18e9ad79c43263d0720d9",
    "decimals": 18,
    "image": "https://rango.vip/i/f4dyMk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03593392,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ZIX",
    "name": "Coinzix Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x48077400faf11183c043feb5184a13ea628bb0db",
    "decimals": 18,
    "image": "https://rango.vip/i/g62UCB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00002746,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ELK",
    "name": "Elk",
    "isPopular": false,
    "chainId": "137",
    "address": "0xeeeeeb57642040be42185f49c52f7e9b38f8eeee",
    "decimals": 18,
    "image": "https://rango.vip/i/mOK4dk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.081759,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELK",
    "name": "Elk Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeeeeeb57642040be42185f49c52f7e9b38f8eeee",
    "decimals": 18,
    "image": "https://rango.vip/i/vzn3l1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.081759,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ELK",
    "name": "Elk",
    "isPopular": false,
    "chainId": "56",
    "address": "0xeeeeeb57642040be42185f49c52f7e9b38f8eeee",
    "decimals": 18,
    "image": "https://rango.vip/i/mOK4dk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.081759,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMRLD",
    "name": "The Emerald Company",
    "isPopular": false,
    "chainId": "1",
    "address": "0xebb1afb0a4ddc9b1f84d9aa72ff956cd1c1eb4be",
    "decimals": 18,
    "image": "https://rango.vip/i/0Zsn3E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00168532,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FUSDC",
    "name": "Fluid USDC",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4cfa50b7ce747e2d61724fcac57f24b748ff2b2a",
    "decimals": 6,
    "image": "https://rango.vip/i/djcPYu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.999889,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUSDC",
    "name": "Fluid USD Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9fb7b4477576fe5b32be4c1843afb1e55f251b33",
    "decimals": 6,
    "image": "https://rango.vip/i/PKdCJV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.9999339544749121,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUSDC",
    "name": "Fluid USDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d1089802ee608ba84c5c98211afe5f37f96b36c",
    "decimals": 6,
    "image": "https://rango.vip/i/TiIWDb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999889,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRICK",
    "name": "Brick Block",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00199c511dc889b8155fa425fc0363ed481e8f48",
    "decimals": 18,
    "image": "https://rango.vip/i/G3hvHi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00909672,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOU",
    "name": "Gou",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed89fc0f41d8be2c98b13b7e3cd3e876d73f1d30",
    "decimals": 9,
    "image": "https://rango.vip/i/rEeQm1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001035,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESG",
    "name": "ESG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20cd2e7ec8f5d8b337fe46a4f565ccef1561b9a9",
    "decimals": 18,
    "image": "https://rango.vip/i/scp3mk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.383703,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TOPG",
    "name": "TOP G",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8NH3AfwkizHmbVd83SSxc2YbsFmFL4m2BeepvL6upump",
    "decimals": 6,
    "image": "https://rango.vip/i/r2lsVS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0003668,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "FUND",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/608ef5c0ce64fea097500db39657bdd36ca708cc5dcc2e250a024b6981dd36bc",
    "decimals": 9,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0668969457151488,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUND",
    "name": "Unification",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9b076b476d8865cdf79d1cf7df420ee397a7f75",
    "decimals": 9,
    "image": "https://rango.vip/i/CPIhYe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.05803,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUND",
    "name": "Teh Fund",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d8d7c26179b7a6aebbf66a91c38ed92d5b4996b",
    "decimals": 18,
    "image": "https://rango.vip/i/gAswG9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0123525,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LOON",
    "name": "Balloon Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GDjFyK4umeTvfVu9r5pkdyaxzD2KtMhmBirwnJoKA7WW",
    "decimals": 6,
    "image": "https://rango.vip/i/QTmV1h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0001004013305386727,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOON",
    "name": "Loon Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c5d5100b339fe7d995a893af6cb496b9474373c",
    "decimals": 18,
    "image": "https://rango.vip/i/9AJk7p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010029,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LORDS",
    "name": "LORDS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x686f2404e77ab0d9070a46cdfb0b7fecdd2318b0",
    "decimals": 18,
    "image": "https://rango.vip/i/g0OKiC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04416893,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "LORDS",
    "name": "Lords",
    "isPopular": false,
    "chainId": "SN_MAIN",
    "address": "0x124aeb495b947201f5fac96fd1138e326ad86195b98df6dec9009158a533b49",
    "decimals": 18,
    "image": "https://rango.vip/i/KB1OJu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 0.04416893,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVX",
    "name": "Everex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3db5fa2c66b7af3eb0c0b782510816cbe4813b8",
    "decimals": 4,
    "image": "https://rango.vip/i/JorDPx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0044546,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KATCHU",
    "name": "Katchu Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CKiW2P4zngHa6fiPhMJGGhgDZm638dWotxkzgi8PsDxf",
    "decimals": 6,
    "image": "https://rango.vip/i/vMKZza",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00382186,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZCN",
    "name": "0chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9ef770b6a5e12e45983c5d80545258aa38f3b78",
    "decimals": 10,
    "image": "https://rango.vip/i/0xNyug",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.066684,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOUR",
    "name": "The 4th Pillar Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4730fb1463a6f1f44aeb45f6c5c422427f37f4d0",
    "decimals": 18,
    "image": "https://rango.vip/i/wCfjyu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0014664,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOUR",
    "name": "Four",
    "isPopular": false,
    "chainId": "1",
    "address": "0x244b797d622d4dee8b188b03546acaabd0cf91a0",
    "decimals": 18,
    "image": "https://rango.vip/i/8JkH6Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000187,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMN",
    "name": "Omni Foundation",
    "isPopular": false,
    "chainId": "1",
    "address": "0x253a5b4f0a210b8d33b702a938b1d56d06b5449e",
    "decimals": 18,
    "image": "https://rango.vip/i/RSqZuV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010155,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QLIX",
    "name": "QLix",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a907b0bbff60702b29a36b19718d99253cfbd9f",
    "decimals": 18,
    "image": "https://rango.vip/i/KaM2ru",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.055822,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CSR",
    "name": "CSR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x75ecb52e403c617679fbd3e77a50f9d10a842387",
    "decimals": 18,
    "image": "https://rango.vip/i/6F5ErI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01400882,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BITO",
    "name": "BITO Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x93b1e78a3e652cd2e71c4a767595b77282344932",
    "decimals": 18,
    "image": "https://rango.vip/i/HVwtgc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.074422,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RAIN",
    "name": "Precipitate.AI",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x1f1aa4d239002bb818536c95e9b762a1fc8484c1",
    "decimals": 18,
    "image": "https://rango.vip/i/WYZpjL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00282524,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAIN",
    "name": "Rainmaker Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x71fc1f555a39e0b698653ab0b475488ec3c34d57",
    "decimals": 18,
    "image": "https://rango.vip/i/sKVJKb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00063343,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAIN",
    "name": "Precipitate.AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe3944ab788a60ca266f1eec3c26925b95f6370ad",
    "decimals": 18,
    "image": "https://rango.vip/i/WYZpjL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00282524,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DSRUN",
    "name": "DerbyStarsRUN",
    "isPopular": false,
    "chainId": "137",
    "address": "0xff76c0b48363a7c7307868a81548d340049b0023",
    "decimals": 18,
    "image": "https://rango.vip/i/e59F4b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01274619,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QDX",
    "name": "Quidax",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1780933e83b09371cf716f3630fe5a422a66a39e",
    "decimals": 18,
    "image": "https://rango.vip/i/Y02t5W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02865937,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVA",
    "name": "Evanesco Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd6caf5bd23cf057f5fccce295dcc50c01c198707",
    "decimals": 18,
    "image": "https://rango.vip/i/FxHPeB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00057333,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "APW",
    "name": "APWine Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6c0ab120dbd11ba701aff6748568311668f63fe0",
    "decimals": 18,
    "image": "https://rango.vip/i/bPIYLQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.269815,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APW",
    "name": "APWine Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4104b135dbc9609fc1a9490e61369036497660c8",
    "decimals": 18,
    "image": "https://rango.vip/i/7yUeLw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.269815,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STREETH",
    "name": "STREETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb840d10d840ef47c233fec1fd040f5b145a6dfa5",
    "decimals": 18,
    "image": "https://rango.vip/i/RHMJQr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00047094,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JUSTICE",
    "name": "AssangeDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x59d1e836f7b7210a978b25a855085cc46fd090b5",
    "decimals": 18,
    "image": "https://rango.vip/i/kMrfc2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010609,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHFT",
    "name": "Crypto Holding Frank",
    "isPopular": false,
    "chainId": "1",
    "address": "0x58002a6b6e659a16de9f02f529b10536e307b0d9",
    "decimals": 18,
    "image": "https://rango.vip/i/RHzTzR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.063811,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SKY",
    "name": "DARKCRYPTO SHARE",
    "isPopular": false,
    "chainId": "25",
    "address": "0x9d3bbb0e988d9fb2d55d07fe471be2266ad9c81c",
    "decimals": 18,
    "image": "https://rango.vip/i/7aGBip",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 1.227666452495191,
    "supportedSwappers": [
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKY",
    "name": "Sky Raiders",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d551bd441fb65513c0d815747b3409c1eb56886",
    "decimals": 18,
    "image": "https://rango.vip/i/XBpTAD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040926,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ISEC",
    "name": "IntelliSecure Systems",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae5b2aa98532c0c27c88f2085d66b5263f4b9fee",
    "decimals": 9,
    "image": "https://rango.vip/i/IOTIbb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0249183,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PROS",
    "name": "Prosper",
    "isPopular": false,
    "chainId": "56",
    "address": "0xed8c8aa8299c10f067496bb66f8cc7fb338a3405",
    "decimals": 18,
    "image": "https://rango.vip/i/5m4yW0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.291898,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PROS",
    "name": "Prosper",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8642a849d0dcb7a15a974794668adcfbe4794b56",
    "decimals": 18,
    "image": "https://rango.vip/i/Wfg9UV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.291898,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DGC",
    "name": "DGC Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x7f29e7086453ed97283893e763ae0989f4b70f8d",
    "decimals": 6,
    "image": "https://rango.vip/i/YClojp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00018306421355947185,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JPEG",
    "name": "JPEG’d Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe80c0cd204d654cebe8dd64a4857cab6be8345a3",
    "decimals": 18,
    "image": "https://rango.vip/i/IkLui3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00043339,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "YAK",
    "name": "Yak Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x59414b3089ce2af0010e7523dea7e2b35d776ec7",
    "decimals": 18,
    "image": "https://rango.vip/i/lEVVUW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 339.93,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YAK",
    "name": "YAK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe842e272a18625319cc36f64eb9f97e5ad0c32af",
    "decimals": 9,
    "image": "https://rango.vip/i/SdE46B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.4807e-14,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FRONK",
    "name": "Fronk",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5yxNbU8DgYJZNi3mPD9rs4XLh9ckXrhPjJ5VCujUWg5H",
    "decimals": 5,
    "image": "https://rango.vip/i/ub43mc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 9.174e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FMC",
    "name": "FAME AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6bfdb6f4e65ead27118592a41eb927cea6956198",
    "decimals": 18,
    "image": "https://rango.vip/i/l3q8sn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033644,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBTC",
    "name": "Binance Wrapped BTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9be89d2a4cd102d8fecc6bf9da793be995c22541",
    "decimals": 8,
    "image": "https://rango.vip/i/BhwKBs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55330,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBTC",
    "name": "BounceBit BTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5e11df1ebcf78b6b6d26e04ff19cd786a1e81dc",
    "decimals": 18,
    "image": "https://rango.vip/i/10wTTX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55327,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LUAUSD",
    "name": "Lumi Finance LUAUSD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1dd6b5f9281c6b4f043c02a83a46c2772024636c",
    "decimals": 18,
    "image": "https://rango.vip/i/OhoDAw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.999628,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "99BTC",
    "name": "99 Bitcoins",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc2eb40516ecaac04ae9964934983d1e9ebdf51fd",
    "decimals": 18,
    "image": "https://rango.vip/i/pedZpK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017806,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPSEC",
    "name": "OpSec",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a7eff1e2c355ad6eb91bebb5ded49257f3fed98",
    "decimals": 18,
    "image": "https://rango.vip/i/R6rJd5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02330194,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "INST",
    "name": "Instadapp (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf50d05a1402d0adafa880d36050736f9f6ee7dee",
    "decimals": 18,
    "image": "https://rango.vip/i/ZRG2hZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INST",
    "name": "Instadapp",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6f40d4a6237c257fff2db00fa0510deeecd303eb",
    "decimals": 18,
    "image": "https://rango.vip/i/JS1uCz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEIPEI",
    "name": "PEIPEI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf327c89bf0769f0f2e99e50232557f03aad6cc17",
    "decimals": 18,
    "image": "https://rango.vip/i/XzhEmf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.92837e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEIPEI",
    "name": "PeiPei",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ffeea07a27fab7ad1df5297fa75e77a43cb5790",
    "decimals": 18,
    "image": "https://rango.vip/i/XuNrEa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.06265e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LINU",
    "name": "Luna Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x78132543d8e20d2417d8a07d9ae199d458a0d581",
    "decimals": 18,
    "image": "https://rango.vip/i/lPjxyi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.772e-9,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VOIP",
    "name": "Voip Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3d74182247ef963e0de37e3f2e174e98dcbfae1",
    "decimals": 18,
    "image": "https://rango.vip/i/NrMw7x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024056,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WARPED",
    "name": "Warped Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6af53c6ec427525f7240e211941223288a0e7c66",
    "decimals": 18,
    "image": "https://rango.vip/i/10slQH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00100995,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCB",
    "name": "Blockchain Bets",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2d886570a0da04885bfd6eb48ed8b8ff01a0eb7e",
    "decimals": 9,
    "image": "https://rango.vip/i/4XeFyL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0044824,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEMAI",
    "name": "Gem AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e5d4ed373596f00a5fc78ceb49994d9dc665aa9",
    "decimals": 9,
    "image": "https://rango.vip/i/sRsYFG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001676,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEMAI",
    "name": "The Next Gem AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfbe44cae91d7df8382208fcdc1fe80e40fbc7e9a",
    "decimals": 18,
    "image": "https://rango.vip/i/EPKVSr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00344403,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "THE",
    "name": "THENA",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf4c8e32eadec4bfe97e0f595add0f4450a863a11",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.158667,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THE",
    "name": "THENODE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb4a677b0e363c3815d46326954a4e4d2b1ace357",
    "decimals": 18,
    "image": "https://rango.vip/i/TKEPL0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.157887,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THE",
    "name": "The Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0cba60ca5ef4d42f92a5070a8fedd13be93e2861",
    "decimals": 18,
    "image": "https://rango.vip/i/86stZb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017125,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LVL",
    "name": "Level Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb64e280e9d1b5dbec4accedb2257a87b400db149",
    "decimals": 18,
    "image": "https://rango.vip/i/5HqkUD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.114173,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GONDOLA",
    "name": "Gondola",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd43fba1f38d9b306aeef9d78ad177d51ef802b46",
    "decimals": 9,
    "image": "https://rango.vip/i/80iun4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000217,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SMOL",
    "name": "Smolcoin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9e64d3b9e8ec387a9a58ced80b71ed815f8d82b5",
    "decimals": 18,
    "image": "https://rango.vip/i/uGM4av",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 8.55182e-7,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SMOL",
    "name": "smol cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A9jLULrDSYZkBtKfvG44w8NWzvCN6rGLpoA3hY7TQ85k",
    "decimals": 6,
    "image": "https://rango.vip/i/WrjzfN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 8.451473529555275e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WINTER",
    "name": "Winter",
    "isPopular": false,
    "chainId": "1",
    "address": "0xccba0b2bc4babe4cbfb6bd2f1edc2a9e86b7845f",
    "decimals": 18,
    "image": "https://rango.vip/i/bFEgPt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00342024,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XTREME",
    "name": "Xtremeverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x000000000503be77a5ed27bef2c19943a8b5ae73",
    "decimals": 18,
    "image": "https://rango.vip/i/UkMUbz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 191.19,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STRDY",
    "name": "Sturdy Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaeb3607ec434454ceb308f5cd540875efb54309a",
    "decimals": 18,
    "image": "https://rango.vip/i/ev9LRT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.161856,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IPOR",
    "name": "IPOR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e4746dc744503b53b4a082cb3607b169a289090",
    "decimals": 18,
    "image": "https://rango.vip/i/JyPJkQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.252294,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAL",
    "name": "Calcium",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20561172f791f915323241e885b4f7d5187c36e1",
    "decimals": 18,
    "image": "https://rango.vip/i/m2yxz7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00162233,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LTX",
    "name": "Tanox",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LTXH7nCGXz5TBZ57H8oZu7YwmDSVfSqWViW4B28yg8X",
    "decimals": 8,
    "image": "https://rango.vip/i/NY8SZ2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0766712493564112,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LTX",
    "name": "Lattice Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa393473d64d2f9f026b60b6df7859a689715d092",
    "decimals": 8,
    "image": "https://rango.vip/i/xnC8x9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.078401,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JIM",
    "name": "jim",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd807f7e2818db8eda0d28b5be74866338eaedb86",
    "decimals": 18,
    "image": "https://rango.vip/i/f1ZAxd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000472,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOA",
    "name": "NOA PLAY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0fd3822072ad001aac1c90a09d9506f097f24458",
    "decimals": 9,
    "image": "https://rango.vip/i/ZUnRlZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027369,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPOT",
    "name": "Defispot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe635efcfac44c5f44508f4d17c3a96cb4ce421dd",
    "decimals": 18,
    "image": "https://rango.vip/i/VxId0K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00286001,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SPOT",
    "name": "SPOT",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8f2e6758c4d6570344bd5007dec6301cd57590a0",
    "decimals": 9,
    "image": "https://rango.vip/i/4qIIPV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.15,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPOT",
    "name": "SPOT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc1f33e0cf7e40a67375007104b929e49a581bafe",
    "decimals": 9,
    "image": "https://rango.vip/i/DP1092",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ECOX",
    "name": "ECOx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcccd1ba9f7acd6117834e0d28f25645decb1736a",
    "decimals": 18,
    "image": "https://rango.vip/i/VE96HS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.102211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SENK",
    "name": "SENK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FeBg9Utf5wFa2PsT6KnJ6uitvWtfc87R38wmRnxrNMiW",
    "decimals": 9,
    "image": "https://rango.vip/i/n9PMD7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00022856,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WORK",
    "name": "Worken",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9tnkusLJaycWpkzojAk5jmxkdkxBHRkFNVSsa7tPUgLb",
    "decimals": 5,
    "image": "https://rango.vip/i/8ThCv5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00182168,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WORK",
    "name": "The Employment Commons Work Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6002410dda2fb88b4d0dc3c1d562f7761191ea80",
    "decimals": 18,
    "image": "https://rango.vip/i/v8jK4P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00231639,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAGAPEPE",
    "name": "MAGA PEPE  ETH ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e7bd91f7d51d58145365341fdb37e0edfc8397f",
    "decimals": 9,
    "image": "https://rango.vip/i/4wgT7Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.405e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAZED",
    "name": "Bazed Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ee4b152824b657644c7a9b50694787e80eb8f4a",
    "decimals": 18,
    "image": "https://rango.vip/i/rFhN3b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02474837,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "XTV",
    "name": "XTV",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GTTS8jkhmfWBdYyKNSgbDQeYqVkpALkP98YJRGbRbvUA",
    "decimals": 9,
    "image": "https://rango.vip/i/mXacqc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.029931843533750644,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAPE",
    "name": "MAGA Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x683a4ac99e65200921f556a19dadf4b0214b5938",
    "decimals": 9,
    "image": "https://rango.vip/i/MwVS6P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.44409e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWISE",
    "name": "StakeWise",
    "isPopular": false,
    "chainId": "1",
    "address": "0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2",
    "decimals": 18,
    "image": "https://rango.vip/i/jvxMZu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0167383,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYPEPE",
    "name": "CyPepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80a88dc663fa256e34ecb5a47314702313b162a5",
    "decimals": 18,
    "image": "https://rango.vip/i/vvaOC2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003975,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHAMPZ",
    "name": "Champignons of Arborethia",
    "isPopular": false,
    "chainId": "1",
    "address": "0x571e21a545842c6ce596663cda5caa8196ac1c7a",
    "decimals": 8,
    "image": "https://rango.vip/i/TAy6Hn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0012254,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VALU",
    "name": "Value",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d5e345796f6fc568798aac6e8704e736cc1bf2b",
    "decimals": 18,
    "image": "https://rango.vip/i/0cWy3X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0051435,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "IHF",
    "name": "IHF Smart Debase Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x3b9728bd65ca2c11a817ce39a6e91808cceef6fd",
    "decimals": 18,
    "image": "https://rango.vip/i/e8GAiq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 7.84,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "REKT",
    "name": "REKT",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1d987200df3b744cfa9c14f713f5334cb4bc4d5d",
    "decimals": 6,
    "image": "https://rango.vip/i/pxe2iS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 3.38794e-10,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "REKT",
    "name": " REKT",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8972ab69d499b5537a31576725f0af8f67203d38",
    "decimals": 18,
    "image": "https://rango.vip/i/5uFpvs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00425072,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PALAI",
    "name": "PaladinAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3cb48aeb3d1abadc23d2d8a6894b3a68338381c2",
    "decimals": 9,
    "image": "https://rango.vip/i/YesMli",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02492163,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WEIRDO",
    "name": "Weirdo",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x76734b57dfe834f102fb61e1ebf844adf8dd931e",
    "decimals": 8,
    "image": "https://rango.vip/i/CSDxym",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00002622,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "MAN",
    "name": "MAN",
    "isPopular": false,
    "chainId": "128",
    "address": "0x7a45d615f26da940ffff43eca1274d8ea116ce0c",
    "decimals": 18,
    "image": "https://rango.vip/i/ftPMoN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.01862035811077282,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPRING",
    "name": "Spring Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf04af3f4e4929f7cd25a751e6149a3318373d4fe",
    "decimals": 18,
    "image": "https://rango.vip/i/ZPFyHN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00293523,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLAPPY",
    "name": "Flappy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x590246bfbf89b113d8ac36faeea12b7589f7fe5b",
    "decimals": 9,
    "image": "https://rango.vip/i/e8qDFj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.37971e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUTUMN",
    "name": "Autumn",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c3bae16c79c30eeb1004fb03c878d89695e3a99",
    "decimals": 18,
    "image": "https://rango.vip/i/Wmaj66",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00398638,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PGOLD",
    "name": "Poison.Finance Gold Potion",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf602a45812040d90b202355bdc05438918cd3fe3",
    "decimals": 18,
    "image": "https://rango.vip/i/je6P4d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.07192050533117507,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAMMA",
    "name": "Gamma",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6bea7cfef803d1e3d5f7c0103f7ded065644e197",
    "decimals": 18,
    "image": "https://rango.vip/i/hPjtyw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.079732,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GAMMA",
    "name": "GAMMA",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb3cb6d2f8f2fde203a022201c81a96c167607f15",
    "decimals": 18,
    "image": "https://rango.vip/i/9Iy8Lk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00085625,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DFND",
    "name": "dFund",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2adc1c84443ad06f0017adca346bd9b6fc52cab",
    "decimals": 18,
    "image": "https://rango.vip/i/zQJQwZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020183,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIC",
    "name": "Victory Impact",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e3778dd6dbfdc1c5b89f95f7c098b21e80ec4fa",
    "decimals": 18,
    "image": "https://rango.vip/i/cvOMJj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00083674,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SRM",
    "name": "Serum",
    "isPopular": false,
    "chainId": "25",
    "address": "0xb858e614779f148992949a3b15e6127dda204ca4",
    "decimals": 6,
    "image": "https://rango.vip/i/K6TSHS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.11321875904200254,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SRM",
    "name": "Serum",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt",
    "decimals": 6,
    "image": "https://rango.vip/i/xFgL6N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02779624,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SRM",
    "name": "Serum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x476c5e26a75bd202a9683ffd34359c0cc15be0ff",
    "decimals": 6,
    "image": "https://rango.vip/i/ymGEva",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02779624,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SRM",
    "name": "Solareum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x99b600d0a4abdbc4a6796225a160bcf3d5ce2a89",
    "decimals": 18,
    "image": "https://rango.vip/i/YfLD1L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03385706,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "XLAYER",
    "symbol": "PUFF",
    "name": "HOW TO FLY",
    "isPopular": false,
    "chainId": "196",
    "address": "0x4d87750a795b0ae310a24349fa9108585100a90e",
    "decimals": 18,
    "image": "https://rango.vip/i/ZPd5gs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/XLAYER/icon.svg",
    "usdPrice": 0.0004652431036441403,
    "supportedSwappers": [
      "PotatoSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PUFF",
    "name": "PUFF",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB",
    "decimals": 9,
    "image": "https://rango.vip/i/AYXd9q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00562519,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUFF",
    "name": "Puff",
    "isPopular": false,
    "chainId": "1",
    "address": "0x18fa05ee5e478eed8925946abb41d09aec5d34d6",
    "decimals": 18,
    "image": "https://rango.vip/i/k7nyUe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04356451,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROKO",
    "name": "Roko Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6f222e04f6c53cc688ffb0abe7206aac66a8ff98",
    "decimals": 18,
    "image": "https://rango.vip/i/y4CjQR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001661,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOLD",
    "name": "Manifold Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd084944d3c05cd115c09d072b9f44ba3e0e45921",
    "decimals": 18,
    "image": "https://rango.vip/i/qmdgH5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.4,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DBR",
    "name": "DOLA Borrowing Right",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad038eb671c44b853887a7e32528fab35dc5d710",
    "decimals": 18,
    "image": "https://rango.vip/i/3y9abG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04536437,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JJ",
    "name": "JJmoji",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8eAUrugF8ToBmkg4CpJjTY9AcPx1UBMdExw2Ju84MCG4",
    "decimals": 9,
    "image": "https://rango.vip/i/PziCC6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01656978,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JJ",
    "name": "Jjmoji",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb81f7b3f0b2baebd5009cddade5c9a9c82378bb",
    "decimals": 18,
    "image": "https://rango.vip/i/UZwwDa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02408834,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JJ",
    "name": "JEJE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1fdd61ef9a5c31b9a2abc7d39c139c779e8412af",
    "decimals": 9,
    "image": "https://rango.vip/i/JJlpad",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.11479e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLUES",
    "name": "Blueshift",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce108380c39e4fe9dace9d5597e048bcc5ef743b",
    "decimals": 18,
    "image": "https://rango.vip/i/XVZbnX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00580361,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THOR",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0xa5f2211b9b8170f694421f2046281775e8468044",
    "decimals": 18,
    "image": "https://rango.vip/i/MWuCPb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.270662,
    "supportedSwappers": [
      "ThorChain",
      "ThorChainStreamingSwap",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "THOR",
    "name": "ThorFi",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x8f47416cae600bccf9530e9f3aeaa06bdd1caa79",
    "decimals": 18,
    "image": "https://rango.vip/i/rZVhcU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.175242,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUMMER",
    "name": "Summer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4d4f3715050571a447fffa2cd4cf091c7014ca5c",
    "decimals": 18,
    "image": "https://rango.vip/i/AF2L9s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00352562,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BMEX",
    "name": "BitMEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb113c6cf239f60d380359b762e95c13817275277",
    "decimals": 6,
    "image": "https://rango.vip/i/fEG0y1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.114112,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XPAY",
    "name": "  Payments",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf2fdd9c25d7bc8002ce89716d1be484b2d976944",
    "decimals": 18,
    "image": "https://rango.vip/i/mEzBtE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00078829,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "ABR",
    "name": "Allbridge - Allbridge",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x2bae00c8bc1868a5f7a216e881bae9e662630111",
    "decimals": 18,
    "image": "https://rango.vip/i/RHw3Az",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.110285,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ABR",
    "name": "Allbridge",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "a11bdAAuV8iB2fu7X6AxAvDTo1QZ8FXB3kk5eecdasp",
    "decimals": 9,
    "image": "https://rango.vip/i/b9voEQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.110285,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABR",
    "name": "Allbridge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa11bd36801d8fa4448f0ac4ea7a62e3634ce8c7c",
    "decimals": 18,
    "image": "https://rango.vip/i/5eNXxP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.110285,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "ABR",
    "name": "Allbridge",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x6e512bfc33be36f2666754e996ff103ad1680cc9",
    "decimals": 18,
    "image": "https://rango.vip/i/k6XTMT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.110285,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCNT",
    "name": "Bincentive",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9669890e48f330acd88b78d63e1a6b3482652cd9",
    "decimals": 18,
    "image": "https://rango.vip/i/jmijj0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00292095,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "METAL",
    "name": "METAL",
    "isPopular": false,
    "chainId": "137",
    "address": "0x200c234721b5e549c3693ccc93cf191f90dc2af9",
    "decimals": 18,
    "image": "https://rango.vip/i/uGyT8E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01400537,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "METAL",
    "name": "Metal Blockchain Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x294559fa758c88d639fd085751e463fee7806eab",
    "decimals": 18,
    "image": "https://rango.vip/i/c1RoYD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04002504,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "METAL",
    "name": "METAL Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x200c234721b5e549c3693ccc93cf191f90dc2af9",
    "decimals": 18,
    "image": "https://rango.vip/i/uGyT8E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01400537,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALD",
    "name": "Aladdin Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb26c4b3ca601136daf98593feaeff9e0ca702a8d",
    "decimals": 18,
    "image": "https://rango.vip/i/1lVdAx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02436732,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WNK",
    "name": "Winkies",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1bda0c3bfa2be7f740f0119b6a34f057bd58eba",
    "decimals": 18,
    "image": "https://rango.vip/i/WnjPIu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00028934,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TXT",
    "name": "Taxa Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x547b2f82cecfab9c2b1d36fdda96ef9f58c63b8c",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00101798,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGEGF",
    "name": "DogeGF",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb130d93e49dca13264344966a611dc79a456bc5",
    "decimals": 18,
    "image": "https://rango.vip/i/2lyzul",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.1594e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GAIA",
    "name": "GAIA Everworld",
    "isPopular": false,
    "chainId": "137",
    "address": "0x723b17718289a91af252d616de2c77944962d122",
    "decimals": 18,
    "image": "https://rango.vip/i/hNyh59",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00313364,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GAIA",
    "name": "GAIA Everworld",
    "isPopular": false,
    "chainId": "56",
    "address": "0x347e430b7cd1235e216be58ffa13394e5009e6e2",
    "decimals": 18,
    "image": "https://rango.vip/i/UIxfie",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00313364,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MARV",
    "name": "Marv",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3bb1be077f3f96722ae92ec985ab37fd0a0c4c51",
    "decimals": 18,
    "image": "https://rango.vip/i/u80tI5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.05729e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "HARAMBE",
    "name": "HarambeCoin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x255f1b39172f65dc6406b8bee8b08155c45fe1b6",
    "decimals": 18,
    "image": "https://rango.vip/i/mZBS8b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 7.18553e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HARAMBE",
    "name": "HARAMBE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fch1oixTPri8zxBnmdCEADoJW2toyFHxqDZacQkwdvSP",
    "decimals": 9,
    "image": "https://rango.vip/i/NE2KIf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01376889,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HARAMBE",
    "name": "HarambeCoin (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CNL2opdqaQDY7yudm5V4MAaTwuuY32LTKqNP93jZM8X6",
    "decimals": 8,
    "image": "https://rango.vip/i/CKVgrS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 7.18553e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HARAMBE",
    "name": "Harambe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xade6fdaba1643e4d1eef68da7170f234470938c6",
    "decimals": 18,
    "image": "https://rango.vip/i/HsYzpQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001686,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HARAMBE",
    "name": "HarambeCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x255f1b39172f65dc6406b8bee8b08155c45fe1b6",
    "decimals": 18,
    "image": "https://rango.vip/i/mZBS8b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.18553e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VATR",
    "name": "Vatra INU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d234a9d23f01c5556ad3dfa88f470f8982ab1b4",
    "decimals": 18,
    "image": "https://rango.vip/i/rFxmIs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00150181,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SBTC",
    "name": "Synth sBTC",
    "isPopular": false,
    "chainId": "10",
    "address": "0x298b9b95708152ff6968aafd889c6586e9169f1d",
    "decimals": 18,
    "image": "https://rango.vip/i/t8iRdW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.24545553715236362,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SBTC",
    "name": "Synth sBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6",
    "decimals": 18,
    "image": "https://rango.vip/i/qlZSfc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 51402,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "CYBER",
    "name": "Cyberconnect",
    "isPopular": false,
    "chainId": "10",
    "address": "0x14778860e937f509e651192a90589de711fb88a9",
    "decimals": 18,
    "image": "https://rango.vip/i/hyui6F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 3.1,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CYBER",
    "name": "CyberConnect",
    "isPopular": false,
    "chainId": "56",
    "address": "0x14778860e937f509e651192a90589de711fb88a9",
    "decimals": 18,
    "image": "https://rango.vip/i/mOeD8t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.1,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYBER",
    "name": "CYBER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x14778860e937f509e651192a90589de711fb88a9",
    "decimals": 18,
    "image": "https://rango.vip/i/L7utna",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "STARSHIP",
    "name": "StarShip",
    "isPopular": false,
    "chainId": "56",
    "address": "0x52419258e3fa44deac7e670eadd4c892b480a805",
    "decimals": 9,
    "image": "https://rango.vip/i/KwDnrH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02101652,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CMPT",
    "name": "Spatial Computing",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d000462fb9826804a45c0ea869b83b69587f2db",
    "decimals": 18,
    "image": "https://rango.vip/i/DSepp6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.28,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOVE",
    "name": "Mover",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3fa729b4548becbad4eab6ef18413470e6d5324c",
    "decimals": 18,
    "image": "https://rango.vip/i/X0F7yj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.062983,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PONCHO",
    "name": "Poncho",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xc2fe011c3885277c7f0e7ffd45ff90cadc8ecd12",
    "decimals": 18,
    "image": "https://rango.vip/i/CNHE1Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.04907209,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PLX",
    "name": "Parallax",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc8ccbd97b96834b976c995a67bf46e5754e2c48e",
    "decimals": 18,
    "image": "https://rango.vip/i/2pNRdW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00248428,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLX",
    "name": "Pullix",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1cc7047e15825f639e0752eb1b89e4225f5327f2",
    "decimals": 18,
    "image": "https://rango.vip/i/Ypp85X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.051217,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SPARTA",
    "name": "Spartan Protocol Token V2",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3910db0600ea925f63c36ddb1351ab6e2c6eb102",
    "decimals": 18,
    "image": "https://rango.vip/i/eYewfT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00436244,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SPARTA",
    "name": "SPARTAN PROTOCOL TOKEN",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe4ae305ebe1abe663f261bc00534067c80ad677c",
    "decimals": 18,
    "image": "https://rango.vip/i/4lEVRw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.004413665996015216,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "STELLA",
    "name": "Stellaswap",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x0e358838ce72d5e61e0018a2ffac4bec5f4c88d2",
    "decimals": 18,
    "image": "https://rango.vip/i/bLmRwC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.01541091,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TPU",
    "name": "TensorSpace",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd9812f24f34e0d727bbf6ea7caaee05b7f7a2603",
    "decimals": 18,
    "image": "https://rango.vip/i/sDTiTg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008866,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZEBU",
    "name": "ZEBU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7unYePWUHcpB28cnS65TpqT2qqmZaftRz9QABkdR8yN7",
    "decimals": 6,
    "image": "https://rango.vip/i/JWw5bi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00015206,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "EGC",
    "name": "EverGrow Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc001bbe2b87079294c63ece98bdd0a88d761434e",
    "decimals": 9,
    "image": "https://rango.vip/i/wwM2k3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.9738e-8,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DVP",
    "name": "Decentralized Vulnerability Platform",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e30ea2329d95802fd804f4291220b0e2f579812",
    "decimals": 18,
    "image": "https://rango.vip/i/o4BI65",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00025165,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LBTC",
    "name": "Lombard Staked BTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8236a87084f8b84306f72007f36f2618a5634494",
    "decimals": 8,
    "image": "https://rango.vip/i/O6Sdvo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55682,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EPEP",
    "name": "Epep",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "RUpbmGF6p42AAeN1QvhFReZejQry1cLkE1PUYFVVpnL",
    "decimals": 8,
    "image": "https://rango.vip/i/OntOEt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00044155311378377376,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LUA",
    "name": "Lumi Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc3abc47863524ced8daf3ef98d74dd881e131c38",
    "decimals": 18,
    "image": "https://rango.vip/i/GJEoWJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 3.22,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUA",
    "name": "LuaToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb1f66997a5760428d3a87d68b90bfe0ae64121cc",
    "decimals": 18,
    "image": "https://rango.vip/i/qH07ha",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03537063,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UCO",
    "name": "UnirisToken (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3c720206bfacb2d16fa3ac0ed87d2048dbc401fc",
    "decimals": 18,
    "image": "https://rango.vip/i/lfr4te",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01264181,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UCO",
    "name": "Archethic",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a3d77e9d6968b780564936d15b09805827c21fa",
    "decimals": 18,
    "image": "https://rango.vip/i/Nrd9FR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01264181,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "UCO",
    "name": "UnirisToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb001f1e7c8bda414ac7cf7ecba5469fe8d24b6de",
    "decimals": 18,
    "image": "https://rango.vip/i/5FqN2v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01264181,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AICODE",
    "name": "AI CODE",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7c8a1a80fdd00c9cccd6ebd573e9ecb49bfa2a59",
    "decimals": 18,
    "image": "https://rango.vip/i/7fTMSp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.572861,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VANA",
    "name": "NIRVANA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc391e78b0ea0d1db04890732742494e7fbfc118",
    "decimals": 18,
    "image": "https://rango.vip/i/2wH0kS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00094069,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEMEME",
    "name": "MEMEME",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a963df363d01eebb2816b366d61c917f20e1ebe",
    "decimals": 18,
    "image": "https://rango.vip/i/bsB9eY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000026806089362978414,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "PASG",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/208b2f137cde510b44c41947c045cfdc27f996a9d990ea64460bdd5b3dbeb2ed",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0076932,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCOIN",
    "name": "Backed Coinbase Global",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbbcb0356bb9e6b3faa5cbf9e5f36185d53403ac9",
    "decimals": 18,
    "image": "https://rango.vip/i/c0aIDz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 163.22,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BCOIN",
    "name": "Bomb Crypto",
    "isPopular": false,
    "chainId": "56",
    "address": "0x00e1656e45f18ec6747f5a8496fd39b50b38396d",
    "decimals": 18,
    "image": "https://rango.vip/i/r1pfx5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01040966,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FXI",
    "name": "FX1Sports",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc5190e7fec4d97a3a3b1ab42dfedac608e2d0793",
    "decimals": 18,
    "image": "https://rango.vip/i/MDeN2A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00586983,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOP",
    "name": "Data Ownership Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97a9a15168c22b3c137e6381037e1499c8ad0978",
    "decimals": 18,
    "image": "https://rango.vip/i/cYLLvz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00327643,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOP",
    "name": "Drops Ownership Power",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6bb61215298f296c55b19ad842d3df69021da2ef",
    "decimals": 18,
    "image": "https://rango.vip/i/W4XuOU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01447602,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOP",
    "name": "D-Drops",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a7cad775fd9c5c43f996a948660ffc21b4e628c",
    "decimals": 18,
    "image": "https://rango.vip/i/rSknlR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00869403,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HACHIKO",
    "name": "Hachiko",
    "isPopular": false,
    "chainId": "1",
    "address": "0x381491960c37b65862819ced0e35385f04b2c78b",
    "decimals": 9,
    "image": "https://rango.vip/i/34Z2I2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.8002e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PIG",
    "name": "Pig Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8850d2c68c632e3b258e612abaa8fada7e6958e5",
    "decimals": 9,
    "image": "https://rango.vip/i/mArOnl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.829e-8,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "FOXY",
    "name": "Foxy",
    "isPopular": false,
    "chainId": "59144",
    "address": "0x5fbdf89403270a1846f5ae7d113a989f850d1566",
    "decimals": 18,
    "image": "https://rango.vip/i/zWbUwc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 0.00646073,
    "supportedSwappers": [
      "LineaPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FOXY",
    "name": "Famous Fox Federation",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq",
    "decimals": 0,
    "image": "https://rango.vip/i/JQpXvO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00433158,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SFM",
    "name": "SafeMoon",
    "isPopular": false,
    "chainId": "56",
    "address": "0x42981d0bfbaf196529376ee702f2a9eb9092fcb5",
    "decimals": 9,
    "image": "https://rango.vip/i/JwuIfW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00002889,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EDSE",
    "name": "Eddie Seal",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2Zvo6bnwJtyXhsPgbcfajTrEtB7NwrJhK8mMmtEXvHHc",
    "decimals": 9,
    "image": "https://rango.vip/i/HqfXaD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00032543,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GAS",
    "name": "Gas Is Cheap",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x8f939d5052afd7515f92a8c93ec4c09fbfeebb91",
    "decimals": 2,
    "image": "https://rango.vip/i/GebIgs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 3.0762937962403054,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAS",
    "name": "Gas Dao",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6bba316c48b49bd1eac44573c5c871ff02958469",
    "decimals": 18,
    "image": "https://rango.vip/i/sQ4XBV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.35171e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FREE",
    "name": "FreeSpeech",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fcvn3f5BTChSH53ixtURMmF5Vqj9yGsSYrRjFkf3wCX2",
    "decimals": 9,
    "image": "https://rango.vip/i/QsoH2e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.1183029563909005e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FREE",
    "name": "Free Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f141ce366a2462f02cea3d12cf93e4dca49e4fd",
    "decimals": 18,
    "image": "https://rango.vip/i/3E36Ia",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11535e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FREE",
    "name": "Ross Ulbricht Genesis Collection",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4cd0c43b0d53bc318cc5342b77eb6f124e47f526",
    "decimals": 18,
    "image": "https://rango.vip/i/CO4cRr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018962,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FREE",
    "name": "FREE coin BSC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x12e34cdf6a031a10fe241864c32fb03a4fdad739",
    "decimals": 18,
    "image": "https://rango.vip/i/zVAahq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.11535e-7,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TREAT",
    "name": "Treat Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfbd5fd3f85e9f4c5e8b40eec9f8b8ab1caaa146b",
    "decimals": 18,
    "image": "https://rango.vip/i/JndSbD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00057637,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AMKT",
    "name": "Alongside Crypto Market Index",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x498c620c7c91c6eba2e3cd5485383f41613b7eb6",
    "decimals": 18,
    "image": "https://rango.vip/i/4MFhvN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 157.82,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMKT",
    "name": "Alongside Crypto Market Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf17a3fe536f8f7847f1385ec1bc967b2ca9cae8d",
    "decimals": 18,
    "image": "https://rango.vip/i/4MFhvN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 157.82,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "AMKT",
    "name": "Alongside Crypto Market Index",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc27d9bc194a648fe3069955a5126699c4e49351c",
    "decimals": 18,
    "image": "https://rango.vip/i/KXmRWK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 157.82,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHARBI",
    "name": "Sharbi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8D1nUMJQam54o34Kj2knFhSTaWoehEr4mBc7LfiDdCqq",
    "decimals": 8,
    "image": "https://rango.vip/i/naAJRG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000511,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHARBI",
    "name": "Sharbi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9bd20ed5be5e1b50cdbb28270a2f0815d9d23af3",
    "decimals": 18,
    "image": "https://rango.vip/i/dHsOcA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000005094944149515078,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LCT",
    "name": "LCT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2b8f0750c7849f7311967f4faa4d48f694195e58",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0014102463549527024,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PINE",
    "name": "Pine",
    "isPopular": false,
    "chainId": "1",
    "address": "0x569424c5ee13884a193773fdc5d1c5f79c443a51",
    "decimals": 18,
    "image": "https://rango.vip/i/QEM0aU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00477852,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ACHI",
    "name": "ACHI INU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HvQKzBzp1YhXdqDUrUB8fqrfbJ6rU9CytzscMyG7EFAe",
    "decimals": 9,
    "image": "https://rango.vip/i/PKo69C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006747,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ACHI",
    "name": "achi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4rUfhWTRpjD1ECGjw1UReVhA8G63CrATuoFLRVRkkqhs",
    "decimals": 9,
    "image": "https://rango.vip/i/QBdWII",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00046037,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRF",
    "name": "Crafting Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x508df5aa4746be37b5b6a69684dfd8bdc322219d",
    "decimals": 18,
    "image": "https://rango.vip/i/iZkka6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00073008,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FAM",
    "name": "Family",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7njsg9BA1xvXX9DNpe5fERHK4zb7MbCHKZ6zsx5k3adr",
    "decimals": 9,
    "image": "https://rango.vip/i/cEkZtr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00031161,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FLOCKA",
    "name": "Waka Flocka",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9n8b1EXLCA8Z22mf7pjLKVNzuQgGbyPfLrmFQvEcHeSU",
    "decimals": 6,
    "image": "https://rango.vip/i/M1urQ5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00023555,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "CELT",
    "name": "CELT",
    "isPopular": false,
    "chainId": "66",
    "address": "0x5ab622494ab7c5e81911558c9552dbd517f403fb",
    "decimals": 18,
    "image": "https://rango.vip/i/3l6SWd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.00003208,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CELT",
    "name": "Celestial",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf6e06b54855eff198a2d9a8686113665499a6134",
    "decimals": 18,
    "image": "https://rango.vip/i/s6jH8x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003208,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOONEY",
    "name": "Moon DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20d4db1946859e2adb0e5acc2eac58047ad41395",
    "decimals": 18,
    "image": "https://rango.vip/i/DzCBLn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027914,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GFY",
    "name": "go fu k yourself ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2d9d7c64f6c00e16c28595ec4ebe4065ef3a250b",
    "decimals": 9,
    "image": "https://rango.vip/i/av2pwg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.74776e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WCADAI",
    "name": "CADAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4229725d41e2233d75b47675b55c6781df0b56a7",
    "decimals": 18,
    "image": "https://rango.vip/i/6T5pZk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.111887,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEXTF",
    "name": "DEXTF Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0",
    "decimals": 18,
    "image": "https://rango.vip/i/xWeltk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.113338,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "DEXTF",
    "name": "DEXTF Token",
    "isPopular": false,
    "chainId": "324",
    "address": "0x9929bcac4417a21d7e6fc86f6dae1cc7f27a2e41",
    "decimals": 18,
    "image": "https://rango.vip/i/pZ6BTJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.113338,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "YFX",
    "name": "YFX",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x569deb225441fd18bde18aed53e2ec7eb4e10d93",
    "decimals": 17,
    "image": "https://rango.vip/i/R6YAWb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02647186,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YFX",
    "name": "Your Futures Exchange",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf55a93b613d172b86c2ba3981a849dae2aecde2f",
    "decimals": 18,
    "image": "https://rango.vip/i/R6YAWb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02647186,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "YFX",
    "name": "YFX",
    "isPopular": false,
    "chainId": "128",
    "address": "0xf55a93b613d172b86c2ba3981a849dae2aecde2f",
    "decimals": 18,
    "image": "https://rango.vip/i/IV4dXe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.02647186,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUNCH",
    "name": "LunchDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x71a28feaee902966dc8d355e7b8aa427d421e7e0",
    "decimals": 18,
    "image": "https://rango.vip/i/M6R4b8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011186,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TITAN",
    "name": "IRON Titanium Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xaaa5b9e6c589642f98a1cda99b9d024b8407285a",
    "decimals": 18,
    "image": "https://rango.vip/i/o4mfOs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.7499e-8,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TITAN",
    "name": "TitanSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a8cccb969a61532d1e6005e2ce12c200caece87",
    "decimals": 18,
    "image": "https://rango.vip/i/nA5ELb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00110418,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TITAN",
    "name": "TitanSwap",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe898edc43920f357a93083f1d4460437de6daec2",
    "decimals": 18,
    "image": "https://rango.vip/i/cbiPfH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0010996859861848052,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FIST",
    "name": "FistToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc9882def23bc42d53895b8361d0b1edc7570bc6a",
    "decimals": 6,
    "image": "https://rango.vip/i/2MMl8J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00549237,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELMO",
    "name": "ElmoERC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x335f4e66b9b61cee5ceade4e727fcec20156b2f0",
    "decimals": 18,
    "image": "https://rango.vip/i/WLe6Vp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00291875,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ZPC",
    "name": "ZenPandaCoin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xee0b14e8fc86691cf6ee42b9954985b4cf968534",
    "decimals": 18,
    "image": "https://rango.vip/i/9qgJw3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.25051729220018e-10,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OASIS",
    "name": "Oasis Metaverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0dc9fc0669f068e04ad79f7d70618d3f9aad439",
    "decimals": 18,
    "image": "https://rango.vip/i/zONHOD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00175373,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OASIS",
    "name": "OASIS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa74a05b17d72e9b0781d973e7963dfaacd266b94",
    "decimals": 18,
    "image": "https://rango.vip/i/iOpZBl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033695,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XMX",
    "name": "XMax",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f8c45b896784a1e408526b9300519ef8660209c",
    "decimals": 8,
    "image": "https://rango.vip/i/Rrsb1m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001017,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POOWEL",
    "name": "JORAM POOWEL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BHcPVARUJEV3rCAmbLgRm7QPmZotsCcHcKWwzvCSAHJi",
    "decimals": 9,
    "image": "https://rango.vip/i/29XLp2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00130591,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRDC",
    "name": "Cardiocoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf81421fc15300c5a8cca9afe12f5cbad502fa756",
    "decimals": 18,
    "image": "https://rango.vip/i/HuY6QQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011347,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEN",
    "name": "Kenysians Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a7ef4998eb9d0f706238756949f311a59e05745",
    "decimals": 18,
    "image": "https://rango.vip/i/d0Bded",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.329185,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEN",
    "name": "Kendoll Janner",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2654e753424a9f02df31cfbc6c5af14a87b6cab5",
    "decimals": 18,
    "image": "https://rango.vip/i/z1BjPV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.68747e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEN",
    "name": "Ken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7316d973b0269863bbfed87302e11334e25ea565",
    "decimals": 9,
    "image": "https://rango.vip/i/Wb9AIP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040475,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOKU",
    "name": "Toku",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9eec1a4814323a7396c938bc86aec46b97f1bd82",
    "decimals": 18,
    "image": "https://rango.vip/i/CYlEzG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.56215e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZURR",
    "name": "ZURRENCY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x89509aa1d14a8e1e5364ec4c3b041213bcdbe08d",
    "decimals": 18,
    "image": "https://rango.vip/i/OJM8YD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "IST",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/92be0717f4678905e53f4e45b2ded18bc0cb97bf1f8b6a25afedf3d5a879b4d5",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/IST.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAK",
    "name": "Rake Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa8b0f154a688c22142e361707df64277e0a0be66",
    "decimals": 18,
    "image": "https://rango.vip/i/DGL9Vl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.67,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRVFRAX",
    "name": "Curve fi FRAX USDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3175df0976dfa876431c2e9ee6bc45b65d3473cc",
    "decimals": 18,
    "image": "https://rango.vip/i/LqSfIg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.003,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CCV2",
    "name": "CryptoCart V2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x612e1726435fe38dd49a0b35b4065b56f49c8f11",
    "decimals": 18,
    "image": "https://rango.vip/i/1c3Jyw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.993317,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DBX",
    "name": "DBX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3cbc780d2934d55a06069e837fabd3e6fc23dab0",
    "decimals": 18,
    "image": "https://rango.vip/i/TCCXST",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003505,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BCT",
    "name": "Toucan Protocol: Base Carbon Tonne",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2f800db0fdb5223b3c3f354886d907a671414a7f",
    "decimals": 18,
    "image": "https://rango.vip/i/Nd9T0J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.648232,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PSWAP",
    "name": "Polkaswap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x519c1001d550c0a1dae7d1fc220f7d14c2a521bb",
    "decimals": 18,
    "image": "https://rango.vip/i/k0fOMw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00050219,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QLINDO",
    "name": "QLINDO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc18c07a18198a6340cf4d94855fe5eb6dd33b46e",
    "decimals": 0,
    "image": "https://rango.vip/i/DjV82t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00058971,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MIM",
    "name": "Magic Internet Money",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x130966628846bfd36ff31a822705796e8cb8c18d",
    "decimals": 18,
    "image": "https://rango.vip/i/j6Pa3E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.002,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "AvaxChainV3",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "MIM",
    "name": "Magic Internet Money",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x0cae51e1032e8461f4806e26332c030e34de3adb",
    "decimals": 18,
    "image": "https://rango.vip/i/dAb0a7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.976483,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIM",
    "name": "Magic Internet Money",
    "isPopular": false,
    "chainId": "1",
    "address": "0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3",
    "decimals": 18,
    "image": "https://rango.vip/i/sViQzR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997273,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MIM",
    "name": "Magic Internet Money",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfe19f0b51438fd612f6fd59c1dbb3ea319f433ba",
    "decimals": 18,
    "image": "https://rango.vip/i/WFfeW3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.988845,
    "supportedSwappers": [
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MIM",
    "name": "Magic Internet Money",
    "isPopular": true,
    "chainId": "42161",
    "address": "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
    "decimals": 18,
    "image": "https://rango.vip/i/CpphDq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.999582,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "SushiArbitrum",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MIM",
    "name": "Magic Internet Money",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G33s1LiUADEBLzN5jL6ocSXqrT2wsUq9W6nZ8o4k1b4L",
    "decimals": 9,
    "image": "https://rango.vip/i/62Iihl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00024224,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MIM",
    "name": "Magic Internet Money",
    "isPopular": false,
    "chainId": "137",
    "address": "0x49a0400587a7f65072c87c4910449fdcc5c47242",
    "decimals": 18,
    "image": "https://rango.vip/i/IskWb2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.99201,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MIM",
    "name": "Magic Internet Money",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb20a02dffb172c474bc4bda3fd6f4ee70c04daf2",
    "decimals": 18,
    "image": "https://rango.vip/i/AX2Meq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.0008254849197773,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLM",
    "name": "CoinClaim",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b4c2708f052dca413600e237675e4d6778a9375",
    "decimals": 16,
    "image": "https://rango.vip/i/0u8rwf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002537,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUMP",
    "name": "Bumper",
    "isPopular": false,
    "chainId": "1",
    "address": "0x785c34312dfa6b74f6f1829f79ade39042222168",
    "decimals": 18,
    "image": "https://rango.vip/i/6lCS61",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01748775,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLEPE",
    "name": "Blepe the Blue",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3617e8a04265160b9ee10253a2c78565571cb76",
    "decimals": 18,
    "image": "https://rango.vip/i/rKc9uO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004217,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLEPE",
    "name": "Blepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53206bf5b6b8872c1bb0b3c533e06fde2f7e22e4",
    "decimals": 18,
    "image": "https://rango.vip/i/Q0zkG8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003489,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YDF",
    "name": "Yieldification",
    "isPopular": false,
    "chainId": "1",
    "address": "0x30dcba0405004cf124045793e1933c798af9e66a",
    "decimals": 18,
    "image": "https://rango.vip/i/JnlfQo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00054631,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIMBOT",
    "name": "AimBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c48250eb1f29491f1efbeec0261eb556f0973c7",
    "decimals": 18,
    "image": "https://rango.vip/i/HkoSzt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.662295,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SI",
    "name": "sealwifhat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fxgdfsy1Z5Mvh53o69s2Ev6TGxtAJ1RQ5RJ5moCpKmQZ",
    "decimals": 9,
    "image": "https://rango.vip/i/u5tMeP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00014772,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SI",
    "name": "SIREN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd23ac27148af6a2f339bd82d0e3cff380b5093de",
    "decimals": 18,
    "image": "https://rango.vip/i/XWgjte",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03761634,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BEBE",
    "name": "Bebe on Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xef8a84eb92afd22a115d5e81b2c3c605b866f044",
    "decimals": 18,
    "image": "https://rango.vip/i/tEjZs0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000573,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEBE",
    "name": "BEBE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xec21890967a8ceb3e55a3f79dac4e90673ba3c2e",
    "decimals": 8,
    "image": "https://rango.vip/i/uG8DWg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.947e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EGS",
    "name": "EdgeSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb009bfaaf85e53f55d8657781eb69feaaed83672",
    "decimals": 18,
    "image": "https://rango.vip/i/nn4oXk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00061883,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LINQ",
    "name": "Linq",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e34eabf5858a126cb583107e643080cee20ca64",
    "decimals": 18,
    "image": "https://rango.vip/i/DuTbcr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02817628,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RAM",
    "name": "Ramses Exchange",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xaaa6c1e32c55a7bfa8066a6fae9b42650f262418",
    "decimals": 18,
    "image": "https://rango.vip/i/zD36nL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01493569,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOAD",
    "name": "LuckyToadv3",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbfb2b6870501a6ff17121d676a0a45a38c9eed1e",
    "decimals": 9,
    "image": "https://rango.vip/i/Wpf019",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00055922,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOAD",
    "name": "TOAD KILLER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x370a366f402e2e41cdbbe54ecec12aae0cce1955",
    "decimals": 18,
    "image": "https://rango.vip/i/mByr1b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.886e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOAD",
    "name": "TOAD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x01e1d7cbd3bc0eb1030485f33708421011459459",
    "decimals": 18,
    "image": "https://rango.vip/i/j2ocgO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.69838e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DADDY",
    "name": "DADDY TATE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4Cnk9EPnW5ixfLZatCPJjDB1PUtcRpVVgTQukm9epump",
    "decimals": 6,
    "image": "https://rango.vip/i/hg80IZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.079702,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DADDY",
    "name": "DADDY ANSEM",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2J5uSgqgarWoh7QDBmHSDA3d7UbfBKDZsdy1ypTSpump",
    "decimals": 6,
    "image": "https://rango.vip/i/EWDa7m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.07955176527081526,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DD",
    "name": "DuckDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d51888c5abb7cdfa9cdd6a50673c7f8afaccd7f",
    "decimals": 18,
    "image": "https://rango.vip/i/vkdBZi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.36888,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DD",
    "name": "Diment Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x71b3a0566f4bf80331d115d8026a7022bf670cce",
    "decimals": 6,
    "image": "https://rango.vip/i/nLNHUS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999613,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASH",
    "name": "ASH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64d91f12ece7362f91a6f8e7940cd55f05060b92",
    "decimals": 18,
    "image": "https://rango.vip/i/HVyKqH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.27,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIR",
    "name": "Ape In Records",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf725f73caee250ae384ec38bb2c77c38ef2cccea",
    "decimals": 18,
    "image": "https://rango.vip/i/vTD0Y0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016163,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIR",
    "name": "AIR Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x294b9da569c0d694870239813bbe7b5824fd2339",
    "decimals": 18,
    "image": "https://rango.vip/i/HpWAk4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.079386,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AIR",
    "name": "AIR",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd8a2ae43fd061d24acd538e3866ffc2c05151b53",
    "decimals": 18,
    "image": "https://rango.vip/i/RvsBYo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.07952402670919141,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "AIR",
    "name": "AIRcoin On Blast",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x6cc87c5ab2cfac0f2d582286f5ba69ca555fecb0",
    "decimals": 18,
    "image": "https://rango.vip/i/RPVI6Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00005965,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIC",
    "name": "MIC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x368b3a58b5f49392e5c9e4c998cb0bb966752e51",
    "decimals": 18,
    "image": "https://rango.vip/i/OF008f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00023363692912390743,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIC",
    "name": "Magic Internet Cash",
    "isPopular": false,
    "chainId": "1",
    "address": "0x285db79fa7e0e89e822786f48a7c98c6c1dc1c7d",
    "decimals": 18,
    "image": "https://rango.vip/i/EqxIpm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00023298,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AFG",
    "name": "Army of Fortune Gem",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x619c82392cb6e41778b7d088860fea8447941f4c",
    "decimals": 18,
    "image": "https://rango.vip/i/xBoSeI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00726426,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KAMA",
    "name": "Kamala Horris",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HnKkzR1YtFbUUxM6g3iVRS2RY68KHhGV7bNdfF1GCsJB",
    "decimals": 9,
    "image": "https://rango.vip/i/0awtIf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00807094,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NOTE",
    "name": "SolNote",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CgbcLNUZP7jWmFeXtaKmABRFXDhxejkeUH94JLu3Lcvk",
    "decimals": 6,
    "image": "https://rango.vip/i/c8rwZj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01745896,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOTE",
    "name": "Notional",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcfeaead4947f0705a14ec42ac3d44129e1ef3ed5",
    "decimals": 8,
    "image": "https://rango.vip/i/0ZrH0q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04814261,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DG",
    "name": "Decentral Games (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xef938b6da8576a896f6e0321ef80996f4890f9c4",
    "decimals": 18,
    "image": "https://rango.vip/i/c2CWZJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01399869,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DG",
    "name": "DeGate Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53c8395465a84955c95159814461466053dedede",
    "decimals": 18,
    "image": "https://rango.vip/i/p7n09M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.084913,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DG",
    "name": "Decentral Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b520c812e8430659fc9f12f6d0c39026c83588d",
    "decimals": 18,
    "image": "https://rango.vip/i/ucgGmH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01399869,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DG",
    "name": "Decentral Games",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9fdc3ae5c814b79dca2556564047c5e7e5449c19",
    "decimals": 18,
    "image": "https://rango.vip/i/8g7ItG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 11.47,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MUMU",
    "name": "Mumu the Bull",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5LafQUrVco6o7KMz42eqVEJ9LW31StPyGjeeu5sKoMtA",
    "decimals": 6,
    "image": "https://rango.vip/i/UcsjZv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003314,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUMU",
    "name": "Mumu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f573070e6090b3264fe707e2c9f201716f123c7",
    "decimals": 18,
    "image": "https://rango.vip/i/chO8tu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.208e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VERI",
    "name": "Veritaseum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f3470a7388c05ee4e7af3d01d8c722b0ff52374",
    "decimals": 18,
    "image": "https://rango.vip/i/3UfxKi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 15.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNPAD",
    "name": "SNPad",
    "isPopular": false,
    "chainId": "1",
    "address": "0x772358ef6ed3e18bde1263f7d229601c5fa81875",
    "decimals": 18,
    "image": "https://rango.vip/i/H0mlQp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01941529,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VONSPEED",
    "name": "Andrea Von Speed",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DVzrCErBzydh92bBzSJX1dKwVvb4omwhrvNz8CwRyxxV",
    "decimals": 6,
    "image": "https://rango.vip/i/O8rj1y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00014855,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAXE",
    "name": "WAX Economic Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a2bc711e19ba6aff6ce8246c546e8c4b4944dfd",
    "decimals": 8,
    "image": "https://rango.vip/i/JHJ4q2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 28.62,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GECKO",
    "name": "Gecko",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6CNHDCzD5RkvBWxxyokQQNQPjFWgoHF94D7BmC73X6ZK",
    "decimals": 6,
    "image": "https://rango.vip/i/9sUVJA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001189,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BURP",
    "name": "Burp",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33f391f4c4fe802b70b77ae37670037a92114a7c",
    "decimals": 18,
    "image": "https://rango.vip/i/a6xjac",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00037593,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "OFF",
    "name": "BlastOff",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xd55edfc79c0d14084260d16f38bda75e28abfb6a",
    "decimals": 8,
    "image": "https://rango.vip/i/eIncyq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.089169,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPHYNX",
    "name": "Sphynx Labs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1901f826dfcbfd9d3138936932366b3493a50893",
    "decimals": 18,
    "image": "https://rango.vip/i/D3C5kh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00181918,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TIDE",
    "name": "Tidalflats",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0a6136f866684c0f19936c0c42a8c181c066f1b",
    "decimals": 18,
    "image": "https://rango.vip/i/qKisVC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00104531,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FBX",
    "name": "FireBotToken",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd125443f38a69d776177c2b9c041f462936f8218",
    "decimals": 18,
    "image": "https://rango.vip/i/gHiWsR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.321063,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FBX",
    "name": "FinanceBlocks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60bb16c4a931b1a0b8a7d945c651dd90f41d42cf",
    "decimals": 18,
    "image": "https://rango.vip/i/cQzz2f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005804,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FBX",
    "name": "FBX Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5de597849cf72c72f073e9085bdd0dadd8e6c199",
    "decimals": 18,
    "image": "https://rango.vip/i/CDqXRD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003697,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLNIC",
    "name": "Solnic",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DeaKMzAeZja3Mh5okZE6WUvygLP3Lfuvm6Rg78HqXTz9",
    "decimals": 6,
    "image": "https://rango.vip/i/oBs3Rn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0011442053062723214,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMAID",
    "name": "MaidSafeCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x329c6e459ffa7475718838145e5e85802db2a303",
    "decimals": 18,
    "image": "https://rango.vip/i/fgA4Dg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.448117,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAPP",
    "name": "Cappasity",
    "isPopular": false,
    "chainId": "1",
    "address": "0x11613b1f840bb5a40f8866d857e24da126b79d73",
    "decimals": 2,
    "image": "https://rango.vip/i/p4tkbp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006201,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GROW",
    "name": "ValleyDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x761a3557184cbc07b7493da0661c41177b2f97fa",
    "decimals": 18,
    "image": "https://rango.vip/i/NkylQY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.4041,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "GROW",
    "name": "ValleyDAO Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc871ccf95024efa2cbce69b5b775d2a1dcf49c1b",
    "decimals": 18,
    "image": "https://rango.vip/i/5ST3VQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.2526339792868314,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACL",
    "name": "Auction Light",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15c12530382e8653904e81b8aad7ffdef55b0256",
    "decimals": 18,
    "image": "https://rango.vip/i/1hEgOG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00035022,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PURSE",
    "name": "Pundi X PURSE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95987b0cdc7f65d989a30b3b7132a38388c548eb",
    "decimals": 18,
    "image": "https://rango.vip/i/RtRpbY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014205,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PURSE",
    "name": "PURSE TOKEN",
    "isPopular": false,
    "chainId": "56",
    "address": "0x29a63f4b209c29b4dc47f06ffa896f32667dad2c",
    "decimals": 18,
    "image": "https://rango.vip/i/8eq7Io",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00014205,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YUGE",
    "name": "YUGE COIN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fpc2tnmme78kjsttyuSjfUfgB14vk15a3P13P9zZYvov",
    "decimals": 6,
    "image": "https://rango.vip/i/Y73EAw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00027482322893020904,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YUGE",
    "name": "Yuge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8854d278bdb3140c161bf011888d9dc7a5918e77",
    "decimals": 9,
    "image": "https://rango.vip/i/M1bh3f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.1866e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VEIL",
    "name": "Veil Exchange",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb244b3574a5627849fca2057e3854340def63071",
    "decimals": 18,
    "image": "https://rango.vip/i/nBXqmJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00060114,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WHIRL",
    "name": "Whirl",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb7037457de15fed6cbecc0c62d5d610834b958ec",
    "decimals": 18,
    "image": "https://rango.vip/i/C0V7pb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.207248,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WHIRL",
    "name": "Whirl Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7f479d78380ad00341fdd7322fe8aef766e29e5a",
    "decimals": 18,
    "image": "https://rango.vip/i/3LUhZE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.21150647447539017,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KIBA",
    "name": "Kiba Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x005d1123878fc55fbd56b54c73963b234a64af3c",
    "decimals": 18,
    "image": "https://rango.vip/i/izSUyB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000145,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTO",
    "name": "Bottos",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36905fc93280f52362a1cbab151f25dc46742fb5",
    "decimals": 18,
    "image": "https://rango.vip/i/zCj7WB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00032022,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "C2H6",
    "name": "Ethane",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6ad9a31f02f1e790ff85584ea3c3d0001e45cd64",
    "decimals": 9,
    "image": "https://rango.vip/i/m7uLPi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.017,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "AXL",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/903a61a498756ea560b85a85132d3aee21b5dedd41213725d22abf276ea6945e",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/AXL.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.491815,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AXL",
    "name": "Axelar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x467719ad09025fcc6cf6f8311755809d45a5e5f3",
    "decimals": 6,
    "image": "https://rango.vip/i/Tj4Ctt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.491815,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AXL",
    "name": "Axelar",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8b1f4432f943c465a973fedc6d7aa50fc96f1f65",
    "decimals": 6,
    "image": "https://rango.vip/i/FHs9vF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.491815,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AXL",
    "name": "Axelar",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6e4e624106cb12e168e6533f8ec7c82263358940",
    "decimals": 6,
    "image": "https://rango.vip/i/wh2iXW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.491815,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AXL",
    "name": "Axelar",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x23ee2343b892b1bb63503a4fabc840e0e2c6810f",
    "decimals": 6,
    "image": "https://rango.vip/i/aNvtrx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.491815,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AXL",
    "name": "AXL INU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x25b24b3c47918b7962b3e49c4f468367f73cc0e0",
    "decimals": 18,
    "image": "https://rango.vip/i/JHguhf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001063,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AXL",
    "name": "Axelar",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x23ee2343b892b1bb63503a4fabc840e0e2c6810f",
    "decimals": 6,
    "image": "https://rango.vip/i/17GpGf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.491815,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOICHI",
    "name": "Hoichi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4ee0aa2d993ca7c9263ecfa26c6f7e13009d2b6",
    "decimals": 18,
    "image": "https://rango.vip/i/MKBfwf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000163,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "2DAI",
    "name": "2DAI io",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb44b653f147569d88a684cbf6549e1968e8b2a1d",
    "decimals": 18,
    "image": "https://rango.vip/i/2cpQJf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00112762,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFD",
    "name": "Feisty Doge NFT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdfdb7f72c1f195c5951a234e8db9806eb0635346",
    "decimals": 18,
    "image": "https://rango.vip/i/Zi4kDY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011082,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NFD",
    "name": "Feisty Doge NFT",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc9c2b86cd4cdbab70cd65d22eb044574c3539f6c",
    "decimals": 18,
    "image": "https://rango.vip/i/Zi4kDY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00011387250545341195,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NFD",
    "name": "Feisty Doge NFT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CY2E69dSG9vBsMoaXDvYmMDSMEP4SZtRY1rqVQ9tkNDu",
    "decimals": 8,
    "image": "https://rango.vip/i/ePyrwr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00011387250545341195,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BEETS",
    "name": "Beethoven X",
    "isPopular": false,
    "chainId": "10",
    "address": "0x97513e975a7fa9072c72c92d8000b0db90b163c5",
    "decimals": 18,
    "image": "https://rango.vip/i/8mgpUw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.017987052206068888,
    "supportedSwappers": [
      "OpenOceanOptimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUNG",
    "name": "Scarab Tools",
    "isPopular": false,
    "chainId": "1",
    "address": "0x84412819ae69b10250d0d54d58f454018f1c8a42",
    "decimals": 18,
    "image": "https://rango.vip/i/g0x3oB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0018039,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XRGB",
    "name": "XRGB",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5cc5e64ab764a0f1e97f23984e20fd4528356a6a",
    "decimals": 18,
    "image": "https://rango.vip/i/7u9zo1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01591142,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XRGB",
    "name": "XRGB",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5cc5e64ab764a0f1e97f23984e20fd4528356a6a",
    "decimals": 18,
    "image": "https://rango.vip/i/wl9DKI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01591142,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UPLOAD",
    "name": "UPLOAD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd3cc3b3e226cf187181c57f8bcc2fa84250df651",
    "decimals": 18,
    "image": "https://rango.vip/i/Fldoo9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00035575,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BARC",
    "name": "The Blu Arctic Water Comp",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c4a50a61bed3b9024d8ffc1f1d168dc8cb1c689",
    "decimals": 9,
    "image": "https://rango.vip/i/YTM7BD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00863414,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CGT",
    "name": "Curio Gas Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e186357c323c806c1efdad36d217f7a54b63d18",
    "decimals": 18,
    "image": "https://rango.vip/i/mAwFDl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00907286,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "DODO",
    "name": "DODO Bird",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xe301ed8c7630c9678c39e4e45193d1e7dfb914f7",
    "decimals": 18,
    "image": "https://rango.vip/i/ztm0so",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.10581,
    "supportedSwappers": [
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "DODO",
    "name": "DODO",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xe9460bd2ffb12b668fa32919c785c239f974d37c",
    "decimals": 18,
    "image": "https://rango.vip/i/576lQz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.10611045368568929,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DODO",
    "name": "DODO bird",
    "isPopular": false,
    "chainId": "56",
    "address": "0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2",
    "decimals": 18,
    "image": "https://rango.vip/i/yOW5Fs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.10581,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DODO",
    "name": "DODO bird",
    "isPopular": false,
    "chainId": "1",
    "address": "0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd",
    "decimals": 18,
    "image": "https://rango.vip/i/X227hb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.10581,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DODO",
    "name": "DODO bird",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x69eb4fa4a2fbd498c257c57ea8b7655a2559a581",
    "decimals": 18,
    "image": "https://rango.vip/i/t4YqX6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.10581,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DODO",
    "name": "DODO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x747e550a7b848ace786c3cfe754aa78febc8a022",
    "decimals": 18,
    "image": "https://rango.vip/i/M1VOsc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.158728,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRG",
    "name": "The Rug Game",
    "isPopular": false,
    "chainId": "1",
    "address": "0x93eeb426782bd88fcd4b48d7b0368cf061044928",
    "decimals": 18,
    "image": "https://rango.vip/i/Zvlpl6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.0512e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SDT",
    "name": "Stake DAO Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x07715ee7219b07b8e01cc7d2787f4e5e75860383",
    "decimals": 18,
    "image": "https://rango.vip/i/bPh59e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.26152637850582855,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDT",
    "name": "Stake DAO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f",
    "decimals": 18,
    "image": "https://rango.vip/i/0YycZS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.257625,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SDT",
    "name": "Stake DAO Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7ba4a00d54a07461d9db2aef539e91409943adc9",
    "decimals": 18,
    "image": "https://rango.vip/i/L4Lzmo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.257625,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SDT",
    "name": "Stake DAO Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x361a5a4993493ce00f61c32d4ecca5512b82ce90",
    "decimals": 18,
    "image": "https://rango.vip/i/KKK2KO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.257625,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QTC",
    "name": "Quantum Cloak",
    "isPopular": false,
    "chainId": "1",
    "address": "0x22c158a3f3ea3419176c083aa11eb593e94965dc",
    "decimals": 18,
    "image": "https://rango.vip/i/AiNO5V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017829,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNT",
    "name": "Mantle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c3a81e81dc49a522a592e7622a7e711c06bf354",
    "decimals": 18,
    "image": "https://rango.vip/i/9tDJUl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.552385,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNT",
    "name": "Minterest",
    "isPopular": false,
    "chainId": "1",
    "address": "0x149cac67f1cd5d80651e7c9bb359ec285d821a05",
    "decimals": 18,
    "image": "https://rango.vip/i/xYO9oe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.5536470277887985,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HBDC",
    "name": "Happy Birthday Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39d30828a163713d91c4eadbba2c497a9139ec5c",
    "decimals": 18,
    "image": "https://rango.vip/i/TvLqMm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00177412,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORACLE",
    "name": "ORACLE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x208bcf9cd1d694d1d3c630362e517940e3724d80",
    "decimals": 9,
    "image": "https://rango.vip/i/2qHBmm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000855,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORACLE",
    "name": "Oracle AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x57b49219614859176ddb029298486b6c30193cbd",
    "decimals": 18,
    "image": "https://rango.vip/i/mRRoQJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017476,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LAMBO",
    "name": " LAMBO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d2b66bc4f9d6388bd2d97b95b565be1686aefb3",
    "decimals": 18,
    "image": "https://rango.vip/i/xH20dL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1e-18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "RIB",
    "name": "RiverBoat",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xbd90a6125a84e5c512129d622a75cdde176ade5e",
    "decimals": 18,
    "image": "https://rango.vip/i/b1NtkF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 281.6446544203193,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRIBL",
    "name": "Tribal Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6988a804c74fd04f37da1ea4781cea68c9c00f86",
    "decimals": 18,
    "image": "https://rango.vip/i/0V0W1R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.004,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MND",
    "name": "Mind",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0dd6dd7775e93eb842db0aa142c9c581031ed3b",
    "decimals": 18,
    "image": "https://rango.vip/i/mPNUKM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01750236,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MND",
    "name": "Mound Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4c97c901b5147f8c1c7ce3c5cf3eb83b44f244fe",
    "decimals": 18,
    "image": "https://rango.vip/i/innYZn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02196807,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MPS",
    "name": "Mt Pelerin Shares",
    "isPopular": false,
    "chainId": "1",
    "address": "0x96c645d3d3706f793ef52c19bbace441900ed47d",
    "decimals": 0,
    "image": "https://rango.vip/i/V9gqwL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.98,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LANDWU",
    "name": "Land Wu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x67c4d14861f9c975d004cfb3ac305bee673e996e",
    "decimals": 9,
    "image": "https://rango.vip/i/dGw95i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.578e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOX",
    "name": "BOX Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x63f584fa56e60e4d0fe8802b27c7e6e3b33e007f",
    "decimals": 18,
    "image": "https://rango.vip/i/00ifUM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016561,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SEAL",
    "name": "Seal",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3B3Zfs7eb46Re9GHWv6ccYRSBGy5EvQF2i2VXMD6tge6",
    "decimals": 6,
    "image": "https://rango.vip/i/EhQxEg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.318816,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SYNO",
    "name": "Synonym Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x577fd586c9e6ba7f2e85e025d5824dbe19896656",
    "decimals": 18,
    "image": "https://rango.vip/i/Ar0VKK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00609886,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XALPHA",
    "name": "XALPHA AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x369733153e6e08d38f2bc72ae2432e855cfbe221",
    "decimals": 18,
    "image": "https://rango.vip/i/yW5q5R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02316653,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "EQB",
    "name": "Equilibria Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x374ca32fd7934c5d43240e1e73fa9b2283468609",
    "decimals": 18,
    "image": "https://rango.vip/i/R3fkCf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.188243,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EQB",
    "name": "Equilibria Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe80d611c6403f70e5b1b9b722d2b3510b740b2b",
    "decimals": 18,
    "image": "https://rango.vip/i/trL1YW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.188243,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "EQB",
    "name": "Equilibria Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xaf3a6f67af1624d3878a8d30b09fae7915dca2a0",
    "decimals": 18,
    "image": "https://rango.vip/i/BIkckM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.18776566550346788,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CIRCLE",
    "name": "Burning Circle",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x5babfc2f240bc5de90eb7e19d789412db1dec402",
    "decimals": 18,
    "image": "https://rango.vip/i/af2w7D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.8,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CIRCLE",
    "name": "You Looked",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EkHr62PC6Y1axrLS7cR8YC4BZeW19mtHxQLCLMrf9vnq",
    "decimals": 3,
    "image": "https://rango.vip/i/LATaSG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00043002,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "BTSG",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/4e5444c35610cc76fc94e7f7886b93121175c28262ddfdde6f84e82bf2425452",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/BTSG.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.02324438,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "BITSONG",
    "symbol": "BTSG",
    "name": null,
    "isPopular": true,
    "chainId": "bitsong-2b",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/O0dhoY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BITSONG/icon.svg",
    "usdPrice": 0.023225326516526753,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BALL",
    "name": "Game 5 BALL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x393bf304dd474f48210f5ce741f19a2a851703ca",
    "decimals": 18,
    "image": "https://rango.vip/i/g0HxJF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00210284,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ZKID",
    "name": "zkSync id",
    "isPopular": false,
    "chainId": "324",
    "address": "0x2141d7fe06a1d69c016fc638ba75b6ef92fa1435",
    "decimals": 18,
    "image": "https://rango.vip/i/k3cemX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00307763,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YESP",
    "name": "Yesports",
    "isPopular": false,
    "chainId": "1",
    "address": "0x46cca329970b33e1a007dd4ef0594a1cedb3e72a",
    "decimals": 18,
    "image": "https://rango.vip/i/1fVtI6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017584,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KIZUNA",
    "name": "Kizuna",
    "isPopular": false,
    "chainId": "1",
    "address": "0x470c8950c0c3aa4b09654bc73b004615119a44b5",
    "decimals": 18,
    "image": "https://rango.vip/i/09T0mP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.94e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NANA",
    "name": "NANA Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HxRELUQfvvjToVbacjr9YECdfQMUqGgPYB68jVDYxkbr",
    "decimals": 9,
    "image": "https://rango.vip/i/oDi0a7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00113705,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTCINU",
    "name": "Bitcoin Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x584a4dd38d28fd1ea0e147ba7b70aed29a37e335",
    "decimals": 18,
    "image": "https://rango.vip/i/3gE5eJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02805902,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USDR",
    "name": "USDR",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb5dfabd7ff7f83bab83995e72a52b97abb7bcf63",
    "decimals": 9,
    "image": "https://rango.vip/i/RQuY6o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.6075668008518307,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "REGEN",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/1dcc8a6cb5689018431323953344a9f6cc4d0bfb261e88c9f7777372c10cd076",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/REGEN.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.01384246,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "REGEN",
    "symbol": "REGEN",
    "name": null,
    "isPopular": true,
    "chainId": "regen-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/0xDJl3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/REGEN/icon.svg",
    "usdPrice": 0.0138567217317188,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TUZKI",
    "name": "Tuzki",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d68015171eaa7af9a5a0a103664cf1e506ff699",
    "decimals": 9,
    "image": "https://rango.vip/i/ay0jqV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.5733e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLURRY",
    "name": "Flurry Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60f63b76e2fc1649e57a3489162732a90acf59fe",
    "decimals": 18,
    "image": "https://rango.vip/i/rD6CbJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003568,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0KN",
    "name": "0 Knowledge Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4594cffbfc09bc5e7ecf1c2e1c1e24f0f7d29036",
    "decimals": 18,
    "image": "https://rango.vip/i/Ml5hcW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00032078,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ETH2X-FLI",
    "name": "ETH 2x Flexible Leverage Index PoS",
    "isPopular": false,
    "chainId": "137",
    "address": "0x66d7fdcc7403f18cae9b0e2e8385649d2acbc12a",
    "decimals": 18,
    "image": "https://rango.vip/i/rZBzIR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 11.27,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETH2X-FLI",
    "name": "Index Coop   ETH 2x Flexible Leverage I",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd",
    "decimals": 18,
    "image": "https://rango.vip/i/XDUNpr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 11.27,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JIZZUS",
    "name": "JIZZUS CHRIST",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7vQdgLtR2Qo6MBotkKVmCKfw83fGZkFQLR3Cj6X3ZRF7",
    "decimals": 7,
    "image": "https://rango.vip/i/mOIhCu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 7.361181889311425e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "QKNTL",
    "name": "Quick Intel",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xbcd4d5ac29e06e4973a1ddcd782cd035d04bc0b7",
    "decimals": 18,
    "image": "https://rango.vip/i/Jv5wTi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01476349,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QKNTL",
    "name": "Quick Intel",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbcd4d5ac29e06e4973a1ddcd782cd035d04bc0b7",
    "decimals": 18,
    "image": "https://rango.vip/i/Jv5wTi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01476349,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LDZ",
    "name": "Voodoo",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E5ZVeBMazQAYq4UEiSNRLxfMeRds9SKL31yPan7j5GJK",
    "decimals": 9,
    "image": "https://rango.vip/i/sxpXI7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00212655,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KUMA",
    "name": "Kuma Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x48c276e8d03813224bb1e55f953adb6d02fd3e02",
    "decimals": 18,
    "image": "https://rango.vip/i/Hk0SVw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.192e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOOK",
    "name": "Solbook",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4nFwuKievw5wcpcXtUDdfxWLyXsEdvgkpENzC9M9Y5me",
    "decimals": 6,
    "image": "https://rango.vip/i/CTFYAV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01254844,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BOOK",
    "name": "Bookie",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8192759bf7f247cc92f74e39b3a4225516624fc1",
    "decimals": 8,
    "image": "https://rango.vip/i/8LNFyJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.010672312840297496,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BLOOM",
    "name": "Bloomer",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xafb5d4d474693e68df500c9c682e6a2841f9661a",
    "decimals": 18,
    "image": "https://rango.vip/i/4DJSti",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00004569,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOOM",
    "name": "BloomBeans BLOOM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c4a8973e6633da2da7187669479c27830c7b1c4",
    "decimals": 18,
    "image": "https://rango.vip/i/QJSG68",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 179.67,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BD20",
    "name": "BRC 20 DEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x312d43881860807fa04b193d69744d087fc3308a",
    "decimals": 18,
    "image": "https://rango.vip/i/YjHTWd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00035848,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PREMIA",
    "name": "Premia",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x51fc0f6660482ea73330e414efd7808811a57fa2",
    "decimals": 18,
    "image": "https://rango.vip/i/MtAljA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.128377,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PREMIA",
    "name": "Premia",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70",
    "decimals": 18,
    "image": "https://rango.vip/i/WlENpA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.128377,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "PREMIA",
    "name": "Premia",
    "isPopular": false,
    "chainId": "10",
    "address": "0x374ad0f47f4ca39c78e5cc54f1c9e426ff8f231a",
    "decimals": 18,
    "image": "https://rango.vip/i/Z1PAgM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.128377,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RADIO",
    "name": "RadioShack",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a5d3a9dcd33cb8d527f7b5f96eb4fef43d55636",
    "decimals": 18,
    "image": "https://rango.vip/i/CHyMmX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00042382,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KOY",
    "name": "Koyo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x198065e69a86cb8a9154b333aad8efe7a3c256f8",
    "decimals": 18,
    "image": "https://rango.vip/i/uq0rh4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00073604,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "YUP",
    "name": "Yup",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x01ccf4941298a0b5ac4714c0e1799a2df8387048",
    "decimals": 18,
    "image": "https://rango.vip/i/SHacbN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.02962033,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YUP",
    "name": "YUP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69bbc3f8787d573f1bbdd0a5f40c7ba0aee9bcc9",
    "decimals": 18,
    "image": "https://rango.vip/i/SHacbN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02962033,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "QSR",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/1b708808d372e959cd4839c594960309283424c775f4a038aaebe7f83a988477",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/QSR.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0201256006262541,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PHM",
    "name": "Phantom Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f9bec82c776c47405bcb38070d2395fd18f89d3",
    "decimals": 18,
    "image": "https://rango.vip/i/vjpjru",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007529,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASTRO",
    "name": "AstroTools.io",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcbd55d4ffc43467142761a764763652b48b969ff",
    "decimals": 18,
    "image": "https://rango.vip/i/iUlh77",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.057428,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PROPHET",
    "name": "Prophet",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa9fbcc25435ad713a9468d8c89dd7baae8914e3a",
    "decimals": 18,
    "image": "https://rango.vip/i/WAR6hS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.95,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PROPHET",
    "name": "Prophet of Ethereum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3fa55eb91be2c5d72890da11a4c0269e7f786555",
    "decimals": 18,
    "image": "https://rango.vip/i/hKP7PR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00048989,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AQTIS",
    "name": "AQTIS Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6ff2241756549b5816a177659e766eaf14b34429",
    "decimals": 18,
    "image": "https://rango.vip/i/SNqsm9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00183827,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RNT",
    "name": "REAL NIGGER TATE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2fUFhZyd47Mapv9wcfXh5gnQwFXtqcYu9xAN4THBpump",
    "decimals": 6,
    "image": "https://rango.vip/i/I4l3Ff",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00386829,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TYPE",
    "name": "TypeAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x443459d45c30a03f90037d011cbe22e2183d3b12",
    "decimals": 18,
    "image": "https://rango.vip/i/6MtZfm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.320457,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MZERO",
    "name": "MetaZero",
    "isPopular": false,
    "chainId": "1",
    "address": "0x328a268b191ef593b72498a9e8a481c086eb21be",
    "decimals": 18,
    "image": "https://rango.vip/i/0Dwu1f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01058088,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "MFAM",
    "name": "MFAM",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xbb8d88bcd9749636bc4d2be22aac4bb3b01a58f1",
    "decimals": 18,
    "image": "https://rango.vip/i/do63Gp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.00093235,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MBLK",
    "name": "Magical Blocks",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf47245e9a3ba3dca8b004e34afc1290b1d435a52",
    "decimals": 18,
    "image": "https://rango.vip/i/BuO4DL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00279379,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SIPHER",
    "name": "Sipher Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f52c8ecbee10e00d9faaac5ee9ba0ff6550f511",
    "decimals": 18,
    "image": "https://rango.vip/i/vlGgJz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.112962,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SIPHER",
    "name": "Sipher Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xb94944669f7967e16588e55ac41be0d5ef399dcd",
    "decimals": 18,
    "image": "https://rango.vip/i/EBfpuG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.11285606572684602,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MILEI",
    "name": "MILEI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "43N5UGr3mnfhJFzpBPNM7ZdnobghiYBtHTaQfZQv65mh",
    "decimals": 6,
    "image": "https://rango.vip/i/GsTcy1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004115,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MILEI",
    "name": "MILEI Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a11ea9d61588d756d9f1014c3cf0d226aedd279",
    "decimals": 18,
    "image": "https://rango.vip/i/ZJpLAz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0078499,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MILEI",
    "name": "MILEI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb71bdc7014f3740d0267d41d632cab8371f8ba3c",
    "decimals": 18,
    "image": "https://rango.vip/i/S7i30V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.75048e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BADCAT",
    "name": "Andy Alter Ego",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe4042c7c1bf740b8ddb2ab43df6d9ed766b2513e",
    "decimals": 9,
    "image": "https://rango.vip/i/oJQf2e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.93593e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DACAT",
    "name": "daCat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x814a870726edb7dfc4798300ae1ce3e5da0ac467",
    "decimals": 18,
    "image": "https://rango.vip/i/WLc0X2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.254e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "STEAK",
    "name": "SteakHut Finance",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb279f8dd152b99ec1d84a489d32c35bc0c7f5674",
    "decimals": 18,
    "image": "https://rango.vip/i/O6r6BF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.310146,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STEAK",
    "name": "Steak",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4c244f1dbca07083fee35220d2169957c275e68",
    "decimals": 18,
    "image": "https://rango.vip/i/44HLqj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.49,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ALMC",
    "name": "AWKWARD LOOK MONKEY CLUB",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7f94zk1EgfoeG57Vj5FtDDjMmPNHM4DYs7KRiyd2T4bA",
    "decimals": 9,
    "image": "https://rango.vip/i/0gyXCW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00007962,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XDOGE",
    "name": "XDOGE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2b274cfbf9534f56b59ad0fb7e645e0354f4941",
    "decimals": 8,
    "image": "https://rango.vip/i/LXTIaS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.669e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BREWLABS",
    "name": "Brewlabs",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdad33e12e61dc2f2692f2c12e6303b5ade7277ba",
    "decimals": 9,
    "image": "https://rango.vip/i/Rb1v5R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00263992,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTC2.0",
    "name": "Bitcoin 2.0",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3feb4fea5132695542f8ede5076ac43296d17c6d",
    "decimals": 8,
    "image": "https://rango.vip/i/ZQcmrE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03324963,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZARP",
    "name": "ZARP Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb755506531786c8ac63b756bab1ac387bacb0c04",
    "decimals": 18,
    "image": "https://rango.vip/i/vza9D8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.054435,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "COLLAB",
    "name": "Collab.Land",
    "isPopular": false,
    "chainId": "10",
    "address": "0x8b21e9b7daf2c4325bf3d18c1beb79a347fe902a",
    "decimals": 18,
    "image": "https://rango.vip/i/QItY6s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00815854,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAN",
    "name": "Pantos Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x536381a8628dbcc8c70ac9a30a7258442eab4c92",
    "decimals": 8,
    "image": "https://rango.vip/i/qR5zQ0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.108564,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RVST",
    "name": "Revest",
    "isPopular": false,
    "chainId": "1",
    "address": "0x120a3879da835a5af037bb2d1456bebd6b54d4ba",
    "decimals": 18,
    "image": "https://rango.vip/i/zRE4NU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0262745,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRCT",
    "name": "BRC App",
    "isPopular": false,
    "chainId": "1",
    "address": "0x455ad1bc4e18fd4e369234b6e11d88acbc416758",
    "decimals": 18,
    "image": "https://rango.vip/i/lnQMk9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0013359,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MC",
    "name": "Merit Circle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x949d48eca67b17269629c7194f4b727d4ef9e5d6",
    "decimals": 18,
    "image": "https://rango.vip/i/nfHHjQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.2,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MC",
    "name": "Matthew Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xae493a1f8bbe36ba8e687352f638d4c07c54f8d7",
    "decimals": 18,
    "image": "https://rango.vip/i/NrpTBt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00066680959344115,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MC",
    "name": "Magic Crystal Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FYUkUybywqUUyrUwiAezbvhTp2DUgx1eg8tQNiKkXqJ9",
    "decimals": 9,
    "image": "https://rango.vip/i/LMcpFK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00066680959344115,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MC",
    "name": "Merit Circle",
    "isPopular": false,
    "chainId": "56",
    "address": "0x949d48eca67b17269629c7194f4b727d4ef9e5d6",
    "decimals": 18,
    "image": "https://rango.vip/i/9aCE0Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.2,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKHIVE",
    "name": "zkHive",
    "isPopular": false,
    "chainId": "1",
    "address": "0x750c3a0a0ce9984eeb8c5d146dff024b584e5e33",
    "decimals": 18,
    "image": "https://rango.vip/i/QIsh9Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00245699,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MPH",
    "name": "88mph.app",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8888801af4d980682e47f1a9036e589479e835c5",
    "decimals": 18,
    "image": "https://rango.vip/i/MnLfTy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.418592,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MPH",
    "name": "Morpher",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6369c3dadfc00054a42ba8b2c09c48131dd4aa38",
    "decimals": 18,
    "image": "https://rango.vip/i/I0Mrbw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01587152,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DCARD",
    "name": "DECENTRACARD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f3d0d2317802a65faac6e4cd94067c37b4d4804",
    "decimals": 9,
    "image": "https://rango.vip/i/9bK8d7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00158495,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IRO",
    "name": "Iro Chan",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba2ae4e0a9c6ecaf172015aa2cdd70a21f5a290b",
    "decimals": 9,
    "image": "https://rango.vip/i/c7CaC3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005981,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NINJA",
    "name": "NINJA TURTLES",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DFrJxDoLMYt6bNYeNe8Wrjzj2UPUSLZLEMMYBLuTKcTk",
    "decimals": 8,
    "image": "https://rango.vip/i/UvOwxy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.26993e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NINJA",
    "name": "NINJA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ",
    "decimals": 6,
    "image": "https://rango.vip/i/LBeqgO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01056557,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NINJA",
    "name": "Shinobi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2xP43MawHfU7pwPUmvkc6AUWg4GX8xPQLTGMkSZfCEJT",
    "decimals": 9,
    "image": "https://rango.vip/i/OC6Ew8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00102586,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "TIGER",
    "name": "TIGER token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xd6597aa36dd90d7fccbd7b8a228f2d5cdc88ead0",
    "decimals": 18,
    "image": "https://rango.vip/i/1PKC2n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.06916988927341462,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NUTS",
    "name": "Deez Nuts",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DEEZgP19ZPovNeWRJZw7KuNbkLH6xjNjZ4HsUJnmZv7J",
    "decimals": 8,
    "image": "https://rango.vip/i/5OePaZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.057976,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NUTS",
    "name": "Thetanuts Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x23f3d4625aef6f0b84d50db1d53516e6015c0c9b",
    "decimals": 18,
    "image": "https://rango.vip/i/YPU3gR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00256048,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MVI",
    "name": "Metaverse Index (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xfe712251173a2cd5f5be2b46bb528328ea3565e1",
    "decimals": 18,
    "image": "https://rango.vip/i/IdaDtB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 21.56,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MVI",
    "name": "Metaverse Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72e364f2abdc788b7e918bc238b21f109cd634d7",
    "decimals": 18,
    "image": "https://rango.vip/i/Q1gJdo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 21.56,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFTFI",
    "name": "NFTFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x09d6f0f5a21f5be4f59e209747e2d07f50bc694c",
    "decimals": 18,
    "image": "https://rango.vip/i/rUc6Mz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00337028,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "STBOT",
    "name": "SolTradingBot",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2x8o3hA5S5fBxCSE9hzVTf3RohcMWHqkDNKNEPuzprD5",
    "decimals": 9,
    "image": "https://rango.vip/i/80Nf18",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00139919,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "HOLD",
    "name": "Holdstation",
    "isPopular": false,
    "chainId": "324",
    "address": "0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2",
    "decimals": 18,
    "image": "https://rango.vip/i/6H4VJ9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.984834,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HOLD",
    "name": "Hold VIP",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb4bbfe92702730ef7f1d28e4b9e42381182f48a5",
    "decimals": 18,
    "image": "https://rango.vip/i/NXQgDP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.26832e-10,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOLD",
    "name": "Everybody",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68b36248477277865c64dfc78884ef80577078f3",
    "decimals": 18,
    "image": "https://rango.vip/i/NrgsWq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006526,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PREAI",
    "name": "Predict Crypto",
    "isPopular": false,
    "chainId": "1",
    "address": "0xabd0e3535ecfbf6959b1798220335faf1b7ada3a",
    "decimals": 18,
    "image": "https://rango.vip/i/H3v8yl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0003213,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GBE",
    "name": "Gambex",
    "isPopular": false,
    "chainId": "1",
    "address": "0x176bc22e1855cd5cf5a840081c6c5b92b55e2210",
    "decimals": 18,
    "image": "https://rango.vip/i/yErGcU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.16,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RIZZ",
    "name": "RIZZ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a944bb731e302fdb3571350513f149f15fcbe34",
    "decimals": 18,
    "image": "https://rango.vip/i/cpk75m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.61574e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DCE",
    "name": "Decentra Ecosystem",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf6aca2769be86b9910165bff78fea643c086c61",
    "decimals": 9,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0470962714979709,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DCE",
    "name": "Ducky City Earn",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x3c3aa127e6ee3d2f2e432d0184dd36f2d2076b52",
    "decimals": 18,
    "image": "https://rango.vip/i/Eh9CLf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00004753,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DCE",
    "name": "Decentra Ecosystem",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf2c5780e2dda407781c0c5eccc9320d5988ea0a6",
    "decimals": 9,
    "image": "https://rango.vip/i/MpXIKY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04640305,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NXTT",
    "name": "NextEarthToken",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0d0b8488222f7f83b23e365320a4021b12ead608",
    "decimals": 18,
    "image": "https://rango.vip/i/0aVXtY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00006301,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAKA",
    "name": "TribeOne",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd85ad783cc94bd04196a13dc042a3054a9b52210",
    "decimals": 18,
    "image": "https://rango.vip/i/6Ah82u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00147322,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKLK",
    "name": "ZkLock",
    "isPopular": false,
    "chainId": "1",
    "address": "0x96884fcaac082db4b32601ada5b177fd6cbffa88",
    "decimals": 18,
    "image": "https://rango.vip/i/DD0fFg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00328799,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SX",
    "name": "SportX",
    "isPopular": false,
    "chainId": "137",
    "address": "0x840195888db4d6a99ed9f73fcd3b225bb3cb1a79",
    "decimals": 18,
    "image": "https://rango.vip/i/MUi3xg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.058513,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SX",
    "name": "SportX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x99fe3b1391503a1bc1788051347a1324bff41452",
    "decimals": 18,
    "image": "https://rango.vip/i/Sg97Ta",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.058513,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SX",
    "name": "SX Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe9f61555f50dd6167f2772e9cf7519790d96624",
    "decimals": 18,
    "image": "https://rango.vip/i/HLPAgg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.056517,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WHY",
    "name": "WHY",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9ec02756a559700d8d9e79ece56809f7bcc5dc27",
    "decimals": 18,
    "image": "https://rango.vip/i/VxHEI7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.60773e-7,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WHY",
    "name": "WENWIFHAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "WHYoaBumcmxCqw38y2mjs4cVkCBgwiDizbMVvcejmGT",
    "decimals": 9,
    "image": "https://rango.vip/i/XMJDO3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00039129,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WPT",
    "name": "WPT Investing Corp",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4fd51cb87ffefdf1711112b5bd8ab682e54988ea",
    "decimals": 18,
    "image": "https://rango.vip/i/Qzcx7I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00349533,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DOGGO",
    "name": "DOGGO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Doggoyb1uHFJGFdHhJf8FKEBUMv58qo98CisWgeD7Ftk",
    "decimals": 5,
    "image": "https://rango.vip/i/EN6TiN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.011e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SSNC",
    "name": "SSNC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6715515f5aa98e8bd3624922e1ba91e6f5fc4402",
    "decimals": 18,
    "image": "https://rango.vip/i/prORjB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00190466,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ANIMA",
    "name": "Anima",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xccd05a0fcfc1380e9da27862adb2198e58e0d66f",
    "decimals": 18,
    "image": "https://rango.vip/i/GypS0O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00846447,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HID",
    "name": "Hypersign Identity",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb14ebf566511b9e6002bb286016ab2497b9b9c9d",
    "decimals": 18,
    "image": "https://rango.vip/i/A3hrJN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01915982,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "CRAFT",
    "name": "CRAFT",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x8ae8be25c23833e0a01aa200403e826f611f9cd2",
    "decimals": 18,
    "image": "https://rango.vip/i/3jvCYG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00247251,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CANDY",
    "name": "Andy s Cat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0632aff522a581b9ffdec2fc2b0e99245a917057",
    "decimals": 18,
    "image": "https://rango.vip/i/G9eAqz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001186,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DBI",
    "name": "Don t Buy Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2de509bf0014ddf697b220be628213034d320ece",
    "decimals": 18,
    "image": "https://rango.vip/i/ha4siQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00043192,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NTO",
    "name": "Neton",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb668473944d2e25b6af6d46917eb0233dbac53ae",
    "decimals": 18,
    "image": "https://rango.vip/i/tWjjzW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005293,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NMT",
    "name": "NetMind Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x03aa6298f1370642642415edc0db8b957783e8d6",
    "decimals": 18,
    "image": "https://rango.vip/i/vUNlRT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.04,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NMT",
    "name": "NFTMart Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd81b71cbb89b2800cdb000aa277dc1491dc923c3",
    "decimals": 18,
    "image": "https://rango.vip/i/0r9ahG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00208435,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NMT",
    "name": "NetMind Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03aa6298f1370642642415edc0db8b957783e8d6",
    "decimals": 18,
    "image": "https://rango.vip/i/C8gj0j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.04,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VENT",
    "name": "Vent Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f0bc16d50f72d10b719dbf6845de2e599eb5624",
    "decimals": 18,
    "image": "https://rango.vip/i/OTiYEq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00538028,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLR",
    "name": "SolRazr",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7j7H7sgsnNDeCngAPjpaCN4aaaru4HS7NAFYSEUyzJ3k",
    "decimals": 6,
    "image": "https://rango.vip/i/RULVBX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02757938,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GGP",
    "name": "GoGoPool Protocol",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x69260b9483f9871ca57f81a90d91e2f96c2cd11d",
    "decimals": 18,
    "image": "https://rango.vip/i/v9N0jJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 4.18,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GORA",
    "name": "Gora",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b9b5ad79cbb7649143decd5afc749a75f8e6c7f",
    "decimals": 18,
    "image": "https://rango.vip/i/SLt0ZN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.075745,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GFOX",
    "name": "Galaxy Fox",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f1cece048cade6b8a05dfa2f90ee4025f4f2662",
    "decimals": 18,
    "image": "https://rango.vip/i/YMxMBJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015342,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MMY",
    "name": "Mummy Finance",
    "isPopular": false,
    "chainId": "10",
    "address": "0x47536f17f4ff30e64a96a7555826b8f9e66ec468",
    "decimals": 18,
    "image": "https://rango.vip/i/VEiGSw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.20331,
    "supportedSwappers": [
      "OpenOceanOptimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DETENSOR",
    "name": "DeTensor",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe6f4a40156c9e8c7addda66848bbb99fdedecf84",
    "decimals": 18,
    "image": "https://rango.vip/i/0cNRn7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0004497,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REDDIT",
    "name": "Reddit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x86eab36585eddb7a949a0b4771ba733d942a8aa7",
    "decimals": 9,
    "image": "https://rango.vip/i/ZKYN65",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00083084,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FBG",
    "name": "Fort Block Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb935deb517e4c2abc282e5e251ed4d05db79e93",
    "decimals": 18,
    "image": "https://rango.vip/i/MDSTXO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02131997,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "SHD",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/0b3d528e74e3deaadf8a68f393887ac7e06028904d02173561b0d27f6e751d0a",
    "decimals": 8,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KTO",
    "name": "Kounotori",
    "isPopular": false,
    "chainId": "1",
    "address": "0x616ef40d55c0d2c506f4d6873bda8090b79bf8fc",
    "decimals": 9,
    "image": "https://rango.vip/i/t5KSl5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.49687e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KENDU",
    "name": "Kendu Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa95f26e30001251fb905d264aa7b00ee9df6c18",
    "decimals": 18,
    "image": "https://rango.vip/i/JN3Gnd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004003,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STZ",
    "name": "99Starz",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f5294df68f871241c4b18fcf78ebd8ac18ab654",
    "decimals": 18,
    "image": "https://rango.vip/i/Kftou1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00926707,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PRNT",
    "name": "PRNT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4TUNzcgp2fPD48fcW4seRjyqyDZMrPj4ZubnXFEsKeYk",
    "decimals": 7,
    "image": "https://rango.vip/i/EwpTHK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006277,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRNT",
    "name": "Prime Numbers Labs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7cfea0dd176651e7b5a1ced9c4faf8ee295315fd",
    "decimals": 18,
    "image": "https://rango.vip/i/vu2Fgy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.19,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLT",
    "name": "Palette Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0944d5848bd9f60a34ba92aea300d4286696eb76",
    "decimals": 18,
    "image": "https://rango.vip/i/q4ApD6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0257759,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLT",
    "name": "Palette",
    "isPopular": false,
    "chainId": "1",
    "address": "0x553afe6468949e0685959022217336717df5fbe8",
    "decimals": 18,
    "image": "https://rango.vip/i/lSM4bD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 45.32,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DGLN",
    "name": "Dogelana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E6UU5M1z4CvSAAF99d9wRoXsasWMEXsvHrz3JQRXtm2X",
    "decimals": 9,
    "image": "https://rango.vip/i/pBFNDe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000776,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ULX",
    "name": "Ultron",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5aa158404fed6b4730c13f49d3a7f820e14a636f",
    "decimals": 18,
    "image": "https://rango.vip/i/gGC42z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02191373,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ULX",
    "name": "Ultron",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd983ab71a284d6371908420d8ac6407ca943f810",
    "decimals": 18,
    "image": "https://rango.vip/i/OsZQV7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02191373,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MSHEESHA",
    "name": "SHEESHA POLYGON",
    "isPopular": false,
    "chainId": "137",
    "address": "0x88c949b4eb85a90071f2c0bef861bddee1a7479d",
    "decimals": 18,
    "image": "https://rango.vip/i/b4spVh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00062078,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XOX",
    "name": "XOX Labs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f5c78f152152dda52a2ea45b0a8c10733010748",
    "decimals": 18,
    "image": "https://rango.vip/i/mFsPEf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00725385,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOLLY",
    "name": "Molly AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a6b457becae7ef1cf9f22510d136c4aac0fc6d2",
    "decimals": 18,
    "image": "https://rango.vip/i/NJ8BUF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00162766,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOLLY",
    "name": "Molly",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbdbe9f26918918bd3f43a0219d54e5fda9ce1bb3",
    "decimals": 9,
    "image": "https://rango.vip/i/YKQO0k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.31541e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MSOT",
    "name": "BTour Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5ef42d0e5e4aa6b36c613d00db8dad303d505f3",
    "decimals": 18,
    "image": "https://rango.vip/i/3chBHC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01137407,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZOOMER",
    "name": "Zoomer",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9MBzpyMRkj2r5nTQZMMnxnCm5j1MAAFSYUtbSKjAF3WU",
    "decimals": 9,
    "image": "https://rango.vip/i/1bhBVB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00518028,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZOOMER",
    "name": "ZOOMER (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "nBZEcHSG771mRbi4y2sSgKjfDUH8jsM2Eo5fNcASLeU",
    "decimals": 8,
    "image": "https://rango.vip/i/XfzfVw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000831,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZOOMER",
    "name": "ZOOMER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d505c03d30e65f6e9b4ef88855a47a89e4b7676",
    "decimals": 18,
    "image": "https://rango.vip/i/6baAPY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000831,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "JEUR",
    "name": "Jarvis Synthetic Euro",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c",
    "decimals": 18,
    "image": "https://rango.vip/i/pU94Qk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.933042,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JEUR",
    "name": "Jarvis Synthetic Euro",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f17bc9a994b87b5225cfb6a2cd4d667adb4f20b",
    "decimals": 18,
    "image": "https://rango.vip/i/GYQBoZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.933042,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ATH",
    "name": "Aethir",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc87b37a581ec3257b734886d9d3a581f5a9d056c",
    "decimals": 18,
    "image": "https://rango.vip/i/VvsK0l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.04401524,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ATH",
    "name": "Athens",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F9BqFoWRML4Red6YPiL3xJBP7u9g8f61AKJMEYDB3wBR",
    "decimals": 9,
    "image": "https://rango.vip/i/rTovrG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.04420547081961114,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATH",
    "name": "Athens",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f9411088cef82fd9fb904eb8092f28eb485c8f6",
    "decimals": 18,
    "image": "https://rango.vip/i/MMltQU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.29419,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATH",
    "name": "AthenaDAO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa4ffdf3208f46898ce063e25c1c43056fa754739",
    "decimals": 18,
    "image": "https://rango.vip/i/1tkMb9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.36551,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATH",
    "name": "Athenas AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe7458bc543cf2df43ac109d2f713dffe6417aa4",
    "decimals": 9,
    "image": "https://rango.vip/i/mNsv2c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013477,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATH",
    "name": "Aethir Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe0ed4138121ecfc5c0e56b40517da27e6c5226b",
    "decimals": 18,
    "image": "https://rango.vip/i/usWjLt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04401524,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ATH",
    "name": "AthenaDAO Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc62c9b7be5d4e5d415f682b2331bfa5f0c8d061a",
    "decimals": 18,
    "image": "https://rango.vip/i/Dg6tI3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.04420547081961114,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EAI",
    "name": "EternalAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa84f95eb3dabdc1bbd613709ef5f2fd42ce5be8d",
    "decimals": 18,
    "image": "https://rango.vip/i/1JgDY9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00852516,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HTS",
    "name": "Home3",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc40629464351c37c1e1f47b3640ea2e7aec31ea5",
    "decimals": 18,
    "image": "https://rango.vip/i/4VhrmS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00843357,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CEICAT",
    "name": "CEILING CAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8PMJczfs9W8TDKiNBD85AuqxE8tWACCDeUwxpUeadL3j",
    "decimals": 9,
    "image": "https://rango.vip/i/NIgTxK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00021323,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NVX",
    "name": "NovaDEX",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GtMtXoJiqSf8Gfp83cuunnDTiJTeTmv7cniVtJ6UAMWH",
    "decimals": 9,
    "image": "https://rango.vip/i/QkJybz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00081897,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DPLN",
    "name": "DePlan",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "J2LWsSXx4r3pYbJ1fwuX5Nqo7PPxjcGPpUb2zHNadWKa",
    "decimals": 6,
    "image": "https://rango.vip/i/IRbeIk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.145377,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BID",
    "name": "Bidao",
    "isPopular": false,
    "chainId": "1",
    "address": "0x25e1474170c4c0aa64fa98123bdc8db49d7802fa",
    "decimals": 18,
    "image": "https://rango.vip/i/EUfTKa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00083541,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XFIT",
    "name": "XFai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4aa41bc1649c9c3177ed16caaa11482295fc7441",
    "decimals": 18,
    "image": "https://rango.vip/i/ziE0Hd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01399805,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOYSCLUB",
    "name": "BoysClub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2d9996f3b9d2e73540fdbfdfe81d71e9e08cbf03",
    "decimals": 9,
    "image": "https://rango.vip/i/u4Lpgj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.51665e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOYSCLUB",
    "name": "Matt Furie s Boys Club",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6968676661ac9851c38907bdfcc22d5dd77b564d",
    "decimals": 18,
    "image": "https://rango.vip/i/vK23Fw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00289057,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SWH",
    "name": "simbawifhat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ASsnSwFhGVREnuN6YmZQKietjprv731BbETDWQsreAwj",
    "decimals": 9,
    "image": "https://rango.vip/i/1nfadQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000028399806369679384,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "XSUSHI",
    "name": "SushiBar",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6811079e3c63ed96eb005384d7e7ec8810e3d521",
    "decimals": 18,
    "image": "https://rango.vip/i/B6XfRg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.825346260193502,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XSUSHI",
    "name": "SushiBar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8798249c2e607446efb7ad49ec89dd1865ff4272",
    "decimals": 18,
    "image": "https://rango.vip/i/3KW3Yj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.818332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUFFY",
    "name": "Luffy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x54012cdf4119de84218f7eb90eeb87e25ae6ebd7",
    "decimals": 9,
    "image": "https://rango.vip/i/N34uiv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001613,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XFT",
    "name": "Offshift",
    "isPopular": false,
    "chainId": "1",
    "address": "0x76bc677d444f1e9d57daf5187ee2b7dc852745ae",
    "decimals": 18,
    "image": "https://rango.vip/i/5ge6uf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.351465,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XFT",
    "name": "Offshift",
    "isPopular": false,
    "chainId": "1",
    "address": "0xabe580e7ee158da464b51ee1a83ac0289622e6be",
    "decimals": 18,
    "image": "https://rango.vip/i/T3u0Sj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.3550915558246984,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POPDOG",
    "name": "PopDog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EATGZHJViJsk7nEKkrdJicwNbfpkJfAtmrEmrjXR8NBj",
    "decimals": 6,
    "image": "https://rango.vip/i/PQfW1w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00136438,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FORA",
    "name": "Fora",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ForaXiBD8K3a7C1PwxV1xqDHs5aV8y8nWRmHebafdkes",
    "decimals": 9,
    "image": "https://rango.vip/i/TJWFbE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0004628702217809463,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PIZZA",
    "name": "COSTCO PIZZA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DZvuAtqMdWzDHMGDpTkRmW2QBxstjCNTcobMDMpVuRDa",
    "decimals": 8,
    "image": "https://rango.vip/i/M1N2n6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.678772574078645,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PIZZA",
    "name": "Pizza",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x40468be13c4388d2ab68a09f56973fa95db5bca0",
    "decimals": 18,
    "image": "https://rango.vip/i/wdUOxS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000153,
    "supportedSwappers": [
      "SushiBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARX",
    "name": "ARCS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d8daff6d70cead12c6f077048552cf89130a2b1",
    "decimals": 18,
    "image": "https://rango.vip/i/MECv1s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00084422,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BUD",
    "name": "BunnyDucky Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BUD1144GGYwmMRFs4Whjfkom5UHqC9a8dZHPVvR2vfPx",
    "decimals": 6,
    "image": "https://rango.vip/i/cRO9fo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.6956193426331e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUD",
    "name": "Big Bud",
    "isPopular": false,
    "chainId": "1",
    "address": "0x420b879b0d18cc182e7e82ad16a13877c3a88420",
    "decimals": 9,
    "image": "https://rango.vip/i/mR0bh3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.6956193426331e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CASTLE",
    "name": "bitcastle",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf9d4daae1300cff251979722c4a3c45857973079",
    "decimals": 18,
    "image": "https://rango.vip/i/VSsFAJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024307,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GHNY",
    "name": "Grizzly Honey",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa045e37a0d1dd3a45fefb8803d22457abc0a728a",
    "decimals": 18,
    "image": "https://rango.vip/i/DqHjSb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.68,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "USP",
    "name": "USP",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xa69d9ba086d41425f35988613c156db9a88a1a96",
    "decimals": 18,
    "image": "https://rango.vip/i/voVxXh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.02751215577760536,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USP",
    "name": "USP Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x79b87d52a03ed26a19c1914be4ce37f812e2056c",
    "decimals": 18,
    "image": "https://rango.vip/i/H1GDa0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRACE",
    "name": "Trace Network Labs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f7fc686cfd64aa5ae15b351d03071e91533094b",
    "decimals": 18,
    "image": "https://rango.vip/i/9WrkdE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0005689,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TEM",
    "name": "TemplarDAO",
    "isPopular": false,
    "chainId": "56",
    "address": "0x19e6bfc1a6e4b042fb20531244d47e252445df01",
    "decimals": 9,
    "image": "https://rango.vip/i/G2kYg8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.95,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIMIR",
    "name": "Mimir",
    "isPopular": false,
    "chainId": "1",
    "address": "0x71dc40668682a124231301414167e4cf7f55383c",
    "decimals": 18,
    "image": "https://rango.vip/i/QlNN2C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00116834,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOLDEN",
    "name": "Golden Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd87996ff3d06858bfc20989aef50cc5fcd4d84ca",
    "decimals": 9,
    "image": "https://rango.vip/i/Pc9oIa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.5558e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FSM",
    "name": "Flying Spaghetti Monster",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DMqv9nGbEVAkQoz13ncKh5XJrFg2BQ5YTwd1XrGEKkaz",
    "decimals": 6,
    "image": "https://rango.vip/i/GyMWUR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.26959122488062e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COM",
    "name": " com  Ordinals ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff9c1f21c621696c4f91cf781ec31bd913ee2c26",
    "decimals": 18,
    "image": "https://rango.vip/i/kYqYxM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.122598,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MAIA",
    "name": "Maia",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x00000000702749f73e5210b08b0a3d440078f888",
    "decimals": 18,
    "image": "https://rango.vip/i/TwJvcm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 17.64,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GW",
    "name": "Gyrowin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77774a06271d6a305caccdbc06f847def05c7777",
    "decimals": 18,
    "image": "https://rango.vip/i/Ytctcl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00103597,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LUIS",
    "name": "Tongue Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5wU4tUcAbds7d5cmnGK2otHa9gbayYsD2mhz1reR6c91",
    "decimals": 6,
    "image": "https://rango.vip/i/9AARq6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00009721,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TEN",
    "name": "Tokenomy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd16ec0f66e54d453e6756713e533355989040e4",
    "decimals": 18,
    "image": "https://rango.vip/i/fofHeJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0283408,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TEN",
    "name": "Tenet",
    "isPopular": false,
    "chainId": "56",
    "address": "0xdff8cb622790b7f92686c722b02cab55592f152c",
    "decimals": 18,
    "image": "https://rango.vip/i/WTg2aC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.028126983036959895,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BCOQ",
    "name": "BLACK COQINU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "coqRkaaKeUygDPhuS3mrmrj6DiHjeQJc2rFbT2YfxWn",
    "decimals": 5,
    "image": "https://rango.vip/i/WCQjWr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.2885e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTRM",
    "name": "Materium",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd17fa52528f37facb3028688e62ec82d9417581",
    "decimals": 0,
    "image": "https://rango.vip/i/nZ7UEp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03522276,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MIMO",
    "name": "Million Monke",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9TE7ebz1dsFo1uQ2T4oYAKSm39Y6fWuHrd6Uk6XaiD16",
    "decimals": 9,
    "image": "https://rango.vip/i/Gpmhrt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00037536,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIMO",
    "name": "Mimo Governance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x90b831fa3bebf58e9744a14d638e25b4ee06f9bc",
    "decimals": 18,
    "image": "https://rango.vip/i/Acl5rC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00458202,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIK",
    "name": "Shikoku",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24da31e7bb182cb2cabfef1d88db19c2ae1f5572",
    "decimals": 18,
    "image": "https://rango.vip/i/agzoIs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.10791e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CGU",
    "name": "Crypto Global United",
    "isPopular": false,
    "chainId": "1",
    "address": "0x849a226f327b89e3133d9930d927f9eb9346f8c9",
    "decimals": 8,
    "image": "https://rango.vip/i/JZOC2a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00223684,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CGU",
    "name": "Crypto Gaming United",
    "isPopular": false,
    "chainId": "56",
    "address": "0x747d74db20cc422f39ab54edb2a3ce21f3c98af1",
    "decimals": 8,
    "image": "https://rango.vip/i/2TgJuk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00223684,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWIPES",
    "name": "Bndr",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4cb1e6c430bb4b874869fd6049ed07ae975b02f1",
    "decimals": 9,
    "image": "https://rango.vip/i/t0YF5M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00396787,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPCAT",
    "name": "OPCAT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdaa7699352ac8709f3d2fd092226d3dd7da40474",
    "decimals": 18,
    "image": "https://rango.vip/i/w38lqw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03628789,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "JPYC",
    "name": "JPY Coin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x431d5dff03120afa4bdf332c61a6e1766ef37bdb",
    "decimals": 18,
    "image": "https://rango.vip/i/oMmY05",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00685785,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "JPYC",
    "name": "JPY Coin",
    "isPopular": false,
    "chainId": "137",
    "address": "0x431d5dff03120afa4bdf332c61a6e1766ef37bdb",
    "decimals": 18,
    "image": "https://rango.vip/i/vZ0rm3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00685785,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "JPYC",
    "name": "JPY Coin (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6ae7dfc73e0dde2aa99ac063dcf7e8a63265108c",
    "decimals": 18,
    "image": "https://rango.vip/i/Njp5S5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00729054,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JPYC",
    "name": "JPY Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x431d5dff03120afa4bdf332c61a6e1766ef37bdb",
    "decimals": 18,
    "image": "https://rango.vip/i/890jEY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00685785,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JPYC",
    "name": "JPY Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2370f9d504c7a6e775bf6e14b3f12846b594cd53",
    "decimals": 18,
    "image": "https://rango.vip/i/r3ftBh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00729054,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YOBASE",
    "name": "All Your Base",
    "isPopular": false,
    "chainId": "1",
    "address": "0x551d0501cd5df92663c3d12c3201c9d70ba79998",
    "decimals": 18,
    "image": "https://rango.vip/i/CvEb1e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000486,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "XMT",
    "name": "MetalSwap",
    "isPopular": false,
    "chainId": "10",
    "address": "0x3e5d9d8a63cc8a88748f229999cf59487e90721e",
    "decimals": 18,
    "image": "https://rango.vip/i/EOROvi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.01441191,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XMT",
    "name": "MetalSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e5d9d8a63cc8a88748f229999cf59487e90721e",
    "decimals": 18,
    "image": "https://rango.vip/i/EOROvi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01441191,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAHAMAS",
    "name": "Bahamas",
    "isPopular": false,
    "chainId": "1",
    "address": "0x426aedbed16726e3f220cb4fed4d4060b95cca46",
    "decimals": 18,
    "image": "https://rango.vip/i/TeWlab",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000297,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WX",
    "name": "Weave6",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2817cecf94465a9f7becf43d9b7c8025e88a4213",
    "decimals": 18,
    "image": "https://rango.vip/i/BXYdlB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00038795,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LCD",
    "name": "Lucidao",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc2a45fe7d40bcac8369371b08419ddafd3131b4a",
    "decimals": 18,
    "image": "https://rango.vip/i/BR5XWm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00605367,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCF",
    "name": "MCFinance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe33ae4e795114279721047484e5ad5cc7df24fcb",
    "decimals": 10,
    "image": "https://rango.vip/i/v4GPr4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00096661,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LBA",
    "name": "Libra Credit",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe5f141bf94fe84bc28ded0ab966c16b17490657",
    "decimals": 18,
    "image": "https://rango.vip/i/lEjhS6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024039,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GNOMY",
    "name": "Gnomy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6439221d2b06a4cdf38f52a55294ddc28e1bed08",
    "decimals": 18,
    "image": "https://rango.vip/i/4kbZ7t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00019144,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VCX",
    "name": "VaultCraft",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce246eea10988c495b4a90a905ee9237a0f91543",
    "decimals": 18,
    "image": "https://rango.vip/i/l9QHt2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02963709,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLPAD",
    "name": "Solpad Finance",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GfJ3Vq2eSTYf1hJP6kKLE9RT6u7jF9gNszJhZwo5VPZp",
    "decimals": 9,
    "image": "https://rango.vip/i/RkTppa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004291,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZNX",
    "name": "ZENEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9471d30d78a3c9f076ce206d14867a8d8be1efde",
    "decimals": 6,
    "image": "https://rango.vip/i/26Lll9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02411516,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BULEI",
    "name": "Bulei",
    "isPopular": false,
    "chainId": "1",
    "address": "0x069e4aa272d17d9625aa3b6f863c7ef6cfb96713",
    "decimals": 9,
    "image": "https://rango.vip/i/i8qi3V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.85932e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CUFF",
    "name": "Jail Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2VYVwrwSNM8WxbFdPU4KQpZUB9FWCenFFoDqvpHQ7rZE",
    "decimals": 6,
    "image": "https://rango.vip/i/47RHdH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006786,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SATOSHI",
    "name": "Satoshi Nakamoto",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x45e7eaedb8e3360f850c963c5419a5236e451217",
    "decimals": 9,
    "image": "https://rango.vip/i/S9IxZe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.264332,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SATOSHI",
    "name": "Satoshi Nakamoto",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcbf4d5efa82e32a9187385480a7c74cb062b956c",
    "decimals": 9,
    "image": "https://rango.vip/i/S9IxZe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.264332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OSEAN",
    "name": "Osean",
    "isPopular": false,
    "chainId": "1",
    "address": "0x50d5118fb90d572b9d42ba65e0addc4900867809",
    "decimals": 18,
    "image": "https://rango.vip/i/4hKPMY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000821,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KIRO",
    "name": "Kirobo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb1191f691a355b43542bea9b8847bc73e7abb137",
    "decimals": 18,
    "image": "https://rango.vip/i/rkAWCD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0009154,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VETME",
    "name": "VetMe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe7ef051c6ea1026a70967e8f04da143c67fa4e1f",
    "decimals": 9,
    "image": "https://rango.vip/i/ATsmlU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00078551,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "B2M",
    "name": "Bit2Me",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7c302fc3ac829c7e896a32c4bd126f3e8bd0a1f",
    "decimals": 18,
    "image": "https://rango.vip/i/UOxJ1K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00975584,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "DEC",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/9bcb27203424535b6230d594553f1659c77ec173e36d9cf4759e7186ee747e84",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/DEC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00161099,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEC",
    "name": "DarkEnergyCrystals",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9393fdc77090f31c7db989390d43f454b1a6e7f3",
    "decimals": 3,
    "image": "https://rango.vip/i/c301Af",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00072358,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEC",
    "name": "Decentr",
    "isPopular": false,
    "chainId": "1",
    "address": "0x30f271c9e86d2b7d00a6376cd96a1cfbd5f0b9b3",
    "decimals": 18,
    "image": "https://rango.vip/i/1Fkkvy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00161099,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DEC",
    "name": "DarkEnergyCrystals",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe9d7023f2132d55cbd4ee1f78273cb7a3e74f10a",
    "decimals": 3,
    "image": "https://rango.vip/i/behkDd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00072358,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "ION",
    "name": null,
    "isPopular": true,
    "chainId": "osmosis-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/ION.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 159.9233937381826,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$DTJR",
    "name": "Donald Trump Jr.",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7G7SMGV9nSG316ykk6iobjMZWa8GZb15Wd25kgaZGTaZ",
    "decimals": 6,
    "image": "https://rango.vip/i/P7UVL2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TELE",
    "name": "Telefy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1e345ea7c33fd6c05f5512a780eb5839ee31674",
    "decimals": 18,
    "image": "https://rango.vip/i/SQzZmX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00099996,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GBOY",
    "name": "GameBoy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GKZbA2gDzw3MoxbfRsnrJTNi5uBDrnrz9bq1pNnx6kv",
    "decimals": 9,
    "image": "https://rango.vip/i/s7IADh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00019159,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ERIC",
    "name": "Elon s Pet Fish ERIC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16c22a91c705ec3c2d5945dbe2aca37924f1d2ed",
    "decimals": 9,
    "image": "https://rango.vip/i/UJeDiw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0005641,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IBIT",
    "name": "InfinityBit Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa3cb87080e68ad54d00573983d935fa85d168fde",
    "decimals": 8,
    "image": "https://rango.vip/i/MuzhMQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00051483,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BSW",
    "name": "Biswap",
    "isPopular": false,
    "chainId": "56",
    "address": "0x965f527d9159dce6288a2219db51fc6eef120dd1",
    "decimals": 18,
    "image": "https://rango.vip/i/DaEENp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.077627,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHEEPEPE",
    "name": "cheepepe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FwBixtdcmxawRFzBNeUmzhQzaFuvv6czs5wCQuLgWWsg",
    "decimals": 6,
    "image": "https://rango.vip/i/mHXM67",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00061047,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KNOB",
    "name": "KNOB$",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5ritAPtFPqQtEFHcHVqNjR5oFNUJqcmgKtZyPd2AyLLy",
    "decimals": 9,
    "image": "https://rango.vip/i/MnNL7o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000596,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPIZ",
    "name": "SPACE iZ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa7a5c1058194af8f00c187adb7fcc0c95f1c6c2d",
    "decimals": 18,
    "image": "https://rango.vip/i/S8v7m7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00034779,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TYRANT",
    "name": "Fable Of The Dragon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ee325ae3e54e83956ef2d5952d3c8bc1fa6ec27",
    "decimals": 9,
    "image": "https://rango.vip/i/CGmtC7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03857915,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VTX",
    "name": "Vortex DeFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xceb286c9604c542d3cc08b41aa6c9675b078a832",
    "decimals": 18,
    "image": "https://rango.vip/i/KcbZ6D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01703806681165551,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "VTX",
    "name": "Vector",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x5817d4f0b62a59b17f75207da1848c2ce75e7af4",
    "decimals": 18,
    "image": "https://rango.vip/i/b6CbPG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01703153,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOGGO",
    "name": "MOGGO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FbtRb4zF2u52FSnjLB79yRg73hbMVgSw9rC897shqzb4",
    "decimals": 9,
    "image": "https://rango.vip/i/MvA63I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.04610651,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AEROBUD",
    "name": "AEROBUD",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfad8cb754230dbfd249db0e8eccb5142dd675a0d",
    "decimals": 18,
    "image": "https://rango.vip/i/E0VCN3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00067431,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MAGICK",
    "name": "Magick",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x9a8e0217cd870783c3f2317985c57bf570969153",
    "decimals": 18,
    "image": "https://rango.vip/i/CjyFlf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00668325,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SBONK",
    "name": "SHIBONK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "H1G6sZ1WDoMmMCFqBKAbg9gkQPCo1sKQtaJWz9dHmqZr",
    "decimals": 9,
    "image": "https://rango.vip/i/piMF1t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RDN",
    "name": "Raiden Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6",
    "decimals": 18,
    "image": "https://rango.vip/i/2W50E9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00306791,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SPEPE",
    "name": "Saiyan PEPE",
    "isPopular": false,
    "chainId": "137",
    "address": "0xfca466f2fa8e667a517c9c6cfa99cf985be5d9b1",
    "decimals": 18,
    "image": "https://rango.vip/i/xvRof3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.38584e-7,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEUS",
    "name": "DEUS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde5ed76e7c05ec5e4572cfc88d1acea165109e44",
    "decimals": 18,
    "image": "https://rango.vip/i/k1cPrq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 14.31,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SEAT",
    "name": "Cris Hensan",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "29ad8JW1YcVT3mxcvxJpe3EzWqXCGD7KaSRWrc3TEMWG",
    "decimals": 6,
    "image": "https://rango.vip/i/3nDKnw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000291,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GSE",
    "name": "GSENetwork",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe530441f4f73bdb6dc2fa5af7c3fc5fd551ec838",
    "decimals": 4,
    "image": "https://rango.vip/i/BTgJMB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002938,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAMER",
    "name": "cyb3rgam3r420",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf89674f18309a2e97843c6e9b19c07c22caef6d5",
    "decimals": 9,
    "image": "https://rango.vip/i/pyEdO5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000347,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GAMER",
    "name": "GameStation",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3f6b3595ecf70735d3f48d69b09c4e4506db3f47",
    "decimals": 18,
    "image": "https://rango.vip/i/D4MAgU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00135862,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PC",
    "name": "Pig Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F5WPg7xdZczNg5pynWjPK8TZLT52WmAiT1ZuKRbaLnEM",
    "decimals": 6,
    "image": "https://rango.vip/i/YHLCsH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003998,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PC",
    "name": "Pepechain Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4f311c430540db1d64e635eb55f969f1660b2016",
    "decimals": 9,
    "image": "https://rango.vip/i/4MWnBX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.3573e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PC",
    "name": "Pepechain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1ec350ea16d1ddaff57f31387b2d9708eb7ce28",
    "decimals": 9,
    "image": "https://rango.vip/i/sVkSoe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005202,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PC",
    "name": "Playable Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x30303101104100c397c069e0642acac518420205",
    "decimals": 9,
    "image": "https://rango.vip/i/3xIgBl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020124,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELF",
    "name": "ELF Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbf2179859fc6d5bee9bf9158632dc51678a4100e",
    "decimals": 18,
    "image": "https://rango.vip/i/tgrxcN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.348145,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELF",
    "name": "ELF",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a13f32e2a556830f3a5e97a96ae941abfcb1d5c",
    "decimals": 18,
    "image": "https://rango.vip/i/J6GGOR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01034214,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "KNIGHT",
    "name": "Forest Knight",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4455ef8b4b4a007a93daa12de63a47eeac700d9d",
    "decimals": 18,
    "image": "https://rango.vip/i/nQAr2A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0099241,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KNIGHT",
    "name": "CitaDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3541a5c1b04adaba0b83f161747815cd7b1516bc",
    "decimals": 18,
    "image": "https://rango.vip/i/Kxc8RM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00111835,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CODEX",
    "name": "Codex Multichain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd26e39807772251c3bb90fb1fcd9ce5b80c5c24",
    "decimals": 9,
    "image": "https://rango.vip/i/Tjg5RT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0055899,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MZR",
    "name": "Mizar",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xbbea044f9e7c0520195e49ad1e561572e7e1b948",
    "decimals": 18,
    "image": "https://rango.vip/i/fhDbL8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00015739,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "LODE",
    "name": "LODE Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xbbaaa0420d474b34be197f95a323c2ff3829e811",
    "decimals": 18,
    "image": "https://rango.vip/i/NZxU3R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.23,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LODE",
    "name": "Lodestar",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf19547f9ed24aa66b03c3a552d181ae334fbb8db",
    "decimals": 18,
    "image": "https://rango.vip/i/WyLlbn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0459054,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAKE",
    "name": "Rake Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6553565eac5daa9bfc5e2892b36291634c9b2ad6",
    "decimals": 18,
    "image": "https://rango.vip/i/7dciCt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016204,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLSB",
    "name": "PulseBitcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ee84583f67d5ecea5420dbb42b462896e7f8d06",
    "decimals": 12,
    "image": "https://rango.vip/i/f1WxvD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01395386,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "POOL",
    "name": "PoolTogether",
    "isPopular": false,
    "chainId": "137",
    "address": "0x25788a1a171ec66da6502f9975a15b609ff54cf6",
    "decimals": 18,
    "image": "https://rango.vip/i/DXqgYU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.282673,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POOL",
    "name": "PoolTogether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0cec1a9154ff802e7934fc916ed7ca50bde6844e",
    "decimals": 18,
    "image": "https://rango.vip/i/RdXbIY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.282673,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "POOL",
    "name": "PoolTogether",
    "isPopular": false,
    "chainId": "10",
    "address": "0x395ae52bb17aef68c2888d941736a71dc6d4e125",
    "decimals": 18,
    "image": "https://rango.vip/i/RzlTWC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.282673,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIV",
    "name": "Shiva Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb51b8dc2d43469c0f0b7365c8a18438907bdf21",
    "decimals": 18,
    "image": "https://rango.vip/i/HhAHsO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.864e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIRB",
    "name": "PIRB",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ad16874759348f04b6b6119463d66c07ae54899",
    "decimals": 18,
    "image": "https://rango.vip/i/SUXcpQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01260304,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLY",
    "name": "Solamander",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CUwif1FiX5b3bwwb2n5Bm35AixvnR8LJjGUVmEwNZNgR",
    "decimals": 9,
    "image": "https://rango.vip/i/rUPePk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00018418,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHOW",
    "name": "CHOW CHOW",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e362038fd3d08887d498944d489af7909619a9b",
    "decimals": 18,
    "image": "https://rango.vip/i/dLMycy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.45535e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VRX",
    "name": "Verox",
    "isPopular": false,
    "chainId": "1",
    "address": "0x87de305311d5788e8da38d19bb427645b09cb4e5",
    "decimals": 18,
    "image": "https://rango.vip/i/ydx4BJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 16.03,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LOVE",
    "name": "Love Monster",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4QQV4LQUUXAn1eN1XQGrfY65TfLe5STJcfsCQozqyb8T",
    "decimals": 9,
    "image": "https://rango.vip/i/ctQJkW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00022665,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOVE",
    "name": "Deesse",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde4ce5447ce0c67920a1371605a39187cb6847c8",
    "decimals": 18,
    "image": "https://rango.vip/i/v6Sd8g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00035935,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOVE",
    "name": "LOVE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x504624040e0642921c2c266a9ac37cafbd8cda4e",
    "decimals": 18,
    "image": "https://rango.vip/i/0NQFD9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 20.44,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOVE",
    "name": "Love.io",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb55ee890426341fe45ee6dc788d2d93d25b59063",
    "decimals": 18,
    "image": "https://rango.vip/i/DD3tuV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000935,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEZZ",
    "name": "MEZZ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4c346edc55504574cceb00aa1091d22404a4bc3",
    "decimals": 18,
    "image": "https://rango.vip/i/BZ7v1n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.145454,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XFUND",
    "name": "unification.com/xfund",
    "isPopular": false,
    "chainId": "1",
    "address": "0x892a6f9df0147e5f079b0993f486f9aca3c87881",
    "decimals": 9,
    "image": "https://rango.vip/i/5isIKm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 268.59,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HILO",
    "name": "HILO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb9fd9fa4863c03c574007ff3370787b9ce65ff6",
    "decimals": 18,
    "image": "https://rango.vip/i/VIoG27",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02870643,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HILO",
    "name": "Hilo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c3fe25a4de7fa243c653cfe1f165bf11d99704e",
    "decimals": 18,
    "image": "https://rango.vip/i/4dLN3S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02520815,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POPE",
    "name": "PopeCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa15865d9de09cb96aaa3a9081b3dfc8481f07d33",
    "decimals": 18,
    "image": "https://rango.vip/i/q7X2uP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.087822,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGB",
    "name": "Apes Go Bananas",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbddf903f43dc7d9801f3f0034ba306169074ef8e",
    "decimals": 18,
    "image": "https://rango.vip/i/3h58I0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.35707e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOTTIE",
    "name": "Fottie",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe3b9cfb8ea8a4f1279fbc28d3e15b4d2d86f18a0",
    "decimals": 9,
    "image": "https://rango.vip/i/UdSu4T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.16578e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "TRI",
    "name": "Trisolaris Token",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xfa94348467f64d5a457f75f8bc40495d33c65abb",
    "decimals": 18,
    "image": "https://rango.vip/i/mlA97H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.00118589,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PWR",
    "name": "MaxxChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd9dcd611bed2be9a4700c725a3810870b9bff094",
    "decimals": 18,
    "image": "https://rango.vip/i/0t7L3m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00179768,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FROYO",
    "name": "Froyo",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe369fec23380f9f14ffd07a1dc4b7c1a9fdd81c9",
    "decimals": 18,
    "image": "https://rango.vip/i/e3zbGo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00008059,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NIGI",
    "name": "Nigi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3boRKAxWR6weV6kufr9ykdLcm9cL5q2p469tCqeCAnHy",
    "decimals": 6,
    "image": "https://rango.vip/i/0bjugC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008379,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLORK",
    "name": "Flork",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb0238975ce84f89212ffa56c64c0f2b47f8f153",
    "decimals": 18,
    "image": "https://rango.vip/i/lfyGZc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00042503249969225447,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DMCC",
    "name": "Decentralized Music Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x148255a3b10666d9788ec48bc61ea3e48974bf2c",
    "decimals": 18,
    "image": "https://rango.vip/i/P4SPH7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02481271,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NUT",
    "name": "NutFlex",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4pb6mymm9hYQN6og9uF24eyZ2qwXCWCwGvcR1DkCgeEr",
    "decimals": 6,
    "image": "https://rango.vip/i/z1YHf7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006868,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NUT",
    "name": "NutCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x473f4068073cd5b2ab0e4cc8e146f9edc6fb52cc",
    "decimals": 18,
    "image": "https://rango.vip/i/pAyNB7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.1657e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WIFE",
    "name": "wifejak",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4y3oUrsJfSp431R3wJrWiaLxRPsnYtpkVJmoV2bYpBiy",
    "decimals": 6,
    "image": "https://rango.vip/i/LzL3QU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0001754,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OXN",
    "name": "0xNumber",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9012744b7a564623b6c3e40b144fc196bdedf1a9",
    "decimals": 18,
    "image": "https://rango.vip/i/2nABOu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03263236,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RENT",
    "name": "RentAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe13cf110176e0dd6590536cd391b8a3522475f82",
    "decimals": 9,
    "image": "https://rango.vip/i/AnpUJo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00349802,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BXBT",
    "name": "BoxBet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33f289d91286535c47270c8479f6776fb3adeb3e",
    "decimals": 18,
    "image": "https://rango.vip/i/QYTn0h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04585523,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NCAT",
    "name": "Neuracat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "82Rc22mnyHrmBGwj15rhYhFzVrU3bgFkjNtV3iHjpump",
    "decimals": 6,
    "image": "https://rango.vip/i/y5UV8l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00014692,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NCAT",
    "name": "Neuracat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2b7c0fa747611d4412b54076c62119926474edb3",
    "decimals": 9,
    "image": "https://rango.vip/i/6pJ2R7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.28871e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JOTCHUA",
    "name": "PERRO DINERO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x808c16ace7404777fe24a6777a9cb2335aa82451",
    "decimals": 18,
    "image": "https://rango.vip/i/qQwjd9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00039645,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PREME",
    "name": "PREME Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d0c49057c09501595a8ce23b773bb36a40b521f",
    "decimals": 18,
    "image": "https://rango.vip/i/8hX0Dc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00497936,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHURRO",
    "name": "CHURRO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A3t817aK6XkhqQA148bjXKWJMhBmP9t2d9caWvQNaHQR",
    "decimals": 6,
    "image": "https://rango.vip/i/lcGwQ2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00017321418794591896,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IUSD",
    "name": "iZUMi Bond USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d",
    "decimals": 18,
    "image": "https://rango.vip/i/AswZWJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STARX",
    "name": "STARX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4e8a9d47000ab8e59c7031e311762c68215e467",
    "decimals": 18,
    "image": "https://rango.vip/i/DN5VF3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01215697,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASTRA",
    "name": "ASTRA PROTOCOL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x201332bd45c8628d814f870bfb584b385a7c351e",
    "decimals": 18,
    "image": "https://rango.vip/i/ak6Eo6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00050743,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASTRA",
    "name": "AstraAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0aa8a7d1fb4c64b3b1dcea9a7ade81c59c25b95b",
    "decimals": 18,
    "image": "https://rango.vip/i/sawgmp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.990988,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUCKER",
    "name": "Duckereum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa52bffad02b1fe3f86a543a4e81962d3b3bb01a7",
    "decimals": 18,
    "image": "https://rango.vip/i/Yr9vma",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00182199,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUCKER",
    "name": "Ducker",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf70ce9ee486106882d3dc43ddbd84e0fa71ac2a5",
    "decimals": 18,
    "image": "https://rango.vip/i/h1kcNe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.97807e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APUGURL",
    "name": "Apu Gurl",
    "isPopular": false,
    "chainId": "1",
    "address": "0x069f01cdd1e32d7bab5fc81527df191835136c9d",
    "decimals": 9,
    "image": "https://rango.vip/i/5kaOHU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000206,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEAR",
    "name": "Pear Swap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5dcd6272c3cbb250823f0b7e6c618bce11b21f90",
    "decimals": 18,
    "image": "https://rango.vip/i/Hg5qi9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00709879,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BOLT",
    "name": "BOLT on Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7cf7132ede0ca592a236b6198a681bb7b42dd5ae",
    "decimals": 18,
    "image": "https://rango.vip/i/pvnSZ2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00037775,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOLT",
    "name": "Bolt Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd5930c307d7395ff807f2921f12c5eb82131a789",
    "decimals": 18,
    "image": "https://rango.vip/i/HhAj88",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00280111,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "TRYB",
    "name": "BiLira",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x564a341df6c126f90cf3ecb92120fd7190acb401",
    "decimals": 6,
    "image": "https://rango.vip/i/6SxWBa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.02952648,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRYB",
    "name": "BiLira",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c537e5624e4af88a7ae4060c022609376c8d0eb",
    "decimals": 6,
    "image": "https://rango.vip/i/zctbq9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02952648,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RKR",
    "name": "Reaktor",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb970e14df2161c0a2f32eba35901f2446581b482",
    "decimals": 9,
    "image": "https://rango.vip/i/WrNYwF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000317,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ALB",
    "name": "AlienBase",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x1dd2d631c92b1acdfcdd51a0f7145a50130050c4",
    "decimals": 18,
    "image": "https://rango.vip/i/4A5egx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0394974,
    "supportedSwappers": [
      "AlienBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLES",
    "name": "Blind Boxes Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe796d6ca1ceb1b022ece5296226bf784110031cd",
    "decimals": 18,
    "image": "https://rango.vip/i/aFQz73",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0039266,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BHIG",
    "name": "BuckHath Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x996229d0c6a485c7f4b52e092eaa907cb2def5c6",
    "decimals": 18,
    "image": "https://rango.vip/i/ufQzi0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04964204,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SELF",
    "name": "SELF Crypto",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4af8aa621df6dd3e2d653188a357fc2b35c6a037",
    "decimals": 18,
    "image": "https://rango.vip/i/AEg740",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00172504,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LIGHT",
    "name": "Lightning",
    "isPopular": false,
    "chainId": "56",
    "address": "0x037838b556d9c9d654148a284682c55bb5f56ef4",
    "decimals": 18,
    "image": "https://rango.vip/i/xtC99p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01841347,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STARS",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/987c17b11abc2b20019178ace62929fe9840202ce79498e29fe8e5cb02b7c0a4",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/STARS.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.006646626934532387,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "STARS",
    "name": "STARS",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "ibc/f6b367385300865f654e110976b838502504231705bac0849b0651c226385885",
    "decimals": 6,
    "image": "https://rango.vip/i/oIoaCw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 0.0066443609316224,
    "supportedSwappers": [
      "WYNDDex",
      "IBC"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "STARS",
    "name": "StarLaunch",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW",
    "decimals": 6,
    "image": "https://rango.vip/i/UYCqnK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02759106,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STARS",
    "name": "Mogul Stars",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc55c2175e90a46602fd42e931f62b3acc1a013ca",
    "decimals": 18,
    "image": "https://rango.vip/i/cr7iv0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005475,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "STARS",
    "name": "Mogul Stars",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbd83010eb60f12112908774998f65761cf9f6f9a",
    "decimals": 18,
    "image": "https://rango.vip/i/BqKWUt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00005475,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "STARGAZE",
    "symbol": "STARS",
    "name": null,
    "isPopular": true,
    "chainId": "stargaze-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/vS1vg1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARGAZE/icon.svg",
    "usdPrice": 0.00665086537270925,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RATIO",
    "name": "Ratio Governance Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J",
    "decimals": 6,
    "image": "https://rango.vip/i/2tpoiE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00148895,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ULT",
    "name": "Unblocked Ledger Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x09617f6fd6cf8a71278ec86e23bbab29c04353a7",
    "decimals": 18,
    "image": "https://rango.vip/i/VKtyKq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.098017,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BIRDDOG",
    "name": "Bird Dog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3XTp12PmKMHxB6YkejaGPUjMGBLKRGgzHWgJuVTsBCoP",
    "decimals": 9,
    "image": "https://rango.vip/i/s8LG41",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.002198,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIRDDOG",
    "name": "Birddog",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70fd93fb088150e203d2243b9bd3190276f80c70",
    "decimals": 9,
    "image": "https://rango.vip/i/ur6ePO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.46416e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIRDDOG",
    "name": "Bird Dog",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf6ce4be313ead51511215f1874c898239a331e37",
    "decimals": 9,
    "image": "https://rango.vip/i/H1dZH5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000264,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SMRTR",
    "name": "SmarterCoin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x6d923f688c7ff287dc3a5943caeefc994f97b290",
    "decimals": 18,
    "image": "https://rango.vip/i/R3bnN8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.57833e-7,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRIDGE",
    "name": "Cross-Chain Bridge Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x92868a5255c628da08f550a858a802f5351c5223",
    "decimals": 18,
    "image": "https://rango.vip/i/utByfR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00084765,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UWU",
    "name": "UwU Lend",
    "isPopular": false,
    "chainId": "1",
    "address": "0x55c08ca52497e2f1534b59e2917bf524d4765257",
    "decimals": 18,
    "image": "https://rango.vip/i/TMfrT2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.806882,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "$KMC",
    "name": "$KMC",
    "isPopular": false,
    "chainId": "137",
    "address": "0x44d09156c7b4acf0c64459fbcced7613f5519918",
    "decimals": 18,
    "image": "https://rango.vip/i/YxIyzI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000937,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZENIQ",
    "name": "ZENIQ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5b52bfb8062ce664d74bbcd4cd6dc7df53fd7233",
    "decimals": 18,
    "image": "https://rango.vip/i/IChJGV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01503667,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHER",
    "name": "Cherry Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa20f77b7ad5a88badc48800c56507b7274c06fdc",
    "decimals": 18,
    "image": "https://rango.vip/i/iVxAj0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00249377,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HZM",
    "name": "HZM Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x069f967be0ca21c7d793d8c343f71e597d9a49b3",
    "decimals": 8,
    "image": "https://rango.vip/i/1X15pr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00042645,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GATSBY",
    "name": "Gatsby Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d0ebc4ec5ac18d30512fb6287886245061b3dbd",
    "decimals": 9,
    "image": "https://rango.vip/i/Fnm8ix",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.0742e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XRP20",
    "name": "XRP20",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe4ab0be415e277d82c38625b72bd7dea232c2e7d",
    "decimals": 18,
    "image": "https://rango.vip/i/gNdSsP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001634,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STATE",
    "name": "New World Order",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00c2999c8b2adf4abc835cc63209533973718eb1",
    "decimals": 18,
    "image": "https://rango.vip/i/2zDCb1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.463e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MCRN",
    "name": "MacaronSwap Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xacb2d47827c9813ae26de80965845d80935afd0b",
    "decimals": 18,
    "image": "https://rango.vip/i/VdkGEv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02399752,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ALICE",
    "name": "My Neighbor Alice",
    "isPopular": false,
    "chainId": "25",
    "address": "0x46efe38ec0558c48352e2ebc85af3bd2e87fb2a1",
    "decimals": 6,
    "image": "https://rango.vip/i/2RL2oD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.9143737492676187,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALICE",
    "name": "ALICE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac51066d7bec65dc4589368da368b212745d63e8",
    "decimals": 6,
    "image": "https://rango.vip/i/qRh4YY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.910066,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALICE",
    "name": "Alice AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x405154cfaf5ea4ef57b65b86959c73dd079fa312",
    "decimals": 18,
    "image": "https://rango.vip/i/kHO5ua",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033712,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ALICE",
    "name": "ALICE",
    "isPopular": false,
    "chainId": "56",
    "address": "0xac51066d7bec65dc4589368da368b212745d63e8",
    "decimals": 6,
    "image": "https://rango.vip/i/bBU2Fg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.910066,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOROS",
    "name": "MOROS NET",
    "isPopular": false,
    "chainId": "1",
    "address": "0xab85fc558d722a2b7c040ffb77db676bd9e7d322",
    "decimals": 18,
    "image": "https://rango.vip/i/lUUaCV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00049936,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEXIO",
    "name": "Dexioprotocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe2cfbbedbce1bd59b1b799c44282e6396d692b84",
    "decimals": 18,
    "image": "https://rango.vip/i/EM0yDa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00292178,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WASD",
    "name": "WASD Studios",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbdcd291c32e06bbf2d7b1ffc823959e3258e3583",
    "decimals": 9,
    "image": "https://rango.vip/i/Be80aT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00045433,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BAMBIT",
    "name": "BAMBIT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "xN9Qd63mUYg7npanmdksmcqp3NQjTcGFQPTyq2F1TQC",
    "decimals": 9,
    "image": "https://rango.vip/i/7d8SdD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00197425,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVEAI",
    "name": "Eve AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x05fe069626543842439ef90d9fa1633640c50cf1",
    "decimals": 18,
    "image": "https://rango.vip/i/ff0k2Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01120649,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VRSW",
    "name": "VirtuSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x99a01a4d6a4d621094983050d9a2f10b2912e53d",
    "decimals": 18,
    "image": "https://rango.vip/i/4ZRu9b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00061966,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AEVUM",
    "name": "Aevum",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EsirN3orp85uyvZyDrZnbe9cyo7N1114ynLFdwMPCQce",
    "decimals": 9,
    "image": "https://rango.vip/i/aHEqjA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00383268,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUNG",
    "name": "Fungify Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e4e7f2aecf408aff4f82f067677050239bdc58a",
    "decimals": 18,
    "image": "https://rango.vip/i/LZIlhA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00880281,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SONIC",
    "name": "Sonic Sniper",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7EW2NTuQFYKVxF3WTA1L1v62pxB7RFYmVC7veGxNDFis",
    "decimals": 6,
    "image": "https://rango.vip/i/4rlLT7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000232,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SONIC",
    "name": "Sonic",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1cd47746b8e72359b28c1c84a4f6a19dc1a0ee5",
    "decimals": 18,
    "image": "https://rango.vip/i/sVQ925",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000803,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DAO",
    "name": "DAO Maker",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4d2d32d8652058bf98c772953e1df5c5c85d9f45",
    "decimals": 18,
    "image": "https://rango.vip/i/VegFtL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.273501,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAO",
    "name": "DAO Maker",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f51bb10119727a7e5ea3538074fb341f56b09ad",
    "decimals": 18,
    "image": "https://rango.vip/i/Hxt4H5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.273501,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "STREAM",
    "name": "Solstream",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "54jVZGHyWURX5evBtZqUsJjwoKzcZJbVokDU93AUZf2h",
    "decimals": 9,
    "image": "https://rango.vip/i/g0sPnZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00314236,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NEX",
    "name": "Nash Exchange Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa486c6bc102f409180ccb8a94ba045d39f8fc7cb",
    "decimals": 8,
    "image": "https://rango.vip/i/ur230l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.140999,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEX",
    "name": "NexAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c623d3cc9a2cc158951b8093cb94e80cf56deea",
    "decimals": 18,
    "image": "https://rango.vip/i/cNdgXA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002422,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEX",
    "name": "NEXUS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc01154b4ccb518232d6bbfc9b9e6c5068b766f82",
    "decimals": 18,
    "image": "https://rango.vip/i/SnMkkd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00080145,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEX",
    "name": "Nash Exchange Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe2dc070524a6e305ddb64d8513dc444b6a1ec845",
    "decimals": 8,
    "image": "https://rango.vip/i/uDj71B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.140999,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SU",
    "name": "Smol Su",
    "isPopular": false,
    "chainId": "1",
    "address": "0x064797ac7f833d01faeeae0e69f3af5a52a91fc8",
    "decimals": 9,
    "image": "https://rango.vip/i/UrjMOD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.284011,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CULO",
    "name": "CULO",
    "isPopular": false,
    "chainId": "137",
    "address": "0x74dd45dd579cad749f9381d6227e7e02277c944b",
    "decimals": 9,
    "image": "https://rango.vip/i/rgqDYA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000125,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WANA",
    "name": "Wanaka Farm",
    "isPopular": false,
    "chainId": "56",
    "address": "0x339c72829ab7dd45c3c52f965e7abe358dd8761e",
    "decimals": 18,
    "image": "https://rango.vip/i/koVCdP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00302275,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AFB",
    "name": "A Fund Baby",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66861d5f0fbfb7b2711712fef2172c560d08d0ab",
    "decimals": 18,
    "image": "https://rango.vip/i/ha8uz6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0012706709584783147,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HHGTTG",
    "name": "Douglas Adams",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee3c722d177559f73288cec91fa3e4bbfd8c27fc",
    "decimals": 9,
    "image": "https://rango.vip/i/Z0YzYh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.0871e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CHR",
    "name": "Chromia",
    "isPopular": false,
    "chainId": "25",
    "address": "0xcdee9300a1527e0000b054320d371a9c8c4a8af6",
    "decimals": 6,
    "image": "https://rango.vip/i/mVwdLa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.17266635155400195,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHR",
    "name": "Chromia",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a2279d4a90b6fe1c4b30fa660cc9f926797baa2",
    "decimals": 6,
    "image": "https://rango.vip/i/dt5t7x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.171811,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CHR",
    "name": "Chroma",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf9cec8d50f6c8ad3fb6dccec577e05aa32b224fe",
    "decimals": 6,
    "image": "https://rango.vip/i/OazYIf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.171811,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TTT",
    "name": "TabTrader Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FNFKRV3V8DtA3gVJN6UshMiLGYA8izxFwkNWmJbFjmRj",
    "decimals": 6,
    "image": "https://rango.vip/i/teLiVT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00205756,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ROCKY",
    "name": "ROCKY",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x3636a7734b669ce352e97780df361ce1f809c58c",
    "decimals": 18,
    "image": "https://rango.vip/i/kdbWpR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00044064,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ROCKY",
    "name": "Rocky",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4icEZCrEYNop2ZaMMCkRHaNzkt6xG9BpijMCQV7mpw6Z",
    "decimals": 9,
    "image": "https://rango.vip/i/9texua",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00047486,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ROCKY",
    "name": "Rocky",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DhTNFBp4NjaxWqf6LwG57GrJQZfXrso1qK9FcMZt9sv7",
    "decimals": 9,
    "image": "https://rango.vip/i/plQY48",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01576619,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BNA",
    "name": "BananaTok",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20910e5b5f087f6439dfcb0dda4e27d1014ac2b8",
    "decimals": 18,
    "image": "https://rango.vip/i/QID3QM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00079984,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOLA",
    "name": "MOONLANA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps",
    "decimals": 9,
    "image": "https://rango.vip/i/mtfW4O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002812,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDL",
    "name": "stake.link",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa95c5ebb86e0de73b4fb8c47a45b792cfea28c23",
    "decimals": 18,
    "image": "https://rango.vip/i/yAgA3E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.218159,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDL",
    "name": "Saddle DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1dc500fde233a4055e25e5bbf516372bc4f6871",
    "decimals": 18,
    "image": "https://rango.vip/i/7wVbMV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.22073898504186534,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SDL",
    "name": "Saddle DAO",
    "isPopular": false,
    "chainId": "10",
    "address": "0xae31207ac34423c41576ff59bfb4e036150f9cf7",
    "decimals": 18,
    "image": "https://rango.vip/i/V3joy9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.22073898504186534,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TFT",
    "name": "ThreeFold",
    "isPopular": false,
    "chainId": "1",
    "address": "0x395e925834996e558bdec77cd648435d620afb5b",
    "decimals": 7,
    "image": "https://rango.vip/i/X5QpQy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01003437,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TFT",
    "name": "TFT on BSC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8f0fb159380176d324542b3a7933f0c2fd0c2bbf",
    "decimals": 7,
    "image": "https://rango.vip/i/9upkKq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01003437,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FECES",
    "name": "FECES",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5j2shkkzascw33QpW6RRqfRkacLkn7QwwV1e7jX36nyn",
    "decimals": 9,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00039704,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "OSMO",
    "name": null,
    "isPopular": true,
    "chainId": "osmosis-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/OSMO.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.37304576,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "OSMO",
    "name": "OSMO",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "ibc/ed07a3391a112b175915cd8faf43a2da8e4790ede12566649d0c2f97716b8518",
    "decimals": 6,
    "image": "https://rango.vip/i/qNAd7P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 0.37304576,
    "supportedSwappers": [
      "WYNDDex",
      "IBC"
    ]
  },
  {
    "blockchain": "SENTINEL",
    "symbol": "OSMO",
    "name": null,
    "isPopular": false,
    "chainId": "sentinelhub-2",
    "address": "ibc/ed07a3391a112b175915cd8faf43a2da8e4790ede12566649d0c2f97716b8518",
    "decimals": 6,
    "image": "https://rango.vip/i/trBF3X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SENTINEL/icon.svg",
    "usdPrice": 0.37341095,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "EMONEY",
    "symbol": "OSMO",
    "name": null,
    "isPopular": false,
    "chainId": "emoney-3",
    "address": "ibc/ed07a3391a112b175915cd8faf43a2da8e4790ede12566649d0c2f97716b8518",
    "decimals": 6,
    "image": "https://rango.vip/i/785L8H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/EMONEY/icon.svg",
    "usdPrice": 0.37341095,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "BITSONG",
    "symbol": "OSMO",
    "name": null,
    "isPopular": false,
    "chainId": "bitsong-2b",
    "address": "ibc/ed07a3391a112b175915cd8faf43a2da8e4790ede12566649d0c2f97716b8518",
    "decimals": 6,
    "image": "https://rango.vip/i/trBF3X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BITSONG/icon.svg",
    "usdPrice": 0.37341095,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "KI",
    "symbol": "OSMO",
    "name": null,
    "isPopular": false,
    "chainId": "kichain-2",
    "address": "ibc/ed07a3391a112b175915cd8faf43a2da8e4790ede12566649d0c2f97716b8518",
    "decimals": 6,
    "image": "https://rango.vip/i/785L8H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/KI/icon.svg",
    "usdPrice": 0.37341095,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "STARGAZE",
    "symbol": "OSMO",
    "name": null,
    "isPopular": false,
    "chainId": "stargaze-1",
    "address": "ibc/ed07a3391a112b175915cd8faf43a2da8e4790ede12566649d0c2f97716b8518",
    "decimals": 6,
    "image": "https://rango.vip/i/trBF3X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARGAZE/icon.svg",
    "usdPrice": 0.37341095,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "KONSTELLATION",
    "symbol": "OSMO",
    "name": null,
    "isPopular": false,
    "chainId": "darchub",
    "address": "ibc/ed07a3391a112b175915cd8faf43a2da8e4790ede12566649d0c2f97716b8518",
    "decimals": 6,
    "image": "https://rango.vip/i/785L8H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/KONSTELLATION/icon.svg",
    "usdPrice": 0.37341095,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "UMEE",
    "symbol": "OSMO",
    "name": null,
    "isPopular": false,
    "chainId": "umee-1",
    "address": "ibc/ed07a3391a112b175915cd8faf43a2da8e4790ede12566649d0c2f97716b8518",
    "decimals": 6,
    "image": "https://rango.vip/i/trBF3X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/UMEE/icon.svg",
    "usdPrice": 0.37341095,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CRAMER",
    "name": "Cramer Coin (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E3tHHRa9Y1TWAA1nPZo8FAyVFgBDnXrS8u8nmMShL3M3",
    "decimals": 8,
    "image": "https://rango.vip/i/AGX5Cc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000390833519510089,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRAMER",
    "name": "Cramer Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64df3aab3b21cc275bb76c4a581cf8b726478ee0",
    "decimals": 18,
    "image": "https://rango.vip/i/Xt7M2i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000390833519510089,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACRIA",
    "name": "Acria AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44f5909e97e1cbf5fbbdf0fc92fd83cde5d5c58a",
    "decimals": 18,
    "image": "https://rango.vip/i/Xmy4GE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00681778,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SGR",
    "name": "Schrodinger",
    "isPopular": false,
    "chainId": "1",
    "address": "0x478156deabfac918369044d52a6bdb5cc5597994",
    "decimals": 8,
    "image": "https://rango.vip/i/VbGXUn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.26,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KNJ",
    "name": "Kunji Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xfbbb21d8e7a461f06e5e27efd69703acb5c732a8",
    "decimals": 18,
    "image": "https://rango.vip/i/Zh3nd6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01901182,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLASH",
    "name": "Flash 3.0",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb19da2482308ec02a242aced4fe0f09d06b12a7",
    "decimals": 18,
    "image": "https://api.rango.exchange/tokens/ETH/FLASH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00618855,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "FLASH",
    "name": "Flashstake",
    "isPopular": false,
    "chainId": "10",
    "address": "0x86bea60374f220de9769b2fef2db725bc1cdd335",
    "decimals": 18,
    "image": "https://rango.vip/i/40p5gX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.0002941285084202125,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLASH",
    "name": "Flashstake",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb1f1f47061a7be15c69f378cb3f69423bd58f2f8",
    "decimals": 18,
    "image": "https://rango.vip/i/afqgsP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00650277,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WBS",
    "name": "White Boy Summer",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GJgHsc1HU4ibmzW6oWQr8L2RRT95ATc1BoNuLkp94AwU",
    "decimals": 9,
    "image": "https://rango.vip/i/YJOqCv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00011669,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FPI",
    "name": "Frax Price Index",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1b01514a2b3cdef16fd3c680a818a0ab97da8a09",
    "decimals": 18,
    "image": "https://rango.vip/i/opy31J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FPI",
    "name": "Frax Price Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ca135cb8527d76e932f34b5145575f9d8cbe08e",
    "decimals": 18,
    "image": "https://rango.vip/i/8ps246",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "FPI",
    "name": "Frax Price Index",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc5d43a94e26fca47a9b21cf547ae4aa0268670e1",
    "decimals": 18,
    "image": "https://rango.vip/i/E1nUYz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.1134588187378696,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUNCHY",
    "name": "Boys Club Munchy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9a0d1b52e0684ab42aa0c2613abb4c04217e8aa6",
    "decimals": 9,
    "image": "https://rango.vip/i/g3nhKo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000326,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CREAM",
    "name": "Cream",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2ba592f78db6436527729929aaf6c908497cb200",
    "decimals": 18,
    "image": "https://rango.vip/i/CAIjK4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 17.79,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CREAM",
    "name": "Cream",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf4d48ce3ee1ac3651998971541badbb9a14d7234",
    "decimals": 18,
    "image": "https://rango.vip/i/Jx5nkn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 17.79,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CREAM",
    "name": "Cream",
    "isPopular": false,
    "chainId": "137",
    "address": "0x04f3c4cf2e806da6df31e80e8a5d121f98edd61d",
    "decimals": 18,
    "image": "https://rango.vip/i/wCco3U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 17.849907259441764,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CREAM",
    "name": "Cream",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd4cb328a82bdf5f03eb737f37fa6b370aef3e888",
    "decimals": 18,
    "image": "https://rango.vip/i/Volf0H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 17.849907259441764,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MIX",
    "name": "MixMarvel Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xcf1b55d79e824da0ae0652f96c66fe33263d743f",
    "decimals": 18,
    "image": "https://rango.vip/i/ikoTCD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00117291,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIX",
    "name": "MixMarvel Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d285f735998f36631f678ff41fb56a10a4d0429",
    "decimals": 18,
    "image": "https://rango.vip/i/kRIC6C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00117291,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MIX",
    "name": "MixMarvel Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x398f7827dccbefe6990478876bbf3612d93baf05",
    "decimals": 18,
    "image": "https://rango.vip/i/UX9a6G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.001175891669232538,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MIX",
    "name": "Mix",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb67754f5b4c704a24d2db68e661b2875a4ddd197",
    "decimals": 18,
    "image": "https://rango.vip/i/W9Bdmz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.001175891669232538,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DFX",
    "name": "DFX Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe7804d91dfcde7f776c90043e03eaa6df87e6395",
    "decimals": 18,
    "image": "https://rango.vip/i/sujmxd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.022406425446184295,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DFX",
    "name": "DFX Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x888888435fde8e7d4c54cab67f206e4199454c60",
    "decimals": 18,
    "image": "https://rango.vip/i/ybQGkZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0222633,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DFX",
    "name": "DeFireX on BSC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x74b3abb94e9e1ecc25bd77d6872949b4a9b2aacf",
    "decimals": 18,
    "image": "https://rango.vip/i/Jne8sI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.022406425446184295,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ZEUS",
    "name": "Zeus Node Finance",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x4156f18bf7c1ef04248632c66aa119de152d8f2e",
    "decimals": 18,
    "image": "https://rango.vip/i/Cttime",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.08658377012818938,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZEUS",
    "name": "ZEUS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ZEUS1aR7aX8DFFJf5QjWj2ftDDdNTroMNGo8YoQm3Gq",
    "decimals": 6,
    "image": "https://rango.vip/i/2IVofF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.18034,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZEUS",
    "name": "Zeus AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa53f9dd70055113e13176e291eddb773f85b2650",
    "decimals": 18,
    "image": "https://rango.vip/i/7KFhs4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00078721,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZEUS",
    "name": "ZEUS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6ef460eb3563cfcc73f8147b0a77daffee71f867",
    "decimals": 18,
    "image": "https://rango.vip/i/om53ik",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00062568,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZEUS",
    "name": "Zeus",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7137e8a3b069c3f787c4ffbb901b91e4ba47d082",
    "decimals": 9,
    "image": "https://rango.vip/i/1roJS4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04374249,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DINGER",
    "name": "Dinger Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e5bd9d9fad182ff0a93ba8085b664bcab00fa68",
    "decimals": 9,
    "image": "https://rango.vip/i/0lZ38U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.88125e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PAI",
    "name": "PAI (Parrot USD)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS",
    "decimals": 6,
    "image": "https://rango.vip/i/TlBFNK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.977436,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PAI",
    "name": "Procreation AI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2TybzwN2X1g7Y6GRbB4cRx4KPK7bqdK9tYCozBXxiTfr",
    "decimals": 6,
    "image": "https://rango.vip/i/nf6aCV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.18304175060467878,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAI",
    "name": "Purple AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0cc4428fbb652c396f28dce8868b8743742a71c",
    "decimals": 18,
    "image": "https://rango.vip/i/IjdBzh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.184503,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NXD",
    "name": "Nexus Dubai",
    "isPopular": false,
    "chainId": "137",
    "address": "0x228b5c21ac00155cf62c57bcc704c0da8187950b",
    "decimals": 18,
    "image": "https://rango.vip/i/vsfGgX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.000038,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEURONI",
    "name": "Neuroni AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x922e2708462c7a3d014d8344f7c4d92b27ecf332",
    "decimals": 18,
    "image": "https://rango.vip/i/NYnXG5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.149358,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CIF",
    "name": "CATWIFHAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G3vWvAaXPHCnncnyAbq6yBRXqfRtEV3h7vExzasZeT6g",
    "decimals": 6,
    "image": "https://rango.vip/i/o1lbN0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000887,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIAMOND",
    "name": "Diamond Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcfffcd2c6294bbb01ca55cbb4a281bdcf532c1ce",
    "decimals": 9,
    "image": "https://rango.vip/i/AMwNvd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.2258e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PEDRO",
    "name": "Pedro the Raccoon",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9SiKU8vnRiBYQSBff84K5zwG7habzwYVzn7KrtgCzNfg",
    "decimals": 9,
    "image": "https://rango.vip/i/UEBF6X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00236068,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HMM",
    "name": "HMM",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BWhsvkyrUJqVvrAKjGYLpnTuUCG4SPEh6xVKcjnYCi27",
    "decimals": 9,
    "image": "https://rango.vip/i/r0TCFI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00022624,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPACEPI",
    "name": "SpacePi Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69b14e8d3cebfdd8196bfe530954a0c226e5008e",
    "decimals": 9,
    "image": "https://rango.vip/i/P5hZht",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.3e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SPACEPI",
    "name": "SpacePi Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x69b14e8d3cebfdd8196bfe530954a0c226e5008e",
    "decimals": 9,
    "image": "https://rango.vip/i/XzPsjl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.275975676882958e-9,
    "supportedSwappers": [
      "OneInchBsc",
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SRT",
    "name": "Smart Reward Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x22987407fd1fc5a971e3fda3b3e74c88666cda91",
    "decimals": 18,
    "image": "https://rango.vip/i/OW86vM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001399,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "CNR",
    "name": "Canary",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x8d88e48465f30acfb8dac0b3e35c9d6d7d36abaf",
    "decimals": 18,
    "image": "https://rango.vip/i/zCFxbH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00095808,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GENI",
    "name": "Genius",
    "isPopular": false,
    "chainId": "1",
    "address": "0x444444444444c1a66f394025ac839a535246fcc8",
    "decimals": 9,
    "image": "https://rango.vip/i/3VCvPe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000353,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SQUAD",
    "name": "Token SQUAD",
    "isPopular": false,
    "chainId": "56",
    "address": "0x724a32dfff9769a0a0e1f0515c0012d1fb14c3bd",
    "decimals": 18,
    "image": "https://rango.vip/i/AJ8u9l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00015983,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PRXY",
    "name": "Proxy",
    "isPopular": false,
    "chainId": "137",
    "address": "0xab3d689c22a2bb821f50a4ff0f21a7980dcb8591",
    "decimals": 18,
    "image": "https://rango.vip/i/GmFzJH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00831989,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BTC2X-FLI",
    "name": "BTC 2x Flexible Leverage Index",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4b54bc363f5f9c6e0fcd82eac6919ae213464cc6",
    "decimals": 18,
    "image": "https://rango.vip/i/sE7FmU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 18.75,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTC2X-FLI",
    "name": "BTC 2x Flexible Leverage Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b498ff89709d3838a063f1dfa463091f9801c2b",
    "decimals": 18,
    "image": "https://rango.vip/i/rJCOUQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 18.75,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEEZY",
    "name": "Peezy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc9bd7011ee97a13dc07087e01499a769ab7e75b4",
    "decimals": 9,
    "image": "https://rango.vip/i/z38pei",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.51832e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEEZY",
    "name": "Young Peezy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf14dd7b286ce197019cba54b189d2b883e70f761",
    "decimals": 9,
    "image": "https://rango.vip/i/m1AzCE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.8846e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEEZY",
    "name": "Young Peezy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69ee720c120ec7c9c52a625c04414459b3185f23",
    "decimals": 18,
    "image": "https://rango.vip/i/VrFUSz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.1942e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AFP",
    "name": "PIGS Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9a3321e1acd3b9f6debee5e042dd2411a1742002",
    "decimals": 18,
    "image": "https://rango.vip/i/B3PIBJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.9718289399591773,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZSC",
    "name": "Zeusshield",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a41e0517a5eca4fdbc7fbeba4d4c47b9ff6dc63",
    "decimals": 18,
    "image": "https://rango.vip/i/m3qFUx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000926,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORD",
    "name": "ordinex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe00734799a67a62af2819825580318ac1b1e4ec",
    "decimals": 18,
    "image": "https://rango.vip/i/EpOfkV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000111,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CYS",
    "name": "Cykura",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg",
    "decimals": 6,
    "image": "https://rango.vip/i/u1UEW1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00390949,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LMDA",
    "name": "lambda.markets",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LMDAmLNduiDmSiMxgae1gW7ubArfEGdAfTpKohqE5gn",
    "decimals": 6,
    "image": "https://rango.vip/i/ylg8JI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00900371,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DAI+",
    "name": "DAI Plus",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x65a2508c429a6078a7bc2f7df81ab575bd9d9275",
    "decimals": 18,
    "image": "https://rango.vip/i/YOFXL4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "AlienBase"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "QCK",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/635cb83ef1dfe598b10a3e90485306fd0d47d34217a4be5fd9977fa010a5367d",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/QCK.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.007807030521368279,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMPIRE",
    "name": "Empire Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9a2af0abb12bee5369b180976be01e8c80d0e7b6",
    "decimals": 9,
    "image": "https://rango.vip/i/FbNNMc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013077,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRIO",
    "name": "Maximus Trio",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf55cd1e399e1cc3d95303048897a680be3313308",
    "decimals": 8,
    "image": "https://rango.vip/i/Tx9wtp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00475458,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POE",
    "name": "Po et",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e0989b1f9b8a38983c2ba8053269ca62ec9b195",
    "decimals": 8,
    "image": "https://rango.vip/i/4cHIhJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000552,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BENJI",
    "name": "Basenji",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xbc45647ea894030a4e9801ec03479739fa2485f0",
    "decimals": 18,
    "image": "https://rango.vip/i/amciX9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.01573866,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BENJI",
    "name": "Taylor Swift's Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CPjDhhBpwEsLygNuczKcNwBPgMeni1xLomD48x51MyYU",
    "decimals": 9,
    "image": "https://rango.vip/i/fBTNRy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0003579,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FNF",
    "name": "FunFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xacf8d5e515ed005655dfefa09c22673a37a7cdee",
    "decimals": 18,
    "image": "https://rango.vip/i/BJxx64",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.70938e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LETSGO",
    "name": "Lets Go Brandon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa6586e19ef681b1ac0ed3d46413d199a555dbb95",
    "decimals": 18,
    "image": "https://rango.vip/i/6VbsST",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.103e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AIKEK",
    "name": "AlphaKEK AI",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x681a09a902d9c7445b3b1ab282c38d60c72f1f09",
    "decimals": 18,
    "image": "https://rango.vip/i/0h8PKT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00385071,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIKEK",
    "name": "AlphaKEK AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8dce83eca4af45dbe618da1779f9aaca43201084",
    "decimals": 18,
    "image": "https://rango.vip/i/0h8PKT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00385071,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHIPI",
    "name": "chipi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "chiPiQTvkQ7oPtAD7YLQaEeHmPqXCa2wcRQdwFNneTe",
    "decimals": 9,
    "image": "https://rango.vip/i/AKkWyX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00013234,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRTR",
    "name": "Barter",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0acf8949e705e0ebb6cb42c2164b0b986454223",
    "decimals": 8,
    "image": "https://rango.vip/i/NT4UB6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00199766,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "ZLK",
    "name": "Zenlink Network Token",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x0f47ba9d9bde3442b42175e51d6a367928a1173b",
    "decimals": 18,
    "image": "https://rango.vip/i/UqgJ7t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.01918317,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FLY",
    "name": "Fluidity",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x000f1720a263f96532d1ac2bb9cdc12b72c6f386",
    "decimals": 6,
    "image": "https://rango.vip/i/4yRuDz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00544459,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLY",
    "name": "Franklin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85f6eb2bd5a062f5f8560be93fb7147e16c81472",
    "decimals": 4,
    "image": "https://rango.vip/i/rd3rga",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00085749,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRALA",
    "name": "TRALA TOKEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd5e0eda0214f1d05af466e483d9376a77a67448b",
    "decimals": 18,
    "image": "https://rango.vip/i/FDZz6Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01069666,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "MIA",
    "name": "Mia",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xa4c7aa67189ec5623121c6c94ec757dfed932d4b",
    "decimals": 18,
    "image": "https://rango.vip/i/vbj9lD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00024592,
    "supportedSwappers": [
      "ThrusterV2.03",
      "ThrusterV2.1"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRR",
    "name": "Frontrow",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe6602b34d8510b033e000975b3322537c7172441",
    "decimals": 18,
    "image": "https://rango.vip/i/526QmX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001583,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CMFI",
    "name": "Compendium Finance",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5Wsd311hY8NXQhkt9cWHwTnqafk7BGEbLu8Py3DSnPAr",
    "decimals": 6,
    "image": "https://rango.vip/i/GbqF2D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00012799,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UDT",
    "name": "Unlock Discount Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x90de74265a416e1393a450752175aed98fe11517",
    "decimals": 18,
    "image": "https://rango.vip/i/XvGhum",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.24,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "UDT",
    "name": "Unlock Discount Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd5d3aa404d7562d09a848f96a8a8d5d65977bf90",
    "decimals": 18,
    "image": "https://rango.vip/i/T1vhOd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 6.507621431195495,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "UDT",
    "name": "Unlock Discount Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc709c9116dbf29da9c25041b13a07a0e68ac5d2d",
    "decimals": 18,
    "image": "https://rango.vip/i/6lKmkc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 6.507621431195495,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MEMES",
    "name": "MemeCoinDAOai",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8W4qpyLx74vwBRewa3rVEPPVMnJ8VWMkCTWCTSYPQTDu",
    "decimals": 6,
    "image": "https://rango.vip/i/ki88jO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0007779172285996982,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEMES",
    "name": "MEMES ST",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19af07b52e5faa0c2b1e11721c52aa23172fe2f5",
    "decimals": 9,
    "image": "https://rango.vip/i/uCHMmw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.31519e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HUSKY",
    "name": "Husky",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x65378b697853568da9ff8eab60c13e1ee9f4a654",
    "decimals": 18,
    "image": "https://rango.vip/i/8fu3En",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 3.3456e-8,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LOAF",
    "name": "LOAF CAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3de2yRhtD4VbJBb8EQAQffYMPLU4EnSHT1eveBwiL3tn",
    "decimals": 6,
    "image": "https://rango.vip/i/EMEsTL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 7.46015e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LILA",
    "name": "LiquidLayer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x96add417293a49e80f024734e96cfd8b355bcc14",
    "decimals": 18,
    "image": "https://rango.vip/i/3AYzP3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02684902,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MYB",
    "name": "MyBit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc",
    "decimals": 18,
    "image": "https://rango.vip/i/7txgc5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015011,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PICKLE",
    "name": "PickleToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x429881672b9ae42b8eba0e26cd9c73711b891ca5",
    "decimals": 18,
    "image": "https://rango.vip/i/EjdEw9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.664467,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "Rainbow Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "PICKLE",
    "name": "PickleToken",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x291c8fceaca3342b29cc36171deb98106f712c66",
    "decimals": 18,
    "image": "https://rango.vip/i/LPaa2J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.664467,
    "supportedSwappers": [
      "Rainbow Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PICKLE",
    "name": "PickleToken",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x965772e0e9c84b6f359c8597c891108dcf1c5b1a",
    "decimals": 18,
    "image": "https://rango.vip/i/7Gl0Jg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.664467,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PICKLE",
    "name": "PickleToken",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2b88ad57897a8b496595925f43048301c37615da",
    "decimals": 18,
    "image": "https://rango.vip/i/400AVN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.664467,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PICKLE",
    "name": "PICKLE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ec15c4745e21ab3831d1f51c492e3b5582d6239",
    "decimals": 18,
    "image": "https://rango.vip/i/eVOnpA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.1193e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PICKLE",
    "name": "Pickle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6942016b8de9d18a5831eeda915e48b27cc8e23d",
    "decimals": 9,
    "image": "https://rango.vip/i/g6yIC5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00075563,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TPAD",
    "name": "TaoPad",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5483dc6abda5f094865120b2d251b5744fc2ecb5",
    "decimals": 18,
    "image": "https://rango.vip/i/IGpCcu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.105021,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIBGF",
    "name": "SHIBGF",
    "isPopular": false,
    "chainId": "1",
    "address": "0x505a84a03e382331a1be487b632cf357748b65d6",
    "decimals": 18,
    "image": "https://rango.vip/i/ifIf3Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.75049e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAF",
    "name": "Pacific",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a969d379700b2e5ea4e684d273d63c1c050ba49",
    "decimals": 18,
    "image": "https://rango.vip/i/LGLr7t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012775,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GLXY",
    "name": "Astrals GLXY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CJ5U6wPmjxFUyTJpUTS7Rt1UqhTmSVRMvmJ8WD4nndXW",
    "decimals": 9,
    "image": "https://rango.vip/i/XLO6T5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00123762,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SVN",
    "name": "Savanna",
    "isPopular": false,
    "chainId": "25",
    "address": "0x654bac3ec77d6db497892478f854cf6e8245dca9",
    "decimals": 18,
    "image": "https://rango.vip/i/2esNzu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00059655,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SVN",
    "name": "StakeVault Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce872db165d4f5623af9c29e03afd416bc5f67bc",
    "decimals": 18,
    "image": "https://rango.vip/i/NEl6dI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.067785,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNS",
    "name": "Monnos Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53884b61963351c283118a8e1fc05ba464a11959",
    "decimals": 18,
    "image": "https://rango.vip/i/5o5J95",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010006,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNS",
    "name": "MINESHIELD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x55ff0f50f639e7acfe06694e6d018bd7678e6da9",
    "decimals": 9,
    "image": "https://rango.vip/i/iT1nJ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010449,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MASK",
    "name": "Mask Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69af81e73a73b40adf4f3d4223cd9b1ece623074",
    "decimals": 18,
    "image": "https://rango.vip/i/TKLq02",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.97,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MASK",
    "name": "Mask Network",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2ed9a5c8c13b93955103b9a7c167b67ef4d568a3",
    "decimals": 18,
    "image": "https://rango.vip/i/cbXUHd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.97,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MASK",
    "name": "Mask Network (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7",
    "decimals": 18,
    "image": "https://rango.vip/i/Z1VdO3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.97,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MASK",
    "name": "Wojak Mask",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2b8aac1630f7bc0c4b1ed8036c0fe0d71cb44709",
    "decimals": 9,
    "image": "https://rango.vip/i/QfghSr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.56147e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MASK",
    "name": "Mask Network",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x533a7b414cd1236815a5e09f1e97fc7d5c313739",
    "decimals": 18,
    "image": "https://rango.vip/i/huCeWR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.9790731467818277,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MASK",
    "name": "Mask Network",
    "isPopular": false,
    "chainId": "10",
    "address": "0x3390108e913824b8ead638444cc52b9abdf63798",
    "decimals": 18,
    "image": "https://rango.vip/i/dEArZW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.9790731467818277,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ICOM",
    "name": "iCommunity",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb131f337c45d386ceec234e194b2663d5c3d9dcf",
    "decimals": 18,
    "image": "https://rango.vip/i/yfY9vj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01870176,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SUNNY",
    "name": "Sunny Governance Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag",
    "decimals": 6,
    "image": "https://rango.vip/i/9v1TXT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000336,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKRT",
    "name": "Sekuritance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x887168120cb89fb06f3e74dc4af20d67df0977f6",
    "decimals": 18,
    "image": "https://rango.vip/i/XNvL6c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00037477,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POODL",
    "name": "Poodl Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b7c762af92dbd917d159eb282b85aa13e955739",
    "decimals": 18,
    "image": "https://rango.vip/i/hP7qdk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000427,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "POODL",
    "name": "Poodl",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4a68c250486a116dc8d6a0c5b0677de07cc09c5d",
    "decimals": 9,
    "image": "https://rango.vip/i/hnda5t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4.443e-9,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ICONS",
    "name": "SportsIcon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f68e7b44e9bcb486c2feadb7a2289d9cdfc9088",
    "decimals": 18,
    "image": "https://rango.vip/i/hk9fZj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02348628359365612,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SURF",
    "name": "SURF.Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea319e87cf06203dae107dd8e5672175e3ee976c",
    "decimals": 18,
    "image": "https://rango.vip/i/GWv5oz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02999413657446289,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPENX",
    "name": "OpenSwap One",
    "isPopular": false,
    "chainId": "1",
    "address": "0x57d579f483854c62fef850b8a5332b0d8424b7e2",
    "decimals": 18,
    "image": "https://rango.vip/i/PGtWwX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00078983,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WEB",
    "name": "Web",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2b81945875f892aff04af0a298d35fb2cf848c7b",
    "decimals": 9,
    "image": "https://rango.vip/i/nkPaFZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.37727e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOGGY",
    "name": "Boggy Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GMEhF4sFXd9PRR9KJo7hyPjeHdcdg5yxTNP22KKNyWvZ",
    "decimals": 9,
    "image": "https://rango.vip/i/l2wHgq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008953,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ICSA",
    "name": "Icosa  ETH ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc4913214444af5c715cc9f7b52655e788a569ed",
    "decimals": 9,
    "image": "https://rango.vip/i/WSnvGA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01861465,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "WAD",
    "name": "WardenSwap",
    "isPopular": false,
    "chainId": "10",
    "address": "0x703d57164ca270b0b330a87fd159cfef1490c0a5",
    "decimals": 18,
    "image": "https://rango.vip/i/YgH5tl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.012050909348219282,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAD",
    "name": "WardenSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b6f3c17e1626a7cbfa4302ce4e3c45522d23a83",
    "decimals": 18,
    "image": "https://rango.vip/i/YgH5tl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.012050909348219282,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ATM",
    "name": "Atletico de Madrid",
    "isPopular": false,
    "chainId": "56",
    "address": "0x25e9d05365c867e59c1904e7463af9f312296f9e",
    "decimals": 2,
    "image": "https://rango.vip/i/ge82Z3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.7551665754853623,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HSC",
    "name": "HashCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2bba3cf6de6058cc1b4457ce00deb359e2703d7f",
    "decimals": 18,
    "image": "https://rango.vip/i/EXjJZD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000326,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SKULL",
    "name": "Skeleton Crew",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SKu11EypaFU3gvr8VSAbi13zEC2CPvqbz9s83N3tWHM",
    "decimals": 0,
    "image": "https://rango.vip/i/axdTuO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006892420467377815,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SKULL",
    "name": "PEPE SKULL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3X36yhq35MJnt2JjwodeFDfv2MFPb99RC53yUyNrpump",
    "decimals": 6,
    "image": "https://rango.vip/i/hUZl8r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001563,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKULL",
    "name": "WOLF SKULL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7022fe5fedbd54b40fdc52be30c1c578fb55c2bf",
    "decimals": 18,
    "image": "https://rango.vip/i/kWShxt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.03148e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "CATWIF",
    "name": "Cat wif Hands",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x3377aca4c0bfd021be6bd762b5f594975e77f9cf",
    "decimals": 18,
    "image": "https://rango.vip/i/onraGM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 7.17277e-7,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SIZE",
    "name": "SIZE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HqLRjru6pD6GFGnQ7TwSSGQRuPhF8UZNey9T4yCsZzuq",
    "decimals": 6,
    "image": "https://rango.vip/i/lmM2Fn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000788,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WEAPON",
    "name": "Megaweapon",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xe3b3f75f99da4ff26aa867ef70b48f8f6b2d4958",
    "decimals": 9,
    "image": "https://rango.vip/i/d6JKyB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.2434318523543826,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "Y2K",
    "name": "Y2K",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x65c936f008bc34fe819bce9fa5afd9dc2d49977f",
    "decimals": 18,
    "image": "https://rango.vip/i/UVCFbB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.193888,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BFR",
    "name": "Buffer Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1a5b0aaf478bf1fda7b934c76e7692d722982a6d",
    "decimals": 18,
    "image": "https://rango.vip/i/bUKRej",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01447352,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BACON",
    "name": "BaconDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34f797e7190c131cf630524655a618b5bd8738e7",
    "decimals": 18,
    "image": "https://rango.vip/i/HvauES",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027199,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BACON",
    "name": "Bacon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe08cd119df4c2f147f623559d6985b8afe315873",
    "decimals": 9,
    "image": "https://rango.vip/i/SdEibS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000613,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BACON",
    "name": "BaconCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa54d2ebfd977ad836203c85f18db2f0a0cf88854",
    "decimals": 18,
    "image": "https://rango.vip/i/hEn91R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0002720563840208644,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "APETARDIO",
    "name": "Apetardio",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xe161be4a74ab8fa8706a2d03e67c02318d0a0ad6",
    "decimals": 18,
    "image": "https://rango.vip/i/Mucrry",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00011592,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NMX",
    "name": "Nominex",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd32d01a43c869edcd1117c640fbdcfcfd97d9d65",
    "decimals": 18,
    "image": "https://rango.vip/i/r6pZ5R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00727328,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ETERNAL",
    "name": "CryptoMines Eternal",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd44fd09d74cd13838f137b590497595d6b3feea4",
    "decimals": 18,
    "image": "https://rango.vip/i/jM8p9E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.169188,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "NCT",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/a76eb6ecf4e3e2d4a23c526fd1b48fdd42f171b206c9d2758ef778a7826add68",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/NCT.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.703092,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NCT",
    "name": "Nectar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e46a38f5daabe8683e10793b06749eef7d733d1",
    "decimals": 18,
    "image": "https://rango.vip/i/Hb3CvE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01287063,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "NCT",
    "name": "Nature Carbon Tonne",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x02de4766c272abc10bc88c220d214a26960a7e92",
    "decimals": 18,
    "image": "https://rango.vip/i/Ch7Bp3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.012893401704707432,
    "supportedSwappers": [
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NCT",
    "name": "Toucan Protocol: Nature Carbon Tonne",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd838290e877e0188a4a44700463419ed96c16107",
    "decimals": 18,
    "image": "https://rango.vip/i/D2qXXO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.703092,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NCT",
    "name": "NameChangeToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e",
    "decimals": 18,
    "image": "https://rango.vip/i/URn0VY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.012893401704707432,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NCT",
    "name": "North Cat Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x29542a3f517d022e5cd126fc72e106ab1601bfae",
    "decimals": 18,
    "image": "https://rango.vip/i/BHt3XV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00159478,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NCT",
    "name": "PolySwarm",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x53236015a675fcb937485f1ae58040e4fb920d5b",
    "decimals": 18,
    "image": "https://rango.vip/i/ESFuie",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.012893401704707432,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "REGEN",
    "symbol": "NCT",
    "name": null,
    "isPopular": true,
    "chainId": "regen-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/HNKWDX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/REGEN/icon.svg",
    "usdPrice": 0.6745513584818085,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPAY",
    "name": "SpaceY 2025",
    "isPopular": false,
    "chainId": "1",
    "address": "0x58fad9e3c3ae54c9ba98c3f0e4bf88ab3e8cf3c5",
    "decimals": 18,
    "image": "https://rango.vip/i/fCue8F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04313415,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOTT",
    "name": "Beauty Bakery Linked Operation Transact",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba93ef534094f8b7001ece2691168140965341ab",
    "decimals": 18,
    "image": "https://rango.vip/i/TmwUvt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00449906,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ACRE",
    "name": "Arable Protocol",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x00ee200df31b869a321b10400da10b561f3ee60d",
    "decimals": 18,
    "image": "https://rango.vip/i/NXrJ4H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00002279563797179914,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ACRE",
    "name": "Arable Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x5f006745a9a192a7cd1236089f704f9b35d3b9cd",
    "decimals": 18,
    "image": "https://rango.vip/i/CeiWCz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00002248,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACRE",
    "name": "Arable Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2cabf797bc907b049e4ccb5b84d13be3a8cfc21",
    "decimals": 18,
    "image": "https://rango.vip/i/CeiWCz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002248,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SWIFT",
    "name": "SOLANA SUMMER",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7ACrQshyNAULRWqZD7tQWpvUqV4pnpeRALoafXfsZVQa",
    "decimals": 6,
    "image": "https://rango.vip/i/oHxx9Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0003926313345987515,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WJXN",
    "name": "Jax Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xca1262e77fb25c0a4112cfc9bad3ff54f617f2e6",
    "decimals": 0,
    "image": "https://rango.vip/i/n2QHCS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01559493,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DEO",
    "name": "Deo",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DeoP2swMNa9d4SGcQkR82j4RYYeNhDjcTCwyzEhKwfAf",
    "decimals": 9,
    "image": "https://rango.vip/i/p7yE2n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.071215,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEO",
    "name": "Demeter",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5e7f20e72c21f6d0bf0a2814fd4164176401cf8e",
    "decimals": 18,
    "image": "https://rango.vip/i/YZu7yR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0171669,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VNDC",
    "name": "VNDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f3f677ecc58f6a1f9e2cf410df4776a8546b5de",
    "decimals": 0,
    "image": "https://rango.vip/i/T03Wm0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003932,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "NORMUS",
    "name": "Normus",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xba5ede8d98ab88cea9f0d69918dde28dc23c2553",
    "decimals": 18,
    "image": "https://rango.vip/i/EJUpTR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00005768,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAPROOT",
    "name": "Taproot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07b701ac44aacb03d8bed42eb85ec38210bdf513",
    "decimals": 6,
    "image": "https://rango.vip/i/dYTqyl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00231429,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CERES",
    "name": "Ceres",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e7b0d4f9b2eaf782ed3d160e3a0a4b1a7930ada",
    "decimals": 18,
    "image": "https://rango.vip/i/Fl0V0o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 11.39,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KTT",
    "name": "K Tune",
    "isPopular": false,
    "chainId": "1",
    "address": "0x14cc8dfaf2258e1b8b2869300dba1b734dc0fe43",
    "decimals": 18,
    "image": "https://rango.vip/i/Yas0sJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00071442,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OKINAMI",
    "name": "Kanagawa Nami",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c4853ec0d55e420002c5efabc7ed8e0ba7a4121",
    "decimals": 9,
    "image": "https://rango.vip/i/Ae9ZKN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00089834,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TUCKER",
    "name": "TUCKER CARLSON",
    "isPopular": false,
    "chainId": "1",
    "address": "0x50b806c5fe274c07e46b96be8c68d2fd2d9597b4",
    "decimals": 18,
    "image": "https://rango.vip/i/RxWF9t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000174,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XWIN",
    "name": "xWIN Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28",
    "decimals": 18,
    "image": "https://rango.vip/i/mviyh1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01309618,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NVG",
    "name": "NightVerse Game",
    "isPopular": false,
    "chainId": "1",
    "address": "0x08f40811c7d6c013744166f3d4cb1a9a92d3d54e",
    "decimals": 18,
    "image": "https://rango.vip/i/IJobuN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00161142,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHEB",
    "name": "Sheboshis",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5de869e3e62b0fb2c15573246ba3bb3fd97a2275",
    "decimals": 18,
    "image": "https://rango.vip/i/ckMqff",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 56.78,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHEB",
    "name": "SHEBOSHIS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6930450a416252c7206fbce76c01ecc850a36cb9",
    "decimals": 9,
    "image": "https://rango.vip/i/xZXHzL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.98956e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SIMPSON",
    "name": "Homer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44aad22afbb2606d7828ca1f8f9e5af00e779ae1",
    "decimals": 9,
    "image": "https://rango.vip/i/2RiSC8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.675e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BRIUN",
    "name": "Briun Armstrung",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8c81b4c816d66d36c4bf348bdec01dbcbc70e987",
    "decimals": 18,
    "image": "https://rango.vip/i/IpiJbl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00030423,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PORTX",
    "name": "ChainPort",
    "isPopular": false,
    "chainId": "1",
    "address": "0x104f3152d8ebfc3f679392977356962ff36566ac",
    "decimals": 18,
    "image": "https://rango.vip/i/fkdDqe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00154744,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KIRA",
    "name": "KIRA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "kiraZUmSnzgfVfhrdvNj6hxHFaPFTTUk8ioY98cbh6G",
    "decimals": 9,
    "image": "https://rango.vip/i/ak4qL0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.067904,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KIRA",
    "name": "KIRA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf98ab0874b13a7fdc39d7295dedd49850a5d426b",
    "decimals": 8,
    "image": "https://rango.vip/i/4kg0Yl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00091725,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QTDAO",
    "name": "Quantum DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26869045311fc5e5353eadcfa654cd47ddc20356",
    "decimals": 18,
    "image": "https://rango.vip/i/8nd7FQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.502204059385957e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NII",
    "name": "Nahmii",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c8155909cd385f120a56ef90728dd50f9ccbe52",
    "decimals": 15,
    "image": "https://rango.vip/i/Va9juL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013588,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNT",
    "name": "Status Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x744d70fdbe2ba4cf95131626614a1763df805b9e",
    "decimals": 18,
    "image": "https://rango.vip/i/QkCXhc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02320889,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNT",
    "name": "Sentinel Bot AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x78ba134c3ace18e69837b01703d07f0db6fb0a60",
    "decimals": 18,
    "image": "https://rango.vip/i/TiUk8g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015152,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SNT",
    "name": "Status",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x707f635951193ddafbb40971a0fcaab8a6415160",
    "decimals": 18,
    "image": "https://rango.vip/i/CsYwVL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.023119196557041522,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SNT",
    "name": "Status Network Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x650af3c15af43dcb218406d30784416d64cfb6b2",
    "decimals": 18,
    "image": "https://rango.vip/i/Tc1WaF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.023119196557041522,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0NE",
    "name": "Civfund Stone",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73a83269b9bbafc427e76be0a2c1a1db2a26f4c2",
    "decimals": 18,
    "image": "https://rango.vip/i/zwYQY6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.4597e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ANON",
    "name": "anon coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A53BzB7297SXdF6mguQQ8kzqjVYt8pUeHW5m1i8pD6hf",
    "decimals": 6,
    "image": "https://rango.vip/i/EUeOvz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0028787639711554793,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ANON",
    "name": "Anonymous",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8VJ51bdE3xorQ1zB7FEa8CsHdM4kw77xCFiCgbnL2qbT",
    "decimals": 6,
    "image": "https://rango.vip/i/JcdYsr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006876,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANON",
    "name": "Anon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f0efa15e9cb7ea9596257da63fecc36ba469b30",
    "decimals": 18,
    "image": "https://rango.vip/i/vSi5Qr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "RF",
    "name": "ReactorFusion",
    "isPopular": false,
    "chainId": "324",
    "address": "0x5f7cbcb391d33988dad74d6fd683aadda1123e4d",
    "decimals": 18,
    "image": "https://rango.vip/i/ho8Lmr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00818134,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HXRO",
    "name": "Hxro (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK",
    "decimals": 8,
    "image": "https://rango.vip/i/Q1sXDu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00678147,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HXRO",
    "name": "HXRO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4bd70556ae3f8a6ec6c4080a0c327b24325438f3",
    "decimals": 18,
    "image": "https://rango.vip/i/3VyrEz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00678147,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BTL",
    "name": "BitLegacy Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "aYZPYgohjK6LYM8o1v6pnr3ZinhuRzSHd6TRDVDUBkK",
    "decimals": 8,
    "image": "https://rango.vip/i/vNfDY7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0006382177578003451,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTL",
    "name": "Bitlocus",
    "isPopular": false,
    "chainId": "1",
    "address": "0x93e32efafd24973d45f363a76d73ccb9edf59986",
    "decimals": 6,
    "image": "https://rango.vip/i/z5IDu1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00064104,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HFUN",
    "name": "Hold fun",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6bec5f1c594af73202cd3e5c1f699d440959954c",
    "decimals": 18,
    "image": "https://rango.vip/i/TCBjjZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00035012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "ORBIT",
    "name": "Orbit Protocol",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x42e12d42b3d6c4a74a88a61063856756ea2db357",
    "decimals": 18,
    "image": "https://rango.vip/i/RxTOqu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00637907,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SCOT",
    "name": "SCOT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EoJEyppNNpycP1ZoPnWq6cxwA7mSYpr9T1WXPFyjEzEy",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000005954167959252232,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BXH",
    "name": "BXH",
    "isPopular": false,
    "chainId": "128",
    "address": "0xcbd6cb9243d8e3381fea611ef023e17d1b7aedf0",
    "decimals": 18,
    "image": "https://rango.vip/i/ST0PHL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00126848,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SAFEMARS",
    "name": "SafeMars",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3ad9594151886ce8538c1ff615efa2385a8c3a88",
    "decimals": 9,
    "image": "https://rango.vip/i/H0s3y0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 7.576e-9,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NTX",
    "name": "NuNet Utility Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0d33beda4d734c72684b5f9abbebf715d0a7935",
    "decimals": 6,
    "image": "https://rango.vip/i/kxu6Bt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02433264,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NTX",
    "name": "NitroEx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfdb15e5e6799be72798b1ccfaecbf186bf73a0c4",
    "decimals": 8,
    "image": "https://rango.vip/i/XpwiWs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003592,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RIVUS",
    "name": "RivusDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60e254e35dd712394b3aba7a1d19114732e143dd",
    "decimals": 18,
    "image": "https://rango.vip/i/cYZn0S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014294,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CLOUD",
    "name": "Cloud",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CLoUDKc4Ane7HeQcPpE3YHnznRxhMimJ4MyaUqyHFzAu",
    "decimals": 9,
    "image": "https://rango.vip/i/OgyYIv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.23616,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLOUD",
    "name": "DeCloud",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf2dfdbe1ea71bbdcb5a4662a16dbf5e487be3ebe",
    "decimals": 18,
    "image": "https://rango.vip/i/Lsoqqi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003608,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOPE",
    "name": "Book of Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x599955aa9fbc197a1b717d8da6a7012cafe70ab3",
    "decimals": 9,
    "image": "https://rango.vip/i/viNtVo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.70064e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WZM",
    "name": "Woozoo Music",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd15a1a2a3211b58113e45809f05934252e34e2f8",
    "decimals": 18,
    "image": "https://rango.vip/i/deDNNk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001735,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WAG",
    "name": "Waggle Network",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E",
    "decimals": 9,
    "image": "https://rango.vip/i/tei9MB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00098393,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RECORD",
    "name": "Music Protocol",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xe642657e4f43e6dcf0bd73ef24008394574dee28",
    "decimals": 18,
    "image": "https://rango.vip/i/aZZdlu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.01410324,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "REUNI",
    "name": "Reunit Wallet",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9ed7e4b1bff939ad473da5e7a218c771d1569456",
    "decimals": 6,
    "image": "https://rango.vip/i/dChV9y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.140181,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REUNI",
    "name": "Reunit Wallet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ed7e4b1bff939ad473da5e7a218c771d1569456",
    "decimals": 6,
    "image": "https://rango.vip/i/dChV9y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.140181,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIS",
    "name": "TosDis",
    "isPopular": false,
    "chainId": "1",
    "address": "0x220b71671b649c03714da9c621285943f3cbcdc6",
    "decimals": 18,
    "image": "https://rango.vip/i/wkzRZW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.978705,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SHELL",
    "name": "Shell",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe47ba52f326806559c1dec7ddd997f6957d0317d",
    "decimals": 18,
    "image": "https://rango.vip/i/oy2bYO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01273332,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHELL",
    "name": "Shell",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8dcaec45365e5ada5676073a07b418c2f538145a",
    "decimals": 18,
    "image": "https://rango.vip/i/oy2bYO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01273332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SHELL",
    "name": "Meta Apes Shell",
    "isPopular": false,
    "chainId": "56",
    "address": "0x208cfec94d2ba8b8537da7a9bb361c6baad77272",
    "decimals": 18,
    "image": "https://rango.vip/i/3fWEJz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0013402488872513382,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIRTU",
    "name": "Virtucloud",
    "isPopular": false,
    "chainId": "1",
    "address": "0x102dc1840f0c3c179670f21fa63597e82df34e60",
    "decimals": 18,
    "image": "https://rango.vip/i/2fLCcb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00030525,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SNK",
    "name": "Snook",
    "isPopular": false,
    "chainId": "137",
    "address": "0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309",
    "decimals": 18,
    "image": "https://rango.vip/i/MKmQgI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00272788,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DPY",
    "name": "Delphy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c2adc2073994fb2ccc5032cc2906fa221e9b391",
    "decimals": 18,
    "image": "https://rango.vip/i/6YMhMP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00144652,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COZY",
    "name": "Cozy Pepe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "cozyLxNaoJvQ3KB5dCJdu7MoZiBpwBWGdvc4dkMXnqA",
    "decimals": 6,
    "image": "https://rango.vip/i/zNjqGb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00014897,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MEDIA",
    "name": "Media Network",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6e51b3a19f114013e5dc09d0477a536c7e4e0207",
    "decimals": 18,
    "image": "https://rango.vip/i/RfKKGz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 10.86,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MEDIA",
    "name": "Solmedia",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BNT4uhSStq1beFADv3cq4wQAVfWB392PjAaxTBpNeWxu",
    "decimals": 6,
    "image": "https://rango.vip/i/bcYthA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.001558,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MEDIA",
    "name": "Media Network",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs",
    "decimals": 6,
    "image": "https://rango.vip/i/KaYMLW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 10.86,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEDIA",
    "name": "Media Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb726152680ece3c9291f1016f1d36f3995f6941",
    "decimals": 6,
    "image": "https://rango.vip/i/kZkZU8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 10.86,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DGEN",
    "name": "DGEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x45f93404ae1e4f0411a7f42bc6a5dc395792738d",
    "decimals": 18,
    "image": "https://rango.vip/i/d2RVZX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00119614,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGGY",
    "name": "DOGGY COIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x09d92c109b475dd513292c76544b4e250da13faa",
    "decimals": 18,
    "image": "https://rango.vip/i/sMZ38S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.48386e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DOGGY",
    "name": "DOGGY",
    "isPopular": false,
    "chainId": "56",
    "address": "0x74926b3d118a63f6958922d3dc05eb9c6e6e00c6",
    "decimals": 18,
    "image": "https://rango.vip/i/1lJ2Z3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0003239,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VBNT",
    "name": "Bancor Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x48fb253446873234f2febbf9bdeaa72d9d387f94",
    "decimals": 18,
    "image": "https://rango.vip/i/jpOCkR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.347918,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VCORE",
    "name": "IMVU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x733b5056a0697e7a4357305fe452999a0c409feb",
    "decimals": 18,
    "image": "https://rango.vip/i/sqqRUg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00093618,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DGNX",
    "name": "DegenX",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x51e48670098173025c477d9aa3f0eff7bf9f7812",
    "decimals": 18,
    "image": "https://rango.vip/i/tSE47F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.03281860049778338,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DGNX",
    "name": "DegenX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0000000000300dd8b0230efcfef136ecdf6abcde",
    "decimals": 18,
    "image": "https://rango.vip/i/K8fmje",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03264746,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHIX",
    "name": "Ethix",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd09911130e6930bf87f2b0554c44f400bd80d3e",
    "decimals": 18,
    "image": "https://rango.vip/i/RQ1m6T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.144183,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "ETHIX",
    "name": "Ethix on Celo",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x9995cc8f20db5896943afc8ee0ba463259c931ed",
    "decimals": 18,
    "image": "https://rango.vip/i/8lsRxD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.144183,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIKARI",
    "name": "Hikari Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd4126f195a8de772eeffa61a4ab6dd43462f4e39",
    "decimals": 18,
    "image": "https://rango.vip/i/fR8hJ5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00042002,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVG",
    "name": "Convergence Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97effb790f2fbb701d88f89db4521348a2b77be8",
    "decimals": 18,
    "image": "https://rango.vip/i/sCHlcz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00180368,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WSB",
    "name": "Wall Street Baby",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7zBbQAPGgoKvqcK74Yua8qGwEkEjAZxUPb5m3kKvvHyF",
    "decimals": 6,
    "image": "https://rango.vip/i/45d5TT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003177,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WSB",
    "name": "Wall Street Bets",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AkVt31h8vgji5wF4nVbq1QmBV5wBoe8JdSoDTkDhQwEw",
    "decimals": 6,
    "image": "https://rango.vip/i/Tnj2GI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 8.546345274372256e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSB",
    "name": "WALL STREET BABY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15f20f9dfdf96ccf6ac96653b7c0abfe4a9c9f0f",
    "decimals": 18,
    "image": "https://rango.vip/i/9LVBGJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.574e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSB",
    "name": "WSB Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0414d8c87b271266a5864329fb4932bbe19c0c49",
    "decimals": 18,
    "image": "https://rango.vip/i/g4hxnY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000454,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSB",
    "name": "Wall Street Bets",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6f91d21de4e40b3b80636b6b3ba425b636b798cf",
    "decimals": 9,
    "image": "https://rango.vip/i/GrWwZ3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.0224e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSB",
    "name": "pTokens WSB",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1590a6fa0cff9c960181cb77d8a873601772f64",
    "decimals": 18,
    "image": "https://rango.vip/i/BgHGDY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015863,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCD",
    "name": "On Chain Dynamics",
    "isPopular": false,
    "chainId": "1",
    "address": "0x017e9db34fc69af0dc7c7b4b33511226971cddc7",
    "decimals": 18,
    "image": "https://rango.vip/i/ujfnXu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00083872,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MRCH",
    "name": "MerchDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbed4ab0019ff361d83ddeb74883dac8a70f5ea1e",
    "decimals": 18,
    "image": "https://rango.vip/i/r04B3e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00518941,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WAGMI",
    "name": "WAGMI",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7466de7bb8b5e41ee572f4167de6be782a7fa75d",
    "decimals": 18,
    "image": "https://rango.vip/i/RRawbk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00009008,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAGMI",
    "name": "Wagmi Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xef8e456967122db4c3c160314bde8d2602ad6199",
    "decimals": 9,
    "image": "https://rango.vip/i/50kTTA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.23233e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAGMI",
    "name": "Wagmi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x92cc36d66e9d739d50673d1f27929a371fb83a67",
    "decimals": 18,
    "image": "https://rango.vip/i/GW7NBj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00478393,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHILI",
    "name": "CHILI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GPyzPHuFFGvN4yWWixt6TYUtDG49gfMdFFi2iniTmCkh",
    "decimals": 2,
    "image": "https://rango.vip/i/ZasTQe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.82e-11,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "BCNA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/d805f1da50d31b96e4282c1d4181eddfb1a44a598bff5666f4b43e4b8bea95a5",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/BCNA.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0028609,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "BITCANNA",
    "symbol": "BCNA",
    "name": null,
    "isPopular": true,
    "chainId": "bitcanna-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/SWqzf8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BITCANNA/icon.svg",
    "usdPrice": 0.00287858976355632,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABYSHIB",
    "name": "Baby Shiba Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00000000051b48047be6dc0ada6de5c3de86a588",
    "decimals": 18,
    "image": "https://rango.vip/i/R2hAgN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00088877,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SLB",
    "name": "Solberg",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7",
    "decimals": 9,
    "image": "https://rango.vip/i/7ZnQO0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01049694,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLOVI",
    "name": "Flovi Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x725024200cd4e1f259fcf2b7153d37fb477e139c",
    "decimals": 9,
    "image": "https://rango.vip/i/d4HLD5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.89856e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCHIVE",
    "name": "Chainback",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc7f950271d118a5bdf250dffc39128dcced8472c",
    "decimals": 18,
    "image": "https://rango.vip/i/Ar7rvk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00069732,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOTG",
    "name": "MetaOctagon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x171d76d931529384639bc9aad5b77b77041ed604",
    "decimals": 18,
    "image": "https://rango.vip/i/ERX4bS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00052651,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WSBS",
    "name": "Wall Street Bets Solana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5RDHUiwLChkKfusxeu9kEDUtRfxsDWV8etoopnZMwVM7",
    "decimals": 6,
    "image": "https://rango.vip/i/Wdf6IB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000005383801831253858,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANDWU",
    "name": "Chinese Andy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e2e7a1f05946ecb2b43b99e3fc2984fa7d7e3bc",
    "decimals": 9,
    "image": "https://rango.vip/i/t5ksfe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.11097e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SBIO",
    "name": "Vector Space Biosciences  Inc ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b5c38cc2d1ba05ed87c8f8a2418475bacb20073",
    "decimals": 18,
    "image": "https://rango.vip/i/S1QszS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.057702,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "HERA",
    "name": "Hera Token",
    "isPopular": false,
    "chainId": "1088",
    "address": "0x6f05709bc91bad933346f9e159f0d3fdbc2c9dce",
    "decimals": 18,
    "image": "https://rango.vip/i/62CaIT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 1.17,
    "supportedSwappers": [
      "Netswap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HERA",
    "name": "Hera Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2c2c937333165d4c5f2dc5f31a43e1239fecfeb",
    "decimals": 18,
    "image": "https://rango.vip/i/feTbSk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.17,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DEFIDO",
    "name": "DeFido",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xd064c53f043d5aee2ac9503b13ee012bf2def1d0",
    "decimals": 18,
    "image": "https://rango.vip/i/roKhwS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00027173,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MEREDITH",
    "name": "Taylor Swift's Cat MEREDITH",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7y3mLy8vai6uMt4iVfgm1gN5GRpQ4aMqtMJgM6oLuoeM",
    "decimals": 6,
    "image": "https://rango.vip/i/AKC3ku",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000017351672102640775,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTO",
    "name": "Merchant",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe66b3aa360bb78468c00bebe163630269db3324f",
    "decimals": 18,
    "image": "https://rango.vip/i/LcmPXV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03882276,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "GCAT",
    "name": "Giga Cat",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xe4fc328ae212232efc5f5dd0e0b1537cd055d715",
    "decimals": 9,
    "image": "https://rango.vip/i/D5H7uq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0000282,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOKU",
    "name": "Goku Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa64dfe8d86963151e6496bee513e366f6e42ed79",
    "decimals": 9,
    "image": "https://rango.vip/i/ouULpa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.403e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHM",
    "name": "Ethereum Meta",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd957f21bd95e723645c07c48a2d8acb8ffb3794",
    "decimals": 18,
    "image": "https://rango.vip/i/de7Qh0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.85192e-13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SLC",
    "name": "Solice",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL",
    "decimals": 6,
    "image": "https://rango.vip/i/yJxfdN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00090136,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GOFX",
    "name": "GooseFX",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD",
    "decimals": 9,
    "image": "https://rango.vip/i/SzqY9M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00410216,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "LBM",
    "name": "Libertum",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x56a38e7216304108e841579041249feb236c887b",
    "decimals": 18,
    "image": "https://rango.vip/i/U805mj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0337364,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "POV",
    "name": "Degen POV",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x4c96a67b0577358894407af7bc3158fc1dffbeb5",
    "decimals": 18,
    "image": "https://rango.vip/i/9Amk0u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 3.9126e-8,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POV",
    "name": "Pepe Original Version",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15a3081b541e8dad25c4a5e0c4c4b4e8d105b2e8",
    "decimals": 18,
    "image": "https://rango.vip/i/RcVCKW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.27171e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BALD",
    "name": "Bald",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x27d2decb4bfc9c76f0309b8e88dec3a601fe25a8",
    "decimals": 18,
    "image": "https://rango.vip/i/yYbZes",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.01775419,
    "supportedSwappers": [
      "OneInchBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XVG",
    "name": "XVG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85614a474dbeed440d5bbdb8ac50b0f22367f997",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006217,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UBI",
    "name": "Universal Basic Income",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd1ad9a21ce722c151a836373babe42c868ce9a4",
    "decimals": 18,
    "image": "https://rango.vip/i/2MNCyw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00036015,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "UBI",
    "name": "Universal Basic Income",
    "isPopular": false,
    "chainId": "10",
    "address": "0xbb586ed34974b15049a876fd5366a4c2d1203115",
    "decimals": 18,
    "image": "https://rango.vip/i/Xxs79D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00036933699160352385,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KNOX",
    "name": "KNOX Dollar",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x0bbf664d46becc28593368c97236faa0fb397595",
    "decimals": 18,
    "image": "https://rango.vip/i/MXCuzE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.016,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$TOAD",
    "name": "Toad",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FViMp5phQH2bX81S7Yyn1yXjj3BRddFBNcMCbTH8FCze",
    "decimals": 4,
    "image": "https://rango.vip/i/utzkyA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.67089e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CANDLE",
    "name": "Candle Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6iFUKWGDksVvmnSYJUGYnsu168xstni8xJkTF7QrpPAu",
    "decimals": 6,
    "image": "https://rango.vip/i/lrhOPT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00120748,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "LIQ",
    "name": "Liquidus",
    "isPopular": false,
    "chainId": "25",
    "address": "0xabd380327fe66724ffda91a87c772fb8d00be488",
    "decimals": 18,
    "image": "https://rango.vip/i/uyFH5s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.01566529,
    "supportedSwappers": [
      "CronaSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LIQ",
    "name": "LIQ Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4wjPQJ6PrkC4dHhYghwJzGBVP78DkBzA2U3kHoFNBuhj",
    "decimals": 6,
    "image": "https://rango.vip/i/FcVMDt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00134251,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LIQ",
    "name": "Liquis",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd82fd4d6d62f89a1e50b1db69ad19932314aa408",
    "decimals": 18,
    "image": "https://rango.vip/i/fkc94c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00455448,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LIQ",
    "name": "Liquidus",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc7981767f644c7f8e483dabdc413e8a371b83079",
    "decimals": 18,
    "image": "https://rango.vip/i/fDDPMF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01566529,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PSYOP",
    "name": "PSYOP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3007083eaa95497cd6b2b809fb97b6a30bdf53d3",
    "decimals": 18,
    "image": "https://rango.vip/i/R2L0cV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004478,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OATH",
    "name": "Oath Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd20523b39faf1d6e9023a4d6085f87b7b0de7926",
    "decimals": 18,
    "image": "https://rango.vip/i/UilLDy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00880424,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHROOM",
    "name": "Shroom",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "xyzR4s6H724bUq6q7MTqWxUnhi8LM5fiKKUq38h8M1P",
    "decimals": 9,
    "image": "https://rango.vip/i/tMXr4u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00056636,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHROOM",
    "name": "shroom.finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed0439eacf4c4965ae4613d77a5c2efe10e5f183",
    "decimals": 18,
    "image": "https://rango.vip/i/18WK33",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01765072,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INUINU",
    "name": "Inu Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc6bdb96e29c38dc43f014eed44de4106a6a8eb5f",
    "decimals": 18,
    "image": "https://rango.vip/i/BM44Np",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.31605e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CREDIT",
    "name": "PROXI DeFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4cb5793bd58bad06bf51fb37717b86b02cbe8a4",
    "decimals": 18,
    "image": "https://rango.vip/i/4dQBTB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00256186,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TATE",
    "name": "TATE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa589d8868607b8d79ee4288ce192796051263b64",
    "decimals": 18,
    "image": "https://rango.vip/i/p0YPY9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.70183e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WATT",
    "name": "WATT token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GjdreVe7iUG4hyESSweGyFzgekWufhEwGJqAaa1hr7pf",
    "decimals": 9,
    "image": "https://rango.vip/i/jeQtwz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00005020566384200083,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WATT",
    "name": "WATTTON",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe67f943af5eb6051ef56f05979cc30b732717fa6",
    "decimals": 4,
    "image": "https://rango.vip/i/33cOn3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000502,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEFEND",
    "name": "BlockDefend AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9511ff502f982643935a39206f98a6c18d4527cf",
    "decimals": 18,
    "image": "https://rango.vip/i/4zlCED",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00534004,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GFARM2",
    "name": "Gains V2",
    "isPopular": false,
    "chainId": "137",
    "address": "0x7075cab6bcca06613e2d071bd918d1a0241379e2",
    "decimals": 18,
    "image": "https://rango.vip/i/Fu9WQF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2840.512284187935,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GFARM2",
    "name": "Gains Farm v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x831091da075665168e01898c6dac004a867f1e1b",
    "decimals": 18,
    "image": "https://rango.vip/i/HwijQF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2840.512284187935,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CRONA",
    "name": "Crona Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xadbd1231fb360047525bedf962581f3eee7b49fe",
    "decimals": 18,
    "image": "https://rango.vip/i/KCXyYH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00070564,
    "supportedSwappers": [
      "CronaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABYFLOKI",
    "name": "BabyFloki",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf35988d795d90711e785b488bb2127692e6f956",
    "decimals": 9,
    "image": "https://rango.vip/i/nl7eBr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020067,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BSX",
    "name": "Basix",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d6f4e7b3f57b238eea545f16f9d6d17b77ec2fb",
    "decimals": 18,
    "image": "https://rango.vip/i/42csdK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0232906,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHEI",
    "name": "SheiShei",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9d09bc374577dac1ab853de412a903408204ea8",
    "decimals": 18,
    "image": "https://rango.vip/i/MxTd3S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.86675e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEGA",
    "name": "MegaWorld $MEGA Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1892f6ff5fbe11c31158f8c6f6f6e33106c5b10e",
    "decimals": 18,
    "image": "https://rango.vip/i/IhCc0m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01244443,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEGA",
    "name": "MEGALODON",
    "isPopular": false,
    "chainId": "1",
    "address": "0x99cffb50aad37d17955253f3a4070556b5127a0b",
    "decimals": 18,
    "image": "https://rango.vip/i/h3a2Vb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00165831,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEGA",
    "name": "Make ETH Great Again",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc06bf3589345a81f0c2845e4db76bdb64bbbbc9d",
    "decimals": 18,
    "image": "https://rango.vip/i/IudIeL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.92,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BSGG",
    "name": "Betswap gg",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x49f519002eeced6902f24c0be72b6d898e4d27fc",
    "decimals": 18,
    "image": "https://rango.vip/i/V0C4GM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00059164,
    "supportedSwappers": [
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BSGG",
    "name": "Betswap.gg",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda16cf041e2780618c49dbae5d734b89a6bac9b3",
    "decimals": 18,
    "image": "https://rango.vip/i/jAOCar",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00059164,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BSGG",
    "name": "Betswap.gg",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69570f3e84f51ea70b7b68055c8d667e77735a25",
    "decimals": 18,
    "image": "https://rango.vip/i/Mfzv6W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0005872495305704092,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "B01",
    "name": "b0rder1ess",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf36f79feb5d97e18c69078d8d13d941cae447a04",
    "decimals": 18,
    "image": "https://rango.vip/i/aWiDHv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.345902,
    "supportedSwappers": [
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SAP",
    "name": "SAP",
    "isPopular": false,
    "chainId": "128",
    "address": "0xfe2f1890d8dc69cf16d611c71fef4a811ca84575",
    "decimals": 18,
    "image": "https://rango.vip/i/G3dRAq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.000004993378455326244,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BNK",
    "name": "Bankera",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7707aada3ce7722ac63b91727daf1999849f6835",
    "decimals": 8,
    "image": "https://rango.vip/i/lddW2s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.071957,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AOC",
    "name": "Alickshundra Occasional-Cortex",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GfkfESc5A4EkxCGWsymNY4ZfkCDdvtB7ajCybLfYL1Qq",
    "decimals": 6,
    "image": "https://rango.vip/i/KUvZ22",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000258,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CPD",
    "name": "Coinspaid",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2",
    "decimals": 18,
    "image": "https://rango.vip/i/TSmUAu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.005172263740297055,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGS",
    "name": "Doggensnout Skeptic",
    "isPopular": false,
    "chainId": "1",
    "address": "0x21e5c85a5b1f38bddde68307af77e38f747cd530",
    "decimals": 9,
    "image": "https://rango.vip/i/Z5qSKD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.774e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ELGATO",
    "name": "el gato",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F47vvwFYuLioQsqEVAjqdY6Yihc8wVRiUcfHGcBR9XUs",
    "decimals": 6,
    "image": "https://rango.vip/i/vMgd2E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004035,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NAOS",
    "name": "NaosToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4a615bb7166210cce20e6642a6f8fb5d4d044496",
    "decimals": 18,
    "image": "https://rango.vip/i/jxwHg8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0085716,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NAOS",
    "name": "NAOS Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x758d08864fb6cce3062667225ca10b8f00496cc2",
    "decimals": 18,
    "image": "https://rango.vip/i/Z7dCKz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0085716,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIT",
    "name": "HitchainCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7995ab36bb307afa6a683c24a25d90dc1ea83566",
    "decimals": 6,
    "image": "https://rango.vip/i/nfQp0h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000208,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIT",
    "name": "HitBTC Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x74b1af114274335598da72f5c6ed7b954a016eed",
    "decimals": 18,
    "image": "https://rango.vip/i/256Pob",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.192274,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EURCV",
    "name": "Societe Generale FORGE EURCV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f7827fdeb7c20b443265fc2f40845b715385ff2",
    "decimals": 18,
    "image": "https://rango.vip/i/MNi4AO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CLUB",
    "name": "CLUB",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GTgMjfKTBF9jVBogMnaiAVnHmvnF2wyGAv3zvtk5CBHq",
    "decimals": 9,
    "image": "https://rango.vip/i/ARx2Dm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.004499120486169433,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FODL",
    "name": "Fodl",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c2e59d098df7b6cbae0848d66de2f8a4889b9c3",
    "decimals": 18,
    "image": "https://rango.vip/i/s5CWnL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00096256,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BOB",
    "name": "BOB",
    "isPopular": false,
    "chainId": "10",
    "address": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "decimals": 18,
    "image": "https://rango.vip/i/QXJdRw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.999883,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BOB",
    "name": "BOB",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "decimals": 18,
    "image": "https://rango.vip/i/f1JlIH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.999883,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BOB",
    "name": "BOB",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "decimals": 18,
    "image": "https://rango.vip/i/hGmGtk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.999883,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOB",
    "name": "BOB",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "decimals": 18,
    "image": "https://rango.vip/i/htAPRB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999883,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOB",
    "name": "BOB",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d8146cf21e8d7cbe46054e01588207b51198729",
    "decimals": 18,
    "image": "https://rango.vip/i/3bmTEm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002099,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BOB",
    "name": "BOB",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "decimals": 18,
    "image": "https://rango.vip/i/nGSzZs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.999883,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIELD",
    "name": "Chatter Shield",
    "isPopular": false,
    "chainId": "1",
    "address": "0x46c0f8259c4e4d50320124e52f3040cb9e4d04c7",
    "decimals": 18,
    "image": "https://rango.vip/i/78Tuwm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00787365,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRDG",
    "name": "TRDGtoken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x92a42db88ed0f02c71d439e55962ca7cab0168b5",
    "decimals": 9,
    "image": "https://rango.vip/i/mhgSjc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.243e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TRDG",
    "name": "Tardigrades.Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x92a42db88ed0f02c71d439e55962ca7cab0168b5",
    "decimals": 9,
    "image": "https://rango.vip/i/Cz70FV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 9.243e-12,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCN",
    "name": "Odyssey",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4092678e4e78230f46a1534c0fbc8fa39780892b",
    "decimals": 18,
    "image": "https://rango.vip/i/FicX3z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004207,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DDD",
    "name": "Scry info",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f5f3cfd7a32700c93f971637407ff17b91c7342",
    "decimals": 18,
    "image": "https://rango.vip/i/ut4tST",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0005525,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DAW",
    "name": "Dawkoins",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7cb67ev3jvBKaAH1tnVM8FL8WfQ96sa2TYByEXajqx8N",
    "decimals": 6,
    "image": "https://rango.vip/i/tR5qs9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004055,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "VISION",
    "name": "Vision Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x034b2090b579228482520c589dbd397c53fc51cc",
    "decimals": 18,
    "image": "https://rango.vip/i/DVisU9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.208609,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VISION",
    "name": "Vision Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf406f7a9046793267bc276908778b29563323996",
    "decimals": 18,
    "image": "https://rango.vip/i/KAUIQm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.208609,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EON",
    "name": "Hyper",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60158131416f5e53d55d73a11be2e203cb26abcc",
    "decimals": 8,
    "image": "https://rango.vip/i/7WZ1kt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012306,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DJT",
    "name": "TrumpCoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HRw8mqK8N3ASKFKJGMJpy4FodwR3GKvCFKPDQNqUNuEP",
    "decimals": 9,
    "image": "https://rango.vip/i/oxajwG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00028058,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "D2T",
    "name": "Dash 2 Trade",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4dd942baa75810a3c1e876e79d5cd35e09c97a76",
    "decimals": 18,
    "image": "https://rango.vip/i/qvsxbn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00236215,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "CMDX",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/ea3e1640f9b1532ab129a571203a0b9f789a7f14bb66e350dcbfa18e1a1931f0",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/CMDX.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00307966,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "COMDEX",
    "symbol": "CMDX",
    "name": null,
    "isPopular": true,
    "chainId": "comdex-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/4cnACm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/COMDEX/icon.svg",
    "usdPrice": 0.0030999080811323354,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNSHETH",
    "name": "unshETH Ether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ae38f7e10a43b5b2fb064b42a2f4514cba909ef",
    "decimals": 18,
    "image": "https://rango.vip/i/eeZlXx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2501.89,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOSHI",
    "name": "Dejitaru Hoshi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5362ca75aa3c0e714bc628296640c43dc5cb9ed6",
    "decimals": 9,
    "image": "https://rango.vip/i/Axzs19",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007408,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOK",
    "name": "BONKLANA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "88tmABf1s3A6jbLD2yLR1tgbGjz68fz3EDrbeSB1JPPc",
    "decimals": 9,
    "image": "https://rango.vip/i/Ij1W0h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00030008,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UNQ",
    "name": "Unique Venture Clubs",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "UNQtEecZ5Zb4gSSVHCAWUQEoNnSVEbWiKCi1v9kdUJJ",
    "decimals": 6,
    "image": "https://rango.vip/i/27f8oL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00017111,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRE",
    "name": "Presearch",
    "isPopular": false,
    "chainId": "1",
    "address": "0xec213f83defb583af3a000b1c0ada660b1902a0f",
    "decimals": 18,
    "image": "https://rango.vip/i/gc2EN8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00864958,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BANK",
    "name": "Bankless DAO",
    "isPopular": false,
    "chainId": "10",
    "address": "0x29faf5905bff9cfcc7cf56a5ed91e0f091f8664b",
    "decimals": 18,
    "image": "https://rango.vip/i/rSffV9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00074469,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BANK",
    "name": "Bankless Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdb7cb471dd0b49b29cab4a1c14d070f27216a0ab",
    "decimals": 18,
    "image": "https://rango.vip/i/4SDqnM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00074469,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BANK",
    "name": "Float Bank",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24a6a37576377f63f194caa5f518a60f45b42921",
    "decimals": 18,
    "image": "https://rango.vip/i/sR2DiY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.525484,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BANK",
    "name": "Bankless Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2d94aa3e47d9d5024503ca8491fce9a2fb4da198",
    "decimals": 18,
    "image": "https://rango.vip/i/S5fIPm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00074469,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BANK",
    "name": "Bank Ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe18ab3568fa19e0ed38bc1d974eddd501e61e12d",
    "decimals": 18,
    "image": "https://rango.vip/i/rXULbZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016328,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BONKFA",
    "name": "Bonk of America",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DoxsC4PpVHiUxCKYeKSkPXVVVSJYzidZZJxW4XCFF2t",
    "decimals": 6,
    "image": "https://rango.vip/i/mQiua4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00019957,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XD",
    "name": "XDoge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x01eeffcd9a10266ed00946121df097eed173b43d",
    "decimals": 18,
    "image": "https://rango.vip/i/zk2qcA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.27714e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INUS",
    "name": "MultiPlanetary Inus",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39207d2e2feef178fbda8083914554c59d9f8c00",
    "decimals": 18,
    "image": "https://rango.vip/i/b1BW6p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.65853e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WARPIE",
    "name": "Warpie",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x10c1b6f768e13c624a4a23337f1a5ba5c9be0e4b",
    "decimals": 18,
    "image": "https://rango.vip/i/tDdYf0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00001235966364802471,
    "supportedSwappers": [
      "SushiBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WMEMO",
    "name": "Wonderful Memories",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b79a28264fc52c7b4cea90558aa0b162f7faf57",
    "decimals": 18,
    "image": "https://rango.vip/i/lQUthH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 205.03,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "XNFT",
    "name": "XNFT",
    "isPopular": false,
    "chainId": "128",
    "address": "0xe5944b50df84001a36c7de0d5cb4da7ab21407d2",
    "decimals": 18,
    "image": "https://rango.vip/i/1OrFql",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00061346,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "MUTE",
    "name": "Mute.io",
    "isPopular": false,
    "chainId": "324",
    "address": "0x0e97c7a0f8b2c9885c8ac9fc6136e829cbc21d42",
    "decimals": 18,
    "image": "https://rango.vip/i/tBATYa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.02759038,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUTE",
    "name": "Mute",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa49d7499271ae71cd8ab9ac515e6694c755d400c",
    "decimals": 18,
    "image": "https://rango.vip/i/1hDRkQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02759038,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OVO",
    "name": "OVO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbfc66d8cce39e668fd5d3c10fd1b1eabb82c27b7",
    "decimals": 18,
    "image": "https://rango.vip/i/33DRvS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00131491,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WALLY",
    "name": "Wally The Whale",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fo6tfAkXj74X6j8hati8SxtwZHHcdGeqXVUPLP9Abvqu",
    "decimals": 6,
    "image": "https://rango.vip/i/HWUZvV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.025e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AKITA",
    "name": "Akita Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3301ee63fb29f863f2333bd4466acb46cd8323e6",
    "decimals": 18,
    "image": "https://rango.vip/i/kF9TWp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.4317e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UCM",
    "name": "UCROWDME",
    "isPopular": false,
    "chainId": "1",
    "address": "0x722f97a435278b7383a1e3c47f41773bebf3232c",
    "decimals": 18,
    "image": "https://rango.vip/i/DbFrzO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024666,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOC",
    "name": "Book of cartoons",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "47psVap1QaXYYEPhFn7pZuvR8m9GQaX8wDodZuY3fWL7",
    "decimals": 6,
    "image": "https://rango.vip/i/twv892",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0013050461070234789,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NAS",
    "name": "Nebulas",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d65d971895edc438f465c17db6992698a52318d",
    "decimals": 18,
    "image": "https://rango.vip/i/2J9ZD0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00524691,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZIPT",
    "name": "Zippie",
    "isPopular": false,
    "chainId": "1",
    "address": "0xedd7c94fd7b4971b916d15067bc454b9e1bad980",
    "decimals": 18,
    "image": "https://rango.vip/i/UBZyRK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.001022219703801144,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ZIPT",
    "name": "Zippie",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x0f61b24272af65eacf6adfe507028957698e032f",
    "decimals": 18,
    "image": "https://rango.vip/i/59EUw8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.001022219703801144,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JNGL",
    "name": "Jungle Labz",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155",
    "decimals": 18,
    "image": "https://rango.vip/i/9Iqyab",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.057748,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GPT",
    "name": "SolGPT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Eyhi3qZCW1hNgsLtv1geaeDgZJmRFCpEtbjABWfGcGx5",
    "decimals": 6,
    "image": "https://rango.vip/i/ttP0XU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.07894634025956786,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GPT",
    "name": "GPT Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcdb4a8742ed7d0259b51e3454c46c9d6c48d5e88",
    "decimals": 18,
    "image": "https://rango.vip/i/dlu4iL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00612956,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "WANNA",
    "name": "WannaSwap",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x7faa64faf54750a2e3ee621166635feaf406ab22",
    "decimals": 18,
    "image": "https://rango.vip/i/7WbNfL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.00050428,
    "supportedSwappers": [
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WANNA",
    "name": "Wanna Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa76cec201e939660f8afb1fb8d5865d069df0750",
    "decimals": 18,
    "image": "https://rango.vip/i/rGWRaM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00959894,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GEC",
    "name": "Gecko Inu: GEC Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xe8385cecb013561b69beb63ff59f4d10734881f3",
    "decimals": 18,
    "image": "https://rango.vip/i/fML5Y8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.8086e-8,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEC",
    "name": "GEC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3001f57f8308b189eb412a64322aad5ef9951290",
    "decimals": 18,
    "image": "https://rango.vip/i/cbDIzR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03011097,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEC",
    "name": "GECOIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe304283c3e60cefaf7ea514007cf4e8fdc3d869d",
    "decimals": 18,
    "image": "https://rango.vip/i/futBxa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.068336,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMRT",
    "name": "SmartMoney",
    "isPopular": false,
    "chainId": "1",
    "address": "0xddcc69879e1d2376ce799051afa98c689f234cca",
    "decimals": 18,
    "image": "https://rango.vip/i/AiAw2e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00019998,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "4TOKEN",
    "name": "Ignore Fud",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8db4beaccd1698892821a9a0dc367792c0cb9940",
    "decimals": 18,
    "image": "https://rango.vip/i/FFw8is",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000113,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CART",
    "name": "CryptoArt Ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5c8c8d560048f34e5f7f8ad71f2f81a89dbd273e",
    "decimals": 18,
    "image": "https://rango.vip/i/FdRKPv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00751581,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CART",
    "name": "CryptoArt.ai",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5c8c8d560048f34e5f7f8ad71f2f81a89dbd273e",
    "decimals": 18,
    "image": "https://rango.vip/i/UsSRzY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.007485862960133636,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SASHIMI",
    "name": "SashimiToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc28e27870558cf22add83540d2126da2e4b464c2",
    "decimals": 18,
    "image": "https://rango.vip/i/FgNxIz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00372935,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "SHIBK",
    "name": "SHIBK",
    "isPopular": false,
    "chainId": "66",
    "address": "0xaa27badaa3c9ec9081b8f6c9cdd2bf375f143780",
    "decimals": 18,
    "image": "https://rango.vip/i/C7IK22",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.000014688440605266257,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOWA",
    "name": "Sowa AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72fca22c6070b4cf68abdb719fa484d9ef10a73b",
    "decimals": 9,
    "image": "https://rango.vip/i/WWsIRR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00032125,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DONALD",
    "name": "DONALD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4Yz5zByTwnVe46AXD6hsrccbq4TKLyih2xRqPyLBDT1P",
    "decimals": 6,
    "image": "https://rango.vip/i/McJ2P6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008972402446056829,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BURRRD",
    "name": "BURRRD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F8qtcT3qnwQ24CHksuRrSELtm5k9ob8j64xAzj3JjsMs",
    "decimals": 4,
    "image": "https://rango.vip/i/njd6Lt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 8.50424e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CRX",
    "name": "CryptEx Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x97a30c692ece9c317235d48287d23d358170fc40",
    "decimals": 18,
    "image": "https://rango.vip/i/qJCiic",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.108224454842148,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOBO",
    "name": "Bobo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5888641e3e6cbea6d84ba81edb217bd691d3be38",
    "decimals": 9,
    "image": "https://rango.vip/i/K5qLMN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.48763e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOBO",
    "name": "BOBO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb90b2a35c65dbc466b04240097ca756ad2005295",
    "decimals": 18,
    "image": "https://rango.vip/i/dMRX92",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.61515e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NUSA",
    "name": "Nusa Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe11f1d5eee6be945bee3fa20dbf46febbc9f4a19",
    "decimals": 18,
    "image": "https://rango.vip/i/MYrOcz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 8.31,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRAINERS",
    "name": "BRAINERS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5044d567f7b30891639d982a05726a6bfe8bae6a",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02499416,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MILO",
    "name": "Milo Inu",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd9de2b1973e57dc9dba90c35d6cd940ae4a3cbe1",
    "decimals": 9,
    "image": "https://rango.vip/i/C8Lybk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.4713e-8,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LSP",
    "name": "Lambo Space Princess",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BAy5FmGzFwcVcZq1yXaDvF1mEAChF3MPtBLrUMBsnLN9",
    "decimals": 6,
    "image": "https://rango.vip/i/63YmrF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.008928254653665122,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PSY",
    "name": "PsyOptions",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PsyFiqqjiv41G7o5SMRzDJCu4psptThNR2GtfeGHfSq",
    "decimals": 6,
    "image": "https://rango.vip/i/vtM7Ss",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00068993,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EEUR",
    "name": "ARYZE eEUR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x735fa792e731a2e8f83f32eb539841b7b72e6d8f",
    "decimals": 18,
    "image": "https://rango.vip/i/03lSiL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.094,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HANU",
    "name": "Hanu Yokia",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72e5390edb7727e3d4e3436451dadaff675dbcc0",
    "decimals": 12,
    "image": "https://rango.vip/i/bW1YU2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.54022e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BERF",
    "name": "BERF",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfc5462143a3178cf044e97c491f6bcb5e38f173e",
    "decimals": 18,
    "image": "https://rango.vip/i/CRH5Hf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00167726,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "REAL",
    "name": "Realy Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AD27ov5fVU2XzwsbvnFvb1JpCBaCB5dRXrczV9CqSVGb",
    "decimals": 9,
    "image": "https://rango.vip/i/5J50Am",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02449722,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "X7R",
    "name": "X7R",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70008f18fc58928dce982b0a69c2c21ff80dca54",
    "decimals": 18,
    "image": "https://rango.vip/i/MLipNI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00759168,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WIF",
    "name": "WIF on ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x886c869cdc619214138c87f1db0ada522b16dfa3",
    "decimals": 18,
    "image": "https://rango.vip/i/C5Rdkg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009181,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FSCC",
    "name": "FISCO Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0789dbae94fb18e5789b8e4489bcb7a1adb58622",
    "decimals": 8,
    "image": "https://rango.vip/i/qx1dou",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.140248,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VADER",
    "name": "Vader",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2602278ee1882889b946eb11dc0e810075650983",
    "decimals": 18,
    "image": "https://rango.vip/i/mXv4xR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000779,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WACO",
    "name": "Waste Digital Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x910c4da718caf4ee38ce5c2490cddaeca689204e",
    "decimals": 18,
    "image": "https://rango.vip/i/Mpmbe8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00019856,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HCT",
    "name": "Hurricane Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x45c13620b55c35a5f539d26e88247011eb10fdbd",
    "decimals": 18,
    "image": "https://rango.vip/i/gAfhd9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00006176,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SG",
    "name": "SocialGood",
    "isPopular": false,
    "chainId": "1",
    "address": "0xddf7fd345d54ff4b40079579d4c4670415dbfd0a",
    "decimals": 18,
    "image": "https://rango.vip/i/NxM3yi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.18573,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "JELLI",
    "name": "Jelli",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xa1b9d812926a529d8b002e69fcd070c8275ec73c",
    "decimals": 9,
    "image": "https://rango.vip/i/COv8hl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00018265,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LOA",
    "name": "League Of Ancients",
    "isPopular": false,
    "chainId": "56",
    "address": "0x94b69263fca20119ae817b6f783fc0f13b02ad50",
    "decimals": 18,
    "image": "https://rango.vip/i/Fwldyc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00149442,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YES",
    "name": "Yes Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "32h846XXTSWGUaaKHMC5b2e39n1nwJD6UtDBppX5p4E9",
    "decimals": 9,
    "image": "https://rango.vip/i/an5MaN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.5368861065066466,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YES",
    "name": "YES",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc10cd3895f2c66d6639ec33ae6360d6cfca7d6d",
    "decimals": 18,
    "image": "https://rango.vip/i/bGGiNo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.17,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "YES",
    "name": "YES Money v2",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x1a49351bdb4be48c0009b661765d01ed58e8c2d8",
    "decimals": 18,
    "image": "https://rango.vip/i/gHaIkI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 3.35,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRYPTO",
    "name": "BetbuInu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x586a7cfe21e55ec0e24f0bfb118f77fe4ca87bab",
    "decimals": 18,
    "image": "https://rango.vip/i/2DqC69",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00299061,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XVC",
    "name": "Xave Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4fabf135bcf8111671870d4399af739683198f96",
    "decimals": 18,
    "image": "https://rango.vip/i/yieLGU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002602,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "GLINT",
    "name": "Beamswap Token",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xcd3b51d98478d53f4515a306be565c6eebef1d58",
    "decimals": 18,
    "image": "https://rango.vip/i/5zO3Q7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.0002466,
    "supportedSwappers": [
      "BeamSwap"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "AKT",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/1480b8fd20ad5fcae81ea87584d269547dd4d436843c1d20f15e00eb64743ef4",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/AKT.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 2.219931937285376,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "AKT",
    "name": "AKT",
    "isPopular": false,
    "chainId": "25",
    "address": "0x39a65a74dc5a778ff93d1765ea51f57bc49c81b3",
    "decimals": 6,
    "image": "https://rango.vip/i/magMo9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 2.212330598287836,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "AKASH",
    "symbol": "AKT",
    "name": null,
    "isPopular": true,
    "chainId": "akashnet-2",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/VFehqW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AKASH/icon.svg",
    "usdPrice": 2.222089706525579,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GONE",
    "name": "GONE",
    "isPopular": false,
    "chainId": "137",
    "address": "0x162539172b53e9a93b7d98fb6c41682de558a320",
    "decimals": 18,
    "image": "https://rango.vip/i/BrDr5j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000447,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHANG",
    "name": "Shanghai Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee772cec929d8430b4fa7a01cd7fbd159a68aa83",
    "decimals": 18,
    "image": "https://rango.vip/i/FADIL9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.6536e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABC",
    "name": "Alpha Bot Calls",
    "isPopular": false,
    "chainId": "1",
    "address": "0x52b552c4a698c9b1de4c7fe0dd4462af141f7c3e",
    "decimals": 18,
    "image": "https://rango.vip/i/UtcjFg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02833973,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XI",
    "name": "Xi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x295b42684f90c77da7ea46336001010f2791ec8c",
    "decimals": 18,
    "image": "https://rango.vip/i/w8F1nq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00270839,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ARKEN",
    "name": "Arken token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1d4268a58ee7ec2cc2af5d70a2fd2b3a896527a2",
    "decimals": 18,
    "image": "https://rango.vip/i/a9NgsG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.000579921905288176,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUILD",
    "name": "BuildAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73454acfddb7a36a3cd8eb171fbea86c6a55e550",
    "decimals": 9,
    "image": "https://rango.vip/i/vrQlzL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.075966,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUILD",
    "name": "Build Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x57b59f981730c6257df57cf6f0d98283749a9eeb",
    "decimals": 18,
    "image": "https://rango.vip/i/qCzqgu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000677,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RPG",
    "name": "RangersProtocolGas",
    "isPopular": false,
    "chainId": "56",
    "address": "0x61dc650c10ec3c758d251cd2d1ab45af1a43e941",
    "decimals": 18,
    "image": "https://rango.vip/i/nnkh26",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.45,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RPG",
    "name": "Rangers Protocol Gas",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e5c8c387c5eba2ecbc137ad012aed5fe729e251",
    "decimals": 18,
    "image": "https://rango.vip/i/FIh8IR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.3744882639663254,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RPG",
    "name": "RangersProtocolGas",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f16d41f9b3db03b462bdd6c92245ee708d1c103",
    "decimals": 18,
    "image": "https://rango.vip/i/M8gScE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.45,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RPG",
    "name": "Rangers Protocol Gas",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc2098a8938119a52b1f7661893c0153a6cb116d5",
    "decimals": 18,
    "image": "https://rango.vip/i/G7Tk7D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.3744882639663254,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CND",
    "name": "Cindicator Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd4c435f5b09f855c3317c8524cb1f586e42795fa",
    "decimals": 18,
    "image": "https://rango.vip/i/x0aR2j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040874,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CND",
    "name": "Coinhound",
    "isPopular": false,
    "chainId": "1",
    "address": "0xec505c81d6a7567b5bde804870b1038832fe6da1",
    "decimals": 18,
    "image": "https://rango.vip/i/taC5lu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003913,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POTATO",
    "name": "POTATO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB",
    "decimals": 3,
    "image": "https://rango.vip/i/C1Iaau",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.4692e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "WHALE",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/edd6f0d66bcd49c1084fb2c35353b4acd7b9191117ce63671b61320548f7c89d",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/WHALE.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00369412,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WHALE",
    "name": "WHALE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9355372396e3f6daf13359b7b607a3374cc638e0",
    "decimals": 4,
    "image": "https://rango.vip/i/gbK9l8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.399322,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ITP",
    "name": "Interport Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2b1d36f5b61addaf7da7ebbd11b35fd8cfb0de31",
    "decimals": 18,
    "image": "https://rango.vip/i/HmRgRN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.066398,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUZUME",
    "name": "Shita kiri Suzume",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b452278223d3954f4ac050949d7998e373e7e43",
    "decimals": 18,
    "image": "https://rango.vip/i/foqGrg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.30789e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BYAT",
    "name": "Byat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BYATmZ7ry2pewxW3213sczJYB7ZJzPr921uvcRcJYYZQ",
    "decimals": 9,
    "image": "https://rango.vip/i/3VrHDK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001111,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STRNGR",
    "name": "Stronger",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc0327d50e6c73db2f8117760592c8bbf1cdcf38",
    "decimals": 18,
    "image": "https://rango.vip/i/0JaAzd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.596692,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TXAG",
    "name": "tSILVER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34abce75d2f8f33940c721dca0f562617787bff3",
    "decimals": 18,
    "image": "https://rango.vip/i/sQJnHK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.766153,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OGCINU",
    "name": "The OG Cheems Inu",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5doZSgpsKVJk9u58hsmDsq8N6oNtELvsycoFJ42P327p",
    "decimals": 6,
    "image": "https://rango.vip/i/0eodiR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.999e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RICE",
    "name": "DAOSquare",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd9908b0cdd50386f92efcc8e1d71766c2782df0",
    "decimals": 18,
    "image": "https://rango.vip/i/DdwjKk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.097574,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MUST",
    "name": "Must",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f",
    "decimals": 18,
    "image": "https://rango.vip/i/ggh1mr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 3.1,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUST",
    "name": "Must",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f",
    "decimals": 18,
    "image": "https://rango.vip/i/3DCj08",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SCRAT",
    "name": "Scrat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3GEznP41VaAGv9yRdBiuYdA8zkffNc8b7DzNJgFbH4Kh",
    "decimals": 6,
    "image": "https://rango.vip/i/ZG8Ugk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0013005,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KLEE",
    "name": "KleeKai",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa67e9f021b9d208f7e3365b2a155e3c55b27de71",
    "decimals": 9,
    "image": "https://rango.vip/i/p6cApg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.82e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WEX",
    "name": "WaultSwap",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90",
    "decimals": 18,
    "image": "https://rango.vip/i/PLkyO1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00001014,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZEUM",
    "name": "Colizeum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x436da116249044e8b4464f0cf21dd93311d88190",
    "decimals": 18,
    "image": "https://rango.vip/i/JifhD6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00076822,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLACK",
    "name": "BlackHole Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd714d91a169127e11d8fab3665d72e8b7ef9dbe2",
    "decimals": 18,
    "image": "https://rango.vip/i/MYJ2bL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00109945,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BLACK",
    "name": "BLACK",
    "isPopular": false,
    "chainId": "128",
    "address": "0xd714d91a169127e11d8fab3665d72e8b7ef9dbe2",
    "decimals": 18,
    "image": "https://rango.vip/i/mrmuFS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00012817179853650228,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "LIKE",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/9989ad6cca39d1131523db0617b50f6442081162294b4795e26746292467b525",
    "decimals": 9,
    "image": "https://rango.vip/tokens/ALL/LIKE.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.000736,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LIKE",
    "name": "Only1 (LIKE)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3bRTivrVsitbmCTGtqwp7hxXPsybkjn4XLNtPsHqa3zR",
    "decimals": 9,
    "image": "https://rango.vip/i/ASYDLb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01988579,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MAI",
    "name": "Mai Stablecoin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x5c49b268c9841aff1cc3b0a418ff5c3442ee3f3b",
    "decimals": 18,
    "image": "https://rango.vip/i/z94EMk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.992631,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "stargate",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAI",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x8d6cebd76f18e1558d4db88138e2defb3909fad6",
    "decimals": 18,
    "image": "https://rango.vip/i/ketwq3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.004,
    "supportedSwappers": [
      "stargate",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MAI",
    "name": null,
    "isPopular": false,
    "chainId": "56",
    "address": "0x3f56e0c36d275367b8c502090edf38289b3dea0d",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/MAI.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4.27,
    "supportedSwappers": [
      "OneInchBsc",
      "stargate"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MAI",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3f56e0c36d275367b8c502090edf38289b3dea0d",
    "decimals": 18,
    "image": "https://rango.vip/i/DqD8I0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.453595,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "stargate"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MAI",
    "name": null,
    "isPopular": false,
    "chainId": "10",
    "address": "0xdfa46478f9e5ea86d57387849598dbfb2e964b02",
    "decimals": 18,
    "image": "https://rango.vip/i/yZnDJq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.493808,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "stargate"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "MAI",
    "name": "Mai Stablecoin",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xfb2019dfd635a03cfff624d210aee6af2b00fc2c",
    "decimals": 18,
    "image": "https://rango.vip/i/JgVHER",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.002454305273327947,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "MAI",
    "name": "Mai Stablecoin",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xdfa46478f9e5ea86d57387849598dbfb2e964b02",
    "decimals": 18,
    "image": "https://rango.vip/i/rcaHva",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.999262,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MAI",
    "name": "MAI Stablecoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9mWRABuz2x6koTPCWiCPM49WUbcrNqGTHBV9T9k7y1o7",
    "decimals": 9,
    "image": "https://rango.vip/i/XgLGmS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.997604,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAI",
    "name": "Micro AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24d73bca2bd9c3a61e99dfc7cb86d3c379ebded7",
    "decimals": 18,
    "image": "https://rango.vip/i/fye4d5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.537048,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAI",
    "name": "Mine AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf4aaa9428a881a5c054d0ed041f5749a336c9ab5",
    "decimals": 9,
    "image": "https://rango.vip/i/7sLLwi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024303,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PRT",
    "name": "PRT (Parrot Protocol)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44",
    "decimals": 6,
    "image": "https://rango.vip/i/LmfeYq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00009854,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRT",
    "name": "Portion Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d0f5149c502faf215c89ab306ec3e50b15e2892",
    "decimals": 18,
    "image": "https://rango.vip/i/Y2h88j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017475,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOCHICAT",
    "name": "MOCHICAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EVDQN4P1YTTD1Y3eLBDBoALWnR4aMBC9y2xUYCrbGbuy",
    "decimals": 9,
    "image": "https://rango.vip/i/m9gX8o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00009405,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAMBOO",
    "name": "Bamboo DeFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf56842af3b56fd72d17cb103f92d027bba912e89",
    "decimals": 18,
    "image": "https://rango.vip/i/67sXTf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0027714,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MAGAIBA",
    "name": "MAGAIBA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A6rSPi9JmJgVkW6BatsA6MjFYLseizPM2Fnt92coFjf4",
    "decimals": 8,
    "image": "https://rango.vip/i/6dBOAy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00012395,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THEOS",
    "name": "Theos",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e10f61749c4952c320412a6b26901605ff6da1d",
    "decimals": 18,
    "image": "https://rango.vip/i/emhBcN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007196,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASG",
    "name": "Nekoverse  City of Greed Anima Spirit G",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb533687ef77459093368c43e95f8df1c2b5a1f7a",
    "decimals": 18,
    "image": "https://rango.vip/i/X7vCD2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00168422,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SIMP",
    "name": "SO COL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41c21693e60fc1a5dbb7c50e54e7a6016aa44c99",
    "decimals": 18,
    "image": "https://rango.vip/i/iSRop9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040405,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KGC",
    "name": "Krypton Galaxy Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa8262eb913fccea4c3f77fc95b8b4043b384cfbb",
    "decimals": 18,
    "image": "https://rango.vip/i/uIXwth",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001546,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RPEPE",
    "name": "RoaringPepe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8jnGePqBBq1KQfPvqA7zAZySiFTyy8tv2zA8u6nHhsdo",
    "decimals": 6,
    "image": "https://rango.vip/i/ZD1wmV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006288977181441585,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "PEFI",
    "name": "PenguinToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xe896cdeaac9615145c0ca09c8cd5c25bced6384c",
    "decimals": 18,
    "image": "https://rango.vip/i/VAmsMr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.006621368205824111,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PEFI",
    "name": "Plant Empires Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa83bfcf9e252adf1f39937984a4e113eda6e445b",
    "decimals": 18,
    "image": "https://rango.vip/i/yUiehM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00654755572085163,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KIF",
    "name": "KittenFinance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x177ba0cac51bfc7ea24bad39d81dcefd59d74faa",
    "decimals": 18,
    "image": "https://rango.vip/i/M5ywDA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 10.07,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TSA",
    "name": "TensorScan AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf02c2dc9b3cb7f1ba21ccd82dff4ebc92da8996f",
    "decimals": 18,
    "image": "https://rango.vip/i/9quoUy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.079602,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DPAY",
    "name": "Devour",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Gnca3UkjR4a1FFNZuGfEELmbaHkL6GteSC2swpdWRmf7",
    "decimals": 8,
    "image": "https://rango.vip/i/xrmYU7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001779,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DPAY",
    "name": "Devour",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5a733681bbe6cd8c764bb8078ef8e13a576dd78",
    "decimals": 18,
    "image": "https://rango.vip/i/TS46rk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001779,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GHUB",
    "name": "The GameHub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5fd82ad8a72252a7a449aded99efec7dd8efa690",
    "decimals": 9,
    "image": "https://rango.vip/i/uNP7qn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00271771,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EHASH",
    "name": "EHash",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2942e3b38e33123965bfbc21e802be943a76bbc6",
    "decimals": 18,
    "image": "https://rango.vip/i/e7iyjk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00983564,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KAKI",
    "name": "KAKI",
    "isPopular": false,
    "chainId": "56",
    "address": "0x42414624c55a9cba80789f47c8f9828a7974e40f",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.6456e-8,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIAO",
    "name": "Biaocoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00282fd551d03dc033256c4bf119532e8c735d8a",
    "decimals": 2,
    "image": "https://rango.vip/i/MzGfnO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.55925e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIAO",
    "name": "Biaoqing",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9fd9278f04f01c6a39a9d1c1cd79f7782c6ade08",
    "decimals": 9,
    "image": "https://rango.vip/i/gsKm6s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0010648,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DCAU",
    "name": "Dragon Crypto Aurum",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x100cc3a819dd3e8573fd2e46d1e66ee866068f30",
    "decimals": 18,
    "image": "https://rango.vip/i/0r5QEw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.53,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JOKER",
    "name": "The Joker Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x113c65707c530502fef959308197353f6df97867",
    "decimals": 18,
    "image": "https://rango.vip/i/yAGG6L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000139,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JOKER",
    "name": "Joker",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0797ec1d0bb0bec541a82c5262c3b0f93f68bfe",
    "decimals": 15,
    "image": "https://rango.vip/i/9JPmYY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.402e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FISH",
    "name": "Fish",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3a3df212b7aa91aa0402b9035b098891d276572b",
    "decimals": 18,
    "image": "https://rango.vip/i/y7TL8P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.167992,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORO",
    "name": "ORO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3eb2622190c57429aac3901808994443b64b466",
    "decimals": 18,
    "image": "https://rango.vip/i/pmdXFj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00392984,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DUET",
    "name": "Duet Governance Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x95ee03e1e2c5c4877f9a298f1c0d6c98698fab7b",
    "decimals": 18,
    "image": "https://rango.vip/i/Tcuq0C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00035948,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HON",
    "name": "Soul Society",
    "isPopular": false,
    "chainId": "1",
    "address": "0xebdb54e76bfec9ab4e06ccf6e484e4126f81befc",
    "decimals": 18,
    "image": "https://rango.vip/i/9XxTuN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HON",
    "name": "Hon Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xed2b42d3c9c6e97e11755bb37df29b6375ede3eb",
    "decimals": 18,
    "image": "https://rango.vip/i/g7bSrC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00784239,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KNK",
    "name": "Kineko",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "kNkT1RDnexWqYP3EYGyWv5ZtazB8CfgGAfJtv9AQ3kz",
    "decimals": 9,
    "image": "https://rango.vip/i/yMdzUY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03105627,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GOL",
    "name": "GOLAZO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9sjyR4GrozeV8a9xM3ykKPGPXJYASy9AuufzefCyaCnP",
    "decimals": 9,
    "image": "https://rango.vip/i/7YQFTT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00007978,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOL",
    "name": "GogolCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x083d41d6dd21ee938f0c055ca4fb12268df0efac",
    "decimals": 4,
    "image": "https://rango.vip/i/EJvqb2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00155097,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESS",
    "name": "Empty Set Share",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24ae124c4cc33d6791f8e8b63520ed7107ac8b3e",
    "decimals": 18,
    "image": "https://rango.vip/i/jGOSb9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001774248145802365,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ESS",
    "name": "Empty Set Share",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xce32aa8d60807182c0003ef9cc1976fa10e5d312",
    "decimals": 18,
    "image": "https://rango.vip/i/jGOSb9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0001774248145802365,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESS",
    "name": "ESSENTIA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc05987bd2be489accf0f509e44b0145d68240f7",
    "decimals": 18,
    "image": "https://rango.vip/i/4BU6O0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017726,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESS",
    "name": "Earth 2 Essence",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c0687215aca7f5e2792d956e170325e92a02aca",
    "decimals": 18,
    "image": "https://rango.vip/i/eKFqKG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.050705,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALPA",
    "name": "AlpaToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ca4408137eb639570f8e647d9bd7b7e8717514a",
    "decimals": 18,
    "image": "https://rango.vip/i/6hd6OM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00485479,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ALPA",
    "name": "AlpaToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc5e6689c9c8b02be7c49912ef19e79cf24977f03",
    "decimals": 18,
    "image": "https://rango.vip/i/nPIFU6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00485479,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEST",
    "name": "Bitpanda Ecosystem",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1b073382e63411e3bcffe90ac1b9a43fefa1ec6f",
    "decimals": 8,
    "image": "https://rango.vip/i/HzStmt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.482749,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKM",
    "name": "Skrumble Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x048fe49be32adfc9ed68c37d32b5ec9df17b3603",
    "decimals": 18,
    "image": "https://rango.vip/i/9Akxz8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006874,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "BIFI",
    "name": "Beefy.finance",
    "isPopular": false,
    "chainId": "25",
    "address": "0xe6801928061cdbe32ac5ad0634427e140efd05f9",
    "decimals": 18,
    "image": "https://rango.vip/i/MVTYtI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 10.31898277005592,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "BIFI",
    "name": "Beefy.Finance",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x173fd7434b8b50df08e3298f173487ebdb35fd14",
    "decimals": 18,
    "image": "https://rango.vip/i/T4rj9h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 281.4972702832924,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BIFI",
    "name": "Beefy Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x99c409e5f62e4bd2ac142f17cafb6810b8f0baae",
    "decimals": 18,
    "image": "https://rango.vip/i/NddRQZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 281.4972702832924,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BIFI",
    "name": "Beefy.finance",
    "isPopular": false,
    "chainId": "137",
    "address": "0xfbdd194376de19a88118e84e279b977f165d01b8",
    "decimals": 18,
    "image": "https://rango.vip/i/VyMw7K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 281.4972702832924,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIFI",
    "name": "Beefy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb1f1ee126e9c96231cc3d3fad7c08b4cf873b1f1",
    "decimals": 18,
    "image": "https://rango.vip/i/2ytUH2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 281.71,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIFI",
    "name": "BiFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2791bfd60d232150bff86b39b7146c0eaaa2ba81",
    "decimals": 18,
    "image": "https://rango.vip/i/Xrl9Td",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00188472,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BIFI",
    "name": "Beefy.finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0xca3f508b8e4dd382ee878a314789373d80a5190a",
    "decimals": 18,
    "image": "https://rango.vip/i/Lg1FFa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 281.4972702832924,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BIFI",
    "name": "BIFI",
    "isPopular": false,
    "chainId": "10",
    "address": "0x4e720dd3ac5cfe1e1fbde4935f386bb1c66f4642",
    "decimals": 18,
    "image": "https://rango.vip/i/EuFqit",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 281.4972702832924,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TROLL",
    "name": "Troll",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf8ebf4849f1fa4faf0dff2106a173d3a6cb2eb3a",
    "decimals": 18,
    "image": "https://rango.vip/i/imRo86",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.029e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BRATT",
    "name": "Son of Brett",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xf395680803b269b62702554723e05b373171b07b",
    "decimals": 18,
    "image": "https://rango.vip/i/opQZC3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0092663,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAVADA",
    "name": "Cavada",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9377e3c3180dddfcda4e4217ed21f2f7c3b235a0",
    "decimals": 6,
    "image": "https://rango.vip/i/RkBQet",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000039,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "CATS",
    "name": "Catoshi",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x402fd175049e95cef2cc9ca1fecdb6d9736e690d",
    "decimals": 18,
    "image": "https://rango.vip/i/I5pLGI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 6.999080075984954e-8,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CATS",
    "name": "Lolcat",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xbc72c1ad220315fa849ff237cf5dd0ad0cf75c73",
    "decimals": 9,
    "image": "https://rango.vip/i/0jHga5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 6.7279e-8,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CATS",
    "name": "CatCoin Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd4b92b1700615afae333b9d16d28eb55e8e689b8",
    "decimals": 9,
    "image": "https://rango.vip/i/oGlA6A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.959e-15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LWC",
    "name": "Linework Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa6a1cc527d48585538b137e6abc14b2a55489d11",
    "decimals": 8,
    "image": "https://rango.vip/i/l4YuO8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00413339,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WEBAI",
    "name": "Website AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc73abe8d7a0da644743fe2ad24f4e16bb7ed43f8",
    "decimals": 9,
    "image": "https://rango.vip/i/TUrk1F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.057954,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "VEE",
    "name": "Vee",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x3709e8615e02c15b096f8a9b460ccb8ca8194e86",
    "decimals": 18,
    "image": "https://rango.vip/i/IcFytE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00004849,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VEE",
    "name": "BLOCKv Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x340d2bde5eb28c1eed91b2f790723e3b160613b7",
    "decimals": 18,
    "image": "https://rango.vip/i/yPtV03",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00589018,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RUBY",
    "name": "Ruby Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb30240d48c05a4b950c470e2d6aefc9117a50624",
    "decimals": 18,
    "image": "https://rango.vip/i/Re6Qfd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0011223,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZMT",
    "name": "Zipmex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa602de53347579f86b996d2add74bb6f79462b2",
    "decimals": 18,
    "image": "https://rango.vip/i/MyDatZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00726112,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTO",
    "name": "Chief Troll Officer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x45e412e1878080815d6d51d47b83d17869433459",
    "decimals": 18,
    "image": "https://rango.vip/i/OSYPNr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017935,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTO",
    "name": "Chief Troll Officer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40c3b81fb887016c0ad02436309c2b265d069a05",
    "decimals": 18,
    "image": "https://rango.vip/i/j7JNzZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.1143e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TIM",
    "name": "TIM",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DWjVPqEX4fPFQ47Xb7EegpGhgWzRviYfPoEWPnxz2CRd",
    "decimals": 9,
    "image": "https://rango.vip/i/5dTdLR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006282,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHARE",
    "name": "SHARE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E6Eg7Esj5tfSwkbDGdrzhrotqptv7ghJNarLZ9rbHDSG",
    "decimals": 6,
    "image": "https://rango.vip/i/F0hPYy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002869,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHARE",
    "name": "Seigniorage Shares",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d",
    "decimals": 9,
    "image": "https://rango.vip/i/jkOon8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00398595,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WMX",
    "name": "Wombex Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa75d9ca2a0a1d547409d82e1b06618ec284a2ced",
    "decimals": 18,
    "image": "https://rango.vip/i/jBjfSF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00026762,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BETS",
    "name": "BetSwirl",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x94025780a1ab58868d9b2dbbb775f44b32e8e6e5",
    "decimals": 18,
    "image": "https://rango.vip/i/o4Fiyi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0003066,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BETS",
    "name": "All Street Bets",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x42069de48741db40aef864f8764432bbccbd0b69",
    "decimals": 18,
    "image": "https://rango.vip/i/TWQaK5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00012074,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BETS",
    "name": "BetSwirl v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x94025780a1ab58868d9b2dbbb775f44b32e8e6e5",
    "decimals": 18,
    "image": "https://rango.vip/i/o4Fiyi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0003066,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BETS",
    "name": "BetSwirl v2",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x94025780a1ab58868d9b2dbbb775f44b32e8e6e5",
    "decimals": 18,
    "image": "https://rango.vip/i/j2c8vt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0003066,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKT",
    "name": "Sukhavati Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x012e0e6342308b247f36ee500ecb14dc77a7a8c1",
    "decimals": 8,
    "image": "https://rango.vip/i/bP3QsS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0009137,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XENO",
    "name": "Xeno",
    "isPopular": false,
    "chainId": "1",
    "address": "0x451fd37983d494bce294295f78a426832376b7df",
    "decimals": 9,
    "image": "https://rango.vip/i/FrAt0q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000801,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CCO",
    "name": "Ccore",
    "isPopular": false,
    "chainId": "1",
    "address": "0x679badc551626e01b23ceecefbc9b877ea18fc46",
    "decimals": 18,
    "image": "https://rango.vip/i/ZEun0J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01064203,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RED",
    "name": "Red Community Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x76960dccd5a1fe799f7c29be9f19ceb4627aeb2f",
    "decimals": 18,
    "image": "https://rango.vip/i/q8QVzE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00091019,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RED",
    "name": "RED TOKEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc744df3419a8c9bd4d6b9852a503eb1c5308a326",
    "decimals": 18,
    "image": "https://rango.vip/i/vEclbd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000311,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "THOL",
    "name": "Tholana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EKCW975DWdt1roK1NVQDf4QGfaGTcQPU5tFD1DMcMe9Q",
    "decimals": 7,
    "image": "https://rango.vip/i/2h5UUq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.6347e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THOL",
    "name": "AngelBlock",
    "isPopular": false,
    "chainId": "1",
    "address": "0x207e14389183a94343942de7afbc607f57460618",
    "decimals": 18,
    "image": "https://rango.vip/i/SWl3fz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00650652,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "GRAV",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/e97634a40119f1898989c2a23224ed83fdd0a57ea46b3a094e287288d1672b44",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/GRAV.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00075872,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRE",
    "name": "CarryToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x115ec79f1de567ec68b7ae7eda501b406626478e",
    "decimals": 18,
    "image": "https://rango.vip/i/tPIbGW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00606371,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRE",
    "name": "Crypto Real Estate",
    "isPopular": false,
    "chainId": "1",
    "address": "0x21b8bfbbefc9e2b9a994871ecd742a5132b98aed",
    "decimals": 18,
    "image": "https://rango.vip/i/ZsGpf3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00041484,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRE",
    "name": "CRESO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x162433c934aa74ba147e05150b1206b2c922f71d",
    "decimals": 18,
    "image": "https://rango.vip/i/7qd3lM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000198,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRE",
    "name": "CRESO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41ea5d41eeacc2d5c4072260945118a13bb7ebce",
    "decimals": 18,
    "image": "https://rango.vip/i/FGITp1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000407,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CLAY",
    "name": "Claynosaurz",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AYtg18TFRQs1mCQ29zWY3aSwxwTcW1uTdZ65dL3WiBQu",
    "decimals": 2,
    "image": "https://rango.vip/i/J1CtAj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.002256481053046521,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USHI",
    "name": "Ushi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6dca182ac5e3f99985bc4ee0f726d6472ab1ec55",
    "decimals": 18,
    "image": "https://rango.vip/i/BQa6ZM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001098,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TNGBL",
    "name": "Tangible",
    "isPopular": false,
    "chainId": "137",
    "address": "0x49e6a20f1bbdfeec2a8222e052000bbb14ee6007",
    "decimals": 18,
    "image": "https://rango.vip/i/llMTNE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.953636,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FST",
    "name": "Futureswap Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e192d382a36de7011f795acc4391cd302003606",
    "decimals": 18,
    "image": "https://rango.vip/i/ywlUym",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01646357,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FST",
    "name": "Futureswap Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x488cc08935458403a0458e45e20c0159c8ab2c92",
    "decimals": 18,
    "image": "https://rango.vip/i/ywlUym",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01646357,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OS",
    "name": "Ethereans",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6100dd79fcaa88420750dcee3f735d168abcb771",
    "decimals": 18,
    "image": "https://rango.vip/i/crETSE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.409597,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "OS",
    "name": "Ethereans",
    "isPopular": false,
    "chainId": "10",
    "address": "0x6af3cb766d6cd37449bfd321d961a61b0515c1bc",
    "decimals": 18,
    "image": "https://rango.vip/i/qokIuW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.409597,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONO",
    "name": "MonoX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2920f7d6134f4669343e70122ca9b8f19ef8fa5d",
    "decimals": 18,
    "image": "https://rango.vip/i/0DIuYK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00051983,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "JUNIOR",
    "name": "JUNIOR",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x214dd1b5cbe543d4189ab39832f1bc1eedebb1d3",
    "decimals": 9,
    "image": "https://rango.vip/i/9W74VI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00227678,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COGE",
    "name": "Cogecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc382e04099a435439725bb40647e2b32dc136806",
    "decimals": 18,
    "image": "https://rango.vip/i/xYYInr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.489e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PWT",
    "name": "PandaInu Wallet Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf3edd4f14a018df4b6f02bf1b2cf17a8120519a2",
    "decimals": 8,
    "image": "https://rango.vip/i/nDngmq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0000011216883585504438,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "P404",
    "name": "Potion 404",
    "isPopular": false,
    "chainId": "1",
    "address": "0x177c3973b16c16fb5d934ca92b6e6afb03383268",
    "decimals": 18,
    "image": "https://rango.vip/i/jbaQLH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.781034,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CKC",
    "name": "ChikinCoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8s9FCz99Wcr3dHpiauFRi6bLXzshXfcGTfgQE7UEopVx",
    "decimals": 6,
    "image": "https://rango.vip/i/4uaaXN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006421,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SATOZ",
    "name": "Satozhi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x845e2e8b42dced7dedcdba9bde32c9e338224f97",
    "decimals": 8,
    "image": "https://rango.vip/i/FNYyrf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01689378,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BPET",
    "name": "xPet tech BPET",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6daf586b7370b14163171544fca24abcc0862ac5",
    "decimals": 18,
    "image": "https://rango.vip/i/o8bLFU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00993739,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0XG",
    "name": "0xGPU ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x486d95c40feba650c38e98cd9d7979d9cd88cea0",
    "decimals": 18,
    "image": "https://rango.vip/i/eBwiJX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00975682,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TINC",
    "name": "Tiny Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x05ad6e30a855be07afa57e08a4f30d00810a402e",
    "decimals": 18,
    "image": "https://rango.vip/i/fNXivU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0028769332570926765,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COL",
    "name": "Colana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "B4cYZYVYeHgLc3W1pCduCYkoS75G6roPaPdPoBCFweNJ",
    "decimals": 9,
    "image": "https://rango.vip/i/EWXSoj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00070152,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PET",
    "name": "Hello Pets",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb870679a7fa65b924026f496de7f27c1dd0e5c5f",
    "decimals": 18,
    "image": "https://rango.vip/i/lwEOA1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04269906,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PET",
    "name": "Pet Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4d4e595d643dc61ea7fcbf12e4b1aaa39f9975b8",
    "decimals": 18,
    "image": "https://rango.vip/i/o88H6P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.04246822335868316,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SINGLE",
    "name": "Single Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x0804702a4e749d39a35fde73d1df0b1f1d6b8347",
    "decimals": 18,
    "image": "https://rango.vip/i/gHVTXp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00055689,
    "supportedSwappers": [
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUFU",
    "name": "Fufu Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7b37a55ffb30c11d95f943672ae98f28cfb7b087",
    "decimals": 2,
    "image": "https://rango.vip/i/rwzBNc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002333,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BOM",
    "name": "Borderless Money",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc59132fbdf8de8fbe510f568a5d831c991b4fc38",
    "decimals": 18,
    "image": "https://rango.vip/i/jXzqKD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 5.662152451205482e-8,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BP",
    "name": "Beyond Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf290b162a7d3e0a328cf198308d421954f08b94",
    "decimals": 18,
    "image": "https://rango.vip/i/9hRwNN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00069725,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BP",
    "name": "BunnyPark",
    "isPopular": false,
    "chainId": "56",
    "address": "0xacb8f52dc63bb752a51186d1c55868adbffee9c1",
    "decimals": 18,
    "image": "https://rango.vip/i/2gtZaK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00165045,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAFT",
    "name": "Raft",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c5cb5d87709387f8821709f7a6664f00dcf0c93",
    "decimals": 18,
    "image": "https://rango.vip/i/319F6u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00044986,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XV",
    "name": "XV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34dce75a3d1910cc9d188aa5a75fb9addcae0fcc",
    "decimals": 18,
    "image": "https://rango.vip/i/6UlKTg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00060065,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ALI",
    "name": "Alethea AI",
    "isPopular": false,
    "chainId": "25",
    "address": "0x45c135c1cdce8d25a3b729a28659561385c52671",
    "decimals": 18,
    "image": "https://rango.vip/i/Hf0Ss8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00830565,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALI",
    "name": "Alethea Artificial Liquid Intelligence",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b0b3a982b4634ac68dd83a4dbf02311ce324181",
    "decimals": 18,
    "image": "https://rango.vip/i/wk6VjI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00830565,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ALI",
    "name": "Artificial Liquid Intelligence Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xbfc70507384047aa74c29cdc8c5cb88d0f7213ac",
    "decimals": 18,
    "image": "https://rango.vip/i/NWrQow",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00830565,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ALI",
    "name": "Alethea Artificial Liquid Intelligence",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xef6124368c0b56556667e0de77ea008dfc0a71d1",
    "decimals": 18,
    "image": "https://rango.vip/i/wk6VjI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.008308224832077965,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LVM",
    "name": "LakeViewMeta",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5bb15141bb6def6d2bafeed8ff84bf889c0c573b",
    "decimals": 18,
    "image": "https://rango.vip/i/hxhfPL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013951,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLRBRG",
    "name": "Floor Cheese Burger",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9138c8779a0ac8a84d69617d5715bd8afa23c650",
    "decimals": 18,
    "image": "https://rango.vip/i/bwuAPR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000025,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "EMP",
    "name": "Empyreal",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x772598e9e62155d7fdfe65fdf01eb5a53a8465be",
    "decimals": 18,
    "image": "https://rango.vip/i/o1Lapc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 19.13,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMP",
    "name": "Export Motors Platform",
    "isPopular": false,
    "chainId": "1",
    "address": "0x13a0599c493cc502ed8c4dd26f22f5cedc248fc4",
    "decimals": 18,
    "image": "https://rango.vip/i/0J6s0x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00321118,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMP",
    "name": "Empyreal",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39d5313c3750140e5042887413ba8aa6145a9bd2",
    "decimals": 18,
    "image": "https://rango.vip/i/o1Lapc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 19.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "SCR",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/178248c262de2e141ee6287ee7ab0854f05f25b0a3f40c4b912fa1c7e51f466e",
    "decimals": 9,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.022662055602668728,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "USA",
    "name": "Based USA",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xb56d0839998fd79efcd15c27cf966250aa58d6d3",
    "decimals": 18,
    "image": "https://rango.vip/i/GFr2ll",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00037555,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USA",
    "name": "American Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "69kdRLyP5DTRkpHraaSZAQbWmAwzF9guKjZfzMXzcbAs",
    "decimals": 6,
    "image": "https://rango.vip/i/YVEYGy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.86481e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USA",
    "name": "DEDPRZ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4ffe9cc172527df1e40d0b2efe1e9f05884a13da",
    "decimals": 18,
    "image": "https://rango.vip/i/SDMi37",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.127215,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPER",
    "name": "Baby Pepe on ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb69100340a5947e856d873463694ae2186146c43",
    "decimals": 9,
    "image": "https://rango.vip/i/KyX07l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.36158e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "POTS",
    "name": "Moonpot",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3fcca8648651e5b974dd6d3e50f61567779772a8",
    "decimals": 18,
    "image": "https://rango.vip/i/Cs6us4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00435175,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOTDOG",
    "name": "Sonic",
    "isPopular": false,
    "chainId": "1",
    "address": "0x01e87d74b11f656a673a3e7c441425816213eb0c",
    "decimals": 18,
    "image": "https://rango.vip/i/CzlaPf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00053801,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DXS",
    "name": "Decentrashop",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc69996612249417db3407d98ea5b534faa0e90a7",
    "decimals": 18,
    "image": "https://rango.vip/i/0FMSAG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.171417,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ANTC",
    "name": "ANT Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4WRST6Q2yyDP1p7Bqszg8PAAoj8j69cj7QY4QLn5Aq3o",
    "decimals": 6,
    "image": "https://rango.vip/i/3wrMPp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0036988,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RAMEN",
    "name": "Ramen Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4f47a0d15c1e53f3d94c069c7d16977c29f9cb6b",
    "decimals": 18,
    "image": "https://rango.vip/i/FocZD2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.001169643763650512,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "BRRR",
    "name": "BRRR",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x0240ae04c9f47b91cf47ca2e7ef44c9de0d385ac",
    "decimals": 18,
    "image": "https://rango.vip/i/Qk1ap8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.005255383490599411,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FINE",
    "name": "Refinable",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4e6415a5727ea08aae4580057187923aec331227",
    "decimals": 18,
    "image": "https://rango.vip/i/kBIoXp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00181694,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FINE",
    "name": "FINE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x75c97384ca209f915381755c582ec0e2ce88c1ba",
    "decimals": 18,
    "image": "https://rango.vip/i/j7WFGM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.373e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GM",
    "name": "GM Solana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CmSryDa4mnDYUicq7qSESsTKAdgBP26jSYcg8zavVoJd",
    "decimals": 7,
    "image": "https://rango.vip/i/JuoTLz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008073151805405102,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GM",
    "name": "GM",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3acxNNmfdKKZj9i35P4VDBFm74Ufdt8ojKWceVGynwC5",
    "decimals": 4,
    "image": "https://rango.vip/i/3ZTKEl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.05e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GM",
    "name": "gm",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5597f0723eeaba1b26948e06f008bf0fc1e37e6",
    "decimals": 18,
    "image": "https://rango.vip/i/c3cwyt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000117,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GM",
    "name": "GhostMarket Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x203aad20f51bbe43e650d3ceea88d43dd6c817c1",
    "decimals": 8,
    "image": "https://rango.vip/i/U4MIcb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00325271,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GM",
    "name": "we love gm",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f0693074f8064cfbcf9fa6e5a3fa0e4f58ccccf",
    "decimals": 18,
    "image": "https://rango.vip/i/6SWlze",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008073151805405102,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GM",
    "name": "GoldMiner",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe2604c9561d490624aa35e156e65e590eb749519",
    "decimals": 18,
    "image": "https://rango.vip/i/QK0L20",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 8.64022e-7,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GM",
    "name": "Guild Member Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe40d881cf66e1f6d19979a5ff6c830c6af65d278",
    "decimals": 18,
    "image": "https://rango.vip/i/aA6ece",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00008073151805405102,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUSD",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f98805a4e8be255a32880fdec7f6728c6568ba0",
    "decimals": 18,
    "image": "https://rango.vip/i/AfzUcU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "ThorChain",
      "EthereumUniswapV3",
      "ThorChainStreamingSwap",
      "stargate",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LUSD",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0x93b346b6bc2548da6a1e7d98e9a421b42541425b",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/LUSD.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "ArbitrumUniSwapV3",
      "stargate"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "LUSD",
    "name": null,
    "isPopular": false,
    "chainId": "10",
    "address": "0xc40f949f8a4e094d1b49a23ea9241d289b7b2819",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/LUSD.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "stargate",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUSD",
    "name": "lUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31a2e08f4232329e4eddb025c0275f43c9cd56d7",
    "decimals": 18,
    "image": "https://rango.vip/i/U30u7h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.22,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUSD",
    "name": "LUSD Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x93b346b6bc2548da6a1e7d98e9a421b42541425b",
    "decimals": 18,
    "image": "https://rango.vip/i/6VBP9r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.0016629862393518,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LUSD",
    "name": "lUSD",
    "isPopular": false,
    "chainId": "56",
    "address": "0x23e8a70534308a4aaf76fb8c32ec13d17a3bd89e",
    "decimals": 18,
    "image": "https://rango.vip/i/TipthC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0000015,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "LUSD",
    "name": "LUSD Stablecoin",
    "isPopular": false,
    "chainId": "324",
    "address": "0x503234f203fc7eb888eec8513210612a43cf6115",
    "decimals": 18,
    "image": "https://rango.vip/i/WxE6E0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "LUSD",
    "name": "Liquity USD",
    "isPopular": false,
    "chainId": "SN_MAIN",
    "address": "0x70a76fd48ca0ef910631754d77dd822147fe98a569b826ec85e3c33fde586ac",
    "decimals": 18,
    "image": "https://rango.vip/i/7xmWVo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 1.0016629862393518,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SYNTH",
    "name": "Synth Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xbd2dbb8ecea9743ca5b16423b4eaa26bdcfe5ed2",
    "decimals": 18,
    "image": "https://rango.vip/i/KzUIaE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.008,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SYNTH",
    "name": "Synth",
    "isPopular": false,
    "chainId": "10",
    "address": "0xa925f4057d6e6c8faf8bde537ad14ba91a1d0337",
    "decimals": 18,
    "image": "https://rango.vip/i/altOem",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.0231140821298808,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYNTH",
    "name": "Synth",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd41f3d112cb8695c7a8992e4055bd273f3ce8729",
    "decimals": 18,
    "image": "https://rango.vip/i/altOem",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.0231140821298808,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MDF",
    "name": "MatrixETF DAO Finance",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z",
    "decimals": 6,
    "image": "https://rango.vip/i/c7yeQE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00059079,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MDF",
    "name": "MatrixETF",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a57367c6194199e5d9aea1ce027431682dfb411",
    "decimals": 18,
    "image": "https://rango.vip/i/iBRPd7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00059079,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOC",
    "name": "LockTrip",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5e3346444010135322268a4630d2ed5f8d09446c",
    "decimals": 18,
    "image": "https://rango.vip/i/5r2GJp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.183048,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "PAC",
    "name": "PacMoon",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x5ffd9ebd27f2fcab044c0f0a26a45cb62fa29c06",
    "decimals": 18,
    "image": "https://rango.vip/i/xwtbjz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00400614,
    "supportedSwappers": [
      "ThrusterV3",
      "ThrusterV2.03",
      "ThrusterV2.1"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBB",
    "name": "BitBullBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9dc9d1c18060b45f3dc15fb8d6ab1895022c63b3",
    "decimals": 18,
    "image": "https://rango.vip/i/myFYnC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027923,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MVC",
    "name": "MileVerse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x581911b360b6eb3a14ef295a83a91dc2bce2d6f7",
    "decimals": 18,
    "image": "https://rango.vip/i/8oiq6V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00431859,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ICHI",
    "name": "ICHI",
    "isPopular": false,
    "chainId": "137",
    "address": "0x111111517e4929d3dcbdfa7cce55d30d4b6bc4d6",
    "decimals": 18,
    "image": "https://rango.vip/i/3DRQ9d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.12,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ICHI",
    "name": "ICHI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x111111517e4929d3dcbdfa7cce55d30d4b6bc4d6",
    "decimals": 18,
    "image": "https://rango.vip/i/oBLepi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ICHI",
    "name": "ichi.farm",
    "isPopular": false,
    "chainId": "1",
    "address": "0x903bef1736cddf2a537176cf3c64579c3867a881",
    "decimals": 9,
    "image": "https://rango.vip/i/Oxz2LJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DOGO",
    "name": "Dogemon",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5LSFpvLDkcdV2a3Kiyzmg5YmJsj2XDLySaXvnfP1cgLT",
    "decimals": 6,
    "image": "https://rango.vip/i/oXDYIr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000342,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCAI",
    "name": "Onchain AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4309e88d1d511f3764ee0f154cee98d783b61f09",
    "decimals": 18,
    "image": "https://rango.vip/i/cxNU7S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0015336,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "STACKS",
    "name": "STACKS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8npbXTu4oTNw3xrMGY1aTm4CtB7cMPVuRUCh3Wqj3fSW",
    "decimals": 9,
    "image": "https://rango.vip/i/OsX3bM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00067784,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PEN",
    "name": "Penjamin Blinkerton",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xd7d919ea0c33a97ad6e7bd4f510498e2ec98cb78",
    "decimals": 18,
    "image": "https://rango.vip/i/JPK3F5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 3.08184e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BNTY",
    "name": "Bounty0x",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2d6158683aee4cc838067727209a0aaf4359de3",
    "decimals": 18,
    "image": "https://rango.vip/i/JRIbm0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011305,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUMMY",
    "name": "DUMMY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x445bd590a01fe6709d4f13a8f579c1e4846921db",
    "decimals": 18,
    "image": "https://rango.vip/i/SEB0ze",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.2768e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "ROSE",
    "name": "Rose Token",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xdcd6d4e2b3e1d1e1e6fa8c21c8a323dcbecff970",
    "decimals": 18,
    "image": "https://rango.vip/i/CN0SFG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.0005988771353914489,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOREX",
    "name": "handle fi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb298285fe4c5410b05390ca80e8fbe9de1f259b",
    "decimals": 18,
    "image": "https://rango.vip/i/fHAc6a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00075109,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BMBO",
    "name": "Bamboo",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5sM9xxcBTM9rWza6nEgq2cShA87JjTBx1Cu82LjgmaEg",
    "decimals": 9,
    "image": "https://rango.vip/i/7vBEug",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000258,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CHONK",
    "name": "Chonk",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6d22d3ed82c947be8860a86a69c4b0cb0f65589e",
    "decimals": 18,
    "image": "https://rango.vip/i/5I8jK0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 4.6029e-8,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PETS",
    "name": "PolkaPet World",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6afcff9189e8ed3fcc1cffa184feb1276f6a82a5",
    "decimals": 18,
    "image": "https://rango.vip/i/hV8kwG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005446,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "PETS",
    "name": "PolkaPet World",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x1e0f2a75be02c025bd84177765f89200c04337da",
    "decimals": 18,
    "image": "https://rango.vip/i/r5bLzL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.000656682799812441,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "MSCP",
    "name": "Moonscape",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x5c22ba65f65adffadfc0947382f2e7c286a0fe45",
    "decimals": 18,
    "image": "https://rango.vip/i/3s5rfZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.00012968,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HVE2",
    "name": "Uhive",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd487892bb4c57edbe7ab401d9fe801c8fe6473f5",
    "decimals": 18,
    "image": "https://rango.vip/i/qfHlj1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0004948,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WNZ",
    "name": "Winnerz",
    "isPopular": false,
    "chainId": "1",
    "address": "0x02795795196f563fdafce8dd97fca4871ded51c3",
    "decimals": 18,
    "image": "https://rango.vip/i/Oy1QDA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.039977,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TETU",
    "name": "TETU Reward Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x255707b70bf90aa112006e1b07b9aea6de021424",
    "decimals": 18,
    "image": "https://rango.vip/i/2vsKt8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00152161,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UGT",
    "name": "Unreal Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9cf98eb8a8b28c83e8612046cf55701ce3eb0063",
    "decimals": 18,
    "image": "https://rango.vip/i/4o2r8R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0028261,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNO",
    "name": "UnoRe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x474021845c4643113458ea4414bdb7fb74a01a77",
    "decimals": 18,
    "image": "https://rango.vip/i/sNktJu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01199949,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POU",
    "name": "POU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PouWaap6bHZv1oT7T7rzRgQHTBD4nsWQRHGbB5hfDA6",
    "decimals": 6,
    "image": "https://rango.vip/i/Aqwl2N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00051703,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VERSE",
    "name": "Verse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x249ca82617ec3dfb2589c4c17ab7ec9765350a18",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013464,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "VERSE",
    "name": "Verse",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc708d6f2153933daa50b2d0758955be0a93a8fec",
    "decimals": 18,
    "image": "https://rango.vip/i/aWbKJT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00013464,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VERSE",
    "name": "Shibaverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ae0d42f23c33338de15bfa89c7405c068d9dc0a",
    "decimals": 18,
    "image": "https://rango.vip/i/l7uBPU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006569,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BREPE",
    "name": "BREPE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0117792d4b100fd329b37e8ab4181df8a5b3326",
    "decimals": 18,
    "image": "https://rango.vip/i/oOCfDH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.74427e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABYMEME",
    "name": "Baby Memecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd54df3c19a7ae672897f2a09821d2c287d36326",
    "decimals": 9,
    "image": "https://rango.vip/i/J454P5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.0883e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATX",
    "name": "AUTOMATIX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa28cbb24313df3907b8d87685bab2c1d0bd46b60",
    "decimals": 18,
    "image": "https://rango.vip/i/TPHhcK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00246592,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FUEL",
    "name": "Fuel",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "fueL3hBZjLLLJHiFH9cqZoozTG3XQZ53diwFPwbzNim",
    "decimals": 0,
    "image": "https://rango.vip/i/28R5iJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00011543084324811836,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUEL",
    "name": "Etherparty",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea38eaa3c86c8f9b751533ba2e562deb9acded40",
    "decimals": 18,
    "image": "https://rango.vip/i/i3u3iq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001154,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FUEL",
    "name": "Fuel Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2090c8295769791ab7a3cf1cc6e0aa19f35e441a",
    "decimals": 18,
    "image": "https://rango.vip/i/60Ag9b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00011543084324811836,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "APT",
    "name": "Apricot",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt",
    "decimals": 6,
    "image": "https://rango.vip/i/rdxQjG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00038047,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "APT",
    "name": "Aptos",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc5b3ac2df8d8d7ac851f763a5b3ff23b4a696d59",
    "decimals": 8,
    "image": "https://rango.vip/i/en0lBC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 5.76842863998182,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "MMF",
    "name": "Mad Meerkat Finance Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x97749c9b61f878a880dfe312d2594ae07aed7656",
    "decimals": 18,
    "image": "https://rango.vip/i/dp5QBp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00176814,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EGT",
    "name": "ElonGoat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x450e7f6e3a2f247a51b98c39297a9a5bfbdb3170",
    "decimals": 9,
    "image": "https://rango.vip/i/s9kA6v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002899,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EGT",
    "name": "Elastic Governance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2aa5ce395b00cc486159adbdd97c55b535cf2cf9",
    "decimals": 18,
    "image": "https://rango.vip/i/7TOuM7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000291148750976955,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BANANA",
    "name": "BANANA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GAbRt1gE7Y5nBhhe95bxE2TaRySm8hMA6syjiUQ7q1Er",
    "decimals": 4,
    "image": "https://rango.vip/i/xb3ikX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 36.81116634828516,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BANANA",
    "name": "ApeSwapFinance Banana",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5d47baba0d66083c52009271faf3f50dcc01023c",
    "decimals": 18,
    "image": "https://rango.vip/i/8tkb2A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00085327,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BANANA",
    "name": "Banana",
    "isPopular": false,
    "chainId": "137",
    "address": "0xbc91347e80886453f3f8bbd6d7ac07c122d87735",
    "decimals": 18,
    "image": "https://rango.vip/i/tFcIzK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.278976,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BANANA",
    "name": "Banana",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38e68a37e401f7271568cecaac63c6b1e19130b4",
    "decimals": 18,
    "image": "https://rango.vip/i/BSTKXE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 36.63,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BANANA",
    "name": "Banana",
    "isPopular": false,
    "chainId": "1",
    "address": "0x94e496474f1725f1c1824cb5bdb92d7691a4f03a",
    "decimals": 18,
    "image": "https://rango.vip/i/QhlSU1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.278976,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BANANA",
    "name": "ApeSwapFinance Banana",
    "isPopular": false,
    "chainId": "1",
    "address": "0x92df60c51c710a1b1c20e42d85e221f3a1bfc7f2",
    "decimals": 18,
    "image": "https://rango.vip/i/UmeO6A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00085327,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BANANA",
    "name": "Banana",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe2311ae37502105b442bbef831e9b53c5d2e9b3b",
    "decimals": 18,
    "image": "https://rango.vip/i/wwjraP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 36.81116634828516,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BANANA",
    "name": "ApeSwapFinance Banana",
    "isPopular": false,
    "chainId": "56",
    "address": "0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95",
    "decimals": 18,
    "image": "https://rango.vip/i/ECFxUh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00085327,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EDGE",
    "name": "Edge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4ec1b60b96193a64acae44778e51f7bff2007831",
    "decimals": 18,
    "image": "https://rango.vip/i/HsEwOC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.083805,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DWEB",
    "name": "DecentraWeb (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8839e639f210b80ffea73aedf51baed8dac04499",
    "decimals": 18,
    "image": "https://rango.vip/i/8jedEj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01791575,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DWEB",
    "name": "DecentraWeb",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe7f58a92476056627f9fdb92286778abd83b285f",
    "decimals": 18,
    "image": "https://rango.vip/i/FE0QxY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01791575,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LQDX",
    "name": "Liquid Crypto",
    "isPopular": false,
    "chainId": "1",
    "address": "0x872952d3c1caf944852c5adda65633f1ef218a26",
    "decimals": 18,
    "image": "https://rango.vip/i/NQEbb6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00681786,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AFC",
    "name": "Army of Fortune Metaverse",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4568ca00299819998501914690d6010ae48a59ba",
    "decimals": 0,
    "image": "https://rango.vip/i/FeI1AV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00000535,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOBBES",
    "name": "Hobbes",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb475332d25d34b59176f5c1d94cb9bc9b5e3954a",
    "decimals": 9,
    "image": "https://rango.vip/i/PfNOK6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.4353e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOBBES",
    "name": "Hobbes",
    "isPopular": false,
    "chainId": "1",
    "address": "0x819c1a1568934ee59d9f3c8b9640908556c44140",
    "decimals": 18,
    "image": "https://rango.vip/i/xeDkvh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.4782e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STKXPRT",
    "name": "pSTAKE Staked XPRT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x45e007750cc74b1d2b4dd7072230278d9602c499",
    "decimals": 6,
    "image": "https://rango.vip/i/3kdRRm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.18396396931750492,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "XKI",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/b547dc9b897e7c3aa5b824696110b8e3d2c31e3ed3f02ff363dcbad82457e07e",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/XKI.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.004210175795072,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "KI",
    "symbol": "XKI",
    "name": null,
    "isPopular": true,
    "chainId": "kichain-2",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/43Y8gM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/KI/icon.svg",
    "usdPrice": 0.0042142973111525,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MATRIX",
    "name": "Matrix Labs",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc8d3dcb63c38607cb0c9d3f55e8ecce628a01c36",
    "decimals": 18,
    "image": "https://rango.vip/i/1J45wv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00070387,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MATRIX",
    "name": "Matrixswap",
    "isPopular": false,
    "chainId": "137",
    "address": "0x211f4e76fcb811ed2b310a232a24b3445d95e3bc",
    "decimals": 18,
    "image": "https://rango.vip/i/5OGdAB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00070387,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FUD",
    "name": "Aavegotchi FUD",
    "isPopular": false,
    "chainId": "137",
    "address": "0x403e967b044d4be25170310157cb1a4bf10bdd0f",
    "decimals": 18,
    "image": "https://rango.vip/i/moqigq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00017186,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MBTC",
    "name": "mStable BTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x945facb997494cc2570096c74b5f66a3507330a1",
    "decimals": 18,
    "image": "https://rango.vip/i/VJE14u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.8910737560581405,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MBTC",
    "name": "Babypie BTC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7c1cca5b25fa0bc9af9275fb53cba89dc172b878",
    "decimals": 8,
    "image": "https://rango.vip/i/8zyZcL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.8910737560581405,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "MBTC",
    "name": "Merlin BTC",
    "isPopular": false,
    "chainId": "34443",
    "address": "0x59889b7021243db5b1e065385f918316cd90d46c",
    "decimals": 18,
    "image": "https://rango.vip/i/1tW2jN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 55657.644330876705,
    "supportedSwappers": [
      "SwapMode"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRAB",
    "name": "CRABBY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6096b8765eb48cd2193f840a977f3727e7800356",
    "decimals": 18,
    "image": "https://rango.vip/i/SHXmg1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00793667,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KABOSU",
    "name": "Kabosu  Arbitrum ",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x53bcf6698c911b2a7409a740eacddb901fc2a2c6",
    "decimals": 18,
    "image": "https://rango.vip/i/4VJH2v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 4.07285e-7,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KABOSU",
    "name": "Kabosu Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbadff0ef41d2a68f22de21eabca8a59aaf495cf0",
    "decimals": 18,
    "image": "https://rango.vip/i/iiM5fq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.80682e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GOLDY",
    "name": "DeFi Land Gold",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GoLDYyyiVeXnVf9qgoK712N5esm1cCbHEK9aNJFx47Sx",
    "decimals": 9,
    "image": "https://rango.vip/i/lP5mcb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001671,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OHM",
    "name": "Olympus",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf0cb2dc0db5e6c66b9a70ac27b06b878da017028",
    "decimals": 9,
    "image": "https://rango.vip/i/fTlWTx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 14.26,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OHM",
    "name": "Olympus",
    "isPopular": false,
    "chainId": "1",
    "address": "0x383518188c0c6d7730d91b2c03a03c837814a899",
    "decimals": 9,
    "image": "https://rango.vip/i/RNiMvL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 82.03,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OHM",
    "name": "Olympus",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6e6a3d8f1affac703b1aef1f43b8d2321be40043",
    "decimals": 9,
    "image": "https://rango.vip/i/GVQRNZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 81.66823561760982,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OHM",
    "name": "Olympus",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5",
    "decimals": 9,
    "image": "https://rango.vip/i/vkZ0Q2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 14.26,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BAREBEARS",
    "name": "BAREBEARS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AeNg6DaCAjNpK7CvkSC6c9j5g8YFSp78aTQxejaNRNcz",
    "decimals": 6,
    "image": "https://rango.vip/i/uwtli1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0011587037683297551,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGEP",
    "name": "Doge Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe7eaec9bca79d537539c00c58ae93117fb7280b9",
    "decimals": 18,
    "image": "https://rango.vip/i/rn8Rj8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.0761e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XCHF",
    "name": "CryptoFranc",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb4272071ecadd69d933adcd19ca99fe80664fc08",
    "decimals": 18,
    "image": "https://rango.vip/i/9ijuJY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.17,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "XCHF",
    "name": "CryptoFranc",
    "isPopular": false,
    "chainId": "10",
    "address": "0xe4f27b04cc7729901876b44f4eaa5102ec150265",
    "decimals": 18,
    "image": "https://rango.vip/i/BEfoyQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.9893089514438643,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNDCC",
    "name": "Mondo Community Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcdb9d30a3ba48cdfcb0ecbe19317e6cf783672f1",
    "decimals": 18,
    "image": "https://rango.vip/i/clIGNl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01003031,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AGA",
    "name": "AGA Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x033d942a6b495c4071083f4cde1f17e986fe856c",
    "decimals": 4,
    "image": "https://rango.vip/i/zzBSqe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.04215449,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGA",
    "name": "AGA Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2d80f5f5328fdcb6eceb7cacf5dd8aedaec94e20",
    "decimals": 4,
    "image": "https://rango.vip/i/PlOKaQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04215449,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGA",
    "name": "AgoraHub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x87b46212e805a3998b7e8077e9019c90759ea88c",
    "decimals": 18,
    "image": "https://rango.vip/i/9bCJuv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01429584,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPA",
    "name": "Pepa ERC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x577fee283e776eec29c9e4d258431982780a38a8",
    "decimals": 9,
    "image": "https://rango.vip/i/nHELU6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.1109e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANY",
    "name": "Anyswap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf99d58e463a2e07e5692127302c20a191861b4d6",
    "decimals": 18,
    "image": "https://rango.vip/i/vfiZP5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.52,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ANY",
    "name": "Anyswap-BEP20",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf68c9df95a18b2a5a5fa1124d79eeeffbad0b6fa",
    "decimals": 18,
    "image": "https://rango.vip/i/Nw0jkw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.52,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ANY",
    "name": "Anyswap",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8",
    "decimals": 18,
    "image": "https://rango.vip/i/HlkbZL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.52,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SPIN",
    "name": "Spintop",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6aa217312960a21adbde1478dc8cbcf828110a67",
    "decimals": 18,
    "image": "https://rango.vip/i/BfcqSX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00383815,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CASINU",
    "name": "Casinu Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1b54a6fa1360bd71a0f28f77a1d6fba215d498c3",
    "decimals": 9,
    "image": "https://rango.vip/i/1BpNfX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000181,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BUCK",
    "name": "Arbucks",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xafd871f684f21ab9d7137608c71808f83d75e6fc",
    "decimals": 18,
    "image": "https://rango.vip/i/38Fz3A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.9995356571257152,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "BAKED",
    "name": "BakedToken",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x8973c9ec7b79fe880697cdbca744892682764c37",
    "decimals": 18,
    "image": "https://rango.vip/i/poL2C4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.00090657,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BAKED",
    "name": "BAKED",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CQbXk942c6GPcRwtZ2WMFP5JcQ9NqbXtb8jUewBi7GoT",
    "decimals": 6,
    "image": "https://rango.vip/i/eqzL5u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00327157,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAKED",
    "name": "Baked",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa4cb0dce4849bdcad2d553e9e68644cf40e26cce",
    "decimals": 18,
    "image": "https://rango.vip/i/FUBwuP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00090657,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "LYRA",
    "name": "Lyra Finance",
    "isPopular": false,
    "chainId": "10",
    "address": "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
    "decimals": 18,
    "image": "https://rango.vip/i/yeeQmL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00055994,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LYRA",
    "name": "Lyra Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x01ba67aac7f75f647d94220cc98fb30fcc5105bf",
    "decimals": 18,
    "image": "https://rango.vip/i/IWg5ou",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00055994,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ROY",
    "name": "Royale",
    "isPopular": false,
    "chainId": "137",
    "address": "0x68ee0d0aad9e1984af85ca224117e4d20eaf68be",
    "decimals": 18,
    "image": "https://rango.vip/i/8TLdIk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00159318,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ROY",
    "name": "Royale",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x68ee0d0aad9e1984af85ca224117e4d20eaf68be",
    "decimals": 18,
    "image": "https://rango.vip/i/e7YMC2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00159318,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "GRVE",
    "name": "Grave token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x9885488cd6864df90eeba6c5d07b35f08ceb05e9",
    "decimals": 18,
    "image": "https://rango.vip/i/gr1ViX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00283666,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEG",
    "name": "Beg",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe6be64e9e5042b6e84e4c27956cce6353efa5f5",
    "decimals": 18,
    "image": "https://rango.vip/i/EaWYAf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.431548,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIN",
    "name": "Shina Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0bbbe391b0d0957f1d013381b643041d2ca4022",
    "decimals": 18,
    "image": "https://rango.vip/i/QjRUs9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.005e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMERICA",
    "name": "America1776",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1776b223ff636d0d76adf2290821f176421dd889",
    "decimals": 9,
    "image": "https://rango.vip/i/LzDmE1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0002352,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DON",
    "name": "Dogeon Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x1db749847c4abb991d8b6032102383e6bfd9b1c7",
    "decimals": 18,
    "image": "https://rango.vip/i/V3DBhW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00010814,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DON",
    "name": "Don key",
    "isPopular": false,
    "chainId": "1",
    "address": "0x217ddead61a42369a266f1fb754eb5d3ebadc88a",
    "decimals": 18,
    "image": "https://rango.vip/i/0OgBSk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00106199,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "MUSD",
    "name": "Mad USD token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x95aeaf383e2e86a47c11cffde1f7944ecb2c38c2",
    "decimals": 18,
    "image": "https://rango.vip/i/JOsewY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.051158,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUSD",
    "name": "mStable USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe2f2a5c287993345a840db3b0845fbc70f5935a5",
    "decimals": 18,
    "image": "https://rango.vip/i/AVnfYO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999257,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XMS",
    "name": "Mars Ecosystem",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7859b01bbf675d67da8cd128a50d155cd881b576",
    "decimals": 18,
    "image": "https://rango.vip/i/2ztrXM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00031889,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOOST",
    "name": "Boost",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e0fca55a6c3a94720ded91153a27f60e26b9aa8",
    "decimals": 18,
    "image": "https://rango.vip/i/9M5n6k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.589815501871358e-7,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BOOST",
    "name": "Boost",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd44e8f8768d4ed25119921a53802d8758a5b20dd",
    "decimals": 18,
    "image": "https://rango.vip/i/mh5kIK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 6.589815501871358e-7,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOOST",
    "name": "Boosted Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e780920601d61cedb860fe9c4a90c9ea6a35e78",
    "decimals": 18,
    "image": "https://rango.vip/i/YBKHUe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.589815501871358e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOOST",
    "name": "Boost Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x302cae5dcf8f051d0177043c3438020b89b33218",
    "decimals": 18,
    "image": "https://rango.vip/i/oWGWCU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002146,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOOST",
    "name": "Boost AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2ce8366603f3fffc460bef0fb90e980c9337967",
    "decimals": 18,
    "image": "https://rango.vip/i/6ff9AT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00223921,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FTI",
    "name": "FansTime",
    "isPopular": false,
    "chainId": "1",
    "address": "0x943ed852dadb5c3938ecdc6883718df8142de4c8",
    "decimals": 18,
    "image": "https://rango.vip/i/3txBam",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000988,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ICE",
    "name": "IceToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xe0ce60af0850bf54072635e66e79df17082a1109",
    "decimals": 18,
    "image": "https://rango.vip/i/1Ftvx4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.003676534209203009,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ICE",
    "name": "Infinity Diamonds ICE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "icex2Fy2KtXjfiAAUEHLPHu7XKDLvwiyVUPP9PNpSkF",
    "decimals": 9,
    "image": "https://rango.vip/i/emRUH1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.003676534209203009,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ICE",
    "name": "Iron Finance ICE Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef",
    "decimals": 18,
    "image": "https://rango.vip/i/ypqCE0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000408,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ICE",
    "name": "Decentral Games ICE",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc6c855ad634dcdad23e64da71ba85b8c51e5ad7c",
    "decimals": 18,
    "image": "https://rango.vip/i/65bPCg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00152398,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ICE",
    "name": "IceToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf16e81dce15b08f326220742020379b855b87df9",
    "decimals": 18,
    "image": "https://rango.vip/i/qDziMC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.219258,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ICE",
    "name": "Ice",
    "isPopular": false,
    "chainId": "1",
    "address": "0x79f05c263055ba20ee0e814acd117c20caa10e0c",
    "decimals": 18,
    "image": "https://rango.vip/i/Nq8dci",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0036755,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ICE",
    "name": "IceToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf16e81dce15b08f326220742020379b855b87df9",
    "decimals": 18,
    "image": "https://rango.vip/i/VsMpfE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.219258,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EYES",
    "name": "EYES Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2dca19e944453e46d9130950ca135461b3bc0c30",
    "decimals": 18,
    "image": "https://rango.vip/i/tLezuA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00019593,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CAN",
    "name": "canwifhat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FF4dN8Qy8NNF88HRgMA3TkbRVZ8PTXWXZCZJb59X3Sbg",
    "decimals": 9,
    "image": "https://rango.vip/i/nm0mK3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00150928,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CAN",
    "name": "CanYaCoin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x007ea5c0ea75a8df45d288a4debdd5bb633f9e56",
    "decimals": 18,
    "image": "https://rango.vip/i/raqerB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0015025156983606836,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "CAN",
    "name": "Channels",
    "isPopular": false,
    "chainId": "128",
    "address": "0x1e6395e6b059fc97a4dda925b6c5ebf19e05c69f",
    "decimals": 18,
    "image": "https://rango.vip/i/CosdzE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0015025156983606836,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "CAN",
    "name": "Channels",
    "isPopular": false,
    "chainId": "128",
    "address": "0xd5f9bdc2e6c8ee0484a6293ce7fa97d96a5e1012",
    "decimals": 18,
    "image": "https://rango.vip/i/7Y0mQi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0002669,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "FILDA",
    "name": "Heco FilDA Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0xe36ffd17b2661eb57144ceaef942d95295e637f0",
    "decimals": 18,
    "image": "https://rango.vip/i/cL1dmb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00083015527709772,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LIEN",
    "name": "LIEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xab37e1358b639fd877f015027bb62d3ddaa7557e",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.209707,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LIEN",
    "name": "Lien",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5d684adaf3fcfe9cfb5cede3abf02f0cdd1012e3",
    "decimals": 8,
    "image": "https://rango.vip/i/iFOjlx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.209707,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CIOTX",
    "name": "Crosschain IOTX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f90b457dea25ef802e38d470dda7343691d8fe1",
    "decimals": 18,
    "image": "https://rango.vip/i/gv0tXN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0326047,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAR",
    "name": "War Legends",
    "isPopular": false,
    "chainId": "1",
    "address": "0x983d8edb44ca96c0595f3c456ebdd47855911f34",
    "decimals": 18,
    "image": "https://rango.vip/i/RJlE0E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014806123097764852,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "WAR",
    "name": "WAR",
    "isPopular": false,
    "chainId": "128",
    "address": "0x910651f81a605a6ef35d05527d24a72fecef8bf0",
    "decimals": 18,
    "image": "https://rango.vip/i/vkMqf0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00179926,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLAG",
    "name": "For Loot And Glory",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9348e94a447bf8b2ec11f374d3f055fd47d936df",
    "decimals": 18,
    "image": "https://rango.vip/i/YJazMy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.222167,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COFI",
    "name": "CoFi Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a23a6bfbadb59fa563008c0fb7cf96dfcf34ea1",
    "decimals": 18,
    "image": "https://rango.vip/i/MMgINb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00449816,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COFI",
    "name": "CoinFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3136ef851592acf49ca4c825131e364170fa32b3",
    "decimals": 18,
    "image": "https://rango.vip/i/bLgUwM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00050332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WUSDR",
    "name": "Wrapped USDR",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc03b43d492d904406db2d7d57e67c7e8234ba752",
    "decimals": 9,
    "image": "https://rango.vip/i/haGQ5G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.666739,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TXAU",
    "name": "tGOLD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe4a6f23fb9e00fca037aa0ea0a6954de0a6c53bf",
    "decimals": 18,
    "image": "https://rango.vip/i/iyRUut",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 77.98,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HTO",
    "name": "HTO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "htoHLBJV1err8xP5oxyQdV2PLQhtVjxLXpKB7FsgJQD",
    "decimals": 9,
    "image": "https://rango.vip/i/6Aj90j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00104069,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TIGRA",
    "name": "Tigra",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe8eff45293598919c99d1cbe5297f2a6935bc64",
    "decimals": 18,
    "image": "https://rango.vip/i/92C0yj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00022814,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONC",
    "name": "One Cash",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd90e69f67203ebe02c917b5128629e77b4cd92dc",
    "decimals": 18,
    "image": "https://rango.vip/i/fDfpzJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.071368,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "PLZ",
    "name": "PLZ",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TYK71t3eD1pTxpkDp7gbqXM5DYfaVdfKjV",
    "decimals": 8,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.00000241367888366391,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MET",
    "name": "Metronome2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2ebd53d035150f328bd754d6dc66b99b0edb89aa",
    "decimals": 18,
    "image": "https://rango.vip/i/yuVSZh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.364524,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MET",
    "name": "Metronome2",
    "isPopular": false,
    "chainId": "10",
    "address": "0x9a2e53158e12bc09270af10c16a466cb2b5d7836",
    "decimals": 18,
    "image": "https://rango.vip/i/E90HUd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.38499006661797136,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FOFAR",
    "name": "FOFAR",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6Hfwp6Fht1pEtusLsDqKuovBisPbFVoA7jZQYeK1aMmG",
    "decimals": 9,
    "image": "https://rango.vip/i/H8r5Lj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.003818340170695023,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOFAR",
    "name": "Fofar0x71",
    "isPopular": false,
    "chainId": "1",
    "address": "0x716bb5e0839451068885250442a5b8377f582933",
    "decimals": 9,
    "image": "https://rango.vip/i/Gdo7SN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.55233e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOFAR",
    "name": "Fofar",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeff49b0f56a97c7fd3b51f0ecd2ce999a7861420",
    "decimals": 9,
    "image": "https://rango.vip/i/vo5MUX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000306,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOMB",
    "name": "BOMB",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c95b093d6c236d3ef7c796fe33f9cc6b8606714",
    "decimals": 0,
    "image": "https://rango.vip/i/j0Eu0J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.05598,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOMB",
    "name": "LollyBomb",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b6ee7393f07b3dd1427b6848d3576f31c313127",
    "decimals": 18,
    "image": "https://rango.vip/i/SSgFup",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00202712,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BOMB",
    "name": "bomb.money",
    "isPopular": false,
    "chainId": "56",
    "address": "0x522348779dcb2911539e76a1042aa922f9c47ee3",
    "decimals": 18,
    "image": "https://rango.vip/i/PHsbcE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00729588,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KRW",
    "name": "KROWN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x499568c250ab2a42292261d6121525d70691894b",
    "decimals": 18,
    "image": "https://rango.vip/i/q4FVoQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000182,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "X8X",
    "name": "X8X",
    "isPopular": false,
    "chainId": "1",
    "address": "0x910dfc18d6ea3d6a7124a6f8b5458f281060fa4c",
    "decimals": 18,
    "image": "https://rango.vip/i/r8ch8E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0012846,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BSKT",
    "name": "Basket",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7ccdba6198db389cf37b714fd6573b73f3670236",
    "decimals": 5,
    "image": "https://rango.vip/i/uZgeCR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000411,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BSKT",
    "name": "Basket",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6gnCPhXtLnUD76HjQuSYPENLSZdG8RvDB1pTLM5aLSJA",
    "decimals": 5,
    "image": "https://rango.vip/i/RDOvpd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.12409555602855861,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BSKT",
    "name": "Basket",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc0899e527007f1b8ced694508fcb7a2b9a46f53",
    "decimals": 18,
    "image": "https://rango.vip/i/uZgeCR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000411,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOBC",
    "name": "Bobcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe803178b48a0e560c2b19f3b3d4e504f79d229ce",
    "decimals": 18,
    "image": "https://rango.vip/i/pvXjT6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.594448,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SA",
    "name": "Superalgos",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfb981ed9a92377ca4d75d924b9ca06df163924fd",
    "decimals": 18,
    "image": "https://rango.vip/i/Xz517t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0009953788676234432,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOLAR",
    "name": "Solareum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a6bfdd2174ade27258fe96b9afae3758dec14f2",
    "decimals": 18,
    "image": "https://rango.vip/i/hTiLTo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01985792,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SOLAR",
    "name": "SOLAR",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5fbccc4acc0b9339d7cdc5d6336a5c88d89e5327",
    "decimals": 18,
    "image": "https://rango.vip/i/3mMMYU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.09403761084648225,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "SOLAR",
    "name": "SolarBeam Token",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x6bd193ee6d2104f14f94e2ca6efefae561a4334b",
    "decimals": 18,
    "image": "https://rango.vip/i/UP6xx8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.00786732,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HBO",
    "name": "HBO",
    "isPopular": false,
    "chainId": "128",
    "address": "0x8764bd4fcc027faf72ba83c0b2028a3bae0d2d57",
    "decimals": 18,
    "image": "https://rango.vip/i/IxTwNx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0014213014591072186,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PLAY",
    "name": "PLAY",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd069599e718f963bd84502b49ba8f8657faf5b3a",
    "decimals": 18,
    "image": "https://rango.vip/i/eEBaK6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00021991,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLAY",
    "name": "Herocoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe477292f1b3268687a29376116b0ed27a9c76170",
    "decimals": 18,
    "image": "https://rango.vip/i/wiTMwM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00072792,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLAY",
    "name": "Playdoge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6551698ee65f5db726e49f9ab0ff1ce9419003a7",
    "decimals": 18,
    "image": "https://rango.vip/i/q2rWZ3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00074161,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLAY",
    "name": "Metaverse NFT Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33e18a092a93ff21ad04746c7da12e35d34dc7c4",
    "decimals": 18,
    "image": "https://rango.vip/i/dK4Pq1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00022477145852565654,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JET",
    "name": "Jet Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz",
    "decimals": 9,
    "image": "https://rango.vip/i/lawSVh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000702,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JET",
    "name": "Jetcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8727c112c712c4a03371ac87a74dd6ab104af768",
    "decimals": 18,
    "image": "https://rango.vip/i/p5ePrW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00212956,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CLIMB",
    "name": "Climb Token Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2a1d286ed5edad78befd6e0d8beb38791e8cd69d",
    "decimals": 8,
    "image": "https://rango.vip/i/cK7fwN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.903961961769482,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEN",
    "name": "Ben",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9bf1d7d63dd7a4ce167cf4866388226eeefa702e",
    "decimals": 18,
    "image": "https://rango.vip/i/vpCBy8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.988e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEN",
    "name": "BEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdcc97d2c1048e8f3f2fc58ace9024ab8b350e4b1",
    "decimals": 18,
    "image": "https://rango.vip/i/gMOf8S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000012016701917920692,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NU",
    "name": "NuCypher",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4fe83213d56308330ec302a8bd641f1d0113a4cc",
    "decimals": 18,
    "image": "https://rango.vip/i/cL8C3T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.064727,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SANSHU!",
    "name": "SANSHU!",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0026dfbd8dbb6f8d0c88303cc1b1596409fda542",
    "decimals": 18,
    "image": "https://rango.vip/i/F9aoms",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00130046,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "MECHA",
    "name": "Mecha",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xa33c3b53694419824722c10d99ad7cb16ea62754",
    "decimals": 18,
    "image": "https://rango.vip/i/vVjPyH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.00002536756359255842,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HEDG",
    "name": "HedgeTrade",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1290473e210b2108a85237fbcd7b6eb42cc654f",
    "decimals": 18,
    "image": "https://rango.vip/i/Elyct9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00961287,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PHTR",
    "name": "Phuture",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1fc4455f62a6e89476f1072530c20cf1a0622da",
    "decimals": 18,
    "image": "https://rango.vip/i/vRT0ZO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00300297,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ISHI",
    "name": "Ishi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85e0b9d3e7e4dba7e59090c533906d0e9211d8b6",
    "decimals": 9,
    "image": "https://rango.vip/i/ZrMbCQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.67146e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BR34P",
    "name": "BR34P",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa86d305a36cdb815af991834b46ad3d7fbb38523",
    "decimals": 8,
    "image": "https://rango.vip/i/gr3fHm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 6.777580280647542,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AMT",
    "name": "Amazy Move Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf625069dce62df95b4910f83446954b871f0fc4f",
    "decimals": 18,
    "image": "https://rango.vip/i/rmG3Ka",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.006586656273408994,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WGC",
    "name": "Wild Goat Coin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfb18511f1590a494360069f3640c27d55c2b5290",
    "decimals": 6,
    "image": "https://rango.vip/i/PI7P2X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00003876,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WGC",
    "name": "Wild Goat Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4Eb7ReeT936hX25KMBfYtyP1RWDdd9iixEwGJroACeLC",
    "decimals": 6,
    "image": "https://rango.vip/i/FYC57V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003876,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WGC",
    "name": "Wild Goat Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AfPeB1BDUotBeNoLv82XRDCNQcdAA1mqis3YC5SMTe7a",
    "decimals": 6,
    "image": "https://rango.vip/i/PisauB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000205,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WGC",
    "name": "Wild Goat Coin",
    "isPopular": false,
    "chainId": "137",
    "address": "0x382586651f043cbdec7bb586e367d77b26d7d149",
    "decimals": 6,
    "image": "https://rango.vip/i/ruEecY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000205,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EOSDAC",
    "name": "eosDAC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7e9e431a0b8c4d532c745b1043c7fa29a48d4fba",
    "decimals": 18,
    "image": "https://rango.vip/i/U23Ay7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00023667,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MORE",
    "name": "More Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xd9d90f882cddd6063959a9d837b05cb748718a05",
    "decimals": 18,
    "image": "https://rango.vip/i/ND2nZM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00104296,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEMD",
    "name": "Lemond",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf45f6c8bb3d77ea762175b8f7ca4d251941649fa",
    "decimals": 18,
    "image": "https://rango.vip/i/C5bw82",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002693,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PLD",
    "name": "Plutonian DAO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2cJgFtnqjaoiu9fKVX3fny4Z4pRzuaqfJ3PBTMk2D9ur",
    "decimals": 6,
    "image": "https://rango.vip/i/eTR2yL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00325127,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIX",
    "name": "Bibox",
    "isPopular": false,
    "chainId": "1",
    "address": "0x009c43b42aefac590c719e971020575974122803",
    "decimals": 18,
    "image": "https://rango.vip/i/TQa6jv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00360961,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVERMOON",
    "name": "EverMoon ERC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4ad434b8cdc3aa5ac97932d6bd18b5d313ab0f6f",
    "decimals": 18,
    "image": "https://rango.vip/i/blQeFe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008769,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FUTURE",
    "name": "Future",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FUTURETnhzFApq2TiZiNbWLQDXMx4nWNpFtmvTf11pMy",
    "decimals": 9,
    "image": "https://rango.vip/i/YIAUEH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00035849,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRESCO",
    "name": "Fresco",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9eb6f357f040be1d2a3d6b4ba750d1ab8a4233c",
    "decimals": 9,
    "image": "https://rango.vip/i/N1GrUE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.646e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "POLA",
    "name": "Pola On Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x76e7447bafa3f0acafc9692629b1d1bc937ca15d",
    "decimals": 18,
    "image": "https://rango.vip/i/HI1yS0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00005146,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POLA",
    "name": "Polaris Share",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc691bc298a304d591ad9b352c7a8d216de9f2ced",
    "decimals": 18,
    "image": "https://rango.vip/i/XTHwwR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02156501,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMON",
    "name": "Ethermon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd6a5ab46ead26f49b03bbb1f9eb1ad5c1767974a",
    "decimals": 18,
    "image": "https://rango.vip/i/Z1FGZw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00104844,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EMON",
    "name": "Ethermon Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd6a5ab46ead26f49b03bbb1f9eb1ad5c1767974a",
    "decimals": 18,
    "image": "https://rango.vip/i/SGKPWI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00104844,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DINO",
    "name": "Coding Dino",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x85e90a5430af45776548adb82ee4cd9e33b08077",
    "decimals": 18,
    "image": "https://rango.vip/i/FPCFZt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0000516,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DINO",
    "name": "DINO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6Y7LbYB3tfGBG6CSkyssoxdtHb77AEMTRVXe8JUJRwZ7",
    "decimals": 6,
    "image": "https://rango.vip/i/Pac2S2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00026028,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DINO",
    "name": "DinoSwap",
    "isPopular": false,
    "chainId": "137",
    "address": "0xaa9654becca45b5bdfa5ac646c939c62b527d394",
    "decimals": 18,
    "image": "https://rango.vip/i/h3SboI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00055714,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DINO",
    "name": "DinoLFG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x49642110b712c1fd7261bc074105e9e44676c68f",
    "decimals": 18,
    "image": "https://rango.vip/i/eyKEgI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00408119,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "FERT",
    "name": "Chikn Fert",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x9c846d808a41328a209e235b5e3c4e626dab169e",
    "decimals": 18,
    "image": "https://rango.vip/i/FMofT8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00011685,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNC",
    "name": "Conic Finance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ae380f0272e2162340a5bb646c354271c0f5cfc",
    "decimals": 18,
    "image": "https://rango.vip/i/q1KTcR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.170092,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SEED",
    "name": "Garden",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x86f65121804d2cdbef79f9f072d4e0c2eebabc08",
    "decimals": 18,
    "image": "https://rango.vip/i/Yru9gW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.106553,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SEED",
    "name": "SEED",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5eed99d066a8caf10f3e4327c1b3d8b673485eed",
    "decimals": 18,
    "image": "https://rango.vip/i/Yru9gW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.106553,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SEED",
    "name": "Bonsai3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31ea904a7eca45122890deb8da3473a2081bc9d1",
    "decimals": 18,
    "image": "https://rango.vip/i/J8grwl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00217317,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "OPN",
    "name": "OPEN Ticketing Ecosystem",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9a6d24c02ec35ad970287ee8296d4d6552a31dbe",
    "decimals": 18,
    "image": "https://rango.vip/i/ZeCYet",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00063846,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OPN",
    "name": "Open Ticketing Ecosystem",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "J1YnyKzmxBwFkPftvZexnHTm4Am7JnmWydhxtXdwEmMv",
    "decimals": 8,
    "image": "https://rango.vip/i/Pe0SHc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00063846,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPN",
    "name": "OPEN Ticketing Ecosystem",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc28eb2250d1ae32c7e74cfb6d6b86afc9beb6509",
    "decimals": 18,
    "image": "https://rango.vip/i/ZeCYet",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00063846,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "SSS",
    "name": "Super Sushi Samurai",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xfd4d19f9fbb9f730c3c88a21755832bd2455144e",
    "decimals": 18,
    "image": "https://rango.vip/i/cJLTMk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 2.886e-9,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RIN",
    "name": "Aldrin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp",
    "decimals": 9,
    "image": "https://rango.vip/i/4Fg2xX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00606261,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUR",
    "name": "Aurix",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e98e5401adcb0d76f4debfc3d794b3031f48790",
    "decimals": 18,
    "image": "https://rango.vip/i/hcxZAr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.86267,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MVERSE",
    "name": "MindVerse",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf67366e83cc9b115ef8cca93baed1f03e6d3ca9a",
    "decimals": 18,
    "image": "https://rango.vip/i/NYyiby",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01344584,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "NCASH",
    "name": "NitroNetwork",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc69eba65e87889f0805db717af06797055a0ba07",
    "decimals": 18,
    "image": "https://rango.vip/i/VMlP9Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0000023091341104222988,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NCASH",
    "name": "Nucleus Vision",
    "isPopular": false,
    "chainId": "1",
    "address": "0x809826cceab68c387726af962713b64cb5cb3cca",
    "decimals": 18,
    "image": "https://rango.vip/i/arlb8A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010715,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SPHERE",
    "name": "Sphere Finance",
    "isPopular": false,
    "chainId": "137",
    "address": "0x62f594339830b90ae4c084ae7d223ffafd9658a7",
    "decimals": 18,
    "image": "https://rango.vip/i/3qeWlP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00038432,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APAD",
    "name": "AlphPad",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5aa7b9be58d4001a7065718641ce7b121b41ef9b",
    "decimals": 18,
    "image": "https://rango.vip/i/WOiVYW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04787667,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REFI",
    "name": "ReFi Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa4bb712b4ea05e74a9590ec550bd922cd857afcb",
    "decimals": 18,
    "image": "https://rango.vip/i/8eQ3Bg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00099485,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TSL",
    "name": "Tesla TSL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03806ce5ef69bd9780edfb04c29da1f23db96294",
    "decimals": 18,
    "image": "https://rango.vip/i/pWEv00",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010374,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "COS",
    "name": "Contentos",
    "isPopular": false,
    "chainId": "56",
    "address": "0x96dd399f9c3afda1f194182f71600f1b65946501",
    "decimals": 18,
    "image": "https://rango.vip/i/ApSCQQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.007034581896751784,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COS",
    "name": "Cats Of Sol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3obNTknsf4hq5pNh3GDPEhyCB9WTKJJzM2PcqfKgLmBu",
    "decimals": 9,
    "image": "https://rango.vip/i/Mn10CH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00071401,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SB",
    "name": "Solbank",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8twuNzMszqWeFbDErwtf4gw13E6MUS4Hsdx5mi3aqXAM",
    "decimals": 9,
    "image": "https://rango.vip/i/tgNBzM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 85.13,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "MARS",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/573fcd90facee750f55a8864ef7d38265f07e5a9273fa0e8dafd39951332b580",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/MARS.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.03227173208958976,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "MARS",
    "name": "Mars",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "ibc/281fee887cdf71eb9c1fefc554822dcb06be4e8a8bff944ed64e3d03437e9384",
    "decimals": 6,
    "image": "https://rango.vip/i/FSoOrH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 0.03227173208958976,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MARS",
    "name": "MarsToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66c0dded8433c9ea86c8cf91237b14e10b4d70b7",
    "decimals": 18,
    "image": "https://rango.vip/i/EyL0Td",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.07623509712675985,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MARS",
    "name": "GATEWAY TO MARS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3d2b3e23855001508e460a6dbe9f9e3116201af",
    "decimals": 9,
    "image": "https://rango.vip/i/DBtOB2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.9955e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MARS",
    "symbol": "MARS",
    "name": null,
    "isPopular": true,
    "chainId": "mars-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/iyE9i1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MARS/icon.svg",
    "usdPrice": 0.0323033242294972,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EGAME",
    "name": "Every Game",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62d3c05b9c3d916fbc111819bbd3cee52906c1ae",
    "decimals": 18,
    "image": "https://rango.vip/i/FZJc97",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000837,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "FIRE",
    "name": "FIRE",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xfcc6ce74f4cd7edef0c5429bb99d38a3608043a5",
    "decimals": 18,
    "image": "https://rango.vip/i/dkuzBi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.05,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FIRE",
    "name": "Fire Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf921ae2dac5fa128dc0f6168bf153ea0943d2d43",
    "decimals": 8,
    "image": "https://rango.vip/i/SxVHai",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00056747,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "FIRE",
    "name": "FIRE",
    "isPopular": false,
    "chainId": "128",
    "address": "0xe97c7f83ec91e29569f1a08de95ad3bb0e8b6b3a",
    "decimals": 18,
    "image": "https://rango.vip/i/UgM3r2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1.0495856798580734,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WIZ",
    "name": "Wizard World WIZ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa3889a5f5f84f4296ff734081ad6d333838a56b5",
    "decimals": 9,
    "image": "https://rango.vip/i/qGsXGd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00080168,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCDN",
    "name": "BlockCDN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e797ce986c3cff4472f7d38d5c4aba55dfefe40",
    "decimals": 15,
    "image": "https://rango.vip/i/mR4UFn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00022249,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AXN",
    "name": "Axion",
    "isPopular": false,
    "chainId": "137",
    "address": "0x839f1a22a59eaaf26c85958712ab32f80fea23d9",
    "decimals": 18,
    "image": "https://rango.vip/i/oQu5gK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000112,
    "supportedSwappers": [
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTK",
    "name": "MetaToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e7375c46a0552520ce6c976dc268ae1b341f45f",
    "decimals": 18,
    "image": "https://rango.vip/i/QZiSIW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03430548,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUC",
    "name": "Auctus Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc12d099be31567add4e4e4d0d45691c3f58f5663",
    "decimals": 18,
    "image": "https://rango.vip/i/49lUsw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00067568,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AUC",
    "name": "Auctus Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xea986d33ef8a20a96120ecc44dbdd49830192043",
    "decimals": 18,
    "image": "https://rango.vip/i/YkFCvu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00067568,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DERP",
    "name": "Derp",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5dfc78c4d073fd343bc6661668948178522a0de5",
    "decimals": 18,
    "image": "https://rango.vip/i/u5bNeO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.694e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DERP",
    "name": "DERP Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x878fcc2bdcccff8c56812607b9a58f29b274c4f0",
    "decimals": 18,
    "image": "https://rango.vip/i/rQibqV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000149,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QWLA",
    "name": "Qawalla",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1d96fd43ee07aa79f8fd003cbdf404fb5ce41ad2",
    "decimals": 18,
    "image": "https://rango.vip/i/nNsPiR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.244473,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "LUM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/8a34af0c1943fd0dfcde9adbf0b2c9959c45e87e6088ea2fc6adacd59261b8a2",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/LUM.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00004980778564711276,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "IOTA",
    "symbol": "LUM",
    "name": "Lum",
    "isPopular": false,
    "chainId": "8822",
    "address": "0x34a85ddc4e30818e44e6f4a8ee39d8cba9a60fb3",
    "decimals": 18,
    "image": "https://rango.vip/i/1vAPmE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/IOTA/icon.svg",
    "usdPrice": 0.093547238276387,
    "supportedSwappers": [
      "IOTAMagicSeaSwap"
    ]
  },
  {
    "blockchain": "LUMNETWORK",
    "symbol": "LUM",
    "name": null,
    "isPopular": true,
    "chainId": "lum-network-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/NVbNCJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LUMNETWORK/icon.svg",
    "usdPrice": 0.000049666076052956,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CROW",
    "name": "Crow",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa2ba423875baa1c74abe77df0b6ac655ce151e6",
    "decimals": 18,
    "image": "https://rango.vip/i/x0eXKK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001759,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CROW",
    "name": "CROW",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6325cf7b3b645de6355e37e0e88f6ff0030f9e97",
    "decimals": 18,
    "image": "https://rango.vip/i/jftfGm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.717289,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EFT",
    "name": "Everflow Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf86cfce1e746456135d7face48c2916d7d3cb676",
    "decimals": 18,
    "image": "https://rango.vip/i/gBBixa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001086,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CENT",
    "name": "Centaurify",
    "isPopular": false,
    "chainId": "1",
    "address": "0x08ba718f288c3b12b01146816bef9fa03cc635bc",
    "decimals": 18,
    "image": "https://rango.vip/i/TUvRsA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000852,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "PIKA",
    "name": "Pika Protocol",
    "isPopular": false,
    "chainId": "10",
    "address": "0x9a601c5bb360811d96a23689066af316a30c3027",
    "decimals": 18,
    "image": "https://rango.vip/i/BJDYWi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.383758,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIKA",
    "name": "PIKA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60f5672a271c7e39e787427a18353ba59a4a3578",
    "decimals": 18,
    "image": "https://rango.vip/i/klpJPN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.409e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIKA",
    "name": "PIKAMOON",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1e64bcc904cfdc19d0faba155a9edc69b4bcdae",
    "decimals": 9,
    "image": "https://rango.vip/i/MnbEIs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00029553,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIKA",
    "name": "Pikachu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa9d54f37ebb99f83b603cc95fc1a5f3907aaccfd",
    "decimals": 18,
    "image": "https://rango.vip/i/Fz35aS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.9809e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ODGN",
    "name": "OrdiGen",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3cc3076cb304494775b3193ef1aa080ba6bf962",
    "decimals": 18,
    "image": "https://rango.vip/i/gHHTCQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018484,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DXD",
    "name": "DXdao",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1d65e8fb6e87b60feccbc582f7f97804b725521",
    "decimals": 18,
    "image": "https://rango.vip/i/sHQwW7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 128.6,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DXD",
    "name": "DXdao",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc3ae0333f0f34aa734d5493276223d95b8f9cb37",
    "decimals": 18,
    "image": "https://rango.vip/i/yD506n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 128.6,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIDY",
    "name": "VIDY",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc77b230f31b517f1ef362e59c173c2be6540b5e8",
    "decimals": 18,
    "image": "https://rango.vip/i/KqNxcA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001377,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "PACT",
    "name": "impactMarket",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x46c9757c5497c5b1f2eb73ae79b6b67d119b0b58",
    "decimals": 18,
    "image": "https://rango.vip/i/nGfC5z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.00003973,
    "supportedSwappers": [
      "UbeSwap",
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PACT",
    "name": "impactMarket  OLD ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdcfa8c46ec015d4bf13d6be492cb7a8eb4580899",
    "decimals": 18,
    "image": "https://rango.vip/i/wNycJI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003973,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "HOKK",
    "name": "Hokkaidu Inu",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xd857af86a2c5b4f46fc7cb8032bd4f5625577eeb",
    "decimals": 9,
    "image": "https://rango.vip/i/JnXiaJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 4.081e-12,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOKK",
    "name": "Hokkaidu Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc40af1e4fecfa05ce6bab79dcd8b373d2e436c4e",
    "decimals": 9,
    "image": "https://rango.vip/i/oSrkCE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.720602033631e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOKK",
    "name": "Hokkaido Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8",
    "decimals": 18,
    "image": "https://rango.vip/i/P94B0L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014021,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HOKK",
    "name": "Hokkaido Inu",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8",
    "decimals": 18,
    "image": "https://rango.vip/i/iXHKzP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00014021,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RCKT",
    "name": "RocketSwap",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6653dd4b92a0e5bf8ae570a98906d9d6fd2eec09",
    "decimals": 18,
    "image": "https://rango.vip/i/8uDZzm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.055241,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "QI",
    "name": "BENQI",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x8729438eb15e2c8b576fcc6aecda6a148776c0f5",
    "decimals": 18,
    "image": "https://rango.vip/i/vIhrIa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01288923,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "Bridgers",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "QI",
    "name": "Qi Dao",
    "isPopular": false,
    "chainId": "137",
    "address": "0x580a84c73811e1839f75d86d75d88cca0c241ff4",
    "decimals": 18,
    "image": "https://rango.vip/i/8vevb4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.012927335955642393,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QI",
    "name": "Qi Dao",
    "isPopular": false,
    "chainId": "1",
    "address": "0x559b7bfc48a5274754b08819f75c5f27af53d53b",
    "decimals": 18,
    "image": "https://rango.vip/i/ytIjO6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01882604,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "QI",
    "name": "BENQI",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8729438eb15e2c8b576fcc6aecda6a148776c0f5",
    "decimals": 18,
    "image": "https://rango.vip/i/PwbKO2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.012927335955642393,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "QI",
    "name": "Qi Dao Protocol",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xa56f9a54880afbc30cf29bb66d2d9adcdcaeadd6",
    "decimals": 18,
    "image": "https://rango.vip/i/slzuVj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.012927335955642393,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OST",
    "name": "OST",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c4e8f2d746113d0696ce89b35f0d8bf88e0aeca",
    "decimals": 18,
    "image": "https://rango.vip/i/REF4vA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017584,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ZEFI",
    "name": "ZCore Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0288d3e353fe2299f11ea2c2e1696b4a648ecc07",
    "decimals": 18,
    "image": "https://rango.vip/i/7WDqbk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00239419,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FTX",
    "name": "FintruX Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd559f20296ff4895da39b5bd9add54b442596a61",
    "decimals": 18,
    "image": "https://rango.vip/i/6VbAVw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040686,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FTX",
    "name": "HairyPlotterFTX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb1a822ce8c799b0777ed1f260113819247e1bf26",
    "decimals": 18,
    "image": "https://rango.vip/i/YFPRNw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000713,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HMKR",
    "name": "Hitmakr",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3300b02efa180c99a2f61f4731665b51e4e254c4",
    "decimals": 9,
    "image": "https://rango.vip/i/sXJw7c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00028323,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ADDY",
    "name": "Adamant",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539",
    "decimals": 18,
    "image": "https://rango.vip/i/22ihxR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02593353,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ADDY",
    "name": "Addy.bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x828eb9a1934c143808e325754c54ae2c1741ec49",
    "decimals": 18,
    "image": "https://rango.vip/i/twhJRQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033146,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BASED",
    "name": "BASEDChad",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x17931cfc3217261ce0fa21bb066633c463ed8634",
    "decimals": 18,
    "image": "https://rango.vip/i/fW2UbO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00013705,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BASED",
    "name": "Basedmilio",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x28e29ec91db66733a94ee8e3b86a6199117baf99",
    "decimals": 18,
    "image": "https://rango.vip/i/4OEyEZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00084211,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BASED",
    "name": "based.markets",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xba5e6fa2f33f3955f0cef50c63dcc84861eab663",
    "decimals": 18,
    "image": "https://rango.vip/i/wIXfYQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.440182,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GOO",
    "name": "Silly Goose",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GRFKaABC518SqXMvBpAVYUZtVT3Nj4mYk7E7xU4gA5Rg",
    "decimals": 9,
    "image": "https://rango.vip/i/FclGaL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00080246,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GOO",
    "name": "Goo",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6f3cc27e17a0f2e52d8e7693ff0d892cea1854bf",
    "decimals": 9,
    "image": "https://rango.vip/i/iP5QDq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000265,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELS",
    "name": "Ethlas",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb575c45004bd7b61c6a8d3446a62a05a6ce18d8",
    "decimals": 18,
    "image": "https://rango.vip/i/5rfLqg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01704489,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BTC",
    "symbol": "BTC",
    "name": "BTC",
    "isPopular": true,
    "chainId": "",
    "address": null,
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/BTC.B.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BTC/icon.svg",
    "usdPrice": 55781.357310689986,
    "supportedSwappers": [
      "ThorChain",
      "Chainflip",
      "ThorChainStreamingSwap",
      "MayaProtocol"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "BTC",
    "name": "Wrapped BTC",
    "isPopular": false,
    "chainId": "324",
    "address": "0xbbeb516fb02a01611cbbe0453fe3c580d7281011",
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/BTC.B.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 55583,
    "supportedSwappers": [
      "OrbiterV2"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "BTC",
    "name": "Wrapped BTC",
    "isPopular": false,
    "chainId": "59144",
    "address": "0x3aab2285ddcddad8edf438c1bab47e1a9d05a9b4",
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/BTC.B.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 55781.357310689986,
    "supportedSwappers": [
      "OrbiterV2"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTC",
    "name": "BlackrockTradingCurrency",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd6323a83b613f668687014e8a5852079494fb68",
    "decimals": 18,
    "image": "https://rango.vip/i/9Tsw5w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0359524,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTC",
    "name": "bitcoin  2015 Wrapper   Meme ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0bc84e95864bdfdcd1ccfb8a3aa522e79ca1410",
    "decimals": 8,
    "image": "https://rango.vip/i/E5KIa5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04740054,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BTC",
    "name": "Wrapped BTC",
    "isPopular": true,
    "chainId": "10",
    "address": "0x68f180fcce6836688e9084f035309e29bf0a2095",
    "decimals": 8,
    "image": "https://rango.vip/i/5kTSgT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 55625,
    "supportedSwappers": [
      "OrbiterV2"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "BTC",
    "name": "Bitcoin",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TN3W4H6rK2ce4vX9YnFQHwKENnHjoxb3m9",
    "decimals": 8,
    "image": "https://rango.vip/i/VTDf13",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 55504,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WGR",
    "name": "Wagerr",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc237868a9c5729bdf3173dddacaa336a0a5bb6e0",
    "decimals": 8,
    "image": "https://rango.vip/i/Hr8MQR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00168703,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DILI",
    "name": "D Community",
    "isPopular": false,
    "chainId": "1",
    "address": "0x37f74e99794853777a10ea1dc08a64c86958f06a",
    "decimals": 18,
    "image": "https://rango.vip/i/LBMPrH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000785,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SLIME",
    "name": "Snail Trail",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x5a15bdcf9a3a8e799fa4381e666466a516f2d9c8",
    "decimals": 18,
    "image": "https://rango.vip/i/oks8Vk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00105901,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PRINT",
    "name": "Print Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HKYX2jvwkdjbkbSdirAiQHqTCPQa3jD2DVRkAFHgFXXT",
    "decimals": 6,
    "image": "https://rango.vip/i/9EK6mj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00012975,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRINT",
    "name": "Print Mining",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64a5b80089c0fb4858a8c2ca9c2988a484539fd8",
    "decimals": 18,
    "image": "https://rango.vip/i/ydGhaY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03637509,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BDT",
    "name": "BlackDragon",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x21ccbc5e7f353ec43b2f5b1fb12c3e9d89d30dca",
    "decimals": 18,
    "image": "https://rango.vip/i/k3IJC0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.19,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BDT",
    "name": "BitDelta",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcac1277aa6ecb68b84fad070910d37029e810b79",
    "decimals": 18,
    "image": "https://rango.vip/i/5xeH7W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000023893177336197135,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTRU",
    "name": "Biblical Truth",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc70cbb442d5c115ee1497d22b421b1f9bd9f3da",
    "decimals": 18,
    "image": "https://rango.vip/i/NIzX3T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000395,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "KALA",
    "name": "Kalax",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x2f67f59b3629bf24962290db9ede0cd4127e606d",
    "decimals": 18,
    "image": "https://rango.vip/i/eF35up",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.094418,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NAT",
    "name": "NatCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x22c5ff2999bd728eaa91f8a25e9515adec2ee20a",
    "decimals": 18,
    "image": "https://rango.vip/i/6vrVh1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.60158e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIG",
    "name": "Big Eyes",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc8de43bfe33ff496fa14c270d9cb29bda196b9b5",
    "decimals": 18,
    "image": "https://rango.vip/i/pv03nm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.49153e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SANI",
    "name": "Sanin Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4521c9ad6a3d4230803ab752ed238be11f8b342f",
    "decimals": 18,
    "image": "https://rango.vip/i/sTawaw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.51597e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "ROCK",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/0835781ef3f3add053874323ab660c75b50b18b16733cab783ca6bbd78244edf",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0560655658040064,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ROCK",
    "name": "Just a Black Rock on Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xece7b98bd817ee5b1f2f536daf34d0b6af8bb542",
    "decimals": 18,
    "image": "https://rango.vip/i/BBzZSR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.000032,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROCK",
    "name": "ROCK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85516e8862ab543ea15972b7809256efec0696ea",
    "decimals": 18,
    "image": "https://rango.vip/i/cvx9Aw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000162,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROCK",
    "name": "EtherRock404",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb5c457ddb4ce3312a6c5a2b056a1652bd542a208",
    "decimals": 18,
    "image": "https://rango.vip/i/Dqx0rq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 525.44,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROCK",
    "name": "Rock",
    "isPopular": false,
    "chainId": "1",
    "address": "0x724af984b63fd53fdedb5ded17063001e3afc3e5",
    "decimals": 18,
    "image": "https://rango.vip/i/3zwN4v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.056364,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ROCK",
    "name": "Bedrock",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc3387e4285e9f80a7cfdf02b4ac6cdf2476a528a",
    "decimals": 18,
    "image": "https://rango.vip/i/uUweDz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0005320280801162088,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LBLOCK",
    "name": "Lucky Block",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2b867efd2de4ad2b583ca0cb3df9c4040ef4d329",
    "decimals": 9,
    "image": "https://rango.vip/i/sJ0J1M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000018,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "TES",
    "name": "Titan Trading Token",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x87e154e86fb691ab8a27116e93ed8d54e2b8c18c",
    "decimals": 18,
    "image": "https://rango.vip/i/0yA916",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.216199,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SILVER",
    "name": "Silver",
    "isPopular": false,
    "chainId": "137",
    "address": "0xbc7cb585346f4f59d07121bb9ed7358076243539",
    "decimals": 18,
    "image": "https://rango.vip/i/YV2jY6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 7.581669768e-14,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SILVER",
    "name": "SILVER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60a26c05c5372dcded66940d2b56076bce925152",
    "decimals": 9,
    "image": "https://rango.vip/i/fyAJrq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.4625e-14,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHINGON",
    "name": "Mexico Chingon",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BpFmEt9efz3ekb7g35DuWiywX3o9nfsRANX76D1g6fNk",
    "decimals": 6,
    "image": "https://rango.vip/i/6GWB8r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0007025,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MDA",
    "name": "Moeda Loyalty Points",
    "isPopular": false,
    "chainId": "1",
    "address": "0x51db5ad35c671a87207d88fc11d593ac0c8415bd",
    "decimals": 18,
    "image": "https://rango.vip/i/NwCDtA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00909675,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MDA",
    "name": "Moeda Loyalty Points",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd72aa9e1cddc2f6d6e0444580002170fba1f8eed",
    "decimals": 18,
    "image": "https://rango.vip/i/MA2nYA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.009097402249347025,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POT",
    "name": "Positron",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9iz45n44TQUPyoRymdZXEunqvZUksZyhzS6zQ7sLMadj",
    "decimals": 9,
    "image": "https://rango.vip/i/0noQOJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00374892949835729,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PTC",
    "name": "Patriots Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1776c8ba4883b7e8f710e8f7b68646788340c177",
    "decimals": 18,
    "image": "https://rango.vip/i/uvxybP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01120636,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "PTC",
    "name": "Particle Trade",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xa027a3a04b44f79560153234e999b17c88e22db9",
    "decimals": 18,
    "image": "https://rango.vip/i/50A0Iw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.01504021,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DUB",
    "name": "The Dub Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x30457a1ab7cd796d6e55e4e5ba12e09f2283e856",
    "decimals": 18,
    "image": "https://rango.vip/i/Z92ueh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0021913085850332136,
    "supportedSwappers": [
      "AlienBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUB",
    "name": "DUBX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x75ce16d11b83605aa039d40d7d846ff23064fb65",
    "decimals": 9,
    "image": "https://rango.vip/i/JWKwG9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.55063e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MINDS",
    "name": "Minds",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb26631c6dda06ad89b93c71400d25692de89c068",
    "decimals": 18,
    "image": "https://rango.vip/i/TEzj6I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02316926,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ICC",
    "name": "Indian Call Center",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EQGG5muKhviWmWJwy4Fi9TeeNpJUX7RpHAwkQMnTAyAj",
    "decimals": 9,
    "image": "https://rango.vip/i/GNGbk0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00005755,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RENZEC",
    "name": "renZEC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2",
    "decimals": 8,
    "image": "https://rango.vip/i/q05n59",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 45.650768626901474,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CUB",
    "name": "Cub Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x50d809c74e0b8e49e7b4c65bb3109abe3ff4c1c1",
    "decimals": 18,
    "image": "https://rango.vip/i/QSIgNk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00153711,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GR",
    "name": "GROM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce593a29905951e8fc579bc092eca72577da575c",
    "decimals": 6,
    "image": "https://rango.vip/i/CoiGEc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0007901,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOBER",
    "name": "Solabrador",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2ZE6hSL36e44wP168YMnxrbi1CSCFuD2BJm7NoNHfsmN",
    "decimals": 9,
    "image": "https://rango.vip/i/nJw9Qi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001569667695646025,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "LOST",
    "name": "LostToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x449674b82f05d498e126dd6615a1057a9c088f2c",
    "decimals": 18,
    "image": "https://rango.vip/i/uKNhkK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00196722,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LOST",
    "name": "Lost",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4riQPy1yXQ2d1FfzgLy9eJy6yoUdZuQkMj7JsVKr4bGu",
    "decimals": 5,
    "image": "https://rango.vip/i/F581dv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000497,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BSP",
    "name": "BallSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe51b8ab09008285a0380dd2680cd9dd5e13924d3",
    "decimals": 18,
    "image": "https://rango.vip/i/vrt0ml",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.20453e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "G$",
    "name": "Good Dollar",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x62b8b11039fcfe5ab0c56e502b1c372a3d2a9c7a",
    "decimals": 18,
    "image": "https://rango.vip/i/Hc7Hho",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.00002263,
    "supportedSwappers": [
      "UbeSwap",
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "G$",
    "name": "GoodDollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x67c5870b4a41d4ebef24d2456547a03f1f3e094b",
    "decimals": 2,
    "image": "https://rango.vip/i/iloK1y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002263,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WIFSA",
    "name": "dogwifsaudihat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6630e3a2ec1e7e0a2f9f1d2289a9a89b0551683a",
    "decimals": 18,
    "image": "https://rango.vip/i/kTIft0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003887,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WMOXY",
    "name": "Wrapped MOXY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x695afdb42edff97af470a15920a66df81a234c0e",
    "decimals": 18,
    "image": "https://rango.vip/i/a4I0Mm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00229625,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "QBT",
    "name": "Qubit Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x17b7163cf1dbd286e262ddc68b553d899b93f526",
    "decimals": 18,
    "image": "https://rango.vip/i/YZ6jkL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0001171,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CSTR",
    "name": "CoreStarter",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G7uYedVqFy97mzjygebnmmaMUVxWHFhNZotY6Zzsprvf",
    "decimals": 9,
    "image": "https://rango.vip/i/PjunW6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00051429,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RATING",
    "name": "DPRating",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe8663a64a96169ff4d95b4299e7ae9a76b905b31",
    "decimals": 8,
    "image": "https://rango.vip/i/QKj2JF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006953,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CATO",
    "name": "CATO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB",
    "decimals": 9,
    "image": "https://rango.vip/i/8QA3mL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00114846,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "HZN",
    "name": "Horizon",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x081ad949defe648774c3b8debe0e4f28a80716dc",
    "decimals": 18,
    "image": "https://rango.vip/i/msPhIN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.013726080818282724,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HZN",
    "name": "Horizon Protocol",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc0eff7749b125444953ef89682201fb8c6a917cd",
    "decimals": 18,
    "image": "https://rango.vip/i/fEgjTQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01373247,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VPN",
    "name": "0xVPN org",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf898bae008cd85046431ab0a75f00689d6aa1b1c",
    "decimals": 18,
    "image": "https://rango.vip/i/ebs6cV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027107,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FTW",
    "name": "Apollo FTW",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc188b5dbb155b6ea693d46d98bf60b8482939b9",
    "decimals": 18,
    "image": "https://rango.vip/i/YUtEAx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00110396,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABYPEPE",
    "name": "Baby Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69babe9811cc86dcfc3b8f9a14de6470dd18eda4",
    "decimals": 9,
    "image": "https://rango.vip/i/3E7Kv2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.99936e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABYPEPE",
    "name": "BabyPepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5c559f3ee9a81da83e069c0093471cb05d84052a",
    "decimals": 18,
    "image": "https://rango.vip/i/5l72Bq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.598e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABYPEPE",
    "name": "Baby Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69cd13d248830602a60b1f20ab11f5049385877d",
    "decimals": 18,
    "image": "https://rango.vip/i/mjUBpA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008433,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPEGA",
    "name": "Pepega",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9634bdb20bbab07bb52d279fa6e0c53ccc89c879",
    "decimals": 9,
    "image": "https://rango.vip/i/RQieeg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.49941e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JBOT",
    "name": "JACKBOT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a97e00b48d56bd5e0502e1a2a8c036a040e1b99",
    "decimals": 9,
    "image": "https://rango.vip/i/o35sD6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000185,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DONGO",
    "name": "Dongo AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c213ae332274e6314bf4cf989604e7f61162967",
    "decimals": 9,
    "image": "https://rango.vip/i/2g3HD8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018217,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HUNNY",
    "name": "Hunny Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x565b72163f17849832a692a3c5928cc502f46d69",
    "decimals": 18,
    "image": "https://rango.vip/i/b2rzcc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00806349,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$BAYSE",
    "name": "coynbayse",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9DgMYGtyeNzGchoSJWLZsueyTYa3v9bSpkzzfRhYJKDo",
    "decimals": 9,
    "image": "https://rango.vip/i/j5R2dk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MEH",
    "name": "Meh",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xa999542c71febba77602fbc2f784ba9ba0c850f6",
    "decimals": 18,
    "image": "https://rango.vip/i/KdMvMv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2.627e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLCAT",
    "name": "CatSolHat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E99fN4tCRb1tQphXK1DU7prXji6hMzxETyPNJro19Fwz",
    "decimals": 6,
    "image": "https://rango.vip/i/c9rIhy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01541356,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIDI",
    "name": "Aidi Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe3e24b4ea87935e15bbe99a24e9ace9998e4614d",
    "decimals": 18,
    "image": "https://rango.vip/i/KLb5Vi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00028713,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SQUOGE",
    "name": "DogeSquatch",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x589c8e8fe013133b41abf546c819787a75688690",
    "decimals": 18,
    "image": "https://rango.vip/i/Tigkv6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00186517,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MEE",
    "name": "MEE Governance Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xeb7eab87837f4dad1bb80856db9e4506fc441f3d",
    "decimals": 18,
    "image": "https://rango.vip/i/GkXsAT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00664873,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HND",
    "name": "Hundred Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x10010078a54396f62c96df8532dc2b4847d47ed3",
    "decimals": 18,
    "image": "https://rango.vip/i/iWI2sp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0092258,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BABYTRUMP",
    "name": "BABYTRUMP (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6NbnHQKD2dh4jswTLmMCP7LnSh4Nh6y2cNgdQg2ny9zW",
    "decimals": 8,
    "image": "https://rango.vip/i/E5B6VW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02457092,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABYTRUMP",
    "name": "BABYTRUMP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x354c8cda7e3b737d360513a0dc5abcee8ee1cea3",
    "decimals": 18,
    "image": "https://rango.vip/i/y7ehp0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02457092,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DRAGY",
    "name": "Dragy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3JoKpqE4kowVTR3Po3gr3sxzLF6vKCvjGx48g8DRx9oN",
    "decimals": 9,
    "image": "https://rango.vip/i/ixrxvC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000196,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QUA",
    "name": "Quasacoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4daeb4a06f70f4b1a5c329115731fe4b89c0b227",
    "decimals": 18,
    "image": "https://rango.vip/i/H6VywU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0018605,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GMFAM",
    "name": "GMFAM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9da5e227e3fa4fc933b5f540be021e7ecc3fd81",
    "decimals": 18,
    "image": "https://rango.vip/i/bfSUs6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.302e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MOON",
    "name": "Moon on Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7b3eb29dbfb671e2b54f2525825bf4add8b936dc",
    "decimals": 18,
    "image": "https://rango.vip/i/fQP7jx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00008955,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOON",
    "name": "MoonMarket",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CcPYxgVbSmP9VgNRLdT7KkznZJQ7DyFJ2ZvSPwgdN62e",
    "decimals": 6,
    "image": "https://rango.vip/i/drta3f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001246,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOON",
    "name": "SolarMoon",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2kMpEJCZL8vEDZe7YPLMCS9Y3WKSAMedXBn7xHPvsWvi",
    "decimals": 5,
    "image": "https://rango.vip/i/aSLxfn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.10579851232019234,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOON",
    "name": "MoonToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68a3637ba6e75c0f66b61a42639c4e9fcd3d4824",
    "decimals": 18,
    "image": "https://rango.vip/i/SBAKTB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.10579851232019234,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SALT",
    "name": "Salt",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4156d3342d5c385a87d264f90653733592000581",
    "decimals": 8,
    "image": "https://rango.vip/i/CYFZTl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01417723,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "VINU",
    "name": "Vita Inu",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x72ccf64ee5e2c7629ee4eee3e6ad6990289178ae",
    "decimals": 18,
    "image": "https://rango.vip/i/Pz1ihv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 9.798e-9,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VINU",
    "name": "Viral Inu",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CgbJxXyaHeU8VsquBpySuFXA94b6LWXxioZ28wRr8fs9",
    "decimals": 6,
    "image": "https://rango.vip/i/v14eHB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000035,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VINU",
    "name": "Vita Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xafcdd4f666c84fed1d8bd825aa762e3714f652c9",
    "decimals": 18,
    "image": "https://rango.vip/i/5wfqao",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.798e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VINU",
    "name": "Vita Inu",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfebe8c1ed424dbf688551d4e2267e7a53698f0aa",
    "decimals": 18,
    "image": "https://rango.vip/i/XtMxCh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 9.798e-9,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INTX",
    "name": "INTEXCOIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7533d63a2558965472398ef473908e1320520ae2",
    "decimals": 9,
    "image": "https://rango.vip/i/MXxg3g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000015,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INTX",
    "name": "Intel X",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed1aecc815c00073ba6707b1cd4bd7f833da7a38",
    "decimals": 18,
    "image": "https://rango.vip/i/9clFHo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.23,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOOB",
    "name": "Book Of Bitcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5d791eebfc229c4fe976e8328ed2c261690cb34",
    "decimals": 9,
    "image": "https://rango.vip/i/Aa9M71",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000314,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WOOP",
    "name": "WOOP",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB",
    "decimals": 5,
    "image": "https://rango.vip/i/CbrTLx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00022531,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOOP",
    "name": "Woonkly Power",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaad483f97f13c6a20b9d05d07c397ce85c42c393",
    "decimals": 18,
    "image": "https://rango.vip/i/E4dcrE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00166371,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WOOP",
    "name": "Woonkly Power",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8b303d5bbfbbf46f1a4d9741e491e06986894e18",
    "decimals": 18,
    "image": "https://rango.vip/i/8H4JF3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00166371,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOLD",
    "name": "GOLDEN TOKEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34d6a0f5c2f5d0082141fe73d93b9dd00ca7ce11",
    "decimals": 18,
    "image": "https://rango.vip/i/1LKAoh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00399998,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOLD",
    "name": "GOLD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x089453742936dd35134383aee9d78bee63a69b01",
    "decimals": 18,
    "image": "https://rango.vip/i/lW8oMA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01395934,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOLD",
    "name": "GOLD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36737b4ac153c762d6a767056e1af7b17573a6b9",
    "decimals": 9,
    "image": "https://rango.vip/i/X0DROD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.14266e-13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOLD",
    "name": "Swords   Dungeons GOLD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa78bcbb74b822e74a847897d2d1d2d5ee2c76bd8",
    "decimals": 18,
    "image": "https://rango.vip/i/5kB7at",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.108342,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GOLD",
    "name": "CyberDragon Gold",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb3a6381070b1a15169dea646166ec0699fdaea79",
    "decimals": 18,
    "image": "https://rango.vip/i/IEtRHm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.43353e-13,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "GOLD",
    "name": "GOLD",
    "isPopular": false,
    "chainId": "128",
    "address": "0xfb371c8b99ba0b239e66e0a66bb6296b00dcd09f",
    "decimals": 18,
    "image": "https://rango.vip/i/pMBXV2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.013899035383067852,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WEC",
    "name": "Whole Earth Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6y8W5YwAuzostqrS4YDJufBvksosfSi47Pd8U4A5vrBC",
    "decimals": 9,
    "image": "https://rango.vip/i/oRNHgQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00065453,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MOLTEN",
    "name": "Molten",
    "isPopular": false,
    "chainId": "10",
    "address": "0x66e535e8d2ebf13f49f3d49e5c50395a97c137b1",
    "decimals": 18,
    "image": "https://rango.vip/i/QJcWo4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.511821,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOLTEN",
    "name": "Molten",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66e535e8d2ebf13f49f3d49e5c50395a97c137b1",
    "decimals": 18,
    "image": "https://rango.vip/i/0cX6Qq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.511821,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PHAUNTEM",
    "name": "Phauntem",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5veVHPNDsmSxDW4Abpb368wLHpo32LP3fqhfJHnkSSwo",
    "decimals": 9,
    "image": "https://rango.vip/i/sX3P6X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00199878,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAT",
    "name": "Wat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x636bd98fc13908e475f56d8a38a6e03616ec5563",
    "decimals": 18,
    "image": "https://rango.vip/i/yya3Ej",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000731,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MM",
    "name": "Million (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5647fe4281f8f6f01e84bce775ad4b828a7b8927",
    "decimals": 18,
    "image": "https://rango.vip/i/1kAkvK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.225794349486112,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MM",
    "name": "Million",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b4c7a5e3f0b99fcd83e9c089bddd6c7fce5c611",
    "decimals": 18,
    "image": "https://rango.vip/i/Vd0SjT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.24,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MM",
    "name": "MilliMeter",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3c221fe28c33814c28c822b631fd76047ef1a63",
    "decimals": 18,
    "image": "https://rango.vip/i/af5bW3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000247,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MM",
    "name": "MMToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa283aa7cfbb27ef0cfbcb2493dd9f4330e0fd304",
    "decimals": 18,
    "image": "https://rango.vip/i/2Ikw9i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.225794349486112,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MM",
    "name": "Million",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbf05279f9bf1ce69bbfed670813b7e431142afa4",
    "decimals": 18,
    "image": "https://rango.vip/i/SpbYsO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.24,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FWB",
    "name": "Friends With Benefits Pro",
    "isPopular": false,
    "chainId": "1",
    "address": "0x35bd01fc9d6d5d81ca9e055db88dc49aa2c699a8",
    "decimals": 18,
    "image": "https://rango.vip/i/3uPHPA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FWB",
    "name": "FWB network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x269877f972622d3c293fca595c65cf34b7f527ce",
    "decimals": 18,
    "image": "https://rango.vip/i/8YpzdW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.142676,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "LUNAR",
    "name": "Lunar Token",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x25e801eb75859ba4052c4ac4233cec0264eadf8c",
    "decimals": 18,
    "image": "https://rango.vip/i/IdgBNQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.00000798700312590571,
    "supportedSwappers": [
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUNAR",
    "name": "Lunar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a645ff83560231aab0f9c830ba108b06c94e34a",
    "decimals": 9,
    "image": "https://rango.vip/i/R7Izw5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00029885,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LUNAR",
    "name": "LunarHighway",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4e8a9d0bf525d78fd9e0c88710099f227f6924cf",
    "decimals": 9,
    "image": "https://rango.vip/i/HOG5Al",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00000798700312590571,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SIUUU",
    "name": "SIUUU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "siuwworrVnkjU3AE6Zj6uieFGC9JSXiGJ7YWxyScGxT",
    "decimals": 9,
    "image": "https://rango.vip/i/iz4URN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.004294713052955321,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SIUUU",
    "name": "crustieno renaldo",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BQ74oddoJCJKz9W4QaDzLFh1JvLbmXRCvdaC65P7anex",
    "decimals": 6,
    "image": "https://rango.vip/i/MLCHYx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.004294713052955321,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "GRAIN",
    "name": "Granary",
    "isPopular": false,
    "chainId": "10",
    "address": "0xfd389dc9533717239856190f42475d3f263a270d",
    "decimals": 18,
    "image": "https://rango.vip/i/SoOks2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00461893,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GRAIN",
    "name": "Granary",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x80bb30d62a16e1f2084deae84dc293531c3ac3a1",
    "decimals": 18,
    "image": "https://rango.vip/i/SoOks2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00461893,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRAIN",
    "name": "Granary",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf88baf18fab7e330fa0c4f83949e23f52fececce",
    "decimals": 18,
    "image": "https://rango.vip/i/SoOks2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00461893,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EGOD",
    "name": "EgodCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1d23bbef17f88fefc2ada631738e4c42e906a2e",
    "decimals": 9,
    "image": "https://rango.vip/i/gfThUU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.1335e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DEGEN",
    "name": "Degen  Base ",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x4ed4e862860bed51a9570b96d89af5e1b0efefed",
    "decimals": 18,
    "image": "https://rango.vip/i/T5kexW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00300191,
    "supportedSwappers": [
      "OneInchBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEGEN",
    "name": "DEGEN Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x126c121f99e1e211df2e5f8de2d96fa36647c855",
    "decimals": 18,
    "image": "https://rango.vip/i/swcOUG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.003022957983790998,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DEGEN",
    "name": "DEGEN Index",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xae6e3540e97b0b9ea8797b157b510e133afb6282",
    "decimals": 18,
    "image": "https://rango.vip/i/swcOUG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.003022957983790998,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DEGEN",
    "name": "DEGEN Index",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8a2870fb69a90000d6439b7adfb01d4ba383a415",
    "decimals": 18,
    "image": "https://rango.vip/i/8OPDKg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.003022957983790998,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEGEN",
    "name": "Degen",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe92b510007bd3ec0adb3d1fca338dd631e98de7",
    "decimals": 18,
    "image": "https://rango.vip/i/I5inJN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000189,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TOSHE",
    "name": "TOSHE",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x00e57ec29ef2ba7df07ad10573011647b2366f6d",
    "decimals": 18,
    "image": "https://rango.vip/i/a5rX7o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.235e-9,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SDOGE",
    "name": "SolDoge",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s",
    "decimals": 0,
    "image": "https://rango.vip/i/oTI3Ov",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001264,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "EMAX",
    "name": "EthereumMax",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x123389c2f0e9194d9ba98c21e63c375b67614108",
    "decimals": 18,
    "image": "https://rango.vip/i/lASmbe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2.48757e-10,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMAX",
    "name": "EthereumMax",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15874d65e649880c2614e7a480cb7c9a55787ff6",
    "decimals": 18,
    "image": "https://rango.vip/i/lbGoS1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.48757e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TIUSD",
    "name": "TiUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6eff556748ee452cbdaf31bcb8c76a28651509bd",
    "decimals": 18,
    "image": "https://rango.vip/i/Bzz5W5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.988439,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "VERSA",
    "name": "VersaGames",
    "isPopular": false,
    "chainId": "25",
    "address": "0x00d7699b71290094ccb1a5884cd835bd65a78c17",
    "decimals": 18,
    "image": "https://rango.vip/i/g4Vwo8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00476899,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "VERSA",
    "name": "VersaGames",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8497842420cfdbc97896c2353d75d89fc8d5be5d",
    "decimals": 18,
    "image": "https://rango.vip/i/iGxPOK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00476899,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ALIEN",
    "name": "AlienUniverse Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4mJ6N65rD9w6sFPQ17UDWot2H64UtzR31biVLaKpZT6J",
    "decimals": 0,
    "image": "https://rango.vip/i/xvWYaA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.002911738978432366,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "ALIEN",
    "name": "Alien",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xca84812e477ee5a96a92328689d8ce2589ab6ffd",
    "decimals": 18,
    "image": "https://rango.vip/i/bBQWkR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.002911738978432366,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CALO",
    "name": "CALO",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb6b91269413b6b99242b1c0bc611031529999999",
    "decimals": 18,
    "image": "https://rango.vip/i/OgkxM4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00117134796048408,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDE",
    "name": "USDe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c9edd5852cd905f086c759e8383e09bff1e68b3",
    "decimals": 18,
    "image": "https://rango.vip/i/WuE18B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999069,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDE",
    "name": "USDE Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1d00e86748573c322f4cc41518aa0e77bd912eb4",
    "decimals": 18,
    "image": "https://rango.vip/i/83vDZ3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.9992171482196367,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GNFT",
    "name": "GNFT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc502002aeb1b9309fccb016adf50507987fc6c2b",
    "decimals": 18,
    "image": "https://rango.vip/i/bQOKLS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.696501,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PONGO",
    "name": "Pongo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c68e725b0b2ffcba8947fded4198c3d1db041e6",
    "decimals": 9,
    "image": "https://rango.vip/i/BbI9TK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.50086e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "QUIDD",
    "name": "Quidd Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7961ade0a767c0e5b67dd1a1f78ba44f727642ed",
    "decimals": 18,
    "image": "https://rango.vip/i/pp2dS3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00966961,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "QUIDD",
    "name": "QUIDD",
    "isPopular": false,
    "chainId": "137",
    "address": "0x123706cdd8e60324e610e9a2cc7012d0f45a5b8e",
    "decimals": 18,
    "image": "https://rango.vip/i/IzqUUJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00966961,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SST",
    "name": "SmartsetToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15f73a3ab443ee6ebf36c605c7868159ce5d028c",
    "decimals": 9,
    "image": "https://rango.vip/i/itVYhv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007068,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GLX",
    "name": "Galaxify",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb48c625e2d55b53d192714deab18ec9d40e18a55",
    "decimals": 9,
    "image": "https://rango.vip/i/NrxBYF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00123691,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SBF",
    "name": "SBF",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FkbWN4dcFQym2PgCELfThghQqLuA2e2jThMJyhZjfG4M",
    "decimals": 6,
    "image": "https://rango.vip/i/4RIXrs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0005363,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KITTI",
    "name": "KITTI TOKEN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "B5Fvzd2RL5ctrmFsvDafXiNGbBqbxapiryJo8JfoSEcA",
    "decimals": 5,
    "image": "https://rango.vip/i/4ECDip",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.77564e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUP",
    "name": "CouponBay",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3209d14ff61766359e64aceff91877cec2ad968e",
    "decimals": 18,
    "image": "https://rango.vip/i/8R1au7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006065,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VBSWAP",
    "name": "vSWAP.fi",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4f0ed527e8a95ecaa132af214dfd41f30b361600",
    "decimals": 18,
    "image": "https://rango.vip/i/RQHiDi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 12.13,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "L2DAO",
    "name": "Layer2DAO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2cab3abfc1670d1a452df502e216a66883cdf079",
    "decimals": 18,
    "image": "https://rango.vip/i/7Xooe1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00054977,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MFI",
    "name": "MarginSwap",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x9fda7ceec4c18008096c2fe2b85f05dc300f94d0",
    "decimals": 18,
    "image": "https://rango.vip/i/UbMV6P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01382031,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MFI",
    "name": "MarginSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa4e3edb11afa93c41db59842b29de64b72e355b",
    "decimals": 18,
    "image": "https://rango.vip/i/B9lfBZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01382031,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HOLY",
    "name": "HolyGrails",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HezGWsxSVMqEZy7HJf7TtXzQRLiDruYsheYWqoUVnWQo",
    "decimals": 9,
    "image": "https://rango.vip/i/snbWpu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00131416,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SSU",
    "name": "SunnySideUp Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AGkFkKgXUEP7ZXazza5a25bSKbz5dDpgafPhqywuQnpf",
    "decimals": 9,
    "image": "https://rango.vip/i/a0tfkj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00109833,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMARTCREDIT",
    "name": "SMARTCREDIT Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72e9d9038ce484ee986fea183f8d8df93f9ada13",
    "decimals": 18,
    "image": "https://rango.vip/i/n9oLA8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.266245,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GEO$",
    "name": "GEOPOLY",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf1428850f92b87e629c6f3a3b75bffbc496f7ba6",
    "decimals": 18,
    "image": "https://rango.vip/i/af1eYi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00033564,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CRASH",
    "name": "747 Airlines (boeing)",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x621e87af48115122cd96209f820fe0445c2ea90e",
    "decimals": 18,
    "image": "https://rango.vip/i/b6jj92",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000108,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UM",
    "name": "UncleMine",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DMCUFm2ZAnSU7UgsdVq23gRogMU3MEBjPgQF1gK53rEn",
    "decimals": 6,
    "image": "https://rango.vip/i/Z20rY6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000221475839956076,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UM",
    "name": "Continuum",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3b1a0c9252ee7403093ff55b4a5886d49a3d837a",
    "decimals": 18,
    "image": "https://rango.vip/i/EdX9S3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00021664,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UM",
    "name": "Continuum World",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb19dd661f076998e3b0456935092a233e12c2280",
    "decimals": 18,
    "image": "https://rango.vip/i/uclIwJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021664,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PRL",
    "name": "Parallel Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd07e82440a395f3f3551b42da9210cd1ef4f8b24",
    "decimals": 18,
    "image": "https://rango.vip/i/lSsK4f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0006617288906379635,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GIAC",
    "name": "Gorilla In A Coupe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x793a5d8b30aab326f83d20a9370c827fea8fdc51",
    "decimals": 18,
    "image": "https://rango.vip/i/YHly8H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.07425e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VUZZ",
    "name": "VuzzMind",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe469699f617bfd0fbffcd575970d34c2cecffa9f",
    "decimals": 9,
    "image": "https://rango.vip/i/gFSIaz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00146645,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CWT",
    "name": "CoinW",
    "isPopular": false,
    "chainId": "1",
    "address": "0x901ea3606d567f9f1e964639d5cbb8659080be8a",
    "decimals": 18,
    "image": "https://rango.vip/i/MzE6PJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03413954,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "TIME",
    "name": "Time",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb54f16fb19478766a268f172c9480f8da1a7c9c3",
    "decimals": 9,
    "image": "https://rango.vip/i/1JHUQK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.082351,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TIME",
    "name": "ChronoTech Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x485d17a6f1b8780392d53d64751824253011a260",
    "decimals": 8,
    "image": "https://rango.vip/i/cIoAjy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 15.18,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TIME",
    "name": "TIME Magazine of Solana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AG9yih1Wkunf17yucoNYUacw4LwQugr2vWBPpHA6xz6q",
    "decimals": 9,
    "image": "https://rango.vip/i/J5ipcJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 15.227904985010678,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOGU",
    "name": "Mogutou",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3256cade5f8cb1256ac2bd1e2d854dec6d667bdf",
    "decimals": 18,
    "image": "https://rango.vip/i/UGiGKe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001893,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "USN",
    "name": "USN",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x5183e1b1091804bc2602586919e6880ac1cf2896",
    "decimals": 18,
    "image": "https://rango.vip/i/lzfoDV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.40749830553205363,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USN",
    "name": "USN (Allbridge from Near)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PUhuAtMHsKavMTwZsLaDeKy2jb7ciETHJP7rhbKLJGY",
    "decimals": 9,
    "image": "https://rango.vip/i/C93d31",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.9778050932163793,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MOONSTAR",
    "name": "MoonStar",
    "isPopular": false,
    "chainId": "56",
    "address": "0xce5814efff15d53efd8025b9f2006d4d7d640b9b",
    "decimals": 9,
    "image": "https://rango.vip/i/gSp5gS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.204300709569396e-9,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BOGE",
    "name": "Boge",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xef553b6914dbd17567393f7e55fbd773fff7d0cb",
    "decimals": 18,
    "image": "https://rango.vip/i/M6hvUk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00027926,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GRAPE",
    "name": "Grape coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x555296de6a86e72752e5c5dc091fe49713aa145c",
    "decimals": 18,
    "image": "https://rango.vip/i/In4cfz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00124327,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GRAPE",
    "name": "Grape",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8upjSpvjcdpuzhfR1zriwg5NXkwDruejqNE9WNbPRtyA",
    "decimals": 6,
    "image": "https://rango.vip/i/raAFLx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00048298,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SPADE",
    "name": "PolygonFarm Finance",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf5ea626334037a2cf0155d49ea6462fddc6eff19",
    "decimals": 18,
    "image": "https://rango.vip/i/BRvmFN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0087271,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LPL",
    "name": "LinkPool",
    "isPopular": false,
    "chainId": "1",
    "address": "0x99295f1141d58a99e939f7be6bbe734916a875b8",
    "decimals": 18,
    "image": "https://rango.vip/i/NIvkcI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.146326,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAGE",
    "name": "Rage Fan",
    "isPopular": false,
    "chainId": "1",
    "address": "0x94804dc4948184ffd7355f62ccbb221c9765886f",
    "decimals": 18,
    "image": "https://rango.vip/i/A90rGU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021591,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RAGE",
    "name": "Rage",
    "isPopular": false,
    "chainId": "137",
    "address": "0x40ccd55b789fdee8d434915dc2aa6bd938506a92",
    "decimals": 18,
    "image": "https://rango.vip/i/6MadIY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00021593833910537992,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LIT",
    "name": "Litentry",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb59490ab09a0f526cc7305822ac65f2ab12f9723",
    "decimals": 18,
    "image": "https://rango.vip/i/E2WHIB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.6073454883410524,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LIT",
    "name": "Liquidity Incentive Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd0205066521550d7d7ab19da8f72bb004b4c341",
    "decimals": 18,
    "image": "https://rango.vip/i/i5CLjb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01631777,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LIT",
    "name": "LIT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc5b3d3231001a776123194cf1290068e8b0c783b",
    "decimals": 18,
    "image": "https://rango.vip/i/KXew6Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002628,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LIT",
    "name": "Litentry",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb59490ab09a0f526cc7305822ac65f2ab12f9723",
    "decimals": 18,
    "image": "https://rango.vip/i/f3Ewfx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.604603,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LIT",
    "name": "Litentry",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x349fc93da004a63f3b1343361465981330a40b25",
    "decimals": 18,
    "image": "https://rango.vip/i/lwhWOp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.6073454883410524,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MURATIAI",
    "name": "MuratiAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69c2fcae7e30b429166bd616a322e32bec036bcf",
    "decimals": 18,
    "image": "https://rango.vip/i/bAOjKf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000166,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MOONED",
    "name": "MoonEdge",
    "isPopular": false,
    "chainId": "137",
    "address": "0x7e4c577ca35913af564ee2a24d882a4946ec492b",
    "decimals": 18,
    "image": "https://rango.vip/i/b5UKMY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00404753,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ATS",
    "name": "Atlas Dex",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv",
    "decimals": 9,
    "image": "https://rango.vip/i/0VDMEp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00286996,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUCHOW",
    "name": "LunaChow",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa5ef74068d04ba0809b7379dd76af5ce34ab7c57",
    "decimals": 18,
    "image": "https://rango.vip/i/B2R8Lq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.12266e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SCY",
    "name": "Synchrony",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SCYfrGCw8aDiqdgcpdGjV6jp4UVVQLuphxTDLNWu36f",
    "decimals": 9,
    "image": "https://rango.vip/i/KchYEh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.1409e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DMC",
    "name": "DMC",
    "isPopular": false,
    "chainId": "128",
    "address": "0x854bb58fdda85f20b5ab20b20d888f0554c02560",
    "decimals": 18,
    "image": "https://rango.vip/i/gSDuIq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00489353,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMETA",
    "name": "StarkMeta",
    "isPopular": false,
    "chainId": "1",
    "address": "0xadc3f2c3d728202658930860158c726d8180a38f",
    "decimals": 18,
    "image": "https://rango.vip/i/iU2pwJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.06408,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QASH",
    "name": "QASH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x618e75ac90b12c6049ba3b27f5d5f8651b0037f6",
    "decimals": 6,
    "image": "https://rango.vip/i/fypyno",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01178155,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RCN",
    "name": "RipioCreditNetwork",
    "isPopular": false,
    "chainId": "137",
    "address": "0x89c296be2f904f3e99a6125815b4b78f5388d2dd",
    "decimals": 18,
    "image": "https://rango.vip/i/Hxovmw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0011984791125364107,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RCN",
    "name": "Ripio",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6",
    "decimals": 18,
    "image": "https://rango.vip/i/PqVowA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00120884,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOS",
    "name": "DOS Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a913bead80f321e7ac35285ee10d9d922659cb7",
    "decimals": 18,
    "image": "https://rango.vip/i/gpyAr9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00057693,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PANDA",
    "name": "Panda Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Aw8qLRHGhMcKq7rxs5XBNCd9oe3BvoAhpNMVz7AdGmty",
    "decimals": 9,
    "image": "https://rango.vip/i/jCPGsL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001675,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PANDA",
    "name": "PandaDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3cbb7f5d7499af626026e96a2f05df806f2200dc",
    "decimals": 18,
    "image": "https://rango.vip/i/rj0R1z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00566716,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GLR",
    "name": "GalleryCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd54619e0b9899d74cc9b981354eb6b59732c43b1",
    "decimals": 18,
    "image": "https://rango.vip/i/D8giIe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.106035,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TR3",
    "name": "Tr3zor",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf61bf4d1a948487d61b8fa63808aac06bda55f98",
    "decimals": 18,
    "image": "https://rango.vip/i/Vj8vlP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005613,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WIN",
    "name": "WINk",
    "isPopular": false,
    "chainId": "56",
    "address": "0xaef0d72a118ce24fee3cd1d43d383897d05b4e99",
    "decimals": 18,
    "image": "https://rango.vip/i/RXkH9n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00008538,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "WIN",
    "name": "WIN",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TLa2f6VPqDgRE67v1736s7bJ8Ray5wYjU7",
    "decimals": 6,
    "image": "https://rango.vip/i/RsL916",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.00008495,
    "supportedSwappers": [
      "Bridgers",
      "SunSwap"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "IMO",
    "name": "Ideamarket",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb41bd4c99da73510d9e081c5fadbe7a27ac1f814",
    "decimals": 18,
    "image": "https://rango.vip/i/Zjp2Cw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.10098880850119868,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ISHND",
    "name": "StrongHands Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e6b19874e97fe8e8cad77f2c0ab5e7a693e5dbf",
    "decimals": 18,
    "image": "https://rango.vip/i/bsEvHZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00229569,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HUSL",
    "name": "The HUSL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2881f7f441267042f9778ffa0d4f834693426be",
    "decimals": 18,
    "image": "https://rango.vip/i/48Izkc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0086218,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOP",
    "name": "SoPay",
    "isPopular": false,
    "chainId": "1",
    "address": "0x076641af1b8f06b7f8c92587156143c109002cbe",
    "decimals": 18,
    "image": "https://rango.vip/i/ZfOGPc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001491,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TORG",
    "name": "TORG",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcfa0885131f602d11d4da248d2c65a62063567a9",
    "decimals": 18,
    "image": "https://rango.vip/i/4aFBgd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.5902e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QAI",
    "name": "QuantixAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb21311d3b91b5324f6c11b4f5a656fcacbff122",
    "decimals": 18,
    "image": "https://rango.vip/i/fWnYnM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 69.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QAI",
    "name": "Quant AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64d93cf499054170f4c211f91f867f902afaece6",
    "decimals": 18,
    "image": "https://rango.vip/i/xPiSob",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000346,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOJAK",
    "name": "Wojak Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5026f006b85729a8b14553fae6af249ad16c9aab",
    "decimals": 18,
    "image": "https://rango.vip/i/NLuTNU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00045186,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SECT",
    "name": "SECT BOT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24edded3f03abb2e9d047464294133378bddb596",
    "decimals": 18,
    "image": "https://rango.vip/i/EPPLKr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.082464,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCT",
    "name": "Micro Tuber",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6876eba317272fe221c67405c5e8eb3b24535547",
    "decimals": 18,
    "image": "https://rango.vip/i/yWeAkM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00045388,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PP",
    "name": "Print The Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8442e0e292186854bb6875b2a0fc1308b9ded793",
    "decimals": 9,
    "image": "https://rango.vip/i/d3nhki",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0006123812445439085,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DUSD",
    "name": "Davos xyz USD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8ec1877698acf262fe8ad8a295ad94d6ea258988",
    "decimals": 18,
    "image": "https://rango.vip/i/LGeRZv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.971862,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DUSD",
    "name": "Davos.xyz USD",
    "isPopular": false,
    "chainId": "137",
    "address": "0xec38621e72d86775a89c7422746de1f52bba5320",
    "decimals": 18,
    "image": "https://rango.vip/i/0fykXj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.971862,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUSD",
    "name": "DefiDollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5bc25f649fc4e26069ddf4cf4010f9f706c23831",
    "decimals": 18,
    "image": "https://rango.vip/i/CUF01P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.027551306027761582,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUSD",
    "name": "Davos.xyz USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa48f322f8b3edff967629af79e027628b9dd1298",
    "decimals": 18,
    "image": "https://rango.vip/i/txbVUm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.971862,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DUSD",
    "name": "DUSD",
    "isPopular": false,
    "chainId": "128",
    "address": "0x9f5c80dc840f9cc60670a20c1e5d0fbd3e13b015",
    "decimals": 18,
    "image": "https://rango.vip/i/HwO0Or",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.02169326088733902,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "DUSD",
    "name": "Davos xyz USD",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x578122317baca7a3c7bb5301460d2f4f96e9394a",
    "decimals": 18,
    "image": "https://rango.vip/i/LGeRZv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.971862,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TUT",
    "name": "Tutellus Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x12a34a6759c871c4c1e8a0a42cfc97e4d7aaf68d",
    "decimals": 18,
    "image": "https://rango.vip/i/lWJ1IP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01048911,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORT",
    "name": "XREATORS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5c59a5b139b0538cb106d775a022cad98dd14b5a",
    "decimals": 18,
    "image": "https://rango.vip/i/XEpIah",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00241813,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORT",
    "name": "ORDINAL TOOLS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe1b621235227bfa14ed81be2ba4e5e4447b52c0",
    "decimals": 9,
    "image": "https://rango.vip/i/2jLBDr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011378,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "BALA",
    "name": "BALA",
    "isPopular": false,
    "chainId": "66",
    "address": "0x287bd66753f0ca8e9c2171d782df39e4c28cfd65",
    "decimals": 18,
    "image": "https://rango.vip/i/c0uTwC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 8.561172274535213e-11,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZAZA",
    "name": "dog zoning out",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3QJzpi68a3CUVPGVUjYLWziGKCAvbNXmC5VFNy1ypump",
    "decimals": 6,
    "image": "https://rango.vip/i/tKO4fa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0001076,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOVESNOOPY",
    "name": "I LOVE SNOOPY",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0edac27aa3e85e2d176f689b0025f90c154393a",
    "decimals": 18,
    "image": "https://rango.vip/i/GQYdYN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.381e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUNR",
    "name": "LunarCrush",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa87135285ae208e22068acdbff64b11ec73eaa5a",
    "decimals": 4,
    "image": "https://rango.vip/i/sBlElD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03910516,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "KOGECOIN",
    "name": "kogecoin.io",
    "isPopular": false,
    "chainId": "137",
    "address": "0x13748d548d95d78a3c83fe3f32604b4796cffa23",
    "decimals": 9,
    "image": "https://rango.vip/i/uHzt3a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0005916,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "AI",
    "name": "Any Inu",
    "isPopular": false,
    "chainId": "10",
    "address": "0x2598c30330d5771ae9f983979209486ae26de875",
    "decimals": 18,
    "image": "https://rango.vip/i/PD3aXt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.0000073,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AI",
    "name": "Any Inu",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x2598c30330d5771ae9f983979209486ae26de875",
    "decimals": 18,
    "image": "https://rango.vip/i/PD3aXt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0000073,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AI",
    "name": "Any Inu (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ACeWC77UeW2DBZMe7YBsuXoxLvk4dHMnPzneApau1Au6",
    "decimals": 8,
    "image": "https://rango.vip/i/jnjqrV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000073,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AI",
    "name": "AI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "99ouK5YUK3JPGCPX9joNtHsMU7NPpU7w91JN4kdQ97po",
    "decimals": 9,
    "image": "https://rango.vip/i/iEvn9j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00029529,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AI",
    "name": "Multiverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc91b523a59acc63a64f61fc7bbfb4bfc82dd25f2",
    "decimals": 18,
    "image": "https://rango.vip/i/qDxeLw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005343,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AI",
    "name": "Any Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2598c30330d5771ae9f983979209486ae26de875",
    "decimals": 18,
    "image": "https://rango.vip/i/PD3aXt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000073,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AI",
    "name": "AI PIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc544207ff1c5b2bc47a35f745010b603b97e99e",
    "decimals": 18,
    "image": "https://rango.vip/i/hXfDxp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01773748,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AI",
    "name": "Chat AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa89bf95c5f15a847c8eb8d348cd7fed719ad7d80",
    "decimals": 18,
    "image": "https://rango.vip/i/8OrXRA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00036344,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AI",
    "name": "Any Inu",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2598c30330d5771ae9f983979209486ae26de875",
    "decimals": 18,
    "image": "https://rango.vip/i/Im7cWj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0000073,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AI",
    "name": "Any Inu",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x2598c30330d5771ae9f983979209486ae26de875",
    "decimals": 18,
    "image": "https://rango.vip/i/3XkYyF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0000073,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AI",
    "name": "Any Inu",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2598c30330d5771ae9f983979209486ae26de875",
    "decimals": 18,
    "image": "https://rango.vip/i/AuDJBc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0000073,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNB",
    "name": "MN Bridge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa922a70569a7555518bf4ded5094661a965e23ca",
    "decimals": 8,
    "image": "https://rango.vip/i/m23GTX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004653,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EYE",
    "name": "NftEyez Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G7eETAaUzmsBPKhokZyfbaT4tD9igdZSmfQGEYWem8Sw",
    "decimals": 6,
    "image": "https://rango.vip/i/hmwlsQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000573,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EYE",
    "name": "SolEye - OFFCHAIN TRACKER",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "74Eyos32V2B6ineYgAcRMZsiDpz65z7sXHq7D5MSMYgF",
    "decimals": 9,
    "image": "https://rango.vip/i/CoVY7G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000686,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EYE",
    "name": "EYE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x92d529163c5e880b9de86f01de0cb8924d790357",
    "decimals": 18,
    "image": "https://rango.vip/i/Xqkcpy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008858268334991374,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EYE",
    "name": "Behodler.io",
    "isPopular": false,
    "chainId": "1",
    "address": "0x155ff1a85f440ee0a382ea949f24ce4e0b751c65",
    "decimals": 18,
    "image": "https://rango.vip/i/ol3o8b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03037777,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EYE",
    "name": "ChartAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb146823fb8ea064d14ba1a52e3e55cde09afff2d",
    "decimals": 18,
    "image": "https://rango.vip/i/O0e8DF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00211416,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOR",
    "name": "Resistor AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b448aeb3bfd1dcbe337d59f6dee159daab52768",
    "decimals": 18,
    "image": "https://rango.vip/i/TNYkz0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007047,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEXM",
    "name": "Nexum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe831f96a7a1dce1aa2eb760b1e296c6a74caa9d5",
    "decimals": 8,
    "image": "https://rango.vip/i/KWdfjv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0289509,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAFEREUM",
    "name": "SAFEREUM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb504035a11e672e12a099f32b1672b9c4a78b22f",
    "decimals": 18,
    "image": "https://rango.vip/i/Tvmw3u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.00342e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XSHIB",
    "name": "XSHIB",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb61ebb6bceb7635ecd7e59884ee2e2bcdfd810ba",
    "decimals": 9,
    "image": "https://rango.vip/i/CTfye4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.0311e-14,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JFI",
    "name": "Jungle DeFi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GePFQaZKHcWE5vpxHfviQtH5jgxokSs51Y5Q4zgBiMDs",
    "decimals": 9,
    "image": "https://rango.vip/i/IfCKve",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00462179,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KIT",
    "name": "Kitsune",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AUgdt7wjBifF9vZpde7BjU6HLroCYh4SUHYc7yhheECW",
    "decimals": 9,
    "image": "https://rango.vip/i/ry2dtY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008764,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KIT",
    "name": "DexKit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7866e48c74cbfb8183cd1a929cd9b95a7a5cb4f4",
    "decimals": 18,
    "image": "https://rango.vip/i/PqneQ9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.278971,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FORK",
    "name": "FORK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d225c4cc612e61d26523b099b0718d03152edef",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.9466e-8,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "XAI",
    "name": "Xai",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4cb9a7ae498cedcbb5eae9f25736ae7d428c9d66",
    "decimals": 18,
    "image": "https://rango.vip/i/pQcGZY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.177585,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XAI",
    "name": "SideShift Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x35e78b3982e87ecfd5b3f3265b601c046cdbe232",
    "decimals": 18,
    "image": "https://rango.vip/i/0DSWq1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.133935,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XAI",
    "name": "XAI Corp",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a13a5929e5f0ff0eaba4bd9e9512c91fce40280",
    "decimals": 9,
    "image": "https://rango.vip/i/PjJzJ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017992,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XAI",
    "name": "XAI Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7c9f0e536dc865ae858b0c0453fe76d13c3beac",
    "decimals": 18,
    "image": "https://rango.vip/i/VIiDlN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.99933,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VEC",
    "name": "Vector Reserve",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1bb9b64927e0c5e207c9db4093b3738eef5d8447",
    "decimals": 9,
    "image": "https://rango.vip/i/frTju7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.24,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYBONK",
    "name": "CYBONK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9194337c06405623c0f374e63fa1cc94e2788c58",
    "decimals": 18,
    "image": "https://rango.vip/i/cVJpsc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.89708e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABYGROK",
    "name": "Baby Grok",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5b339d3912d5c72e16440b63a9c1df6024de93c",
    "decimals": 9,
    "image": "https://rango.vip/i/hf4pR9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.1912e-14,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TMED",
    "name": "MDsquare",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd32641191578ea9b208125ddd4ec5e7b84fcab4c",
    "decimals": 18,
    "image": "https://rango.vip/i/UxDoU9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001177,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUGS",
    "name": "Bugs Bunny",
    "isPopular": false,
    "chainId": "1",
    "address": "0x490bd60a5d3e1207fba9b699017561434cc8c675",
    "decimals": 18,
    "image": "https://rango.vip/i/V2kamz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00209189,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SSE",
    "name": "SelfieSteve",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CAa3j9oD6nDn5AeRmwZ6fcR78TAVXv9kumoihWvSbXsB",
    "decimals": 9,
    "image": "https://rango.vip/i/5P7Ftl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000781,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATD",
    "name": "A2DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8052327f1baf94a9dc8b26b9100f211ee3774f54",
    "decimals": 18,
    "image": "https://rango.vip/i/OS8K5b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04395559,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BAG",
    "name": "catwifbag",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "D8r8XTuCrUhLheWeGXSwC3G92RhASficV3YA7B2XWcLv",
    "decimals": 9,
    "image": "https://rango.vip/i/PfjP27",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0012419,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BAG",
    "name": "BIRKIN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Dnpy7ZkE1LvhyTC1hmTb8opJ6g9rgt2oRvN8z7y35WRJ",
    "decimals": 9,
    "image": "https://rango.vip/i/JXiq4f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0014183559450012277,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BAG",
    "name": "Bag",
    "isPopular": false,
    "chainId": "137",
    "address": "0x73b29199a8e4c146e893eb95f18dac41738a88c6",
    "decimals": 18,
    "image": "https://rango.vip/i/WQel8a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00141115,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAG",
    "name": "Bagholder",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70881d5c8a5950ceedf1f1b4b5d4105718642548",
    "decimals": 18,
    "image": "https://rango.vip/i/q7yDPj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00524746,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAG",
    "name": "tehBag",
    "isPopular": false,
    "chainId": "1",
    "address": "0x235c8ee913d93c68d2902a8e0b5a643755705726",
    "decimals": 18,
    "image": "https://rango.vip/i/9JmFb2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00028966,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAG",
    "name": "Bag",
    "isPopular": false,
    "chainId": "1",
    "address": "0x808688c820ab080a6ff1019f03e5ec227d9b522b",
    "decimals": 18,
    "image": "https://rango.vip/i/a4uGsa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00141115,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BAG",
    "name": "Heco BAG Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0xa042fb0e60125a4022670014ac121931e7501af4",
    "decimals": 18,
    "image": "https://rango.vip/i/49ksRT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0014183559450012277,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "BAG",
    "name": "Bag",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xb9dfcd4cf589bb8090569cb52fac1b88dbe4981f",
    "decimals": 18,
    "image": "https://rango.vip/i/GBZlDR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00141115,
    "supportedSwappers": [
      "ThrusterV3",
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LOWQ",
    "name": "LowQ",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7UAzn8R4mBfG1foqyL1169Lzd6cSWXYSLQFXYHzsiNQG",
    "decimals": 9,
    "image": "https://rango.vip/i/MClpK1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001245,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRYC",
    "name": "TRYC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0000000005c6b7c1fd10915a05f034f90d524d6e",
    "decimals": 6,
    "image": "https://rango.vip/i/F4hJoJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02936944,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "JEFE",
    "name": "Jefe",
    "isPopular": false,
    "chainId": "10",
    "address": "0x9fd22a17b4a96da3f83797d122172c450381fb88",
    "decimals": 9,
    "image": "https://rango.vip/i/Ugf0oN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.04859825,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JEFE",
    "name": "Jefe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9fd22a17b4a96da3f83797d122172c450381fb88",
    "decimals": 9,
    "image": "https://rango.vip/i/Ugf0oN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04859825,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "SWORD",
    "name": "eZKalibur Token",
    "isPopular": false,
    "chainId": "324",
    "address": "0x240f765af2273b0cab6caff2880d6d8f8b285fa4",
    "decimals": 18,
    "image": "https://rango.vip/i/wPJB3W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.032816546612209924,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SWORD",
    "name": "SWORD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x66b7cd2046c633196cfd061db21ca6a23ab9ba3a",
    "decimals": 18,
    "image": "https://rango.vip/i/7kyUd8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 4.03037e-7,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "JFIVE",
    "name": "JFIVE",
    "isPopular": false,
    "chainId": "56",
    "address": "0x845705db1623b8363a6d9813f33b73ec50cea0a2",
    "decimals": 9,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00119784,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USH",
    "name": "Hedge USD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6",
    "decimals": 9,
    "image": "https://rango.vip/i/cHDMIo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.850889,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USH",
    "name": "unshETHing_Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe60779cc1b2c1d0580611c526a8df0e3f870ec48",
    "decimals": 18,
    "image": "https://rango.vip/i/cltkUZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00154018,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USH",
    "name": "unshETHing_Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x91d6d6af7635b7b23a8ced9508117965180e2362",
    "decimals": 18,
    "image": "https://rango.vip/i/vaAHdl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00154018,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPHRI",
    "name": "Spherium",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a0cdfab62ed35b836dc0633482798421c81b3ec",
    "decimals": 18,
    "image": "https://rango.vip/i/aJXnZw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00313602,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "TIA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/d79e7d83ab399bfff93433e54faa480c191248fc556924a2a8351ae2638b3877",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/TIA.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 4.010822662809164,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TIA",
    "name": "TIA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x824e35f7a75324f99300afac75ecf7354e17ea26",
    "decimals": 9,
    "image": "https://rango.vip/i/KIZqE4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00390998,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CORN",
    "name": "Solanacorn",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6DSqVXg9WLTWgz6LACqxN757QdHe1sCqkUfojWmxWtok",
    "decimals": 7,
    "image": "https://rango.vip/i/B7uQW2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 7.06511e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REP",
    "name": "Reputation",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1985365e9f78359a9b6ad760e32412f4a445e862",
    "decimals": 18,
    "image": "https://rango.vip/i/iZf4r9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.3483058603163063,
    "supportedSwappers": [
      "EthUniSwapV2"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHRUB",
    "name": "Shrub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b991130eae3cca364406d718da22fa1c3e7c256",
    "decimals": 18,
    "image": "https://rango.vip/i/7SqUGi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004185,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOLVERINU",
    "name": "Wolverinu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7cc97bf17c5adabe25f9d19d15a1ec8a1ad65f14",
    "decimals": 18,
    "image": "https://rango.vip/i/31WEvw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.7029e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BLERF",
    "name": "BLERF",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x347f500323d51e9350285daf299ddb529009e6ae",
    "decimals": 18,
    "image": "https://rango.vip/i/DEPTaY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000321,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEM",
    "name": "Memecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42dbbd5ae373fea2fc320f62d44c058522bb3758",
    "decimals": 18,
    "image": "https://rango.vip/i/Ws1otB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00909411,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YAMA",
    "name": "YAMA Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66a3a58f812d0f433daaf1d96e14fd72d1d03d67",
    "decimals": 18,
    "image": "https://rango.vip/i/OdYk0s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007369,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "NT",
    "name": "NT",
    "isPopular": false,
    "chainId": "128",
    "address": "0x8b70512b5248e7c1f0f6996e2fde2e952708c4c9",
    "decimals": 18,
    "image": "https://rango.vip/i/quR6PE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00034364,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SNTR",
    "name": "Sentre",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SENBBKVCM7homnf5RX9zqpf1GFe935hnbU4uVzY1Y6M",
    "decimals": 9,
    "image": "https://rango.vip/i/5JHcMn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00061181,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATF",
    "name": "Antfarm Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x518b63da813d46556fea041a88b52e3caa8c16a8",
    "decimals": 18,
    "image": "https://rango.vip/i/NSDYyJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.102096,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATF",
    "name": "ArithFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00000000ba2ca30042001abc545871380f570b1f",
    "decimals": 18,
    "image": "https://rango.vip/i/MeFNLU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02532159,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DYOR",
    "name": "Book of DYOR",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DidjvEEFLk31yEjTkxf6CfNi6RcsUjPS6qHNTVzhApNU",
    "decimals": 6,
    "image": "https://rango.vip/i/QWDLfy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0008422,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DYOR",
    "name": "DYOR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8484e645a054586a6d6af60c0ee911d7b5180e64",
    "decimals": 18,
    "image": "https://rango.vip/i/7zv5nY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.6201e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "PTP",
    "name": "Platypus",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x22d4002028f537599be9f666d1c4fa138522f9c8",
    "decimals": 18,
    "image": "https://rango.vip/i/YkWMnM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00016486,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LGO",
    "name": "Level Governance Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbe2b6c5e31f292009f495ddbda88e28391c9815e",
    "decimals": 18,
    "image": "https://rango.vip/i/e7dXbG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 81341,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VDZ",
    "name": "Voidz",
    "isPopular": false,
    "chainId": "1",
    "address": "0x93c5a00b41fb5f3906b421b2399ac64b79fdbd42",
    "decimals": 18,
    "image": "https://rango.vip/i/rI3CUp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00170266,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MONS",
    "name": "Monsters Clan Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe4c797d43631f4d660ec67b5cb0b78ef5c902532",
    "decimals": 18,
    "image": "https://rango.vip/i/cd4QRO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.005800813735844535,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NFTBS",
    "name": "NFTBOOKS",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6396252377f54ad33cff9131708da075b21d9b88",
    "decimals": 9,
    "image": "https://rango.vip/i/g1q92J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 9.167e-9,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SMURF",
    "name": "Real Smurf Cat-шайлушай",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EArkn8uVf8YLfpF2eCdkCvDaPYpQuJzKXxaCnyxXc2P7",
    "decimals": 6,
    "image": "https://rango.vip/i/sP3uev",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002459,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UXP",
    "name": "UXP Governance Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M",
    "decimals": 9,
    "image": "https://rango.vip/i/YAvnQV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01550915,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCD",
    "name": "CDbio",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc66cdac744916afb6811c71c277d88de90ce8d5b",
    "decimals": 18,
    "image": "https://rango.vip/i/rsBTPx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0128796,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUDDHA",
    "name": "Buddha",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdefb0b264032e4e128b00d02b3fd0aa00331237b",
    "decimals": 18,
    "image": "https://rango.vip/i/7LXPf2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.49559e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FLOOF",
    "name": "FLOOF",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3jzdrXXKxwkBk82u2eCWASZLCKoZs1LQTg87HBEAmBJw",
    "decimals": 1,
    "image": "https://rango.vip/i/Zmfmk1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.19901e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LBR",
    "name": "Lybra Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed1167b6dc64e8a366db86f2e952a482d0981ebd",
    "decimals": 18,
    "image": "https://rango.vip/i/augT1c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.058607,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SETH",
    "name": "Synth sETH",
    "isPopular": false,
    "chainId": "10",
    "address": "0xe405de8f52ba7559f9df3c368500b6e6ae6cee49",
    "decimals": 18,
    "image": "https://rango.vip/i/PfgsP8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2328.29,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SETH",
    "name": "Synth sETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb",
    "decimals": 18,
    "image": "https://rango.vip/i/2UGbrw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2328.29,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RBIS",
    "name": "ArbiSmart",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf34b1db61aca1a371fe97bad2606c9f534fb9d7d",
    "decimals": 18,
    "image": "https://rango.vip/i/XEIAku",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040715,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAT",
    "name": "Kambria",
    "isPopular": false,
    "chainId": "1",
    "address": "0x14da230d6726c50f759bc1838717f8ce6373509c",
    "decimals": 18,
    "image": "https://rango.vip/i/2Fj8pl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021283,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SKI",
    "name": "Ski Mask Dog",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x768be13e1680b5ebe0024c42c896e3db59ec0149",
    "decimals": 9,
    "image": "https://rango.vip/i/GaNUNv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00066481,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "VSHARE",
    "name": "VSHARE",
    "isPopular": false,
    "chainId": "25",
    "address": "0xdcc261c03cd2f33ebea404318cdc1d9f8b78e1ad",
    "decimals": 18,
    "image": "https://rango.vip/i/W11GGR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0017304,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MIGGLES",
    "name": "Mister Miggles",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xb1a03eda10342529bbf8eb700a06c60441fef25d",
    "decimals": 18,
    "image": "https://rango.vip/i/xoD3d5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.01611248,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KRS",
    "name": "Kingdom Raids",
    "isPopular": false,
    "chainId": "56",
    "address": "0x37b53894e7429f794b56f22a32e1695567ee9913",
    "decimals": 18,
    "image": "https://rango.vip/i/726Rsn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.000016979799004829477,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CZOL",
    "name": "CZOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AUwugnCh1tFc5scRHLNqnHjfcRbHRsq7yrKFUe7Ysmgs",
    "decimals": 6,
    "image": "https://rango.vip/i/RnBnkL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000117,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "JOE",
    "name": "JoeToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd",
    "decimals": 18,
    "image": "https://rango.vip/i/f5zLx8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.29589,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "Bridgers",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "JOE",
    "name": "JOE",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07",
    "decimals": 18,
    "image": "https://rango.vip/i/58OEke",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.29589,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JOE",
    "name": "Joe Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x76e222b07c53d28b89b0bac18602810fc22b49a8",
    "decimals": 18,
    "image": "https://rango.vip/i/iTrqwZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00433666,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CARL",
    "name": "Carl",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CARL1SLwhaK4eaF633jgEgYgJ7UDJmTa9XGHfMgPud5e",
    "decimals": 6,
    "image": "https://rango.vip/i/iVx9d0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000005440133256505631,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POPO",
    "name": "POPO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x195be8ee12aa1591902c4232b5b25017a9cbbdea",
    "decimals": 18,
    "image": "https://rango.vip/i/vkVJ5L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00025525,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHEESE",
    "name": "CheeseFry",
    "isPopular": false,
    "chainId": "1",
    "address": "0x332e824e46fceeb9e59ba9491b80d3e6d42b0b59",
    "decimals": 18,
    "image": "https://rango.vip/i/IS1pt7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0016258773263553552,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WIFS",
    "name": "dogwifscarf",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6vUQsePjhpH67Db6p7Koj1wQsQP1UtovBkWXSrC1DkaA",
    "decimals": 9,
    "image": "https://rango.vip/i/sNoH5G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003779,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DHN",
    "name": "Dohrnii",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32462ba310e447ef34ff0d15bce8613aa8c4a244",
    "decimals": 18,
    "image": "https://rango.vip/i/oraQKn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00058317,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCW",
    "name": "OctopusWallet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26c75c7d815efe6bf5a6decd17d20d1fdad96a08",
    "decimals": 18,
    "image": "https://rango.vip/i/Q3FIeq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00030225,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOUL",
    "name": "Soul Scanner",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "J4ywFdm8H7hjwKzCaEQujhkDRfCnRviVnHMvFNDAoLNQ",
    "decimals": 6,
    "image": "https://rango.vip/i/vv8MLo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.242076,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOUL",
    "name": "SOUL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F6weWmuc1vwdL4u38Ro9jKXHEMjP9BoNdWMF5o5TvtJf",
    "decimals": 9,
    "image": "https://rango.vip/i/oeuw5N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.05251605181676173,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOUL",
    "name": "SOUL TOKEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x992d339532a9c42f1b0e59a57e95f38da38c66f6",
    "decimals": 18,
    "image": "https://rango.vip/i/EuZl5A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024298,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOUL",
    "name": "Phantasma Stake",
    "isPopular": false,
    "chainId": "1",
    "address": "0x75858677e27c930fb622759feaffee2b754af07f",
    "decimals": 8,
    "image": "https://rango.vip/i/8myPPQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.052578,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SOUL",
    "name": "APOyield SOULS",
    "isPopular": false,
    "chainId": "56",
    "address": "0x67d012f731c23f0313cea1186d0121779c77fcfe",
    "decimals": 8,
    "image": "https://rango.vip/i/Dlrfir",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.05251605181676173,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SOVI",
    "name": "SOVI",
    "isPopular": false,
    "chainId": "128",
    "address": "0x49e16563a2ba84e560780946f0fb73a8b32c841e",
    "decimals": 18,
    "image": "https://rango.vip/i/rEbIcM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.17523578637304008,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SPA",
    "name": "Sperax",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x5575552988a3a80504bbaeb1311674fcfd40ad4b",
    "decimals": 18,
    "image": "https://rango.vip/i/8BHm1h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00651837,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPA",
    "name": "Sperax",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb4a3b0faf0ab53df58001804dda5bfc6a3d59008",
    "decimals": 18,
    "image": "https://rango.vip/i/fMyePH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00651837,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOG",
    "name": "Bogdanoff",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ba74fb26ca523f2dc22fa4318581cc2452eaba1",
    "decimals": 18,
    "image": "https://rango.vip/i/PbTrkt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.86278e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BOG",
    "name": "Bogged Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb09fe1613fe03e7361319d2a43edc17422f36b09",
    "decimals": 18,
    "image": "https://rango.vip/i/XbV9vu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01649302,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BETA",
    "name": "Beta Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe1a001fe942f96eea22ba08783140b9dcc09d28",
    "decimals": 18,
    "image": "https://rango.vip/i/I8f4bj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04339664,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BETA",
    "name": "Beta Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbe1a001fe942f96eea22ba08783140b9dcc09d28",
    "decimals": 18,
    "image": "https://rango.vip/i/r93vNq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.04339664,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PURR",
    "name": "Purrcoin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xe4fcf2d991505089bbb36275570757c1f9800cb0",
    "decimals": 18,
    "image": "https://rango.vip/i/0Wgrr2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00004987,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MCOIN",
    "name": "Mirror COIN",
    "isPopular": false,
    "chainId": "56",
    "address": "0x49022089e78a8d46ec87a3af86a1db6c189afa6f",
    "decimals": 18,
    "image": "https://rango.vip/i/k9SjPV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.7068369053609633,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KORRA",
    "name": "KORRA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "t5cSTUSZzUAQXQKzQvhieFG4Hz4xC23z9du1Chp8gES",
    "decimals": 6,
    "image": "https://rango.vip/i/Zpiwtz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00093898,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DORK",
    "name": "DORK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae3359ed3c567482fb0102c584c23daa2693eacf",
    "decimals": 18,
    "image": "https://rango.vip/i/rJPD0C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000259,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VEX",
    "name": "Velorex",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc029a12e4a002c6858878fd9d3cc74e227cc2dda",
    "decimals": 9,
    "image": "https://rango.vip/i/j7nTEZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00046598,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USDB",
    "name": "USDB",
    "isPopular": false,
    "chainId": "137",
    "address": "0xfa1fbb8ef55a4855e5688c0ee13ac3f202486286",
    "decimals": 18,
    "image": "https://rango.vip/i/iem13K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.0045444707960325,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDB",
    "name": "USDB",
    "isPopular": false,
    "chainId": "1",
    "address": "0x02b5453d92b730f29a86a0d5ef6e930c4cf8860b",
    "decimals": 18,
    "image": "https://rango.vip/i/iem13K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.0045444707960325,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDB",
    "name": "USDB",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc8699abbba90c7479dedccef19ef78969a2fc608",
    "decimals": 18,
    "image": "https://rango.vip/i/iem13K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.0045444707960325,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "USDB",
    "name": "USDB",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x5ab7084cb9d270c2cb052dd30dbecbca42f8620c",
    "decimals": 18,
    "image": "https://rango.vip/i/iem13K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.0045444707960325,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "USDB",
    "name": "USDB",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x4300000000000000000000000000000000000003",
    "decimals": 18,
    "image": "https://rango.vip/i/KaHxTz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 1.003,
    "supportedSwappers": [
      "ThrusterV3",
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GMI",
    "name": "Bankless DeFi Innovation Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x47110d43175f7f2c2425e7d15792acc5817eb44f",
    "decimals": 18,
    "image": "https://rango.vip/i/qboXFS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003064717357422883,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GMI",
    "name": "Gamifi",
    "isPopular": false,
    "chainId": "56",
    "address": "0x93d8d25e3c9a847a5da79f79ecac89461feca846",
    "decimals": 18,
    "image": "https://rango.vip/i/6wooiK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00003064717357422883,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOOX",
    "name": "MOOxMOO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77ebcf0659bbf4e68d8ce6d84bb25c5cde207b97",
    "decimals": 18,
    "image": "https://rango.vip/i/K9H5zz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.151194,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UMAD",
    "name": "MADworld",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31c2415c946928e9fd1af83cdfa38d3edbd4326f",
    "decimals": 8,
    "image": "https://rango.vip/i/UiKxrC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012124,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMUDGE",
    "name": "Smudge Lord",
    "isPopular": false,
    "chainId": "1",
    "address": "0x516e2758b044433371076a48127b8cfa7b0bdb43",
    "decimals": 18,
    "image": "https://rango.vip/i/x9dGal",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.55678e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GNT",
    "name": "GreenTrust",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf750a26eb0acf95556e8529e72ed530f3b60f348",
    "decimals": 18,
    "image": "https://rango.vip/i/XY6hTb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.016e-9,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "TECH",
    "name": "TECH",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x5ac04b69bde6f67c0bd5d6ba6fd5d816548b066a",
    "decimals": 18,
    "image": "https://rango.vip/i/BUtneo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00003514,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TECH",
    "name": "Cryptomeda",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6286a9e6f7e745a6d884561d88f94542d6715698",
    "decimals": 18,
    "image": "https://rango.vip/i/KEaVcv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00026283,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TECH",
    "name": "Cryptomeda",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6286a9e6f7e745a6d884561d88f94542d6715698",
    "decimals": 18,
    "image": "https://rango.vip/i/Tl2e0G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00026283,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "XUSD",
    "name": "xDollar Stablecoin",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3a3e7650f8b9f667da98f236010fbf44ee4b2975",
    "decimals": 18,
    "image": "https://rango.vip/i/WdHwU0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.045,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XUSD",
    "name": "StraitsX XUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc08e7e23c235073c6807c2efe7021304cb7c2815",
    "decimals": 6,
    "image": "https://rango.vip/i/J3Nrpq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ATLAS",
    "name": "Star Atlas",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx",
    "decimals": 8,
    "image": "https://rango.vip/i/I3ueLD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00170002,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GEN",
    "name": "SolGen",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "1F1sRpidpKBQjmCnHAu8vJpmktLVK3ZjKyxVwEy8XpC",
    "decimals": 6,
    "image": "https://rango.vip/i/N1r20i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0017443959813708881,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEN",
    "name": "DAOstack",
    "isPopular": false,
    "chainId": "1",
    "address": "0x543ff227f64aa17ea132bf9886cab5db55dcaddf",
    "decimals": 18,
    "image": "https://rango.vip/i/iZbalq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.045e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEN",
    "name": "Generational Wealth",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcae3faa4b6cf660aef18474074949ba0948bc025",
    "decimals": 18,
    "image": "https://rango.vip/i/Sih6gX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.04802e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEN",
    "name": "Genesis",
    "isPopular": false,
    "chainId": "1",
    "address": "0x99999999999997fceb5549c58ab66df52385ca4d",
    "decimals": 18,
    "image": "https://rango.vip/i/tAa53r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00174584,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GEN",
    "name": "Gen Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb0f2939a1c0e43683e5954c9fe142f7df9f8d967",
    "decimals": 18,
    "image": "https://rango.vip/i/SgJog5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0017443959813708881,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HBOT",
    "name": "Hummingbot Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5097d9baeafb89f9bcb78c9290d545db5f9e9cb",
    "decimals": 18,
    "image": "https://rango.vip/i/jb8prR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00229669,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CAP",
    "name": "Cap",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x031d35296154279dc1984dcd93e392b1f946737b",
    "decimals": 18,
    "image": "https://rango.vip/i/ryR9Jl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.23,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CAP",
    "name": "Based Brians",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xd473475958d4c1538418224a52e5c0a6c997835a",
    "decimals": 18,
    "image": "https://rango.vip/i/HAnO90",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 8.84,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAP",
    "name": "Cap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x43044f861ec040db59a7e324c40507addb673142",
    "decimals": 18,
    "image": "https://rango.vip/i/zSBzZt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.23,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEEPO",
    "name": "Peepo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaada04204e9e1099daf67cf3d5d137e84e41cf41",
    "decimals": 18,
    "image": "https://rango.vip/i/0JFiCp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.4192e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOSTAI",
    "name": "Host AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07e128e823d2b9b22edbda43820aa1a72de99613",
    "decimals": 18,
    "image": "https://rango.vip/i/bDOb7U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00041573,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "POLYCUB",
    "name": "PolyCub",
    "isPopular": false,
    "chainId": "137",
    "address": "0x7cc15fef543f205bf21018f038f591c6bada941c",
    "decimals": 18,
    "image": "https://rango.vip/i/GcEBG3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00261918,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OT",
    "name": "Oracle Tools",
    "isPopular": false,
    "chainId": "1",
    "address": "0x257508ca6341337dd243d54afd964e19375b4af9",
    "decimals": 18,
    "image": "https://rango.vip/i/At0FmY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00177528,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHANGE",
    "name": "ChangeX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7051faed0775f664a0286af4f75ef5ed74e02754",
    "decimals": 18,
    "image": "https://rango.vip/i/yhGhJa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00340634,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAX",
    "name": "MAX Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe7976c4efc60d9f4c200cc1bcef1a1e3b02c73e7",
    "decimals": 18,
    "image": "https://rango.vip/i/mDbMM7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.306856,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAX",
    "name": "Maxity",
    "isPopular": false,
    "chainId": "1",
    "address": "0x63f7b1b538a78cb699e5399621b3d2e047c40de4",
    "decimals": 18,
    "image": "https://rango.vip/i/f37Q2o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02970019,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAX",
    "name": "UPMAX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8805792d41facb22b6f47d468b06af36ff3fc1c5",
    "decimals": 18,
    "image": "https://rango.vip/i/KxOgUd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01763083,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAX",
    "name": "Matr1x",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb7109df1a93f8fe2b8162c6207c9b846c1c68090",
    "decimals": 18,
    "image": "https://rango.vip/i/NOpu5V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.302451,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "MMO",
    "name": "MM Optimiser",
    "isPopular": false,
    "chainId": "25",
    "address": "0x50c0c5bda591bc7e89a342a3ed672fb59b3c46a7",
    "decimals": 18,
    "image": "https://rango.vip/i/uSPCJb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.012290449552996623,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AMI",
    "name": "Avax Meme Index",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc139aa91399600f6b72975ac3317b6d49cb30a69",
    "decimals": 18,
    "image": "https://rango.vip/i/VgG2bk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0010723068162423,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MON",
    "name": "Moneta",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1ea48b9965bb5086f3b468e50ed93888a661fc17",
    "decimals": 18,
    "image": "https://rango.vip/i/g0uOq9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.12973074351363106,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MON",
    "name": "MON Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc555d625828c4527d477e595ff1dd5801b4a600e",
    "decimals": 18,
    "image": "https://rango.vip/i/x6NNQO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.129275,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LFG",
    "name": "LessFnGas",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LFG1ezantSY2LPX8jRz2qa31pPEhpwN9msFDzZw4T9Q",
    "decimals": 7,
    "image": "https://rango.vip/i/F9AwPg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.1173e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LFG",
    "name": "LFG Token [via ChainPort.io]",
    "isPopular": false,
    "chainId": "1",
    "address": "0x969786c4a8884013d1c9ff18dcca2aedbbbfaa8f",
    "decimals": 18,
    "image": "https://rango.vip/i/B2Tzee",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008116,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FELIX",
    "name": "FELIX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6f3cbe18e9381dec6026e6cb8166c13944fcfee1",
    "decimals": 9,
    "image": "https://rango.vip/i/6Q02Fz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00152687,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHUMP",
    "name": "CHUMP",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HJ8WWpsheTMKwuoFkvEuhAzdqqUTgqdS7JVR37rxgnFS",
    "decimals": 9,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001476,
    "supportedSwappers": [
      "Jupiter",
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEFY",
    "name": "DEFY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x205ed31c867bf715e4182137af95afe9177cd8e7",
    "decimals": 18,
    "image": "https://rango.vip/i/1DiQEJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001976,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LHC",
    "name": "Lightcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x320d31183100280ccdf69366cd56180ea442a3e8",
    "decimals": 8,
    "image": "https://rango.vip/i/UDPFNn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011159,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GLDX",
    "name": "Goldex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc631120155621ee625835ec810b9885cdd764cd6",
    "decimals": 8,
    "image": "https://rango.vip/i/m500LM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02471212,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RABBIT",
    "name": "Rabbit Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x95a1199eba84ac5f19546519e287d43d2f0e1b41",
    "decimals": 18,
    "image": "https://rango.vip/i/Q5ttM7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00025811,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ECC",
    "name": "Etherconnect Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8d047f4f57a190c96c8b9704b39a1379e999d82b",
    "decimals": 8,
    "image": "https://rango.vip/i/wGoh9n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00011696643862518387,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GDT",
    "name": "GDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc67b12049c2d0cf6e476bc64c7f82fc6c63cffc5",
    "decimals": 8,
    "image": "https://rango.vip/i/Iqn0iT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0043199,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GDT",
    "name": "Gradient",
    "isPopular": false,
    "chainId": "1",
    "address": "0x396c95abe154b3b2ed204cf45c8726aa7ad47a4d",
    "decimals": 18,
    "image": "https://rango.vip/i/Tj9TOR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03963596,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "GDT",
    "name": "GDT",
    "isPopular": false,
    "chainId": "128",
    "address": "0x0f1ed0e22a74a90d92746a1794394da117e4de40",
    "decimals": 18,
    "image": "https://rango.vip/i/XFbWu0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.03972457042281085,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOJI",
    "name": "BOJI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2oMYg3aPHjFjxg1PRYLwvdRQayexUS8N4CjLXJ64GkLq",
    "decimals": 9,
    "image": "https://rango.vip/i/dF7y8n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00015746399472003282,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TCT",
    "name": "TokenClub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4824a7b64e3966b0133f4f4ffb1b9d6beb75fff7",
    "decimals": 18,
    "image": "https://rango.vip/i/gZSiXp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00079729,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTBS",
    "name": "BitBase",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32e6c34cd57087abbd59b5a4aecc4cb495924356",
    "decimals": 18,
    "image": "https://rango.vip/i/ARRj1E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.16414604291183316,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BTBS",
    "name": "BitBase",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6fefd97f328342a8a840546a55fdcfee7542f9a8",
    "decimals": 18,
    "image": "https://rango.vip/i/btT464",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.16414604291183316,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "POP",
    "name": "Popcorn (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc5b57e9a1e7914fda753a88f24e5703e617ee50c",
    "decimals": 18,
    "image": "https://rango.vip/i/fMnMos",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00641841,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POP",
    "name": "Popcorn",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd0cd466b34a24fcb2f87676278af2005ca8a78c4",
    "decimals": 18,
    "image": "https://rango.vip/i/NjWXHD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00641841,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POP",
    "name": "POP Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d858bcd53e085920620549214a8b27ce2f04670",
    "decimals": 18,
    "image": "https://rango.vip/i/5tc6da",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000456,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POP",
    "name": "Proof Of Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x265f542c1e78068f13d87c6fe0df54f3e9562a48",
    "decimals": 9,
    "image": "https://rango.vip/i/deaY9s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006241,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POP",
    "name": "Proof of Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f75aa2274273f4d41267a0e2cd6c9b96c5b7510",
    "decimals": 18,
    "image": "https://rango.vip/i/4iymXa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018523,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "POP",
    "name": "Popcorn",
    "isPopular": false,
    "chainId": "10",
    "address": "0x6f0fecbc276de8fc69257065fe47c5a03d986394",
    "decimals": 18,
    "image": "https://rango.vip/i/4nazEs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00641841,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PMA",
    "name": "PumaPay",
    "isPopular": false,
    "chainId": "1",
    "address": "0x846c66cf71c43f80403b51fe3906b3599d63336f",
    "decimals": 18,
    "image": "https://rango.vip/i/pZXZgM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000779,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DLTA",
    "name": "delta theta",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0000000de40dfa9b17854cbc7869d80f9f98d823",
    "decimals": 18,
    "image": "https://rango.vip/i/DbD8uE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00175482,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOGUL",
    "name": "Mogul Productions",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88fd59e1dd3715a98bb66149da9c944d9e795c12",
    "decimals": 18,
    "image": "https://rango.vip/i/MKqSsQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0022015,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PARA",
    "name": "Paralink Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a8d5bc8a8948b68dfc0ce9c14ac4150e083518c",
    "decimals": 18,
    "image": "https://rango.vip/i/akwsPx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00051522,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PARA",
    "name": "Paragon Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f43ee50942e96d84052253ab13f59c1d942fb98",
    "decimals": 9,
    "image": "https://rango.vip/i/KRSDBz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002537,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PARA",
    "name": "ParaWerx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd16fd95d949f996e3808eeea0e3881c59e76ef1e",
    "decimals": 18,
    "image": "https://rango.vip/i/UbUrQw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001927,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LCR",
    "name": "LaunchR",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba00357fd9348da1adbae9b2867b6b596eba4f24",
    "decimals": 18,
    "image": "https://rango.vip/i/6u5hvy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00142275,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LCR",
    "name": "LCR",
    "isPopular": false,
    "chainId": "56",
    "address": "0x988f7c894e4001eeb7b570cde80dffe21cf7b6b9",
    "decimals": 9,
    "image": "https://rango.vip/i/iqwsLh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00002863,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "UFO",
    "name": "UFO",
    "isPopular": false,
    "chainId": "66",
    "address": "0xa892e1fef8b31acc44ce78e7db0a2dc610f92d00",
    "decimals": 18,
    "image": "https://rango.vip/i/CoYCWG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.000037498342716896826,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UFO",
    "name": "THE TRUTH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b",
    "decimals": 18,
    "image": "https://rango.vip/i/lshALm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.7852e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HOOP",
    "name": "Hoopoe Ventures",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x0fec6d8a84a85b79a1ffe0e28c1902e08b653efe",
    "decimals": 18,
    "image": "https://rango.vip/i/U5pCuD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00006169553566028181,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HOOP",
    "name": "Chibi Dinos",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf19cfb40b3774df6eed83169ad5ab0aaf6865f25",
    "decimals": 18,
    "image": "https://rango.vip/i/e2YEZM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00006169553566028181,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BNSD",
    "name": "BNSD Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x668dbf100635f593a3847c0bdaf21f0a09380188",
    "decimals": 18,
    "image": "https://rango.vip/i/Y8k6Uq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00095448,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MBC",
    "name": "Mad Bears Club",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ee1pKgTQmP5xjYQs76HmRM2c2YkqEdc9tk5mQbiGFigT",
    "decimals": 9,
    "image": "https://rango.vip/i/DORMgQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.210801,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMIDGE",
    "name": "Smidge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea3665e272f14052442e433fb0059424d16cc6c7",
    "decimals": 18,
    "image": "https://rango.vip/i/4Ancok",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.6327e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "M",
    "name": "M",
    "isPopular": false,
    "chainId": "1",
    "address": "0x866a2bf4e572cbcf37d5071a7a58503bfb36be1b",
    "decimals": 6,
    "image": "https://rango.vip/i/CxcrDE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00041066791731885903,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLAZE",
    "name": "Titan Blaze",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfcd7ccee4071aa4ecfac1683b7cc0afecaf42a36",
    "decimals": 18,
    "image": "https://rango.vip/i/PFPLey",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.53,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ZKDOGE",
    "name": "zkDoge",
    "isPopular": false,
    "chainId": "324",
    "address": "0xbfb4b5616044eded03e5b1ad75141f0d9cb1499b",
    "decimals": 18,
    "image": "https://rango.vip/i/SHMBjs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00000712,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARA",
    "name": "Ara Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa92e7c82b11d10716ab534051b271d2f6aef7df5",
    "decimals": 18,
    "image": "https://rango.vip/i/R3PiQz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020974,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ACE",
    "name": "ACEToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc27a719105a987b4c34116223cae8bd8f4b5def4",
    "decimals": 18,
    "image": "https://rango.vip/i/mlv3mn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.01,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACE",
    "name": "ACENT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xec5483804e637d45cde22fa0869656b64b5ab1ab",
    "decimals": 18,
    "image": "https://rango.vip/i/Sr9cFJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00226153,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ACE",
    "name": "ACEToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfaaa87943bfca6d97434be3d26c589647fea4375",
    "decimals": 18,
    "image": "https://rango.vip/i/nzImSV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 6.68338e-7,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NIRV",
    "name": "NIRV",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "NRVwhjBQiUPYtfDT5zRBVJajzFQHaBUNtC7SNVvqRFa",
    "decimals": 6,
    "image": "https://rango.vip/i/5CFQ0d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0058779185593758635,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "POOP",
    "name": "Raresama",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xfffffffecb45afd30a637967995394cc88c0c194",
    "decimals": 18,
    "image": "https://rango.vip/i/aGc0mf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.14342428210459315,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PST",
    "name": "Primas",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe3fedaecd47aa8eab6b23227b0ee56f092c967a9",
    "decimals": 18,
    "image": "https://rango.vip/i/woln9c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00138166,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIB",
    "name": "Pibble Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1864ce27e9f7517047933caae530674e8c70b8a7",
    "decimals": 18,
    "image": "https://rango.vip/i/NK7Lhq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00028273,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RBX",
    "name": "RBX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8254e26e453eb5abd29b3c37ac9e8da32e5d3299",
    "decimals": 18,
    "image": "https://rango.vip/i/UxV6Ro",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00077815,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RBX",
    "name": "RabbitX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ba925fdeae6b46d0bb4d424d829982cb2f7309e",
    "decimals": 18,
    "image": "https://rango.vip/i/QFH79H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02204856,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RBX",
    "name": "RBX",
    "isPopular": false,
    "chainId": "56",
    "address": "0xace3574b8b054e074473a9bd002e5dc6dd3dff1b",
    "decimals": 18,
    "image": "https://rango.vip/i/k9nSJl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00077815,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MDS",
    "name": "Midas",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9TVjnzpF3X8DHsfVqYWoCGphJxtGYh1PDCFN5QmsHW5t",
    "decimals": 9,
    "image": "https://rango.vip/i/JKzyYr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.988957,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MDS",
    "name": "MediShares",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66186008c1050627f979d464eabb258860563dbe",
    "decimals": 18,
    "image": "https://rango.vip/i/sDnden",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013708,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REV",
    "name": "Revain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2ef52ed7de8c5ce03a4ef0efbe9b7450f2d7edc9",
    "decimals": 6,
    "image": "https://rango.vip/i/n4Vcrk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002429,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BEE",
    "name": "BEE",
    "isPopular": false,
    "chainId": "128",
    "address": "0xb1f80844a1b84c61ab80cafd88b1f8c09f9342e1",
    "decimals": 8,
    "image": "https://rango.vip/i/nNlxNn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.007404755760284933,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOLID",
    "name": "Solidly",
    "isPopular": false,
    "chainId": "1",
    "address": "0x777172d858dc1599914a1c4c6c9fc48c99a60990",
    "decimals": 18,
    "image": "https://rango.vip/i/cJV2FN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.092507,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATNT",
    "name": "Artizen",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa2d8c9a8bd0f7945143bfd509be3ff23dd78918",
    "decimals": 3,
    "image": "https://rango.vip/i/Lcrxjn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00233848,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SNE",
    "name": "StrongNodeEdge Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x32934cb16da43fd661116468c1b225fc26cf9a8c",
    "decimals": 18,
    "image": "https://rango.vip/i/JyR3nD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000569,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FIT",
    "name": "300FIT Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c72fca8523686fd9e5740b0826fa4bb376e0241",
    "decimals": 18,
    "image": "https://rango.vip/i/urxeFz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00053994,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FIT",
    "name": "FIT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b01637302b6adfc2c82678e2a8d680cff6337b7",
    "decimals": 18,
    "image": "https://rango.vip/i/LVOWqY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00118362,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELEC",
    "name": "Electrify Asia",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd49ff13661451313ca1553fd6954bd1d9b6e02b9",
    "decimals": 18,
    "image": "https://rango.vip/i/7UQr9f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015043,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "POCO",
    "name": "Poco Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x394bba8f309f3462b31238b3fd04b83f71a98848",
    "decimals": 18,
    "image": "https://rango.vip/i/ueFJ6k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00010777,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNNS",
    "name": "CNNS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c3be406174349cfa4501654313d97e6a31072e1",
    "decimals": 18,
    "image": "https://rango.vip/i/z6BcUn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005166,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NPXS",
    "name": "Pundi X  OLD ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa15c7ebe1f07caf6bff097d8a589fb8ac49ae5b3",
    "decimals": 18,
    "image": "https://rango.vip/i/cvPQAy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011306,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RVC",
    "name": "Revenue Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbcbdecf8e76a5c32dba69de16985882ace1678c6",
    "decimals": 18,
    "image": "https://rango.vip/i/q4zbFk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00135268,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SRN",
    "name": "Sirin Labs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25",
    "decimals": 18,
    "image": "https://rango.vip/i/NB9K0F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009883,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOKU",
    "name": "SokuSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c3a8eceb656ec63eae80a4ebd565e4887db6160",
    "decimals": 18,
    "image": "https://rango.vip/i/fxC7Ye",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000504,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "IMMO",
    "name": "Immortal",
    "isPopular": false,
    "chainId": "42220",
    "address": "0xe685d21b7b0fc7a248a6a8e03b8db22d013aa2ee",
    "decimals": 9,
    "image": "https://rango.vip/i/CX1E7B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.48621581114878454,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PGX",
    "name": "Pegaxy Stone",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc1c93d475dc82fe72dbc7074d55f5a734f8ceeae",
    "decimals": 18,
    "image": "https://rango.vip/i/zFsBqe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00451841,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "PGX",
    "name": "Pegaxy Stone",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x0ef27ddc8f89d4886e89d630de089962ffc12e43",
    "decimals": 18,
    "image": "https://rango.vip/i/TgRVTx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00451841,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MONEY",
    "name": "Moremoney USD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x0f577433bf59560ef2a79c124e9ff99fca258948",
    "decimals": 18,
    "image": "https://rango.vip/i/1mFQke",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.007,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNET",
    "name": "Snetwork",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff19138b039d938db46bdda0067dc4ba132ec71c",
    "decimals": 8,
    "image": "https://rango.vip/i/nI4hVq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00121746,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LAND",
    "name": "Land",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3258cd8134b6b28e814772dd91d5ecceea512818",
    "decimals": 18,
    "image": "https://rango.vip/i/EFDlmD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.3057880354389944,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LAND",
    "name": "Land",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3cd1833ce959e087d0ef0cb45ed06bffe60f23ba",
    "decimals": 18,
    "image": "https://rango.vip/i/EFDlmD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.3057880354389944,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LAND",
    "name": "Landshare",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc03e6ad83de7c58c9166ff08d66b960d78e64105",
    "decimals": 18,
    "image": "https://rango.vip/i/TmzNA5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.3,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LAND",
    "name": "Landshare Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9d986a3f147212327dd658f712d5264a73a1fdb0",
    "decimals": 18,
    "image": "https://rango.vip/i/du2uB7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.3057880354389944,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LAND",
    "name": "Landshare Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa73164db271931cf952cbaeff9e8f5817b42fa5c",
    "decimals": 18,
    "image": "https://rango.vip/i/IATGIp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.3,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOUSE",
    "name": "House",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa4baa6951b6ee382e9ff9af2d523278b99ca6d0",
    "decimals": 18,
    "image": "https://rango.vip/i/ztpHTR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.081368,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "AQUA",
    "name": "Aqua",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1hnftys64ectjfynm6qjk9my8jd3f6l9dq9utcd3dy8ehwrsx9q4q7n9uxt",
    "decimals": 6,
    "image": "https://rango.vip/i/jVpnxy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 0.0005934368677180147,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AQUA",
    "name": "AquaStake",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12f9a180198d91f854f3ca23caf8be1c83ef3b76",
    "decimals": 18,
    "image": "https://rango.vip/i/YvGpe0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000023,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AQUA",
    "name": "AQUA",
    "isPopular": false,
    "chainId": "56",
    "address": "0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991",
    "decimals": 18,
    "image": "https://rango.vip/i/KzaAbL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 10.15,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YIKES",
    "name": "Yikes Dog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ce3dRaePi2PrcsHb45i8qcaeCpHacvjXbbzo2DTPfX8z",
    "decimals": 9,
    "image": "https://rango.vip/i/RWnWaT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002434,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNFT",
    "name": "Mongol NFT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3235b13708f178af6f110de7177ed5de10c1093d",
    "decimals": 18,
    "image": "https://rango.vip/i/pzZ9Rn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000932,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HMX",
    "name": "HMX",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x83d6c8c06ac276465e4c92e7ac8c23740f435140",
    "decimals": 18,
    "image": "https://rango.vip/i/PZRuwf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.63,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HMX",
    "name": "Hermes",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb012be90957d70d9a070918027655f998c123a88",
    "decimals": 18,
    "image": "https://rango.vip/i/wAgiVg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00114552,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HMX",
    "name": "HMX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x83d6c8c06ac276465e4c92e7ac8c23740f435140",
    "decimals": 18,
    "image": "https://rango.vip/i/PZRuwf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.63,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLAPE",
    "name": "SolAPE Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN",
    "decimals": 9,
    "image": "https://rango.vip/i/DVvuJ6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00032633,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BITCOIN",
    "name": "HarryPotterObamaSonic10Inu  ETH ",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x2a06a17cbc6d0032cac2c6696da90f29d39a1a29",
    "decimals": 8,
    "image": "https://rango.vip/i/BXjxsz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.077944,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BITCOIN",
    "name": "HarryPotterObamaSonic10Inu  ETH ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72e4f9f808c49a2a61de9c5896298920dc4eeea9",
    "decimals": 8,
    "image": "https://rango.vip/i/BXjxsz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.077944,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAI",
    "name": "Generaitiv",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d8ca4b20b115d4da5c13dc45dd582a5de3e78bf",
    "decimals": 18,
    "image": "https://rango.vip/i/LxdScU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.056402,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAI",
    "name": "Gate AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe14b7a963925e451761166ad08dc6118eceddb9a",
    "decimals": 9,
    "image": "https://rango.vip/i/qLbuoz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007297,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAI",
    "name": "Game Integrated AI Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8058e0fd62ef9176798cfeda842da696386e7b2e",
    "decimals": 18,
    "image": "https://rango.vip/i/KNWqQy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IHT",
    "name": "IHT Real Estate Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeda8b016efa8b1161208cf041cd86972eee0f31e",
    "decimals": 18,
    "image": "https://rango.vip/i/Pb0Mdq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006284,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VAI",
    "name": "VAI Stablecoin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4bd17003473389a42daf6a0a729f6fdb328bbbd7",
    "decimals": 18,
    "image": "https://rango.vip/i/ryHe8k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.999171,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VAI",
    "name": "VAIOT Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd13cfd3133239a3c73a9e535a5c4dadee36b395c",
    "decimals": 18,
    "image": "https://rango.vip/i/4q7cP0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.073519,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VAI",
    "name": "Volume AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd23410f1fff27279c7314ff4735e71852084ddf",
    "decimals": 18,
    "image": "https://rango.vip/i/SjVYD3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000905,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPT",
    "name": "Open Predict Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4fe5851c9af07df9e5ad8217afae1ea72737ebda",
    "decimals": 18,
    "image": "https://rango.vip/i/qwKbuR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.04090203325046e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUTH",
    "name": "TruthGPT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf75d880b3128981d1fed3292fc02e3fb37acd53",
    "decimals": 9,
    "image": "https://rango.vip/i/m1E11T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007993,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUTH",
    "name": "Truth Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcf4c91ecafc43c9f382db723ba20b82efa852821",
    "decimals": 18,
    "image": "https://rango.vip/i/zZNkUj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004941,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLNY",
    "name": "Colony Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e828ac5c480069d4765654fb4b8733b910b13b2",
    "decimals": 18,
    "image": "https://rango.vip/i/mAeDlL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01050337,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANG",
    "name": "Aureus Nummus Gold",
    "isPopular": false,
    "chainId": "1",
    "address": "0x130914e1b240a7f4c5d460b7d3a2fd3846b576fa",
    "decimals": 18,
    "image": "https://rango.vip/i/YFDYpv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020199,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PAR",
    "name": "PAR Stablecoin",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe2aa7db6da1dae97c5f5c6914d285fbfcc32a128",
    "decimals": 18,
    "image": "https://rango.vip/i/UCoMwG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.1,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAR",
    "name": "Parachute",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06",
    "decimals": 18,
    "image": "https://rango.vip/i/J2NiYT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014658,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAR",
    "name": "PAR Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68037790a0229e9ce6eaa8a99ea92964106c4703",
    "decimals": 18,
    "image": "https://rango.vip/i/5p9SCg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TALK",
    "name": "Soltalk AI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3Ce4PdWfdGjp2F5gn2iyxz7CDMG7TpwunbKHkF67itqf",
    "decimals": 6,
    "image": "https://rango.vip/i/nsUqIU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00078376,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TALK",
    "name": "Talken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcaabcaa4ca42e1d86de1a201c818639def0ba7a7",
    "decimals": 18,
    "image": "https://rango.vip/i/jMk32r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03397831,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGI",
    "name": "SingularityNET Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8eb24319393716668d768dcec29356ae9cffe285",
    "decimals": 8,
    "image": "https://rango.vip/i/zmDpu7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.11385949183098718,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGI",
    "name": "Agility",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f18ea482ad5cc6bc65803817c99f477043dce85",
    "decimals": 18,
    "image": "https://rango.vip/i/QjEbdG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00169812,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGI",
    "name": "AGI Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7da2641000cbb407c329310c461b2cb9c70c3046",
    "decimals": 18,
    "image": "https://rango.vip/i/oytBn3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.113284,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ABI",
    "name": "Abachi",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb827710314a05bcbee9180e11c2abe5823289422",
    "decimals": 18,
    "image": "https://rango.vip/i/sEZfKJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.838954,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ABI",
    "name": "Abachi",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6d5f5317308c6fe7d6ce16930353a8dfd92ba4d7",
    "decimals": 9,
    "image": "https://rango.vip/i/ANvYdn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.399775588493973,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABI",
    "name": "Abachi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbf0b8b7475edb32d103001efd19fdd2753d7b76d",
    "decimals": 18,
    "image": "https://rango.vip/i/sEZfKJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.838954,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LIMEX",
    "name": "Limestone Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x400b1d8a7dd8c471026b2c8cbe1062b27d120538",
    "decimals": 8,
    "image": "https://rango.vip/i/oKBILa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001891,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVAG",
    "name": "Crypto Village Accelerator CVAG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72a66e54b66892ae3bbe54df7bb7dd5ae927a6f9",
    "decimals": 18,
    "image": "https://rango.vip/i/zBuY5Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.996e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ADEL",
    "name": "Delphi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x94d863173ee77439e4292284ff13fad54b3ba182",
    "decimals": 18,
    "image": "https://rango.vip/i/W3sPS4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00310373,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MYST",
    "name": "MYSTCL",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xdc46c1e93b71ff9209a0f8076a9951569dc35855",
    "decimals": 18,
    "image": "https://rango.vip/i/0w7gbx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 61.65,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MYST",
    "name": "Mysterium (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1379e8886a944d2d9d440b3d88df536aea08d9f3",
    "decimals": 18,
    "image": "https://rango.vip/i/fzKoVl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.21451,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MYST",
    "name": "Mysterium",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4cf89ca06ad997bc732dc876ed2a7f26a9e7f361",
    "decimals": 18,
    "image": "https://rango.vip/i/0PUfhg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.21451,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHINJI",
    "name": "Shinji Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f31dd60b84ea2a273cfc56876437e069ae80afd",
    "decimals": 9,
    "image": "https://rango.vip/i/ZGZ1Qn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3e-18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DARA",
    "name": "Immutable",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0255af6c9f86f6b0543357bacefa262a2664f80f",
    "decimals": 18,
    "image": "https://rango.vip/i/WIbVFD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00295001,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NFTE",
    "name": "NFTEarthOFT",
    "isPopular": false,
    "chainId": "137",
    "address": "0x492fa53b88614923937b7197c87e0f7f8eeb7b20",
    "decimals": 18,
    "image": "https://rango.vip/i/UnKVi4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.000010256799238336708,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFTE",
    "name": "NFTEarthOFT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c223a82e07fecb49d602150d7c2b3a4c9630310",
    "decimals": 18,
    "image": "https://rango.vip/i/3055gt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000010256799238336708,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NFTE",
    "name": "NFTEarthOFT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1912a3504e59d1c1b060bf2d371deb00b70e8796",
    "decimals": 18,
    "image": "https://rango.vip/i/4VUROU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.000010256799238336708,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "NFTE",
    "name": "NFTEarthOFT",
    "isPopular": false,
    "chainId": "10",
    "address": "0x8637725ada78db0674a679cea2a5e0a0869ef4a1",
    "decimals": 18,
    "image": "https://rango.vip/i/oabCW8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.000010256799238336708,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MFTU",
    "name": "Mainstream For The Underground",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba745513acebcbb977497c569d4f7d340f2a936b",
    "decimals": 18,
    "image": "https://rango.vip/i/zecTg3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1e-17,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TRONPAD",
    "name": "TRONPAD.network",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1bf7aedec439d6bfe38f8f9b20cf3dc99e3571c4",
    "decimals": 18,
    "image": "https://rango.vip/i/fcQa3l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00155623,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHWY",
    "name": "CHWY",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce176825afc335d9759cb4e323ee8b31891de747",
    "decimals": 9,
    "image": "https://rango.vip/i/bD8KRl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.07636e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HEC",
    "name": "HeroesChained",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc7f4debc8072e23fe9259a5c0398326d8efb7f5c",
    "decimals": 18,
    "image": "https://rango.vip/i/GAqskr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01847188,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "COLLAR",
    "name": "PolyPup Finance",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8df26a1bd9bd98e2ec506fc9d8009954716a05dc",
    "decimals": 18,
    "image": "https://rango.vip/i/wfoiDL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0005945117233921341,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COLLAR",
    "name": "DOG COLLAR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9783b81438c24848f85848f8df31845097341771",
    "decimals": 18,
    "image": "https://rango.vip/i/5DhGd1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.65656e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LC",
    "name": "Lion Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5HJ3fCrCXUEEraLJUBLvhbNwSYS2RNzjuuS33FLH7UjP",
    "decimals": 9,
    "image": "https://rango.vip/i/vKN5Xc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00024468375937223186,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DAWG",
    "name": "Cheeky Dawg",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xa835f70dd5f8b4f0023509f8f36c155785758db0",
    "decimals": 18,
    "image": "https://rango.vip/i/1nnGIy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 7.85399e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DAWG",
    "name": "DAWG",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3DHPqxdMXogNNnpqBMF8N4Zs4dn1WR31H7UjWq6FExwG",
    "decimals": 9,
    "image": "https://rango.vip/i/VdceUi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0011178,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAWG",
    "name": "Dawg Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd5fa38027462691769b8a8ba6c444890103b5b94",
    "decimals": 9,
    "image": "https://rango.vip/i/SshwFu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021334,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NEKO",
    "name": "NEKO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6wdbFQAxDVwUdJrZEnnzgPzsZ1NruvLhf9qCvmSD5DLX",
    "decimals": 6,
    "image": "https://rango.vip/i/hZWQwK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00021239258720633322,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEKO",
    "name": "The Neko",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e9d0bb190ac34492aa11b80d28c1c86487a341f",
    "decimals": 18,
    "image": "https://rango.vip/i/FBI55A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000577,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBCC",
    "name": "Bitcoin Black Credit Card",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbbcc7c16d56fc3b0c0a9a2ced36c74bcf73e683e",
    "decimals": 18,
    "image": "https://rango.vip/i/gbfOAu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005013,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTCP",
    "name": "Bitcoin Pro",
    "isPopular": false,
    "chainId": "1",
    "address": "0x723cbfc05e2cfcc71d3d89e770d32801a5eef5ab",
    "decimals": 8,
    "image": "https://rango.vip/i/dczXAf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 27.39,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SNS",
    "name": "SynesisOne",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SNSNkV9zfG5ZKWQs6x4hxvBRV6s8SqMfSGCtECDvdMd",
    "decimals": 9,
    "image": "https://rango.vip/i/lXpaYF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01010194,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNS",
    "name": "Shibarium Name Service",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea4a2327e75252517535fd013b7c6706609819db",
    "decimals": 18,
    "image": "https://rango.vip/i/verryu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.88646e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCA",
    "name": "ScaleSwapToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1fbd3df007eb8a7477a1eab2c63483dcc24effd6",
    "decimals": 18,
    "image": "https://rango.vip/i/1CHrKx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01685218,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STON",
    "name": "Ston",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc47f2ba852669b178699449e50682d6ceaf8c07",
    "decimals": 18,
    "image": "https://rango.vip/i/AQJ5kD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017011,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NOLA",
    "name": "Nola",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf8388c2b6edf00e2e27eef5200b1befb24ce141d",
    "decimals": 18,
    "image": "https://rango.vip/i/asLNqu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00015321,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "FLX",
    "name": "Flux Token",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xea62791aa682d455614eaa2a12ba3d9a2fd197af",
    "decimals": 18,
    "image": "https://rango.vip/i/zMIeNU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.03782211,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLX",
    "name": "Flux Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ea8ea4237344c9931214796d9417af1a1180770",
    "decimals": 18,
    "image": "https://rango.vip/i/JN8eJk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03782211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLX",
    "name": "Flex Ungovernance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6243d8cea23066d098a15582d81a598b4e8391f4",
    "decimals": 18,
    "image": "https://rango.vip/i/kjkpqZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.33,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ANDY",
    "name": "Andy",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x18a8bd1fe17a1bb9ffb39ecd83e9489cfd17a022",
    "decimals": 18,
    "image": "https://rango.vip/i/V2dVvg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00611597,
    "supportedSwappers": [
      "SushiBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANDY",
    "name": "aNDY",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4058f6a829ddd684e1b7589b33312827f0a47bb",
    "decimals": 18,
    "image": "https://rango.vip/i/n29Uvl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001883,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANDY",
    "name": "Andy on ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x748509433ef209c4d11ada51347d5724a5da0ca5",
    "decimals": 9,
    "image": "https://rango.vip/i/bivOkm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.051294,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANDY",
    "name": "ANDY ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68bbed6a47194eff1cf514b50ea91895597fc91e",
    "decimals": 18,
    "image": "https://rango.vip/i/WOoLT0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005845,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "ANDY",
    "name": "AndyBlast",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xd43d8adac6a4c7d9aeece7c3151fca8f23752cf8",
    "decimals": 9,
    "image": "https://rango.vip/i/Tv0Abv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.053004,
    "supportedSwappers": [
      "ThrusterV3",
      "ThrusterV2.1"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "QUACK",
    "name": "Quack Coin Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x5ace197d87b614942bc1670eb0ddd55ce4432801",
    "decimals": 18,
    "image": "https://rango.vip/i/Ua1u8Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00001191,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "QUACK",
    "name": "QUACK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6frkvZf72wiz3uqRWhBqLftNU4PS6XXYCoNrW9P4CFdK",
    "decimals": 6,
    "image": "https://rango.vip/i/xrtE3G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 6.22538294721497e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "QUACK",
    "name": "RichQUACK.com",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd74b782e05aa25c50e7330af541d46e18f36661c",
    "decimals": 9,
    "image": "https://rango.vip/i/GHrP8B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 6.20707e-10,
    "supportedSwappers": [
      "OneInchBsc",
      "Bridgers"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HAPPY",
    "name": "HappyFans",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf5d8a096cccb31b9d7bce5afe812be23e3d4690d",
    "decimals": 18,
    "image": "https://rango.vip/i/pPonws",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00000155,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HAPPY",
    "name": "HappyAyeSOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ETBneBQ97qDUygsEBDnpiUF6e832GHV8FzsJCvbUgN1B",
    "decimals": 9,
    "image": "https://rango.vip/i/MtuhtD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000015847256276088241,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAPPY",
    "name": "HappyFans",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3079f61704e9efa2bcf1db412f735d8d4cfa26f4",
    "decimals": 18,
    "image": "https://rango.vip/i/ZV3pGl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000155,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "NOIS",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/6928afa9ea721938fed13b051f9dbf1272b16393d20c49ea5e4901bb76d94a90",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.003928918218722981,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RFX",
    "name": "Reflex",
    "isPopular": false,
    "chainId": "1",
    "address": "0x159a1dfae19057de57dfffcbb3da1ae784678965",
    "decimals": 8,
    "image": "https://rango.vip/i/LyCHvJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009806,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MICRO",
    "name": "Micro GPT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8cedb0680531d26e62abdbd0f4c5428b7fdc26d5",
    "decimals": 18,
    "image": "https://rango.vip/i/1yVUhn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000003083011597694203,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POOF",
    "name": "PoofAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36862ff1f4f2cc859a13a45d4921f8ab3664e22b",
    "decimals": 18,
    "image": "https://rango.vip/i/WANWbI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006098,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "POOF",
    "name": "Poof",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x00400fcbf0816bebb94654259de7273f4a05c762",
    "decimals": 18,
    "image": "https://rango.vip/i/K8nDnR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.000026350084671793608,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "L3USD",
    "name": "L3USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c2d8a078b33bf7782a16acce2c5ba6653a90d5f",
    "decimals": 18,
    "image": "https://rango.vip/i/RkY17s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999805,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "L3USD",
    "name": "L3USD",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2c2d8a078b33bf7782a16acce2c5ba6653a90d5f",
    "decimals": 18,
    "image": "https://rango.vip/i/Q2wMry",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.999805,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TI",
    "name": "Titanium22",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc5170dd7386247cdb8c48545c803f5d0e3347022",
    "decimals": 18,
    "image": "https://rango.vip/i/IXXtcL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.05969e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHEESHA",
    "name": "Sheesha Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x232fb065d9d24c34708eedbf03724f2e95abe768",
    "decimals": 18,
    "image": "https://rango.vip/i/EpjQQr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.27,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SHEESHA",
    "name": "Sheesha Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x232fb065d9d24c34708eedbf03724f2e95abe768",
    "decimals": 18,
    "image": "https://rango.vip/i/EpjQQr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4.87,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "META",
    "name": "Meta Pool",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xc21ff01229e982d7c8b8691163b0a3cb8f357453",
    "decimals": 24,
    "image": "https://rango.vip/i/SBFabV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.017270456287509412,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "META",
    "name": "META",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "METADDFL6wWMWEoKTFJwcThTbUmtarRJZjRpzUvkxhr",
    "decimals": 9,
    "image": "https://rango.vip/i/yHmgcy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2116.61,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "META",
    "name": "Metadium",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde2f7766c8bf14ca67193128535e5c7454f8387c",
    "decimals": 18,
    "image": "https://rango.vip/i/Uv5QcN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02377275,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CPT",
    "name": "Cryptaur",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88d50b466be55222019d71f9e8fae17f5f45fca1",
    "decimals": 8,
    "image": "https://rango.vip/i/tpipkW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000279,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "VS",
    "name": "veSync",
    "isPopular": false,
    "chainId": "324",
    "address": "0x5756a28e2aae01f600fc2c01358395f5c1f8ad3a",
    "decimals": 18,
    "image": "https://rango.vip/i/LZk2CT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.000475434017346815,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOX",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0xc770eefad204b5180df6a14ee197d99d808ee52d",
    "decimals": 18,
    "image": "https://rango.vip/i/GN83x8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03675466,
    "supportedSwappers": [
      "ThorChain",
      "EthereumUniswapV3",
      "ThorChainStreamingSwap",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FOX",
    "name": "FOX",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf929de51d91c77e42f5090069e0ad7a09e513c73",
    "decimals": 18,
    "image": "https://rango.vip/i/PTxVPO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.03675466,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FOX",
    "name": "FOX (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x65a05db8322701724c197af82c9cae41195b0aa8",
    "decimals": 18,
    "image": "https://rango.vip/i/N3LHRA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03675466,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "FOX",
    "name": "ShapeShift FOX",
    "isPopular": false,
    "chainId": "10",
    "address": "0xf1a0da3367bc7aa04f8d94ba57b862ff37ced174",
    "decimals": 18,
    "image": "https://rango.vip/i/eknt9Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.03675466,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DOGGS",
    "name": "Doggensnout",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HzF4L5A6Y4y3jpwvvsZXW51uUki851ctRYJ2GYK4dp7g",
    "decimals": 9,
    "image": "https://rango.vip/i/GKMuOT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008058,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BVM",
    "name": "BVM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x069d89974f4edabde69450f9cf5cf7d8cbd2568d",
    "decimals": 18,
    "image": "https://rango.vip/i/n1bbmc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.370047,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JEFF",
    "name": "JEFF",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FkCaFsprX7gySagFoQPHNbe9MRkjrvh21cokJo6C1e2T",
    "decimals": 6,
    "image": "https://rango.vip/i/niKg6p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.020366357306705232,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JEFF",
    "name": "JEFF",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf831938caf837cd505de196bbb408d81a06376ab",
    "decimals": 18,
    "image": "https://rango.vip/i/povzUB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00178986,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JEFF",
    "name": "JEFFWorld Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a8b8e526d093476ac5c488a3ea057f8de9c0dee",
    "decimals": 18,
    "image": "https://rango.vip/i/YdrKdA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02037337,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "EPS",
    "name": "Ellipsis",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa7f552078dcc247c2684336020c03648500c6d9f",
    "decimals": 18,
    "image": "https://rango.vip/i/i05px9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02126454,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "YEL",
    "name": "Yel Finance",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x949185d3be66775ea648f4a306740ea9eff9c567",
    "decimals": 18,
    "image": "https://rango.vip/i/80Xvot",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00264094,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "YEL",
    "name": "YEL Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd3b71117e6c1558c1553305b44988cd944e97300",
    "decimals": 18,
    "image": "https://rango.vip/i/TI67bQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00202049,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YEL",
    "name": "YEL Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7815bda662050d84718b988735218cffd32f75ea",
    "decimals": 18,
    "image": "https://rango.vip/i/Wip1Cz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00202049,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YEL",
    "name": "YELToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x949185d3be66775ea648f4a306740ea9eff9c567",
    "decimals": 18,
    "image": "https://rango.vip/i/80Xvot",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00264094,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "YEL",
    "name": "YEL Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd3b71117e6c1558c1553305b44988cd944e97300",
    "decimals": 18,
    "image": "https://rango.vip/i/TI67bQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00202049,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "YEL",
    "name": "Yel Finance",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x949185d3be66775ea648f4a306740ea9eff9c567",
    "decimals": 18,
    "image": "https://rango.vip/i/80Xvot",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.0026239745965964414,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MILK",
    "name": "MILK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "MLKmUCaj1dpBY881aFsrBwR9RUMoKic8SWT3u1q5Nkj",
    "decimals": 9,
    "image": "https://rango.vip/i/7JbV5S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00180445,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MILK",
    "name": "Tiddy Juice Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CCKDRAd4Xwjoovtf2s1duu3d4TPTmFRyh1hfrb3ZUGR2",
    "decimals": 9,
    "image": "https://rango.vip/i/lwIIyg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.5418024217029773,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MILK",
    "name": "Milk",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1599fe55cda767b1f631ee7d414b41f5d6de393d",
    "decimals": 18,
    "image": "https://rango.vip/i/hfvB44",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00008645,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MILK",
    "name": "MILK Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf31826269ac7f452b1274cc884812f426c18ddca",
    "decimals": 18,
    "image": "https://rango.vip/i/1Dktha",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.5418024217029773,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MILK",
    "name": "MILK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf538296e7dd856af7044deec949489e2f25705bc",
    "decimals": 18,
    "image": "https://rango.vip/i/JnHouK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.915e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIS",
    "name": "MIS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b4d2e899658fb59b1d518b68fe836b100ee8958",
    "decimals": 18,
    "image": "https://rango.vip/i/4bMSNc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.14695803633560578,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOV",
    "name": "DOVU",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac3211a5025414af2866ff09c23fc18bc97e79b1",
    "decimals": 18,
    "image": "https://rango.vip/i/KE36j4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005869,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "VC",
    "name": "Velocore",
    "isPopular": false,
    "chainId": "324",
    "address": "0x99bbe51be7cce6c8b84883148fd3d12ace5787f2",
    "decimals": 18,
    "image": "https://rango.vip/i/LMwcrl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00014920292904120933,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VC",
    "name": "Venture Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AebrVZPfSH7KPAxPwnuqTZB9QNepdktk7HSSY4SNj7BM",
    "decimals": 9,
    "image": "https://rango.vip/i/3HhjhH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00087497,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "WPC",
    "name": "WPC",
    "isPopular": false,
    "chainId": "66",
    "address": "0x6f620ec89b8479e97a6985792d0c64f237566746",
    "decimals": 18,
    "image": "https://rango.vip/i/dsMZ3e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.000060603537111554434,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WPC",
    "name": "WORLD PEACE COIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc7c53760375530e5af29fded5e13989325299382",
    "decimals": 9,
    "image": "https://rango.vip/i/RSjP7G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000357,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAT",
    "name": "ratsDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2d3590fac07b91c9451e8d16c372ece9758f040f",
    "decimals": 18,
    "image": "https://rango.vip/i/KCBtCL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.5469e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "BYTE",
    "name": "ByteToken",
    "isPopular": false,
    "chainId": "1088",
    "address": "0x721532bc0da5ffaeb0a6a45fb24271e8098629a7",
    "decimals": 18,
    "image": "https://rango.vip/i/YljJ6S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 0.07697781007876346,
    "supportedSwappers": [
      "Netswap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BYTE",
    "name": "Byte",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde342a3e269056fc3305f9e315f4c40d917ba521",
    "decimals": 9,
    "image": "https://rango.vip/i/Sg06VR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000364,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BYTE",
    "name": "ByteAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9c2b4b0da5ebd20c29ef20758064554a55a88b68",
    "decimals": 18,
    "image": "https://rango.vip/i/TCYWe7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024963,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GIB",
    "name": "GIB",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3efcd659b7a45d14dda8a102836ce4b765c42324",
    "decimals": 18,
    "image": "https://rango.vip/i/sq2DzX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00005888988431579318,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SVNN",
    "name": "Savanna Haus",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DbM7mcJM9zitHanzKmFf7NH4SaEZZDCf5TPEgzwTmuh4",
    "decimals": 6,
    "image": "https://rango.vip/i/OV7w5x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00022149623202801644,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "MOBI",
    "name": "Mobius DAO Token",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x73a210637f6f6b7005512677ba6b3c96bb4aa44b",
    "decimals": 18,
    "image": "https://rango.vip/i/TepE8m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.000017376819544174255,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "C3",
    "name": "C3 Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xad01dffe604cdc172d8237566ee3a3ab6524d4c6",
    "decimals": 18,
    "image": "https://rango.vip/i/B3yYNO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.052341586932356625,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PUGAI",
    "name": "PUGAI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PugAiDuaQ5hzicBHAW9qrQQ8qi4B6sh3n7PknKhyjeX",
    "decimals": 5,
    "image": "https://rango.vip/i/FbMsgq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.81656e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GBURN",
    "name": "GBURN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4eea762311be76f9071aa01058c047ad12a017a1",
    "decimals": 18,
    "image": "https://rango.vip/i/51bCkP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0003916,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAIN",
    "name": "Main",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfceb206e1a80527908521121358b5e26caabaa75",
    "decimals": 18,
    "image": "https://rango.vip/i/XQ9Pgh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00043499,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HUB",
    "name": "Crypto Hub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6923f9b683111dcc0e20124e9a031deeae5dad93",
    "decimals": 18,
    "image": "https://rango.vip/i/gM3JSz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0010478,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EDOGE",
    "name": "EtherDoge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a7b7b9b2f7d0c63f66171721339705a6188a7d5",
    "decimals": 18,
    "image": "https://rango.vip/i/NBni1S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.19015e-13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "IUX",
    "name": "GeniuX",
    "isPopular": false,
    "chainId": "137",
    "address": "0x346404079b3792a6c548b072b9c4dddfb92948d5",
    "decimals": 18,
    "image": "https://rango.vip/i/mseV8y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00029085,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PEPEBNB",
    "name": "PEPEBNB",
    "isPopular": false,
    "chainId": "56",
    "address": "0x47fd014706081068448b89fc6baca2730977216a",
    "decimals": 9,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4.7896e-8,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MNTO",
    "name": "Minato",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf0dfad1817b5ba73726b02ab34dd4b4b00bcd392",
    "decimals": 18,
    "image": "https://rango.vip/i/II19Xx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.937503,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNTO",
    "name": "Minato",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97a9bac06f90940bce9caec2b880ff17707519e4",
    "decimals": 18,
    "image": "https://rango.vip/i/II19Xx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.937503,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BILLY",
    "name": "BILLY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3B5wuUrMEi5yATD7on46hKfej3pfmd7t1RKgrsN3pump",
    "decimals": 6,
    "image": "https://rango.vip/i/F1mrzL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0166932,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LADYS",
    "name": "Milady Meme Coin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3b60ff35d3f7f62d636b067dd0dc0dfdad670e4e",
    "decimals": 18,
    "image": "https://rango.vip/i/nUqum6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 7.8663e-8,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LADYS",
    "name": "Milady Meme Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12970e6868f88f6557b76120662c1b3e50a646bf",
    "decimals": 18,
    "image": "https://rango.vip/i/Cx0hPF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.8663e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAI",
    "name": "WienerAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe8526a77a2c3590e5973ba81308b90bea21fbff",
    "decimals": 18,
    "image": "https://rango.vip/i/O3D2Lx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016194,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAI",
    "name": "Watcher AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe8e531aa894969759d0d4b207a972a3a97d287f0",
    "decimals": 9,
    "image": "https://rango.vip/i/ldV8Yh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "KING",
    "name": "KING",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf3ec49acb3084618121741e4bbb20996d383e9b2",
    "decimals": 18,
    "image": "https://rango.vip/i/ZSciQx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0004202302822754143,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KING",
    "name": "KING",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9noXzpXnkyEcKF3AeXqUHTdR59V5uvrRBUZ9bwfQwxeq",
    "decimals": 9,
    "image": "https://rango.vip/i/xN1KN2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001636,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KING",
    "name": "KING",
    "isPopular": false,
    "chainId": "1",
    "address": "0x149d8290f653deb8e34c037d239d3d8eee9de5ad",
    "decimals": 18,
    "image": "https://rango.vip/i/m4vpBj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00042021,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KING",
    "name": "KING",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe28027c99c7746ffb56b0113e5d9708ac86fae8f",
    "decimals": 9,
    "image": "https://rango.vip/i/X9pvB8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001636,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ARENA",
    "name": "ESPL ARENA",
    "isPopular": false,
    "chainId": "56",
    "address": "0xcffd4d3b517b77be32c76da768634de6c738889b",
    "decimals": 18,
    "image": "https://rango.vip/i/k3u4sj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00646833820413999,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ZOO",
    "name": "ZooToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x1b88d7ad51626044ec62ef9803ea264da4442f32",
    "decimals": 18,
    "image": "https://rango.vip/i/o2wfvQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00355768,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZOO",
    "name": "ZooDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x09f098b155d561fc9f7bccc97038b7e3d20baf74",
    "decimals": 18,
    "image": "https://rango.vip/i/kDU0nz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00082994,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZOO",
    "name": "ZOO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c08638473cafbca3beb113616a1871f4bfad4f9",
    "decimals": 18,
    "image": "https://rango.vip/i/quSPXK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00370354454107271,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ZOO",
    "name": "ZOO Crypto World",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1d229b958d5ddfca92146585a8711aecbe56f095",
    "decimals": 18,
    "image": "https://rango.vip/i/S29Olm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00031163,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEY",
    "name": "SelfKey",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4cc19356f2d37338b9802aa8e8fc58b0373296e7",
    "decimals": 18,
    "image": "https://rango.vip/i/NyLSa9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0046708,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KEY",
    "name": "MoMo KEY",
    "isPopular": false,
    "chainId": "56",
    "address": "0x85c128ee1feeb39a59490c720a9c563554b51d33",
    "decimals": 18,
    "image": "https://rango.vip/i/7SlzQZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 9.99,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RVL",
    "name": "Revolotto",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6dc3d0d6ec970bf5522611d8eff127145d02b675",
    "decimals": 18,
    "image": "https://rango.vip/i/0SJPzD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.037519,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SEA",
    "name": "Sea Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfb52fc1f90dd2b070b9cf7ad68ac3d68905643fa",
    "decimals": 18,
    "image": "https://rango.vip/i/1YGf0I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.002310087540172697,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OSEA",
    "name": "Omnisea",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc72633f995e98ac3bb8a89e6a9c4af335c3d6e44",
    "decimals": 18,
    "image": "https://rango.vip/i/yDhD9r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.002502,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DND",
    "name": "DungeonSwap Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x14c358b573a4ce45364a3dbd84bbb4dae87af034",
    "decimals": 18,
    "image": "https://rango.vip/i/jS3f6v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00727602,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ARC",
    "name": "Arcane",
    "isPopular": false,
    "chainId": "25",
    "address": "0x289b8f552c9fd66f9cea35b193f7ca73ae24a5d5",
    "decimals": 18,
    "image": "https://rango.vip/i/LOhsh4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00011212926999719686,
    "supportedSwappers": [
      "CronaSwap"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ARC",
    "name": "Arcadeum",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7f465507f058e17ad21623927a120ac05ca32741",
    "decimals": 18,
    "image": "https://rango.vip/i/PN8mws",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02194489,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARC",
    "name": "ARC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc82e3db60a52cf7529253b4ec688f631aad9e7c2",
    "decimals": 18,
    "image": "https://rango.vip/i/8JeRBE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01860047,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARC",
    "name": "Arcade Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2903bd7db50f300b0884f7a15904baffc77f3ec7",
    "decimals": 18,
    "image": "https://rango.vip/i/GyNQnQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02733348,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARC",
    "name": "Archly",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9482c407d32204462d8cbbc0755e96c39b79878e",
    "decimals": 18,
    "image": "https://rango.vip/i/A6kJIX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.029340746151002295,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "COW",
    "name": "CoW Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xcb8b5cd20bdcaea9a010ac1f8d835824f5c87a04",
    "decimals": 18,
    "image": "https://rango.vip/i/k1uaTI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.182886,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COW",
    "name": "CoW Protocol Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdef1ca1fb7fbcdc777520aa7f396b4e015f497ab",
    "decimals": 18,
    "image": "https://rango.vip/i/wNyIFb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.182886,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RIA",
    "name": "Calvaria  DoE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b110fda4e20db18ad7052f8468a455de7449eb6",
    "decimals": 18,
    "image": "https://rango.vip/i/egXtPh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00095967,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAISY",
    "name": "Daisy Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40955d77f87123b71b145098358a60573ac7be96",
    "decimals": 18,
    "image": "https://rango.vip/i/XtlMBf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.10003,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "OPX",
    "name": "OPX Finance",
    "isPopular": false,
    "chainId": "10",
    "address": "0xcdb4bb51801a1f399d4402c61bc098a72c382e65",
    "decimals": 18,
    "image": "https://rango.vip/i/NlVl3P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.076418,
    "supportedSwappers": [
      "OpenOceanOptimism"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MQL",
    "name": "MiraQle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x428dc22668e6f3468273634067e5545ed5417a3e",
    "decimals": 18,
    "image": "https://rango.vip/i/MO6hWd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000111,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CBT",
    "name": "Community Business Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa93660c3f6a848556bb8e265f994160a1f2b289",
    "decimals": 18,
    "image": "https://rango.vip/i/jFwGTO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.99858e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DST",
    "name": "DAYSTARTER",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe3a46b2bc1d83c731d58cab765d3b45bce789095",
    "decimals": 18,
    "image": "https://rango.vip/i/yLix2H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02569237,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WHL",
    "name": "WhaleRoom",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2af72850c504ddd3c1876c66a914caee7ff8a46a",
    "decimals": 18,
    "image": "https://rango.vip/i/ndUAQL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.280764,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRC",
    "name": "Force",
    "isPopular": false,
    "chainId": "1",
    "address": "0x58083b54013631bacc0bbb6d4efa543fee1d9ce0",
    "decimals": 18,
    "image": "https://rango.vip/i/wYutox",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00681906,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANC",
    "name": "Wrapped ANC Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f3adc247e91c3c50bc08721355a41037e89bc20",
    "decimals": 18,
    "image": "https://rango.vip/i/P9IQlu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00792378,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BLK",
    "name": "BLINk",
    "isPopular": false,
    "chainId": "56",
    "address": "0x63870a18b6e42b01ef1ad8a2302ef50b7132054f",
    "decimals": 6,
    "image": "https://rango.vip/i/So8xE6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0021999493006798826,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BITS",
    "name": "BitSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42496acd2c7b52ce90ed65ada6cafb0e893e2474",
    "decimals": 9,
    "image": "https://rango.vip/i/UCrizz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00030059,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAID",
    "name": "MaidCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4af698b479d0098229dc715655c667ceb6cd8433",
    "decimals": 18,
    "image": "https://rango.vip/i/tdVV8g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.29931682725858827,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VIA",
    "name": "Octavia",
    "isPopular": false,
    "chainId": "56",
    "address": "0x21ac3bb914f90a2bb1a16088e673a9fdda641434",
    "decimals": 18,
    "image": "https://rango.vip/i/JTF23N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.061682,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XCN",
    "name": "Onyxcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2cd3d43c775978a96bdbf12d733d5a1ed94fb18",
    "decimals": 18,
    "image": "https://rango.vip/i/9CufxQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00124969,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XCN",
    "name": "Onyxcoin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7324c7c0d95cebc73eea7e85cbaac0dbdf88a05b",
    "decimals": 18,
    "image": "https://rango.vip/i/9CufxQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00124969,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "XCN",
    "name": "Onyxcoin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9c632e6aaa3ea73f91554f8a3cb2ed2f29605e0c",
    "decimals": 18,
    "image": "https://rango.vip/i/GoUEeU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00124969,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "XCN",
    "name": "Chain",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x58bbc087e36db40a84b22c1b93a042294deeafed",
    "decimals": 18,
    "image": "https://rango.vip/i/x5I6qU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0012508462114366717,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "EMC",
    "name": "EdgeMatrix Computing network",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xdfb8be6f8c87f74295a87de951974362cedcfa30",
    "decimals": 18,
    "image": "https://rango.vip/i/EVKfyL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.098438,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RBT",
    "name": "RIBBIT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "65nTNuJGHme4PQvKQyJykKp1bJAkK4A8Q66sd2yBWugf",
    "decimals": 6,
    "image": "https://rango.vip/i/tUV8Ml",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000167,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RBT",
    "name": "Reboot World",
    "isPopular": false,
    "chainId": "1",
    "address": "0x902169d471b62f22ffadc690ca292ec454d0b260",
    "decimals": 18,
    "image": "https://rango.vip/i/Tj1k3v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0003189,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BLOCK",
    "name": "Blockasset",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk",
    "decimals": 6,
    "image": "https://rango.vip/i/jQZ8Sr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.050184,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOCK",
    "name": "BlockGames",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8fc17671d853341d9e8b001f5fc3c892d09cb53a",
    "decimals": 18,
    "image": "https://rango.vip/i/Q2RUEE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.053627,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOCK",
    "name": "Block Browser",
    "isPopular": false,
    "chainId": "1",
    "address": "0x226d6d842d49b4d757bef1632053a198d5d9c8aa",
    "decimals": 18,
    "image": "https://rango.vip/i/1eSsTw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.12022,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOCK",
    "name": "Block",
    "isPopular": false,
    "chainId": "1",
    "address": "0x807a0774236a0fbe9e7f8e7df49edfed0e6777ea",
    "decimals": 18,
    "image": "https://rango.vip/i/8Pp2MC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.05039271830119094,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "OK",
    "name": "Okcash",
    "isPopular": false,
    "chainId": "10",
    "address": "0xd3ac016b1b8c80eeadde4d186a9138c9324e4189",
    "decimals": 18,
    "image": "https://rango.vip/i/cuS5Yr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00336855,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "OK",
    "name": "Okcash",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xd3ac016b1b8c80eeadde4d186a9138c9324e4189",
    "decimals": 18,
    "image": "https://rango.vip/i/KyEprU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00336855,
    "supportedSwappers": [
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OK",
    "name": "Okcash",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd3ac016b1b8c80eeadde4d186a9138c9324e4189",
    "decimals": 18,
    "image": "https://rango.vip/i/utUWGB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00336855,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "OK",
    "name": "Okcash",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x979584b07e1e14c7718c9fdab2e35bbb5fec2c59",
    "decimals": 18,
    "image": "https://rango.vip/i/y3W8Ii",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00336855,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OK",
    "name": "Okcash",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd3ac016b1b8c80eeadde4d186a9138c9324e4189",
    "decimals": 18,
    "image": "https://rango.vip/i/cHh8oO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00336855,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "ARB",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/10e5e5b06d78ffbb61fd9f89209dee5fd4446ed0550cbb8e3747da79e10d9dc6",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ARB.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.49579049859583996,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ARB",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0x912ce59144191c1204e64559fe8253a0e49e6548",
    "decimals": 18,
    "image": "https://rango.vip/i/C6Rlle",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.493482,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "ArbitrumUniSwapV3",
      "Bridgers",
      "MayaProtocol",
      "SushiArbitrum",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARB",
    "name": "Arbitrum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb50721bcf8d664c30412cfbc6cf7a15145234ad1",
    "decimals": 18,
    "image": "https://rango.vip/i/q4B1nK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.493482,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ARB",
    "name": "ARB Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9tzZzEHsKnwFL1A3DyFJwj36KnZj3gZ7g4srWp9YTEoh",
    "decimals": 6,
    "image": "https://rango.vip/i/TXITtr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0002164,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TX",
    "name": "TraderX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7b18e5d77622ba37173801f77745a0654df825ff",
    "decimals": 18,
    "image": "https://rango.vip/i/kHK3Mh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00692829,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TX",
    "name": "Tradix",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8954d907520532c1f0d89d42569232fd0f995fdf",
    "decimals": 8,
    "image": "https://rango.vip/i/JdN95w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00032583,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "TX",
    "name": "TX Token",
    "isPopular": false,
    "chainId": "728126428",
    "address": "THeqBTH8vfn2FT65PxzXUCKKFAAxV4ciXC",
    "decimals": 8,
    "image": "https://rango.vip/i/rSuFW7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.08372405078459778,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DFT",
    "name": "DigiFinex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2a54f1ec1f09316ef12c1770d32ed8f21b1fb6a",
    "decimals": 8,
    "image": "https://rango.vip/i/9JHcxV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00983497,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DFT",
    "name": "DFuture",
    "isPopular": false,
    "chainId": "56",
    "address": "0x42712df5009c20fee340b245b510c0395896cf6e",
    "decimals": 18,
    "image": "https://rango.vip/i/jbFDEs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.009885067610390487,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FRN",
    "name": "FREE ROSS NOW",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "J5tzd1ww1V1qrgDUQHVCGqpmpbnEnjzGs9LAqJxwkNde",
    "decimals": 6,
    "image": "https://rango.vip/i/1xguiI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0011162346891504913,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVIL",
    "name": "Doctor Evil",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcf9560b9e952b195d408be966e4f6cf4ab8206e5",
    "decimals": 18,
    "image": "https://rango.vip/i/5qFpJ9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004203,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PEX",
    "name": "PearDAO",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6a0b66710567b6beb81a71f7e9466450a91a384b",
    "decimals": 18,
    "image": "https://rango.vip/i/Co2UQ8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0265178,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAB",
    "name": "CASHCAB",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73af41fe7054057218e0eb07fe43ba5f25c7d79f",
    "decimals": 18,
    "image": "https://rango.vip/i/wDiqO4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04566159,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "POST",
    "name": "Post Tech",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc29b9c4f45fd4f07768cf913acde0c7772b47124",
    "decimals": 18,
    "image": "https://rango.vip/i/A3I0pV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00038512,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BERN",
    "name": "BonkEarn",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CKfatsPMUf8SkiURsDXs7eK6GWb4Jsd6UDbs7twMCWxo",
    "decimals": 5,
    "image": "https://rango.vip/i/EYA8yx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00140146,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GB",
    "name": "Good Bridging",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x90842eb834cfd2a1db0b1512b254a18e4d396215",
    "decimals": 9,
    "image": "https://rango.vip/i/6GPeTV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00015021771851960044,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CHESS",
    "name": "Chess",
    "isPopular": false,
    "chainId": "56",
    "address": "0x20de22029ab63cf9a7cf5feb2b737ca1ee4c82a6",
    "decimals": 18,
    "image": "https://rango.vip/i/PaNlm0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.147179,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VLT",
    "name": "Bankroll Vault",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b785a0322126826d8226d77e173d75dafb84d11",
    "decimals": 18,
    "image": "https://rango.vip/i/ifJ6kc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.176953,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNT",
    "name": "Cryption Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x429876c4a6f89fb470e92456b8313879df98b63c",
    "decimals": 18,
    "image": "https://rango.vip/i/DHDM7z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.371797257353482e-9,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CNT",
    "name": "Cryption Network Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x989d099d29f62b839c8cbd41c82c6554a5515752",
    "decimals": 18,
    "image": "https://rango.vip/i/DHDM7z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 6.371797257353482e-9,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CNT",
    "name": "Cryption Network Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd1e6354fb05bf72a8909266203dab80947dceccf",
    "decimals": 18,
    "image": "https://rango.vip/i/GQY30Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 6.371797257353482e-9,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZENI",
    "name": "Edoverse Zeni",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9c6666d5ff4b53b5eb3bd866664c15d0bfcecaa7",
    "decimals": 18,
    "image": "https://rango.vip/i/sYda9f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CXT",
    "name": "Covalent X Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7abc8a5768e6be61a6c693a6e4eacb5b60602c4d",
    "decimals": 18,
    "image": "https://rango.vip/i/G5O15v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.070109,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SCS",
    "name": "Solcasino Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SCSuPPNUSypLBsV4darsrYNg4ANPgaGhKhsA3GmMyjz",
    "decimals": 5,
    "image": "https://rango.vip/i/MC0sqz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00429219,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "VOLT",
    "name": "Volt Inu",
    "isPopular": false,
    "chainId": "137",
    "address": "0x7f792db54b0e580cdc755178443f0430cf799aca",
    "decimals": 9,
    "image": "https://rango.vip/i/CtUUF9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2.52707e-7,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VOLT",
    "name": "Volt Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7db5af2b9624e1b3b4bb69d6debd9ad1016a58ac",
    "decimals": 9,
    "image": "https://rango.vip/i/lNIbgJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.502954289408096e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VOLT",
    "name": "Volt Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f792db54b0e580cdc755178443f0430cf799aca",
    "decimals": 9,
    "image": "https://rango.vip/i/9uLwNr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.52707e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUN",
    "name": "Lunyr",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa05a73ffe78ef8f1a739473e462c54bae6567d9",
    "decimals": 18,
    "image": "https://rango.vip/i/RpJJ0i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01669453,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TKN",
    "name": "Monolith TKN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaaaf91d9b90df800df4f55c205fd6989c977e73a",
    "decimals": 8,
    "image": "https://rango.vip/i/boUCMJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04333403,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TKN",
    "name": "TokenDao",
    "isPopular": false,
    "chainId": "1",
    "address": "0x82d09e30d5d682d69b4a5d97c61b7ba651457625",
    "decimals": 18,
    "image": "https://rango.vip/i/A7KLZN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02167597,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "TKN",
    "name": "Token Name Service",
    "isPopular": false,
    "chainId": "10",
    "address": "0x3eb398fec5f7327c6b15099a9681d9568ded2e82",
    "decimals": 18,
    "image": "https://rango.vip/i/Op8DkU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.049511240196609414,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MAY",
    "name": "Mayfair",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf9df075716b2d9b95616341dc6bc64c85e56645c",
    "decimals": 18,
    "image": "https://rango.vip/i/bvmdsP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00615193,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PTOY",
    "name": "Patientory",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ae4bf2c33a8e667de34b54938b0ccd03eb8cc06",
    "decimals": 8,
    "image": "https://rango.vip/i/gI4oou",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00438047,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONX",
    "name": "OnX.finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0ad1806fd3e7edf6ff52fdb822432e847411033",
    "decimals": 18,
    "image": "https://rango.vip/i/aslQB9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00798946,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CRM",
    "name": "Crema",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CRMaDAzKCWYbhUfsKYA8448vaA1qUzCETd7gNBDzQ1ks",
    "decimals": 9,
    "image": "https://rango.vip/i/yjBLGr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008834699165036469,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHDW",
    "name": "Shadow Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y",
    "decimals": 9,
    "image": "https://rango.vip/i/2yXgbg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.353484,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DLT",
    "name": "Agrello",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07e3c70653548b04f0a75970c1f81b4cbbfb606f",
    "decimals": 18,
    "image": "https://rango.vip/i/nFQYAi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00057881,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HVN",
    "name": "Hiveterminal",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0eb85285d83217cd7c891702bcbc0fc401e2d9d",
    "decimals": 8,
    "image": "https://rango.vip/i/yWnirs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004774,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUZZ",
    "name": "Bee Tools",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa704662ecb62be83f88ca1a3b5277a381cb32dce",
    "decimals": 18,
    "image": "https://rango.vip/i/1rQStz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017252,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IND",
    "name": "Indorse",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf8e386eda857484f5a12e4b5daa9984e06e73705",
    "decimals": 18,
    "image": "https://rango.vip/i/pvuKqg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0016390924432463675,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIBE",
    "name": "VIBE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe8ff5c9c75deb346acac493c463c8950be03dfba",
    "decimals": 18,
    "image": "https://rango.vip/i/O8djp1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.39785e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUB",
    "name": "Substratum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8d75959f1e61ec2571aa72798237101f084de63a",
    "decimals": 18,
    "image": "https://rango.vip/i/kLGQvs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00023859,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IETH",
    "name": "iEthereum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc71d8baaa436aa7e42da1f40bec48f11ab3c9e4a",
    "decimals": 8,
    "image": "https://rango.vip/i/BkxEa6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02285637,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IETH",
    "name": "Instadapp ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc383a3833a87009fd9597f8184979af5edfad019",
    "decimals": 18,
    "image": "https://rango.vip/i/0DosCV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2681.41,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "DNA",
    "name": "DNA Dollar",
    "isPopular": false,
    "chainId": "25",
    "address": "0xcc57f84637b441127f2f74905b9d99821b47b20c",
    "decimals": 18,
    "image": "https://rango.vip/i/sjQSCP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.004317891544158664,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DNA",
    "name": "EncrypGen",
    "isPopular": false,
    "chainId": "1",
    "address": "0xef6344de1fcfc5f48c30234c16c1389e8cdc572c",
    "decimals": 18,
    "image": "https://rango.vip/i/4gJG0W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00222869,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SEND",
    "name": " send",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x3f14920c99beb920afa163031c4e47a3e03b3e4a",
    "decimals": 0,
    "image": "https://rango.vip/i/hviT0h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00008329,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SEND",
    "name": "Send",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SENDdRQtYMWaQrBroBrJ2Q53fgVuq95CV9UPGEvpCxa",
    "decimals": 6,
    "image": "https://rango.vip/i/n4xVHf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00314379,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SEND",
    "name": "Send",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe362a9415d8c332a78ba6734620f1afc19099bbc",
    "decimals": 18,
    "image": "https://rango.vip/i/Ljvol3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SEND",
    "name": "Sendpicks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d48206b97b164555c8fc7a40d59a7230e055166",
    "decimals": 18,
    "image": "https://rango.vip/i/LiB7Q4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00032827,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SEND",
    "name": "Send Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f14920c99beb920afa163031c4e47a3e03b3e4a",
    "decimals": 0,
    "image": "https://rango.vip/i/hviT0h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008329,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BON",
    "name": "bon shatiro",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6bPFbxgpsFZg6WDruj7vrodq4GY7K7TmqUnAm1byu3PW",
    "decimals": 6,
    "image": "https://rango.vip/i/Jg3aFa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000005792515872326007,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRD",
    "name": "Bread",
    "isPopular": false,
    "chainId": "1",
    "address": "0x558ec3152e2eb2174905cd19aea4e34a23de9ad6",
    "decimals": 18,
    "image": "https://rango.vip/i/3H6AFT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00900092,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APPC",
    "name": "AppCoins",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a7a8bd9106f2b8d977e08582dc7d24c723ab0db",
    "decimals": 18,
    "image": "https://rango.vip/i/H6nRye",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00050143,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EKO",
    "name": "EchoLinkV2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1cf3e03f7360288dd01d0a9cfab266cfcdb3e0c1",
    "decimals": 18,
    "image": "https://rango.vip/i/8vZYCK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000762,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IC",
    "name": "Icy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa876f27f13a9eb6e621202cefdd5afc4a90e6457",
    "decimals": 9,
    "image": "https://rango.vip/i/iIhK4V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000145,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIT",
    "name": "AIT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x79650799e7899a802cb96c0bc33a6a8d4ce4936c",
    "decimals": 18,
    "image": "https://rango.vip/i/jlCQje",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00218029,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIT",
    "name": "AIT Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x89d584a1edb3a70b3b07963f9a3ea5399e38b136",
    "decimals": 18,
    "image": "https://rango.vip/i/rwNtzA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.059405,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHAT",
    "name": "Solchat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "947tEoG318GUmyjVYhraNRvWpMX7fpBTDQFBoJvSkSG3",
    "decimals": 9,
    "image": "https://rango.vip/i/EMn7uK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.47,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHAT",
    "name": "VectorChat.ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb3d7f42c58abd83616ad7c8c72473ee46df2678",
    "decimals": 18,
    "image": "https://rango.vip/i/Yujf0I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0330629,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LET",
    "name": "Linkeye",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa3118b34522580c35ae27f6cf52da1dbb756288",
    "decimals": 6,
    "image": "https://rango.vip/i/MXX4aK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00025981,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DMT",
    "name": "Dream Machine Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8b0e6f19ee57089f7649a455d89d7bc6314d04e8",
    "decimals": 18,
    "image": "https://rango.vip/i/5nzwWy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 31.94,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DMT",
    "name": "Dark Matter Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd28449bb9bb659725accad52947677cce3719fd7",
    "decimals": 18,
    "image": "https://rango.vip/i/ei6Smb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 8.71,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DMT",
    "name": "Dark Matter Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x79126d32a86e6663f3aaac4527732d0701c1ae6c",
    "decimals": 18,
    "image": "https://rango.vip/i/mTVR2y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.71,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DMT",
    "name": "DarkMatter",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5b1d655c93185b06b00f7925791106132cb3ad75",
    "decimals": 18,
    "image": "https://rango.vip/i/ePWJlf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00153999,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DMT",
    "name": "DMT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b7f0e51cd1739d6c96982d55ad8fa634dd43a9c",
    "decimals": 18,
    "image": "https://rango.vip/i/z0Lq8m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 31.94,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DMT",
    "name": "DMT",
    "isPopular": false,
    "chainId": "128",
    "address": "0x57a7bcdfab1631aca9d6e0f39959477182cfae12",
    "decimals": 18,
    "image": "https://rango.vip/i/jPZF4T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.000005248431494502254,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XBP",
    "name": "BlitzPredict",
    "isPopular": false,
    "chainId": "1",
    "address": "0x28dee01d53fed0edf5f6e310bf8ef9311513ae40",
    "decimals": 18,
    "image": "https://rango.vip/i/XNw9Qw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005195386977958908,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAX",
    "name": "DAEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b4bdc478791897274652dc15ef5c135cae61e60",
    "decimals": 18,
    "image": "https://rango.vip/i/rAdoHY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00318234,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MT",
    "name": "MyToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b4e2b4b13d125238aa0480dd42b4f6fc71b37cc",
    "decimals": 18,
    "image": "https://rango.vip/i/4hpYXD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009663,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GSC",
    "name": "Global Social Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x228ba514309ffdf03a81a205a6d040e429d6e80c",
    "decimals": 18,
    "image": "https://rango.vip/i/E4qlru",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00077935,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DGX",
    "name": "Digix",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4f3afec4e5a3f2a6a1a411def7d7dfe50ee057bf",
    "decimals": 9,
    "image": "https://rango.vip/i/MVDpQK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 52.81,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DGTX",
    "name": "Digitex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc666081073e8dff8d3d1c2292a29ae1a2153ec09",
    "decimals": 18,
    "image": "https://rango.vip/i/5XdxPx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001166,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MFT",
    "name": "Hifi Finance",
    "isPopular": false,
    "chainId": "137",
    "address": "0x91ca694d2b293f70fe722fba7d8a5259188959c3",
    "decimals": 18,
    "image": "https://rango.vip/i/UGn6A6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0038975147940598885,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MFT",
    "name": "Mainframe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf2c7238198ad8b389666574f2d8bc411a4b7428",
    "decimals": 18,
    "image": "https://rango.vip/i/fT26S1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0040196,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KBC",
    "name": "Kabuni",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa88920b4a35f7d0e81bc586ce1875036fced9154",
    "decimals": 18,
    "image": "https://rango.vip/i/DjTlbs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01221481,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEMO",
    "name": "LemoChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60c24407d01782c2175d32fe7c8921ed732371d1",
    "decimals": 18,
    "image": "https://rango.vip/i/cv2hdh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000513,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOTO",
    "name": "TOTO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x973e00eee6d180b5a0eb08ce3047ac4ea7a45cd5",
    "decimals": 9,
    "image": "https://rango.vip/i/5u6siP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.66654e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RYO",
    "name": "RYO Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c7fe8f97db97cbccc76989ab742afc66ca6e75c",
    "decimals": 18,
    "image": "https://rango.vip/i/xYhxro",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.96,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XDNA",
    "name": "extraDNA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e57c27761ebbd381b0f9d09bb92ceb51a358abb",
    "decimals": 18,
    "image": "https://rango.vip/i/pzeaMl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000796,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HB",
    "name": "HB",
    "isPopular": false,
    "chainId": "128",
    "address": "0xe4ab411dcfb3332486fd3ca91ca9ad9b4d820063",
    "decimals": 8,
    "image": "https://rango.vip/i/pDRXui",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 7.739971121655251,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YUKI",
    "name": "Samurai Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "53yANribNp1WzRsciY6upAN2VPY85waZEtADTeJhtQGN",
    "decimals": 6,
    "image": "https://rango.vip/i/COmylW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002862,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVN",
    "name": "Evn Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9af15d7b8776fa296019979e70a5be53c714a7ec",
    "decimals": 18,
    "image": "https://rango.vip/i/LKB6PR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0005141158791938532,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RTH",
    "name": "Rotharium",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3fd8f39a962efda04956981c31ab89fab5fb8bc8",
    "decimals": 18,
    "image": "https://rango.vip/i/On3QEF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.29,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAA",
    "name": "Moon Rabbit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c6bf16c273636523c29db7db04396143770f6a0",
    "decimals": 18,
    "image": "https://rango.vip/i/GQXg2c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000151,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRXC",
    "name": "TronClassic",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad5fe5b0b8ec8ff4565204990e4405b2da117d8e",
    "decimals": 0,
    "image": "https://rango.vip/i/MY8yLW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.9983e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIR",
    "name": "Mirror Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x09a3ecafa817268f77be1283176b946c4ff2e608",
    "decimals": 18,
    "image": "https://rango.vip/i/HZW3EH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01204224,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MIR",
    "name": "Mirror Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5b6dcf557e2abe2323c48445e8cc948910d8c2c9",
    "decimals": 18,
    "image": "https://rango.vip/i/0AjzSP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01204224,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PXG",
    "name": "PlayGame",
    "isPopular": false,
    "chainId": "1",
    "address": "0x47e67ba66b0699500f18a53f94e2b9db3d47437e",
    "decimals": 18,
    "image": "https://rango.vip/i/6fLSIa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002699,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HERB",
    "name": "Herbalist",
    "isPopular": false,
    "chainId": "1",
    "address": "0x04a020325024f130988782bd5276e53595e8d16e",
    "decimals": 8,
    "image": "https://rango.vip/i/gdSHX2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000196,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HPT",
    "name": "Heco-Peg HPT Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0xe499ef4616993730ced0f31fa2703b92b50bb536",
    "decimals": 18,
    "image": "https://rango.vip/i/D2cKjH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.000059132848779379154,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTU",
    "name": "BTU Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb683d83a532e2cb7dfa5275eed3698436371cc9f",
    "decimals": 18,
    "image": "https://rango.vip/i/9zuXQL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.165782,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BTU",
    "name": "BTU Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xba9a5dd807c9f072850be15a52df3408ba25fd18",
    "decimals": 18,
    "image": "https://rango.vip/i/eVjvsf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.12797498271770835,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOTX",
    "name": "BOTXCOIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xef19f4e48830093ce5bc8b3ff7f903a0ae3e9fa1",
    "decimals": 18,
    "image": "https://rango.vip/i/qBELB6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00084429,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PROPEL",
    "name": "Propel",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe0ce60af0850bf54072635e66e79df17082a1109",
    "decimals": 18,
    "image": "https://rango.vip/i/MZACXI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000192,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BSOV",
    "name": "BitcoinSoV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26946ada5ecb57f3a1f91605050ce45c482c9eb1",
    "decimals": 8,
    "image": "https://rango.vip/i/NcmHJ8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01912319,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYM",
    "name": "SymVerse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2fd61567c29e7adb4ca17e60e1f4a3fcfe68acb8",
    "decimals": 18,
    "image": "https://rango.vip/i/z0gvfl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00274756,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JDC",
    "name": "JD Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2fe39f22eac6d3c1c86dd9d143640ebb94609fce",
    "decimals": 18,
    "image": "https://rango.vip/i/9JMNzn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00851738,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TUNA",
    "name": "Tunachain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xadd353fb2e2c563383ff3272a500f3e7134dafe4",
    "decimals": 18,
    "image": "https://rango.vip/i/1olZgK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01244395,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BXC",
    "name": "BXC",
    "isPopular": false,
    "chainId": "128",
    "address": "0x15f4c382926f8a083bc924abd3aa84ce3982caa9",
    "decimals": 18,
    "image": "https://rango.vip/i/5ycpmD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.035957912993527845,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLT",
    "name": "CoinLoan",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2001f2a0cf801ecfda622f6c28fb6e10d803d969",
    "decimals": 8,
    "image": "https://rango.vip/i/JbBHhq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.329909,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BALI",
    "name": "BALI Skull",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb4d71ecbc4f6945f1ab0faff17c9bdeaf86b847",
    "decimals": 18,
    "image": "https://rango.vip/i/I8jAfN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.21823e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZOOM",
    "name": "ChainZoom",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e29793c9bdeb6ce5a84c2beced91ad50c530b16",
    "decimals": 18,
    "image": "https://rango.vip/i/kEJHp8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0027308,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIE",
    "name": "DeFiPIE Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x607c794cda77efb21f8848b7910ecf27451ae842",
    "decimals": 18,
    "image": "https://rango.vip/i/BzGWiU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.005408455622963451,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CBU",
    "name": "Banque Universal",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcef46305d096fa876dd23048bf80f9345282e3fc",
    "decimals": 0,
    "image": "https://rango.vip/i/GuosW7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.133334,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MINI",
    "name": "Mini",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4d953cf077c0c95ba090226e59a18fcf97db44ec",
    "decimals": 18,
    "image": "https://rango.vip/i/rrrfes",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00638041,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RICK",
    "name": "Pick Or Rick",
    "isPopular": false,
    "chainId": "1",
    "address": "0x75430d0782a443bd4f1c92c69009599dea53a206",
    "decimals": 9,
    "image": "https://rango.vip/i/CnQ9L4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.44191e-13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ITAM",
    "name": "ITAM",
    "isPopular": false,
    "chainId": "56",
    "address": "0x04c747b40be4d535fc83d09939fb0f626f32800b",
    "decimals": 18,
    "image": "https://rango.vip/i/Zem7oc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00705377,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YAM",
    "name": "YAM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0aacfbec6a24756c20d41914f2caba817c0d8521",
    "decimals": 18,
    "image": "https://rango.vip/i/3y6Xbq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04033212,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ENG",
    "name": "ENGLAND",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4XQvdipJBdrb5hUgUrbZPPFmp6BCav41n55dc7KDYW3m",
    "decimals": 6,
    "image": "https://rango.vip/i/qKFAKD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002804,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ENG",
    "name": "ENG Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GtLBsmS6Z4FC9gmnCFS2ZzCKmb9vYL3kELnjVmxHurnh",
    "decimals": 2,
    "image": "https://rango.vip/i/Mk8PjS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.009261158693977558,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ENG",
    "name": "Enigma",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0ee6b27b759c9893ce4f094b49ad28fd15a23e4",
    "decimals": 8,
    "image": "https://rango.vip/i/YEV8ht",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00932606,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JBX",
    "name": "Juicebox",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4554cc10898f92d45378b98d6d6c2dd54c687fb2",
    "decimals": 18,
    "image": "https://rango.vip/i/nz0RQb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00129799,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DUST",
    "name": "DUST Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ",
    "decimals": 9,
    "image": "https://rango.vip/i/1vMBAj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.230923,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUST",
    "name": "Dust Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb5b1b659da79a2507c27aad509f15b4874edc0cc",
    "decimals": 9,
    "image": "https://rango.vip/i/uC9t7w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.230923,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STA",
    "name": "Statera",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa7de087329bfcda5639247f96140f9dabe3deed1",
    "decimals": 18,
    "image": "https://rango.vip/i/ndmMIH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00168479,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STA",
    "name": "STA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06874f973dc3c96dc22a10ef0d0609f877f335ea",
    "decimals": 18,
    "image": "https://rango.vip/i/isAHF3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00248154,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XOR",
    "name": "Sora Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40fd72257597aa14c7231a7b1aaa29fce868f677",
    "decimals": 18,
    "image": "https://rango.vip/i/QbO66D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000749,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "KFC",
    "name": "KungFu Cat",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xa4089e74354b03b56d75961a816f71432ff596f7",
    "decimals": 18,
    "image": "https://rango.vip/i/rQgsqu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00001087,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KFC",
    "name": "Chicken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe63684bcf2987892cefb4caa79bd21b34e98a291",
    "decimals": 18,
    "image": "https://rango.vip/i/IeVLJj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.79,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CORE",
    "name": "CORE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2maDvG9nXGVstjdnsCZoSsNtjoda1SsZTLrHBVRgLR5F",
    "decimals": 9,
    "image": "https://rango.vip/i/2rXZ8T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.8512675686224598,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CORE",
    "name": "cVault.finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62359ed7505efc61ff1d56fef82158ccaffa23d7",
    "decimals": 18,
    "image": "https://rango.vip/i/ykTI5q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4473.23,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CORE",
    "name": "CoreConnect",
    "isPopular": false,
    "chainId": "1",
    "address": "0x90edf25b14393350f0c1b5b12b6cb3cd3781fb4a",
    "decimals": 18,
    "image": "https://rango.vip/i/y85y8z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01790743,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CORE",
    "name": "CoreAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b91f277501cf8322ebe34f137dd35b384b353c7",
    "decimals": 9,
    "image": "https://rango.vip/i/tylHbq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00054592,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "CORE",
    "name": "Core Markets",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x233b23de890a8c21f6198d03425a2b986ae05536",
    "decimals": 18,
    "image": "https://rango.vip/i/aUMbAS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00842721,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUP",
    "name": "SAKURA UNITED PLATFORM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5ec1a08902ae0ae5323466c35ea49a37409e4ca",
    "decimals": 18,
    "image": "https://rango.vip/i/lvQx2w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.203699,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DFD",
    "name": "DefiDollar DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20c36f062a31865bed8a5b1e512d9a1a20aa333a",
    "decimals": 18,
    "image": "https://rango.vip/i/3HoKsQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.007298774747479064,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DFD",
    "name": "DefiDollar DAO",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9899a98b222fcb2f3dbee7df45d943093a4ff9ff",
    "decimals": 18,
    "image": "https://rango.vip/i/041wMc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.007298774747479064,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LST",
    "name": "Liquid Staking Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LSTxxxnJzKDFSLr4dUkPcmCf5VyryEqzPLz5j4bpxFp",
    "decimals": 9,
    "image": "https://rango.vip/i/xPhiG4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 171.23,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LST",
    "name": "Lendroid Support Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4de2573e27e648607b50e1cfff921a33e4a34405",
    "decimals": 18,
    "image": "https://rango.vip/i/67sQ9H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.002619913147498856,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "UNW",
    "name": "Uniwhale Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5b65cd9feb54f1df3d0c60576003344079f8dc06",
    "decimals": 18,
    "image": "https://rango.vip/i/NFtJWF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00351266,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GYSR",
    "name": "Geyser",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc48f61a288a08f1b80c2edd74652e1276b6a168c",
    "decimals": 18,
    "image": "https://rango.vip/i/RzPhAw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03956547,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GYSR",
    "name": "Geyser",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbea98c05eeae2f3bc8c3565db7551eb738c8ccab",
    "decimals": 18,
    "image": "https://rango.vip/i/F2Yx3s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03956547,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "GYSR",
    "name": "Geyser",
    "isPopular": false,
    "chainId": "10",
    "address": "0x117cfd9060525452db4a34d51c0b3b7599087f05",
    "decimals": 18,
    "image": "https://rango.vip/i/FWbb5c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.03878472477592067,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGOV",
    "name": "Answer Governance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1420af453fd7bf940573431d416cace7ff8280c",
    "decimals": 18,
    "image": "https://rango.vip/i/asXp4g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004545,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EPAN",
    "name": "Paypolitan",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72630b1e3b42874bf335020ba0249e3e9e47bafc",
    "decimals": 18,
    "image": "https://rango.vip/i/sZ5ZqX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00179336,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DFIAT",
    "name": "DeFiato",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1045f5ccb01daea4f8eab055f5fcbb7c0e7c89f0",
    "decimals": 18,
    "image": "https://rango.vip/i/8dD4B3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00099446,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNN",
    "name": "UNION Protocol Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x226f7b842e0f0120b7e194d05432b3fd14773a9d",
    "decimals": 18,
    "image": "https://rango.vip/i/okh1K1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001681,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UNN",
    "name": "Union",
    "isPopular": false,
    "chainId": "137",
    "address": "0x67480287cb3715d1d9429b38772c71d6e94c16da",
    "decimals": 18,
    "image": "https://rango.vip/i/kd0P4F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00017174031307807286,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RARE",
    "name": "RARE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HmLspvjpQtQEnArUyJoBSFGS38gNJwBuxAeqSV9SZ66K",
    "decimals": 9,
    "image": "https://rango.vip/i/c5RTx0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00210814,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RARE",
    "name": "SuperRare",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba5bde662c17e2adff1075610382b9b691296350",
    "decimals": 18,
    "image": "https://rango.vip/i/uOriUQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.145496,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RARE",
    "name": "RARE.UNIQUE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x93dfaf57d986b9ca77df9376c50878e013d9c7c8",
    "decimals": 18,
    "image": "https://rango.vip/i/asbhe8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00779823,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EDC",
    "name": "Edcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d5963ba32e877871dff3e2e697283dc64066271",
    "decimals": 18,
    "image": "https://rango.vip/i/WJC2Mk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02414421,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "EDC",
    "name": "EDC",
    "isPopular": false,
    "chainId": "128",
    "address": "0x68a0a1fef18dfcc422db8be6f0f486dea1999edc",
    "decimals": 9,
    "image": "https://rango.vip/i/wvosji",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 4.898051925750492,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PFI",
    "name": "PartyFinance",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe46b4a950c389e80621d10dfc398e91613c7e25e",
    "decimals": 18,
    "image": "https://rango.vip/i/6zGwFW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 14.68812868051448,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VBETH",
    "name": "Venus BETH",
    "isPopular": false,
    "chainId": "56",
    "address": "0x972207a639cc1b374b893cc33fa251b55ceb7c07",
    "decimals": 8,
    "image": "https://rango.vip/i/C6agTo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 50.89,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANI",
    "name": "Anime",
    "isPopular": false,
    "chainId": "1",
    "address": "0x75cb71325a44fb102a742626b723054acb7e1394",
    "decimals": 18,
    "image": "https://rango.vip/i/i40Dzr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00100176,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ANI",
    "name": "ANI",
    "isPopular": false,
    "chainId": "56",
    "address": "0xac472d0eed2b8a2f57a6e304ea7ebd8e88d1d36f",
    "decimals": 18,
    "image": "https://rango.vip/i/ITHoYl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00100176,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "LHB",
    "name": "Heco LHB Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0x8f67854497218043e1f72908ffe38d0ed7f24721",
    "decimals": 18,
    "image": "https://rango.vip/i/RHlKhe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00006414583485183941,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LAVA",
    "name": "Lava Network",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x11e969e9b3f89cb16d686a03cd8508c9fc0361af",
    "decimals": 6,
    "image": "https://rango.vip/i/50xsAF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.066018,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HFI",
    "name": "HFI",
    "isPopular": false,
    "chainId": "128",
    "address": "0x98fc3b60ed4a504f588342a53746405e355f9347",
    "decimals": 18,
    "image": "https://rango.vip/i/E1IcLu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.000017645285394428496,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BT",
    "name": "BT.Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x76c5449f4950f6338a393f53cda8b53b0cd3ca3a",
    "decimals": 18,
    "image": "https://rango.vip/i/jRXfAM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.1762724978803858,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BT",
    "name": "Bitenium Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x997507cc49fbf0cd6ce5e1ee543218556fafdebc",
    "decimals": 18,
    "image": "https://rango.vip/i/HU8NOf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00100133,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ADCO",
    "name": "Advertise Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb6c3dc857845a713d3531cea5ac546f6767992f4",
    "decimals": 6,
    "image": "https://rango.vip/i/cyzW48",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.515582,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DVT",
    "name": "SafeStake Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x29fa1fee0f4f0ab0e36ef7ab8d7a35439ec6be75",
    "decimals": 18,
    "image": "https://rango.vip/i/5O7W91",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02922326,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAUS",
    "name": "DAOhaus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf2051511b9b121394fa75b8f7d4e7424337af687",
    "decimals": 18,
    "image": "https://rango.vip/i/4qLtYh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.559671,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "HAUS",
    "name": "DAOhaus Token on Optimism",
    "isPopular": false,
    "chainId": "10",
    "address": "0x01b8b6384298d4848e3be63d4c9d17830eee488a",
    "decimals": 18,
    "image": "https://rango.vip/i/Mx2oIh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.5539795680198801,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BERRY",
    "name": "Berry",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2d787d4f5005bd66ac910c2e821241625c406ed5",
    "decimals": 18,
    "image": "https://rango.vip/i/DEbhDM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0002265,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BERRY",
    "name": "Strawberry AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb76314c2540199f4b844d4ebbc7998c604880ca",
    "decimals": 18,
    "image": "https://rango.vip/i/JW59GC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.055623,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOAT",
    "name": "Goat Trading",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6ae2a128cd07d672164ca9f2712ea737d198dd41",
    "decimals": 18,
    "image": "https://rango.vip/i/TY9LqU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00056034,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOAT",
    "name": "GOATED",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe73cec024b30a7195af80d13f3b6917d80af11d8",
    "decimals": 9,
    "image": "https://rango.vip/i/yfKJCe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001103,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "CHE",
    "name": "CHE",
    "isPopular": false,
    "chainId": "66",
    "address": "0x8179d97eb6488860d816e3ecafe694a4153f216c",
    "decimals": 18,
    "image": "https://rango.vip/i/dVGWqU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.0012722148924339386,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PIPI",
    "name": "PIPI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HmTZ1SFRhgp63kgoT64iAwKXZez9Wf3df8r3MFywN8mp",
    "decimals": 6,
    "image": "https://rango.vip/i/73R3EJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000212,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIPI",
    "name": "Pipi on ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6977597bbbdcc453636bd67a161a96d85098f327",
    "decimals": 9,
    "image": "https://rango.vip/i/nKumhi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.31135e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEV",
    "name": "Lever Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc194e6f748a222754c3e8b9946922c09e7d4e91",
    "decimals": 18,
    "image": "https://rango.vip/i/CKZS9H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00238934,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "LEV",
    "name": "LEV",
    "isPopular": false,
    "chainId": "128",
    "address": "0xbc194e6f748a222754c3e8b9946922c09e7d4e91",
    "decimals": 18,
    "image": "https://rango.vip/i/W2TljP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.10704026825162162,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MER",
    "name": "Mercurial(Deprecated)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K",
    "decimals": 6,
    "image": "https://rango.vip/i/KKrXsh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00010199,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UMI",
    "name": "Umi Digital",
    "isPopular": false,
    "chainId": "1",
    "address": "0x61107a409fffe1965126aa456af679719695c69c",
    "decimals": 18,
    "image": "https://rango.vip/i/l0q2WU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.76367e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIH",
    "name": "Shih Tzu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x841fb148863454a3b3570f515414759be9091465",
    "decimals": 18,
    "image": "https://rango.vip/i/f5qQ4M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.22367e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PINK",
    "name": "Pink Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9133049fb1fddc110c92bf5b7df635abb70c89dc",
    "decimals": 18,
    "image": "https://rango.vip/i/DHzhVS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00021432,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RNB",
    "name": "Rentible",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20a62aca58526836165ca53fe67dd884288c8abf",
    "decimals": 18,
    "image": "https://rango.vip/i/ElYt5Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03275101,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FREN",
    "name": "Friend",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe6a768464b042a6d029394db1fdef360cb60bbeb",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 5.437128321593018e-7,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FREN",
    "name": "Frenchie",
    "isPopular": false,
    "chainId": "56",
    "address": "0x13958e1eb63dfb8540eaf6ed7dcbbc1a60fd52af",
    "decimals": 18,
    "image": "https://rango.vip/i/yDSlnC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 5.437128321593018e-7,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SETS",
    "name": "Sensitrust",
    "isPopular": false,
    "chainId": "1",
    "address": "0x04e0af0af1b7f0023c6b12af5a94df59b0e8cf59",
    "decimals": 18,
    "image": "https://rango.vip/i/tEykKS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00988886,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NIIFI",
    "name": "NiiFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x852e5427c86a3b46dd25e5fe027bb15f53c4bcb8",
    "decimals": 15,
    "image": "https://rango.vip/i/GJLrl0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00185948,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USHIBA",
    "name": "American Shiba",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb893a8049f250b57efa8c62d51527a22404d7c9a",
    "decimals": 9,
    "image": "https://rango.vip/i/kAF1M8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.425e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEZOGE",
    "name": "Bezoge Earth",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc349913d53b446485e98b76800b6254f43df695",
    "decimals": 9,
    "image": "https://rango.vip/i/3Eh1gB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.613e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZOOT",
    "name": "Zoo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1341a2257fa7b770420ef70616f888056f90926c",
    "decimals": 9,
    "image": "https://rango.vip/i/BXmn5p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.87e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUMINU",
    "name": "Cuminu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd6327ce1fb9d6020e8c2c0e124a1ec23dcab7536",
    "decimals": 18,
    "image": "https://rango.vip/i/vILbWa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004297,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "FEED",
    "name": "chikn feed",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xab592d197acc575d16c3346f4eb70c703f308d1e",
    "decimals": 18,
    "image": "https://rango.vip/i/RwxbAH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00026769,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FEED",
    "name": "FEED on ACF Game",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3LDAW7enNUZ4DjE1jCi1cDpXvXLrJ1rPiECPbcHpMgG2",
    "decimals": 2,
    "image": "https://rango.vip/i/sJAEy1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00026939195559247425,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FEED",
    "name": "Feeder.finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x67d66e8ec1fd25d98b3ccd3b19b7dc4b4b7fc493",
    "decimals": 18,
    "image": "https://rango.vip/i/j6ASn4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00044885,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TBC",
    "name": "Ten Best Coins",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03042ae6fcfd53e3a0baa1fab5ce70e0cb74e6fb",
    "decimals": 18,
    "image": "https://rango.vip/i/9FKkIx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5871.45,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIBAKEN",
    "name": "ShibaKen.Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa4cf2afd3b165975afffbf7e487cdd40c894ab6b",
    "decimals": 0,
    "image": "https://rango.vip/i/55d0CU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.365e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFTS",
    "name": "NFT Stars",
    "isPopular": false,
    "chainId": "1",
    "address": "0x08037036451c768465369431da5c671ad9b37dbc",
    "decimals": 18,
    "image": "https://rango.vip/i/Vq2IVf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04123217,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SARCO",
    "name": "Sarcophagus",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7697b462a7c4ff5f8b55bdbc2f4076c2af9cf51a",
    "decimals": 18,
    "image": "https://rango.vip/i/ViKbhF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02867367,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SARCO",
    "name": "Sarcophagus",
    "isPopular": false,
    "chainId": "10",
    "address": "0x7c6b91d9be155a6db01f749217d76ff02a7227f2",
    "decimals": 18,
    "image": "https://rango.vip/i/8DOFP7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.028886911644809745,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTR",
    "name": "Creator Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x923b83c26b3809d960ff80332ed00aa46d7ed375",
    "decimals": 18,
    "image": "https://rango.vip/i/DHXr2t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00051615,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTR",
    "name": "Concentrator Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb3ad645db386d7f6d753b2b9c3f4b853da6890b8",
    "decimals": 18,
    "image": "https://rango.vip/i/pZ1AZY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.247036,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GTC",
    "name": "Gitcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde30da39c46104798bb5aa3fe8b9e0e1f348163f",
    "decimals": 18,
    "image": "https://rango.vip/i/M2c6bq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.56744,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GTC",
    "name": "GTIB",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe138fda441fc31b36171122397a8a11d6cd2c479",
    "decimals": 0,
    "image": "https://rango.vip/i/Qha7vE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.572742,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GTC",
    "name": "Game.com Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb70835d7822ebb9426b56543e391846c107bd32c",
    "decimals": 18,
    "image": "https://rango.vip/i/cK5e2i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00051693,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GTC",
    "name": "Gitcoin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7f9a7db853ca816b9a138aee3380ef34c437dee0",
    "decimals": 18,
    "image": "https://rango.vip/i/sw4TFn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.5693544317356221,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "GTC",
    "name": "Gitcoin",
    "isPopular": false,
    "chainId": "10",
    "address": "0x1eba7a6a72c894026cd654ac5cdcf83a46445b08",
    "decimals": 18,
    "image": "https://rango.vip/i/xv1VA3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.5693544317356221,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "PLN",
    "name": "Pollen",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x7b2b702706d9b361dfe3f00bd138c0cfda7fb2cf",
    "decimals": 18,
    "image": "https://rango.vip/i/PHoEnW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.002993071336581804,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INVI",
    "name": "INVI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x356a5160f2b34bc8d88fb084745465ebbbed0174",
    "decimals": 13,
    "image": "https://rango.vip/i/YYywPB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.592161,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BIRB",
    "name": "birb",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2EBjqPYGLUExdWwJJRLqtGPawzb2aMjE1wTpUYKhy2UQ",
    "decimals": 9,
    "image": "https://rango.vip/i/IJLiYJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00018215,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "LYD",
    "name": "LydiaFinance Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084",
    "decimals": 18,
    "image": "https://rango.vip/i/rLn7MD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00029847,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MINIFOOTBALL",
    "name": "MiniFootball",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd024ac1195762f6f13f8cfdf3cdd2c97b33b248b",
    "decimals": 9,
    "image": "https://rango.vip/i/OoPDFf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.585854099487e-12,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYCE",
    "name": "Crypto Carbon Energy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9681ee0d91e737c3b60aceba7fbdae61b5462f42",
    "decimals": 6,
    "image": "https://rango.vip/i/7GpEs6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.210097,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAID",
    "name": "RaidTech",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6106292cfc8994a4483702f6fb05aca80e7fbae4",
    "decimals": 18,
    "image": "https://rango.vip/i/YbrE5t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000717,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAID",
    "name": "Raid Guild Token ⚔️",
    "isPopular": false,
    "chainId": "1",
    "address": "0x154e35c2b0024b3e079c5c5e4fc31c979c189ccb",
    "decimals": 18,
    "image": "https://rango.vip/i/lYUUy9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00119395,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUNK",
    "name": "CryptoPunks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x269616d549d7e8eaa82dfb17028d0b212d11232a",
    "decimals": 18,
    "image": "https://rango.vip/i/mDNEex",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 63984,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUNK",
    "name": "Punk",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9cea2ed9e47059260c97d697f82b8a14efa61ea5",
    "decimals": 18,
    "image": "https://rango.vip/i/GulT4H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.0163082528240364,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "PAPER",
    "name": "Dope Wars Paper",
    "isPopular": false,
    "chainId": "10",
    "address": "0x00f932f0fe257456b32deda4758922e56a4f4b42",
    "decimals": 18,
    "image": "https://rango.vip/i/9LHW5J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00289274,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAPER",
    "name": "Dope Wars Paper",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ae1d57b58fa6411f32948314badd83583ee0e8c",
    "decimals": 18,
    "image": "https://rango.vip/i/9LHW5J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00289274,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GEM",
    "name": "GEM",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E7BGDtpNXUTqPNbZxKHiLowgLddiAeuKcByD7tSnfYWD",
    "decimals": 5,
    "image": "https://rango.vip/i/CUiveO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0003898886678291582,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEM",
    "name": "Gems Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc7bba5b765581efb2cdd2679db5bea9ee79b201f",
    "decimals": 18,
    "image": "https://rango.vip/i/c2KQh3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005637,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEM",
    "name": "GemieToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9008064e6cf73e27a3aba4b10e69f855a4f8efcc",
    "decimals": 18,
    "image": "https://rango.vip/i/AhRT5k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00038995,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEM",
    "name": "GEM ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0447d3454b25935eed47f65b4bd22b9b23be326a",
    "decimals": 18,
    "image": "https://rango.vip/i/SIOWFv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01784698,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEM",
    "name": "NFTmall GEM Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b17baadf0f21f03e35249e0e59723f34994f806",
    "decimals": 18,
    "image": "https://rango.vip/i/MYfZnT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00270653,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IJC",
    "name": "IjasCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc7ff1e126cc81e816915ff48c940ed9d4e6d05d6",
    "decimals": 18,
    "image": "https://rango.vip/i/TQeKQJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001367,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "IVN",
    "name": "Investin Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "iVNcrNE9BRZBC9Aqf753iZiZfbszeAVUoikgT9yvr2a",
    "decimals": 6,
    "image": "https://rango.vip/i/UhrTLS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.126671,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XBN",
    "name": "Elastic BNB",
    "isPopular": false,
    "chainId": "56",
    "address": "0x547cbe0f0c25085e7015aa6939b28402eb0ccdac",
    "decimals": 18,
    "image": "https://rango.vip/i/z7iyNx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0009798084614324545,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWPR",
    "name": "Swapr",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6cacdb97e3fc8136805a9e7c342d866ab77d0957",
    "decimals": 18,
    "image": "https://rango.vip/i/H1J1Gw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00370214,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SWPR",
    "name": "Swapr",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xde903e2712288a1da82942dddf2c20529565ac30",
    "decimals": 18,
    "image": "https://rango.vip/i/H1J1Gw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00370214,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "STR",
    "name": "Solster",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9zoqdwEBKWEi9G5Ze8BSkdmppxGgVv1Kw4LuigDiNr9m",
    "decimals": 9,
    "image": "https://rango.vip/i/DPV4Hz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00229053,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "STR",
    "name": "Stater",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa79e0bfc579c709819f4a0e95d4597f03093b011",
    "decimals": 18,
    "image": "https://rango.vip/i/LiKlm6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0006498144463819303,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AVAI",
    "name": "AVAI",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x346a59146b9b4a77100d369a3d18e8007a9f46a6",
    "decimals": 18,
    "image": "https://rango.vip/i/LkkvEe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.7722695729214184,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EBSO",
    "name": "eBlockStock",
    "isPopular": false,
    "chainId": "1",
    "address": "0x866f8a50a64e68ca66e97e032c5da99538b3f942",
    "decimals": 4,
    "image": "https://rango.vip/i/RrzZc3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01191636,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "XSB",
    "name": "Solareum",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4UuGQgkD3rSeoXatXRWwRfRd21G87d5LiCfkVzNNv1Tt",
    "decimals": 9,
    "image": "https://rango.vip/i/IYhyxr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006227,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HBIT",
    "name": "HashBit",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Hd8crL1e3KnYEWvHBx7B2TSsadkQuFr52CwXXxZJyCv1",
    "decimals": 6,
    "image": "https://rango.vip/i/qdqkfx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.34157e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "PAGE",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/23a62409e4ad8133116c249b1fa38eed30e500a115d7b153109462cd82c1cd99",
    "decimals": 8,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.000918192189786368,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAGE",
    "name": "Page",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60e683c6514edd5f758a55b6f393bebbafaa8d5e",
    "decimals": 8,
    "image": "https://rango.vip/i/uy6IPw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00095869,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ENV",
    "name": "Envoy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1d1a5306daae314af6c5d027a492b313e07e1a0",
    "decimals": 18,
    "image": "https://rango.vip/i/8fy0RA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040197,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COC",
    "name": "COC",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "cocvP5K8DsDYbJkRGasSg69xWFenrVVdBLLKjJKPJSo",
    "decimals": 5,
    "image": "https://rango.vip/i/md2NiV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 7.1647e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COC",
    "name": "CocktailBar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x22b6c31c2beb8f2d0d5373146eed41ab9ede3caf",
    "decimals": 8,
    "image": "https://rango.vip/i/SbnVpZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 10.42,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COC",
    "name": "Coin of the champions",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4bb7277a74678f053259cb1f96140347efbfd46",
    "decimals": 18,
    "image": "https://rango.vip/i/F1A65Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.005e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "COC",
    "name": "CryptoOracle Collective",
    "isPopular": false,
    "chainId": "10",
    "address": "0x629c2fd5d5f432357465b59d7832389a89956f0b",
    "decimals": 18,
    "image": "https://rango.vip/i/HdLZZF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 9.98488547702434e-10,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COC",
    "name": "CryptoOracle Collective",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc8d9871a79551ab4439c9e08f12962e3785f0437",
    "decimals": 18,
    "image": "https://rango.vip/i/HdLZZF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.98488547702434e-10,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOV",
    "name": "SubDAO Governance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x274e7eb07b485cfde53d02270555213447570ac6",
    "decimals": 18,
    "image": "https://rango.vip/i/gEhJxH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00026455,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LIFT",
    "name": "Uplift",
    "isPopular": false,
    "chainId": "1",
    "address": "0x513c3200f227ebb62e3b3d00b7a83779643a71cf",
    "decimals": 18,
    "image": "https://rango.vip/i/WH4KUD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00462114,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RIOT",
    "name": "RIOT (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4ff0b68abc2b9e4e1401e9b691dba7d66b264ac8",
    "decimals": 18,
    "image": "https://rango.vip/i/5xWXFt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00148036,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RIOT",
    "name": "Riot Racers",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf56408077487cb879c992909c5b5c66d68c02eb4",
    "decimals": 18,
    "image": "https://rango.vip/i/cMKYpM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00148036,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "1FLR",
    "name": "Flare Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5f0197ba06860dac7e31258bdf749f92b6a636d4",
    "decimals": 18,
    "image": "https://rango.vip/i/pMcLrD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0000374,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PINU",
    "name": "Piccolo Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a1311b8c404629e38f61d566cefefed083b9670",
    "decimals": 9,
    "image": "https://rango.vip/i/DxnWbP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.185e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CT",
    "name": "CryptoTwitter",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6f2dec5da475333b0af4a3ffc9a33b0211a9a452",
    "decimals": 18,
    "image": "https://rango.vip/i/Jue4lD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.16225e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OOGI",
    "name": "OOGI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "H7Qc9APCWWGDVxGD5fJHmLTmdEgT9GFatAKFNg6sHh8A",
    "decimals": 9,
    "image": "https://rango.vip/i/1VE71w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000027695350754132887,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JACY",
    "name": "JACY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x916c5de09cf63f6602d1e1793fb41f6437814a62",
    "decimals": 9,
    "image": "https://rango.vip/i/TMhBt5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.016e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOCKS",
    "name": "BLOCKS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a6d4c8735371ebaf8874fbd518b56edd66024eb",
    "decimals": 18,
    "image": "https://rango.vip/i/0gmCDm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001235,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPN",
    "name": "Sportzchain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32ea3dc70e2962334864a9665254d2433e4ddbfd",
    "decimals": 18,
    "image": "https://rango.vip/i/bV9RKm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003112,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FTR",
    "name": "Fautor",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd6c7bb8531295e88d364ea67d5d1acc7d3f87454",
    "decimals": 18,
    "image": "https://rango.vip/i/rGoBe5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.051032,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SWOLE",
    "name": "Swole Doge",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4BzxVoBQzwKoqm1dQc78r42Yby3EzAeZmMiYFdCjeu5Z",
    "decimals": 9,
    "image": "https://rango.vip/i/kRuo4Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000029528186610035687,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SWOLE",
    "name": "SWOLE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "sio28ienC3iABUKJFzkikHknbR3xyhjzhJE34tipyDP",
    "decimals": 9,
    "image": "https://rango.vip/i/xgS25g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000029528186610035687,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "THC",
    "name": "THC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x56083560594e314b5cdd1680ec6a493bb851bbd8",
    "decimals": 9,
    "image": "https://rango.vip/i/MWmyOw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00008071,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CIND",
    "name": "Cindrum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac0968a3e2020ac8ca83e60ccf69081ebc6d3bc3",
    "decimals": 18,
    "image": "https://rango.vip/i/w7AmWx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006527,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CAVE",
    "name": "Crypto Cavemen",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4SZjjNABoqhbd4hnapbvoEPEqT8mnNkfbEoAwALf1V8t",
    "decimals": 6,
    "image": "https://rango.vip/i/iQEVkp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01658547,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APP",
    "name": "Moon App",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc5d27f27f08d1fd1e3ebbaa50b3442e6c0d50439",
    "decimals": 18,
    "image": "https://rango.vip/i/7w2ZYZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00271862,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANIM",
    "name": "Animalia",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfbcb5cbedeebcc55dcd136d34db1daaf74cf67e8",
    "decimals": 18,
    "image": "https://rango.vip/i/S78C1x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00310258,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEYS",
    "name": "Keys",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0a189c975e4928222978a74517442239a0b86ff",
    "decimals": 9,
    "image": "https://rango.vip/i/pCjS95",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00058009,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONUS",
    "name": "ONUS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4184aa04215e5d716dd4c213fed519acadc68f92",
    "decimals": 18,
    "image": "https://rango.vip/i/W1f1D0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.456749,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XIDO",
    "name": "Xido Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf6650117017ffd48b725b4ec5a00b414097108a7",
    "decimals": 18,
    "image": "https://rango.vip/i/MboPLn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.550696,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LTT",
    "name": "Luxury Travel Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f91ad19af450b44cf5176b4de719d77cb19eec7",
    "decimals": 18,
    "image": "https://rango.vip/i/wZm9eC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00123356,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HDAO",
    "name": "HyperDao",
    "isPopular": false,
    "chainId": "1",
    "address": "0x74faab6986560fd1140508e4266d8a7b87274ffd",
    "decimals": 18,
    "image": "https://rango.vip/i/YcawFT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012982,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HDAO",
    "name": "humanDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdac657ffd44a3b9d8aba8749830bf14beb66ff2d",
    "decimals": 18,
    "image": "https://rango.vip/i/EyI0dA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00385128,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "MOO",
    "name": "Moola",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x17700282592d6917f6a73d0bf8accf4d578c131e",
    "decimals": 18,
    "image": "https://rango.vip/i/ddLGry",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.00355023,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MAGE",
    "name": "MetaBrands",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x921f99719eb6c01b4b8f0ba7973a7c24891e740a",
    "decimals": 18,
    "image": "https://rango.vip/i/z2ha4n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.02111172,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAGE",
    "name": "MetaBrands",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd52aae39a2b5cc7812f7b9450ebb61dfef702b15",
    "decimals": 18,
    "image": "https://rango.vip/i/EWUVLj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02111172,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KRX",
    "name": "KRYZA Exchange",
    "isPopular": false,
    "chainId": "1",
    "address": "0x93ad9b819c88d98b4c9641470a96e24769ae7922",
    "decimals": 9,
    "image": "https://rango.vip/i/4BxmPY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00759947,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEVT",
    "name": "DeHorizon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb5c578947de0fd71303f71f2c3d41767438bd0de",
    "decimals": 18,
    "image": "https://rango.vip/i/MZGcc2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002347,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MODA",
    "name": "moda",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5e430f88d1be82eb3ef92b6ff06125168fd5dcf2",
    "decimals": 18,
    "image": "https://rango.vip/i/Vb8VuR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.158154,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MODA",
    "name": "moda",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1117ac6ad6cdf1a3bc543bad3b133724620522d5",
    "decimals": 18,
    "image": "https://rango.vip/i/AV7nUQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.158154,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RUN",
    "name": "Run Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC",
    "decimals": 9,
    "image": "https://rango.vip/i/IEbw12",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002242,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "MPWR",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/dd3938d8131f41994c1f01f4eb5233dee9a0a5b787545b9a07a321925655bf38",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/MPWR.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.002886250120915968,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MPWR",
    "name": "Empower",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6731827cb6879a2091ce3ab3423f7bf20539b579",
    "decimals": 18,
    "image": "https://rango.vip/i/dVSvCp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000471,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FCP",
    "name": "Filipcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb6dd77fd132dcaa10f1858734e838a0fa7431580",
    "decimals": 18,
    "image": "https://rango.vip/i/ljNELU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000578,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RBC",
    "name": "Rubic",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa4eed63db85311e22df4473f87ccfc3dadcfa3e3",
    "decimals": 18,
    "image": "https://rango.vip/i/zdAziB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0020074003625848843,
    "supportedSwappers": [
      "EthUniSwapV2"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RBC",
    "name": "RUBIC TOKEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3330bfb7332ca23cd071631837dc289b09c33333",
    "decimals": 18,
    "image": "https://rango.vip/i/ZlPXEm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01420135,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RBC",
    "name": "Rubic",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2e9ae8f178d5ea81970c7799a377b3985cbc335f",
    "decimals": 18,
    "image": "https://rango.vip/i/VEg4PB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.014133020961060115,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "PLY",
    "name": "Aurigami Token",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x09c9d464b58d96837f8d8b6f4d9fe4ad408d3a4f",
    "decimals": 18,
    "image": "https://rango.vip/i/Nv0kTL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.00005873,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLY",
    "name": "PlayNity token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20d60c6eb195868d4643f2c9b0809e4de6cc003d",
    "decimals": 6,
    "image": "https://rango.vip/i/r9HnWb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00113351,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLY",
    "name": "Aurigami Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1ab43204a195a0fd37edec621482afd3792ef90b",
    "decimals": 18,
    "image": "https://rango.vip/i/pvag1L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005873,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WAL",
    "name": "WastedLands",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd306c124282880858a634e7396383ae58d37c79c",
    "decimals": 18,
    "image": "https://rango.vip/i/nIJVE4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00011308265399927803,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PHX",
    "name": "Phoenix Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaec65404ddc3af3c897ad89571d5772c1a695f22",
    "decimals": 18,
    "image": "https://rango.vip/i/Z6JIMR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00188143,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PLS",
    "name": "Plutus",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x51318b7d00db7acc4026c88c3952b66278b6a67f",
    "decimals": 18,
    "image": "https://rango.vip/i/2hD3Pk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02466158,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LIFE",
    "name": "Life Crypto",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c936d4ae98e6d2172db18c16c4b601c99918ee6",
    "decimals": 18,
    "image": "https://rango.vip/i/UZS9UI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005808,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MARVIN",
    "name": "Marvin Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x55a380d134d722006a5ce2d510562e1239d225b1",
    "decimals": 18,
    "image": "https://rango.vip/i/XgJ15w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.2587e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MARVIN",
    "name": "MARVIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe3f03cef497c81d2b28a2fae63ae84b373028718",
    "decimals": 9,
    "image": "https://rango.vip/i/jTKYx6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.57447e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BFC",
    "name": "Bifrost",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c7d5ae016f806603cb1782bea29ac69471cab9c",
    "decimals": 18,
    "image": "https://rango.vip/i/nuKmrH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03730425,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VCG",
    "name": "VCGamers",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f36fb2d91d9951cf58ae4c1956c0b77e224f1e9",
    "decimals": 18,
    "image": "https://rango.vip/i/N4x9Tr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00712159,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "DARK",
    "name": "DARKCRYPTO SHARE",
    "isPopular": false,
    "chainId": "25",
    "address": "0x83b2ac8642ae46fc2823bc959ffeb3c1742c48b5",
    "decimals": 18,
    "image": "https://rango.vip/i/HWQu2z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0011911945031123363,
    "supportedSwappers": [
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DARK",
    "name": "Dark Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FmQ7v2QUqXVVtAXkngBh3Mwx7s3mKT55nQ5Z673dURYS",
    "decimals": 9,
    "image": "https://rango.vip/i/fA9Zt6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.04647109,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DARK",
    "name": "Dark Forest",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8efe7dd5984640537b6596fb28b762f6c000f184",
    "decimals": 9,
    "image": "https://rango.vip/i/UeAGHD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0035745,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DREAM",
    "name": "DREAM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb44377b74ef1773639b663d0754cb8410a847d02",
    "decimals": 18,
    "image": "https://rango.vip/i/GEp3d8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0415371,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "XGEM",
    "name": "Exchange Genesis Ethlas Medium",
    "isPopular": false,
    "chainId": "137",
    "address": "0x02649c1ff4296038de4b9ba8f491b42b940a8252",
    "decimals": 18,
    "image": "https://rango.vip/i/g2hVO1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00002975,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SVT",
    "name": "Solvent",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV",
    "decimals": 6,
    "image": "https://rango.vip/i/OFaJvd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.04308487105553401,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DBC",
    "name": "DBank Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "98ouGzbMV2DnHfn3Uc7oahwvDP4Cf7Ls77JsBiARdjvn",
    "decimals": 6,
    "image": "https://rango.vip/i/yEkUEZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0024914177416766195,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "KACY",
    "name": "Kassandra",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf32398dae246c5f672b52a54e9b413dffcae1a44",
    "decimals": 18,
    "image": "https://rango.vip/i/yXgDd7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0020598817418316677,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AERO",
    "name": "Aerodrome",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x940181a94a35a4569e4529a3cdfb74e38fd98631",
    "decimals": 18,
    "image": "https://rango.vip/i/Q2JnWq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.523076,
    "supportedSwappers": [
      "OneInchBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DGMV",
    "name": "DigiMetaverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8eedefe828a0f16c8fc80e46a87bc0f1de2d960c",
    "decimals": 18,
    "image": "https://rango.vip/i/vmLrm8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00644694,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "VIS",
    "name": "Envision Labs",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfc60aa1ffca50ce08b3cdec9626c0bb9e9b09bec",
    "decimals": 18,
    "image": "https://rango.vip/i/9Py7Bn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.03412244,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIS",
    "name": "Envision",
    "isPopular": false,
    "chainId": "1",
    "address": "0x469084939d1c20fae3c73704fe963941c51be863",
    "decimals": 18,
    "image": "https://rango.vip/i/xRXC0n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03600278,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "EARN",
    "name": "HOLD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x806cc7a21bd85e960857ac1e097802fabad6f594",
    "decimals": 18,
    "image": "https://rango.vip/i/5Iz532",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00341165,
    "supportedSwappers": [
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "EARN",
    "name": "HOLD",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x803b629c339941e2b77d2dc499dac9e1fd9eac66",
    "decimals": 18,
    "image": "https://rango.vip/i/5Iz532",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00341165,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EARN",
    "name": "Earn Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12ed0641242e4c6c220e3ca8f616e9d5470ac99a",
    "decimals": 18,
    "image": "https://rango.vip/i/SRflpk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00029174,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EARN",
    "name": "HOLD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b61c4f33bcdef83359ab97673cb5961c6435f4e",
    "decimals": 18,
    "image": "https://rango.vip/i/5Iz532",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00341165,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVC",
    "name": "Civic",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41e5560054824ea6b0732e656e3ad64e20e94e45",
    "decimals": 8,
    "image": "https://rango.vip/i/TWNiGb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.08959,
    "supportedSwappers": [
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CVC",
    "name": "Civic",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9dffb23cad3322440bccff7ab1c58e781ddbf144",
    "decimals": 8,
    "image": "https://rango.vip/i/j4pGbm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0891931403883408,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ADOGE",
    "name": "ArbiDoge",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x155f0dd04424939368972f4e1838687d6a831151",
    "decimals": 18,
    "image": "https://rango.vip/i/xCjliO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.12129e-7,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FOOD",
    "name": "Food Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EcK2evV2cDECVsmvY2FxU51eu3fp4w48zrZxuA92AAAN",
    "decimals": 9,
    "image": "https://rango.vip/i/BI1D0L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000019,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FOOD",
    "name": "Food",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "foodQJAztMzX1DKpLaiounNe2BDMds5RNuPC6jsNrDG",
    "decimals": 0,
    "image": "https://rango.vip/i/6JfXFL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.07098612322622884,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FOOD",
    "name": "FoodChain Global",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6f06e6bed64cf4c4187c06ee2a4732f6a171bc4e",
    "decimals": 18,
    "image": "https://rango.vip/i/z3JTBX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.070974,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "STRM",
    "name": "Streamer Inu",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfe4717f60ac5603dc6863700cd8ecf805908688d",
    "decimals": 18,
    "image": "https://rango.vip/i/tG24L0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00021402,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STRM",
    "name": "StreamerInu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8162b5bc8f651007cc38a09f557bab2bf4cefb5b",
    "decimals": 18,
    "image": "https://rango.vip/i/tG24L0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021402,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HDRN",
    "name": "Hedron",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3819f64f282bf135d62168c1e513280daf905e06",
    "decimals": 9,
    "image": "https://rango.vip/i/0yUTA2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.9027e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IAI",
    "name": "inheritance Art",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6dde4ffd6db302bc9a46850f61399e082f6c2122",
    "decimals": 18,
    "image": "https://rango.vip/i/SrriTG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.481244,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GOD",
    "name": "GOD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9kt93AW5QMjFL6ZxomnSq3FbWiU5ibNeTSgBz9UDFSB6",
    "decimals": 6,
    "image": "https://rango.vip/i/gsITnt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.2796167896321981,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOD",
    "name": "GOD Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c746edf20762dc201ac40135e0c13e400d23d58",
    "decimals": 9,
    "image": "https://rango.vip/i/ULdT2A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.6125e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RATS",
    "name": "RatsBase",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x06c4c7f8011fbfb6a9654ee4142532f9bb3c52b8",
    "decimals": 9,
    "image": "https://rango.vip/i/kIcicp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2.54949e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LGOLD",
    "name": "Lyfe Gold",
    "isPopular": false,
    "chainId": "1",
    "address": "0x27778e14ce36d3b85e1effeb43816a17bbb7088a",
    "decimals": 18,
    "image": "https://rango.vip/i/OVzj93",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 65.42,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MYRIA",
    "name": "Myria",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0ef786bf476fe0810408caba05e536ac800ff86",
    "decimals": 18,
    "image": "https://rango.vip/i/21DoaQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.002024,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LFT",
    "name": "LendFlare DAO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb620be8a1949aa9532e6a3510132864ef9bc3f82",
    "decimals": 18,
    "image": "https://rango.vip/i/P08b3G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.017098155547304886,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHARDS",
    "name": "SolChicks Shards",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8j3hXRK5rdoZ2vSpGLRmXtWmW6iYaRUw5xVk4Kzmc9Hp",
    "decimals": 9,
    "image": "https://rango.vip/i/hgRaxw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000198,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HAT",
    "name": "HAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HrqgFZipMFHXvN5nKvTUaCwuA3Tp2UGqcQzArRGAyQ22",
    "decimals": 9,
    "image": "https://rango.vip/i/e9x40Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003317,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAT",
    "name": "Hat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x76c4ec0068923da13ee11527d6cf9b7521000049",
    "decimals": 18,
    "image": "https://rango.vip/i/Za2A7E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02517265,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HAT",
    "name": "JoeHatToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x82fe038ea4b50f9c957da326c412ebd73462077c",
    "decimals": 18,
    "image": "https://rango.vip/i/OuhlTg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 903.11,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUPER",
    "name": "SuperFarm",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55",
    "decimals": 18,
    "image": "https://rango.vip/i/DK14NS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.618594,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SUPER",
    "name": "SuperFarm",
    "isPopular": false,
    "chainId": "56",
    "address": "0x51ba0b044d96c3abfca52b64d733603ccc4f0d4d",
    "decimals": 18,
    "image": "https://rango.vip/i/nhnWxz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.618594,
    "supportedSwappers": [
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SUPER",
    "name": "SUPER TOKEN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5nrAynqdsEyBc3GRc7hvM5QnDwjQVePNg9kn6bCvi4G3",
    "decimals": 5,
    "image": "https://rango.vip/i/lGKqbf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.6206643168206567,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SUPER",
    "name": "SuperFarm",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa1428174f516f527fafdd146b883bb4428682737",
    "decimals": 18,
    "image": "https://rango.vip/i/U3SJue",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.618594,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SUPER",
    "name": "SuperFarm",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7f9cf5a2630a0d58567122217df7609c26498956",
    "decimals": 18,
    "image": "https://rango.vip/i/fdQyMw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.6206643168206567,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UNIM",
    "name": "Unicorn Milk",
    "isPopular": false,
    "chainId": "137",
    "address": "0x64060ab139feaae7f06ca4e63189d86adeb51691",
    "decimals": 18,
    "image": "https://rango.vip/i/GbO8Dw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00018148,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CROGE",
    "name": "CROGE token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xc4a174ccb5fb54a6721e11e0ca961e42715023f9",
    "decimals": 9,
    "image": "https://rango.vip/i/6q71NC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0002698814268131205,
    "supportedSwappers": [
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FPIS",
    "name": "Frax Price Index Share",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc2544a32872a91f4a553b404c6950e89de901fdb",
    "decimals": 18,
    "image": "https://rango.vip/i/kTH1KI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.361893,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "FPIS",
    "name": "Frax Price Index Share",
    "isPopular": false,
    "chainId": "10",
    "address": "0x8368dca5ce2a4db530c0f6e535d90b6826428dee",
    "decimals": 18,
    "image": "https://rango.vip/i/g4Y16X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.36992373429683195,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "STAR",
    "name": "StarHeroes",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb299751b088336e165da313c33e3195b8c6663a6",
    "decimals": 18,
    "image": "https://rango.vip/i/hvWjvj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.04904374,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "STAR",
    "name": "STAR",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc19669a405067927865b40ea045a2baabbbe57f5",
    "decimals": 18,
    "image": "https://rango.vip/i/UI1EiN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.996347,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STAR",
    "name": "Starbot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6953f27db0701e22616e701dba91acc2e4b6deca",
    "decimals": 18,
    "image": "https://rango.vip/i/Q7YtC0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03071915,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STAR",
    "name": "StarHeroes",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb299751b088336e165da313c33e3195b8c6663a6",
    "decimals": 18,
    "image": "https://rango.vip/i/5wdmYZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04904374,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STAR",
    "name": "Star",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24eb24647786135352cbb14f888b23277f0014e7",
    "decimals": 18,
    "image": "https://rango.vip/i/t0DUDR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04888847440164405,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "STAR",
    "name": "STAR",
    "isPopular": false,
    "chainId": "128",
    "address": "0xc3684755e5e7f3eaaad178de351969f4145e9b33",
    "decimals": 18,
    "image": "https://rango.vip/i/RwMRWU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.04888847440164405,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "YUSD",
    "name": "YUSD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x111111111111ed1d73f860f57b2798b683f2d325",
    "decimals": 18,
    "image": "https://rango.vip/i/DzewGo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.992114,
    "supportedSwappers": [
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "BOBA",
    "symbol": "ZENC",
    "name": "Zencha",
    "isPopular": false,
    "chainId": "288",
    "address": "0x7445efc8a6f1279434133408bedeb6eb4d1537ab",
    "decimals": 18,
    "image": "https://rango.vip/i/2PdGit",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BOBA/icon.svg",
    "usdPrice": 0.001021577466598807,
    "supportedSwappers": [
      "OolongSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RPC",
    "name": "Republic Credits",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EAefyXw6E8sny1cX3LTH6RSvtzH6E5EFy1XsE2AiH1f3",
    "decimals": 6,
    "image": "https://rango.vip/i/gyIfVm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000922,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BURN",
    "name": "Burn",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2fe5e51729be71261bcf42854012827bc44c044",
    "decimals": 18,
    "image": "https://rango.vip/i/dxmqDB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000599,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BURN",
    "name": "Smoked Token Burn",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97225fae89b370e7721f961d1145e64df56f2482",
    "decimals": 18,
    "image": "https://rango.vip/i/iSz1B7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002985,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GEMS",
    "name": "SolGems",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2YJH1Y5NbdwJGEUAMY6hoTycKWrRCP6kLKs62xiSKWHM",
    "decimals": 9,
    "image": "https://rango.vip/i/U9N5ed",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.1536502223003349,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GEMS",
    "name": "GEMS",
    "isPopular": false,
    "chainId": "137",
    "address": "0x25efae7b0b2866cafb14e8ead333a42eeb2a0b80",
    "decimals": 6,
    "image": "https://rango.vip/i/9PB8Cq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.1536502223003349,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEMS",
    "name": "Gems VIP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3010ccb5419f1ef26d40a7cd3f0d707a0fa127dc",
    "decimals": 18,
    "image": "https://rango.vip/i/VNX86F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.153897,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEMS",
    "name": "Gemtools",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea55f9d4a1bcea5e3e806f169f33f0092d5fb7f0",
    "decimals": 9,
    "image": "https://rango.vip/i/0XJQiQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009744,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KUKU",
    "name": "KuKu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x27206f5a9afd0c51da95f20972885545d3b33647",
    "decimals": 0,
    "image": "https://rango.vip/i/mMHIvG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.292283491223852e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AVXT",
    "name": "Avaxtars Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x397bbd6a0e41bdf4c3f971731e180db8ad06ebc1",
    "decimals": 6,
    "image": "https://rango.vip/i/vvaB7C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00007421914945883436,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ARGO",
    "name": "Argo Protocol",
    "isPopular": false,
    "chainId": "25",
    "address": "0x47a9d630dc5b28f75d3af3be3aaa982512cd89aa",
    "decimals": 18,
    "image": "https://rango.vip/i/ElO4JD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00056559,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARGO",
    "name": "ArGoApp",
    "isPopular": false,
    "chainId": "1",
    "address": "0x28cca76f6e8ec81e4550ecd761f899110b060e97",
    "decimals": 18,
    "image": "https://rango.vip/i/xxhx8q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00463989,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ARGO",
    "name": "ArGo Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd0252fb67606ed74d0cacd17b2eb38446e4466c9",
    "decimals": 18,
    "image": "https://rango.vip/i/QCpkWG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0005561070658659792,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "USV",
    "name": "Universal Store of Value",
    "isPopular": false,
    "chainId": "137",
    "address": "0xac63686230f64bdeaf086fe6764085453ab3023f",
    "decimals": 9,
    "image": "https://rango.vip/i/UuZQQ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 6.94,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USV",
    "name": "Universal Store of Value",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88536c9b2c4701b8db824e6a16829d5b5eb84440",
    "decimals": 9,
    "image": "https://rango.vip/i/KVYmGK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.94,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "X",
    "name": "X",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f3141c4d6b047fb930991b450f1ed996a51cb26",
    "decimals": 18,
    "image": "https://rango.vip/i/ErCvwd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003068,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "X",
    "name": "X",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa62894d5196bc44e4c3978400ad07e7b30352372",
    "decimals": 9,
    "image": "https://rango.vip/i/QXEGaQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.97063e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "X",
    "name": "X.com",
    "isPopular": false,
    "chainId": "1",
    "address": "0xabec00542d141bddf58649bfe860c6449807237c",
    "decimals": 18,
    "image": "https://rango.vip/i/A1aAte",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0426545,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "X",
    "name": "AI-X",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f5166c4fdb9055efb24a7e75cc1a21ca8ca61a3",
    "decimals": 9,
    "image": "https://rango.vip/i/tIMj4p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.331e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "HOT",
    "name": "Holo",
    "isPopular": false,
    "chainId": "25",
    "address": "0xc4010cfb5172d82a348bcbc8cd543733c1e9bf89",
    "decimals": 18,
    "image": "https://rango.vip/i/EDwWai",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.001493913338656972,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOT",
    "name": "HoloToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c6ee5e31d828de241282b9606c8e98ea48526e2",
    "decimals": 18,
    "image": "https://rango.vip/i/RoRTzj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00149818,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "RUSD",
    "name": "Rose USD",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x19cc40283b057d6608c22f1d20f17e16c245642e",
    "decimals": 18,
    "image": "https://rango.vip/i/7LuuV0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.9335887082562871,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RUSD",
    "name": "f x  rUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65d72aa8da931f047169112fcf34f52dbaae7d18",
    "decimals": 18,
    "image": "https://rango.vip/i/OAHgqI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RUSD",
    "name": "rUSD",
    "isPopular": false,
    "chainId": "56",
    "address": "0x07663837218a003e66310a01596af4bf4e44623d",
    "decimals": 18,
    "image": "https://rango.vip/i/VKe6TM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.9044386417440794,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "START",
    "name": "Starter xyz",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1d7ca62f6af49ec66f6680b8606e634e55ef22c1",
    "decimals": 18,
    "image": "https://rango.vip/i/3rpGUJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02836249,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "INU",
    "name": "Solana INU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko",
    "decimals": 9,
    "image": "https://rango.vip/i/Fmrnas",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004696,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INU",
    "name": "Hachiko Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf32aa187d5bc16a2c02a6afb7df1459d0d107574",
    "decimals": 18,
    "image": "https://rango.vip/i/5tBDwV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.795e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INU",
    "name": "Inu.",
    "isPopular": false,
    "chainId": "1",
    "address": "0x050d94685c6b0477e1fc555888af6e2bb8dfbda5",
    "decimals": 18,
    "image": "https://rango.vip/i/pO7VmP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.72768e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INU",
    "name": "Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc76d53f988820fe70e01eccb0248b312c2f1c7ca",
    "decimals": 18,
    "image": "https://rango.vip/i/40yj0k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.403e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INU",
    "name": "Intrinsic Number Up",
    "isPopular": false,
    "chainId": "1",
    "address": "0x795a7e7a0f6b84ee1f2bc9e70a74dbbb49cef9a3",
    "decimals": 18,
    "image": "https://rango.vip/i/KSyqdu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INU",
    "name": "Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf7d75a5e807b5345eb7aab8cc9a2f9e2175eb88e",
    "decimals": 18,
    "image": "https://rango.vip/i/gCwbpW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004342,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFTL",
    "name": "NFTLAUNCH.network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe7f72bc0252ca7b16dbb72eeee1afcdb2429f2dd",
    "decimals": 18,
    "image": "https://rango.vip/i/tJFrwh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00202226,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFTL",
    "name": "Nifty League",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c8d2fce49906e11e71cb16fa0ffeb2b16c29638",
    "decimals": 18,
    "image": "https://rango.vip/i/bO7tI9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00123519,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NFTL",
    "name": "NFTL Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2f7b4c618dc8e0bba648e54cdadce3d8361f9816",
    "decimals": 18,
    "image": "https://rango.vip/i/utQIFa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0012549283052562738,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DEBT",
    "name": "DEBT",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc632f90affec7121120275610bf17df9963f181c",
    "decimals": 8,
    "image": "https://rango.vip/i/SCxNOP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.777764,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OWL",
    "name": "OWL Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a5f9352af8af974bfc03399e3767df6370d82e4",
    "decimals": 18,
    "image": "https://rango.vip/i/NsRZY3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.002033419359908994,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "OWL",
    "name": "OwlDAO token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9085b4d52c3e0b8b6f9af6213e85a433c7d76f19",
    "decimals": 18,
    "image": "https://rango.vip/i/BGt9tu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.002033419359908994,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AURABAL",
    "name": "Aura BAL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x616e8bfa43f920657b3497dbf40d6b1a02d4608d",
    "decimals": 18,
    "image": "https://rango.vip/i/by0cPk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.69,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STC",
    "name": "Student Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15b543e986b8c34074dfc9901136d9355a537e7e",
    "decimals": 18,
    "image": "https://rango.vip/i/4L2803",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00588298,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "U",
    "name": "Uranium3o8",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8cc379a292a47cb8406fb1bd8a6d98f442275f0e",
    "decimals": 18,
    "image": "https://rango.vip/i/z3EN4A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.346499,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LAIKA",
    "name": "Laika",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77f9cf0bd8c500cffdf420e72343893aecc2ec0b",
    "decimals": 18,
    "image": "https://rango.vip/i/2x2mvq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.69007e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTRFLY",
    "name": "BTRFLY",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc55126051b22ebb829d00368f4b12bde432de5da",
    "decimals": 18,
    "image": "https://rango.vip/i/8zgkdM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 147.25,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTRFLY",
    "name": "BTRFLY",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0d4ceb216b3ba9c3701b291766fdcba977cec3a",
    "decimals": 9,
    "image": "https://rango.vip/i/xlLUCy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 149.2628927666908,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TINU",
    "name": "Trump Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd3fb8597d260efb2e693efd500d62a330a00f1eb",
    "decimals": 18,
    "image": "https://rango.vip/i/onaeYO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000128,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "MAG",
    "name": "MAG",
    "isPopular": false,
    "chainId": "66",
    "address": "0x53b22d356f34e977e48921e07381de0f8200b8e6",
    "decimals": 18,
    "image": "https://rango.vip/i/IAwAzA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.0975756174294376,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAG",
    "name": "Magnum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9d4b6dc1e1ee3577cc442de015cc11f238b35ed",
    "decimals": 18,
    "image": "https://rango.vip/i/efNXp6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.09802,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAG",
    "name": "Magnesium",
    "isPopular": false,
    "chainId": "1",
    "address": "0x544d230f0362f3843fda5caa99b94cb2b336e384",
    "decimals": 9,
    "image": "https://rango.vip/i/DcCJmc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1e-18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAG",
    "name": "Magnify Cash",
    "isPopular": false,
    "chainId": "1",
    "address": "0x71da932ccda723ba3ab730c976bc66daaf9c598c",
    "decimals": 18,
    "image": "https://rango.vip/i/M0eQ2K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00116236,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "O",
    "name": "Childhoods End",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb53ecf1345cabee6ea1a65100ebb153cebcac40f",
    "decimals": 18,
    "image": "https://rango.vip/i/MFOkOS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000827,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HER",
    "name": "Caroline",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5c2975269e74cb3a8514e5b800a1e66c694d4df8",
    "decimals": 18,
    "image": "https://rango.vip/i/5HOTCv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000132,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUSD",
    "name": "Coin98 Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc285b7e09a4584d027e5bc36571785b515898246",
    "decimals": 18,
    "image": "https://rango.vip/i/2UPXgR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.655145,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JPGC",
    "name": "JPGoldCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5891599664ed15c6e88041b4f5bc08594f026f0e",
    "decimals": 18,
    "image": "https://rango.vip/i/SgJCAD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00132979,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRTG",
    "name": "Pre Retogeum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd04ccc050058a6a422851fa6c0f92bb65eb06ca",
    "decimals": 18,
    "image": "https://rango.vip/i/XhJUBN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HATCHY",
    "name": "HATCHY",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x502580fc390606b47fc3b741d6d49909383c28a9",
    "decimals": 18,
    "image": "https://rango.vip/i/mq493y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00097369,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "ET",
    "name": "ET",
    "isPopular": false,
    "chainId": "128",
    "address": "0x99336b9a5f70f52b4b1fc2886ba9bd2d2263f5d5",
    "decimals": 18,
    "image": "https://rango.vip/i/Mm36jd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0010082055705106197,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAN",
    "name": "Santiment",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098",
    "decimals": 18,
    "image": "https://rango.vip/i/RQ6nd8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.080589,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLEV",
    "name": "CLever",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72953a5c32413614d24c29c84a66ae4b59581bbf",
    "decimals": 18,
    "image": "https://rango.vip/i/p5DcNS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.77,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BOOP",
    "name": "Boop",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x13a7dedb7169a17be92b0e3c7c2315b46f4772b3",
    "decimals": 18,
    "image": "https://rango.vip/i/Cs1f8Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00002947,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOOP",
    "name": "BOOP",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CCRJohp9bfQdhZCvCe7MzUQuXMJkKBi4XCjUq3A2YqN8",
    "decimals": 10,
    "image": "https://rango.vip/i/jWBRlt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002951728816967815,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOOP",
    "name": "Boop",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8013266cb5c9dd48be3ad7d1ce832874d64b3ce1",
    "decimals": 18,
    "image": "https://rango.vip/i/G8KA99",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02498476,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAO",
    "name": "BaoToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x374cb8c27130e2c9e04f44303f3c8351b9de61c1",
    "decimals": 18,
    "image": "https://rango.vip/i/XtNUDT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.92509e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAO",
    "name": "Bao Token V2",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce391315b414d4c7555956120461d21808a69f3a",
    "decimals": 18,
    "image": "https://rango.vip/i/wNfcO4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00051899,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PALM",
    "name": "PaLM AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1df7305e4bab3885cab5b1e4dfc338452a67891",
    "decimals": 9,
    "image": "https://rango.vip/i/tYfi8o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.393927,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBC",
    "name": "Big Bonus Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x015628ce9150db1bce2fbb717a09e846f8a32436",
    "decimals": 18,
    "image": "https://rango.vip/i/5TJzSj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.49620299939686446,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBC",
    "name": "Bossblockchain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x102fa93ef3d2dc5b0b9ed3e7b3bf832796cb7b95",
    "decimals": 18,
    "image": "https://rango.vip/i/pmek4V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000597,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SILK",
    "name": "Spider Tanks",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb045f7f363fe4949954811b113bd56d208c67b23",
    "decimals": 8,
    "image": "https://rango.vip/i/t1AJP4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.056661,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NPM",
    "name": "Neptune Mutual",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x57f12fe6a4e5fe819eec699fadf9db2d06606bb4",
    "decimals": 18,
    "image": "https://rango.vip/i/hFiboE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.04894073,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NPM",
    "name": "Neptune Mutual",
    "isPopular": false,
    "chainId": "137",
    "address": "0x57f12fe6a4e5fe819eec699fadf9db2d06606bb4",
    "decimals": 18,
    "image": "https://rango.vip/i/jnf1Gu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.04894073,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NPM",
    "name": "Neptune Mutual",
    "isPopular": false,
    "chainId": "1",
    "address": "0x57f12fe6a4e5fe819eec699fadf9db2d06606bb4",
    "decimals": 18,
    "image": "https://rango.vip/i/Z9ZGYz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04894073,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NPM",
    "name": "Neptune Mutual",
    "isPopular": false,
    "chainId": "56",
    "address": "0x57f12fe6a4e5fe819eec699fadf9db2d06606bb4",
    "decimals": 18,
    "image": "https://rango.vip/i/k5m8MN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.04894073,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DINERO",
    "name": "Dinero Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6df0e641fc9847c0c6fde39be6253045440c14d3",
    "decimals": 18,
    "image": "https://rango.vip/i/DctWdP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.058996,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DINERO",
    "name": "Dinerobet",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa3ad8c7ab6b731045b5b16e3fdf77975c71abe79",
    "decimals": 18,
    "image": "https://rango.vip/i/4OQjWb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00062946,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CP",
    "name": "Cookies Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5bdc32663ec75e85ff4abc2cae7ae8b606a2cfca",
    "decimals": 18,
    "image": "https://rango.vip/i/UgFiZe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.216e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CP",
    "name": "CP",
    "isPopular": false,
    "chainId": "56",
    "address": "0x82c19905b036bf4e329740989dcf6ae441ae26c1",
    "decimals": 18,
    "image": "https://rango.vip/i/zMFrtt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.21249913571807e-9,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CP",
    "name": "Cookies Protocol",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf9d3d8b25b95bcda979025b74fdfa7ac3f380f9f",
    "decimals": 18,
    "image": "https://rango.vip/i/GXWnmd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.216e-9,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HWT",
    "name": "Honor World Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xbcc9c1763d54427bdf5efb6e9eb9494e5a1fbabf",
    "decimals": 18,
    "image": "https://rango.vip/i/nQ2Sws",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00098021,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ADVT",
    "name": "Advantis X",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69e37422cb87d963367f73a119c8ce9a4d529b72",
    "decimals": 9,
    "image": "https://rango.vip/i/ZRMJDR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.06832e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LYFE",
    "name": "Lyfe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd87de4ccef2c2fe651bc4d130cb1a365248f21fa",
    "decimals": 18,
    "image": "https://rango.vip/i/w8Xcfi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.89,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MATCH",
    "name": "MatchTrade",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb9e3c6cda17c8755a4e8a1cc944afb7fc7d7905",
    "decimals": 18,
    "image": "https://rango.vip/i/nzsrP6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027635,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MATCH",
    "name": "Match Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0dcb3e02798d1c6a9650fe1381015ec34705153",
    "decimals": 18,
    "image": "https://rango.vip/i/BwGluZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03264335,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "METADOGE",
    "name": "Meta Doge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8530b66ca3ddf50e0447eae8ad7ea7d5e62762ed",
    "decimals": 18,
    "image": "https://rango.vip/i/O6RHMn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.7845e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HAMI",
    "name": "HAMI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4sp2EUDrQf46rZun6sYAWzjrXwUpx2T3njuoKmV766RJ",
    "decimals": 9,
    "image": "https://rango.vip/i/a4O3wO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.001275043256349021,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HAMI",
    "name": "Hamachi",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x02150e97271fdc0d6e3a16d9094a0948266f07dd",
    "decimals": 18,
    "image": "https://rango.vip/i/bsdPtX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00000432,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HACHI",
    "name": "Hachi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a7e415c169ce3a580c6f374dc26f6aaad1eccfe",
    "decimals": 18,
    "image": "https://rango.vip/i/qhMf4d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.14532e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HACHI",
    "name": "Hachiko Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95af148dcdc6b36b977addf7ea2599c5e0483263",
    "decimals": 18,
    "image": "https://rango.vip/i/iT4Bht",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.0941e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HACHI",
    "name": "HACHI KUN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x100891bf73ba8274c234aa34621bc626ed6eca8e",
    "decimals": 9,
    "image": "https://rango.vip/i/umyfTa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.7809e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CMOS",
    "name": "CoinMerge OS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x87869a9789291a6cec99f3c3ef2ff71fceb12a8e",
    "decimals": 9,
    "image": "https://rango.vip/i/EEoc7f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EXD",
    "name": "Exorde",
    "isPopular": false,
    "chainId": "1",
    "address": "0x02de007d412266a2e0fa9287c103474170f06560",
    "decimals": 18,
    "image": "https://rango.vip/i/V9NEXj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02095779,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GETH",
    "name": "Guarded Ether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3802c218221390025bceabbad5d8c59f40eb74b8",
    "decimals": 18,
    "image": "https://rango.vip/i/Y5ogOQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2339.56,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GETH",
    "name": "Goerli ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd69db25f6d620a7bad3023c5d32761d353d3de9",
    "decimals": 18,
    "image": "https://rango.vip/i/xsW2zc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00926822,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LSD",
    "name": "Liquid Solana Derivative 42069",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DDti34vnkrCehR8fih6dTGpPuc3w8tL4XQ4QLQhc3xPa",
    "decimals": 9,
    "image": "https://rango.vip/i/BkUmKO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004756,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LSD",
    "name": "LSD Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfac77a24e52b463ba9857d6b758ba41ae20e31ff",
    "decimals": 18,
    "image": "https://rango.vip/i/XjwOi9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00258216,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LSD",
    "name": "Liquid Staking Derivatives",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97d4f49eeb0e2c96d5ebaa71ab8418e563ecd9fd",
    "decimals": 9,
    "image": "https://rango.vip/i/Gvdi07",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01824767,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LSD",
    "name": "Bad Trip",
    "isPopular": false,
    "chainId": "1",
    "address": "0x749b964f3dd571b177fc6e415a07f62b05047da4",
    "decimals": 18,
    "image": "https://rango.vip/i/EgDH0m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0029245879058903287,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "LSD",
    "name": "LSD Coin",
    "isPopular": false,
    "chainId": "324",
    "address": "0x458a2e32eabc7626187e6b75f29d7030a5202bd4",
    "decimals": 18,
    "image": "https://rango.vip/i/T7hfWl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.0029245879058903287,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOKE",
    "name": "Doke Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x517abf1fcdbd76bc75b532683ada9113e313a128",
    "decimals": 9,
    "image": "https://rango.vip/i/Hq4YIv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001508,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOPE",
    "name": "DogePepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8e3e431ffb17dac4bedec04b901a3c03179fd1b",
    "decimals": 8,
    "image": "https://rango.vip/i/1tMbFk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.536e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SHRAP",
    "name": "Shrapnel",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xd402298a793948698b9a63311404fbbee944eafd",
    "decimals": 18,
    "image": "https://rango.vip/i/BbjIkW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.02007125,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHRAP",
    "name": "Shrapnel",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31e4efe290973ebe91b3a875a7994f650942d28f",
    "decimals": 18,
    "image": "https://rango.vip/i/pNLhVq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02007125,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SATS",
    "name": "Satoshis Vision",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c22910c6f75f828b305e57c6a54855d8adeabf8",
    "decimals": 9,
    "image": "https://rango.vip/i/CeHYcM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0040778,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MOD",
    "name": "MODEFI (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8346ab8d5ea7a9db0209aed2d1806afa0e2c4c21",
    "decimals": 18,
    "image": "https://rango.vip/i/ecUG9L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03220829,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOD",
    "name": "Modefi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea1ea0972fa092dd463f2968f9bb51cc4c981d71",
    "decimals": 18,
    "image": "https://rango.vip/i/6tRAgK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03220829,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MOD",
    "name": "Modefi",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd4fbc57b6233f268e7fba3b66e62719d74deecbc",
    "decimals": 18,
    "image": "https://rango.vip/i/DfMR2w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03220829,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NERF",
    "name": "Neural Radiance Field",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf8942831f3a096f708b8b31f191b8958cf176c5",
    "decimals": 18,
    "image": "https://rango.vip/i/EMUq43",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04161607,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GDX",
    "name": "GrokDogeX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c21638d4bcb88568f88bc84a50e317715f8de8a",
    "decimals": 18,
    "image": "https://rango.vip/i/GD305i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000305,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEED",
    "name": "Meeds DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8503a7b00b4b52692cc6c14e5b96f142e30547b7",
    "decimals": 18,
    "image": "https://rango.vip/i/aB9pJ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.208063,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDG",
    "name": "Crypto SDG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f5def84ced3e9e295dae28df96d0b846de92c1a",
    "decimals": 18,
    "image": "https://rango.vip/i/I1hKHf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00073004,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "CAI",
    "name": "Colony Avalanche Index",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x48f88a3fe843ccb0b5003e70b4192c1d7448bef0",
    "decimals": 18,
    "image": "https://rango.vip/i/JJcBEM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 121.09,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAI",
    "name": "CryptoAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf36c5f04127f7470834ed6f98bddc1be62aba48d",
    "decimals": 18,
    "image": "https://rango.vip/i/89p2Sj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00152825,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CHAD",
    "name": "Based Chad",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xecaf81eb42cd30014eb44130b89bcd6d4ad98b92",
    "decimals": 18,
    "image": "https://rango.vip/i/wlFF0w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0000068,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHAD",
    "name": "CHAD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G7rwEgk8KgQ4RUTnMy2W2i7dRDq4hXHD4CSp9PSmSbRW",
    "decimals": 9,
    "image": "https://rango.vip/i/GTTkAj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00021551,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHAD",
    "name": "Chad",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68d009f251ff3a271477f77acb704c3b0f32a0c0",
    "decimals": 18,
    "image": "https://rango.vip/i/eCdKv6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000121,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHAD",
    "name": "CHAD FROG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e22b4f836a461ddc7765e5fad693688e76e6069",
    "decimals": 9,
    "image": "https://rango.vip/i/HUFRVo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.6706e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHAD",
    "name": "Chad Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b89b97169a797d94f057f4a0b01e2ca303155e4",
    "decimals": 18,
    "image": "https://rango.vip/i/pGQ5cJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000327,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ZKPEPE",
    "name": "ZKPEPE",
    "isPopular": false,
    "chainId": "324",
    "address": "0x7d54a311d56957fa3c9a3e397ca9dc6061113ab3",
    "decimals": 18,
    "image": "https://rango.vip/i/CLBz0F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00000151,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FGM",
    "name": "Feels Good Man",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f19d846d99a0e75581913b64510fe0e18bbc31f",
    "decimals": 18,
    "image": "https://rango.vip/i/gSLvJi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000062,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PLEB",
    "name": "Pleb",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x76baa16ff15d61d32e6b3576c3a8c83a25c2f180",
    "decimals": 18,
    "image": "https://rango.vip/i/N5wdIy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00007127,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLEB",
    "name": "Plebbit",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea81dab2e0ecbc6b5c4172de4c22b6ef6e55bd8f",
    "decimals": 18,
    "image": "https://rango.vip/i/3yrlri",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000265,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLEB",
    "name": "PLEBToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe19f85c920b572ca48942315b06d6cac86585c87",
    "decimals": 18,
    "image": "https://rango.vip/i/RrAyzH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.401e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLEB",
    "name": "Plebz",
    "isPopular": false,
    "chainId": "1",
    "address": "0x740a5ac14d0096c81d331adc1611cf2fd28ae317",
    "decimals": 9,
    "image": "https://rango.vip/i/PR5SqA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.344e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PEPI",
    "name": "Pepe Inscriptions",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x19706c142d33376240e418d6385f05691a5fa8e2",
    "decimals": 9,
    "image": "https://rango.vip/i/89hCI7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.34,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PEPI",
    "name": "PEPi",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x28a5e71bfc02723eac17e39c84c5190415c0de9f",
    "decimals": 9,
    "image": "https://rango.vip/i/IqLGSO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 4.79,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPI",
    "name": "Pepito",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2cb83e8e1b326373b7f1068d10c50ebfa04f070",
    "decimals": 9,
    "image": "https://rango.vip/i/bZXyZP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002781,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RUG",
    "name": "Rug",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe33f57f41a20b2f00dec91dcc1169597f36221f",
    "decimals": 18,
    "image": "https://rango.vip/i/A6SyB6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.67,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GENSLR",
    "name": "Good Gensler",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad1a5b8538a866ecd56ddd328b50ed57ced5d936",
    "decimals": 18,
    "image": "https://rango.vip/i/ANCV1X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.48342e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IYKYK",
    "name": "IYKYK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7cb683151a83c2b10a30cbb003cda9996228a2ba",
    "decimals": 18,
    "image": "https://rango.vip/i/A4yj4G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.87896e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PPC",
    "name": "Peercoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x044d078f1c86508e13328842cc75ac021b272958",
    "decimals": 6,
    "image": "https://rango.vip/i/nCxBcv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.332212,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OGSM",
    "name": "OG SMINEM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9778ac3d5a2f916aa9abf1eb85c207d990ca2655",
    "decimals": 18,
    "image": "https://rango.vip/i/s1INuH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.034e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUSK",
    "name": "MuskGold",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6069c9223e8a5da1ec49ac5525d4bb757af72cd8",
    "decimals": 18,
    "image": "https://rango.vip/i/8UnHYg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00087633,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUSK",
    "name": "MUSK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x719e7f0dadfdea25b78595da944f44d15d7e6795",
    "decimals": 18,
    "image": "https://rango.vip/i/P2Tn3t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00022273,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUSK",
    "name": "Musk",
    "isPopular": false,
    "chainId": "1",
    "address": "0x10f2cf6ef155460c5b716080eb57928652867f2e",
    "decimals": 18,
    "image": "https://rango.vip/i/FTgJ24",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.807e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRY",
    "name": "Foundry Logistics Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c972b70c533e2e045f333ee28b9ffb8d717be69",
    "decimals": 18,
    "image": "https://rango.vip/i/aXsztv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0009470184149011008,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NYAN",
    "name": "NYAN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "NYANpAp9Cr7YarBNrby7Xx4xU6No6JKTBuohNA3yscP",
    "decimals": 9,
    "image": "https://rango.vip/i/nTRMjY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.079797,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NYAN",
    "name": "Nyan Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "49ztDWXk7qEfz8Y1t8owmhLhewiA3ptVNPpRGAjRd8Wh",
    "decimals": 2,
    "image": "https://rango.vip/i/CcKYRM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0797194459431681,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NYAN",
    "name": "ArbiNYAN",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xed3fb761414da74b74f33e5c5a1f78104b188dfc",
    "decimals": 18,
    "image": "https://rango.vip/i/xLt75V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00200512,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NYAN",
    "name": "Nyan Meme Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ebe30595a44e5288c24161ddfc1e9fa08e33a0c",
    "decimals": 18,
    "image": "https://rango.vip/i/vmjJ4J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.111e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOXE",
    "name": "FOXE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x378e1be15be6d6d1f23cfe7090b6a77660dbf14d",
    "decimals": 18,
    "image": "https://rango.vip/i/hFWDIl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.34656e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAM",
    "name": "Bambi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9db0fb0aebe6a925b7838d16e3993a3976a64aab",
    "decimals": 18,
    "image": "https://rango.vip/i/tXwu1G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.08941e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPANK",
    "name": "SpankChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42d6622dece394b54999fbd73d108123806f6a18",
    "decimals": 18,
    "image": "https://rango.vip/i/P97EsE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00692129,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SPANK",
    "name": "SPANK",
    "isPopular": false,
    "chainId": "10",
    "address": "0xcfd1d50ce23c46d3cf6407487b2f8934e96dc8f9",
    "decimals": 18,
    "image": "https://rango.vip/i/kUl3rp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00005203654257564403,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WEN",
    "name": "Wen",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk",
    "decimals": 5,
    "image": "https://rango.vip/i/WnXlWl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008286199932406659,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WEN",
    "name": "WEN Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x830a8512db4f6fca51968593e2667156c2c483a8",
    "decimals": 18,
    "image": "https://rango.vip/i/jEbenI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.62719e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WEN",
    "name": "wen",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ba5273d53d8a964b0a338e25def0c082f5c29d3",
    "decimals": 8,
    "image": "https://rango.vip/i/nxfc2M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000231,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HARAM",
    "name": "Haram",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc961da88bb5e8ee2ba7dfd4c62a875ef80f7202f",
    "decimals": 9,
    "image": "https://rango.vip/i/fUypar",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.4603847948108e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LARRY",
    "name": "LarryCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd08623fb2a1f044025eec65886011cf5d0f06b01",
    "decimals": 18,
    "image": "https://rango.vip/i/UgNWNG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000349,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DSQ",
    "name": "DollarSqueeze",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7340ea46360576dc46ef49bce99bc5072c32421d",
    "decimals": 18,
    "image": "https://rango.vip/i/prNAyA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0048125,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEMD",
    "name": "MemeDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a786dac1f315c8a0e9962172ad8ae0c04d9c9b6",
    "decimals": 18,
    "image": "https://rango.vip/i/ysafCm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.29339e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUZZ",
    "name": "MUZZLE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xef3daa5fda8ad7aabff4658f1f78061fd626b8f0",
    "decimals": 18,
    "image": "https://rango.vip/i/lFNugk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000256,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ERA",
    "name": "Era Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6f9f0c4ad9af7ebd61ac5a1d4e0f2227f7b0e5f9",
    "decimals": 18,
    "image": "https://rango.vip/i/GxyPvF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00031508,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VROOM",
    "name": "Mushroom Racers Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "KARTdF5K68Q2nGppizG3DeCzp7AhHy6YXf2uTQjBSQx",
    "decimals": 0,
    "image": "https://rango.vip/i/tHptIO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.561480689909917e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "VROOM",
    "name": "VROOM",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x891c9b37177bdf8edc891119c9d8aeefda9a5246",
    "decimals": 18,
    "image": "https://rango.vip/i/uMakTq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 1.561480689909917e-7,
    "supportedSwappers": [
      "ThrusterV2.1"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONS",
    "name": "One Share",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5bb29c33c4a3c29f56f8aca40b4db91d8a5fe2c5",
    "decimals": 18,
    "image": "https://rango.vip/i/lPlt5Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.21,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEEP",
    "name": "BEEP Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9a0df129e798438a8ad995368bd82baa7eee8913",
    "decimals": 18,
    "image": "https://rango.vip/i/xE7dKM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.147e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ONE",
    "name": "Harmony",
    "isPopular": false,
    "chainId": "25",
    "address": "0xf0f2ccf4f18a13f73f7c48fa248645dd4ac51341",
    "decimals": 18,
    "image": "https://rango.vip/i/3ExvZW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.010754451445770821,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ONE",
    "name": "Harmony ONE",
    "isPopular": false,
    "chainId": "56",
    "address": "0x03ff0ff224f904be3118461335064bb48df47938",
    "decimals": 18,
    "image": "https://rango.vip/i/XYKu13",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0105832,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ONE",
    "name": "BigONE Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x04baf95fd4c52fd09a56d840baee0ab8d7357bf0",
    "decimals": 18,
    "image": "https://rango.vip/i/LHH109",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00046823,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONE",
    "name": "BigONE Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x946551dd05c5abd7cc808927480225ce36d8c475",
    "decimals": 18,
    "image": "https://rango.vip/i/oZpPur",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00046823,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "ONE",
    "name": "ONE",
    "isPopular": false,
    "chainId": "128",
    "address": "0x04baf95fd4c52fd09a56d840baee0ab8d7357bf0",
    "decimals": 18,
    "image": "https://rango.vip/i/LAIoYs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00046823,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "ARCH",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/23ab778d694c1ecfc59b91d8c399c115cc53b0bd1c61020d8e19519f002bdd85",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ARCH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.03186596,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCH",
    "name": "Archer DAO Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f3f9d3068568f8040775be2e8c03c103c61f3af",
    "decimals": 18,
    "image": "https://rango.vip/i/pHRjLK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03188895309462405,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCH",
    "name": "ARCH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73c69d24ad28e2d43d03cbf35f79fe26ebde1011",
    "decimals": 18,
    "image": "https://rango.vip/i/iyJIrp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02717283,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MAXI",
    "name": "Maximus",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x885d748c00a279b67a7749ec6b03301700dd0455",
    "decimals": 18,
    "image": "https://rango.vip/i/PADQ7w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.008154192188134686,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MAXI",
    "name": "Maximizer",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x7c08413cbf02202a1c13643db173f2694e0f73f0",
    "decimals": 9,
    "image": "https://rango.vip/i/QS0hBq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.005105150429607697,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAXI",
    "name": "Maximus DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d86eb9f43c57f6ff3bc9e23d8f9d82503f0e84b",
    "decimals": 8,
    "image": "https://rango.vip/i/Dq0Pqe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00197714,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NIHAO",
    "name": "Nihao",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3681a720605bd6f8fe9a2fabff6a7cdecdc605d",
    "decimals": 18,
    "image": "https://rango.vip/i/rc69m2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.39855e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NIHAO",
    "name": "Nihao Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa672b803e807ab9b7cb8514350523cd6d2e4d5cc",
    "decimals": 9,
    "image": "https://rango.vip/i/xAU5uH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.4615e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FINALE",
    "name": "Ben s Finale",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc7a2572fa8fdb0f7e81d6d3c4e3ccf78fb0dc374",
    "decimals": 18,
    "image": "https://rango.vip/i/Jjbg2b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000268,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FRENS",
    "name": "Chimp Frens",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HNm1VgnyhaMZZF71RjNFNiYLN76zyZTDcBZPjYveWFXX",
    "decimals": 6,
    "image": "https://rango.vip/i/IQ1m8x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000019996794121342476,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BLUE",
    "name": "Blue Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc12e249fabe1c5eb7c558e5f50d187687a244e31",
    "decimals": 18,
    "image": "https://rango.vip/i/JNNoY0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0017713420417095262,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLUE",
    "name": "BLUE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95d8bf2f57cf973251972b496dc6b1d9c6b5bce3",
    "decimals": 18,
    "image": "https://rango.vip/i/y5yvXL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00356569,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGC",
    "name": "DogeClub",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda8263d8ce3f726233f8b5585bcb86a3120a58b6",
    "decimals": 18,
    "image": "https://rango.vip/i/EUg0Ot",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.09976e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOOM",
    "name": "Foom",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd0d56273290d339aaf1417d9bfa1bb8cfe8a0933",
    "decimals": 18,
    "image": "https://rango.vip/i/D1DTl3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.0306e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALPHA",
    "name": "AlphaToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1faa113cbe53436df28ff0aee54275c13b40975",
    "decimals": 18,
    "image": "https://rango.vip/i/9qV4u3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.052051,
    "supportedSwappers": [
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ALPHA",
    "name": "Aavegotchi ALPHA",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6a3e7c3c6ef65ee26975b12293ca1aad7e1daed2",
    "decimals": 18,
    "image": "https://rango.vip/i/0Gazgn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00056632,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ALPHA",
    "name": "PolyAlpha Finance",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0b048d6e01a6b9002c291060bf2179938fd8264c",
    "decimals": 18,
    "image": "https://rango.vip/i/we7Lu9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.05222004753227423,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALPHA",
    "name": "Alpha",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38f9bb135ea88033f4377b9ea0fb5cfb773fec2f",
    "decimals": 18,
    "image": "https://rango.vip/i/0wzwF8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000132,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ALPHA",
    "name": "AlphaToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa1faa113cbe53436df28ff0aee54275c13b40975",
    "decimals": 18,
    "image": "https://rango.vip/i/pRhM4z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.052051,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ALPHA",
    "name": "Alpha Venture DAO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc9cbf102c73fb77ec14f8b4c8bd88e050a6b2646",
    "decimals": 18,
    "image": "https://rango.vip/i/QU1UDd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.05222004753227423,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ARCHI",
    "name": "Archi Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x93d504070ab0eede5449c89c5ea0f5e34d8103f8",
    "decimals": 18,
    "image": "https://rango.vip/i/rOqDDI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02598271,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$ELY",
    "name": "Elyssa AR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ed2fc62d6850eaadcb717465752dab591286839",
    "decimals": 18,
    "image": "https://rango.vip/i/fTsB4n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00529677,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAITABIT",
    "name": "SaitaBit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x927402ab67c0cda3c187e9dfe34554ac581441f2",
    "decimals": 18,
    "image": "https://rango.vip/i/wdKO2c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00068357,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TWEETY",
    "name": "Tweety",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa68fd12a3b0f7aea66fe8f7111ae3f8d9ac5058",
    "decimals": 9,
    "image": "https://rango.vip/i/G7hnAZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000017,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPE2.0",
    "name": "Pepe 2.0",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0305f515fa978cf87226cf8a9776d25bcfb2cc0b",
    "decimals": 18,
    "image": "https://rango.vip/i/Zf1slT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.7114e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JARED",
    "name": "Jared From Subway",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36880f14af2e85cae8e467827fa077d6bf12ea56",
    "decimals": 18,
    "image": "https://rango.vip/i/7Lp2yO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.79649e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEEPA",
    "name": "Peepa",
    "isPopular": false,
    "chainId": "1",
    "address": "0x88417754ff7062c10f4e3a4ab7e9f9d9cbda6023",
    "decimals": 18,
    "image": "https://rango.vip/i/IsIE7C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.59607e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ITX",
    "name": "Intellix",
    "isPopular": false,
    "chainId": "1",
    "address": "0x23d894fb4a0f551f2f923fc85e09819d1f3894b2",
    "decimals": 18,
    "image": "https://rango.vip/i/waMgeZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000356,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAGIEBOT",
    "name": "Wagie Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2c869382c7ac9f87ff73548d029d67c0f9dee31",
    "decimals": 9,
    "image": "https://rango.vip/i/aqpBhu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02450802,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LIZA",
    "name": "LIZA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b227d72570d3ead66014bca8305cbef7f90d1ee",
    "decimals": 18,
    "image": "https://rango.vip/i/osUz77",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00066604,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOTTY",
    "name": "Lotty",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb459f7204a8ac84f9e7758d6d839ebd01670e35c",
    "decimals": 18,
    "image": "https://rango.vip/i/NtF0HC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.30037e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAMS",
    "name": "Hamsters",
    "isPopular": false,
    "chainId": "1",
    "address": "0x48c87cdacb6bb6bf6e5cd85d8ee5c847084c7410",
    "decimals": 18,
    "image": "https://rango.vip/i/5q23dz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00802619,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHEREUM",
    "name": "VoldemortTrumpRobotnik-10Neko",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc32db1d3282e872d98f6437d3bcfa57801ca6d5c",
    "decimals": 18,
    "image": "https://rango.vip/i/snmwcH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001318,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHEREUM",
    "name": "HarryPotterTrumpHomerSimpson777Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24249b5a869a445c9b0ce269a08d73c618df9d21",
    "decimals": 8,
    "image": "https://rango.vip/i/jL4l32",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00030753,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MBOT",
    "name": "MoonBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38cf11283de05cf1823b7804bc75068bd6296957",
    "decimals": 18,
    "image": "https://rango.vip/i/mM6tp2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.153451,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVILPEPE",
    "name": "Evil Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7bd44cf5c0566aab26150a0cd5c3d20c5535686f",
    "decimals": 18,
    "image": "https://rango.vip/i/jOzBcZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001217,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "YOU",
    "name": "U Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf93fc7d6508ae2faf8fc5675e896bc38d6e7212c",
    "decimals": 18,
    "image": "https://rango.vip/i/DESPjR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00019814,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YOU",
    "name": "Youwho",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb92ba0a6a843379499770de82aa936d6ba0fd8ca",
    "decimals": 18,
    "image": "https://rango.vip/i/lSGJOR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00078095,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AG",
    "name": "Alpha Gardeners",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcc8e21f599995d1c8367054841b8af5024ddf01b",
    "decimals": 18,
    "image": "https://rango.vip/i/urkQ8U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003245,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOOLS",
    "name": "Blocktools",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc14b4d4ca66f40f352d7a50fd230ef8b2fb3b8d4",
    "decimals": 18,
    "image": "https://rango.vip/i/hfI5TP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.859983,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TOOLS",
    "name": "TOOLS",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1311b352467d2b5c296881badea82850bcd8f886",
    "decimals": 18,
    "image": "https://rango.vip/i/pcHc0D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00684685,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COINBT",
    "name": "CoinBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6fa5e1c43b5a466cbd1cae7993b67c982400d481",
    "decimals": 18,
    "image": "https://rango.vip/i/OJe2SX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.769521,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMNI",
    "name": "Omni Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36e66fbbce51e4cd5bd3c62b637eb411b18949d4",
    "decimals": 18,
    "image": "https://rango.vip/i/ZHpfOs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.796243871572903,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OMNI",
    "name": "OmniCat",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9e20461bc2c4c980f62f1b279d71734207a6a356",
    "decimals": 18,
    "image": "https://rango.vip/i/1ycMyP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00003381,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OMNI",
    "name": "OmniCat (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7mmXL6Et4SbpDs2iXoZQ3oPEeXeAiyETxh1QjDNi5qnV",
    "decimals": 8,
    "image": "https://rango.vip/i/IU0GDV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003381,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "OMNI",
    "name": "OmniCat",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xc48e605c7b722a57277e087a6170b9e227e5ac0a",
    "decimals": 18,
    "image": "https://rango.vip/i/HVqect",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00003381,
    "supportedSwappers": [
      "SushiBase"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "OMNI",
    "name": "OmniCat",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9e20461bc2c4c980f62f1b279d71734207a6a356",
    "decimals": 18,
    "image": "https://rango.vip/i/buD4jF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00003381,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMNI",
    "name": "OmniCat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e20461bc2c4c980f62f1b279d71734207a6a356",
    "decimals": 18,
    "image": "https://rango.vip/i/1BVYZ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003381,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "OMNI",
    "name": "OmniCat",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9e20461bc2c4c980f62f1b279d71734207a6a356",
    "decimals": 18,
    "image": "https://rango.vip/i/Kxu5Xz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00003381,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOBO",
    "name": "DogeBonk",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73c6a7491d0db90bdb0060308cde0f49dfd1d0b0",
    "decimals": 18,
    "image": "https://rango.vip/i/4xBoEe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00065476,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEWS",
    "name": "Newsly",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f8221e82e0d4669ad66eabf02a5baed43ea49e7",
    "decimals": 18,
    "image": "https://rango.vip/i/2DBsXg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00566132,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHEPE",
    "name": "Shiba V Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b0a8c7c34374c1d0c649917a97eee6c6c929b1b",
    "decimals": 9,
    "image": "https://rango.vip/i/pXrbKT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.89652e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TIP",
    "name": "Tipcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0176b898e92e814c06cc379e508ceb571f70bd40",
    "decimals": 18,
    "image": "https://rango.vip/i/V4aAxQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004762,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETE",
    "name": "Ethereum Express",
    "isPopular": false,
    "chainId": "1",
    "address": "0x000000e29fa2bd3e5c215ffc71aa66b29c9769a2",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00061119,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETE",
    "name": "EtherEmpires",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd27b128dc6536309cdebf7f1aff0cb7717bc0268",
    "decimals": 18,
    "image": "https://rango.vip/i/2fdOCz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00732031,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "VOLTA",
    "name": "Volta Club",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x9b06f3c5de42d4623d7a2bd940ec735103c68a76",
    "decimals": 18,
    "image": "https://rango.vip/i/c4sIji",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 31.13,
    "supportedSwappers": [
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VOLTA",
    "name": "Volta Club",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b06f3c5de42d4623d7a2bd940ec735103c68a76",
    "decimals": 18,
    "image": "https://rango.vip/i/c4sIji",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 31.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "CMP",
    "name": "CMP",
    "isPopular": false,
    "chainId": "128",
    "address": "0x681472b80d6e5c4fc27760a9b92e77d7eea99679",
    "decimals": 18,
    "image": "https://rango.vip/i/h92rum",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00003894915388036573,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIS",
    "name": "AISociety",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42a7797351dfd281a80807196c8508eb70bb2af9",
    "decimals": 18,
    "image": "https://rango.vip/i/6hJqEc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00038239,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AA",
    "name": "A3S",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb0ecc6ac0073c063dcfc026ccdc9039cae2998e1",
    "decimals": 18,
    "image": "https://rango.vip/i/UPeHjz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.278651,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIB2",
    "name": "SHIB2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2de7b02ae3b1f11d51ca7b2495e9094874a064c0",
    "decimals": 18,
    "image": "https://rango.vip/i/WKzgbk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001464,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CLFI",
    "name": "CLFI",
    "isPopular": false,
    "chainId": "137",
    "address": "0x08715f5c743f747de0005ad6c45336c163711137",
    "decimals": 8,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.87,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DONG",
    "name": "DongCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4208aa4d7a9a10f4f8bb7f6400c1b2161d946969",
    "decimals": 18,
    "image": "https://rango.vip/i/ikbQ3M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.35306e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PEUSD",
    "name": "peg eUSD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xdce765f021410b3266aa0053c93cb4535f1e12e0",
    "decimals": 18,
    "image": "https://rango.vip/i/1CjLQD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.89792,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEUSD",
    "name": "peg eUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd585aaafa2b58b1cd75092b51ade9fa4ce52f247",
    "decimals": 18,
    "image": "https://rango.vip/i/1CjLQD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.89792,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BB",
    "name": "Bookiebot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x562e12e1e792643d168c1fa01c1b7198a0f83c9f",
    "decimals": 18,
    "image": "https://rango.vip/i/jLpvJO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.297863,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PAW",
    "name": "PayPaw",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "pawSXHWsonrTey4SX7tz1fM9ksuLpE13Y54K57ym4Rg",
    "decimals": 6,
    "image": "https://rango.vip/i/u5c9vb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006759,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PAW",
    "name": "CopyCats token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3WV4fTWGvtWNvQb8oVU4t99By8KztDLtExqHnkPfHAA9",
    "decimals": 9,
    "image": "https://rango.vip/i/U2FvCB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.0418431447328e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAW",
    "name": "PAW",
    "isPopular": false,
    "chainId": "1",
    "address": "0x419777d3e39aa9b00405724eace5ea57620c9062",
    "decimals": 18,
    "image": "https://rango.vip/i/432qNU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.86728e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAW",
    "name": "PAWZONE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1aa51bc7eb181ce48ce626bf62f8956fa9555136",
    "decimals": 18,
    "image": "https://rango.vip/i/0jNqV5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000116,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "APU",
    "name": "Apu Apustaja",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ECutGg12PNhqhkvnH1s1FcuXgCDzKDNhSf5aLtANioR7",
    "decimals": 6,
    "image": "https://rango.vip/i/K1bHiV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0001717084258487734,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APU",
    "name": "Apu Apustaja",
    "isPopular": false,
    "chainId": "1",
    "address": "0x594daad7d77592a2b97b725a7ad59d7e188b5bfa",
    "decimals": 18,
    "image": "https://rango.vip/i/j9Y8cJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017237,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIT",
    "name": "I will poop it NFT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e4a47cac6a28a62dcc20990ed2cda9bc659469f",
    "decimals": 18,
    "image": "https://rango.vip/i/xUvQul",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.681e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLF",
    "name": "BlackLatexFist",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2b896c7f060ae441b76bbe47cddca934ba60f37f",
    "decimals": 18,
    "image": "https://rango.vip/i/ktna3o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.364e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FIDO",
    "name": "Fido",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b985d38b1fc891bb57bff59573626b1896d4aa1",
    "decimals": 9,
    "image": "https://rango.vip/i/CyClwB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001135,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGB",
    "name": "DogeBoy  OLD ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb0f92f94d02a4d634fd394c1889fe3cab1fcffc7",
    "decimals": 18,
    "image": "https://rango.vip/i/p0w22y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.737e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOJO",
    "name": "Dojo Supercomputer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8260328d0c405d9ca061d80199102ddc9089e43c",
    "decimals": 9,
    "image": "https://rango.vip/i/GH6oRZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.27901e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOJO",
    "name": "Project Dojo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34bc13de8e5124a7c47d4b7ff7c5ade6ee34faba",
    "decimals": 18,
    "image": "https://rango.vip/i/R9q8hS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000028,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOJO",
    "name": "DOJO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x180dae91d6d56235453a892d2e56a3e40ba81df8",
    "decimals": 18,
    "image": "https://rango.vip/i/EK3Goy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.1e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DOJO",
    "name": "DOJO",
    "isPopular": false,
    "chainId": "128",
    "address": "0x180dae91d6d56235453a892d2e56a3e40ba81df8",
    "decimals": 18,
    "image": "https://rango.vip/i/FA6D18",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 4.854913386006615e-10,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "SQUID",
    "name": "SQUID",
    "isPopular": false,
    "chainId": "66",
    "address": "0xc9a9be0f88b44889f30ea0978e984fb5a6efe68b",
    "decimals": 18,
    "image": "https://rango.vip/i/7ap5ox",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.027983931073995865,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SQUID",
    "name": "Squid Game",
    "isPopular": false,
    "chainId": "1",
    "address": "0x561cf9121e89926c27fa1cfc78dfcc4c422937a4",
    "decimals": 18,
    "image": "https://rango.vip/i/57gjk5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000002,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SQUID",
    "name": "Squid",
    "isPopular": false,
    "chainId": "1",
    "address": "0x21ad647b8f4fe333212e735bfc1f36b4941e6ad2",
    "decimals": 9,
    "image": "https://rango.vip/i/wxadjO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.1738228405544e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BAI",
    "name": "BlockAI",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6a27cd26a373530835b9fe7ac472b3e080070f64",
    "decimals": 18,
    "image": "https://rango.vip/i/NpksmB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.445187,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAI",
    "name": "BetAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36c79f0b8a2e8a3c0230c254c452973e7a3ba155",
    "decimals": 9,
    "image": "https://rango.vip/i/UJuUU5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00248971,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GME",
    "name": "GameStop",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8wXtPeU6557ETkp9WHFY1n1EcU6NxDvbAggHGsMYiHsB",
    "decimals": 9,
    "image": "https://rango.vip/i/FEJAm7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00278987,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GME",
    "name": "DumbMoney",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc4b4ec763722b71eb1d729749b447a9645f5f30",
    "decimals": 9,
    "image": "https://rango.vip/i/DBDtaU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00279866,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GME",
    "name": "DumbMoney",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e3fa615392688ddd9bf8f25d1f8dc744ac1a12c",
    "decimals": 9,
    "image": "https://rango.vip/i/PBcwtA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014295,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GME",
    "name": "GameStop",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc56c7a0eaa804f854b536a5f3d5f49d2ec4b12b8",
    "decimals": 9,
    "image": "https://rango.vip/i/z2E2oq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001415,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MZM",
    "name": "MetaZooMee",
    "isPopular": false,
    "chainId": "1",
    "address": "0x61b57bdc01e3072fab3e9e2f3c7b88d482734e05",
    "decimals": 18,
    "image": "https://rango.vip/i/tgNArh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010695,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGE-1",
    "name": "Satellite Doge-1 Mission",
    "isPopular": false,
    "chainId": "1",
    "address": "0xffe203b59393593965842439ce1e7d7c78109b46",
    "decimals": 18,
    "image": "https://rango.vip/i/Io2GH0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00053703,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGE-1",
    "name": "DOGE-1",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc7af7dc0a7a7e47e21eb50433a903d742370fffb",
    "decimals": 18,
    "image": "https://rango.vip/i/MmgsNc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000199,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZETA",
    "name": "Zeta",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf091867ec603a6628ed83d274e835539d82e9cc8",
    "decimals": 18,
    "image": "https://rango.vip/i/z0hy3y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.432978,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZETA",
    "name": "stoicDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb281d84989c06e2a6ccdc5ea7bf1663c79a1c31a",
    "decimals": 18,
    "image": "https://rango.vip/i/l0UshY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005768,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ZETA",
    "name": "Zeta",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0000028a2eb8346cd5c0267856ab7594b7a55308",
    "decimals": 18,
    "image": "https://rango.vip/i/o1WGYf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.432978,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FROGE",
    "name": "Froge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9a97b0798b1649045c1d7114f8c432846828404",
    "decimals": 18,
    "image": "https://rango.vip/i/5ZJl1g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.383e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOX",
    "name": "The Blox Project",
    "isPopular": false,
    "chainId": "1",
    "address": "0x320ed4c7243e35a00f9ca30a1ae60929d15eae37",
    "decimals": 18,
    "image": "https://rango.vip/i/rSJZFO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00092311,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOX",
    "name": "BLOX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc9f00080d96cea3ef92d2e2e563d4cd41fb5bb36",
    "decimals": 18,
    "image": "https://rango.vip/i/N2XqvE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00253527,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DMIND",
    "name": "DecentraMind",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3eb9c7ee5f72e51f61e832137719fe8d1e53a2ce",
    "decimals": 9,
    "image": "https://rango.vip/i/IC0iWV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0090205,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GROK",
    "name": "Grok",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BQ3F72yt9FVRgYrqCVCG3YohyBesDZ9bTuhGdmQ7GNEF",
    "decimals": 9,
    "image": "https://rango.vip/i/GqTe9L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.050629,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GROK",
    "name": "GROK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8390a1da07e376ef7add4be859ba74fb83aa02d5",
    "decimals": 9,
    "image": "https://rango.vip/i/TQbfPv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0037700482562343972,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GROK",
    "name": "GROK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ee27a1f959ea7ea2aa171a7e2e48fd9f17bb8eb",
    "decimals": 9,
    "image": "https://rango.vip/i/83Hfiy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.1039e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIND",
    "name": "Eternal AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60927b83ddd2096f38f22a8a2d84cf863402d1a1",
    "decimals": 18,
    "image": "https://rango.vip/i/8ilIyO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00433171,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIND",
    "name": "Morpheus Infrastructure Node",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc9eb61ffb66d5815d643bbb8195e17c49687ae1e",
    "decimals": 18,
    "image": "https://rango.vip/i/wLERJB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00156946,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "KEK",
    "name": "Aavegotchi KEK",
    "isPopular": false,
    "chainId": "137",
    "address": "0x42e5e06ef5b90fe15f853f59299fc96259209c5c",
    "decimals": 18,
    "image": "https://rango.vip/i/2s3an7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00201523,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEK",
    "name": "KeKChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x67954768e721fad0f0f21e33e874497c73ed6a82",
    "decimals": 18,
    "image": "https://rango.vip/i/Hjt42J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006952,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEK",
    "name": "EI Risitas",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0200b1c6598a996a339196259ffdc30c1f44339",
    "decimals": 9,
    "image": "https://rango.vip/i/mJdvjF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.69517e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEK",
    "name": "Kekcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9514a6eba63a0bbbe2faea919e773ebe0f527c1",
    "decimals": 18,
    "image": "https://rango.vip/i/A5gc5R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.6872e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEK",
    "name": "Pepe Prophecy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa6345ffadfa23dfc9014bce72ff2fa8712e54231",
    "decimals": 9,
    "image": "https://rango.vip/i/WzmsSb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.869e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "KAI",
    "name": "Kinetix Finance",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8ad3d3e6b1b7b65138bd508e48330b544539b2c3",
    "decimals": 18,
    "image": "https://rango.vip/i/d20XRT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0018140492693693743,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAI",
    "name": "Komputai",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe75f2acafba1ad56c5ed712ffbc1d31910e74396",
    "decimals": 18,
    "image": "https://rango.vip/i/DQqJvr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.059586,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAI",
    "name": "Kinetix Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0bbcefa5f3630cae34842cb9d9b36bc0d4257a0d",
    "decimals": 18,
    "image": "https://rango.vip/i/QDcFpu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00361395,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAI",
    "name": "Kreaitor",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd8695414822e25ab796c1d360914ddf510a01138",
    "decimals": 18,
    "image": "https://rango.vip/i/d8p2zt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00948632,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAI",
    "name": "Kai Ken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa045fe936e26e1e1e1fb27c1f2ae3643acde0171",
    "decimals": 9,
    "image": "https://rango.vip/i/0P3qHy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.373e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CEX",
    "name": "ChainEx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd01d133166820557db7138963bcd9009c54e4c33",
    "decimals": 18,
    "image": "https://rango.vip/i/IGZ8Hg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00454196,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEGA",
    "name": "DEGA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97aee01ed2aabad9f54692f94461ae761d225f17",
    "decimals": 18,
    "image": "https://rango.vip/i/wYvefX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010857,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NOVA",
    "name": "NOVA FINANCE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A",
    "decimals": 9,
    "image": "https://rango.vip/i/yDyOLg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00801714,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NOVA",
    "name": "Nova DAO",
    "isPopular": false,
    "chainId": "137",
    "address": "0x119fd89e56e3845b520644dcedf4a86cd0b66aa6",
    "decimals": 18,
    "image": "https://rango.vip/i/7LS7hT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.007998436419856772,
    "supportedSwappers": [
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOVA",
    "name": "Nova Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd0b3a986fff305854a7238a8e099cce1ced01a3d",
    "decimals": 18,
    "image": "https://rango.vip/i/nKFMvz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.77062e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOVA",
    "name": "Nova DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x82967568a57625675b260ebab1294038c9accc6e",
    "decimals": 18,
    "image": "https://rango.vip/i/9MHBl7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00069127,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GTX",
    "name": "Gigantix Wallet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c001d1c9e8c7b8dc717c714d30b31480ab360f5",
    "decimals": 18,
    "image": "https://rango.vip/i/MxNUOr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00038314,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XCEPT",
    "name": "XCeption",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac506c7dc601500e997cad42ea446624ed40c743",
    "decimals": 18,
    "image": "https://rango.vip/i/oE29Qg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00047955,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XETH",
    "name": "Leveraged ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe063f04f280c60aeca68b38341c2eecbec703ae2",
    "decimals": 18,
    "image": "https://rango.vip/i/3Tc97W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.63,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "XETH",
    "name": "XETH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x55380fe7a1910dff29a47b622057ab4139da42c5",
    "decimals": 18,
    "image": "https://rango.vip/i/E072Zx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.6249202021675992,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SANTA",
    "name": "Santaclaus",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG",
    "decimals": 9,
    "image": "https://rango.vip/i/gl7QEy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.52679955985784e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FLUID",
    "name": "FluidFi",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x876ec6be52486eeec06bc06434f3e629d695c6ba",
    "decimals": 18,
    "image": "https://rango.vip/i/ygm83E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.04742851759654635,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLUID",
    "name": "Fluid",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e47951508fd4a4126f8ff9cf5e6fa3b7cc8e073",
    "decimals": 18,
    "image": "https://rango.vip/i/n68wjU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04695439,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEAR",
    "name": "Bear Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x17837004ea685690b32dbead02a274ec4333a26a",
    "decimals": 18,
    "image": "https://rango.vip/i/NGughK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.83998e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BEAR",
    "name": "Bear",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc3eae9b061aa0e1b9bd3436080dc57d2d63fedc1",
    "decimals": 18,
    "image": "https://rango.vip/i/NHbWFD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.84018898795548e-10,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "MST",
    "name": "Metacosmic system token",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TRcjyxJpGRsaQtWNecG1QLNwRzyqoce4UB",
    "decimals": 18,
    "image": "https://rango.vip/i/dlGpfm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.009258385982516586,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETF",
    "name": "ETF The Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x667210a731447f8b385e068205759be2311b86d4",
    "decimals": 18,
    "image": "https://rango.vip/i/88KhZ3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00510207,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETF",
    "name": "ETF Rocks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e241a9ec66832a16bceaeb9156e524487f061d7",
    "decimals": 18,
    "image": "https://rango.vip/i/OG3QiG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001091,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "LONG",
    "name": "LONG",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x2816a491dd0b7a88d84cbded842a618e59016888",
    "decimals": 18,
    "image": "https://rango.vip/i/Tab7iU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 185.75,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LONG",
    "name": "Nobiko Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AYABiqKuTh9Va5Aqc6AujFevHwDGmECGQiFmKW5g3K4Z",
    "decimals": 5,
    "image": "https://rango.vip/i/emUNT5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00061583,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LONG",
    "name": "LONG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0080428794a79a40ae03cf6e6c1d56bd5467a4a2",
    "decimals": 18,
    "image": "https://rango.vip/i/StSoml",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 45.84,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LONG",
    "name": "LONG Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe59baad09b07086ee6c39bd0fc234c157c31ccc",
    "decimals": 18,
    "image": "https://rango.vip/i/h0OYGS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003034,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMOJI",
    "name": "emoji ERC20",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf12ccd17759367cf139776710b47b00c43d1ac2b",
    "decimals": 9,
    "image": "https://rango.vip/i/Ua3UVF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002157,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SILLY",
    "name": "Silly Dragon",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7EYnhQoR9YM3N7UoaKRoA44Uy8JeaZV3qyouov87awMs",
    "decimals": 9,
    "image": "https://rango.vip/i/hUCBzC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00870474,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNDX",
    "name": "UNODEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x185ece9bc75164f9fc0fbe44738e8dd1863f8464",
    "decimals": 18,
    "image": "https://rango.vip/i/yQdae8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00411238,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBCG",
    "name": "BBCGoldCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60c0d11c10a0c04acb47c6296156bdffac62ef97",
    "decimals": 4,
    "image": "https://rango.vip/i/LXMkqj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.241719,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGN",
    "name": "Agnus AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x550775e17ed6767621a1aed580e6eb29ede981e9",
    "decimals": 18,
    "image": "https://rango.vip/i/F9f6GR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002885,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAUDIBONK",
    "name": "Saudi Bonk",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e4990e997e1df3f6b39ff49384e2e7e99bc55fe",
    "decimals": 18,
    "image": "https://rango.vip/i/J9CneF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.75673e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0XC",
    "name": "0xCalls",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ca83216fae72717332469e6a2eb28c4bf9af9ec",
    "decimals": 18,
    "image": "https://rango.vip/i/yrapOy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01903966,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSTOR",
    "name": "StorageChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x50b275a15e4f5004aa96f972a30e6a9c718b203f",
    "decimals": 18,
    "image": "https://rango.vip/i/cHFd9g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0221011,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "LION",
    "name": "LION token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x49fb98f9b4a3183cd88e7a115144fdf00fa6fb95",
    "decimals": 18,
    "image": "https://rango.vip/i/qJAg2t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.000246368129599108,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LION",
    "name": "LionDEX",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8ebb85d53e6955e557b7c53acde1d42fd68561ec",
    "decimals": 18,
    "image": "https://rango.vip/i/kLRbwZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00081811,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LION",
    "name": "King Of Meme",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3c3745894d979f8f85761bd060520bddbc464e9",
    "decimals": 9,
    "image": "https://rango.vip/i/sgmFpp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.583e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SONA",
    "name": "Sonata Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcefde37817da4fc51ddc24e3820ad316784ee04b",
    "decimals": 18,
    "image": "https://rango.vip/i/RvpN6b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003412,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LYVE",
    "name": "Lyve Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0aa1582bebf8d96ea384b6829a5d41278579cd88",
    "decimals": 18,
    "image": "https://rango.vip/i/CzFAoZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.065853,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HONK",
    "name": "Honk",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3ag1Mj9AKz9FAkCQ6gAEhpLSX8B2pUbPdkb9iBsDLZNB",
    "decimals": 9,
    "image": "https://rango.vip/i/asidEX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00118979,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HONK",
    "name": "Clown Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5efcea234f7547de4569aad1215fa5d2adaced38",
    "decimals": 18,
    "image": "https://rango.vip/i/3q8MzV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.84636e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HONK",
    "name": "Pepoclown",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd8e8438cf7beed13cfabc82f300fb6573962c9e3",
    "decimals": 9,
    "image": "https://rango.vip/i/a81pac",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.439e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FAR",
    "name": "FarLaunch",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x93e6407554b2f02640ab806cd57bd83e848ec65d",
    "decimals": 18,
    "image": "https://rango.vip/i/U49pSH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00001362,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOYBOX",
    "name": "Memefi Toybox 404",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9f69c75a3b67425474f8bcab9a3626d8b8249e1",
    "decimals": 18,
    "image": "https://rango.vip/i/jnJct5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 42.22,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GIGA",
    "name": "GIGACHAD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "63LfDmNb3MQ8mw9MtZ2To9bEA2M71kZUUGq5tiJxcqj9",
    "decimals": 5,
    "image": "https://rango.vip/i/tVSLru",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01518375,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GIGA",
    "name": "GigaToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb2b9b5b136dc29feb0548dff315021b9b6c2ba0",
    "decimals": 18,
    "image": "https://rango.vip/i/L5OtK4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004502,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GIGA",
    "name": "GigaSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x83249c6794bca5a77eb8c0af9f1a86e055459cea",
    "decimals": 9,
    "image": "https://rango.vip/i/HFWTLO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000249,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "IOTA",
    "symbol": "GIGA",
    "name": "GigaFish",
    "isPopular": false,
    "chainId": "8822",
    "address": "0x7c311ad614ca8b7441739ceab8a218587ecc6554",
    "decimals": 18,
    "image": "https://rango.vip/i/hUgNuQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/IOTA/icon.svg",
    "usdPrice": 3.471663004865161e-7,
    "supportedSwappers": [
      "IOTAMagicSeaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPYRO",
    "name": "SPYRO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d7497751656618fc38cfb5478994a20f7e235df",
    "decimals": 18,
    "image": "https://rango.vip/i/dIbYYm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.37518e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "A51",
    "name": "A51 Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb3f13b0c61d65d67d7d6215d70c89533ee567a91",
    "decimals": 18,
    "image": "https://rango.vip/i/CNlsnJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.079701,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "A51",
    "name": "A51 Finance",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe9e7c09e82328c3107d367f6c617cf9977e63ed0",
    "decimals": 18,
    "image": "https://rango.vip/i/q4oi2t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.079701,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "EGG",
    "name": "chikn egg",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x7761e2338b35bceb6bda6ce477ef012bde7ae611",
    "decimals": 18,
    "image": "https://rango.vip/i/OstX8f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00420984,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EGG",
    "name": "Eggdog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EXA537HSBVpsFijENbt6Muuy9AADUN8dUmYKD4oKbjJE",
    "decimals": 9,
    "image": "https://rango.vip/i/vOCitM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00026524,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EGG",
    "name": "JustAnEgg",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "wo1zgt8rfrYpvdVi4nidoj1SYfcR4pQx69bmNv2JLhQ",
    "decimals": 9,
    "image": "https://rango.vip/i/n6sbax",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0019733,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EGG",
    "name": "EGG",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4ynyx6BzY2XGFgjjun9Cruj1bSRo8FLsAqNnPsW6jDsu",
    "decimals": 9,
    "image": "https://rango.vip/i/LLusxF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003565,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EGG",
    "name": "Nestree",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65ccd72c0813ce6f2703593b633202a0f3ca6a0c",
    "decimals": 18,
    "image": "https://rango.vip/i/Yc9neb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00296685,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EGG",
    "name": "EGG",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe99379955b676d5a7ebe3f42f2b684796e48d437",
    "decimals": 18,
    "image": "https://rango.vip/i/iDs6Xm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000816,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EGG",
    "name": "Waves Ducks",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc2708a3a4ba7f64bddc1a49f92f941bc77cad23a",
    "decimals": 18,
    "image": "https://rango.vip/i/oGLoOE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.53,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "EGG",
    "name": "Goose Golden Egg",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf952fc3ca7325cc27d15885d37117676d25bfda6",
    "decimals": 18,
    "image": "https://rango.vip/i/rVq5NK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01125489,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EGG",
    "name": "Waves Ducks",
    "isPopular": false,
    "chainId": "137",
    "address": "0x51de72b17c7bd12e9e6d69eb506a669eb6b5249e",
    "decimals": 18,
    "image": "https://rango.vip/i/84G94G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 5.53,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MDX",
    "name": "MultiDEX AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8edc6f7d2f23c10653972e611f707ce0562d61b1",
    "decimals": 18,
    "image": "https://rango.vip/i/H2BjP6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02318124,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MDX",
    "name": "MANDALA EXCHANGE TOKEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x947aeb02304391f8fbe5b25d7d98d649b57b1788",
    "decimals": 18,
    "image": "https://rango.vip/i/lYkPvt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00160361,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MDX",
    "name": "MDX Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9c65ab58d8d978db963e63f2bfb7121627e3a739",
    "decimals": 18,
    "image": "https://rango.vip/i/mN6pU7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01451094,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "MDX",
    "name": "MDX",
    "isPopular": false,
    "chainId": "128",
    "address": "0x25d2e80cb6b86881fd7e07dd263fb79f4abe033c",
    "decimals": 18,
    "image": "https://rango.vip/i/unZ24I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.01472528,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STONKS",
    "name": "STONKSDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d9a4d8ab4f5bd1d5d08ae3a95e8ed8bb4d7e3b9",
    "decimals": 18,
    "image": "https://rango.vip/i/L43h0k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.46392e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STONKS",
    "name": "Stonks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d4a23832fad83258b32ce4fd3109ceef4332af4",
    "decimals": 9,
    "image": "https://rango.vip/i/6vynrK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000536,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "INSP",
    "name": "INSPECT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8d279274789ccec8af94a430a5996eaace9609a9",
    "decimals": 18,
    "image": "https://rango.vip/i/Cpw1W0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02136499,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INSP",
    "name": "Inspect",
    "isPopular": false,
    "chainId": "1",
    "address": "0x186ef81fd8e77eec8bffc3039e7ec41d5fc0b457",
    "decimals": 18,
    "image": "https://rango.vip/i/keLvpK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02136499,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INSP",
    "name": "Inspire AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x179f782d7fbe745f40b20e0c7dbb6205d43fa4b9",
    "decimals": 9,
    "image": "https://rango.vip/i/k4VL17",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00589734,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MINU",
    "name": "Minu",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf48f91df403976060cc05dbbf8a0901b09fdefd4",
    "decimals": 18,
    "image": "https://rango.vip/i/jc3h8y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.796e-7,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BINU",
    "name": "BaseInu",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x63e71271719f03d7233f4fa306b6ea868d0f52ff",
    "decimals": 18,
    "image": "https://rango.vip/i/j75OVP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 3.66331e-7,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DNODE",
    "name": "DecentraNode",
    "isPopular": false,
    "chainId": "1",
    "address": "0x014337b35167b3711195361bb85259009e50a8a4",
    "decimals": 9,
    "image": "https://rango.vip/i/6IcV2n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.76,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEPE",
    "name": "Baby Pepe Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdbcd57cc74b180f928258f7b1a32f6f7e64bf12e",
    "decimals": 18,
    "image": "https://rango.vip/i/ahIHpX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011447,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "BEPE",
    "name": "Blast Pepe",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xb582dc28968c725d2868130752afa0c13ebf9b1a",
    "decimals": 18,
    "image": "https://rango.vip/i/4W0lZ1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 7.92666e-7,
    "supportedSwappers": [
      "ThrusterV2.1"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHOPPY",
    "name": "Choppy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf938346d7117534222b48d09325a6b8162b3a9e7",
    "decimals": 9,
    "image": "https://rango.vip/i/uw6bF4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.83556e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NS",
    "name": "NodeSynapse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x30672ae2680c319ec1028b69670a4a786baa0f35",
    "decimals": 9,
    "image": "https://rango.vip/i/E7O59I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000613,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RYU",
    "name": "RyuJin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xca530408c3e552b020a2300debc7bd18820fb42f",
    "decimals": 18,
    "image": "https://rango.vip/i/cjiBup",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.4848e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GNOME",
    "name": "GnomeLand",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x42069d11a2cc72388a2e06210921e839cfbd3280",
    "decimals": 18,
    "image": "https://rango.vip/i/tTfHkx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00020589,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "GNOME",
    "name": "GnomeLand",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x42069d11a2cc72388a2e06210921e839cfbd3280",
    "decimals": 18,
    "image": "https://rango.vip/i/tTfHkx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00020589,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GNOME",
    "name": "GnomeLand",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42069d11a2cc72388a2e06210921e839cfbd3280",
    "decimals": 18,
    "image": "https://rango.vip/i/tTfHkx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020589,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "PUMP",
    "name": "Pump",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x216a5a1135a9dab49fa9ad865e0f22fe22b5630a",
    "decimals": 18,
    "image": "https://rango.vip/i/vC3wk7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.073073,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WFO",
    "name": "WoofOracle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97d2fc7d16bc34121c3311f2e2e05d298c19956f",
    "decimals": 18,
    "image": "https://rango.vip/i/tT4mX0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.2895e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCADE",
    "name": "ArcadeFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x01194726b1b55bbf99cb083ba8e5dcc0834adbc3",
    "decimals": 9,
    "image": "https://rango.vip/i/dp9b55",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00041725,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLAST",
    "name": "BlastAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62431de84c503e152a8957ff51c8945aaaa7d929",
    "decimals": 9,
    "image": "https://rango.vip/i/vSrHVl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00032258,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLAST",
    "name": "Blast Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3127294f1fd3c097ef31e54301069346b29d0209",
    "decimals": 18,
    "image": "https://rango.vip/i/6k0pjU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001901,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLAST",
    "name": "SafeBLAST",
    "isPopular": false,
    "chainId": "1",
    "address": "0x614d7f40701132e25fe6fc17801fbd34212d2eda",
    "decimals": 9,
    "image": "https://rango.vip/i/TqgT5P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.919e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "BLAST",
    "name": "Blast",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xb1a5700fa2358173fe465e6ea4ff52e36e88e2ad",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00831629,
    "supportedSwappers": [
      "ThrusterV3",
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "PACM",
    "name": "Pacman Blastoff",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x0b4d0ee29857c3961b380d4ec138ea5814e346b9",
    "decimals": 18,
    "image": "https://rango.vip/i/E19a7Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 1.2212154748621844e-7,
    "supportedSwappers": [
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PIF",
    "name": "pepewifhat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8vRnnknwERunJEEDtEFoxzaxbxGnEY61FPbx8uTVKsUD",
    "decimals": 6,
    "image": "https://rango.vip/i/Fed5mG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000359,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SORA",
    "name": "Sora AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8a87405d9a4f2f866319b77004e88dff66c0d92",
    "decimals": 18,
    "image": "https://rango.vip/i/qe6a9g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00106887,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PORA",
    "name": "PORA AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd4ce03b97085e5023d3a5fbff6e4f2c4dffb7c3",
    "decimals": 18,
    "image": "https://rango.vip/i/bkIjXd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAMBIT",
    "name": "Gambit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2ae21de576e0fe0367651ddcf76e04dd0608c076",
    "decimals": 18,
    "image": "https://rango.vip/i/ynOmAz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.90868e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLANA",
    "name": "CatFrogDogShark",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "fESbUKjuMY6jzDH9VP8cy4p3pu2q5W2rK2XghVfNseP",
    "decimals": 6,
    "image": "https://rango.vip/i/tpekEh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00021887,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOLANA",
    "name": "BarbieCrashBandicootRFK88",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d806324b6df5af3c1a81acba14a8a62fe6d643f",
    "decimals": 18,
    "image": "https://rango.vip/i/RijoWD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.62086e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SANDY",
    "name": "Sandy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6hBry7w55A3NXDAXxjKqrx3oXfAatNkAJUSGt66ZRsZk",
    "decimals": 6,
    "image": "https://rango.vip/i/IuZK57",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00005795886850079323,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SANDY",
    "name": "SANDY",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc033f6932f71c6ff1de3177f90dff24b70e50618",
    "decimals": 9,
    "image": "https://rango.vip/i/m44TWj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.3265e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CREW",
    "name": "Crypto Crew Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xed198db07571dd3be19bfcf86a990c2137dd8648",
    "decimals": 18,
    "image": "https://rango.vip/i/6KRM4t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0000027987443363919695,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OBEMA",
    "name": "Mechelle Obema",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CzkM8bzWFdXsjtZQnz2piTxJKPtJ5mfTL8S6sNZg7n7S",
    "decimals": 2,
    "image": "https://rango.vip/i/ELo4Zw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00017812,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MFERS",
    "name": "MFERS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad913dcd987fe54ce823e4b755f90598cd62bb15",
    "decimals": 18,
    "image": "https://rango.vip/i/u54OaL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00034578,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AII",
    "name": "Artificial idiot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c0bb14e8367c384885a97bac6d5cceab474ed75",
    "decimals": 18,
    "image": "https://rango.vip/i/WrmuDh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003557,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BONK",
    "name": "Bonk On Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x72499bddb67f4ca150e1f522ca82c87bc9fb18c8",
    "decimals": 18,
    "image": "https://rango.vip/i/Cwflcx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2.5e-9,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BONK",
    "name": "BONK on ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4fbaf51b95b024d0d7cab575be2a1f0afedc9b64",
    "decimals": 18,
    "image": "https://rango.vip/i/l1WSaM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.598e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BONK",
    "name": "Bonk",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1151cb3d861920e07a38e03eead12c32178567f6",
    "decimals": 5,
    "image": "https://rango.vip/i/vDWYfi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001574,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETH",
    "name": "ETH",
    "isPopular": true,
    "chainId": "1",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2343.73,
    "supportedSwappers": [
      "Symbiosis",
      "Arbitrum Bridge",
      "ThorChain",
      "Chainflip",
      "Hyphen",
      "Satellite",
      "ThorChainStreamingSwap",
      "MayaProtocol",
      "stargate",
      "Optimism Bridge",
      "Across",
      "Lido",
      "Polygon Bridge",
      "OneInchEth",
      "XY Finance",
      "OrbiterV2",
      "Rainbow Bridge",
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ETH",
    "name": "ETH",
    "isPopular": true,
    "chainId": "10",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2346.64,
    "supportedSwappers": [
      "XY Finance",
      "Symbiosis",
      "OrbiterV2",
      "OpenOceanOptimism",
      "Hyphen",
      "Bridgers",
      "OptimismUniSwapV3",
      "stargate",
      "ParaSwap Optimism",
      "Optimism Bridge",
      "Across"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ETH",
    "name": "ETH",
    "isPopular": true,
    "chainId": "324",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 2343.7,
    "supportedSwappers": [
      "XY Finance",
      "Symbiosis",
      "SpaceFi",
      "OrbiterV2",
      "Across",
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "POLYGONZK",
    "symbol": "ETH",
    "name": "ETH",
    "isPopular": true,
    "chainId": "1101",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGONZK/icon.svg",
    "usdPrice": 2349.64,
    "supportedSwappers": [
      "XY Finance",
      "Symbiosis",
      "PolygonPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ETH",
    "name": "ETH",
    "isPopular": true,
    "chainId": "42161",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2344.67,
    "supportedSwappers": [
      "Symbiosis",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3",
      "Hyphen",
      "MayaProtocol",
      "stargate",
      "Across",
      "XY Finance",
      "ParaSwap Arbitrum",
      "OrbiterV2",
      "Bridgers",
      "SushiArbitrum",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "ETH",
    "name": "ETH",
    "isPopular": true,
    "chainId": "59144",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 2345.06,
    "supportedSwappers": [
      "XY Finance",
      "Symbiosis",
      "OrbiterV2",
      "LineaPancakeV3",
      "EchoDex",
      "stargate",
      "Across",
      "LineaKyberV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "ETH",
    "name": "ETH",
    "isPopular": true,
    "chainId": "81457",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 2349.95,
    "supportedSwappers": [
      "XY Finance",
      "Symbiosis",
      "OrbiterV2",
      "ThrusterV3",
      "ThrusterV2.03",
      "ThrusterV2.1"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "ETH",
    "name": "ETH",
    "isPopular": true,
    "chainId": "534352",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 2343.51,
    "supportedSwappers": [
      "XY Finance",
      "Symbiosis",
      "OrbiterV2",
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ETH",
    "name": null,
    "isPopular": true,
    "chainId": "8453",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/WETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2347.11,
    "supportedSwappers": [
      "Symbiosis",
      "BaseCurveFinance",
      "OrbiterV2",
      "SushiBase",
      "stargate",
      "Across",
      "OneInchBase",
      "AlienBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "ETH",
    "name": null,
    "isPopular": false,
    "chainId": "34443",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/WETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 2348.7303740215402,
    "supportedSwappers": [
      "Symbiosis",
      "SupSwapModeV3",
      "OrbiterV2",
      "SwapMode",
      "Across"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "ETH",
    "name": "Ether",
    "isPopular": true,
    "chainId": "SN_MAIN",
    "address": "0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 2341.86,
    "supportedSwappers": [
      "Avnu",
      "OrbiterV2",
      "MySwap",
      "10KSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "ETH",
    "name": "Ethereum",
    "isPopular": true,
    "chainId": "1313161554",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 2343.7,
    "supportedSwappers": [
      "TrisolarisSwap",
      "Rainbow Bridge",
      "OpenOceanAurora",
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "ETH",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/ea1d43981d5c9a1c4aaea9c23bb1d4fa126ba9bc7020a25e0ae4aa841ea25dc5",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/ETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 2344.5604450554883,
    "supportedSwappers": [
      "Satellite",
      "Osmosis"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ETH",
    "name": null,
    "isPopular": true,
    "chainId": "56",
    "address": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    "decimals": 18,
    "image": "https://rango.vip/i/2WgpA5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2338.38,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "OrbiterV2",
      "BSCPancakeV3",
      "Hyphen",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "ETH",
    "name": "Ethereum",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x639a647fbe20b6c8ac19e48e2de44ea792c62c5c",
    "decimals": 18,
    "image": "https://rango.vip/i/pZI3Eh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 48.47,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "BOBA",
    "symbol": "ETH",
    "name": "ETH",
    "isPopular": true,
    "chainId": "288",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/MTyH5i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BOBA/icon.svg",
    "usdPrice": 2402.752889280138,
    "supportedSwappers": [
      "OolongSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "ETH",
    "name": "Ethereum",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xfa9343c3897324496a05fc75abed6bac29f8a40f",
    "decimals": 18,
    "image": "https://rango.vip/i/fR57mY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 1551.5511641422106,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ETH",
    "name": "Ether (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
    "decimals": 8,
    "image": "https://rango.vip/i/f34jlO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2345.09,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETH",
    "name": "The Infinite Garden",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5e21d1ee5cf0077b314c381720273ae82378d613",
    "decimals": 18,
    "image": "https://rango.vip/i/bpdBQZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00437116,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "ETH",
    "name": "Heco-Peg ETH Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0x64ff637fb478863b7468bc97d30a5bf3a428a1fd",
    "decimals": 18,
    "image": "https://rango.vip/i/5pKxbT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 2346.291509786051,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "ETH",
    "name": "Ethereum",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TRFe3hT5oYhjSZ6f3ji5FJ7YCfrkWnHRvh",
    "decimals": 18,
    "image": "https://rango.vip/i/ERjRmf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 2348.7303740215402,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REALM",
    "name": "Realm",
    "isPopular": false,
    "chainId": "1",
    "address": "0x464fdb8affc9bac185a7393fd4298137866dcfb8",
    "decimals": 18,
    "image": "https://rango.vip/i/T3RMIi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00082769,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLPAKA",
    "name": "Solpaka",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BDHqX9YfJE3M6caox3obUX5YpWHz2cjnGFiZJtRghdCo",
    "decimals": 9,
    "image": "https://rango.vip/i/jstEaz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000271,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ORE",
    "name": "pTokens ORE (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd52f6ca48882be8fbaa98ce390db18e1dbe1062d",
    "decimals": 18,
    "image": "https://rango.vip/i/XLPAxY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00041519,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORE",
    "name": "pTokens ORE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4f640f2529ee0cf119a2881485845fa8e61a782a",
    "decimals": 18,
    "image": "https://rango.vip/i/PyZi4x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00041519,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ORE",
    "name": "pTokens ORE [via ChainPort.io]",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4ef285c8cbe52267c022c39da98b97ca4b7e2ff9",
    "decimals": 18,
    "image": "https://rango.vip/i/QvKq6t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00041519,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BULL",
    "name": "BULL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7ZYeCVdsXRFfh7TC5JRfBaZxQ6UhF5nNNdNtRzrdsDYF",
    "decimals": 9,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00477324,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BULL",
    "name": "Bullieverse",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9f95e17b2668afe01f8fbd157068b0a4405cc08d",
    "decimals": 18,
    "image": "https://rango.vip/i/OdP71i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00156306,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BULL",
    "name": "BULL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BULLa6g9e5UCuTXC5Z3Cf7s7CgvJhnJfY71DwipSmF8w",
    "decimals": 9,
    "image": "https://rango.vip/i/M0Xt51",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.06656781258576157,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BULL",
    "name": "Bullieverse",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9f95e17b2668afe01f8fbd157068b0a4405cc08d",
    "decimals": 18,
    "image": "https://rango.vip/i/Oz0vI4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00618967,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BULL",
    "name": "Mumu the Bull",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b7331c6e98bad1dc8f096ff3d98c93b3b9b1173",
    "decimals": 18,
    "image": "https://rango.vip/i/FrS69B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.006e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BULL",
    "name": "Bull Market",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9be776559fed779cabd67042a7b8987aae592541",
    "decimals": 18,
    "image": "https://rango.vip/i/547vLX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00030317,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BULL",
    "name": "TERRIER",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb418ded94300913fccbef784a49150f46f0fb827",
    "decimals": 18,
    "image": "https://rango.vip/i/si2xHD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003316,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TEQ",
    "name": "Teq Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x23150e1db43a04ae1ea993b76dd3c37156cabe0f",
    "decimals": 18,
    "image": "https://rango.vip/i/l8PqLJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00403202,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "BASE",
    "name": "Defido Coin",
    "isPopular": false,
    "chainId": "25",
    "address": "0x0422b63182798ae9876cae3cabd225d96b284d14",
    "decimals": 18,
    "image": "https://rango.vip/i/82Jb0g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 2.0137638301082455e-9,
    "supportedSwappers": [
      "CronaSwap"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BASE",
    "name": "BASE",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xd07379a755a8f11b57610154861d694b2a0f615a",
    "decimals": 18,
    "image": "https://rango.vip/i/Omew7C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000172,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BASE",
    "name": "BaseTools",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x0bf0ba3962a189d56f358143f38b7ffd26b8d48f",
    "decimals": 18,
    "image": "https://rango.vip/i/wTrbJq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0000017189154752027614,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BASE",
    "name": "Base Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07150e919b4de5fd6a63de1f9384828396f25fdc",
    "decimals": 9,
    "image": "https://rango.vip/i/cD928a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.951576,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BASE",
    "name": "Maximus Base",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9f84d418b008888a992ff8c6d22389c2c3504e0",
    "decimals": 8,
    "image": "https://rango.vip/i/YCbHlM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00451205,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEXUSAI",
    "name": "NexusAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe96edd48cf0c6e930ce55f171a721017b28e0f08",
    "decimals": 9,
    "image": "https://rango.vip/i/ZxAWsF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020495,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STATS",
    "name": "STATS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39142c18b6db2a8a41b7018f49e1478837560cad",
    "decimals": 9,
    "image": "https://rango.vip/i/8OAjO7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00682147,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "JELLY",
    "name": "Jelly",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x895a26f67a8375d0419cb7e4bd1109ac139ab741",
    "decimals": 18,
    "image": "https://rango.vip/i/uuOdid",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.01851402814594082,
    "supportedSwappers": [
      "SushiBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JELLY",
    "name": "Jelly Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5f06ffa53ad7f5914f493f16e57b56c8dd2ea80",
    "decimals": 18,
    "image": "https://rango.vip/i/uox9zs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.013199626397010328,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "DIG",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/307e5c96c8f60d1cbee269a9a86c0834e1db06f2b3788ae4f716edb97a48b97d",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/DIG.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0000330265823509888,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LORGY",
    "name": "Memeolorgy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "C3Vg7ps6dNPugYCpA52KETpdCytE6ki3oe8ccTsaY63i",
    "decimals": 9,
    "image": "https://rango.vip/i/7YvR7K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000017817839703720493,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "R4RE",
    "name": "R4RE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x23b586c0e79fb291ccb0244d468847eae9bb90f6",
    "decimals": 18,
    "image": "https://rango.vip/i/mpVkbE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00650139,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MEAT",
    "name": "Meat",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x47c3118ad183712acd42648e9e522e13690f29a0",
    "decimals": 6,
    "image": "https://rango.vip/i/ADml9V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00000477,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLOKI",
    "name": "FLOKI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcf0c122c6b73ff809c693db761e7baebe62b6a2e",
    "decimals": 9,
    "image": "https://rango.vip/i/yTgRYV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011833,
    "supportedSwappers": [
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "FLOKI",
    "name": "FLOKI",
    "isPopular": false,
    "chainId": "66",
    "address": "0x9851b036c11c59f8634bcb97e05170bdc213c1c9",
    "decimals": 9,
    "image": "https://rango.vip/i/Eqdmyd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.00002358836064959358,
    "supportedSwappers": [
      "OkcSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FLOKI",
    "name": "FLOKI",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfb5b838b6cfeedc2873ab27866079ac55363d37e",
    "decimals": 9,
    "image": "https://rango.vip/i/eLt2qK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00011833,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FLOKI",
    "name": "FLOKI",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2b3f34e9d4b127797ce6244ea341a83733ddd6e4",
    "decimals": 9,
    "image": "https://rango.vip/i/UhBFxd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00011877174250555887,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CAPY",
    "name": "Capybara",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CAPYD6Lrm7bTZ6C7t7JvSxvpEcfKQ9YNB7kUjh6p6XBN",
    "decimals": 9,
    "image": "https://rango.vip/i/YvqERU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00113527,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAPY",
    "name": "Capybara Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf03d5fc6e08de6ad886fca34abf9a59ef633b78a",
    "decimals": 18,
    "image": "https://rango.vip/i/owjws3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000552,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTGX",
    "name": "Montage Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06d6f0dd6703a1cfe16025dcc55f36f017887627",
    "decimals": 18,
    "image": "https://rango.vip/i/TKL1wS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000976,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOBA",
    "name": "Honey Badger",
    "isPopular": false,
    "chainId": "1",
    "address": "0x25cbb21a9da7c3c63bb77ccca5b2e2482aedb710",
    "decimals": 9,
    "image": "https://rango.vip/i/PlsAMh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.036509,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "COSMIC",
    "name": "COSMIC on Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7c101a0e141517009d3138743213e3e835a809de",
    "decimals": 18,
    "image": "https://rango.vip/i/WwvVek",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00002781,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COSMIC",
    "name": "Cosmic Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40e64405f18e4fb01c6fc39f4f0c78df5ef9d0e0",
    "decimals": 18,
    "image": "https://rango.vip/i/g2jPhV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.357503,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COSMIC",
    "name": "Cosmic",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a59eec501745ad6bdfc37558ddacb38ca5a8c48",
    "decimals": 18,
    "image": "https://rango.vip/i/51F9af",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 566.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "COSMIC",
    "name": "CosmicSwap",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa5eb60ca85898f8b26e18ff7c7e43623ccba772c",
    "decimals": 18,
    "image": "https://rango.vip/i/odojV0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0329358,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SGT",
    "name": "SGT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5b649c07e7ba0a1c529deaabed0b47699919b4a2",
    "decimals": 8,
    "image": "https://rango.vip/i/Yy7W1j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.25,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SGT",
    "name": "Shill Guard Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0e7626287bd02cbe3531c65148261bf0c0ed98b",
    "decimals": 18,
    "image": "https://rango.vip/i/RaCeYH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00280254,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SGT",
    "name": "SGT",
    "isPopular": false,
    "chainId": "128",
    "address": "0x3ca3d376bfe88510712b3e8ca5aee2cfd8c3d956",
    "decimals": 18,
    "image": "https://rango.vip/i/QISaaK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0000016114496836830174,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PEACH",
    "name": "Based Peaches",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8319767a7b602f88e376368dca1b92d38869b9b4",
    "decimals": 18,
    "image": "https://rango.vip/i/WIAYw4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00009878,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COPE",
    "name": "COPE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh",
    "decimals": 6,
    "image": "https://rango.vip/i/JBtJRD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01047879,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COPE",
    "name": "Cope Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd8e163967fed76806df0097b704ba721b9b37656",
    "decimals": 18,
    "image": "https://rango.vip/i/Op6u3P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000013,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COME",
    "name": "Community of Meme",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GmW12mAzyTj897Y3pgxDQzpnNid7q58E8T7V56rmaUdD",
    "decimals": 6,
    "image": "https://rango.vip/i/rtTzCD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.84173e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLOAK",
    "name": "Cloak",
    "isPopular": false,
    "chainId": "1",
    "address": "0x13f7b4581df403542286563c2f762077b2a368da",
    "decimals": 18,
    "image": "https://rango.vip/i/b0FL2r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011019,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DARE",
    "name": "The Dare",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72a93697a5ac73cfee39ff87298220f77c538611",
    "decimals": 18,
    "image": "https://rango.vip/i/cZjn1K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0005045,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "KITTY",
    "name": "Kitty",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xbca7f1998dc9ffb70b086543a808960a460abca7",
    "decimals": 18,
    "image": "https://rango.vip/i/qFqorc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0006998816645363492,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KITTY",
    "name": "Kitty Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6XWfkyg5mzGtKNftSDgYjyoPyUsLRf2rafj95XSFSFrr",
    "decimals": 9,
    "image": "https://rango.vip/i/aVLcn3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00032837,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KITTY",
    "name": "Kitty Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x61a35258107563f6b6f102ae25490901c8760b12",
    "decimals": 18,
    "image": "https://rango.vip/i/so3CYb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.92324e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KITTY",
    "name": "KITTY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8yJ15ee2AUQmwbWPxXLTTeBTzyMGn4MtSRKMqVHw1J1G",
    "decimals": 6,
    "image": "https://rango.vip/i/UsWxxB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00014207,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ZED",
    "name": "ZED RUN",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5ec03c1f7fa7ff05ec476d19e34a22eddb48acdc",
    "decimals": 18,
    "image": "https://rango.vip/i/7xhB4q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01458271,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SNACK",
    "name": "SnackboxAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x578b388528f159d026693c3c103100d36ac2ad65",
    "decimals": 18,
    "image": "https://rango.vip/i/wf2qDR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013194,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OTX",
    "name": "OTX EXCHANGE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7717f2828fe4dac8558d23ee4cdfed9544e9321f",
    "decimals": 18,
    "image": "https://rango.vip/i/yrPJm8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00023086,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "ROME",
    "name": "Rome",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x4a436073552044d5f2f49b176853ad3ad473d9d6",
    "decimals": 9,
    "image": "https://rango.vip/i/oHUenH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 11.72,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEME",
    "name": "Memecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb131f4a55907b10d1f0a50d8ab8fa09ec342cd74",
    "decimals": 18,
    "image": "https://rango.vip/i/2U64mw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00882095,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEME",
    "name": "MEME",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd5525d397898e5502075ea5e830d8914f6f0affe",
    "decimals": 18,
    "image": "https://rango.vip/i/fq6mKW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.008854526552798822,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHUCK",
    "name": "Chuck",
    "isPopular": false,
    "chainId": "1",
    "address": "0x420698ebc9b7c225731c02d887d0729057339d39",
    "decimals": 18,
    "image": "https://rango.vip/i/Fk5GzJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000311,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CCC",
    "name": "Chairman Chow",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6",
    "decimals": 9,
    "image": "https://rango.vip/i/ewkhrw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00026872860145039066,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CCC",
    "name": "CCC Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb09122d1e17170c1807bfc1ef3c614bd85e1b6e",
    "decimals": 18,
    "image": "https://rango.vip/i/RichNP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00128754,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OIL",
    "name": "Petroleum OIL",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x500756c7d239aee30f52c7e52af4f4f008d1a98f",
    "decimals": 18,
    "image": "https://rango.vip/i/upiGS5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.14119,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FROG",
    "name": "CRAZY FROG",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x89eb6680c8d85d384c02059e4b07e4dd8535e549",
    "decimals": 18,
    "image": "https://rango.vip/i/Mf0UNo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000227,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FROG",
    "name": "FrogSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4fee21439f2b95b72da2f9f901b3956f27fe91d5",
    "decimals": 18,
    "image": "https://rango.vip/i/l06iiB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04387013,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FROG",
    "name": "Frog",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a2c375553e6965b42c135bb8b15a8914b08de0c",
    "decimals": 9,
    "image": "https://rango.vip/i/3kUEcC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.88968e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPY",
    "name": "Pepy coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1e349fac47e50b42cd323c4285ef4622b60a5e0",
    "decimals": 18,
    "image": "https://rango.vip/i/y6eIg2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.5261e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SPEND",
    "name": "SolSpend",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3mp1MN5v7zdGXTvvcC9zUMoszMrh9pNdaCDkAQKc7Fec",
    "decimals": 5,
    "image": "https://rango.vip/i/8soTlL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00452678,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XTRACK",
    "name": "Xtrack AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee372d2b7e7c83de7e345267b5e4efc1899a4fab",
    "decimals": 18,
    "image": "https://rango.vip/i/gfYbj9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00234462,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PUN",
    "name": "Punkko",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FaSJ3PKDjaHatJMgpC92cmhjcJxnc8sbTkpaPuTF3hW1",
    "decimals": 6,
    "image": "https://rango.vip/i/Ae6knp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00005548,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FOMO",
    "name": "FOMO_BASE",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6432096f054288ee45b7f6ad8863a1f4a8e1201c",
    "decimals": 18,
    "image": "https://rango.vip/i/L7uW45",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00003776,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FOMO",
    "name": "FOMO Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xa7ea9d5d4d4c7cf7dbde5871e6d108603c6942a5",
    "decimals": 18,
    "image": "https://rango.vip/i/0XkYbu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00249156,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FOMO",
    "name": "FOMO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Cx9oLynYgC3RrgXzin7U417hNY9D6YB1eMGw4ZMbWJgw",
    "decimals": 9,
    "image": "https://rango.vip/i/Eoe8hN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00217938,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FOMO",
    "name": "Aavegotchi FOMO",
    "isPopular": false,
    "chainId": "137",
    "address": "0x44a6e0be76e1d9620a7f76588e4509fe4fa8e8c8",
    "decimals": 18,
    "image": "https://rango.vip/i/TEcpx4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00041563,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOMO",
    "name": "FOMO Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9028c2a7f8c8530450549915c5338841db2a5fea",
    "decimals": 18,
    "image": "https://rango.vip/i/kXCvvW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006875,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "IOTA",
    "symbol": "FOMO",
    "name": "FOMOFox",
    "isPopular": false,
    "chainId": "8822",
    "address": "0x30a1398cf11cb9e852e5d80ace7ffd5ec271169e",
    "decimals": 18,
    "image": "https://rango.vip/i/iRODLS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/IOTA/icon.svg",
    "usdPrice": 0.0005053243220298074,
    "supportedSwappers": [
      "IOTAMagicSeaSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOP",
    "name": "Boring Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BLwTnYKqf7u4qjgZrrsKeNs2EzWkMLqVCu6j8iHyrNA3",
    "decimals": 8,
    "image": "https://rango.vip/i/cfULeo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00025838,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WOLF",
    "name": "Landwolf",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x4f94b8aef08c92fefe416af073f1df1e284438ec",
    "decimals": 18,
    "image": "https://rango.vip/i/SNT2cc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00000551,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WOLF",
    "name": "LandWolf",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x88faea256f789f8dd50de54f9c807eef24f71b16",
    "decimals": 18,
    "image": "https://rango.vip/i/Vb21BP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2.954e-9,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WOLF",
    "name": "LandWolf",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Faf89929Ni9fbg4gmVZTca7eW6NFg877Jqn6MizT3Gvw",
    "decimals": 8,
    "image": "https://rango.vip/i/oixOa9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00140954,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WOLF",
    "name": "WOLF SOLANA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HmKqChBkZEvqFnH8sxja694n77ziYMBWaucZRKfJDRr2",
    "decimals": 5,
    "image": "https://rango.vip/i/eRf7vB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001003,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WOLF",
    "name": "moonwolf.io",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8f18dc399594b451eda8c5da02d0563c0b2d0f16",
    "decimals": 9,
    "image": "https://rango.vip/i/Oj6Xif",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 7.433e-8,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOLF",
    "name": "Landwolf",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf6afc05fccea5a53f22a3e39ffee861e016bd9a0",
    "decimals": 18,
    "image": "https://rango.vip/i/waTQtu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00038798,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOLF",
    "name": "Landwolf",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33abe795f9c1b6136608c36db211bd7590f5fdae",
    "decimals": 18,
    "image": "https://rango.vip/i/1qKE4v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000163,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOLF",
    "name": "Landwolf",
    "isPopular": false,
    "chainId": "1",
    "address": "0x67859a9314b9dca2642023ad8231beaa6cbf1933",
    "decimals": 18,
    "image": "https://rango.vip/i/1nGPSS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.3715e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOLF",
    "name": "Landwolf",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9c7d4fb43919def524c1a9d92fe836169eaf0615",
    "decimals": 18,
    "image": "https://rango.vip/i/G30aSD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00495233,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOLF",
    "name": "LandWolf",
    "isPopular": false,
    "chainId": "1",
    "address": "0x67466be17df832165f8c80a5a120ccc652bd7e69",
    "decimals": 18,
    "image": "https://rango.vip/i/HrExb1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001964,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NAZA",
    "name": "NAZA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FjtwiPH9gyffNX7mdE2ZS4s8hZRfZB2VRzyaKMNpaakS",
    "decimals": 9,
    "image": "https://rango.vip/i/LeV9yc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000006465098151898894,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEDE",
    "name": "Dede",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d7b68970d2be6dc93124477b4e2e1c9a6b180aa",
    "decimals": 9,
    "image": "https://rango.vip/i/FDdxTu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00100217,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GUM",
    "name": "GourmetGalaxy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4f5fa8f2d12e5eb780f6082dd656c565c48e0f24",
    "decimals": 18,
    "image": "https://rango.vip/i/sJdBc6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00667126,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GUM",
    "name": "Gourmet Galaxy",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc53708664b99df348dd27c3ac0759d2da9c40462",
    "decimals": 18,
    "image": "https://rango.vip/i/9WhiNf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00667126,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CATCH",
    "name": "SpaceCatch",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xbc4c97fb9befaa8b41448e1dfcc5236da543217f",
    "decimals": 18,
    "image": "https://rango.vip/i/0xWx4y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.227031,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRYO",
    "name": "CryoDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf4308b0263723b121056938c2172868e408079d0",
    "decimals": 18,
    "image": "https://rango.vip/i/ClhZuP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.64491,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "CRYO",
    "name": "CryoDAO",
    "isPopular": false,
    "chainId": "10",
    "address": "0x01402d1bc10ae6e96c0e494a5860748517a3c070",
    "decimals": 18,
    "image": "https://rango.vip/i/wexp5b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.6611786660014334,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AB",
    "name": "Arma Block",
    "isPopular": false,
    "chainId": "1",
    "address": "0x84fad63f8f26335f4f1bebc9fbf5ba277fd23c9e",
    "decimals": 18,
    "image": "https://rango.vip/i/huzK3W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00042871,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COOL",
    "name": "CoolCoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3wNmRxyZPzDWVAydsF3VuNKCNk89A8uSo7EzTnubDJcR",
    "decimals": 9,
    "image": "https://rango.vip/i/CX3Jq7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000056418477512809487,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COOL",
    "name": "Cool Cats",
    "isPopular": false,
    "chainId": "1",
    "address": "0x114f1388fab456c4ba31b1850b244eedcd024136",
    "decimals": 18,
    "image": "https://rango.vip/i/l2za0e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000056418477512809487,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "STX",
    "name": "Stacks",
    "isPopular": false,
    "chainId": "25",
    "address": "0x30bba7b57952e7028c47d5c6ab295d0da7139ef9",
    "decimals": 6,
    "image": "https://rango.vip/i/nXH79e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 1.3798543493573796,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "PEPE",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/e47f4e97c534c95b942729e1b25dbde111ea791411cff100515050bea0ac0c6b",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/PEPE.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PEPE",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0x25d887ce7a35172c62febfd67a1856f20faebb00",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/PEPE.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.000007,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "MayaProtocol"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPE",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x6982508145454ce325ddbe47a25d4ec3d2311933",
    "decimals": 18,
    "image": "https://rango.vip/i/ffelkU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000007,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "MayaProtocol",
      "EthUniSwapV2",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PEPE",
    "name": "Pepe",
    "isPopular": false,
    "chainId": "56",
    "address": "0x25d887ce7a35172c62febfd67a1856f20faebb00",
    "decimals": 18,
    "image": "https://rango.vip/i/JD1K9l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.000007,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PEPE",
    "name": "Pepe",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x698dc45e4f10966f6d1d98e3bfd7071d8144c233",
    "decimals": 9,
    "image": "https://rango.vip/i/usrbCc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 4.644e-9,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PEPE",
    "name": "PEPESOLANA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CYuXNHURE8cF3rQc1687DZnmsWx9ATNG3mZPFmryu22S",
    "decimals": 9,
    "image": "https://rango.vip/i/56VnWX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000007014688790394632,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PEPE",
    "name": "Pepe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F9CpWoyeBJfoRB8f2pBe2ZNPbPsEE76mWZWme3StsvHK",
    "decimals": 6,
    "image": "https://rango.vip/i/xgsmmH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00119137,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PEPE",
    "name": "Pepe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ox698TZsZAN2miNQj1FQb48A58FZ546c4sWqq9Y9vmU",
    "decimals": 4,
    "image": "https://rango.vip/i/3WTCtV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000007014688790394632,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPE",
    "name": "PEPE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2fb3842189fc7a699d047d9e647474f27779331d",
    "decimals": 18,
    "image": "https://rango.vip/i/T9mpA8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.6492e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPE",
    "name": "Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4dfae3690b93c47470b03036a17b23c1be05127c",
    "decimals": 18,
    "image": "https://rango.vip/i/Rp5zQH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.49,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPE",
    "name": "PEPE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa57ed6e54be8125bbe45d6ca330e45ebb71ef11e",
    "decimals": 18,
    "image": "https://rango.vip/i/y6JXJK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.39696e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPE",
    "name": "Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe042e9d09cb588331ff911c2b46fd833a3e5bd6",
    "decimals": 18,
    "image": "https://rango.vip/i/nu0HPN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00051652,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPE",
    "name": "Pepe King Prawn",
    "isPopular": false,
    "chainId": "1",
    "address": "0x51a59a02ba906194285e81eb1f98ffa28e7cf4c9",
    "decimals": 9,
    "image": "https://rango.vip/i/S2FgP0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00042091,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPE",
    "name": "PEPEGOLD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4a27e9aab8f8ba9de06766c8476ed1d16494e35f",
    "decimals": 18,
    "image": "https://rango.vip/i/QW6gYF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001177,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "PEPE",
    "name": "Pepe",
    "isPopular": false,
    "chainId": "324",
    "address": "0xfd282f16a64c6d304ac05d1a58da15bed0467c71",
    "decimals": 18,
    "image": "https://rango.vip/i/HY4mCL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.000007014688790394632,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PEPE",
    "name": "Pepe",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x35e6a59f786d9266c7961ea28c7b768b33959cbb",
    "decimals": 18,
    "image": "https://rango.vip/i/5W9Uk5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.000007014688790394632,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "PEPE",
    "name": "Pepe",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc1c167cc44f7923cd0062c4370df962f9ddb16f5",
    "decimals": 18,
    "image": "https://rango.vip/i/QYU0SS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.000007014688790394632,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BART",
    "name": "ReptilianZuckerBidenBartcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa89b728708be04f57c7a33c6f790b6f077298e26",
    "decimals": 18,
    "image": "https://rango.vip/i/CSyKX4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002401,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BART",
    "name": "Ballswapper Accelerator Reflection",
    "isPopular": false,
    "chainId": "1",
    "address": "0x54b2b9e2f5418db7f8aad6ccb495a0d2a1418e82",
    "decimals": 18,
    "image": "https://rango.vip/i/Regq3Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.29540331537e-13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPTA",
    "name": "OPTA Global",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e1cb26689a0b8763d15ffe9d7b1c637cd9282d4",
    "decimals": 18,
    "image": "https://rango.vip/i/HLVB5B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000218,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GENAI",
    "name": "GenBox",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1131d427ecd794714ed00733ac0f851e904c8398",
    "decimals": 18,
    "image": "https://rango.vip/i/0PLc3p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014825,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GENAI",
    "name": "GenAi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3585c1ee4a9c2144510084182b96af961f4ce10d",
    "decimals": 18,
    "image": "https://rango.vip/i/b6rI7v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.4882e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GENAI",
    "name": "GENUINE AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1b141e732a33d793d788ae65e1378013d49e0160",
    "decimals": 9,
    "image": "https://rango.vip/i/f42acv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008546,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "DOT",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/6b2b19d874851f631ff0af82c38a20d4b82f438c7a22f41eda33568345397244",
    "decimals": 10,
    "image": "https://rango.vip/tokens/ALL/DOT.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 3.9740016435532795,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "DOT",
    "name": "DOT",
    "isPopular": false,
    "chainId": "25",
    "address": "0x994047fe66406cbd646cd85b990e11d7f5db8fc7",
    "decimals": 10,
    "image": "https://rango.vip/i/d069ss",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 4.101657625505418,
    "supportedSwappers": [
      "CronaSwap",
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DOT",
    "name": "Polkadot Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
    "decimals": 18,
    "image": "https://rango.vip/i/i4rT75",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4.01,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UNIX",
    "name": "UniX Gaming (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8c4476dfec8e7eedf2de3e9e9461b7c14c828d46",
    "decimals": 18,
    "image": "https://rango.vip/i/WVwDNV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01659922,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNIX",
    "name": "UniX Gaming",
    "isPopular": false,
    "chainId": "1",
    "address": "0xddd6a0ecc3c6f6c102e5ea3d8af7b801d1a77ac8",
    "decimals": 18,
    "image": "https://rango.vip/i/Pz8Mhe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00154545,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "UNIX",
    "name": "UniX Gaming",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x6f97d3f120fbbdaacf1c9da61a8ad126b7426861",
    "decimals": 18,
    "image": "https://rango.vip/i/P92ZJ5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00154545,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HYPERAI",
    "name": "HyperHash AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34b64fd41675520bf5098bbcc37c679ca55fb5df",
    "decimals": 9,
    "image": "https://rango.vip/i/R0a8vU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00164838,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOPPY",
    "name": "Hoppy Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c130499d33097d4d000d3332e1672f75b431543",
    "decimals": 8,
    "image": "https://rango.vip/i/tcBNA3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.19664e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOPPY",
    "name": "Hoppy The Frog",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5c6f5fef89b64f36bfccb063962820136bac42f",
    "decimals": 9,
    "image": "https://rango.vip/i/qTYBgp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.12613e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOPPY",
    "name": "Hoppy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e79b51959cf968d87826592f46f819f92466615",
    "decimals": 9,
    "image": "https://rango.vip/i/QgzspI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005006,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THING",
    "name": "Nothing Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xffd822149fa6749176c7a1424e71a417f26189c8",
    "decimals": 18,
    "image": "https://rango.vip/i/EA3xu6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.7611e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZLDA",
    "name": "ZELDA 2 0",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9fa1e2c1f1199784a3e398e23e611dda3a581cb",
    "decimals": 6,
    "image": "https://rango.vip/i/SNoG8W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00433037,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZDAI",
    "name": "Zydio AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b683c400457ef31f3c27c90acb6ab69304d1b77",
    "decimals": 9,
    "image": "https://rango.vip/i/MDA5ms",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00022079,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "HIM",
    "name": "Human Intelligence Machin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xc1ffaef4e7d553bbaf13926e258a1a555a363a07",
    "decimals": 18,
    "image": "https://rango.vip/i/0HWotK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.04812504,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HIM",
    "name": "Himitsu",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3sk6T5gzpvPcvGDqXC4erFvrB9yeJrRJniXLaH6Cpump",
    "decimals": 6,
    "image": "https://rango.vip/i/OpgMzL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001583,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAD",
    "name": "BAD IDEA AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32b86b99441480a7e5bd3a26c124ec2373e3f015",
    "decimals": 18,
    "image": "https://rango.vip/i/v7VJWZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.667e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WISE",
    "name": "Wise Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb77e62709e39ad1cbeebe77cf493745aec0f453a",
    "decimals": 18,
    "image": "https://rango.vip/i/pjAyx0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.15264080105871525,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WISE",
    "name": "Wise Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66a0f676479cee1d7373f3dc2e2952778bff5bd6",
    "decimals": 18,
    "image": "https://rango.vip/i/QiNn9Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.153295,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLMEME",
    "name": "Solana Meme",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5MRMqvLZyRQhrMn2a8vSL3Kv9vfjNhjRKRPHtTBz1VEB",
    "decimals": 7,
    "image": "https://rango.vip/i/Ayc7Aw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 6.873411864003908e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "GGC",
    "name": "Green God Candle",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x4045b33f339a3027af80013fb5451fdbb01a4492",
    "decimals": 18,
    "image": "https://rango.vip/i/nNYALG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000147,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PROOF",
    "name": "PROOF Platform",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b4a69de6ca0defdd02c0c4ce6cb84de5202944e",
    "decimals": 9,
    "image": "https://rango.vip/i/TghjNe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.057254,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AIGPU",
    "name": "AIGPU Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x39353a32eceafe4979a8606512c046c3b6398cc4",
    "decimals": 18,
    "image": "https://rango.vip/i/cGzih1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00093181,
    "supportedSwappers": [
      "AlienBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PATTON",
    "name": "Patton",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a961d752eaa791cbff05991e4613290aec0d9ac",
    "decimals": 9,
    "image": "https://rango.vip/i/4lbz6y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.07152e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BUNNY",
    "name": "Bunny Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51",
    "decimals": 18,
    "image": "https://rango.vip/i/9OZCe3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.059373,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CAT",
    "name": "Simons Cat",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6894cde390a3f51155ea41ed24a33a4827d3063d",
    "decimals": 18,
    "image": "https://rango.vip/i/2NyyCx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00002333,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CAT",
    "name": "Catcoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7hWcHohzwtLddDUG81H2PkWq6KEkMtSDNkYXsso18Fy3",
    "decimals": 3,
    "image": "https://rango.vip/i/kyovXm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.26681e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAT",
    "name": "Cat Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x56015bbe3c01fe05bc30a8a9a9fd9a88917e7db3",
    "decimals": 18,
    "image": "https://rango.vip/i/FOakZp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.064251,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAT",
    "name": "Maxwell the cat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x122303734c898e9d233affc234271f04e42e77ad",
    "decimals": 18,
    "image": "https://rango.vip/i/rl77EQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009917,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAT",
    "name": "BitClave",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1234567461d3f8db7496581774bd869c83d51c93",
    "decimals": 18,
    "image": "https://rango.vip/i/B22RA1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003538,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAT",
    "name": "Scat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x02d7a93829b365b7ad4c582dace1493aac50a290",
    "decimals": 18,
    "image": "https://rango.vip/i/jtLrv2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.28842e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAT",
    "name": "Catcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x59f4f336bf3d0c49dbfba4a74ebd2a6ace40539a",
    "decimals": 9,
    "image": "https://rango.vip/i/MeduzA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.26681e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DCN",
    "name": "Compute Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f8958c757b65881cec98028cae0c4ee45726eae",
    "decimals": 18,
    "image": "https://rango.vip/i/HhJHz7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00173068,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "DCN",
    "name": "Dentacoin",
    "isPopular": false,
    "chainId": "10",
    "address": "0x1da650c3b2daa8aa9ff6f661d4156ce24d08a062",
    "decimals": 0,
    "image": "https://rango.vip/i/d8D3cu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2.10132e-7,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RYOSHI",
    "name": "Ryoshis Vision",
    "isPopular": false,
    "chainId": "1",
    "address": "0x777e2ae845272a2f540ebf6a3d03734a5a8f618e",
    "decimals": 18,
    "image": "https://rango.vip/i/zBTbnu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.313e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RYOSHI",
    "name": "Ryoshi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65e9ed59a6c03e97ae984b6c4ff912448ebd3566",
    "decimals": 18,
    "image": "https://rango.vip/i/O9GhKK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00627749,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ULTRA",
    "name": "ULTRA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x35282d87011f87508d457f08252bc5bfa52e10a0",
    "decimals": 18,
    "image": "https://rango.vip/i/1O11Sf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.035,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SHIB",
    "name": "Shiba Inu",
    "isPopular": false,
    "chainId": "25",
    "address": "0xbed48612bc69fa1cab67052b42a95fb30c1bcfee",
    "decimals": 18,
    "image": "https://rango.vip/i/IzF986",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.000013216357951540145,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIB",
    "name": "SHIBA INU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
    "decimals": 18,
    "image": "https://rango.vip/i/GmLZJ2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001316,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHIB",
    "name": "shibwifhat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F6qoefQq4iCBLoNZ34RjEqHjHkD8vtmoRSdw9Nd55J1k",
    "decimals": 9,
    "image": "https://rango.vip/i/QmEztA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00221203,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHIB",
    "name": "SHIBA INU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN",
    "decimals": 9,
    "image": "https://rango.vip/i/hrSovM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001318311320853188,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHIB",
    "name": "Shiba Inu (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CiKu4eHsVrc1eueVQeHn7qhXTcVu95gSQmBpX4utjL9z",
    "decimals": 8,
    "image": "https://rango.vip/i/p64tF9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001304,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIB",
    "name": "NicCageWaluigiElmo42069Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfcaf0e4498e78d65526a507360f755178b804ba8",
    "decimals": 18,
    "image": "https://rango.vip/i/rexR0c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005243,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SHIB",
    "name": "Shiba Inu",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x5033833c9fe8b9d3e09eed2f73d2aaf7e3872fd1",
    "decimals": 18,
    "image": "https://rango.vip/i/QbMdR8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00001318311320853188,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SHIB",
    "name": "SHIBA INU",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6f8a06447ff6fcf75d803135a7de15ce88c1d4ec",
    "decimals": 18,
    "image": "https://rango.vip/i/1LbCLl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00001318311320853188,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SHIB",
    "name": "SHIB",
    "isPopular": false,
    "chainId": "128",
    "address": "0xc38072aa3f8e049de541223a9c9772132bb48634",
    "decimals": 18,
    "image": "https://rango.vip/i/znlJlW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.000003916556838702891,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YEEHAW",
    "name": "YEEHAW",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AhwJjMyCqb6jWnvFr5hDTurSAZirKoofg6vFzhG8N71o",
    "decimals": 9,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004479827671910092,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MAGA",
    "name": "MAGA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "sfYDFZJguyF4YLZjje7qwwh41NRymFfZ3QXZbVm7Eyg",
    "decimals": 6,
    "image": "https://rango.vip/i/cpqLfh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000998967243807067,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MAGA",
    "name": "TRUMP’S HAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AwduJQUJoSCZm5BUdhF99iZkUTUGHU34TPft8w5kNbw1",
    "decimals": 9,
    "image": "https://rango.vip/i/12TjBb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000998967243807067,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAGA",
    "name": "MAGA Coin ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc9b6a17ebb43491635f603a01f8bb3e4b5d22228",
    "decimals": 9,
    "image": "https://rango.vip/i/PVh1E7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016614,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAGA",
    "name": "MAGA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd29da236dd4aac627346e1bba06a619e8c22d7c5",
    "decimals": 9,
    "image": "https://rango.vip/i/Pb4qXm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000998,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BCAT",
    "name": "Buisness Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7ABhUfzLKT7acJ9rFRZJUBvcqYfCfHLakTvZM75kAVPS",
    "decimals": 9,
    "image": "https://rango.vip/i/YQJ7GU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.002273707635545562,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BCAT",
    "name": "Bodega Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7bQsj9DciGXs6cTkhB3D1WbcEjuMpmD7amQRWjEVBpu",
    "decimals": 6,
    "image": "https://rango.vip/i/1REeBs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.002273707635545562,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCAT",
    "name": "BananaCat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0590cc9232ebf68d81f6707a119898219342ecb9",
    "decimals": 9,
    "image": "https://rango.vip/i/TT0U1Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00084111,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FEFE",
    "name": "Fefe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5a858d94011566f7d53f92feb54aff9ee3785db1",
    "decimals": 9,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.1943e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FEFE",
    "name": "Fefe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfefe157c9d0ae025213092ff9a5cb56ab492bab8",
    "decimals": 9,
    "image": "https://rango.vip/i/tcubrY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00292045,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOJO",
    "name": "Mojo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07ddacf367f0d40bd68b4b80b4709a37bdc9f847",
    "decimals": 18,
    "image": "https://rango.vip/i/RIgFST",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.63771e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOJO",
    "name": "MOJO The Gorilla",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b95fe1c06e58c269f1267e0f0093b7b26b85481",
    "decimals": 18,
    "image": "https://rango.vip/i/mkWDAE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003997,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOJO",
    "name": "Planet Mojo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed2d13a70acbd61074fc56bd0d0845e35f793e5e",
    "decimals": 18,
    "image": "https://rango.vip/i/6eQKgU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01908802,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RIBBIT",
    "name": "Ribbit Meme",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb794ad95317f75c44090f64955954c3849315ffe",
    "decimals": 18,
    "image": "https://rango.vip/i/H71j3q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.19e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOBBY",
    "name": "Bobby",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "48yNDqabAvGNfnkhadsV1MAvtp44fFDdHBRBdFhvpump",
    "decimals": 6,
    "image": "https://rango.vip/i/ReDvA9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00012589,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOBBY",
    "name": "Kennedy Memecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa462bde22d98335e18a21555b6752db93a937cff",
    "decimals": 18,
    "image": "https://rango.vip/i/6adsIP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000575,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "APEX",
    "name": "ApeX",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x61a1ff55c5216b636a294a07d77c6f4df10d3b56",
    "decimals": 18,
    "image": "https://rango.vip/i/EUoo7v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.35,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "APEX",
    "name": "APEX",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg",
    "decimals": 9,
    "image": "https://rango.vip/i/9Uxw6w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00316133,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APEX",
    "name": "ApeX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x52a8845df664d76c69d2eea607cd793565af42b8",
    "decimals": 18,
    "image": "https://rango.vip/i/EUoo7v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.35,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INS",
    "name": "Inscribe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9572938bcbf08adcee86fd12a7c0d08dc4ab841",
    "decimals": 18,
    "image": "https://rango.vip/i/0MJQf0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01258082,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRAIN",
    "name": "BrAIngent",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2aa35f6d376a9f1cc391db157e3eeb08819479c",
    "decimals": 18,
    "image": "https://rango.vip/i/qC8W9R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02372536,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BONKE",
    "name": "BONKE",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xb9898511bd2bad8bfc23eba641ef97a08f27e730",
    "decimals": 18,
    "image": "https://rango.vip/i/v9tBjV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 9.29222e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PE",
    "name": "PE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd32bec7c76d28aa054fc0c907d601b9263e22c7",
    "decimals": 18,
    "image": "https://rango.vip/i/ctZ3Qu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010313,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TRUMP",
    "name": "MAGA",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4ea98c1999575aaadfb38237dd015c5e773f75a2",
    "decimals": 9,
    "image": "https://rango.vip/i/75pAo4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.94,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TRUMP",
    "name": "MAGA (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HaP8r3ksG76PhQLTqR8FYBeNiQpejcFbQmiHbg787Ut1",
    "decimals": 8,
    "image": "https://rango.vip/i/Blv7zu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.97,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TRUMP",
    "name": "TRUMP",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AwRErBEFGTnohzfLeRSBH9HddQEy2oeRxnWLrbvFFh95",
    "decimals": 6,
    "image": "https://rango.vip/i/G7gWli",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.931915696029116,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TRUMP",
    "name": "MoonTrump",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BqhNdGtS1Nqtzi2MvZ7G8NN1vRuHZ12UpHGJKe71e1JT",
    "decimals": 9,
    "image": "https://rango.vip/i/Kj5Z4x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00032557,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TRUMP",
    "name": "Donald Trump",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4h8LjZWUfUQVgbEZ29UzTuGXNW6rwrJis78ZU66ekkPV",
    "decimals": 9,
    "image": "https://rango.vip/i/ZAkFOb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.931915696029116,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUMP",
    "name": "MAGA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4f4a556361b8b4869f97b8709ff47c1b057ea13b",
    "decimals": 9,
    "image": "https://rango.vip/i/Iv14NV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.46867e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUMP",
    "name": "MAGA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x576e2bed8f7b46d34016198911cdf9886f78bea7",
    "decimals": 9,
    "image": "https://rango.vip/i/vmStlK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.25,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUMP",
    "name": "MAGA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6aa56e1d98b3805921c170eb4b3fe7d4fda6d89b",
    "decimals": 9,
    "image": "https://rango.vip/i/7Q4QZv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.94,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ECI",
    "name": "E C Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32f0d04b48427a14fb3cbc73db869e691a9fec6f",
    "decimals": 18,
    "image": "https://rango.vip/i/tD7jvF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003397,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AMADEUS",
    "name": "AMADEUS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Eq9xBLGnBc2B6wkdoZW6v1aCC4evtSaNPkSFKaDNQNFr",
    "decimals": 9,
    "image": "https://rango.vip/i/kDIyWi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00010096406509831837,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKGUN",
    "name": "zkGUN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6873c95307e13beb58fb8fcddf9a99667655c9e4",
    "decimals": 18,
    "image": "https://rango.vip/i/YUVES8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00061381,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPURDO",
    "name": "Spurdo Spärde",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd3999188ff689b99d8097a4876f61e70b22f7881",
    "decimals": 18,
    "image": "https://rango.vip/i/MXtbhP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.23784e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPURDO",
    "name": "SPURDO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3927fb89f34bbee63351a6340558eebf51a19fb8",
    "decimals": 18,
    "image": "https://rango.vip/i/6UG5fS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00173332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPURDO",
    "name": "spurdo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x59c6766de1dc50a9c9db86cb0461b5ce07408ab7",
    "decimals": 8,
    "image": "https://rango.vip/i/Y0qCr5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.085e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLOCHI",
    "name": "Flochi Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd05f33b4fa630d6ba8a3ce75f7785439e6a3bb00",
    "decimals": 18,
    "image": "https://rango.vip/i/TvNuTq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.681e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLOCHI",
    "name": "Flochi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9cbefeec232cdbe428ec59ce310c6febc01d6163",
    "decimals": 18,
    "image": "https://rango.vip/i/dpLwzX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.40582e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "G",
    "name": "Gravity",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9c7beba8f6ef6643abd725e45a4e8387ef260649",
    "decimals": 18,
    "image": "https://rango.vip/i/GxaYQb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03218962,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "G",
    "name": "Andrew Tate's Dog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FmqVMWXBESyu4g6FT1uz1GABKdJ4j6wbuuLFwPJtqpmu",
    "decimals": 9,
    "image": "https://rango.vip/i/GrU8Ub",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.03223123436368953,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "G",
    "name": "G",
    "isPopular": false,
    "chainId": "1",
    "address": "0x276105758dfb270f5cd845aa04a6ba09c88699ca",
    "decimals": 9,
    "image": "https://rango.vip/i/6GbEZr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.13417e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "DOGE",
    "symbol": "DOGE",
    "name": null,
    "isPopular": false,
    "chainId": "",
    "address": null,
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/DOGE.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/DOGE/icon.svg",
    "usdPrice": 0.09760810214803257,
    "supportedSwappers": [
      "ThorChain",
      "ThorChainStreamingSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "DOGE",
    "name": "Dogecoin",
    "isPopular": false,
    "chainId": "25",
    "address": "0x1a8e39ae59e5556b56b76fcba98d22c9ae557396",
    "decimals": 8,
    "image": "https://rango.vip/i/1WY4ox",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0977672196003903,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DOGE",
    "name": "Dogecoin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xba2ae424d960c26247dd6c32edc70b295c744c43",
    "decimals": 8,
    "image": "https://rango.vip/i/j5QmZU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.097977,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGE",
    "name": "Department Of Government Efficiency",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1121acc14c63f3c872bfca497d10926a6098aac5",
    "decimals": 18,
    "image": "https://rango.vip/i/S77akV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.001189,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DOGE",
    "name": "DOGE",
    "isPopular": false,
    "chainId": "128",
    "address": "0x40280e26a572745b1152a54d1d44f365daa51618",
    "decimals": 8,
    "image": "https://rango.vip/i/4UR6Ft",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.316410312358565,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MONKE",
    "name": "Monke",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7771450ece9c61430953d2646f995e33a06c91f5",
    "decimals": 18,
    "image": "https://rango.vip/i/HMYcn5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000988,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONKE",
    "name": "Monke",
    "isPopular": false,
    "chainId": "1",
    "address": "0x18cc2ba8995c6307e355726244adb023cf00522f",
    "decimals": 9,
    "image": "https://rango.vip/i/M2AhHb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.0202e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONKE",
    "name": "MONKE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc8168d5665f4418353728ac970713e09c0b7c20e",
    "decimals": 18,
    "image": "https://rango.vip/i/M96ChG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.4144e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONKE",
    "name": "Monke Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb624960aaad05d433075a5c9e760adec26036934",
    "decimals": 9,
    "image": "https://rango.vip/i/wuTyKa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002267,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RWD",
    "name": "REWARD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5daa087714cb169f605c673a00aef62a9a7236a6",
    "decimals": 18,
    "image": "https://rango.vip/i/fO16eP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.4584e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BONES",
    "name": "BONES Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE",
    "decimals": 2,
    "image": "https://rango.vip/i/VM1wUz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000754,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BONES",
    "name": "Bones",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe7c8537f92b4feefdc19bd6b4023dfe79400cb30",
    "decimals": 18,
    "image": "https://rango.vip/i/XnVOmu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010004,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BMONEY",
    "name": "B MONEY",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf10da48d4aaa8d784c5e369cb998e263cfe32aa8",
    "decimals": 9,
    "image": "https://rango.vip/i/BELwve",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.35654e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOGE",
    "name": "Moge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x433f62964edd67d7349088fe44544f822f863a6c",
    "decimals": 9,
    "image": "https://rango.vip/i/vxMoWx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.882e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BAGS",
    "name": "Heco BAGS Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0x6868d406a125eb30886a6dd6b651d81677d1f22c",
    "decimals": 18,
    "image": "https://rango.vip/i/WdcU3X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.189822,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKX",
    "name": "ZKX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb91d822dfef943165f368d92c16bac9bd5ec6842",
    "decimals": 18,
    "image": "https://rango.vip/i/ZEihGJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.019194519031589325,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RDT",
    "name": "Ridotto",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4740735aa98dc8aa232bd049f8f0210458e7fca3",
    "decimals": 18,
    "image": "https://rango.vip/i/RKbxPN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01166596,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RDT",
    "name": "Ridotto",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe9c64384deb0c2bf06d991a8d708c77eb545e3d5",
    "decimals": 18,
    "image": "https://rango.vip/i/mqRyYn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01166596,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GHD",
    "name": "Giftedhands",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b544e6fcf6c8dce9d8b45a4fdf21c9b02f9fda9",
    "decimals": 18,
    "image": "https://rango.vip/i/RymKkm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEURON",
    "name": "Cerebrum DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xab814ce69e15f6b9660a3b184c0b0c97b9394a6b",
    "decimals": 18,
    "image": "https://rango.vip/i/AfoyS3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00022215,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "APE",
    "name": "APE",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xd7c295e399ca928a3a14b01d760e794f1adf8990",
    "decimals": 18,
    "image": "https://rango.vip/i/1nZnox",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 7.373148900309274,
    "supportedSwappers": [
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "APE",
    "name": "ApeCoin",
    "isPopular": false,
    "chainId": "25",
    "address": "0x9c62f89a8c9907582f21205ce90443730361ea05",
    "decimals": 18,
    "image": "https://rango.vip/i/VjP16S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.6275671987975873,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APE",
    "name": "ApeCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4d224452801aced8b2f0aebe155379bb5d594381",
    "decimals": 18,
    "image": "https://rango.vip/i/a1c0iA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.624021,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Bridgers",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "APE",
    "name": "ApeCoin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc762043e211571eb34f1ef377e5e8e76914962f9",
    "decimals": 18,
    "image": "https://rango.vip/i/RdXbuS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.6252150106315549,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "APE",
    "name": "Ape.lol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DF5yCVTfhVwvS1VRfHETNzEeh1n6DjAqEBs3kj9frdAr",
    "decimals": 9,
    "image": "https://rango.vip/i/EyqrcI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00023314,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APE",
    "name": "Ape",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0385e7283c83e2871e9af49eec0966088421ddd",
    "decimals": 18,
    "image": "https://rango.vip/i/XfEnx4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.6612e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "APE",
    "name": "ApeCoin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x74885b4d524d497261259b38900f54e6dbad2210",
    "decimals": 18,
    "image": "https://rango.vip/i/UpXIpj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.6252150106315549,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BRETT",
    "name": "Brett",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x532f27101965dd16442e59d40670faf5ebb142e4",
    "decimals": 18,
    "image": "https://rango.vip/i/joLF4T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.067652,
    "supportedSwappers": [
      "SushiBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BRETT",
    "name": "Brett",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DxtssVdyYe4wWE5f5zEgx2NqtDFbVL3ABGY62WCycHWg",
    "decimals": 9,
    "image": "https://rango.vip/i/VbJuVi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00103158,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRETT",
    "name": "Brett",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80ee5c641a8ffc607545219a3856562f56427fe9",
    "decimals": 9,
    "image": "https://rango.vip/i/DoRHA1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00988118,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRETT",
    "name": "Brett",
    "isPopular": false,
    "chainId": "1",
    "address": "0x240d6faf8c3b1a7394e371792a3bf9d28dd65515",
    "decimals": 9,
    "image": "https://rango.vip/i/d5fJoz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.065812,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRETT",
    "name": "BRETT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66e564819340cc2f54abceb4e49941fa07e426b4",
    "decimals": 9,
    "image": "https://rango.vip/i/lMnKBK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000308,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRETT",
    "name": "Brett",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66bff695f3b16a824869a8018a3a6e3685241269",
    "decimals": 18,
    "image": "https://rango.vip/i/Pb0Ect",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000209,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SPIKE",
    "name": "Spike",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BX9yEgW8WkoWV8SvqTMMCynkQWreRTJ9ZS81dRXYnnR9",
    "decimals": 6,
    "image": "https://rango.vip/i/kJduCw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00019513,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SPIKE",
    "name": "Spike",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "sPiKEYAqoaGYYBAnPxro8NLSYLu93sr56n352jJRLN5",
    "decimals": 9,
    "image": "https://rango.vip/i/Hno9P7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00007963,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPIKE",
    "name": "Spike",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19848077f45356b21164c412eff3d3e4ff6ebc31",
    "decimals": 9,
    "image": "https://rango.vip/i/OFTXiI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000208,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "MAD",
    "name": "MAD bucks token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x212331e1435a8df230715db4c02b2a3a0abf8c61",
    "decimals": 18,
    "image": "https://rango.vip/i/H9v0sI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00638622,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAD",
    "name": "MADToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5b09a0371c1da44a8e24d36bf5deb1141a84d875",
    "decimals": 18,
    "image": "https://rango.vip/i/u2Z1nm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000010549555000861573,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MICKEY",
    "name": "Steamboat Willie",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9609b540e5dedddb147abbf9812ade06b1e61b2c",
    "decimals": 18,
    "image": "https://rango.vip/i/4FYrIp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021823,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZAZZLES",
    "name": "Zazzles",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44a023a4c32bdd2c89ee87ee76a2332b1a883012",
    "decimals": 9,
    "image": "https://rango.vip/i/KgCM1h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.66515e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MIU",
    "name": "Miu",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x77f8fbccd9995d1a00ae94badaa293e7eafc4a4d",
    "decimals": 18,
    "image": "https://rango.vip/i/Qj6IWL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0000081,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SHIBA",
    "name": "Shiba Armstrong",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x041fdf3f472d2c8a7ecc458fc3b7f543e6c57ef7",
    "decimals": 9,
    "image": "https://rango.vip/i/nHjuUF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00017382,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIBA",
    "name": "Shiba",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd1450a131599ff34f3be1775d8c8bf79e353d8c",
    "decimals": 18,
    "image": "https://rango.vip/i/YvX3Ir",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.30859e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BARRON",
    "name": "Mini Donald",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f70300bce8c2302780bd0a153ebb75b8ca7efcb",
    "decimals": 9,
    "image": "https://rango.vip/i/l5vMEr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000063,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TONT",
    "name": "TONKIT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa8da83b0b8fca779c36f3e39fa93df2a3906617",
    "decimals": 18,
    "image": "https://rango.vip/i/EwlUIf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017321,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "PAUL",
    "name": "PAUL",
    "isPopular": false,
    "chainId": "128",
    "address": "0xfc01b8f883a89278235ba674bbe2bb48db96d9cf",
    "decimals": 18,
    "image": "https://rango.vip/i/J7LmbP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 2.493598879940739e-8,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KRAZY",
    "name": "krazy n d ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x187df9748016da82578c83a61c3b3093ac6b8669",
    "decimals": 18,
    "image": "https://rango.vip/i/HowVTh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.62482e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "MEOW",
    "name": "Zeek Coin",
    "isPopular": false,
    "chainId": "324",
    "address": "0x79db8c67d0c33203da4efb58f7d325e1e0d4d692",
    "decimals": 18,
    "image": "https://rango.vip/i/cwIVtI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00000167,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MEOW",
    "name": "MeowCat",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x8ad25b0083c9879942a64f00f20a70d3278f6187",
    "decimals": 18,
    "image": "https://rango.vip/i/BMcKc9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00771439,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEOW",
    "name": "Schrodinger",
    "isPopular": false,
    "chainId": "1",
    "address": "0x74d2d73b455540b037298c0e0925bc702aedbe4a",
    "decimals": 9,
    "image": "https://rango.vip/i/WmjD6P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.97259e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEOW",
    "name": "MEOW COIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e7572fb16e176d40d28090e51a7a9ea08f68199",
    "decimals": 9,
    "image": "https://rango.vip/i/5GAICC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.1212e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEOW",
    "name": "Meowshi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x650f44ed6f1fe0e1417cb4b3115d52494b4d9b6d",
    "decimals": 18,
    "image": "https://rango.vip/i/5ZdaZw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.007737993925953055,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ARG",
    "name": "ArgentinaCoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9XRpjZjhJPeWtUymiEWn3FW7uAnMeQca14ucTWWWyP2g",
    "decimals": 6,
    "image": "https://rango.vip/i/uqMbjt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00016043,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUCK",
    "name": "DLP Duck Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f",
    "decimals": 18,
    "image": "https://rango.vip/i/Z6lNau",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00169483,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUCK",
    "name": "Unit Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x92e187a03b6cd19cb6af293ba17f2745fd2357d5",
    "decimals": 18,
    "image": "https://rango.vip/i/lipS63",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040293,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "DUCK",
    "name": "Unit protocol",
    "isPopular": false,
    "chainId": "10",
    "address": "0x0e49ca6ea763190084c846d3fc18f28bc2ac689a",
    "decimals": 18,
    "image": "https://rango.vip/i/OvRdkE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.001702636884559779,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STRUMP",
    "name": "Super Trump",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7039cd6d7966672f194e8139074c3d5c4e6dcf65",
    "decimals": 9,
    "image": "https://rango.vip/i/qX2zc2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00541924,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPEG",
    "name": "Pepe Girl",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8bcbef61acd66537362f38167f11875134ffcd63",
    "decimals": 18,
    "image": "https://rango.vip/i/mP94OR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.8955e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FINK",
    "name": "Based Fink",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9ee4ed687d1ae6fc3a7a427add25c6356876af6c",
    "decimals": 18,
    "image": "https://rango.vip/i/MuLjWy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00009839,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KERMIT",
    "name": "Kermit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53250b5dfa8c911547afeaf18db025024c8e919a",
    "decimals": 9,
    "image": "https://rango.vip/i/0ZU66o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016442,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARKY",
    "name": "Arky",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9727eaf447203be268e5d471b6503bf47a71ea72",
    "decimals": 9,
    "image": "https://rango.vip/i/dpBkNM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.432e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MOM",
    "name": "Monetum",
    "isPopular": false,
    "chainId": "10",
    "address": "0x5e70affe232e2919792f77eb94e566db0320fa88",
    "decimals": 18,
    "image": "https://rango.vip/i/4UeN5Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2.944710666478031e-7,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOM",
    "name": "Monetum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1351986732367ff6b51784c6a75f63502de13a9a",
    "decimals": 18,
    "image": "https://rango.vip/i/4UeN5Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.944710666478031e-7,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FIGHT",
    "name": "Fight to MAGA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8802269d1283cdb2a5a329649e5cb4cdcee91ab6",
    "decimals": 9,
    "image": "https://rango.vip/i/BJ6BOx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00567158,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FIGHT",
    "name": "Fight Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4f39c3319188a723003670c3f9b9e7ef991e52f3",
    "decimals": 18,
    "image": "https://rango.vip/i/X2L8T0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.005738717601803095,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUKU",
    "name": "Fuku Kun",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1001271083c249bd771e1bb76c22d935809a61ee",
    "decimals": 9,
    "image": "https://rango.vip/i/nfJzjN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000201,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUKU",
    "name": "FUKU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a159543abfc7baf816fdbc99efd48e4ee7acc63",
    "decimals": 9,
    "image": "https://rango.vip/i/KSIxth",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.21931e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEIRO",
    "name": "Neiro",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee2a03aa6dacf51c18679c516ad5283d8e7c2637",
    "decimals": 9,
    "image": "https://rango.vip/i/LVnwF6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.071873,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEIRO",
    "name": "Neiro",
    "isPopular": false,
    "chainId": "1",
    "address": "0x812ba41e071c7b7fa4ebcfb62df5f45f6fa853ee",
    "decimals": 9,
    "image": "https://rango.vip/i/jGrVKX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004733,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTX",
    "name": "Bitnex AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc525abf65169e6d443f698276761eb9ea7d921a4",
    "decimals": 9,
    "image": "https://rango.vip/i/viB36L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009556,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CORGI",
    "name": "Corgi",
    "isPopular": false,
    "chainId": "25",
    "address": "0xcc6e924721f75af3a8f8282216569276f3f2a104",
    "decimals": 9,
    "image": "https://rango.vip/i/jxBtzt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 5.042426078566098e-12,
    "supportedSwappers": [
      "CronaSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WOW",
    "name": "WOWSwap",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xa384bc7cdc0a93e686da9e7b8c0807cd040f4e0b",
    "decimals": 18,
    "image": "https://rango.vip/i/4lCoZN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.066139,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WOW",
    "name": "WOWSwap",
    "isPopular": false,
    "chainId": "137",
    "address": "0x855d4248672a1fce482165e8dbe1207b94b1968a",
    "decimals": 18,
    "image": "https://rango.vip/i/tFKfzS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.066139,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOW",
    "name": "WOWSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3405a1bd46b85c5c029483fbecf2f3e611026e45",
    "decimals": 18,
    "image": "https://rango.vip/i/tFKfzS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.066139,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WOW",
    "name": "WOWswap",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4da996c5fe84755c80e108cf96fe705174c5e36a",
    "decimals": 18,
    "image": "https://rango.vip/i/bjTm9u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.066139,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USR",
    "name": "Resolv USR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66a1e37c9b0eaddca17d3662d6c05f4decf3e110",
    "decimals": 18,
    "image": "https://rango.vip/i/V8K4zC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "WTRX",
    "name": "Wrapped TRX",
    "isPopular": true,
    "chainId": "728126428",
    "address": "TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR",
    "decimals": 6,
    "image": "https://rango.vip/i/E0M4Pz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.149111,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "WSTETH",
    "name": "Wrapped liquid staked Ether 2.0",
    "isPopular": false,
    "chainId": "324",
    "address": "0x703b52f2b28febcb60e1372858af5b18849fe867",
    "decimals": 18,
    "image": "https://rango.vip/i/uqhz7i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 2756.98,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "WSTETH",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/2f21e6d4271de3f561f20a02cd541daf7405b1e9cb3b9b07e3c2ac7d8a4338a5",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/WSTETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 2754.177228784527,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSTETH",
    "name": "Wrapped liquid staked Ether 2.0",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0",
    "decimals": 18,
    "image": "https://rango.vip/i/5fmfiX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2756.98,
    "supportedSwappers": [
      "Satellite",
      "MayaProtocol",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WSTETH",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0x5979d7b546e38e414f7e9822514be443a4800529",
    "decimals": 18,
    "image": "https://rango.vip/i/OXyQsb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2756.98,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "MayaProtocol",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "WSTETH",
    "name": "Wrapped stETH",
    "isPopular": false,
    "chainId": "10",
    "address": "0x1f32b1c2345538c0c6f582fcb022739c4a194ebb",
    "decimals": 18,
    "image": "https://rango.vip/i/qsdGcc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2756.98,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WSTETH",
    "name": "Wrapped liquid staked Ether 2.0 (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x03b54a6e9a984069379fae1a4fc4dbae93b3bccd",
    "decimals": 18,
    "image": "https://rango.vip/i/Se211L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2756.98,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WSTETH",
    "name": "Wrapped liquid staked Ether 2.0",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xc1cba3fcea344f92d9239c08c0568f6f2f0ee452",
    "decimals": 18,
    "image": "https://rango.vip/i/GdQU9n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2756.98,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": false,
    "chainId": "324",
    "address": "0xbbeb516fb02a01611cbbe0453fe3c580d7281011",
    "decimals": 8,
    "image": "https://rango.vip/i/LgHdl8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 55583,
    "supportedSwappers": [
      "Across",
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "WBTC",
    "name": "Wrapped Bitcoin",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xf4eb217ba2454613b15dbdea6e5f22276410e89e",
    "decimals": 8,
    "image": "https://rango.vip/i/rYY2GC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 55810,
    "supportedSwappers": [
      "TrisolarisSwap",
      "Rainbow Bridge",
      "OpenOceanAurora",
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x922d641a426dcffaef11680e5358f34d97d112e1",
    "decimals": 8,
    "image": "https://rango.vip/i/9asRak",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 56779.052164009496,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x1dc78acda13a8bc4408b207c9e48cdbc096d95e0",
    "decimals": 8,
    "image": "https://rango.vip/i/9asRak",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 178679.43448635415,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": true,
    "chainId": "1",
    "address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    "decimals": 8,
    "image": "https://rango.vip/i/RTzCUB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55646,
    "supportedSwappers": [
      "ThorChain",
      "Arbitrum Bridge",
      "OrbiterV2",
      "Rainbow Bridge",
      "EthereumUniswapV3",
      "Bridgers",
      "ThorChainStreamingSwap",
      "Optimism Bridge",
      "EthUniSwapV2",
      "Across",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "WBTC",
    "name": "Wrapped Bitcoin",
    "isPopular": true,
    "chainId": "10",
    "address": "0x68f180fcce6836688e9084f035309e29bf0a2095",
    "decimals": 8,
    "image": "https://rango.vip/i/5kTSgT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 55625,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge",
      "Across"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WBTC",
    "name": "Wrapped Bitcoin",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "decimals": 8,
    "image": "https://rango.vip/i/ZpYMbS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 55658,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "ParaSwap Polygon",
      "PolygonUniSwapV3",
      "QuickSwap",
      "OneInchPolygon",
      "Across"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "WBTC",
    "name": "Wrapped Bitcoin",
    "isPopular": false,
    "chainId": "59144",
    "address": "0x3aab2285ddcddad8edf438c1bab47e1a9d05a9b4",
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/WBTC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 55761.23184779172,
    "supportedSwappers": [
      "LineaPancakeV3",
      "Across"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "WBTC",
    "name": "Wrapped Bitcoin",
    "isPopular": true,
    "chainId": "34443",
    "address": "0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf",
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/WBTC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 55761.23184779172,
    "supportedSwappers": [
      "SwapMode",
      "Across"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": true,
    "chainId": "42161",
    "address": "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
    "decimals": 8,
    "image": "https://rango.vip/i/sdShpP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 55691,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "ArbitrumUniSwapV3",
      "OrbiterV2",
      "Bridgers",
      "MayaProtocol",
      "SushiArbitrum",
      "Across",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": true,
    "chainId": "25",
    "address": "0x062e66477faf219f25d27dced647bf57c3107d52",
    "decimals": 8,
    "image": "https://rango.vip/i/7qZ4Ka",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 55539,
    "supportedSwappers": [
      "CronaSwap",
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "WBTC",
    "name": "Wrapped Bitcoin",
    "isPopular": true,
    "chainId": "1285",
    "address": "0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8",
    "decimals": 8,
    "image": "https://rango.vip/i/Ohwn3C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 1034.05,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": true,
    "chainId": "534352",
    "address": "0x3c1bca5a656e69edcd0d4e36bebb3fcdaca60cf1",
    "decimals": 8,
    "image": "https://rango.vip/i/r9L9l6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 55630,
    "supportedSwappers": [
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "POLYGONZK",
    "symbol": "WBTC",
    "name": "Polygon zkEVM Bridged WBTC  Polygon zkE",
    "isPopular": true,
    "chainId": "1101",
    "address": "0xea034fb02eb1808c2cc3adbc15f447b93cbe08e1",
    "decimals": 8,
    "image": "https://rango.vip/i/TbbIVl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGONZK/icon.svg",
    "usdPrice": 55632,
    "supportedSwappers": [
      "PolygonPancakeV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": false,
    "chainId": "42220",
    "address": "0xd71ffd0940c920786ec4dbb5a12306669b5b81ef",
    "decimals": 18,
    "image": "https://rango.vip/i/PQhnlQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 55761.23184779172,
    "supportedSwappers": [
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xe57ebd2d67b462e9926e04a8e33f01cd0d64346d",
    "decimals": 8,
    "image": "https://rango.vip/i/qaLRQ2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 751960.8392195998,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WBTC",
    "name": "Wrapped BTC (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh",
    "decimals": 8,
    "image": "https://rango.vip/i/IKT2Ky",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 55619,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": true,
    "chainId": "SN_MAIN",
    "address": "0x3fe2b97c1fd336e750087d68b9b867997fd64a2661ff3ca5a7c771641e8e7ac",
    "decimals": 8,
    "image": "https://rango.vip/i/RTzCUB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 55614,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": false,
    "chainId": "42220",
    "address": "0xbaab46e28388d2779e6e31fd00cf0e5ad95e327b",
    "decimals": 8,
    "image": "https://rango.vip/i/7A0vzc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 52215.25273141089,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "WBTC",
    "name": "Wrapped Bitcoin",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xf7bc58b8d8f97adc129cfc4c9f45ce3c0e1d2692",
    "decimals": 8,
    "image": "https://rango.vip/i/lTxHa0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 55761.23184779172,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "WBTC",
    "name": "Wrapped BTC",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TXpw8XeWYeTUd4quDskoUqeQPowRh4jY65",
    "decimals": 8,
    "image": "https://rango.vip/i/EXzeka",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 55761.23184779172,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "WETH",
    "name": "Wrapped Ether",
    "isPopular": true,
    "chainId": "324",
    "address": "0x5aea5775959fbc2557cc8789bc1bf90a239d9a91",
    "decimals": 18,
    "image": "https://rango.vip/i/y3AWmn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 2343.7,
    "supportedSwappers": [
      "Symbiosis",
      "SpaceFi",
      "Across",
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "WETH",
    "name": "WETH",
    "isPopular": true,
    "chainId": "1313161554",
    "address": "0xc9bdeed33cd01541e1eed10f90519d2c06fe3feb",
    "decimals": 18,
    "image": "https://rango.vip/i/MTyH5i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 2343.7,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora",
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "WETH",
    "name": "Wrapped Ether",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x30d2a9f5fdf90ace8c17952cbb4ee48a55d916a7",
    "decimals": 18,
    "image": "https://rango.vip/i/NOzAwA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 1305.4620206774239,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "WETH",
    "name": "WETH",
    "isPopular": true,
    "chainId": "25",
    "address": "0xe44fd7fcb2b1581822d0c862b68222998a0c299a",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/WETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 2343.7,
    "supportedSwappers": [
      "XY Finance",
      "CronaSwap",
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WETH",
    "name": "WETH",
    "isPopular": true,
    "chainId": "56",
    "address": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    "decimals": 18,
    "image": "https://rango.vip/i/2WgpA5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2338.38,
    "supportedSwappers": [
      "XY Finance",
      "Symbiosis"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WETH",
    "name": "WETH",
    "isPopular": true,
    "chainId": "137",
    "address": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    "decimals": 18,
    "image": "https://rango.vip/i/3X7tZJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2345.01,
    "supportedSwappers": [
      "XY Finance",
      "PolygonCurveFinance",
      "OrbiterV2",
      "ParaSwap Polygon",
      "Hyphen",
      "PolygonUniSwapV3",
      "Bridgers",
      "QuickSwap",
      "OneInchPolygon",
      "Across",
      "Polygon Bridge"
    ]
  },
  {
    "blockchain": "XLAYER",
    "symbol": "WETH",
    "name": "WETH",
    "isPopular": true,
    "chainId": "196",
    "address": "0x5a77f1443d16ee5761d310e38b62f77f726bc71c",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/WETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/XLAYER/icon.svg",
    "usdPrice": 2344.74,
    "supportedSwappers": [
      "XY Finance",
      "PotatoSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WETH",
    "name": null,
    "isPopular": true,
    "chainId": "1",
    "address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    "decimals": 18,
    "image": "https://rango.vip/i/RDYF4p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2343.73,
    "supportedSwappers": [
      "Symbiosis",
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "Bridgers",
      "Satellite",
      "EthUniSwapV2",
      "Across",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WETH",
    "name": null,
    "isPopular": true,
    "chainId": "8453",
    "address": "0x4200000000000000000000000000000000000006",
    "decimals": 18,
    "image": "https://rango.vip/i/tsDHhB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2347.11,
    "supportedSwappers": [
      "Symbiosis",
      "BaseCurveFinance",
      "SushiBase",
      "Across",
      "OneInchBase",
      "AlienBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "WETH",
    "name": null,
    "isPopular": true,
    "chainId": "59144",
    "address": "0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/WETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 2345.06,
    "supportedSwappers": [
      "Symbiosis",
      "LineaPancakeV3",
      "EchoDex",
      "Across",
      "LineaKyberV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WETH",
    "name": null,
    "isPopular": true,
    "chainId": "42161",
    "address": "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
    "decimals": 18,
    "image": "https://rango.vip/i/y578UU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2344.67,
    "supportedSwappers": [
      "Symbiosis",
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "Synapse Swapper",
      "ArbitrumUniSwapV3",
      "Bridgers",
      "SushiArbitrum",
      "Across",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "WETH",
    "name": null,
    "isPopular": true,
    "chainId": "10",
    "address": "0x4200000000000000000000000000000000000006",
    "decimals": 18,
    "image": "https://rango.vip/i/3MWFjT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2346.64,
    "supportedSwappers": [
      "Symbiosis",
      "OpenOceanOptimism",
      "Hyphen",
      "Bridgers",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Across"
    ]
  },
  {
    "blockchain": "POLYGONZK",
    "symbol": "WETH",
    "name": null,
    "isPopular": true,
    "chainId": "1101",
    "address": "0x4f9a0e7fd2bf6067db6994cf12e4495df938e6e9",
    "decimals": 18,
    "image": "https://rango.vip/i/Dhp6Hr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGONZK/icon.svg",
    "usdPrice": 2349.64,
    "supportedSwappers": [
      "Symbiosis",
      "PolygonPancakeV3"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "WETH",
    "name": null,
    "isPopular": true,
    "chainId": "534352",
    "address": "0x5300000000000000000000000000000000000004",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/WETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 2343.51,
    "supportedSwappers": [
      "Symbiosis",
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "WETH",
    "name": null,
    "isPopular": true,
    "chainId": "1088",
    "address": "0x420000000000000000000000000000000000000a",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/WETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 2370.62,
    "supportedSwappers": [
      "Symbiosis",
      "Synapse Swapper",
      "Netswap"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "WETH",
    "name": null,
    "isPopular": true,
    "chainId": "34443",
    "address": "0x4200000000000000000000000000000000000006",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/WETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 2359.7852169586267,
    "supportedSwappers": [
      "Symbiosis",
      "SupSwapModeV3",
      "SwapMode",
      "Across"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "WETH",
    "name": null,
    "isPopular": true,
    "chainId": "81457",
    "address": "0x4300000000000000000000000000000000000004",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/WETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 2349.95,
    "supportedSwappers": [
      "Symbiosis",
      "ThrusterV3",
      "ThrusterV2.03",
      "ThrusterV2.1"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WETH",
    "name": "Wrapped Ether",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x8b82a291f83ca07af22120aba21632088fc92931",
    "decimals": 18,
    "image": "https://rango.vip/i/wNnplE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 2345.09,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WETH",
    "name": "Wrapped Ether",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4db5a66e937a9f4473fa95b1caf1d1e1d62e29ea",
    "decimals": 18,
    "image": "https://rango.vip/i/wNnplE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2345.09,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "WETH",
    "name": "Wrapped Ether",
    "isPopular": true,
    "chainId": "42220",
    "address": "0x66803fb87abd4aac3cbb3fad7c3aa01f6f3fb207",
    "decimals": 18,
    "image": "https://rango.vip/i/2YGbdR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 2351.69,
    "supportedSwappers": [
      "UbeSwap",
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "WETH",
    "name": "wETH",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "ibc/95a45a81521eafdbedaeeb6da975c02e55b414c95ad3ce50709272366a90ca17",
    "decimals": 18,
    "image": "https://rango.vip/i/x2GcEz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 2359.7852169586267,
    "supportedSwappers": [
      "WYNDDex",
      "IBC"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "WETH",
    "name": "Wrapped Ether",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xab3f0245b83feb11d15aaffefd7ad465a59817ed",
    "decimals": 18,
    "image": "https://rango.vip/i/6aRi2i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 2339.12,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WETH",
    "name": "Wrapped Ether",
    "isPopular": false,
    "chainId": "137",
    "address": "0x11cd37bb86f65419713f30673a480ea33c826872",
    "decimals": 18,
    "image": "https://rango.vip/i/wNnplE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2345.09,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WETH",
    "name": "Wrapped Ether",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4c28f48448720e9000907bc2611f73022fdce1fa",
    "decimals": 18,
    "image": "https://rango.vip/i/NseAOJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2359.7852169586267,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "WETH",
    "name": "Wrapped Ether",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x122013fd7df1c6f636a5bb8f03108e876548b455",
    "decimals": 18,
    "image": "https://rango.vip/i/EboU3E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 2949.898285313775,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "IOTA",
    "symbol": "WETH",
    "name": "WETH",
    "isPopular": false,
    "chainId": "8822",
    "address": "0x160345fc359604fc6e70e3c5facbde5f7a9342d8",
    "decimals": 18,
    "image": "https://rango.vip/i/BN9qN3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/IOTA/icon.svg",
    "usdPrice": 2353.3231537171364,
    "supportedSwappers": [
      "IOTAMagicSeaSwap"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "WETH",
    "name": "Wrapped ETH",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TXWkP3jLBqRGojUih1ShzNyDaN5Csnebok",
    "decimals": 18,
    "image": "https://rango.vip/i/Mo5Jsv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 2359.7852169586267,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EETH",
    "name": "ether.fi ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x35fa164735182de50811e8e2e824cfb9b6118ac2",
    "decimals": 18,
    "image": "https://rango.vip/i/n9n7PH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2340.57,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EETH",
    "name": "EverETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe46a1d19962ea120765d3139c588ffd617be04a8",
    "decimals": 18,
    "image": "https://rango.vip/i/xmHID7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00072349,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "weETH",
    "name": "Wrapped eETH",
    "isPopular": false,
    "chainId": "534352",
    "address": "0x01f0a31698c4d065659b9bdc21b3610292a1c506",
    "decimals": 18,
    "image": "https://rango.vip/i/S2HWI1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 2482.7290295136727,
    "supportedSwappers": [
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "weETH",
    "name": "Wrapped eETH",
    "isPopular": false,
    "chainId": "56",
    "address": "0x04c0599ae5a44757c0af6f9ec3b93da8976c150a",
    "decimals": 18,
    "image": "https://rango.vip/i/5A42IV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2482.7290295136727,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "weETH",
    "name": "Wrapped eETH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x35751007a407ca6feffe80b3cb397736d2cf4dbe",
    "decimals": 18,
    "image": "https://rango.vip/i/xI7Oxi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2451.57,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "weETH",
    "name": "Wrapped eETH",
    "isPopular": false,
    "chainId": "10",
    "address": "0x346e03f8cce9fe01dcb3d0da3e9d00dc2c0e08f0",
    "decimals": 18,
    "image": "https://rango.vip/i/xa5npz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2482.7290295136727,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "weETH",
    "name": "Wrapped eETH",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x04c0599ae5a44757c0af6f9ec3b93da8976c150a",
    "decimals": 18,
    "image": "https://rango.vip/i/G5sRgB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 2482.7290295136727,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BTCB",
    "name": "BTCB Token",
    "isPopular": true,
    "chainId": "56",
    "address": "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    "decimals": 18,
    "image": "https://rango.vip/i/N9IbGq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 55722,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "OrbiterV2",
      "BSCPancakeV3",
      "Bridgers",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BTCB",
    "name": "Bitcoin Avalanche Bridged  BTC b ",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2297aebd383787a160dd0d9f71508148769342e3",
    "decimals": 8,
    "image": "https://rango.vip/i/k1N1lH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 55656,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BTCB",
    "name": "Bitcoin on Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x0c41f1fc9022feb69af6dc666abfe73c9ffda7ce",
    "decimals": 18,
    "image": "https://rango.vip/i/VQrvwv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.238129,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WBETH",
    "name": "Wrapped Binance Beacon ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2e3356610840701bdf5611a53974510ae27e2e1",
    "decimals": 18,
    "image": "https://rango.vip/i/keRDmC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2453.79,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "RETH",
    "name": "Rocket Pool ETH",
    "isPopular": false,
    "chainId": "10",
    "address": "0x9bcef72be871e61ed4fbbc7630889bee758eb81d",
    "decimals": 18,
    "image": "https://rango.vip/i/1MD0el",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2625.29,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RETH",
    "name": "Rocket Pool ETH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xec70dcb4a1efa46b8f2d97c310c9c4790ba5ffa8",
    "decimals": 18,
    "image": "https://rango.vip/i/XLt0ru",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2625.29,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RETH",
    "name": "Rocket Pool ETH",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xb6fe221fe9eef5aba221c348ba20a1bf5e73624c",
    "decimals": 18,
    "image": "https://rango.vip/i/u6YbMV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2625.29,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RETH",
    "name": "StaFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9559aaa82d9649c7a7b220e7c461d2e74c9a3593",
    "decimals": 18,
    "image": "https://rango.vip/i/2heVNn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2575.71,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RETH",
    "name": "Rocket Pool ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae78736cd615f374d3085123a210448e74fc6393",
    "decimals": 18,
    "image": "https://rango.vip/i/cPAQAd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2625.29,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "RETH",
    "name": "Rocket Pool ETH",
    "isPopular": false,
    "chainId": "324",
    "address": "0x32fd44bb869620c0ef993754c8a00be67c464806",
    "decimals": 18,
    "image": "https://rango.vip/i/G0wJDk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 2646.559212918496,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUFETH",
    "name": "pufETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd9a442856c234a39a81a089c06451ebaa4306a72",
    "decimals": 18,
    "image": "https://rango.vip/i/9l8DAH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2353.89,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "METH",
    "name": "ETH Monsta",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0345173a92742e9daf55d44ac65e0d987b22379e",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2448.3237432417495,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "METH",
    "name": "mETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd5f7838f5c461feff7fe49ea5ebaf7728bb0adfa",
    "decimals": 18,
    "image": "https://rango.vip/i/wk1yOM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2439.92,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "EZETH",
    "name": "Renzo Restaked ETH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2416092f143378750bb29b79ed961ab195cceea5",
    "decimals": 18,
    "image": "https://rango.vip/i/9h54IF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2383.16,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "EZETH",
    "name": "Renzo Restaked ETH",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x2416092f143378750bb29b79ed961ab195cceea5",
    "decimals": 18,
    "image": "https://rango.vip/i/01wAZM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2383.16,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EZETH",
    "name": "Renzo Restaked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbf5495efe5db9ce00f80364c8b423567e58d2110",
    "decimals": 18,
    "image": "https://rango.vip/i/fQk0e4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2383.16,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WBNB",
    "name": "Wrapped BNB",
    "isPopular": true,
    "chainId": "56",
    "address": "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    "decimals": 18,
    "image": "https://rango.vip/i/QQPTU7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 500.74,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "Bridgers",
      "Satellite",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WBNB",
    "name": "WBNB (wormhole)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xecdcb5b88f8e3c15f95c720c51c71c9e2080525d",
    "decimals": 18,
    "image": "https://rango.vip/i/Xzflek",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 502.28,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WBNB",
    "name": "WBNB (wormhole)",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x442f7f22b1ee2c842beaff52880d4573e9201158",
    "decimals": 18,
    "image": "https://rango.vip/i/Xzflek",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 502.28,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RSETH",
    "name": "Kelp DAO Restaked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1290d69c65a6fe4df752f95823fae25cb99e5a7",
    "decimals": 18,
    "image": "https://rango.vip/i/mYRUvH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2395.72,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MSTETH",
    "name": "Eigenpie mstETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x49446a0874197839d15395b908328a74ccc96bc0",
    "decimals": 18,
    "image": "https://rango.vip/i/inw5bZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2368.39,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "cbETH",
    "name": "Coinbase Wrapped Staked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe9895146f7af43049ca1c1ae358b0541ea49704",
    "decimals": 18,
    "image": "https://rango.vip/i/31HFnv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2528.63,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "cbETH",
    "name": "Coinbase Wrapped Staked ETH",
    "isPopular": false,
    "chainId": "10",
    "address": "0xaddb6a0412de1ba0f936dcaeb8aaa24578dcf3b2",
    "decimals": 18,
    "image": "https://rango.vip/i/gXpYGH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2528.63,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SFRXETH",
    "name": "Frax Staked Ether",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac3e018457b222d93114458476f3e3416abbe38f",
    "decimals": 18,
    "image": "https://rango.vip/i/PBuNG2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2565.99,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWETH",
    "name": "swETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf951e335afb289353dc249e82926178eac7ded78",
    "decimals": 18,
    "image": "https://rango.vip/i/DssU72",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2498.71,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHX",
    "name": "ETHx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa35b1b31ce002fbf2058d22f30f95d405200a15b",
    "decimals": 18,
    "image": "https://rango.vip/i/uUYT3G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2435.07,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ETHX",
    "name": "LSDx Pool",
    "isPopular": false,
    "chainId": "324",
    "address": "0x668cc2668eeeaf8075d38e72ef54fa546bf3c39c",
    "decimals": 18,
    "image": "https://rango.vip/i/PEd6hN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 2486.324123615204,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RSWETH",
    "name": "rswETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfae103dc9cf190ed75350761e95403b7b8afa6c0",
    "decimals": 18,
    "image": "https://rango.vip/i/a9BzcG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2383.99,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BETH",
    "name": "Beacon ETH",
    "isPopular": false,
    "chainId": "56",
    "address": "0x250632378e573c6be1ac2f97fcdf00515d0aa91b",
    "decimals": 18,
    "image": "https://rango.vip/i/x5BinJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2480.71,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BETH",
    "name": "BETH",
    "isPopular": false,
    "chainId": "128",
    "address": "0xb6f4c418514dd4680f76d5caa3bb42db4a893acb",
    "decimals": 18,
    "image": "https://rango.vip/i/gOQu5d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 2349.7661178192143,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LSETH",
    "name": "Liquid Staked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c1bed5b9a0928467c9b1341da1d7bd5e10b6549",
    "decimals": 18,
    "image": "https://rango.vip/i/wWGThZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2481.71,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "slisBNB",
    "name": "Staked Lista BNB",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb0b84d294e0c75a6abe60171b70edeb2efd14a1b",
    "decimals": 18,
    "image": "https://rango.vip/i/il61T9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 511.39,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "SOL",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/1e43d59e565d41fb4e54ca639b838ffd5bcfc20003d330a56cb1396231aa1cba",
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/SOL.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 128.79,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOL",
    "name": "SOL",
    "isPopular": true,
    "chainId": "mainnet-beta",
    "address": null,
    "decimals": 9,
    "image": "https://rango.vip/tokens/ALL/SOL.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 128.40432243080934,
    "supportedSwappers": [
      "Jupiter",
      "SolanaWrapper",
      "DeBridge",
      "Bridgers"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SOL",
    "name": null,
    "isPopular": false,
    "chainId": "43114",
    "address": "0xfe6b19286885a4f7f55adad09c3cd1f906d2478f",
    "decimals": 9,
    "image": "https://rango.vip/i/YhJ1nK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 128.79,
    "supportedSwappers": [
      "ThorChain",
      "ParaSwap Avalanche",
      "ThorChainStreamingSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "SOL",
    "name": "Solana",
    "isPopular": false,
    "chainId": "25",
    "address": "0xc9de0f3e08162312528ff72559db82590b481800",
    "decimals": 9,
    "image": "https://rango.vip/i/ut1Llk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 128.89625298977276,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOL",
    "name": "SOL Wormhole ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd31a59c85ae9d8edefec411d448f90841571b89c",
    "decimals": 9,
    "image": "https://rango.vip/i/C58yZv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 128.79,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SOL",
    "name": "SOLANA",
    "isPopular": false,
    "chainId": "56",
    "address": "0x570a5d26f7765ecb712c0924e4de545b89fd43df",
    "decimals": 18,
    "image": "https://rango.vip/i/z1hgrR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 128.53,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SOL",
    "name": "Wrapped SOL",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfa54ff1a158b5189ebba6ae130ced6bbd3aea76e",
    "decimals": 9,
    "image": "https://rango.vip/i/YhJ1nK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 128.79,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SOL",
    "name": "Wrapped SOL",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd93f7e271cb87c23aaa73edc008a79646d1f9912",
    "decimals": 9,
    "image": "https://rango.vip/i/YhJ1nK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 128.79,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SOL",
    "name": "SOL",
    "isPopular": false,
    "chainId": "137",
    "address": "0x7dff46370e9ea5f0bad3c4e29711ad50062ea7a4",
    "decimals": 18,
    "image": "https://rango.vip/i/qyy7UY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 128.40432243080934,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SOL",
    "name": "SOL Wormhole ",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb74da9fe2f96b9e0a5f4a3cf0b92dd2bec617124",
    "decimals": 9,
    "image": "https://rango.vip/i/rA9Ebn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 128.40432243080934,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SOL",
    "name": "Solana",
    "isPopular": false,
    "chainId": "10",
    "address": "0xba1cf949c382a32a09a17b2adf3587fc7fa664f1",
    "decimals": 9,
    "image": "https://rango.vip/i/uAf34t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 128.40432243080934,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "INF",
    "name": "Infinity",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm",
    "decimals": 9,
    "image": "https://rango.vip/i/qPVNdu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 158.05,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PZETH",
    "name": "Renzo Restaked LST",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c9532a60e0e7c6bbd2b2c1303f63ace1c3e9811",
    "decimals": 18,
    "image": "https://rango.vip/i/XSvcmB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2736.84,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WAVAX",
    "name": "Wrapped AVAX",
    "isPopular": true,
    "chainId": "43114",
    "address": "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
    "decimals": 18,
    "image": "https://rango.vip/i/5r7dL0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 21.5,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "Satellite",
      "AvaxChainV3",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAVAX",
    "name": "Wrapped AVAX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85f138bfee4ef8e540890cfb48f620571d67eda3",
    "decimals": 18,
    "image": "https://rango.vip/i/7QDOTz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 21.46,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WAVAX",
    "name": "WAVAX (wormhole)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x7bb11e7f8b10e9e571e5d8eace04735fdfb2358a",
    "decimals": 18,
    "image": "https://rango.vip/i/7QDOTz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 21.46,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WAVAX",
    "name": "WAVAX (wormhole)",
    "isPopular": false,
    "chainId": "56",
    "address": "0x96412902aa9aff61e13f085e70d3152c6ef2a817",
    "decimals": 18,
    "image": "https://rango.vip/i/7QDOTz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 21.46,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sSOL",
    "name": "SunnySideUp staked SOL (sSOL)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS",
    "decimals": 9,
    "image": "https://rango.vip/i/aIm9Wd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 130.870334172312,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "STRX",
    "name": "StrikeX",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd6fdde76b8c1c45b33790cc8751d5b88984c44ec",
    "decimals": 18,
    "image": "https://rango.vip/i/GW0VLQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03211205,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRVUSD",
    "name": "Curve.Fi USD Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf939e0a03fb07f59a73314e73794be0e57ac1b4e",
    "decimals": 18,
    "image": "https://rango.vip/i/v6oOY9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.998399,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CRVUSD",
    "name": "CRVUSD",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x417ac0e078398c154edfadd9ef675d30be60af93",
    "decimals": 18,
    "image": "https://rango.vip/i/L3Jz5R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.998399,
    "supportedSwappers": [
      "BaseCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CRVUSD",
    "name": "CRVUSD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x498bf2b1e120fed3ad3d42ea2165e9b73f99c1e5",
    "decimals": 18,
    "image": "https://rango.vip/i/R5n8Bl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.998399,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "WCRO",
    "name": "WCRO Token",
    "isPopular": true,
    "chainId": "25",
    "address": "0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23",
    "decimals": 18,
    "image": "https://rango.vip/i/M1S9ic",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.076413,
    "supportedSwappers": [
      "CronaSwap",
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FBTC",
    "name": "Ignition FBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc96de26018a54d51c097160568752c4e3bd6c364",
    "decimals": 8,
    "image": "https://rango.vip/i/hhWqeL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55870,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WMATIC",
    "name": "Wrapped Matic",
    "isPopular": true,
    "chainId": "137",
    "address": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    "decimals": 18,
    "image": "https://rango.vip/i/NcQpfK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.372141,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "ParaSwap Polygon",
      "PolygonUniSwapV3",
      "Satellite",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WMATIC",
    "name": "Wrapped Matic",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c9f4c87d911613fe9ca58b579f737911aad2d43",
    "decimals": 18,
    "image": "https://rango.vip/i/QpOgNr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.364766,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WMATIC",
    "name": "WMATIC (wormhole)",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc836d8dc361e44dbe64c4862d55ba041f88ddd39",
    "decimals": 18,
    "image": "https://rango.vip/i/QpOgNr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.364766,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WMATIC",
    "name": "Wrapped Matic",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf2f13f0b7008ab2fa4a2418f4ccc3684e49d20eb",
    "decimals": 18,
    "image": "https://rango.vip/i/QpOgNr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.364766,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WNXM",
    "name": "Wrapped NXM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d438f3b5175bebc262bf23753c1e53d03432bde",
    "decimals": 18,
    "image": "https://rango.vip/i/HYaY03",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 53.06,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JSOL",
    "name": "JPOOL Solana Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn",
    "decimals": 9,
    "image": "https://rango.vip/i/59hFwE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 155.41,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SETH2",
    "name": "StakeWise Staked ETH2",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe2e637202056d30016725477c5da089ab0a043a",
    "decimals": 18,
    "image": "https://rango.vip/i/rFQFQi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2326.88,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ankrETH",
    "name": "Ankr Staked ETH",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe05a08226c49b636acf99c40da8dc6af83ce5bb3",
    "decimals": 18,
    "image": "https://rango.vip/i/bJV7lF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2733.7,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WCFG",
    "name": "Wrapped Centrifuge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc221b7e65ffc80de234bbb6667abdd46593d34f0",
    "decimals": 18,
    "image": "https://rango.vip/i/yPf2It",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.324714,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WTAO",
    "name": "Wrapped TAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77e06c9eccf2e797fd462a92b6d7642ef85b0a44",
    "decimals": 9,
    "image": "https://rango.vip/i/oYD1Db",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 235.5,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WTAO",
    "name": "WorldTAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70b02a7d6d86c8ed2193d3c167468ebae28b9538",
    "decimals": 18,
    "image": "https://rango.vip/i/4XLwjH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00334953,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HBTC",
    "name": "Huobi BTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0316eb71485b0ab14103307bf65a021042c6d380",
    "decimals": 18,
    "image": "https://rango.vip/i/J2aqP0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9754.63,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HBTC",
    "name": "Heco-Peg HBTC Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0x66a79d23e58475d2738179ca52cd0b41d73f0bea",
    "decimals": 18,
    "image": "https://rango.vip/i/ySJK8B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 9754.63,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WEVER",
    "name": "Wrapped Ever",
    "isPopular": false,
    "chainId": "1",
    "address": "0x29d578cec46b50fa5c88a99c6a4b70184c062953",
    "decimals": 9,
    "image": "https://rango.vip/i/YW2xm1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04088075,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "STRIDE",
    "symbol": "stDYDX",
    "name": null,
    "isPopular": true,
    "chainId": "stride-1",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/Fjb3GB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STRIDE/icon.svg",
    "usdPrice": 0.9283438340974535,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RENBTC",
    "name": "renBTC",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdbf31df14b66535af65aac99c32e9ea844e14501",
    "decimals": 8,
    "image": "https://rango.vip/i/xwGyL8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 55415,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RENBTC",
    "name": "renBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb4c2781e4eba804ce9a9803c67d0893436bb27d",
    "decimals": 8,
    "image": "https://rango.vip/i/hZ4xYI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55415,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RENBTC",
    "name": "renBTC",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfce146bf3146100cfe5db4129cf6c82b0ef4ad8c",
    "decimals": 8,
    "image": "https://rango.vip/i/UqF079",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 55415,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RENBTC",
    "name": "RENBTC",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xdbf31df14b66535af65aac99c32e9ea844e14501",
    "decimals": 8,
    "image": "https://rango.vip/i/c3IwU9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 55449,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "RENBTC",
    "name": "RENBTC",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xdbf31df14b66535af65aac99c32e9ea844e14501",
    "decimals": 8,
    "image": "https://rango.vip/i/yxsUnB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 55747.36370257785,
    "supportedSwappers": [
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOETH",
    "name": "Wrapped OETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdcee70654261af21c44c093c300ed3bb97b78192",
    "decimals": 18,
    "image": "https://rango.vip/i/7Bdp4o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2580.41,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "stSOL",
    "name": "Lido Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj",
    "decimals": 9,
    "image": "https://rango.vip/i/3tWeJV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 154.88,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "STRIDE",
    "symbol": "stTIA",
    "name": null,
    "isPopular": true,
    "chainId": "stride-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/3tQD4m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STRIDE/icon.svg",
    "usdPrice": 4.19006379609552,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "WMOVR",
    "name": "Wrapped MOVR",
    "isPopular": true,
    "chainId": "1285",
    "address": "0x98878b06940ae243284ca214f92bb71a2b032b8a",
    "decimals": 18,
    "image": "https://rango.vip/i/ms2xYk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 104.2765305998336,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "STRIDE",
    "symbol": "stOSMO",
    "name": null,
    "isPopular": true,
    "chainId": "stride-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/snSblc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STRIDE/icon.svg",
    "usdPrice": 0.4743951801442306,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WMINIMA",
    "name": "Wrapped Minima",
    "isPopular": false,
    "chainId": "1",
    "address": "0x669c01caf0edcad7c2b8dc771474ad937a7ca4af",
    "decimals": 18,
    "image": "https://rango.vip/i/O4uFEX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.05398,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAMPL",
    "name": "Wrapped Ampleforth",
    "isPopular": false,
    "chainId": "1",
    "address": "0xedb171c18ce90b633db442f2a6f72874093b49ef",
    "decimals": 18,
    "image": "https://rango.vip/i/vM2oED",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.52,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WAMPL",
    "name": "Wrapped Ampleforth",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1c8ec4de3c2bfd3050695d89853ec6d78ae650bb",
    "decimals": 18,
    "image": "https://rango.vip/i/vM2oED",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 8.507535051930448,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "STRIDE",
    "symbol": "stATOM",
    "name": null,
    "isPopular": true,
    "chainId": "stride-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/HJk30E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STRIDE/icon.svg",
    "usdPrice": 5.382457126991433,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CDCETH",
    "name": "Crypto.com Wrapped Staked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe18ae03741a5b84e39c295ac9c856ed7991c38e",
    "decimals": 18,
    "image": "https://rango.vip/i/KAMmUp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2408.68,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "BTCK",
    "name": "BTCK",
    "isPopular": false,
    "chainId": "66",
    "address": "0x54e4622dc504176b3bb432dccaf504569699a7ff",
    "decimals": 18,
    "image": "https://rango.vip/i/uYIUts",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 56075,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WPOKT",
    "name": "Wrapped POKT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x67f4c72a50f8df6487720261e188f2abe83f57d7",
    "decimals": 6,
    "image": "https://rango.vip/i/1bhDbz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03771774,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "WHT",
    "name": "Wrapped HT",
    "isPopular": true,
    "chainId": "128",
    "address": "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    "decimals": 18,
    "image": "https://rango.vip/i/Xfx3gw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 2.740861900949569,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ankrBNB",
    "name": "Ankr Staked BNB",
    "isPopular": false,
    "chainId": "56",
    "address": "0x52f24a5e03aee338da5fd9df68d2b6fae1178827",
    "decimals": 18,
    "image": "https://rango.vip/i/gB1dn9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 544.33,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "STNEAR",
    "name": "Staked NEAR",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x07f9f7f963c5cd2bbffd30ccfb964be114332e30",
    "decimals": 24,
    "image": "https://rango.vip/i/bUWO1Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 4.82,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "WGLMR",
    "name": "Wrapped Glimmer",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xacc15dc74880c9944775448304b263d191c6077f",
    "decimals": 18,
    "image": "https://rango.vip/i/k5uTW7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.150133,
    "supportedSwappers": [
      "BeamSwap",
      "StellaSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WALV",
    "name": "Alvey Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x256d1fce1b1221e8398f65f9b36033ce50b2d497",
    "decimals": 18,
    "image": "https://rango.vip/i/yQJxE1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00510937,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WALV",
    "name": "Alvey Chain",
    "isPopular": false,
    "chainId": "56",
    "address": "0x256d1fce1b1221e8398f65f9b36033ce50b2d497",
    "decimals": 18,
    "image": "https://rango.vip/i/37UPCh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00510937,
    "supportedSwappers": [
      "OneInchBsc",
      "Bridgers"
    ]
  },
  {
    "blockchain": "XLAYER",
    "symbol": "WOKB",
    "name": "Wrapped OKB",
    "isPopular": true,
    "chainId": "196",
    "address": "0xe538905cf8410324e03a5a23c1c177a474d59b2b",
    "decimals": 18,
    "image": "https://rango.vip/i/owrNGC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/XLAYER/icon.svg",
    "usdPrice": 36.06,
    "supportedSwappers": [
      "PotatoSwap"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "TBTC",
    "name": "tBTC",
    "isPopular": false,
    "chainId": "10",
    "address": "0x6c84a8f1c29108f47a79964b5fe888d4f4d0de40",
    "decimals": 18,
    "image": "https://rango.vip/i/gMNzIz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 55766,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TBTC",
    "name": "tBTC",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x236aa50979d5f3de3bd1eeb40e81137f22ab794b",
    "decimals": 18,
    "image": "https://rango.vip/i/j8Eavx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 55766,
    "supportedSwappers": [
      "BaseCurveFinance",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TBTC",
    "name": "Threshold Bitcoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6DNSN2BJsaPFdFFc1zP37kkeNe4Usc1Sqkzr9C9vPWcU",
    "decimals": 8,
    "image": "https://rango.vip/i/L9OAdl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 55766,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TBTC",
    "name": "Polygon tBTC v2",
    "isPopular": false,
    "chainId": "137",
    "address": "0x236aa50979d5f3de3bd1eeb40e81137f22ab794b",
    "decimals": 18,
    "image": "https://rango.vip/i/k1EtI7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 55766,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TBTC",
    "name": "tBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8daebade922df735c38c80c7ebd708af50815faa",
    "decimals": 18,
    "image": "https://rango.vip/i/e6fsvg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55776.885657092695,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TBTC",
    "name": "Tau Bitcoin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2cd1075682b0fccaadd0ca629e138e64015ba11c",
    "decimals": 9,
    "image": "https://rango.vip/i/EEgImp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 55776.885657092695,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "WOKT",
    "name": "WOKT",
    "isPopular": true,
    "chainId": "66",
    "address": "0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15",
    "decimals": 18,
    "image": "https://rango.vip/i/4nNIRg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 6.228474908279536,
    "supportedSwappers": [
      "CherrySwap",
      "OkcSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUMPBTC",
    "name": "pumpBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf469fbd2abcd6b9de8e169d128226c0fc90a012e",
    "decimals": 8,
    "image": "https://rango.vip/i/VfYeAQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 56338,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STKOSMO",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/ecbe78bf7677320a93e7ba1761d144bcbf0cbc247c290c049655e106fe5dc68e",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.3949526054659721,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TELEBTC",
    "name": "teleBTC",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3bf668fe1ec79a84ca8481cead5dbb30d61cc685",
    "decimals": 8,
    "image": "https://rango.vip/i/qqI2cH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 55856.91391800634,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WACME",
    "name": "Wrapped Accumulate",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf4ef6ee483953fe3b84abd08c6a060445c01170",
    "decimals": 8,
    "image": "https://rango.vip/i/P8KAF6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00819247,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "STRIDE",
    "symbol": "stSTARS",
    "name": null,
    "isPopular": true,
    "chainId": "stride-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/97Wwbs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STRIDE/icon.svg",
    "usdPrice": 0.010271089006584638,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RMATIC",
    "name": "StaFi rMATIC",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9f28e2455f9ffcfac9ebd6084853417362bc5dbb",
    "decimals": 18,
    "image": "https://rango.vip/i/H30963",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.431581,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RMATIC",
    "name": "StaFi Staked MATIC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3dbb00c9be5a327e25caf4f650844c5dba81e34b",
    "decimals": 18,
    "image": "https://rango.vip/i/rOCajD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.431581,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "STRIDE",
    "symbol": "stINJ",
    "name": null,
    "isPopular": true,
    "chainId": "stride-1",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/cCusDi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STRIDE/icon.svg",
    "usdPrice": 22.237107362942954,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "STRIDE",
    "symbol": "stSOMM",
    "name": null,
    "isPopular": true,
    "chainId": "stride-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/kbG5RY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STRIDE/icon.svg",
    "usdPrice": 0.022964443814655497,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "pBTC",
    "name": "pBTC (Parrot BTC)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun",
    "decimals": 8,
    "image": "https://rango.vip/i/1Wc6QQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1589.1809146948463,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STKATOM",
    "name": "pSTAKE Staked ATOM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44017598f2af1bd733f9d87b5017b4e7c1b28dde",
    "decimals": 6,
    "image": "https://rango.vip/i/2eewBa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.019248528274355,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "WRSETH",
    "name": "Kelp DAO",
    "isPopular": false,
    "chainId": "34443",
    "address": "0xe7903b1f75c534dd8159b313d92cdcfbc62cb3cd",
    "decimals": 18,
    "image": "https://rango.vip/i/FHIWqD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 2413.738480445949,
    "supportedSwappers": [
      "SwapMode"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "USDC.E",
    "name": "Bridged USDC (zkSync)",
    "isPopular": true,
    "chainId": "324",
    "address": "0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4",
    "decimals": 6,
    "image": "https://rango.vip/i/hj2JqK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.999614,
    "supportedSwappers": [
      "XY Finance",
      "Symbiosis",
      "SpaceFi",
      "OrbiterV2",
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ZKDX",
    "name": "ZKDX",
    "isPopular": false,
    "chainId": "324",
    "address": "0xc3a3aa99b80845d22cc41dea3bdb9b2a9ebe199a",
    "decimals": 18,
    "image": "https://rango.vip/i/pklzjo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00214047,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ZORRO",
    "name": "Zorro",
    "isPopular": false,
    "chainId": "324",
    "address": "0x244c238325fc1bdf6eded726ee1b47d55895d944",
    "decimals": 18,
    "image": "https://rango.vip/i/BpzTQ3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00003149,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "NOP",
    "name": "NOP App",
    "isPopular": false,
    "chainId": "324",
    "address": "0x941fc398d9faebdd9f311011541045a1d66c748e",
    "decimals": 18,
    "image": "https://rango.vip/i/t9Lztv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00008077517097267616,
    "supportedSwappers": [
      "zkSwap",
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "KUJIRA",
    "symbol": "AXLUSDC",
    "name": null,
    "isPopular": false,
    "chainId": "kaiyo-1",
    "address": "ibc/295548a78785a1007f232de286149a6ff512f180af5657780fc89c009e2c348f",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/AXLUSDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/KUJIRA/icon.svg",
    "usdPrice": 1.0004415482027293,
    "supportedSwappers": [
      "Satellite",
      "FinKujira"
    ]
  },
  {
    "blockchain": "KUJIRA",
    "symbol": "AXLDOT",
    "name": null,
    "isPopular": false,
    "chainId": "kaiyo-1",
    "address": "ibc/b37e4d9fb5b30f3e1e20a4b2de2a005e584c5c822c44527546556ae2470b4539",
    "decimals": 10,
    "image": "https://rango.vip/tokens/ALL/AXLDOT.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/KUJIRA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Satellite",
      "FinKujira"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AVE",
    "name": "Avaware",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x78ea17559b3d2cf85a7f9c2c704eda119db5e6de",
    "decimals": 18,
    "image": "https://rango.vip/i/itpurb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00001953794503704274,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AABLOCK",
    "name": "Blocknet",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc931f61b1534eb21d8c11b24f3f5ab2471d4ab50",
    "decimals": 8,
    "image": "https://rango.vip/i/prh57x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.022494415336406417,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "CYCLE",
    "name": "Cycle Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x81440c939f2c1e34fc7048e518a637205a632a74",
    "decimals": 18,
    "image": "https://rango.vip/i/l3YVnQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.03407612924205263,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WET",
    "name": "Weble Ecosystem Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb1466d4cf0dcfc0bcddcf3500f473cdacb88b56d",
    "decimals": 18,
    "image": "https://rango.vip/i/eF2AXF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.3547308219601938,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WALBT",
    "name": "Wrapped AllianceBlock Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x9e037de681cafa6e661e6108ed9c2bd1aa567ecd",
    "decimals": 18,
    "image": "https://rango.vip/i/ppFjPB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.001625566861570785,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "TUNDRA",
    "name": "TundraToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x21c5402c3b7d40c89cc472c9df5dd7e51bbab1b1",
    "decimals": 18,
    "image": "https://rango.vip/i/sATGGn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.06594481419390624,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SHIBX",
    "name": "SHIBAVAX",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x440abbf18c54b2782a4917b80a1746d3a2c2cce1",
    "decimals": 18,
    "image": "https://rango.vip/i/dNdQw5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.000017994813476601748,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "APEIN",
    "name": "Ape In",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x938fe3788222a74924e062120e7bfac829c719fb",
    "decimals": 18,
    "image": "https://rango.vip/i/WH5xjA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.1421356269128557,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BOOFI",
    "name": "Boo Finance Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb00f1ad977a949a3ccc389ca1d1282a2946963b0",
    "decimals": 18,
    "image": "https://rango.vip/i/UvsYyE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.001104632264928214,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "CRA",
    "name": "CRA",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xa32608e873f9ddef944b24798db69d80bbb4d1ed",
    "decimals": 18,
    "image": "https://rango.vip/i/Xv70PQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0004616594301198603,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "NFSG",
    "name": "NFTSoccerGames",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf69c2fcd9128d49dfa22348c69177f9380438eb8",
    "decimals": 6,
    "image": "https://rango.vip/i/pOMUT2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.078081,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "RACEX",
    "name": "RaceX",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x7086e045b78e1e72f741f25231c08d238812cf8a",
    "decimals": 18,
    "image": "https://rango.vip/i/00hmYf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0000990725488641758,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SHAKE",
    "name": "SHAKE token by SpaceSwap v2",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc1d02e488a9ce2481bfdcd797d5373dd2e70a9c2",
    "decimals": 18,
    "image": "https://rango.vip/i/E2uRkl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 69.84787041172582,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "YDR",
    "name": "YDragon",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf03dccaec9a28200a6708c686cf0b8bf26ddc356",
    "decimals": 18,
    "image": "https://rango.vip/i/g7Fi2O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00398964,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BAVA",
    "name": "BavaToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xe19a1684873fab5fb694cfd06607100a632ff21c",
    "decimals": 18,
    "image": "https://rango.vip/i/9wqyHg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01509072,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AAVE.E",
    "name": "Aave Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x63a72806098bd3d9520cc43356dd78afe5d386d9",
    "decimals": 18,
    "image": "https://rango.vip/i/Vp5Kce",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 126.07,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "LINK.E",
    "name": "Chainlink Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x5947bb275c521040051d82396192181b413227a3",
    "decimals": 18,
    "image": "https://rango.vip/i/bJooka",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 9.91,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SUSHI.E",
    "name": "SushiToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x37b608519f91f70f2eeb0e5ed9af4061722e4f76",
    "decimals": 18,
    "image": "https://rango.vip/i/4tNtec",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.567192,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WBTC.E",
    "name": "Wrapped BTC",
    "isPopular": true,
    "chainId": "43114",
    "address": "0x50b7545627a5162f82a992c33b87adc75187b218",
    "decimals": 8,
    "image": "https://rango.vip/i/Ew9dYj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 55738,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "AvaxChainV3",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WETH.E",
    "name": "Wrapped Ether",
    "isPopular": true,
    "chainId": "43114",
    "address": "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
    "decimals": 18,
    "image": "https://rango.vip/i/WOEVSs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 2338.85,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "Hyphen",
      "AvaxChainV3",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SAVAX",
    "name": "Staked AVAX",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be",
    "decimals": 18,
    "image": "https://rango.vip/i/krI1fE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 25.08,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AXLATOM",
    "name": "Axelar Wrapped ATOM",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x80d18b1c9ab0c9b5d6a6d5173575417457d00a12",
    "decimals": 6,
    "image": "https://rango.vip/i/77b1wC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 3.967211950140122,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "KTE",
    "name": "KyteOne",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x056d114ff1e01de3bca30f0efa3655df42880e5b",
    "decimals": 18,
    "image": "https://rango.vip/i/j6wBMw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00297102226956259,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "USDT.E",
    "name": "Tether USD",
    "isPopular": true,
    "chainId": "43114",
    "address": "0xc7198437980c041c805a1edcba50c1ce5db95118",
    "decimals": 6,
    "image": "https://rango.vip/i/GJxbOP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.999086,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "Synapse Swapper",
      "PangolinSwap",
      "Hyphen",
      "Bridgers",
      "AvaxChainV3",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "USDC.E",
    "name": "USD Coin",
    "isPopular": true,
    "chainId": "43114",
    "address": "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    "decimals": 6,
    "image": "https://rango.vip/i/j9eYAa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.999106,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "Synapse Swapper",
      "PangolinSwap",
      "Hyphen",
      "Bridgers",
      "AvaxChainV3",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DAI.E",
    "name": "Dai Stablecoin",
    "isPopular": true,
    "chainId": "43114",
    "address": "0xd586e7f844cea2f87f50152665bcbc2c279d8d70",
    "decimals": 18,
    "image": "https://rango.vip/i/q5Lqky",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "Synapse Swapper",
      "PangolinSwap",
      "Bridgers",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "TSD",
    "name": "TSD Stablecoin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x4fbf0429599460d327bd5f55625e30e4fc066095",
    "decimals": 18,
    "image": "https://rango.vip/i/vJL0TY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.162303650556947,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AXLUST",
    "name": "Axelar Wrapped UST",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x260bbf5698121eb85e7a74f2e45e16ce762ebe11",
    "decimals": 6,
    "image": "https://rango.vip/i/fCCU6o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.015318766781310788,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "UST",
    "name": "UST",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb599c3590f42f8f995ecfa0f85d2980b76862fc1",
    "decimals": 6,
    "image": "https://rango.vip/i/7Ayzjc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01535935,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "RUGPULL",
    "name": "Rugpull Prevention",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x61ecd63e42c27415696e10864d70ecea4aa11289",
    "decimals": 18,
    "image": "https://rango.vip/i/6MzClt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.012653558564703874,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AXIAL",
    "name": "AxialToken",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xcf8419a615c57511807236751c0af38db4ba3351",
    "decimals": 18,
    "image": "https://rango.vip/i/GLQgFY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0005415223719970406,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SUBAVA",
    "name": "Subava",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xa2cde628d7617956eaf4780e32f68df19cc13d62",
    "decimals": 18,
    "image": "https://rango.vip/i/NCpjtL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 9.328029394748166e-8,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BTC.B",
    "name": "Bitcoin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x152b9d0fdc40c096757f570a51e494bd4b943e50",
    "decimals": 8,
    "image": "https://rango.vip/i/ugj2nh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 55656,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap",
      "AvaxChainV3",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BNZ",
    "name": "MadSkullz: Bonez Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x4d6ec47118f807ace03d3b3a4ee6aa96cb2ab677",
    "decimals": 18,
    "image": "https://rango.vip/i/DNxEHh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00061673,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BALLN",
    "name": "Balln Chikn",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x4afc7838167b77530278483c3d8c1ffe698a912e",
    "decimals": 18,
    "image": "https://rango.vip/i/tshvr8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.0015576267173671,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "B4FWX",
    "name": "B4FWX",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x13b1f0579bc895b2ffb835f295fd9b63fef36da0",
    "decimals": 18,
    "image": "https://rango.vip/i/SEPlxb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00358701,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "PangolinSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDFI",
    "name": "USDFI",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc9f5955f6da20e44a068f3d58fb2404f56f9a6f2",
    "decimals": 18,
    "image": "https://rango.vip/i/LIIpvU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.835407,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "STABLE",
    "name": "STABLE",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8bf75bc68fd337dfd8186d731df8b3c2cb14b9e6",
    "decimals": 18,
    "image": "https://rango.vip/i/OttM42",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.96,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "cKES",
    "name": "Celo Kenyan Shilling",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x456a3d042c0dbd3db53d5489e98dfb038553b0d0",
    "decimals": 18,
    "image": "https://rango.vip/i/guVCTq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.00784121,
    "supportedSwappers": [
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "eXOF",
    "name": "ECO CFA (West African Franc)",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x73f93dcc49cb8a239e2032663e9475dd5ef29a08",
    "decimals": 18,
    "image": "https://rango.vip/i/JOqfVs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "USDC.E",
    "name": "Bridged USDC",
    "isPopular": true,
    "chainId": "1313161554",
    "address": "0xb12bfca5a55806aaf64e99521918a4bf0fc40802",
    "decimals": 6,
    "image": "https://rango.vip/i/7YUUp2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.99913,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora",
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "USDT.E",
    "name": "Tether USD",
    "isPopular": true,
    "chainId": "1313161554",
    "address": "0x4988a896b1227218e4a686fde5eabdcabd91571f",
    "decimals": 6,
    "image": "https://rango.vip/i/m85wFd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.999281,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora",
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "ATLUNA",
    "name": "Luna Terra",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xc4bdd27c33ec7daa6fcfd8532ddb524bf4038096",
    "decimals": 18,
    "image": "https://rango.vip/i/U74NQ6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.00007172,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "ATUST",
    "name": "UST Terra",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x5ce9f0b6afb36135b5ddbf11705ceb65e634a9dc",
    "decimals": 18,
    "image": "https://rango.vip/i/CRTErg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.01544835,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora",
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "POLAR",
    "name": "Polar",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xf0f3b9eee32b1f490a4b8720cf6f005d4ae9ea86",
    "decimals": 18,
    "image": "https://rango.vip/i/w8PJ02",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.0202827987402705,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "SPOLAR",
    "name": "Polar Share",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x9d6fc90b25976e40adad5a3edd08af9ed7a21729",
    "decimals": 18,
    "image": "https://rango.vip/i/AGa6Ck",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.3600090147233071,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "AUSDO",
    "name": "aUSDO",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x293074789b247cab05357b08052468b5d7a23c5a",
    "decimals": 8,
    "image": "https://rango.vip/i/Wfr4wb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.02867318208226843,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "PAD",
    "name": "SmartPad token",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x34f291934b88c7870b7a17835b926b264fc13a81",
    "decimals": 18,
    "image": "https://rango.vip/i/teg9Kr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.0005420001761981687,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "AVRIT",
    "name": "Avrit Learning",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xb4530aa877d25e51c18677753acd80ffa54009b2",
    "decimals": 18,
    "image": "https://rango.vip/i/2Y0L1q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.6806968172984224,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "BSTN",
    "name": "Bastion",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x9f1f933c660a1dc856f0e0fe058435879c5ccef0",
    "decimals": 18,
    "image": "https://rango.vip/i/JmAA0h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 8.493831964107968e-7,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "KSW",
    "name": "KillSwitchToken",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xe4eb03598f4dcab740331fa432f4b85ff58aa97e",
    "decimals": 18,
    "image": "https://rango.vip/i/7Nf2tk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.003156043095406442,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "LINEAR",
    "name": "LiNEAR",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x918dbe087040a41b786f0da83190c293dae24749",
    "decimals": 24,
    "image": "https://rango.vip/i/RDq6Z9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 4.54,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "UMINT",
    "name": "YouMinter",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x984c2505a14da732d7271416356f535953610340",
    "decimals": 18,
    "image": "https://rango.vip/i/W38RaO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.01905441187368314,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "SHITZU",
    "name": "Shitzu",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x68e401b61ea53889505cc1366710f733a60c2d41",
    "decimals": 18,
    "image": "https://rango.vip/i/tpyCBK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.00011126888914638447,
    "supportedSwappers": [
      "TrisolarisSwap",
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "VWAVE",
    "name": "vaporwave.finance",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x2451db68ded81900c4f16ae1af597e9658689734",
    "decimals": 18,
    "image": "https://rango.vip/i/armlxP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 7.62,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "ETHERNAL",
    "name": "ETHERNAL",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x17cbd9c274e90c537790c51b4015a65cd015497e",
    "decimals": 18,
    "image": "https://rango.vip/i/qhPsXO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 30.2715072473576,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "NEARX",
    "name": "NearX",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xb39eeb9e168ef6c639f5e282fef1f6bc4dcae375",
    "decimals": 24,
    "image": "https://rango.vip/i/EFyNcI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 4.369758410520466,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "NSTART",
    "name": "NearStarter",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x06aebb0f3d9ebe9829e1b67bd3dd608f711d3412",
    "decimals": 24,
    "image": "https://rango.vip/i/de6yXN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.005426593328793698,
    "supportedSwappers": [
      "TrisolarisSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "BRL",
    "name": "BRL",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x12c87331f086c3c926248f964f8702c0842fd77f",
    "decimals": 18,
    "image": "https://rango.vip/i/MapL8t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.0076604866277642375,
    "supportedSwappers": [
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "PLRS",
    "name": "PLRS",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x631edd14bb0b2505c91176feedc5f082d27dd5b8",
    "decimals": 18,
    "image": "https://rango.vip/i/mxChcN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 9.569207011637795e-11,
    "supportedSwappers": [
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "PAPR",
    "name": "PAPR",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0xa5c09de3aa1cdb5cb190be66c77e033be1ca594a",
    "decimals": 18,
    "image": "https://rango.vip/i/sXsnQu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.032969435419372486,
    "supportedSwappers": [
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "PRNTR",
    "name": "PRNTR",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x29daeb6a2a87b159f5fee1f19ec6ccaf4ef2acca",
    "decimals": 18,
    "image": "https://rango.vip/i/Uf33Cw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.511929724249563,
    "supportedSwappers": [
      "AuroraSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "UST",
    "name": "Axelar Wrapped UST",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x085416975fe14c2a731a97ec38b9bf8135231f62",
    "decimals": 6,
    "image": "https://rango.vip/i/FBtxR4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.20971848171199342,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "USDBC",
    "name": null,
    "isPopular": true,
    "chainId": "8453",
    "address": "0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca",
    "decimals": 6,
    "image": "https://rango.vip/i/rQZ60P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.999717,
    "supportedSwappers": [
      "Symbiosis",
      "BaseCurveFinance",
      "OrbiterV2",
      "SushiBase",
      "stargate",
      "AlienBase",
      "OneInchBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "fUSDC",
    "name": "Fluid USDC",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ez2zVjw85tZan1ycnJ5PywNNxR6Gm4jbXQtZKyQNu3Lv",
    "decimals": 6,
    "image": "https://rango.vip/i/q8AjNO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.999889,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "21BTC",
    "name": "21.co Wrapped Bitcoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "21BTCo9hWHjGYYUQQLqjLgDBxjcn8vDt4Zic7TB3UbNE",
    "decimals": 8,
    "image": "https://rango.vip/i/qsFO3F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 57453,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "bunk",
    "name": "bunkee",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2nhjjqSkA8FYCUdJvQhYjbtZdPjZbNo8VtNKTkJ3hncb",
    "decimals": 6,
    "image": "https://rango.vip/i/lq2yLw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008183,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FURBY",
    "name": "FURBY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "34pxeud2ajwJaq7XZrYdvUUGHKJP2dFrAg6k8zvSsigt",
    "decimals": 9,
    "image": "https://rango.vip/i/CdruDS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "M.USDC",
    "name": "USDC Token",
    "isPopular": true,
    "chainId": "1088",
    "address": "0xea32a96608495e54156ae48931a7c20f0dcc1a21",
    "decimals": 6,
    "image": "https://rango.vip/i/vnQOZD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 0.999719,
    "supportedSwappers": [
      "Synapse Swapper",
      "Netswap"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "M.USDT",
    "name": "USDT Token",
    "isPopular": true,
    "chainId": "1088",
    "address": "0xbb06dca3ae6887fabf931640f67cab3e3a16f4dc",
    "decimals": 6,
    "image": "https://rango.vip/i/x8dfXF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 0.998955,
    "supportedSwappers": [
      "Netswap"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "RELAY",
    "name": "Relay Token",
    "isPopular": false,
    "chainId": "1088",
    "address": "0xfe282af5f9eb59c30a3f78789eeffa704188bdd4",
    "decimals": 18,
    "image": "https://rango.vip/i/M40dMF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 0.016978646549576006,
    "supportedSwappers": [
      "Netswap"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "ENKI",
    "name": "ENKI Protocol",
    "isPopular": false,
    "chainId": "1088",
    "address": "0x096a84536ab84e68ee210561ffd3a038e79736f1",
    "decimals": 18,
    "image": "https://rango.vip/i/uk1Xsm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 3.33,
    "supportedSwappers": [
      "Netswap"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "EMETIS",
    "name": "ENKI Metis",
    "isPopular": false,
    "chainId": "1088",
    "address": "0x97a2de3a09f4a4229369ee82c7f76be1a5564661",
    "decimals": 18,
    "image": "https://rango.vip/i/97zrdi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": 27.835019981645484,
    "supportedSwappers": [
      "Netswap"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AXLUSDC",
    "name": "Axelar USDC",
    "isPopular": false,
    "chainId": "137",
    "address": "0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed",
    "decimals": 6,
    "image": "https://api.rango.exchange/tokens/COSMOS/AXLUSDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.999524,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "Satellite",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "KAX",
    "name": "KAXAA",
    "isPopular": false,
    "chainId": "137",
    "address": "0x42af7aac6ae6425ffa96370cfd0b12522aa4b458",
    "decimals": 18,
    "image": "https://api.rango.exchange/tokens/POLYGON/KAX.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GODZ",
    "name": "Godzilla Project Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xae7cf30e14e132e43689ebe4fab49706c59a0bf7",
    "decimals": 9,
    "image": "https://api.rango.exchange/tokens/ETH/GODZ.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4.02434e-10,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AXLUSDC",
    "name": "Axelar USDC",
    "isPopular": true,
    "chainId": "56",
    "address": "0x4268b8f0b87b6eae5d897996e6b845ddbd99adf3",
    "decimals": 6,
    "image": "https://api.rango.exchange/tokens/COSMOS/AXLUSDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.999524,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "BSCPancakeV3",
      "Satellite",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STETH",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0xae7ab96520de3a18e5e111b5eaab095312d7fe84",
    "decimals": 18,
    "image": "https://rango.vip/i/B6xqIa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2344.83,
    "supportedSwappers": [
      "Lido",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "USD₮",
    "name": "Tether USD",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x48065fbbe25f71c9282ddf5e1cd6d6a887483d5e",
    "decimals": 6,
    "image": "https://rango.vip/i/vcRVCn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.999615,
    "supportedSwappers": [
      "UbeSwap",
      "AllBridge",
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "USDC.AXL",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/d189335c6e4a68b513c10ab227bf1c1d38c746766278ba3eeb4fb14124f1d858",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 1.0000000020866304,
    "supportedSwappers": [
      "Satellite",
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "WBTC.AXL",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/d1542aa8762db13087d8364f3ea6509fd6f009a34f00426af9e4f9fa85cbbf1f",
    "decimals": 8,
    "image": "https://rango.vip/tokens/ALL/WBTC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 55798.89284126305,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "USDT.AXL",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/8242ad24008032e457d2e12d46588fd39fb54fb29680c6c7663d296b383c37c4",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDT.E.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.9979152620822803,
    "supportedSwappers": [
      "Satellite",
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "MOONBEAM.DOT.AXL",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/3ff92d26b407fd61ae95d975712a7c319cde28de4d80bdc9978d935932b991d7",
    "decimals": 10,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "Satellite",
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "IOV",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/52b1aa623b34eb78fd767cea69e8d7fa6c9cfe1fbf49c5406268fd325e2cc2ac",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/IOV.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00057407,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "IXO",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/f3ff7a84a73b62921538642f9797c423d2b4c4acb3c7fcffce7f12aa69909c4b",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/IXO.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.01941271417637376,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "DSM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/ea4c0a9f72e2cedf10d0e7a9a6a22954db3444910db5be980df59b05a46dad1c",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/DSM.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0047508362376806395,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "UMEE",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/67795e528df67c5606fc20f824ea39a6ef55ba133f4dc79c90a8c47a0901e17c",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/UMEE.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0008806100791518551,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "NETA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/297c64cc42b5a8d8f82fe2ebe208a6fe8f94b86037fa28c4529a23701c228f7a",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/NETA.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 7.723928266748851,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "WETH.GRV",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/65381c5f3fd21442283d56925e62ea524ded8b6927f0ff94e21e0020954c40b5",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/WETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 2355.249549044224,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "USDC.GRV",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/9f9b07ef9ad291167cf5700628145de1deb777c2cfc7907553b24446515f6d0e",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/USDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 1.0018843494818304,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "HASH",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/ce5bff1d9bada03bb5cca5f56939392a761b53a10fbd03b37506669c3218d3b2",
    "decimals": 9,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.018663634559836162,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "TGD",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/1e09cb0f506acf12fde4683fb6b34da62fb4be122641e0d93aaf98a87675676c",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/TGD.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0005639156676321281,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "GLTO",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/52c57fca7d6854aa178e7a183ddbe4ef322b904b1d719fc485f6ffbc1f72a19e",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/GLTO.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.000418784602196992,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "GKEY",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/7c781b4c2082cd62129a972d47486d78ec17155c299270e3c89348ea026beaf8",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/GKEY.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 38.000654554503754,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STATOM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/c140afd542ae77bd7dcc83f13fdd8c5e5bb8c4929785e6ec2f4c636f98f17901",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/STATOM.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 5.36,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STSTARS",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/5dd1f95ed336014d00ce2520977ec71566d282f9749170adc83a392e0ea7426a",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/STSTARS.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.010271089006584638,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STOSMO",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/d176154b0c63d1f9c6dcfb4f70349ebf2e2b5a87a05902f57a6ae92b863e9aec",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/STOSMO.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.47191,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "CMST",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/23ca6c8d1ab2145dd13eb1e089a2e3f960dc298b468cce034e19e5a78b61136e",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/CMST.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.086098,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "PHMN",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/d3b574938631b0a1ba704879020c696e514cfadaa7643cde4bd5eb010bde327b",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/PHMN.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 9.1,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "QSTARS",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/46c83bb054e12e189882b5284542db605d94c99827e367c9192cf0579cd5bc83",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/QSTARS.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.009463090927291156,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "WYND",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/2fbac4bf296d7844796844b35978e5899984ba5a6314b2dd8f83c215550010b3",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/WYND.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.00210685501530112,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "QOSMO",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/42d24879d4569ce6477b7e88206adbfe47c222c6cad51a54083e4a72594269fc",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/QOSMO.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.45070156180008963,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "FRNZ",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/7fa7ec64490e3bde5a1a28cbe73cc0ad22522794957bc891c46321e3a6074db9",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/FRNZ.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "LORE",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/b1c1806a540b3e165a2d42222c59946fb85ba325596fc85662d7047649f419f3",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/LORE.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.0052604163727948796,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STIBCX",
    "name": null,
    "isPopular": true,
    "chainId": "osmosis-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/STIBCX.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 33.414260017685045,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STSOMM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/5a0060579d24fbe5268bea74c3281e7fe533d361c41a99307b4998fec611e46b",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.022964443814655497,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "YIELDETH",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/fbb3fef80ed2344d821d4f95c31dbfd33e4e31d5324cad94ef756e67b749f668",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 2557.5429698058333,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "RATOM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/b66ce615c600ed0a8b5af425ecfe0d57be2377587f66c45934a76886f34dc9b7",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 6.482389060312788,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STRDST",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/cff40564fda3e958d9904b8b479124987901168494655d9cc6b7c0ec0416020b",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.01420766176986239,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "NBTC",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/75345531d87bd90bf108be7240bd721cb2cb0a1f16d4eba71b09ec3c43e15c8f",
    "decimals": 14,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 55717.19679335661,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "HYDROGEN",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/4f3b0ec2fe2d370d10c3671a1b7b06d2a964c721470c305cbb846ed60e6caa20",
    "decimals": 0,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 1.6814288170516663e-9,
    "supportedSwappers": [
      "Osmosis"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "NEWT",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/bf685448e564b5a4ac8f6e0493a0b979d0e0bf5ec11f7e15d25a0a2160c944dd",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.010073186576221247,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "APOLLO",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/73bb20af857d1fe6e061d01ca13870872ad0c979497caf71bea25b1cbf6879f1",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STDYDX",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/980e82a9f8e7ca8cd480f4577e73682a6d3855a267d1831485d7ebef0e7a6c2c",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/STDYDX.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0.9283438340974535,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STTIA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/698350b8a61d575025f3ed13e9ac9c0f45c89defe92f76d5838f1d3c1a7ff7c9",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 4.19006379609552,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STSAGA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/2cd9f8161c3fc332e78ef0c25f6e684d09379fb2f56ef9267e7ec139642ec57b",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STINJ",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/c04dfc9bcd893e57f2befe40f63efd18d2768514dbd5f63abd2ff7f48fc01d36",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "STDYM",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/d53e785dc9c5c2ca50cadb1efe4de5d0c30418be0e9c6f2af9f092a247e8bc22",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "OSMOSIS",
    "symbol": "HAVA",
    "name": null,
    "isPopular": false,
    "chainId": "osmosis-1",
    "address": "ibc/884ebc228dfce8f1304d917a712aa9611427a6c1ecc3179b2e91d7468fb091a2",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OSMOSIS/icon.svg",
    "usdPrice": 4.1132e-8,
    "supportedSwappers": [
      "Osmosis",
      "IBC"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TXS",
    "name": "TXS",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8b298c5fbf0a8d4cd65ae524c8792b8cdfd9fbe9",
    "decimals": 9,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "GRIMACE",
    "name": "GRIMACE",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc6759a4fc56b3ce9734035a56b36e8637c45b77e",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.135168,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "PRIMO",
    "name": "PRIMO",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TPbbHx2ztzYoJS12S4BkyhNjRBH9i7539r",
    "decimals": 6,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEPE",
    "name": "MEPE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc80285cbe1b73aa6b7f984abdc7ee85cced6df43",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHC",
    "name": "EtheraCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6cabcf2b777db604539c46a329e2f793656f6a16",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2331.58,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HPXT",
    "name": "HPXT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x50363c8db819733070f50e145e12f439ba9c6df0",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEMO",
    "name": "MEMO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb8eec5a2dbf6e6f4cc542ad31cce706f8f80419",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MLUT",
    "name": "MLUT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7358cda6a5db35ce11a3a79a61145ec93b63eed4",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CRICKETS",
    "name": "CRICKETS",
    "isPopular": false,
    "chainId": "56",
    "address": "0xad4c689dcb380a94f7e13400bd03fd998cf7af7a",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NBD",
    "name": "NBD",
    "isPopular": false,
    "chainId": "56",
    "address": "0x199d07aa6723e9324f44f89885101ff79e11919a",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00007411,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "314",
    "name": "314",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc7d4515ba4c82c98a5a81d2a5ef98dcf35314314",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LYK",
    "name": "LYK",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf50441d584d435e5f917c8201f72ca2b1b7f1d04",
    "decimals": 8,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "N",
    "name": "N",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc7bb03ddd9311fc0338be013e7b523254092fda9",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.08498e-7,
    "supportedSwappers": [
      "Bridgers",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "TRONDOGE",
    "name": "TRONDOGE",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TEKWczHwjSiS75N37YSz1fgraZLuarks86",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.00039073,
    "supportedSwappers": [
      "Bridgers"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "Arken",
    "name": "Arken token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xaf5db6e1cc585ca312e8c8f7c499033590cf5c98",
    "decimals": 18,
    "image": "https://rango.vip/i/a9NgsG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0005806,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "CABAG",
    "name": "Cabbage.Cash",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xdb333724fae72b4253fc3d44c8270cbbc86d147b",
    "decimals": 18,
    "image": "https://rango.vip/i/SvOoQp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "YTS",
    "name": "YetiSwap",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x488f73cddda1de3664775ffd91623637383d6404",
    "decimals": 18,
    "image": "https://rango.vip/i/svbTDy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AVME",
    "name": "AVME",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x1ecd47ff4d9598f89721a2866bfeb99505a413ed",
    "decimals": 18,
    "image": "https://rango.vip/i/ayrKqz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GDL",
    "name": "Gondola",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xd606199557c8ab6f4cc70bd03facc96ca576f142",
    "decimals": 18,
    "image": "https://rango.vip/i/jAUZou",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SHERPA",
    "name": "Sherpa",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xa5e59761ebd4436fa4d20e1a27cba29fb2471fc6",
    "decimals": 18,
    "image": "https://rango.vip/i/YHVPEL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "TEDDY",
    "name": "TEDDY",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x094bd7b2d99711a1486fb94d4395801c6d0fddcc",
    "decimals": 18,
    "image": "https://rango.vip/i/AX4m4K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "TOK",
    "name": "TOK",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xae9d2385ff2e2951dd4fa061e74c4d3dedd24347",
    "decimals": 8,
    "image": "https://rango.vip/i/rynLjj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00019679,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "IronICE",
    "name": "Iron Finance ICE Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xfc108f21931576a21d0b4b301935dac80d9e5086",
    "decimals": 18,
    "image": "https://rango.vip/i/9uAv07",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ankrAVAX",
    "name": "Ankr Staked Avax",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc3344870d52688874b06d844e0c36cc39fc727f6",
    "decimals": 18,
    "image": "https://rango.vip/i/Bd70X8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "IMX.a",
    "name": "IMX@avalanche",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xea6887e4a9cda1b77e70129e5fba830cdb5cddef",
    "decimals": 18,
    "image": "https://rango.vip/i/qhi6I3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "iDYP",
    "name": "iDeFiYieldProtocol",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xbd100d061e120b2c67a24453cf6368e63f1be056",
    "decimals": 18,
    "image": "https://rango.vip/i/eTjmDZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0006595,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "GLDB",
    "name": "BiAltin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xca220f1e486a8e35d6f1dcd62073ad8dd04659ed",
    "decimals": 6,
    "image": "https://rango.vip/i/t6s0As",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MELT",
    "name": "Defrost Finance Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x47eb6f7525c1aa999fbc9ee92715f5231eb1241d",
    "decimals": 18,
    "image": "https://rango.vip/i/YXTUEf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "TUS",
    "name": "Treasure Under Sea",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf693248f96fe03422fea95ac0afbbbc4a8fdd172",
    "decimals": 18,
    "image": "https://rango.vip/i/veMOcm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00001295,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "IME",
    "name": "Imperium Empires Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf891214fdcf9cdaa5fdc42369ee4f27f226adad6",
    "decimals": 18,
    "image": "https://rango.vip/i/d5LBpu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00000408,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HSHARES",
    "name": "HERMES Shares",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xfa4b6db72a650601e7bd50a0a9f537c9e98311b2",
    "decimals": 18,
    "image": "https://rango.vip/i/94Vmjy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ENXT",
    "name": "ENXT Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x164334ed9e63fbedc8b52e6dbd408af4f051419f",
    "decimals": 6,
    "image": "https://rango.vip/i/lT9Dbb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SWAPXI",
    "name": "SwapXI Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x312ee43df66d1fd1ea28e5b28f355da84dca13c2",
    "decimals": 12,
    "image": "https://rango.vip/i/hu9i0q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BRIBE",
    "name": "BRIBE",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xce2fbed816e320258161ced52c2d0cebcdfd8136",
    "decimals": 18,
    "image": "https://rango.vip/i/8pxJBZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DLAUNCH",
    "name": "DefiLaunch Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x0659133127749cc0616ed6632912ddf7cc8d7545",
    "decimals": 18,
    "image": "https://rango.vip/i/U6pnz3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AGF",
    "name": "Augmented Finance",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb67a9374da03d4114a6fb8f0e7f2b82b5cb34ee3",
    "decimals": 18,
    "image": "https://rango.vip/i/Vuh1QL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "KSHARE",
    "name": "King Shares",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x94d649e017625de1aa04733aa046de0161df18ef",
    "decimals": 18,
    "image": "https://rango.vip/i/8hBo7S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "RICH",
    "name": "RICH",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x4960bc6032351ab87627b8f36b37b4938a44c7ef",
    "decimals": 18,
    "image": "https://rango.vip/i/LRqC8y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00002867,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BUSINESSES",
    "name": "BUSINESSES",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x15c841043e13ffaa9a99fabea236d40f45615623",
    "decimals": 18,
    "image": "https://rango.vip/i/hGVw18",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DOLLA",
    "name": "dolla",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x4b322765d761984c0f9e72200b62a9b445f38cac",
    "decimals": 18,
    "image": "https://rango.vip/i/E03N3k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "OML",
    "name": "Omlira",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x979ffd8eed7a43629ea29581df4bfe2b3f224e47",
    "decimals": 18,
    "image": "https://rango.vip/i/aiKSiF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SPLASH",
    "name": "Splash Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xe16253892f126d068e711c2fdde6db56969dbcf6",
    "decimals": 18,
    "image": "https://rango.vip/i/2NSk3T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.04120157,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WAVE",
    "name": "Wave Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xbc6f589171d6d66eb44ebcc92dffb570db4208da",
    "decimals": 18,
    "image": "https://rango.vip/i/rxFyVC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 4.85936e-7,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "BUSD.e",
    "name": "Binance USD",
    "isPopular": true,
    "chainId": "43114",
    "address": "0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98",
    "decimals": 18,
    "image": "https://rango.vip/i/PsiPay",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "agEUR",
    "name": "agEUR",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x6fefd97f328342a8a840546a55fdcfee7542f9a8",
    "decimals": 18,
    "image": "https://rango.vip/i/xZt4G0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "UND",
    "name": "Unbound Dollar",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x6b56ec4a92765203508fb40fec9fa23e549b705a",
    "decimals": 18,
    "image": "https://rango.vip/i/5aRtuP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01583171,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "LAMA",
    "name": "LAMA",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x89a8633bcad3af0951acc5137811ea21a17c37dc",
    "decimals": 18,
    "image": "https://rango.vip/i/TRHhQK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 7.88452e-7,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "KOVIN",
    "name": "Kovin Segnocchi",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x694200a68b18232916353250955be220e88c5cbb",
    "decimals": 18,
    "image": "https://rango.vip/i/bwn1YD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00000502,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "wstETH",
    "name": "Wrapped liquid staked Ether 2.0",
    "isPopular": false,
    "chainId": "SN_MAIN",
    "address": "0x42b8f0484674ca266ac5d08e4ac6a3fe65bd3129795def2dca5c34ecc5f96d2",
    "decimals": 18,
    "image": "https://rango.vip/i/d36QaX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 2779.2153173899433,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AXLUSDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xfab550568c688d5d8a52c7d794cb93edc26ec0ec",
    "decimals": 6,
    "image": "https://rango.vip/i/pLKNmJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.999524,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "Satellite",
      "AvaxChainV3",
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "AXLUSDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "59144",
    "address": "0xeb466342c4d449bc9f53a865d5cb90586f405215",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/AXLUSDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 0.999524,
    "supportedSwappers": [
      "LineaPancakeV3",
      "Satellite",
      "LineaKyberV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "AXLUSDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "42220",
    "address": "0xeb466342c4d449bc9f53a865d5cb90586f405215",
    "decimals": 6,
    "image": "https://rango.vip/i/DbpPQ6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.999524,
    "supportedSwappers": [
      "Satellite"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AXLUSDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xeb466342c4d449bc9f53a865d5cb90586f405215",
    "decimals": 6,
    "image": "https://rango.vip/i/RwLzpQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.999524,
    "supportedSwappers": [
      "BaseCurveFinance",
      "SushiBase",
      "Satellite",
      "OneInchBase",
      "AlienBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "AXLUSDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "534352",
    "address": "0xeb466342c4d449bc9f53a865d5cb90586f405215",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/AXLUSDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 1.006,
    "supportedSwappers": [
      "Satellite"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AXLUSDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xeb466342c4d449bc9f53a865d5cb90586f405215",
    "decimals": 6,
    "image": "https://rango.vip/i/PxnPPP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.999524,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "Satellite",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "AXLUSDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "10",
    "address": "0xeb466342c4d449bc9f53a865d5cb90586f405215",
    "decimals": 6,
    "image": "https://rango.vip/i/uIw3sB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.999524,
    "supportedSwappers": [
      "Satellite",
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "AXLUSDC",
    "name": "USD Coin",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xeb466342c4d449bc9f53a865d5cb90586f405215",
    "decimals": 6,
    "image": "https://rango.vip/tokens/ALL/AXLUSDC.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "Satellite"
    ]
  },
  {
    "blockchain": "NEUTRON",
    "symbol": "AXL-WSTETH",
    "name": "Wrapped liquid staked Ether 2.0",
    "isPopular": false,
    "chainId": "neutron-1",
    "address": "ibc/4d04085167777659c11784a356d6b0d13d5c7f0ce77f7db1152fe03a2de2cbf2",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/AXL-WSTETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/NEUTRON/icon.svg",
    "usdPrice": 2344.83,
    "supportedSwappers": [
      "Satellite"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VTHOR",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x815c23eca83261b6ec689b60cc4a58b54bc24d8d",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.5711857607622629,
    "supportedSwappers": [
      "ThorChain",
      "ThorChainStreamingSwap"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "nUSD",
    "name": "Synapse nUSD",
    "isPopular": false,
    "chainId": "56",
    "address": "0x23b891e5c62e0955ae2bd185990103928ab817b3",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/NUSD.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Synapse Swapper"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "nUSD",
    "name": "Synapse nUSD",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb6c473756050de474286bed418b77aeac39b02af",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/NUSD.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Synapse Swapper"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "nUSD",
    "name": "Synapse nUSD",
    "isPopular": false,
    "chainId": "1088",
    "address": "0x961318fc85475e125b99cc9215f62679ae5200ab",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/NUSD.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Synapse Swapper"
    ]
  },
  {
    "blockchain": "METIS",
    "symbol": "nETH",
    "name": "Synapse nETH",
    "isPopular": false,
    "chainId": "1088",
    "address": "0x931b8f17764362a3325d30681009f0edd6211231",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/NETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/METIS/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Synapse Swapper"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "nUSD",
    "name": "Synapse nUSD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2913e812cf0dcca30fb28e6cac3d2dcff4497688",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/NUSD.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Synapse Swapper"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "nETH",
    "name": "Synapse nETH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3ea9b0ab55f34fb188824ee288ceaefc63cf908e",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/NETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Synapse Swapper"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "nUSD",
    "name": "Synapse nUSD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xcfc37a6ab183dd4aed08c204d1c2773c0b1bdf46",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/NUSD.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Synapse Swapper"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "nETH",
    "name": "Synapse nETH",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x19e1ae0ee35c0404f835521146206595d37981ae",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/NETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Synapse Swapper"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AVWETH",
    "name": "AAVE Wrapped Ether",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x53f7c5869a859f0aec3d334ee8b4cf01e3492f21",
    "decimals": 18,
    "image": "https://rango.vip/tokens/ALL/AVWETH.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Synapse Swapper"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GLD",
    "name": null,
    "isPopular": false,
    "chainId": "42161",
    "address": "0xafd091f140c21770f4e5d53d26b2859ae97555aa",
    "decimals": 18,
    "image": "https://rango.vip/tokens/UNKNOWN/icon.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.007667723145506267,
    "supportedSwappers": [
      "MayaProtocol"
    ]
  },
  {
    "blockchain": "MAYA",
    "symbol": "CACAO",
    "name": null,
    "isPopular": true,
    "chainId": "mayachain-mainnet-v1",
    "address": null,
    "decimals": 10,
    "image": "https://rango.vip/tokens/ALL/CACAO.png",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MAYA/icon.svg",
    "usdPrice": 0.5554924546300063,
    "supportedSwappers": [
      "MayaProtocol"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "sUSD",
    "name": null,
    "isPopular": false,
    "chainId": "1",
    "address": "0x57ab1ec28d129707052df4df418d58a2d46d5f51",
    "decimals": 18,
    "image": "https://rango.vip/i/GP4iOm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.992424,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "EthereumUniswapV3",
      "stargate",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "miMATIC",
    "name": null,
    "isPopular": false,
    "chainId": "137",
    "address": "0xa3fa99a148fa48d14ed51d610c367c61876997f1",
    "decimals": 18,
    "image": "https://rango.vip/i/vnl033",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.999262,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "ParaSwap Polygon",
      "PolygonUniSwapV3",
      "QuickSwap",
      "stargate",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "sUSD",
    "name": null,
    "isPopular": false,
    "chainId": "10",
    "address": "0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9",
    "decimals": 18,
    "image": "https://rango.vip/i/Umc5YO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.990546,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3",
      "stargate",
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "ezETH",
    "name": "Renzo Restaked ETH",
    "isPopular": false,
    "chainId": "34443",
    "address": "0x2416092f143378750bb29b79ed961ab195cceea5",
    "decimals": 18,
    "image": "https://rango.vip/i/iIjAYy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 2396.4684837590967,
    "supportedSwappers": [
      "SupSwapModeV3",
      "SwapMode"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "weETH.mode",
    "name": "Wrapped eETH",
    "isPopular": false,
    "chainId": "34443",
    "address": "0x04c0599ae5a44757c0af6f9ec3b93da8976c150a",
    "decimals": 18,
    "image": "https://rango.vip/i/5al5ZJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SupSwapModeV3",
      "SwapMode"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CADDY",
    "name": "Adamant Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x09ad12552ec45f82be90b38dfe7b06332a680864",
    "decimals": 18,
    "image": "https://rango.vip/i/7So1Tw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00010255831187137282,
    "supportedSwappers": [
      "CronaSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CRN",
    "name": "CroNode",
    "isPopular": false,
    "chainId": "25",
    "address": "0x8174bac1453c3ac7caed909c20ceadeb5e1cda00",
    "decimals": 18,
    "image": "https://rango.vip/i/HdwZkt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.026811084184111777,
    "supportedSwappers": [
      "CronaSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CROATOM",
    "name": "CroATOM",
    "isPopular": false,
    "chainId": "25",
    "address": "0x8b0d1164e98dd0592598b56c1baa86462c73a2d1",
    "decimals": 9,
    "image": "https://rango.vip/i/wvZG0K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0007576633388353448,
    "supportedSwappers": [
      "CronaSwap"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "MIMAS",
    "name": "Mimas finance",
    "isPopular": false,
    "chainId": "25",
    "address": "0x10c9284e6094b71d3ce4e38b8bffc668199da677",
    "decimals": 18,
    "image": "https://rango.vip/i/JFc5VE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00007697,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "CROISSANT",
    "name": "Croissant games",
    "isPopular": false,
    "chainId": "25",
    "address": "0xa0c3c184493f2fae7d2f2bd83f195a1c300fa353",
    "decimals": 18,
    "image": "https://rango.vip/i/rAswYP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00009262,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "RNA",
    "name": "RNA Dollar",
    "isPopular": false,
    "chainId": "25",
    "address": "0xde9e2addfd3bbadb67553cdb120c6c6593b180f9",
    "decimals": 18,
    "image": "https://rango.vip/i/iAeETh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.003290165292322991,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "ANN",
    "name": "Annex",
    "isPopular": false,
    "chainId": "25",
    "address": "0x98936bde1cf1bff1e7a8012cee5e2583851f2067",
    "decimals": 18,
    "image": "https://rango.vip/i/cNq6pU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00008387826016724354,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "MSHARE",
    "name": "MSHARE Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xf8b9facb7b4410f5703eb29093302f2933d6e1aa",
    "decimals": 18,
    "image": "https://rango.vip/i/sb6NYz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.515535,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "BETIFY",
    "name": "BETIFY Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xd465b6b4937d768075414d413e981af0b49349cc",
    "decimals": 9,
    "image": "https://rango.vip/i/V7DEmr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.0014335578000324977,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "METF",
    "name": "METF token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xb8df27c687c6af9afe845a2afad2d01e199f4878",
    "decimals": 18,
    "image": "https://rango.vip/i/ShH2LA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.828955,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "PES",
    "name": "Pegasus Dollar token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x8efbaa6080412d7832025b03b9239d0be1e2aa3b",
    "decimals": 18,
    "image": "https://rango.vip/i/sIqgGv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.13368722020920265,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "BURROW",
    "name": "MMF Money",
    "isPopular": false,
    "chainId": "25",
    "address": "0x49ab7ca4d2cf6777c8c56c5e9edb7d1218ae1299",
    "decimals": 18,
    "image": "https://rango.vip/i/WKaZB5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.000004066113689896862,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "HKN",
    "name": "HAKUNA Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xa60943a1b19395c999ce6c21527b6b278f3f2046",
    "decimals": 18,
    "image": "https://rango.vip/i/Lere9x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00028869197615601745,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "DEER",
    "name": "ToxicDeer Finance",
    "isPopular": false,
    "chainId": "25",
    "address": "0x6d6d6ba0c7e7dbafffec82b1ddf92e271650a63a",
    "decimals": 18,
    "image": "https://rango.vip/i/crgzUU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.03282313,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "XDSHARE",
    "name": "ToxicDeer Shares",
    "isPopular": false,
    "chainId": "25",
    "address": "0x6f715158d4b1468528da002f5941c72fe4159520",
    "decimals": 18,
    "image": "https://rango.vip/i/teC1ta",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.3965239367866655,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "MNG",
    "name": "Mongoose Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0xc9b23289c60783649aa327732fccec2f5d0ac466",
    "decimals": 18,
    "image": "https://rango.vip/i/QyR1iH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00008214114008800054,
    "supportedSwappers": [
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "BCRO",
    "name": "Bonded CRO",
    "isPopular": false,
    "chainId": "25",
    "address": "0xebaceb7f193955b946cc5dd8f8724a80671a1f2f",
    "decimals": 18,
    "image": "https://rango.vip/i/OJCDEy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.089986,
    "supportedSwappers": [
      "VVSFinance",
      "MMFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "QRDO",
    "name": "Qredo Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x70bb395f1a824d9a3f9d510c25e699ceaf603dec",
    "decimals": 8,
    "image": "https://rango.vip/i/KMF8Ib",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.008523360500948736,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "V3S",
    "name": "V3S",
    "isPopular": false,
    "chainId": "25",
    "address": "0xc7e99a2f064cf86af91db252a9d8bc16e6fe7427",
    "decimals": 18,
    "image": "https://rango.vip/i/iEIpV5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 1.5181223576228996e-7,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "PLA",
    "name": "PlayDapp Token",
    "isPopular": false,
    "chainId": "25",
    "address": "0x044597363b0054986ae4289d25cd7d0d451766fc",
    "decimals": 18,
    "image": "https://rango.vip/i/hURalI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.10922679005916179,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "CRONOS",
    "symbol": "OPL",
    "name": "Panterra Opal",
    "isPopular": false,
    "chainId": "25",
    "address": "0x28d292a914edaeb6daa13642231333bd157632b2",
    "decimals": 18,
    "image": "https://rango.vip/i/H2HgWV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CRONOS/icon.svg",
    "usdPrice": 0.00026707598064301094,
    "supportedSwappers": [
      "VVSFinance"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REPV2",
    "name": "Reputation",
    "isPopular": false,
    "chainId": "1",
    "address": "0x221657776846890989a759ba2973e427dff5c9bb",
    "decimals": 18,
    "image": "https://rango.vip/i/re8hfz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.341999,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RNDR",
    "name": "Render Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24",
    "decimals": 18,
    "image": "https://rango.vip/i/fRVWwa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.715894299017029,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOCKS",
    "name": "Unisocks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x23b608675a2b2fb1890d3abbd85c5775c51691d5",
    "decimals": 18,
    "image": "https://rango.vip/i/ex0hOg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 13933.43762662206,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TVK",
    "name": "Terra Virtua Kolect",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988",
    "decimals": 18,
    "image": "https://rango.vip/i/Oxrz1T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.1052,
    "supportedSwappers": [
      "EthUniSwapV2",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "ABBUSD",
    "name": "Wrapped BUSD (Allbridge from BSC)",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x5c92a4a7f59a9484afd79dbe251ad2380e589783",
    "decimals": 18,
    "image": "https://rango.vip/i/CJTxUN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "PAD",
    "name": "NearPad Token",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x885f8cf6e45bdd3fdcdc644efdcd0ac93880c781",
    "decimals": 18,
    "image": "https://rango.vip/i/Op9QrC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": 0.00926967,
    "supportedSwappers": [
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "AURORA",
    "symbol": "SOLACE",
    "name": "solace",
    "isPopular": false,
    "chainId": "1313161554",
    "address": "0x1bda7007c9e3bc33267e883864137af8eb53cc2d",
    "decimals": 18,
    "image": "https://rango.vip/i/sTM7XT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AURORA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OpenOceanAurora"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "TUX",
    "name": "Magicaltux",
    "isPopular": false,
    "chainId": "10",
    "address": "0x17aabf6838a6303fc6e9c5a227dc1eb6d95c829a",
    "decimals": 18,
    "image": "https://rango.vip/i/CoiDtl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00024622,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ANKRETH",
    "name": "Ankr Staked ETH ",
    "isPopular": false,
    "chainId": "10",
    "address": "0xe05a08226c49b636acf99c40da8dc6af83ce5bb3",
    "decimals": 18,
    "image": "https://rango.vip/i/R9mDkZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2733.7,
    "supportedSwappers": [
      "OpenOceanOptimism"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "WTBT",
    "name": "WTBT",
    "isPopular": false,
    "chainId": "10",
    "address": "0xdb4ea87ff83eb1c80b8976fc47731da6a31d35e5",
    "decimals": 18,
    "image": "https://rango.vip/i/OPAnhW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OpenOceanOptimism",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "SWMOVR",
    "name": "Wrapped MOVR(SUSHI)",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xf50225a84382c74cbdea10b0c176f71fc3de0c4d",
    "decimals": 18,
    "image": "https://rango.vip/i/SgcsSB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "RIVRSHIB",
    "name": "RivrShiba",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x3d593056f3e34db7a4720d7f171447c489cfa195",
    "decimals": 9,
    "image": "https://rango.vip/i/5HJcQ1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "RIVRDOGE",
    "name": "RivrDoge",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x5d4360f1be94bd6f182f09cfe5ef9832e65eb1ac",
    "decimals": 18,
    "image": "https://rango.vip/i/vg38u2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "RELAY",
    "name": "Relay Token",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xad7f1844696652dda7959a49063bffccafafefe7",
    "decimals": 18,
    "image": "https://rango.vip/i/snpgWX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.04152965,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "RELAYMAI",
    "name": "MIMATIC",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x7f5a79576620c046a293f54ffcdbd8f2468174f1",
    "decimals": 18,
    "image": "https://rango.vip/i/kSnTlF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.999262,
    "supportedSwappers": [
      "SolarbeamSwap",
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "BNB.BSC",
    "name": "BNB from BSC wrapped by passport.meter.io",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x868892cccedbff0b028f3b3595205ea91b99376b",
    "decimals": 18,
    "image": "https://rango.vip/i/54y4a5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 504.53,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "BEANS",
    "name": "MoonBeans",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xc2392dd3e3fed2c8ed9f7f0bdf6026fcd1348453",
    "decimals": 18,
    "image": "https://rango.vip/i/eP9WyU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.062125,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "AROME",
    "name": "Alpha Rome",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x3d2d044e8c6dad46b4f7896418d3d4dfaad902be",
    "decimals": 9,
    "image": "https://rango.vip/i/ZxLsSs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OpenOceanMoonRiver"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MASKS",
    "name": "Masks",
    "isPopular": false,
    "chainId": "10",
    "address": "0x3925f9820c312d968644f12ebd314c13558a7c05",
    "decimals": 18,
    "image": "https://rango.vip/i/NVPig3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00001358,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "KITE",
    "name": "Kite",
    "isPopular": false,
    "chainId": "10",
    "address": "0xf467c7d5a4a9c4687ffc7986ac6ad5a4c81e1404",
    "decimals": 18,
    "image": "https://rango.vip/i/6uIXiV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 3.04,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "NBL",
    "name": "NBL",
    "isPopular": false,
    "chainId": "10",
    "address": "0x4b03afc91295ed778320c2824bad5eb5a1d852dd",
    "decimals": 18,
    "image": "https://rango.vip/i/9Lsa1F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00007433,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "HANEP",
    "name": "HANePlatform",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc3248a1bd9d72fa3da6e6ba701e58cbf818354eb",
    "decimals": 18,
    "image": "https://rango.vip/i/h5AqvX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 25.93,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "TLX",
    "name": "TLX DAO Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xd9cc3d70e730503e7f28c1b407389198c4b75fa2",
    "decimals": 18,
    "image": "https://rango.vip/i/dNro6t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.097582,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "NYA",
    "name": "Nya",
    "isPopular": false,
    "chainId": "10",
    "address": "0x38f9bf9dce51833ec7f03c9dc218197999999999",
    "decimals": 18,
    "image": "https://rango.vip/i/fa0H9Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 3.76977e-7,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MOOBIFI",
    "name": "Staked BIFI",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc55e93c62874d8100dbd2dfe307edc1036ad5434",
    "decimals": 18,
    "image": "https://rango.vip/i/73wsQU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 308.05,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "IB",
    "name": "Iron Bank",
    "isPopular": false,
    "chainId": "10",
    "address": "0x00a35fd824c717879bf370e70ac6868b95870dfb",
    "decimals": 18,
    "image": "https://rango.vip/i/rg2YIR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.398494,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "EQZ",
    "name": "Equalizer",
    "isPopular": false,
    "chainId": "10",
    "address": "0x81ab7e0d570b01411fcc4afd3d50ec8c241cb74b",
    "decimals": 18,
    "image": "https://rango.vip/i/e48OKk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00317089,
    "supportedSwappers": [
      "OptimismUniSwapV3",
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "UNIDX",
    "name": "UniDex",
    "isPopular": false,
    "chainId": "10",
    "address": "0x28b42698caf46b4b012cf38b6c75867e0762186d",
    "decimals": 18,
    "image": "https://rango.vip/i/W0WZhr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.524884,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "UNIBTC",
    "name": "Universal BTC",
    "isPopular": false,
    "chainId": "10",
    "address": "0x93919784c523f39cacaa98ee0a9d96c3f32b593e",
    "decimals": 8,
    "image": "https://rango.vip/i/J8vG4i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 55541,
    "supportedSwappers": [
      "OptimismUniSwapV3"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "wstETH",
    "name": "Wrapped liquid staked Ether 2.0",
    "isPopular": false,
    "chainId": "534352",
    "address": "0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32",
    "decimals": 18,
    "image": "https://rango.vip/i/WRnXSF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 2759.36,
    "supportedSwappers": [
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "wrsETH",
    "name": "rsETHWrapper",
    "isPopular": false,
    "chainId": "534352",
    "address": "0xa25b25548b4c98b0c7d3d27dca5d5ca743d68b7f",
    "decimals": 18,
    "image": "https://rango.vip/i/pFhxvZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 2396.09,
    "supportedSwappers": [
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "STONE",
    "name": "StakeStone Ether",
    "isPopular": false,
    "chainId": "534352",
    "address": "0x80137510979822322193fc997d400d5a6c747bf7",
    "decimals": 18,
    "image": "https://rango.vip/i/eO1KT9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 2401.12,
    "supportedSwappers": [
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "SCROLL",
    "symbol": "PufETH",
    "name": "PufferVault",
    "isPopular": false,
    "chainId": "534352",
    "address": "0xc4d46e8402f476f269c379677c99f18e22ea030e",
    "decimals": 18,
    "image": "https://rango.vip/i/oujlwR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SCROLL/icon.svg",
    "usdPrice": 2373.0030223296035,
    "supportedSwappers": [
      "ZebraScrollV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MCV",
    "name": "MCVERSE",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x916aba115f5162960e48a2675ad4d8cbd09ce8e4",
    "decimals": 18,
    "image": "https://rango.vip/i/XPOTiy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00042942,
    "supportedSwappers": [
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ART",
    "name": "Salvor",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf99516bc189af00ff8effd5a1f2295b67d70a90e",
    "decimals": 18,
    "image": "https://rango.vip/i/OUnXcH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.02380697,
    "supportedSwappers": [
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "w3ULL",
    "name": "w3ULL",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xa77e70d0af1ac7ff86726740db1bd065c3566937",
    "decimals": 18,
    "image": "https://rango.vip/i/vfm2BS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00133317,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ZJOE",
    "name": "zJOE",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x769bfeb9faacd6eb2746979a8dd0b7e9920ac2a4",
    "decimals": 18,
    "image": "https://rango.vip/i/gXqNMw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.261581,
    "supportedSwappers": [
      "ParaSwap Avalanche",
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "NYA",
    "name": "Nya",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x38f9bf9dce51833ec7f03c9dc218197999999999",
    "decimals": 18,
    "image": "https://rango.vip/i/fa0H9Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 3.76977e-7,
    "supportedSwappers": [
      "AvaxChainV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "agEUR",
    "name": "agEur",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a7e4e63778b4f12a199c062f3efdd288afcbce8",
    "decimals": 18,
    "image": "https://rango.vip/i/cC2LVn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.083,
    "supportedSwappers": [
      "EthereumUniswapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUROC",
    "name": "Euro Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1abaea1f7c830bd89acc67ec4af516284b1bc33c",
    "decimals": 6,
    "image": "https://rango.vip/i/7hyTvl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QRDO",
    "name": "Qredo Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4123a133ae3c521fd134d7b13a2dec35b56c2463",
    "decimals": 8,
    "image": "https://rango.vip/i/x1sL4Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.074551,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QSP",
    "name": "Quantstamp",
    "isPopular": false,
    "chainId": "1",
    "address": "0x99ea4db9ee77acd40b119bd1dc4e33e1c070b80d",
    "decimals": 18,
    "image": "https://rango.vip/i/CT9K6r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00524599,
    "supportedSwappers": [
      "EthereumUniswapV3"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "tBTC",
    "name": "tBTC v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x18084fba666a33d37592fa2633fd49a74dd93a88",
    "decimals": 18,
    "image": "https://rango.vip/i/aU3IHj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55766,
    "supportedSwappers": [
      "EthereumUniswapV3",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "agEUR",
    "name": "agEur",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe0b52e49357fd4daf2c15e02058dce6bc0057db4",
    "decimals": 18,
    "image": "https://rango.vip/i/7tb0hk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.083,
    "supportedSwappers": [
      "PolygonUniSwapV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "UST",
    "name": "Wrapped UST Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x23396cf899ca06c4472205fc903bdb4de249d6fc",
    "decimals": 18,
    "image": "https://rango.vip/i/Ld8qel",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01544835,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "axlSTARS",
    "name": "Axelar Wrapped STARS",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc3cac4ae38ccf6985ef9039acc1abbc874ddcbb0",
    "decimals": 6,
    "image": "https://rango.vip/i/nymi50",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00684317,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "bCFX",
    "name": "Conflux",
    "isPopular": false,
    "chainId": "56",
    "address": "0x045c4324039da91c52c55df5d785385aab073dcf",
    "decimals": 18,
    "image": "https://rango.vip/i/ulvEQp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BNBx",
    "name": "Liquid Staking BNB",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275",
    "decimals": 18,
    "image": "https://rango.vip/i/ErDYQ5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 546.06,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "Bonk",
    "name": "Bonk",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa697e272a73744b343528c3bc4702f2565b2f422",
    "decimals": 5,
    "image": "https://rango.vip/i/kkpijd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00001574,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BOXY",
    "name": "BOXY",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9f5d4479b783327b61718fa13b3a0583869a80c1",
    "decimals": 18,
    "image": "https://rango.vip/i/dQQ1EM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.04228682,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ezETH",
    "name": "Renzo Restaked ETH",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2416092f143378750bb29b79ed961ab195cceea5",
    "decimals": 18,
    "image": "https://rango.vip/i/hjicR5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2383.16,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MB4",
    "name": "Matthew Box 404",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4536819095a8969c94362ba130ee0bb1cda714cb",
    "decimals": 18,
    "image": "https://rango.vip/i/yZW5mK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "mCAKE",
    "name": "mCake Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x581fa684d0ec11ccb46b1d92f1f24c8a3f95c0ca",
    "decimals": 18,
    "image": "https://rango.vip/i/Q6EeFr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "Metis",
    "name": "Metis Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe552fb52a4f19e44ef5a967632dbc320b0820639",
    "decimals": 18,
    "image": "https://rango.vip/i/MxD5pK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 29.458934099347704,
    "supportedSwappers": [
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "mwBETH",
    "name": "Bridged mwBETH",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7dc91cbd6cb5a3e6a95eed713aa6bf1d987146c8",
    "decimals": 18,
    "image": "https://rango.vip/i/HeLVyY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ordi",
    "name": "ordi",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe2ae1a99bbd2efab0a5c38f2146b7ace61abc5ce",
    "decimals": 18,
    "image": "https://rango.vip/i/ZcXt2y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 28.602297199731254,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "pxETH",
    "name": "Pirex Ether OFT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x300d2c875c6fb8ce4bf5480b4d34b7c9ea8a33a4",
    "decimals": 18,
    "image": "https://rango.vip/i/AyBENZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2338.2,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "rBNB",
    "name": "StaFi rBNB",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf027e525d491ef6ffcc478555fbb3cfabb3406a6",
    "decimals": 18,
    "image": "https://rango.vip/i/jChkU1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 620.42,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RDP",
    "name": "Radpie",
    "isPopular": false,
    "chainId": "56",
    "address": "0x27c073e8427aa493a90b8dc8b73a89e670fd77bb",
    "decimals": 18,
    "image": "https://rango.vip/i/sjcQB1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.161649,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "sats",
    "name": "sats",
    "isPopular": false,
    "chainId": "56",
    "address": "0x143d7a700a533b4baf6d693449b278a8a2f5927d",
    "decimals": 18,
    "image": "https://rango.vip/i/irgcHN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.013050227258105e-7,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SolvBTC",
    "name": "Solv BTC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4aae823a6a0b376de6a78e74ecc5b079d38cbcf7",
    "decimals": 18,
    "image": "https://rango.vip/i/fhbWzz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 55593,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SolvBTC.ENA",
    "name": "SolvBTC Ethena",
    "isPopular": false,
    "chainId": "56",
    "address": "0x53e63a31fd1077f949204b94f431bcab98f72bce",
    "decimals": 18,
    "image": "https://rango.vip/i/JSUrrp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 55803,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "stkBNB",
    "name": "Staked BNB",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc2e9d07f66a89c44062459a47a0d2dc038e4fb16",
    "decimals": 18,
    "image": "https://rango.vip/i/M1Uy4G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 526.51,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "STONE",
    "name": "StakeStone Ether",
    "isPopular": false,
    "chainId": "56",
    "address": "0x80137510979822322193fc997d400d5a6c747bf7",
    "decimals": 18,
    "image": "https://rango.vip/i/BzTU3n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2401.12,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "unshETH",
    "name": "unshETH Ether",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0ae38f7e10a43b5b2fb064b42a2f4514cba909ef",
    "decimals": 18,
    "image": "https://rango.vip/i/Nhm1wd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2501.89,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "wBETH",
    "name": "Wrapped Binance Beacon ETH",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa2e3356610840701bdf5611a53974510ae27e2e1",
    "decimals": 18,
    "image": "https://rango.vip/i/73QLDM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2453.79,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "wstETH",
    "name": "Wrapped liquid staked Ether 2.0",
    "isPopular": false,
    "chainId": "56",
    "address": "0x26c5e01524d2e6280a48f2c50ff6de7e52e9611c",
    "decimals": 18,
    "image": "https://rango.vip/i/EIs0zp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2779.2153173899433,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "xALGO",
    "name": "Governance xAlgo",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0034719300501b06e10ebb1d07ea5967301f0941",
    "decimals": 6,
    "image": "https://rango.vip/i/RjY7S4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.339569,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ZBC",
    "name": "ZEBEC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x37a56cdcd83dce2868f721de58cb3830c44c6303",
    "decimals": 9,
    "image": "https://rango.vip/i/C0M58E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00470797,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDCso",
    "name": "USDCso (wormhole)",
    "isPopular": false,
    "chainId": "56",
    "address": "0x91ca579b0d47e5cfd5d0862c21d5659d39c8ecf0",
    "decimals": 6,
    "image": "https://rango.vip/i/M4gWuP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "BSCPancakeV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "XNF",
    "name": "XNF",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc84fae1141b92fa5bf847276828f69caf651cb7f",
    "decimals": 18,
    "image": "https://rango.vip/i/IsDpUM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.083445,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "UND",
    "name": "Unstoppable Ecosystem Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb5a628803ee72d82098d4bcaf29a42e63531b441",
    "decimals": 18,
    "image": "https://rango.vip/i/MYEyni",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01583171,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PINGU",
    "name": "Pingu Exchange",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x83e60b9f7f4db5cdb0877659b1740e73c662c55b",
    "decimals": 18,
    "image": "https://rango.vip/i/nXuL8w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02593454,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LOTUS",
    "name": "White Lotus",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xef261714f7e5ba6b86f4780eb6e3bf26b10729cf",
    "decimals": 18,
    "image": "https://rango.vip/i/hVDL4U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.589306,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FUC",
    "name": "Funny Coin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1f52145666c862ed3e2f1da213d479e61b2892af",
    "decimals": 18,
    "image": "https://rango.vip/i/19sSrQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00003262,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LTM04",
    "name": "LumiTerra Totem 404",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa6ef0ad746d1c35d6ff4d66ceeae0e596d742924",
    "decimals": 18,
    "image": "https://rango.vip/i/pxXeOx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LUDAMOON",
    "name": "Lumi to da moon",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x292975973200064b1c6453505aeac5be697f5233",
    "decimals": 18,
    "image": "https://rango.vip/i/OKY3Fp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LUMIN",
    "name": "Lumin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1fc01117e196800f416a577350cb1938d10501c2",
    "decimals": 12,
    "image": "https://rango.vip/i/6PbItw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.092704,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KEI",
    "name": "KEI Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa06d505ec28fd9756a200d2b503a66103db98d09",
    "decimals": 18,
    "image": "https://rango.vip/i/soT1h3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01712936,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BONER",
    "name": "BONER",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf9ca0ec182a94f6231df9b14bd147ef7fb9fa17c",
    "decimals": 18,
    "image": "https://rango.vip/i/jeUPOG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00001373,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "plsARB",
    "name": "Plutus ARB",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7a5d193fe4ed9098f7eadc99797087c96b002907",
    "decimals": 18,
    "image": "https://rango.vip/i/P34geS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.493699,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BRO",
    "name": "Brokkr",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2b45e21c35a33c58e4c5ce82a82466b0754fd154",
    "decimals": 18,
    "image": "https://rango.vip/i/iRO4AY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00197023,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MYRC",
    "name": "Blox MYRC",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3ed03e95dd894235090b3d4a49e0c3239edce59e",
    "decimals": 18,
    "image": "https://rango.vip/i/VDgSpJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.229035,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MARK",
    "name": "Mchain Network",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4d01397994aa636bdcc65c9e8024bc497498c3bb",
    "decimals": 18,
    "image": "https://rango.vip/i/dKHN0R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00124484,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CRC",
    "name": "CrazyRabbit",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe4177c1400a8eee1799835dcde2489c6f0d5d616",
    "decimals": 18,
    "image": "https://rango.vip/i/sqxYeE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.139877,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "POION",
    "name": "Poison Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x31c91d8fb96bff40955dd2dbc909b36e8b104dde",
    "decimals": 18,
    "image": "https://rango.vip/i/tQxyx6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02471957,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TORQ",
    "name": "Torque",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb56c29413af8778977093b9b4947efeea7136c36",
    "decimals": 18,
    "image": "https://rango.vip/i/BtzcCA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 4.40842e-7,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ROSNET",
    "name": "Rosnet",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd79bb960dc8a206806c3a428b31bca49934d18d7",
    "decimals": 9,
    "image": "https://rango.vip/i/JLSj2c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01599792,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LILAI",
    "name": "LilAI",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x655a6beebf2361a19549a99486ff65f709bd2646",
    "decimals": 18,
    "image": "https://rango.vip/i/Z8zxet",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00200012,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "rsETH",
    "name": "KelpDao Restaked ETH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x4186bfc76e2e237523cbc30fd220fe055156b41f",
    "decimals": 18,
    "image": "https://rango.vip/i/JsCOcn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2396.46,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GHA",
    "name": "Ghast",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xeca66820ed807c096e1bd7a1a091cd3d3152cc79",
    "decimals": 18,
    "image": "https://rango.vip/i/vM46dW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2.47,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BONSAI",
    "name": "Bonsai",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x79ead7a012d97ed8deece279f9bc39e264d7eef9",
    "decimals": 18,
    "image": "https://rango.vip/i/5tvaBk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.222838,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GOA",
    "name": "Goat Protocol",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8c6bd546fb8b53fe371654a0e54d7a5bd484b319",
    "decimals": 18,
    "image": "https://rango.vip/i/IpZLk0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.72,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KORA",
    "name": "Kortana",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x50e401255275dc405a99d3281f396cca681eea9d",
    "decimals": 18,
    "image": "https://rango.vip/i/wdJrLx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00018097,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CIP",
    "name": "Crypto Index Pool",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd7a892f28dedc74e6b7b33f93be08abfc394a360",
    "decimals": 18,
    "image": "https://rango.vip/i/NCxCny",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0032832,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "POGAI",
    "name": "POGAI",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6fd58f5a2f3468e35feb098b5f59f04157002407",
    "decimals": 18,
    "image": "https://rango.vip/i/muqvne",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00002068,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "OD",
    "name": "Open Dollar",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x221a0f68770658c15b525d0f89f5da2baab5f321",
    "decimals": 18,
    "image": "https://rango.vip/i/W5YOCR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.992957,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "VKA",
    "name": "Vaultka",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xafccb724e3aec1657fc9514e3e53a0e71e80622d",
    "decimals": 18,
    "image": "https://rango.vip/i/Xu6GDl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.04121959,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TIA.n",
    "name": "TIA",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xd56734d7f9979dd94fae3d67c7e928234e71cd4c",
    "decimals": 6,
    "image": "https://rango.vip/i/nVNLlo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 4.01,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DLCBTC",
    "name": "dlcBTC",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x050c24dbf1eec17babe5fc585f06116a259cc77a",
    "decimals": 8,
    "image": "https://rango.vip/i/xI7G8E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 55598,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NUMA",
    "name": "NUMA",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7fb7ede54259cb3d4e1eaf230c7e2b1ffc951e9a",
    "decimals": 18,
    "image": "https://rango.vip/i/FZC63z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.335069,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ABCRAM",
    "name": "Abacus",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9efcfc5b49390fc3fb9b58607d2e89445bb380bf",
    "decimals": 18,
    "image": "https://rango.vip/i/vLEZIH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01501056,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KNOW",
    "name": "The Knowers",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6b5b5eac259e883b484ed879d43dd4d616a90e65",
    "decimals": 18,
    "image": "https://rango.vip/i/9Y2RLw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00044562,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "USD24",
    "name": "Fiat24 USD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xbe00f3db78688d9704bcb4e0a827aea3a9cc0d62",
    "decimals": 2,
    "image": "https://rango.vip/i/fQysuf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.994533,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NYA",
    "name": "Nya",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x38f9bf9dce51833ec7f03c9dc218197999999999",
    "decimals": 18,
    "image": "https://rango.vip/i/fa0H9Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 3.76977e-7,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SCHRODI",
    "name": "Schrodi",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x35ca1e5a9b1c09fa542fa18d1ba4d61c8edff852",
    "decimals": 18,
    "image": "https://rango.vip/i/saD89x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00235275,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DZHV",
    "name": "DizzyHavoc",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x3419875b4d3bca7f3fdda2db7a476a79fd31b4fe",
    "decimals": 18,
    "image": "https://rango.vip/i/Ifd7rR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00191168,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "HYETH",
    "name": "High Yield ETH Index",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8b5d1d8b3466ec21f8ee33ce63f319642c026142",
    "decimals": 18,
    "image": "https://rango.vip/i/iHtYRm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2798.7,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "tBTC",
    "name": "Arbitrum tBTC v2",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6c84a8f1c29108f47a79964b5fe888d4f4d0de40",
    "decimals": 18,
    "image": "https://rango.vip/i/jZuARE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 55766,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FU",
    "name": "FU Money",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9aee3c99934c88832399d6c6e08ad802112ebeab",
    "decimals": 18,
    "image": "https://rango.vip/i/LbkC39",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.01838376,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LDY",
    "name": "Ledgity Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x999faf0af2ff109938eefe6a7bf91ca56f0d07e1",
    "decimals": 18,
    "image": "https://rango.vip/i/kRK9ht",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.02570382,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "EUSD",
    "name": "Electronic USD",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x12275dcb9048680c4be40942ea4d92c74c63b844",
    "decimals": 18,
    "image": "https://rango.vip/i/ImoXow",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.999013,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "O404",
    "name": "OMNI404",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb5c457ddb4ce3312a6c5a2b056a1652bd542a208",
    "decimals": 18,
    "image": "https://rango.vip/i/Mso5fh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 725.87,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "GENSX",
    "name": "Genius X",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf29fdf6b7bdffb025d7e6dfdf344992d2d16e249",
    "decimals": 6,
    "image": "https://rango.vip/i/8JhURy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00038158,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CFSH",
    "name": "ChessFish",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc218a06a17feb66b1a730889fb9624326fabff4b",
    "decimals": 18,
    "image": "https://rango.vip/i/Bcmzou",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.35,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "IBEX",
    "name": "Impermax",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x56659245931cb6920e39c189d2a0e7dd0da2d57b",
    "decimals": 18,
    "image": "https://rango.vip/i/FWRuJF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.055798,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CBETH",
    "name": "Coinbase Wrapped Staked ETH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1debd73e752beaf79865fd6446b0c970eae7732f",
    "decimals": 18,
    "image": "https://rango.vip/i/b0XFbl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2528.63,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NSWAP",
    "name": "Nulswap",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xfdad8edc724277e975f4955d288c6eb5b20a3146",
    "decimals": 8,
    "image": "https://rango.vip/i/GlXQRP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00596086,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "XBG",
    "name": "XBorg",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x93fa0b88c0c78e45980fa74cdd87469311b7b3e4",
    "decimals": 18,
    "image": "https://rango.vip/i/A1NnJo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.063701,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ADAI",
    "name": "Aave v3 DAI",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x82e64f49ed5ec1bc6e43dad4fc8af9bb3a2312ee",
    "decimals": 18,
    "image": "https://rango.vip/i/3kjYKS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.999938,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CNFI",
    "name": "Connect Financial",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x6f5401c53e2769c858665621d22ddbf53d8d27c5",
    "decimals": 18,
    "image": "https://rango.vip/i/kHnVbl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.053391,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ETH+",
    "name": "ETHPlus",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x18c14c2d707b2212e17d1579789fc06010cfca23",
    "decimals": 18,
    "image": "https://rango.vip/i/YqX1zC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2388.12,
    "supportedSwappers": [
      "ArbitrumUniSwapV3",
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WXM",
    "name": "WeatherXM",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb6093b61544572ab42a0e43af08abafd41bf25a6",
    "decimals": 18,
    "image": "https://rango.vip/i/WsFpMZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.405308,
    "supportedSwappers": [
      "ArbitrumUniSwapV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "CHAR",
    "name": "Biochar",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x50e85c754929840b58614f48e29c64bc78c58345",
    "decimals": 18,
    "image": "https://rango.vip/i/TCrVYp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 154.63,
    "supportedSwappers": [
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "cETH",
    "name": "wrapped.com ETH",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x2def4285787d58a2f811af24755a8150622f4361",
    "decimals": 18,
    "image": "https://rango.vip/i/fqtMjM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 1891.02,
    "supportedSwappers": [
      "UbeSwap",
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "cUSD",
    "name": "Celo Dollar",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x765de816845861e75a25fca122bb6898b8b1282a",
    "decimals": 18,
    "image": "https://rango.vip/i/DO3Mrm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 1.003,
    "supportedSwappers": [
      "UbeSwap",
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "cEUR",
    "name": "Celo Euro",
    "isPopular": false,
    "chainId": "42220",
    "address": "0xd8763cba276a3738e6de85b4b3bf5fded6d6ca73",
    "decimals": 18,
    "image": "https://rango.vip/i/6QrXPX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "UbeSwap",
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "cREAL",
    "name": "Celo Real",
    "isPopular": false,
    "chainId": "42220",
    "address": "0xe8537a3d056da446677b9e9d6c5db704eaab4787",
    "decimals": 18,
    "image": "https://rango.vip/i/HYs2lM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.178663,
    "supportedSwappers": [
      "UbeSwap",
      "CeloUniswapV3"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "wstETH",
    "name": "Wrapped liquid staked Ether 2.0",
    "isPopular": false,
    "chainId": "59144",
    "address": "0xb5bedd42000b71fdde22d3ee8a79bd49a568fc8f",
    "decimals": 18,
    "image": "https://rango.vip/i/7ZaEG8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 2756.98,
    "supportedSwappers": [
      "LineaPancakeV3"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "ezETH",
    "name": "Renzo Restaked ETH",
    "isPopular": false,
    "chainId": "59144",
    "address": "0x2416092f143378750bb29b79ed961ab195cceea5",
    "decimals": 18,
    "image": "https://rango.vip/i/n1bKcj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 2383.16,
    "supportedSwappers": [
      "LineaPancakeV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "LUNE",
    "name": "Luneko",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xacd1caef47e4c47bafe8a51b3f4305fc38203b7a",
    "decimals": 18,
    "image": "https://rango.vip/i/PAEM04",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00160717,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BBOOK",
    "name": "BASE BOOK",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x1dedb41feb0e12637a820c22c1b739ca2e75b48c",
    "decimals": 18,
    "image": "https://rango.vip/i/aZyMT0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 7.59084e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "9-5",
    "name": "9to5",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xd727e37dccd5720d1e3849606d3ab669cb68c368",
    "decimals": 18,
    "image": "https://rango.vip/i/VZUOdD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000773,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "HYUSD",
    "name": "High Yield USD  Base ",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xcc7ff230365bd730ee4b352cc2492cedac49383e",
    "decimals": 18,
    "image": "https://rango.vip/i/mwUDdo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.05,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FLIES",
    "name": "XCOPYFLIES",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9d6b8b6fb293c757e05073b84a583ecfaef8d8a7",
    "decimals": 18,
    "image": "https://rango.vip/i/NCohNA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.52,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "LOWER",
    "name": "lower",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x67040bb0ad76236ddd5d156d23ec920a089d1eac",
    "decimals": 18,
    "image": "https://rango.vip/i/RFmNFq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00002841,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BENG",
    "name": "Based Peng",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x3e05d37cfbd8caaad9e3322d35cc727afaff63e3",
    "decimals": 18,
    "image": "https://rango.vip/i/M2hpkM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0000027,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PILL",
    "name": "Pill",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x388e543a5a491e7b42e3fbcd127dd6812ea02d0d",
    "decimals": 18,
    "image": "https://rango.vip/i/iAAd5a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00004829,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RAWR",
    "name": "Dino Poker",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x469fda1fb46fcb4befc0d8b994b516bd28c87003",
    "decimals": 18,
    "image": "https://rango.vip/i/mocblI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 10.03,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DAU",
    "name": "Daily Active Users",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x0753be703ab39a30c0041b7395351e9f65206500",
    "decimals": 18,
    "image": "https://rango.vip/i/bzaxqI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 43.47,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "VOID",
    "name": "The Void",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x21eceaf3bf88ef0797e3927d855ca5bb569a47fc",
    "decimals": 18,
    "image": "https://rango.vip/i/WwaeGN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.01203019,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MBDAO",
    "name": "Moonboots DAO",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x0dd7913197bfb6d2b1f03f9772ced06298f1a644",
    "decimals": 18,
    "image": "https://rango.vip/i/MLWWdp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.03978011,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MEMBER",
    "name": "member",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7d89e05c0b93b24b5cb23a073e60d008fed1acf9",
    "decimals": 18,
    "image": "https://rango.vip/i/z3z0M4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00003323,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FLIES",
    "name": "MUTATIO",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8b67f2e56139ca052a7ec49cbcd1aa9c83f2752a",
    "decimals": 18,
    "image": "https://rango.vip/i/usSL7W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.52,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TREE",
    "name": "/treeplanting",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6888c2409d48222e2cb738eb5a805a522a96ce80",
    "decimals": 18,
    "image": "https://rango.vip/i/FxGkOG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00001945,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "$blue",
    "name": "blue on base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfd9fa4f785331ce88b5af8994a047ba087c705d8",
    "decimals": 18,
    "image": "https://rango.vip/i/wzYyvh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.87682e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SACA",
    "name": "Sandwich Cat",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xed1978d01d4a8a9d6a43ac79403d5b8dfbed739b",
    "decimals": 18,
    "image": "https://rango.vip/i/3velP0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00004632,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TORCH",
    "name": "Torch",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9252384e10ba6beae1a52dbb2a3beca2592268d9",
    "decimals": 18,
    "image": "https://rango.vip/i/32Bdy6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00146652,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FRAME",
    "name": "Frame Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x91f45aa2bde7393e0af1cc674ffe75d746b93567",
    "decimals": 18,
    "image": "https://rango.vip/i/FbSMvT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 9.4088e-8,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DUH",
    "name": "Duh",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8011eef8fc855df1c4f421443f306e19818e60d3",
    "decimals": 18,
    "image": "https://rango.vip/i/nszyCG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00008719,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "INT",
    "name": "Internet Token",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x968d6a288d7b024d5012c0b25d67a889e4e3ec19",
    "decimals": 18,
    "image": "https://rango.vip/i/yGgI2V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00352822,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RDAT",
    "name": "r DataDAO",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x4498cd8ba045e00673402353f5a4347562707e7d",
    "decimals": 18,
    "image": "https://rango.vip/i/FgUdmI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.01604918,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PURP",
    "name": "Purp",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x1b1514c76c54ce8807d7fdedf85c664eee734ece",
    "decimals": 18,
    "image": "https://rango.vip/i/S9zuY4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00006151,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AIINU",
    "name": "AI INU",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8853f0c059c27527d33d02378e5e4f6d5afb574a",
    "decimals": 18,
    "image": "https://rango.vip/i/Jpddw5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00502057,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BABYBRETT",
    "name": "Baby Brett",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x52de0096836bada37c83d6648e0615c40f2d9e51",
    "decimals": 18,
    "image": "https://rango.vip/i/g5BMEU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 6.219e-9,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WEETHBASE",
    "name": "ether fi Bridged weETH  Base ",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x04c0599ae5a44757c0af6f9ec3b93da8976c150a",
    "decimals": 18,
    "image": "https://rango.vip/i/9FS75J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2455.24,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "NOJEET",
    "name": "NOJEET",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xa68482c74367ff7e7d8aed23553e4c7c2af231f1",
    "decimals": 18,
    "image": "https://rango.vip/i/TFqvy5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00048234,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SOAP",
    "name": "BASED SBF WIF SOAP",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x4b1265ef6c7500983acaf99cb7a94bbf0dd1cca4",
    "decimals": 18,
    "image": "https://rango.vip/i/CjIfVp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000657,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RFND",
    "name": "Refund  Base ",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x26fb8f2f3b26c750ee34005c1930deb232940cfe",
    "decimals": 18,
    "image": "https://rango.vip/i/gD8GnI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.08344e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "POON",
    "name": "POON Coin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6e93dede32b00fc0b993cee3be8727089fdd013b",
    "decimals": 18,
    "image": "https://rango.vip/i/jZbKX1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00010999,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AYB",
    "name": "All Your Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7ed613ab8b2b4c6a781ddc97ea98a666c6437511",
    "decimals": 18,
    "image": "https://rango.vip/i/eiWzuv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 3.31459e-7,
    "supportedSwappers": [
      "SushiBase",
      "AlienBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "HPC",
    "name": "Happy Puppy Club",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x1f3ba804efb9cfe17d595e7262cea4782dbf6e4e",
    "decimals": 18,
    "image": "https://rango.vip/i/vsZPeu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00013822,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "NFI",
    "name": "NetherFi",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x60359a0dd148b18d5cf1ddf8aa1916221ed0cbcd",
    "decimals": 18,
    "image": "https://rango.vip/i/lSRWNy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00067546,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "WOMPWOMP",
    "name": "sad trombone",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xf4ffe72af996335e0140c06f23fc6b69e257deec",
    "decimals": 18,
    "image": "https://rango.vip/i/Z9CrNY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000658,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ONCHAIN",
    "name": " onchain",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfef2d7b013b88fec2bfe4d2fee0aeb719af73481",
    "decimals": 18,
    "image": "https://rango.vip/i/Kmq8or",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.0006493,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DRONE",
    "name": "Drone",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfa1f6e048e66ac240a4bb7eab7ee888e76081a6c",
    "decimals": 18,
    "image": "https://rango.vip/i/k3X45U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000678,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "LTAI",
    "name": "LibertAI",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xf8b1b47aa748f5c7b5d0e80c726a843913eb573a",
    "decimals": 18,
    "image": "https://rango.vip/i/e8Dprw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.391237,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BUZ",
    "name": "Buz Economy",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xc73dc7ae7a4fa40517aafa941ae1ee436b91a12c",
    "decimals": 4,
    "image": "https://rango.vip/i/dOJFqu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.26959,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "PBB",
    "name": "Pepe But Blue",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9a3b7959e998bf2b50ef1969067d623877050d92",
    "decimals": 18,
    "image": "https://rango.vip/i/y0W2aI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00002913,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "$poop",
    "name": "poopcoin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x686b1209b2de12818aa69dd139530448d0c792b3",
    "decimals": 18,
    "image": "https://rango.vip/i/EhVNK0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00014984,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BIRDN",
    "name": "bird n",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x46a8615b7b75579504f465712913c190ce7bd12c",
    "decimals": 18,
    "image": "https://rango.vip/i/U6ptNi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000936,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DUDEGEN",
    "name": "DUDEGEN",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xcc6ce98579ba909344bb765f0c4f45964d5ce1d2",
    "decimals": 18,
    "image": "https://rango.vip/i/crF8Hd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00001107,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "GLOOM",
    "name": "Gloom",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xbb5d04c40fa063faf213c4e0b8086655164269ef",
    "decimals": 18,
    "image": "https://rango.vip/i/IUVQw7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00003669,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BROGE",
    "name": "Broge",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xe8e55a847bb446d967ef92f4580162fb8f2d3f38",
    "decimals": 18,
    "image": "https://rango.vip/i/bkmxx4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 9.582e-11,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ELONRWA",
    "name": "ElonRWA",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xaa6cccdce193698d33deb9ffd4be74eaa74c4898",
    "decimals": 18,
    "image": "https://rango.vip/i/wqWsQT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00004773,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MEELAY",
    "name": "Javor Meelay",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x81a3e86b1c54a29fa83c92bfcaabddfefd4f3d90",
    "decimals": 18,
    "image": "https://rango.vip/i/BPtGJc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000285,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RISITA",
    "name": "Risitas",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xe6f47303032a09c8c0f8ebb713c00e6ed345e8c3",
    "decimals": 18,
    "image": "https://rango.vip/i/AIWibY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00001802,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "STREET",
    "name": "Base Street",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xb661933d9f24ec34dc0fd6862f086079f864b26f",
    "decimals": 18,
    "image": "https://rango.vip/i/veM2wZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00001064,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SENDIT",
    "name": "Sendit",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xba5b9b2d2d06a9021eb3190ea5fb0e02160839a4",
    "decimals": 18,
    "image": "https://rango.vip/i/db16Rl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00001852,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BOOL",
    "name": "Bool",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x5698bba26d8668d781cdcfd2d6d6881226dd0f52",
    "decimals": 18,
    "image": "https://rango.vip/i/fOSjSl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00113561,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FLOWER",
    "name": "Farcaster Flower",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xb043bad01195700e737d0aee852584eae9393134",
    "decimals": 18,
    "image": "https://rango.vip/i/rfv3yL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000971,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "YOYO",
    "name": "Yoyo",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xe31876c6a62a813f57b815d8d2d0f5c8aa06f49b",
    "decimals": 18,
    "image": "https://rango.vip/i/Tz9YRy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00001876,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BELL",
    "name": "Bell Curve Money",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x4a4200947ad71ba4eb5ffeff10cc603a17af2f95",
    "decimals": 18,
    "image": "https://rango.vip/i/1fsdd5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 3.0037e-8,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DUNE",
    "name": "DUNE404",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xbdedeab2318e5c6997e60e743ae11df37481c747",
    "decimals": 18,
    "image": "https://rango.vip/i/vIggno",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 8.59,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ROR",
    "name": "Rorschach",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9e13480a81af1dea2f255761810ef8d6cbf21735",
    "decimals": 18,
    "image": "https://rango.vip/i/raTN0u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000798,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "YIN",
    "name": "Flux YIN",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xecb36ff12cbe4710e9be2411de46e6c180a4807f",
    "decimals": 8,
    "image": "https://rango.vip/i/oT0pja",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.075825,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "UNLUCKY",
    "name": "UNLUCKY",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x3933012dcf9beb0d63778725345e04dcc0c69c7e",
    "decimals": 0,
    "image": "https://rango.vip/i/It5LbY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 5.98954e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MOONCATS",
    "name": "Mooncats on Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xc5396321805c7f1bce608cda194aa9155fb20f7d",
    "decimals": 18,
    "image": "https://rango.vip/i/LaH4qS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00029532,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BLEU",
    "name": "Le Bleu Elefant",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xbf4db8b7a679f89ef38125d5f84dd1446af2ea3b",
    "decimals": 18,
    "image": "https://rango.vip/i/FFbSTB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00008434,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "ESAB",
    "name": "ESAB",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6fd31533621452aac187c9cbdfdfb6ef50d28149",
    "decimals": 18,
    "image": "https://rango.vip/i/IiLCZS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.04142745,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CHAR",
    "name": "Biochar",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x20b048fa035d5763685d695e66adf62c5d9f5055",
    "decimals": 18,
    "image": "https://rango.vip/i/7FlzYg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 154.63,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BTAMA",
    "name": "Basetama",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xfa7f2e933c09cd113ef6e72b76d61fb4b27496a1",
    "decimals": 18,
    "image": "https://rango.vip/i/lsRK83",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00136075,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TRUFFI",
    "name": "Truffi",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x2496a9af81a87ed0b17f6edeaf4ac57671d24f38",
    "decimals": 9,
    "image": "https://rango.vip/i/CE93iY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00489865,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "SIAM",
    "name": "Siamese",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xba71cb8ef2d59de7399745793657838829e0b147",
    "decimals": 18,
    "image": "https://rango.vip/i/RbC4AK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.222e-9,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FARTHER",
    "name": "Farther",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x8ad5b9007556749de59e088c88801a3aaa87134b",
    "decimals": 18,
    "image": "https://rango.vip/i/yIErie",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00002443,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "GIF",
    "name": "goatwifhat",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x6b82297c6f1f9c3b1f501450d2ee7c37667ab70d",
    "decimals": 18,
    "image": "https://rango.vip/i/yMlm5X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00009864,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "Crash",
    "name": "Crash",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x4dd9077269dd08899f2a9e73507125962b5bc87f",
    "decimals": 18,
    "image": "https://rango.vip/i/6ipti2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00323184,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MONKY",
    "name": "Monky",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9124577428c5bd73ad7636cbc5014081384f29d6",
    "decimals": 18,
    "image": "https://rango.vip/i/hCMWYh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00000108,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "RIKU",
    "name": "RIKU",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xd1412d909f67b8db7505ddfcf26cf2303f4b1bb4",
    "decimals": 18,
    "image": "https://rango.vip/i/hB5jDs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.05222e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "NORMILIO",
    "name": "Normilio",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xcde90558fc317c69580deeaf3efc509428df9080",
    "decimals": 18,
    "image": "https://rango.vip/i/UOylHl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00030502,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "LOUDER",
    "name": "LOUDER",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x120edc8e391ba4c94cb98bb65d8856ae6ec1525f",
    "decimals": 18,
    "image": "https://rango.vip/i/J1u4a4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00124734,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TN100X",
    "name": "TN100x",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x5b5dee44552546ecea05edea01dcd7be7aa6144a",
    "decimals": 18,
    "image": "https://rango.vip/i/AhY5Nx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00023951,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "toby",
    "name": "toby",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xb8d98a102b0079b69ffbc760c8d857a31653e56e",
    "decimals": 18,
    "image": "https://rango.vip/i/kxPNbm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 1.1317e-8,
    "supportedSwappers": [
      "SushiBase",
      "OneInchBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "MFER",
    "name": "mfercoin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xe3086852a4b125803c815a158249ae468a3254ca",
    "decimals": 18,
    "image": "https://rango.vip/i/XQMeRh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00867771,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "Mog",
    "name": "Mog Coin",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x2da56acb9ea78330f947bd57c54119debda7af71",
    "decimals": 18,
    "image": "https://rango.vip/i/GDaRKR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 7.56208e-7,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CBETH",
    "name": "Coinbase Wrapped Staked ETH",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22",
    "decimals": 18,
    "image": "https://rango.vip/i/qU813n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 2528.63,
    "supportedSwappers": [
      "BaseCurveFinance",
      "OneInchBase",
      "AlienBase",
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "EUSD",
    "name": "Electronic USD",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xcfa3ef56d303ae4faaba0592388f19d7c3399fb4",
    "decimals": 18,
    "image": "https://rango.vip/i/ze4kVp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.999013,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DZHV",
    "name": "DizzyHavoc",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x3419875b4d3bca7f3fdda2db7a476a79fd31b4fe",
    "decimals": 18,
    "image": "https://rango.vip/i/Ifd7rR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00191168,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "TREE",
    "name": "Tree",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x52c2b317eb0bb61e650683d2f287f56c413e4cf6",
    "decimals": 18,
    "image": "https://rango.vip/i/fiJ6az",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.139546,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "UNV",
    "name": "Unvest",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x51707dc661630f8fd624b985fa6ef4f1d4d919db",
    "decimals": 18,
    "image": "https://rango.vip/i/oupC9B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00112691,
    "supportedSwappers": [
      "BaseUniSwapV3"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "WYND",
    "name": "WYND",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1mkw83sv6c7sjdvsaplrzc8yaes9l42p4mhy0ssuxjnyzl87c9eps7ce3m9",
    "decimals": 6,
    "image": "https://rango.vip/i/imUx7K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 0.00210685501530112,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "WYJUNO",
    "name": "wyJUNO",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1naunqzk6jseqeqhq43nm6kdneraws2rkmteprjzppw6j9xcrurxqx0ld9e",
    "decimals": 6,
    "image": "https://rango.vip/i/XKtoEt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "NETA",
    "name": "NETA",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno168ctmpyppk90d34p3jjy658zf5a5l3w8wk35wht6ccqj4mr0yv8s4j5awr",
    "decimals": 6,
    "image": "https://rango.vip/i/j7rzxd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 7.723928266748851,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "PHMN",
    "name": "POSTHUMAN",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1rws84uz7969aaa7pej303udhlkt3j9ca0l3egpcae98jwak9quzq8szn2l",
    "decimals": 6,
    "image": "https://rango.vip/i/BihoyI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 8.678949612510007,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "SEJUNO",
    "name": "StakeEasy seJUNO",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1dd0k0um5rqncfueza62w9sentdfh3ec4nw4aq4lk5hkjl63vljqscth9gv",
    "decimals": 6,
    "image": "https://rango.vip/i/eR3Ewh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "GLTO",
    "name": "Gelotto",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1j0a9ymgngasfn3l5me8qpd53l5zlm9wurfdk7r65s5mg6tkxal3qpgf5se",
    "decimals": 6,
    "image": "https://rango.vip/i/BHZQWV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 0.000418784602196992,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "BJUNO",
    "name": "bJuno",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1wwnhkagvcd3tjz6f8vsdsw5plqnw8qy2aj3rrhqr2axvktzv9q2qz8jxn3",
    "decimals": 6,
    "image": "https://rango.vip/i/mnV5Vx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "CZAR",
    "name": "CZAR",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1x02k67asfmjawgc96dj8nxq6se3fmx36gedgs5hvkjegdhfy97rs3jgj2h",
    "decimals": 6,
    "image": "https://rango.vip/i/0XA4dR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "CANLAB",
    "name": "CANLAB",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1vn38rzq0wc7zczp4dhy0h5y5kxh2jjzeahwe30c9cc6dw3lkyk5qn5rmfa",
    "decimals": 3,
    "image": "https://rango.vip/i/rr1W0i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "SEASY",
    "name": "StakeEasy",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno19rqljkh95gh40s7qdx40ksx3zq5tm4qsmsrdz9smw668x9zdr3lqtg33mf",
    "decimals": 6,
    "image": "https://rango.vip/i/9AsMw4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "RAC",
    "name": "RAC",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1r4pzw8f9z0sypct5l9j906d47z998ulwvhvqe5xdwgy8wf84583sxwh0pa",
    "decimals": 6,
    "image": "https://rango.vip/i/DfAXzn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "SGNL",
    "name": "Signal",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno14lycavan8gvpjn97aapzvwmsj8kyrvf644p05r0hu79namyj3ens87650k",
    "decimals": 6,
    "image": "https://rango.vip/i/mkLCdp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "AMPJUNO",
    "name": "ampJuno",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1a0khag6cfzu5lrwazmyndjgvlsuk7g4vn9jd8ceym8f4jf6v2l9q6d348a",
    "decimals": 6,
    "image": "https://rango.vip/i/644jdL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "CASA",
    "name": "CASA",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1ju8k8sqwsqu5k6umrypmtyqu2wqcpnrkf4w4mntvl0javt4nma7s8lzgss",
    "decimals": 6,
    "image": "https://rango.vip/i/hDhu5q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "HOWL",
    "name": "HOWL",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno1g0wuyu2f49ncf94r65278puxzclf5arse9f3kvffxyv4se4vgdmsk4dvqz",
    "decimals": 6,
    "image": "https://rango.vip/i/XFTXVz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "JUNO",
    "symbol": "KLEO",
    "name": "Kleomedes",
    "isPopular": false,
    "chainId": "juno-1",
    "address": "juno10gthz5ufgrpuk5cscve2f0hjp56wgp90psqxcrqlg4m9mcu9dh8q4864xy",
    "decimals": 6,
    "image": "https://rango.vip/i/DAG4MZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/JUNO/icon.svg",
    "usdPrice": 0.0014376372545825311,
    "supportedSwappers": [
      "WYNDDex"
    ]
  },
  {
    "blockchain": "BOBA",
    "symbol": "BANA",
    "name": "Banana",
    "isPopular": false,
    "chainId": "288",
    "address": "0xc67b9b1b0557aeafa10aa1ffa1d7c87087a6149e",
    "decimals": 18,
    "image": "https://rango.vip/i/gyHe7C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BOBA/icon.svg",
    "usdPrice": 0.004087243625272972,
    "supportedSwappers": [
      "OolongSwap"
    ]
  },
  {
    "blockchain": "BOBA",
    "symbol": "BDOGE",
    "name": "BobaDoge",
    "isPopular": false,
    "chainId": "288",
    "address": "0x121636c43e96d97ab00b6c6994cddebef27de1c7",
    "decimals": 18,
    "image": "https://rango.vip/i/U6UPsb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BOBA/icon.svg",
    "usdPrice": 1.8807820927939884e-9,
    "supportedSwappers": [
      "OolongSwap"
    ]
  },
  {
    "blockchain": "BOBA",
    "symbol": "BTEA",
    "name": "BobaTeaInu",
    "isPopular": false,
    "chainId": "288",
    "address": "0x9c3a582a678be0b0e954d7a5c07b0c7eb8ef1e03",
    "decimals": 9,
    "image": "https://rango.vip/i/KqAeTu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BOBA/icon.svg",
    "usdPrice": 3.1323720213207874e-8,
    "supportedSwappers": [
      "OolongSwap"
    ]
  },
  {
    "blockchain": "BOBA",
    "symbol": "SHIBUI",
    "name": "Shibui",
    "isPopular": false,
    "chainId": "288",
    "address": "0xf08ad7c3f6b1c6843ba027ad54ed8ddb6d71169b",
    "decimals": 18,
    "image": "https://rango.vip/i/Mwv1h9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BOBA/icon.svg",
    "usdPrice": 0.005943547470447662,
    "supportedSwappers": [
      "OolongSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "XCAUSD",
    "name": "Acala Dollar",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xffffffff52c56a9257bb97f4b2b6f7b2d624ecda",
    "decimals": 12,
    "image": "https://rango.vip/i/k6Samc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.299826,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "XCIBTC",
    "name": "InterBTC",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xffffffff5ac1f9a51a93f5c527385edf7fe98a52",
    "decimals": 8,
    "image": "https://rango.vip/i/GgqPCv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 58008,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "XCINTR",
    "name": "Interlay",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xffffffff4c1cbcd97597339702436d4f18a375ab",
    "decimals": 10,
    "image": "https://rango.vip/i/vCWzWp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.1546846602838312,
    "supportedSwappers": [
      "StellaSwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "ETHK",
    "name": "ETHK",
    "isPopular": true,
    "chainId": "66",
    "address": "0xef71ca2ee68f45b9ad6f72fbdb33d707b872315c",
    "decimals": 18,
    "image": "https://rango.vip/i/mOsC1S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 2504.6320387011624,
    "supportedSwappers": [
      "CherrySwap",
      "OkcSwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "DOTK",
    "name": "DOTK",
    "isPopular": false,
    "chainId": "66",
    "address": "0xabc732f6f69a519f6d508434481376b6221eb7d5",
    "decimals": 18,
    "image": "https://rango.vip/i/6VYDbj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 7.347740136071902,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "FILK",
    "name": "FILK",
    "isPopular": false,
    "chainId": "66",
    "address": "0x3f8969be2fc0770dcc174968e4b4ff146e0acdaf",
    "decimals": 18,
    "image": "https://rango.vip/i/2wspvV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 7.405604972117699,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "LTCK",
    "name": "LTCK",
    "isPopular": false,
    "chainId": "66",
    "address": "0xfa520efc34c81bfc1e3dd48b7fe9ff326049f986",
    "decimals": 18,
    "image": "https://rango.vip/i/qlqmOh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 94.85344445727446,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "LINKK",
    "name": "LINKK",
    "isPopular": false,
    "chainId": "66",
    "address": "0xbeb67de6cc5af652b2d9b0235750ed70f5a2cb0d",
    "decimals": 18,
    "image": "https://rango.vip/i/iOTa3M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 19.17461527295447,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "UNIK",
    "name": "UNIK",
    "isPopular": false,
    "chainId": "66",
    "address": "0x59d226bb0a4d74274d4354ebb6a0e1a1aa5175b6",
    "decimals": 18,
    "image": "https://rango.vip/i/yB5v8R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 10.678211009043668,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "ZKSK",
    "name": "ZKSK",
    "isPopular": false,
    "chainId": "66",
    "address": "0xcd08d321f6bc10a10f094e4b2e6c9b8bf9906401",
    "decimals": 18,
    "image": "https://rango.vip/i/dI7SFa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.006952272924942312,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "XRPK",
    "name": "XRPK",
    "isPopular": false,
    "chainId": "66",
    "address": "0xa715f624eb32c6c960b0e044b10cde585d5f5984",
    "decimals": 18,
    "image": "https://rango.vip/i/8b0ETa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.588661533968684,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "TRXK",
    "name": "TRXK",
    "isPopular": false,
    "chainId": "66",
    "address": "0x00505505a7576d6734704fabb16f41924e3e384b",
    "decimals": 18,
    "image": "https://rango.vip/i/btDT1T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.1399832000911228,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "BCHK",
    "name": "BCHK",
    "isPopular": false,
    "chainId": "66",
    "address": "0x18d103b7066aeedb6005b78a462ef9027329b1ea",
    "decimals": 18,
    "image": "https://rango.vip/i/OIiYxS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 622.1647018629255,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "ETCK",
    "name": "ETCK",
    "isPopular": false,
    "chainId": "66",
    "address": "0x99970778e2715bbc9cf8fb83d10dcbc2d2d551a3",
    "decimals": 18,
    "image": "https://rango.vip/i/yvGaCj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 32.64338507842626,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "BLADE",
    "name": "BLADE",
    "isPopular": false,
    "chainId": "66",
    "address": "0xa07403c1bd0c5cf53df07f15faa589241352527b",
    "decimals": 18,
    "image": "https://rango.vip/i/UAtvpL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.00029056561988726316,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "KOA",
    "name": "KOA",
    "isPopular": false,
    "chainId": "66",
    "address": "0x2168795125d44653e0b858b5f6dcc86d719b6a36",
    "decimals": 18,
    "image": "https://rango.vip/i/kFhyqo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.0006117384880580905,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "ACMD",
    "name": "ACMD",
    "isPopular": false,
    "chainId": "66",
    "address": "0xc95efaf132507d81805f8cfb90e4863939310105",
    "decimals": 18,
    "image": "https://rango.vip/i/gK38hd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 9.207422417489031e-7,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "OFI",
    "name": "OFI",
    "isPopular": false,
    "chainId": "66",
    "address": "0x2e2f982c3e66db8604b0a99862dd97d31ed740b3",
    "decimals": 18,
    "image": "https://rango.vip/i/96vm26",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.00041843196274264643,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "STK",
    "name": "STK",
    "isPopular": false,
    "chainId": "66",
    "address": "0x3e259bfe720093abb26a2c3fe57670259b2ebea2",
    "decimals": 12,
    "image": "https://rango.vip/i/gTErOf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.0014195415569910898,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "DMX",
    "name": "DMX",
    "isPopular": false,
    "chainId": "66",
    "address": "0xaa06bc645e77be80d8eca3e6a139cffa8122fde2",
    "decimals": 18,
    "image": "https://rango.vip/i/S2NZti",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.000007359416259251303,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "PGO",
    "name": "PGO",
    "isPopular": false,
    "chainId": "66",
    "address": "0x60fa9b096e1142bf360628de03d6b3603c1d10f7",
    "decimals": 18,
    "image": "https://rango.vip/i/0wZgfK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.0005843231841954881,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "BABYDOGE",
    "name": "BabyDoge",
    "isPopular": false,
    "chainId": "66",
    "address": "0x97513e975a7fa9072c72c92d8000b0db90b163c5",
    "decimals": 9,
    "image": "https://rango.vip/i/zQGOS6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 9.001683589117283e-10,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "CGD",
    "name": "CGD",
    "isPopular": false,
    "chainId": "66",
    "address": "0x0048961f4f582814f91eadd740945da6d9baac18",
    "decimals": 18,
    "image": "https://rango.vip/i/8utJTX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.014280600322872249,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "VGOD",
    "name": "VGOD",
    "isPopular": false,
    "chainId": "66",
    "address": "0xfaf18431a3dd0319680c49da62dbcd2339320679",
    "decimals": 9,
    "image": "https://rango.vip/i/poMWuA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 2.6319623928559686e-10,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "HEP",
    "name": "HEP",
    "isPopular": false,
    "chainId": "66",
    "address": "0xfd83168291312a0800f44610974350c569d12e42",
    "decimals": 0,
    "image": "https://rango.vip/i/IMiOvi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.000022622405383986336,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "ORG",
    "name": "ORG",
    "isPopular": false,
    "chainId": "66",
    "address": "0x79b627bc95fa5b36eca53eb39c3cdf43aafdd10f",
    "decimals": 18,
    "image": "https://rango.vip/i/d39IQi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.00002980605081719625,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "XENSA",
    "name": "XENSA",
    "isPopular": false,
    "chainId": "66",
    "address": "0x01d346f740e27b0f3f5513bc4d2e132e3a43cc6e",
    "decimals": 18,
    "image": "https://rango.vip/i/IQaYqZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.009013832342702421,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "CHIP",
    "name": "CHIP",
    "isPopular": false,
    "chainId": "66",
    "address": "0x0e816c86894d62ba0d1b4dcf73406dda593da49c",
    "decimals": 18,
    "image": "https://rango.vip/i/F72bPH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 0.000013690858725065024,
    "supportedSwappers": [
      "CherrySwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "OKXEN",
    "name": "okXEN",
    "isPopular": false,
    "chainId": "66",
    "address": "0x1cc4d981e897a3d2e7785093a648c0a75fad0453",
    "decimals": 18,
    "image": "https://rango.vip/i/VnNbvg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 9.138423584157696e-11,
    "supportedSwappers": [
      "CherrySwap",
      "OkcSwap"
    ]
  },
  {
    "blockchain": "OKC",
    "symbol": "STOKT",
    "name": "Staked OKT",
    "isPopular": false,
    "chainId": "66",
    "address": "0x97b05e6c5026d5480c4b6576a8699866eb58003b",
    "decimals": 18,
    "image": "https://rango.vip/i/PdKw8h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OKC/icon.svg",
    "usdPrice": 6.674250383187599,
    "supportedSwappers": [
      "OkcSwap"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALCH",
    "name": "Alchemy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0000a1c00009a619684135b824ba02f7fbf3a572",
    "decimals": 18,
    "image": "https://rango.vip/i/Lk9CVz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEFI5",
    "name": "DEFI Top 5 Tokens Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa6de2697d59e88ed7fc4dfe5a33dac43565ea41",
    "decimals": 18,
    "image": "https://rango.vip/i/uFh1R6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DSU",
    "name": "Digital Standard Unit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x605d26fbd5be761089281d5cec2ce86eea667109",
    "decimals": 18,
    "image": "https://rango.vip/i/yq1r4b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUX",
    "name": "dForce EUR",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb986f3a2d91d3704dc974a24fb735dcc5e3c1e70",
    "decimals": 18,
    "image": "https://rango.vip/i/sqIZK2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KUN",
    "name": "QIAN governance token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65d9bc970aa9b2413027fa339f7f179b3f3f2604",
    "decimals": 18,
    "image": "https://rango.vip/i/9rcmtt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MATTER",
    "name": "Antimatter.Finance Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b99cca871be05119b2012fd4474731dd653febe",
    "decimals": 18,
    "image": "https://rango.vip/i/6l7Wsb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "wSPA",
    "name": "Wrapped Sperax",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a95fe4c7e64e09856989f9ea0b57b9ab5f770cb",
    "decimals": 18,
    "image": "https://rango.vip/i/vgTVSU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUM",
    "name": "SUM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x043c308bb8a5ae96d0093444be7f56459f1340b1",
    "decimals": 18,
    "image": "https://rango.vip/i/TJx5QG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAC",
    "name": "Taekwondo Access Credit",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdeeb6091a5adc78fa0332bee5a38a8908b6b566e",
    "decimals": 18,
    "image": "https://rango.vip/i/an31e6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TCR",
    "name": "Tracer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9c4a4204b79dd291d6b6571c5be8bbcd0622f050",
    "decimals": 18,
    "image": "https://rango.vip/i/Kf0PJK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0392196,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNT",
    "name": "Unity Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8d610e20481f4c4f3acb87bba9c46bef7795fdfe",
    "decimals": 18,
    "image": "https://rango.vip/i/w7QhgD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015815,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VALX",
    "name": "Validator",
    "isPopular": false,
    "chainId": "1",
    "address": "0x27c4af9a860c4cadc358005f8b48140b2e434a7b",
    "decimals": 18,
    "image": "https://rango.vip/i/FpVGo8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VISR",
    "name": "VISOR",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf938424f7210f31df2aee3011291b658f872e91e",
    "decimals": 18,
    "image": "https://rango.vip/i/4O47YO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VOX",
    "name": "Vox.Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12d102f06da35cc0111eb58017fd2cd28537d0e1",
    "decimals": 18,
    "image": "https://rango.vip/i/lBax45",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WCHI",
    "name": "Wrapped CHI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6dc02164d75651758ac74435806093e421b64605",
    "decimals": 8,
    "image": "https://rango.vip/i/tOSZXz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03824833,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XTK",
    "name": "xToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f3edcdd180dbe4819bd98fee8929b5cedb3adeb",
    "decimals": 18,
    "image": "https://rango.vip/i/Rcju7V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017506,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IBBTC",
    "name": "Interest-Bearing Bitcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4e15973e6ff2a35cc804c2cf9d2a1b817a8b40f",
    "decimals": 18,
    "image": "https://rango.vip/i/qYubCW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RDPX",
    "name": "Dopex Rebate Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ff5a8451a839f5f0bb3562689d9a44089738d11",
    "decimals": 18,
    "image": "https://rango.vip/i/QMG03z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.36,
    "supportedSwappers": [
      "Arbitrum Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ALCH",
    "name": "Alchemy",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x0e15258734300290a651fdbae8deb039a8e7a2fa",
    "decimals": 18,
    "image": "https://rango.vip/i/llsdpp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DEFI5",
    "name": "DEFI Top 5 Tokens Index",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xdeba25af35e4097146d7629055e0ec3c71706324",
    "decimals": 18,
    "image": "https://rango.vip/i/uFh1R6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DSU",
    "name": "Digital Standard Unit",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xe212f5e733257ed5628a2febcedbc9222e535f51",
    "decimals": 18,
    "image": "https://rango.vip/i/yq1r4b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "EUX",
    "name": "dForce EUR",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x969131d8ddc06c2be11a13e6e7facf22cf57d95e",
    "decimals": 18,
    "image": "https://rango.vip/i/sqIZK2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "KUN",
    "name": "QIAN governance token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x04cb2d263a7489f02d813eaab9ba1bb8466347f2",
    "decimals": 18,
    "image": "https://rango.vip/i/qp0J43",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MATTER",
    "name": "Antimatter.Finance Governance Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xaaa62d9584cbe8e4d68a43ec91bff4ff1fadb202",
    "decimals": 18,
    "image": "https://rango.vip/i/Njg6cQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SUM",
    "name": "SUM",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x20f9628a485ebcc566622314f6e07e7ee61ff332",
    "decimals": 18,
    "image": "https://rango.vip/i/TJx5QG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TAC",
    "name": "Taekwondo Access Credit",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xfa51b42d4c9ea35f1758828226aaedbec50dd54e",
    "decimals": 18,
    "image": "https://rango.vip/i/an31e6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "TCR",
    "name": "Tracer",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa72159fc390f0e3c6d415e658264c7c4051e9b87",
    "decimals": 18,
    "image": "https://rango.vip/i/Kf0PJK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.0392196,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge",
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "UNT",
    "name": "Unity Network",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x250f471385894fc81183a99d6fde8ce9c5b142d6",
    "decimals": 18,
    "image": "https://rango.vip/i/w7QhgD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00015815,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "VALX",
    "name": "Validator",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8d1c89dcf613e3e709afe9abecae591d0e2b64ca",
    "decimals": 18,
    "image": "https://rango.vip/i/FpVGo8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "VISR",
    "name": "VISOR",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x995c235521820f2637303ca1970c7c044583df44",
    "decimals": 18,
    "image": "https://rango.vip/i/XygVkt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "VOX",
    "name": "Vox.Finance",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x2ed14d1788dfb780fd216706096aed018514eccd",
    "decimals": 18,
    "image": "https://rango.vip/i/ohJz3f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "WCHI",
    "name": "Wrapped CHI",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa64ecce74f8cdb7a940766b71f1b108bac69851a",
    "decimals": 8,
    "image": "https://rango.vip/i/tOSZXz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.03824833,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "XTK",
    "name": "xToken",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf0a5717ec0883ee56438932b0fe4a20822735fba",
    "decimals": 18,
    "image": "https://rango.vip/i/XdPc6b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.00017506,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "IBBTC",
    "name": "Interest-Bearing Bitcoin",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9ab3fd50fcae73a1aeda959468fd0d662c881b42",
    "decimals": 18,
    "image": "https://rango.vip/i/qYubCW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RDPX",
    "name": "Dopex Rebate Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x32eb7902d4134bf98a28b963d26de779af92a212",
    "decimals": 18,
    "image": "https://rango.vip/i/jyFHGT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.36,
    "supportedSwappers": [
      "ParaSwap Arbitrum",
      "Arbitrum Bridge"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WSOL",
    "name": "Wrapped SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "So11111111111111111111111111111111111111112",
    "decimals": 9,
    "image": "https://rango.vip/i/FVo3CM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 128.53,
    "supportedSwappers": [
      "Jupiter",
      "SolanaWrapper"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$WIF",
    "name": "dogwifhat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm",
    "decimals": 6,
    "image": "https://rango.vip/i/M3y4cL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.52,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JitoSOL",
    "name": "Jito Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn",
    "decimals": 9,
    "image": "https://rango.vip/i/vaekFY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 145.82,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Bonk",
    "name": "Bonk",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
    "decimals": 5,
    "image": "https://rango.vip/i/37tpYF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001574,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JupSOL",
    "name": "Jupiter Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "jupSoLaHXQiZZTSfEWMTRRgpnyFm8f6sZdosWBjx93v",
    "decimals": 9,
    "image": "https://rango.vip/i/MZDjno",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 132.86,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "mSOL",
    "name": "Marinade staked SOL (mSOL)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So",
    "decimals": 9,
    "image": "https://rango.vip/i/XKTZhk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 155.91,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$michi",
    "name": "michi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5mbK36SZ7J19An8jFochhQS4of8g6BwUjbeCSxBSoWdp",
    "decimals": 6,
    "image": "https://rango.vip/i/49fUOD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.101347,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "aura",
    "name": "aura",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DtR4D9FtVoTX2569gaL837ZgrB6wNjj6tkmnX9Rdk9B2",
    "decimals": 6,
    "image": "https://rango.vip/i/BtR1Mw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00407382,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "bSOL",
    "name": "BlazeStake Staked SOL (bSOL)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1",
    "decimals": 9,
    "image": "https://rango.vip/i/KiEYF0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 147.99,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "bicho",
    "name": "bicho",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GkJxELgJXpQRm7dfc2yS18vBDRxP5SjVJgbrmTGgpump",
    "decimals": 6,
    "image": "https://rango.vip/i/SzGK1g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00026627,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "hSOL",
    "name": "Helius Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A",
    "decimals": 9,
    "image": "https://rango.vip/i/1PCImE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 131.92,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "mini",
    "name": "mini",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2JcXacFwt9mVAwBQ5nZkYwCyXQkRcdsYrDXn6hj22SbP",
    "decimals": 6,
    "image": "https://rango.vip/i/8kpbL2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00859778,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ISC",
    "name": "International Stable Currency",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "J9BcrQfX4p9D1bvLzRNCbMDv8f44a9LFdeqNE4Yk2WMD",
    "decimals": 6,
    "image": "https://rango.vip/i/wN4SeZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.77,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LOCKIN",
    "name": "LOCK IN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8Ki8DpuWNxu9VsS3kQbarsCWMcFGWkzzA8pUPto9zBd5",
    "decimals": 9,
    "image": "https://rango.vip/i/7ak58d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01024923,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "tremp",
    "name": "doland tremp",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FU1q8vJpZNUrmqsciSjp8bAKKidGsLmouB8CBdf8TKQv",
    "decimals": 9,
    "image": "https://rango.vip/i/vaS2oK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.152174,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "nub",
    "name": "nubcat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GtDZKAqvMZMnti46ZewMiXCa4oXF4bZxwQPoKzXPFxZn",
    "decimals": 9,
    "image": "https://rango.vip/i/m4DLwI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0063013,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$MYRO",
    "name": "Myro",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4",
    "decimals": 9,
    "image": "https://rango.vip/i/ixk7FG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.063815,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SRMet",
    "name": "Serum (Portal from Ethereum)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "xnorPhAzWXUczCP3KjU5yDxmKKZi5cSbxytQ1LgE3kG",
    "decimals": 6,
    "image": "https://rango.vip/i/XFmiRa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YARD",
    "name": "SolYard Finance",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz",
    "decimals": 9,
    "image": "https://rango.vip/i/cgq47H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004373,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JOHN",
    "name": "John",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BuxH23osRyFFLbWG3czrTsfBQYbxzVZ8f7QV4cjTHN5x",
    "decimals": 6,
    "image": "https://rango.vip/i/3QKDGw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00052956,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ket",
    "name": "ket",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2SsU91ZE2dni5aD8i7bfTR4Bvf6UcGFBbLsvDucWUdw3",
    "decimals": 9,
    "image": "https://rango.vip/i/dHW15T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000018737546115916285,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "whoren",
    "name": "elizabath whoren",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EF23Avq2cTPnMVTfHacZ3SG5Z8misHmFA2gbt2rKqiYH",
    "decimals": 9,
    "image": "https://rango.vip/i/2mldxU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00145681,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PULP",
    "name": "PULP",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5YtGSyQ7YxFgDc9oj4SpZGTjoS5p5Fubkg8sMauQqXoS",
    "decimals": 9,
    "image": "https://rango.vip/i/vkyGkW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Labz",
    "name": "Insane Labz",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4VC7UYqBo9Siw8ZnkPXfw9D3dzYCiVrPDzs9XRtyRJMH",
    "decimals": 9,
    "image": "https://rango.vip/i/khGoZn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0148999,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SEYLER",
    "name": "mechel seyler",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5g6b3eCLHC4WvmUJRiTvE5mNCUMiaHbKxQyWKuJdmDi",
    "decimals": 9,
    "image": "https://rango.vip/i/VAgUw3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Albärt",
    "name": "Albärt",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EUroSPBddnvGhRGWj56S864XWG6wgJ42yLsHSNyAWBj",
    "decimals": 9,
    "image": "https://rango.vip/i/tPkiSf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DFTU",
    "name": "DFTU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9hD434AapfbidD6hret16hHGMqKh9z3WrkicL5Cvcsz8",
    "decimals": 8,
    "image": "https://rango.vip/i/WhlOvm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "aeUSDT",
    "name": "Wrapped USDT (Allbridge from Ethereum)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Bn113WT6rbdgwrm12UJtnmNqGqZjY4it2WoUQuQopFVn",
    "decimals": 9,
    "image": "https://rango.vip/i/uhQV48",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SPOOKY",
    "name": "Spooky The Phantom",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FdGoS1Dok5CLnS8fVSmj5A92uY1yhzdTC2ZxuLJdkwgs",
    "decimals": 6,
    "image": "https://rango.vip/i/iMbV1v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000395,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GRUMP",
    "name": "GRUMPY CAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ch5JJQZspiJ9MCDURZAA8nnVvbiwD1tnPcfmkCcVPiDb",
    "decimals": 9,
    "image": "https://rango.vip/i/n4F2Rw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004158,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHEXBACCA",
    "name": "CHEXBACCA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8J5e2FPmBJ1subEUeVkELpeBZv9aYUrMmfHys7sREeXr",
    "decimals": 6,
    "image": "https://rango.vip/i/y9UP51",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00013248,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHEPI",
    "name": "SHEPI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2MMJXYvSJuY4C3sSj9eAWVroiLWnuxG7DQieB5PCMjKm",
    "decimals": 9,
    "image": "https://rango.vip/i/wkpPrP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "rFRAKT",
    "name": "Random FRAKTs",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7V5AaqHTwiySegaAmNPLekQfTAoK4WvEVgfi2R8V44tB",
    "decimals": 9,
    "image": "https://rango.vip/i/qMyhEn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ched",
    "name": "giggleched",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "12mcpYL84oMi8Hiinyjuv2Zq3F47tLLxjw1THLcAdKT2",
    "decimals": 6,
    "image": "https://rango.vip/i/5g8OPs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002892,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ASV",
    "name": "Asvoria",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AxaTJdRuuc3626FtPWdQCMcWPH6yzgxXKWbFCZN3TMgy",
    "decimals": 9,
    "image": "https://rango.vip/i/leyvnW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PRE-",
    "name": "PRE-TOKEN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EZGvDFqrMSAxTt3Ud2bcguASaPKwGExiDzHVLqT3STZm",
    "decimals": 6,
    "image": "https://rango.vip/i/PZFdwr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLNTN",
    "name": "SOLANATIN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "aXNx9xd9EUAd1xci3aUX1FjyNTAxaYrX29GsL7YtBJq",
    "decimals": 6,
    "image": "https://rango.vip/i/sXoKoR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PFIRE",
    "name": "Pepe On Fire",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PFireKhT5WG7axMSLBmMRpvYH7cgHx9CRWHU8F8HNbr",
    "decimals": 6,
    "image": "https://rango.vip/i/ecjQPZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 6.5877e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GLEEK",
    "name": "GLEEK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4ACuWnJZjE1Q51589mBmmyfD82RZ4LNFVeuPdSRFPc3L",
    "decimals": 9,
    "image": "https://rango.vip/i/q13wtV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001401,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SECROT",
    "name": "Secrot Servoce",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7VQzGuk2xbWteJ2A5yzGkuDmCQLQGAsvD9MjmW8VutTG",
    "decimals": 6,
    "image": "https://rango.vip/i/ysinM4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SDO",
    "name": "TheSolanDAO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7SZUnH7H9KptyJkUhJ5L4Kee5fFAbqVgCHvt7B6wg4Xc",
    "decimals": 5,
    "image": "https://rango.vip/i/Y3KCqU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.93081e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DLP8",
    "name": "DLP8 Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9AGm8DWXiB4PwvH1V2r31ChsTE2f2TDySvDbfJmfaMBq",
    "decimals": 8,
    "image": "https://rango.vip/i/HAMApB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MALL",
    "name": "MetaMall",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i",
    "decimals": 3,
    "image": "https://rango.vip/i/rxmIEh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000763,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHAN",
    "name": "memechan",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ChanGGuDHboPswpTmKDfsTVGQL96VHhmvpwrE4UjWssd",
    "decimals": 9,
    "image": "https://rango.vip/i/efqIzB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00082672,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$BIAO",
    "name": "Biao Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3W52uCb8NW8ruMF9mmJX3oKiYAjdPai4633srsZFQCS6",
    "decimals": 6,
    "image": "https://rango.vip/i/jUNj8j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003457,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YOLO",
    "name": "YoloNolo Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4jE4VuqFWZfmSXjTDD5KUoN2qkVu96nPTPj4mhs5PA1W",
    "decimals": 5,
    "image": "https://rango.vip/i/064jni",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00237353,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MELL",
    "name": "Mellivora",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "MELLd8PyFoeNW3D5VaUe7L96eZeihtrzgLWrbKz5DR2",
    "decimals": 6,
    "image": "https://rango.vip/i/Xy2UAa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00489253,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$BLEK",
    "name": "Blekrok",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EYaJJKb2VDZuYKEWf5TjngFRc43cMgttPuRtcJwQt35z",
    "decimals": 5,
    "image": "https://rango.vip/i/yiEBlI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "2080",
    "name": "2080",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Dwri1iuy5pDFf2u2GwwsH2MxjR6dATyDv9En9Jk8Fkof",
    "decimals": 9,
    "image": "https://rango.vip/i/0DBPyH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00480822,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$Clown",
    "name": "ClownOnSolana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "V7ntWk4D9Frwaec2CUjMRMMsJG28Cd31HxDKNdP1qjM",
    "decimals": 9,
    "image": "https://rango.vip/i/zGYFX4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UberJeets",
    "name": "Uber Jeets",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G2ShfTkHaPgY1YUqZzB611coeFpbhyGirzgGmyW5fEhV",
    "decimals": 6,
    "image": "https://rango.vip/i/E0eenM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOL100",
    "name": "SOL100",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GkDg1ZfoFkroLAwLqtJNXhxCDg8gmKxHAGxSUZagYFfE",
    "decimals": 9,
    "image": "https://rango.vip/i/yOHTHc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDCav",
    "name": "USD Coin (Portal from Avalanche)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FHfba3ov5P3RjaiLVgh8FTv4oirxQDoVXuoUUDvHuXax",
    "decimals": 6,
    "image": "https://rango.vip/i/8DXWdM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "vSOL",
    "name": "The Vault",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "vSoLxydx6akxyMD9XEcPvGYNGq6Nn66oqVb3UkGkei7",
    "decimals": 9,
    "image": "https://rango.vip/i/qQVK2j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 132.01,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EVOL",
    "name": "Evolve",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EVoLsbmQXT6R3b11WjPpGEXyCjw1zmmir271XqDbKRsg",
    "decimals": 9,
    "image": "https://rango.vip/i/OHZKxJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00023527,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GODZ",
    "name": "Godz Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BB33fYoeBVA2uv119be9tKvmXeuwtcx1W25N9KFNd2ca",
    "decimals": 9,
    "image": "https://rango.vip/i/mzMW8e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.02434e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "dSOL",
    "name": "Drift Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Dso1bDeDjCQxTrWHqUUi63oBvV7Mdm6WaobLbQ7gnPQ",
    "decimals": 9,
    "image": "https://rango.vip/i/fOGjR8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 133.88,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Doogle",
    "name": "Doogle",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F6TsRcjtLBzkdtZYqjTPVq9WtnwHMMc1WcQguEgCpump",
    "decimals": 6,
    "image": "https://rango.vip/i/5zfZP7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00055313,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sUSDC-9",
    "name": "Saber Wrapped USD Coin (9 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "JEFFSQ3s8T3wKsvp4tnRAsUBW7Cqgnf8ukBZC4C8XBm1",
    "decimals": 9,
    "image": "https://rango.vip/i/TZkKAe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WTRBR",
    "name": "Water Bears",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Aymdf5Fp2URJNcsHpEF2NqEqFHiqvvhrDvYQtyZFw7zn",
    "decimals": 4,
    "image": "https://rango.vip/i/oZLmLv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NARD",
    "name": "Nard",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F89doZeeUe9ajcU6gNR1F5RLaQWcJMdPDNSuLuvJUst9",
    "decimals": 6,
    "image": "https://rango.vip/i/dRDXDR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JENSEN",
    "name": "JENSEN HUANG",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HfQuaLjMguh7vyZqqrWuVqpsjQd7tAPrzBWKqA3pDTH3",
    "decimals": 9,
    "image": "https://rango.vip/i/SScybK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00025629,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DABLNS",
    "name": "DABLOONS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "dab15vg2k8zGJPy4xM2DH2G2BY3khrqduXapzYAV3y8",
    "decimals": 8,
    "image": "https://rango.vip/i/hq7x04",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MELON",
    "name": "Melon Dog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7DGJnYfJrYiP5CKBx6wpbu8F5Ya1swdFoesuCrAKCzZc",
    "decimals": 6,
    "image": "https://rango.vip/i/X1WsAF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00145113,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DILDO",
    "name": "Dildo BagHands",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CDW5fC3Fp69Km6Kg4xTf5SiFqP3B24wt2SVK9GwG6LUs",
    "decimals": 9,
    "image": "https://rango.vip/i/gs3fFS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "soLINK",
    "name": "Wrapped Chainlink (Sollet)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG",
    "decimals": 6,
    "image": "https://rango.vip/i/P4iFwh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DED",
    "name": "DED",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7raHqUrZXAqtxFJ2wcmtpH7SQYLeN9447vD4KhZM7tcP",
    "decimals": 2,
    "image": "https://rango.vip/i/2kgbKW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008124,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KEKW",
    "name": "KEKW",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CEYNkwuEXU1KD3MN47NaMvHznPPimR15Sjfv6Y2r1SVw",
    "decimals": 6,
    "image": "https://rango.vip/i/6vySmn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EMMY",
    "name": "Emmy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8Qrc2pf9p24NyJVG1FagnqJXwKw6h5L5McxnMfJoUxev",
    "decimals": 9,
    "image": "https://rango.vip/i/BtwOFw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002789,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BULB",
    "name": "BULB Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A6JVLMAjR1aeCfz3X2z4vVQ9GafYWVT75tC5V5qefja2",
    "decimals": 9,
    "image": "https://rango.vip/i/4MRVGQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PEPECAT",
    "name": "Pepe The Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FfhArvgv8WB7eZ6qwENMouJRzcVpQVDoDtTuHrCk4Cxi",
    "decimals": 6,
    "image": "https://rango.vip/i/Psb8Uz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000725,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Orcat",
    "name": "Orcat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3iG5NRc36xaVnWKdFKw9HwDjG2Bf58YitCJmGUm7hrFJ",
    "decimals": 6,
    "image": "https://rango.vip/i/EZ8Oj8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MMOSH",
    "name": "MMOSH: The Stoked Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FwfrwnNVLGyS8ucVjWvyoRdFDpTY8w6ACMAxJ4rqGUSS",
    "decimals": 9,
    "image": "https://rango.vip/i/DB4LCK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "aeWETH",
    "name": "Wrapped ETH (Allbridge from Ethereum)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AaAEw2VCw1XzgvKB8Rj2DyK2ZVau9fbt2bE8hZFWsMyE",
    "decimals": 9,
    "image": "https://rango.vip/i/8Jepq5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOT",
    "name": "Starbots Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AkhdZGVbJXPuQZ53u2LrimCjkRP6ZyxG1SoM85T98eE1",
    "decimals": 8,
    "image": "https://rango.vip/i/uFgePY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00029385,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HCOIN",
    "name": "Hydrogencoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4B619RbcXbXrKTzNVgDSRiUn9wfxWgA1w1oFLveGacNy",
    "decimals": 3,
    "image": "https://rango.vip/i/IVZRNG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wstETH",
    "name": "Lido Wrapped Staked ETH",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo",
    "decimals": 8,
    "image": "https://rango.vip/i/Ma22Zx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2779.2153173899433,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RACEFI",
    "name": "RaceFi Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AAmGoPDFLG6bE82BgZWjVi8k95tj9Tf3vUN7WvtUm2BU",
    "decimals": 6,
    "image": "https://rango.vip/i/1DNuLY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00187977,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ASTRALIS",
    "name": "ASTRALIS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ASTRALvKjGK2xk2pamjMBU5dav5cEQa6zpKCP6FZ7BAJ",
    "decimals": 6,
    "image": "https://rango.vip/i/hUicFG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Culture",
    "name": "For The Culture",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BuAL6Qt1CJbfa6wnHnYNqj572nQpKGR7C5xe4jn3icBp",
    "decimals": 9,
    "image": "https://rango.vip/i/C5ixzi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ILU",
    "name": "ILoveU Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Gm6szibJfB1ZzUxNYf85nXwFchugqTqNyE5fDwWfBc7K",
    "decimals": 9,
    "image": "https://rango.vip/i/3giUsk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PISS",
    "name": "Frog Piss",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7qULVSb7XdoKBDDa7WnuUvoTx5ye4Vrj94iPcwibyQ1F",
    "decimals": 6,
    "image": "https://rango.vip/i/Aa1BjK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.50036e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "xALGO",
    "name": "Wrapped ALGO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "xALGoH1zUfRmpCriy94qbfoMXHtK6NDnMKzT4Xdvgms",
    "decimals": 6,
    "image": "https://rango.vip/i/kgWkhk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.339569,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "stakeSOL",
    "name": "stakeSOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "st8QujHLPsX3d6HG9uQg9kJ91jFxUgruwsb1hyYXSNd",
    "decimals": 9,
    "image": "https://rango.vip/i/dlTYYh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 139.82,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DEADCO",
    "name": "DeadCoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "r8EXVDnCDeiw1xxbUSU7MNbLfbG1tmWTvigjvWNCiqh",
    "decimals": 9,
    "image": "https://rango.vip/i/PsvTyw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "tert",
    "name": "tert",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4bBxhRezDJDu3uuh1KM3bWetYiAZSauTeUGixn9rmiX9",
    "decimals": 6,
    "image": "https://rango.vip/i/OQSnpo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000505,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "XBM",
    "name": "Beast Meme",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "XBMuuVZKHd6u8GyX6JakhjgpCA6h7FG28bXaWX2s51P",
    "decimals": 9,
    "image": "https://rango.vip/i/2j0sZ2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SATORI",
    "name": "Satori",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HkSiK3rrAf4JHXN7Cf1j7GsLofX9jo8962xabT1qiff1",
    "decimals": 2,
    "image": "https://rango.vip/i/Li4TdX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UNKN",
    "name": "UNKN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "unknXbA1bDg39nuBqVgMNZ5qSZa8pw5HditgkPe5eFA",
    "decimals": 9,
    "image": "https://rango.vip/i/Cw9KzN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "L",
    "name": "Lorm the Worm",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2emYMetySwE7Xh6qrG1AwAQ3TDvrLkUSpSZ5AjcWCc7B",
    "decimals": 6,
    "image": "https://rango.vip/i/9ppwPt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003763,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BASC",
    "name": "BASC",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DM3Y4R7n1HGhP9AkNT6Ex4w1qQTpgq1TyujrMDX11FMT",
    "decimals": 9,
    "image": "https://rango.vip/i/3KHUPA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FOXES",
    "name": "Famous Fox Federation",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3RCVCywxSs8pDRU1hw8fo7xTUCpNuX86w8cKTM6fgWBY",
    "decimals": 2,
    "image": "https://rango.vip/i/ylNL2l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.649659,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHICKS",
    "name": "SolChicks",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2",
    "decimals": 9,
    "image": "https://rango.vip/i/EzW4xE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003561,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "tradebot",
    "name": "TradeBot",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GaRph5BcLZZ4sRh6EXKS6V7dicVanhcwa5iWEE6AbEYR",
    "decimals": 6,
    "image": "https://rango.vip/i/K4m8UM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0037564428743573873,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GIANT",
    "name": "AndreDefiant",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A6YRaK4toMe2qZY7KLNCugXBrsjCG4fAjAR8uZUBeUek",
    "decimals": 9,
    "image": "https://rango.vip/i/fUv5KC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wFRAX_v1",
    "name": "Frax (Wormhole v1)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU",
    "decimals": 9,
    "image": "https://rango.vip/i/JS15yK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JOEVER",
    "name": "It's Joever",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5ycBARVYYau1CW8s1r6Ty439rbVvGm7x5DRkxGiGKmZ",
    "decimals": 9,
    "image": "https://rango.vip/i/6oRh5a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ISL",
    "name": "The Islanders",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "75XracgnqjPeuexHKWQU3bBcXMZG6XLDF867tKB1T9e6",
    "decimals": 9,
    "image": "https://rango.vip/i/KT6j3K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GMCAT",
    "name": "GM Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "83HDsxuNFnhanLgkTdij3dT7tP5FH3bb1TV1rbTT7atz",
    "decimals": 6,
    "image": "https://rango.vip/i/iwWdLD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FRIES",
    "name": "Soltato FRIES",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FriCEbw1V99GwrJRXPnSQ6su2TabHabNxiZ3VNsVFPPN",
    "decimals": 9,
    "image": "https://rango.vip/i/KYuVIq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ANSOM",
    "name": "ansom",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BfHkvKMEYjwPXnL36uiM8RnAoMFy8aqNyTJXYU3ZnZtz",
    "decimals": 6,
    "image": "https://rango.vip/i/FqMxTU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002788,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wBUSD_v1",
    "name": "Binance USD (Wormhole v1)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX",
    "decimals": 9,
    "image": "https://rango.vip/i/2QHivB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PROTUGAL",
    "name": "PROTUGAL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BT2apS5umybEthwy6xP5PfgNDw3ykMyxirY5XxZ7H654",
    "decimals": 9,
    "image": "https://rango.vip/i/lC09eP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TSUKI",
    "name": "Tsuki",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "463SK47VkB7uE7XenTHKiVcMtxRsfNE2X4Q9wByaURVA",
    "decimals": 9,
    "image": "https://rango.vip/i/CEynJ5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00185939,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$RETIRE",
    "name": "Retire on Sol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HXkbUADfocGyz2WrzJpjEfry8qyNDm5Kwiiq3Mz3tTi1",
    "decimals": 6,
    "image": "https://rango.vip/i/G4P8jc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001094,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$micha",
    "name": "micha",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "43MvZht7GGYEuCg3bgiauh3N7zVe73yyGRSC1aUBXLHT",
    "decimals": 9,
    "image": "https://rango.vip/i/vQO7Cy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00050201,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Board",
    "name": "Solboard",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4xBEoJFNxRY7ZyUPEFmWwHrGzYN5uqzsAH94DTvBv3b1",
    "decimals": 6,
    "image": "https://rango.vip/i/gJ3EgP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0024005307305095137,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "eSOL",
    "name": "Eversol staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Hg35Vd8K3BS2pLB3xwC2WqQV8pmpCm3oNRGYP1PEpmCM",
    "decimals": 9,
    "image": "https://rango.vip/i/38TvbJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NELSOL",
    "name": "DogWalter",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EBPpUYEGsmVGG291MXoXmwucqw6nR9dEUEfK5LJdeuve",
    "decimals": 6,
    "image": "https://rango.vip/i/N3dWuM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00007665,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MIMANY",
    "name": "MIMANY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3Rcc6tMyS7ZEa29dxV4g3J5StorS9J1dn98gd42pZTk1",
    "decimals": 6,
    "image": "https://rango.vip/i/SJB4hU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00029557,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UCIT",
    "name": "UCIT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HH8bchogQD71iuLghP4cuvSU7vsGJoMJDBxvWTFu7MpA",
    "decimals": 2,
    "image": "https://rango.vip/i/h6Wmgj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.1637e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOOSH",
    "name": "Soosh",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6wktcKujaFRKV9Sz455nHUNz34dEWht1gqKzR5KQ9Ljb",
    "decimals": 2,
    "image": "https://rango.vip/i/s5XauP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$WNZ",
    "name": "Winerz",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "WNZzxM1WqWFH8DpDZSqr6EoHKWXeMx9NLLd2R5RzGPA",
    "decimals": 4,
    "image": "https://rango.vip/i/TlRVZm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00019917,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "renBTC",
    "name": "renBTC",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5",
    "decimals": 8,
    "image": "https://rango.vip/i/m8Vrde",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 55747.36370257785,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BRO",
    "name": "Be Like Bro",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Bro4MuM7ZSWgGGhioxdMne8TwiZjKLJYK9eNQQbGEN9X",
    "decimals": 6,
    "image": "https://rango.vip/i/zR0Ad7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00197023,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$MARVIN",
    "name": "MARVIN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ELuv4btje7nZNLeMSw7foptfSiRzEXpNxYuet8cM27SN",
    "decimals": 6,
    "image": "https://rango.vip/i/qRzdoV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SBABYDOGE",
    "name": "SOL BABAY DOGE COIN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BABYsocP6cB95xvBDXnjXKX96VBNC37dmNWUtaV9Jk6v",
    "decimals": 2,
    "image": "https://rango.vip/i/CdSyeM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.9536e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDCpo",
    "name": "USD Coin (PoS) (Portal from Polygon)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E2VmbootbVCBkMNNxKQgCLMS1X3NoGMaYAsufaAsf7M",
    "decimals": 6,
    "image": "https://rango.vip/i/JeBKoG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.990871,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YORK",
    "name": "Yorkipoo",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg",
    "decimals": 9,
    "image": "https://rango.vip/i/rR9yJF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLPAY",
    "name": "SOLPAY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "zwqe1Nd4eiWyCcqdo4FgCq7LYZHdSeGKKudv6RwiAEn",
    "decimals": 9,
    "image": "https://rango.vip/i/E69dtE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008855,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "jucySOL",
    "name": "Juicy SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "jucy5XJ76pHVvtPZb5TKRcGQExkwit2P5s4vY8UzmpC",
    "decimals": 9,
    "image": "https://rango.vip/i/W24gSa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 134.3,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "REMILIO",
    "name": "remilio",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "remiG7sGaHWgrY7o6SXJW5CYi5A7kmKutyJz6x6hUsp",
    "decimals": 9,
    "image": "https://rango.vip/i/fmNwGd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00045504,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BRASIL",
    "name": "BRASIL COIN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HhAcs9JjcveM45FG2Zi3JcWAomra3QezZ22mVvr7zPSE",
    "decimals": 6,
    "image": "https://rango.vip/i/78VQGq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLI",
    "name": "Solana Ecosystem Index",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8JnNWJ46yfdq8sKgT1Lk4G7VWkAA8Rhh7LhqgJ6WY41G",
    "decimals": 6,
    "image": "https://rango.vip/i/hnxqb7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.29,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SLITE",
    "name": "SolanaLite",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7eJCLyW5KkvzdzkVXs1ukA1WfFjCcocXjVit64tYcown",
    "decimals": 9,
    "image": "https://rango.vip/i/OLsEoE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EZB",
    "name": "Eat Ze Bugz",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9Zf6CPFt8mJ5TBWJur3x5HrKdr7yfNg9QhEx2jkNNyFc",
    "decimals": 9,
    "image": "https://rango.vip/i/IPCoZY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ROPE",
    "name": "Rope Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo",
    "decimals": 9,
    "image": "https://rango.vip/i/wnZRpt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00193906,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TMOON",
    "name": "TurboMoon",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7Cx1fMabGWgm86xvv73WzSAcwtLppKf39Ywe8e6Qq4T6",
    "decimals": 5,
    "image": "https://rango.vip/i/SDIaML",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 5.76807e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "aaUSDC",
    "name": "Wrapped USDC (Allbridge from Avalanche)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8Yv9Jz4z7BUHP68dz8E8m3tMe6NKgpMUKn8KVqrPA6Fr",
    "decimals": 9,
    "image": "https://rango.vip/i/MNVATq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "soFTT",
    "name": "Wrapped FTT (Sollet)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3",
    "decimals": 6,
    "image": "https://rango.vip/i/JQaRfJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.28,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "gil",
    "name": "kith gil",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CyUgNnKPQLqFcheyGV8wmypnJqojA7NzsdJjTS4nUT2j",
    "decimals": 6,
    "image": "https://rango.vip/i/th7Ukb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00010554,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$KSH",
    "name": "Keeshond Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6j14WyX1Ag2pLWvn99euK4xp2VcZD62VeJv2iwCrYmT8",
    "decimals": 9,
    "image": "https://rango.vip/i/5Ywyaq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PRGC",
    "name": "ProtoReality Games Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "66edZnAPEJSxnAK4SckuupssXpbu5doV57FUcghaqPsY",
    "decimals": 9,
    "image": "https://rango.vip/i/RuSomT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$INA",
    "name": "pepeinatux",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2yd2Suus3YY4Sa7LHhn1PSHkjXj3XKrars4cCog2tGU8",
    "decimals": 6,
    "image": "https://rango.vip/i/mohC8I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00178848,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "REWD",
    "name": "Reward Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2eu1K3wvfPC7gVj1CK8ohv4ggusdN6qxyxpjHyTCkjZT",
    "decimals": 6,
    "image": "https://rango.vip/i/ApNI5o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000151,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EPCT",
    "name": "Epics Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CvB1ztJvpYQPvdPBePtRzjL4aQidjydtUz61NWgcgQtP",
    "decimals": 6,
    "image": "https://rango.vip/i/dIP1ZR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01454953,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "superSOL",
    "name": "Superfast Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "suPer8CPwxoJPQ7zksGMwFvjBQhjAHwUMmPV4FVatBw",
    "decimals": 9,
    "image": "https://rango.vip/i/uLQtgu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 133.18,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ENRX",
    "name": "Enrex",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5s4BYUXLuvs9ZcVDTxkTpKhThWFSpaU8GG55q2iySe2N",
    "decimals": 2,
    "image": "https://rango.vip/i/qssB5V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001924,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OPOS",
    "name": "Only Possible On Solana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BqVHWpwUDgMik5gbTciFfozadpE2oZth5bxCDrgbDt52",
    "decimals": 9,
    "image": "https://rango.vip/i/gKeRAo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.255989,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOXXY",
    "name": "Boxxy Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EzYyFvUE2AepSxbtdDkkq5rWpN7bXhYzQphZ2gF4Y24R",
    "decimals": 6,
    "image": "https://rango.vip/i/8obTYK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MUTANT",
    "name": "MUTANT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "mmonechkLNdUxDbvP9Yntwo459QfUmqDdzV95DNvnr6",
    "decimals": 9,
    "image": "https://rango.vip/i/0smwYy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BarrTremp",
    "name": "Barron Tremp",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2CMxmFb2nsNdw351TfZoawpN1DnDuVrH4Wn6fxH2EJT6",
    "decimals": 6,
    "image": "https://rango.vip/i/hnhFCy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$VIDEOGAME",
    "name": "video-game.cc",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Cd35Zsee8atgY14q47mgN4N7xa2VpJG57ygtAXL7pump",
    "decimals": 6,
    "image": "https://rango.vip/i/DSbBIv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COMFY",
    "name": "SOCOMFY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FbJpd8yhrGGkWVL1Ujf7qFvTw4uD4675k8CYk82LEKvZ",
    "decimals": 8,
    "image": "https://rango.vip/i/O1G0x3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003145,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MUGI",
    "name": "MUGI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Dc7mzCE1aG8rNJUgD3zDiRPcgJUYKSybxBA1oXWqjLz4",
    "decimals": 6,
    "image": "https://rango.vip/i/vzYH1Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NONCE",
    "name": "nonce pesoli",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3wDQAHLuaaotFajeJXpJpNMio3LBhSTscHa8Xq5UwLo2",
    "decimals": 6,
    "image": "https://rango.vip/i/aAt14G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WXM",
    "name": "WeatherXM",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "wxmJYe17a2oGJZJ1wDe6ZyRKUKmrLj2pJsavEdTVhPP",
    "decimals": 9,
    "image": "https://rango.vip/i/18VBZA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.405308,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "bozoHYBRID",
    "name": "Bozo Hybrid",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EJPtJEDogxzDbvM8qvAsqYbLmPj5n1vQeqoAzj9Yfv3q",
    "decimals": 9,
    "image": "https://rango.vip/i/ZaDeTT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01140516,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EGIRL",
    "name": "EGIRL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ARt4N4WY4PEdYUuBG7qENwuYSSiQUqP1RXFiahhwfzH9",
    "decimals": 9,
    "image": "https://rango.vip/i/ViI9OB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PORTNOY",
    "name": "PORTNOY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8wzYfqeqkjBwYBHMacBVen8tSuJqXiDtsCgmjnUJDSKM",
    "decimals": 6,
    "image": "https://rango.vip/i/2vHlFJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HELIA",
    "name": "StareCat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FZnSMd1hPu5MVgttmtfDZUPN5gcuexvrh3CB67UuQshb",
    "decimals": 6,
    "image": "https://rango.vip/i/FRvvr8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00044699,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DEDS",
    "name": "Decimus Dynamics Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BvEj2MNMPsUrD4vSk7NHs4TtRcCcJd75Wx5HvVbY4rbK",
    "decimals": 9,
    "image": "https://rango.vip/i/4z6cQ1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "pipi",
    "name": "pipi the cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BZKuDqzD4rb2puUV2cbSNd2kZzJ5JzKiQKwBzCk8hrLu",
    "decimals": 9,
    "image": "https://rango.vip/i/Fe2rm0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01587448,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GCR",
    "name": "Golden Celestial Ratio",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7dGbPgUxKpB5qWiLRKcTQSC3om1fPzUpgGAFfwej9hXx",
    "decimals": 9,
    "image": "https://rango.vip/i/c2yajR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00061208,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Bekele",
    "name": "Nayeb Bekele",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4pPMX85G5t6M8hoKvdsbRDGbMkXxRg1vefcQ7MVjjMKM",
    "decimals": 6,
    "image": "https://rango.vip/i/8smtOf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDTbs",
    "name": "Tether USD (Portal from BSC)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv",
    "decimals": 8,
    "image": "https://rango.vip/i/Bk7CF7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DRIPSPLIT",
    "name": "Drippies",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "uL2qhMckUAroJPt2MLHwEeppJNYE3wBAGFMCs3anwXn",
    "decimals": 2,
    "image": "https://rango.vip/i/7jvrzo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "macho",
    "name": "macho",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8zFovnzXzK9JDiftGaw7wiRxARrRtvm9Lz12vJ8CZ5ZA",
    "decimals": 5,
    "image": "https://rango.vip/i/lSkKJr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00022053337848640465,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SCUM",
    "name": "Dead Bear Inc",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5cvj5rEEocG5Wvh3oJuY6MoYj7gVZd8aoXSLZjDY6W4W",
    "decimals": 9,
    "image": "https://rango.vip/i/4EJj4k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DONK",
    "name": "DONK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "xABfKiG2KCHi6keTeLycW1iK7B52wJmchSWXu3YrsDp",
    "decimals": 5,
    "image": "https://rango.vip/i/Lapn8W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.30436e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GRAY",
    "name": "Gray",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6XfB1VVAxjtfKnF1kotGxLUq4p87xTDp3cCyC6Q2VHNG",
    "decimals": 9,
    "image": "https://rango.vip/i/4UjQcW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DOBI",
    "name": "DOBI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GzQzkt2B4Jr6whWVBF7XqkzWvoUy1jEd5z9tczzGg1rH",
    "decimals": 6,
    "image": "https://rango.vip/i/KDRNt5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00005532,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BRETTGOLD",
    "name": "BRETT GOLD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F6ExBzKdLRcJkCAknQgfbhRXX78EhqoNxPnegJWPpump",
    "decimals": 6,
    "image": "https://rango.vip/i/J0Gn8v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00042942,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FCAT",
    "name": "Floki Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CcFfGMU9Jodk6s5fSZM1vYGF3UNng7fZvTmPgzvU1ScF",
    "decimals": 6,
    "image": "https://rango.vip/i/W8J2CU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001522,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SMOLCAT",
    "name": "Smol Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AJd5bi9wK4GupVf9XeftfrzQraQVthErLxEtdHv5qEHU",
    "decimals": 9,
    "image": "https://rango.vip/i/qJ46KL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "INBRED",
    "name": "INBRED CAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EjzzyCSiLqjFDprpZj8e1zjXmcTG5HPGFRSEoWcJWHh9",
    "decimals": 9,
    "image": "https://rango.vip/i/aaQci9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00069054,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BSLSK",
    "name": "Basilisk",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EMAb1cexvwrMeViyYiK8941V7SGWiXsyhMREBcswggPw",
    "decimals": 2,
    "image": "https://rango.vip/i/IS2DKN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OPPIE",
    "name": "TOLYS CAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GwyxednWbrhgT2K6iPUsbtadErA7TBGqsJjyzAody2mv",
    "decimals": 6,
    "image": "https://rango.vip/i/ehZ6Z1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00034402,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "aSOL",
    "name": "aSOL Aggregate Solana Stake Pool",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond",
    "decimals": 9,
    "image": "https://rango.vip/i/EVqXfe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "shork",
    "name": "shork",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BJB5tHWAHboMAwti5AHbCNX17F3jL8ehkh4zuTUx99Zn",
    "decimals": 6,
    "image": "https://rango.vip/i/PkrIAL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00018820346077665212,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CROWS",
    "name": "Royal Crows",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6gSmbxSfgJrqeGBYwuKGuSbShhbDLjcCLWqzivvPeG3i",
    "decimals": 2,
    "image": "https://rango.vip/i/tHTnUr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OGT",
    "name": "Olympic Games Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DHbCBJVWGTHpiV4WNZ9AajvNkEWKRdu2mmJXYjQj847t",
    "decimals": 9,
    "image": "https://rango.vip/i/dn78XO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "tTRIAD",
    "name": "Triad",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "t3DohmswhKk94PPbPYwA6ZKACyY3y5kbcqeQerAJjmV",
    "decimals": 6,
    "image": "https://rango.vip/i/34eNoV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "pepe",
    "name": "pepecoin on SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3tS6fbLh2P8tzxXuqCiHZpZhsxJpmrR3Xb9psmypnp69",
    "decimals": 9,
    "image": "https://rango.vip/i/JR3qtV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00018226,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NESTA",
    "name": "Nest Arcade",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV",
    "decimals": 9,
    "image": "https://rango.vip/i/roViEo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002671,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Barron",
    "name": "Time Traveler",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HmAgiwjjP9CXqK5wQNsHKtjAt2CH3Kv8Q7xH5kGL2nqZ",
    "decimals": 6,
    "image": "https://rango.vip/i/vCzTAu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0004278375829449264,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "xETH",
    "name": "Synthetic ETH",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8bqjz8DeSuim1sEAsQatjJN4zseyxSPdhHQcuuhL8PCK",
    "decimals": 9,
    "image": "https://rango.vip/i/eTkp4w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.6480060109789336,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HuntBoden",
    "name": "Hunter Boden",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "22513u2QwiY6xaJn7nVFWGKy3aBdw6WfZsRPW2RRtCKj",
    "decimals": 6,
    "image": "https://rango.vip/i/H6xngm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004895,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CICADA",
    "name": "Locust Pocus",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HdnUzyXVD64afcdti3asmtWnsSP9TDrRs16UAqoJp9xS",
    "decimals": 6,
    "image": "https://rango.vip/i/6v1wKM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004621,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sRLY",
    "name": "sRLY (Rally Solana)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh",
    "decimals": 9,
    "image": "https://rango.vip/i/xXzF9A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.005976787435696609,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SENDER",
    "name": "Burnie Senders",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9yotbA6u4g2ZZoUajkyS8FntaFUwVRFyLn2Q4PSrfNik",
    "decimals": 9,
    "image": "https://rango.vip/i/YlanOE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CTKOL",
    "name": "catKOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fyr3bF5PatZLcq6odQ7izFyJom1uoS96WftVWdWsJYwq",
    "decimals": 6,
    "image": "https://rango.vip/i/yowwnS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RIBBET",
    "name": "RIBBET",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "32gaR4rn9JyzoDVwMzZ5j3NgcHc5RQhMSJby55FFKnq3",
    "decimals": 9,
    "image": "https://rango.vip/i/fN7sCv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$GARY",
    "name": "GARY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk",
    "decimals": 9,
    "image": "https://rango.vip/i/Y9ahkK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.057897,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "clSUI",
    "name": "Cloned Sui",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "JzwfZvJGdsqbrKZQUvzJpWhbHcZUix7CYcCaoiNpjxg",
    "decimals": 8,
    "image": "https://rango.vip/i/MIMAke",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.792309,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PINGU",
    "name": "Pingu",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ffjrfw9phxYYpQc9fyYq5uRV3K6943Wzo3t9a1L4vfoW",
    "decimals": 4,
    "image": "https://rango.vip/i/KEuwLz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.44298e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "pumpkinSOL",
    "name": "Pumpkin's Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "pumpkinsEq8xENVZE6QgTS93EN4r9iKvNxNALS1ooyp",
    "decimals": 9,
    "image": "https://rango.vip/i/vduJnx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 138,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CIGGS",
    "name": "CHUNGHWA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7p6RjGNZ7HLHpfTo6nh21XYw4CZgxXLQPzKXG72pNd2y",
    "decimals": 9,
    "image": "https://rango.vip/i/lujA53",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JORDAN",
    "name": "Jordan Belfort",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HKmEsdtmRm9WXuEHgJbU3oHyij4EwB3YtEuUrm5qEJFZ",
    "decimals": 9,
    "image": "https://rango.vip/i/EM5exR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DAPE",
    "name": "Degenerate Ape Academy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6AarZpv8KwmPBxBEZdRmd3g1q2tUBaSgTNQ5e621qcZQ",
    "decimals": 2,
    "image": "https://rango.vip/i/QIh9sJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FLTH",
    "name": "FLTH",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FLTHudk5B5zag7JmGXqrYrFfey6otevLQA6jm1UHHLEE",
    "decimals": 9,
    "image": "https://rango.vip/i/PwDtxx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00646488,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "aaDAI",
    "name": "Wrapped DAI (Allbridge from Avalanche)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EgQ3yNtVhdHz7g1ZhjfGbxhFKMPPaFkz8QHXM5RBZBgi",
    "decimals": 9,
    "image": "https://rango.vip/i/HorhPQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$blep",
    "name": "blep :p",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Bkd4k5nLA6Aq7PHLJUFnWGSyWriyT2fcKbADScaBGwJy",
    "decimals": 6,
    "image": "https://rango.vip/i/4RmEQ8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GMECAT",
    "name": "GME CAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DcgDYp2AbHzCSsdhxTds887uk98wtTTA9fd2DLzNi6UZ",
    "decimals": 9,
    "image": "https://rango.vip/i/b6xidb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "swhETH-9",
    "name": "Saber Wrapped Ether (Portal) (9 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "KNVfdSJyq1pRQk9AKKv1g5uyGuk6wpm4WG16Bjuwdma",
    "decimals": 9,
    "image": "https://rango.vip/i/rq1Slb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": " TOBI",
    "name": "MOTO DOG",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6h4ZKWqb9dDW8jaB4d76LNfP8PoRgiSvQLBHnkuih1Ty",
    "decimals": 6,
    "image": "https://rango.vip/i/Nt5SOd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001588,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CWH",
    "name": "Cat Wif Hoodie",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2biAFVhHGr13P2wBKeRjnD1HUsBGFBvbHtMyooVZii7E",
    "decimals": 6,
    "image": "https://rango.vip/i/bfqr3j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KEYDOG",
    "name": "keydog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3fGoNjPohYRVPk9iJuwEW5WuURU5gmAdSRgNgeaanexQ",
    "decimals": 6,
    "image": "https://rango.vip/i/pa0q58",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001712,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "rkSOL",
    "name": "StaRKe SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EPCz5LK372vmvCkZH3HgSuGNKACJJwwxsofW6fypCPZL",
    "decimals": 9,
    "image": "https://rango.vip/i/E2UKfQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KI",
    "name": "Genopets Ki",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "kiGenopAScF8VF31Zbtx2Hg8qA5ArGqvnVtXb83sotc",
    "decimals": 9,
    "image": "https://rango.vip/i/0JovRi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00133715,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$Gretch",
    "name": "Gretchen Witmer",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7ay4X2zcHQRSSZd8NtzNwxqzLtwxoCCRnH2daWn3pump",
    "decimals": 6,
    "image": "https://rango.vip/i/uoq8g7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FRKT",
    "name": "FRAKT Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ErGB9xa24Szxbk1M28u2Tx8rKPqzL6BroNkkzk5rG4zj",
    "decimals": 8,
    "image": "https://rango.vip/i/6NIwPq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00074104,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GMCOCK",
    "name": "Felt Gaming Labs",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8CSTYs264XFrGym2WLy6QGgq6isxU8G4Und9q1gVbbbB",
    "decimals": 8,
    "image": "https://rango.vip/i/4nKku6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "peanie",
    "name": "peanie",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "dekNoN3D8mXa4JHLwTbVXz8aPAyJUkk443UjcSpJKi4",
    "decimals": 6,
    "image": "https://rango.vip/i/6aAtfI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00142815,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FRNT",
    "name": "Final Frontier",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3vHSsV6mgvpa1JVuuDZVB72vYbeUNzW4mBxiBftwzHEA",
    "decimals": 9,
    "image": "https://rango.vip/i/Mfc14F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LEGEND",
    "name": "THE Legend",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EEAy5QjPS5F3Jqqd51MYfHo8enAKtgFZxNnFjbeVpump",
    "decimals": 6,
    "image": "https://rango.vip/i/YOLLkI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000543,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FUJI",
    "name": "FUJI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "fujiCeCeP9AFDVCv27P5JRcKLoH7wfs2C9xmDECs24m",
    "decimals": 3,
    "image": "https://rango.vip/i/z1cCFD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "jenner",
    "name": "Caitlyn Jenner",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4GJ3TCt5mTgQT5BRKb14AkjddpFQqKVfphxzS3t4foZ9",
    "decimals": 6,
    "image": "https://rango.vip/i/ez5tjP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0002420812093932833,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UST",
    "name": "UST (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i",
    "decimals": 6,
    "image": "https://rango.vip/i/WsUFYe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01535935,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "fuseSOL",
    "name": "Fuse Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "fuseYvhNJbSzdDByyTCrLcogsoNwAviB1WeewhbqgFc",
    "decimals": 9,
    "image": "https://rango.vip/i/AbqMSA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EUROe",
    "name": "EUROe Stablecoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2VhjJ9WxaGC3EZFwJG9BDUs9KxKCAjQY4vgd1qxgYWVg",
    "decimals": 6,
    "image": "https://rango.vip/i/1hdCkp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CROX",
    "name": "CROX",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DTEqTxxGFn3SZ4C8tNP35X8iegCCgCBrX974WFSuYVZh",
    "decimals": 9,
    "image": "https://rango.vip/i/ILpwhj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00063774,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sUST-8",
    "name": "Saber Wrapped UST (Portal) (8 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "UST8SCn7jrqsq51odVLqcmvnC658HkqrKrPL3w2hHQ7",
    "decimals": 8,
    "image": "https://rango.vip/i/Q6gN4B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOKU",
    "name": "BōKU Labz",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BkW2v5uv6skTW5c5GYjBctkbY9nuyyHs3gry1dCo5Hra",
    "decimals": 9,
    "image": "https://rango.vip/i/rkEoj8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GER",
    "name": "Germany ",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "52DfsNknorxogkjqecCTT3Vk2pUwZ3eMnsYKVm4z3yWy",
    "decimals": 9,
    "image": "https://rango.vip/i/Gtw5NA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004547,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "pathSOL",
    "name": "Pathfinders SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "pathdXw4He1Xk3eX84pDdDZnGKEme3GivBamGCVPZ5a",
    "decimals": 9,
    "image": "https://rango.vip/i/VbTvrd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 128.12,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BASIS",
    "name": "basis",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa",
    "decimals": 6,
    "image": "https://rango.vip/i/pgGCrr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00225041,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "spurdo",
    "name": "Spurdo Spärde",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8HJ81sHVsmJMzm6XBfTgywWQXwQTmVdmXaQHm7htGPH2",
    "decimals": 9,
    "image": "https://rango.vip/i/WhxsjK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000895,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BMT",
    "name": "BMT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FanJWA4yEVUJj1r83tR7XybxmDGF6bNH8M81ag9aeUbF",
    "decimals": 9,
    "image": "https://rango.vip/i/cdR0Bs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00088888,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DUAL",
    "name": "Dual DAO Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DUALa4FC2yREwZ59PHeu1un4wis36vHRv5hWVBmzykCJ",
    "decimals": 6,
    "image": "https://rango.vip/i/DWQaab",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00392065,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SPACEGOLD",
    "name": "SPACEGOLD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9mXZ54YnJJRmUN2MaMEtWCfFyoncP4ZhKz7U9DZ4JY2X",
    "decimals": 4,
    "image": "https://rango.vip/i/zd1qDY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CFA",
    "name": "Tradfi Bro",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F1n2Tn7Eb9jTbSQiqy2Z7G4VTbkreHGQqcRKKmwZv726",
    "decimals": 9,
    "image": "https://rango.vip/i/Qly9bW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0001318,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "batcat",
    "name": "batcat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EtBc6gkCvsB9c6f5wSbwG8wPjRqXMB5euptK6bqG1R4X",
    "decimals": 6,
    "image": "https://rango.vip/i/JEgJQn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000569,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "speed",
    "name": "real fast",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5Wd2ALxQfnpgQKCyH4WL9giBiiuuLuJs84CJxfQccvmN",
    "decimals": 6,
    "image": "https://rango.vip/i/gOtlBl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00100192,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ICEDOUT",
    "name": "Iced Out Bulls",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "26bedy893CHqi5bcuUFhMgD6uTLw9V9iLWKAQjjDjpEA",
    "decimals": 9,
    "image": "https://rango.vip/i/UDC1EL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "daoSOL",
    "name": "daoSOL Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh",
    "decimals": 9,
    "image": "https://rango.vip/i/zLjGfC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 144.34,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GOFURS",
    "name": "GOFURS Del Sol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4xnxNjLkeVoJEAUFjj5xTvkdTLGYHtrdyyXThGDFhwmr",
    "decimals": 6,
    "image": "https://rango.vip/i/Tqmaoh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 5.62,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "edgeSOL",
    "name": "Edgevana Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "edge86g9cVz87xcpKpy3J77vbp4wYd9idEV562CCntt",
    "decimals": 9,
    "image": "https://rango.vip/i/u1t62c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 146.29,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "strongSOL",
    "name": "Stronghold LST",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "strng7mqqc1MBJJV6vMzYbEqnwVGvKKGKedeCvtktWA",
    "decimals": 9,
    "image": "https://rango.vip/i/NLWgY9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 133.05,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SLO",
    "name": "SmoLanO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E43qU77tnWDwN11o7TtaGMNpxCAqz8RZEZ7PcTCUXSim",
    "decimals": 9,
    "image": "https://rango.vip/i/Yyl0yV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00018015,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "QUEST",
    "name": "QUEST",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1",
    "decimals": 4,
    "image": "https://rango.vip/i/lBqPom",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "aaUSDT",
    "name": "Wrapped USDT (Allbridge from Avalanche)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FwEHs3kJEdMa2qZHv7SgzCiFXUQPEycEXksfBkwmS8gj",
    "decimals": 9,
    "image": "https://rango.vip/i/uhQV48",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FUDSI",
    "name": "Fuddles Shiba Inu",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "91Fv8PztVLsf4S49RANPGW92veJ2oL6BjwsP9i666Wxu",
    "decimals": 6,
    "image": "https://rango.vip/i/ClaufO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BANA",
    "name": "Shibana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BhPXDQio8xtNC6k5Bg5fnUVL9kGN8uvRDNwW8MZBu8DL",
    "decimals": 4,
    "image": "https://rango.vip/i/nSZ8mb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.39158e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "rZOOM",
    "name": "Random Zaysan Raptors",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Vjq9T5xmqRzLXQRyvigzyZzpHCGCsbYAJ7afLVuF8j9",
    "decimals": 9,
    "image": "https://rango.vip/i/VpTsYx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "METAS",
    "name": "METASEER",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3Ztt53vwGhQGoEp3n1RjSu4CFnGRfqzwo6L8KN8gmXfd",
    "decimals": 9,
    "image": "https://rango.vip/i/PyG0oh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WEYU",
    "name": "WEYU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EHaEBhYHWA7HSphorXXosysJem6qF4agccoqDqQKCUge",
    "decimals": 8,
    "image": "https://rango.vip/i/uv4jby",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EURO2024",
    "name": "EURO2024",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "D5oCx51J1hgwGAaaWgn4aKqU8TvbaTDx3NtNUdkKrGgq",
    "decimals": 9,
    "image": "https://rango.vip/i/fiwuDB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Norm",
    "name": "Norman",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9M26M7CxkJdaewdiH8v5kSiiXKbWBBirTE1QmXHmDvVg",
    "decimals": 6,
    "image": "https://rango.vip/i/TzFFVi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00016022,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PEEP",
    "name": "Peepo",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "n54ZwXEcLnc3o7zK48nhrLV4KTU5wWD4iq7Gvdt5tik",
    "decimals": 6,
    "image": "https://rango.vip/i/VQyRCD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000522,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$SSHIB",
    "name": "SSHIB",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6VHL2vMKgrF1YQFSv29Rs1pj9VCRK29bD11NtDqerqHA",
    "decimals": 5,
    "image": "https://rango.vip/i/q1VlZY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.632e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$BEAR",
    "name": "BEAR",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "24a1k6wSDQUSwoRXJyvkr25kF5do8f6Ddff6A2fTc1pM",
    "decimals": 9,
    "image": "https://rango.vip/i/uGtp7t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "calUSD",
    "name": "calUSD Stablecoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CALusHembJf3tQ69BxFbLRUSpGRwKzEnLKWUPhQo5dFk",
    "decimals": 9,
    "image": "https://rango.vip/i/I9jzh9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "apUSDC",
    "name": "Wrapped USDC (Allbridge from Polygon)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "eqKJTf1Do4MDPyKisMYqVaUFpkEFAs3riGF3ceDH2Ca",
    "decimals": 6,
    "image": "https://rango.vip/i/pH6vhs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$JPMT",
    "name": "Jake Paul Mike Tyson",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7ErxzRN1hpyMZC8gps7ANZFTGgeDG7cFmVZcMfE6oGrd",
    "decimals": 6,
    "image": "https://rango.vip/i/Sw452Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Mbapepe",
    "name": "Mbapepe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5cJUt4HzoVpRfwAqeNEDfbFJLVNxSpV9rzgPyyY3XLJx",
    "decimals": 9,
    "image": "https://rango.vip/i/wcimtP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000021460453162681812,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GMFC",
    "name": "Gamify Club",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "42Y3CgJQLnHjdScYMu8VS4TbeZMUNVdBMKYbf7hz7aum",
    "decimals": 6,
    "image": "https://rango.vip/i/gVjw1n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Jorlps",
    "name": "Jorlps",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EA4SyW5UDnntXwUyyogN9UgpLU4q71HWgjhJBFUHTqLY",
    "decimals": 9,
    "image": "https://rango.vip/i/xWSNde",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHYCAT",
    "name": "ShyCat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6WErZ2aMZYLUyzbP9n5gm4fwHJbv9Ln8yimSQRwZpump",
    "decimals": 6,
    "image": "https://rango.vip/i/LL2B7P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$SOLO",
    "name": "Solordi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "J8cKU4pD2NTSovvV5XghWHQiJy5TTEzgSyozorxz6ax8",
    "decimals": 9,
    "image": "https://rango.vip/i/Vj7zxc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00051527,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Blob",
    "name": "DumbGayRetard",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "85vb4pd3gyVMxQ211ZBXXWQJwJ4xZPBC5AQXvnmb1HTi",
    "decimals": 8,
    "image": "https://rango.vip/i/L64XdP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 6.818e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DUBAI",
    "name": "Habibi Come To Dubai",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "93NhryHqdN5eVz2n4Qoof7ELwnTcgHBw44tmGcLzxKXz",
    "decimals": 9,
    "image": "https://rango.vip/i/Zukp6f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PHY",
    "name": "Physis",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EswgBj2hZKdgovX2ihWSUDnuBg9VNbGmSGoH5yjNsPRa",
    "decimals": 9,
    "image": "https://rango.vip/i/Dqq6t7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00556351,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "rot",
    "name": "brainrot",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "APoM2sXUzdRHTkUjXSsdUheX1wPPdP4HFLotmtRNMU8P",
    "decimals": 6,
    "image": "https://rango.vip/i/nrGiDD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00190687,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POOT",
    "name": "Poot Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CErSpNnEHUNsNw3AZJhyvekwhMpr9H3W2S71uA3pzJus",
    "decimals": 9,
    "image": "https://rango.vip/i/c30PM6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BabyBonk",
    "name": "Baby Bonk",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Dx1Lq5FjangW5ifRMEogAiakm24LyB5AoHmQifepvNjV",
    "decimals": 9,
    "image": "https://rango.vip/i/5yUGjV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0010523,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$CWIF",
    "name": "catwifhat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7atgF8KQo4wJrD5ATGX7t1V2zVvykPJbFfNeVf1icFv1",
    "decimals": 2,
    "image": "https://rango.vip/i/2WOKrT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 6.69034e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FEEL",
    "name": "FEEL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FEELeKVxHtsHHvu1ARySjETxnJMKbLC6wHCaMD68ipiR",
    "decimals": 9,
    "image": "https://rango.vip/i/G1Yvig",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "soBTC",
    "name": "Wrapped Bitcoin (Sollet)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E",
    "decimals": 6,
    "image": "https://rango.vip/i/JVHFLf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1675.1,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PURPE",
    "name": "Purple Pepe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HBoNJ5v8g71s2boRivrHnfSB5MVPLDHHyVjruPfhGkvL",
    "decimals": 1,
    "image": "https://rango.vip/i/rNqkGW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001124,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ROLL",
    "name": "HRHC Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "76aYNHbDfHemxSS7vmh6eJGfjodK8m7srCxiYCrKxzY1",
    "decimals": 6,
    "image": "https://rango.vip/i/IpJRTm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00032302,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$BEN",
    "name": "BEN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2Ry7AGS1w488LdSxhz2XvSrU1EVUzUGJo27DBP57couh",
    "decimals": 7,
    "image": "https://rango.vip/i/pYRgT1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000121,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SolBlock",
    "name": "SolBlock AI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3gxEC9N9SGgoaRiey3AkbynbHcHsdmgrKDQf31zsiJvm",
    "decimals": 9,
    "image": "https://rango.vip/i/1icGEp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00072032,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JIMMY",
    "name": "JIMMY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "94XduSfSnyas7jAEFSJSXiCi1xQ4mENWcii1aCvjVuqu",
    "decimals": 9,
    "image": "https://rango.vip/i/lTfuat",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00023439,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$STICKY",
    "name": "stickmanpride",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8tgtGXhYRGKtaF4CPeVzSQpM3cUXBZ8P4ZW4hEhyLWZZ",
    "decimals": 6,
    "image": "https://rango.vip/i/S30mq8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000347,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLA",
    "name": "Sola Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FYfQ9uaRaYvRiaEGUmct45F9WKam3BYXArTrotnTNFXF",
    "decimals": 9,
    "image": "https://rango.vip/i/W7P1m8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00076731,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "clARB",
    "name": "Cloned Arbitrum",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9cMWa1wuWcio3vgEpiFg7PqKbcoafuUw5sLYFkXJ2J8M",
    "decimals": 8,
    "image": "https://rango.vip/i/mvpYsI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.466706,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$MOONDELA",
    "name": "Nelson Moondela",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BSXvX9dMvedcXUvwUQ5pzYJb9G8bBXKtddQns4oAxgEo",
    "decimals": 6,
    "image": "https://rango.vip/i/dnjdyv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BUSDbs",
    "name": "Binance USD (Portal from BSC)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5RpUwQ8wtdPCZHhu6MERp2RGrpobsbZ6MH5dDHkUjs2",
    "decimals": 8,
    "image": "https://rango.vip/i/gJDUNJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sRLY",
    "name": "Rally (Legacy - deprecated)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq",
    "decimals": 9,
    "image": "https://rango.vip/i/xXzF9A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.005976787435696609,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NEKI",
    "name": "Maneki-neko",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ALKiRVrfLgzeAV2mCT7cJHKg3ZoPvsCRSV7VCRWnE8zQ",
    "decimals": 9,
    "image": "https://rango.vip/i/ztvuq6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004911,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RUSTY",
    "name": "First Youtube dog  - Community",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ayy1QvG5vR6nJ9fdijWWTrvNmjVfEhGGoQrX9nhZ6Dg3",
    "decimals": 9,
    "image": "https://rango.vip/i/IVI47j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CROCHET",
    "name": "Crochet World",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Aqjju8gCv3Uc2XsmF5x92ZarDo3hCnP7EgUeDkv1i7jK",
    "decimals": 9,
    "image": "https://rango.vip/i/h5QnsF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001674,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Cope",
    "name": "Cope",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "o1Mw5Y3n68o8TakZFuGKLZMGjm72qv4JeoZvGiCLEvK",
    "decimals": 2,
    "image": "https://rango.vip/i/QWV2sr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 8.33358e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wifSOL",
    "name": "wifSOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fi5GayacZzUrfaCRCJtBz2vSYkGF56xjgCceZx5SbXwq",
    "decimals": 9,
    "image": "https://rango.vip/i/Z83xgq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ANSEM",
    "name": "SoylanaManletCaptainZ",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3Lec18q7nPM62LQwqXG2ddiBTDrFCiNw1NEA1ehBZPgB",
    "decimals": 1,
    "image": "https://rango.vip/i/7xJW4U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000014,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "doky",
    "name": "Donkey King",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5Rs53fY3q4t4mLk9zBQ45cVNgF1RH7NqRNXiP6Pa5rYH",
    "decimals": 6,
    "image": "https://rango.vip/i/o0f8tG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000305,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ETX",
    "name": "Ethrix",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9DZ58i5vAfk3JaFVYezYzhrVht7j8McZsUbuTcDiSbrP",
    "decimals": 5,
    "image": "https://rango.vip/i/D70Xs7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002434,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VICE",
    "name": "VICE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AWeL8nCXxJm3GZkEVRqxeeT5KWT2KwDa1shgQFeBogkC",
    "decimals": 9,
    "image": "https://rango.vip/i/QHkunB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MRC",
    "name": "Moon Roll Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5HsZR8eG7QpQcN8Mnp8oFdENRkJMP9ZkcKhPSCKTJSWh",
    "decimals": 9,
    "image": "https://rango.vip/i/JwCZWX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00114316,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FELON",
    "name": "Convicted Felon",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "f1niVdDCEAxcKg5a1pdsvwN18AQHBJQQcgATWob3M8r",
    "decimals": 9,
    "image": "https://rango.vip/i/1ZOnRc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$CRYPT",
    "name": "Crypt",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CRYPTi2V87Tu6aLc9gSwXM1wSLc6rjZh3TGC4GDRCecq",
    "decimals": 9,
    "image": "https://rango.vip/i/6619nD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00039978,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$GGEM",
    "name": "Guild Saga Gems",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GGEMxCsqM74URiXdY46VcaSW73a4yfHfJKrJrUmDVpEF",
    "decimals": 9,
    "image": "https://rango.vip/i/dfZOE6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DOCS",
    "name": "SolDocs",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "gr1qPTo3tpMAxt59BftQo2uSfRHRuUZJaWLhR8ADtwz",
    "decimals": 9,
    "image": "https://rango.vip/i/KlLWPN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00080575,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$DRUNK",
    "name": "DRUNK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EaRMzBwWRwvSgus8rfdZGfdzX3ZKRKoCL7U36G7xfvcb",
    "decimals": 9,
    "image": "https://rango.vip/i/IRQoKU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00068406,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOGDOG",
    "name": "MOGDOG",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Bzu1nWVKRFEn7FRumTNrTC4qqxtBaMCMBNY1z7ejpump",
    "decimals": 6,
    "image": "https://rango.vip/i/IeRdN5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001241,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOULO",
    "name": "SouloCoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Gz3u6eJaKEviYpPC5AwUziz891kNX76PNdsmJrnaNNY4",
    "decimals": 9,
    "image": "https://rango.vip/i/jwi5qU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008686,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FINDER",
    "name": "Gem Finder",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Dn7mshRUg4LEq1RfMLz27ViL2P16hn8p12bGBVZsiyak",
    "decimals": 9,
    "image": "https://rango.vip/i/ZeWacB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01258695,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "milly",
    "name": "milly",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6yNcxrS1Fd6Ut3c3bTuJKQLG9xzpbMZCBqTahvpeAXjk",
    "decimals": 9,
    "image": "https://rango.vip/i/U1mrMW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000011764729172387605,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "THOG",
    "name": "Theory Of Gravity",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5CqfXex1knfRiozwDtgFFNaiGR9TsmSUcWDNUTUGZQru",
    "decimals": 6,
    "image": "https://rango.vip/i/DAHvAL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00132411,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Pepe",
    "name": "Pepe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "B5WTLaRwaUQpKk7ir1wniNB6m5o8GgMrimhKMYan2R6B",
    "decimals": 6,
    "image": "https://rango.vip/i/2gbdIy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00280745,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ABULL",
    "name": "AumentoBULL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "J3hxVJtn3kGtr1tHkJSWXvMr2D4M8iBzxzB75zywxVzF",
    "decimals": 9,
    "image": "https://rango.vip/i/KsEH4j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "IRS",
    "name": "Internal Retard Service",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A3tCD8Q1yjdy3gRDJ4LFzpECnafW3sHNa3dJKHesG9Xf",
    "decimals": 9,
    "image": "https://rango.vip/i/pv4u8a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$RKT",
    "name": "Racket",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "RKT69NZHN5uovcan3q5hRbZzfJuXiGEuPmGANoBJLLz",
    "decimals": 5,
    "image": "https://rango.vip/i/VGeG68",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00013044,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Pika",
    "name": "Pika",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "42o42KH1dzEDjqijWpWHcNtpmW42Hxzg7YbMs1h6A5r1",
    "decimals": 6,
    "image": "https://rango.vip/i/bo0JHk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.979576976045381e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FM",
    "name": "Flowmatic",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Eh1fXbAipe4k7CYR9UMb2bbWmBcpU3HcyX3LWuRVFBLz",
    "decimals": 4,
    "image": "https://rango.vip/i/KZKCUl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.073376,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "fUSD",
    "name": "Synthetic USD (Fabric)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "B7mXkkZgn7abwz1A3HnKkb18Y6y18WcbeSkh1DuLMkee",
    "decimals": 8,
    "image": "https://rango.vip/i/NJD24D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.4131892330205793,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "D/ACC",
    "name": "D/ACC",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FabjHjc1druUQoHVtudpNiCpnf73rtLzMkRM1b5NSbb6",
    "decimals": 9,
    "image": "https://rango.vip/i/sGrKRp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003056,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MYKE",
    "name": "Myke Thysen",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DV2TCTbENV9xJ14kHC89qd1w2KZB6efvZKHNRpoo5cuD",
    "decimals": 6,
    "image": "https://rango.vip/i/ViV7ay",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CRYY",
    "name": "CRY Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g",
    "decimals": 9,
    "image": "https://rango.vip/i/5780dU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$NEON",
    "name": "NeonGame Credits",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DuSyBCGuhPvyGu6cSvbZonvQvh8JLyGvXJn1TmkJh6Zn",
    "decimals": 0,
    "image": "https://rango.vip/i/FCf7gK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "chibi",
    "name": "chibi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HfXLMuSCHvsuYHRLekyW88oiz1LJvRerKh4QH1y5f8xk",
    "decimals": 6,
    "image": "https://rango.vip/i/BnJ4Uw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000317,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Moutai",
    "name": "Moutai",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "45EgCwcPXYagBC7KqBin4nCFgEZWN7f3Y6nACwxqMCWX",
    "decimals": 6,
    "image": "https://rango.vip/i/M2aJm1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.005632829155867045,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PHOX",
    "name": "PHOX",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9Pug19UEjW5wiFG5bLWYpjtz6s9K8ZGXmRvZwyfX7SNy",
    "decimals": 6,
    "image": "https://rango.vip/i/6nLIat",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHARKI",
    "name": "sharki",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6U48jtR53ZK3E1MozLrpwJDTrtj74uuFhMGNzGY18YPu",
    "decimals": 9,
    "image": "https://rango.vip/i/b3DfLH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00056558,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$BOZO",
    "name": "BOZO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BoZoQQRAmYkr5iJhqo7DChAs7DPDwEZ5cv1vkYC9yzJG",
    "decimals": 5,
    "image": "https://rango.vip/i/qeYDc9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.3248e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WAVE",
    "name": "Lost At Sea WAVE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GS6E87SLTioRDG3uSVRwQmuKKMxDmWU7fktCTJ5xkEM8",
    "decimals": 9,
    "image": "https://rango.vip/i/mAbgpV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.85936e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Green",
    "name": "Phthalocyanine Green",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4iEkSPpXxjsD6fRY9UmCGX73NJch5yakPzYt6McLpump",
    "decimals": 6,
    "image": "https://rango.vip/i/Vfowvf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0036597946384585582,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TEIT",
    "name": "Andru Teit",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GzWP5TSam9VBdPx2bA4US2E3sX5pP65JMZWCyYFY9ZbF",
    "decimals": 6,
    "image": "https://rango.vip/i/zG4ptT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "T1NY",
    "name": "Tiny Bonez",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    "decimals": 9,
    "image": "https://rango.vip/i/SQA133",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BITARD",
    "name": "BITARD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2ZZaE2gNQSPZLEFiNzBewgQhj3wGpx4oDzuAawh4DHBB",
    "decimals": 9,
    "image": "https://rango.vip/i/nCaCL9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000911,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$TURBO",
    "name": "TURBO on Solana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "42KmnheUHt3UaQFoAKnipLgBJ1Zm427heJdQcHGPtnjc",
    "decimals": 7,
    "image": "https://rango.vip/i/112BiP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PU238",
    "name": "Plutonium 238",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7p6zGHdmWHvCH4Lsik2MoMBXqPGhFbSPSceSBXd8KNEC",
    "decimals": 6,
    "image": "https://rango.vip/i/yR2RFh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sUSDC-8",
    "name": "Saber Wrapped USD Coin (8 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "88881Hu2jGMfCs9tMu5Rr7Ah7WBNBuXqde4nR5ZmKYYy",
    "decimals": 8,
    "image": "https://rango.vip/i/2KwGUL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POVT",
    "name": "Phil Overdrive | Youtube",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PhiLR4JDZB9z92rYT5xBXKCxmq4pGB1LYjtybii7aiS",
    "decimals": 5,
    "image": "https://rango.vip/i/fnoVaq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$PUPPA",
    "name": "PUPPA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8shTEDxbwvM2C9ELXNHsh8pmkoWovzJ3xgazQFWFapMx",
    "decimals": 6,
    "image": "https://rango.vip/i/avk3jD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00016584,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Murgan",
    "name": "Piers Murgan",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "63kAw6KkS7fSqJ4RADJqdYFcB8QVEZz2BjA9m81sEwpN",
    "decimals": 6,
    "image": "https://rango.vip/i/XdOCsU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$FORCE",
    "name": "Force",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC",
    "decimals": 9,
    "image": "https://rango.vip/i/xHxMAe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "bonkSOL",
    "name": "Bonk SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BonK1YhkXEGLZzwtcvRTip3gAL9nCeQD7ppZBLXhtTs",
    "decimals": 9,
    "image": "https://rango.vip/i/6zIjR7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 133.91,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WVE",
    "name": "everwave",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "33MJjPS6H7P2nwD648uiTiUu8v5fJ1zKyjnVqeWZ344F",
    "decimals": 9,
    "image": "https://rango.vip/i/T7thOX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FBZ",
    "name": "FakeBiz",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BKydRTNdaMJ8B4zPva3YhwUQcpvAsyZaGJnKA6F44fX7",
    "decimals": 9,
    "image": "https://rango.vip/i/IjWIr5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "clockSOL",
    "name": "Overclock SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GRJQtWwdJmp5LLpy8JWjPgn5FnLyqSJGNhn5ZnCTFUwM",
    "decimals": 9,
    "image": "https://rango.vip/i/dCDrQw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 138.06,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$gerta",
    "name": "gerta",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DLvWoNT1d5iSX2T1aUUBzRdGbSef2xW3MwJBKtewVW6h",
    "decimals": 6,
    "image": "https://rango.vip/i/F8glDs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001473,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KIWI",
    "name": "KIWI Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "66Qq2qS67K4L5xQ3xUTinCyxzdPeZQG1R1ipK8jrY7gc",
    "decimals": 5,
    "image": "https://rango.vip/i/Nnotru",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000681,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TPC",
    "name": "TOYPOODLE COIN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5WWRMYPchxgh3VmYGPqoq2kfzCtBLxXB9vFH2TeFeR9m",
    "decimals": 4,
    "image": "https://rango.vip/i/glgNYQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POW",
    "name": "People of Walmart",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "wA1MArtTmL1yYtweBjyHZZdteHQRd5fgwsUKx7iiqKU",
    "decimals": 6,
    "image": "https://rango.vip/i/WzW4w6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00284352,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Leia",
    "name": "Leia",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9EPYaNqzJLSPSFH9aKSxn2EaJoxHS1oqt7pVBR3yivt",
    "decimals": 6,
    "image": "https://rango.vip/i/AxPNOg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006815,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "cgntSOL",
    "name": "Cogent SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CgnTSoL3DgY9SFHxcLj6CgCgKKoTBr6tp4CPAEWy25DE",
    "decimals": 9,
    "image": "https://rango.vip/i/cHFZcS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 146.37,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PUMPMAN",
    "name": "Pump Man",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BU7mDyqDEYMwB7gPyAHf7MRXSmT9DNMZn7o3AZavYL1R",
    "decimals": 6,
    "image": "https://rango.vip/i/QPFaBi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KREECHURE",
    "name": "Kreechures",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3XwZtyP5g2ku5cNzLXYcBYygvToXKdjw73GSRq73QYLQ",
    "decimals": 2,
    "image": "https://rango.vip/i/2JOfy4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sUSDT-9",
    "name": "Saber Wrapped USDT (9 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AEUT5uFm1D575FVCoQd5Yq891FJEqkncZUbBFoFcAhTV",
    "decimals": 9,
    "image": "https://rango.vip/i/rFcpfD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "doodoo",
    "name": "DooDoo (Wormhole)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "JDwzFSxcUvLubUb9xAuuZNvh4bbcEJcuM9TezpmRHVWF",
    "decimals": 8,
    "image": "https://rango.vip/i/Ox7Xhb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.304216,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GCR",
    "name": "GCRClassic",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "791hZNiCJy1qGSGzAvqUU8X6gejiBJ2mBV8JjYoVnzBR",
    "decimals": 6,
    "image": "https://rango.vip/i/UO6Rpk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001958,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "lilpump",
    "name": "Lil Pump",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9vrGUHwsC8LyLjQoh3zJb9S53x7A88u49La63qPB6F5t",
    "decimals": 6,
    "image": "https://rango.vip/i/hLkY6J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00067467,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "green",
    "name": "solana green",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7VUUicqKYFVmewg1U4w4e9K4FoEYdTcYyxYCPhRNf5Qy",
    "decimals": 9,
    "image": "https://rango.vip/i/IgDfYI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0036597946384585582,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AXSet",
    "name": "Axie Infinity Shard (Portal from Ethereum)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HysWcbHiYY9888pHbaqhwLYZQeZrcQMXKQWRqS7zcPK5",
    "decimals": 8,
    "image": "https://rango.vip/i/L9uyNj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.65,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$KEIF",
    "name": "Keif The Kat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9QgXH6RjuLx5izvgRU1ovzackRsbzQoe415mxHUZJkkH",
    "decimals": 6,
    "image": "https://rango.vip/i/4C8pYU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DFTZ",
    "name": "DeFiTimeZ",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "71WsPPm44SUTWDfcCdLKsDezw4ZBXDrPjHz2rea7Lo97",
    "decimals": 7,
    "image": "https://rango.vip/i/vBeKdv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SWAG",
    "name": "swag coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor",
    "decimals": 2,
    "image": "https://rango.vip/i/iOOFOC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00155537,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JURDEN",
    "name": "Jurden Peeturdson",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HYWB4HkyfDSA1i4t4cQaK9cvLHG4nQBRzGX9tzmEmgDr",
    "decimals": 6,
    "image": "https://rango.vip/i/TSSvLj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NLTK",
    "name": "Nasi Lemak",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3uNAevHamuZKKQdtdLzmHNvqD8r14tXUUXx5PN48UbYC",
    "decimals": 9,
    "image": "https://rango.vip/i/8b7e23",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HOPE",
    "name": "HOPE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9Zhvjcce3kHbYQGojybM9GbtE76rvoMJM36egCcmPs2s",
    "decimals": 9,
    "image": "https://rango.vip/i/MUba4j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00057844,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POOTI",
    "name": "POOTI (RELAUNCH)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2nDpiBboQgHcBuRyNwCQzc5fcpxBZKQ1t37pr5aNFczn",
    "decimals": 6,
    "image": "https://rango.vip/i/J7HSHB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00005547,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sMEAN",
    "name": "Staked MEAN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "sMEANebFMnd9uTYpyntGzBmTmzEukRFwCjEcnXT2E8z",
    "decimals": 6,
    "image": "https://rango.vip/i/0VBc6Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DINOEGG",
    "name": "DINOEGG",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2TxM6S3ZozrBHZGHEPh9CtM74a9SVXbr7NQ7UxkRvQij",
    "decimals": 6,
    "image": "https://rango.vip/i/uez1Ay",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GYC",
    "name": "GameYoo Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GYCVdmDthkf3jSz5ns6fkzCmHub7FSZxjVCfbfGqkH7P",
    "decimals": 9,
    "image": "https://rango.vip/i/8zuNaD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FANT",
    "name": "Phantasia",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r",
    "decimals": 6,
    "image": "https://rango.vip/i/y25vCn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDTpo",
    "name": "Tether USD (PoS) (Portal from Polygon)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5goWRao6a3yNC4d6UjMdQxonkCMvKBwdpubU3qhfcdf1",
    "decimals": 6,
    "image": "https://rango.vip/i/xRBGeO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.988031,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "XCOPE",
    "name": "XCOPE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3K6rftdAaQYMPunrtNRHgnK2UAtjm2JwyT2oCiTDouYE",
    "decimals": 0,
    "image": "https://rango.vip/i/yfHEoX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Puri",
    "name": "Puri",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CzLWmvjtj5bp9YEWkzzBnuWovVtogeuchFu5GeFh68hB",
    "decimals": 9,
    "image": "https://rango.vip/i/1a6aYF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OLYMP24",
    "name": "OLYMPIC 2024",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4e1SQnaJn9JEAp7KYJwrQp3V4ED1dSkPygYbfFQM11uA",
    "decimals": 6,
    "image": "https://rango.vip/i/tXzP46",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Froggo",
    "name": "Froggo",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A12XggFFk3b5GCd6ZYxuQ55cQbxarHL4h7Jxs3GQcdC3",
    "decimals": 9,
    "image": "https://rango.vip/i/yDLvJ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MEWING",
    "name": "Mewing Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9bxaVJmUwSc71j8Z2pvUL3UAr1s5fCnwUpvYhqV9jtmw",
    "decimals": 6,
    "image": "https://rango.vip/i/bXUMKN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00021705,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MuShu",
    "name": "Dragon",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CF97pGa5aNhXYkERsYLbAZ1wyk6cSFrfKhd1ansRD3So",
    "decimals": 10,
    "image": "https://rango.vip/i/WrbkVZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Tyrese",
    "name": "Tyrese filmed the moon landing",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EKiwUg6K1aAyfF1uugHxEYPQksVTkCeHorkr8XwsoNAa",
    "decimals": 9,
    "image": "https://rango.vip/i/I3D3SD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZPET",
    "name": "Zeus Pet AI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4AnuSHLSYc4J6yjNekrYMjknL4zJHCvRLXfUxgpmCLeQ",
    "decimals": 3,
    "image": "https://rango.vip/i/RRU3WD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BARK",
    "name": "BarkCoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "APkM2bqzpwQaiv5BY2eA7vR7DEB1EX5MMf6JNcBCHYrC",
    "decimals": 6,
    "image": "https://rango.vip/i/UgELY7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008594,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VOTEDOGE",
    "name": "Doge for President",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "98ZmjtNLjTr1yih6D7fdFm8ViU1jEYDcBU1wjc2k9imV",
    "decimals": 9,
    "image": "https://rango.vip/i/ZIWIe4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00058561,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SLCL",
    "name": "Solcial token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV",
    "decimals": 9,
    "image": "https://rango.vip/i/FpcMNe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00038467,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SEEDED",
    "name": "Seeded Network",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "seedEDBqu63tJ7PFqvcbwvThrYUkQeqT6NLf81kLibs",
    "decimals": 9,
    "image": "https://rango.vip/i/mEw5pq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00053996,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CREDITS",
    "name": "SDrive Credit Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4ptcYkUypE7sDH82oXaeykaAJunuB4yeDJeiLJwS2nQc",
    "decimals": 8,
    "image": "https://rango.vip/i/mCRkj6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BUSDet",
    "name": "Binance USD (Portal from Ethereum)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "33fsBLA8djQm82RpHmE3SuVrPGtZBWNYExsEUeKX1HXX",
    "decimals": 8,
    "image": "https://rango.vip/i/tvh5wL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ARTE",
    "name": "ARTE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6Dujewcxn1qCd6rcj448SXQL9YYqTcqZCNQdCn3xJAKS",
    "decimals": 6,
    "image": "https://rango.vip/i/Hst3WN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TENKAI",
    "name": "Tenkai Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "TKDrcm3n4mfXFfPKZoLp5soRSdFQSmyWLdomdKL3ktU",
    "decimals": 0,
    "image": "https://rango.vip/i/qpAyHl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "clDOGE",
    "name": "Cloned Doge",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Dd6Pde7K4J7yB691gW8K177TWd1Huy6RkfTwrbkz8Fre",
    "decimals": 8,
    "image": "https://rango.vip/i/E7Ite8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.108672,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HEIST",
    "name": "The Heist",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FjBedzf7WrWmo4yd3Tc2U6indeEQXtdewZ514hYxH5X9",
    "decimals": 2,
    "image": "https://rango.vip/i/HaNG40",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TWTR",
    "name": "Twitter",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FcScaNdN3TRPMwcgqHj1E5GuEh1rNesBGVN5WtZuad1z",
    "decimals": 9,
    "image": "https://rango.vip/i/s9mBQr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000106,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "maga",
    "name": "maek amuraca graet agun",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "TrumptpNNBEgVjDc8bnemRTNYZKhdsst9ujNAnTSHqp",
    "decimals": 6,
    "image": "https://rango.vip/i/PkipDk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004859,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BIJU",
    "name": "BIJU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4onzDs1X6ubktirorHB8iYbve3K4bBtkGpYehqzGm9So",
    "decimals": 9,
    "image": "https://rango.vip/i/n5mWZs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SVIZ",
    "name": "Space Vizsla",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ",
    "decimals": 9,
    "image": "https://rango.vip/i/sAWJdc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "soSUSHI",
    "name": "Wrapped SUSHI (Sollet)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy",
    "decimals": 6,
    "image": "https://rango.vip/i/FORNSq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KRILL",
    "name": "KRILL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EP2aYBDD4WvdhnwWLUMyqU69g1ePtEjgYK6qyEAFCHTx",
    "decimals": 9,
    "image": "https://rango.vip/i/pXYYX6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0001008,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KENG",
    "name": "Keng Boldwen",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BKykA4H1s4717FhBGFhDXkKF4UzvvsWB4uxqHBWX8FUU",
    "decimals": 3,
    "image": "https://rango.vip/i/wDbdnk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ELE",
    "name": "Elementerra",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8A9HYfj9WAMgjxARWVCJHAeq9i8vdN9cerBmqUamDj7U",
    "decimals": 9,
    "image": "https://rango.vip/i/d1hqy1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000123,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MEND",
    "name": "Mend",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ch9NFVk5sqEPQHtw2gJVgnHfTm7FW1JspYwc7SxLi6q3",
    "decimals": 9,
    "image": "https://rango.vip/i/m1bZJ5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000588,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GRAF",
    "name": "Smoking Giraffe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9EL3CHVQS3nwUFhyVT7AGbttRsbJ5UE8Qjnw5ZAtkUhr",
    "decimals": 5,
    "image": "https://rango.vip/i/twRtNO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00035089,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "hubSOL",
    "name": "SolanaHub staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HUBsveNpjo5pWqNkH57QzxjQASdTVXcSK7bVKTSZtcSX",
    "decimals": 9,
    "image": "https://rango.vip/i/TUIzkI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 133.03,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "yPRT",
    "name": "yPRT (Parrot Yield Token)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM",
    "decimals": 6,
    "image": "https://rango.vip/i/1ebRey",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "abUSDC",
    "name": "Wrapped USDC (Allbridge from BSC)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8XSsNvaKU9FDhYWAv7Yc7qSNwuJSzVrXBNEk7AFiWF69",
    "decimals": 9,
    "image": "https://rango.vip/i/pH6vhs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SCALES",
    "name": "SCALES",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8FA3TPf84h9gEZAxDhiLkMN4vvZcdEUZxmGadbo12auh",
    "decimals": 5,
    "image": "https://rango.vip/i/aQAYEN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.177e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "pwrSOL",
    "name": "Power Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "pWrSoLAhue6jUxUkbWgmEy5rD9VJzkFmvfTDV5KgNuu",
    "decimals": 9,
    "image": "https://rango.vip/i/9Hq0ub",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 139.61,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "srenBTC-9",
    "name": "Saber Wrapped renBTC (9 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FACTQhZBfRzC7A76antnpAoZtiwYmUfdAN8wz7e8rxC5",
    "decimals": 9,
    "image": "https://rango.vip/i/STagYd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LEGEND",
    "name": "Legends of SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LGNDeXXXaDDeRerwwHfUtPBNz5s6vrn1NMSt9hdaCwx",
    "decimals": 9,
    "image": "https://rango.vip/i/k8UmiI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000543,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WIZE",
    "name": "Project Wisdom",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7rrJLRar2WjZwRoF3iJKHKnA7d7d9YJT1X9HAJnwUH3Z",
    "decimals": 6,
    "image": "https://rango.vip/i/EiAACM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HEEHEE",
    "name": "HeeeHeee",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9dLuVbJMd4ZpTpFgmaFHAGSsFwVjtcnzFWaLAA1expHg",
    "decimals": 6,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0010799,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHADS",
    "name": "Chads",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DjzG1gPn2cotdV9cvkho4KxD3Poy2VjJeghCivv8U8Wj",
    "decimals": 2,
    "image": "https://rango.vip/i/Q9h1lT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sBTC-9",
    "name": "Saber Wrapped Bitcoin (Sollet) (9 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9999j2A8sXUtHtDoQdk528oVzhaKBsXyRGZ67FKGoi7H",
    "decimals": 9,
    "image": "https://rango.vip/i/ECHtWh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "duk",
    "name": "duk",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A2khRbhRJNrAEHj95htivC4cR4VbJwfssDH5FPPbP4m9",
    "decimals": 6,
    "image": "https://rango.vip/i/awR6Hx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00022576,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "fPHX",
    "name": "FillmorePHX",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6gx6Ph2ek73kF6EWDrG4GQ54pcLJB6CYpATuRyxKXumo",
    "decimals": 9,
    "image": "https://rango.vip/i/bURa57",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Kini",
    "name": "Kini Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GV4pBbdAp1Apvf6PPFR4VvL6BtNg4E7VY91GmkHqpump",
    "decimals": 6,
    "image": "https://rango.vip/i/E6sQar",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002041,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "opple",
    "name": "steep jubs",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BG5Dp9gU5WbkHEaz6y95apb5NVPw3jC17M4ro27sgSXP",
    "decimals": 9,
    "image": "https://rango.vip/i/dZImgZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0001413,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NVDA",
    "name": "NVIDIA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9dwPiStDBwJJqC3QzMnjpJP7xohZbMVmHELFx3uy3KRq",
    "decimals": 9,
    "image": "https://rango.vip/i/oZiea6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MROW",
    "name": "MROW",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GTeZ6qkRgHS4MZq9nSPvgtFWSNLn1HFq8rx5bSVktGub",
    "decimals": 9,
    "image": "https://rango.vip/i/KFTSLL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FJB",
    "name": "FJB",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PRZdvhsihALAYQAwCaQYCSVTbkxfT9Fme28p19Np9VD",
    "decimals": 6,
    "image": "https://rango.vip/i/1o83pU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HOWDY",
    "name": "Howdy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ppVT3Vqb323UBEW3QuTvdNCpQm1spEZM8Bt1tv9WALW",
    "decimals": 6,
    "image": "https://rango.vip/i/LLNkIi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00017357,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UGM",
    "name": "USER GENERATED MONEY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2Ey5sa3XTtjaR6KGaVmX48MUtUAmrBT14JeWjQWJcc4h",
    "decimals": 6,
    "image": "https://rango.vip/i/ziMKsy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DEV",
    "name": "DevCoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "H5euuuZXAuFak2NVTMu53fckdkHFWuJzXXb3TfKTrLWK",
    "decimals": 9,
    "image": "https://rango.vip/i/2Ld3C6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.085988,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Mail",
    "name": "SolMail",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "C8cNX2D1y3jqKpMFkQhP1gGbfvTEdeckZXLBKSN5z5KF",
    "decimals": 6,
    "image": "https://rango.vip/i/0cCNDv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00133731,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$daumen",
    "name": "Daumenfrosch",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "T1oYbAejEESrZLtSAjumAXhzFqZGNxQ4kVN9vPUoxMv",
    "decimals": 9,
    "image": "https://rango.vip/i/KG2qkZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BULLY",
    "name": "Bully",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7qnTXCm7ZVMLdqNWzhfSm3RLKk8MrX3dYavB4hqJv5F9",
    "decimals": 9,
    "image": "https://rango.vip/i/HusKh4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003449,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MIGO",
    "name": "AmigosOnSol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8odnqwCqNVQ4UrYmYgkWPuedBr3yEMWY2ZBhtNW7jrCu",
    "decimals": 6,
    "image": "https://rango.vip/i/7XICej",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PATS",
    "name": "PATS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "35fN6LMYt6cKsemgbR28nFooiJtcnvaKPCeRXyuMKfoF",
    "decimals": 6,
    "image": "https://rango.vip/i/ccsZoz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00017798,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DRAW",
    "name": "DragonWar",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "48AEwauAHsJibyt3WqjQ6EoHnFBcnyHASfo7vB2eCXPS",
    "decimals": 0,
    "image": "https://rango.vip/i/UPCUp1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006338,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOTION",
    "name": "Motion",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Uiupt55owCqBJdunYQxyYf48KRMU4F53xQAyf31dpzx",
    "decimals": 6,
    "image": "https://rango.vip/i/Hl5VEv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00013903,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SLX",
    "name": "Solex Finance",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AASdD9rAefJ4PP7iM89MYUsQEyCQwvBofhceZUGDh5HZ",
    "decimals": 9,
    "image": "https://rango.vip/i/HNwZzc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001284,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RHO",
    "name": "RHO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Trhor7npQLca4DFiUWR9vJCAw1je2zghSbwh37nW81i",
    "decimals": 6,
    "image": "https://rango.vip/i/W8ioAl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00111578,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "acUSDC",
    "name": "Wrapped USDC (Allbridge from Celo)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DHpoYejUDqzByb6HAdaLWF7KZvwUv2vWYDY9cTENNZui",
    "decimals": 9,
    "image": "https://rango.vip/i/MNVATq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JJJJC",
    "name": "JJC",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6FLsSkF4AqQeDuqEmGPyBZvVebc4WWWntnZP2QZyBzG8",
    "decimals": 9,
    "image": "https://rango.vip/i/KqPi7y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NCTR",
    "name": "Nectar",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AgnHzGspNu7F3nFM4izuPt5g7m1URjVaTaFNgvqSXcjC",
    "decimals": 9,
    "image": "https://rango.vip/i/cGwhdQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.132491,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDCbs",
    "name": "USD Coin (Portal from BSC)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA",
    "decimals": 8,
    "image": "https://rango.vip/i/3r4mHq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "N2H4",
    "name": "Hydrazine",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda",
    "decimals": 6,
    "image": "https://rango.vip/i/nA2lIg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZBC",
    "name": "ZEBEC",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "zebeczgi5fSEtbpfQKVZKCJ3WgYXxjkMUkNNx7fLKAF",
    "decimals": 9,
    "image": "https://rango.vip/i/kg6IRI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00470797,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "xSOL",
    "name": "ElagabalX Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "B5GgNAZQDN8vPrQ15jPrXmJxVtManHLqHogj9B9i4zSs",
    "decimals": 9,
    "image": "https://rango.vip/i/0VANXU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$SCOT",
    "name": "Scottish",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EoJEyppNNpycP1ZoPnWq6cxwA7mSYpr9T1WXPFyjEzEy",
    "decimals": 6,
    "image": "https://rango.vip/i/qcsfa7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000599,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BEACH",
    "name": "Solana Beach",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ho2FQgg65oM1zpYuEnC8fULpBnWtqRCTrXRP56AeyCci",
    "decimals": 9,
    "image": "https://rango.vip/i/Xm6MSQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008278,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "arab",
    "name": "Arab Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BFpchrNVhyTRzMNAg9QkiZfRN2vqRBwcYoTX8qgkbDvm",
    "decimals": 9,
    "image": "https://rango.vip/i/js0feR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00113408,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WISDM",
    "name": "Dog Of Wisdom",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "53ctv3wwFXQbXruKWsbQcCe7sefowyu96pXK6FRLTjfv",
    "decimals": 4,
    "image": "https://rango.vip/i/odArq6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003469,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Cheng",
    "name": "Chengshi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BEsnQvkfeaQ3G8qbeN5FmUnsQUqXzXW5i83Hv3syoTTc",
    "decimals": 6,
    "image": "https://rango.vip/i/SZEJmq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001599,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "boden",
    "name": "jeo boden",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o",
    "decimals": 9,
    "image": "https://rango.vip/i/rHKjzW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00416692,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "soALEPH",
    "name": "Wrapped ALEPH (Sollet)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K",
    "decimals": 6,
    "image": "https://rango.vip/i/50sckI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RAi",
    "name": "RealmAi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "RaiuuHKrphE2jENyANz37mcTquwmwBqdnAiR881aEBZ",
    "decimals": 9,
    "image": "https://rango.vip/i/rmrmJ8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.969131151797245,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOONAK",
    "name": "RISHI MOONAK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A8iqgLj9bmqb2JM8umkHj7B7AGriD1EKLZHVRGA2VcNP",
    "decimals": 6,
    "image": "https://rango.vip/i/XjXKkj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MODUL",
    "name": "Modul",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A98UDy7z8MfmWnTQt6cKjje7UfqV3pTLf4yEbuwL2HrH",
    "decimals": 9,
    "image": "https://rango.vip/i/qmVhyq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOAR",
    "name": "MOAR GAINZ",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "76gDMbyohg6yHy6XJ2Rn9kxC22Q6v6uYpPXxkiwR5ota",
    "decimals": 6,
    "image": "https://rango.vip/i/vL5Zbl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DWH",
    "name": "DevWifHat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DEVwHJ57QMPPArD2CyjboMbdWvjEMjXRigYpaUNDTD7o",
    "decimals": 6,
    "image": "https://rango.vip/i/QxEprR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOTS",
    "name": "Skullbot Biker Gang",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HDEqEpFgTrBawzDgTG1eyH8Go9PX84LCEC8Qjt8T4jFN",
    "decimals": 2,
    "image": "https://rango.vip/i/aVzSdz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CONUR",
    "name": "the keng is beck",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7yqC6J7qxuRzWg1xHHBmcTLG6X1K7Y5DBYB49Ks8nGKf",
    "decimals": 6,
    "image": "https://rango.vip/i/PZlJsK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EDUST",
    "name": "Exiled Dust Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "21kbezaCxz9dG2NtXp7pW1hAmtKe6ykiJkeT1EiGXDyY",
    "decimals": 9,
    "image": "https://rango.vip/i/ZHs5Ql",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$POINTS",
    "name": "$POINTS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9cX8hMxZ2vW7pxYEPf2G5UHrcmMx83iTgGcxwwRKdarq",
    "decimals": 9,
    "image": "https://rango.vip/i/ropS9a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001601,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DGNA",
    "name": "DegenAlley",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8iSagwHZNj4Hx4CMeoZwLLMVbWt4mUT6qk42TxiHkRtn",
    "decimals": 6,
    "image": "https://rango.vip/i/Lgtgz8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TopG",
    "name": "Andwu Tet",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2kARUpcjqKJdSTgZQKrzABLKbjvi2hNADYwHq7z52gGq",
    "decimals": 9,
    "image": "https://rango.vip/i/ITtnmr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00037020290996062137,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "capri",
    "name": "Caprisun Monkey",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "JD3S1oqnjG5trRTZXpLmKnS7LsKsppyFa51rPBBMWogv",
    "decimals": 6,
    "image": "https://rango.vip/i/hRI8gh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00040468,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "xUSD",
    "name": "Synthetic USD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "83LGLCm7QKpYZbX8q4W2kYWbtt8NJBwbVwEepzkVnJ9y",
    "decimals": 6,
    "image": "https://rango.vip/i/DjzZoh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.9998301511742116,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ELIXIR",
    "name": "ELIXIR",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ELXRYrf8wd4DcyXDU9QPnMdD8jn2twg7o7qEtf5z2GBW",
    "decimals": 9,
    "image": "https://rango.vip/i/mp1Ss3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NICK",
    "name": "I CHOOSE RICH EVERYTIME!",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G3q2zUkuxDCXMnhdBPujjPHPw9UTMDbXqzcc2UHM3jiy",
    "decimals": 6,
    "image": "https://rango.vip/i/muM3Ga",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00016747,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WUPHF",
    "name": "WUPHF",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8UFSHiHmiqyecJtYUtDDuP8ZVyNfGovj7qgPtLsaP9gh",
    "decimals": 9,
    "image": "https://rango.vip/i/LdmxH9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WOOOO",
    "name": "Enrique Flair",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "JCqsyVajfHBGjNWNmLT6Cfx1Vn9f3mRibtNSdUsVRWS5",
    "decimals": 6,
    "image": "https://rango.vip/i/2BPznO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PRANA",
    "name": "prANA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PRAxfbouRoJ9yZqhyejEAH6RvjJ86Y82vfiZTBSM3xG",
    "decimals": 6,
    "image": "https://rango.vip/i/StDbeR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00729716,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OXS",
    "name": "Oxbull Sol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx",
    "decimals": 9,
    "image": "https://rango.vip/i/QAvZR1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LUX",
    "name": "LUX Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LUX88ZHPnte7tThA4F2nnXDCZWE3G61TqLBvFw7i8SM",
    "decimals": 9,
    "image": "https://rango.vip/i/S492xe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00111539,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Hobbes",
    "name": "Ansem's Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6n7Janary9fqzxKaJVrhL9TG2F61VbAtwUMu1YZscaQS",
    "decimals": 6,
    "image": "https://rango.vip/i/gWyu91",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00053139,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FORGE",
    "name": "FORGE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds",
    "decimals": 9,
    "image": "https://rango.vip/i/Kkvzdt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01389468,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HMTR",
    "name": "HampterFi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7JhmUcZrrfhyt5nTSu3AfsrUq2L9992a7AhwdSDxdoL2",
    "decimals": 0,
    "image": "https://rango.vip/i/6b7Xay",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TINY",
    "name": "TinyBits",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HKfs24UEDQpHS5hUyKYkHd9q7GY5UQ679q2bokeL2whu",
    "decimals": 6,
    "image": "https://rango.vip/i/ocLjBM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00239194,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "iceSOL",
    "name": "iceSOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "iceSdwqztAQFuH6En49HWwMxwthKMnGzLFQcMN3Bqhj",
    "decimals": 9,
    "image": "https://rango.vip/i/RXhxww",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PHTEVE",
    "name": "Phteven",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DhRQWsiP53gVXnG8KQwq63Uo1FvouRpVNL8536tsVD5H",
    "decimals": 8,
    "image": "https://rango.vip/i/h09ANL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002905,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "QUIL",
    "name": "Quilson",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BzW7Gnm32KTn82a1yCiYFooBLzi37MvEXFF8soQVpump",
    "decimals": 6,
    "image": "https://rango.vip/i/YJqaWh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000148,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OPOS",
    "name": "OPOS Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3byG6HEKhG5gUZ5PZU9zKkFaEHYPveGEtvs68LYR8R5N",
    "decimals": 4,
    "image": "https://rango.vip/i/L3CyOQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.255989,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SUBO",
    "name": "SUBO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HtpMfWQELCfCCeG6nHQDPfxLPrr2BNkZ2YVa9hKqpump",
    "decimals": 6,
    "image": "https://rango.vip/i/DLzJY2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FAP",
    "name": "FAP",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "D1YaKkQRaQQg6sPUtDiWyskppzfgMV51ovcagVU436TX",
    "decimals": 9,
    "image": "https://rango.vip/i/5JXehc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOOTY",
    "name": "BOOTY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "bootyAfCh1eSQeKhFaDjN9Pu6zwPmAoQPoJWVuPasjJ",
    "decimals": 9,
    "image": "https://rango.vip/i/1LF3AX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00140811,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "aeUSDC",
    "name": "Wrapped USDC (Allbridge from Ethereum)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DdFPRnccQqLD4zCHrBqdY95D6hvw6PLWp9DEXj1fLCL9",
    "decimals": 9,
    "image": "https://rango.vip/i/MNVATq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.999276,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WMP",
    "name": "Whalemap",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BygDd5LURoqztD3xETc99WCxLUbTi6WYSht9XiBgZ4HW",
    "decimals": 9,
    "image": "https://rango.vip/i/7jCxjL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TRUMPWIF",
    "name": "TRUMPWIFHAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DDGcYJkMMD1iiLRfPQLZePxLJCLDhiioQ83frmdAJd3h",
    "decimals": 6,
    "image": "https://rango.vip/i/tNmUcY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TAOLIE",
    "name": "TAOlie Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2zE5rJ2ctXMz9hVbk1AvJa78X7mh3kuR728SNzGXTEeu",
    "decimals": 9,
    "image": "https://rango.vip/i/YaywDC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00129231,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TRANSEM",
    "name": "Transem",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E4r9cN6ZooDPwCrWiRQkdP15KD9G6wq5Hzkpk7ogTpfk",
    "decimals": 6,
    "image": "https://rango.vip/i/x7mZW3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NOCT",
    "name": "Neural Octopus",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8HfNu2EfwFQz2srSNDvPsjaWV3G65ZWP3pDC67mcNv6J",
    "decimals": 9,
    "image": "https://rango.vip/i/TPRiEf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NRC",
    "name": "Neon Rocket Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Gh1jKzmxf95cT5PQabNbfJskkQU8kQ5UugfpbHSnPq9z",
    "decimals": 3,
    "image": "https://rango.vip/i/8qKL6o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01968638,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$RE",
    "name": "REdao",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "REdaoGk6EcBVgXW7vHs9FnzWmkr3ba6eHRBBgEtLNWo",
    "decimals": 9,
    "image": "https://rango.vip/i/coC5KD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SPOODY",
    "name": "Spodermen",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8Nd3TZJfxt9yYKiPiPmYp6S5DhLftG3bwSqdW3KJwArb",
    "decimals": 6,
    "image": "https://rango.vip/i/hK7IxH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006582,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YAW",
    "name": "Yawww",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "YAWtS7vWCSRPckx1agB6sKidVXiXiDUfehXdEUSRGKE",
    "decimals": 6,
    "image": "https://rango.vip/i/FLYKqm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001793,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "xBTC",
    "name": "Synthetic BTC",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HWxpSV3QAGzLQzGAtvhSYAEr7sTQugQygnni1gnUGh1D",
    "decimals": 10,
    "image": "https://rango.vip/i/YsLJk6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.023182280497258755,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CATY",
    "name": "Caty Wuud",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8mY8bif63v5vAHYaHPhpob71K4uJsDKXVx7h9h1XmJ6N",
    "decimals": 6,
    "image": "https://rango.vip/i/dlq9S9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "XIAO",
    "name": "xiaojie",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EDavhezsuNnhdoAKPExWaMtnuhq6FVqoBYnyFEJLLBqC",
    "decimals": 6,
    "image": "https://rango.vip/i/3yvqxe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00005119,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VCC",
    "name": "VentureCapital",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb",
    "decimals": 6,
    "image": "https://rango.vip/i/N3Wuzp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HAGGORD",
    "name": "ps1 hagrid",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AYyYgh3i43s1QSpvG4vwhJ6s3gewfN7uteFwYrswgMGw",
    "decimals": 9,
    "image": "https://rango.vip/i/5bngBa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.04901414,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DPUNKZ",
    "name": "Duck Punkz Universe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "McpgFn2CxFYFq6JLiBxeC6viNfebLsfsf9Sv5wcwKvL",
    "decimals": 2,
    "image": "https://rango.vip/i/wi2gIi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SMRAI",
    "name": "SMRAI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "rvxo8t7TKeSmAgpdqK1CY9ddZi3NyowRCh1m2d7KrUc",
    "decimals": 9,
    "image": "https://rango.vip/i/FYQUAL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "rLGND",
    "name": "Random Blockasset Legends",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9m8E1yLHaG1B2TFSNeWahsitQh5yQRnrbyw756HFAcEa",
    "decimals": 9,
    "image": "https://rango.vip/i/BFNltE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SNIFF",
    "name": "SNIFF",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4oaV94McVveRosSgdZTn3jkMpr1ge7SQZjwBZ7xG6oA7",
    "decimals": 6,
    "image": "https://rango.vip/i/c3o4mO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000616,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PIXI",
    "name": "Pixi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FtHCi9cxJSSizrzMzsPjAfTfJi32V1CGRDM5Skqn4QBF",
    "decimals": 6,
    "image": "https://rango.vip/i/ma6Uzf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00016243,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TIMMI",
    "name": "TIMMI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BxXmDhM8sTF3QG4foaVM2v1EUvg9DLSVUsDRTjR8tMyS",
    "decimals": 6,
    "image": "https://rango.vip/i/SfDmnf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00011904,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WUT",
    "name": "WUT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DG5bH1BnfjB5YL7Vt3GjykkUKf6maDUW3jYvdNa9eEVa",
    "decimals": 6,
    "image": "https://rango.vip/i/pRuCzY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00059102,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "soYFI",
    "name": "Wrapped YFI (Sollet)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB",
    "decimals": 6,
    "image": "https://rango.vip/i/4vMEFX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POWME",
    "name": "Pixel Wall of Meme",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6r25fEibeDLfh4K9pwJcVGmt4g8yxMqZ2rAA5jwdJzvt",
    "decimals": 6,
    "image": "https://rango.vip/i/VOLoYL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TICKET",
    "name": "The Ticket Finance",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer",
    "decimals": 8,
    "image": "https://rango.vip/i/KDI5Be",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BURR",
    "name": "Burrito Boyz",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "XwTZraiF1dVh69cZ2SpqyjDLmei2uVps5CYHD9vqK6d",
    "decimals": 2,
    "image": "https://rango.vip/i/JmnEEA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDTet",
    "name": "Tether USD (Portal from Ethereum)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1",
    "decimals": 6,
    "image": "https://rango.vip/i/s5jd83",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.999229,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "99CENTS",
    "name": "Arizona Iced Tea",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3VTXWW93L1WRfQ7QDPjvfBQgzFRVqDgHPnc8vwQmEpUi",
    "decimals": 6,
    "image": "https://rango.vip/i/KmvSqQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00011968,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FLUXT",
    "name": "Flux Terminal",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "D1wUhnzTDscCDRdxDwR4h82XkesXgQR4Q2zLhSuYJA5m",
    "decimals": 6,
    "image": "https://rango.vip/i/M2OiYN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004511,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TOLY",
    "name": "Anatoly Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "to1yVXiNRMVVgS8i54Yjj3xB51MTorFrCMz7N8cirbK",
    "decimals": 9,
    "image": "https://rango.vip/i/JC2uoB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003017,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COINFRA",
    "name": "Coinfra Samurai",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FtVugRqBcn5gakjzfDyA3Spms63fYDAbhke1YAFgLgLB",
    "decimals": 2,
    "image": "https://rango.vip/i/485Xgq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "1SP",
    "name": "1SPACE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2zzC22UBgJGCYPdFyo7GDwz7YHq5SozJc1nnBqLU8oZb",
    "decimals": 9,
    "image": "https://rango.vip/i/ZhCsMY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.10352e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KRUG",
    "name": "KRUGs",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ECy2miAgmx3UyYoGGHt15AHBkoA3twoZDQyNLwETzAUv",
    "decimals": 2,
    "image": "https://rango.vip/i/eR7Q0B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FUACK",
    "name": "FUACK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4P6gQYnZ2AvwmAr37GCeUq1vyk1PQJmXnuA1QFRKGHkz",
    "decimals": 6,
    "image": "https://rango.vip/i/veKT0j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003666,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SAYLOR",
    "name": "Saylor",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8bXZuG6NVuhdmGeMEjypYZGny48DgpZ68TvkvVTmFDdF",
    "decimals": 9,
    "image": "https://rango.vip/i/yhhN5e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "kenidy",
    "name": "ruburt f kenidy jr",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR",
    "decimals": 9,
    "image": "https://rango.vip/i/7EMpdC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00005018,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sLUNA-9",
    "name": "Saber Wrapped LUNA (Portal) (9 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LUNGEjUXyP48nrC1GYY5o4eTAkwm4RdX8BxFUxWJBLB",
    "decimals": 9,
    "image": "https://rango.vip/i/lvKewz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "IN",
    "name": "Sol Invictus",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY",
    "decimals": 9,
    "image": "https://rango.vip/i/dx3rRt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00812137,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Ww3",
    "name": "World War 3",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7m2TUkpPZCScBhPJnGjWjbh75KkDNnwAdd7i74m8awad",
    "decimals": 6,
    "image": "https://rango.vip/i/c9MyUC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0002989789708933564,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ANTT",
    "name": "Antara Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ANTT2Ve8c3JC6fAksFxUGtByzEHmJs49EupP7htA5W7j",
    "decimals": 9,
    "image": "https://rango.vip/i/zRyDrE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01103292,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CNN",
    "name": "Fak Nous",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DoyVgrZbkAVMbTVGqa9mFNgjThsr337vxjFtEDWQibN7",
    "decimals": 6,
    "image": "https://rango.vip/i/w0dQoR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ssoFTT-8",
    "name": "Saber Wrapped Wrapped FTT (Sollet) (8 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FTT8cGNp3rfTC6c44uPTuEFLqmsVDhjd2BhH65v2uppr",
    "decimals": 8,
    "image": "https://rango.vip/i/KV60TS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DEVIN",
    "name": "SCOTT WU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7gbEP2TAy5wM3TmMp5utCrRvdJ3FFqYjgN5KDpXiWPmo",
    "decimals": 6,
    "image": "https://rango.vip/i/lMbUvl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00076732,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GMERS",
    "name": "GMers",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "25yPcyqSpSv8T2JWyoKKRiU622Sr7LoMk9S3FmuBFt8S",
    "decimals": 2,
    "image": "https://rango.vip/i/tcNWlW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PIXL",
    "name": "Pixels.so Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5L2YboFbHAUpBDDJjvDB5M6pu9CW2FRjyDB2asZyvjtE",
    "decimals": 8,
    "image": "https://rango.vip/i/pfaP7h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02675844,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sboy",
    "name": "soulja coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G3ukjeHBrDJ1zUFr6KandnW4bPcjmvK3qL2uATRb3F63",
    "decimals": 6,
    "image": "https://rango.vip/i/yeZXis",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00012989,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OOINK",
    "name": "OOINK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "KRTapyUMe5fW92KZkYoXToFtc6Cn7UG6seaKz646oGu",
    "decimals": 9,
    "image": "https://rango.vip/i/seT3sz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Cuy",
    "name": "Cuy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8MD6dV7B8Tgwo72WyPmfsXiXpt1G1y2u9ohhNrt3LVwX",
    "decimals": 6,
    "image": "https://rango.vip/i/iTbaKT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VOID",
    "name": "VOID Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DjPt6xxMoZx1DyyWUHGs4mwqWWX48Fwf6ZJgqv2F9qwc",
    "decimals": 9,
    "image": "https://rango.vip/i/znJme4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00055236,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": " EVERMOON",
    "name": "Evermoon",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CgzdCjj5YNH51uFfQftFbuJKMrwgdWheVjwqjU84MV8y",
    "decimals": 6,
    "image": "https://rango.vip/i/VBc8ca",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001958,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOGO",
    "name": "Monkey Gold",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2WUecdL1GQA6pwQs7sMsCf2shEfZxWK2RaNHkthmLtmK",
    "decimals": 9,
    "image": "https://rango.vip/i/o2cBvi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "boy",
    "name": "Good Boy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GmEXg8FwpULzVb15r41CNB5qFnRcXCrEyZSmD3X7sv2e",
    "decimals": 6,
    "image": "https://rango.vip/i/kGigRB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006615,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GOSU",
    "name": "Gosu",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6D7nXHAhsRbwj8KFZR2agB6GEjMLg4BM7MAqZzRT8F1j",
    "decimals": 8,
    "image": "https://rango.vip/i/rYy77s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SBC",
    "name": "Stable Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DBAzBUXaLj1qANCseUPZz4sp9F8d2sc78C4vKjhbTGMA",
    "decimals": 9,
    "image": "https://rango.vip/i/muxaRo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PSOL",
    "name": "Parasol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Hmatmu1ktLbobSvim94mfpZmjL5iiyoM1zidtXJRAdLZ",
    "decimals": 7,
    "image": "https://rango.vip/i/wjASlg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00521616,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "swtUST-9",
    "name": "Saber Wrapped UST (Portal) (9 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "UST98bfV6EASdTFQrRwCBczpehdMFwYCUdLT5tEbhpW",
    "decimals": 9,
    "image": "https://rango.vip/i/HH0UOv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FISHY",
    "name": "FISHY by sharky.fi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fishy64jCaa3ooqXw7BHtKvYD8BTkSyAPh6RNE3xZpcN",
    "decimals": 6,
    "image": "https://rango.vip/i/O4rsCD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00788101,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BULLZ",
    "name": "BULLZ",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CdkXjsEokNWMabBn2HCrPcXNmSDE59QKtseLh1j9tEwd",
    "decimals": 6,
    "image": "https://rango.vip/i/WbyNeb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TREN",
    "name": "TREN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb",
    "decimals": 9,
    "image": "https://rango.vip/i/5oMeWm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00011644,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LPFi",
    "name": "LP Finance DAO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LPFiNAybMobY5oHfYVdy9jPozFBGKpPiEGoobK2xCe3",
    "decimals": 9,
    "image": "https://rango.vip/i/uoAGuP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "bul",
    "name": "bul",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8RGy4BZUEkW9dc2UEZ3QHYYdas66X63vazdZJezGJw5e",
    "decimals": 7,
    "image": "https://rango.vip/i/7h7y8M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.88293e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GARF",
    "name": "Garf",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Pz1b7iALFqzsgdL9ca6P3NZvTXwSF1koaQqnNohVFcT",
    "decimals": 6,
    "image": "https://rango.vip/i/lkTRIW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOONBURN",
    "name": "MOONBURN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "F14Cp89oAXMrNnaC4mKMNKHPWw2p2R4DRFAZEdJhUBkD",
    "decimals": 6,
    "image": "https://rango.vip/i/OayWZK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MELENYE",
    "name": "melenye tremp",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Cer9R2rqUEyemrgCWu5SsJu5d52sdZfC2gCYpwBhtEbB",
    "decimals": 6,
    "image": "https://rango.vip/i/dbQr5p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ROBERT",
    "name": "Robert",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AoN2z7w7ccQJQiWS7rjS45dcyYkVkBddXDcrzmj69tqf",
    "decimals": 3,
    "image": "https://rango.vip/i/Hq86vz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PPP",
    "name": "Piexeled Pepe",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9HoRXnCcXdTWA1YhYJoPVpp9YByYS3Y8nVV42z1MTBoV",
    "decimals": 6,
    "image": "https://rango.vip/i/Nx6WRS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ZAZU",
    "name": "ZAZU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "n7EksMkvk3WT5FjQ3HBMLm9XYU3EnoXUSJ7PoWpxsoG",
    "decimals": 6,
    "image": "https://rango.vip/i/6suH9g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00012462,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TOOL",
    "name": "Toolkit",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "tooLsNYLiVqzg8o4m3L2Uetbn62mvMWRqkog6PQeYKL",
    "decimals": 0,
    "image": "https://rango.vip/i/4DzD7n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DKM",
    "name": "DeadKnight Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HtbhBYdcfXbbD2JiH6jtsTt2m2FXjn7h4k6iXfz98k5W",
    "decimals": 9,
    "image": "https://rango.vip/i/AfhItR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "STSHIP",
    "name": "StarShip🚀",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GHtLF7drbYXGTHX73uSxqPKkJUzDqcBNe2M9fzjJzr3j",
    "decimals": 8,
    "image": "https://rango.vip/i/vZWtKe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.75212e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BEAVER",
    "name": "Chinese Beaver",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "M9i5xQz8Z2Ua3VHuBkjBSkP5HYwdetu7N9RP5VUsW4z",
    "decimals": 6,
    "image": "https://rango.vip/i/QVi399",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$WEN",
    "name": "WEN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3J5QaP1zJN9yXE7jr5XJa3Lq2TyGHSHu2wssK7N1Aw4p",
    "decimals": 6,
    "image": "https://rango.vip/i/JE9kFt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.3502e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BURGUM",
    "name": "DAWG BURGUM",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A7nt9kPpgGZFRjdgZ5EDUD53UpsodwdxRJYPHESwMXSC",
    "decimals": 9,
    "image": "https://rango.vip/i/V4m70J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Harold",
    "name": "Hide the pain Harold",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "B8AmDZRJeHuq8CPciey6jocq9p4ivc4ZurDXPwtqs2Qg",
    "decimals": 6,
    "image": "https://rango.vip/i/gZcYlV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0007308987065449328,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LEIA",
    "name": "Leia",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7usVzynPTUJ9czdS96ezm9C6Z3hCsjb7j6TMKipURyyQ",
    "decimals": 6,
    "image": "https://rango.vip/i/LrRFxi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002342,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MATICpo",
    "name": "MATIC (Portal from Polygon)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Gz7VkD4MacbEB6yC5XD3HcumEiYx2EtDYYrfikGsvopG",
    "decimals": 8,
    "image": "https://rango.vip/i/83fcSo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.364766,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "XGLI",
    "name": "Glitter Finance",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FsPncBfeDV3Uv9g6yyx1NnKidvUeCaAiT2NtBAPy17xg",
    "decimals": 6,
    "image": "https://rango.vip/i/WjNziQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00005986,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "zippySOL",
    "name": "Zippy Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Zippybh3S5xYYam2nvL6hVJKz1got6ShgV4DyD1XQYF",
    "decimals": 9,
    "image": "https://rango.vip/i/ryV6vG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 137.77,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WHEY",
    "name": "WHEY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ue4yjkPjA4QGis37eWbBsnqfzyK83BtY4AioDETp3Ab",
    "decimals": 6,
    "image": "https://rango.vip/i/I5nmZK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00019001,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDTav",
    "name": "Tether USD (Portal from Avalanche)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Kz1csQA91WUGcQ2TB3o5kdGmWmMGp8eJcDEyHzNDVCX",
    "decimals": 6,
    "image": "https://rango.vip/i/xC5swR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GMSOL",
    "name": "We Say gm",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "gmdu3snwW28DmmxCseChp9owWLUhamH9eS3hWfHG8Vg",
    "decimals": 9,
    "image": "https://rango.vip/i/75VBIa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SIXY",
    "name": "611Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GKNr1Gwf7AMvEMEyMzBoEALVBvCpKJue9Lzn9HfrYYhg",
    "decimals": 9,
    "image": "https://rango.vip/i/2Mg0n0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "soba",
    "name": "SolBastard",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "25p2BoNp6qrJH5As6ek6H7Ei495oSkyZd3tGb97sqFmH",
    "decimals": 6,
    "image": "https://rango.vip/i/NuzQWX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00085943,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FIW",
    "name": "777FuckIlluminatiWorldwide",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FeKmTunVrXDKEoDJbuTwZi8vfFFw3MHzpPB79JD8ARYU",
    "decimals": 6,
    "image": "https://rango.vip/i/zR8YY0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00014445,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "harold",
    "name": "harold",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EjmDTt8G3T725eFSV7oWmGD8J848guo3LZ1EB3RfwGSw",
    "decimals": 9,
    "image": "https://rango.vip/i/of2CaK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00073172,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SAC",
    "name": "Stoned Ape Crew",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GZL4yjPohDShW4RofJ6dEWu2Fv7qEa5mBT7Dpje5hqe7",
    "decimals": 2,
    "image": "https://rango.vip/i/7e5zY0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 5.51932e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "omaba",
    "name": "borack omaba",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AZ7ABJtSeZoFHhNzWhMVREMgGKZVCN8nEZwQfyZdALSZ",
    "decimals": 9,
    "image": "https://rango.vip/i/mE57bE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CREAMY",
    "name": "Creamy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CREAMpdDimXxj2zTCwP5wMEtba4NYaKCrTBEQTSKtqHe",
    "decimals": 9,
    "image": "https://rango.vip/i/NXDRLC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00071878,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOOSK",
    "name": "elawn moosk",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3KroURETdWLyFReapbdh6nTWQgS7Y6rorLY8jb1pJXoM",
    "decimals": 6,
    "image": "https://rango.vip/i/udqMun",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00025052,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "lanternSOL",
    "name": "Lantern Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LnTRntk2kTfWEY6cVB8K9649pgJbt6dJLS1Ns1GZCWg",
    "decimals": 9,
    "image": "https://rango.vip/i/FmWq5n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 136.47,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WINE",
    "name": "Wine",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DoVRzHXXicZucnuskdMg7DePhBHFkhvvhta5nf1deFoQ",
    "decimals": 6,
    "image": "https://rango.vip/i/2k4ffe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.365999,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "pSOL",
    "name": "pSOL (Parrot SOL)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX",
    "decimals": 9,
    "image": "https://rango.vip/i/jYK3ER",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00521616,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SHUT",
    "name": "Shuttlecoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9e6nnqbsTjWx3ss6a3x7Q9ZvpupLNYLb8cTbVmm6UD2K",
    "decimals": 9,
    "image": "https://rango.vip/i/jv9Ijk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "acat",
    "name": "Apple Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "acatzTjUeHDT3SoufN6NMxGUmBFtoqFHnFwusdw8kYX",
    "decimals": 6,
    "image": "https://rango.vip/i/ntT4iO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008079,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CDBD",
    "name": "COINDASHBOARD",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "55bhM86E8brNHWy7sNz4chqtfQtrVGDvaeWyVczJs8RC",
    "decimals": 6,
    "image": "https://rango.vip/i/1REMLD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AMMO",
    "name": "Ammunition",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ammoK8AkX2wnebQb35cDAZtTkvsXQbi82cGeTnUvvfK",
    "decimals": 0,
    "image": "https://rango.vip/i/CPFxjs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KLGT",
    "name": "Kleyne Gelt",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "519W6ZZnduudeo6tp1YMgeWiNFeoq4kTVS4uMZggKE5r",
    "decimals": 9,
    "image": "https://rango.vip/i/eif1Ym",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GMEOW",
    "name": "gmeow cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A5LCTQ1vJECCQWSXJYs3rfCoexctbUgTCAEKDuNye8bZ",
    "decimals": 6,
    "image": "https://rango.vip/i/xsBL2N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00018839,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "QDOT",
    "name": "QDOT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HCpyiCXvziKByTGoQMLUmooAWBkCLvZmzB9D6TyPdACR",
    "decimals": 8,
    "image": "https://rango.vip/i/FJeOi5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FC",
    "name": "Floki Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DAt9V3J3Cv959NRVmoENNfrqhTB68g2B6ovU6r9tajrG",
    "decimals": 6,
    "image": "https://rango.vip/i/Dkhyil",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BZONE",
    "name": "BANANA ZONE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6bE23pRia6rvShfBhkFiWF5fRPWXyG6EYvoMvvLss91p",
    "decimals": 6,
    "image": "https://rango.vip/i/ovgfWS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "mockJUP",
    "name": "mockJUP",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "JxxWsvm9jHt4ah7DT9NuLyVLYZcZLUdPD93PcPQ71Ka",
    "decimals": 9,
    "image": "https://rango.vip/i/1jfpiT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00114608,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$SWTS",
    "name": "SWEETS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5SwxhEunuUUcWe4ojybdDwky6dpLxAehNmF4AA71STNh",
    "decimals": 5,
    "image": "https://rango.vip/i/Q2JIE4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 7.2828e-10,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AKIRA",
    "name": "AKIRA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FqSkp1BxNKEaLeyZPA7REd47aQMDUzz8fsg1f3rtKrJh",
    "decimals": 4,
    "image": "https://rango.vip/i/dvKhpP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HYPE",
    "name": "HypeProxy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8TQdiAzdZZEtkWUR8Zj1tqDYGPv9TR1XjPqrew39Vq9V",
    "decimals": 7,
    "image": "https://rango.vip/i/bFGLZB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000531,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HOWIE",
    "name": "Howerd Sturn",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9CQLBCa7n6sf1LTB6rZHJxEmtVWfBk9cRxEWTq6ygQHG",
    "decimals": 6,
    "image": "https://rango.vip/i/tSYi0M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "hiji",
    "name": "hiji",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EjErrBoCw7eWYkMfimhPckaPSuBukyhUYwv2dLJYDWB7",
    "decimals": 6,
    "image": "https://rango.vip/i/4GI9Te",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "croc",
    "name": "croc cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5V9qpFo8NMFyhYHbrqY2c1TJrvzZSnRJAdj4ryPVB18n",
    "decimals": 6,
    "image": "https://rango.vip/i/szQJMz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00012758,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "NoHat",
    "name": "DogWifNoHat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5BKTP1cWao5dhr8tkKcfPW9mWkKtuheMEAU6nih2jSX",
    "decimals": 6,
    "image": "https://rango.vip/i/Uy8drA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00040679,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "USDCet",
    "name": "USD Coin (Portal from Ethereum)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM",
    "decimals": 6,
    "image": "https://rango.vip/i/gNoPud",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.997379,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DATE",
    "name": "SolDate(DATE) Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9",
    "decimals": 9,
    "image": "https://rango.vip/i/onZRUl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "clBNB",
    "name": "Cloned BNB",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6KnsFQzCrY1VdD9Qub1ZhRcFSnzQRqhMpT2Xn4X5P2cj",
    "decimals": 8,
    "image": "https://rango.vip/i/3XWmK6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 518.32,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "dainSOL",
    "name": "dainSOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2LuXDpkn7ZWMqufwgUv7ZisggGkSE5FpeHCHBsRgLg3m",
    "decimals": 9,
    "image": "https://rango.vip/i/UA59qJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WHC",
    "name": "Whales Club",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko",
    "decimals": 6,
    "image": "https://rango.vip/i/X5nmiu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0004813,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MERTD",
    "name": "Merted",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DDRmuJ77t7d6pkBDXE47ZALjSZm3udVGJMgZjZBk41LH",
    "decimals": 9,
    "image": "https://rango.vip/i/uPtrKS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WNDO",
    "name": "WNDO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E8G4uo2i9d12aGnXDHXXcw6hU2fh2NytR5XR3qurTLBx",
    "decimals": 9,
    "image": "https://rango.vip/i/K4YVSG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MusCat",
    "name": "MusCat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6PPskLwejGjiRbsrdTvPdo8Ayf6rERxvSezcPDA53TAP",
    "decimals": 6,
    "image": "https://rango.vip/i/f5sbKQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.645584908972556e-8,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LAPTOP",
    "name": "Hunter Biden's Laptop",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HLwEJQVzs7SvjXuXpBTRHaLp5S6uWoWTUhLjJxBfy1c7",
    "decimals": 4,
    "image": "https://rango.vip/i/vzh5lI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000745,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MP",
    "name": "Maneko Pet",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5zYbnE6UXTn6HMTPXCNPW61iA1vyCNTZLVQdUUnoazB",
    "decimals": 9,
    "image": "https://rango.vip/i/ZQUCry",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00059664,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GREG",
    "name": "greg16676935420",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "zZRRHGndBuUsbn4VM47RuagdYt57hBbskQ2Ba6K5775",
    "decimals": 6,
    "image": "https://rango.vip/i/dCUBnw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008289,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sFTT-9",
    "name": "Saber Wrapped Wrapped FTT (Sollet) (9 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt",
    "decimals": 9,
    "image": "https://rango.vip/i/GMmPIg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$FROG",
    "name": "Frog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Frog8vt6gmuuUuuerd7ispP6yavssBUtMpEP5DCEuUgD",
    "decimals": 9,
    "image": "https://rango.vip/i/YRBqFD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POOR",
    "name": "Poor token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2mqi8iLJf9WaVRHd7nhRkqvCafxfb3Us7wshaJR73ykS",
    "decimals": 5,
    "image": "https://rango.vip/i/kX199n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$YETI",
    "name": "YETI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FDKBUXKxCdNQnDrqP7DLe8Kri3hzFRxcXyoskoPa74rk",
    "decimals": 9,
    "image": "https://rango.vip/i/sNIP0I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OLUMPC",
    "name": "Olumpec Terch",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5WjrhXBcYsTcn2KvaJv4cuBA5WDuprQVA9YoHYTC2wbi",
    "decimals": 6,
    "image": "https://rango.vip/i/Hca9Jd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000533,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UPT",
    "name": "UPROCK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "UPTx1d24aBWuRgwxVnFmX4gNraj3QGFzL3QqBgxtWQG",
    "decimals": 9,
    "image": "https://rango.vip/i/epnfOw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0170682,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SCOIN",
    "name": "Sdrive Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5qKDWkBejLtRh1UGFV7e58QEkdn2fRyH5ehVXqUYujNW",
    "decimals": 9,
    "image": "https://rango.vip/i/CbF9Zw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0016522,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sETH-8",
    "name": "Saber Wrapped Ethereum (Sollet) (8 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SL819j8K9FuFPL84UepVcFkEZqDUUvVzwDmJjCHySYj",
    "decimals": 8,
    "image": "https://rango.vip/i/BWmxrw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KARMA",
    "name": "Good Karma",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8qYH37jFCVbGSjQPdMsf8TDwp1JHTjU1McA8GoCCpump",
    "decimals": 6,
    "image": "https://rango.vip/i/o8EPaU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SMORES",
    "name": "SMORES",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "smoEhMZMweWBnpd1QoU4ZjuVNBxMFchqy4NRMBbtW7V",
    "decimals": 6,
    "image": "https://rango.vip/i/TroOQE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "picoSOL",
    "name": "picoSOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "picobAEvs6w7QEknPce34wAE4gknZA9v5tTonnmHYdX",
    "decimals": 9,
    "image": "https://rango.vip/i/hAOvHm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 134.48,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HorseMeat",
    "name": "Horse Meat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2FprjEk4MTSY9CxpKuENbGDdy69R15GHhtHpG5Durdbq",
    "decimals": 9,
    "image": "https://rango.vip/i/4pA1x8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "OVOL",
    "name": "Ovols",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4v3UTV9jibkhPfHi5amevropw6vFKVWo7BmxwQzwEwq6",
    "decimals": 2,
    "image": "https://rango.vip/i/AiJUq0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.091732,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RICH",
    "name": "RICH",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A2PVd9wmEk9Ek9MFbF6VVBm4UiGYK24TCmE5oR2WDWGH",
    "decimals": 6,
    "image": "https://rango.vip/i/Lrx6O5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002867,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TAPES",
    "name": "Trippin' Ape Tribe Floor Index",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DxRbkqB2cEqa4rnXJwq4z3GuksgpL2U27PWBw8QBcNv2",
    "decimals": 2,
    "image": "https://rango.vip/i/171eZF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RRR",
    "name": "Race Raiders Rewards",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3zE4Qmh9hrC4inHynT3aSRzHcWhnxPCHLeN9WWqvoRVz",
    "decimals": 9,
    "image": "https://rango.vip/i/MaM8Q6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DASCH",
    "name": "Dasch Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GTuDe5yneFKaWSsPqqKgu413KTk8WyDnUZcZUGxuNYsT",
    "decimals": 9,
    "image": "https://rango.vip/i/phXU0V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DOLAN",
    "name": "Dolan Duck",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4YK1njyeCkBuXG6phNtidJWKCbBhB659iwGkUJx98P5Z",
    "decimals": 6,
    "image": "https://rango.vip/i/0JrTuB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0074539,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DANNY",
    "name": "Degen Danny",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5NHd3MsP6dXi9r1saPkeB2DoZyXLvPiqv4n9J54Cpump",
    "decimals": 6,
    "image": "https://rango.vip/i/UTku2R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00181644,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHB",
    "name": "Charactbit",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "YtfMZ4jg2ubdz4GasY86iuGjHdo5rCPJnFqgSf8gxAz",
    "decimals": 9,
    "image": "https://rango.vip/i/NqWjFb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003701,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Rock",
    "name": "Rock",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5KxnfDmsXVBNkVHYhW4kztV7ZCCCbrkYxBVrqLWF3G7J",
    "decimals": 4,
    "image": "https://rango.vip/i/9sw7bs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.698e-11,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SOLCHAN",
    "name": "Solchan.org",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ChanM2vka4gJ3ob1SejJXnxoNuoAXGGJxDMJRSLD7nzW",
    "decimals": 9,
    "image": "https://rango.vip/i/L4obg1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$DAT",
    "name": "dats dat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DATiwd3NsbaRArGvmaFcFpc9jF8qciN7Fj4jRHAFUHFA",
    "decimals": 9,
    "image": "https://rango.vip/i/kpjHkq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHILN",
    "name": "Calm Bear",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2DfEnQrC6EVnhA3wGXiQ8UoBtEEmStsW6oNT6izn6AeH",
    "decimals": 9,
    "image": "https://rango.vip/i/CJWGgi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001978,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CLINTON",
    "name": "Bull Clinton",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AF7X9tJDkLDVApfMnKSkMUUsxxPhuSGUZbDECtrbvVu3",
    "decimals": 9,
    "image": "https://rango.vip/i/vryx0y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GH0ST",
    "name": "GH0ST",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HbxiDXQxBKMNJqDsTavQE7LVwrTR36wjV2EaYEqUw6qH",
    "decimals": 9,
    "image": "https://rango.vip/i/avQ4if",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02011945,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "srenBTC-10",
    "name": "Saber Wrapped renBTC (10 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BtX7AfzEJLnU8KQR1AgHrhGH5s2AHUTbfjhUQP8BhPvi",
    "decimals": 10,
    "image": "https://rango.vip/i/x4dQ9Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BABS",
    "name": "BABS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Ceqwd4CSGBZnW8PHEexBQCAV2NDPkBoSqheiKR7gzELV",
    "decimals": 5,
    "image": "https://rango.vip/i/Cvqbl1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POZZ",
    "name": "PozzCoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "27nqFZqb2iPBeVA7bbE4KPZrJgi3dJdKV9VzhCguSy6Y",
    "decimals": 9,
    "image": "https://rango.vip/i/0MChFt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ROL",
    "name": "ROL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "RoLLn5qBN4juQ1D2KFpJyAcC7Deo3cYotXi4qDooHLU",
    "decimals": 6,
    "image": "https://rango.vip/i/md3b2O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SCRAP",
    "name": "SCRAP",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6naWDMGNWwqffJnnXFLBCLaYu1y5U9Rohe5wwJPHvf1p",
    "decimals": 3,
    "image": "https://rango.vip/i/i0gKMU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0017252,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$FLUFF",
    "name": "$FLUFF",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CoRkC3r6MqYuTeMRc7D8JJF7UiUyFWurXGpYy1xQATNq",
    "decimals": 9,
    "image": "https://rango.vip/i/XEEqs4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00126257,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CRRT",
    "name": "Breezy Bunnies Carrot",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HkNokfCXG33eu5vCcS49mq3jZcKZeQSQCyta964YxxYg",
    "decimals": 0,
    "image": "https://rango.vip/i/drd5cZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "jeoing737",
    "name": "joeing737",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "H2ZpBXtzk1DaDTzsoXqMhjN6Bd3qzgefHjUZ1e2zuwAe",
    "decimals": 6,
    "image": "https://rango.vip/i/55QIyL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00017636,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wDingocoin",
    "name": "Wrapped Dingocoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6VYF5jXq6rfq4QRgGMG6co7b1Ev1Lj7KSbHBxfQ9e1L3",
    "decimals": 8,
    "image": "https://rango.vip/i/6pMJgT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000805,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LIBRA",
    "name": "Libra Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Hz1XePA2vukqFBcf9P7VJ3AsMKoTXyPn3s21dNvGrHnd",
    "decimals": 9,
    "image": "https://rango.vip/i/Lyf9jx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001747,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$MILK",
    "name": "Milk",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8FktFPUvi73Ywf7ZivsbuQFyQCqZUwvqSQ51hNvRhR26",
    "decimals": 8,
    "image": "https://rango.vip/i/zyMC5F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Miku",
    "name": "Mikuko Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7GNyprqrpvJHNwzWSj4KVkqw9cKyV5aR3ehM2HeE5pw",
    "decimals": 0,
    "image": "https://rango.vip/i/k10uMa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JUNKz",
    "name": "JUNK",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AMzmwvDRKdt5AQ3m1m28tWjzBxmQNe1PsmHnYitVZwzp",
    "decimals": 9,
    "image": "https://rango.vip/i/UxFHxQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FLIPGG",
    "name": "Flip.gg | #1 Lootbox Game",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "VVWAy5U2KFd1p8AdchjUxqaJbZPBeP5vUQRZtAy8hyc",
    "decimals": 9,
    "image": "https://rango.vip/i/iLBEAv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "obema",
    "name": "burek obema",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BMQZzVJa1X5cBYc8fCRVbWuFC4czA1s5GE3HekWfuhjz",
    "decimals": 6,
    "image": "https://rango.vip/i/jROusH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000036081585153837496,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "EDO",
    "name": "EDO",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7sWMHQaJJPn1rkeizq41iPiW5gG7Ry7PXYAVHaUwY4qu",
    "decimals": 9,
    "image": "https://rango.vip/i/P0grwe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "meesa",
    "name": "meesa",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DaYYrQTtKqGCBiPtnXSUT5d8nDXBrtW5LoJHsg4opump",
    "decimals": 6,
    "image": "https://rango.vip/i/KyM06l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DWAKE",
    "name": "Dwake",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9oUXhgFmW2HWqWHds1NoV3DKLY3AAtNevA3dP7PtyEbr",
    "decimals": 6,
    "image": "https://rango.vip/i/NtlW5q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001096,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DMME",
    "name": "DMME.me",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CG9WyPmf9EUQWGFUsaPAnFKuAi4eNZGGgCh7mrvAFm8p",
    "decimals": 6,
    "image": "https://rango.vip/i/TlrpwQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "aeDAI",
    "name": "Wrapped DAI (Allbridge from Ethereum)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9w6LpS7RU1DKftiwH3NgShtXbkMM1ke9iNU4g3MBXSUs",
    "decimals": 9,
    "image": "https://rango.vip/i/HorhPQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LSTAR",
    "name": "Learning Star",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "C6qep3y7tCZUJYDXHiwuK46Gt6FsoxLi8qV1bTCRYaY1",
    "decimals": 6,
    "image": "https://rango.vip/i/XLSudm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003919,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wDAI_v1",
    "name": "Dai Stablecoin (Wormhole v1)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1",
    "decimals": 9,
    "image": "https://rango.vip/i/HorhPQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$DOH",
    "name": "DOUGHGE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Dsad47jgGNoJ8p4LJQ8x4yCFRMHBL7kd1Js3TJj7X52C",
    "decimals": 9,
    "image": "https://rango.vip/i/4wzqcn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004354,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HOTTO",
    "name": "HottoShotto",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz",
    "decimals": 9,
    "image": "https://rango.vip/i/9Lhaeo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Solala",
    "name": "Solala",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HTPHknF5rBNCuZ1qK3muJA6Yo1HEhZj722oSESJnPBiw",
    "decimals": 9,
    "image": "https://rango.vip/i/gFvnKq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00019085,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "phaseSOL",
    "name": "Phase Labs SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "phaseZSfPxTDBpiVb96H4XFSD8xHeHxZre5HerehBJG",
    "decimals": 9,
    "image": "https://rango.vip/i/I9LI32",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 132.86,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GAY",
    "name": "Gay Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9GnU66c8qtMmj8rZ481N8Ums1Zmqdv5HgtQJyRVXt8MS",
    "decimals": 9,
    "image": "https://rango.vip/i/8pGIzp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00018219,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$TEST",
    "name": "TEST",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "D8TFo4FsDF9CnejkezEZtwdhdmwaiNgLRDAQUTwWQuBU",
    "decimals": 6,
    "image": "https://rango.vip/i/eADHSJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "IVRY",
    "name": "Portals",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2MtPZqwNKTNsBoFCwm4ZTWk3ySz4LSd82ucDGeTk7VNu",
    "decimals": 2,
    "image": "https://rango.vip/i/d2oLR5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ladyboy",
    "name": "Ladyboy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AmCKJCFZfq7Lwy6MnDgHGcnKcqAi5ftHxAMzYRDwogTF",
    "decimals": 6,
    "image": "https://rango.vip/i/GzmuXW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Mami",
    "name": "Mami",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "62mALBEzUQWS3r8EzjnX1C2ricdTy9hkv8gs7mLtpump",
    "decimals": 6,
    "image": "https://rango.vip/i/TpN2LR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003032,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HODI",
    "name": "Cat in Hoodie",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HodiZE88VH3SvRYYX2fE6zYE6SsxPn9xJUMUkW1Dg6A",
    "decimals": 9,
    "image": "https://rango.vip/i/9DKhA7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00084221,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HOOD",
    "name": "wagmicatgirlkanye420etfmoon1000x",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AGyUuFvYGnUUXWG6GUKga4B3MGmBuEZ9hGqY73n76XpJ",
    "decimals": 8,
    "image": "https://rango.vip/i/JlJcrc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002425,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PONK",
    "name": "Ponk",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HeqCcMjmuV5s25J49YiJyT6bD5qWLkP88YPajBySniaV",
    "decimals": 5,
    "image": "https://rango.vip/i/akkLly",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 4.757e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SNAP",
    "name": "SolSnap",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SNApmcWQqj3Ny2YFkQmkELQnNgaXRu6KmnYSPiFZcLn",
    "decimals": 6,
    "image": "https://rango.vip/i/Fetg64",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00187195,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Māo",
    "name": "猫",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "xgWKZ2h4tnstP3NzGyVFgA6Adu3Q7uCBtiYt7cErPA9",
    "decimals": 6,
    "image": "https://rango.vip/i/w7pqdL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UPT",
    "name": "UPTOBER",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9CPWiCFL11DWr92dsTrzLf5cKz6zKhLz3xzRcYCQ3Nd2",
    "decimals": 6,
    "image": "https://rango.vip/i/6KZzaj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0170682,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ACF",
    "name": "ACF Game",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2cZv8HrgcWSvC6n1uEiS48cEQGb1d3fiowP2rpa4wBL9",
    "decimals": 2,
    "image": "https://rango.vip/i/p4CgWc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.15,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WIFOUT",
    "name": "DOGWIFOUTHAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2ioyweEeV4xJCkFJvh868X9iP3L6Q31MVCawfbJLRTHq",
    "decimals": 9,
    "image": "https://rango.vip/i/fh66QA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00006628,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$dicki",
    "name": "dicki",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "8EHC2gfTLDb2eGQfjm17mVNLWPGRc9YVD75bepZ2nZJa",
    "decimals": 9,
    "image": "https://rango.vip/i/d3G9iQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0097437,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sols",
    "name": "sols",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2wme8EVkw8qsfSk2B3QeX4S64ac6wxHPXb3GrdckEkio",
    "decimals": 9,
    "image": "https://rango.vip/i/i1HvFs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.242253,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JOEL",
    "name": "Joel",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "JACSU5f2fCsQSCDNz1VX2Se4vmQyj8k5EYigD4RppvGV",
    "decimals": 9,
    "image": "https://rango.vip/i/1AiWRt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001254,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "soETH",
    "name": "Wrapped Ethereum (Sollet)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk",
    "decimals": 6,
    "image": "https://rango.vip/i/Q6NTCN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 345.91,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wCAPS_v1",
    "name": "Wrapped Capsule Coin (Wormhole v1)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T",
    "decimals": 8,
    "image": "https://rango.vip/i/35ttRe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BHST",
    "name": "Bit Harvest Studio Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BHSTv1nsz57fsXZzcsYWXhEygyqAaQu63DUJd3Rcp5Jo",
    "decimals": 9,
    "image": "https://rango.vip/i/gg7LON",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HIKO",
    "name": "Hiko The Meow",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Dr9s6hHSrSDom1zhwtJ7vp3RsJ3AX2y7ja2ZYEHQiC7f",
    "decimals": 6,
    "image": "https://rango.vip/i/1GNBV0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$BEER",
    "name": "BEER",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AujTJJ7aMS8LDo3bFzoyXDwT3jBALUbu4VZhzZdTZLmG",
    "decimals": 6,
    "image": "https://rango.vip/i/4syYsN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001363,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WCC",
    "name": "White Coffee Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BykkD9369RvXuEDbR7pTRz49b7cfLRTzHgSVoqK8gc15",
    "decimals": 6,
    "image": "https://rango.vip/i/PKjJHl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000444,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "white",
    "name": "White Solana",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FUCKuTfQVT9yCe3jPXdejBPhcPJpnceQy17uvcT9cLx8",
    "decimals": 9,
    "image": "https://rango.vip/i/TRXusj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3934.5552278235155,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "tooker",
    "name": "tooker kurlson",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9EYScpiysGnEimnQPzazr7Jn9GVfxFYzgTEj85hV9L6U",
    "decimals": 9,
    "image": "https://rango.vip/i/ORJY2L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00726059,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Cheese",
    "name": "Cheese",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AbrMJWfDVRZ2EWCQ1xSCpoVeVgZNpq1U2AoYG98oRXfn",
    "decimals": 6,
    "image": "https://rango.vip/i/wIkPQx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00162896,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "vBLSH",
    "name": "vBLSH",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FNMLmBPkhh7nBFyGHsdrmCuvgEf6ygpaVqFejimHEx9V",
    "decimals": 9,
    "image": "https://rango.vip/i/NPV8yN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FUX",
    "name": "Fux Nous",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "pz8KjM9AHRu8MvjEbxHjZ6RwxQjTgxvTtRsaQtCUyQf",
    "decimals": 6,
    "image": "https://rango.vip/i/hqbvrp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "IMSO",
    "name": "Intergalactic",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "imsoru6juztJ4pTJvPQaTnY766uEmCVc4AQJ8V1Yuvs",
    "decimals": 9,
    "image": "https://rango.vip/i/mITi6a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "abBUSD",
    "name": "Wrapped BUSD (Allbridge from BSC)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6nuaX3ogrr2CaoAPjtaKHAoBNWok32BMcRozuf32s2QF",
    "decimals": 9,
    "image": "https://rango.vip/i/2QHivB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SKYH",
    "name": "Sky Hause",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "J9hBnna1TMySX9hA3FgiC5xYph5pYHmcLVq2Sp36hDkz",
    "decimals": 6,
    "image": "https://rango.vip/i/makx1g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00023031,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HAPPI",
    "name": "happi cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "aBvs3Zv9JYmvUCKqRJvGctDQSCt6R7NAMELid3FeqsQ",
    "decimals": 6,
    "image": "https://rango.vip/i/Ei3Kz3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00013865,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ySOL",
    "name": "Synatra Staked SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "yso11zxLbHA3wBJ9HAtVu6wnesqz9A2qxnhxanasZ4N",
    "decimals": 9,
    "image": "https://rango.vip/i/Rgj9dy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 180.77,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LUNY",
    "name": "Luna Yield",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf",
    "decimals": 9,
    "image": "https://rango.vip/i/PIZ9Ai",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wFTT_v1",
    "name": "FTT (Wormhole v1)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi",
    "decimals": 9,
    "image": "https://rango.vip/i/XZE3JV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LILY",
    "name": "Solily Protocol Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LiLyT885cG9xZKYQk9x6VWMzmcui4ueV9J1uzPDDajY",
    "decimals": 6,
    "image": "https://rango.vip/i/3mtbgf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "laineSOL",
    "name": "Laine Stake",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "LAinEtNLgpmCP9Rvsf5Hn8W6EhNiKLZQti1xfWMLy6X",
    "decimals": 9,
    "image": "https://rango.vip/i/nuxq39",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 148.73,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "LEDGER",
    "name": "go to bed old man",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4gkvFD2SFKjR266heEc4XxWd7oPVxesLtAh5uRXdN2vt",
    "decimals": 6,
    "image": "https://rango.vip/i/QRMNld",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MESSI",
    "name": "Lionel Messi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HiRx4TWNmuPK6VKxGKPSw8T7dZ9NuCrakpNpRoiCEyrA",
    "decimals": 6,
    "image": "https://rango.vip/i/ExCrEA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GOTTI",
    "name": "GOTTI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FoAnSCG6CcqTq2rsTi58yyYBNk1HgsbLzS6b1kTP2ACL",
    "decimals": 9,
    "image": "https://rango.vip/i/rdTArR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 340.22,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BLZE",
    "name": "Blaze",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BLZEEuZUBVqFhj8adcCFPJvPVCiCyVmh3hkJMrU8KuJA",
    "decimals": 9,
    "image": "https://rango.vip/i/f3snnF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00029596,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "prtSOL",
    "name": "prtSOL (Parrot Staked SOL)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BdZPG9xWrG3uFrx2KrUW1jT4tZ9VKPDWknYihzoPRJS3",
    "decimals": 9,
    "image": "https://rango.vip/i/hxBnkX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$TIPS",
    "name": "Just The Tip",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AK87oZM8ovFU14bq8mu4ufi5zsCPDbqqVvh5b6WHbUdL",
    "decimals": 9,
    "image": "https://rango.vip/i/WTJoC6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00109254,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "rogen",
    "name": "jeo rogen",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DtgDZb83TqywcuBuWE89jx4k5Y7b6nQ4GYJq3Wd61JQQ",
    "decimals": 6,
    "image": "https://rango.vip/i/wCk7fD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ELMNT",
    "name": "ELMNT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9xYeZDHEwyuqJmqrTourbFRaxN2qhkYesnz3iQ3FPz4r",
    "decimals": 5,
    "image": "https://rango.vip/i/9Iym0M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GODEX",
    "name": "GUARD OF DECENT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GoDCaGctsLDnkV8T6jBAfTeMj8DesxT71CkZekV5ZXcf",
    "decimals": 9,
    "image": "https://rango.vip/i/kwGlmS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00016986,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "puten",
    "name": "vledemer puten",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6y56NYaUtKtjT5LKrVvZ3rsLnKyQ96phU3ckSARUkNax",
    "decimals": 9,
    "image": "https://rango.vip/i/w4hIw9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Chud",
    "name": "Chudjak",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6yjNqPzTSanBWSa6dxVEgTjePXBrZ2FoHLDQwYwEsyM6",
    "decimals": 6,
    "image": "https://rango.vip/i/s1OxZI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0064588,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$GRW",
    "name": "GrowSol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FPymkKgpg1sLFbVao4JMk4ip8xb8C8uKqfMdARMobHaw",
    "decimals": 9,
    "image": "https://rango.vip/i/5h8KZN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 5.24372e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ju",
    "name": "ju rugan",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AbBEjBdz31yCb9gTFfWgEi7uprCzjTEKL58xqHVbbjhu",
    "decimals": 9,
    "image": "https://rango.vip/i/3jFBA0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00018077,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GU",
    "name": "Kugle GU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "5KV2W2XPdSo97wQWcuAVi6G4PaCoieg4Lhhi61PAMaMJ",
    "decimals": 9,
    "image": "https://rango.vip/i/6hU3Hv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.195127,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "tub",
    "name": "tub",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Vm4ZLJ9WsCVPqdy6ubq7NECRZfvdLY4yLGERDnjEuB9",
    "decimals": 6,
    "image": "https://rango.vip/i/k5275F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00002714,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HASUKI",
    "name": "Hasuki",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DMvsB8cjKXDQJs8cvoDtKxX7KEMVeZ31KzycszuinJUY",
    "decimals": 2,
    "image": "https://rango.vip/i/n6KDee",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DIRTY",
    "name": "Dirty Street Cats",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AuWLSEuDRJi6hVcXbeez9WVbpeRsiTvvbZG1svzeBaxf",
    "decimals": 6,
    "image": "https://rango.vip/i/s9DxFV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003632,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "trumpie",
    "name": "trumpie",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HC2KyVkPK9Mc9NEFPUi43okhTYPa5fStk6a3Ci3cDbQS",
    "decimals": 6,
    "image": "https://rango.vip/i/l2w5R3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00009031,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CWR",
    "name": "Capo Was Right",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3hkAy2qcNJaxS3KebjNdugYbgakLvUZHRGZyRmGjSsbm",
    "decimals": 9,
    "image": "https://rango.vip/i/FWI2qd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0002983,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "RatSol",
    "name": "Rats on SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HNRrr5YBYgBz3orDGVc4pGQsc5mnLtyVtfKaMt4WNDMz",
    "decimals": 6,
    "image": "https://rango.vip/i/tyhoqh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ISKT",
    "name": "Rafkróna",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "isktkk27QaTpoRUhwwS5n9YUoYf8ydCuoTz5R2tFEKu",
    "decimals": 2,
    "image": "https://rango.vip/i/keNTMg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MOAI",
    "name": ":moyai:(MOAI)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7NQSHjuEGENZDWfSvPZz7oP2D6c5Jc3LjFC6uh179ufr",
    "decimals": 9,
    "image": "https://rango.vip/i/iAuoFV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.02576072,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOTMAN",
    "name": "botman",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2adqrK24G2iu2mmEijzKPKg69DUwGk3fUWAQx7Y1kSSK",
    "decimals": 6,
    "image": "https://rango.vip/i/IV6Tck",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PbP",
    "name": "Pixel By Pixel",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3f7wfg9yHLtGKvy75MmqsVT1ueTFoqyySQbusrX1YAQ4",
    "decimals": 9,
    "image": "https://rango.vip/i/qvHrYH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "YAKU",
    "name": "Yaku",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AqEHVh8J2nXH9saV2ciZyYwPpqWFRfD2ffcq5Z8xxqm5",
    "decimals": 9,
    "image": "https://rango.vip/i/5FOjTd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00920544,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HPOW",
    "name": "HydroCryptoPower",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "H865QN9mXUjDgSds2HtNrmTrRg2Z28oHgBQjJXfAWoQm",
    "decimals": 6,
    "image": "https://rango.vip/i/HHkxqQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Milady",
    "name": "Milady",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HB2pjBPuquh1XGEVC1GCKepdh4daoJnkJiHFmaorfBWu",
    "decimals": 9,
    "image": "https://rango.vip/i/ChdoTE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 6998.28,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "clAPT",
    "name": "Cloned Aptos",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HHncifGW3yJyaW2fRRfBYAawnD9ogbsWM5PccFA4GHSx",
    "decimals": 8,
    "image": "https://rango.vip/i/bOima4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.67,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "gomu",
    "name": "gomu gator",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Bx74hpFiaiBbSonrjyqxjGfAA7gRxM2CeKiy31uN6biR",
    "decimals": 6,
    "image": "https://rango.vip/i/NYUIzP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00022989,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "TOWL",
    "name": "towl",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9j3vEmBng3JhcL7LPaAx2CoLqE46AYmGPbbFgHKZVKWZ",
    "decimals": 6,
    "image": "https://rango.vip/i/EUhBti",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DJCAT",
    "name": "DJCAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A5gVj5j16U4vCTMXQYYrhkmWgx6gej4nAeZRNQMLUAfy",
    "decimals": 6,
    "image": "https://rango.vip/i/lfwSk5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00044783,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ANONN",
    "name": "Anonymous",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GreXYjqvAaEtqBsizrojtwHTNqXraVGZiJpCk5qtj1zC",
    "decimals": 9,
    "image": "https://rango.vip/i/24wN55",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DEA",
    "name": "Dog Enforcement Agency",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HxtRWx83K61KjsNu1iCB4uhX9cbUtcSizRjLXXSZyyEm",
    "decimals": 6,
    "image": "https://rango.vip/i/FtGeO5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "doben",
    "name": "dark boden",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EaiD1TvwaFavVTi9eroMDcovp1ok1dpRpbrKJmzeHfXM",
    "decimals": 4,
    "image": "https://rango.vip/i/CPdTrm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0002628410160960906,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HARIBO",
    "name": "Haribo",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CYobWQ73DYiyERWcvgLkYMSzkXn5Vy18JKxidjeqM6oy",
    "decimals": 9,
    "image": "https://rango.vip/i/AdWQbj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VIVAION",
    "name": "Vivaion Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "2jw1uFmc1hhfJH3EqGhaE2rfZMMC2YBpxkZcdUbPppMn",
    "decimals": 9,
    "image": "https://rango.vip/i/XsTQET",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BELUGA",
    "name": "Beluga Cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E63CDwLy9Dwr3EptAzopV9RuWoQnn5ZVYEjLWnJX8dCw",
    "decimals": 9,
    "image": "https://rango.vip/i/aujgjc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000708,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SUITE",
    "name": "The Suites Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4h41QKUkQPd2pCAFXNNgZUyGUxQ6E7fMexaZZHziCvhh",
    "decimals": 6,
    "image": "https://rango.vip/i/2Dacck",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0008934199493349425,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WHO",
    "name": "Who",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "A8eYjpNQF6QYEE7DnM4F514MErFzV1pxVT69d7mdSsMk",
    "decimals": 9,
    "image": "https://rango.vip/i/8VaOH3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KOW",
    "name": "Kowalski",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3WoatNLfMffazu8aCSmRSdWFngpJQ4GUMFJxtSzoDqau",
    "decimals": 6,
    "image": "https://rango.vip/i/ZxFPNS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "monk",
    "name": "monkeyhaircut",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FYa25XnBsXQXAdTnsyKBKd5gZ1VZhChBRF57CqfRxJZX",
    "decimals": 6,
    "image": "https://rango.vip/i/9OXRZt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0016488,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GSOL",
    "name": "Sunrise gSOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "gso1xA56hacfgTHTF4F7wN5r4jbnJsKh99vR595uybA",
    "decimals": 9,
    "image": "https://rango.vip/i/YvQloN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$WIFI",
    "name": "WIFI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "AcfKwAf4UQLA5DLRQD2eQxW6pLv79VKzVso38WMfTxGZ",
    "decimals": 6,
    "image": "https://rango.vip/i/UcHOF7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00010955,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CHAI",
    "name": "CHAI (Portal)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3jsFX1tx2Z8ewmamiwSU851GzyzM2DJMq7KWW5DM8Py3",
    "decimals": 8,
    "image": "https://rango.vip/i/pgJazl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.15,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BROKIE",
    "name": "BROKIEINU",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4neSyzJmcSWQF58DKHdo7FNzJDDKSgaaQqrzuSXS5U6g",
    "decimals": 6,
    "image": "https://rango.vip/i/i8WsrK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00035429,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MONKES",
    "name": "Solana Monkey Business",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FmoKY2ERGmE9NzrYphAJcqH5BPRy2Hs4VomRfu8Qgt7Y",
    "decimals": 2,
    "image": "https://rango.vip/i/F1Im2z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DCAP",
    "name": "Doge Capital",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BQXDYWZdtXqeLXFWYeRhLrGh8gcTmDQZQc92ENMaXSry",
    "decimals": 2,
    "image": "https://rango.vip/i/3oJ9mW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GuryVee",
    "name": "Gury Vee",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Agqc1Dm7DD7va5RRwbEZoX1CtYBKEBwASw83KbhjKBPn",
    "decimals": 6,
    "image": "https://rango.vip/i/aFQqnz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "SAMOWIF",
    "name": "samo wif hat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GS1VjXDZmDFsiqzBFYoACgRQBmXYuvdPJ88NQcXxg3qM",
    "decimals": 6,
    "image": "https://rango.vip/i/KLQ7Rt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00543093,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BATT",
    "name": "BAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BzjsQpgKjxZedFawUV9GZMExhr7VbvsasQv12v2PVxSt",
    "decimals": 8,
    "image": "https://rango.vip/i/KKn7HT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Bazinga",
    "name": "Bazinga",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "C3JX9TWLqHKmcoTDTppaJebX2U7DcUQDEHVSmJFz6K6S",
    "decimals": 6,
    "image": "https://rango.vip/i/8yXFnB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00373707,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "KKO",
    "name": "KKO Protocol",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "kiNeKo77w1WBEzFFCXrTDRWGRWGP8yHvKC9rX6dqjQh",
    "decimals": 9,
    "image": "https://rango.vip/i/ODdQG4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "xSTEP",
    "name": "Staked Step",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G",
    "decimals": 9,
    "image": "https://rango.vip/i/oHozFI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Badger",
    "name": "Badger",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9V4x6ikFm9XKsnh3TiYJWPwQfFkJZDjifu7VSUqg3es1",
    "decimals": 9,
    "image": "https://rango.vip/i/UaiaFH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 2.549824400436222,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PUMPR",
    "name": "Pumpr",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "PumPRGmZ56t3Vngxo6fCP7ZJQ14oUg3biKxXrEyQBSf",
    "decimals": 6,
    "image": "https://rango.vip/i/zcdiyg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00022768,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$HONEY",
    "name": "HONEY",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HonyeYAaTPgKUgQpayL914P6VAqbQZPrbkGMETZvW4iN",
    "decimals": 6,
    "image": "https://rango.vip/i/4r3B0A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BOFB",
    "name": "BofB",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "45wdSjpSqZCk9mkqmq5Nh7beCEqqUJMJcVduwYCip5eq",
    "decimals": 8,
    "image": "https://rango.vip/i/LngS7r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BIRDS",
    "name": "BIRDIES",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9m9fmqJ2s7iUXZhFMsrdes1UcAtu3JyZXK3ZXfW3KPEw",
    "decimals": 5,
    "image": "https://rango.vip/i/fhhDEC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 6.60163e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "clOP",
    "name": "Cloned Optimism",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4cwVHQtwiK7r9aAUsu4cetk1JtLWPymopWcpTSsihCdL",
    "decimals": 8,
    "image": "https://rango.vip/i/iThsua",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.496156,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "D2X",
    "name": "D2",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GyuP7chtXSRB6erApifBxFvuTtz94x3zQo3JdWofBTgy",
    "decimals": 3,
    "image": "https://rango.vip/i/0kmPsZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0170374,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wHUSD_v1",
    "name": "HUSD Stablecoin (Wormhole v1)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX",
    "decimals": 8,
    "image": "https://rango.vip/i/HiN1vX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "JIN",
    "name": "Jinpeng",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GgzJWhQWTpv2VHqvJnx5uEWQzNkfQSncDEWUdgQk4fvh",
    "decimals": 9,
    "image": "https://rango.vip/i/XmwU7p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HAMWTR",
    "name": "Ham Water",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "HSwR3tZuxQKq8ddz9YxR4BX65Znm6MNdWGURw3x5FJQi",
    "decimals": 9,
    "image": "https://rango.vip/i/RySRNh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "VIBEZ",
    "name": "VIBEZ",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FyNuYGBBry5LAtPEkh8Y73izjTUNT2td2J3sGCK7E9Ju",
    "decimals": 9,
    "image": "https://rango.vip/i/1P9wIv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "COBAN",
    "name": "COBAN",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7udMmYXh6cuWVY6qQVCd9b429wDVn2J71r5BdxHkQADY",
    "decimals": 3,
    "image": "https://rango.vip/i/eQBpQW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00089505,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "smole",
    "name": "smolecoin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9Ttyez3xiruyj6cqaR495hbBkJU6SUWdV6AmQ9MvbyyS",
    "decimals": 6,
    "image": "https://rango.vip/i/hpfgYs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003339,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "xLFNTY",
    "name": "xLifinity",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "xLfNTYy76B8Tiix3hA51Jyvc1kMSFV4sPdR7szTZsRu",
    "decimals": 6,
    "image": "https://rango.vip/i/i2EvJf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "piin",
    "name": "piin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "piinKrjeiUVwhnXzsgNCV2aVGsBc4GwHQ9eCtkAgcTw",
    "decimals": 6,
    "image": "https://rango.vip/i/BR28Lh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00010691609296977559,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$POPO",
    "name": "Popo Pepe's Dog",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "96QoNkZLTKH7Gecf6dKMRXBQXW1wqh378uF9pnTejgAw",
    "decimals": 9,
    "image": "https://rango.vip/i/rfeitf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003479,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PRM",
    "name": "PRM Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "prmmgF5GJCSDNEcwZe2HWb5DsDsFngxTetZ95C4VKxX",
    "decimals": 6,
    "image": "https://rango.vip/i/0SyMEn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003763,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wHEX_v1",
    "name": "HEX (Wormhole v1)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv",
    "decimals": 8,
    "image": "https://rango.vip/i/NVIEzD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Genie",
    "name": "Genie",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "3Jjt8QhbqNoYfSQYHWf8ZsTJwE2CyvmUrzgzJD5Jpump",
    "decimals": 6,
    "image": "https://rango.vip/i/b0rxcr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00008682,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UNVAXSPERM",
    "name": "Unvaxxed Sperm",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9iQBkyLKtqRXvb6ARGvQydt1LYgGcTtVe3PTDpWhdPze",
    "decimals": 6,
    "image": "https://rango.vip/i/4dbdDx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00034932,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FUM",
    "name": "FUMoney",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EZF2sPJRe26e8iyXaCrmEefrGVBkqqNGv9UPGG9EnTQz",
    "decimals": 9,
    "image": "https://rango.vip/i/FEgXZM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$FLY",
    "name": "StayFly",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4h4LvS6NsVjZ87uBwrYyTeppTm1ii5PtRN9A6Ld2kZjw",
    "decimals": 6,
    "image": "https://rango.vip/i/7KZMoZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "ELSA",
    "name": "ELSA",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G7Dg4UU9zTULPe44Um9JjLSsSDZFDiVy8aLhzQuGjPsW",
    "decimals": 1,
    "image": "https://rango.vip/i/YDhw85",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00056392,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "buni",
    "name": "buni",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7y1TrdzE1cEeCgBvgdNB9DViMYdQ7UU2FKhnPDLYa7ae",
    "decimals": 6,
    "image": "https://rango.vip/i/jMxO3D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00001889,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sUSDT-8",
    "name": "Saber Wrapped USDT (8 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "T8KdT8hDzNhbGx5sjpEUxepnbDB1TZoCa7vtC5JjsMw",
    "decimals": 8,
    "image": "https://rango.vip/i/Pb3VGk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FATALITY",
    "name": "Fatality Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DXCoKQ7iLpux398fNHewQn6djfGobzFuPiR5o8hrVHAb",
    "decimals": 9,
    "image": "https://rango.vip/i/5m1gJo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01999826,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "MADx",
    "name": "Mad Lads",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Cj2NAxiDDVvY79PLBdVQK3F3bjoHp7hvZv4kLL1vgtV3",
    "decimals": 2,
    "image": "https://rango.vip/i/GtnfmF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wHAPI",
    "name": "Wrapped HAPI",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6VNKqgz9hk7zRShTFdg5AnkfKwZUcojzwAkzxSH3bnUm",
    "decimals": 9,
    "image": "https://rango.vip/i/0aMvzF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 8.7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HORNT",
    "name": "HORNT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "7nbJrQMK3FwJDMia93EpESHqqJiEdQqvuwnGMiwnWMJ5",
    "decimals": 6,
    "image": "https://rango.vip/i/Paj2lj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00004077,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "HUHCAT",
    "name": "huh cat",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6Ra49aqZTbEurJB1UQgAj1TjvbqajGP5um7gsTym8tWm",
    "decimals": 9,
    "image": "https://rango.vip/i/YZXrlY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00029737,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BRAH",
    "name": "BRAH SUPPS INTL.",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "6CBm8B8uoCUZWjs9n32BNy4z6LjGj4etBH2X48JWVhzf",
    "decimals": 6,
    "image": "https://rango.vip/i/8lQ1d8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WOOSH",
    "name": "NOIKE",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "29377Uz8eu6CeXmnMXJJge3gdaycyCbrubnLiUva75AA",
    "decimals": 6,
    "image": "https://rango.vip/i/pbNQPt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0000205,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Wojak",
    "name": "Wojak",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4MPA8WbyYtKiufXDSdckEoMPdN5XK1Xw9S9LSLMjK5Y4",
    "decimals": 9,
    "image": "https://rango.vip/i/UuUTkb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.0004520805822038964,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "CDC",
    "name": "Cat Driving a Car",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "ASNR8RxZ3wK8QiNxmVEvxXtFGCXCHRD8oiAaNihHJvLq",
    "decimals": 9,
    "image": "https://rango.vip/i/2a0KO8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BITXBIT",
    "name": "BITXBIT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5",
    "decimals": 9,
    "image": "https://rango.vip/i/Gb7ewq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "FAB",
    "name": "FABRIC",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96",
    "decimals": 9,
    "image": "https://rango.vip/i/8s3ZsB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 8.28817e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "abUSDT",
    "name": "Wrapped USDT (Allbridge from BSC)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E77cpQ4VncGmcAXX16LHFFzNBEBb2U7Ar7LBmZNfCgwL",
    "decimals": 9,
    "image": "https://rango.vip/i/JWLnc7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "BURD",
    "name": "tudaBirds Token",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Qikhhhg9Ta3Jg7WoDFbSYuCAE14hx9hPvdz1zVp3zUw",
    "decimals": 9,
    "image": "https://rango.vip/i/mrK94G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "STAN",
    "name": "Stanley Cup Coin",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CQSzJzwW5H1oyWrp6QhfUKYYwyovbSiVDKnAxNfb1tJC",
    "decimals": 5,
    "image": "https://rango.vip/i/T34pSd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00294245,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$PTRUMP",
    "name": "PepeTrump",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Gk2kRrwNMBU4Dn9JhC1Dks8G5X9nqi4ZE5jMvK6bdgEd",
    "decimals": 6,
    "image": "https://rango.vip/i/Yv5eAD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00046913,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "zSOL",
    "name": "zSOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "So111DzVTTNpDq81EbeyKZMi4SkhU9yekqB8xmMpqzA",
    "decimals": 9,
    "image": "https://rango.vip/i/7s5hlk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 129.27,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Orys",
    "name": "ORYS",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Fa96VxtHsUwGbi8DtjXFjsUjAP6rm18AWw5ezsTN4sxw",
    "decimals": 9,
    "image": "https://rango.vip/i/xRjf1c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sunSBR",
    "name": "sunSBR",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "sunSBR8hRLZy13HCHdrT3hjJZVRpkVfXF3QaaJYjxZ2",
    "decimals": 6,
    "image": "https://rango.vip/i/3dV7xv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WIPE",
    "name": "WipeMyAss",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "9ae76zqD3cgzR9gvf5Thc2NN3ACF7rqqnrLqxNzgcre6",
    "decimals": 9,
    "image": "https://rango.vip/i/pnBEsF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00000424,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "sBTC-8",
    "name": "Saber Wrapped Bitcoin (Sollet) (8 decimals)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv",
    "decimals": 8,
    "image": "https://rango.vip/i/PRSo72",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "DRAKO",
    "name": "Drako",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "drakduQWnTS89CdTUdgHmZzEkN6reBLYqrk8rzVhU53",
    "decimals": 5,
    "image": "https://rango.vip/i/KCFhKX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 1.44871e-7,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$SNOOPY",
    "name": "Snoopy",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "4LP5JKsyKC5pSAoodwcZnDCSK2ggsMcZvHKoo7HCPDCV",
    "decimals": 9,
    "image": "https://rango.vip/i/Ba44eF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00023543227094394913,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "GOBI",
    "name": "Gobi",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "MarcoPaG4dV4qit3ZPGPFm4qt4KKNBKvAsm2rPGNF72",
    "decimals": 6,
    "image": "https://rango.vip/i/c0dwWw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "WYAC",
    "name": "WOMAN YELLING AT CAT",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "BEgBsVSKJSxreiCE1XmWWq8arnwit7xDqQXSWYgay9xP",
    "decimals": 6,
    "image": "https://rango.vip/i/t14TXp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00097112,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "apUSDT",
    "name": "Wrapped USDT (Allbridge from Polygon)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "DNhZkUaxHXYvpxZ7LNnHtss8sQgdAfd1ZYS1fB7LKWUZ",
    "decimals": 6,
    "image": "https://rango.vip/i/JWLnc7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "AFSeX",
    "name": "𝝠ndre's Fleet Share",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "FbUy1NubUtCfoif7TAr6wAtJVJFapYUVWxRGmUwvKNyS",
    "decimals": 2,
    "image": "https://rango.vip/i/vj1VCh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "POLYGONE",
    "name": "Polygone on SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "J9nsngni1Pavf4ijP4R9QBaD1yEzKzzUQ1vVgcDQT18J",
    "decimals": 5,
    "image": "https://rango.vip/i/OMqaDH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "pre",
    "name": "pre",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "G6mc7tiVSym3zrmmxekF3HYSc9c2hiKnGk7idoHqHTUK",
    "decimals": 9,
    "image": "https://rango.vip/i/LcPQRP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00003651,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "pussyinbio",
    "name": "Pussy In Bio",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "E9pBR4xjscYLPqFZ4YM4gUkczqz7MHpB6dk4sfSQTnJD",
    "decimals": 6,
    "image": "https://rango.vip/i/7PcMUl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.000026059221304052092,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "abETH",
    "name": "Wrapped ETH (Allbridge from BSC)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EyrnrbE5ujd3HQG5PZd9MbECN9yaQrqc8pRwGtaLoyC",
    "decimals": 9,
    "image": "https://rango.vip/i/8Jepq5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "acUSD",
    "name": "Wrapped CUSD (Allbridge from Celo)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EwxNF8g9UfmsJVcZFTpL9Hx5MCkoQFoJi6XNWzKf1j8e",
    "decimals": 9,
    "image": "https://rango.vip/i/0qvcYc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.993496,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "compassSOL",
    "name": "Compass SOL",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "Comp4ssDzXcLeu2MnLuGNNFC4cmLPMng8qWHPvzAMU1h",
    "decimals": 9,
    "image": "https://rango.vip/i/zJaRSI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 134.52,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "wUST_v1",
    "name": "Wrapped UST (Wormhole v1)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm",
    "decimals": 9,
    "image": "https://rango.vip/i/OYhusE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.01544835,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "UPS",
    "name": "UPS token (UPFI Network)",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7",
    "decimals": 6,
    "image": "https://rango.vip/i/RMu9vK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00009201,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "Speero",
    "name": "Speero",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "C7K4Tot6fnnNwhWpqw9H277QPcP56vHAEeXubRHDyCo9",
    "decimals": 6,
    "image": "https://rango.vip/i/jpyW8D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 3.391e-9,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "PNKZ",
    "name": "PepePunkz",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "GE9R56XteXi2DF5LPmVJ5BEDCk9wnEEhvM5t5xcuXG9c",
    "decimals": 9,
    "image": "https://rango.vip/i/WBCEKH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "SOLANA",
    "symbol": "$COFFEE",
    "name": "Coffee",
    "isPopular": false,
    "chainId": "mainnet-beta",
    "address": "85Yf3z6zwgLGQLfxww7KoHGw4sHpx4iki2P1Ci7k7gqZ",
    "decimals": 6,
    "image": "https://rango.vip/i/uZmG0e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/SOLANA/icon.svg",
    "usdPrice": 0.00833422,
    "supportedSwappers": [
      "Jupiter"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "AMOON",
    "name": "ArbiMoon",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1a7bd9edc36fb2b3c0852bcd7438c2a957fd7ad5",
    "decimals": 9,
    "image": "https://rango.vip/i/7CRIje",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ARBIFLUX",
    "name": "ArbiFLUX",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x64081252c497fcfec247a664e9d10ca8ed71b276",
    "decimals": 18,
    "image": "https://rango.vip/i/FpL0Ss",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ARBIS",
    "name": "Arbis",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x9f20de1fc9b161b34089cbeae888168b44b03461",
    "decimals": 18,
    "image": "https://rango.vip/i/MSncQ9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ARBY",
    "name": "Adamant Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x09ad12552ec45f82be90b38dfe7b06332a680864",
    "decimals": 18,
    "image": "https://rango.vip/i/wLZw9g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ARBYS",
    "name": "Arbys",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x86a1012d437bbff84fbdf62569d12d4fd3396f8c",
    "decimals": 18,
    "image": "https://rango.vip/i/LDzq42",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ARVAULT",
    "name": "ArVault",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xa6219b4bf4b861a2b1c02da43b2af266186edc04",
    "decimals": 9,
    "image": "https://rango.vip/i/nOcAAv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "CREDA",
    "name": "CreDA Protocol Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc136e6b376a9946b156db1ed3a34b08afdaed76d",
    "decimals": 18,
    "image": "https://rango.vip/i/DWhATO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "JETH",
    "name": "Jones ETH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x662d0f9ff837a51cf89a1fe7e0882a906dac08a3",
    "decimals": 18,
    "image": "https://rango.vip/i/BUC7BT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LIQD",
    "name": "Liquid",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x93c15cd7de26f07265f0272e0b831c5d7fab174f",
    "decimals": 18,
    "image": "https://rango.vip/i/7ZPmrU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 2300.99,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "LQETH",
    "name": "liquid ETH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x73700aecfc4621e112304b6edc5ba9e36d7743d3",
    "decimals": 18,
    "image": "https://rango.vip/i/H7XmMV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "MAGNET",
    "name": "Magnethereum",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x8ed4191f81f1e1d24a8a1195267d024d9358c9d7",
    "decimals": 18,
    "image": "https://rango.vip/i/7mFwKk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "NUON",
    "name": "NUON",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xfb9fed8cb962548a11fe7f6f282949061395c7f5",
    "decimals": 18,
    "image": "https://rango.vip/i/qONT0r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.033,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "PANA",
    "name": "Pana DAO",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x369eb8197062093a20402935d3a707b4ae414e9d",
    "decimals": 18,
    "image": "https://rango.vip/i/DOu7mQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "W3N",
    "name": "Web3 No Value",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf7693c6fd9a7172d537fa75d133d309501cbd657",
    "decimals": 18,
    "image": "https://rango.vip/i/96sfnd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.138e-9,
    "supportedSwappers": [
      "SushiArbitrum"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "BALDCAT",
    "name": "Baldcat",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x1443cbca252d1ffae56b4ee4cc904e9554e2f6ad",
    "decimals": 8,
    "image": "https://rango.vip/i/JKijoD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.000015843124061044417,
    "supportedSwappers": [
      "SushiBase"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "FELLA",
    "name": "FELLA",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x122a3f185655847980639e8edf0f0f66cd91c5fe",
    "decimals": 18,
    "image": "https://rango.vip/i/uWuyqr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.003053984777895661,
    "supportedSwappers": [
      "SushiBase"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "KIBBLE",
    "name": "Kibble",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x64cc19a52f4d631ef5be07947caba14ae00c52eb",
    "decimals": 18,
    "image": "https://rango.vip/i/AuLAPj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.00516687,
    "supportedSwappers": [
      "SushiBase"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "NANG",
    "name": "Base Balloons",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x2ccbecc47d83ee80e27fd09c8e757b82f1ffad4c",
    "decimals": 18,
    "image": "https://rango.vip/i/yExdRH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.000005762234752768076,
    "supportedSwappers": [
      "SushiBase"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UST",
    "name": "Wrapped UST Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x692597b009d13c4049a947cab2239b7d6517875f",
    "decimals": 18,
    "image": "https://rango.vip/i/RDYURs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01544835,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ANKRMATIC",
    "name": "Ankr Staked MATIC",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0e9b89007eee9c958c0eda24ef70723c2c93dd58",
    "decimals": 18,
    "image": "https://rango.vip/i/HFHnIA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.438285,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "TRY",
    "name": "TryHards",
    "isPopular": false,
    "chainId": "137",
    "address": "0xefee2de82343be622dcb4e545f75a3b9f50c272d",
    "decimals": 18,
    "image": "https://rango.vip/i/BbBA6T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00384854,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "VSQ",
    "name": "VSQ",
    "isPopular": false,
    "chainId": "137",
    "address": "0x29f1e986fca02b7e54138c04c4f503dddd250558",
    "decimals": 9,
    "image": "https://rango.vip/i/2csJMk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ICE_3",
    "name": "IceToken",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59",
    "decimals": 18,
    "image": "https://rango.vip/i/NxADXR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.219258,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MABBC",
    "name": "Matic ABBC",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe83ce6bfb580583bd6a62b4be7b34fc25f02910d",
    "decimals": 8,
    "image": "https://rango.vip/i/XnYQ4H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SNP",
    "name": "Synapse Network",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d",
    "decimals": 18,
    "image": "https://rango.vip/i/Uhp7AN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03005783,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "STMATIC",
    "name": "Staked MATIC (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4",
    "decimals": 18,
    "image": "https://rango.vip/i/CJaqz2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.412968,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GCR",
    "name": "Global Coin Research (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa69d14d6369e414a32a5c7e729b7afbafd285965",
    "decimals": 4,
    "image": "https://rango.vip/i/bSPN52",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.245057,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "JCHF",
    "name": "Jarvis Synthetic Swiss Franc",
    "isPopular": false,
    "chainId": "137",
    "address": "0xbd1463f02f61676d53fd183c2b19282bff93d099",
    "decimals": 18,
    "image": "https://rango.vip/i/YtooLl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.815205,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SALE",
    "name": "DxSale.Network (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8f6196901a4a153d8ee8f3fa779a042f6092d908",
    "decimals": 18,
    "image": "https://rango.vip/i/zfHvx1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.145844,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GFC",
    "name": "GCOIN",
    "isPopular": false,
    "chainId": "137",
    "address": "0x071ac29d569a47ebffb9e57517f855cb577dcc4c",
    "decimals": 18,
    "image": "https://rango.vip/i/CQaoX6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00346428,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AGAR",
    "name": "AGA Rewards",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf84bd51eab957c2e7b7d646a3427c5a50848281d",
    "decimals": 8,
    "image": "https://rango.vip/i/KmT5qT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "IFARM",
    "name": "iFARM",
    "isPopular": false,
    "chainId": "137",
    "address": "0xab0b2ddb9c7e440fac8e140a89c0dbcbf2d7bbff",
    "decimals": 18,
    "image": "https://rango.vip/i/Ne2yqe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 61.59,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MOCEAN",
    "name": "Ocean Token (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x282d8efce846a88b159800bd4130ad77443fa1a1",
    "decimals": 18,
    "image": "https://rango.vip/i/YYi9Zr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.457784,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "KRILL",
    "name": "Krill",
    "isPopular": false,
    "chainId": "137",
    "address": "0x05089c9ebffa4f0aca269e32056b1b36b37ed71b",
    "decimals": 18,
    "image": "https://rango.vip/i/ZoIQVC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00081479,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AMUSDC",
    "name": "Aave Matic Market USDC",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1a13f4ca1d028320a707d99520abfefca3998b7f",
    "decimals": 6,
    "image": "https://rango.vip/i/UTRZ3v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.998342,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AMUSDT",
    "name": "Aave Matic Market USDT",
    "isPopular": false,
    "chainId": "137",
    "address": "0x60d55f02a771d515e077c9c2403a1ef324885cec",
    "decimals": 6,
    "image": "https://rango.vip/i/5fr5N9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.9982,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AMWBTC",
    "name": "Aave Matic Market WBTC",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5c2ed810328349100a66b82b78a1791b101c9d61",
    "decimals": 8,
    "image": "https://rango.vip/i/abM6py",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 55566,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AMWETH",
    "name": "Aave Matic Market WETH",
    "isPopular": false,
    "chainId": "137",
    "address": "0x28424507fefb6f7f8e9d3860f56504e4e5f5f390",
    "decimals": 18,
    "image": "https://rango.vip/i/23cLbe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2343.53,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AMWMATIC",
    "name": "Aave Matic Market WMATIC",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4",
    "decimals": 18,
    "image": "https://rango.vip/i/bbeHk8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.3717,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AMAAVE",
    "name": "Aave Matic Market AAVE",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360",
    "decimals": 18,
    "image": "https://rango.vip/i/ozHjcX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 125.99,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AMDAI",
    "name": "Aave Matic Market DAI",
    "isPopular": false,
    "chainId": "137",
    "address": "0x27f8d03b3a2196956ed754badc28d73be8830a6e",
    "decimals": 18,
    "image": "https://rango.vip/i/ME2kqG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.998361,
    "supportedSwappers": [
      "PolygonCurveFinance",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WEXPOLY",
    "name": "WaultSwap Polygon",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4c4bf319237d98a30a929a96112effa8da3510eb",
    "decimals": 18,
    "image": "https://rango.vip/i/KlH1v4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "OMEN",
    "name": "Augury Finance",
    "isPopular": false,
    "chainId": "137",
    "address": "0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e",
    "decimals": 18,
    "image": "https://rango.vip/i/3yJxMl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00097153,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NACHO",
    "name": "NACHO",
    "isPopular": false,
    "chainId": "137",
    "address": "0xcd86152047e800d67bdf00a4c635a8b6c0e5c4c2",
    "decimals": 18,
    "image": "https://rango.vip/i/SHd7Rk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.04527457,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WIXS",
    "name": "Wrapped Ixs Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1ba17c639bdaecd8dc4aac37df062d17ee43a1b8",
    "decimals": 18,
    "image": "https://rango.vip/i/7fwzC5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.374638,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DEUSDC",
    "name": "deBridge USD Coin",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa",
    "decimals": 6,
    "image": "https://rango.vip/i/H0wf6U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CXETH",
    "name": "CelsiusX Wrapped ETH",
    "isPopular": false,
    "chainId": "137",
    "address": "0xfe4546fefe124f30788c4cc1bb9aa6907a7987f9",
    "decimals": 18,
    "image": "https://rango.vip/i/IiYyI0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 29.95,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ROUTE (POS)",
    "name": "Route",
    "isPopular": false,
    "chainId": "137",
    "address": "0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4",
    "decimals": 18,
    "image": "https://rango.vip/i/2S1B3d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.56,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DERC",
    "name": "DeRace Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb35fcbcf1fd489fce02ee146599e893fdcdc60e6",
    "decimals": 18,
    "image": "https://rango.vip/i/KbSjXE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.31295,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "VHC",
    "name": "Vault Hill City (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x51b5619f5180e333d18b6310c8d540aea43a0371",
    "decimals": 18,
    "image": "https://rango.vip/i/51o9a0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MESH",
    "name": "Meshswap Protocol",
    "isPopular": false,
    "chainId": "137",
    "address": "0x82362ec182db3cf7829014bc61e9be8a2e82868a",
    "decimals": 18,
    "image": "https://rango.vip/i/gWLCCW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00655649,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "XZAR",
    "name": "South African Tether (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x30de46509dbc3a491128f97be0aaf70dc7ff33cb",
    "decimals": 18,
    "image": "https://rango.vip/i/Vxl7YI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AURUM",
    "name": "RaiderAurum",
    "isPopular": false,
    "chainId": "137",
    "address": "0x34d4ab47bee066f361fa52d792e69ac7bd05ee23",
    "decimals": 18,
    "image": "https://rango.vip/i/Z6EEeK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0002553,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RAIDER",
    "name": "RaiderToken",
    "isPopular": false,
    "chainId": "137",
    "address": "0xcd7361ac3307d1c5a46b63086a90742ff44c63b3",
    "decimals": 18,
    "image": "https://rango.vip/i/rZbO37",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00616089,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DDAO",
    "name": "DEFI HUNTERS DAO Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x90f3edc7d5298918f7bb51694134b07356f7d0c7",
    "decimals": 18,
    "image": "https://rango.vip/i/32ujlY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.02846596,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CUSDCV3",
    "name": "Compound USDC",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf25212e676d1f7f89cd72ffee66158f541246445",
    "decimals": 6,
    "image": "https://rango.vip/i/wWpuJw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DE",
    "name": "DeNet File Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x081ec4c0e30159c8259bad8f4887f83010a681dc",
    "decimals": 18,
    "image": "https://rango.vip/i/IwMiiG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00540317,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CRISP-M",
    "name": "CRISP Scored Mangroves",
    "isPopular": false,
    "chainId": "137",
    "address": "0xef6ab48ef8dfe984fab0d5c4cd6aff2e54dfda14",
    "decimals": 18,
    "image": "https://rango.vip/i/7FMUnW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "OX_OLD",
    "name": "Open Exchange Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x78a0a62fba6fb21a83fe8a3433d44c73a4017a6f",
    "decimals": 18,
    "image": "https://rango.vip/i/T6sdOv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00025644,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RETRO",
    "name": "RETRO",
    "isPopular": false,
    "chainId": "137",
    "address": "0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb",
    "decimals": 18,
    "image": "https://rango.vip/i/FXNkHU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0029167,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DFX_1",
    "name": "DFX Token (L2)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x27f485b62c4a7e635f561a87560adf5090239e93",
    "decimals": 18,
    "image": "https://rango.vip/i/vVutjG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0222633,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "A3A",
    "name": "3A Utility Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x58c7b2828e7f2b2caa0cc7feef242fa3196d03df",
    "decimals": 18,
    "image": "https://rango.vip/i/tMG0da",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00179534,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FXCBETH",
    "name": "Coinbase Wrapped Staked ETH (FXERC20)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4b4327db1600b8b1440163f667e199cef35385f5",
    "decimals": 18,
    "image": "https://rango.vip/i/6URLkg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 2528.63,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DQUICK(NEW)",
    "name": "Dragon QUICK(NEW)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x958d208cdf087843e9ad98d23823d32e17d723a1",
    "decimals": 18,
    "image": "https://rango.vip/i/nZWu4b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.054065,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EURO3",
    "name": "EURO3",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa0e4c84693266a9d3bbef2f394b33712c76599ab",
    "decimals": 18,
    "image": "https://rango.vip/i/eNagbZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.097,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FCC",
    "name": "first choice coin",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb6c3c00d730acca326db40e418353f04f7444e2b",
    "decimals": 18,
    "image": "https://rango.vip/i/4d46dN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FXVRSW",
    "name": "Virtuswap Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x57999936fc9a9ec0751a8d146cce11901be8bed0",
    "decimals": 18,
    "image": "https://rango.vip/i/RYslTR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00061966,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GLTR",
    "name": "GAX Liquidity Token Reward",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3801c3b3b5c98f88a9c9005966aa96aa440b9afc",
    "decimals": 18,
    "image": "https://rango.vip/i/9N4K7g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000136,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GOON",
    "name": "GOON",
    "isPopular": false,
    "chainId": "137",
    "address": "0x433cde5a82b5e0658da3543b47a375dffd126eb6",
    "decimals": 18,
    "image": "https://rango.vip/i/vPDFkV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00207786,
    "supportedSwappers": [
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LGNS",
    "name": "Longinus",
    "isPopular": false,
    "chainId": "137",
    "address": "0xeb51d9a39ad5eef215dc0bf39a8821ff804a0f01",
    "decimals": 9,
    "image": "https://rango.vip/i/VjPifO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 32.81,
    "supportedSwappers": [
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PBORA",
    "name": "pBORA",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0ef39e52704ad52e2882bbfa6781167e1b6c4510",
    "decimals": 18,
    "image": "https://rango.vip/i/mPmxz4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0802094294875151,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RVLT",
    "name": "Revolt 2 Earn",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5d301750cc9719f00872e33ee81f9c37aba242f4",
    "decimals": 18,
    "image": "https://rango.vip/i/QBi3Na",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 6.779e-9,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "QuickSwap",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SLING",
    "name": "Slingshot",
    "isPopular": false,
    "chainId": "137",
    "address": "0xfc9fa9771145fbb98d15c8c2cc94b837a56d554c",
    "decimals": 18,
    "image": "https://rango.vip/i/lkF8As",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00150573,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WTAKI",
    "name": "Wormhole Taki",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd872b7ffca41a67eda85b04a9185c6b270006b58",
    "decimals": 9,
    "image": "https://rango.vip/i/mNYKwc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AXMATIC",
    "name": "axMatic",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1221591c1d77a9c334abb0fe530ae6ee3af51af9",
    "decimals": 18,
    "image": "https://rango.vip/i/f2cv00",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BOR",
    "name": "BoringDao",
    "isPopular": false,
    "chainId": "137",
    "address": "0xff88434e29d1e2333ad6baa08d358b436196da6b",
    "decimals": 18,
    "image": "https://rango.vip/i/lJ6mj0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 35.32,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BZRX",
    "name": "BZRX Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x54cfe73f2c7d0c4b62ab869b473f5512dc0944d2",
    "decimals": 18,
    "image": "https://rango.vip/i/9vUmPj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01317445,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CERBY",
    "name": "Cerby Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdef1fac7bf08f173d286bbbdcbeeade695129840",
    "decimals": 18,
    "image": "https://rango.vip/i/xnevMK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CHECK",
    "name": "Check Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb74f2220ad34a03730400c9c97bd10863bbc4130",
    "decimals": 6,
    "image": "https://rango.vip/i/HvCi4y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.0246019,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CHUM",
    "name": "ChumHum",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2e2dde47952b9c7defde7424d00dd2341ad927ca",
    "decimals": 18,
    "image": "https://rango.vip/i/menXYS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CSUSHI",
    "name": "cSUSHI",
    "isPopular": false,
    "chainId": "137",
    "address": "0x26aa9b3d8a49a2ed849ac66ea9aa37ee36bc6b24",
    "decimals": 18,
    "image": "https://rango.vip/i/TozCAZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01191475,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "$DG",
    "name": "decentral.games (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2a93172c8dccbfbc60a39d56183b7279a2f647b4",
    "decimals": 18,
    "image": "https://rango.vip/i/Ykyp4g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 11.47,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DMAGIC",
    "name": "Dark Magic",
    "isPopular": false,
    "chainId": "137",
    "address": "0x61daecab65ee2a1d5b6032df030f3faa3d116aa7",
    "decimals": 18,
    "image": "https://rango.vip/i/1s2FdX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.062594,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DRAX",
    "name": "Drax",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1ba3510a9ceeb72e5cdba8bcdde9647e1f20fb4b",
    "decimals": 18,
    "image": "https://rango.vip/i/AddVdq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DTOP",
    "name": "dHEDGE Top Index",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0361bdeab89df6bbcc52c43589fabba5143d19dd",
    "decimals": 18,
    "image": "https://rango.vip/i/Ram9PN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ELE",
    "name": "Eleven.finance",
    "isPopular": false,
    "chainId": "137",
    "address": "0xacd7b3d9c10e97d0efa418903c0c7669e702e4c0",
    "decimals": 18,
    "image": "https://rango.vip/i/NquDgr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000123,
    "supportedSwappers": [
      "ParaSwap Polygon",
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EP",
    "name": "Elemental Particles",
    "isPopular": false,
    "chainId": "137",
    "address": "0x60ed6acef3a96f8cdaf0c0d207bbafa66e751af2",
    "decimals": 18,
    "image": "https://rango.vip/i/eF548c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ETH2X-FLI-P",
    "name": "ETH 2x Flexible Leverage Index",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3ad707da309f3845cd602059901e39c4dcd66473",
    "decimals": 18,
    "image": "https://rango.vip/i/KTZhPV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 5.12,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "FIREFBX",
    "name": "FireVault FBX",
    "isPopular": false,
    "chainId": "137",
    "address": "0x960d43be128585ca45365cd74a7773b9d814dfbe",
    "decimals": 18,
    "image": "https://rango.vip/i/AE6Uzp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "GMS",
    "name": "Gemstones",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0d962a1a2a27b402e4d84772dea65ac8592eb6bf",
    "decimals": 18,
    "image": "https://rango.vip/i/gcTLct",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "IBBTC",
    "name": "Interest-Bearing BTC",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4eac4c4e9050464067d673102f8e24b2fcceb350",
    "decimals": 18,
    "image": "https://rango.vip/i/gM0JgA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "IMRTL",
    "name": "Immortl",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2df54842cd85c60f21b4871e09bcc6047b2dcc4d",
    "decimals": 18,
    "image": "https://rango.vip/i/J1x9lT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LICP",
    "name": "Liquid ICP",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1b43b97094aa3c6cc678edb9e28ac67daaa7cc64",
    "decimals": 18,
    "image": "https://rango.vip/i/1ByqNa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "LSMATIC",
    "name": "Liquid Staked MATIC Index",
    "isPopular": false,
    "chainId": "137",
    "address": "0x858bcee0e62dd0c961611ddddb908767b0ce801c",
    "decimals": 18,
    "image": "https://rango.vip/i/m3kn1o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MSC",
    "name": "MetaSoccer Cash",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5d2375c6af4b7de4e395ada20aab937895b4fa70",
    "decimals": 18,
    "image": "https://rango.vip/i/9cTyFS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NBO",
    "name": "Nature Based Offset",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6bca3b77c1909ce1a4ba1a20d1103bde8d222e48",
    "decimals": 18,
    "image": "https://rango.vip/i/iwOVr5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NCR",
    "name": "Neos Credits",
    "isPopular": false,
    "chainId": "137",
    "address": "0xda6f726e2088f129d3ecb2257206adf7d8537ba5",
    "decimals": 18,
    "image": "https://rango.vip/i/ZyVSnm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.04846678,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NGNC",
    "name": "NGN Coin",
    "isPopular": false,
    "chainId": "137",
    "address": "0xab9ad9089f23e6779a8727900709427719f753e1",
    "decimals": 6,
    "image": "https://rango.vip/i/AVFcoi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PAUTO",
    "name": "Orbit Bridge Polygon Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x7f426f6dc648e50464a0392e60e1bb465a67e9cf",
    "decimals": 18,
    "image": "https://rango.vip/i/g0GUUI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 10.08,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PECO",
    "name": "Polygon Ecosystem Index",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa9536b9c75a9e0fae3b56a96ac8edf76abc91978",
    "decimals": 18,
    "image": "https://rango.vip/i/2pZVnp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PGOV",
    "name": "bZX PGOV Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd5d84e75f48e75f01fb2eb6dfd8ea148ee3d0feb",
    "decimals": 18,
    "image": "https://rango.vip/i/Mm8OjB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PHBD",
    "name": "Polygon HBD",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6d969cea201e427d2875724fd4e8044833fbc7f4",
    "decimals": 3,
    "image": "https://rango.vip/i/HIBE89",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.743517,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "POLAR",
    "name": "Polaris Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdc3acb92712d1d44ffe15d3a8d66d9d18c81e038",
    "decimals": 18,
    "image": "https://rango.vip/i/U5IqKT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00001486,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "POLYBUNNY",
    "name": "Polygon Bunny Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4c16f69302ccb511c5fac682c7626b9ef0dc126a",
    "decimals": 18,
    "image": "https://rango.vip/i/AIMRsN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00538692,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PTREAT",
    "name": "pTREAT",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5d0915f929fc090fd9c843a53e7e30335dd199bc",
    "decimals": 18,
    "image": "https://rango.vip/i/6ZVcQz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PUSD",
    "name": "PUSD",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9af3b7dc29d3c4b1a5731408b6a9656fa7ac3b72",
    "decimals": 18,
    "image": "https://rango.vip/i/tf1pJp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.140123,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PYD",
    "name": "PolyQuity Dollar",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0527c8c43250279d6eb74da1078193f5316fc9a0",
    "decimals": 18,
    "image": "https://rango.vip/i/QKhTus",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PYQ",
    "name": "PYQ",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5a3064cbdccf428ae907796cf6ad5a664cd7f3d8",
    "decimals": 18,
    "image": "https://rango.vip/i/fPpXQy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "PZUG",
    "name": "pZUG",
    "isPopular": false,
    "chainId": "137",
    "address": "0xeb45921fedadf41df0bfcf5c33453acedda32441",
    "decimals": 18,
    "image": "https://rango.vip/i/2W7M9t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "REIN",
    "name": "Reyna Ventures",
    "isPopular": false,
    "chainId": "137",
    "address": "0x23c70dd93d4ecac7fb93631488c5412e02f4a57c",
    "decimals": 18,
    "image": "https://rango.vip/i/yqhdol",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RENDGB",
    "name": "renDGB",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2628568509e87c4429fbb5c664ed11391be1bd29",
    "decimals": 8,
    "image": "https://rango.vip/i/XiczEp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RENDOGE",
    "name": "Ren DOGE",
    "isPopular": false,
    "chainId": "137",
    "address": "0xce829a89d4a55a63418bcc43f00145adef0edb8e",
    "decimals": 8,
    "image": "https://rango.vip/i/051sxh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RENFIL",
    "name": "renFIL",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc4ace9278e7e01755b670c0838c3106367639962",
    "decimals": 18,
    "image": "https://rango.vip/i/GiumrS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RIC",
    "name": "RIC",
    "isPopular": false,
    "chainId": "137",
    "address": "0x263026e7e53dbfdce5ae55ade22493f828922965",
    "decimals": 18,
    "image": "https://rango.vip/i/P4e3yd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00560389,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ROLL",
    "name": "Polyroll Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xc68e83a305b0fad69e264a1769a0a070f190d2d6",
    "decimals": 18,
    "image": "https://rango.vip/i/AISFtS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00032302,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UBO",
    "name": "Universal Basic Offset",
    "isPopular": false,
    "chainId": "137",
    "address": "0x2b3ecb0991af0498ece9135bcd04013d7993110c",
    "decimals": 18,
    "image": "https://rango.vip/i/23J6LE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UNITY",
    "name": "Unity",
    "isPopular": false,
    "chainId": "137",
    "address": "0x7e94de269df2eb9f4d0443d46500191f19c9a8da",
    "decimals": 18,
    "image": "https://rango.vip/i/8bGJWs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WARU",
    "name": "Waru Token V2",
    "isPopular": false,
    "chainId": "137",
    "address": "0xfa3872970c1952741c518b33f1dce815f55dc00a",
    "decimals": 18,
    "image": "https://rango.vip/i/TkhMuM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WBAN",
    "name": "Wrapped Banano",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe20b9e246db5a0d21bf9209e4858bc9a3ff7a034",
    "decimals": 18,
    "image": "https://rango.vip/i/P3g1aX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00307791,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WCCX",
    "name": "WrappedConceal",
    "isPopular": false,
    "chainId": "137",
    "address": "0x137ee749f0f8c2ed34ca00de33bb59e3dafa494a",
    "decimals": 6,
    "image": "https://rango.vip/i/HxDDNA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WFIL",
    "name": "Wrapped Filecoin",
    "isPopular": false,
    "chainId": "137",
    "address": "0xede1b77c0ccc45bfa949636757cd2ca7ef30137f",
    "decimals": 18,
    "image": "https://rango.vip/i/X6sgAn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 3.33,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WOOFY",
    "name": "Woofy",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd0660cd418a64a1d44e9214ad8e459324d8157f1",
    "decimals": 12,
    "image": "https://rango.vip/i/8qjqQ6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchPolygon"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "DBASE",
    "name": "Doge Base",
    "isPopular": false,
    "chainId": "8453",
    "address": "0xbcf19453badf7c7ac0630b11dcb75d52b7eb8930",
    "decimals": 18,
    "image": "https://rango.vip/i/qRuqnH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "AXLUSDT",
    "name": "Axelar Wrapped USDT",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x7f5373ae26c3e8ffc4c77b7255df7ec1a9af52a6",
    "decimals": 6,
    "image": "https://rango.vip/i/5nBoGG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": 0.998992,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CUSDBCV3",
    "name": "Compound USDbC",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x9c4ec768c28520b50860ea7a15bd7213a9ff58bf",
    "decimals": 6,
    "image": "https://rango.vip/i/doct7Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "BASE",
    "symbol": "CWETHV3",
    "name": "Compound WETH",
    "isPopular": false,
    "chainId": "8453",
    "address": "0x46e6b214b524310239732d51387075e0e70970bf",
    "decimals": 18,
    "image": "https://rango.vip/i/SAMJRl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BASE/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBase"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UST",
    "name": "Wrapped UST Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa47c8bf37f92abed4a126bda807a7b7498661acd",
    "decimals": 18,
    "image": "https://rango.vip/i/vE2qej",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01544835,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBCH",
    "name": "Binance Wrapped BCH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85c4edc43724e954e5849caaab61a26a9cb65f1b",
    "decimals": 8,
    "image": "https://rango.vip/i/W5hA8G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BDOT",
    "name": "Binance Wrapped DOT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7884f51dc1410387371ce61747cb6264e1daee0b",
    "decimals": 10,
    "image": "https://rango.vip/i/EudUvM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUSDCV3",
    "name": "Compound USDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3d688b66703497daa19211eedff47f25384cdc3",
    "decimals": 6,
    "image": "https://rango.vip/i/YHccSB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HRD",
    "name": "Hoard",
    "isPopular": false,
    "chainId": "1",
    "address": "0x461b71cff4d4334bba09489ace4b5dc1a1813445",
    "decimals": 9,
    "image": "https://rango.vip/i/qGnO32",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WLITI",
    "name": "wLitiCapital",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b63128c40737b13647552e0c926bcfeccc35f93",
    "decimals": 18,
    "image": "https://rango.vip/i/Gp8cIM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00300811,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NATION",
    "name": "Nation3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x333a4823466879eef910a04d473505da62142069",
    "decimals": 18,
    "image": "https://rango.vip/i/8Qv6jY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 28.88,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XAUT",
    "name": "Tether Gold",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68749665ff8d2d112fa859aa293f07a622782f38",
    "decimals": 6,
    "image": "https://rango.vip/i/ZJdn6t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2517.56,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRO",
    "name": "Gro DAO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ec8798b81485a254928b70cda1cf0a2bb0b74d7",
    "decimals": 18,
    "image": "https://rango.vip/i/pCmS1Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.413768,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GBPT",
    "name": "poundtoken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x86b4dbe5d203e634a12364c0e428fa242a3fba98",
    "decimals": 18,
    "image": "https://rango.vip/i/I6nj7Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.21,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAD",
    "name": "NearPad Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea7cc765ebc94c4805e3bff28d7e4ae48d06468a",
    "decimals": 18,
    "image": "https://rango.vip/i/ABrxM0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00926967,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CWETHV3",
    "name": "Compound WETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa17581a9e3356d9a858b789d68b4d866e593ae94",
    "decimals": 18,
    "image": "https://rango.vip/i/s1Bbmf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DSETH",
    "name": "Diversified Staked ETH Index (dsETH)",
    "isPopular": false,
    "chainId": "1",
    "address": "0x341c05c0e9b33c0e38d64de76516b2ce970bb3be",
    "decimals": 18,
    "image": "https://rango.vip/i/PF8yIe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2487.12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHWETH",
    "name": "Aave Ethereum WETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4d5f47fa6a74757f35c14fd3a6ef8e3c9bc514e8",
    "decimals": 18,
    "image": "https://rango.vip/i/b4CwZr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2344.72,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHDAI",
    "name": "Aave Ethereum DAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x018008bfb33d285247a21d44e50697654f754e63",
    "decimals": 18,
    "image": "https://rango.vip/i/8pePox",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999938,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHWSTETH",
    "name": "Aave Ethereum wstETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b925ed163218f6662a35e0f0371ac234f9e9371",
    "decimals": 18,
    "image": "https://rango.vip/i/ucCPgr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2758.97,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHUSDC",
    "name": "Aave Ethereum USDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x98c23e9d8f34fefb1b7bd6a91b7ff122f4e16f5c",
    "decimals": 6,
    "image": "https://rango.vip/i/hVYDst",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHLINK",
    "name": "Aave Ethereum LINK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5e8c8a7243651db1384c0ddfdbe39761e8e7e51a",
    "decimals": 18,
    "image": "https://rango.vip/i/14ZO9m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.92,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHWBTC",
    "name": "Aave Ethereum WBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ee5bf7ae06d1be5997a1a72006fe6c607ec6de8",
    "decimals": 8,
    "image": "https://rango.vip/i/9gYsp0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55792,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHAAVE",
    "name": "Aave Ethereum AAVE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa700b4eb416be35b2911fd5dee80678ff64ff6c9",
    "decimals": 18,
    "image": "https://rango.vip/i/b3f7Mz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 126.69,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTC++",
    "name": "PieDAO BTC++",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd",
    "decimals": 18,
    "image": "https://rango.vip/i/dUvsrN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHCBETH",
    "name": "Aave Ethereum cbETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x977b6fc5de62598b08c85ac8cf2b745874e8b78c",
    "decimals": 18,
    "image": "https://rango.vip/i/ufqad1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2530.41,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHRETH",
    "name": "Aave Ethereum rETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcc9ee9483f662091a1de4795249e24ac0ac2630f",
    "decimals": 18,
    "image": "https://rango.vip/i/EbSEzj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2630.38,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHUSDT",
    "name": "Aave Ethereum USDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x23878914efe38d27c4d67ab83ed1b93a74d4086a",
    "decimals": 6,
    "image": "https://rango.vip/i/OsZI54",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.99981,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUST",
    "name": "TRUST DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x57700244b20f84799a31c6c96dadff373ca9d6c5",
    "decimals": 18,
    "image": "https://rango.vip/i/P3MdmG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DTH",
    "name": "Dether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5adc961d6ac3f7062d2ea45fefb8d8167d44b190",
    "decimals": 18,
    "image": "https://rango.vip/i/vwXgag",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YFL",
    "name": "YFLink",
    "isPopular": false,
    "chainId": "1",
    "address": "0x28cb7e841ee97947a86b06fa4090c8451f64c0be",
    "decimals": 18,
    "image": "https://rango.vip/i/zq7tfF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.88,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHLUSD",
    "name": "Aave Ethereum LUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3fe6a295459fae07df8a0cecc36f37160fe86aa9",
    "decimals": 18,
    "image": "https://rango.vip/i/KTFc5S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GTCETH",
    "name": "Gitcoin Staked ETH Index (gtcETH)",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36c833eed0d376f75d1ff9dfdee260191336065e",
    "decimals": 18,
    "image": "https://rango.vip/i/7u10gZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3524.58,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOAM",
    "name": "FOAM Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4946fcea7c692606e8908002e55a582af44ac121",
    "decimals": 18,
    "image": "https://rango.vip/i/RWsB14",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01069213,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATUSD",
    "name": "Aave Interest bearing TUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4da9b813057d04baef4e5800e36083717b4a0341",
    "decimals": 18,
    "image": "https://rango.vip/i/yLD6Mx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997578,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XIO",
    "name": "XIO Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f7f961648ae6db43c75663ac7e5414eb79b5704",
    "decimals": 18,
    "image": "https://rango.vip/i/T6W4VP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00247556,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EWTB",
    "name": "Energy Web Token Bridged",
    "isPopular": false,
    "chainId": "1",
    "address": "0x178c820f862b14f316509ec36b13123da19a6054",
    "decimals": 18,
    "image": "https://rango.vip/i/OxXlK9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEV",
    "name": "Dev",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5caf454ba92e6f2c929df14667ee360ed9fd5b26",
    "decimals": 18,
    "image": "https://rango.vip/i/8uEgQr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.085988,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CDAI",
    "name": "Compound Dai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d3a536e4d6dbd6114cc1ead35777bab948e3643",
    "decimals": 8,
    "image": "https://rango.vip/i/WbrzXv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02376004,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASUSD",
    "name": "Aave Interest bearing SUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x625ae63000f46200499120b906716420bd059240",
    "decimals": 18,
    "image": "https://rango.vip/i/dJr3Or",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997856,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUSDT",
    "name": "Aave Interest bearing USDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x71fc860f7d3a592a4a98740e39db31d25db65ae8",
    "decimals": 6,
    "image": "https://rango.vip/i/26I5JC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999303,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUIDL",
    "name": "dfohub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7b123f53421b1bf8533339bfbdc7c98aa94163db",
    "decimals": 18,
    "image": "https://rango.vip/i/ApndIw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUSDC",
    "name": "Aave Interest bearing USDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ba00d6856a4edf4665bca2c2309936572473b7e",
    "decimals": 6,
    "image": "https://rango.vip/i/WPgxRc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999495,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DZAR",
    "name": "Digital Rand",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9cb2f26a23b8d89973f08c957c4d7cdf75cd341c",
    "decimals": 6,
    "image": "https://rango.vip/i/cRXk43",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EQMT",
    "name": "EQUUSMiningToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa462d0e6bb788c7807b1b1c96992ce1f7069e195",
    "decimals": 18,
    "image": "https://rango.vip/i/cnuY9a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALINK",
    "name": "Aave Interest bearing LINK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa64bd6c70cb9051f6a9ba1f163fdc07e0dfb5f84",
    "decimals": 18,
    "image": "https://rango.vip/i/y4xjiA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.87,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMTRG",
    "name": "Meter Governance mapped by Meter.io",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd2949f67dcdc549c6ebe98696449fa79d988a9f",
    "decimals": 18,
    "image": "https://rango.vip/i/lVM8ic",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.44063,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CWBTC",
    "name": "Compound Wrapped BTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc11b1268c1a384e55c48c2391d8d480264a3a7f4",
    "decimals": 8,
    "image": "https://rango.vip/i/IKU83m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1118.39,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRND",
    "name": "Trendering",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3dd23a0a854b4f9ae80670f528094e9eb607ccb",
    "decimals": 18,
    "image": "https://rango.vip/i/KGver4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00142751,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEC",
    "name": "Ethfinex Nectar Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcc80c051057b774cd75067dc48f8987c4eb97a5e",
    "decimals": 18,
    "image": "https://rango.vip/i/4Ets7A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANJ",
    "name": "Aragon Network Juror",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd62b1c403fa761baadfc74c525ce2b51780b184",
    "decimals": 18,
    "image": "https://rango.vip/i/vz3ZdH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCX",
    "name": "MachiX Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd15ecdcf5ea68e3995b2d0527a0ae0a3258302f8",
    "decimals": 18,
    "image": "https://rango.vip/i/zMVE98",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUIDL_2",
    "name": "DFOHub",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd6f0bb2a45110f819e908a915237d652ac7c5aa8",
    "decimals": 18,
    "image": "https://rango.vip/i/BPWOGj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KARMA",
    "name": "Karma",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdfe691f37b6264a90ff507eb359c45d55037951c",
    "decimals": 4,
    "image": "https://rango.vip/i/2tGt4a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RMPL",
    "name": "RMPL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe17f017475a709de58e976081eb916081ff4c9d5",
    "decimals": 9,
    "image": "https://rango.vip/i/54YML2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "2KEY",
    "name": "2key.network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe48972fcd82a274411c01834e2f031d4377fa2c0",
    "decimals": 18,
    "image": "https://rango.vip/i/IWETjB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNC",
    "name": "UniCrypt",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf29e46887ffae92f1ff87dfe39713875da541373",
    "decimals": 18,
    "image": "https://rango.vip/i/CKxV1J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01708379,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POWER",
    "name": "UniPower",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509",
    "decimals": 18,
    "image": "https://rango.vip/i/KrXDtL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUSDT",
    "name": "Compound USDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9",
    "decimals": 8,
    "image": "https://rango.vip/i/IS8erp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XAMP",
    "name": "Antiample",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf911a7ec46a2c6fa49193212fe4a2a9b95851c27",
    "decimals": 9,
    "image": "https://rango.vip/i/MgR2AS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ADAI",
    "name": "Aave Interest bearing DAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc1e690f61efd961294b3e1ce3313fbd8aa4f85d",
    "decimals": 18,
    "image": "https://rango.vip/i/NSCJ0r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999431,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHAI",
    "name": "Chai Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06af07097c9eeb7fd685c692751d5c66db49c215",
    "decimals": 18,
    "image": "https://rango.vip/i/3Pqub9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CSAI",
    "name": "Compound Sai",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5dce57282a584d2746faf1593d3121fcac444dc",
    "decimals": 8,
    "image": "https://rango.vip/i/Ipzuqa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CETH",
    "name": "Compound ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5",
    "decimals": 8,
    "image": "https://rango.vip/i/Dz2czo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 47.1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUSDC",
    "name": "Compound USD Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39aa39c021dfbae8fac545936693ac917d5e7563",
    "decimals": 8,
    "image": "https://rango.vip/i/mClbZ2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02412282,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETH",
    "name": "Aave Interest bearing ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a3a65aab0dd2a17e3f1947ba16138cd37d08c04",
    "decimals": 18,
    "image": "https://rango.vip/i/GNwUJ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABAT",
    "name": "Aave Interest bearing BAT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00",
    "decimals": 18,
    "image": "https://rango.vip/i/Lkjmsn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.155757,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AKNC",
    "name": "Aave Interest bearing KNC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d91be44c06d373a8a226e1f3b146956083803eb",
    "decimals": 18,
    "image": "https://rango.vip/i/wsqFlG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.417377,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALEND",
    "name": "Aave Interest bearing LEND",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d2d3688df45ce7c552e19c27e007673da9204b8",
    "decimals": 18,
    "image": "https://rango.vip/i/iOT6CP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMANA",
    "name": "Aave Interest bearing MANA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6fce4a401b6b80ace52baaefe4421bd188e76f6f",
    "decimals": 18,
    "image": "https://rango.vip/i/WpJhLK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.254732,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMKR",
    "name": "Aave Interest bearing MKR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7deb5e830be29f91e298ba5ff1356bb7f8146998",
    "decimals": 18,
    "image": "https://rango.vip/i/NKTzJi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1604.38,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AREP",
    "name": "Aave Interest bearing REP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x71010a9d003445ac60c4e6a7017c1e89a477b438",
    "decimals": 18,
    "image": "https://rango.vip/i/UPFVex",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASNX",
    "name": "Aave Interest bearing SNX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x328c4c80bc7aca0834db37e6600a6c49e12da4de",
    "decimals": 18,
    "image": "https://rango.vip/i/f9vviz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.27,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AWBTC",
    "name": "Aave Interest bearing WBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc4b8ed459e00e5400be803a9bb3954234fd50e3",
    "decimals": 8,
    "image": "https://rango.vip/i/lBZsS9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55434,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AZRX",
    "name": "Aave Interest bearing ZRX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6fb0855c404e09c47c3fbca25f08d4e41f9f062f",
    "decimals": 18,
    "image": "https://rango.vip/i/0CWLNa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.27078,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YDAIV2",
    "name": "iearn DAIv2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16de59092dae5ccf4a1e6439d611fd0653f0bd01",
    "decimals": 18,
    "image": "https://rango.vip/i/e8O69l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YDAIV3",
    "name": "iearn DAI v3",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc2cb1040220768554cf699b0d863a3cd4324ce32",
    "decimals": 18,
    "image": "https://rango.vip/i/5iwcZR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YBTC",
    "name": "iearn WBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x04aa51bbcb46541455ccf1b8bef2ebc5d3787ec9",
    "decimals": 8,
    "image": "https://rango.vip/i/fxggwM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YUSDTV2",
    "name": "iearn USDT v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x83f798e925bcd4017eb265844fddabb448f1707d",
    "decimals": 6,
    "image": "https://rango.vip/i/hH28WL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YUSDTV3",
    "name": "iearn USDT v3",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe6354ed5bc4b393a5aad09f21c46e101e692d447",
    "decimals": 6,
    "image": "https://rango.vip/i/ZejkTR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YUSDCV2",
    "name": "iearn USDC v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd6ad7a6750a7593e092a9b218d66c0a814a3436e",
    "decimals": 6,
    "image": "https://rango.vip/i/kaMiUY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YUSDCV3",
    "name": "iearn USDC v3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26ea744e5b887e5205727f55dfbe8685e3b21951",
    "decimals": 6,
    "image": "https://rango.vip/i/HwCHgw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCDT",
    "name": "Blockchain Certified Data Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5",
    "decimals": 18,
    "image": "https://rango.vip/i/yaHebO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03651098,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABUSD",
    "name": "Aave Interest bearing Binance USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6ee0f7bb50a54ab5253da0667b0dc2ee526c30a8",
    "decimals": 18,
    "image": "https://rango.vip/i/RpCkED",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.998008,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PBTC",
    "name": "pTokens BTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5228a22e72ccc52d415ecfd199f99d0665e7733b",
    "decimals": 18,
    "image": "https://rango.vip/i/70kT7V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1593.12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FNT",
    "name": "Falcon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc5864ede28bd4405aa04d93e05a0531797d9d59",
    "decimals": 6,
    "image": "https://rango.vip/i/uN9bEh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000731,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMV1",
    "name": "OM Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2baecdf43734f22fd5c152db08e3c27233f0c7d2",
    "decimals": 18,
    "image": "https://rango.vip/i/y4wgG0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDN",
    "name": "Neutrino USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x674c6ad92fd080e4004b2312b45f796a192d27a0",
    "decimals": 18,
    "image": "https://rango.vip/i/oqryC4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0213933,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWFL",
    "name": "Swapfolio",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba21ef4c9f433ede00badefcc2754b8e74bd538a",
    "decimals": 18,
    "image": "https://rango.vip/i/rLWdRV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FSW",
    "name": "FalconSwap Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfffffffff15abf397da76f1dcc1a1604f45126db",
    "decimals": 18,
    "image": "https://rango.vip/i/FdoVuf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MXX",
    "name": "Multiplier",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a6f3bf52a26a21531514e23016eeae8ba7e7018",
    "decimals": 8,
    "image": "https://rango.vip/i/auBhnW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YYDAI+YUSDC+YUSDT+YTUSD",
    "name": "yearn Curve.fi yDAI/yUSDC/yUSDT/yTUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5dbcf33d8c2e976c6b560249878e6f1491bca25c",
    "decimals": 18,
    "image": "https://rango.vip/i/yWxS00",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHAKE",
    "name": "SHAKE token by SpaceSwap v2 ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6006fc2a849fedaba8330ce36f5133de01f96189",
    "decimals": 18,
    "image": "https://rango.vip/i/ZthBaY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 69.55,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWAG",
    "name": "Swag Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x87edffde3e14c7a66c9b9724747a1c5696b742e6",
    "decimals": 18,
    "image": "https://rango.vip/i/eNzvd6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00155537,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEFI+S",
    "name": "PieDAO DEFI Small Cap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c",
    "decimals": 18,
    "image": "https://rango.vip/i/CwY7YX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOUGH",
    "name": "PieDAO DOUGH v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad32a8e6220741182940c5abf610bde99e737b2d",
    "decimals": 18,
    "image": "https://rango.vip/i/JYnUo0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018308,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUNI",
    "name": "Compound Uniswap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x35a18000230da775cac24873d00ff85bccded550",
    "decimals": 8,
    "image": "https://rango.vip/i/p0WkuH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.126399,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VOICE",
    "name": "Voice Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e2364966267b5d7d2ce6cd9a9b5bd19d9c7c6a9",
    "decimals": 18,
    "image": "https://rango.vip/i/K8WGlj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 81.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WANATHA",
    "name": "Wrapped ANATHA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3383c5a8969dc413bfddc9656eb80a1408e4ba20",
    "decimals": 18,
    "image": "https://rango.vip/i/K0IFaT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APY",
    "name": "APY Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95a4492f028aa1fd432ea71146b433e7b4446611",
    "decimals": 18,
    "image": "https://rango.vip/i/siVurS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00108271,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KP4R",
    "name": "Keep4r",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa89ac6e529acf391cfbbd377f3ac9d93eae9664e",
    "decimals": 18,
    "image": "https://rango.vip/i/T4DgSn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOMOE",
    "name": "TomoChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x05d3606d5c81eb9b7b18530995ec9b29da05faba",
    "decimals": 18,
    "image": "https://rango.vip/i/Jupl3m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.410558,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$ROPE",
    "name": "$ROPE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d47894f8becb68b9cf3428d256311affe8b068b",
    "decimals": 18,
    "image": "https://rango.vip/i/MjDKiG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AYFI",
    "name": "Aave Interest bearing YFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12e51e77daaa58aa0e9247db7510ea4b46f9bead",
    "decimals": 18,
    "image": "https://rango.vip/i/dzJWZZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4808.08,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAAVE",
    "name": "Aave Interest bearing Aave Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba3d9687cf50fe253cd2e1cfeede1d6787344ed5",
    "decimals": 18,
    "image": "https://rango.vip/i/1VKWnL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 126.69,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUNI",
    "name": "Aave Interest bearing Uniswap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb124541127a0a657f056d9dd06188c4f1b0e5aab",
    "decimals": 18,
    "image": "https://rango.vip/i/g9aXvD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AENJ",
    "name": "Aave Interest bearing ENJ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x712db54daa836b53ef1ecbb9c6ba3b9efb073f40",
    "decimals": 18,
    "image": "https://rango.vip/i/gDYNlg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.131956,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QRX",
    "name": "QuiverX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e0dade58d2d89ebbe7afc384e3e4f15b70b14d8",
    "decimals": 18,
    "image": "https://rango.vip/i/ivWnOg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0047963,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AREN",
    "name": "Aave Interest bearing REN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69948cc03f478b95283f7dbf1ce764d0fc7ec54c",
    "decimals": 18,
    "image": "https://rango.vip/i/o4jfB2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03503194,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIPT",
    "name": "Power Index Pool Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26607ac599266b21d13c7acf7942c7701a8b699c",
    "decimals": 18,
    "image": "https://rango.vip/i/eJg4wW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAAVE",
    "name": "Aave interest bearing AAVE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xffc97d72e13e01096502cb8eb52dee56f74dad7b",
    "decimals": 18,
    "image": "https://rango.vip/i/xxaBb7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 126.9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABAT",
    "name": "Aave interest bearing BAT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x05ec93c0365baaeabf7aeffb0972ea7ecdd39cf1",
    "decimals": 18,
    "image": "https://rango.vip/i/2M1d8O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.155757,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABUSD",
    "name": "Aave interest bearing BUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa361718326c15715591c299427c62086f69923d9",
    "decimals": 18,
    "image": "https://rango.vip/i/slS4OK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.998008,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ADAI",
    "name": "Aave interest bearing DAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x028171bca77440897b824ca71d1c56cac55b68a3",
    "decimals": 18,
    "image": "https://rango.vip/i/jpdrGh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999431,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AENJ",
    "name": "Aave interest bearing ENJ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac6df26a590f08dcc95d5a4705ae8abbc88509ef",
    "decimals": 18,
    "image": "https://rango.vip/i/hcHeWB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.132242,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AKNC",
    "name": "Aave interest bearing KNC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39c6b3e42d6a679d7d776778fe880bc9487c2eda",
    "decimals": 18,
    "image": "https://rango.vip/i/uxtK1W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.417377,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALINK",
    "name": "Aave interest bearing LINK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa06bc25b5805d5f8d82847d191cb4af5a3e873e0",
    "decimals": 18,
    "image": "https://rango.vip/i/ZknJhw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.87,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMANA",
    "name": "Aave interest bearing MANA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa685a61171bb30d4072b338c80cb7b2c865c873e",
    "decimals": 18,
    "image": "https://rango.vip/i/I8G9Md",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.254732,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMKR",
    "name": "Aave interest bearing MKR",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc713e5e149d5d0715dcd1c156a020976e7e56b88",
    "decimals": 18,
    "image": "https://rango.vip/i/VRyIP5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1604.38,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AREN",
    "name": "Aave interest bearing REN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcc12abe4ff81c9378d670de1b57f8e0dd228d77a",
    "decimals": 18,
    "image": "https://rango.vip/i/vzdMyw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03495593,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASNX",
    "name": "Aave interest bearing SNX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x35f6b052c598d933d69a4eec4d04c73a191fe6c2",
    "decimals": 18,
    "image": "https://rango.vip/i/nanZ9T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.27,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASUSD",
    "name": "Aave interest bearing SUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c5024cd4f8a59110119c56f8933403a539555eb",
    "decimals": 18,
    "image": "https://rango.vip/i/3zLivc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997856,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATUSD",
    "name": "Aave interest bearing TUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x101cc05f4a51c0319f570d5e146a8c625198e636",
    "decimals": 18,
    "image": "https://rango.vip/i/KsP8F9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997578,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUNI",
    "name": "Aave interest bearing UNI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9d7cb55f463405cdfbe4e90a6d2df01c2b92bf1",
    "decimals": 18,
    "image": "https://rango.vip/i/9MtIHG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUSDC",
    "name": "Aave interest bearing USDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbcca60bb61934080951369a648fb03df4f96263c",
    "decimals": 6,
    "image": "https://rango.vip/i/AqUH4e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999495,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUSDT",
    "name": "Aave interest bearing USDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ed3b47dd13ec9a98b44e6204a523e766b225811",
    "decimals": 6,
    "image": "https://rango.vip/i/MqbdjD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999303,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AWBTC",
    "name": "Aave interest bearing WBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656",
    "decimals": 8,
    "image": "https://rango.vip/i/PjsDdI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55434,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AWETH",
    "name": "Aave interest bearing WETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x030ba81f1c18d280636f32af80b9aad02cf0854e",
    "decimals": 18,
    "image": "https://rango.vip/i/dFBX8s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2343.53,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AYFI",
    "name": "Aave interest bearing YFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5165d24277cd063f5ac44efd447b27025e888f37",
    "decimals": 18,
    "image": "https://rango.vip/i/U8Uyvn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4808.08,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AZRX",
    "name": "Aave interest bearing ZRX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf7ff54aacacbff42dfe29dd6144a69b629f8c9e",
    "decimals": 18,
    "image": "https://rango.vip/i/2xoQSY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.27078,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDFL",
    "name": "USDFreeLiquidity",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2b4200a8d373d484993c37d63ee14aee0096cd12",
    "decimals": 18,
    "image": "https://rango.vip/i/IyN32t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PBTC35A",
    "name": "POW BTC-35W/T",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa8b12cc90abf65191532a12bb5394a714a46d358",
    "decimals": 18,
    "image": "https://rango.vip/i/AS8JbQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.21,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CFI",
    "name": "CyberFi Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4",
    "decimals": 18,
    "image": "https://rango.vip/i/YCVC8v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.142199,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEBASE",
    "name": "Debase",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9248c485b0b80f76da451f167a8db30f33c70907",
    "decimals": 18,
    "image": "https://rango.vip/i/r2vAHE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MARK",
    "name": "Benchmark",
    "isPopular": false,
    "chainId": "1",
    "address": "0x67c597624b17b16fb77959217360b7cd18284253",
    "decimals": 9,
    "image": "https://rango.vip/i/WRts81",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0008285,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STBZ",
    "name": "Stabilize Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb987d48ed8f2c468d52d6405624eadba5e76d723",
    "decimals": 18,
    "image": "https://rango.vip/i/nTSx6A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.158945,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INFI",
    "name": "INFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x159751323a9e0415dd3d6d42a1212fe9f4a0848c",
    "decimals": 18,
    "image": "https://rango.vip/i/3CjZG6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.108576,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LYXE",
    "name": "LUKSO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa8b919680258d369114910511cc87595aec0be6d",
    "decimals": 18,
    "image": "https://rango.vip/i/Nc9s4u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARMOR",
    "name": "Armor",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1337def16f9b486faed0293eb623dc8395dfe46a",
    "decimals": 18,
    "image": "https://rango.vip/i/rofAJO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00089993,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARNXM",
    "name": "Armor NXM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1337def18c680af1f9f45cbcab6309562975b1dd",
    "decimals": 18,
    "image": "https://rango.vip/i/CRvSEL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANKRETH",
    "name": "Ankr Staked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe95a203b1a91a908f9b9ce46459d101078c2c3cb",
    "decimals": 18,
    "image": "https://rango.vip/i/zNSDZZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2733.7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GNYERC20",
    "name": "GNYerc20",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb1f871ae9462f1b2c6826e88a7827e76f86751d4",
    "decimals": 18,
    "image": "https://rango.vip/i/skrvjh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00800814,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$TRDL",
    "name": "Strudel Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x297d33e17e61c2ddd812389c2105193f8348188a",
    "decimals": 18,
    "image": "https://rango.vip/i/dxyK6r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNISTAKE",
    "name": "Unistake",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ed8e7c9604790f7ec589f99b94361d8aab64e5e",
    "decimals": 18,
    "image": "https://rango.vip/i/apu50X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00364227,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MP3",
    "name": "mp3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x018fb5af9d015af25592a014c4266a84143de7a0",
    "decimals": 18,
    "image": "https://rango.vip/i/5e3v3W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTMX",
    "name": "BitMax token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcca0c9c383076649604ee31b20248bc04fdf61ca",
    "decimals": 18,
    "image": "https://rango.vip/i/ezh7kO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BFLY",
    "name": "Butterfly Protocol Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf680429328caaacabee69b7a9fdb21a71419c063",
    "decimals": 18,
    "image": "https://rango.vip/i/4z7Mts",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00440814,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KBTC",
    "name": "KBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe6c3502997f97f9bde34cb165fbce191065e068f",
    "decimals": 18,
    "image": "https://rango.vip/i/ZXrx3y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 61661,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTASK",
    "name": "CTASK Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x196c81385bc536467433014042788eb707703934",
    "decimals": 18,
    "image": "https://rango.vip/i/fnRNsx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEPAY",
    "name": "DePay",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0bed124a09ac2bd941b10349d8d224fe3c955eb",
    "decimals": 18,
    "image": "https://rango.vip/i/RULvCr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.296842,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WARP",
    "name": "Warp Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed40834a13129509a89be39a9be9c0e96a0ddd71",
    "decimals": 18,
    "image": "https://rango.vip/i/KqvaoR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.059671,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UMX",
    "name": "unimex network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x10be9a8dae441d276a5027936c3aaded2d82bc15",
    "decimals": 18,
    "image": "https://rango.vip/i/9rAdGI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARTE",
    "name": "ethart",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34612903db071e888a4dadcaa416d3ee263a87b9",
    "decimals": 18,
    "image": "https://rango.vip/i/oMuKLz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UTU",
    "name": "UTU Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa58a4f5c4bb043d2cc1e170613b74e767c94189b",
    "decimals": 18,
    "image": "https://rango.vip/i/HwDoVJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00062882,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AC",
    "name": "ACoconut",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9a0aba393aac4dfbff4333b06c407458002c6183",
    "decimals": 18,
    "image": "https://rango.vip/i/DrTfTF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02931341,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROYA",
    "name": "Royale",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7eaf9c89037e4814dc0d9952ac7f888c784548db",
    "decimals": 18,
    "image": "https://rango.vip/i/g3FOF6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNIFI",
    "name": "UniFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e78b8274e1d6a76a0dbbf90418894df27cbceb5",
    "decimals": 18,
    "image": "https://rango.vip/i/Tfk39i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.181423,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HY",
    "name": "hybrix hydra",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b53e429b0badd98ef7f01f03702986c516a5715",
    "decimals": 18,
    "image": "https://rango.vip/i/oN0lxn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SIG",
    "name": "xSigma",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7777777777697cfeecf846a76326da79cc606517",
    "decimals": 18,
    "image": "https://rango.vip/i/UjYvl5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRDAI",
    "name": "Cream Dai Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x92b767185fb3b04f881e3ac8e5b0662a027a1d9f",
    "decimals": 8,
    "image": "https://rango.vip/i/5TfKB3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FONT",
    "name": "Font",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c25bdf026ea05f32713f00f73ca55857fbf6342",
    "decimals": 18,
    "image": "https://rango.vip/i/pYuZO7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NDR",
    "name": "NodeRunners",
    "isPopular": false,
    "chainId": "1",
    "address": "0x739763a258640919981f9ba610ae65492455be53",
    "decimals": 18,
    "image": "https://rango.vip/i/NtFIiw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFTI",
    "name": "NFT INDEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5feeac09d36b18b3fa757e5cf3f8da6b8e27f4c",
    "decimals": 18,
    "image": "https://rango.vip/i/r07QYV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROBOT",
    "name": "MetaFactory",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb5453340c03db5ade474b27e68b6a9c6b2823eb",
    "decimals": 18,
    "image": "https://rango.vip/i/KfW2vr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFY",
    "name": "Non-Fungible Yearn",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1cbb83ebcd552d5ebf8131ef8c9cd9d9bab342bc",
    "decimals": 18,
    "image": "https://rango.vip/i/9kAnko",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "B20",
    "name": "B.20",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4de189abf94c57f396bd4c52ab13b954febefd8",
    "decimals": 18,
    "image": "https://rango.vip/i/Y4ohIn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02882751,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCX_OLD",
    "name": "ARC Governance Token (Old)",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed30dd7e50edf3581ad970efc5d9379ce2614adb",
    "decimals": 18,
    "image": "https://rango.vip/i/vv4c9c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STABLEX",
    "name": "ARC STABLEx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd91538b91b4ba7797d39a2f66e63810b50a33d0",
    "decimals": 18,
    "image": "https://rango.vip/i/OrnLor",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FTX TOKEN",
    "name": "FTT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9",
    "decimals": 18,
    "image": "https://rango.vip/i/L8PbEt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.28,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HTRE",
    "name": "HodlTree",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdea67845a51e24461d5fed8084e69b426af3d5db",
    "decimals": 18,
    "image": "https://rango.vip/i/7IydNO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EXRT",
    "name": "EXRT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb20043f149817bff5322f1b928e89abfc65a9925",
    "decimals": 8,
    "image": "https://rango.vip/i/oPTSjF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVECRV-DAO",
    "name": "veCRV-DAO yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc5bddf9843308380375a611c18b50fb9341f502a",
    "decimals": 18,
    "image": "https://rango.vip/i/SN5hrc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.388655,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EROWAN",
    "name": "erowan",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07bac35846e5ed502aa91adf6a9e7aa210f2dcbe",
    "decimals": 18,
    "image": "https://rango.vip/i/X97ibo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001305,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VETH",
    "name": "Voucher Ethereum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3d088842dcf02c13699f936bb83dfbbc6f721ab",
    "decimals": 18,
    "image": "https://rango.vip/i/t6KAi5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 48.74578270932791,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "URQA",
    "name": "UREEQA Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1735db6ab5baa19ea55d0adceed7bcdc008b3136",
    "decimals": 18,
    "image": "https://rango.vip/i/7KAZ37",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00180633,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BASV2",
    "name": "BASv2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x106538cc16f938776c7c180186975bca23875287",
    "decimals": 18,
    "image": "https://rango.vip/i/UzQ0zb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.150119,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWM",
    "name": "SWARM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3505f494c3f0fed0b594e01fa41dd3967645ca39",
    "decimals": 18,
    "image": "https://rango.vip/i/ziUPsm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00693984,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EDDA",
    "name": "EDDA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfbbe9b1142c699512545f47937ee6fae0e4b0aa9",
    "decimals": 18,
    "image": "https://rango.vip/i/wMrENy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 14.34,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CARDS",
    "name": "CARD.STARTER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d6f0dea3ac3c607b3998e6ce14b6350721752d9",
    "decimals": 18,
    "image": "https://rango.vip/i/jwXmzk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EBOX",
    "name": "ethbox Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33840024177a7daca3468912363bed8b425015c5",
    "decimals": 18,
    "image": "https://rango.vip/i/D9GlY3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0023376,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TCAP",
    "name": "TCAP Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16c52ceece2ed57dad87319d91b5e3637d50afa4",
    "decimals": 18,
    "image": "https://rango.vip/i/nxn2es",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BASK",
    "name": "BasketDAO Gov",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44564d0bd94343f72e3c8a0d22308b7fa71db0bb",
    "decimals": 18,
    "image": "https://rango.vip/i/156wYT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ERSDL",
    "name": "UnFederalReserveToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6",
    "decimals": 18,
    "image": "https://rango.vip/i/LZSIzu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0010663,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XEND",
    "name": "XEND",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe4cfe9eaa8cdb0942a80b7bc68fd8ab0f6d44903",
    "decimals": 18,
    "image": "https://rango.vip/i/KTSB5l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.050419,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TANGO",
    "name": "keyTango Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x182f4c4c97cd1c24e1df8fc4c053e5c47bf53bef",
    "decimals": 18,
    "image": "https://rango.vip/i/CTKxOL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0006197,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALUSD",
    "name": "Alchemix USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc6da0fe9ad5f3b0d58160288917aa56653660e9",
    "decimals": 18,
    "image": "https://rango.vip/i/t4KTXg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.977288,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NBU",
    "name": "Nimbus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb58343b36c7528f23caae63a150240241310049",
    "decimals": 18,
    "image": "https://rango.vip/i/KBGak2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUNCH",
    "name": "MUNCH Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x944eee930933be5e23b690c8589021ec8619a301",
    "decimals": 9,
    "image": "https://rango.vip/i/iUAc5M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000939,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ISLE",
    "name": "Island",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1681bcb589b3cfcf0c0616b0ce9b19b240643dc1",
    "decimals": 9,
    "image": "https://rango.vip/i/IBqXme",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAVA",
    "name": "Cavapoo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x456d8f0d25a4e787ee60c401f8b963a465148f70",
    "decimals": 9,
    "image": "https://rango.vip/i/zNBrrV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWAPP",
    "name": "Swapp Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8cb924583681cbfe487a62140a994a49f833c244",
    "decimals": 18,
    "image": "https://rango.vip/i/TU568m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CADC",
    "name": "CAD Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcadc0acd4b445166f12d2c07eac6e2544fbe2eef",
    "decimals": 18,
    "image": "https://rango.vip/i/MvF9R5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCX",
    "name": "ARCx Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1321f1f1aa541a56c31682c57b80ecfccd9bb288",
    "decimals": 18,
    "image": "https://rango.vip/i/vv4c9c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.435271,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHV",
    "name": "ETH Volatility Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc53342fd7575f572b0ff4569e31941a5b821ac76",
    "decimals": 18,
    "image": "https://rango.vip/i/RPQcBL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 81.5,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NRCH",
    "name": "EnreachDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69fa8e7f6bf1ca1fb0de61e1366f7412b827cc51",
    "decimals": 9,
    "image": "https://rango.vip/i/No5gje",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.135627,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONE1INCH",
    "name": "Stable 1INCH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x853bb55c1f469902f088a629db8c8803a9be3857",
    "decimals": 18,
    "image": "https://rango.vip/i/gRlION",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EURT",
    "name": "Euro Tether",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc581b735a1688071a1746c968e0798d642ede491",
    "decimals": 6,
    "image": "https://rango.vip/i/DKrVfe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.084,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEANU",
    "name": "Keanu Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x106552c11272420aad5d7e94f8acab9095a6c952",
    "decimals": 9,
    "image": "https://rango.vip/i/u8IJhH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "1MIL",
    "name": "1MILNFT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016",
    "decimals": 18,
    "image": "https://rango.vip/i/lUadph",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.079909,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TMM",
    "name": "Take My Muffin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19042021329fddcfbea5f934fb5b2670c91f7d20",
    "decimals": 6,
    "image": "https://rango.vip/i/hCQXCx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLOKIPUP",
    "name": "Floki Pup",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf50a07e4ff052a14f3f608da8936d8ae0ed5be50",
    "decimals": 9,
    "image": "https://rango.vip/i/DUCCD2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAWTH",
    "name": "Pawthereum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaecc217a749c2405b5ebc9857a16d58bdc1c367f",
    "decimals": 9,
    "image": "https://rango.vip/i/D3wgB2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0029407,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZINU",
    "name": "Zombie Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc50ef449171a51fbeafd7c562b064b6471c36caa",
    "decimals": 9,
    "image": "https://rango.vip/i/TJAn9k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040925,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MINISAITAMA",
    "name": "mini SAITAMA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c3685559af6f3d20c501b1076a8056a0a14426a",
    "decimals": 9,
    "image": "https://rango.vip/i/bbo5sr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROTTS",
    "name": "ROTTSCHILD.com",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfb40e79e56cc7d406707b66c4fd175e07eb2ae3c",
    "decimals": 9,
    "image": "https://rango.vip/i/5Phqce",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TONCOIN",
    "name": "Wrapped TON Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x582d872a1b094fc48f5de31d3b73f2d9be47def1",
    "decimals": 9,
    "image": "https://rango.vip/i/AFPKty",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.78,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIBLI",
    "name": "Studio Shibli",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb1a88c33091490218965787919fcc9862c1798ee",
    "decimals": 9,
    "image": "https://rango.vip/i/6KPZlz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VXL",
    "name": "Voxel X Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16cc8367055ae7e9157dbcb9d86fd6ce82522b31",
    "decimals": 18,
    "image": "https://rango.vip/i/e482ST",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00079395,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WTF",
    "name": "fees.wtf",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa68dd8cb83097765263adad881af6eed479c4a33",
    "decimals": 18,
    "image": "https://rango.vip/i/0znHm6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024676,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACYC",
    "name": "AllCoinsYieldCapital",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb56a1f3310578f23120182fb2e58c087efe6e147",
    "decimals": 18,
    "image": "https://rango.vip/i/CtpsHU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WXRP",
    "name": "Wrapped XRP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39fbbabf11738317a448031930706cd3e612e1b9",
    "decimals": 18,
    "image": "https://rango.vip/i/uLJXul",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.550302,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XYZ",
    "name": "XYZ Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x618679df9efcd19694bb1daa8d00718eacfa2883",
    "decimals": 18,
    "image": "https://rango.vip/i/1eHqUb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006659,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NCR",
    "name": "Neos Credits",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb5c3c46e28b53a39c255aa39a411dd64e5fed9c",
    "decimals": 18,
    "image": "https://rango.vip/i/Y9zW7F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04846678,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WFLOW",
    "name": "Wrapped Flow",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5c147e74d63b1d31aa3fd78eb229b65161983b2b",
    "decimals": 18,
    "image": "https://rango.vip/i/xvNAp1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.698318,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FNC",
    "name": "Fancy Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f280dac515121dcda3eac69eb4c13a52392cace",
    "decimals": 18,
    "image": "https://rango.vip/i/W3iMGo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00057281,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ICETH",
    "name": "Interest Compounding ETH Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c07f7abe10ce8e33dc6c5ad68fe033085256a84",
    "decimals": 18,
    "image": "https://rango.vip/i/Tm06de",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2607.34,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QUAD",
    "name": "Quadency Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xab2a7b5876d707e0126b3a75ef7781c77c8877ee",
    "decimals": 18,
    "image": "https://rango.vip/i/3Ftqxi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00413715,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DERC",
    "name": "DeRace Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9fa69536d1cda4a04cfb50688294de75b505a9ae",
    "decimals": 18,
    "image": "https://rango.vip/i/Ab7p3I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.31295,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UST",
    "name": "UST",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa693b19d2931d498c5b318df961919bb4aee87a5",
    "decimals": 6,
    "image": "https://rango.vip/i/W4ckpz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01535935,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PDI",
    "name": "Phuture DeFi Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x632806bf5c8f062932dd121244c9fbe7becb8b48",
    "decimals": 18,
    "image": "https://rango.vip/i/lbLdtt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XZAR",
    "name": "South African Tether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x48f07301e9e29c3c38a80ae8d9ae771f224f1054",
    "decimals": 18,
    "image": "https://rango.vip/i/axt37R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BED",
    "name": "Bankless BED Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2af1df3ab0ab157e1e2ad8f88a7d04fbea0c7dc6",
    "decimals": 18,
    "image": "https://rango.vip/i/VJB3K5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 88.43,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SALE",
    "name": "DxSale.Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf063fe1ab7a291c5d06a86e14730b00bf24cb589",
    "decimals": 18,
    "image": "https://rango.vip/i/jzRjzH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.145844,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "A1INCH",
    "name": "Aave interest bearing 1INCH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb29130cbcc3f791f077eade0266168e808e5151e",
    "decimals": 18,
    "image": "https://rango.vip/i/KPzMlt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.247189,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MYC",
    "name": "Mycelium",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b13006980acb09645131b91d259eaa111eaf5ba",
    "decimals": 18,
    "image": "https://rango.vip/i/IqfK7m",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00160095,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TENSHI",
    "name": "TENSHI v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x52662717e448be36cb54588499d5a8328bd95292",
    "decimals": 18,
    "image": "https://rango.vip/i/e9HDD8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00034953,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NTVRK",
    "name": "NETVRK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc0d6cf33e38bce7ca7d89c0e292274031b7157a",
    "decimals": 18,
    "image": "https://rango.vip/i/0S18VZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0326028,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOE",
    "name": "Dogs Of Elon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf8e9f10c22840b613cda05a0c5fdb59a4d6cd7ef",
    "decimals": 18,
    "image": "https://rango.vip/i/RhX7mJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013377,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WMLK",
    "name": "Wrapped MiL.k",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf87c4b9c0c1528147cac4e05b7ac349a9ab23a12",
    "decimals": 8,
    "image": "https://rango.vip/i/KMc04O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DCHF",
    "name": "Defi Franc",
    "isPopular": false,
    "chainId": "1",
    "address": "0x045da4bfe02b320f4403674b3b7d121737727a36",
    "decimals": 18,
    "image": "https://rango.vip/i/mX1vp1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.009,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAINI",
    "name": "Rainicorn",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed",
    "decimals": 18,
    "image": "https://rango.vip/i/zCY7dZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00580562,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POLAR",
    "name": "PolarSync",
    "isPopular": false,
    "chainId": "1",
    "address": "0x58fcaa970339a9b1f8c0a5b4f3fcd7af2ba3075e",
    "decimals": 18,
    "image": "https://rango.vip/i/JiahXn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013437,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABYDOGE",
    "name": "Baby Doge Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac57de9c1a09fec648e93eb98875b212db0d460b",
    "decimals": 9,
    "image": "https://rango.vip/i/Oglhsj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.06889e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QUARTZ",
    "name": "Sandclock",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba8a621b4a54e61c442f5ec623687e2a942225ef",
    "decimals": 18,
    "image": "https://rango.vip/i/C8Fe9r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.11526,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALETH",
    "name": "Alchemix ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0100546f2cd4c9d97f798ffc9755e47865ff7ee6",
    "decimals": 18,
    "image": "https://rango.vip/i/wkxjv5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2135.57,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BMDA",
    "name": "Bermuda",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4236f8aaf2b1f3a28420eb15b8e0ddf63201a95e",
    "decimals": 18,
    "image": "https://rango.vip/i/B3jUAN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01159114,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": " REALTOKEN-S-10604-SOMERSET-AVE-DETROIT-MI",
    "name": "RealToken S 10604 Somerset Ave Detroit MI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4cb613947890ea300fedc509ac19f8efa0cdd14",
    "decimals": 18,
    "image": "https://rango.vip/i/ekkH2H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEWO",
    "name": "New Order",
    "isPopular": false,
    "chainId": "1",
    "address": "0x98585dfc8d9e7d48f0b1ae47ce33332cf4237d96",
    "decimals": 18,
    "image": "https://rango.vip/i/0iTfRQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00473211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CARD",
    "name": "Cardstack",
    "isPopular": false,
    "chainId": "1",
    "address": "0x954b890704693af242613edef1b603825afcd708",
    "decimals": 18,
    "image": "https://rango.vip/i/zXNIbq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00042986,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "X7DAO",
    "name": "X7DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7105e64bf67eca3ae9b123f0e5ca2b83b2ef2da0",
    "decimals": 18,
    "image": "https://rango.vip/i/e2XJnQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00505405,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHCRV",
    "name": "Aave Ethereum CRV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7b95ec873268a6bfc6427e7a28e396db9d0ebc65",
    "decimals": 18,
    "image": "https://rango.vip/i/EutE7G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.277424,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHUNI",
    "name": "Aave Ethereum UNI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf6d2224916ddfbbab6e6bd0d1b7034f4ae0cab18",
    "decimals": 18,
    "image": "https://rango.vip/i/Av3wGu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHMKR",
    "name": "Aave Ethereum MKR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a458a9dc9048e005d22849f470891b840296619",
    "decimals": 18,
    "image": "https://rango.vip/i/pB4GBO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1610.48,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHBAL",
    "name": "Aave Ethereum BAL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2516e7b3f76294e03c42aa4c5b5b4dce9c436fb8",
    "decimals": 18,
    "image": "https://rango.vip/i/FgH6nf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.78,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHSNX",
    "name": "Aave Ethereum SNX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc7b4c17861357b8abb91f25581e7263e08dcb59c",
    "decimals": 18,
    "image": "https://rango.vip/i/bl3jzt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.27,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHLDO",
    "name": "Aave Ethereum LDO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9a44fd41566876a39655f74971a3a6ea0a17a454",
    "decimals": 18,
    "image": "https://rango.vip/i/uUSLCx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.95259,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WZNN",
    "name": "Wrapped ZNN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2e96a63479c2edd2fd62b382c89d5ca79f572d3",
    "decimals": 8,
    "image": "https://rango.vip/i/sEF30w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.051,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETH1INCH",
    "name": "Aave Ethereum 1INCH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x71aef7b30728b9bb371578f36c5a1f1502a5723e",
    "decimals": 18,
    "image": "https://rango.vip/i/UnBHJk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.247189,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHENS",
    "name": "Aave Ethereum ENS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x545bd6c032efdde65a377a6719def2796c8e0f2e",
    "decimals": 18,
    "image": "https://rango.vip/i/jn2F2K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 16.51,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHRPL",
    "name": "Aave Ethereum RPL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb76cf92076adbf1d9c39294fa8e7a67579fde357",
    "decimals": 18,
    "image": "https://rango.vip/i/Vxq5D8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.65,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "8PAY V2",
    "name": "8PAY Network v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06ddb3a8bc0abc14f85e974cf1a93a6f8d4909d9",
    "decimals": 18,
    "image": "https://rango.vip/i/RitqSq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00175683,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHSDAI",
    "name": "Aave Ethereum sDAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c612e3b15b96ff9a6faed838f8d07d479a8dd4c",
    "decimals": 18,
    "image": "https://rango.vip/i/VJHoXA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999938,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZETH",
    "name": "ZeroLiquid ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x776280f68ad33c4d49e6846507b7dbaf7811c89f",
    "decimals": 18,
    "image": "https://rango.vip/i/Y9Proq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FXN",
    "name": "FXN Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x365accfca291e7d3914637abf1f7635db165bb09",
    "decimals": 18,
    "image": "https://rango.vip/i/nRsVBj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 62.84,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHKNC",
    "name": "Aave Ethereum KNC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5b502e3796385e1e9755d7043b9c945c3accec9c",
    "decimals": 18,
    "image": "https://rango.vip/i/7hXt7Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.42005,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHSTG",
    "name": "Aave Ethereum STG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1ba9843bd4327c6c77011406de5fa8749f7e3479",
    "decimals": 18,
    "image": "https://rango.vip/i/IdjKCv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.2739,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WEETH",
    "name": "Wrapped eETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee",
    "decimals": 18,
    "image": "https://rango.vip/i/8FiPb0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2451.57,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OSETH",
    "name": "StakeWise Staked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1c9acdc66974dfb6decb12aa385b9cd01190e38",
    "decimals": 18,
    "image": "https://rango.vip/i/mswEqG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2413.82,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUSDE",
    "name": "Staked USDe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d39a5de30e57443bff2a8307a4256c8797a3497",
    "decimals": 18,
    "image": "https://rango.vip/i/OpAlis",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.098,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHFXS",
    "name": "Aave Ethereum FXS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x82f9c5ad306bba1ad0de49bb5fa6f01bf61085ef",
    "decimals": 18,
    "image": "https://rango.vip/i/aOYosj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHCRVUSD",
    "name": "Aave Ethereum crvUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb82fa9f31612989525992fcfbb09ab22eff5c85a",
    "decimals": 18,
    "image": "https://rango.vip/i/deSixu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PXETH",
    "name": "Pirex Ether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x04c154b66cb340f3ae24111cc767e0184ed00cc6",
    "decimals": 18,
    "image": "https://rango.vip/i/uyVJZH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2338.2,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GENETH",
    "name": "GenesisLRT restaked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf073bac22dab7faf4a3dd6c6189a70d54110525c",
    "decimals": 18,
    "image": "https://rango.vip/i/z7XzkK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2377.84,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGUSD",
    "name": "Angle USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0000206329b97db379d5e1bf586bbdb969c63274",
    "decimals": 18,
    "image": "https://rango.vip/i/jr2zn0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.992911,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDOLA",
    "name": "Staked Dola",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb45ad160634c528cc3d2926d9807104fa3157305",
    "decimals": 18,
    "image": "https://rango.vip/i/Yei3YQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.058,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHPYUSD",
    "name": "Aave Ethereum PYUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c0d01abf3e6adfca0989ebba9d6e85dd58eab1e",
    "decimals": 6,
    "image": "https://rango.vip/i/h5gz3a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AETHGHO",
    "name": "Aave Ethereum GHO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00907f9921424583e7ffbfedf84f92b7b2be4977",
    "decimals": 18,
    "image": "https://rango.vip/i/az3Hi5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STATAETHUSDC",
    "name": "Static Aave Ethereum USDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x73eddfa87c71addc275c2b9890f5c3a8480bc9e6",
    "decimals": 6,
    "image": "https://rango.vip/i/ZizYhl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STATAETHPYUSD",
    "name": "Static Aave Ethereum PYUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00f2a835758b33f3ac53516ebd69f3dc77b0d152",
    "decimals": 6,
    "image": "https://rango.vip/i/ZizYhl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STATAETHUSDT",
    "name": "Static Aave Ethereum USDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x862c57d48becb45583aeba3f489696d22466ca1b",
    "decimals": 6,
    "image": "https://rango.vip/i/ZizYhl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STATAETHWETH",
    "name": "Static Aave Ethereum WETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x252231882fb38481497f3c767469106297c8d93b",
    "decimals": 18,
    "image": "https://rango.vip/i/ZizYhl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STATAETHLUSD",
    "name": "Static Aave Ethereum LUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdbf5e36569798d1e39ee9d7b1c61a7409a74f23a",
    "decimals": 18,
    "image": "https://rango.vip/i/ZizYhl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STATAETHDAI",
    "name": "Static Aave Ethereum DAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf270c38ff895ea3f95ed488ceace2386f038249",
    "decimals": 18,
    "image": "https://rango.vip/i/ZizYhl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STATAETHWSTETH",
    "name": "Static Aave Ethereum wstETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x322aa5f5be95644d6c36544b6c5061f072d16df5",
    "decimals": 18,
    "image": "https://rango.vip/i/ZizYhl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STATAETHCRVUSD",
    "name": "Static Aave Ethereum crvUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x848107491e029afde0ac543779c7790382f15929",
    "decimals": 18,
    "image": "https://rango.vip/i/ZizYhl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STATAETHFRAX",
    "name": "Static Aave Ethereum FRAX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee66abd4d0f9908a48e08ae354b0f425de3e237e",
    "decimals": 18,
    "image": "https://rango.vip/i/ZizYhl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWUSD",
    "name": "Swerve fi USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77c6e4a580c0dce4e5c7a17d0bc077188a83a059",
    "decimals": 18,
    "image": "https://rango.vip/i/rtvKsf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.051,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPAI",
    "name": "Optopia AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf8e57ac2730d3088d98b79209739b0d5ba085a03",
    "decimals": 18,
    "image": "https://rango.vip/i/hOztIP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00056626,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IMBTC",
    "name": "The Tokenized Bitcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3212b29e33587a00fb1c83346f5dbfa69a458923",
    "decimals": 8,
    "image": "https://rango.vip/i/oRIqfb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 53637,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDLEDAISAFE",
    "name": "IdleDAI  Risk Adjusted ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa14ea0e11121e6e951e87c66afe460a00bcd6a16",
    "decimals": 18,
    "image": "https://rango.vip/i/OvBbIc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.16,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LLM",
    "name": "LLM eth",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f5e1a2424ebdd141b0df15f974ddcb87751a012",
    "decimals": 18,
    "image": "https://rango.vip/i/5BGs8t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00498052,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDLEUSDCSAFE",
    "name": "IdleUSDC  Risk Adjusted ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3391bc034f2935ef0e1e41619445f998b2680d35",
    "decimals": 18,
    "image": "https://rango.vip/i/HOLh63",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDLEUSDTSAFE",
    "name": "IdleUSDT  Risk Adjusted ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x28fac5334c9f7262b3a3fe707e250e01053e07b5",
    "decimals": 18,
    "image": "https://rango.vip/i/HN4wBI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.19,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UETH",
    "name": "Unagii ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77607588222e01bf892a29abab45796a2047fc7b",
    "decimals": 18,
    "image": "https://rango.vip/i/vppn5S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2590.9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$LOOT",
    "name": "LootBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb478c6245e3d85d6ec3486b62ea872128d562541",
    "decimals": 18,
    "image": "https://rango.vip/i/I3BPhB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.10569,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBADGER",
    "name": "Badger Sett Badger",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19d97d8fa813ee2f51ad4b4e04ea08baf4dffc28",
    "decimals": 18,
    "image": "https://rango.vip/i/naIMSB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.39,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GEKE",
    "name": "Geke",
    "isPopular": false,
    "chainId": "1",
    "address": "0x471a202f69d6e975da55e363dab1bdb2e86e0c0f",
    "decimals": 18,
    "image": "https://rango.vip/i/XaF0wZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.314e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USEU",
    "name": "Nexus Pro USEU",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe07ecc676daf0b24b24a1c46c966d9c463984b38",
    "decimals": 18,
    "image": "https://rango.vip/i/o74IGt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.928167,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UWBTC",
    "name": "Unagii Wrapped Bitcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3af5ba94c29a8407785f5f6d90ef5d69a8eb2436",
    "decimals": 8,
    "image": "https://rango.vip/i/4qoZRE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55385,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDLEUSDCYIELD",
    "name": "IdleUSDC  Yield ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5274891bec421b39d23760c04a6755ecb444797c",
    "decimals": 18,
    "image": "https://rango.vip/i/ZSA2u1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.18,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BHIGH",
    "name": "Backed HIGH   High Yield Corp Bond",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20c64dee8fda5269a78f2d5bdba861ca1d83df7a",
    "decimals": 18,
    "image": "https://rango.vip/i/xyvUFf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.42,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDLEUSDTYIELD",
    "name": "IdleUSDT  Yield ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf34842d05a1c888ca02769a633df37177415c2f8",
    "decimals": 18,
    "image": "https://rango.vip/i/i4AZ67",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.23,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POINTS",
    "name": "Points",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7c1eb0fe4a30d3b2a846c04aa6300888f087a5f",
    "decimals": 18,
    "image": "https://rango.vip/i/9iElHO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00047029,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDLESUSDYIELD",
    "name": "IdleSUSD  Yield ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf52cdcd458bf455aed77751743180ec4a595fd3f",
    "decimals": 18,
    "image": "https://rango.vip/i/kxYFDE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.21,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDLETUSDYIELD",
    "name": "IdleTUSD  Best Yield ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc278041fdd8249fe4c1aad1193876857eea3d68c",
    "decimals": 18,
    "image": "https://rango.vip/i/dhSLVH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATC",
    "name": "Aster",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0eb3032bcac2be1fa95e296442f225edb80fc3cd",
    "decimals": 18,
    "image": "https://rango.vip/i/UlQTaG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002896,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNIYFIWETH",
    "name": "Aave AMM UniYFIWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5394794be8b6ed5572fcd6b27103f46b5f390e8f",
    "decimals": 18,
    "image": "https://rango.vip/i/qwNoKP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 17881.43,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMWETH",
    "name": "Aave AMM WETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf9fb4ad91812b704ba883b11d2b576e890a6730a",
    "decimals": 18,
    "image": "https://rango.vip/i/agBZYp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2343.53,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMBPTWBTCWETH",
    "name": "Aave AMM BptWBTCWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x358bd0d980e031e23eba9aa793926857703783bd",
    "decimals": 18,
    "image": "https://rango.vip/i/xpSdAC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 178318,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVLUSD",
    "name": "LUSD yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0x378cb52b00f9d0921cb46dfc099cff73b42419dc",
    "decimals": 18,
    "image": "https://rango.vip/i/1MInCl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGEAI",
    "name": "DogeAi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd31e53966bf212e860d48a3a8651a23d09a7fdc3",
    "decimals": 18,
    "image": "https://rango.vip/i/P81l0G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002864,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVSUSHI",
    "name": "SUSHI yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d765cbe5bc922694afe112c140b8878b9fb0390",
    "decimals": 18,
    "image": "https://rango.vip/i/QVzfGh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.648086,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMBPTBALWETH",
    "name": "Aave AMM BptBALWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd109b2a304587569c84308c55465cd9ff0317bfb",
    "decimals": 18,
    "image": "https://rango.vip/i/XSZhyt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 96.88,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNION",
    "name": "Union Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5dfe42eea70a3e6f93ee54ed9c321af07a85535c",
    "decimals": 18,
    "image": "https://rango.vip/i/563s8C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01004969,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVTUSD",
    "name": "TUSD yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd0877d9095789caf24c98f7cce092fa8e120775",
    "decimals": 18,
    "image": "https://rango.vip/i/DGv1xS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.054,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIL",
    "name": "Shila Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20c3fa331a385b63ee39137e99d0cf2db142fce1",
    "decimals": 18,
    "image": "https://rango.vip/i/sWJl5r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.8782e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESOV",
    "name": "eSOV",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbdab72602e9ad40fc6a6852caf43258113b8f7a5",
    "decimals": 18,
    "image": "https://rango.vip/i/DUGGS3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.493087,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVLINK",
    "name": "LINK yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0x671a912c10bba0cfa74cfc2d6fba9ba1ed9530b2",
    "decimals": 18,
    "image": "https://rango.vip/i/rNOFWB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 10.14,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVRAI",
    "name": "RAI yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0x873fb544277fd7b977b196a826459a69e27ea4ea",
    "decimals": 18,
    "image": "https://rango.vip/i/PcemK9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.99,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MODAI",
    "name": "MODAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xafa42b8ba6ba9dace46dae129a2a1ef54b73fa8b",
    "decimals": 18,
    "image": "https://rango.vip/i/Fszf0x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000196,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PSDN",
    "name": "Poseidon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa5b947687163fe88c3e6af5b17ae69896f4abccf",
    "decimals": 18,
    "image": "https://rango.vip/i/0Ya7CL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVWETH",
    "name": "WETH yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa258c4606ca8206d8aa700ce2143d7db854d168c",
    "decimals": 18,
    "image": "https://rango.vip/i/BqFVCz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2521.03,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVSUSD",
    "name": "sUSD yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa5ca62d95d24a4a350983d5b8ac4eb8638887396",
    "decimals": 18,
    "image": "https://rango.vip/i/ywgXFG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.033,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YV1INCH",
    "name": "1INCH yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8c3b7a2a618c552c23b1e4701109a9e756bab67",
    "decimals": 18,
    "image": "https://rango.vip/i/B3G41U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.264369,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVWBTC",
    "name": "WBTC yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa696a63cc78dffa1a63e9e50587c197387ff6c7e",
    "decimals": 8,
    "image": "https://rango.vip/i/d7C3q3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 56979,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVSNX",
    "name": "SNX yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf29ae508698bdef169b89834f76704c3b205aedf",
    "decimals": 18,
    "image": "https://rango.vip/i/pcMmir",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.45,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOODIE",
    "name": "CryptoPunk  7171",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdffa3a7f5b40789c7a437dbe7b31b47f9b08fe75",
    "decimals": 18,
    "image": "https://rango.vip/i/pYXR4Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.97,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WPR",
    "name": "WePower",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4cf488387f035ff08c371515562cba712f9015d4",
    "decimals": 18,
    "image": "https://rango.vip/i/nQhs5g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00035722,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "B-80BAL-20WETH",
    "name": "Balancer 80 BAL 20 WETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5c6ee304399dbdb9c8ef030ab642b10820db8f56",
    "decimals": 18,
    "image": "https://rango.vip/i/8mmV1D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.3,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEARDY",
    "name": "Bearded Dragon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32c6f1c1731ff8f98ee2ede8954f696446307846",
    "decimals": 18,
    "image": "https://rango.vip/i/lI1QAZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024277,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVHEGIC",
    "name": "HEGIC yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe11ba472f74869176652c35d30db89854b5ae84d",
    "decimals": 18,
    "image": "https://rango.vip/i/wLIjXQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01755019,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KNDA",
    "name": "Kenda",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb40c535c8899f95e3b722df2f0619ebd28c4a4ea",
    "decimals": 18,
    "image": "https://rango.vip/i/bOg4Bl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000298,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPENC",
    "name": "OPEN Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d86b1b2554ec410eccffbf111a6994910111340",
    "decimals": 8,
    "image": "https://rango.vip/i/ib72KW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00752666,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLP",
    "name": "Gameflip",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a1bda28adb5b0a812a7cf10a1950c920f79bcd3",
    "decimals": 18,
    "image": "https://rango.vip/i/QQK6dh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00768604,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IFARM",
    "name": "iFARM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1571ed0bed4d987fe2b498ddbae7dfa19519f651",
    "decimals": 18,
    "image": "https://rango.vip/i/XMSVv4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 61.59,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNIMKRWETH",
    "name": "Aave AMM UniMKRWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x370adc71f67f581158dc56f539df5f399128ddf9",
    "decimals": 18,
    "image": "https://rango.vip/i/LYPKR7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5687.61,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XVM",
    "name": "XVM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40bb3f1be815ecc5e8279385a8f2893252a6ccbd",
    "decimals": 18,
    "image": "https://rango.vip/i/rAgIDA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.92598e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUUS",
    "name": "Nexus Pro EUUS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x735acdedd91a80334ff72f07bff41e1eecf26677",
    "decimals": 18,
    "image": "https://rango.vip/i/jn4Czd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.078,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELE",
    "name": "EigenElephant",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1e1e2dd585c0b10995c4ef292aa9a0795f95811",
    "decimals": 18,
    "image": "https://rango.vip/i/BFZfxs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000039,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USCC",
    "name": "Superstate USCC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x14d60e7fdc0d71d8611742720e4c50e7a974020c",
    "decimals": 6,
    "image": "https://rango.vip/i/nOKP9Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UCASH",
    "name": "U CASH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x92e52a1a235d9a103d970901066ce910aacefd37",
    "decimals": 8,
    "image": "https://rango.vip/i/SzXGKX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006171,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFTFN",
    "name": "NFTFN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9a64977ebf739dff35ed4281a4b5e833bfdb1314",
    "decimals": 18,
    "image": "https://rango.vip/i/B1TkT7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVXFPIS",
    "name": "Convex FPIS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2847348b58ced0ca58d23c7e9106a49f1427df6",
    "decimals": 18,
    "image": "https://rango.vip/i/F1ylXk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.391831,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PPDEX",
    "name": "Pepedex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1f508c7c9f0d1b15a76fba564eef2d956220cf7",
    "decimals": 18,
    "image": "https://rango.vip/i/1MHEet",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.082332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRC20",
    "name": "BRC on the ERC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e87d63d11d1c16052bbca06d43ba4ceb4ee686c",
    "decimals": 9,
    "image": "https://rango.vip/i/ENftDL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00331704,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WMC",
    "name": "Wrapped MistCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7fd4d7737597e7b4ee22acbf8d94362343ae0a79",
    "decimals": 2,
    "image": "https://rango.vip/i/6NwVTM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.07,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SEUR",
    "name": "sEUR",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd71ecff9342a5ced620049e616c5035f1db98620",
    "decimals": 18,
    "image": "https://rango.vip/i/Dcv4cN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.069,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FAI",
    "name": "Fairum Community",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcda2f16c6aa895d533506b426aff827b709c87f5",
    "decimals": 18,
    "image": "https://rango.vip/i/8b0ypv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.074857,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSIENNA",
    "name": "Sienna  ERC 20 ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b00e6e8d787b13756eb919786c9745054db64f9",
    "decimals": 18,
    "image": "https://rango.vip/i/xYU8gK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0114699,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CBAT",
    "name": "cBAT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c8c6b02e7b2be14d4fa6022dfd6d75921d90e4e",
    "decimals": 8,
    "image": "https://rango.vip/i/Ua6QMf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0032254,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCURVE",
    "name": "LP sCurve",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc25a3a3b969415c80451098fa907ec722572917f",
    "decimals": 18,
    "image": "https://rango.vip/i/YEJgYP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.072,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CZRX",
    "name": "c0x",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb3319f5d18bc0d84dd1b4825dcde5d5f7266d407",
    "decimals": 8,
    "image": "https://rango.vip/i/N4vGhc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00557214,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAL",
    "name": "Paladin Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xab846fb6c81370327e784ae7cbb6d6a6af6ff4bf",
    "decimals": 18,
    "image": "https://rango.vip/i/rv4wkP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04233084,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NFNT",
    "name": "nfinityAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x82d36570842fc1ac2a3b4dbe0e7c5c0e2e665090",
    "decimals": 18,
    "image": "https://rango.vip/i/CJtuXp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01739608,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOY",
    "name": "Fund Of Yours",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf91e8afbe87642dc628786188a54b78580a4d76",
    "decimals": 18,
    "image": "https://rango.vip/i/GgnZPs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.27619e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COV",
    "name": "Covesting",
    "isPopular": false,
    "chainId": "1",
    "address": "0xada86b1b313d1d5267e3fc0bb303f0a2b66d0ea7",
    "decimals": 18,
    "image": "https://rango.vip/i/pEW0bV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.059982,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FREPE",
    "name": "Fren Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x79cd36e049f678ace70a58341ae751fda8d8665d",
    "decimals": 18,
    "image": "https://rango.vip/i/m7eORI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00635911,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JIZZ",
    "name": "JizzRocket",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b937af714ac7e2129bd33d93641f52b665ca352",
    "decimals": 18,
    "image": "https://rango.vip/i/1hf4CC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000116,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEL",
    "name": "KelVPN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4abb9cc67bd3da9eb966d1159a71a0e68bd15432",
    "decimals": 18,
    "image": "https://rango.vip/i/9nGbiX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0044959,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGS",
    "name": "Aegis",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb2f2bcce3efa95eda95a233af45f3e0d4f00e2a",
    "decimals": 8,
    "image": "https://rango.vip/i/4wOpuO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00309373,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BMC",
    "name": "BountyMarketCap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd945d2031b4c63c0e363304fb771f709b502dc0a",
    "decimals": 18,
    "image": "https://rango.vip/i/uRmkGc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.052263,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SSP",
    "name": "Smartshare",
    "isPopular": false,
    "chainId": "1",
    "address": "0x624d520bab2e4ad83935fa503fb130614374e850",
    "decimals": 4,
    "image": "https://rango.vip/i/5Ea0pt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000195,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOOX",
    "name": "Foox  Ordinals ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20fcefa41045080764c48c2b9429e44c644e5dea",
    "decimals": 18,
    "image": "https://rango.vip/i/3jPyvf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00062152,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NMAI",
    "name": "NomotaAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x837ee5a664d51bc2e7d26eb63cffeb48e037bde2",
    "decimals": 18,
    "image": "https://rango.vip/i/EXBWLe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00197123,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NATI",
    "name": "IlluminatiCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b9ae6b1d4f0eeed904d1cef68b9bd47499f3fff",
    "decimals": 18,
    "image": "https://rango.vip/i/83ULO7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.39156e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CWBTC",
    "name": "Compound Wrapped BTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xccf4429db6322d5c611ee964527d42e5d685dd6a",
    "decimals": 8,
    "image": "https://rango.vip/i/enjeSd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1118.39,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEAN",
    "name": "Bean",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbea0000029ad1c77d3d5d23ba2d8893db9d1efab",
    "decimals": 6,
    "image": "https://rango.vip/i/pRhsUy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.469801,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KNG",
    "name": "Kanga Exchange",
    "isPopular": false,
    "chainId": "1",
    "address": "0x471d113059324321749e097705197a2b44a070fc",
    "decimals": 18,
    "image": "https://rango.vip/i/cVPNL1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUMP",
    "name": "dump trade",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf8ef8fef6fa5489d097652dedfb6617ce28a0d6",
    "decimals": 18,
    "image": "https://rango.vip/i/6T8TpD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00381583,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLIPPY",
    "name": "Clippy AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc0e2969d23a084a0bb65a2b9a4242e7f9ea65b6",
    "decimals": 18,
    "image": "https://rango.vip/i/0sPnr9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.0427e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYG",
    "name": "Cyclix Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b15602f008a05d9694d777dead2f05586216cb4",
    "decimals": 18,
    "image": "https://rango.vip/i/F4xJCN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.206215,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WTIA",
    "name": "Trestle Wrapped TIA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6ad2d2c22bb58ea94be18cff11ef67e8bb97b652",
    "decimals": 18,
    "image": "https://rango.vip/i/g9bLEd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.86,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TEAR",
    "name": "TEAR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x299a1503e88433c0fd1bd68625c25c5a703eb64f",
    "decimals": 18,
    "image": "https://rango.vip/i/b3R5aq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00123887,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QTF",
    "name": "Quantfury",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0fcbc31c503b4a9ed90e87f8ff46c318a4a14260",
    "decimals": 8,
    "image": "https://rango.vip/i/FTb7dh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.14,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRIC",
    "name": "Frictionless",
    "isPopular": false,
    "chainId": "1",
    "address": "0x23fa3aa82858e7ad1f0f04352f4bb7f5e1bbfb68",
    "decimals": 18,
    "image": "https://rango.vip/i/fhTcTk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0016091,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FAI",
    "name": "FuturesAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcdbb2498fa9e7b5849bed5d3661386d0ce2733b2",
    "decimals": 18,
    "image": "https://rango.vip/i/4c4tsb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013004,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRTN",
    "name": "Proton Project",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d10e0194400a04600881057787eb9e9c063dca0",
    "decimals": 18,
    "image": "https://rango.vip/i/wrlyn4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.129032,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0XB",
    "name": "0xBlack",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc226294dafb6e69905b3e7635b575d0508a42c5",
    "decimals": 18,
    "image": "https://rango.vip/i/toi6Co",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001111,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOK",
    "name": "Tokenplace",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4fb721ef3bf99e0f2c193847afa296b9257d3c30",
    "decimals": 8,
    "image": "https://rango.vip/i/cI4ttQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00019679,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LMI",
    "name": "Lockheed Martin Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf05ce8a2cef336006e933c02fc89887f5b3c726",
    "decimals": 18,
    "image": "https://rango.vip/i/h6GqJX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008861,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HART",
    "name": "Hara",
    "isPopular": false,
    "chainId": "1",
    "address": "0x52928c95c4c7e934e0efcfab08853a0e4558861d",
    "decimals": 18,
    "image": "https://rango.vip/i/8jURKk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00077935,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CCOMP",
    "name": "cCOMP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70e36f6bf80a52b3b46b3af8e106cc0ed743e8e4",
    "decimals": 8,
    "image": "https://rango.vip/i/adp0Ac",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.21,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ISLA",
    "name": "Insula",
    "isPopular": false,
    "chainId": "1",
    "address": "0x697ef32b4a3f5a4c39de1cb7563f24ca7bfc5947",
    "decimals": 18,
    "image": "https://rango.vip/i/XBxDXd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04208418,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MIS3",
    "name": "MIS3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x024b6e7dc26f4d5579bdd936f8d7bc31f2339999",
    "decimals": 18,
    "image": "https://rango.vip/i/myPOxf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.146935,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNV",
    "name": "Concave",
    "isPopular": false,
    "chainId": "1",
    "address": "0x000000007a58f5f58e697e51ab0357bc9e260a04",
    "decimals": 18,
    "image": "https://rango.vip/i/KAlo2D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.82,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANFD",
    "name": "Angry Doge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4f14cdbd815b79e9624121f564f24685c6b1211b",
    "decimals": 18,
    "image": "https://rango.vip/i/1Y0fIh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000821,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AXIAV3",
    "name": "Axia",
    "isPopular": false,
    "chainId": "1",
    "address": "0x793786e2dd4cc492ed366a94b88a3ff9ba5e7546",
    "decimals": 18,
    "image": "https://rango.vip/i/o01j1W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0012593,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWAT",
    "name": "SWTCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0f1728d9513efc316d0e93a0758c992f88b0809",
    "decimals": 8,
    "image": "https://rango.vip/i/Zg36Wq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.24956e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LISTR",
    "name": "LISTR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4d01f7d65617464f6a72e55cc26fd9a3259a2ee3",
    "decimals": 18,
    "image": "https://rango.vip/i/CdePZO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00023554,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAIL",
    "name": "Tail",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfeeeef4d7b4bf3cc8bd012d02d32ba5fd3d51e31",
    "decimals": 18,
    "image": "https://rango.vip/i/64B8a6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003252,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETH+",
    "name": "ETHPlus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe72b141df173b999ae7c1adcbf60cc9833ce56a8",
    "decimals": 18,
    "image": "https://rango.vip/i/RfKDM8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2388.12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BZN",
    "name": "Benzene",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6524b87960c2d573ae514fd4181777e7842435d4",
    "decimals": 18,
    "image": "https://rango.vip/i/VNGXc0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.102492,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OAP",
    "name": "OpenAlexa Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1788430620960f9a70e3dc14202a3a35dde1a316",
    "decimals": 18,
    "image": "https://rango.vip/i/y6fXCY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0075001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IUSDC",
    "name": "Instadapp USDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc8871267e07408b89aa5aecc58adca5e574557f8",
    "decimals": 6,
    "image": "https://rango.vip/i/22cChD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.17,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDAI",
    "name": "Instadapp DAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40a9d39aa50871df092538c5999b107f34409061",
    "decimals": 18,
    "image": "https://rango.vip/i/0hGVBE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCS",
    "name": "Bitclouds",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2fbf61e5236126871018bfbf368ba4cfce0d4512",
    "decimals": 18,
    "image": "https://rango.vip/i/9UsoOX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021867,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTRL",
    "name": "AltCTRL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf74751c07c92b668f02527d0e1384ee6d68ac90e",
    "decimals": 9,
    "image": "https://rango.vip/i/rZde4L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00437126,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSCRT",
    "name": "Wrapped SCRT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2b89bf8ba858cd2fcee1fada378d5cd6936968be",
    "decimals": 6,
    "image": "https://rango.vip/i/uejrjo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.284561,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LPU",
    "name": "CortexLPU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e68250f3031dba94a0b430b881e574a500aa78c",
    "decimals": 18,
    "image": "https://rango.vip/i/aT6H08",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000346,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTBILL",
    "name": "Midas mTBILL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd629e5241cbc5919847783e6c96b2de4754e438",
    "decimals": 18,
    "image": "https://rango.vip/i/QT9j25",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ODE",
    "name": "ODEM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbf52f2ab39e26e0951d2a02b49b7702abe30406a",
    "decimals": 18,
    "image": "https://rango.vip/i/ivFIt2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011915,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OGPU",
    "name": "OPEN GPU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x067def80d66fb69c276e53b641f37ff7525162f6",
    "decimals": 18,
    "image": "https://rango.vip/i/rwpOlI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.797065,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FVT",
    "name": "Finance Vote",
    "isPopular": false,
    "chainId": "1",
    "address": "0x45080a6531d671ddff20db42f93792a489685e32",
    "decimals": 18,
    "image": "https://rango.vip/i/MdUkgw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00067113,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUPERBID",
    "name": "SuperBid",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0563dce613d559a47877ffd1593549fb9d3510d6",
    "decimals": 18,
    "image": "https://rango.vip/i/SuyPD6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00615072,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OHMI",
    "name": "One Hundred Million Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4159862bcf6b4393a80550b1ed03dffa6f90533c",
    "decimals": 18,
    "image": "https://rango.vip/i/AzINrS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00077085,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPEJR",
    "name": "Pepe Junior",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5dfbf02313e1be7e8db5aa6f660433588338f201",
    "decimals": 9,
    "image": "https://rango.vip/i/d1OPCI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.2944e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BULLY",
    "name": "bully ze bull",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d64bde04e64be1c4cae808719c1127f2ccc252b",
    "decimals": 9,
    "image": "https://rango.vip/i/g7zeYr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.1874e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PPBLZ",
    "name": "Pepemon Pepeballs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4d2ee5dae46c86da2ff521f7657dad98834f97b8",
    "decimals": 18,
    "image": "https://rango.vip/i/RiEWTz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 10.15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DMX",
    "name": "Dymmax",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf058501585023d040ea9493134ed72c083553eed",
    "decimals": 18,
    "image": "https://rango.vip/i/Vkpcih",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00820517,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SVD",
    "name": "Savedroid",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbdeb4b83251fb146687fa19d1c660f99411eefe3",
    "decimals": 18,
    "image": "https://rango.vip/i/Zgls6B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000388,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$ZKP",
    "name": "$ZKP Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x909e34d3f6124c324ac83dcca84b74398a6fa173",
    "decimals": 18,
    "image": "https://rango.vip/i/eCxIWA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00867483,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "M2",
    "name": "M2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x965d79f1a1016b574a62986e13ca8ab04dfdd15c",
    "decimals": 18,
    "image": "https://rango.vip/i/xOlHVn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020763,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BKC",
    "name": "FACTS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34bdf48a8f753de4822a6cfb1fee275f9b4d662e",
    "decimals": 18,
    "image": "https://rango.vip/i/NGmWNi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000062,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KIMCHI",
    "name": "KIMCHI finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e18821e69b9faa8e6e75dffe54e7e25754beda0",
    "decimals": 18,
    "image": "https://rango.vip/i/l5rfWQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001876,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYFM",
    "name": "CyberFM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4a621d9f1b19296d1c0f87637b3a8d4978e9bf82",
    "decimals": 18,
    "image": "https://rango.vip/i/hFfsV6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1e-17,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABPT",
    "name": "Aave Balancer Pool Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41a08648c3766f9f9d85598ff102a08f4ef84f84",
    "decimals": 18,
    "image": "https://rango.vip/i/1EIfzA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.174659,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STKABPT",
    "name": "Staked Aave Balancer Pool Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1116930326d21fb917d5a27f1e9943a9595fb47",
    "decimals": 18,
    "image": "https://rango.vip/i/WEfT7o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.174659,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAJOR",
    "name": "Major Dog",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03cce75a4795c1cfab8b7c0a1fb38df46d2f4159",
    "decimals": 9,
    "image": "https://rango.vip/i/QLBTNP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.8041e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIER",
    "name": "Pier Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x87e75025d6607564ad005088ab549dd76ca2ce48",
    "decimals": 18,
    "image": "https://rango.vip/i/vfN5Aw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00393943,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PURE",
    "name": "Puriever",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2904b9b16652d7d0408eccfa23a19d4a3358230f",
    "decimals": 18,
    "image": "https://rango.vip/i/us6m78",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012399,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HEDGEHOG",
    "name": "Hedgehog",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d4ca1177087924edfe0908ef655169ea766fdc3",
    "decimals": 18,
    "image": "https://rango.vip/i/xyNunV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEAL",
    "name": "Stealth Deals",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a771a90b8f2a10afd9a75b8e49d646d1139bb98",
    "decimals": 18,
    "image": "https://rango.vip/i/n481JS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01989783,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RENBTCCURVE",
    "name": "LP renBTC Curve",
    "isPopular": false,
    "chainId": "1",
    "address": "0x49849c98ae39fff122806c06791fa73784fb3675",
    "decimals": 18,
    "image": "https://rango.vip/i/hGrVWA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 57100,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLINK",
    "name": "cLINK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xface851a4921ce59e912d19329929ce6da6eb0c7",
    "decimals": 8,
    "image": "https://rango.vip/i/Wb60FT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.200316,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LBXC",
    "name": "LUX BIO EXCHANGE COIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xffe510a92434a0df346c5e72a3494b043cf249eb",
    "decimals": 18,
    "image": "https://rango.vip/i/M67jSY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000438,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PTKN",
    "name": "Panda",
    "isPopular": false,
    "chainId": "1",
    "address": "0x45b6d1cfcc0218f4d57db1a2895c0f14c8c3cf96",
    "decimals": 18,
    "image": "https://rango.vip/i/xwbLem",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004487,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LP",
    "name": "Liquid Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e8203e0b1d56984abc66f183a8d0b1a09a7e607",
    "decimals": 9,
    "image": "https://rango.vip/i/EAuabO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.079537,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOMA",
    "name": "SOMA finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77163c968c2506077dbe74838dea72314a2d5760",
    "decimals": 18,
    "image": "https://rango.vip/i/LBbcBB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMAS",
    "name": "Amasa",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65a8fba02f641a13bb7b01d5e1129b0521004f52",
    "decimals": 18,
    "image": "https://rango.vip/i/ifofBs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00289612,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EBET",
    "name": "EarnBet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5fbc2ffe91ac74e3e286bd7504b233f0e5291c69",
    "decimals": 8,
    "image": "https://rango.vip/i/ESvB2o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00131702,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UERII",
    "name": "UERII",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc1abb8c93be6811affc70675b0432926c4bfbb5d",
    "decimals": 18,
    "image": "https://rango.vip/i/HPvTbb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00109671,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAM",
    "name": "Datamine",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf80d589b3dbe130c270a69f1a69d050f268786df",
    "decimals": 18,
    "image": "https://rango.vip/i/Arufme",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02905755,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMDAI",
    "name": "Aave AMM DAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x79be75ffc64dd58e66787e4eae470c8a1fd08ba4",
    "decimals": 18,
    "image": "https://rango.vip/i/9pUBk4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997262,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUSDT",
    "name": "Aave AMM USDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x17a79792fe6fe5c95dfe95fe3fcee3caf4fe4cb7",
    "decimals": 6,
    "image": "https://rango.vip/i/w5xKs6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.997135,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOONCAT",
    "name": "MOONCAT Vault  NFTX ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x98968f0747e0a261532cacc0be296375f5c08398",
    "decimals": 18,
    "image": "https://rango.vip/i/6JEwtU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 370.36,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEMELON",
    "name": "Meme Elon Doge Floki",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c8ea636345a231e4b1a28f6eeb2072ed909c406",
    "decimals": 18,
    "image": "https://rango.vip/i/JRCdbT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.2422e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "N1",
    "name": "NFTify",
    "isPopular": false,
    "chainId": "1",
    "address": "0xacbd826394189cf2623c6df98a18b41fc8ffc16d",
    "decimals": 18,
    "image": "https://rango.vip/i/AOpekR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00430122,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0XMR",
    "name": "0xMonero",
    "isPopular": false,
    "chainId": "1",
    "address": "0x035df12e0f3ac6671126525f1015e47d79dfeddf",
    "decimals": 18,
    "image": "https://rango.vip/i/uCXQ5D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04799622,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CMND",
    "name": "CloudMind AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x89407418b9aa8b525911640f42a87676c6bb077b",
    "decimals": 9,
    "image": "https://rango.vip/i/L9fufh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013713,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STMATIC",
    "name": "Lido Staked Matic",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ee91f9f426fa633d227f7a9b000e28b9dfd8599",
    "decimals": 18,
    "image": "https://rango.vip/i/nA1mbo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.412968,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALS",
    "name": "Atalis",
    "isPopular": false,
    "chainId": "1",
    "address": "0xedee9cc3bf9cfd86a493a23a0775508e0f826644",
    "decimals": 9,
    "image": "https://rango.vip/i/ryI6jH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005212,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTSE",
    "name": "BTSE Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x666d875c600aa06ac1cf15641361dec3b00432ef",
    "decimals": 8,
    "image": "https://rango.vip/i/AeOzIE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.499822,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WBEAM",
    "name": "Wrapped BEAM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5acbb03d73267c03349c76ead672ee4d941f499",
    "decimals": 8,
    "image": "https://rango.vip/i/eFFs4P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03246471,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGZ",
    "name": "Dogz",
    "isPopular": false,
    "chainId": "1",
    "address": "0x82a77710495a35549d2add797412b4a4497d33ef",
    "decimals": 18,
    "image": "https://rango.vip/i/ViAPhz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001558,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YERTLE",
    "name": "Yertle The Turtle",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc06d9013a1d3f25f76ee5291bbc04a181985814e",
    "decimals": 9,
    "image": "https://rango.vip/i/9yMUbg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000546,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DROP",
    "name": "DropCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe69d699cbcd79cb1b55f82cf36bf1a2836053562",
    "decimals": 18,
    "image": "https://rango.vip/i/C3NyIN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.27329e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVED",
    "name": "Evedo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5aaefe84e0fb3dd1f0fcff6fa7468124986b91bd",
    "decimals": 18,
    "image": "https://rango.vip/i/EKYTbD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00308234,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DSS",
    "name": "Defi Shopping Stake",
    "isPopular": false,
    "chainId": "1",
    "address": "0x213c53c96a01a89e6dcc5683cf16473203e17513",
    "decimals": 18,
    "image": "https://rango.vip/i/86FaDv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00765272,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0XANON",
    "name": "0xAnon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7199b5a15c7fb79aa861780230adc65fff99ec73",
    "decimals": 8,
    "image": "https://rango.vip/i/9LbgEe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00516925,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUNKETH-20",
    "name": "MetaStreet V2 mwstETH WPUNKS 20",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc975342a95ccb75378ddc646b8620fa3cd5bc051",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2553.32,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ULU",
    "name": "Universal Liquidity Union",
    "isPopular": false,
    "chainId": "1",
    "address": "0x035bfe6057e15ea692c0dfdcab3bb41a64dd2ad4",
    "decimals": 18,
    "image": "https://rango.vip/i/VkgXsi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.36,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSTA",
    "name": "Wrapped Statera",
    "isPopular": false,
    "chainId": "1",
    "address": "0xedeec5691f23e4914cf0183a4196bbeb30d027a0",
    "decimals": 18,
    "image": "https://rango.vip/i/ri7r91",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00038008,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASI",
    "name": "AltSignals",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d942f9872863645bcb181aba66c7d9646a91378",
    "decimals": 18,
    "image": "https://rango.vip/i/S4N97I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00403706,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WVG0",
    "name": "Wrapped Virgin Gen 0 CryptoKittties",
    "isPopular": false,
    "chainId": "1",
    "address": "0x25c7b64a93eb1261e130ec21a3e9918caa38b611",
    "decimals": 18,
    "image": "https://rango.vip/i/waDO4c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 89.76,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATRI",
    "name": "Atari",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdacd69347de42babfaecd09dc88958378780fb62",
    "decimals": 0,
    "image": "https://rango.vip/i/zcEg7Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027125,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLOSEDAI",
    "name": "ClosedAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x50b0696468f42cab1ddc76413a1312aff3cabdf6",
    "decimals": 18,
    "image": "https://rango.vip/i/vs0uby",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018148,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IBETH",
    "name": "Interest Bearing ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x67b66c99d3eb37fa76aa3ed1ff33e8e39f0b9c7a",
    "decimals": 18,
    "image": "https://rango.vip/i/8wLQ3d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2658.73,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MVEDA",
    "name": "MedicalVeda",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcbe7142f5c16755d8683ba329efa1abf7b54482d",
    "decimals": 8,
    "image": "https://rango.vip/i/I123vm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00147169,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNIDX",
    "name": "UniDex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0655dcee37e5c0b70fffd70d85f88f8edf0aff6",
    "decimals": 18,
    "image": "https://rango.vip/i/W0WZhr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.524884,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "R34P",
    "name": "R34P",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcaeaf8381d4b20b43afa42061d6f80319a8881f6",
    "decimals": 8,
    "image": "https://rango.vip/i/WA7VL3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.3,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WCX",
    "name": "WeCoOwn",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc014186cf1ba36032aaec7f96088f09eb3934347",
    "decimals": 18,
    "image": "https://rango.vip/i/r6iYQX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005872,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GIX",
    "name": "GoldFinX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd434a09191d401da3283a5545bb3515d033b8c4",
    "decimals": 18,
    "image": "https://rango.vip/i/8xWynd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0008197,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAAVE",
    "name": "cAAVE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe65cdb6479bac1e22340e4e755fae7e509ecd06c",
    "decimals": 8,
    "image": "https://rango.vip/i/7Ph34G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.61,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASAP",
    "name": "Asap Sniper Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6522f491df42651cf5e6636b7261adaa096d095f",
    "decimals": 18,
    "image": "https://rango.vip/i/y1bP5F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006766,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEBO",
    "name": "CSP DAO Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f0c8b125040f707441cad9e5ed8a8408673b455",
    "decimals": 18,
    "image": "https://rango.vip/i/mjxFuY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.35,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOMEE",
    "name": "SoMee Social",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65f9a292f1aeed5d755aa2fd2fb17ab2e9431447",
    "decimals": 18,
    "image": "https://rango.vip/i/thTgTK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00150094,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CSUSHI",
    "name": "cSUSHI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b0181102a0112a2ef11abee5563bb4a3176c9d7",
    "decimals": 8,
    "image": "https://rango.vip/i/g49Tt9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01191475,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYFI",
    "name": "cYFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80a2ae356fc9ef4305676f7a3e2ed04e12c33946",
    "decimals": 8,
    "image": "https://rango.vip/i/AHbiy6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 98.83,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOXETH",
    "name": "Cat in a Box Ether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7690202e2c2297bcd03664e31116d1dffe7e3b73",
    "decimals": 18,
    "image": "https://rango.vip/i/DbtAGl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2450.93,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNFI",
    "name": "Connect Financial",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeabb8996ea1662cad2f7fb715127852cd3262ae9",
    "decimals": 18,
    "image": "https://rango.vip/i/kHnVbl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.053391,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LCS",
    "name": "LocalCoinSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaa19961b6b858d9f18a115f25aa1d98abc1fdba8",
    "decimals": 18,
    "image": "https://rango.vip/i/ewEIA9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.128506,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAG",
    "name": "Change",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d4b8cce0591c9044a22ee543533b72e976e36c3",
    "decimals": 18,
    "image": "https://rango.vip/i/TfSLaa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00148432,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUCKS",
    "name": "Ducks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8666cb197af5103f7a3a0295b50efea47f3df78b",
    "decimals": 18,
    "image": "https://rango.vip/i/VUu9ko",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.231e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UUSDT",
    "name": "Unagii Tether USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x178bf8fd04b47d2de3ef3f6b3d112106375ad584",
    "decimals": 6,
    "image": "https://rango.vip/i/FXeNgl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.053,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UUSDC",
    "name": "Unagii USD Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc5991ccd8caceba01edc44c2bb9832712c29cab",
    "decimals": 6,
    "image": "https://rango.vip/i/hzgUPi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.087,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UDAI",
    "name": "Unagii Dai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4ad0b81f92b16624bbcf46fc0030cfbbf8d02376",
    "decimals": 18,
    "image": "https://rango.vip/i/dz5VJR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.073,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPPA",
    "name": "PEPPA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf4172630a656a47ece8616e75791290446fa41a0",
    "decimals": 2,
    "image": "https://rango.vip/i/r80PRg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.994e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDLEDAIYIELD",
    "name": "IdleDAI  Best Yield ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3fe7940616e5bc47b0775a0dccf6237893353bb4",
    "decimals": 18,
    "image": "https://rango.vip/i/4X3JaR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.2,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDLEWBTCYIELD",
    "name": "IdleWBTC  Best Yield ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c81121b15197fa0eeaee1dc75533419dcfd3151",
    "decimals": 18,
    "image": "https://rango.vip/i/O6ZSRH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 56032,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RG",
    "name": "Runes Glyphs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x14c3f2ce251eb9385839d291c40bf085f3c6f266",
    "decimals": 9,
    "image": "https://rango.vip/i/ZAZZJ9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0356127,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAC",
    "name": "Double A Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe75ad3aab14e4b0df8c5da4286608dabb21bd864",
    "decimals": 5,
    "image": "https://rango.vip/i/2V8omF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000073,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CCX",
    "name": "ClearCryptos",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1d6405138a335ce5fd7364086334efb3e4f28b59",
    "decimals": 18,
    "image": "https://rango.vip/i/Tg2q04",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00322802,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FNK",
    "name": "Fnk com",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb5fe099475d3030dde498c3bb6f3854f762a48ad",
    "decimals": 18,
    "image": "https://rango.vip/i/VyJmE8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0081972,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RETH2",
    "name": "rETH2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20bc832ca081b91433ff6c17f85701b6e92486c5",
    "decimals": 18,
    "image": "https://rango.vip/i/vI12vw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2468.32,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KUSUNOKI",
    "name": "Kusunoki Samurai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36919a60a2b67b6d2329863093d180d23d5a0308",
    "decimals": 18,
    "image": "https://rango.vip/i/rsuhSp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.6443e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARTX",
    "name": "ARTX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x741b0428efdf4372a8df6fb54b018db5e5ab7710",
    "decimals": 18,
    "image": "https://rango.vip/i/st4Q4a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04271878,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FMT",
    "name": "Finminity",
    "isPopular": false,
    "chainId": "1",
    "address": "0x99c6e435ec259a7e8d65e1955c9423db624ba54c",
    "decimals": 18,
    "image": "https://rango.vip/i/4YrsYu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01514672,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYNTX",
    "name": "Syntax AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x794fcee2c16e9495c3f590958b1209400751ba80",
    "decimals": 18,
    "image": "https://rango.vip/i/pa8YAd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00041599,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IWBTC",
    "name": "Instadapp WBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xec363faa5c4dd0e51f3d9b5d0101263760e7cdeb",
    "decimals": 8,
    "image": "https://rango.vip/i/jE7TWQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 59873,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGURI",
    "name": "Aguri",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2cc774364ec2d4cf53433c76dad287b743b17441",
    "decimals": 9,
    "image": "https://rango.vip/i/AtEUAv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.46715e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZCO",
    "name": "Zebi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2008e3057bd734e10ad13c9eae45ff132abc1722",
    "decimals": 8,
    "image": "https://rango.vip/i/SBw46J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011902,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIE",
    "name": "Die Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6ef6610d24593805144d73b13d4405e00a4e4ac7",
    "decimals": 18,
    "image": "https://rango.vip/i/GW6GMO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.1728e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WIZARD",
    "name": "WIZARD Vault  NFTX ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x87931e7ad81914e7898d07c68f145fc0a553d8fb",
    "decimals": 18,
    "image": "https://rango.vip/i/xVsUUJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1059.78,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PHUNK",
    "name": "PHUNK Vault  NFTX ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb39185e33e8c28e0bb3dbbce24da5dea6379ae91",
    "decimals": 18,
    "image": "https://rango.vip/i/dBoNYL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 210.63,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MESH",
    "name": "MeshBox",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcf9fbffec9e0e5bbc62e79bf1965f5db76955661",
    "decimals": 18,
    "image": "https://rango.vip/i/gjgVbS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0007191,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GCR",
    "name": "Global Coin Research",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6307b25a665efc992ec1c1bc403c38f3ddd7c661",
    "decimals": 4,
    "image": "https://rango.vip/i/vBEeSE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.245057,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DXC",
    "name": "Dex Trade Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb0e99627bc29adef1178f16117bf495351e81997",
    "decimals": 18,
    "image": "https://rango.vip/i/hHZGxH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.302926,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SENT",
    "name": "SENTinel",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa44e5137293e855b1b7bc7e2c6f8cd796ffcb037",
    "decimals": 8,
    "image": "https://rango.vip/i/efpNWo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0008385,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ADULT",
    "name": "Adult Playground",
    "isPopular": false,
    "chainId": "1",
    "address": "0x565d3902d6a5a2d5ce28ff427423e88933334dd2",
    "decimals": 18,
    "image": "https://rango.vip/i/p7cOou",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00132946,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARTH",
    "name": "ARTH Valuecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8cc0f052fff7ead7f2edcccac895502e884a8a71",
    "decimals": 18,
    "image": "https://rango.vip/i/55ixFe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.64,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STILT",
    "name": "Stilton",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb5f1457d6fba1956fb8d31b0b7caca14bde0be4b",
    "decimals": 9,
    "image": "https://rango.vip/i/hi0oPU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.0415e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TYC",
    "name": "Tycoon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a82d3111ab5faf39d847d46023d9090261a658f",
    "decimals": 18,
    "image": "https://rango.vip/i/S0gnYI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0003542,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SRUNE",
    "name": "sRUNE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0352557b007a4aae1511c114409b932f06f9e2f4",
    "decimals": 18,
    "image": "https://rango.vip/i/zlLJi8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.56,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVBOOST",
    "name": "Yearn Compounding veCRV yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d409a0a012cfba9b15f6d4b36ac57a46966ab9a",
    "decimals": 18,
    "image": "https://rango.vip/i/u9IrrZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.81628,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COSHI",
    "name": "CoShi Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x668c50b1c7f46effbe3f242687071d7908aab00a",
    "decimals": 9,
    "image": "https://rango.vip/i/fiQuiD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.30659e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AWO",
    "name": "AiWork",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68c85b9f78f30a0df5ac5723e4e700037f185415",
    "decimals": 18,
    "image": "https://rango.vip/i/oOHeaw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001511,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDOG",
    "name": "Small Doge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x537edd52ebcb9f48ff2f8a28c51fcdb9d6a6e0d4",
    "decimals": 18,
    "image": "https://rango.vip/i/eJwoRX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.0935e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TINFA",
    "name": "TINFA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x380bd08929d4ae51de22f708dccb6b4c534ab79a",
    "decimals": 18,
    "image": "https://rango.vip/i/755mbw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000731,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIPS",
    "name": "Moneybrain BiPS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5cb888182fbffdb62c08fb4b5a343914f00fdfee",
    "decimals": 18,
    "image": "https://rango.vip/i/DJcvkU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 15.23,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAWGS",
    "name": "SpaceDawgs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64a77277e37d44957fe5815d6ff442ab8b16cc29",
    "decimals": 9,
    "image": "https://rango.vip/i/nBKhzn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.54804e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JCHF",
    "name": "Jarvis Synthetic Swiss Franc",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53dfea0a8cc2a2a2e425e1c174bc162999723ea0",
    "decimals": 18,
    "image": "https://rango.vip/i/unr9k3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.815205,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TKINU",
    "name": "Tsuki Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda23d301761e4e2bf474951f978f6dfb6f3c9f14",
    "decimals": 9,
    "image": "https://rango.vip/i/ewCZ8I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.75295e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DS",
    "name": "DS Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb67beb26ebeb0dceec354ae0942256d03c01771b",
    "decimals": 18,
    "image": "https://rango.vip/i/JWw9Mg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0002949,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GATE",
    "name": "GATENet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d7630adf7ab0b0cb00af747db76864df0ec82e4",
    "decimals": 18,
    "image": "https://rango.vip/i/Ii3LJ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00308159,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELONONE",
    "name": "AstroElon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97b65710d03e12775189f0d113202cc1443b0aa2",
    "decimals": 9,
    "image": "https://rango.vip/i/GbATXl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.00535e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELFI",
    "name": "ELYFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4da34f8264cb33a5c9f17081b9ef5ff6091116f4",
    "decimals": 18,
    "image": "https://rango.vip/i/VsejOu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01032462,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SERP",
    "name": "Shibarium Perpetuals",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd414e39155f91e94443a9fe97e856569d0f5eec",
    "decimals": 9,
    "image": "https://rango.vip/i/ekM5FL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007218,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RINIA",
    "name": "Rinia Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f828a0644f12fa352888e645a90333d30f6fd7d",
    "decimals": 9,
    "image": "https://rango.vip/i/YNn9ga",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00450627,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMWBTC",
    "name": "Aave AMM WBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x13b2f6928d7204328b0e8e4bcd0379aa06ea21fa",
    "decimals": 8,
    "image": "https://rango.vip/i/jMk2Xl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55434,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNIBATWETH",
    "name": "Aave AMM UniBATWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1b0edf4460cc4d8bfaa18ed871bff15e5b57eb4",
    "decimals": 18,
    "image": "https://rango.vip/i/h6tBCF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 56.44,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNIDAIUSDC",
    "name": "Aave AMM UniDAIUSDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe340b25fe32b1011616bb8ec495a4d503e322177",
    "decimals": 18,
    "image": "https://rango.vip/i/96pWgi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2264010,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SQGL",
    "name": "Chromie Squiggle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8d137e3337eb1b58a222fef2b2cc7c423903d9cf",
    "decimals": 18,
    "image": "https://rango.vip/i/LKmS0o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9039.8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNIUSDCWETH",
    "name": "Aave AMM UniUSDCWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x391e86e2c002c70dee155eaceb88f7a3c38f5976",
    "decimals": 18,
    "image": "https://rango.vip/i/IqC51G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 234215131,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNICRVWETH",
    "name": "Aave AMM UniCRVWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ea20e7ffb006d4cfe84df2f72d8c7bd89247db0",
    "decimals": 18,
    "image": "https://rango.vip/i/E4gpnZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 569.8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNILINKWETH",
    "name": "Aave AMM UniLINKWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8db81b84d30e2387de0ff330420a4aaa6688134",
    "decimals": 18,
    "image": "https://rango.vip/i/ecI6HO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 547.41,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNIRENWETH",
    "name": "Aave AMM UniRENWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa9e201a4e269d6cd5e9f0fcbcb78520cf815878b",
    "decimals": 18,
    "image": "https://rango.vip/i/Tj28vI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 41.55,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNISNXWETH",
    "name": "Aave AMM UniSNXWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38e491a71291cd43e8de63b7253e482622184894",
    "decimals": 18,
    "image": "https://rango.vip/i/DC6XcH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 249.44,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNIUNIWETH",
    "name": "Aave AMM UniUNIWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d26dcd840fcc8e4b2193ace8a092e4a65832f9f",
    "decimals": 18,
    "image": "https://rango.vip/i/N6YDnV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 589.6,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BITT",
    "name": "BITT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f9913853f749b3fe6d6d4e16a1cc3c1656b6d51",
    "decimals": 18,
    "image": "https://rango.vip/i/IvAo3V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0009214,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUSDC",
    "name": "Aave AMM USDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd24946147829deaa935be2ad85a3291dbf109c80",
    "decimals": 6,
    "image": "https://rango.vip/i/oZp3fq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999495,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNIWBTCWETH",
    "name": "Aave AMM UniWBTCWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc58f53a8adff2fb4eb16ed56635772075e2ee123",
    "decimals": 18,
    "image": "https://rango.vip/i/lBlhlZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2902642755,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GTT",
    "name": "Game Tournament Trophy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5eda6c581f4373b07ce111baf8d1c4fc21cbaa1",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015494,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNIAAVEWETH",
    "name": "Aave AMM UniAAVEWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe59d2ff6995a926a574390824a657eed36801e55",
    "decimals": 18,
    "image": "https://rango.vip/i/IYiRxx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1616.34,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PCE",
    "name": "PEACE COIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c28310cc0b8d898c57b93913098e74a3ba23228",
    "decimals": 18,
    "image": "https://rango.vip/i/3rwo9K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01210849,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNIDAIWETH",
    "name": "Aave AMM UniDAIWETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9303eabc860a743aabcc3a1629014cabcc3f8d36",
    "decimals": 18,
    "image": "https://rango.vip/i/Jopogf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 189.54,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEEB",
    "name": "MEEB Vault  NFTX ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x641927e970222b10b2e8cdbc96b1b4f427316f16",
    "decimals": 18,
    "image": "https://rango.vip/i/wYYDEJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 962.36,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABAL",
    "name": "Aave interest bearing BAL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x272f97b7a56a387ae942350bbc7df5700f8a4576",
    "decimals": 18,
    "image": "https://rango.vip/i/gPbNHw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.78,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEBULA",
    "name": "Nebula",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac1419ee74f203c6b9daa3635ad7169b7ebb5c1a",
    "decimals": 9,
    "image": "https://rango.vip/i/qO3UNp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00068927,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARAI",
    "name": "Aave RAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc9bc48c72154ef3e5425641a3c747242112a46af",
    "decimals": 18,
    "image": "https://rango.vip/i/ogL1fe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.94,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGUSD",
    "name": "Aave interest bearing GUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd37ee7e4f452c6638c96536e68090de8cbcdb583",
    "decimals": 2,
    "image": "https://rango.vip/i/1ykOCB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999493,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAUSD",
    "name": "Parallel USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x571f54d23cdf2211c83e9a0cbd92aca36c48fa02",
    "decimals": 18,
    "image": "https://rango.vip/i/xc4RgJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.006,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACRV",
    "name": "Aave interest bearing CRV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8dae6cb04688c62d939ed9b68d32bc62e49970b1",
    "decimals": 18,
    "image": "https://rango.vip/i/SCELth",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.273809,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUNC",
    "name": "Sunrise",
    "isPopular": false,
    "chainId": "1",
    "address": "0x692accdd8b86692427e0aa4752ae917df01cc56f",
    "decimals": 18,
    "image": "https://rango.vip/i/ptOIO8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010664,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMBSWAP",
    "name": "SimbCoin Swap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53bd789f2cdb846b227d8ffc7b46ed4263231fdf",
    "decimals": 18,
    "image": "https://rango.vip/i/YdS946",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00099937,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EQZ",
    "name": "Equalizer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1da87b114f35e1dc91f72bf57fc07a768ad40bb0",
    "decimals": 18,
    "image": "https://rango.vip/i/yENSWl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00317089,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ENTR",
    "name": "ENTER Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd779eea9936b4e323cddff2529eb6f13d0a4d66e",
    "decimals": 18,
    "image": "https://rango.vip/i/GKa0SM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00694908,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CODEG",
    "name": "CodeGenie",
    "isPopular": false,
    "chainId": "1",
    "address": "0xced6a1b885ee79899422d3a2f61fa9c77282c573",
    "decimals": 18,
    "image": "https://rango.vip/i/yEAdzv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00844697,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$DG",
    "name": "decentral.games",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee06a81a695750e71a662b51066f2c74cf4478a0",
    "decimals": 18,
    "image": "https://rango.vip/i/5coRGN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 11.47,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DBUY",
    "name": "Doont Buy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4ece5c5cfb9b960a49aae739e15cdb6cfdcc5782",
    "decimals": 9,
    "image": "https://rango.vip/i/vKUdFg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00065871,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAMMUNIWBTCUSDC",
    "name": "Aave AMM UniWBTCUSDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2365a4890ed8965e564b7e2d27c38ba67fec4c6f",
    "decimals": 18,
    "image": "https://rango.vip/i/aDYlje",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 84064924915647,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AXSUSHI",
    "name": "Aave XSUSHI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf256cc7847e919fac9b808cc216cac87ccf2f47a",
    "decimals": 18,
    "image": "https://rango.vip/i/xF0Tlf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.819235,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANB",
    "name": "Angryb",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c9aceb63181cd08a093d052ec041e191f229692",
    "decimals": 18,
    "image": "https://rango.vip/i/Uh2vrc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.00031e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PPIZZA",
    "name": "PPizza",
    "isPopular": false,
    "chainId": "1",
    "address": "0xab306326bc72c2335bd08f42cbec383691ef8446",
    "decimals": 18,
    "image": "https://rango.vip/i/cuuX4P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.27309e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SALLY",
    "name": "SALAMANDER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42e70913b53cfcc38b18ffbd124e8f65c706deaf",
    "decimals": 9,
    "image": "https://rango.vip/i/tc4ENI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.0298e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRIGHT",
    "name": "Bright Union",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbeab712832112bd7664226db7cd025b153d3af55",
    "decimals": 18,
    "image": "https://rango.vip/i/YlfEvb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00274685,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VRN",
    "name": "Varen",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72377f31e30a405282b522d588aebbea202b4f23",
    "decimals": 18,
    "image": "https://rango.vip/i/zS4vYw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.79,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YDR",
    "name": "YDragon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3757232b55e60da4a8793183ac030cfce4c3865d",
    "decimals": 18,
    "image": "https://rango.vip/i/3SQFAl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00398964,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XDATA",
    "name": "Streamr (old)",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0cf0ee63788a0849fe5297f3407f701e122cc023",
    "decimals": 18,
    "image": "https://rango.vip/i/q7zPf4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03774407,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HALVING",
    "name": "Halving",
    "isPopular": false,
    "chainId": "1",
    "address": "0x665f4709940f557e9dde63df0fd9cf6425852b4d",
    "decimals": 9,
    "image": "https://rango.vip/i/4pkewz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.16541e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRIGHT",
    "name": "Bright",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5dd57da40e6866c9fcc34f4b6ddc89f1ba740dfe",
    "decimals": 18,
    "image": "https://rango.vip/i/ytsDNV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0195373,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RIFI",
    "name": "Rikkei Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc8ef1460277ea47d179dec66d1c5f8b7f7ae5a28",
    "decimals": 18,
    "image": "https://rango.vip/i/VDRQ5e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00196828,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TSX",
    "name": "TradeStars",
    "isPopular": false,
    "chainId": "1",
    "address": "0x734c90044a0ba31b3f2e640c10dc5d3540499bfd",
    "decimals": 18,
    "image": "https://rango.vip/i/UDqsmA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00166887,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DRAGGY0X62",
    "name": "Draggy 0x62",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62dc60b69b650290b0d5b993e145e0e87892be14",
    "decimals": 9,
    "image": "https://rango.vip/i/wjhZ1G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000137,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRINTS",
    "name": "FingerprintsDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4dd28568d05f09b02220b09c2cb307bfd837cb95",
    "decimals": 18,
    "image": "https://rango.vip/i/IruiZA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.582439,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNV",
    "name": "Unvest",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdcb2fa7eab2507613417bb9762efa73093fc6b65",
    "decimals": 18,
    "image": "https://rango.vip/i/oupC9B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00112691,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SANSHU",
    "name": "Sanshu Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc73c167e7a4ba109e4052f70d5466d0c312a344d",
    "decimals": 9,
    "image": "https://rango.vip/i/R4VIYV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.635e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YIN",
    "name": "YIN Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x794baab6b878467f93ef17e2f2851ce04e3e34c8",
    "decimals": 18,
    "image": "https://rango.vip/i/yYhmaU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00031524,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIGSB",
    "name": "BigShortBets",
    "isPopular": false,
    "chainId": "1",
    "address": "0x131157c6760f78f7ddf877c0019eba175ba4b6f6",
    "decimals": 18,
    "image": "https://rango.vip/i/qCNjyQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.365552,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AFIN",
    "name": "Asian Fintech",
    "isPopular": false,
    "chainId": "1",
    "address": "0xee9e5eff401ee921b138490d00ca8d1f13f67a72",
    "decimals": 8,
    "image": "https://rango.vip/i/EvQu6i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00100724,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DELREY",
    "name": "Delrey Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfca89d55a768375ab7ca04485a35a964bea828dd",
    "decimals": 18,
    "image": "https://rango.vip/i/ZmG4Cu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.060096,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCRIV",
    "name": "SCRIV",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd76050f75627e508fa14b84036fbf40b8cc549bd",
    "decimals": 8,
    "image": "https://rango.vip/i/ClhHHr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00041339,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NPX",
    "name": "Napoleon X",
    "isPopular": false,
    "chainId": "1",
    "address": "0x28b5e12cce51f15594b0b91d5b5adaa70f684a02",
    "decimals": 2,
    "image": "https://rango.vip/i/4FPRg0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00931074,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONONOKE-INU",
    "name": "Mononoke Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4da08a1bff50be96bded5c7019227164b49c2bfc",
    "decimals": 9,
    "image": "https://rango.vip/i/fXF4uT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.05941e-13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BC",
    "name": "Bitcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe03b2642a5111ad0efc0cbce766498c2dd562ae9",
    "decimals": 9,
    "image": "https://rango.vip/i/VX74lC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00095796,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OAT",
    "name": "OAT Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x781fbc4c6edf7a37dcc08a3b323f122e8a09eac5",
    "decimals": 18,
    "image": "https://rango.vip/i/ypYSzF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00404392,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PULSE",
    "name": "Pulse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x52a047ee205701895ee06a375492490ec9c597ce",
    "decimals": 18,
    "image": "https://rango.vip/i/pmKIsR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01679264,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCX",
    "name": "Scarcity",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1b8568fbb47708e9e9d31ff303254f748805bf21",
    "decimals": 18,
    "image": "https://rango.vip/i/qjWzJQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.45,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CCS",
    "name": "CloutContracts",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1da4858ad385cc377165a298cc2ce3fce0c5fd31",
    "decimals": 0,
    "image": "https://rango.vip/i/RJZKpS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.587563,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MGH",
    "name": "MetaGameHub DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8765b1a0eb57ca49be7eacd35b24a574d0203656",
    "decimals": 18,
    "image": "https://rango.vip/i/krC4Sb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0005363,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAIR",
    "name": "HairDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ce115f0341ae5dabc8b477b74e83db2018a6f42",
    "decimals": 18,
    "image": "https://rango.vip/i/BvXhTJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 32.21,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOONE",
    "name": "No One",
    "isPopular": false,
    "chainId": "1",
    "address": "0x296233e84c1d7bff11121bf6d60f0ffa39c3f0cf",
    "decimals": 9,
    "image": "https://rango.vip/i/3cNNeb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.76345e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEXA",
    "name": "LEXA AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe59b14e43479dc30d77f553e4b5c6fc67e1f6caf",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020341,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DSAI",
    "name": "DeSend Ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb27782fdb56352a684686a852374ef20910457e2",
    "decimals": 18,
    "image": "https://rango.vip/i/aB041k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001633,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KLTR",
    "name": "Kollector",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa92c49c403386111c1629aee00936eed2a9e74a6",
    "decimals": 18,
    "image": "https://rango.vip/i/rKQxin",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01588641,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KXA",
    "name": "Kryxivia Game",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2223bf1d7c19ef7c06dab88938ec7b85952ccd89",
    "decimals": 18,
    "image": "https://rango.vip/i/bjaQpQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00074167,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUDD",
    "name": "Novatti Australian Digital Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4cce605ed955295432958d8951d0b176c10720d5",
    "decimals": 6,
    "image": "https://rango.vip/i/P2sKeD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.65308,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VCK",
    "name": "28VCK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa5b75a9e13df9775cf5b996a049d9cc07c15731",
    "decimals": 18,
    "image": "https://rango.vip/i/aA451M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00150149,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVPAD",
    "name": "CV Pad",
    "isPopular": false,
    "chainId": "1",
    "address": "0x259b0f9494b3f02c652fa11417b94cb700f1f7d8",
    "decimals": 18,
    "image": "https://rango.vip/i/nLYVqG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IBEX",
    "name": "Impermax",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf655c8567e0f213e6c634cd2a68d992152161dc6",
    "decimals": 18,
    "image": "https://rango.vip/i/FWRuJF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.055798,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AVATLY",
    "name": "AVATLY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5fa3418d828e5cd3c61a66e0fc7fa4a35dadf960",
    "decimals": 18,
    "image": "https://rango.vip/i/37VlgA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00242638,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CITADEL",
    "name": "The Citadel",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6cadf6abbceb53e631c288778daacf125481c1bb",
    "decimals": 18,
    "image": "https://rango.vip/i/4xScpT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.104e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "F9",
    "name": "Falcon Nine",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38a94e92a19e970c144ded0b2dd47278ca11cc1f",
    "decimals": 9,
    "image": "https://rango.vip/i/XbKsgk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00059926,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INQU",
    "name": "IntelliQuant",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31bd628c038f08537e0229f0d8c0a7b18b0cda7b",
    "decimals": 18,
    "image": "https://rango.vip/i/eNDNpy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016414,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARUSD",
    "name": "Aladdin rUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07d1718ff05a8c53c8f05adaed57c0d672945f9a",
    "decimals": 18,
    "image": "https://rango.vip/i/N19yhi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.024,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IETHV",
    "name": "Inverse Ethereum Volatility Index Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3a707d56d538e85b783e8ce12b346e7fb6511f90",
    "decimals": 18,
    "image": "https://rango.vip/i/zaJ63t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 165.7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CPRX",
    "name": "Crypto Perx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc6e145421fd494b26dcf2bfeb1b02b7c5721978f",
    "decimals": 18,
    "image": "https://rango.vip/i/UjfDTq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015306,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHK",
    "name": "iShook",
    "isPopular": false,
    "chainId": "1",
    "address": "0xebe4a49df7885d015329c919bf43e6460a858f1e",
    "decimals": 18,
    "image": "https://rango.vip/i/FrCXwE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00404367,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PVGO",
    "name": "Privago AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d85693b4cb4b8bb4d407e33ba1d9de861db80ca",
    "decimals": 18,
    "image": "https://rango.vip/i/iN3Hnu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009204,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XBOT",
    "name": "XBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x330528172778cc5196d5f6742886c72505e0613d",
    "decimals": 18,
    "image": "https://rango.vip/i/xpnSxC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.012233,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOUSD",
    "name": "Wrapped OUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2af830e8cbdfed6cc11bab697bb25496ed6fa62",
    "decimals": 18,
    "image": "https://rango.vip/i/UY9w87",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.16,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GZLR",
    "name": "Guzzler",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f4909cc95fb870bf48c128c1fdbb5f482797632",
    "decimals": 18,
    "image": "https://rango.vip/i/1zovHV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000593,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANOM",
    "name": "Anomus Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5224f552f110ec78e6e0468138950ae5f3040942",
    "decimals": 18,
    "image": "https://rango.vip/i/Ex7tGz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000793,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "69420",
    "name": "69420",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69cbaf6c147086c3c234385556f8a0c6488d3420",
    "decimals": 9,
    "image": "https://rango.vip/i/2XAm4Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.09617e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VACHI",
    "name": "NOVAWCHI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x337af08bb6980ecb68389c5ed8876d08643abf8a",
    "decimals": 18,
    "image": "https://rango.vip/i/RmSCeq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOOD",
    "name": "Blood",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95392f142af1c12f6e39897ff9b09c599666b50c",
    "decimals": 18,
    "image": "https://rango.vip/i/ADMEtL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01009416,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BENT",
    "name": "Bent Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x01597e397605bf280674bf292623460b4204c375",
    "decimals": 18,
    "image": "https://rango.vip/i/Nj80Pv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01599204,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOLLY",
    "name": "BollyCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6bd361e10c1afed0d95259e7c0115f3a60e4ea99",
    "decimals": 18,
    "image": "https://rango.vip/i/yEX0i2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00096568,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DUPE",
    "name": "DupeBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa5a6e1347f6d99c57a53416c5c4645128909b62",
    "decimals": 9,
    "image": "https://rango.vip/i/WeR53K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02379083,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JINKO",
    "name": "Jinko AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe97db8503dd3e3eb0286aafc50327c598efdb578",
    "decimals": 18,
    "image": "https://rango.vip/i/QMVNM1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00146252,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVDAI",
    "name": "DAI yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda816459f1ab5631232fe5e97a05bbbb94970c95",
    "decimals": 18,
    "image": "https://rango.vip/i/pknZvl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BECOIN",
    "name": "bePAY Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f081eb884fd47b79536d28e2dd9d4886773f783",
    "decimals": 6,
    "image": "https://rango.vip/i/eoOi5S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0016507,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JNE",
    "name": "Jake Newman Enterprises",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9657477ac915f56ca87c253db1320218ec2d5ddd",
    "decimals": 18,
    "image": "https://rango.vip/i/6OZEM6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03254418,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONGOOSE",
    "name": "Mongoose",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1817b6d8d890f3943b61648992730373b71f156",
    "decimals": 9,
    "image": "https://rango.vip/i/nAtOYG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.1e-17,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "( ͡° ͜ʖ ͡°)",
    "name": "Lenny Face",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdadb4ae5b5d3099dd1f586f990b845f2404a1c4c",
    "decimals": 18,
    "image": "https://rango.vip/i/Ar3wiN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004841,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "D2D",
    "name": "Prime",
    "isPopular": false,
    "chainId": "1",
    "address": "0x43d4a3cd90ddd2f8f4f693170c9c8098163502ad",
    "decimals": 18,
    "image": "https://rango.vip/i/a8BFF9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01309408,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "٨",
    "name": "Dentacoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x08d32b0da63e2c3bcf8019c9c5d849d7a9d791e6",
    "decimals": 0,
    "image": "https://rango.vip/i/mlJpG1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.10132e-7,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TEMPLE",
    "name": "Temple",
    "isPopular": false,
    "chainId": "1",
    "address": "0x470ebf5f030ed85fc1ed4c2d36b9dd02e77cf1b7",
    "decimals": 18,
    "image": "https://rango.vip/i/Xnikq2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.36,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONSTON",
    "name": "Onston",
    "isPopular": false,
    "chainId": "1",
    "address": "0x47b9f01b16e9c9cb99191dca68c9cc5bf6403957",
    "decimals": 18,
    "image": "https://rango.vip/i/OyhxpL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00049031,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STELAI",
    "name": "StellaryAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd4ee6c8052df6742e4b342cf720ff3ac74f415e",
    "decimals": 9,
    "image": "https://rango.vip/i/O6ABv2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008941,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESGC",
    "name": "ESG Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe857734840dc188b4283d5af14ab8685467ab87d",
    "decimals": 18,
    "image": "https://rango.vip/i/yFlHWt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00207017,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIMFERS",
    "name": "hiMFERS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb755d5bc7de83232b9df1886bd5cdb38895933b0",
    "decimals": 18,
    "image": "https://rango.vip/i/OFOt03",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00051914,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOHO",
    "name": "Foho Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd2e93924bdd4e20c3cf4a8736e5955224fa450e",
    "decimals": 8,
    "image": "https://rango.vip/i/eSGQRF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01012572,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POW",
    "name": "POW",
    "isPopular": false,
    "chainId": "1",
    "address": "0x43ab765ee05075d78ad8aa79dcb1978ca3079258",
    "decimals": 18,
    "image": "https://rango.vip/i/azDrXj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00284352,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OSQTH",
    "name": "Opyn Squeeth",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1b99e3e573a1a9c5e6b2ce818b617f0e664e86b",
    "decimals": 18,
    "image": "https://rango.vip/i/311REh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 85.09,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WBRGE",
    "name": "Wrapped OrdBridge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6602e9319f2c5ec0ba31ffcdc4301d7ef03b709e",
    "decimals": 18,
    "image": "https://rango.vip/i/q97bfw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00329177,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AAG",
    "name": "AAG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ba19d656b65f1684cfea4af428c23b9f3628f97",
    "decimals": 18,
    "image": "https://rango.vip/i/NDwI9W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00066332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CIA",
    "name": "CIA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x52f4d5ee6c91e01be67ca1f64b11ed0ee370817d",
    "decimals": 9,
    "image": "https://rango.vip/i/wvOOHv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.95234e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COVEYFI",
    "name": "Cove YFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff71841eefca78a64421db28060855036765c248",
    "decimals": 18,
    "image": "https://rango.vip/i/YJgLZF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3685.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOTHING",
    "name": "NOTHING",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ab7d54f8cb054141142f04ba0b3d41ac4c4d61c",
    "decimals": 18,
    "image": "https://rango.vip/i/2XeBQO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00058394,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FXUSD",
    "name": "f(x) USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x085780639cc2cacd35e474e71f4d000e2405d8f6",
    "decimals": 18,
    "image": "https://rango.vip/i/xONhcW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NUBTC",
    "name": "Unvaxxed Sperm",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8d6d1b5083089a5587ad942b7c8298a17cdca86",
    "decimals": 9,
    "image": "https://rango.vip/i/t1wfrq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010029,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUDT",
    "name": "Auditchain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb90cb79b72eb10c39cbdf86e50b1c89f6a235f2e",
    "decimals": 18,
    "image": "https://rango.vip/i/vOPX8q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01416758,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCOMP",
    "name": "Stablecomp",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd94a8f9caed25e63ecc90edfefaf3635ea1e182a",
    "decimals": 18,
    "image": "https://rango.vip/i/gCsf6K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00128347,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RELAY",
    "name": "Relay Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d843fa9495d23de997c394296ac7b4d721e841c",
    "decimals": 18,
    "image": "https://rango.vip/i/X8G2Rr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04152965,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AISIG",
    "name": "AISignal",
    "isPopular": false,
    "chainId": "1",
    "address": "0x508b27902c6c14972a10a4e413b9cfa449e9cedb",
    "decimals": 0,
    "image": "https://rango.vip/i/jbNkwT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00047611,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOR",
    "name": "BoringDao",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c9d6c1c73b31c837832c72e04d3152f051fc1a9",
    "decimals": 18,
    "image": "https://rango.vip/i/iKtWWY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 35.32,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MBA",
    "name": "MBA Platform",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf298644967f7b046c178ae37a3ec9d1614ffdc2",
    "decimals": 18,
    "image": "https://rango.vip/i/KIhxWw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.272729,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIGITS",
    "name": "Digits DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe56ab825fd35678a32dc35bc4eb17e238e1404f",
    "decimals": 18,
    "image": "https://rango.vip/i/51l8WE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00441175,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TARGET",
    "name": "Target Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9e07fb66976ae5e40cc5f33674725320917b239",
    "decimals": 18,
    "image": "https://rango.vip/i/AjGi69",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00253445,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EURE",
    "name": "Monerium EUR emoney",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3231cb76718cdef2155fc47b5286d82e6eda273f",
    "decimals": 18,
    "image": "https://rango.vip/i/123KKA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPORK",
    "name": "SporkDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb624fde1a972b1c89ec1dad691442d5e8e891469",
    "decimals": 18,
    "image": "https://rango.vip/i/yGtGKJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00528827,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNW",
    "name": "CoinWealth",
    "isPopular": false,
    "chainId": "1",
    "address": "0x433fce7dfbec729a79999eaf056cb073b2153eba",
    "decimals": 6,
    "image": "https://rango.vip/i/0wEXaK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010717,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FU",
    "name": "FU Money",
    "isPopular": false,
    "chainId": "1",
    "address": "0x43df01681966d5339702e96ef039e481b9da20c1",
    "decimals": 18,
    "image": "https://rango.vip/i/LbkC39",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01838376,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YAWN",
    "name": "YAWN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x881d4c8618d68872fa404518b2460ea839a02a6a",
    "decimals": 18,
    "image": "https://rango.vip/i/az9Ild",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007774,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GYFI",
    "name": "Gyroscope",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70c4430f9d98b4184a4ef3e44ce10c320a8b7383",
    "decimals": 18,
    "image": "https://rango.vip/i/lbYCvI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASTETH",
    "name": "Aave Interest Bearing STETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1982b2f5814301d4e9a8b0201555376e62f82428",
    "decimals": 18,
    "image": "https://rango.vip/i/jDyF9g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2343.53,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "G-CRE",
    "name": "Gluwa Creditcoin Vesting Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa3ee21c306a700e682abcdfe9baa6a08f3820419",
    "decimals": 18,
    "image": "https://rango.vip/i/8ueqes",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.377491,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONE",
    "name": "Mone Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24a5fe2c2240e58252cdfc43bb94ae67d2ead218",
    "decimals": 18,
    "image": "https://rango.vip/i/nZF2E1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0007299,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEGOV2",
    "name": "dego.finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3da932456d082cba208feb0b096d49b202bf89c8",
    "decimals": 18,
    "image": "https://rango.vip/i/0YQWgH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.35,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCLOUD",
    "name": "MoonCloud ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8de43c9c1cd0a039b945b388f7b038b51f949baf",
    "decimals": 9,
    "image": "https://rango.vip/i/3ZxOJJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011066,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CMKR",
    "name": "cMKR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95b4ef2869ebd94beb4eee400a99824bf5dc325b",
    "decimals": 8,
    "image": "https://rango.vip/i/nSHyZk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 32.56,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RTB",
    "name": "Roundtable",
    "isPopular": false,
    "chainId": "1",
    "address": "0x055999b83f9cade9e3988a0f34ef72817566800d",
    "decimals": 18,
    "image": "https://rango.vip/i/oAEtjl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01415417,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BORZOI",
    "name": "Borzoi Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb6dc73987848ba6cb3ce297562723c1a78f85fce",
    "decimals": 18,
    "image": "https://rango.vip/i/ZNntUA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009032,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYNR",
    "name": "MOBLAND",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc6e06778708177a18210181b073da747c88490a",
    "decimals": 18,
    "image": "https://rango.vip/i/ZJw337",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005006,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MVD",
    "name": "Metavault DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x788b6d2b37aa51d916f2837ae25b05f0e61339d1",
    "decimals": 9,
    "image": "https://rango.vip/i/oT4pKI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.67,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BERNX",
    "name": "Backed ERNX   Bond",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f95aa88ddbb7d9d484aa3d482bf0a80009c52c9",
    "decimals": 18,
    "image": "https://rango.vip/i/tonSOW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QLT",
    "name": "Quantland",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0173661769325565d4f011b2e5cda688689cc87c",
    "decimals": 9,
    "image": "https://rango.vip/i/wdLepb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 14.3,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RHO",
    "name": "Rho",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f3cd642e81d030d7b514a2ab5e3a5536beb90ec",
    "decimals": 18,
    "image": "https://rango.vip/i/RqoCNQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.38,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOTTIE",
    "name": "Hottie Froggie",
    "isPopular": false,
    "chainId": "1",
    "address": "0x489d79959e6ad1e3fef7c939a2d889deff1668a8",
    "decimals": 18,
    "image": "https://rango.vip/i/bTvJSL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014842,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGE69",
    "name": "Doge69",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf71a2079566c87e26bcd9766d140d5bfdeeae731",
    "decimals": 9,
    "image": "https://rango.vip/i/n4Rb9e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.9508e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLEXUSD",
    "name": "flexUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa774ffb4af6b0a91331c084e1aebae6ad535e6f3",
    "decimals": 18,
    "image": "https://rango.vip/i/4dH7Wz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.131156,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STFLIP",
    "name": "Thunderhead Staked FLIP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x961d4921e1718e633bac8ded88c4a1cae44b785a",
    "decimals": 18,
    "image": "https://rango.vip/i/pMPpzX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HTL",
    "name": "Hotelium",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6247c86b016bc4d9ae141849c0a9eb38c004b742",
    "decimals": 18,
    "image": "https://rango.vip/i/zH8Ulo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.28,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$VPAD",
    "name": "VEROPAD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x33cd63136d2f04826a9170b2ed6e09b6c9fb38a5",
    "decimals": 9,
    "image": "https://rango.vip/i/UZerWI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006089,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KIWI",
    "name": "KIWI DEPLOYER BOT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f59bfdd1d95a3736d2e219f905ae9b2e69c6638",
    "decimals": 9,
    "image": "https://rango.vip/i/cwb5OK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.77996e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYDOGE",
    "name": "Cyberdoge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6dc6a27822ae2ca3a47da39a2f2bbd525dd693f8",
    "decimals": 18,
    "image": "https://rango.vip/i/amZZEY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01431635,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNQT",
    "name": "Unique Utility",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa80f2c8f61c56546001f5fc2eb8d6e4e72c45d4c",
    "decimals": 18,
    "image": "https://rango.vip/i/MwJY6b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00195213,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WPCI",
    "name": "Wrapped Paycoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c2a309d9005433c1bc2c92ef1be06489e5bf258",
    "decimals": 8,
    "image": "https://rango.vip/i/EVIXJv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.087572,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0X0",
    "name": "0x0 ai  AI Smart Contract",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5a3e6a77ba2f983ec0d371ea3b475f8bc0811ad5",
    "decimals": 9,
    "image": "https://rango.vip/i/psPHVD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.111929,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUU",
    "name": "MUU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x849c479d7a90eb378dbd00e8f166371176244eb1",
    "decimals": 9,
    "image": "https://rango.vip/i/cX8F2r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.4266e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RESET",
    "name": "MetaReset",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb70eaf5d316192881aac8786c90b7907b83f02e8",
    "decimals": 18,
    "image": "https://rango.vip/i/SztDBo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012283,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIRYO-INU",
    "name": "Shiryo-Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e2f15302b90edde696593607b6bd444b64e8f02",
    "decimals": 9,
    "image": "https://rango.vip/i/6wEuaH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.202e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FROGEX",
    "name": "FrogeX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5fa54fddf1870c344dbfabb37dfab8700ec0def1",
    "decimals": 9,
    "image": "https://rango.vip/i/9WfSF6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.91844e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PETH",
    "name": "JPEG’d ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x821a278dfff762c76410264303f25bf42e195c0c",
    "decimals": 18,
    "image": "https://rango.vip/i/8q37Ko",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2331.93,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUDIT",
    "name": "SmartAudit AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa318a1277e26dfcdf6f4095b90d137743d900e61",
    "decimals": 18,
    "image": "https://rango.vip/i/jaDiDc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00160432,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FERC",
    "name": "FairERC20",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2ecba91da63c29ea80fbe7b52632ca2d1f8e5be0",
    "decimals": 18,
    "image": "https://rango.vip/i/CaUC9H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01059742,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUIDL",
    "name": "BlackRock USD Institutional Digital Liquidity Fund",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7712c34205737192402172409a8f7ccef8aa2aec",
    "decimals": 6,
    "image": "https://rango.vip/i/QVQfJd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHADOWCATS",
    "name": "Shadowcats",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0018d5e01e53878f90feab02f1b2019a21adf8b1",
    "decimals": 18,
    "image": "https://rango.vip/i/JM5oZy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.065575,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CDS",
    "name": "Capital DAO Starter",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c48ca59bf2699e51d4974d4b6d284ae52076e5e",
    "decimals": 18,
    "image": "https://rango.vip/i/qHgGV7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00084615,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GTROK",
    "name": "GTROK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd78cb66b3affd27569782737fa5b842277e1add7",
    "decimals": 9,
    "image": "https://rango.vip/i/ghZwX8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.385e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOLO",
    "name": "Alpha Impact",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2a63a5dd36c91ec2da59b188ff047f66fac122a",
    "decimals": 18,
    "image": "https://rango.vip/i/WyewrR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0043948,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LISA",
    "name": "Mona Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb94acdf8662cd955f137e0c9c9fba535c87b57b4",
    "decimals": 18,
    "image": "https://rango.vip/i/z11FDo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.408e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BNS",
    "name": "BNS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19e2a43fbbc643c3b2d9667d858d49cad17bc2b5",
    "decimals": 8,
    "image": "https://rango.vip/i/SrWp9g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000965,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCCN",
    "name": "Succession",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdfddf7a69716124bc346ba556d4b9f9e74c4a8bc",
    "decimals": 18,
    "image": "https://rango.vip/i/7uV3Ko",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00296156,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOPE",
    "name": "HOPE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc353bf07405304aeab75f4c2fac7e88d6a68f98e",
    "decimals": 18,
    "image": "https://rango.vip/i/YDKCxR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.520405,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MRETH",
    "name": "Eigenpie rETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd05728038681bcc79b2d5aeb4d9b002e66c93a40",
    "decimals": 18,
    "image": "https://rango.vip/i/EM9OeN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPCH",
    "name": "Opticash",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4d4d883f920f7c0c36a1be71a02aa0cde2aa22d1",
    "decimals": 18,
    "image": "https://rango.vip/i/LnudjO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WIS",
    "name": "Experty Wisdom",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdecade1c6bf2cd9fb89afad73e4a519c867adcf5",
    "decimals": 18,
    "image": "https://rango.vip/i/2p0MUJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00049376,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USD0++",
    "name": "USD0 Liquid Bond",
    "isPopular": false,
    "chainId": "1",
    "address": "0x35d8949372d46b7a3d5a56006ae77b215fc69bc0",
    "decimals": 18,
    "image": "https://rango.vip/i/eKEWlm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APUFF",
    "name": "Airpuff",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2be056e595110b30ddd5eaf674bdac54615307d9",
    "decimals": 18,
    "image": "https://rango.vip/i/a8g0ni",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00495848,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LMT",
    "name": "Lympo Market",
    "isPopular": false,
    "chainId": "1",
    "address": "0x327673ae6b33bd3d90f0096870059994f30dc8af",
    "decimals": 18,
    "image": "https://rango.vip/i/SLCvxV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00039209,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IBEUR",
    "name": "Iron Bank EUR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x96e61422b6a9ba0e068b6c5add4ffabc6a4aae27",
    "decimals": 18,
    "image": "https://rango.vip/i/EvlBWE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.006,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EXNT",
    "name": "ExNetwork",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd6c67b93a7b248df608a653d82a100556144c5da",
    "decimals": 16,
    "image": "https://rango.vip/i/SS9mLE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00610053,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BMT",
    "name": "BMCHAIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf028adee51533b1b47beaa890feb54a457f51e89",
    "decimals": 18,
    "image": "https://rango.vip/i/YzfLau",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00088888,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SGTV2",
    "name": "SharedStake Governance v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24c19f7101c1731b85f1127eaa0407732e36ecdd",
    "decimals": 18,
    "image": "https://rango.vip/i/N2kjH3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02962702,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CONI",
    "name": "Coniun",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4561de8e0c2bba725d38d266ef62426e62678d82",
    "decimals": 18,
    "image": "https://rango.vip/i/5Xvsg8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00351147,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SENT",
    "name": "Sentiment Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    "decimals": 18,
    "image": "https://rango.vip/i/UzKsw0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00022149,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GLG",
    "name": "Gilgeous",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc57f1d079c862b70aa12faab19293f827187aaf6",
    "decimals": 18,
    "image": "https://rango.vip/i/D5gOz6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.13235e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DETO",
    "name": "Delta Exchange",
    "isPopular": false,
    "chainId": "1",
    "address": "0xab93df617f51e1e415b5b4f8111f122d6b48e55c",
    "decimals": 18,
    "image": "https://rango.vip/i/7wiK6C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02204371,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GFX",
    "name": "GamyFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65ad6a2288b2dd23e466226397c8f5d1794e58fc",
    "decimals": 18,
    "image": "https://rango.vip/i/3vvLkZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03160728,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEEZNUTS",
    "name": "DEEZ NUTS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x84dad4e4a4d1510052d39e916330372db8cd1238",
    "decimals": 18,
    "image": "https://rango.vip/i/en9r2t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00114,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOPS",
    "name": "Mops",
    "isPopular": false,
    "chainId": "1",
    "address": "0x602f65bb8b8098ad804e99db6760fd36208cd967",
    "decimals": 18,
    "image": "https://rango.vip/i/2yNXff",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.2921e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLUESPARROW",
    "name": "BlueSparrowToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24ccedebf841544c9e6a62af4e8c2fa6e5a46fde",
    "decimals": 9,
    "image": "https://rango.vip/i/ZZxXI6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03729056,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XYN",
    "name": "Exynos Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa6ed258e3126be568a5f53ade7b6a1e478e5ef98",
    "decimals": 18,
    "image": "https://rango.vip/i/biCmuL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001201,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLUESPARROW",
    "name": "BlueSparrowToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4d67edef87a5ff910954899f4e5a0aaf107afd42",
    "decimals": 9,
    "image": "https://rango.vip/i/kKVUWk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.985e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CKU",
    "name": "Cryptoku",
    "isPopular": false,
    "chainId": "1",
    "address": "0x08f7be99ed83369541501d60f4e66f8e34c3f736",
    "decimals": 18,
    "image": "https://rango.vip/i/GpILhV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04503395,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PITCHFXS",
    "name": "Pitch FXS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x11ebe21e9d7bf541a18e1e3ac94939018ce88f0b",
    "decimals": 18,
    "image": "https://rango.vip/i/dj20UD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.47,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YETH",
    "name": "Yearn Ether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1bed97cbc3c24a4fb5c069c6e311a967386131f7",
    "decimals": 18,
    "image": "https://rango.vip/i/0E19jm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2393.44,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BGAI",
    "name": "Bitgrok AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x46b2a4075420bdb6fe643075748487b27f354390",
    "decimals": 18,
    "image": "https://rango.vip/i/xpaMiC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007684,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AICB",
    "name": "AICB",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa97cd1c5bcee878496fadb4edee081d05239051f",
    "decimals": 18,
    "image": "https://rango.vip/i/kyvQ2Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003463,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CATT",
    "name": "Catex",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e605c269e0c92e70beeb85486f1fc550f9380bd",
    "decimals": 18,
    "image": "https://rango.vip/i/8InSN0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005098,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRETH",
    "name": "frETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb4bd4628e6efb0cb521d9ec35050c75840320374",
    "decimals": 18,
    "image": "https://rango.vip/i/xOK0IQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WMLX",
    "name": "Wrapped Millix",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77d0cb0ab54f9e74b9405a5b3f60da06a78f1aad",
    "decimals": 0,
    "image": "https://rango.vip/i/0BEEap",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.076084,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MXNT",
    "name": "Mexican Peso Tether",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed03ed872159e199065401b6d0d487d78d9464aa",
    "decimals": 6,
    "image": "https://rango.vip/i/snG5wU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.050083,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VTRADING",
    "name": "Vtrading",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69cade383df52ec02562869da8aa146be08c5c3c",
    "decimals": 18,
    "image": "https://rango.vip/i/F2DLTx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02640532,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AC",
    "name": "ARTCOIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1250b98cbde9f99f4c42dcdacee193221f17eb50",
    "decimals": 18,
    "image": "https://rango.vip/i/2bFPYF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.132523,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MMETH",
    "name": "Eigenpie mETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a053350ca5f9352a16ded26ab333e2d251dad7c",
    "decimals": 18,
    "image": "https://rango.vip/i/4rW0VH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GST-ETH",
    "name": "STEPN Green Satoshi Token on ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x473037de59cf9484632f4a27b509cfe8d4a31404",
    "decimals": 8,
    "image": "https://rango.vip/i/sKq3Fa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01407444,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PULSE",
    "name": "PULSE AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc7d16b1e7c54f35a67af95d5a6eecaec27b2a62",
    "decimals": 18,
    "image": "https://rango.vip/i/TEEmkQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001594,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RISITA",
    "name": "Risitas  OLD ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe6f47303032a09c8c0f8ebb713c00e6ed345e8c3",
    "decimals": 18,
    "image": "https://rango.vip/i/K4fOoi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000187,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DCNT",
    "name": "Decanect",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4ce4c025692b3142dbde1cd432ef55b9a8d18701",
    "decimals": 9,
    "image": "https://rango.vip/i/Vn0uIS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0003152,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EGRN",
    "name": "Energreen",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb8d6d3ac21e4efe3675bbb18514010ac9c5558f",
    "decimals": 18,
    "image": "https://rango.vip/i/MerB9J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00094318,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUBT",
    "name": "CubToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbf9e72eeb5adb8b558334c8672950b7a379d4266",
    "decimals": 18,
    "image": "https://rango.vip/i/eJWsJv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.543e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPILLWAYS",
    "name": "Spillways",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8790f2fc7ca2e7db841307fb3f4e72a03baf7b47",
    "decimals": 18,
    "image": "https://rango.vip/i/xgLfN9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00031751,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MSFRXETH",
    "name": "Eigenpie frxETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x879054273cb2dad631980fa4efe6d25eefe08aa4",
    "decimals": 18,
    "image": "https://rango.vip/i/mzf98I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COF",
    "name": "Cryptoforce",
    "isPopular": false,
    "chainId": "1",
    "address": "0x230f5ed78a45452f726365b8ad1d6866f5faa68f",
    "decimals": 9,
    "image": "https://rango.vip/i/op2KQP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000385,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MWBETH",
    "name": "Eigenpie wBETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe46a5e19b19711332e33f33c2db3ea143e86bc10",
    "decimals": 18,
    "image": "https://rango.vip/i/cOhy6s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNHT",
    "name": "CNH Tether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e109e9dd7fa1a58bc3eff667e8e41fc3cc07aef",
    "decimals": 6,
    "image": "https://rango.vip/i/oaAERK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.140902,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLACK",
    "name": "Flack Exchange",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf31120603a27a16314effc37a3f32a42028310af",
    "decimals": 18,
    "image": "https://rango.vip/i/m4hqlX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01369544,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPENAIERC",
    "name": "OpenAI ERC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a6aa13393b7d1100c00a57c76c39e8b6c835041",
    "decimals": 9,
    "image": "https://rango.vip/i/DsUuZe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00364058,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GLTM",
    "name": "Golteum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x556d4f40982cb95e0714989e0c229c42be8b1499",
    "decimals": 18,
    "image": "https://rango.vip/i/BNHj9S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YCRV",
    "name": "Yearn CRV",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfcc5c47be19d06bf83eb04298b026f81069ff65b",
    "decimals": 18,
    "image": "https://rango.vip/i/ELjv4Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.262941,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LP-YCRV",
    "name": "LP Yearn CRV Vault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc97232527b62efb0d8ed38cf3ea103a6cca4037e",
    "decimals": 18,
    "image": "https://rango.vip/i/Gk7ghX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.2,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANARCHY",
    "name": "Anarchy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53fd2342b43ecd24aef1535bc3797f509616ce8c",
    "decimals": 9,
    "image": "https://rango.vip/i/4GEuRF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01319209,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MILADY",
    "name": "Milady Vault  NFTX ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x227c7df69d3ed1ae7574a1a7685fded90292eb48",
    "decimals": 18,
    "image": "https://rango.vip/i/bxF6mD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6998.28,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CARB",
    "name": "Carbon Crates",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1bc71108e898586068caba9ee09397a2d275c092",
    "decimals": 18,
    "image": "https://rango.vip/i/nLDYGk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 12.39,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATEC",
    "name": "AnonTech",
    "isPopular": false,
    "chainId": "1",
    "address": "0x49c8efd98ac8114de2fce73d57e2944aebd5613d",
    "decimals": 9,
    "image": "https://rango.vip/i/WomOjT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010802,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRVRENWSBTC",
    "name": "Curve fi renBTC wBTC sBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x075b1bb99792c9e1041ba13afef80c91a1e70fb3",
    "decimals": 18,
    "image": "https://rango.vip/i/Pf2mG8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 56885,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ECT",
    "name": "Echain Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda4dd9586d27202a338843dd6b9824d267006783",
    "decimals": 9,
    "image": "https://rango.vip/i/d7Yu7g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOWIE",
    "name": "Bowie",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcae0dd4bda7ff3e700355c7629b24d5d728bd2ce",
    "decimals": 18,
    "image": "https://rango.vip/i/QuMre0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001062,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PHONON",
    "name": "Phonon DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x758b4684be769e92eefea93f60dda0181ea303ec",
    "decimals": 18,
    "image": "https://rango.vip/i/iv6qjE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015097,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PSM",
    "name": "Possum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb69b067d9d8d6dd1209fe4557c43586e54f9045",
    "decimals": 18,
    "image": "https://rango.vip/i/2UT1oZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00026578,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WXX",
    "name": "Wrapped XX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x171120219d3223e008558654ec3254a0f206edb2",
    "decimals": 9,
    "image": "https://rango.vip/i/6dssd0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04959276,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAOG",
    "name": "DAOGAME",
    "isPopular": false,
    "chainId": "1",
    "address": "0x682f3317a8db21ba205dc84a0b7bd5010333bda7",
    "decimals": 18,
    "image": "https://rango.vip/i/eZ7Eoa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00204461,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHIKUN",
    "name": "Arise Chikun",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9a7703338730b82a803ba050df55f9b3959f3fb2",
    "decimals": 9,
    "image": "https://rango.vip/i/Udd6eX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005217,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DKUMA",
    "name": "KumaDex Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f5dd1a1538a4f9f82e543098f01f22480b0a3a8",
    "decimals": 18,
    "image": "https://rango.vip/i/oxaZz5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00022817,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XYXYX",
    "name": "Xyxyx",
    "isPopular": false,
    "chainId": "1",
    "address": "0x382e57ca8e4c4db9649884ca77b0a355692d14ac",
    "decimals": 18,
    "image": "https://rango.vip/i/par0FX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1103.53,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FIDU",
    "name": "Fidu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a445e9f40e0b97c92d0b8a3366cef1d67f700bf",
    "decimals": 18,
    "image": "https://rango.vip/i/14aeW6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.641691,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLUSD",
    "name": "Boosted LUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb9d7dddca9a4ac480991865efef82e01273f79c3",
    "decimals": 18,
    "image": "https://rango.vip/i/48SYfl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.26,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOONG",
    "name": "PLUMPY DRAGONS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x954a75564cb355ea2d6fccc6c1212fd01fdcb06f",
    "decimals": 18,
    "image": "https://rango.vip/i/C8hkBw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008418,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZRS",
    "name": "Zaros",
    "isPopular": false,
    "chainId": "1",
    "address": "0x75e88b8c2d34a52a6d36deada664d7dc9116e4ef",
    "decimals": 18,
    "image": "https://rango.vip/i/SVWX9J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00980056,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KARCON",
    "name": "Kaarigar Connect",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44af2390d7ed22966d3e570bffb648817e4335f0",
    "decimals": 18,
    "image": "https://rango.vip/i/y5Nwtc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033477,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIB01",
    "name": "Backed IB01   Treasury Bond 0 1yr",
    "isPopular": false,
    "chainId": "1",
    "address": "0xca30c93b02514f86d5c86a6e375e3a330b435fb5",
    "decimals": 18,
    "image": "https://rango.vip/i/nfotpN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 112.3,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FDAI",
    "name": "Fluid DAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x244517dc59943e8cdfbd424bdb3262c5f04a1387",
    "decimals": 6,
    "image": "https://rango.vip/i/SPtxtu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WFIO",
    "name": "Wrapped FIO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbea269038eb75bdab47a9c04d0f5c572d94b93d5",
    "decimals": 9,
    "image": "https://rango.vip/i/MGfN7r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02548007,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETH20",
    "name": "ETH 2 0",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfbe6f37d3db3fc939f665cfe21238c11a5447831",
    "decimals": 9,
    "image": "https://rango.vip/i/hPKexR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.87135e-13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIB05",
    "name": "Half Shiba Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8eb94a06b4716093dbfe335cbdb098deb2dcde1b",
    "decimals": 18,
    "image": "https://rango.vip/i/V00KjE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04680455,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAE",
    "name": "Receive Access Ecosystem",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5a3229ccb22b6484594973a03a3851dcd948756",
    "decimals": 18,
    "image": "https://rango.vip/i/3E4kWH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.968957,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": " SHOP",
    "name": "SHOP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64b78325d7495d6d4be92f234fa3f3b8d8964b8b",
    "decimals": 18,
    "image": "https://rango.vip/i/n0HKFX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00029978,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FTUSD",
    "name": "Fluid TUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b319db00d07c8fadfaaef13c910141a5da0aa8f",
    "decimals": 18,
    "image": "https://rango.vip/i/LVniTR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUCKY",
    "name": "Maximus Lucky",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b0956258ff7bd7645aa35369b55b61b8e6d6140",
    "decimals": 8,
    "image": "https://rango.vip/i/vWKt2k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00436257,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MBASE2.0",
    "name": "Minebase 2.0",
    "isPopular": false,
    "chainId": "1",
    "address": "0x291aa47c58558adfc2bcd6f060578fdae1f6570c",
    "decimals": 18,
    "image": "https://rango.vip/i/MBR7Ta",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02694331,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YUKKY",
    "name": "YUKKY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x37f678ad6221a0fd71cda0eca19c8802f4cabf44",
    "decimals": 18,
    "image": "https://rango.vip/i/GVvoOo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02479145,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "A3A",
    "name": "3A",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f817b28da4940f018c6b5c0a11c555ebb1264f9",
    "decimals": 18,
    "image": "https://rango.vip/i/tWzCjJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00179534,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NXM",
    "name": "Nexus Mutual",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b",
    "decimals": 18,
    "image": "https://rango.vip/i/eQIqed",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 53.23,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XOGN",
    "name": "Staked OGN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x63898b3b6ef3d39332082178656e9862bee45c57",
    "decimals": 18,
    "image": "https://rango.vip/i/DT0Vwt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNI-V2",
    "name": "Uniswap V2",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe94b2ec38fa88bdc8ca9110b24deb5341ecef251",
    "decimals": 9,
    "image": "https://rango.vip/i/Cf4TqW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.087675,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GMEME",
    "name": "GoodMeme",
    "isPopular": false,
    "chainId": "1",
    "address": "0x238cefec182679c27a3035713416fa0a8198b302",
    "decimals": 18,
    "image": "https://rango.vip/i/NjjQiJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11135e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YASHA",
    "name": "YASHA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd75f1f81b69bdd4df8efbb70e9c6f4609009d753",
    "decimals": 18,
    "image": "https://rango.vip/i/XXbcq7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000137,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FDAI",
    "name": "Flux DAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe2ba8693ce7474900a045757fe0efca900f6530b",
    "decimals": 8,
    "image": "https://rango.vip/i/arpFV8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DARUMA",
    "name": "Daruma",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa888d9616c2222788fa19f05f77221a290eef704",
    "decimals": 9,
    "image": "https://rango.vip/i/1k3zJz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001864,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DECI",
    "name": "Maximus DECI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b32022693210cd2cfc466b9ac0085de8fc34ea6",
    "decimals": 8,
    "image": "https://rango.vip/i/4FUC20",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00118118,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$MECHA",
    "name": "Mechanium",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc5bcc8ba3f33ab0d64f3473e861bdc0685b19ef5",
    "decimals": 18,
    "image": "https://rango.vip/i/drwifA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01655273,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MADAI",
    "name": "Morpho Aave Dai Stablecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36f8d0d0573ae92326827c4a82fe4ce4c244cab6",
    "decimals": 18,
    "image": "https://rango.vip/i/pA6N7Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STLINK",
    "name": "Staked LINK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8b295df2cd735b15be5eb419517aa626fc43cd5",
    "decimals": 18,
    "image": "https://rango.vip/i/wnurAD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.92,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAUSDC",
    "name": "Morpho Aave USD Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa5269a8e31b93ff27b887b56720a25f844db0529",
    "decimals": 18,
    "image": "https://rango.vip/i/tRo7xO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TUPELO",
    "name": "tupelothedog",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb15a5aab2a65745314fcd0d7f5080bfa65bd7c03",
    "decimals": 9,
    "image": "https://rango.vip/i/BlPMyp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006172,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMMX",
    "name": "AMMX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc881255e4d639b42e326158c7b8ccb7f33459261",
    "decimals": 18,
    "image": "https://rango.vip/i/nkJeyy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016827,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WELLE",
    "name": "Welle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1376a81fe3ee7d0e431f1ac24286b00f3ccf44e7",
    "decimals": 18,
    "image": "https://rango.vip/i/IvvL6E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0010118,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EGGS",
    "name": "Eggs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e516ba5bf3b7ee47fb99b09eadb60bde80a82e0",
    "decimals": 18,
    "image": "https://rango.vip/i/H012JS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.5732e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOLD1",
    "name": "Teh Golden One",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0ec72cd6690db40b16be166858299f19d4f8e5b0",
    "decimals": 9,
    "image": "https://rango.vip/i/3f20z5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001677,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROO",
    "name": "Lucky Roo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d7107c8e30617cadc11f9692a19c82ae8bba938",
    "decimals": 18,
    "image": "https://rango.vip/i/MTQk8f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.4633e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WDAG",
    "name": "Wrapped DAG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e3cfe45e3ee7c017277f22e35d2f29edc99d570",
    "decimals": 8,
    "image": "https://rango.vip/i/OleU7b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02510069,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "METAMEME",
    "name": "met a meta metameme",
    "isPopular": false,
    "chainId": "1",
    "address": "0x23a2164d482fd2fec9c2d0b66528d42fee7b8817",
    "decimals": 9,
    "image": "https://rango.vip/i/YkNGQo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.7148e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIUNDEAD",
    "name": "hiUNDEAD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70d0ff0d3b3f5e69220c09befc70606fa5f89293",
    "decimals": 18,
    "image": "https://rango.vip/i/3htwyN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017669,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEJITARU SHIRUDO",
    "name": "SHIELD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcf4c68db4c2fa0bf58df07b14f45ce7709a716ac",
    "decimals": 18,
    "image": "https://rango.vip/i/ymOfq9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006285,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EARTH",
    "name": "PaleBlueDot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbf5badfae2d219943dcd9652d1ce65960b8a1e0c",
    "decimals": 18,
    "image": "https://rango.vip/i/rsT5Il",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 16768.25,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDUSD",
    "name": "Davos Protocol Staked DUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c91d9482c4802315e617267bb3ef50c0aa15c41",
    "decimals": 18,
    "image": "https://rango.vip/i/yEGIoe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RPGMAI",
    "name": "RPG Maker Ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x96362879529c15c484eabc861c435940e7af22bb",
    "decimals": 18,
    "image": "https://rango.vip/i/mesqCd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00023589,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOGT",
    "name": "Lord of Dragons",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba58444c8050ed9385b7417533a73644036d21eb",
    "decimals": 18,
    "image": "https://rango.vip/i/PZOOtp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00101064,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$ADS",
    "name": "Alkimi Exchange",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3106a0a076bedae847652f42ef07fd58589e001f",
    "decimals": 18,
    "image": "https://rango.vip/i/i1nqgl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.148114,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BYPASS",
    "name": "Bypass",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38cf6cea814aefd01027a0bbf8a78b7aa95a698e",
    "decimals": 9,
    "image": "https://rango.vip/i/fA6IAJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02367985,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YNLSDE",
    "name": "YieldNest Restaked LSD   Eigenlayer",
    "isPopular": false,
    "chainId": "1",
    "address": "0x35ec69a77b79c255e5d47d5a3bdbefefe342630c",
    "decimals": 18,
    "image": "https://rango.vip/i/DesOVx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESLBR",
    "name": "Escrowed LBR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x571042b7138ee957a96a6820fce79c48fe2da816",
    "decimals": 18,
    "image": "https://rango.vip/i/NUWfB4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELO",
    "name": "Elosys",
    "isPopular": false,
    "chainId": "1",
    "address": "0x61b34a012646cd7357f58ee9c0160c6d0021fa41",
    "decimals": 18,
    "image": "https://rango.vip/i/9cHpjh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0107259,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MESLBR",
    "name": "Match Finance esLBR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0af0e83d064f160376303ac67dd9a7971af88d4c",
    "decimals": 18,
    "image": "https://rango.vip/i/9vzNOa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALPHABET",
    "name": "Alphabet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x038ed1383763d704d4271fe856ac96b4557e9d06",
    "decimals": 18,
    "image": "https://rango.vip/i/ZobgRv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 29.71,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOONS",
    "name": "City Boys",
    "isPopular": false,
    "chainId": "1",
    "address": "0x792833b894775bd769b3c602ba7172e59a83ab3f",
    "decimals": 18,
    "image": "https://rango.vip/i/5rFu9U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001663,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ERR",
    "name": "Error 404",
    "isPopular": false,
    "chainId": "1",
    "address": "0x893c47bc1ff55c2269236ac7a4288681532161e0",
    "decimals": 18,
    "image": "https://rango.vip/i/6drJZ8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02514861,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAVOC",
    "name": "havoc",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f94b198ce85c19a846c2b1a4d523f40a747a850",
    "decimals": 9,
    "image": "https://rango.vip/i/EJnpDA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.35076e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEZUKO",
    "name": "Nezuko",
    "isPopular": false,
    "chainId": "1",
    "address": "0x049715c70fdbdd2be4814f76a53dc3d6f4367756",
    "decimals": 18,
    "image": "https://rango.vip/i/uifQo3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.83837e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORMIT",
    "name": "ORMIT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1d2d925887f442a27b5393bd5dfbe059910b1ac0",
    "decimals": 18,
    "image": "https://rango.vip/i/7Jr1GL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002875,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BISC",
    "name": "Bidao Smart Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4295c8556afee00264c0789dde2ddd2dba71acfe",
    "decimals": 18,
    "image": "https://rango.vip/i/oKrVQ6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008971,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TEXAN",
    "name": "Texan",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcfcffe432a48db53f59c301422d2edd77b2a88d7",
    "decimals": 18,
    "image": "https://rango.vip/i/9E88Su",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000111,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BULLET",
    "name": "Bullet Gate Betting Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ef32a03784c8fd63bbf027251b9620865bd54b6",
    "decimals": 8,
    "image": "https://rango.vip/i/qIiTxH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01340001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNTE",
    "name": "Mintera",
    "isPopular": false,
    "chainId": "1",
    "address": "0x94d40b49f020bfebba1a80a0191eb3737b90e8d3",
    "decimals": 18,
    "image": "https://rango.vip/i/XL7t88",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVAAVE",
    "name": "Aave yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd9788f3931ede4d5018184e198699dc6d66c1915",
    "decimals": 18,
    "image": "https://rango.vip/i/FrQq1i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 132.3,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVCOMP",
    "name": "COMP yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4a3fe75762017db0ed73a71c9a06db7768db5e66",
    "decimals": 18,
    "image": "https://rango.vip/i/6hZ1nz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 48.44,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVYFI",
    "name": "YFI yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb25ca703181e7484a155dd612b06f57e12be5f0",
    "decimals": 18,
    "image": "https://rango.vip/i/3WIZx3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4907.06,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ST-YCRV",
    "name": "Staked Yearn CRV Vault",
    "isPopular": false,
    "chainId": "1",
    "address": "0x27b5739e22ad9033bcbf192059122d163b60349d",
    "decimals": 18,
    "image": "https://rango.vip/i/pHEIUG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.491594,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RUNIX",
    "name": "THE RUNIX TOKEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf6ccfd6ef2850e84b73adeace9a075526c5910d4",
    "decimals": 18,
    "image": "https://rango.vip/i/2nVtiP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016642,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVUSDC",
    "name": "USDC yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa354f35829ae975e850e23e9615b11da1b3dc4de",
    "decimals": 6,
    "image": "https://rango.vip/i/qubhZ2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.1,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVUSDT",
    "name": "USDT yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b27f92c0e212c671ea351827edf93db27cc0c65",
    "decimals": 6,
    "image": "https://rango.vip/i/Ga1Ikv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.073,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YVUNI",
    "name": "UNI yVault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfbeb78a723b8087fd2ea7ef1afec93d35e8bed42",
    "decimals": 18,
    "image": "https://rango.vip/i/8T9NwI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.29,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$MNB",
    "name": "Mineable",
    "isPopular": false,
    "chainId": "1",
    "address": "0x42b91f1d05afea671a2da3c780eda2abf0a2a366",
    "decimals": 18,
    "image": "https://rango.vip/i/NVq2VK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018111,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAII",
    "name": "DAII",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1981e32c2154936741ab6541a737b87c68f13ce1",
    "decimals": 18,
    "image": "https://rango.vip/i/DArlgh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.993545,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGPAD",
    "name": "DogPad Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6f3277ad0782a7da3eb676b85a8346a100bf9c1c",
    "decimals": 18,
    "image": "https://rango.vip/i/wUxpdp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.6125e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCHA",
    "name": "ArchAngel",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36e43065e977bc72cb86dbd8405fae7057cdc7fd",
    "decimals": 9,
    "image": "https://rango.vip/i/SOWoAH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.227e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEVETH",
    "name": "mevETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24ae2da0f361aa4be46b48eb19c91e02c5e4f27e",
    "decimals": 18,
    "image": "https://rango.vip/i/BCEEig",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2502.78,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KSETH",
    "name": "Kernel Staked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x513d27c94c0d81eed9dc2a88b4531a69993187cf",
    "decimals": 18,
    "image": "https://rango.vip/i/pErzoh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2522.52,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DCO",
    "name": "DCOMY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a304fda5a85182dca1d03741bb2f07881b9e095",
    "decimals": 8,
    "image": "https://rango.vip/i/eCb2J2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SENAI",
    "name": "Sentinel AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8070dee687fc754b55101374e3ca6e4fd024a39e",
    "decimals": 9,
    "image": "https://rango.vip/i/wJ0mVI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001341,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KUSD",
    "name": "Kernel USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0bb9ab78aaf7179b7515e6753d89822b91e670c4",
    "decimals": 18,
    "image": "https://rango.vip/i/tladGw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999071,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COCO",
    "name": "0xCoco",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb50350ab555ed5d56265e096288536e8cac41eb",
    "decimals": 18,
    "image": "https://rango.vip/i/zUTvwM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00053885,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BONK20",
    "name": "Bonk2 0",
    "isPopular": false,
    "chainId": "1",
    "address": "0x043312456f73d8014d9b84f4337de54995cd2a5b",
    "decimals": 9,
    "image": "https://rango.vip/i/yQiEvp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.194e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JOVJOU",
    "name": "JovJou",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7e5981c2e072f53a0323d3d80baca3e31fb1550c",
    "decimals": 18,
    "image": "https://rango.vip/i/exPqSk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01009034,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELEV",
    "name": "Elevate Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x045109cf1be9edec048aa0b3d7a323154a1aea65",
    "decimals": 18,
    "image": "https://rango.vip/i/jwrNzC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001105,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNBNK",
    "name": "Unbanked",
    "isPopular": false,
    "chainId": "1",
    "address": "0x06b884e60794ce02aafab13791b59a2e6a07442f",
    "decimals": 18,
    "image": "https://rango.vip/i/fG1oMH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004278,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DETH",
    "name": "Stakehouse dETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d1e5cf16077f349e999d6b21a4f646e83cd90c5",
    "decimals": 18,
    "image": "https://rango.vip/i/GIl83N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 970.81,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIBOT",
    "name": "Aibot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf1fb4cf949277adc3f89c0d6091100789033b7b5",
    "decimals": 18,
    "image": "https://rango.vip/i/KY9UZd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.055883,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAGER",
    "name": "WagerOn",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97c8321b9434db4110b1d75a551e561036ff2e6a",
    "decimals": 18,
    "image": "https://rango.vip/i/bHUZBH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010224,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PARTY",
    "name": "Maximus Pool Party",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4581af35199bbde87a89941220e04e27ce4b0099",
    "decimals": 18,
    "image": "https://rango.vip/i/dDadwV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00368494,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PUBLX",
    "name": "PUBLC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a6658f40e51b372e593b7d2144c1402d5cf33e8",
    "decimals": 18,
    "image": "https://rango.vip/i/XHrB59",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00068105,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALCA",
    "name": "AliceNet",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb556b0ee2cbd89ed95ddea881477723a3aa8f8b",
    "decimals": 18,
    "image": "https://rango.vip/i/sEyRVm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00976012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KABO",
    "name": "KaboChan",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c5cb676e38d6973837b9496f6524835208145a2",
    "decimals": 18,
    "image": "https://rango.vip/i/wHA2l9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000663,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "4096",
    "name": "4096",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4096fc7119040175589387656f7c6073265f4096",
    "decimals": 0,
    "image": "https://rango.vip/i/QlltEq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 19.29,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLUT",
    "name": "Flute",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4f08705fb8f33affc231ed66e626b40e84a71870",
    "decimals": 11,
    "image": "https://rango.vip/i/zUOsxa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014647,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SSHIP",
    "name": "StarShip ERC20",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc1ecfaf43c53bec9b9143ab274f35603fd10b886",
    "decimals": 18,
    "image": "https://rango.vip/i/WlGYMT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003393,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EBIT",
    "name": "eBit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4f14ba78a51925ee934c373a2cf56b2d8da63f7f",
    "decimals": 18,
    "image": "https://rango.vip/i/9SgXmD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00689758,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COREDAO",
    "name": "coreDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf66cd2f8755a21d3c8683a10269f795c0532dd58",
    "decimals": 18,
    "image": "https://rango.vip/i/5Bii3I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.944317,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BARK",
    "name": "Bark",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53340a1ef3a0ddeba1d94bbd1e2ff55936f0ea60",
    "decimals": 18,
    "image": "https://rango.vip/i/D4v1UW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000107,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SLUGLORD",
    "name": "    G O   ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5791254f5d7a4d7ce4dda0391ce15812b65ac2a2",
    "decimals": 9,
    "image": "https://rango.vip/i/3jJEvO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.1505e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RSB",
    "name": "RuneStone Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d0fe0db46ec0017a89478e98c9ff86109ad4673",
    "decimals": 18,
    "image": "https://rango.vip/i/UCHZBl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04499954,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HYUSD",
    "name": "High Yield USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xacdf0dba4b9839b96221a8487e9ca660a48212be",
    "decimals": 18,
    "image": "https://rango.vip/i/EHp7lY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.055,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKT",
    "name": "ZkTsunami",
    "isPopular": false,
    "chainId": "1",
    "address": "0x76fca1adb104770b38581b64d55e67fa5a0f3966",
    "decimals": 9,
    "image": "https://rango.vip/i/ATjGRT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00972793,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIBAKC",
    "name": "hiBAKC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b0b09f5b14f6d50e6672ae158f9d71893feca18",
    "decimals": 18,
    "image": "https://rango.vip/i/hLWZR5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00073994,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RBOT",
    "name": "RunesBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x722383e1e8994cb8a55cbc1621dc068b62403b1e",
    "decimals": 18,
    "image": "https://rango.vip/i/B3sst4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01509213,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPES",
    "name": "McPepe s",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6bf765c43030387a983f429c1438e9d2025b7e12",
    "decimals": 18,
    "image": "https://rango.vip/i/Qls0y8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001045,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALEA",
    "name": "Alea",
    "isPopular": false,
    "chainId": "1",
    "address": "0x24bff4fe25b5807bad49b2c08d79bb271766e68a",
    "decimals": 18,
    "image": "https://rango.vip/i/riGD9R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.48,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCAI",
    "name": "Archive AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5c8190b76e90b4dd0702740cf6eb0f7ee01ab5e9",
    "decimals": 9,
    "image": "https://rango.vip/i/0BITmw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00375331,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IM",
    "name": "Internet Money  ETH ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a58153a0cd1cfaea94ce1f7fdc5d7e679eca936",
    "decimals": 18,
    "image": "https://rango.vip/i/dalgu9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.191e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THUSD",
    "name": "Threshold USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcfc5bd99915aaa815401c5a41a927ab7a38d29cf",
    "decimals": 18,
    "image": "https://rango.vip/i/mxJwcS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.994869,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORBN",
    "name": "Orbeon Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa7fbd9254f10f8e20a31a593c9e8bc0d041e15f6",
    "decimals": 9,
    "image": "https://rango.vip/i/RepHiH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00023952,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CCB",
    "name": " CCB    ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e81d527f8fa05d82c514401c8144275174557cd",
    "decimals": 18,
    "image": "https://rango.vip/i/PN9F0p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001366,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COOMER",
    "name": "COOMER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x494930dabcfa57748a4c4788c0054f723a789047",
    "decimals": 9,
    "image": "https://rango.vip/i/KOmjeC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.20667e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPTIG",
    "name": "Catgirl Optimus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd5df655087d99b7b720a5bc8711f296180a4f44b",
    "decimals": 18,
    "image": "https://rango.vip/i/Wx39sj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002593,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRENDX",
    "name": "Trend X",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc89d9aa9d9e54bb196319c6195aea1038d2bc936",
    "decimals": 18,
    "image": "https://rango.vip/i/8So8gR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00088537,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOOCH",
    "name": "Gooch",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d3d490964205c8bc8ded39e48e88e8fde45b41f",
    "decimals": 18,
    "image": "https://rango.vip/i/s4BznN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015022,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FFRAX",
    "name": "Flux FRAX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c9a2d6b33b4826757273d47ebee0e2dddcd978b",
    "decimals": 8,
    "image": "https://rango.vip/i/UKEZIa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUSDT",
    "name": "Flux USDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x81994b9607e06ab3d5cf3afff9a67374f05f27d7",
    "decimals": 8,
    "image": "https://rango.vip/i/9FR0HV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0437754,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MPEPE",
    "name": "MicroPepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4216663ddc7bd10eaf44609df4dd0f91cd2be7f2",
    "decimals": 18,
    "image": "https://rango.vip/i/O3ZdyR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004034,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WJAURA",
    "name": "Wrapped Jones AURA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x198d7387fa97a73f05b8578cdeff8f2a1f34cd1f",
    "decimals": 18,
    "image": "https://rango.vip/i/VAyEtk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.76457,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SIMPSON690",
    "name": "Simpson6900",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad6eefb4f4a6ce3e2cd2049c3104f5ce5ed082f0",
    "decimals": 9,
    "image": "https://rango.vip/i/FWxmi9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.7661e-14,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOONG",
    "name": "Loong",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcaeda9650ccd356af7776057a105f9e6ffe68213",
    "decimals": 18,
    "image": "https://rango.vip/i/apjL1W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.371e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HERE",
    "name": "SphereX Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd722424cf94b583752dfc80c08e2531ab3b762dc",
    "decimals": 18,
    "image": "https://rango.vip/i/hxRSGT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STTIA",
    "name": "Bridged stTIA  Hyperlane ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x48a612a6da945205a221e94bb9f40b0550cd2c4e",
    "decimals": 6,
    "image": "https://rango.vip/i/zxpvB4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.19006379609552,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POOLZ",
    "name": "Poolz Finance  OLD ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69a95185ee2a045cdc4bcd1b1df10710395e4e23",
    "decimals": 18,
    "image": "https://rango.vip/i/siQGed",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01416084,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FONZY",
    "name": "Fonzy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb939da54f9748440a1b279d42be1296942732288",
    "decimals": 18,
    "image": "https://rango.vip/i/BPvyiD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.52885e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAFETH",
    "name": "Simple Asymmetry ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6732efaf6f39926346bef8b821a04b6361c4f3e5",
    "decimals": 18,
    "image": "https://rango.vip/i/TFYseO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2777.63,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "Z3",
    "name": "Z Cubed",
    "isPopular": false,
    "chainId": "1",
    "address": "0x50eec6d765792dcfb0913c8403ef2a12e1b861a6",
    "decimals": 18,
    "image": "https://rango.vip/i/wr2lCe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00263534,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STANDARD",
    "name": "Stakeborg Standard",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda0c94c73d127ee191955fb46bacd7ff999b2bcd",
    "decimals": 18,
    "image": "https://rango.vip/i/KIpvJ9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01209806,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCOPEX",
    "name": "ScopexAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6fc16de36e040e0c7d1a70d039ef701516516b32",
    "decimals": 9,
    "image": "https://rango.vip/i/qiKnSW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008904,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TNT",
    "name": "Tierion Network Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x08f5a9235b08173b7569f83645d2c7fb55e8ccd8",
    "decimals": 8,
    "image": "https://rango.vip/i/CKlBge",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00260302,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEEP$",
    "name": "The Peoples Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5919dea604631016c15c805e3d948a0384879892",
    "decimals": 9,
    "image": "https://rango.vip/i/Tlz4jF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00035244,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WCT",
    "name": "WCTrades",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd488321ef57ab16b4f334558b1fc4e0213c82db1",
    "decimals": 18,
    "image": "https://rango.vip/i/4Ccqc2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000642,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPMND",
    "name": "Open Mind Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x87fd01183ba0235e1568995884a78f61081267ef",
    "decimals": 18,
    "image": "https://rango.vip/i/9gGnys",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03277229,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ODOGE",
    "name": "Ordinal Doge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68b429161ec09a6c1d65ba70727ab1faa5bc4026",
    "decimals": 18,
    "image": "https://rango.vip/i/8RIEHr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.2499e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XAIGROK",
    "name": "XAIGROK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x718dab2c7bbd2d9503dc1ce1dbc215abc41a3545",
    "decimals": 8,
    "image": "https://rango.vip/i/aQER4o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.38342e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIMBO",
    "name": "Bimbo The Dog",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0364e6c6f4f9ad90f11a39e681bd6c0156b3efdb",
    "decimals": 18,
    "image": "https://rango.vip/i/CZX103",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000966,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHECKS",
    "name": "Checks Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x049e9f5369358786a1ce6483d668d062cfe547ec",
    "decimals": 18,
    "image": "https://rango.vip/i/QqMSXj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.5687e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OGZ",
    "name": "OGzClub",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb7bda6a89e724f63572ce68fddc1a6d1d5d24bcf",
    "decimals": 18,
    "image": "https://rango.vip/i/y0JJNl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.44022e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIMAGE",
    "name": "AiMage Tools",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4872208c83acbfd7f6dea5aa6ce6d5d7aed2ac1c",
    "decimals": 18,
    "image": "https://rango.vip/i/gcJNMu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000314,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PARTY",
    "name": "PoolParty",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0fe0ed7f146cb12e4b9759aff4fa8d34571802ca",
    "decimals": 18,
    "image": "https://rango.vip/i/jUY22q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004337,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRAF",
    "name": "Graffiti",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5138ebe7acaae209d6f0b651e4d02a67ef61f436",
    "decimals": 18,
    "image": "https://rango.vip/i/0tBEyM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.054898,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAUSDT",
    "name": "Morpho Aave Tether USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xafe7131a57e44f832cb2de78ade38cad644aac2f",
    "decimals": 18,
    "image": "https://rango.vip/i/HdDlFz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MORPHO",
    "name": "Morpho",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9994e35db50125e0df82e4c2dde62496ce330999",
    "decimals": 18,
    "image": "https://rango.vip/i/KP1oXR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00166279,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTE",
    "name": "MixToEarn",
    "isPopular": false,
    "chainId": "1",
    "address": "0x159cdaf78be31e730d9e1330adfcfbb79a5fdb95",
    "decimals": 18,
    "image": "https://rango.vip/i/7pqTx0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002142,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIXL",
    "name": "PIXL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x427a03fb96d9a94a6727fbcfbba143444090dd64",
    "decimals": 18,
    "image": "https://rango.vip/i/1TDNw9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02675844,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MACRV",
    "name": "Morpho Aave Curve DAO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9dc7094530cb1bcf5442c3b9389ee386738a190c",
    "decimals": 18,
    "image": "https://rango.vip/i/P8h0DC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MRING",
    "name": "MagicRing",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd59d7d2e955533fcd21641da8a70eae9624a3c49",
    "decimals": 18,
    "image": "https://rango.vip/i/BEyJYk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.45186e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NARUTO",
    "name": "Naruto",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad8d0de33c43eefe104a279cdb6ae250c12e6214",
    "decimals": 9,
    "image": "https://rango.vip/i/j49H6k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.60524e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUR-C",
    "name": "Euro Coinvertible",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf7790914dc335b20aa19d7c9c9171e14e278a134",
    "decimals": 18,
    "image": "https://rango.vip/i/Kk3vRM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WCELL",
    "name": "Wrapped CellMates",
    "isPopular": false,
    "chainId": "1",
    "address": "0x18e5f92103d1b34623738ee79214b1659f2ee109",
    "decimals": 18,
    "image": "https://rango.vip/i/dB5BAz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 254.08,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSBC",
    "name": "WSB Classic",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe46091dce9c67691bcf22768bbee0bc9e20d4beb",
    "decimals": 9,
    "image": "https://rango.vip/i/RmLLGq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000329,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPEXL",
    "name": "PepeXL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xddd5592cf4759313c649eb4e624a79541ed222ed",
    "decimals": 18,
    "image": "https://rango.vip/i/fGu2Dn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.28993e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CREATOPY",
    "name": "Creatopy Builder",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d69a8ccb7cbc03d3dbb2c4ab03c0c1fad3e74b0",
    "decimals": 18,
    "image": "https://rango.vip/i/Bt0OHV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009701,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPEDOGE",
    "name": "Pepe Doge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8c55c80a1cb7394088a36c6b634dc2bf3c6fb67",
    "decimals": 18,
    "image": "https://rango.vip/i/hUwus0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000062,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CX",
    "name": "Crypto X",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ad02305ba9a4985390170337582986e419f1a2c",
    "decimals": 9,
    "image": "https://rango.vip/i/kGGyEY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.0591e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CBDC",
    "name": "CBDC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe07c41e9cdf7e0a7800e4bbf90d414654fd6413d",
    "decimals": 9,
    "image": "https://rango.vip/i/Lv43eJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002701,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUDDY",
    "name": "BuddyAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x98e35f5599b57998900e5e0675721c90a5499327",
    "decimals": 9,
    "image": "https://rango.vip/i/EC42ma",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.265745,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TROLL20",
    "name": "TROLL 2 0",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f5fa8adf5f09a5f9de05b65fe82a404913f02c4",
    "decimals": 18,
    "image": "https://rango.vip/i/JPikrO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.628e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEKO",
    "name": "Keko",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c7f442aab99d5e18cfae2291c507c0b5f3c1eb5",
    "decimals": 18,
    "image": "https://rango.vip/i/a5sN5Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00081354,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KETAMINE",
    "name": "Ketamine",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa4fc78495d545fd0991a4bc86a0fe01cda4422bd",
    "decimals": 18,
    "image": "https://rango.vip/i/q502kh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003356,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "D/ACC",
    "name": "d acc",
    "isPopular": false,
    "chainId": "1",
    "address": "0x81db1949d0e888557bc632f7c0f6698b1f8c9106",
    "decimals": 9,
    "image": "https://rango.vip/i/ujac5N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003056,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAVE",
    "name": "Dave Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f4c5447af6a96d8eeaee1d932338cfc57890dbd",
    "decimals": 18,
    "image": "https://rango.vip/i/EkODII",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.95101e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DYSTO",
    "name": "DystoWorld AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde466831fde62e5141d49ba9d962d3fa3fae466f",
    "decimals": 18,
    "image": "https://rango.vip/i/AnU8RP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00737501,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIZ",
    "name": "MARKETVIZ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c10c0de3362ff21f8ed6bc7f4ac5e391153fd2c",
    "decimals": 18,
    "image": "https://rango.vip/i/Ydvs6F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00101499,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CIA",
    "name": "Cat Intelligence Agency",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f7505f486c22f4338ac2bde67a3e93a547644b9",
    "decimals": 18,
    "image": "https://rango.vip/i/JG1lKz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VNLNK",
    "name": "Vinlink",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f1e49d6dcfc9eefcce9d5ae3c660f8ead75061a",
    "decimals": 9,
    "image": "https://rango.vip/i/h1GQsP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.31176e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$0XGAS",
    "name": "0xGasless",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5fc111f3fa4c6b32eaf65659cfebdeed57234069",
    "decimals": 18,
    "image": "https://rango.vip/i/MDoSNC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04763178,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYNH",
    "name": "SyncHub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ae7c53210f6f847740794ed7fcfae73706f72ba",
    "decimals": 9,
    "image": "https://rango.vip/i/qovn2D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010667,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLUMS",
    "name": "PLUMS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x666cbfaa3baa2faccfac8854fea1e5db140fb104",
    "decimals": 18,
    "image": "https://rango.vip/i/ogESPq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.47081e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGGGO",
    "name": "Dogggo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1abecc1b3958da78259fa2793653fc48e976420",
    "decimals": 18,
    "image": "https://rango.vip/i/XbgItj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00054384,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JEST",
    "name": "Jester",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa4bc2b90743294e5e6fd3321a9a131947f7785db",
    "decimals": 9,
    "image": "https://rango.vip/i/fdQIqo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.16,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOFI",
    "name": "MobiFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2dbf14d0b47ed3ba02bdb7c954e05a72deb7544",
    "decimals": 18,
    "image": "https://rango.vip/i/OWf3mQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00224199,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TIPJA",
    "name": "Tipja",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e235f491ae66b82296d58332adc2a021c449c10",
    "decimals": 18,
    "image": "https://rango.vip/i/BabZ79",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.416e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAIJU",
    "name": "KAIJUNO8",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4fe8d4775b7cb2546b9ee86182081cdf8f77b053",
    "decimals": 18,
    "image": "https://rango.vip/i/3BhGET",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.061e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WTF",
    "name": "DaVinci",
    "isPopular": false,
    "chainId": "1",
    "address": "0x730bcbe5cdc1a3061dfe700774b7b8dd1d4173db",
    "decimals": 18,
    "image": "https://rango.vip/i/U2Uvue",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 23.7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PISS",
    "name": "Pisscoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9c0bd34bebc33a0e898554cfc91e8a84c728bf9f",
    "decimals": 18,
    "image": "https://rango.vip/i/Wfpec8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.50036e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOKENWATCH",
    "name": "TokenWatch",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3b36424c70e0e6aee3b91d1894c2e336447dbd3",
    "decimals": 18,
    "image": "https://rango.vip/i/bS44nP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.09092,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BC3M",
    "name": "Backed GOVIES 0 6 months EURO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f123cf3f37ce3328cc9b5b8415f9ec5109b45e7",
    "decimals": 18,
    "image": "https://rango.vip/i/x2MSH7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 135.28,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCSPX",
    "name": "Backed CSPX Core S P 500",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e2c4fb7ede391d116e6b41cd0608260e8801d59",
    "decimals": 18,
    "image": "https://rango.vip/i/RDh3qb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 579.85,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "3D3D",
    "name": "3d3d",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe60fba03048effb4acf3f0088ec2f53d779d3bb",
    "decimals": 18,
    "image": "https://rango.vip/i/TWsHxb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.0708e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INX",
    "name": "INX Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbbc7f7a6aadac103769c66cbc69ab720f7f9eae3",
    "decimals": 18,
    "image": "https://rango.vip/i/23JOTp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.139992,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GYOSHI",
    "name": "GYOSHI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f17d72cbe65df609315df5c4f5f729efbd00ade",
    "decimals": 18,
    "image": "https://rango.vip/i/auIzJe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.63316e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BROS",
    "name": "Crypto Bros",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9dcd367e2afa8d6e5d6cf0306094e3eb7bbaaf4d",
    "decimals": 18,
    "image": "https://rango.vip/i/5jXGZP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.99581e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GUY",
    "name": "Family Guy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4743a7a193cdf202035e9bc6830a07f1607630c4",
    "decimals": 18,
    "image": "https://rango.vip/i/dtpQf1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.1887e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TBO",
    "name": "Biturbo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x55747be9f9f5beb232ad59fe7af013b81d95fd5e",
    "decimals": 18,
    "image": "https://rango.vip/i/IafgEB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01646119,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRE",
    "name": "GRE Labs",
    "isPopular": false,
    "chainId": "1",
    "address": "0xace5b23671cc6dd00cf41fcb4ee31e008209f472",
    "decimals": 18,
    "image": "https://rango.vip/i/fFwFSi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000468,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOFO",
    "name": "FOFO Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x27f103f86070cc639fef262787a16887d22d8415",
    "decimals": 18,
    "image": "https://rango.vip/i/ZpL6ZS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.362e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REMIT",
    "name": "BlockRemit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3db045814d0a29d831fe38055cb97a956ef7cafb",
    "decimals": 18,
    "image": "https://rango.vip/i/cXkjMv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015743,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMERALD",
    "name": "SJ741 Emeralds",
    "isPopular": false,
    "chainId": "1",
    "address": "0x382edfe4c6168858c81893fe00fcb7b68914d929",
    "decimals": 8,
    "image": "https://rango.vip/i/frHD7n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 190.75,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VXDEFI",
    "name": "vXDEFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1a3864dbf62fb94834b108ff6bf439ce70183ac",
    "decimals": 18,
    "image": "https://rango.vip/i/FzWiYc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TNR",
    "name": "The Night Riders",
    "isPopular": false,
    "chainId": "1",
    "address": "0x14bfc34b292aa3f8afa0c366244ffb77f72761f6",
    "decimals": 9,
    "image": "https://rango.vip/i/zSWi63",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.20571e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWEEPR",
    "name": "Sweepr",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9305d3d847e4cf950d43c99094a93314833cf3a9",
    "decimals": 18,
    "image": "https://rango.vip/i/trazRZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00068449,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUTTER",
    "name": "Butter",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d248ce39e26fb00f911fb1e7a45a00d8c94341c",
    "decimals": 18,
    "image": "https://rango.vip/i/TSb8si",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.95746e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESCO",
    "name": "Esco Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7163436b8efffb469f6bb81cc908b1661d4795e6",
    "decimals": 18,
    "image": "https://rango.vip/i/dTz7KE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.87957e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GROQ",
    "name": "GROQ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3d330b8d4eb25b0933e564d7284d462346d453ef",
    "decimals": 9,
    "image": "https://rango.vip/i/ciSW4s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000531,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BARA",
    "name": "Capybara Memecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf190dbd849e372ff824e631a1fdf199f38358bcf",
    "decimals": 18,
    "image": "https://rango.vip/i/Q43jrr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0003129,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RUNIC",
    "name": "Runic Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb4f5c4bf62fac1be7e4bef9c7c055bf1c52241d",
    "decimals": 18,
    "image": "https://rango.vip/i/CwhyN9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00619737,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RGUSD",
    "name": "Revenue Generating USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x78da5799cf427fee11e9996982f4150ece7a99a7",
    "decimals": 18,
    "image": "https://rango.vip/i/1nOyQ9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.993944,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OGMF",
    "name": "CryptoPirates",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62d04c79c1f3a2d7230ffcd3ab01794e1d153239",
    "decimals": 18,
    "image": "https://rango.vip/i/rEkoxb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.38679e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUCKY",
    "name": "luckyinu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1c8fa30fded3e0031dc24c1646d74108b096cc2",
    "decimals": 9,
    "image": "https://rango.vip/i/vBwLQ7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.521e-12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GRAVITAS",
    "name": "Gravitas",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb17d69c91135516b0256c67e8bd32cd238b56161",
    "decimals": 9,
    "image": "https://rango.vip/i/97DhJV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001731,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CODE",
    "name": "Code Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x283344eea472f0fe04d6f722595a2fffefe1901a",
    "decimals": 13,
    "image": "https://rango.vip/i/vtXZiA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.8381e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PELO",
    "name": "PepElon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb5ce43fe2fcffffb2eece95ec413d08def28046f",
    "decimals": 18,
    "image": "https://rango.vip/i/QTZhzN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.79704e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WHAT",
    "name": "What the Duck",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1b900b2cde13b384f89d6dd697dc03ac61c702bb",
    "decimals": 18,
    "image": "https://rango.vip/i/mBCneH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.819e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BANDS",
    "name": "BANDS  OLD ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2048e0d048224381cac3ea06012ced4a6f122d32",
    "decimals": 18,
    "image": "https://rango.vip/i/SWqnkb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002265,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GUARDAI",
    "name": "GuardAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1679946ba555ebf5cb38e8b089ef1e1e5d2abb1",
    "decimals": 18,
    "image": "https://rango.vip/i/lH8Ro5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02568353,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOPE",
    "name": "WojakPepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2e0f591191ee5f6fb8a7f1777a733b6aa92bb55",
    "decimals": 9,
    "image": "https://rango.vip/i/2VXXo9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.354e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DADA",
    "name": "DADA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7acc3f723419fa0c1f789618f798e75c5189c24f",
    "decimals": 18,
    "image": "https://rango.vip/i/GFQvEj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000009,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GUESS",
    "name": "GuessOnChain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb31ba344310bc4872c6188ff210d7341a301ea9",
    "decimals": 9,
    "image": "https://rango.vip/i/oN22lD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002238,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KETH",
    "name": "Stakehouse kETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0c28a5a2da3920946e8bf821f61f7bea311048b",
    "decimals": 18,
    "image": "https://rango.vip/i/IUyXIj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USC",
    "name": "USC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38547d918b9645f2d94336b6b61aeb08053e142c",
    "decimals": 18,
    "image": "https://rango.vip/i/hEQEs8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.006,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$WOLF",
    "name": "Wolf of Wall Street",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8365332d4baf69bc24ca2401b90c3853ab9f818e",
    "decimals": 18,
    "image": "https://rango.vip/i/WadZvw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00732754,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAC",
    "name": "Meta Art Connection",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15e5d3deff5c3a13adca4f313ff44735881ebd6c",
    "decimals": 18,
    "image": "https://rango.vip/i/GQnz1G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.24,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOS",
    "name": "Gelios",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4b7af50644c661e270fbb8da770049c9fc0bbe1",
    "decimals": 18,
    "image": "https://rango.vip/i/wSj48I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00142475,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COLR",
    "name": "colR Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2d2e1309db33b38a19ee2a7cd9cb5de39d76663",
    "decimals": 18,
    "image": "https://rango.vip/i/3t4CS4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00073994,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIZ",
    "name": "Vision City",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3bf954e809620bf2f1fcb667f1c7d2d2e94350d1",
    "decimals": 9,
    "image": "https://rango.vip/i/trBp16",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.30397e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CMINER",
    "name": "ChainMiner",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda63feff6e6d75cd7a862cd56c625045dcf26e88",
    "decimals": 9,
    "image": "https://rango.vip/i/NV7WuL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012353,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TEST",
    "name": "Test",
    "isPopular": false,
    "chainId": "1",
    "address": "0x807534b396919783b7e30383fe57d857bc084338",
    "decimals": 18,
    "image": "https://rango.vip/i/E9Siua",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000867,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ENOCH",
    "name": "Enoch",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4db57d585fa82ca32d25086ddc069d899f08d455",
    "decimals": 18,
    "image": "https://rango.vip/i/pP23RP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPENOMICS",
    "name": "Pepenomics",
    "isPopular": false,
    "chainId": "1",
    "address": "0x696969ade0cc455414fc4800ebea505d690b2429",
    "decimals": 9,
    "image": "https://rango.vip/i/XomzrP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.7413e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INSTETH",
    "name": "Inception stETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7fa768e035f956c41d6aeaa3bd857e7e5141cad5",
    "decimals": 18,
    "image": "https://rango.vip/i/EZtyCL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2507.88,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HYPE",
    "name": "Hyperbolic Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85225ed797fd4128ac45a992c46ea4681a7a15da",
    "decimals": 18,
    "image": "https://rango.vip/i/UwAXKg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00611461,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FINU",
    "name": "Formula Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c022e58c5e3ee213f06f975fd8a0d3a6fe9ca1c",
    "decimals": 18,
    "image": "https://rango.vip/i/oM4Alm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005567,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZENF",
    "name": "Zenland",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9b7b5d5e8d2bcc78884f9f9099bfa42a9e5c1a5",
    "decimals": 18,
    "image": "https://rango.vip/i/13KeGe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00222828,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HEEL",
    "name": "Good Dog",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf941d3aabf2ee0f5589e68ba6047b8329592b366",
    "decimals": 9,
    "image": "https://rango.vip/i/Z1Uot5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02842037,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XROOTAI",
    "name": "XRootAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15a7edbbd8909358ae91a278b42d8ebf0ce9452e",
    "decimals": 9,
    "image": "https://rango.vip/i/MGU0FD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017588,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BDXN",
    "name": "Bondex",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbdbdbdd0c22888e63cb9098ad6d68439197cb091",
    "decimals": 18,
    "image": "https://rango.vip/i/JFnuDS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHAN",
    "name": "Chanalog",
    "isPopular": false,
    "chainId": "1",
    "address": "0xab97bf5fb097e503bba2c86b7c56c0059ac0dc06",
    "decimals": 18,
    "image": "https://rango.vip/i/94NKSM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00088132,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUSION",
    "name": "FusionBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6230f552a1c825d02e1140ccc0d3f5eeec81ca84",
    "decimals": 9,
    "image": "https://rango.vip/i/ZTA0Qv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.093896,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XTAI",
    "name": "XToolsAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41d321e0365a42058a465dc5bf98242a92c2e9f3",
    "decimals": 9,
    "image": "https://rango.vip/i/jMgzyk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0004866,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "L",
    "name": "L",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf8c76dbea329ec4fa987afc514f805b21b249d79",
    "decimals": 18,
    "image": "https://rango.vip/i/U3offa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.85758e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BERC",
    "name": "Fair BERC20",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc8e35221904f61b4200ca44a08e4dac387ac83a",
    "decimals": 18,
    "image": "https://rango.vip/i/RY45XK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0015438,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RVSL",
    "name": "Reversal",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6aa3ecec75ceb388d2e929814ead4fc4cd0648fc",
    "decimals": 18,
    "image": "https://rango.vip/i/Ll2Dmn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.088525,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FAIR",
    "name": "0xFair",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32eca61dc25d0742f238ce523e66b68867625dad",
    "decimals": 18,
    "image": "https://rango.vip/i/hqa2EC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00030392,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MERC",
    "name": "Liquid Mercury",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa203eb78fee91c8459c6d4ef3a899d8724ee5b35",
    "decimals": 18,
    "image": "https://rango.vip/i/6WJtYh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00668363,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FERRET",
    "name": "Ferret AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbcbda13bd60bc0e91745186e274d1445078d6b33",
    "decimals": 18,
    "image": "https://rango.vip/i/SBtnyI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004139,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NTMPI",
    "name": "Neutaro",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53be7be0ce7f92bcbd2138305735160fb799be4f",
    "decimals": 6,
    "image": "https://rango.vip/i/2ytD2M",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02873764,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RIWA",
    "name": "Recycle Impact World Association",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf482d79ae6e8725c199213fc909d6bc30df62815",
    "decimals": 9,
    "image": "https://rango.vip/i/yrywSC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.1594e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPINU",
    "name": "Pepe Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0981d9774a59a703db85f5eaa23672283ea31106",
    "decimals": 18,
    "image": "https://rango.vip/i/rVNpTi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.0613e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBOT",
    "name": "BetBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x70be04312f5f66d03708f0a1d6353b3e0f80ddbb",
    "decimals": 9,
    "image": "https://rango.vip/i/wO3DTh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00142082,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STKGHO",
    "name": "Aave stkGHO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a88df1cfe15af22b3c4c783d4e6f7f9e0c1885d",
    "decimals": 18,
    "image": "https://rango.vip/i/N1OIDW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LAELAPS",
    "name": "Laelaps",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c059413686565d5ad6cce6eed7742c42dbc44ca",
    "decimals": 18,
    "image": "https://rango.vip/i/jPMDdZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006033,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IETHV2",
    "name": "Instadapp ETH v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0d3707c569ff8c87fa923d3823ec5d81c98be78",
    "decimals": 18,
    "image": "https://rango.vip/i/Z682ag",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2621.52,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BITROCK",
    "name": "Bitrock",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde67d97b8770dc98c746a3fc0093c538666eb493",
    "decimals": 9,
    "image": "https://rango.vip/i/L7xtRz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03866176,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLPC",
    "name": "Pepe Le Pew Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa34ee6108fe427f91edce0d6520d9fec0e64f67b",
    "decimals": 9,
    "image": "https://rango.vip/i/ghAJOR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.51597e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOJAK20",
    "name": "Wojak 2 0 Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4e30d504fe3b18423d82a34e7ebe529a56f89c2",
    "decimals": 18,
    "image": "https://rango.vip/i/5LiNZv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.5378e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GENIE",
    "name": "GenieBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x56978e609f2cab06f77c5c8fd75166fcd8f09bd8",
    "decimals": 18,
    "image": "https://rango.vip/i/8Lw2rY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0005161,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THUG",
    "name": "Thug Life",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce9de5365739b1bed5c8546867aee4209fbb8538",
    "decimals": 18,
    "image": "https://rango.vip/i/A8CXye",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000199,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$STACK",
    "name": "$STACK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c0f743928ca8fa7fb24ad89669c8a7838f34917",
    "decimals": 18,
    "image": "https://rango.vip/i/mZCWD0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00211043,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALFA",
    "name": "alfa society",
    "isPopular": false,
    "chainId": "1",
    "address": "0x128ad1ad707c3b36e6f2ac9739f9df7516fdb592",
    "decimals": 18,
    "image": "https://rango.vip/i/ClBcV1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.399723,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOUGH",
    "name": "L3T H1M C00K",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfc116ea24f002f600e363bdce4b91715fe5e0392",
    "decimals": 9,
    "image": "https://rango.vip/i/j3WbQX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018308,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TBOS",
    "name": "Aktionariat TBo c o Comon Accelerator H",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb446566d6d644249d5d82aab5fea8a5b7da3f691",
    "decimals": 0,
    "image": "https://rango.vip/i/1uTXqO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DQTS",
    "name": "Aktionariat ServiceHunter AG Tokenized ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8747a3114ef7f0eebd3eb337f745e31dbf81a952",
    "decimals": 0,
    "image": "https://rango.vip/i/MBzgY9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.91,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEOBOT",
    "name": "NeoBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f2d4708f75de6fb60b687fed326697634774deb",
    "decimals": 18,
    "image": "https://rango.vip/i/VigLPV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00110339,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GYOZA",
    "name": "Gyoza",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e51a6b3cc6d5ae69a0d44db9de846aeb5a582dd",
    "decimals": 9,
    "image": "https://rango.vip/i/FlVPyJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00121129,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CBOT",
    "name": "CompanionBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf49311af05a4ffb1dbf33d61e9b2d4f0a7d4a71c",
    "decimals": 9,
    "image": "https://rango.vip/i/5moOLr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01394757,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RCE",
    "name": "Raw Chicken Experiment",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe579b687fd9cd4fd6cb85abc8fbe069ab0be2cb",
    "decimals": 18,
    "image": "https://rango.vip/i/yeltjW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.2826e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AFS",
    "name": "Aktionariat Alan Frei Company Tokenized",
    "isPopular": false,
    "chainId": "1",
    "address": "0x56528c1df17fd5451451eb6efde297758bc8f9a1",
    "decimals": 0,
    "image": "https://rango.vip/i/EUWIhI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.67,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MDI",
    "name": "Medicle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x848896470d989f30503d8f883c331f63b73b66ea",
    "decimals": 18,
    "image": "https://rango.vip/i/mqrhyi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00793742,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASX",
    "name": "Asymetrix",
    "isPopular": false,
    "chainId": "1",
    "address": "0x67d85a291fcdc862a78812a3c26d55e28ffb2701",
    "decimals": 18,
    "image": "https://rango.vip/i/4o2qv4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00820159,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHAP",
    "name": "Chappie",
    "isPopular": false,
    "chainId": "1",
    "address": "0x414cbf31c62d99515bfd66497b495a585b52f703",
    "decimals": 0,
    "image": "https://rango.vip/i/Lg1Uey",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005779,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TETRIS",
    "name": "Tetris",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb8a95e76a16b58c30b01e39dd6aad5949e5e802",
    "decimals": 9,
    "image": "https://rango.vip/i/bCgX41",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0004354,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SENT",
    "name": "SentimentAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8dbd1331b1de57835b24657ed21d0691e2e7362a",
    "decimals": 18,
    "image": "https://rango.vip/i/ZfwpRX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001406,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "X ",
    "name": "XAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xafe53eea0cfe20198328890b69107d5fd8159a77",
    "decimals": 9,
    "image": "https://rango.vip/i/oKsiq0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.14812e-13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESPR",
    "name": "Espresso Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa3c31927a092bd54eb9a0b5dfe01d9db5028bd4f",
    "decimals": 9,
    "image": "https://rango.vip/i/BjoKco",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015399,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NONE",
    "name": "None Trading",
    "isPopular": false,
    "chainId": "1",
    "address": "0x903ff0ba636e32de1767a4b5eeb55c155763d8b7",
    "decimals": 18,
    "image": "https://rango.vip/i/KRcsOH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01377471,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INLSETH",
    "name": "Inception lsETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x94b888e11a9e960a9c3b3528eb6ac807b27ca62e",
    "decimals": 18,
    "image": "https://rango.vip/i/FAhLER",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ШАЙЛУШАЙ",
    "name": "Real Smurf Cat",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff836a5821e69066c87e268bc51b849fab94240c",
    "decimals": 18,
    "image": "https://rango.vip/i/4hoRAj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00016067,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LSDAI",
    "name": "Liquid Savings DAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1a70b24e109f7a8b39806c554e123efc6769e91",
    "decimals": 18,
    "image": "https://rango.vip/i/u2W1R5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "!",
    "name": "WOW",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4efce4c758ddfb3911a1a1282a29ce0bdb16ef86",
    "decimals": 18,
    "image": "https://rango.vip/i/0Nay42",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.49043e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAESAR",
    "name": "Caesar s Arena",
    "isPopular": false,
    "chainId": "1",
    "address": "0x343cf59a43bd7ddd38b7236a478139a86a26222b",
    "decimals": 18,
    "image": "https://rango.vip/i/EgE8tB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007013,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRT",
    "name": "FortuneBets",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb622907fbff6cbf7c3ce355173251e3edb13a606",
    "decimals": 18,
    "image": "https://rango.vip/i/FgWz6w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002435,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WPE",
    "name": "OpesAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd075e95423c5c4ba1e122cae0f4cdfa19b82881b",
    "decimals": 18,
    "image": "https://rango.vip/i/iCxbRS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 196.2,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EPETS",
    "name": "Etherpets",
    "isPopular": false,
    "chainId": "1",
    "address": "0x280df82db83abb0a4c734bb02afc7985a1c8eaf2",
    "decimals": 9,
    "image": "https://rango.vip/i/9Sy3HP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00085284,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDTSO",
    "name": "Bridged Tether  Wormhole ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1cdd2eab61112697626f7b4bb0e23da4febf7b7c",
    "decimals": 6,
    "image": "https://rango.vip/i/sFWAuL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.988617,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0XAISWAP",
    "name": "0xAISwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c6778023c3d4fd79ddd14810079f64c39e9e43d",
    "decimals": 18,
    "image": "https://rango.vip/i/bLox2q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.105566,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DBNB",
    "name": "DecentraBNB",
    "isPopular": false,
    "chainId": "1",
    "address": "0x99f618edcfedca1fcc8302e14daa84802114a8c5",
    "decimals": 9,
    "image": "https://rango.vip/i/b88HyV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000307,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUNDED",
    "name": "Funded",
    "isPopular": false,
    "chainId": "1",
    "address": "0x83d4927d060a7a33ad838bd7ea0ec128d58c9003",
    "decimals": 18,
    "image": "https://rango.vip/i/ZmHVJA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.456276,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GROKBOT",
    "name": "GROKBOT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x12272c264ca580d2190b16db14cea3815f52060c",
    "decimals": 18,
    "image": "https://rango.vip/i/WJmsHs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00049318,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHFP",
    "name": "SuperFrank",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f0c858c26775df75e45934b189154c436bd7335",
    "decimals": 8,
    "image": "https://rango.vip/i/LGu0Ko",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.12,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTC20",
    "name": "Bitcoin20",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe86df1970055e9caee93dae9b7d5fd71595d0e18",
    "decimals": 18,
    "image": "https://rango.vip/i/FOros0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.104299,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANVA",
    "name": "AlphaNova",
    "isPopular": false,
    "chainId": "1",
    "address": "0x209a78d23f825950a5df4d6d21288e5212b44f2c",
    "decimals": 18,
    "image": "https://rango.vip/i/y3n6db",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018503,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PBT",
    "name": "PolyBet",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa406844323f1603701e6ad95adc8a082213a68ce",
    "decimals": 18,
    "image": "https://rango.vip/i/N8Rqhx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00086603,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWBTC",
    "name": "Swell Restaked BTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8db2350d78abc13f5673a411d4700bcf87864dde",
    "decimals": 8,
    "image": "https://rango.vip/i/JbNvIZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 53807,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TESO",
    "name": "TeSo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe1bc2e2338defcd88e7f9f5f745ddb222180ab9",
    "decimals": 9,
    "image": "https://rango.vip/i/Kf3HL2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00055114,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYBL",
    "name": "Sybulls",
    "isPopular": false,
    "chainId": "1",
    "address": "0xca4b70beccabce29efa5bc5c86311e5d38461842",
    "decimals": 18,
    "image": "https://rango.vip/i/YO15KU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.116641,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ILUM",
    "name": "Illuminati",
    "isPopular": false,
    "chainId": "1",
    "address": "0x98e1f56b334438e3f0bde22d92f5bfd746e0631f",
    "decimals": 18,
    "image": "https://rango.vip/i/3IfMQ6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000463,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "B-BAOETH-ETH-BPT",
    "name": "baoETH ETH StablePool",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a44e35d5451e0b78621a1b3e7a53dfaa306b1d0",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2345.31,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEPE",
    "name": "Karen Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe6f2e70f30a0894d0aee79e11653275e89c7bd6",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000892,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BNSAI",
    "name": "bonsAI Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x401e6d25c2991824299aa5dbe67c82486a64381d",
    "decimals": 18,
    "image": "https://rango.vip/i/k0w0eU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00156876,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOC",
    "name": "Socrates",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3250577e12b9469915c1fa3a71c22817ca44c4d9",
    "decimals": 18,
    "image": "https://rango.vip/i/uRrFUd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00353295,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHARES",
    "name": "shares finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xebb82c932759b515b2efc1cfbb6bf2f6dbace404",
    "decimals": 18,
    "image": "https://rango.vip/i/gTeWNw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.29725,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CROC",
    "name": "CrocBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcaa79bf8b1d00bf3d4f6dbec6221955871c04618",
    "decimals": 18,
    "image": "https://rango.vip/i/fu2vhw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004803,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POG",
    "name": "Pepe OG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a693a301215aad39d83a32a5b5279f2d238851b",
    "decimals": 18,
    "image": "https://rango.vip/i/8Jh7wg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001976,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NAVYSEAL",
    "name": "Navy seal",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34df29dd880e9fe2cec0f85f7658b75606fb2870",
    "decimals": 9,
    "image": "https://rango.vip/i/KDgxBS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000205,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MSWETH",
    "name": "Eigenpie swETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32bd822d615a3658a68b6fdd30c2fcb2c996d678",
    "decimals": 18,
    "image": "https://rango.vip/i/p8dvJO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2471.06,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VIDS",
    "name": "Aktionariat Technologies of Understandi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe221db71c5c527149bdf56c4d13a54cf55e543c1",
    "decimals": 0,
    "image": "https://rango.vip/i/vEFywG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 61.44,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HIDOODLES",
    "name": "hiDOODLES",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3700adfd26d5bc062cb8b8a77e68fbd43f58ecab",
    "decimals": 18,
    "image": "https://rango.vip/i/dp1mvQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00558423,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDEBT",
    "name": "USDEBT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00c5ca160a968f47e7272a0cfcda36428f386cb6",
    "decimals": 18,
    "image": "https://rango.vip/i/OHtTtn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.798e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIVA",
    "name": "DIVA Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b7ffcb2b92fb4890f22f62a52fb7a180eab818e",
    "decimals": 18,
    "image": "https://rango.vip/i/gG7M75",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00659924,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XLRT",
    "name": "Xccelerate",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8a3c710e41cd95799c535f22dbae371d7c858651",
    "decimals": 18,
    "image": "https://rango.vip/i/RkJofa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00031477,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASDCRV",
    "name": "Aladdin sdCRV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x43e54c2e7b3e294de3a155785f52ab49d87b9922",
    "decimals": 18,
    "image": "https://rango.vip/i/TNi4L4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVEX",
    "name": "EventsX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x17729f7ffddd4ed480cf4cfb1668ef90d1e6a24e",
    "decimals": 18,
    "image": "https://rango.vip/i/3BrSPM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004067,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TREE",
    "name": "TREEMEISTER",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfbbe098ee65238e4d9f771404edddcbf89cd689b",
    "decimals": 18,
    "image": "https://rango.vip/i/TaiRn8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001932,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHADOW",
    "name": "Shadowladys DN404",
    "isPopular": false,
    "chainId": "1",
    "address": "0x46305b2ebcd92809d5fcef577c20c28a185af03c",
    "decimals": 18,
    "image": "https://rango.vip/i/bsaxZf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.28,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HANDZ",
    "name": "Handz of Gods",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9baa12a9e3b9dc355f162082762f95626367d087",
    "decimals": 18,
    "image": "https://rango.vip/i/0Sb6Ug",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUSD",
    "name": "eUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97de57ec338ab5d51557da3434828c5dbfada371",
    "decimals": 18,
    "image": "https://rango.vip/i/1mX77Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.977725,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUSD",
    "name": "eUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf3ac4f479375802a821f7b7b46cd7eb5e4262cc",
    "decimals": 18,
    "image": "https://rango.vip/i/FjbHIb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.007,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XGPT",
    "name": "X GPT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x51fe05eac152494908ff1ebbd50e116e960baf64",
    "decimals": 18,
    "image": "https://rango.vip/i/SaxCZ3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.070588,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "X0",
    "name": "X0",
    "isPopular": false,
    "chainId": "1",
    "address": "0x125518ba3bbcc836eea4c520a086ef297706e999",
    "decimals": 0,
    "image": "https://rango.vip/i/lH9m9a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GCOTI",
    "name": "COTI Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf2ca40d3fc4459436d11b94d21fa4b8a89fb51d",
    "decimals": 18,
    "image": "https://rango.vip/i/u79P0n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.051395,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZW",
    "name": "Zenith Wallet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x52d30b949bdbc63780e5a263cf436d4df983fe58",
    "decimals": 18,
    "image": "https://rango.vip/i/K5Yf8u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00177355,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OXL",
    "name": "0x Leverage",
    "isPopular": false,
    "chainId": "1",
    "address": "0x03ee5026c07d85ff8ae791370dd0f4c1ae6c97fc",
    "decimals": 18,
    "image": "https://rango.vip/i/xGro4W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00353133,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOTC",
    "name": "Bot Compiler",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9532ca064278ce3ba4fcc66cebec6d9f04f58f70",
    "decimals": 9,
    "image": "https://rango.vip/i/gfbprB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010501,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "₿",
    "name": "⠀⠀⠀⠀⠀🟧🟧⠀⠀⠀🟧🟧\n⠀⠀⠀⠀⠀🟧🟧⠀⠀⠀🟧🟧\n🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧\n🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧🟧\n🟧🟧🟧🟧🟧🟧🟧🟧🟧",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe4efdd2eb216a4620cfa55c5cc67bd09dc64ff24",
    "decimals": 9,
    "image": "https://rango.vip/i/azMyLU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033595,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNIVERSE",
    "name": "Unicorn Metaverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf6f31b8afbf8e3f7fc8246bef26093f02838da98",
    "decimals": 18,
    "image": "https://rango.vip/i/pZJARw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02380175,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TUF",
    "name": "TUF Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6f8b23296394d20ec048fbdec8ebc0ca90f5c8f1",
    "decimals": 18,
    "image": "https://rango.vip/i/E1AenN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001785,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOMOV2",
    "name": "Momo v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x08d0222a206d1aee59a9b66969c04fd1e8a0f864",
    "decimals": 18,
    "image": "https://rango.vip/i/7bFrTR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.039e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EEYOR",
    "name": "Eeyor",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07c904d8c04323ef9fe6bf13aaeba05b62c54825",
    "decimals": 18,
    "image": "https://rango.vip/i/V707fc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.8862e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INSWETH",
    "name": "Inception swETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3ade5ace1bbb033ccae8177c12ecbfa16bd6a9d",
    "decimals": 18,
    "image": "https://rango.vip/i/LKxk0y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2899.6,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUFFIN",
    "name": "Muffin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68aae81b4241ffe03d3552d42a69940604fe28bf",
    "decimals": 9,
    "image": "https://rango.vip/i/vZVfWp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001887,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ALTD",
    "name": "Altitude",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8929e9dbd2785e3ba16175e596cdd61520fee0d1",
    "decimals": 18,
    "image": "https://rango.vip/i/uHMlxc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00104849,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$MEOW",
    "name": "MeowMeme",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd561a593d9dd8b9a0e3a487dfb517c9371d6dda7",
    "decimals": 18,
    "image": "https://rango.vip/i/NSH1lh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002515,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DGN",
    "name": "Degen",
    "isPopular": false,
    "chainId": "1",
    "address": "0xca7013ba4bf76bcdc3ffc71735896682644f47c2",
    "decimals": 18,
    "image": "https://rango.vip/i/OtqTDu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.48337e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SILKROAD",
    "name": "SuperMarioPorsche911Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1142866f451d9d5281c5c8349a332bd338e552a1",
    "decimals": 18,
    "image": "https://rango.vip/i/4fMb6H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.5496e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RADA",
    "name": "RADA Foundation",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7aa2f174fbc4d0a17b34adfb9b3e1dc029b46d76",
    "decimals": 18,
    "image": "https://rango.vip/i/eguq1P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00019301,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SONIK",
    "name": "SONIK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f891b5ecbd89dd8a5ee4d1d80efc3fe78b306cb",
    "decimals": 18,
    "image": "https://rango.vip/i/2Pk4U7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.1825e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KLUB",
    "name": "KlubCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x29d7139271398d0c2e22523fda06e023dcb07f8f",
    "decimals": 18,
    "image": "https://rango.vip/i/Tp4ixI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00023415,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAXU",
    "name": "Pax Unitas",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e452b391a86c9240e98df7277ce0bea5be08e43",
    "decimals": 18,
    "image": "https://rango.vip/i/QRkewc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.28666e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMOTI",
    "name": "EmotiCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b0e1c344141fb361b842d397df07174e1cdb988",
    "decimals": 9,
    "image": "https://rango.vip/i/sto9Fq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005517,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUCK",
    "name": "Cuckadoodledoo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb63e6be33bc5b5386d7ab0529dc6c400f2ac2ec",
    "decimals": 18,
    "image": "https://rango.vip/i/PNxMAI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00173682,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XRAI",
    "name": "X Ratio AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x890a60b4450484847380bddca0c3c3ee2ff0dfbe",
    "decimals": 18,
    "image": "https://rango.vip/i/E7CNLE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00019939,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NVDAD",
    "name": "Dinari NVDA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4dafffddea93ddf1e0e7b61e844331455053ce5c",
    "decimals": 18,
    "image": "https://rango.vip/i/Mu845i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MELON",
    "name": "MELON",
    "isPopular": false,
    "chainId": "1",
    "address": "0x37dba54fdc402aff647ce06c66972f5d662c326d",
    "decimals": 18,
    "image": "https://rango.vip/i/oigcw5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012654,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPURAI",
    "name": "PEPURAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9863bcc2fb23dfdf5fe275aa4c5575a32a580911",
    "decimals": 18,
    "image": "https://rango.vip/i/n8IBnA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.60677e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ƎԀƎԀ",
    "name": "ƎԀƎԀ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69d29f1b0cc37d8d3b61583c99ad0ab926142069",
    "decimals": 9,
    "image": "https://rango.vip/i/aLGexW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.28898e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRVY",
    "name": "Curve Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc8b7ff89eb2b73ecdd579f81424a3b582200808",
    "decimals": 18,
    "image": "https://rango.vip/i/btWwfc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.46238e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAEL",
    "name": "RealAliensEnjoyingLiquidity",
    "isPopular": false,
    "chainId": "1",
    "address": "0x428487ad75dc9197cccb496629ab516974329dc1",
    "decimals": 18,
    "image": "https://rango.vip/i/MPIhNu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.203613,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TETHER",
    "name": "HarryPotterObamaHomerSimpson888Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4d9b0b7a6db042cb990d36a0df5aa2960e552f16",
    "decimals": 9,
    "image": "https://rango.vip/i/xaOZDD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001056,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CAFE",
    "name": "0xDEFCAFE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdefcafe7eac90d31bbba841038df365de3c4e207",
    "decimals": 9,
    "image": "https://rango.vip/i/YQd258",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00453865,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BN",
    "name": "TNA Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf385905e56db4d8a208b20aa8a88dbb225f773d4",
    "decimals": 18,
    "image": "https://rango.vip/i/kRlyF6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00575457,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIVA",
    "name": "Diva Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbfabde619ed5c4311811cf422562709710db587d",
    "decimals": 18,
    "image": "https://rango.vip/i/rLFxT6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00659924,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BGPT",
    "name": "BlockGPT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x089729b0786c8803cff972c16e402f3344d079ea",
    "decimals": 18,
    "image": "https://rango.vip/i/a8tUa1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000579,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONIC",
    "name": "Trade Bionic",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcdd0d11de0225b528b3a20d6436392c8260969d0",
    "decimals": 18,
    "image": "https://rango.vip/i/rHo8HO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01657465,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMEGA",
    "name": "Omega Cloud",
    "isPopular": false,
    "chainId": "1",
    "address": "0x339e6c8d204b1aaa3fb74bc7ba15b9fb6648b7d2",
    "decimals": 9,
    "image": "https://rango.vip/i/Wsl6nV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015806,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JFISH",
    "name": "Jellyfish Mobile",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcdeb595293511115d9d9d44b189cc0da4a08cfaf",
    "decimals": 18,
    "image": "https://rango.vip/i/jKGJSV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0353281,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "صباح الفرولة",
    "name": "Strawberry Elephant",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd22a61e8503bea5842e5e0126ca9ffc4dd492084",
    "decimals": 18,
    "image": "https://rango.vip/i/RTgwBA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.054775,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UDX",
    "name": "UniDexAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce3ee7435a5bedbe73b92f39828b0cfd9d0ff568",
    "decimals": 18,
    "image": "https://rango.vip/i/wOAp1d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03793303,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIBTA",
    "name": "Backed IBTA   Treasury Bond 1 3yr",
    "isPopular": false,
    "chainId": "1",
    "address": "0x52d134c6db5889fad3542a09eaf7aa90c0fdf9e4",
    "decimals": 18,
    "image": "https://rango.vip/i/1hEFCK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.6,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GDAG",
    "name": "GhostDAG org",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8bf30e9f44e5d068a9d0c20da22660997a532e33",
    "decimals": 18,
    "image": "https://rango.vip/i/O0Z2oQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000775,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CDETI",
    "name": "Index Coop CoinDesk ETH Trend Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x55b2cfcfe99110c773f00b023560dd9ef6c8a13b",
    "decimals": 18,
    "image": "https://rango.vip/i/ohaH9C",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MORI",
    "name": "Mori Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00c8555542158fff0feb892c8e000a8d1831762c",
    "decimals": 18,
    "image": "https://rango.vip/i/G2pmpC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.886621,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OUSG",
    "name": "OUSG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1b19c19393e2d034d8ff31ff34c81252fcbbee92",
    "decimals": 18,
    "image": "https://rango.vip/i/1O4IyM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 107.85,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWEET",
    "name": "Sweet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2df7d0e4903029717c949cad204075a3d75c8806",
    "decimals": 9,
    "image": "https://rango.vip/i/nvkAOS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00131073,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NINA",
    "name": "NinaPumps",
    "isPopular": false,
    "chainId": "1",
    "address": "0x697a79af2de4af9e9aa0d08905374556ad1353bb",
    "decimals": 18,
    "image": "https://rango.vip/i/KbOviy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.75635e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHAINS",
    "name": "ChainSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1f830aa68b53fd3ee3bb86d7f8254e604740c8b",
    "decimals": 18,
    "image": "https://rango.vip/i/z0ePKp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013661,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DZHV",
    "name": "DizzyHavoc",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3419875b4d3bca7f3fdda2db7a476a79fd31b4fe",
    "decimals": 18,
    "image": "https://rango.vip/i/Ifd7rR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00191168,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XMAS",
    "name": "Elon Xmas",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5713c26280647adad2f25bb54376943ecaa9d8e3",
    "decimals": 9,
    "image": "https://rango.vip/i/Y4N7Ck",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005979,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHEESED",
    "name": "Cheesed",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa247c6d23c8c7d223420700d16d189cff9357f38",
    "decimals": 9,
    "image": "https://rango.vip/i/9LGaR8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.36752e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MPENDLE",
    "name": "mPendle",
    "isPopular": false,
    "chainId": "1",
    "address": "0x83e817e1574e2201a005ec0f7e700ed5606f555e",
    "decimals": 18,
    "image": "https://rango.vip/i/SrSDyM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BJK",
    "name": "Be ikta ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1903be033d3e436dd79a8cf9030675bcf97ab589",
    "decimals": 6,
    "image": "https://rango.vip/i/ApmbEt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.720199,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOOD",
    "name": "Feels Good Man",
    "isPopular": false,
    "chainId": "1",
    "address": "0x76af4cb74c8d4da51403d672a799e94b5958c230",
    "decimals": 9,
    "image": "https://rango.vip/i/gI5uMu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001118,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACHF",
    "name": "Anchored Coins ACHF",
    "isPopular": false,
    "chainId": "1",
    "address": "0x653aab62056b92641116d63927de6141d780e596",
    "decimals": 18,
    "image": "https://rango.vip/i/vKvCrT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCHRODINGE",
    "name": "Elon Cat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64c79c8c59a2be17c8d651f73e5ee7942eebdc9e",
    "decimals": 18,
    "image": "https://rango.vip/i/7X3ZUd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00015487,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIB20",
    "name": "Shib2 0",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34ba042827996821cffeb06477d48a2ff9474483",
    "decimals": 8,
    "image": "https://rango.vip/i/LqIEqf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007265,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VDO",
    "name": "ValiDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2ef8a2ccb058915e00e16aa13cc6e36f19d8893b",
    "decimals": 18,
    "image": "https://rango.vip/i/6DIFlD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.248188,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MCBETH",
    "name": "Eigenpie cbETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd09124e8a1e3d620e8807ad1d968021a5495cee8",
    "decimals": 18,
    "image": "https://rango.vip/i/6W9Ijw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCISLY",
    "name": "Of Course I Still Love You",
    "isPopular": false,
    "chainId": "1",
    "address": "0x356e17967206efb413b60ab0ba44e269063a26c9",
    "decimals": 9,
    "image": "https://rango.vip/i/O2Ayhz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.06951e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSKR",
    "name": "Wiskers",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd4ca4a692f14d88af3e7ae13cf00d5095213b25",
    "decimals": 18,
    "image": "https://rango.vip/i/qGZQ39",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00093117,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BS9000",
    "name": "BabySmurf9000",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0058c8581b9fed6864faa654505bc89890cdb2dd",
    "decimals": 9,
    "image": "https://rango.vip/i/gpkyIF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002487,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PINEOWL",
    "name": "Pineapple Owl",
    "isPopular": false,
    "chainId": "1",
    "address": "0x79c6ffe2ccbca761e9e289a69432bffb0b744876",
    "decimals": 9,
    "image": "https://rango.vip/i/EPLfgC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003527,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EBLOCK",
    "name": "EchoBlock",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa9c1eae6b76c09f84a89ff785ee4001a2b7294ce",
    "decimals": 9,
    "image": "https://rango.vip/i/E6aXp6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008531,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STONED",
    "name": "STONED",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8d85b9553896bd8159e608cb958628e01ed10916",
    "decimals": 9,
    "image": "https://rango.vip/i/sLLPJt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013835,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PBMC",
    "name": "PBM Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x87fdd1e031b356bdc1c55a3231cfe266552d8284",
    "decimals": 14,
    "image": "https://rango.vip/i/dbtVYP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.78,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CJPY",
    "name": "Convertible JPY Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1cfa5641c01406ab8ac350ded7d735ec41298372",
    "decimals": 18,
    "image": "https://rango.vip/i/de8ES5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00604052,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MXH",
    "name": "Metroxynth",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95640a134721475bc78594c8ea66c0182c7b9a50",
    "decimals": 9,
    "image": "https://rango.vip/i/pbDDww",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00059459,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EPL",
    "name": "Epic League",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1236ea13c7339287cd00ab196aaa8217006b04dc",
    "decimals": 18,
    "image": "https://rango.vip/i/FlXlyG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03674284,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIGAU",
    "name": "Dignity Gold",
    "isPopular": false,
    "chainId": "1",
    "address": "0x394d14d78850e516fa5eb88f843ef43196e136b0",
    "decimals": 18,
    "image": "https://rango.vip/i/fW3jtS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WIGGER",
    "name": "Wigger",
    "isPopular": false,
    "chainId": "1",
    "address": "0x170dec83c7753aaad20c01a0016b5a2e143990d4",
    "decimals": 18,
    "image": "https://rango.vip/i/fkzG5u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005997,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IOC",
    "name": "Intelligence On Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed1ddc491a2c8b1f7d6e8933580a47e124ea38db",
    "decimals": 18,
    "image": "https://rango.vip/i/koETXH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.048,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FATGUY",
    "name": "FAT GUY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5277f67a2be2e1a241613c357f26ae12458bf2c9",
    "decimals": 18,
    "image": "https://rango.vip/i/yD26dk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005948,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JAB",
    "name": "Jable",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9aaf731799e824a74a4d3a14e6b00bcc28c327db",
    "decimals": 18,
    "image": "https://rango.vip/i/vJl1TL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01120762,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ST-YETH",
    "name": "Staked Yearn Ether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x583019ff0f430721ada9cfb4fac8f06ca104d0b4",
    "decimals": 18,
    "image": "https://rango.vip/i/VNeUZx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2442.33,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GSLAM",
    "name": "GramSlams",
    "isPopular": false,
    "chainId": "1",
    "address": "0xed40ab79a3225902435c26233ed84fb74bd8ffb8",
    "decimals": 18,
    "image": "https://rango.vip/i/7D9RTL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00054266,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TNQ",
    "name": "TNQ Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x47e5c76f155083f1aee39578311a2a5faa938a82",
    "decimals": 6,
    "image": "https://rango.vip/i/3zfHey",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWOT",
    "name": "Swot AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb39364b51d2c97b62b838bc5213b8627eb469101",
    "decimals": 18,
    "image": "https://rango.vip/i/KJXOxv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00034587,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FETH",
    "name": "f x  Protocol Fractional ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53805a76e1f5ebbfe7115f16f9c87c2f7e633726",
    "decimals": 18,
    "image": "https://rango.vip/i/eDhpjR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.027,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MDEX",
    "name": "MasterDEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0610eb7d8ee12d59412da32625d5e273e78ff0b",
    "decimals": 18,
    "image": "https://rango.vip/i/Q0YrVF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EAG",
    "name": "Emerging Assets Group",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa338b5a4bbd8053994bb6c55d770fc2447d66b88",
    "decimals": 18,
    "image": "https://rango.vip/i/II2QTw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01187096,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PARO",
    "name": "Parobot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf7c1caaa2b29458e8f9a6c45348e385351971209",
    "decimals": 18,
    "image": "https://rango.vip/i/dfgGBQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006255,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASTROX",
    "name": "Astro X",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0eb1e72204c42de2263d37db606fb25c21d649b8",
    "decimals": 9,
    "image": "https://rango.vip/i/FJFHRf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001013,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CTRL",
    "name": "CTRL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x556bb0b27e855e6f2cebb47174495b9bbeb97ff1",
    "decimals": 18,
    "image": "https://rango.vip/i/FYZVrR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00781928,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNCLE",
    "name": "PEPE UNCLE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcf01d4485398f3b8a0b177c5225402e2fdb4f997",
    "decimals": 18,
    "image": "https://rango.vip/i/OXfT7N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000175,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONAI",
    "name": "Monai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c282c35b5e1088bb208991c151182a782637699",
    "decimals": 18,
    "image": "https://rango.vip/i/RHFVBA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.055211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MARKS",
    "name": "Marksman",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0a8f4c4f23d72857745e26695dcd8dedf8e349b9",
    "decimals": 18,
    "image": "https://rango.vip/i/g14DPt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.13874,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGE1",
    "name": "DOGE 1",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd721706581d97ecd202bbab5c71b5a85f0f78e69",
    "decimals": 9,
    "image": "https://rango.vip/i/EiCvSt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02112316,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GROK20",
    "name": "Grok2 0",
    "isPopular": false,
    "chainId": "1",
    "address": "0x87d907568a0761ea45d2917e324557920668f224",
    "decimals": 18,
    "image": "https://rango.vip/i/LPvoxe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000879,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RMAV",
    "name": "Rogue MAV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f0f56472c3e5730b1ea2f444e7829288da261e6",
    "decimals": 18,
    "image": "https://rango.vip/i/ZUcjYw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RNG",
    "name": "RuniGun",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c85f830bea7d21c71cbd0047aa6de0d7acf3262",
    "decimals": 18,
    "image": "https://rango.vip/i/m9fjx6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0022666,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XEZETH",
    "name": "Leveraged ezETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e5a5af7ee900d34bcfb70c47023bf1d6be35cf5",
    "decimals": 18,
    "image": "https://rango.vip/i/8dOshW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.305796,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AZEE",
    "name": "SurrealVerse",
    "isPopular": false,
    "chainId": "1",
    "address": "0x686d1596e5632fe0471961e7977e8efe371b0b21",
    "decimals": 18,
    "image": "https://rango.vip/i/769Sos",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00351617,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NAMX",
    "name": "Namx",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeda43fa2f35ea174a7e9b73658b18a7da00adf38",
    "decimals": 18,
    "image": "https://rango.vip/i/MVsiBK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.641112,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "T2T2",
    "name": "T2T2",
    "isPopular": false,
    "chainId": "1",
    "address": "0x390e61f798267fe7aa9bbe61be8bb1776250d44c",
    "decimals": 18,
    "image": "https://rango.vip/i/n0fseJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00030989,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DYL",
    "name": "Dyl",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a8946eda77817126ffe301249f6dc4c7df293c3",
    "decimals": 18,
    "image": "https://rango.vip/i/f7RRVv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011018,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEW",
    "name": "doge in a memes world",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa13edd1a27ab4fb8982c033acb082cdb5f98b79b",
    "decimals": 9,
    "image": "https://rango.vip/i/VcON9a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.4305e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZAPEX",
    "name": "ZapExchange",
    "isPopular": false,
    "chainId": "1",
    "address": "0x396de8bb0a1745b531bf5cd5952539a1b5fe66e0",
    "decimals": 9,
    "image": "https://rango.vip/i/FTM7iH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00032136,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GANG",
    "name": "Shadow Wizard Money Gang",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff00644ca76def7a3f7501a281ffe45934aefbfe",
    "decimals": 9,
    "image": "https://rango.vip/i/KoDBJr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000836,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MYC",
    "name": "Mega Yacht Cult",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd939212f16560447ed82ce46ca40a63db62419b5",
    "decimals": 18,
    "image": "https://rango.vip/i/ZXjzLy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001266,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOOBIFI",
    "name": "Staked BIFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbeef8e0982874e0292e6c5751c5a4092b3e1beef",
    "decimals": 18,
    "image": "https://rango.vip/i/73wsQU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 308.05,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOB",
    "name": "Blob",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72831eebef4e3f3697a6b216e3713958210ae8cd",
    "decimals": 18,
    "image": "https://rango.vip/i/Z8po9r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00075493,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BBOX",
    "name": "BlockBox",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f4a8167ca311a87b0d03aafa44e0d2c3d8a3631",
    "decimals": 18,
    "image": "https://rango.vip/i/mT3W2S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00042736,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BABYX",
    "name": "Baby X",
    "isPopular": false,
    "chainId": "1",
    "address": "0xabd601423a2cd5723cb546acc5c40fb01c3422cf",
    "decimals": 9,
    "image": "https://rango.vip/i/pm5nII",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.001e-15,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INX",
    "name": "InsightX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x67f3086f7823eaf35f5aaadfb2e9b9c5b09578cf",
    "decimals": 18,
    "image": "https://rango.vip/i/uxhlu2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00312746,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGGA",
    "name": "Doggacoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1a8a39f2986cf9688f6dc9e5ee0cc0bc8d5edd67",
    "decimals": 9,
    "image": "https://rango.vip/i/hjRLUQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.79751e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZENO",
    "name": "ZenoCard",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd51e4965ad973e8c1e1f22369bb884e6914b012c",
    "decimals": 9,
    "image": "https://rango.vip/i/UCenTi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020684,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SND",
    "name": "SatNode",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd12b2859227b88c8065b6216e6e1b980d8dfe152",
    "decimals": 18,
    "image": "https://rango.vip/i/DykMmt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00032994,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "СЕРБСКАЯЛЕДИ",
    "name": "Serbian Dancing Lady",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa8c08d8bb15a2d1a4d3b78def9c635ef1e340e16",
    "decimals": 18,
    "image": "https://rango.vip/i/Z7mpWI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.902e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KOCHI",
    "name": "Kochi Ken ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6fba952443be1de22232c824eb8d976b426b3c38",
    "decimals": 9,
    "image": "https://rango.vip/i/yXbftb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.14356e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEGABOT",
    "name": "Megabot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x201b5b64438843553e3c3671810ae671c93c685c",
    "decimals": 18,
    "image": "https://rango.vip/i/rUWpXP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03568887,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CARTMAN",
    "name": "Cartman",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d5777dce2541175adf6d49cadd666f3ab0ac142",
    "decimals": 18,
    "image": "https://rango.vip/i/8HBsHo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.0441e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKITTY",
    "name": "ZKitty Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7df18e4efd6e6f73cfb462937dac40fe42533016",
    "decimals": 18,
    "image": "https://rango.vip/i/JeP2uo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03824445,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EYEBOT",
    "name": "Eyebot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd713f15673b9861b6123840f6e0eba03d6aae51",
    "decimals": 9,
    "image": "https://rango.vip/i/tbz7yi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021951,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PROMPTIDE",
    "name": "PromptIDE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3e66b03d098d0482be9cb3d6999787231a93ed9",
    "decimals": 9,
    "image": "https://rango.vip/i/fiobKz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.59519e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XGN",
    "name": "0xGen",
    "isPopular": false,
    "chainId": "1",
    "address": "0x683c8e87e74f3f8f27c0d2ebd4350fe4dba814ef",
    "decimals": 18,
    "image": "https://rango.vip/i/3kF4s4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00268167,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INWBETH",
    "name": "Inception wbETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda9b11cd701e10c2ec1a284f80820edd128c5246",
    "decimals": 18,
    "image": "https://rango.vip/i/x96sLl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2723.79,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XINU",
    "name": "XINU  ETH ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x47879db9e657e644082071b48e2f33d80f369f02",
    "decimals": 9,
    "image": "https://rango.vip/i/51mFEc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.7515e-14,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DGCS",
    "name": "Aktionariat Green Consensus AG Tokenize",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e1a609ec87cf6477613f515f6eb64ef2d31089a",
    "decimals": 0,
    "image": "https://rango.vip/i/amwfMv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VXR",
    "name": "Vox Royale",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcf16287a869ac8397815aba9b8c962c0f18ba6ea",
    "decimals": 4,
    "image": "https://rango.vip/i/p3O6SB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.384852,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVXPRISMA",
    "name": "Convex Prisma",
    "isPopular": false,
    "chainId": "1",
    "address": "0x34635280737b5bfe6c7dc2fc3065d60d66e78185",
    "decimals": 18,
    "image": "https://rango.vip/i/CK1Qj2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02352484,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOX",
    "name": "DOXcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc57bf43f8ca4458309386f9a3e8246a8cadcca51",
    "decimals": 18,
    "image": "https://rango.vip/i/hY39Ep",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.61923e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLB",
    "name": "Blueberry",
    "isPopular": false,
    "chainId": "1",
    "address": "0x904f36d74bed2ef2729eaa1c7a5b70dea2966a02",
    "decimals": 18,
    "image": "https://rango.vip/i/M5HGuB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00164349,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEEROY",
    "name": "LEEROY JENKINS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e971b5b21367888239f00da16f0a6b0effecb03",
    "decimals": 18,
    "image": "https://rango.vip/i/tbNUOl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000229,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ΜELEM",
    "name": "μElemental",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba10085f901ae4048134e556d579cfd1bfaf89cf",
    "decimals": 18,
    "image": "https://rango.vip/i/5t5sS2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00103709,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCARCE",
    "name": "No Decimal",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8b4d316e40ac4baf08957963210c6cff683a152b",
    "decimals": 0,
    "image": "https://rango.vip/i/4JCmSt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 12.44,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VB",
    "name": "VirtuBlock",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68d1c2a170bac7f73d7680970345fcf8ddec79bb",
    "decimals": 9,
    "image": "https://rango.vip/i/A2Gibf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007618,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPARKO",
    "name": "Sparko",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2c540c3c7be7af98278dc6963e092cd450009d1f",
    "decimals": 18,
    "image": "https://rango.vip/i/BNBDYg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03186856,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CVXFXN",
    "name": "Convex FXN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x183395dbd0b5e93323a7286d1973150697fffcb3",
    "decimals": 18,
    "image": "https://rango.vip/i/mJlP5w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 62.39,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OOFP",
    "name": "OOFP",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2960d83c53085b5631f4d0be4916806e40ef1f3",
    "decimals": 18,
    "image": "https://rango.vip/i/0IMLnT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.068975,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TREE",
    "name": "Tree",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba25b2281214300e4e649fead9a6d6acd25f1c0a",
    "decimals": 18,
    "image": "https://rango.vip/i/fiJ6az",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.139546,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IUCN",
    "name": "IUCN Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0b4663216b812e4a2f0fc2029ff1232958f4bf8c",
    "decimals": 18,
    "image": "https://rango.vip/i/LGOm1x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00021135,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INCR",
    "name": "Increment",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1b9ebb707d87fbec93c49d9f2d994ebb60461b9b",
    "decimals": 18,
    "image": "https://rango.vip/i/X9nnpE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0049128,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EBTC",
    "name": "eBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x661c70333aa1850ccdbae82776bb436a0fcfeefb",
    "decimals": 18,
    "image": "https://rango.vip/i/KTxNnD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55684,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COIND",
    "name": "Dinari COIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x46b979440ac257151ee5a5bc9597b76386907fa1",
    "decimals": 18,
    "image": "https://rango.vip/i/l418pF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOLE",
    "name": "Hole Guys",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb4725590574bb8afae4a3f44f05f9c0f5ebd8f4b",
    "decimals": 18,
    "image": "https://rango.vip/i/6SQYsF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001489,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUCK",
    "name": "Cybertruck",
    "isPopular": false,
    "chainId": "1",
    "address": "0xab5d6508e4726141d29c6074ab366afa03f4ec8d",
    "decimals": 18,
    "image": "https://rango.vip/i/ySdP6Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006853,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "METHX",
    "name": "Eigenpie ETHx",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9a1722b1f4a1bb2f271211ade8e851afc54f77e5",
    "decimals": 18,
    "image": "https://rango.vip/i/AbEuNw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DVNCI",
    "name": "DiVinciPay",
    "isPopular": false,
    "chainId": "1",
    "address": "0x79ca240990ec3f11381a8f80529828aad0628658",
    "decimals": 9,
    "image": "https://rango.vip/i/Ovid1c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00615747,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MODS",
    "name": "Modulus Domain Service",
    "isPopular": false,
    "chainId": "1",
    "address": "0x081a4c60fbc381dc861bae6629c93d835d5f9a89",
    "decimals": 18,
    "image": "https://rango.vip/i/hfGBtb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.2003e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BWL",
    "name": "Blackwater Labs",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe0bd989ec7417374a1c0d9d4fb8b692d1a9e27eb",
    "decimals": 10,
    "image": "https://rango.vip/i/hlIMAS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01206441,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RND",
    "name": "Rand",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8be8b0dde627dd6be9a4b7fcadd04899aabdb2b1",
    "decimals": 6,
    "image": "https://rango.vip/i/To8oqq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.052081,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NAAI",
    "name": "NeoAudit AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7865ec47bef9823ad0010c4970ed90a5e8107e53",
    "decimals": 18,
    "image": "https://rango.vip/i/9dBb0w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0049773,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UTS",
    "name": "Unitus",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b6564b5da73a41d3a66e6558a98fd0e9e1e77ad",
    "decimals": 18,
    "image": "https://rango.vip/i/8N0Fmt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00600032,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SIFU",
    "name": "Sifu Vision",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8dd09822e83313adca54c75696ae80c5429697ff",
    "decimals": 18,
    "image": "https://rango.vip/i/GhQLPs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 72.4,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INT",
    "name": "Intrepid Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2762ba628b962f93498d8893b6e4346140fe96d",
    "decimals": 18,
    "image": "https://rango.vip/i/CisXIc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.24,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IDE",
    "name": "Ide x ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd617a1359086e33ff339ea0b9c6de479a3f5943",
    "decimals": 9,
    "image": "https://rango.vip/i/WXWmah",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001182,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NTD",
    "name": "Neural Tensor Dynamics",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e354f9ab5bcc9fb981f31b794c5fe13f7a89218",
    "decimals": 18,
    "image": "https://rango.vip/i/LGfqqq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00128578,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RETARD",
    "name": "Retard Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9749ac257e5c7ee59a87cd1a2e93fdb9678a64e6",
    "decimals": 18,
    "image": "https://rango.vip/i/YzVukr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04661959,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AICOM",
    "name": "AI COM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d1a74967eca155782edf8e84782c74db33fc499",
    "decimals": 9,
    "image": "https://rango.vip/i/FTDrJM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000234,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BONKI",
    "name": "BONK Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf995771a957c19319a7d8d58b4082b049420340f",
    "decimals": 9,
    "image": "https://rango.vip/i/Ni3S7I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.7569e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPINU",
    "name": "Pepinu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68f108fb7141ffe36b832c5c225d9e7e474bd664",
    "decimals": 18,
    "image": "https://rango.vip/i/al3TDO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001133,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETR",
    "name": "EtheRunes",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf21d69bce43f819cb92b8c23e2a001f91157653",
    "decimals": 18,
    "image": "https://rango.vip/i/WBvnKB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00039142,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POD",
    "name": "The Other Party",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe90ce7764d8401d19ed3733a211bd3b06c631bc0",
    "decimals": 18,
    "image": "https://rango.vip/i/9diibJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.687e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AVATAR",
    "name": "Avatar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x559cc0850361afe1973c0ba5d0a3446c8a5ad678",
    "decimals": 18,
    "image": "https://rango.vip/i/tEdDsf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01479694,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WCD",
    "name": "WcDonalds",
    "isPopular": false,
    "chainId": "1",
    "address": "0x79349edd0b8e83ffaa1af2e6ba0c8ce87731c267",
    "decimals": 9,
    "image": "https://rango.vip/i/Q1kTpU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000277,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MANKRETH",
    "name": "Eigenpie ankrETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5a4a503f4745c06a07e29d9a9dd88ab52f7a505b",
    "decimals": 18,
    "image": "https://rango.vip/i/iz69RP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAIT",
    "name": "Rabbit Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x273b8e7adddcb4de101416300fcd3688c0612a27",
    "decimals": 18,
    "image": "https://rango.vip/i/bqnAw7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001211,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAS",
    "name": "Tao Accounting System",
    "isPopular": false,
    "chainId": "1",
    "address": "0x64d5fea7d2d600918b76159285994d6ed218f264",
    "decimals": 18,
    "image": "https://rango.vip/i/4kSA0w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0048672,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DN",
    "name": "Deez Nuts",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc7937b44532bf4c0a1f0de3a46c79dddb6dd169d",
    "decimals": 18,
    "image": "https://rango.vip/i/GjiHks",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOETH",
    "name": "Eigenpie oETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x310718274509a38cc5559a1ff48c5edbe75a382b",
    "decimals": 18,
    "image": "https://rango.vip/i/b5U1oW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GROC",
    "name": "Grok Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad8f047d9b742565bb9e10c7655bd3ee9c1eab75",
    "decimals": 9,
    "image": "https://rango.vip/i/S63fA8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000863,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHIBA",
    "name": "Chiba Neko",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe9f4f6c8dadb2ac61f31eb1f5171e27d8552df7",
    "decimals": 9,
    "image": "https://rango.vip/i/6nrzTz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01090063,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GPUBOT",
    "name": "GPUBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa069add2d093f9df0e82ab64ec7dd0320cb4f65d",
    "decimals": 18,
    "image": "https://rango.vip/i/7lrxOH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.163366,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RASTO",
    "name": "Rastopyry",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5408d3883ec28c2de205064ae9690142b035fed2",
    "decimals": 9,
    "image": "https://rango.vip/i/BHmA06",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007116,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORDIBOT",
    "name": "OrdiBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c7b199ac2bca0dba8d1785480648f0318b9a7b8",
    "decimals": 9,
    "image": "https://rango.vip/i/pjGAPm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001244,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ENQAI",
    "name": "enqAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x710287d1d39dcf62094a83ebb3e736e79400068a",
    "decimals": 18,
    "image": "https://rango.vip/i/m78Hnn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00955378,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0XBET",
    "name": "0xBET",
    "isPopular": false,
    "chainId": "1",
    "address": "0x78993f9bee8b68f2531a92427595405f294161db",
    "decimals": 18,
    "image": "https://rango.vip/i/idVta7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00174431,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YOYO",
    "name": "Yoyo Market",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4a88ebf6f76b04f5e0e71a351a22e573f636affe",
    "decimals": 18,
    "image": "https://rango.vip/i/N6noqF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0005195,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VITA-FAST",
    "name": "Molecules of Korolchuk IP NFT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6034e0d6999741f07cb6fb1162cbaa46a1d33d36",
    "decimals": 18,
    "image": "https://rango.vip/i/IcOQRI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.48,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PARTY",
    "name": "Party Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa685406eff334b1368318d0325fc2cdc0e7086f1",
    "decimals": 18,
    "image": "https://rango.vip/i/CUHeKT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.05074e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SABR",
    "name": "SatsBridge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ca98c8b217c3b45074834908555d36af2ac6449",
    "decimals": 18,
    "image": "https://rango.vip/i/oG4Mav",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00034136,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "APXETH",
    "name": "Dinero apxETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ba021b0a9b958b5e75ce9f6dff97c7ee52cb3e6",
    "decimals": 18,
    "image": "https://rango.vip/i/nHb7qF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTCMEME",
    "name": "BTCMEME",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5441765d3ab74e0347df52ffab5a69e5146b5d26",
    "decimals": 18,
    "image": "https://rango.vip/i/4IRrkA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000263,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ULS",
    "name": "UNITS LIMITED SUPPLY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0cdbbc7fe1c8da0cc41ba96d7edb4cce5982f23f",
    "decimals": 18,
    "image": "https://rango.vip/i/AyyyDm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00165984,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JUDGE",
    "name": "Judge AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x01202c9a1adfc1475c960c23bdf7530698330fa0",
    "decimals": 18,
    "image": "https://rango.vip/i/CHcHA6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00045026,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRTX",
    "name": "CortexLoop",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36705e789d0d1ba53fd9bb93512722018b0a089e",
    "decimals": 9,
    "image": "https://rango.vip/i/vnSTYB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00020783,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MMG",
    "name": "Meta Minigames",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa187927c9185108458647aeec193ef4a62d3bd80",
    "decimals": 18,
    "image": "https://rango.vip/i/vBcB51",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00154869,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRCBOT",
    "name": "BRC20 BOT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5e05f367a1923b2a886e2f2bc45c2278a0b9b448",
    "decimals": 9,
    "image": "https://rango.vip/i/wXgLKf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001408,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "科太币",
    "name": " Ethereum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdc5e9445169c73cf21e1da0b270e8433cac69959",
    "decimals": 9,
    "image": "https://rango.vip/i/swt8Kk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.82778e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LESLIE",
    "name": "Leslie",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcc9e0bd9438ca0056653d134de794abeaff8c676",
    "decimals": 9,
    "image": "https://rango.vip/i/HkfVej",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000151,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEXED",
    "name": "DEXED",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6dd5f0038474dc29a0adc6ad34d37b0ba53e5435",
    "decimals": 9,
    "image": "https://rango.vip/i/OUPT9s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00301758,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HRZN",
    "name": "Horizon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x13c4b558f6663329c13c838cc0b5b796f7fc0531",
    "decimals": 18,
    "image": "https://rango.vip/i/TUJbqI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01441294,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "POAI",
    "name": "Port AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2056ec69ac5afaf210b851ff74de4c194fcd986e",
    "decimals": 18,
    "image": "https://rango.vip/i/jf6Ca0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001608,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MARK",
    "name": "MoneyArk Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf1eeb83c364ad9ffeb5f97f223c1705d4810033",
    "decimals": 9,
    "image": "https://rango.vip/i/CV6eAU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0008285,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORDIFI",
    "name": "OrdinalsFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x57ca1f39b0757b8610617e90fa9b2980ccebfece",
    "decimals": 9,
    "image": "https://rango.vip/i/ij00rK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013146,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NRDC",
    "name": "Nordic Ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1eb7bd905855c483db19f53c8c4d42db42a159fc",
    "decimals": 18,
    "image": "https://rango.vip/i/jxhFuJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00041169,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MLH",
    "name": "Moolahverse",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdf87270e04bc5ac140e93571d0dd0c6f4a058b41",
    "decimals": 18,
    "image": "https://rango.vip/i/rUuNVt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00210204,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ΜPPG",
    "name": "μPudgyPenguins",
    "isPopular": false,
    "chainId": "1",
    "address": "0x30f7c830e0c2f4bec871df809d73e27ef19eb151",
    "decimals": 18,
    "image": "https://rango.vip/i/7COsAM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02373126,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEMON",
    "name": "Lemon Terminal",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e13914f4fe838e96346ce42f588193eb17d92b6",
    "decimals": 18,
    "image": "https://rango.vip/i/CVg4k0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02156953,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RPILL",
    "name": "Red Pill",
    "isPopular": false,
    "chainId": "1",
    "address": "0x43fe2b0c5485c10e772a1843e32a7642ace5b88c",
    "decimals": 18,
    "image": "https://rango.vip/i/kI5q7N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.2474e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAOS",
    "name": "Dextensor",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe94e8e29bd3db22917e83d019af7babdf8b9adf6",
    "decimals": 9,
    "image": "https://rango.vip/i/4aGERr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01906656,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ORDIBRIDGE",
    "name": "Ordinal Bridge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd3843c6be03520f45871874375d618b3c7923019",
    "decimals": 9,
    "image": "https://rango.vip/i/SwdtOx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00019904,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EPOCH",
    "name": "Epoch Island",
    "isPopular": false,
    "chainId": "1",
    "address": "0x97d0cfeb4fde54b430307c9482d6f79c761fe9b6",
    "decimals": 18,
    "image": "https://rango.vip/i/7djv6a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00440681,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TXN",
    "name": "TXN Club",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7236a7ad67976ee07961ab26ed6f4cd23f7a9bd1",
    "decimals": 18,
    "image": "https://rango.vip/i/8Zc4OV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00333667,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZOOA",
    "name": "Zoopia",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc256f81d35a54c3599b424171d719e9ae87b2e9b",
    "decimals": 18,
    "image": "https://rango.vip/i/GVeLpZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00066876,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOOD",
    "name": "wagmicatgirlkanye420etfmoon1000x",
    "isPopular": false,
    "chainId": "1",
    "address": "0x04815313e9329e8905a77251a1781cfa7934259a",
    "decimals": 18,
    "image": "https://rango.vip/i/ixyuH2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002425,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0XOS",
    "name": "0xOS AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x74588af8de14287e91d89758636d277d66f217b6",
    "decimals": 18,
    "image": "https://rango.vip/i/sc4PcB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001806,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMNIX",
    "name": "OmniBotX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x621879c6239d8ab9b82712fb56e7be880ce0c6ee",
    "decimals": 18,
    "image": "https://rango.vip/i/heixi6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00083195,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YOD",
    "name": "Year of the Dragon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x30d0e4e6fb0330e45a13e1e06260837f27015de5",
    "decimals": 18,
    "image": "https://rango.vip/i/DmmI2k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000257,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WIFPEPEMOG",
    "name": "WIFPEPEMOGINU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f0bbaf936f7ada2aca5b80bed7b655758d66950",
    "decimals": 9,
    "image": "https://rango.vip/i/liErS4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.6992e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THND",
    "name": "Three Hundred AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7567d006f6be77e3d87aa831855cb4102e37b17d",
    "decimals": 18,
    "image": "https://rango.vip/i/GOFQ4Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4554.5,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TCARD",
    "name": "TeleCard",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3e156234501c41a041a178ff547e295609c4769a",
    "decimals": 9,
    "image": "https://rango.vip/i/P3hpSF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012118,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FACTORY",
    "name": "ChainFactory",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd05d90a656fc375ac1478689d7bcd31098f2dd1f",
    "decimals": 18,
    "image": "https://rango.vip/i/MTmJSP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02848798,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THZ",
    "name": "TeraHertz Capital",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a8d8fcb18d132d77373eb02b22d8e7d378f4437",
    "decimals": 18,
    "image": "https://rango.vip/i/lW5gOF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01001655,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BZRX",
    "name": "bZx Protocol Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x56d811088235f11c8920698a204a5010a788f4b3",
    "decimals": 18,
    "image": "https://rango.vip/i/RHJ8LT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01317445,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WLYX",
    "name": "Wrapped LYX  SigmaSwap ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0808e6c4400bde1d70db0d02170b67de05e07ef5",
    "decimals": 18,
    "image": "https://rango.vip/i/NC85uX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.07,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SS",
    "name": "Secret Society",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe4b4c008ff36e3c50c4299c223504a480de9c833",
    "decimals": 9,
    "image": "https://rango.vip/i/EZHg1i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.090193,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRF",
    "name": "Parifi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcffcfdada28ab44d5440301470dcd410e75c4765",
    "decimals": 18,
    "image": "https://rango.vip/i/k1nYbG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01379599,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WTS",
    "name": "WatchTowers AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26a37f5a0200ad622f6db7cd687c78b7049ee841",
    "decimals": 18,
    "image": "https://rango.vip/i/nywbQA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00166381,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RKC",
    "name": "RAKUICHI Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf976fc699eb81a302a29bac6590d1565e8e5da0d",
    "decimals": 18,
    "image": "https://rango.vip/i/fvq4Cc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0004712,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TORO",
    "name": "TORO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xab14f1262ea8d910756828cbe08e6cedfb66360d",
    "decimals": 18,
    "image": "https://rango.vip/i/4UeLMv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00121351,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ECL",
    "name": "ECL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf857c938829c2a53557fb3fbb1c85d10a5227e03",
    "decimals": 18,
    "image": "https://rango.vip/i/PHRNGy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00993157,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GTRON",
    "name": "Gravitron",
    "isPopular": false,
    "chainId": "1",
    "address": "0x20bc71ddf242b88502d9819e02d45ad0d4a586c8",
    "decimals": 9,
    "image": "https://rango.vip/i/dISD2h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009907,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UEFN",
    "name": "United Emirates Of Fun",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfeff7b68bc540826da22b296c82a4b8b6b845f41",
    "decimals": 18,
    "image": "https://rango.vip/i/j025h7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00051971,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KMN",
    "name": "KASPAMINING",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbded8a4dc74a940eab68703167db89b1712b68ea",
    "decimals": 9,
    "image": "https://rango.vip/i/ZeCSCK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00029909,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOKA",
    "name": "TOKA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a85556a6701a02e75bc4de8ec340c6de1b29f72",
    "decimals": 18,
    "image": "https://rango.vip/i/hZi3pw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006206,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JPGD",
    "name": "JPEG d",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce722f60f35c37ab295adc4e6ba45bcc7ca89dd6",
    "decimals": 18,
    "image": "https://rango.vip/i/XdsLam",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00030383,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BONKBEST",
    "name": "BONKBEST",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd836d22531d810f192ba6bd0ba3c28c35d4606c2",
    "decimals": 9,
    "image": "https://rango.vip/i/Mt6IpC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.09931e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATAI",
    "name": "ArtemisAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x92098551e613dfdcd4d7c7b2c35615709e4e0397",
    "decimals": 18,
    "image": "https://rango.vip/i/ClOIg9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00044925,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "404BLOCKS",
    "name": "404Blocks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x45b3cf56896c4547426a4145ad1d0ae971120214",
    "decimals": 18,
    "image": "https://rango.vip/i/aD5rTm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 377.76,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OBLUE",
    "name": "Blueprint oBLUE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xedb73d4ed90be7a49d06d0d940055e6d181d22fa",
    "decimals": 18,
    "image": "https://rango.vip/i/qfVEiX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEECASINO",
    "name": "beecasinogames",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd8c163cc0ec3a53ce6bec89e665fb97cce51c42",
    "decimals": 9,
    "image": "https://rango.vip/i/L1QHUb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00008237,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BARK",
    "name": "Bark Gas Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72fc1c1c926bd26712f62e7485392cd405478f05",
    "decimals": 9,
    "image": "https://rango.vip/i/KQnO9g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00184022,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FGPU",
    "name": "FlexGPU",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0b6addde76be6a09231edc12a5e3794c11f594f",
    "decimals": 18,
    "image": "https://rango.vip/i/L8yqE0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001195,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NED",
    "name": "NED",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8d9d725aaa3f6236763ff548051657a342c37623",
    "decimals": 18,
    "image": "https://rango.vip/i/yywiJt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001606,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TADPOLE",
    "name": "Froggy Friends",
    "isPopular": false,
    "chainId": "1",
    "address": "0xecd48f326e70388d993694de59b4542ce8af7649",
    "decimals": 18,
    "image": "https://rango.vip/i/AMMwRF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.97,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MNRCH",
    "name": "Monarch",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c061d18d2b5bbfbe8a8d1eeb9ee27efd544cc5d",
    "decimals": 18,
    "image": "https://rango.vip/i/57rJEU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.44,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STPETH",
    "name": "Stake Together",
    "isPopular": false,
    "chainId": "1",
    "address": "0x218de5e6324c5351c3a2bf0c40d76f585b8de04d",
    "decimals": 18,
    "image": "https://rango.vip/i/ySAhTP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIA",
    "name": "Olympia AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ab51734fc5d5fdd8abb58941840a5df1e3f3a99",
    "decimals": 9,
    "image": "https://rango.vip/i/Ns3sOJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010691,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRSCT",
    "name": "Transactra Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe98796e0af4ababd716508429e51ff9ac1bb4d5",
    "decimals": 9,
    "image": "https://rango.vip/i/Lojowk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00018959,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CFSH",
    "name": "ChessFish",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe2976a66e8cef3932cdaeb935e114dcd5ce20f20",
    "decimals": 18,
    "image": "https://rango.vip/i/Bcmzou",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.35,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GARBAGE",
    "name": "Garbage",
    "isPopular": false,
    "chainId": "1",
    "address": "0x619e398858a3110df4d89056a15a40338a01e65f",
    "decimals": 18,
    "image": "https://rango.vip/i/X6feh7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033737,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MWAVE",
    "name": "MeshWave",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4447743d42680f7fbb56e87b3617463113ca649e",
    "decimals": 9,
    "image": "https://rango.vip/i/dT4URZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00044659,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GROK15",
    "name": "Grok1 5",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa711bcc2b6f5c4fc3dfaccc2a01148765cbbab1c",
    "decimals": 9,
    "image": "https://rango.vip/i/3upgtv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.5069e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MACHINA",
    "name": "Church of the Machina",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5d56b6581d2e7e7574adce2dc593f499a53d7505",
    "decimals": 18,
    "image": "https://rango.vip/i/9clnmB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002014,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DRAGONX",
    "name": "DragonX win",
    "isPopular": false,
    "chainId": "1",
    "address": "0x96a5399d07896f757bd4c6ef56461f58db951862",
    "decimals": 18,
    "image": "https://rango.vip/i/aaDEas",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001081,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DRBT",
    "name": "DeFi Robot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x661013bb8d1c95d86d9c85f76e9004561f1bb36f",
    "decimals": 18,
    "image": "https://rango.vip/i/x9vDQu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00060921,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INTERN",
    "name": "Interns",
    "isPopular": false,
    "chainId": "1",
    "address": "0x435998003ccb7abeaa392494c89f7799fe241db5",
    "decimals": 18,
    "image": "https://rango.vip/i/WjMFXO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002749,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKIN",
    "name": "zkInfra",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd57187e56e5b31b4d7813d7ceea1e9f9b97ee82f",
    "decimals": 18,
    "image": "https://rango.vip/i/vuNzrc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02387274,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WBAI",
    "name": "Wrapped Balance AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8ccd897ca6160ed76755383b201c1948394328c7",
    "decimals": 9,
    "image": "https://rango.vip/i/s9PrK1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.45,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PINCHI",
    "name": "Da Pinchi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf4cccfda0781ae019a9d4e1853dcd3e288daaa89",
    "decimals": 9,
    "image": "https://rango.vip/i/NR2e8v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013699,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TFBILL",
    "name": "Adapt3r Digital Treasury Bill Fund",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1f3aca66403d29b909605040c30ae1f1245d14c",
    "decimals": 6,
    "image": "https://rango.vip/i/sly7n1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WINS",
    "name": "Trophy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5f48d1fd6814cd1cd38aeb895755e57d519196d1",
    "decimals": 18,
    "image": "https://rango.vip/i/OXugo6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03012958,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FEDAI",
    "name": "Federal AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd4318fa09c45cfb6355ded6085b0d698b64ec1cd",
    "decimals": 8,
    "image": "https://rango.vip/i/luvmjd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00077584,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIBBLE",
    "name": "Dibbles",
    "isPopular": false,
    "chainId": "1",
    "address": "0x891de5f139791ddf9dbabf519cfe2a049f8fc6d3",
    "decimals": 18,
    "image": "https://rango.vip/i/CiBIfD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.4239e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NODIFI",
    "name": "NodifiAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4727c419acfb49c8660736506ca023a4e8509e0",
    "decimals": 18,
    "image": "https://rango.vip/i/woF4Jh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01121496,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRND",
    "name": "TrendAppend",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc106b98c4d0b3f1c92da0e9a6089e9c63ceacbb0",
    "decimals": 9,
    "image": "https://rango.vip/i/woGAeg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00142751,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PABLO",
    "name": "Pablo DeFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x926ff6584b5905cc793cfb19bfc0ad6443671f47",
    "decimals": 18,
    "image": "https://rango.vip/i/38nltO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017385,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "喵喵喵喵喵",
    "name": "我们一起学猫叫，一起喵喵喵喵喵",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77be1ba1cd2d7a63bffc772d361168cc327dd8bc",
    "decimals": 9,
    "image": "https://rango.vip/i/4Q2vTm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.31436e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LNDRY",
    "name": "LNDRY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x338be6a72db57a239f0d71d0ec4e5b1e38762e9f",
    "decimals": 18,
    "image": "https://rango.vip/i/F0LGgy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03533659,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYPHER",
    "name": "Cypher AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8110af6bd2af3f5c4586032ff813c8934451abbe",
    "decimals": 9,
    "image": "https://rango.vip/i/o8ROzJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013036,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PAIRED",
    "name": "PairedWorld",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa7f3508cfcf054cc9cf1440580b78784e07382db",
    "decimals": 18,
    "image": "https://rango.vip/i/NIvxCX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0064452,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEFY",
    "name": "BEFY",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39a9728fb398583154e6cc5e3defa60908f58e2f",
    "decimals": 18,
    "image": "https://rango.vip/i/DTu75U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000964,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TOILET",
    "name": "Skibidi Toilet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x344d6117ae0984f3afdd23e593f92d95a83dcd0e",
    "decimals": 9,
    "image": "https://rango.vip/i/Nap4ka",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00452772,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LDY",
    "name": "Ledgity Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x482df7483a52496f4c65ab499966dfcdf4ddfdbc",
    "decimals": 18,
    "image": "https://rango.vip/i/kRK9ht",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02570382,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MACKE",
    "name": "Mackerel",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd69a0a9682f679f50e34de40105a93bebb2ff43d",
    "decimals": 18,
    "image": "https://rango.vip/i/E5kqx0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02719616,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DADA",
    "name": "龘龘 DáDá",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c688327c9371bb3bd69f6e1f1a6d8c9ca0880a7",
    "decimals": 18,
    "image": "https://rango.vip/i/OJ0AQV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.1994e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOHOT",
    "name": "SOHOTRN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3f8143212871014b472ea83285af7f25928dee4",
    "decimals": 9,
    "image": "https://rango.vip/i/1NRAEb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003728,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARBOT",
    "name": "Alpha Radar AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x723696965f47b990dff00064fcaca95f0ee01123",
    "decimals": 18,
    "image": "https://rango.vip/i/gp4m0v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00275389,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USTB",
    "name": "Superstate Short Duration U S  Governme",
    "isPopular": false,
    "chainId": "1",
    "address": "0x43415eb6ff9db7e26a15b704e7a3edce97d31c4e",
    "decimals": 6,
    "image": "https://rango.vip/i/lrbeVf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.002,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$HOLA",
    "name": "HOLA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb81914f05daf95802eb30726a399733e0696cd79",
    "decimals": 18,
    "image": "https://rango.vip/i/ZNHfUR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.15947e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RSFT",
    "name": "ROYAL SMART FUTURE TOKEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x78223d31298107f3e310b09797b07967832046a6",
    "decimals": 18,
    "image": "https://rango.vip/i/d8c5Mu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.05019,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "O404",
    "name": "OMNI404",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd5c02bb3e40494d4674778306da43a56138a383e",
    "decimals": 18,
    "image": "https://rango.vip/i/Mso5fh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 725.87,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HASH",
    "name": "HashMind",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa849cd6239906f23b63ba34441b73a5c6eba8a00",
    "decimals": 18,
    "image": "https://rango.vip/i/FDlI8a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.06925,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRIMEETH",
    "name": "Prime Staked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6ef3d766dfe02dc4bf04aae9122eb9a0ded25615",
    "decimals": 18,
    "image": "https://rango.vip/i/jO6otf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2486.48,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DENCH",
    "name": "DENCHCOIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b7265d153886a7dc717e815862acde6ff7b5bc8",
    "decimals": 18,
    "image": "https://rango.vip/i/ekLvR9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VETH",
    "name": "Vector ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38d64ce1bdf1a9f24e0ec469c9cade61236fb4a0",
    "decimals": 18,
    "image": "https://rango.vip/i/Fh2NiW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2335.05,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TENSOR",
    "name": "Wrapped Hypertensor",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16a3543fa6b32cac3b0a755f64a729e84f89a75c",
    "decimals": 18,
    "image": "https://rango.vip/i/BkyPWS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.46,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TGPU",
    "name": "TonGPU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4346139e71ba7b4b6abd405782703006cc180988",
    "decimals": 18,
    "image": "https://rango.vip/i/hBqplb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003336,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VITALEK",
    "name": "vitalek buteren",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf5264e1673c9365e7c5d4d1d8b440bbf131ff435",
    "decimals": 18,
    "image": "https://rango.vip/i/p3rK3I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002722,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRACER",
    "name": "Coinracer Reloaded",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c92c0295807f1f7c0726cf51a1d26298563f14a",
    "decimals": 18,
    "image": "https://rango.vip/i/ig7x4v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00075325,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ROYALE",
    "name": "Duel Royale",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44d03e7ee25caee19172eaddf5fbfbff07990c7e",
    "decimals": 18,
    "image": "https://rango.vip/i/2n9iTd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01374326,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WUSDM",
    "name": "Wrapped USDM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x57f5e098cad7a3d1eed53991d4d66c45c9af7812",
    "decimals": 18,
    "image": "https://rango.vip/i/NKgEVC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOON",
    "name": "HighNoon",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd20e1b78c353877a25274c85fb5566277e5f60e",
    "decimals": 18,
    "image": "https://rango.vip/i/HfplPT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.115325,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LAURION",
    "name": "Laurion 404",
    "isPopular": false,
    "chainId": "1",
    "address": "0x61db59daf2af68310fffa003d3b2f452260b673c",
    "decimals": 18,
    "image": "https://rango.vip/i/CsCJkD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.2,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOKEN",
    "name": "Hokkaido Ken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x29e9b047d506f75085533b7b7f53e8de6b43b86f",
    "decimals": 9,
    "image": "https://rango.vip/i/iyGYhr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.422e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COFFEE",
    "name": "Coffee Club Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa3b3beaf9c0a6160a8e47f000c094d34121f1a57",
    "decimals": 18,
    "image": "https://rango.vip/i/I8UWV9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00108587,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AKEN",
    "name": "ALTOKEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe3aaf3b1dd087331ec68c4dd86e8fe542598d5e",
    "decimals": 18,
    "image": "https://rango.vip/i/UG53Nm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001462,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STTAO",
    "name": "Tensorplex Staked TAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb60acd2057067dc9ed8c083f5aa227a244044fd6",
    "decimals": 9,
    "image": "https://rango.vip/i/MH1GmP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 251.99,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EVIRE",
    "name": "Evire",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ceec758dfe5ef8c32cde7b2259cc79b1891e8ed",
    "decimals": 18,
    "image": "https://rango.vip/i/sQw85f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033967,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNITAO",
    "name": "UNITAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19efa7d0fc88ffe461d1091f8cbe56dc2708a84f",
    "decimals": 18,
    "image": "https://rango.vip/i/usLhCA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0044895,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPLY",
    "name": "ARENA SUPPLY CRATE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x909c1b012d1a56584484bc39969da13ee11e8fa6",
    "decimals": 18,
    "image": "https://rango.vip/i/zmNb3u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 39.49,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GTM",
    "name": "ColonizeMars",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe8b1e79d937c648ce1fe96e6739ddb2714058a18",
    "decimals": 18,
    "image": "https://rango.vip/i/NqzYBS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014581,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMUSD",
    "name": "OpenMoney USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd3043d66afe00344c115f7f81d18277c5c718ff8",
    "decimals": 6,
    "image": "https://rango.vip/i/KB6CBE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999393,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MPETH",
    "name": "mpETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x48afbbd342f64ef8a9ab1c143719b63c2ad81710",
    "decimals": 18,
    "image": "https://rango.vip/i/xY9wNT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2480.97,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INVA",
    "name": "InnoviaTrust",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1814b8a33446549ed5766ab3250b670498699bd6",
    "decimals": 18,
    "image": "https://rango.vip/i/XEjmG4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00161009,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BONZI",
    "name": "Bonzi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd6175692026bcd7cb12a515e39cf0256ef35cb86",
    "decimals": 18,
    "image": "https://rango.vip/i/ZDWEXW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0023886,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DFP2",
    "name": "DefiPlaza",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f57430a6ceda85a67121757785877b4a71b8e6d",
    "decimals": 18,
    "image": "https://rango.vip/i/YomEt1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01298724,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRAI",
    "name": "BribeAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbec771d15f7e67bc0bb4571c7eb409228cc6fef9",
    "decimals": 18,
    "image": "https://rango.vip/i/gxc8xk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.052253,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAP",
    "name": "DApp AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbf72ee725f9b06dc564324774801acebad061946",
    "decimals": 18,
    "image": "https://rango.vip/i/ANyPpk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00393844,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEEPL",
    "name": "DeepL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd0bcb2c156a3507670f9bedc319a6409c41ba68e",
    "decimals": 9,
    "image": "https://rango.vip/i/cbqBNq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001552,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SENDEX",
    "name": "Sendex AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb93746dc7a8bf5e381ffb278085b1f62bd0a1fc8",
    "decimals": 9,
    "image": "https://rango.vip/i/x9UeOu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.072,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UTX",
    "name": "UTIX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x958a03181806cb8807bef40e864e55f89b3393d3",
    "decimals": 18,
    "image": "https://rango.vip/i/Dz7Yus",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01319395,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ESTA",
    "name": "Egostation",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce5464b006a10d20c5fc56a19618212a129eed45",
    "decimals": 18,
    "image": "https://rango.vip/i/Od6kJz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 252.55,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SVEC",
    "name": "Staked Vector",
    "isPopular": false,
    "chainId": "1",
    "address": "0x66d5c66e7c83e0682d947176534242c9f19b3365",
    "decimals": 9,
    "image": "https://rango.vip/i/XJ6Afr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WFCA",
    "name": "World Friendship Cash",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae4533189c7281501f04ba4b7c37e3aded402902",
    "decimals": 18,
    "image": "https://rango.vip/i/Xz4KTO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.067282,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRENDGURU",
    "name": "TrendGuru",
    "isPopular": false,
    "chainId": "1",
    "address": "0x559babc46a6ab52fd11b2790bce01f0a434a8b4e",
    "decimals": 18,
    "image": "https://rango.vip/i/FQOtQj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000289,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHU",
    "name": "Shutter",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe485e2f1bab389c08721b291f6b59780fec83fd7",
    "decimals": 18,
    "image": "https://rango.vip/i/OIuUyB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01424473,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ERRDB",
    "name": "Dibbles 404",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2907dcbc51191a5d7494077d1156fffc34f110ab",
    "decimals": 18,
    "image": "https://rango.vip/i/aUyA2Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 33.6,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZYPTO",
    "name": "Zypto Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a65cb87f596caf31a4932f074c59c0592be77d7",
    "decimals": 18,
    "image": "https://rango.vip/i/UPAUMm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01653143,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XEROAI",
    "name": "Xero AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc5842df170b8c8d09eb851a8d5db3dfa00669e3f",
    "decimals": 9,
    "image": "https://rango.vip/i/rkzLKE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00941592,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCD",
    "name": "Arcade",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe020b01b6fbd83066aa2e8ee0ccd1eb8d9cc70bf",
    "decimals": 18,
    "image": "https://rango.vip/i/d6AvX2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03432643,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEP",
    "name": "Kelp Earned Points",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e3a59427b1d87db234dd4ff63b25e4bf94672f4",
    "decimals": 18,
    "image": "https://rango.vip/i/fFS3jc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.066156,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IRYDE",
    "name": "iRYDE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x74fe27e70db10147f8b6b38b3c9d12bbdcf3b5af",
    "decimals": 18,
    "image": "https://rango.vip/i/HrtjWU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.32002e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WPAW",
    "name": "Wrapped PAW",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc11158c5da9db1d553ed28f0c2ba1cbedd42cfcb",
    "decimals": 18,
    "image": "https://rango.vip/i/0wKcgH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.0391e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ELDG",
    "name": "Everlodge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdda31d354a519ecfb0bc2a536b5e7be147c0f7f4",
    "decimals": 18,
    "image": "https://rango.vip/i/MtwhHM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00370465,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$ALPHA",
    "name": "Alphabyte",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7ba441772fdd56dda03e7e91284db2ea2ba237da",
    "decimals": 9,
    "image": "https://rango.vip/i/cmMKK5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005589,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOOD",
    "name": "Bloodboy",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6abaf438f098f75c5892e1fabf08b1896c805967",
    "decimals": 9,
    "image": "https://rango.vip/i/edOwHz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00029475,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FIAS",
    "name": "LitCraft FIAS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1d64231e179f074baa3f22d44a6ca3a2670b2709",
    "decimals": 18,
    "image": "https://rango.vip/i/olqcCl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01110299,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XFOUR",
    "name": "0x404",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeb4628418e1acbba62bc72c9b7a53b1865ff283d",
    "decimals": 18,
    "image": "https://rango.vip/i/SpnvlQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00365715,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TALYS",
    "name": "TALYS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3ea4055ab599f5cbb580d2c5e56d2fcff7db98cb",
    "decimals": 18,
    "image": "https://rango.vip/i/QdIsKb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013374,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AIMEE",
    "name": "Aimee",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62cf53a8d024c785e6bd49b83d94a74e1f9c48b1",
    "decimals": 9,
    "image": "https://rango.vip/i/QbrK8h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006751,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MOSETH",
    "name": "Eigenpie OsETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x352a3144e88d23427993938cfd780291d95ef091",
    "decimals": 18,
    "image": "https://rango.vip/i/XOCEdc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KBT",
    "name": "KoinBay Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41b13e815308485f7f1af5afcc64a01519085609",
    "decimals": 8,
    "image": "https://rango.vip/i/qOO6m6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAGYU",
    "name": "Wagyu",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf76c15cbf1fa9d60a7af39e660d6d7c62ef8021e",
    "decimals": 18,
    "image": "https://rango.vip/i/XQbCQF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02803415,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAOX",
    "name": "TAOx",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b96c78d4472031f1fbed2d1c4bd3895e9212344",
    "decimals": 18,
    "image": "https://rango.vip/i/gbkzSL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00040012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGL",
    "name": "DogLibre",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd5eb7e91ae88ea2550f9bfd04208399c95df4dc7",
    "decimals": 18,
    "image": "https://rango.vip/i/WlhJZ1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NDS",
    "name": "NodeStation AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3824255df9eabc9347abcaa5872b1763fe9d47bc",
    "decimals": 9,
    "image": "https://rango.vip/i/JIs4gy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009646,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BC",
    "name": "Blood Crystal",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4b6d036d0bc62a633acca6d10956e9dbbb16748f",
    "decimals": 18,
    "image": "https://rango.vip/i/3eFyx1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00938848,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAMBO",
    "name": "Samurai Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d827543d120f49f0614ca1cb2fa18f96ac817f0",
    "decimals": 18,
    "image": "https://rango.vip/i/Tx6SPW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010319,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHINU",
    "name": "Ethereum Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1ef846ce0da79d8d4e111bf8c5117cd1209a0478",
    "decimals": 8,
    "image": "https://rango.vip/i/slqird",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001032,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOB",
    "name": "Secured On Blockchain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x142f4330ab3eda738cb373791c2e99cc325bed20",
    "decimals": 9,
    "image": "https://rango.vip/i/LQOAcF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.686319,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AZURE",
    "name": "Azure Wallet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1f769203d2abcb78f5a77dd15c0078c50fb13287",
    "decimals": 18,
    "image": "https://rango.vip/i/mjR2tH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00061816,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAVX",
    "name": "WAVX Exchange",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe2808d7298f0d4111ea1773d3320f5d80eab83e8",
    "decimals": 18,
    "image": "https://rango.vip/i/UN5ZND",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.151912,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAH",
    "name": "TaoHarvest",
    "isPopular": false,
    "chainId": "1",
    "address": "0xefb20cd759f617ab59b758a1f06de02feb5e8bd5",
    "decimals": 18,
    "image": "https://rango.vip/i/szZmD0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024976,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEONAI",
    "name": "NeonAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x391fb9429d8685d5d0f14a185fc0820c6ac2817e",
    "decimals": 9,
    "image": "https://rango.vip/i/xLc0v7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013367,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRAI",
    "name": "Produce AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e6d98de664a28a32b584c72e606ff7dea898dc6",
    "decimals": 18,
    "image": "https://rango.vip/i/SLuiEf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00345146,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DMAI",
    "name": "DegenMasters AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x05f72d4555e71ef3f5862627828fc2f830d3ccb1",
    "decimals": 18,
    "image": "https://rango.vip/i/R05wXz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001039,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAOBOT",
    "name": "tao bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x49fb8ad7578148e17c3ef0c344ce23a66ed372c4",
    "decimals": 18,
    "image": "https://rango.vip/i/vOzRcG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03882779,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NHUB",
    "name": "NodeHUB",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff055ff1ec9aa85f14e757d769f9d52d023f2e3b",
    "decimals": 18,
    "image": "https://rango.vip/i/6ufuKm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017668,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RUDES",
    "name": "RUNodE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6ab98d9e04cfb57c6b6e9800a5a43a9946a6523d",
    "decimals": 18,
    "image": "https://rango.vip/i/sPXSVc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01002012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VRD",
    "name": "Viridis Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf25304e75026e6a35fedca3b0889ae5c4d3c55d8",
    "decimals": 18,
    "image": "https://rango.vip/i/zgWI5q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002026,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DINOSHI",
    "name": "DINOSHI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x249fc40d68a3a55dac335550b64c1a03b4c0ed72",
    "decimals": 18,
    "image": "https://rango.vip/i/tEoeKy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.065244,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMAI20",
    "name": "SempsunAi2 0",
    "isPopular": false,
    "chainId": "1",
    "address": "0x02a97fd243b8301bcd6ba3a87693162d6bd130bd",
    "decimals": 9,
    "image": "https://rango.vip/i/JBHVYP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.148e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAOP",
    "name": "TAOPlay",
    "isPopular": false,
    "chainId": "1",
    "address": "0x72cdb56e9d96c64fb52e8cdeda7475f3aa843f59",
    "decimals": 18,
    "image": "https://rango.vip/i/VLRhas",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00151332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SYNTHAI",
    "name": "SynthAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x86b5e2642d094078ad7fe4e0a61f1f4eb4e3280c",
    "decimals": 9,
    "image": "https://rango.vip/i/x0TOJP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00005295,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0XSEARCH",
    "name": "Search",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa816739edaac4ebf329219d4ef575085cc28037",
    "decimals": 18,
    "image": "https://rango.vip/i/xSlHaQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01121643,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRATE",
    "name": " CRATE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa1147413cbf11737eff228fb0073c5ce101e9d87",
    "decimals": 18,
    "image": "https://rango.vip/i/SjIsZ9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0002389,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLD",
    "name": "Cloud AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38604d52d3a89b16a90053ae0c6d009504a9a5b8",
    "decimals": 10,
    "image": "https://rango.vip/i/hbRFde",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01829835,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TTF",
    "name": "Track The Funds Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e32b8a41f2e86a3ee198912ac8d756c84295b40",
    "decimals": 9,
    "image": "https://rango.vip/i/ohHrNX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00947995,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLOBS",
    "name": "blobs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x39de85301c78f4d623e5c05cde2fd119a3a92cd9",
    "decimals": 9,
    "image": "https://rango.vip/i/4J5tnu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.4158e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PADRE",
    "name": "Padre",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb36cf340a35f9860d0bb59afb0355580f0000dad",
    "decimals": 18,
    "image": "https://rango.vip/i/BKIQAA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01058678,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QUANTUM",
    "name": "QUANTUM HUB",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7086e014fd59e66567a986e7b401a5810c2361ce",
    "decimals": 9,
    "image": "https://rango.vip/i/VDry9r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024485,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MLSETH",
    "name": "Eigenpie LsETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa939c02dba8f237b40d2a3e96ad4252b00bb8a72",
    "decimals": 18,
    "image": "https://rango.vip/i/YBQ46t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MGLS",
    "name": "Meme Moguls",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda251891e21e6edb0e6828e77621c7b98ea4e8ba",
    "decimals": 18,
    "image": "https://rango.vip/i/At5jBY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009667,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "721",
    "name": " CryptoPunks 721",
    "isPopular": false,
    "chainId": "1",
    "address": "0x609c117183c9264af6ee54fe3f290f212b31afbf",
    "decimals": 18,
    "image": "https://rango.vip/i/uBminj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.121612,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CLOSEDAI",
    "name": "Super Closed Source for Maximum Profit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x571d9b73dc04ed88b4e273e048c8d4848f83b779",
    "decimals": 9,
    "image": "https://rango.vip/i/3FKERO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002109,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BETZ",
    "name": "Bet Lounge",
    "isPopular": false,
    "chainId": "1",
    "address": "0x134359b7c852c82e4ebdd16a61020e6b81dd6a6b",
    "decimals": 18,
    "image": "https://rango.vip/i/1wqwni",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013523,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUSION",
    "name": "Fusion Ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9094c15f2f535a765e8a2dac20b05148be7044cd",
    "decimals": 18,
    "image": "https://rango.vip/i/T80wiS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001332,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOCAI",
    "name": "LocalAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x56b8be7c2d3ffe0d8d6feb4d4eb4650c3ea10bb6",
    "decimals": 18,
    "image": "https://rango.vip/i/E9ezZQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00206263,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GSFY",
    "name": "Gasify AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba28a90e44ade1e24d5dd6f31cb9a71756ee65dd",
    "decimals": 8,
    "image": "https://rango.vip/i/4ksqq9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00822188,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LTS",
    "name": "LinkTensor",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4f45acfd7361e283170e28394bbe18395407e0f0",
    "decimals": 9,
    "image": "https://rango.vip/i/1P8TKi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00139485,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCX",
    "name": "Architex",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e4d27d772099e18900caf5dcc36cb612dcee886",
    "decimals": 18,
    "image": "https://rango.vip/i/tEqbmO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.435271,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HELGA",
    "name": "Helga Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x78e3b2ee11950df78a35fd924e92fbb8d1403780",
    "decimals": 18,
    "image": "https://rango.vip/i/8MqrXZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.23e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPC",
    "name": "OpClouds",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2a8bf9e7c91c6e3237095e07eb3defbce358df00",
    "decimals": 18,
    "image": "https://rango.vip/i/JRGlIO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00030099,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAOVM",
    "name": "TAOVM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x49e1cc8d19e4c52bde82412f900f35ea5e9b5901",
    "decimals": 18,
    "image": "https://rango.vip/i/TtGasl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00041714,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DWIF",
    "name": "dragon wif hat",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd775997452923437ca96065ba15ed02f4a33ed39",
    "decimals": 18,
    "image": "https://rango.vip/i/94uMzS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002997,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTC2X",
    "name": "Index Coop Bitcoin 2x Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2ac55ca3bbd2dd1e9936ec640dcb4b745fde759",
    "decimals": 18,
    "image": "https://rango.vip/i/cL1Txy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 74.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETH2X",
    "name": "Index Coop Ethereum 2x Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65c4c0517025ec0843c9146af266a2c5a2d148a2",
    "decimals": 18,
    "image": "https://rango.vip/i/UoGa7g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 34.56,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LTAO",
    "name": "LinkTao",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbf7bc9e63635dc11b335d52b0349d0100a53a1a7",
    "decimals": 9,
    "image": "https://rango.vip/i/GIevZK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010008,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UN",
    "name": "UNIT DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x93109af5638be68ed2d0e094f618777ff1051d28",
    "decimals": 18,
    "image": "https://rango.vip/i/d04nFo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PNX",
    "name": "PhenX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd166b7d9824cc5359360b47389aba9341ce12619",
    "decimals": 9,
    "image": "https://rango.vip/i/Ux4ST2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.291659,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TSAI",
    "name": "TesseractAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1c8d908f0e495cf6d8459547d1d28b72bf04bf2",
    "decimals": 18,
    "image": "https://rango.vip/i/xXSeDg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00046695,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHARX",
    "name": "RaidSharksBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x81994dfbbe69c9e03226c2ebe62f7352dc3db812",
    "decimals": 18,
    "image": "https://rango.vip/i/UhjZUB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00091011,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TAOSHARD",
    "name": "TAO Subnet Sharding",
    "isPopular": false,
    "chainId": "1",
    "address": "0x693170bd3c37dcd46168d8b399aa7551a32de2af",
    "decimals": 18,
    "image": "https://rango.vip/i/OX8lAg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001239,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HASH",
    "name": "HashPower AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0c79e09497c9862479c9e6499c61342b5fe3e6b2",
    "decimals": 9,
    "image": "https://rango.vip/i/J5kyNR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00330067,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "21SOL",
    "name": "21 co Wrapped SOL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb80a1d87654bef7ad8eb6bbda3d2309e31d4e598",
    "decimals": 9,
    "image": "https://rango.vip/i/zsb2wy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "21BNB",
    "name": "21 co Wrapped BNB",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1be9d03bfc211d83cff3abdb94a75f9db46e1334",
    "decimals": 8,
    "image": "https://rango.vip/i/yPpsHz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PIXE",
    "name": "Pixel",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a26edf3bbc9f154ca9175216ceb9812f5305e6e",
    "decimals": 18,
    "image": "https://rango.vip/i/9LuWOM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00083036,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OCP404",
    "name": "OnChain Pepe 404",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb87b96868644d99cc70a8565ba7311482edebf6e",
    "decimals": 18,
    "image": "https://rango.vip/i/H6WM3Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 465.44,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PGENZ",
    "name": "Pigeon Park",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2d17b511a85b401980cc0fed15a8d57fdb8eec60",
    "decimals": 18,
    "image": "https://rango.vip/i/BizZLK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.6633e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRACK",
    "name": "Tracker AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda558a295e8d2c1438f7b4806e283940dec7e6db",
    "decimals": 9,
    "image": "https://rango.vip/i/JSNZew",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00403397,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STEUR",
    "name": "Angle Staked EURA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x004626a008b1acdc4c74ab51644093b155e59a23",
    "decimals": 18,
    "image": "https://rango.vip/i/NEJJwM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.16,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRDM",
    "name": "TradeMaster ninja",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc8dfb57d83bf561457b1a3f6ce22956bb554bcab",
    "decimals": 18,
    "image": "https://rango.vip/i/HxMWmm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01723611,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "21AVAX",
    "name": "21 co Wrapped AVAX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x399508a43d7e2b4451cd344633108b4d84b33b03",
    "decimals": 18,
    "image": "https://rango.vip/i/zELxAz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STUSD",
    "name": "Angle Staked USDA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0022228a2cc5e7ef0274a7baa600d44da5ab5776",
    "decimals": 18,
    "image": "https://rango.vip/i/0NXKaN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GVEC",
    "name": "Governance VEC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb2d007293a421471586d6bc3fbfeb1990fdfa2e6",
    "decimals": 18,
    "image": "https://rango.vip/i/YQnkXf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUDAI",
    "name": "MudAi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf9a467d10fc76b2f20b4f8e2d88b1ad7dc278d4f",
    "decimals": 18,
    "image": "https://rango.vip/i/qtplUH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000225,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRET",
    "name": "CREAT OR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6d0bb9b6ce385e28ea4ebb7d76dcb3a1aaf7bc4b",
    "decimals": 18,
    "image": "https://rango.vip/i/5STmwk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TNODE",
    "name": "Telenode",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb831717e73dba259ce632b54cfe233d5ead60a1a",
    "decimals": 18,
    "image": "https://rango.vip/i/T6mq5I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00011206,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCHRODI",
    "name": "Schrodi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x35ca1e5a9b1c09fa542fa18d1ba4d61c8edff852",
    "decimals": 18,
    "image": "https://rango.vip/i/saD89x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00235275,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BEANS",
    "name": "Dancing Beans",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f4eb21acd80a596bcca596ab40bad928f5d2bcf",
    "decimals": 18,
    "image": "https://rango.vip/i/k6WBui",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 26.67,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SENTRY",
    "name": "Token Sentry Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2eead196943d6a140ee337a5536de01979ebac9d",
    "decimals": 9,
    "image": "https://rango.vip/i/SBInjG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000489,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOE",
    "name": "God Of Ethereum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xccc80ce58995baae4e5867e5cde3bd9f8b242376",
    "decimals": 9,
    "image": "https://rango.vip/i/z3y6DI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.9001e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NIMBUS",
    "name": "Nimbus Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbe4d9c8c638b5f0864017d7f6a04b66c42953847",
    "decimals": 18,
    "image": "https://rango.vip/i/WiMUDD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00065829,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZION",
    "name": "Zion Wallet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e4e1093b4e80e3cc73c92264715ca984bad7e55",
    "decimals": 18,
    "image": "https://rango.vip/i/8nZiJF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00037143,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNAI",
    "name": "Cloudnet Ai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1b78ffbc66139466c4a432f763afce8d4c991060",
    "decimals": 18,
    "image": "https://rango.vip/i/IQMk1N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002558,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RNGD",
    "name": "Renegade",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2407d54b42a570aeb63819d433a63676163cb6d6",
    "decimals": 8,
    "image": "https://rango.vip/i/YB5S9S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0003938,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XEON",
    "name": "Xeon Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8d65a2eabde4b31cbd7e43f27e47559d1ccec86c",
    "decimals": 9,
    "image": "https://rango.vip/i/4Twwbs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00451626,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PERPX",
    "name": "Perpex",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53f7535cc14ff028de181f9789d403c838b5f885",
    "decimals": 18,
    "image": "https://rango.vip/i/bCcYto",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00043271,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MISTY",
    "name": "Misty Meets Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x329cae8c175ac6773d5e79bd30624b953c68a308",
    "decimals": 18,
    "image": "https://rango.vip/i/rk2f0d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.0805e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOMADS",
    "name": "NOMADS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x51573c4c228de0227d3b1e6e598981f8054e8807",
    "decimals": 18,
    "image": "https://rango.vip/i/7mPtGP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01307545,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WTAI",
    "name": "Wrapped Trade AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9cdb8ea1002541a816b271363d64b2787631885f",
    "decimals": 18,
    "image": "https://rango.vip/i/cqX1Eb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00126242,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEROSENE",
    "name": "Kerosene",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3768d6e78e65fc64b8f12ffc824452130bd5394",
    "decimals": 18,
    "image": "https://rango.vip/i/V44JJe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.097715,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RWB",
    "name": "RawBlock",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf614e54d972bab297172765ca83683288f3eae36",
    "decimals": 9,
    "image": "https://rango.vip/i/DnUvGi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00009236,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INP",
    "name": "Ionic Pocket Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa67d87058b2f4e958b38717909565b6daefb95fe",
    "decimals": 9,
    "image": "https://rango.vip/i/4ZpUQD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006484,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CITTY",
    "name": "Citty Meme Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5488eff1976e4a56b4255e926d419a7054df196a",
    "decimals": 18,
    "image": "https://rango.vip/i/HNbQwj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00234792,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PLAYFI",
    "name": "PlayFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x82f13ab56cc0d1b727e8253a943f0de75b048b0b",
    "decimals": 18,
    "image": "https://rango.vip/i/TXD7ok",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0223146,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "THUB",
    "name": "TensorHub",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7fed466b893c716235e1b8d685c913f7d2797463",
    "decimals": 9,
    "image": "https://rango.vip/i/pWeuzJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00012584,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UDW",
    "name": "Underworld",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00000000e88649dd6aab90088ca25d772d4607d0",
    "decimals": 18,
    "image": "https://rango.vip/i/aNs2W0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 129.14,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OTACON",
    "name": "Otacon AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f17eeccc84739b9450c88de0429020e2dec05eb",
    "decimals": 18,
    "image": "https://rango.vip/i/yeq95G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00209032,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZAO",
    "name": "zkTAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad53ed3fee57160f026189eb91e73e9317fa92f1",
    "decimals": 18,
    "image": "https://rango.vip/i/fxMQSB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00060914,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BETTED",
    "name": "Green Games",
    "isPopular": false,
    "chainId": "1",
    "address": "0x891e33c8a49ed9f59be3726834ad78cf597b56e9",
    "decimals": 18,
    "image": "https://rango.vip/i/lQ0cFg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00084119,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEORBIT",
    "name": "DeOrbit Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8176941f1b5173dfdbadcc5d387829b27e98138",
    "decimals": 18,
    "image": "https://rango.vip/i/WI1xkd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00197017,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMETX",
    "name": "SpecialMetalX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0bcc26e40d87873615e082c1b5df15e487f94737",
    "decimals": 18,
    "image": "https://rango.vip/i/s1jJPt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.34,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CIFI",
    "name": "CIFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7efbac35b65e73484764fd00f18e64929e782855",
    "decimals": 18,
    "image": "https://rango.vip/i/WRprVC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00043494,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YNETH",
    "name": "YieldNest Restaked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x09db87a538bd693e9d08544577d5ccfaa6373a48",
    "decimals": 18,
    "image": "https://rango.vip/i/QjV0j2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2247.11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VITARNA",
    "name": "VitaRNA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7b66e84be78772a3afaf5ba8c1993a1b5d05f9c2",
    "decimals": 18,
    "image": "https://rango.vip/i/zAFhYK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.55,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OLM",
    "name": "OpenLM RevShare Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5018913f2fdf33971864804ddb5fca25c539032",
    "decimals": 18,
    "image": "https://rango.vip/i/uQyT0h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01915288,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XWAVE",
    "name": "XenoWave",
    "isPopular": false,
    "chainId": "1",
    "address": "0x56093f3c6ec0bf91860c474946fa353cdb778d35",
    "decimals": 18,
    "image": "https://rango.vip/i/YM9Ida",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002962,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUXE",
    "name": "Fuxion Labs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3fca2cd116121decd03043fbaba39f60651de903",
    "decimals": 18,
    "image": "https://rango.vip/i/8CidQ2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03350102,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MEGADEATH",
    "name": "MEGADEATH  PEPE ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6c3d78e55fc939da4ca94760f6b27c3425a7a865",
    "decimals": 9,
    "image": "https://rango.vip/i/vrbKug",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000232,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NOUS",
    "name": "NousAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36096eb8c11729fdd7685d5e1b82b17d542c38ce",
    "decimals": 9,
    "image": "https://rango.vip/i/CDBVWH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024422,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HAIPERAI",
    "name": "HaiperAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1317d2a56520e64bfe3c2ecd89b6a8b3fb1d6a08",
    "decimals": 18,
    "image": "https://rango.vip/i/6TmlBP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.9346e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INOVAI",
    "name": "INOVAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x649004e1c9fd8b0241d544bd78fc4eb3d3b4d199",
    "decimals": 18,
    "image": "https://rango.vip/i/9az2qr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00094853,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMOCHI",
    "name": "Omochi the Frog",
    "isPopular": false,
    "chainId": "1",
    "address": "0x888888ae2c4a298efd66d162ffc53b3f2a869888",
    "decimals": 9,
    "image": "https://rango.vip/i/bYZ03g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.9534e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EEFI",
    "name": "Elastic Finance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x857ffc55b1aa61a7ff847c82072790cae73cd883",
    "decimals": 18,
    "image": "https://rango.vip/i/UPTuHz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 108.7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HLX",
    "name": "HELIOS",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2614f29c39de46468a921fd0b41fdd99a01f2edf",
    "decimals": 18,
    "image": "https://rango.vip/i/wyWoSy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000169,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOOTER",
    "name": "Looter",
    "isPopular": false,
    "chainId": "1",
    "address": "0x000000000a1c6659ac226dbb1c5bdc648df72e9e",
    "decimals": 18,
    "image": "https://rango.vip/i/WNL2hy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00822225,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKARCH",
    "name": "zkArchive",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5796136ac98f83e732b99edf783ad247c997e8ef",
    "decimals": 9,
    "image": "https://rango.vip/i/jAwLxl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002044,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASET",
    "name": "AssetLink",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e6641e62baa87d77e01ab1c7e9d2f323f26942b",
    "decimals": 18,
    "image": "https://rango.vip/i/DhSEOV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZAAR",
    "name": "Zaar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x95ac17ce4021417e25b8edf807366fc3be091b5e",
    "decimals": 18,
    "image": "https://rango.vip/i/XiAGg4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01314487,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRUNE",
    "name": "BitRunes",
    "isPopular": false,
    "chainId": "1",
    "address": "0x724313985dcb55d432d3888ddc0b9e3d3859e86d",
    "decimals": 18,
    "image": "https://rango.vip/i/nduzWq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000135,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JOK",
    "name": "JokInTheBox",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa728aa2de568766e2fa4544ec7a77f79c0bf9f97",
    "decimals": 18,
    "image": "https://rango.vip/i/A9i14a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.62239e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GOLDCAT",
    "name": "GOLD CAT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x607496f14918891594177c24a983e901c1896e63",
    "decimals": 9,
    "image": "https://rango.vip/i/H4ZO2H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.5409e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FRX",
    "name": "FairEx",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8decef6c5d56a07e532b014fbb97db05c7380cbe",
    "decimals": 18,
    "image": "https://rango.vip/i/IzqS6F",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00099624,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TNET",
    "name": "Tangle Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3916ea28cccc5ce0a58535dd2635a5c12ddd4457",
    "decimals": 18,
    "image": "https://rango.vip/i/MmmtlY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00081009,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DRV3",
    "name": "DRIVE3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4022754bf8857395383c63326391f289d1bb14b9",
    "decimals": 18,
    "image": "https://rango.vip/i/mHeECn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01528288,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INO",
    "name": "Innovai",
    "isPopular": false,
    "chainId": "1",
    "address": "0x342f4ca388896e6300bb899aca2d8619cbefcece",
    "decimals": 18,
    "image": "https://rango.vip/i/8EQ8kr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00038883,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CONVERGE",
    "name": "Converge Bot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x032c3ec6d4c894844fd855874062a86592801cc9",
    "decimals": 18,
    "image": "https://rango.vip/i/kXVSdl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00105159,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MMTR",
    "name": "Memeinator",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb8a4350edafd7af34164dd5870e49e28393ff3ec",
    "decimals": 18,
    "image": "https://rango.vip/i/NwHRT5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00115933,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEMO",
    "name": "Nemo Sum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb60fdf036f2ad584f79525b5da76c5c531283a1b",
    "decimals": 9,
    "image": "https://rango.vip/i/mtzgM7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00212521,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPEBOMB",
    "name": "pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8dba4bc68126bd186fbb62c976539d1558c9fe73",
    "decimals": 18,
    "image": "https://rango.vip/i/QWJyXt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001038,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMAX",
    "name": "AMAX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x864ac60596435031cdd28257091c26c62e53e44d",
    "decimals": 18,
    "image": "https://rango.vip/i/FH98cN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.87,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZAPI",
    "name": "Zapicorn",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc434268603ca8854e0be1a3ff15cad73bd6ec49a",
    "decimals": 9,
    "image": "https://rango.vip/i/gCNthk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00054911,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRX",
    "name": "Bricks",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ce6f2c0e2a1b4540894f286254bf13b1110d240",
    "decimals": 18,
    "image": "https://rango.vip/i/7bdqrF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "$VAULT",
    "name": "VaultTech",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7f9b09f4717072cf4dc18b95d1b09e2b30c76790",
    "decimals": 18,
    "image": "https://rango.vip/i/tbzplW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00502739,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BANDS",
    "name": "BANDS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd28cca138166f1d6dae560a0e15e5be2a97c819d",
    "decimals": 18,
    "image": "https://rango.vip/i/vQXtkO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002899,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GAV",
    "name": "GavCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x55c3a56e638e96c91f98735cc86f60a6820e6a44",
    "decimals": 9,
    "image": "https://rango.vip/i/e3QO8H",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.142e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUBSY",
    "name": "Bubsy AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd699b83e43415b774b6ed4ce9999680f049af2ab",
    "decimals": 18,
    "image": "https://rango.vip/i/NEZiYD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03070096,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AITAX",
    "name": "AITaxBot",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f04c2bd696a6191246144ba762456a24c457520",
    "decimals": 18,
    "image": "https://rango.vip/i/nFxdI5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00441765,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUMP ",
    "name": "FreeTrump",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd015422879a1308ba557510345e944b912b9ab73",
    "decimals": 18,
    "image": "https://rango.vip/i/i6fqzM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00672867,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PYGES",
    "name": "Pyges",
    "isPopular": false,
    "chainId": "1",
    "address": "0x96e99106d9c58573171dd6c19d767d2ae7ec0435",
    "decimals": 9,
    "image": "https://rango.vip/i/YWogyC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.414e-9,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZUNUSD",
    "name": "Zunami USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c0d76c9b18779665475f3e212d9ca1ed6a1a0e6",
    "decimals": 18,
    "image": "https://rango.vip/i/TAAwFQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.991948,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "L7L",
    "name": "LE7EL",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea46bf7fe6c9a2e29a97d2da2775131067b4ba0c",
    "decimals": 18,
    "image": "https://rango.vip/i/2lpFYc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PRAI",
    "name": "ProtonAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x13439d2ec5aee05f52712ad9fb9fcadd300bb608",
    "decimals": 9,
    "image": "https://rango.vip/i/fI8kUk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00680825,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARQX",
    "name": "ARQx AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5e432eecd01c12ee7071ee9219c2477a347da192",
    "decimals": 18,
    "image": "https://rango.vip/i/m80DRp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00178479,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZUNETH",
    "name": "Zunami ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc2e660c62f72c2ad35ace6db78a616215e2f2222",
    "decimals": 18,
    "image": "https://rango.vip/i/EkCx16",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2338.08,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUDF",
    "name": "Forte AUD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd2a530170d71a9cfe1651fb468e2b98f7ed7456b",
    "decimals": 6,
    "image": "https://rango.vip/i/7I4RsW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.66575,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NODED",
    "name": "Masternoded Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xca93a5d889e445cecb42e5386f7d516511d2820f",
    "decimals": 18,
    "image": "https://rango.vip/i/ysuXnZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.78358e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HGP",
    "name": "Hourglass Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x017073b9bae99d2afbb6d7e30d464f993ae9c741",
    "decimals": 18,
    "image": "https://rango.vip/i/mbBWuD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 196.4,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNFRG",
    "name": "CoinForge",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb6d78683a4e54b91031acb41510bd8e144fed025",
    "decimals": 18,
    "image": "https://rango.vip/i/rKHGoH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00003106,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BOPPY",
    "name": "Boppy The Bat",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe79031b5aaeb3ee8d0145e3d75b81b36bffe341d",
    "decimals": 9,
    "image": "https://rango.vip/i/F5F0yJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.33486e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COVE",
    "name": "Cove DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x32fb7d6e0cbeb9433772689aa4647828cc7cbba8",
    "decimals": 18,
    "image": "https://rango.vip/i/s4j04c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "0XVOX",
    "name": "HashVox AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6371d7ebd6851b2f6c688081d34edf230332fd00",
    "decimals": 9,
    "image": "https://rango.vip/i/XPlXx3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006481,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AUSD₮",
    "name": "aUSD₮",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9eead9ce15383caeed975427340b3a369410cfbf",
    "decimals": 6,
    "image": "https://rango.vip/i/Ix04E4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.995509,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RESCUE",
    "name": "Rescue",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7c851d60b26a4f2a6f2c628ef3b65ed282c54e52",
    "decimals": 18,
    "image": "https://rango.vip/i/Gd3aHK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.000115,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DYAD",
    "name": "Dyad",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd03723a9a3abe0562451496a9a394d2c4bad4ab",
    "decimals": 18,
    "image": "https://rango.vip/i/27vK46",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.007,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HOPE",
    "name": "History of Pepe",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3b37a9caf74ead14e521d46af0bf00737d827828",
    "decimals": 18,
    "image": "https://rango.vip/i/x0V6gC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00057844,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUSDT",
    "name": "Fluid Tether USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5c20b550819128074fd538edf79791733ccedd18",
    "decimals": 6,
    "image": "https://rango.vip/i/GPHXlg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0437754,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FWSTETH",
    "name": "Fluid Wrapped Staked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2411802d8bea09be0af8fd8d08314a63e706b29c",
    "decimals": 18,
    "image": "https://rango.vip/i/juRR23",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FWETH",
    "name": "Fluid Wrapped Ether",
    "isPopular": false,
    "chainId": "1",
    "address": "0x90551c1795392094fe6d29b758eccd233cfaa260",
    "decimals": 18,
    "image": "https://rango.vip/i/u8288N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NPI",
    "name": "Non Playable Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80034f803afb1c6864e3ca481ef1362c54d094b9",
    "decimals": 9,
    "image": "https://rango.vip/i/mTdbRh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.66825e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LUMAI",
    "name": "LuminAi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae3e12b3a07393a5be2f5e980c585f95d5334a08",
    "decimals": 18,
    "image": "https://rango.vip/i/TLaYHt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00013428,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QUIL",
    "name": "Wrapped QUIL",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8143182a775c54578c8b7b3ef77982498866945d",
    "decimals": 8,
    "image": "https://rango.vip/i/CsYWIC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.208806,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRETTEI",
    "name": "Brettei",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd749b369d361396286f8cc28a99dd3425ac05619",
    "decimals": 18,
    "image": "https://rango.vip/i/MOsekV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001207,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JANNY",
    "name": "Janny",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ff46696d6e4896137acb1628b06e28c10ee9634",
    "decimals": 9,
    "image": "https://rango.vip/i/GaBTcD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00007144,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BITG",
    "name": "BitGate",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3973c606b493eee0e14b2b5654d5c4049ce9c2d9",
    "decimals": 18,
    "image": "https://rango.vip/i/KAiTw6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00017853,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RZBY",
    "name": "Razzberry Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3cedd76acb8b88f5616767785b4dd0902de4a355",
    "decimals": 18,
    "image": "https://rango.vip/i/xgx5Gv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.7939e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OMND",
    "name": "OpenMind",
    "isPopular": false,
    "chainId": "1",
    "address": "0x002606d5aac4abccf6eaeae4692d9da6ce763bae",
    "decimals": 18,
    "image": "https://rango.vip/i/iOvhvD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03663445,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TERM",
    "name": "Term Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3d21f79c3120a4ffda7a535f8005a7c297799bf",
    "decimals": 18,
    "image": "https://rango.vip/i/a3yMvp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DTI",
    "name": "DT Inu",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0880164084017b8d49baa0a33f545ad55914e9fd",
    "decimals": 9,
    "image": "https://rango.vip/i/wB8oty",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00068437,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAGE",
    "name": "Sage Market",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa71261c2b51cb8030700f5601ca597c522dc232e",
    "decimals": 18,
    "image": "https://rango.vip/i/dIJTmw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03626344,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUL",
    "name": "Multipool",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ef3da23c304d88f856f2928b0be17d9f5d0752f",
    "decimals": 18,
    "image": "https://rango.vip/i/EH0bcL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02896921,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HQ",
    "name": "HyperQuant",
    "isPopular": false,
    "chainId": "1",
    "address": "0x986df267bb88a0a0be06dd4a1052e1bdec1a6172",
    "decimals": 18,
    "image": "https://rango.vip/i/T39wmg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01936609,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HINU",
    "name": "Hokkaido Inu Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0113c07b3b8e4f41b62d713b5b12616bf2856585",
    "decimals": 9,
    "image": "https://rango.vip/i/R2lMQv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.4239e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WAAC",
    "name": "Wrapped AyeAyeCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x30ae41d5f9988d359c733232c6c693c0e645c77e",
    "decimals": 0,
    "image": "https://rango.vip/i/eLdDVm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.719925,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CINO",
    "name": "Cinogames",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9b0b23b35ad8136e6181f22b346134ce5f426090",
    "decimals": 18,
    "image": "https://rango.vip/i/DqJPjz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHERK",
    "name": "Sherk",
    "isPopular": false,
    "chainId": "1",
    "address": "0x460372866fe1448de1549cebdb0539f4075a2aa8",
    "decimals": 9,
    "image": "https://rango.vip/i/Q2r7Wj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00060498,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HONKLER",
    "name": "Honkler",
    "isPopular": false,
    "chainId": "1",
    "address": "0x69d26c4901765ffa6d7716045b680c9574cb00b5",
    "decimals": 18,
    "image": "https://rango.vip/i/W2XmCh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0007381,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "O2T",
    "name": "Option2Trade",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9cb228d638639881155211b03b8dc1027bf0880d",
    "decimals": 9,
    "image": "https://rango.vip/i/B7b0y7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0012922,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ABE",
    "name": "Aboard",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb0ed164f6e3c6a4153eeb43bf9674955a259ec45",
    "decimals": 18,
    "image": "https://rango.vip/i/Vk05pp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OJEE",
    "name": "VIMworld OJEE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31c5dec1f10dc084b95c239734dea0adb9c97c9c",
    "decimals": 18,
    "image": "https://rango.vip/i/KV85R1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00041644,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "INFC",
    "name": "Infinect",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcc98d9587786e06fcfd94597a078a1d0389e8a15",
    "decimals": 18,
    "image": "https://rango.vip/i/znuDcU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00200561,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CZ",
    "name": "Changpeng Zhao",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc3960227e41c3f54e9b399ce216149dea5315c34",
    "decimals": 9,
    "image": "https://rango.vip/i/dx4aiE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006013,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EMBER",
    "name": "Ember Sword",
    "isPopular": false,
    "chainId": "1",
    "address": "0x35f3bad2fcc8053869086885f7898a3d4309db4e",
    "decimals": 18,
    "image": "https://rango.vip/i/ut6gJh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0084718,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TUIT",
    "name": "Tuition Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x963cd3e835d81ce8e4ae4836e654336dab4298e9",
    "decimals": 18,
    "image": "https://rango.vip/i/qEqKrE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00555026,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MULLET",
    "name": "Project Mullet",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a569bff9f87b526b39331ca870516c1d93c0fda",
    "decimals": 18,
    "image": "https://rango.vip/i/PUHVhh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00014333,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWK",
    "name": "Sowaka",
    "isPopular": false,
    "chainId": "1",
    "address": "0xddfbe9173c90deb428fdd494cb16125653172919",
    "decimals": 18,
    "image": "https://rango.vip/i/Gd01Hg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.301455,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZUN",
    "name": "Zunami Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6b5204b0be36771253cc38e88012e02b752f0f36",
    "decimals": 18,
    "image": "https://rango.vip/i/bZqgae",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.101937,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANDI",
    "name": "Andi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe5e880b7a3e3cceca841584be21b290535eba606",
    "decimals": 9,
    "image": "https://rango.vip/i/jKMvcq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 7.47e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAERU",
    "name": "Kaeru The Frog",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6987e0304d1b26a311e68e3f3da26b1c885a4e83",
    "decimals": 18,
    "image": "https://rango.vip/i/58Wne5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 9.1185e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MONKEI",
    "name": "Monkei",
    "isPopular": false,
    "chainId": "1",
    "address": "0x28b7f370a2b0fd04a9f420c8863b12f35c0f791a",
    "decimals": 9,
    "image": "https://rango.vip/i/8Ceyny",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.9883e-11,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TBD",
    "name": "The Big Debate",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaed18826655bf9167a377c8647132a937d6a4f36",
    "decimals": 18,
    "image": "https://rango.vip/i/mThz0W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001261,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FOMEOW",
    "name": "fomeow",
    "isPopular": false,
    "chainId": "1",
    "address": "0x53b38ebd32bde4293423234939f6555cb7975f1e",
    "decimals": 9,
    "image": "https://rango.vip/i/05OkiL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00010194,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUSD",
    "name": "Electronic Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0d69e286b938e21cbf7e51d71f6a4c8918f482f",
    "decimals": 18,
    "image": "https://rango.vip/i/UT0y1k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.999013,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WXM",
    "name": "WeatherXM",
    "isPopular": false,
    "chainId": "1",
    "address": "0xde654f497a563dd7a121c176a125dd2f11f13a83",
    "decimals": 18,
    "image": "https://rango.vip/i/WsFpMZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.405308,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "E",
    "name": "Etheism",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc0cfbe1602dd586349f60e4681bf4badca584ec9",
    "decimals": 9,
    "image": "https://rango.vip/i/k4otg2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5317.82,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RLP",
    "name": "Resolv RLP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4956b52ae2ff65d74ca2d61207523288e4528f96",
    "decimals": 18,
    "image": "https://rango.vip/i/JeWclc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.038,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAUMEN",
    "name": "daumenfrosch",
    "isPopular": false,
    "chainId": "1",
    "address": "0x80795a7bb55f003b1572411a271e31f73e03dd73",
    "decimals": 9,
    "image": "https://rango.vip/i/ENhDsp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.2386e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "C2D",
    "name": "Crypto 2 Debit",
    "isPopular": false,
    "chainId": "1",
    "address": "0x202bab532e9e44f06688abf3406437dbe49b3018",
    "decimals": 18,
    "image": "https://rango.vip/i/QOJ7IW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00006103,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OWN",
    "name": "Otherworld",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2d81f9460bd21e8578350a4f06e62848ed4bb27e",
    "decimals": 18,
    "image": "https://rango.vip/i/WlX0JA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.59537,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PEPETR",
    "name": "Pepe Treasure",
    "isPopular": false,
    "chainId": "1",
    "address": "0xffb032d971469fd358f11a4192c4e0b852df5190",
    "decimals": 9,
    "image": "https://rango.vip/i/uCyryQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.5783e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZRC",
    "name": "Zircuit",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfd418e42783382e86ae91e445406600ba144d162",
    "decimals": 18,
    "image": "https://rango.vip/i/uvt8Bg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WNE",
    "name": "Winee3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x46971fc433d90cf2ff1da4a66abe320dfb0ce3b1",
    "decimals": 9,
    "image": "https://rango.vip/i/qUEcK3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004703,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QF",
    "name": "Quantum Fusion",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6019dcb2d0b3e0d1d8b0ce8d16191b3a4f93703d",
    "decimals": 18,
    "image": "https://rango.vip/i/NEgAKH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.906654,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEXI",
    "name": "LexiAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1caf237d7a2d103e3e9b1855988c01ac10344600",
    "decimals": 9,
    "image": "https://rango.vip/i/0f7pDZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01033496,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REPX",
    "name": "RepoSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3261902514414a131202e792a5ef763db795e639",
    "decimals": 18,
    "image": "https://rango.vip/i/KVN9KV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRYPTIQ",
    "name": "Cryptiq browser",
    "isPopular": false,
    "chainId": "1",
    "address": "0xda5fab7affc6dffd24d60e23153d241a3d9f9603",
    "decimals": 18,
    "image": "https://rango.vip/i/MTPzJp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00665599,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WRUNI",
    "name": "Wrapped RUNI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xec56840be7c495cbf98c0157b458cd207ff85da1",
    "decimals": 6,
    "image": "https://rango.vip/i/BP11fp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.626488,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SPETH",
    "name": "Spectrum ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf96d4b1e0a0b129e1471e88df6f1281b933bc474",
    "decimals": 18,
    "image": "https://rango.vip/i/fKFs9s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SIA",
    "name": "SIA AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf792851286fc963535fa9894573b83845bd4c1c2",
    "decimals": 18,
    "image": "https://rango.vip/i/nWqQ5E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002312,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STEVE",
    "name": "Steve",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e068796ba34613eb9b285affe0283fef3f4d66f",
    "decimals": 18,
    "image": "https://rango.vip/i/2t0eZP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.42727e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MXNB",
    "name": "MXNB",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf197ffc28c23e0309b5559e7a166f2c6164c80aa",
    "decimals": 6,
    "image": "https://rango.vip/i/DanJG7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.050645,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KRETH",
    "name": "Kernel Restaked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf02c96dbbb92dc0325ad52b3f9f2b951f972bf00",
    "decimals": 18,
    "image": "https://rango.vip/i/AtEX1g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2349.07,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOLVBTC",
    "name": "Solv Protocol SolvBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7a56e1c57c7475ccf742a1832b028f0456652f97",
    "decimals": 18,
    "image": "https://rango.vip/i/YCM2CH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55593,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BUG",
    "name": "Bug",
    "isPopular": false,
    "chainId": "1",
    "address": "0x89fd2d8fd8d937f55c89b7da3ceed44fa27e4a81",
    "decimals": 9,
    "image": "https://rango.vip/i/SdfESa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.75596e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LMI",
    "name": "Lucky Mio",
    "isPopular": false,
    "chainId": "1",
    "address": "0xefc3f1ecff8b9e9389323ef610bb9149236e62fd",
    "decimals": 18,
    "image": "https://rango.vip/i/RxWddg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.81761e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHEZUSD",
    "name": "ShezmuUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd60eea80c83779a8a5bfcdac1f3323548e6bb62d",
    "decimals": 18,
    "image": "https://rango.vip/i/VXpiyk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.007,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SQUIRRY",
    "name": "Squirry",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbfa7cb34879167e982206fabf6ced5e2ba5cd496",
    "decimals": 9,
    "image": "https://rango.vip/i/g3tn3D",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.4859e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YUM",
    "name": "Yum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xce682c89c63d2850cb2ca898e44d6c7c30d897a6",
    "decimals": 18,
    "image": "https://rango.vip/i/AOeTCV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03656123,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "Q",
    "name": "QSTAR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9abfc0f085c82ec1be31d30843965fcc63053ffe",
    "decimals": 9,
    "image": "https://rango.vip/i/eNwdjx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00038485,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XBG",
    "name": "XBorg",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeae00d6f9b16deb1bd584c7965e4c7d762f178a1",
    "decimals": 18,
    "image": "https://rango.vip/i/A1NnJo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.063701,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SWAG",
    "name": "Swag",
    "isPopular": false,
    "chainId": "1",
    "address": "0x36c7188d64c44301272db3293899507eabb8ed43",
    "decimals": 9,
    "image": "https://rango.vip/i/nchafE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00423687,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BNDL",
    "name": "Bundl",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7dffadd25a6b9269d80515d1a992a5b825efffb9",
    "decimals": 18,
    "image": "https://rango.vip/i/2izqlf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.363985,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OPENLI",
    "name": "OpenLiquidity",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa955ec865f51c55e3b6ce02528a6844c2eb9c26",
    "decimals": 9,
    "image": "https://rango.vip/i/2RWvnC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00066175,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "QNS",
    "name": "QuantoSwap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x37a2f8701856a78de92dbe35df2200c355eae090",
    "decimals": 18,
    "image": "https://rango.vip/i/yFWMo8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STEAKLRT",
    "name": "Steakhouse Resteaking Vault",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbeef69ac7870777598a04b2bd4771c71212e6abc",
    "decimals": 18,
    "image": "https://rango.vip/i/hcoGsu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2760.28,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GNCAT",
    "name": "GanNamCAT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9e566e28c61690f8afe0468f523e137b1ff29f01",
    "decimals": 9,
    "image": "https://rango.vip/i/BJfkre",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.71081e-10,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COIL",
    "name": "SpiralDAO Coil",
    "isPopular": false,
    "chainId": "1",
    "address": "0x823e1b82ce1dc147bbdb25a203f046afab1ce918",
    "decimals": 18,
    "image": "https://rango.vip/i/SLfJuZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.551349,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HYETH",
    "name": "High Yield ETH Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4506022fb8090774e8a628d5084eed61d9b99ee",
    "decimals": 18,
    "image": "https://rango.vip/i/iHtYRm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2798.7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RAINBOW",
    "name": "Rainbow",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe657d49abae3ea21618bb481f1dab4322855f60e",
    "decimals": 18,
    "image": "https://rango.vip/i/Mj505Y",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 6.5186e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DASIA",
    "name": "DASIA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x98c26b1768e95d40cc77ace1b6fa77a13fffd963",
    "decimals": 4,
    "image": "https://rango.vip/i/c2hBtO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04356009,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CYDX",
    "name": "CyberDEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0084063ea01d5f09e56ef3ff6232a9e18b0bacd",
    "decimals": 18,
    "image": "https://rango.vip/i/qICYFF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03781914,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FILTER",
    "name": "Filter AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea4170a365952c666a9f34950771e51841732de9",
    "decimals": 18,
    "image": "https://rango.vip/i/WgMUlt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02060412,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHEZETH",
    "name": "ShezmuETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x63a0964a36c34e81959da5894ad888800e17405b",
    "decimals": 18,
    "image": "https://rango.vip/i/QnrVsX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2388.6,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCA",
    "name": "ARCA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c00c3e03c3a10a0c1d9b6d1a42e797d7cb4147a",
    "decimals": 18,
    "image": "https://rango.vip/i/fPHSnM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNIBTC",
    "name": "Universal BTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x004e9c3ef86bc1ca1f0bb5c7662861ee93350568",
    "decimals": 8,
    "image": "https://rango.vip/i/vyrOdT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55541,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XFRXETH",
    "name": "Leveraged frxETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2bb0c32101456f5960d4e994bac183fe0dc6c82c",
    "decimals": 18,
    "image": "https://rango.vip/i/jFbM6v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.47623,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XWBTC",
    "name": "Leverage WBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9f23562ec47249761222ef7ac02b327a8c45ba7d",
    "decimals": 18,
    "image": "https://rango.vip/i/M6CHGj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.76106,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XCVX",
    "name": "Leveraged CVX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb90d347e10a085b591955cbd0603ac7866fcadc8",
    "decimals": 18,
    "image": "https://rango.vip/i/2chRzW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.735042,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XEETH",
    "name": "Leveraged eETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0xacb3604aadf26e6c0bb8c720420380629a328d2c",
    "decimals": 18,
    "image": "https://rango.vip/i/vdX1lc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.494378,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XSTETH",
    "name": "Leveraged stETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5a097b014c547718e79030a077a91ae37679eff5",
    "decimals": 18,
    "image": "https://rango.vip/i/chN6Fa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.45658,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUMATIC",
    "name": "TruFin Staked MATIC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa43a7c62d56df036c187e1966c03e2799d8987ed",
    "decimals": 18,
    "image": "https://rango.vip/i/P4LtQu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.393214,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUTBL",
    "name": "Spiko EU T Bills Money Market Fund",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa0769f7a8fc65e47de93797b4e21c073c117fc80",
    "decimals": 5,
    "image": "https://rango.vip/i/qyOIzO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.13,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ACCORD",
    "name": "Accord AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x755f57af4c14aabfe5fbc92b27b015dcdbd30c15",
    "decimals": 18,
    "image": "https://rango.vip/i/ensROk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00103255,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKCRO",
    "name": "Cronos zkEVM CRO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x28ff2e4dd1b58efeb0fc138602a28d5ae81e44e2",
    "decimals": 18,
    "image": "https://rango.vip/i/zDdDGm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.077481,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RSVUSDT",
    "name": "RedSonic Vault Tether USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2e5d4e2a2336045471bfe446caa407f0c3a2419c",
    "decimals": 6,
    "image": "https://rango.vip/i/nVDK3n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RSVETH",
    "name": "RedSonic Vault Ethereum",
    "isPopular": false,
    "chainId": "1",
    "address": "0xca9de1f80df74331c5fcb7eee2d05e746d47bfb2",
    "decimals": 18,
    "image": "https://rango.vip/i/E15r5U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LGAS",
    "name": "Laser Gas",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd9b9f72d6f5cdec754243d7d3cefd5b4370af094",
    "decimals": 18,
    "image": "https://rango.vip/i/h898NP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SSAI",
    "name": "Shelter Secure AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x266b4d1c60634c5ab2b9f5117888a4850d87c4c0",
    "decimals": 18,
    "image": "https://rango.vip/i/xZM3gA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00073125,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USTBL",
    "name": "Spiko US T Bills Money Market Fund",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe4880249745eac5f1ed9d8f7df844792d560e750",
    "decimals": 5,
    "image": "https://rango.vip/i/is9OCR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.015,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASKO",
    "name": "ASKOBAR NETWORK",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeeee2a622330e6d2036691e983dee87330588603",
    "decimals": 18,
    "image": "https://rango.vip/i/wUxscl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.1957e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SVETH",
    "name": "Vector Reserve Staked vETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6733f0283711f225a447e759d859a70b0c0fd2bc",
    "decimals": 18,
    "image": "https://rango.vip/i/vrNDRf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2932.99,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ULTRAETHS",
    "name": "Affine ultraETHs 2 0",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf0a949b935e367a94cdfe0f2a54892c2bc7b2131",
    "decimals": 18,
    "image": "https://rango.vip/i/qgoh4p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2752.56,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USD3",
    "name": "Stable com USD3",
    "isPopular": false,
    "chainId": "1",
    "address": "0x046029f68b0e00ebec2e394d17f70ec848fcf1d2",
    "decimals": 18,
    "image": "https://rango.vip/i/G8QnZf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.998341,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YPRISMA",
    "name": "Yearn yPRISMA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe3668873d944e4a949da05fc8bde419eff543882",
    "decimals": 18,
    "image": "https://rango.vip/i/ZnxTBC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02348087,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SKOL",
    "name": "Skol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb369daca21ee035312176eb8cf9d88ce97e0aa95",
    "decimals": 18,
    "image": "https://rango.vip/i/jyAFuj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.072214,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMP",
    "name": "Seamoon Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7d36f7d8e9220f021305b8f13414c87df688aa8b",
    "decimals": 18,
    "image": "https://rango.vip/i/oClSfn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUSX",
    "name": "Savings USX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbc404429558292ee2d769e57d57d6e74bbd2792d",
    "decimals": 18,
    "image": "https://rango.vip/i/vpvr89",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.024,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCALEX",
    "name": "SCALEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd1d2c1bc0d97880b67c504e20ae6f4fa2e03b823",
    "decimals": 18,
    "image": "https://rango.vip/i/CMwVKA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00079358,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USD3",
    "name": "Web 3 Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0d86883faf4ffd7aeb116390af37746f45b6f378",
    "decimals": 18,
    "image": "https://rango.vip/i/wqwHk5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.013,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DHUB",
    "name": "DevHub AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc4116efc9e279d4bbf577d92f224508f13637c6c",
    "decimals": 18,
    "image": "https://rango.vip/i/05oKUs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00027911,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CNCT",
    "name": "Connect",
    "isPopular": false,
    "chainId": "1",
    "address": "0x516d339afa72f6959b8e06a31fbc32da3e49348b",
    "decimals": 18,
    "image": "https://rango.vip/i/BrE80L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00524506,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UNREAL",
    "name": "Unreal AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb39a0dae3c2afd1f3c55ad47d1c7a0bb6c1ca260",
    "decimals": 9,
    "image": "https://rango.vip/i/G1w5vt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.66,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRITON",
    "name": "Triton",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8c6691704d0c3630d6a4fbd5383e0f8e59e10616",
    "decimals": 18,
    "image": "https://rango.vip/i/YXOJFm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.381419,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EPENDLE",
    "name": "Equilibria Finance ePENDLE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x22fc5a29bd3d6cce19a06f844019fd506fce4455",
    "decimals": 18,
    "image": "https://rango.vip/i/7otyny",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.714251,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WEETHS",
    "name": "ether fi weETHs",
    "isPopular": false,
    "chainId": "1",
    "address": "0x917cee801a67f933f2e6b33fc0cd1ed2d5909d88",
    "decimals": 18,
    "image": "https://rango.vip/i/M4F1Jq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2348.23,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NUGX",
    "name": "Nugget Rush",
    "isPopular": false,
    "chainId": "1",
    "address": "0x75fa2a76e5ec2269cf507b9296ac108373c72a6e",
    "decimals": 18,
    "image": "https://rango.vip/i/uGQqfu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00276938,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDPENDLE",
    "name": "Stake DAO sdPENDLE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5ea630e00d6ee438d3dea1556a110359acdc10a9",
    "decimals": 18,
    "image": "https://rango.vip/i/67ngxH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GIZMO",
    "name": "GIZMO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb538d9f3e1ae450827618519acd96086fc4c0a59",
    "decimals": 18,
    "image": "https://rango.vip/i/xIGYZr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00033731,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DGNETH",
    "name": "Degen ETH Staked ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x005f893ecd7bf9667195642f7649da8163e23658",
    "decimals": 18,
    "image": "https://rango.vip/i/N1Pxsf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2352,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FIBER",
    "name": "Fiber Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xecbbe10d3b5adb681deb586d012b588e046bcb9b",
    "decimals": 9,
    "image": "https://rango.vip/i/T2kS1P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02473267,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHEZBTC",
    "name": "ShezmuBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x786f4782d1a5c602ea30bc4a95154110b9e231ea",
    "decimals": 18,
    "image": "https://rango.vip/i/2pD50z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 59759,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NYA",
    "name": "Nya",
    "isPopular": false,
    "chainId": "1",
    "address": "0x38f9bf9dce51833ec7f03c9dc218197999999999",
    "decimals": 18,
    "image": "https://rango.vip/i/fa0H9Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.76977e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BMSTR",
    "name": "Backed MicroStrategy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac28c9178acc8ba4a11a29e013a3a2627086e422",
    "decimals": 18,
    "image": "https://rango.vip/i/huM0Ie",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 151.59,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BCSBGC3",
    "name": "Backed Swiss Domestic Government Bond 0",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd8b95b1987741849ca7e71e976aeb535fd2e55a2",
    "decimals": 18,
    "image": "https://rango.vip/i/TlVH1c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 64.59,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BTSLA",
    "name": "Backed Tesla",
    "isPopular": false,
    "chainId": "1",
    "address": "0x14a5f2872396802c3cc8942a39ab3e4118ee5038",
    "decimals": 18,
    "image": "https://rango.vip/i/9VBTiA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 229.82,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BMSFT",
    "name": "Backed Microsoft",
    "isPopular": false,
    "chainId": "1",
    "address": "0x374a457967ba24fd3ae66294cab08244185574b0",
    "decimals": 18,
    "image": "https://rango.vip/i/bUKNzW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 408.38,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BGOOGL",
    "name": "Backed Alphabet Class A",
    "isPopular": false,
    "chainId": "1",
    "address": "0xebee37aaf2905b7bda7e3b928043862e982e8f32",
    "decimals": 18,
    "image": "https://rango.vip/i/xtREps",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 157.31,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BGME",
    "name": "Backed GameStop Corp",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7212088a11b4d8f6fc90fbb3dfe793b45dd72323",
    "decimals": 18,
    "image": "https://rango.vip/i/Kv3E03",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 22.27,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOLVBTCBBN",
    "name": "Solv Protocol SolvBTC BBN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd9d920aa40f578ab794426f5c90f6c731d159def",
    "decimals": 18,
    "image": "https://rango.vip/i/hdXD8J",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 55493,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRUMAGA",
    "name": "TrumpMAGA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x328ea6e5ba4cc4b58799f2aec3d8ba839f4314ba",
    "decimals": 18,
    "image": "https://rango.vip/i/NcIMao",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.66717e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GORPLES",
    "name": "GorplesCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xff33a6b3dc0127862eedd3978609404b22298a54",
    "decimals": 18,
    "image": "https://rango.vip/i/aWYRd0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00207779,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YBETH",
    "name": "Veno Yield Bearing ETH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x76bf2d1e6dfda645c0c17440b17eccc181dfc351",
    "decimals": 18,
    "image": "https://rango.vip/i/XGMzNQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YBUSD",
    "name": "Veno Yield Bearing USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa59075dfce274e028b58bddfcc3d709960f594a",
    "decimals": 18,
    "image": "https://rango.vip/i/XsjJdO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RDDTD",
    "name": "Dinari RDDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac20315350d7b59cbe846144f5eb8a6d1df1f5fc",
    "decimals": 18,
    "image": "https://rango.vip/i/k7rGaK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RIOTD",
    "name": "Dinari RIOT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcd7c6ed75151745c893dfc1dca1daa1d55034e67",
    "decimals": 18,
    "image": "https://rango.vip/i/eq8yP7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SOR",
    "name": "Sorcery Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5197ab79b03f1479cbf391a2030b8883546ff251",
    "decimals": 16,
    "image": "https://rango.vip/i/MTLd5o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0007274,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BNVDA",
    "name": "Backed NVIDIA",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa34c5e0abe843e10461e2c9586ea03e55dbcc495",
    "decimals": 18,
    "image": "https://rango.vip/i/ufHgjR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 107.21,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GMED",
    "name": "Dinari GME",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7fb5da212239593f4bf544fbe2ec70ce91f0afdb",
    "decimals": 18,
    "image": "https://rango.vip/i/eeNgGw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NVDAD",
    "name": "Dinari NVDA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x62ec03c917face0e6841afdafc166bf571e55e4f",
    "decimals": 18,
    "image": "https://rango.vip/i/Mu845i",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RLUSD",
    "name": "Ripple USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcfd748b9de538c9f5b1805e8db9e1d4671f7f2ec",
    "decimals": 18,
    "image": "https://rango.vip/i/m8kgxw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COIND",
    "name": "Dinari COIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd71b200bf061509b85df50cc0d8cdee8818a4577",
    "decimals": 18,
    "image": "https://rango.vip/i/l418pF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AYIN",
    "name": "Ayin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x320aebbdca1397f2e3c7f1e482e104a7d9ec97e4",
    "decimals": 18,
    "image": "https://rango.vip/i/y5V7W2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.71,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AITT",
    "name": "Aittcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeec2e29ff5cd4cecea61de09e9f28fae74c70ddd",
    "decimals": 8,
    "image": "https://rango.vip/i/PGSzV4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000377,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UBERD",
    "name": "Dinari UBER",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf89983560d466efa2c38f3308886e5a3127ab1c1",
    "decimals": 18,
    "image": "https://rango.vip/i/CxL0jg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AMCD",
    "name": "Dinari AMC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x800ee7b69dce0f6649bb0c879b468a665b1a44ce",
    "decimals": 18,
    "image": "https://rango.vip/i/wJm00V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LILAI",
    "name": "LilAI",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85cf7f10683c73359e7b06c082eef5851ff2956d",
    "decimals": 18,
    "image": "https://rango.vip/i/Z8zxet",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00200012,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WSTLINK",
    "name": "Wrapped Staked LINK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x911d86c72155c33993d594b0ec7e6206b4c803da",
    "decimals": 18,
    "image": "https://rango.vip/i/KPMhqT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 11.29,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SLR",
    "name": "Solarcoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4e9e4ab99cfc14b852f552f5fb3aa68617825b6c",
    "decimals": 18,
    "image": "https://rango.vip/i/dru4nB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01736644,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BLAZEX",
    "name": "BlazeX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdd1b6b259986571a85da82a84f461e1c212591c0",
    "decimals": 9,
    "image": "https://rango.vip/i/M7dNgK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0001079,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDFI",
    "name": "USDFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa7a0b3fe94121e366d774d60d075f6386f750884",
    "decimals": 18,
    "image": "https://rango.vip/i/sgYonf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.835407,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STABLE",
    "name": "Stable",
    "isPopular": false,
    "chainId": "1",
    "address": "0x60b9c41d99fe3eb64ecc1344bad31d87f1bced6d",
    "decimals": 18,
    "image": "https://rango.vip/i/H34isn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.96,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EKUBO",
    "name": "Ekubo Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x04c46e830bb56ce22735d5d8fc9cb90309317d0f",
    "decimals": 18,
    "image": "https://rango.vip/i/RTBf3q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.97,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BZPR1",
    "name": "Backed ZPR1   1 3 Month T Bill",
    "isPopular": false,
    "chainId": "1",
    "address": "0xade6057fcafa57d6d51ffa341c64ce4814995995",
    "decimals": 18,
    "image": "https://rango.vip/i/QMkwGB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 111.65,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EGBP",
    "name": "ARYZE eGBP",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd711d7d893de57dc13ff465763218770bd42db1d",
    "decimals": 18,
    "image": "https://rango.vip/i/65vOHA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.32,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LLD",
    "name": "Liberland LLD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x054c9d4c6f4ea4e14391addd1812106c97d05690",
    "decimals": 18,
    "image": "https://rango.vip/i/KXZtmj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.039,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LINDA",
    "name": "Linda",
    "isPopular": false,
    "chainId": "1",
    "address": "0x039d2e8f097331278bd6c1415d839310e0d5ece4",
    "decimals": 18,
    "image": "https://rango.vip/i/J9DSN4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000083,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SHIBELON",
    "name": "ShibElon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4c584cd339bdde73b7f5210486dd8bbeee3fde6d",
    "decimals": 9,
    "image": "https://rango.vip/i/hGBUzp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.9466e-7,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JART",
    "name": "JournArt",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf3e07812ebc8604fddb0aa35ff79a03f48f48948",
    "decimals": 18,
    "image": "https://rango.vip/i/A2X4Be",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STUSDT",
    "name": "Staked USDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x25ec98773d7b4ced4cafab96a2a1c0945f145e10",
    "decimals": 18,
    "image": "https://rango.vip/i/5BLPt5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.007,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUSD",
    "name": "ARYZE eUSD",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa4335da338ec4c07c391fc1a9bf75f306adadc08",
    "decimals": 18,
    "image": "https://rango.vip/i/5U7QLL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.998971,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SEN",
    "name": "Seneca",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb19b6b4971bd4206bab176c75b1efe3e28ee5a8",
    "decimals": 18,
    "image": "https://rango.vip/i/D8eBVF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00331442,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USTB",
    "name": "Re al US T Bill",
    "isPopular": false,
    "chainId": "1",
    "address": "0x83fedbc0b85c6e29b589aa6bdefb1cc581935ecd",
    "decimals": 18,
    "image": "https://rango.vip/i/SKF4RM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.002,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XCCX",
    "name": "BlockChainCoinX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7df4122d3eae29fc8fb6be58d9177e8e560be4fb",
    "decimals": 6,
    "image": "https://rango.vip/i/CpaTc9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01177849,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YIELDETH",
    "name": "YieldETH  Sommelier ",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb5b29320d2dde5ba5bafa1ebcd270052070483ec",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2574,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EUROS",
    "name": "The Standard EURO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb399511642fe1666c6a07f83483e6e4feaed9a00",
    "decimals": 18,
    "image": "https://rango.vip/i/agjgwv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.742252,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STSW",
    "name": "Stackswap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1c98b54d673c026c8286badca3e840aaf72931a3",
    "decimals": 6,
    "image": "https://rango.vip/i/07sDFH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01500845,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UWON",
    "name": "UWON",
    "isPopular": false,
    "chainId": "1",
    "address": "0x438e48ed4ce6beecf503d43b9dbd3c30d516e7fd",
    "decimals": 18,
    "image": "https://rango.vip/i/j8sJBJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.982631,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JM",
    "name": "JustMoney",
    "isPopular": false,
    "chainId": "1",
    "address": "0x388d819724dd6d71760a38f00dc01d310d879771",
    "decimals": 8,
    "image": "https://rango.vip/i/XrbYnv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00002228,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDS",
    "name": "Spice USD",
    "isPopular": false,
    "chainId": "1",
    "address": "0x45fdb1b92a649fb6a64ef1511d3ba5bf60044838",
    "decimals": 18,
    "image": "https://rango.vip/i/t8gM5E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.081753,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WBUY",
    "name": "Wrapped BUY Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x396ec402b42066864c406d1ac3bc86b575003ed8",
    "decimals": 2,
    "image": "https://rango.vip/i/Ien4S3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02027001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRFI",
    "name": "CrossFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8848812bd31aeee33313c10a840ffc3169078c5b",
    "decimals": 18,
    "image": "https://rango.vip/i/RS6AH5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00447738,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AGS",
    "name": "Collector Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x667fd83e24ca1d935d36717d305d54fa0cac991c",
    "decimals": 18,
    "image": "https://rango.vip/i/AjChV8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00629852,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PGEN",
    "name": "Polygen",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf6719e1a8fcbb1b9c290019e37e004966a8916c9",
    "decimals": 18,
    "image": "https://rango.vip/i/iBgKHF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000514,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZENITH",
    "name": "Zenith Chain",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0343131c0257ac21ea5a8dc83841f071efd9285c",
    "decimals": 18,
    "image": "https://rango.vip/i/avy2t2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00064578,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DOGIRA",
    "name": "Dogira",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd8c1232fcd219286e341271385bd70601503b3d7",
    "decimals": 9,
    "image": "https://rango.vip/i/Aeh3RS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00004486,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SFIL",
    "name": "Filecoin Standard Full Hashrate",
    "isPopular": false,
    "chainId": "1",
    "address": "0x965b85d4674f64422c4898c8f8083187f02b32c0",
    "decimals": 8,
    "image": "https://rango.vip/i/hKK5Fz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.075097,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VST",
    "name": "Voice Street Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x77a1f4e744d810239f465043e35d067ca33de259",
    "decimals": 18,
    "image": "https://rango.vip/i/ohFifq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00447631,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDCPO",
    "name": "Bridged USD Coin  Wormhole POS ",
    "isPopular": false,
    "chainId": "1",
    "address": "0x566957ef80f9fd5526cd2bef8be67035c0b81130",
    "decimals": 6,
    "image": "https://rango.vip/i/nZZwLD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.990871,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NNT",
    "name": "Nunu Spirits",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19193f450086d0442157b852081976d41657ad56",
    "decimals": 18,
    "image": "https://rango.vip/i/ThjtNg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00024891,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SMBR",
    "name": "Sombra",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16b3e050e9e2f0ac4f1bea1b3e4fdc43d7f062dd",
    "decimals": 9,
    "image": "https://rango.vip/i/DiyObR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00811005,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "A4",
    "name": "A4 Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9767203e89dcd34851240b3919d4900d3e5069f1",
    "decimals": 6,
    "image": "https://rango.vip/i/qdLG2T",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0000154,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HANEP",
    "name": "HANePlatform",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5052fa4a2a147eaaa4c0242e9cc54a10a4f42070",
    "decimals": 18,
    "image": "https://rango.vip/i/h5AqvX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 25.93,
    "supportedSwappers": [
      "Optimism Bridge",
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "@LFG",
    "name": "@LFG",
    "isPopular": false,
    "chainId": "1",
    "address": "0x40a9a694197a0b4b92f2aad48da6bc1b6ff194e9",
    "decimals": 18,
    "image": "https://rango.vip/i/IPsKVs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00370649,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "JARVIS",
    "name": "Jarvis",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd8fdda057de7e0162b7a386bec253844b5e07a5",
    "decimals": 18,
    "image": "https://rango.vip/i/k8mP52",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.050001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TGR",
    "name": "Tegro",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc7026a20a640bc71b9074f7aed52a00cd9147091",
    "decimals": 18,
    "image": "https://rango.vip/i/ZVT1pM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01592476,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCAI",
    "name": "SecureChain AI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe35009059cb55ded065027e9832a2c564aff7512",
    "decimals": 18,
    "image": "https://rango.vip/i/PXLdIs",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00043789,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OH",
    "name": "Oh  Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x16ba8efe847ebdfef99d399902ec29397d403c30",
    "decimals": 18,
    "image": "https://rango.vip/i/BMpJxv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00325937,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MPDAO",
    "name": "Meta Pool DAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0x798bcb35d2d48c8ce7ef8171860b8d53a98b361d",
    "decimals": 6,
    "image": "https://rango.vip/i/XbB99a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03403087,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SFRAX",
    "name": "Staked FRAX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa663b02cf0a4b149d2ad41910cb81e23e1c41c32",
    "decimals": 18,
    "image": "https://rango.vip/i/a7dCFc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.073,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ANKRMATIC",
    "name": "Ankr Staked MATIC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26dcfbfa8bc267b250432c01c982eaf81cc5480c",
    "decimals": 18,
    "image": "https://rango.vip/i/srui1I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.438285,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "21BTC",
    "name": "21 co Wrapped BTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3f67093dffd4f0af4f2918703c92b60acb7ad78b",
    "decimals": 8,
    "image": "https://rango.vip/i/MrJkfW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 57453,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NEUY",
    "name": "NEUY",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa80505c408c4defd9522981cd77e026f5a49fe63",
    "decimals": 18,
    "image": "https://rango.vip/i/fDJSNv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.02323367,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LFI",
    "name": "LunaFi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb28f803a8772e6584a65ab6dfc535ae6fef8a0b2",
    "decimals": 18,
    "image": "https://rango.vip/i/frqKyR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000606,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SEAN",
    "name": "Starfish Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa719cb79af39a9c10eda2755e0938bce35e9de24",
    "decimals": 18,
    "image": "https://rango.vip/i/tl11vv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.0005538,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TXPT",
    "name": "tPLATINUM",
    "isPopular": false,
    "chainId": "1",
    "address": "0x19b22dbadc298c359a1d1b59e35f352a2b40e33c",
    "decimals": 18,
    "image": "https://rango.vip/i/DKsVB9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 21.32,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XAV",
    "name": "Xave",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1db06f39c14d813d7b1ccb275a93f5b052de1cac",
    "decimals": 18,
    "image": "https://rango.vip/i/MjoZsu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.03334708,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KEI",
    "name": "KEI Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xac87d4cbb82ce7f4bcf31dbdc0024306cfd3ec5a",
    "decimals": 18,
    "image": "https://rango.vip/i/soT1h3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.01712936,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OOOI",
    "name": "Corridor Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd7b675cd5c84a13d1d0f84509345530f6421b57c",
    "decimals": 18,
    "image": "https://rango.vip/i/wrZJdT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BERNA",
    "name": "Backed ERNA   Bond",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0f76d32cdccdcbd602a55af23eaf58fd1ee17245",
    "decimals": 18,
    "image": "https://rango.vip/i/0ByaqJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 5.88,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CDFI",
    "name": "CeDeFiAi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x527856315a4bcd2f428ea7fa05ea251f7e96a50a",
    "decimals": 18,
    "image": "https://rango.vip/i/bVPDpV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.223609,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BONZAI",
    "name": "BonzAI DePIN",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdda9ff241c7160be8295ef9eca2e782361467666",
    "decimals": 18,
    "image": "https://rango.vip/i/6k8u3Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.061335,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YAKU",
    "name": "Yaku",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1155db64b59265f57533bc0f9ae012fffd34eb7f",
    "decimals": 9,
    "image": "https://rango.vip/i/mvZTM8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00920544,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BRISH",
    "name": "Brish",
    "isPopular": false,
    "chainId": "1",
    "address": "0x37f24b26bcefbfac7f261b97f8036da98f81a299",
    "decimals": 18,
    "image": "https://rango.vip/i/Ljorru",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001203,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CUTE",
    "name": "PEPE UWU",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2cb5d9fd89d48c516f11904117c57e3934f39524",
    "decimals": 18,
    "image": "https://rango.vip/i/wsQmgk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 8.5972e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WBAN",
    "name": "Wrapped Banano",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe20b9e246db5a0d21bf9209e4858bc9a3ff7a034",
    "decimals": 18,
    "image": "https://rango.vip/i/WHZbux",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00307791,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WPUNK",
    "name": "Wrapped Punk",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaf6a1125d4cc55a4110dc63cd2ff6e005afb8676",
    "decimals": 18,
    "image": "https://rango.vip/i/H64umY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.996861,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BNIU",
    "name": "Backed NIU Technologies",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2f11eeee0bf21e7661a22dbbbb9068f4ad191b86",
    "decimals": 18,
    "image": "https://rango.vip/i/qfnGsi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.84,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CHB",
    "name": "COINHUB",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf68d4d917592f3a62417ace42592f15296cc33a0",
    "decimals": 8,
    "image": "https://rango.vip/i/Bbdukp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00001,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZKE",
    "name": "zkEra Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9bd69bc59118ce0fbce9b03551a765a779bd25cf",
    "decimals": 18,
    "image": "https://rango.vip/i/aw7OvC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.04044928,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "HYDRO",
    "name": "Hydro",
    "isPopular": false,
    "chainId": "1",
    "address": "0xebbdf302c940c6bfd49c6b165f457fdb324649bc",
    "decimals": 18,
    "image": "https://rango.vip/i/WsPeKJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "COVER",
    "name": "Cover Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713",
    "decimals": 18,
    "image": "https://rango.vip/i/aUuQOx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FNX",
    "name": "FinNexus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xef9cd7882c067686691b6ff49e650b43afbbcc6b",
    "decimals": 18,
    "image": "https://rango.vip/i/HVpPhg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ASSY",
    "name": "ASSY Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfa2562da1bba7b954f26c74725df51fb62646313",
    "decimals": 18,
    "image": "https://rango.vip/i/J5yfs3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ATTR",
    "name": "Attrace",
    "isPopular": false,
    "chainId": "1",
    "address": "0x44e2dec86b9f0e0266e9aa66e10323a2bd69cf9a",
    "decimals": 18,
    "image": "https://rango.vip/i/cPq14A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "AXLTIA",
    "name": "Axelar Wrapped TIA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65e3fa51c4ce0af1b9cd5cbc7c5fdb80a09d431d",
    "decimals": 6,
    "image": "https://rango.vip/i/XMjbwc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAB",
    "name": "Basis Bond",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc36824905dff2eaaee7ecc09fcc63abc0af5abc5",
    "decimals": 18,
    "image": "https://rango.vip/i/EukzvY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BDI",
    "name": "BasketDAO DeFi Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0309c98b1bffa350bcb3f9fb9780970ca32a5060",
    "decimals": 18,
    "image": "https://rango.vip/i/PceLNG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BDIGG",
    "name": "Badger Sett Digg",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7e7e112a68d8d2e221e11047a72ffc1065c38e1a",
    "decimals": 18,
    "image": "https://rango.vip/i/FJ0XHk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BGLD",
    "name": "Based Gold",
    "isPopular": false,
    "chainId": "1",
    "address": "0xba7970f10d9f0531941dced1dda7ef3016b24e5b",
    "decimals": 18,
    "image": "https://rango.vip/i/868Yjm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BIOS",
    "name": "BIOS",
    "isPopular": false,
    "chainId": "1",
    "address": "0xaaca86b876ca011844b5798eca7a67591a9743c8",
    "decimals": 18,
    "image": "https://rango.vip/i/pedn7S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00031016,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BNPL",
    "name": "BNPL Pay",
    "isPopular": false,
    "chainId": "1",
    "address": "0x84d821f7fbdd595c4c4a50842913e6b1e07d7a53",
    "decimals": 18,
    "image": "https://rango.vip/i/Rp4gyG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CMK",
    "name": "Credmark",
    "isPopular": false,
    "chainId": "1",
    "address": "0x68cfb82eacb9f198d508b514d898a403c449533e",
    "decimals": 18,
    "image": "https://rango.vip/i/1OdK9t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRD",
    "name": "CRD Network",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcaaa93712bdac37f736c323c93d4d5fdefcc31cc",
    "decimals": 18,
    "image": "https://rango.vip/i/88HTfb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "CRETH2",
    "name": "Cream ETH 2",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcbc1065255cbc3ab41a6868c22d1f1c573ab89fd",
    "decimals": 18,
    "image": "https://rango.vip/i/jhPjKR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DAOX",
    "name": "The DAOX Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe00639a1f59b52773b7d39d9f9bef07f6248dbae",
    "decimals": 18,
    "image": "https://rango.vip/i/op5N7P",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DEFI+L",
    "name": "PieDAO DEFI Large Cap",
    "isPopular": false,
    "chainId": "1",
    "address": "0x78f225869c08d478c34e5f645d07a87d3fe8eb78",
    "decimals": 18,
    "image": "https://rango.vip/i/hdQ7KP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DIVINE",
    "name": "divinedao",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb5e09e6bf6a5e96934b3fd99a40f7edaca1173ed",
    "decimals": 18,
    "image": "https://rango.vip/i/2geqgb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "DSD",
    "name": "Dynamic Set Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbd2f0cd039e0bfcf88901c98c0bfac5ab27566e3",
    "decimals": 18,
    "image": "https://rango.vip/i/vVYeHC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ETHC",
    "name": "ETH Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c37577f1de12046aea6975862559a50d8f50158",
    "decimals": 18,
    "image": "https://rango.vip/i/CfKvQa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2331.58,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FFRAX",
    "name": "Fluid FRAX",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2be1e42bf263aab47d27ba92e72c14823e101d7c",
    "decimals": 18,
    "image": "https://rango.vip/i/JXDVcm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.957251,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FLOAT",
    "name": "Float FLOAT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb05097849bca421a3f51b249ba6cca4af4b97cb9",
    "decimals": 18,
    "image": "https://rango.vip/i/EoL4Qz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "FUSDT",
    "name": "Fluid USDT",
    "isPopular": false,
    "chainId": "1",
    "address": "0xadc234a4e90e2045f353f5d4fcde66144d23b458",
    "decimals": 6,
    "image": "https://rango.vip/i/h1i4Ld",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.042,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GANG",
    "name": "GANG",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb73758fe1dc58ac2a255a2950a3fdd84da656b84",
    "decimals": 18,
    "image": "https://rango.vip/i/d32USw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00000836,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "GTON",
    "name": "Graviton",
    "isPopular": false,
    "chainId": "1",
    "address": "0x01e0e2e61f554ecaaec0cc933e739ad90f24a86d",
    "decimals": 18,
    "image": "https://rango.vip/i/KbKXNh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IBETHV2",
    "name": "Interest Bearing Ether v2",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeea3311250fe4c3268f8e684f7c87a82ff183ec1",
    "decimals": 8,
    "image": "https://rango.vip/i/uRM6nm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IBY",
    "name": "I Bet You",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6a68de599e8e0b1856e322ce5bd11c5c3c79712b",
    "decimals": 18,
    "image": "https://rango.vip/i/aaD3Nx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ILSI",
    "name": "Invest Like Stakeborg Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0acc0fee1d86d2cd5af372615bf59b298d50cd69",
    "decimals": 18,
    "image": "https://rango.vip/i/f6r8v0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KAE",
    "name": "Kanpeki",
    "isPopular": false,
    "chainId": "1",
    "address": "0x65def5029a0e7591e46b38742bfedd1fb7b24436",
    "decimals": 18,
    "image": "https://rango.vip/i/FZPows",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "KKO",
    "name": "Kineko",
    "isPopular": false,
    "chainId": "1",
    "address": "0x368c5290b13caa10284db58b4ad4f3e9ee8bf4c9",
    "decimals": 18,
    "image": "https://rango.vip/i/JMqJZK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LDN",
    "name": "Ludena Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb29663aa4e2e81e425294193616c1b102b70a158",
    "decimals": 18,
    "image": "https://rango.vip/i/N1nwSN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEAG",
    "name": "LeagueDAO Governance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x7b39917f9562c8bc83c7a6c2950ff571375d505d",
    "decimals": 18,
    "image": "https://rango.vip/i/Wf56X2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LEVX",
    "name": "LevX DAŌh..Geez",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf474e526ade9ad2cc2b66ffce528b1a51b91fcdc",
    "decimals": 18,
    "image": "https://rango.vip/i/omKT3U",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LOBI",
    "name": "Lobi",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdec41db0c33f3f6f3cb615449c311ba22d418a8d",
    "decimals": 9,
    "image": "https://rango.vip/i/xZDRxi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MAMZN",
    "name": "Mirrored Amazon",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0cae9e4d663793c2a2a0b211c1cf4bbca2b9caa7",
    "decimals": 18,
    "image": "https://rango.vip/i/vRB7ya",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MBBASED",
    "name": "Moonbase",
    "isPopular": false,
    "chainId": "1",
    "address": "0x26cf82e4ae43d31ea51e72b663d26e26a75af729",
    "decimals": 18,
    "image": "https://rango.vip/i/3W8Eik",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "METACAT",
    "name": "MetaCat",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0dc7d0192c148d7d2d6fa32dc280f953c0ad6a34",
    "decimals": 18,
    "image": "https://rango.vip/i/rpID5K",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MFB",
    "name": "Mirrored Facebook",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0e99cc0535bb6251f6679fa6e65d6d3b430e840b",
    "decimals": 18,
    "image": "https://rango.vip/i/Z7OXsJ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MGOOGL",
    "name": "M Google",
    "isPopular": false,
    "chainId": "1",
    "address": "0x59a921db27dd6d4d974745b7ffc5c33932653442",
    "decimals": 18,
    "image": "https://rango.vip/i/XMrW8u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MMSFT",
    "name": "Mirrored Microsoft",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41bbedd7286daab5910a1f15d12cbda839852bd7",
    "decimals": 18,
    "image": "https://rango.vip/i/MzPVoY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MQQQ",
    "name": "Wrapped Mirror QQQ Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x13b02c8de71680e71f0820c996e4be43c2f57d15",
    "decimals": 18,
    "image": "https://rango.vip/i/S8gRFA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MSLV",
    "name": "Mirrored iShares Si",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d1555d8cb3c846bb4f7d5b1b1080872c3166676",
    "decimals": 18,
    "image": "https://rango.vip/i/sZLZiF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MTSLA",
    "name": "Mirrored Tesla",
    "isPopular": false,
    "chainId": "1",
    "address": "0x21ca39943e91d704678f5d00b6616650f066fd63",
    "decimals": 18,
    "image": "https://rango.vip/i/8ptPJT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUSH",
    "name": "Mushroom",
    "isPopular": false,
    "chainId": "1",
    "address": "0xea6412fb370e8d1605e6aeeaa21ad07c3c7e9f24",
    "decimals": 18,
    "image": "https://rango.vip/i/yMxQP2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUSO",
    "name": "M US Oil",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31c63146a635eb7465e5853020b39713ac356991",
    "decimals": 18,
    "image": "https://rango.vip/i/FeKcKb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MVIXY",
    "name": "Mirrored ProShares",
    "isPopular": false,
    "chainId": "1",
    "address": "0xf72fcd9dcf0190923fadd44811e240ef4533fc86",
    "decimals": 18,
    "image": "https://rango.vip/i/ZwFADa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "NIL",
    "name": "nil",
    "isPopular": false,
    "chainId": "1",
    "address": "0x47252a63c723889814aebcac0683e615624cec64",
    "decimals": 18,
    "image": "https://rango.vip/i/cWQsCC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "OH-GEEZ",
    "name": "Oh..Geez",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1098269bfc70b26dea43f18f812d2b9854e874ba",
    "decimals": 18,
    "image": "https://rango.vip/i/pNedAR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "1ONE",
    "name": "Harmony ONE",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd5cd84d6f044abe314ee7e414d37cae8773ef9d3",
    "decimals": 18,
    "image": "https://rango.vip/i/3YqZK3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONEBTC",
    "name": "oneBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc88f47067db2e25851317a2fdae73a22c0777c37",
    "decimals": 9,
    "image": "https://rango.vip/i/TIJydx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ONEWING",
    "name": "oneWING",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8f041a3940a5e6fb580075c3774e15fcfa0e1618",
    "decimals": 9,
    "image": "https://rango.vip/i/ABisQX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PECO",
    "name": "Polygon Ecosystem Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9d3ee6b64e69ebe12a4bf0b01d031cb80f556ee4",
    "decimals": 18,
    "image": "https://rango.vip/i/eiHzYP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PONT",
    "name": "Poly Ontology Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb46c550539ac3db72dc7af7c89b11c306c727c2",
    "decimals": 9,
    "image": "https://rango.vip/i/5VXTJT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PWING",
    "name": "Poly Ontology Wing Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xdb0f18081b505a7de20b18ac41856bcb4ba86a1a",
    "decimals": 9,
    "image": "https://rango.vip/i/hY4GoG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 4.129463337213974,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "REMIO",
    "name": "Remilio Babies",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa35bd2246978dfbb1980dff8ff0f5834335dfdbc",
    "decimals": 18,
    "image": "https://rango.vip/i/eLE2Tu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RENBCH",
    "name": "renBCH",
    "isPopular": false,
    "chainId": "1",
    "address": "0x459086f2376525bdceba5bdda135e4e9d3fef5bf",
    "decimals": 8,
    "image": "https://rango.vip/i/d8ME7h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RENDGB",
    "name": "renDGB",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe3cb486f3f5c639e98ccbaf57d95369375687f80",
    "decimals": 8,
    "image": "https://rango.vip/i/YO7rBI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RENDOGE",
    "name": "renDOGE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3832d2f059e55934220881f831be501d180671a7",
    "decimals": 8,
    "image": "https://rango.vip/i/DaoP3A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RENLUNA",
    "name": "renLUNA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x52d87f22192131636f93c5ab18d0127ea52cb641",
    "decimals": 6,
    "image": "https://rango.vip/i/o0cZ0t",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RULER",
    "name": "RULER",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2aeccb42482cc64e087b6d2e5da39f5a7a7001f8",
    "decimals": 18,
    "image": "https://rango.vip/i/C5NX8q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SAK3",
    "name": "Sake",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe9f84de264e91529af07fa2c746e934397810334",
    "decimals": 18,
    "image": "https://rango.vip/i/i6RhkC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCEX",
    "name": "Synth sCEX",
    "isPopular": false,
    "chainId": "1",
    "address": "0xeabacd844a196d7faf3ce596edebf9900341b420",
    "decimals": 18,
    "image": "https://rango.vip/i/DRmIhg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SDEFI",
    "name": "Synth sDEFI",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1afe1fd76fd88f78cbf599ea1846231b8ba3b6b",
    "decimals": 18,
    "image": "https://rango.vip/i/qHs1hw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SEEN",
    "name": "seen.haus",
    "isPopular": false,
    "chainId": "1",
    "address": "0xca3fe04c7ee111f0bbb02c328c699226acf9fd33",
    "decimals": 18,
    "image": "https://rango.vip/i/XTB4vz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "STZEN",
    "name": "stakedZEN",
    "isPopular": false,
    "chainId": "1",
    "address": "0x31b595e7cfdb624d10a3e7a562ed98c3567e3865",
    "decimals": 8,
    "image": "https://rango.vip/i/UDssNP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUNDER",
    "name": "Sunder Goverance Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbdbf245c690d54b67c6e610a28486a2c6de08be6",
    "decimals": 18,
    "image": "https://rango.vip/i/hBCIxC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SUSH",
    "name": "Sushimi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4086590892a9232166d7849ec34158d823498d40",
    "decimals": 18,
    "image": "https://rango.vip/i/4bqBMy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SXRP",
    "name": "Synth XRP",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa2b0fde6d710e201d0d608e924a484d1a5fed57c",
    "decimals": 18,
    "image": "https://rango.vip/i/gBswfA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TGBP",
    "name": "TrueGBP",
    "isPopular": false,
    "chainId": "1",
    "address": "0x00000000441378008ea67f4284a57932b1c000a5",
    "decimals": 18,
    "image": "https://rango.vip/i/b89z9G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TRY",
    "name": "TRYfinance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xc12ecee46ed65d970ee5c899fcc7ae133aff9b03",
    "decimals": 18,
    "image": "https://rango.vip/i/Uzlb3V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00384854,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TWO",
    "name": "TwoCoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1e0275806c3cd0bdb5c99916a064d36b5e8eae8d",
    "decimals": 18,
    "image": "https://rango.vip/i/VWawHe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UBXT",
    "name": "UpBots",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8564653879a18c560e7c0ea0e084c516c62f5653",
    "decimals": 18,
    "image": "https://rango.vip/i/X7mJJT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "UOP",
    "name": "Utopia Genesis Foundation",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe4ae84448db5cfe1daf1e6fb172b469c161cb85f",
    "decimals": 18,
    "image": "https://rango.vip/i/1JP7U5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VBTC",
    "name": "vBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe1406825186d63980fd6e2ec61888f7b91c4bae4",
    "decimals": 18,
    "image": "https://rango.vip/i/Z2PORN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1136.6720779344828,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "VOL",
    "name": "Volatility Protocol Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5166e09628b696285e3a151e84fb977736a83575",
    "decimals": 18,
    "image": "https://rango.vip/i/1MrEtS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WASABI",
    "name": "Wasabi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x896e145568624a498c5a909187363ae947631503",
    "decimals": 18,
    "image": "https://rango.vip/i/u7bq73",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WCELO",
    "name": "Wrapped Celo",
    "isPopular": false,
    "chainId": "1",
    "address": "0xe452e6ea2ddeb012e20db73bf5d3863a3ac8d77a",
    "decimals": 18,
    "image": "https://rango.vip/i/GzP3GY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WCUSD",
    "name": "Wrapped Celo Dollar",
    "isPopular": false,
    "chainId": "1",
    "address": "0xad3e3fc59dff318beceaab7d00eb4f68b1ecf195",
    "decimals": 18,
    "image": "https://rango.vip/i/bxd5ex",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WFIL",
    "name": "Wrapped Filecoin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6e1a19f235be7ed8e3369ef73b196c07257494de",
    "decimals": 18,
    "image": "https://rango.vip/i/HXJuFx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 3.33,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WKDA",
    "name": "Wrapped Kadena",
    "isPopular": false,
    "chainId": "1",
    "address": "0x85d7bdfc9c3426b33a684241eeee70385bc42820",
    "decimals": 18,
    "image": "https://rango.vip/i/5ldUd8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WOOFY",
    "name": "Woofy",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd0660cd418a64a1d44e9214ad8e459324d8157f1",
    "decimals": 12,
    "image": "https://rango.vip/i/sL4fTP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WXMR",
    "name": "Wrapped XMR",
    "isPopular": false,
    "chainId": "1",
    "address": "0x465e07d6028830124be2e4aa551fbe12805db0f5",
    "decimals": 18,
    "image": "https://rango.vip/i/Y3Cieq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "WZEC",
    "name": "Wrapped ZEC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x4a64515e5e1d1073e83f30cb97bed20400b66e10",
    "decimals": 18,
    "image": "https://rango.vip/i/tg3Kdt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XBE",
    "name": "XBE",
    "isPopular": false,
    "chainId": "1",
    "address": "0x5de7cc4bcbca31c473f6d2f27825cfb09cc0bb16",
    "decimals": 18,
    "image": "https://rango.vip/i/rWm2UO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "XCRMRK",
    "name": "xcRMRK",
    "isPopular": false,
    "chainId": "1",
    "address": "0x471ea49dd8e60e697f4cac262b5fafcc307506e4",
    "decimals": 10,
    "image": "https://rango.vip/i/ioF0aA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 2.36,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YETI",
    "name": "Yearn Ecosystem Token Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0xb4bebd34f6daafd808f73de0d10235a92fbb6c3d",
    "decimals": 18,
    "image": "https://rango.vip/i/DBBpUa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 1.98e-8,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YLA",
    "name": "Yearn Lazy Ape Index",
    "isPopular": false,
    "chainId": "1",
    "address": "0x9ba60ba98413a60db4c651d4afe5c937bbd8044b",
    "decimals": 18,
    "image": "https://rango.vip/i/Lfwudl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YOP",
    "name": "YOP",
    "isPopular": false,
    "chainId": "1",
    "address": "0xae1eaae3f627aaca434127644371b67b18444051",
    "decimals": 8,
    "image": "https://rango.vip/i/sM635u",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "YPIE",
    "name": "PieDAO Yearn Ecosystem Pie",
    "isPopular": false,
    "chainId": "1",
    "address": "0x17525e4f4af59fbc29551bc4ece6ab60ed49ce31",
    "decimals": 18,
    "image": "https://rango.vip/i/e5LWmW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ZLOT",
    "name": "zLot Finance",
    "isPopular": false,
    "chainId": "1",
    "address": "0xa8e7ad77c60ee6f30bac54e2e7c0617bd7b5a03e",
    "decimals": 18,
    "image": "https://rango.vip/i/q8E8DA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "ARCHIE",
    "name": "Archie Neko",
    "isPopular": false,
    "chainId": "1",
    "address": "0xfe5f69dfa2d4501e78078266f6d430c079098f90",
    "decimals": 9,
    "image": "https://rango.vip/i/29XLVd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "USDCSO",
    "name": "USDCso (wormhole)",
    "isPopular": false,
    "chainId": "1",
    "address": "0x41f7b8b9b897276b7aae926a9016935280b44e97",
    "decimals": 6,
    "image": "https://rango.vip/i/M4gWuP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchEth"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TONCOIN",
    "name": "Wrapped TON Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x76a797a59ba2c17726896976b7b3747bfd1d220f",
    "decimals": 9,
    "image": "https://rango.vip/i/qoxh3h",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 4.78,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TRUEPNL",
    "name": "PNL",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb346c52874c7023df183068c39478c3b7b2515bc",
    "decimals": 18,
    "image": "https://rango.vip/i/AzS1i5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01597292,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BZEN",
    "name": "BITZEN",
    "isPopular": false,
    "chainId": "56",
    "address": "0xdacc0417add48b63cbefb77efbe4a3801aad51ba",
    "decimals": 9,
    "image": "https://rango.vip/i/wyOCQP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BABYDOGE",
    "name": "Baby Doge Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc748673057861a797275cd8a068abb95a902e8de",
    "decimals": 9,
    "image": "https://rango.vip/i/HGnt6v",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 9.06889e-10,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NELO",
    "name": "NELO Metaverse",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe38950f71e2d2fc4ca9dc9c3625d82560b0a5d8f",
    "decimals": 9,
    "image": "https://rango.vip/i/uUVwJk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "COINSCOPE",
    "name": "Coinscope",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd41c4805a9a3128f9f7a7074da25965371ba50d5",
    "decimals": 18,
    "image": "https://rango.vip/i/Lx5pPZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LAND_2",
    "name": "META-UTOPIA LAND",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9131066022b909c65edd1aaf7ff213dacf4e86d0",
    "decimals": 18,
    "image": "https://rango.vip/i/ocKGEt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PINKSALE",
    "name": "Pink Sale",
    "isPopular": false,
    "chainId": "56",
    "address": "0x602ba546a7b06e0fc7f58fd27eb6996ecc824689",
    "decimals": 18,
    "image": "https://rango.vip/i/VyO7Y5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 217.28,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VRGW",
    "name": "Virtual Reality Game World",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfdd2374be7ae7a71138b9f6b93d9ef034a49edb6",
    "decimals": 18,
    "image": "https://rango.vip/i/XtVte8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BDO",
    "name": "bDollar",
    "isPopular": false,
    "chainId": "56",
    "address": "0x190b589cf9fb8ddeabbfeae36a813ffb2a702454",
    "decimals": 18,
    "image": "https://rango.vip/i/4hzGPE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00430214,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ZSEED",
    "name": "zSeedToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5cd50aae14e14b3fdf3ff13c7a40e8cf5ae8b0a5",
    "decimals": 18,
    "image": "https://rango.vip/i/gIzm2n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SBDO",
    "name": "bDollar Share",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0d9319565be7f53cefe84ad201be3f40feae2740",
    "decimals": 18,
    "image": "https://rango.vip/i/YYKVyg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VSXP",
    "name": "Venus SXP",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2ff3d0f6990a40261c66e1ff2017acbc282eb6d0",
    "decimals": 8,
    "image": "https://rango.vip/i/G269gI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00381099,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VUSDC",
    "name": "Venus USDC",
    "isPopular": false,
    "chainId": "56",
    "address": "0xeca88125a5adbe82614ffc12d0db554e2e2867c8",
    "decimals": 8,
    "image": "https://rango.vip/i/NcAcpN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02438679,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VUSDT",
    "name": "Venus USDT",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfd5840cd36d94d7229439859c0112a4185bc0255",
    "decimals": 8,
    "image": "https://rango.vip/i/os56VO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02427832,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VBUSD_2",
    "name": "Venus BUSD",
    "isPopular": false,
    "chainId": "56",
    "address": "0x95c78222b3d6e262426483d42cfa53685a67ab9d",
    "decimals": 8,
    "image": "https://rango.vip/i/ETeVbk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0222607,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VBNB",
    "name": "Venus BNB",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa07c5b74c9b40447a954e1466938b865b6bbea36",
    "decimals": 8,
    "image": "https://rango.vip/i/bKOTeE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 12.2819321967828,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VXVS",
    "name": "Venus XVS",
    "isPopular": false,
    "chainId": "56",
    "address": "0x151b1e2635a717bcdc836ecd6fbb62b674fe3e1d",
    "decimals": 8,
    "image": "https://rango.vip/i/0XCw2Q",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.123592,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VBTC",
    "name": "Venus BTC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x882c173bc7ff3b7786ca16dfed3dfffb9ee7847b",
    "decimals": 8,
    "image": "https://rango.vip/i/gGdQut",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1132.89,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VETH",
    "name": "Venus ETH",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf508fcd89b8bd15579dc79a6827cb4686a3592c8",
    "decimals": 8,
    "image": "https://rango.vip/i/EwuhVF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 48.6,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VLTC",
    "name": "Venus LTC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x57a5297f2cb2c0aac9d554660acd6d385ab50c6b",
    "decimals": 8,
    "image": "https://rango.vip/i/zHhbmx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.32,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VXRP",
    "name": "Venus XRP",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb248a295732e0225acd3337607cc01068e3b9c10",
    "decimals": 8,
    "image": "https://rango.vip/i/4AzlAy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01086575,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VBCH",
    "name": "Venus BCH",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5f0388ebc2b94fa8e123f404b79ccf5f40b29176",
    "decimals": 8,
    "image": "https://rango.vip/i/vOeJeR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 6.41,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VDOT",
    "name": "Venus DOT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1610bc33319e9398de5f57b33a5b184c806ad217",
    "decimals": 8,
    "image": "https://rango.vip/i/hpbaSK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0898,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VLINK",
    "name": "Venus LINK",
    "isPopular": false,
    "chainId": "56",
    "address": "0x650b940a1033b8a1b1873f78730fcfc73ec11f1f",
    "decimals": 8,
    "image": "https://rango.vip/i/RJp8tM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.201732,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VDAI",
    "name": "Venus DAI",
    "isPopular": false,
    "chainId": "56",
    "address": "0x334b3ecb4dca3593bccc3c7ebd1a1c1d1780fbf1",
    "decimals": 8,
    "image": "https://rango.vip/i/5x8EYb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.02394917,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VFIL",
    "name": "Venus FIL",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf91d58b5ae142dacc749f58a49fcbac340cb0343",
    "decimals": 8,
    "image": "https://rango.vip/i/uakfsv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.068933,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PAX",
    "name": "Paxos Standard",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb7f8cd00c5a06c0537e2abff0b58033d02e5e094",
    "decimals": 18,
    "image": "https://rango.vip/i/Alcojr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BTD",
    "name": "Bolt Dollar",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd1102332a213e21faf78b69c03572031f3552c33",
    "decimals": 18,
    "image": "https://rango.vip/i/JlUa0z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "JULB",
    "name": "JULb",
    "isPopular": false,
    "chainId": "56",
    "address": "0x32dffc3fe8e3ef3571bf8a72c0d0015c5373f41d",
    "decimals": 18,
    "image": "https://rango.vip/i/J1v8zK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BKANGAL",
    "name": "Kangal",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd632bd021a07af70592ce1e18717ab9aa126decb",
    "decimals": 18,
    "image": "https://rango.vip/i/ZeezLy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00000177,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SOUP",
    "name": "Soup",
    "isPopular": false,
    "chainId": "56",
    "address": "0x94f559ae621f1c810f31a6a620ad7376776fe09e",
    "decimals": 18,
    "image": "https://rango.vip/i/0pGEmY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MDO",
    "name": "Midas Dollar",
    "isPopular": false,
    "chainId": "56",
    "address": "0x35e869b7456462b81cdb5e6e42434bd27f3f788c",
    "decimals": 18,
    "image": "https://rango.vip/i/mvwWhS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BSRK",
    "name": "SparkPoint",
    "isPopular": false,
    "chainId": "56",
    "address": "0x14b1166ab53a237c8ceaee2bbc4bbca200cb7da8",
    "decimals": 18,
    "image": "https://rango.vip/i/MMPC6x",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MSC",
    "name": "Monster Slayer Cash",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8c784c49097dcc637b93232e15810d53871992bf",
    "decimals": 18,
    "image": "https://rango.vip/i/gecONM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ALLOY",
    "name": "HyperAlloy",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5ef5994fa33ff4eb6c82d51ee1dc145c546065bd",
    "decimals": 18,
    "image": "https://rango.vip/i/V2dgOx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "YPANDA",
    "name": "YieldPanda.finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9806aec346064183b5ce441313231dff89811f7a",
    "decimals": 8,
    "image": "https://rango.vip/i/BKQt1B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FAT",
    "name": "Fatfi Protocol",
    "isPopular": false,
    "chainId": "56",
    "address": "0x90e767a68a7d707b74d569c8e79f9bbb79b98a8b",
    "decimals": 18,
    "image": "https://rango.vip/i/MHiqOe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SAFESTAR",
    "name": "SafeStar",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3c00f8fcc8791fa78daa4a480095ec7d475781e2",
    "decimals": 9,
    "image": "https://rango.vip/i/WswKom",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FEB",
    "name": "FEB Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa72a0564d0e887123112e6a4dc1aba7611ad861d",
    "decimals": 0,
    "image": "https://rango.vip/i/cV7DxR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PCWS",
    "name": "PolyCrowns",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbcf39f0edda668c58371e519af37ca705f2bfcbd",
    "decimals": 18,
    "image": "https://rango.vip/i/xk3Z3c",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "POLAR",
    "name": "Polaris",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3a5325f0e5ee4da06a285e988f052d4e45aa64b4",
    "decimals": 18,
    "image": "https://rango.vip/i/wOwLGL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00001486,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BDIGG",
    "name": "Badger Sett Digg",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5986d5c77c65e5801a5caa4fae80089f870a71da",
    "decimals": 18,
    "image": "https://rango.vip/i/uJgxjg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "VANCAT",
    "name": "VANCAT Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8597ba143ac509189e89aab3ba28d661a5dd9830",
    "decimals": 0,
    "image": "https://rango.vip/i/4Bj48L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NAUT",
    "name": "Astronaut",
    "isPopular": false,
    "chainId": "56",
    "address": "0x05b339b0a346bf01f851dde47a5d485c34fe220c",
    "decimals": 8,
    "image": "https://rango.vip/i/8FktYX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HOGL",
    "name": "HOGL Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0x182c763a4b2fbd18c9b5f2d18102a0ddd9d5df26",
    "decimals": 18,
    "image": "https://rango.vip/i/FckYnb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BBADGER",
    "name": "Badger Sett Badger",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1f7216fdb338247512ec99715587bb97bbf96eae",
    "decimals": 18,
    "image": "https://rango.vip/i/btw5ul",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.39,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BWJUP",
    "name": "BSC Wrapped Jupiter",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0231f91e02debd20345ae8ab7d71a41f8e140ce7",
    "decimals": 18,
    "image": "https://rango.vip/i/qxmDlF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00049809,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HPS",
    "name": "HappinessToken",
    "isPopular": false,
    "chainId": "56",
    "address": "0xeda21b525ac789eab1a08ef2404dd8505ffb973d",
    "decimals": 18,
    "image": "https://rango.vip/i/2ToMPB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ACSI",
    "name": "ACryptoS(I)",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5b17b4d5e4009b5c43e3e3d63a5229f794cba389",
    "decimals": 18,
    "image": "https://rango.vip/i/Wwpecb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.06597,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SWIRL",
    "name": "Swirl.Cash",
    "isPopular": false,
    "chainId": "56",
    "address": "0x52d86850bc8207b520340b7e39cdaf22561b9e56",
    "decimals": 18,
    "image": "https://rango.vip/i/KTFGCE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XSPACE",
    "name": "XSPACE",
    "isPopular": false,
    "chainId": "56",
    "address": "0xad90c05bc51672eedfee36e58b3ff1a78bbc146d",
    "decimals": 9,
    "image": "https://rango.vip/i/LN7d1z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WARDEN",
    "name": "WardenSwap Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0feadcc3824e7f3c12f40e324a60c23ca51627fc",
    "decimals": 18,
    "image": "https://rango.vip/i/RLyJXy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SAFEGALAXY",
    "name": "SafeGalaxy",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6b51231c43b1604815313801db5e9e614914d6e4",
    "decimals": 9,
    "image": "https://rango.vip/i/Lkw9mW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MRAT",
    "name": "Moon Rat Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6d949f9297a522c0f97c232cc209a67bd7cfa471",
    "decimals": 9,
    "image": "https://rango.vip/i/Ztcztb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BGOV",
    "name": "BGOV Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf8e026dc4c0860771f691ecffbbdfe2fa51c77cf",
    "decimals": 18,
    "image": "https://rango.vip/i/G6YZAp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FSAFE",
    "name": "Fair Safe",
    "isPopular": false,
    "chainId": "56",
    "address": "0xee738a9e5fb78c24d26cecd30389ed977c38d0ca",
    "decimals": 9,
    "image": "https://rango.vip/i/iS48x0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SAFEBTC",
    "name": "SafeBTC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x380624a4a7e69db1ca07deecf764025fc224d056",
    "decimals": 9,
    "image": "https://rango.vip/i/ZJ5OZ8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "AQUAGOAT",
    "name": "Aquagoat",
    "isPopular": false,
    "chainId": "56",
    "address": "0x07af67b392b7a202fad8e0fbc64c34f33102165b",
    "decimals": 9,
    "image": "https://rango.vip/i/gKrFqS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.19248e-10,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ECP",
    "name": "Eclipse",
    "isPopular": false,
    "chainId": "56",
    "address": "0x375483cfa7fc18f6b455e005d835a8335fbdbb1f",
    "decimals": 9,
    "image": "https://rango.vip/i/VUbtkR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.263,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MOONTOKEN",
    "name": "Moon Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x81e4d494b85a24a58a6ba45c9b418b32a4e039de",
    "decimals": 18,
    "image": "https://rango.vip/i/gfneY6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ELONGATE",
    "name": "ElonGate",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2a9718deff471f3bb91fa0eceab14154f150a385",
    "decimals": 9,
    "image": "https://rango.vip/i/rC9lDc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PDO",
    "name": "pDollar",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5bccfbd33873a5498f8406146868eddd5e998962",
    "decimals": 18,
    "image": "https://rango.vip/i/mRyuZC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.137652,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XEND",
    "name": "XEND",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4a080377f83d669d7bb83b3184a8a5e61b500608",
    "decimals": 18,
    "image": "https://rango.vip/i/Nuc5mq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.050419,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WENMOON",
    "name": "WenMoon Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb93ba7dc61ecfced69067151fc00c41ca369a797",
    "decimals": 7,
    "image": "https://rango.vip/i/adBObB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FMT",
    "name": "Finminity",
    "isPopular": false,
    "chainId": "56",
    "address": "0x99c6e435ec259a7e8d65e1955c9423db624ba54c",
    "decimals": 18,
    "image": "https://rango.vip/i/FnZQhg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01514672,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MOONMOON",
    "name": "MoonMoon",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0e0e877894a101ad8711ae3a0194fa44ca837a79",
    "decimals": 9,
    "image": "https://rango.vip/i/WWeLrq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SAFESPACE",
    "name": "SAFESPACE",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe1db3d1ee5cfe5c6333be96e6421f9bd5b85c987",
    "decimals": 9,
    "image": "https://rango.vip/i/FS9Q98",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BLEO",
    "name": "BEP20 LEO",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6421531af54c7b14ea805719035ebf1e3661c44a",
    "decimals": 3,
    "image": "https://rango.vip/i/eJRUST",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.029146,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "UNIF",
    "name": "Unified",
    "isPopular": false,
    "chainId": "56",
    "address": "0xce5347fdd503f25f8428151a274544a5bd1bd8ca",
    "decimals": 9,
    "image": "https://rango.vip/i/AtPRBS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BINGUS",
    "name": "Bingus Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xda20c8a5c3b1ab48e31ba6e43f0f2830e50218d8",
    "decimals": 9,
    "image": "https://rango.vip/i/AAgln4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00042365,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BROOBEE",
    "name": "ROOBEE",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe",
    "decimals": 18,
    "image": "https://rango.vip/i/dWIhGy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LOT",
    "name": "Lottery Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4e7ae924fd9a5d60b56be486b2900efe0c6a9ca7",
    "decimals": 9,
    "image": "https://rango.vip/i/D09sL7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KAIINU",
    "name": "Kai Inu",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe5a09784b16e1065c37df14c6e2f06fdce317a1b",
    "decimals": 9,
    "image": "https://rango.vip/i/PUWFkR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PORN",
    "name": "Porn",
    "isPopular": false,
    "chainId": "56",
    "address": "0x31b9773f225408129a90788ef013bd449e283865",
    "decimals": 9,
    "image": "https://rango.vip/i/9Xob5E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MTDR",
    "name": "Matador Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x994517e000aa3f117e7ad61b0e2336c76b4fd94a",
    "decimals": 18,
    "image": "https://rango.vip/i/IdIpPD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PEKC",
    "name": "PEACOCKCOIN",
    "isPopular": false,
    "chainId": "56",
    "address": "0x050787de0cf5da03d9387b344334d51cae5dd0fd",
    "decimals": 9,
    "image": "https://rango.vip/i/IoU6sP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MOONRISE",
    "name": "MoonRise",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7ee7f14427cc41d6db17829eb57dc74a26796b9d",
    "decimals": 9,
    "image": "https://rango.vip/i/kB6OpZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WBAN",
    "name": "Wrapped Banano",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe20b9e246db5a0d21bf9209e4858bc9a3ff7a034",
    "decimals": 18,
    "image": "https://rango.vip/i/O3FrTx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00307791,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XYSL",
    "name": "xYSL token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0047a0deadafb7ee6b1a0d219e70fb6767057d93",
    "decimals": 18,
    "image": "https://rango.vip/i/zjIw6r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "QA",
    "name": "Quantum Assets Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4ef29f3b804c316ba8ba464a765c601fc092a2e9",
    "decimals": 18,
    "image": "https://rango.vip/i/3Qy2Q4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SUSHIBA",
    "name": "Sushiba",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa96658cd0d04a8fdcdc30d1156cc65bbfc7591ed",
    "decimals": 9,
    "image": "https://rango.vip/i/oLzGg7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BPAY",
    "name": "BNBPay",
    "isPopular": false,
    "chainId": "56",
    "address": "0xebc76079da0c245fae7225b58a57a54809b40618",
    "decimals": 9,
    "image": "https://rango.vip/i/WJYeZZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TSUGA",
    "name": "Tsukiverse: Galactic Adventures",
    "isPopular": false,
    "chainId": "56",
    "address": "0x58d372a8db7696c0c066f25c9eaf2af6f147b726",
    "decimals": 18,
    "image": "https://rango.vip/i/Fq0w9d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PULI",
    "name": "PULI INU",
    "isPopular": false,
    "chainId": "56",
    "address": "0xaef0a177c8c329cbc8508292bb7e06c00786bbfc",
    "decimals": 9,
    "image": "https://rango.vip/i/o4luCX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RVZ",
    "name": "Revoluzion",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7d89c67d3c4e72e8c5c64be201dc225f99d16aca",
    "decimals": 9,
    "image": "https://rango.vip/i/nkY0Zg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DEUSDC",
    "name": "deBridge USD Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa",
    "decimals": 6,
    "image": "https://rango.vip/i/aEOOIW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DERC",
    "name": "DeRace Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x373e768f79c820aa441540d254dca6d045c6d25b",
    "decimals": 18,
    "image": "https://rango.vip/i/Cwc5Hy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.31295,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TRY",
    "name": "TryHards",
    "isPopular": false,
    "chainId": "56",
    "address": "0x75d107de2217ffe2cd574a1b3297c70c8fafd159",
    "decimals": 18,
    "image": "https://rango.vip/i/dZV0fh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00384854,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BLID",
    "name": "Bolide",
    "isPopular": false,
    "chainId": "56",
    "address": "0x766afcf83fd5eaf884b3d529b432ca27a6d84617",
    "decimals": 18,
    "image": "https://rango.vip/i/zn2IRg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00519762,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SALE",
    "name": "DxSale.Network",
    "isPopular": false,
    "chainId": "56",
    "address": "0x04f73a09e2eb410205be256054794fb452f0d245",
    "decimals": 18,
    "image": "https://rango.vip/i/fjLAju",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.145844,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SNP",
    "name": "Synapse Network",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d",
    "decimals": 18,
    "image": "https://rango.vip/i/Uhp7AN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.03005783,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "RAINI",
    "name": "Rainicorn",
    "isPopular": false,
    "chainId": "56",
    "address": "0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed",
    "decimals": 18,
    "image": "https://rango.vip/i/zCY7dZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00580562,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MMG",
    "name": "MMG Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf018aea0a08a5d88674f0837bdac27ab89824dee",
    "decimals": 18,
    "image": "https://rango.vip/i/xknIFG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00154869,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BABBC",
    "name": "Binance ABBC",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe83ce6bfb580583bd6a62b4be7b34fc25f02910d",
    "decimals": 8,
    "image": "https://rango.vip/i/PUHG5S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "INUKO",
    "name": "Inuko Coin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xea51801b8f5b88543ddad3d1727400c15b209d8f",
    "decimals": 18,
    "image": "https://rango.vip/i/uOcJpS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01653769,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XCT",
    "name": "Citadel.one",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe8670901e86818745b28c8b30b17986958fce8cc",
    "decimals": 6,
    "image": "https://rango.vip/i/qTJ2kW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00217577,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ALIX",
    "name": "AlinX",
    "isPopular": false,
    "chainId": "56",
    "address": "0xaf6bd11a6f8f9c44b9d18f5fa116e403db599f8e",
    "decimals": 18,
    "image": "https://rango.vip/i/1MtL2f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DRACE",
    "name": "DeathRoad Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa6c897caaca3db7fd6e2d2ce1a00744f40ab87bb",
    "decimals": 18,
    "image": "https://rango.vip/i/n2kDup",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00001159,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DRS",
    "name": "DragonSlayer",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc8e8ecb2a5b5d1ecff007bf74d15a86434aa0c5c",
    "decimals": 9,
    "image": "https://rango.vip/i/amaX5o",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 5.6912e-8,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NBL",
    "name": "Nobility",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa67a13c9283da5aabb199da54a9cb4cd8b9b16ba",
    "decimals": 18,
    "image": "https://rango.vip/i/Q5lqA7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00007433,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PETG",
    "name": "PET GAMES",
    "isPopular": false,
    "chainId": "56",
    "address": "0x09607078980cbb0665aba9c6d1b84b8ead246aa0",
    "decimals": 18,
    "image": "https://rango.vip/i/xobn4b",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SMON",
    "name": "StarMon",
    "isPopular": false,
    "chainId": "56",
    "address": "0xab15b79880f11cffb58db25ec2bc39d28c4d80d2",
    "decimals": 18,
    "image": "https://rango.vip/i/rtJOZO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00569316,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TSC",
    "name": "TrusterCoin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa2a26349448ddafae34949a6cc2cecf78c0497ac",
    "decimals": 9,
    "image": "https://rango.vip/i/XtSx3R",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "UNCL",
    "name": "UNCL on xDai on BSC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0e8d5504bf54d9e44260f8d153ecd5412130cabb",
    "decimals": 18,
    "image": "https://rango.vip/i/tiaAD7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WEYU",
    "name": "WEYU",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfafd4cb703b25cb22f43d017e7e0d75febc26743",
    "decimals": 18,
    "image": "https://rango.vip/i/QsXFzA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ZIN",
    "name": "ZomaInfinity",
    "isPopular": false,
    "chainId": "56",
    "address": "0xfbe0b4ae6e5a200c36a341299604d5f71a5f0a48",
    "decimals": 18,
    "image": "https://rango.vip/i/pLVgQj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "8PAY V2",
    "name": "8PAY Network v2",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6eadc05928acd93efb3fa0dfbc644d96c6aa1df8",
    "decimals": 18,
    "image": "https://rango.vip/i/9UyIjW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00175683,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ANTEX",
    "name": "Antex",
    "isPopular": false,
    "chainId": "56",
    "address": "0xca1acab14e85f30996ac83c64ff93ded7586977c",
    "decimals": 8,
    "image": "https://rango.vip/i/sKAVRK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ANYMTLX",
    "name": "anyMTLX",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5921dee8556c4593eefcfad3ca5e2f618606483b",
    "decimals": 18,
    "image": "https://rango.vip/i/OOVSKC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.95,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BALBT",
    "name": "AllianceBlock",
    "isPopular": false,
    "chainId": "56",
    "address": "0x72faa679e1008ad8382959ff48e392042a8b06f7",
    "decimals": 18,
    "image": "https://rango.vip/i/VJzqOG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BATH",
    "name": "Battle Hero",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0bc89aa98ad94e6798ec822d0814d934ccd0c0ce",
    "decimals": 18,
    "image": "https://rango.vip/i/f8L48r",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BFI",
    "name": "b.earnfi",
    "isPopular": false,
    "chainId": "56",
    "address": "0x81859801b01764d4f0fa5e64729f5a6c3b91435b",
    "decimals": 18,
    "image": "https://rango.vip/i/V8r9eD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BITU",
    "name": "BitU USD",
    "isPopular": false,
    "chainId": "56",
    "address": "0x654a32542a84bea7d2c2c1a1ed1aaaf26888e6bd",
    "decimals": 18,
    "image": "https://rango.vip/i/YvvhRd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BMXX",
    "name": "Multiplier",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4131b87f74415190425ccd873048c708f8005823",
    "decimals": 18,
    "image": "https://rango.vip/i/UOVqvK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BSCDEFI",
    "name": "BSC Ecosystem Defi blue chips",
    "isPopular": false,
    "chainId": "56",
    "address": "0x40e46de174dfb776bb89e04df1c47d8a66855eb3",
    "decimals": 18,
    "image": "https://rango.vip/i/2dCQCy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BSCX",
    "name": "BSCEX",
    "isPopular": false,
    "chainId": "56",
    "address": "0x5ac52ee5b2a633895292ff6d8a89bb9190451587",
    "decimals": 18,
    "image": "https://rango.vip/i/XRJaTi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 2.69,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BUX",
    "name": "BUX",
    "isPopular": false,
    "chainId": "56",
    "address": "0x211ffbe424b90e25a15531ca322adf1559779e45",
    "decimals": 18,
    "image": "https://rango.vip/i/NdJnRA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00417349,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DITTO",
    "name": "Ditto",
    "isPopular": false,
    "chainId": "56",
    "address": "0x233d91a0713155003fc4dce0afa871b508b3b715",
    "decimals": 9,
    "image": "https://rango.vip/i/yKPIA3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DKT",
    "name": "Duelist King",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7ceb519718a80dd78a8545ad8e7f401de4f2faa7",
    "decimals": 18,
    "image": "https://rango.vip/i/qfxoOX",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DLP",
    "name": "WBNB-RDNT Pancake LP",
    "isPopular": false,
    "chainId": "56",
    "address": "0x346575fc7f07e6994d76199e41d13dc1575322e1",
    "decimals": 18,
    "image": "https://rango.vip/i/O974zw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.559598,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "DPT",
    "name": "Diviner Protocol",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe69caef10a488d7af31da46c89154d025546e990",
    "decimals": 18,
    "image": "https://rango.vip/i/aNS1Bq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "ESRDNT",
    "name": "Radpie esRDNT",
    "isPopular": false,
    "chainId": "56",
    "address": "0x016abb5e48a7b64c2014f8834ecbe56cd72bf13d",
    "decimals": 18,
    "image": "https://rango.vip/i/ZftY4w",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HOO",
    "name": "Hoo",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe1d1f66215998786110ba0102ef558b22224c016",
    "decimals": 8,
    "image": "https://rango.vip/i/Tb65ex",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "HTB",
    "name": "Hotbit",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4e840aadd28da189b9906674b4afcb77c128d9ea",
    "decimals": 18,
    "image": "https://rango.vip/i/6LBO8j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "IF",
    "name": "Impossible Finance",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb0e1fc65c1a741b4662b813eb787d369b8614af1",
    "decimals": 18,
    "image": "https://rango.vip/i/wIWJZ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.01098229,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KBTC",
    "name": "Kinza Babylon Staked BTC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9356f6d95b8e109f4b7ce3e49d672967d3b48383",
    "decimals": 18,
    "image": "https://rango.vip/i/YJW2tQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 61661,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "KUN",
    "name": "Qian Governance Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1a2fb0af670d0234c2857fad35b789f8cb725584",
    "decimals": 18,
    "image": "https://rango.vip/i/pW2k8d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "LMT",
    "name": "Lympo Market Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x9617857e191354dbea0b714d78bc59e57c411087",
    "decimals": 18,
    "image": "https://rango.vip/i/wiojJy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00039209,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MAMZN",
    "name": "Mirror AMZN Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3947b992dc0147d2d89df0392213781b04b25075",
    "decimals": 18,
    "image": "https://rango.vip/i/Kavncm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MDLP",
    "name": "Magpie locked DLP",
    "isPopular": false,
    "chainId": "56",
    "address": "0x11727e5b7fa33ff4d380f3e7e877f19876c25b97",
    "decimals": 18,
    "image": "https://rango.vip/i/P7OUZv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MGOOGL",
    "name": "Mirror GOOGL Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0x62d71b23bf15218c7d2d7e48dbbd9e9c650b173f",
    "decimals": 18,
    "image": "https://rango.vip/i/w5Ffix",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MNFLX",
    "name": "Mirror NFLX Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa04f060077d90fe2647b61e4da4ad1f97d6649dc",
    "decimals": 18,
    "image": "https://rango.vip/i/Su9J3e",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MPENDLE",
    "name": "mPendle",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0465aad9da170798433f4ab7fa7ec8b9b9bf0bb1",
    "decimals": 18,
    "image": "https://rango.vip/i/9oNoYe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1.15,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "MTSLA",
    "name": "Mirror TSLA Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf215a127a196e3988c09d052e16bcfd365cd7aa3",
    "decimals": 18,
    "image": "https://rango.vip/i/NW0UGa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "PBTC",
    "name": "pBTC",
    "isPopular": false,
    "chainId": "56",
    "address": "0xed28a457a5a76596ac48d87c0f577020f6ea1c4c",
    "decimals": 18,
    "image": "https://rango.vip/i/DDjb8Z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 1593.12,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "POPEN",
    "name": "pTokens OPEN",
    "isPopular": false,
    "chainId": "56",
    "address": "0xabae871b7e3b67aeec6b46ae9fe1a91660aadac5",
    "decimals": 18,
    "image": "https://rango.vip/i/a5Upik",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0001102,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "QSD",
    "name": "QIAN second generation dollar",
    "isPopular": false,
    "chainId": "56",
    "address": "0x07aaa29e63ffeb2ebf59b33ee61437e1a91a3bb2",
    "decimals": 18,
    "image": "https://rango.vip/i/4t8E1N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SDCAKE",
    "name": "Stake DAO CAKE",
    "isPopular": false,
    "chainId": "56",
    "address": "0x6a1c1447f97b27da23dc52802f5f1435b5ac821a",
    "decimals": 18,
    "image": "https://rango.vip/i/sdj6mR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SOLVBTC.BBN",
    "name": "SolvBTC Babylon",
    "isPopular": false,
    "chainId": "56",
    "address": "0x1346b618dc92810ec74163e4c27004c921d446a5",
    "decimals": 18,
    "image": "https://rango.vip/i/pZkwfW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 55493,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "STBTC",
    "name": "Lorenzo stBTC",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf6718b2701d4a6498ef77d7c152b2137ab28b8a3",
    "decimals": 18,
    "image": "https://rango.vip/i/s55rnQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SWAMP",
    "name": "Swampy",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc5a49b4cbe004b6fd55b30ba1de6ac360ff9765d",
    "decimals": 18,
    "image": "https://rango.vip/i/igUTeU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00170264,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "TDOGE",
    "name": "Tau DOGE",
    "isPopular": false,
    "chainId": "56",
    "address": "0xe550a593d09fbc8dcd557b5c88cea6946a8b404a",
    "decimals": 8,
    "image": "https://rango.vip/i/9Fy0AB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00397204,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "UBXT",
    "name": "UpBots",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbbeb90cfb6fafa1f69aa130b7341089abeef5811",
    "decimals": 18,
    "image": "https://rango.vip/i/etBysC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WAULTX",
    "name": "Wault",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb64e638e60d154b43f660a6bf8fd8a3b249a6a21",
    "decimals": 18,
    "image": "https://rango.vip/i/vLrHar",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WMASS",
    "name": "Wrapped MASS",
    "isPopular": false,
    "chainId": "56",
    "address": "0x7e396bfc8a2f84748701167c2d622f041a1d7a17",
    "decimals": 8,
    "image": "https://rango.vip/i/aYB6mp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WROSE",
    "name": "Wrapped ROSE",
    "isPopular": false,
    "chainId": "56",
    "address": "0xf00600ebc7633462bc4f9c61ea2ce99f5aaebd4a",
    "decimals": 18,
    "image": "https://rango.vip/i/catfqA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.051404,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XMARK",
    "name": "xMARK",
    "isPopular": false,
    "chainId": "56",
    "address": "0x26a5dfab467d4f58fb266648cae769503cec9580",
    "decimals": 9,
    "image": "https://rango.vip/i/MOiYgi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "CERBY",
    "name": "Cerby Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xdef1fac7bf08f173d286bbbdcbeeade695129840",
    "decimals": 18,
    "image": "https://rango.vip/i/LgZOWi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "EKTAV2",
    "name": "EKTAv2",
    "isPopular": false,
    "chainId": "56",
    "address": "0x45808ce43eb2d7685ff0242631f0feb6f3d8701a",
    "decimals": 18,
    "image": "https://rango.vip/i/zv4kPm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.00115403,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "FRIES",
    "name": "fry.world",
    "isPopular": false,
    "chainId": "56",
    "address": "0x393b312c01048b3ed2720bf1b090084c09e408a1",
    "decimals": 18,
    "image": "https://rango.vip/i/Z79jd5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "JNTR/B",
    "name": "JNTR/b",
    "isPopular": false,
    "chainId": "56",
    "address": "0x3c037c4c2296f280bb318d725d0b454b76c199b9",
    "decimals": 18,
    "image": "https://rango.vip/i/n7yapM",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NAR",
    "name": "NAR Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xa1303e6199b319a891b79685f0537d289af1fc83",
    "decimals": 18,
    "image": "https://rango.vip/i/NmZPnY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NICE",
    "name": "NICE",
    "isPopular": false,
    "chainId": "56",
    "address": "0x8506560320826e459f356cb56ccf721da8875414",
    "decimals": 18,
    "image": "https://rango.vip/i/QsFomC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NYA",
    "name": "Nyanswop Token",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbfa0841f7a90c4ce6643f651756ee340991f99d5",
    "decimals": 18,
    "image": "https://rango.vip/i/rpY58n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 3.76977e-7,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "QUSD",
    "name": "QUSD Stablecoin",
    "isPopular": false,
    "chainId": "56",
    "address": "0xb8c540d00dd0bf76ea12e4b4b95efc90804f924e",
    "decimals": 18,
    "image": "https://rango.vip/i/ptSfCg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.993659,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SHWA",
    "name": "ShibaWallet",
    "isPopular": false,
    "chainId": "56",
    "address": "0x4a2940263acfd179dbc1c6b69a3392671accaf5b",
    "decimals": 18,
    "image": "https://rango.vip/i/deIjtU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "SSPELL",
    "name": "Staked Spell",
    "isPopular": false,
    "chainId": "56",
    "address": "0x66eff5221ca926636224650fd3b9c497ff828f7d",
    "decimals": 18,
    "image": "https://rango.vip/i/7yRonx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "STAX",
    "name": "StableXSwap",
    "isPopular": false,
    "chainId": "56",
    "address": "0x0da6ed8b13214ff28e9ca979dd37439e8a88f6c4",
    "decimals": 18,
    "image": "https://rango.vip/i/Xjpq7W",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WCCX",
    "name": "WrappedConceal",
    "isPopular": false,
    "chainId": "56",
    "address": "0x988c11625472340b7b36ff1534893780e0d8d841",
    "decimals": 6,
    "image": "https://rango.vip/i/jI6vU2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WENLAMBO",
    "name": "WenLambo",
    "isPopular": false,
    "chainId": "56",
    "address": "0xd8a31016cd7da048ca21ffe04256c6d08c3a2251",
    "decimals": 18,
    "image": "https://rango.vip/i/cJdOKT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WSOTE",
    "name": "Soteria",
    "isPopular": false,
    "chainId": "56",
    "address": "0x541e619858737031a1244a5d0cd47e5ef480342c",
    "decimals": 18,
    "image": "https://rango.vip/i/0WIwqH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WARC",
    "name": "WrappedARC",
    "isPopular": false,
    "chainId": "56",
    "address": "0x86fac768241b4133d131edccbd47f143a4fa9d32",
    "decimals": 18,
    "image": "https://rango.vip/i/IXGo64",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.0000418,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc",
      "PancakeSwapBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "WZNN",
    "name": "Zenon",
    "isPopular": false,
    "chainId": "56",
    "address": "0x84b174628911896a3b87fa6980d05dbc2ee74836",
    "decimals": 8,
    "image": "https://rango.vip/i/51NsHf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "USDTSO",
    "name": "USDTso (wormhole)",
    "isPopular": false,
    "chainId": "56",
    "address": "0x49d5cc521f75e13fa8eb4e89e9d381352c897c96",
    "decimals": 6,
    "image": "https://rango.vip/i/ICZdda",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": 0.988617,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "NRB",
    "name": "Neorbit",
    "isPopular": false,
    "chainId": "56",
    "address": "0x2f725cb7b6827bb4b02db1369b1e4c6a33e5633d",
    "decimals": 9,
    "image": "https://rango.vip/i/iuGIO6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "YSL",
    "name": "YSL",
    "isPopular": false,
    "chainId": "56",
    "address": "0xef4c37e914243322a32a81abd34de76af1b47da0",
    "decimals": 18,
    "image": "https://rango.vip/i/DnkZ30",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "XYSL",
    "name": "xYSL",
    "isPopular": false,
    "chainId": "56",
    "address": "0xbb0ae29392ee663b89c7c91429513ad8de32e371",
    "decimals": 18,
    "image": "https://rango.vip/i/j8R9Xm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "BSC",
    "symbol": "BYSL",
    "name": "bYSL",
    "isPopular": false,
    "chainId": "56",
    "address": "0xc34326533e8606e742c7e3775f59994a3ed9fb2f",
    "decimals": 18,
    "image": "https://rango.vip/i/Cykd27",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BSC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Bsc",
      "OneInchBsc"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "WISP",
    "name": "Whisper",
    "isPopular": false,
    "chainId": "324",
    "address": "0xc8ec5b0627c794de0e4ea5d97ad9a556b361d243",
    "decimals": 18,
    "image": "https://rango.vip/i/aeQrdU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00005344,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ZKSHIB",
    "name": "Zksync Shib",
    "isPopular": false,
    "chainId": "324",
    "address": "0x5e38cb3e6c0faafaa5c32c482864fcef5a0660ad",
    "decimals": 18,
    "image": "https://rango.vip/i/GYlE5k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "ZGEM",
    "name": "ZkSync Gem Token",
    "isPopular": false,
    "chainId": "324",
    "address": "0x2b64237277c605d07f17b96f9627712340c32981",
    "decimals": 18,
    "image": "https://rango.vip/i/QLav3V",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.00010263,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "VC_OLD",
    "name": "Velocore",
    "isPopular": false,
    "chainId": "324",
    "address": "0x85d84c774cf8e9ff85342684b0e795df72a24908",
    "decimals": 18,
    "image": "https://rango.vip/i/Nlnkqa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "CBETH",
    "name": "Coinbase Wrapped Staked ETH",
    "isPopular": false,
    "chainId": "324",
    "address": "0x75af292c1c9a37b3ea2e6041168b4e48875b9ed5",
    "decimals": 18,
    "image": "https://rango.vip/i/gV0YMq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 2534.8403292154535,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "IBEX",
    "name": "Impermax",
    "isPopular": false,
    "chainId": "324",
    "address": "0xbe9f8c0d6f0fd7e46cdacca340747ea2f247991d",
    "decimals": 18,
    "image": "https://rango.vip/i/UG5H28",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": 0.055798,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "XCRMRK",
    "name": "xcRMRK",
    "isPopular": false,
    "chainId": "324",
    "address": "0x6f1a89c16a49549508a2b6d2ac6f34523aa2a545",
    "decimals": 10,
    "image": "https://rango.vip/i/EIEXh5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ZKSYNC",
    "symbol": "RAISE",
    "name": "Raise Finance Token",
    "isPopular": false,
    "chainId": "324",
    "address": "0x3d79f1e3f6afd3f30ea450afffb8632aed59b46f",
    "decimals": 18,
    "image": "https://rango.vip/i/0GtIxi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ZKSYNC/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "OneInchZksync"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "2CRV",
    "name": "2CRV",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7f90122bf0700f9e7e1f688fe926940e8839f353",
    "decimals": 18,
    "image": "https://rango.vip/i/4VYStF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.026,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "DEUSDC",
    "name": "DEUSDC",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa",
    "decimals": 6,
    "image": "https://rango.vip/i/ZPMBzG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "VST",
    "name": "VST",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x64343594ab9b56e99087bfa6f2335db24c2d1f17",
    "decimals": 18,
    "image": "https://rango.vip/i/m5Kguq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.998951,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FRAXBP-F",
    "name": "FRAXBP-F",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc9b8a3fdecb9d5b218d02555a8baf332e5b740d5",
    "decimals": 18,
    "image": "https://rango.vip/i/y1XfJl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 1.0163683980181417,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "FETH",
    "name": "FETH",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc608dfb90a430df79a8a1edbc8be7f1a0eb4e763",
    "decimals": 18,
    "image": "https://rango.vip/i/TDOjdt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.9817906641615046,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "BOBRCRV",
    "name": "BOBRCRV",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xf12132857fb45b1f1342420eaaf818509f850468",
    "decimals": 18,
    "image": "https://rango.vip/i/BStdWu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 108.39574187323686,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "ASDCRV",
    "name": "ASDCRV",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x75289388d50364c3013583d97bd70ced0e183e32",
    "decimals": 18,
    "image": "https://rango.vip/i/Zi3zcP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.3935315957459316,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "VSDCRV",
    "name": "VSDCRV",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x62d5a59e0d67c0381aad53b201b4a1b8dcd2c833",
    "decimals": 18,
    "image": "https://rango.vip/i/QXoTBD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 0.2735496531959894,
    "supportedSwappers": [
      "ArbitrumCurveFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AVDAI",
    "name": "AVDAI",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x47afa96cdc9fab46904a55a6ad4bf6660b53c38a",
    "decimals": 18,
    "image": "https://rango.vip/i/wqGNBb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AVUSDC",
    "name": "AVUSDC",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x46a51127c3ce23fb7ab1de06226147f446e4a857",
    "decimals": 6,
    "image": "https://rango.vip/i/AoPAoc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "AVUSDT",
    "name": "AVUSDT",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x532e6537fea298397212f09a61e03311686f548e",
    "decimals": 6,
    "image": "https://rango.vip/i/sFL6rt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "USDL STABLECOIN",
    "name": "USDL STABLECOIN",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x40b393cecf8f7d7fc79b83e8fa40e850511817f6",
    "decimals": 18,
    "image": "https://rango.vip/i/HVdUsA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "AvalancheCurveFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "JSGD",
    "name": "JSGD",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa926db7a4cc0cb1736d5ac60495ca8eb7214b503",
    "decimals": 18,
    "image": "https://rango.vip/i/7WYQbH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "JCAD",
    "name": "JCAD",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8ca194a3b22077359b5732de53373d4afc11dee3",
    "decimals": 18,
    "image": "https://rango.vip/i/X7XEZ4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CADC",
    "name": "CAD Coin (PoS)",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5d146d8b1dacb1ebba5cb005ae1059da8a1fbf57",
    "decimals": 18,
    "image": "https://rango.vip/i/sAvzt6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EURT",
    "name": "EURT",
    "isPopular": false,
    "chainId": "137",
    "address": "0x7bdf330f423ea880ff95fc41a280fd5ecfd3d09f",
    "decimals": 6,
    "image": "https://rango.vip/i/98uJqf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.0792159914287118,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AM3CRV",
    "name": "AM3CRV",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe7a24ef0c5e95ffb0f6684b813a78f2a3ad7d171",
    "decimals": 18,
    "image": "https://rango.vip/i/itifVS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.14,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "4EUR-F",
    "name": "4EUR-F",
    "isPopular": false,
    "chainId": "137",
    "address": "0xad326c253a84e9805559b73a08724e11e49ca651",
    "decimals": 18,
    "image": "https://rango.vip/i/RyHLg3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "JJPY",
    "name": "JJPY",
    "isPopular": false,
    "chainId": "137",
    "address": "0x8343091f2499fd4b6174a46d067a920a3b851ff9",
    "decimals": 18,
    "image": "https://rango.vip/i/mHtTfQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ARTH.USD",
    "name": "ARTH.USD",
    "isPopular": false,
    "chainId": "137",
    "address": "0x84f168e646d31f6c33fdbf284d9037f59603aa28",
    "decimals": 18,
    "image": "https://rango.vip/i/eaawez",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CRVUSDBTCETH",
    "name": "CRVUSDBTCETH",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdad97f7713ae9437fa9249920ec8507e5fbb23d3",
    "decimals": 18,
    "image": "https://rango.vip/i/JISRTC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ASDCRV",
    "name": "ASDCRV",
    "isPopular": false,
    "chainId": "137",
    "address": "0x75289388d50364c3013583d97bd70ced0e183e32",
    "decimals": 18,
    "image": "https://rango.vip/i/jDNUys",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "PolygonCurveFinance"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "ECP",
    "name": "ECP",
    "isPopular": false,
    "chainId": "59144",
    "address": "0x9201f3b9dfab7c13cd659ac5695d12d605b5f1e6",
    "decimals": 18,
    "image": "https://rango.vip/i/GVQDl0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": 0.263,
    "supportedSwappers": [
      "EchoDex"
    ]
  },
  {
    "blockchain": "LINEA",
    "symbol": "LIN",
    "name": "Lineaswap.build",
    "isPopular": false,
    "chainId": "59144",
    "address": "0x9dd6ea6f9d1fba5ed640651f06802e32ff455221",
    "decimals": 18,
    "image": "https://rango.vip/i/VEo32n",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/LINEA/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "EchoDex"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "SMD",
    "name": "SwapMode Token",
    "isPopular": false,
    "chainId": "34443",
    "address": "0xfda619b6d20975be80a10332cd39b9a4b0faa8bb",
    "decimals": 18,
    "image": "https://rango.vip/i/KsvL8X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 0.019772877800215288,
    "supportedSwappers": [
      "SwapMode"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "LINDA",
    "name": "Linda",
    "isPopular": false,
    "chainId": "34443",
    "address": "0xfebbb7f02aec3f970591d7bee937efb3b07a5683",
    "decimals": 18,
    "image": "https://rango.vip/i/IPf2jL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 0.00003394383693866311,
    "supportedSwappers": [
      "SwapMode"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "BEAST",
    "name": "BeastMode",
    "isPopular": false,
    "chainId": "34443",
    "address": "0x6a660e56fa3b630a786cc4ae98859f8532d03de9",
    "decimals": 18,
    "image": "https://rango.vip/i/YzsWHq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 0.0000071500023494991846,
    "supportedSwappers": [
      "SwapMode"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "STONE",
    "name": "Snakestone Ether",
    "isPopular": false,
    "chainId": "34443",
    "address": "0x80137510979822322193fc997d400d5a6c747bf7",
    "decimals": 18,
    "image": "https://rango.vip/i/Rd9qnO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 2416.303133008202,
    "supportedSwappers": [
      "SwapMode"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "WEETH",
    "name": "Wrapped eETH",
    "isPopular": false,
    "chainId": "34443",
    "address": "0x028227c4dd1e5419d11bb6fa6e661920c519d4f5",
    "decimals": 18,
    "image": "https://rango.vip/i/ItwJmK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 2448.9486284674845,
    "supportedSwappers": [
      "SwapMode"
    ]
  },
  {
    "blockchain": "MODE",
    "symbol": "IONUSDC",
    "name": "Ionic USD Coin",
    "isPopular": false,
    "chainId": "34443",
    "address": "0x2be717340023c9e14c1bb12cb3ecbcfd3c3fb038",
    "decimals": 6,
    "image": "https://rango.vip/i/kLS3he",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MODE/icon.svg",
    "usdPrice": 0.2057223631564515,
    "supportedSwappers": [
      "SwapMode"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "RNDR",
    "name": "Render Token",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xc8a4eea31e9b6b61c406df013dd4fec76f21e279",
    "decimals": 18,
    "image": "https://rango.vip/i/ygWBTT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "SOCKS",
    "name": "Unisocks",
    "isPopular": false,
    "chainId": "42161",
    "address": "0xb2be52744a804cc732d606817c2572c5a3b264e7",
    "decimals": 18,
    "image": "https://rango.vip/i/CMdy6S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 13795.26,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "ARBITRUM",
    "symbol": "tBTC",
    "name": "tBTC v2",
    "isPopular": false,
    "chainId": "42161",
    "address": "0x7e2a1edee171c5b19e6c54d73752396c0a572594",
    "decimals": 18,
    "image": "https://rango.vip/i/YfqS58",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ARBITRUM/icon.svg",
    "usdPrice": 55776.885657092695,
    "supportedSwappers": [
      "ParaSwap Arbitrum"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "aCRV",
    "name": "AladdinCRV",
    "isPopular": false,
    "chainId": "10",
    "address": "0x764ad60e1b81f6cacfec1a2926393d688d4493e6",
    "decimals": 18,
    "image": "https://rango.vip/i/VnDnih",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.277424,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "alETH",
    "name": "Alchemix ETH",
    "isPopular": false,
    "chainId": "10",
    "address": "0x3e29d3a9316dab217754d13b28646b76607c5f04",
    "decimals": 18,
    "image": "https://rango.vip/i/CdB81p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2135.57,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BAXA",
    "name": "BAXagent Coin",
    "isPopular": false,
    "chainId": "10",
    "address": "0xdb9888b842408b0b8efa1f5477bd9f351754999e",
    "decimals": 18,
    "image": "https://rango.vip/i/jYdIVH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BitANT",
    "name": "BitANT",
    "isPopular": false,
    "chainId": "10",
    "address": "0x5029c236320b8f15ef0a657054b84d90bfbeded3",
    "decimals": 18,
    "image": "https://rango.vip/i/nuLaj5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "BitBTC",
    "name": "BitBTC",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc98b98d17435aa00830c87ea02474c5007e1f272",
    "decimals": 18,
    "image": "https://rango.vip/i/Hw8jqo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "bwAJNA",
    "name": "Burn Wrapped AJNA",
    "isPopular": false,
    "chainId": "10",
    "address": "0x6c518f9d1a163379235816c543e62922a79863fa",
    "decimals": 18,
    "image": "https://rango.vip/i/gLUPoL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "crvUSD",
    "name": "Curve.Fi USD Stablecoin",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc52d7f23a2e460248db6ee192cb23dd12bddcbf6",
    "decimals": 18,
    "image": "https://rango.vip/i/fnaYIe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.998399,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "D2D",
    "name": "Prime",
    "isPopular": false,
    "chainId": "10",
    "address": "0x03e5b5930f3a42b56af4a982b10e60957bdd2f61",
    "decimals": 18,
    "image": "https://rango.vip/i/x8zYBg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.01309408,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "DRODEC",
    "name": "DRODEC",
    "isPopular": false,
    "chainId": "10",
    "address": "0x05c87b79844227a4d95171e9969da3dd7d65914e",
    "decimals": 18,
    "image": "https://rango.vip/i/sifiwx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ePendle",
    "name": "Equilibria Pendle",
    "isPopular": false,
    "chainId": "10",
    "address": "0x76c37f9949e05b37c8373d155c1fef46a6858481",
    "decimals": 18,
    "image": "https://rango.vip/i/pX80R9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.714251,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "EPOCH",
    "name": "Epoch",
    "isPopular": false,
    "chainId": "10",
    "address": "0xd1cac46a9a77169c310c2c780a4267ee6ca884f5",
    "decimals": 18,
    "image": "https://rango.vip/i/4sV9sR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00440681,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "EST",
    "name": "Erica Social Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x7b0bcc23851bbf7601efc9e9fe532bf5284f65d3",
    "decimals": 18,
    "image": "https://rango.vip/i/n1IkOq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "FLy",
    "name": "Franklin",
    "isPopular": false,
    "chainId": "10",
    "address": "0xb58d1d90c4f0a89c96337387feafff0a7a75a722",
    "decimals": 4,
    "image": "https://rango.vip/i/OET4CC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.0008569096287415786,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "FOAM",
    "name": "FOAM Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x79e6c6b6aaba4432fabacb30cc0c879d8f3e598e",
    "decimals": 18,
    "image": "https://rango.vip/i/Yg46eo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.01069213,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "HAIR",
    "name": "HairDAO Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x5465145a47260d5e715733997333a175d97285bb",
    "decimals": 18,
    "image": "https://rango.vip/i/aKWSyx",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 32.21,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "IBEX",
    "name": "Impermax",
    "isPopular": false,
    "chainId": "10",
    "address": "0xbb6bbaa0f6d839a00c82b10747abc3b7e2eecc82",
    "decimals": 18,
    "image": "https://rango.vip/i/0W1NG7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.055798,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "IPT",
    "name": "Interest Protocol",
    "isPopular": false,
    "chainId": "10",
    "address": "0xa211e25f7246950e0cce054e3161c7c0b6379485",
    "decimals": 18,
    "image": "https://rango.vip/i/mAW47a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "LIZ",
    "name": "Theranos Coin",
    "isPopular": false,
    "chainId": "10",
    "address": "0x3bb4445d30ac020a84c1b5a8a2c6248ebc9779d0",
    "decimals": 18,
    "image": "https://rango.vip/i/enp0hk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "LPF",
    "name": "Loopfi",
    "isPopular": false,
    "chainId": "10",
    "address": "0x0b3e851cf6508a16266bc68a651ea68b31ef673b",
    "decimals": 18,
    "image": "https://rango.vip/i/R8cSwy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "mpETH",
    "name": "MetaPoolETH",
    "isPopular": false,
    "chainId": "10",
    "address": "0x819845b60a192167ed1139040b4f8eca31834f27",
    "decimals": 18,
    "image": "https://rango.vip/i/HZbqDm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2480.97,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "MUGLOO",
    "name": "Mugloo",
    "isPopular": false,
    "chainId": "10",
    "address": "0x1164b451624e0d53bd8be84d3ff7f9591ba8922e",
    "decimals": 4,
    "image": "https://rango.vip/i/Xe2zwU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "PHTK",
    "name": "PhunToken",
    "isPopular": false,
    "chainId": "10",
    "address": "0xfa956eb0c4b3e692ad5a6b2f08170ade55999aca",
    "decimals": 18,
    "image": "https://rango.vip/i/Mu4qCo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "rETH",
    "name": "StaFi Staked ETH",
    "isPopular": false,
    "chainId": "10",
    "address": "0x6c2f7b6110a37b3b0fbdd811876be368df02e8b0",
    "decimals": 18,
    "image": "https://rango.vip/i/SAEoYt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2646.559212918496,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "RFWSTETH",
    "name": "Respawn Finance Wrapped Staked Ethereum",
    "isPopular": false,
    "chainId": "10",
    "address": "0xeaeadac73baaf4cb8b024de9d65b2eefa722856c",
    "decimals": 18,
    "image": "https://rango.vip/i/ZUrCax",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SCRY",
    "name": "Scry Protocol",
    "isPopular": false,
    "chainId": "10",
    "address": "0x64ba55a341ec586a4c5d58d6297cde5125ab55bc",
    "decimals": 18,
    "image": "https://rango.vip/i/K0smr8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "sDAI",
    "name": "Savings Dai",
    "isPopular": false,
    "chainId": "10",
    "address": "0x2218a117083f5b482b0bb821d27056ba9c04b1d3",
    "decimals": 18,
    "image": "https://rango.vip/i/oG95Qh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "sfrxETH",
    "name": "Staked Frax Ether",
    "isPopular": false,
    "chainId": "10",
    "address": "0x484c2d6e3cdd945a8b2df735e079178c1036578c",
    "decimals": 18,
    "image": "https://rango.vip/i/HDXNzr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2565.99,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "SHU",
    "name": "Shutter Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0xaf1d71bf947709315655514467d5158e5d3046d5",
    "decimals": 18,
    "image": "https://rango.vip/i/zGFAOD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.01424473,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "sLINK",
    "name": "Synthetic Chainlink",
    "isPopular": false,
    "chainId": "10",
    "address": "0xc5db22719a06418028a40a9b5e9a7c02959d0d08",
    "decimals": 18,
    "image": "https://rango.vip/i/WZVclC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "stkLYRA",
    "name": "Staked Lyra",
    "isPopular": false,
    "chainId": "10",
    "address": "0x0f5d45a7023612e9e244fe84fac5fcf3740d1492",
    "decimals": 18,
    "image": "https://rango.vip/i/4G7JIY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "TheDAO",
    "name": "TheDAO",
    "isPopular": false,
    "chainId": "10",
    "address": "0xd8f365c2c85648f9b89d9f1bf72c0ae4b1c36cfd",
    "decimals": 16,
    "image": "https://rango.vip/i/nj6rMG",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "UST",
    "name": "UST (Wormhole)",
    "isPopular": false,
    "chainId": "10",
    "address": "0xba28feb4b6a6b81e3f26f08b83a19e715c4294fd",
    "decimals": 6,
    "image": "https://rango.vip/i/RmqQuA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.01535935,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "VALX",
    "name": "Validator",
    "isPopular": false,
    "chainId": "10",
    "address": "0x0a9aaa1c7542b42233ac7ffda364e97ade21f160",
    "decimals": 18,
    "image": "https://rango.vip/i/WMpYbe",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "veKWENTA",
    "name": "veKwenta",
    "isPopular": false,
    "chainId": "10",
    "address": "0x678d8f4ba8dfe6bad51796351824dcceceaeff2b",
    "decimals": 18,
    "image": "https://rango.vip/i/na75UT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "wOpenX",
    "name": "OpenX Optimism",
    "isPopular": false,
    "chainId": "10",
    "address": "0x96bbd55479e9923512dcc95eb7df5edde6fb9874",
    "decimals": 18,
    "image": "https://rango.vip/i/O5Z6On",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "xZOOMER",
    "name": "xZoomerCoin",
    "isPopular": false,
    "chainId": "10",
    "address": "0xb962150760f9a3bb00e3e9cf48297ee20ada4a33",
    "decimals": 18,
    "image": "https://rango.vip/i/y0rRn3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.00000831,
    "supportedSwappers": [
      "ParaSwap Optimism"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "ZUN",
    "name": "Zunami Governance Token",
    "isPopular": false,
    "chainId": "10",
    "address": "0x4a36562d5d7074f8ee664922b320e066e67c2a24",
    "decimals": 18,
    "image": "https://rango.vip/i/bTMdma",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.101937,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "zunETH",
    "name": "Zunami ETH",
    "isPopular": false,
    "chainId": "10",
    "address": "0x805b93d5ff318cc054b46d6850af12feb55472d6",
    "decimals": 18,
    "image": "https://rango.vip/i/tHyDLt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 2338.08,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "OPTIMISM",
    "symbol": "zunUSD",
    "name": "Zunami USD",
    "isPopular": false,
    "chainId": "10",
    "address": "0x93c3e0fa191141c957c2632752ec35c84d0c440a",
    "decimals": 18,
    "image": "https://rango.vip/i/qcrEtn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/OPTIMISM/icon.svg",
    "usdPrice": 0.991948,
    "supportedSwappers": [
      "ParaSwap Optimism",
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DCAR",
    "name": "Dragon Crypto Argenti",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x250bdca7d1845cd543bb55e7d82dca24d48e9f0f",
    "decimals": 18,
    "image": "https://rango.vip/i/EbdEag",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00799861,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "TRESR",
    "name": "TRESR",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x9913ba363073ca3e9ea0cd296e36b75af9e40bef",
    "decimals": 18,
    "image": "https://rango.vip/i/GOsg6I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00366534,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "yyAVAX",
    "name": "YieldYak gAVAX",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf7d9281e8e363584973f946201b82ba72c965d27",
    "decimals": 18,
    "image": "https://rango.vip/i/wJDPZy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 24.61,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "WORM",
    "name": "Chikn Worm",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x79ba10485ae46a9436d560d9664369176ec2eb2b",
    "decimals": 18,
    "image": "https://rango.vip/i/chugOv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00002844,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "agEUR",
    "name": "agEUR",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xaec8318a9a59baeb39861d10ff6c7f7bf1f96c57",
    "decimals": 18,
    "image": "https://rango.vip/i/TPaWus",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "EMDX",
    "name": "EMDX",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xe533b81297b820d2eb2cd837263926596328e8d2",
    "decimals": 18,
    "image": "https://rango.vip/i/gygAcS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.01518254,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "xQI",
    "name": "xQI",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xe05a6b6f84e67a74c1c263d7e35d06eab5c211b5",
    "decimals": 18,
    "image": "https://rango.vip/i/86i3bu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "xALGO",
    "name": "Governance xAlgo",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc3fb03e75cf916973d430d63635f3f6154d01dc9",
    "decimals": 6,
    "image": "https://rango.vip/i/SSd4kV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.339569,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "LUCKY",
    "name": "Lucky Coin",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xb5cc2ce99b3f98a969dbe458b96a117680ae0fa1",
    "decimals": 18,
    "image": "https://rango.vip/i/7wXJgv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 71.42,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "FLD",
    "name": "Fold",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x88f89be3e9b1dc1c5f208696fb9cabfcc684bd5f",
    "decimals": 18,
    "image": "https://rango.vip/i/HKvl7f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00118938,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "KINGSHIT",
    "name": "Kingshit",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x0cd741f007b417088ca7f4392e8d6b49b4f7a975",
    "decimals": 18,
    "image": "https://rango.vip/i/fXJEIS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 168.26,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "$SICKO",
    "name": "ACID404: THE SICKOS ",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf6c95c3a750cc7f6a8c96d9b08cc132a44c7bd72",
    "decimals": 18,
    "image": "https://rango.vip/i/YPny2j",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DWC",
    "name": "Dog Wif Crocs",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc06e17bdc3f008f4ce08d27d364416079289e729",
    "decimals": 18,
    "image": "https://rango.vip/i/8jOmKj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 2.215e-9,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "DZHV",
    "name": "DizzyHavoc",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x3419875b4d3bca7f3fdda2db7a476a79fd31b4fe",
    "decimals": 18,
    "image": "https://rango.vip/i/ZrlkBP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00191168,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SQRCAT",
    "name": "SQRCAT",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xc8e7fb72b53d08c4f95b93b390ed3f132d03f2d5",
    "decimals": 18,
    "image": "https://rango.vip/i/tfMZhD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 5.148e-9,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "MAJIN",
    "name": "MAJIN",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x73f49d00ac1b520f94d11248808c40774aeb0802",
    "decimals": 18,
    "image": "https://rango.vip/i/7TvO8z",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 7.19489e-10,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "KONG",
    "name": "KONG",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xebb5d4959b2fba6318fbda7d03cd44ae771fc999",
    "decimals": 18,
    "image": "https://rango.vip/i/4XBoO9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 2.6279e-8,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "KINGSHIT.x",
    "name": "Kingshit X",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x05b0def5c00ba371683d7035934bcf82b737c364",
    "decimals": 18,
    "image": "https://rango.vip/i/V2MViI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "$Td",
    "name": "Big Red",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x87bbfc9dcb66caa8ce7582a3f17b60a25cd8a248",
    "decimals": 9,
    "image": "https://rango.vip/i/XuZP3B",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00000304,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "SWOL",
    "name": "SWOL ",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x245b532ad64c7fbfeec9aa42f37291b183cea91b",
    "decimals": 18,
    "image": "https://rango.vip/i/pG5wUL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "XAV",
    "name": "Xave Finance",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x913c61ec3573e5e4ee6488552535fb1be84ff2ac",
    "decimals": 18,
    "image": "https://rango.vip/i/8bqL4d",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.03334708,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "monUSD",
    "name": "MonroeUSD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xdf3d57c3480951958cef19905e4cf7fc1ba9ad42",
    "decimals": 18,
    "image": "https://rango.vip/i/eaYkvt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "roeUSD",
    "name": "Monroe USD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xf88df111343bffe7a2d89fb770d77a264d53f043",
    "decimals": 18,
    "image": "https://rango.vip/i/05RedF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "ARROW",
    "name": "Arrow Markets Token",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x5c5e384bd4e36724b2562ccaa582afd125277c9b",
    "decimals": 18,
    "image": "https://rango.vip/i/emTNX1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.134196,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "JIGEN",
    "name": "Jigen",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x25f11e22d5079c2970496d592579bf4902d09d76",
    "decimals": 18,
    "image": "https://rango.vip/i/ERyllo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00668555,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "aUSD",
    "name": "aUSD",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xabe7a9dfda35230ff60d1590a929ae0644c47dc1",
    "decimals": 18,
    "image": "https://rango.vip/i/J68Pk2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 1.0002587095416025,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "JUC",
    "name": "Juice",
    "isPopular": false,
    "chainId": "43114",
    "address": "0xd5d053d5b769383e860d1520da7a908e00919f36",
    "decimals": 18,
    "image": "https://rango.vip/i/oupoea",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00098847,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "XPOW",
    "name": "XPower",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x735d8f3b6a5d2c96d0405230c50eaf96794fbb88",
    "decimals": 18,
    "image": "https://rango.vip/i/n1Bg3k",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00011915,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "APOW",
    "name": "APower",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x7f9c841feadddb4bdbb2a161ca40bebc4f215a9a",
    "decimals": 18,
    "image": "https://rango.vip/i/o6l9aD",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.0111863,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "AVAX_CCHAIN",
    "symbol": "HEFE",
    "name": "HEFE",
    "isPopular": false,
    "chainId": "43114",
    "address": "0x18e3605b13f10016901eac609b9e188cf7c18973",
    "decimals": 18,
    "image": "https://rango.vip/i/UAddR0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/AVAX_CCHAIN/icon.svg",
    "usdPrice": 0.00069533,
    "supportedSwappers": [
      "ParaSwap Avalanche"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "4INT",
    "name": "4INT",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5ceebb0947d58fabde2fc026ffe4b33ccfe1ba8b",
    "decimals": 9,
    "image": "https://rango.vip/i/jQygDA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00088055,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BLID",
    "name": "Bolide",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4b27cd6e6a5e83d236ead376d256fe2f9e9f0d2e",
    "decimals": 18,
    "image": "https://rango.vip/i/egwl9g",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00519762,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DECATS",
    "name": "DECATS",
    "isPopular": false,
    "chainId": "137",
    "address": "0x198f1d316aad1c0bfd36a79bd1a8e9dba92daa18",
    "decimals": 18,
    "image": "https://rango.vip/i/yYOX6X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 4.13,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "END",
    "name": "Endblock",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0c087f8d6a1f14f71bb7cc7e1b061ca297af7555",
    "decimals": 18,
    "image": "https://rango.vip/i/abzenm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.303423,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "Lunr",
    "name": "Lunr Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xbbfe0b60de96a189bf09079de86a2db7bf0c7883",
    "decimals": 4,
    "image": "https://rango.vip/i/SfBw0s",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.03910516,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "METOD",
    "name": "META TOY DRAGONZ TOKEN",
    "isPopular": false,
    "chainId": "137",
    "address": "0x65ad509db5924ef0001d977590985f965ef1aaaa",
    "decimals": 18,
    "image": "https://rango.vip/i/pWHFgp",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00044843,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "WOOLY",
    "name": "Mammoth",
    "isPopular": false,
    "chainId": "137",
    "address": "0x6d830a664e6aa9aab976ab66258eecd0b5fb1dc7",
    "decimals": 18,
    "image": "https://rango.vip/i/l4VqBF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00000339,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "STACK",
    "name": "StackOS",
    "isPopular": false,
    "chainId": "137",
    "address": "0x980111ae1b84e50222c8843e3a7a038f36fecd2b",
    "decimals": 18,
    "image": "https://rango.vip/i/TFSynt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 38.49,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "OAI",
    "name": "OMNI Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xabc6790673a60b8a7f588450f59d2d256b1aef7f",
    "decimals": 18,
    "image": "https://rango.vip/i/o10pqc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "APOLLO",
    "name": "Apollo",
    "isPopular": false,
    "chainId": "137",
    "address": "0x577aa684b89578628941d648f1fbd6dde338f059",
    "decimals": 18,
    "image": "https://rango.vip/i/82twU1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.104771,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SING",
    "name": "Sing Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0xcb898b0efb084df14dd8e018da37b4d0f06ab26d",
    "decimals": 18,
    "image": "https://rango.vip/i/REgGM1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00451233,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "NEBULA",
    "name": "NEBULA",
    "isPopular": false,
    "chainId": "137",
    "address": "0xba47320ef6b1039f19f3c4096f0f3b3e8eb8d1f8",
    "decimals": 18,
    "image": "https://rango.vip/i/9zi805",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00068927,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "eUSDC",
    "name": "Easy USDC",
    "isPopular": false,
    "chainId": "137",
    "address": "0x4ebde54ba404be158262ede801744b92b9878c61",
    "decimals": 8,
    "image": "https://rango.vip/i/esK5Ok",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "eUSDT",
    "name": "Easy USDT",
    "isPopular": false,
    "chainId": "137",
    "address": "0xfc39742fe9420a7af23757fc7e78d1c3ae4a9474",
    "decimals": 8,
    "image": "https://rango.vip/i/esK5Ok",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.965288,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "eDAI",
    "name": "Easy DAI",
    "isPopular": false,
    "chainId": "137",
    "address": "0xa1c09c8f4f5d03fcc27b456475d53d988e98d7c5",
    "decimals": 8,
    "image": "https://rango.vip/i/esK5Ok",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "EASY",
    "name": "EASY",
    "isPopular": false,
    "chainId": "137",
    "address": "0xdb3b3b147a030f032633f6c4bebf9a2fb5a882b5",
    "decimals": 18,
    "image": "https://rango.vip/i/esK5Ok",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RBAL",
    "name": "Rebalance Token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x03247a4368a280bec8133300cd930a3a61d604f6",
    "decimals": 18,
    "image": "https://rango.vip/i/GCRNVq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BZB",
    "name": "BlazarBits",
    "isPopular": false,
    "chainId": "137",
    "address": "0x462d8d82c2b2d2ddabf7f8a93928de09d47a5807",
    "decimals": 18,
    "image": "https://rango.vip/i/CwwDfm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "POLYSAFE",
    "name": "PolySafe",
    "isPopular": false,
    "chainId": "137",
    "address": "0xb1cb4ac6b1685dcbe691b9246406748805aa6fa8",
    "decimals": 9,
    "image": "https://rango.vip/i/RkYztl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "mRTK",
    "name": "Matic Ruletka",
    "isPopular": false,
    "chainId": "137",
    "address": "0x38332d8671961ae13d0bde040d536eb336495eea",
    "decimals": 18,
    "image": "https://rango.vip/i/acvXtl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AGAcr",
    "name": "AGA Carbon Rewards",
    "isPopular": false,
    "chainId": "137",
    "address": "0xcbce9f77921c2e90522d438df4c5582f4c617768",
    "decimals": 18,
    "image": "https://rango.vip/i/g80KAw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.83,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "AGAc",
    "name": "AGA Carbon Credit",
    "isPopular": false,
    "chainId": "137",
    "address": "0x669ddc70273084ea30e6cd4f28ca6e2c70735065",
    "decimals": 18,
    "image": "https://rango.vip/i/7S07dg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00182511,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ROYA",
    "name": "Royale",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0bd820ad2d7ab7305b5c9538ba824c9b9beb0561",
    "decimals": 18,
    "image": "https://rango.vip/i/SZvCNZ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "BOOTY",
    "name": "Pirate Booty",
    "isPopular": false,
    "chainId": "137",
    "address": "0xd12dc5319808bb31ba95ae5764def2627d5966ce",
    "decimals": 18,
    "image": "https://rango.vip/i/yEJEXv",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00549472,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ROSN",
    "name": "Roseon token",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1b2a3c1417c69da16bb9193268d0aabfe54b3b39",
    "decimals": 18,
    "image": "https://rango.vip/i/XB8UF8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "XDO",
    "name": "xDollar",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3dc7b06dd0b1f08ef9acbbd2564f8605b4868eea",
    "decimals": 18,
    "image": "https://rango.vip/i/Z0JFBW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "vDFYN",
    "name": "vDFYN",
    "isPopular": false,
    "chainId": "137",
    "address": "0x75455c3de45dd32cbe9a5ad5e518d3d50823c976",
    "decimals": 18,
    "image": "https://rango.vip/i/OzvKj3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "MATPAD",
    "name": "MATICPAD",
    "isPopular": false,
    "chainId": "137",
    "address": "0x3bfce6d6f0d3d3f1326d86abdbe2845b4740dc2e",
    "decimals": 18,
    "image": "https://rango.vip/i/HFTWPq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ATTR",
    "name": "Attrace",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0335defc8a1977b99488e0b5f5c6bc3d44facdd4",
    "decimals": 18,
    "image": "https://rango.vip/i/9twNUQ",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RVRS",
    "name": "Reverse",
    "isPopular": false,
    "chainId": "137",
    "address": "0x5dd175a4242afe19e5c1051d8cd13fc8979f2329",
    "decimals": 9,
    "image": "https://rango.vip/i/Cusu5X",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CNW",
    "name": "CoinWealth",
    "isPopular": false,
    "chainId": "137",
    "address": "0x0a307bd521701f9d70fb29bfa9e2e36dc998dadb",
    "decimals": 6,
    "image": "https://rango.vip/i/XFRNK6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.00010717,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "UND",
    "name": "Unbound Dollar",
    "isPopular": false,
    "chainId": "137",
    "address": "0x1eba4b44c4f8cc2695347c6a78f0b7a002d26413",
    "decimals": 18,
    "image": "https://rango.vip/i/8IRC94",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 0.01583171,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "CUBAN",
    "name": "TITANDAO",
    "isPopular": false,
    "chainId": "137",
    "address": "0x60caa53091da042196cb9b5f98aaa9552aef8c82",
    "decimals": 18,
    "image": "https://rango.vip/i/EnMtsw",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "SROCKET",
    "name": "Stable One Rocket",
    "isPopular": false,
    "chainId": "137",
    "address": "0x94788309d420ad9f9f16d79fc13ab74de83f85f7",
    "decimals": 18,
    "image": "https://rango.vip/i/AR2sCR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "ARTH",
    "name": "ARTH",
    "isPopular": false,
    "chainId": "137",
    "address": "0xe52509181feb30eb4979e29ec70d50fd5c44d590",
    "decimals": 18,
    "image": "https://rango.vip/i/FEwM8S",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 1.64,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "DUNE",
    "name": "Alpha Dune",
    "isPopular": false,
    "chainId": "137",
    "address": "0x9d9b6f1475ab43b8b83829771237fd44432b04a3",
    "decimals": 18,
    "image": "https://rango.vip/i/QjpnXj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": 8.59,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "POLYGON",
    "symbol": "RDUNE",
    "name": "R Alpha Dune",
    "isPopular": false,
    "chainId": "137",
    "address": "0xf196b73eba4a75680969aaf0c9979a008204a0a9",
    "decimals": 18,
    "image": "https://rango.vip/i/QjpnXj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/POLYGON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ParaSwap Polygon"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "DAIv0",
    "name": "Dai Stablecoin v0",
    "isPopular": true,
    "chainId": "SN_MAIN",
    "address": "0xda114221cb83fa859dbdb4c44beeaa0bb37c7537ad5ae66fe5e0efd20e6eb3",
    "decimals": 18,
    "image": "https://rango.vip/i/KlMakr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 0.999416,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "EKUBO",
    "name": "Ekubo Protocol",
    "isPopular": false,
    "chainId": "SN_MAIN",
    "address": "0x75afe6402ad5a5c20dd25e10ec3b3986acaa647b77e4ae24b0cbc9a54a27a87",
    "decimals": 18,
    "image": "https://rango.vip/i/5gJrSS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 1.97,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "rETH",
    "name": "Rocket Pool ETH",
    "isPopular": false,
    "chainId": "SN_MAIN",
    "address": "0x319111a5037cbec2b3e638cc34a3474e2d2608299f3e62866e9cc683208c610",
    "decimals": 18,
    "image": "https://rango.vip/i/sXHgie",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": 2646.559212918496,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "STARKNET",
    "symbol": "vSTRK",
    "name": "Starknet Voting Token",
    "isPopular": false,
    "chainId": "SN_MAIN",
    "address": "0x782f0ddca11d9950bc3220e35ac82cf868778edb67a5e58b39838544bc4cd0f",
    "decimals": 18,
    "image": "https://rango.vip/i/COvEuV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARKNET/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Avnu",
      "MySwap"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HDOT",
    "name": "Heco-Peg HDOT Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0xa2c49cee16a5e5bdefde931107dc1fae9f7773e3",
    "decimals": 18,
    "image": "https://rango.vip/i/N659nc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 13.03115699478224,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HLTC",
    "name": "Heco-Peg HLTC Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0xecb56cf772b5c9a6907fb7d32387da2fcbfb63b4",
    "decimals": 18,
    "image": "https://rango.vip/i/S6Bqzd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 277.8292586423082,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HBCH",
    "name": "Heco-Peg HBCH Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0xef3cebd77e0c52cb6f60875d9306397b5caca375",
    "decimals": 18,
    "image": "https://rango.vip/i/xVtZpV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1500.1096877581265,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HFIL",
    "name": "Heco-Peg HFIL Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0xae3a768f9ab104c69a7cd6041fe16ffa235d1810",
    "decimals": 18,
    "image": "https://rango.vip/i/O7NJhT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 11.478580993385673,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "LYFI",
    "name": "Heco LYFI Token",
    "isPopular": false,
    "chainId": "128",
    "address": "0xa441b796e160e204cd0f53d3216275056a355980",
    "decimals": 18,
    "image": "https://rango.vip/i/u3O4Jl",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SH",
    "name": "SH",
    "isPopular": false,
    "chainId": "128",
    "address": "0x3dc2536480775d0ab5d1d010b853cd2166cf6e11",
    "decimals": 12,
    "image": "https://rango.vip/i/gt6hfR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.012586069441053267,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DHM",
    "name": "DHM",
    "isPopular": false,
    "chainId": "128",
    "address": "0xca757a8fc34c5d65f38792f329b05e7d9ca8b18e",
    "decimals": 18,
    "image": "https://rango.vip/i/tehQgt",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HBC",
    "name": "HBC",
    "isPopular": false,
    "chainId": "128",
    "address": "0x72bd642c5d097344814afe23f7e6001f19f6204a",
    "decimals": 18,
    "image": "https://rango.vip/i/opPxRK",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SCASH",
    "name": "SCASH",
    "isPopular": false,
    "chainId": "128",
    "address": "0xb6ccfa7ef3a295932536e0988cffd85228cb177c",
    "decimals": 18,
    "image": "https://rango.vip/i/pbSXTc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.01010267,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "XF",
    "name": "XF",
    "isPopular": false,
    "chainId": "128",
    "address": "0xe0fe25eefcfcaddef844fe30b8be1d68ac6b7af3",
    "decimals": 18,
    "image": "https://rango.vip/i/kM0VYy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.002611301758645199,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HWIN",
    "name": "HWIN",
    "isPopular": false,
    "chainId": "128",
    "address": "0x2effc887b3195d7c8f3966a07c421d828e8f8aee",
    "decimals": 18,
    "image": "https://rango.vip/i/DvpaIA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00021077564518965795,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "FIX",
    "name": "FIX",
    "isPopular": false,
    "chainId": "128",
    "address": "0xde9495de889996404b14ddbf05f66db7401f0733",
    "decimals": 18,
    "image": "https://rango.vip/i/9kzzOV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.13967338725502354,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "VNLA",
    "name": "VNLA",
    "isPopular": false,
    "chainId": "128",
    "address": "0xe09a504071c0c1483e86727fb95f550a0559d9d2",
    "decimals": 18,
    "image": "https://rango.vip/i/MbYyic",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0028019949566112624,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "ODT",
    "name": "ODT",
    "isPopular": false,
    "chainId": "128",
    "address": "0x991075cc14a91fe001528943b3eb6df31cff431f",
    "decimals": 18,
    "image": "https://rango.vip/i/oKT6U0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0009180571217134461,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "LLC",
    "name": "LLC",
    "isPopular": false,
    "chainId": "128",
    "address": "0x6a4db3965cb6293dba0f63f14fb36873172e38d3",
    "decimals": 18,
    "image": "https://rango.vip/i/b699oH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0035284952471232073,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "CON",
    "name": "CON",
    "isPopular": false,
    "chainId": "128",
    "address": "0xeef1324343ca7bf6e743e21dd9e92dfa4efc3a56",
    "decimals": 18,
    "image": "https://rango.vip/i/zSRJz3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.029347790887204617,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HGT",
    "name": "HGT",
    "isPopular": false,
    "chainId": "128",
    "address": "0xfd6ce15009d46c6327649218431e8643f82f6d64",
    "decimals": 18,
    "image": "https://rango.vip/i/ZPAmOi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00005643062286828331,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "STK",
    "name": "STK",
    "isPopular": false,
    "chainId": "128",
    "address": "0xd187e65f5a6e88d84437d96d2ae8c909cc329f95",
    "decimals": 12,
    "image": "https://rango.vip/i/AIHQBr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.051179,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "LUCK",
    "name": "LUCK",
    "isPopular": false,
    "chainId": "128",
    "address": "0xaab4b32014189e924a9759701586e8bfb0feae3c",
    "decimals": 18,
    "image": "https://rango.vip/i/JOtLhm",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0007024265517839022,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DBS",
    "name": "DBS",
    "isPopular": false,
    "chainId": "128",
    "address": "0x414fe43435086924b7b5a32d0218ee3a65bbfbeb",
    "decimals": 8,
    "image": "https://rango.vip/i/HxODGI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0004951796377279203,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "RDTK",
    "name": "RDTK",
    "isPopular": false,
    "chainId": "128",
    "address": "0x1e0abaf926013fadc7da19ad41665bb753639a94",
    "decimals": 18,
    "image": "https://rango.vip/i/dAFpxi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 3.091833746085214e-8,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "XMPT",
    "name": "XMPT",
    "isPopular": false,
    "chainId": "128",
    "address": "0x4fa8f0a59a47beabf950a8795933c01455dd9c81",
    "decimals": 18,
    "image": "https://rango.vip/i/6eWShg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0013047266585595053,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HFLUX",
    "name": "hFLUX",
    "isPopular": false,
    "chainId": "128",
    "address": "0xd10852df03ea8b8af0cc0b09cac3f7dbb15e0433",
    "decimals": 18,
    "image": "https://rango.vip/i/dXxXeR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.02650351,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "AFT",
    "name": "AFT",
    "isPopular": false,
    "chainId": "128",
    "address": "0x25032dbfc1c889ecc78ef1adcfdf65e45a3e02ec",
    "decimals": 18,
    "image": "https://rango.vip/i/ScP8I7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00029293422745109605,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "YF",
    "name": "YF",
    "isPopular": false,
    "chainId": "128",
    "address": "0x0d1cde65e2dba76d08c29867aa3bc1b84e1e3aed",
    "decimals": 18,
    "image": "https://rango.vip/i/uY8bTS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HTMOON",
    "name": "HTMoon",
    "isPopular": false,
    "chainId": "128",
    "address": "0xb62e3b6a3866f5754fdefcf82e733310e2851043",
    "decimals": 9,
    "image": "https://rango.vip/i/qIYR4L",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "NDFIL",
    "name": "NDFIL",
    "isPopular": false,
    "chainId": "128",
    "address": "0x10c41dbf49b935f268b6cab0a0a9e9b62a654bde",
    "decimals": 18,
    "image": "https://rango.vip/i/OtcKOS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.33376161418887074,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "NDCEX",
    "name": "NDCEX",
    "isPopular": false,
    "chainId": "128",
    "address": "0xe7aad5eb7d46c8fcecdd1dc88114cca2385c2317",
    "decimals": 18,
    "image": "https://rango.vip/i/zYV4IC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.020314956916364032,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HOGT",
    "name": "HOGT",
    "isPopular": false,
    "chainId": "128",
    "address": "0xfc33785f44c1069010b5de466ee7657c27ab8a0e",
    "decimals": 18,
    "image": "https://rango.vip/i/TzEH81",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HBELT",
    "name": "HBELT",
    "isPopular": false,
    "chainId": "128",
    "address": "0x8c517631a7cd20fc365aa9a4025955fcdaa11f57",
    "decimals": 18,
    "image": "https://rango.vip/i/aVqFxz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.07922196100618445,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "FA",
    "name": "FA",
    "isPopular": false,
    "chainId": "128",
    "address": "0xaca179e010a5d0624d103d2e8e563b26716194ce",
    "decimals": 18,
    "image": "https://rango.vip/i/y5ic2a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "PNX",
    "name": "PNX",
    "isPopular": false,
    "chainId": "128",
    "address": "0xdb0ad86386dc5c12cd2b9cb7f17f31f405c7e376",
    "decimals": 18,
    "image": "https://rango.vip/i/E6Tmkg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.291659,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "PUSD",
    "name": "PUSD",
    "isPopular": false,
    "chainId": "128",
    "address": "0xc36ff5b42092a1822948e8fac103509cc8b68dc6",
    "decimals": 18,
    "image": "https://rango.vip/i/L8HmZA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.020459035446551983,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "KUNGFU",
    "name": "kungfu",
    "isPopular": false,
    "chainId": "128",
    "address": "0x1b625dd82aeb3aac21398ea9933fbd56e9652383",
    "decimals": 18,
    "image": "https://rango.vip/i/rQLHNu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0007641681375103508,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "DOGMOON",
    "name": "Dogmoon",
    "isPopular": false,
    "chainId": "128",
    "address": "0x67bab912ee30074cf9a94826e2e02d9936842781",
    "decimals": 9,
    "image": "https://rango.vip/i/hFpQmf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 6.506057507829834e-11,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "UDOG",
    "name": "UDog",
    "isPopular": false,
    "chainId": "128",
    "address": "0x6ab015683914cd09a209b500f9cb24c39352973d",
    "decimals": 18,
    "image": "https://rango.vip/i/1nW5cg",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1.2051210559250135e-11,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "NIO",
    "name": "NIO",
    "isPopular": false,
    "chainId": "128",
    "address": "0x19ece5243ed8cbf4b4aabc7f897881eb5759fb7b",
    "decimals": 18,
    "image": "https://rango.vip/i/P6nYv2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.00021581594021924342,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "MNC",
    "name": "MNC",
    "isPopular": false,
    "chainId": "128",
    "address": "0xc66496bd7616ee02691e56d17608e59e6c9f94ea",
    "decimals": 18,
    "image": "https://rango.vip/i/2VNCjL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.0000013298090949506443,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "MOMAT",
    "name": "MOMAT",
    "isPopular": false,
    "chainId": "128",
    "address": "0x162edc778dfd179a1e54e4bcaaf650dc293bb2c9",
    "decimals": 18,
    "image": "https://rango.vip/i/TQdTNy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "VFIL",
    "name": "VFIL",
    "isPopular": false,
    "chainId": "128",
    "address": "0x1f60736262af2df60b60bdeedc113333c22f6034",
    "decimals": 18,
    "image": "https://rango.vip/i/R7OGUc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.06916294829504833,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "BTAU",
    "name": "BTAU",
    "isPopular": false,
    "chainId": "128",
    "address": "0x782b2be4cb221cd9d7916ce2b4e15f7aa43ab754",
    "decimals": 18,
    "image": "https://rango.vip/i/jcEyK2",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.018493760175631934,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "C",
    "name": "C",
    "isPopular": false,
    "chainId": "128",
    "address": "0xfed613cdd4182b73a032fe1f03bd7872465010f8",
    "decimals": 18,
    "image": "https://rango.vip/i/RgndV1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 2.2974914798588584e-11,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "SAME",
    "name": "SAME",
    "isPopular": false,
    "chainId": "128",
    "address": "0xb273fb0f00ff4681fe6cd1137a5e68faba3b1889",
    "decimals": 18,
    "image": "https://rango.vip/i/cRoqEE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HTSUN",
    "name": "HTSUN",
    "isPopular": false,
    "chainId": "128",
    "address": "0xca1539b060a6663915c983ac14d2e52b7c0e8360",
    "decimals": 9,
    "image": "https://rango.vip/i/wyvpxn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 5.451348562231467e-7,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "PIMOON",
    "name": "PiMoon",
    "isPopular": false,
    "chainId": "128",
    "address": "0xe1c91942e366dd875d0ba2729fd89569fdd552db",
    "decimals": 18,
    "image": "https://rango.vip/i/vUDHzc",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1.4973334539629602e-10,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "XMDX",
    "name": "xMDX",
    "isPopular": false,
    "chainId": "128",
    "address": "0xf5cf092c93cddfe9087b86bdca5cb3f4f72b7242",
    "decimals": 18,
    "image": "https://rango.vip/i/aos3GR",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HJW",
    "name": "HJW",
    "isPopular": false,
    "chainId": "128",
    "address": "0x96b414744296e0027b3f75557936d4bffd5eb810",
    "decimals": 18,
    "image": "https://rango.vip/i/E5Qz2f",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "ITFX",
    "name": "ITFX",
    "isPopular": false,
    "chainId": "128",
    "address": "0x0569843257b91a9753351a4d621ada83f642f57c",
    "decimals": 18,
    "image": "https://rango.vip/i/k6F3Ti",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.014460320011204909,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HN",
    "name": "HN",
    "isPopular": false,
    "chainId": "128",
    "address": "0x7fc7b1c464f16f8ccc20b17eb95231a8bab8b7f4",
    "decimals": 18,
    "image": "https://rango.vip/i/SJxz0E",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 5.727556943343895e-11,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "FCN",
    "name": "FCN",
    "isPopular": false,
    "chainId": "128",
    "address": "0x914b633038f36d03fef5af7f12e519879576771a",
    "decimals": 18,
    "image": "https://rango.vip/i/PEPJx0",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1.0564192556810111e-10,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "YZN",
    "name": "YZN",
    "isPopular": false,
    "chainId": "128",
    "address": "0xcc3f028d82c22daa75502fd183778bfd91e5b753",
    "decimals": 18,
    "image": "https://rango.vip/i/3f9eCP",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "WNOW",
    "name": "WNOW",
    "isPopular": false,
    "chainId": "128",
    "address": "0x81c9309d8598fb863bbd337d35dcb6036bcd51ae",
    "decimals": 18,
    "image": "https://rango.vip/i/81U1cF",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 0.005304797790966546,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "MARK",
    "name": "MARK",
    "isPopular": false,
    "chainId": "128",
    "address": "0x779a8134750809f79cf0ba48ee0ff1a5c41a8fdc",
    "decimals": 18,
    "image": "https://rango.vip/i/ziJfPC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 3.1155389058079147e-7,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "OVERHTMOON",
    "name": "OverHtmoon",
    "isPopular": false,
    "chainId": "128",
    "address": "0x8b79370b33ca2be7968a841145dd4e62b1d4291a",
    "decimals": 9,
    "image": "https://rango.vip/i/CFaifO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 4.3503483533758e-13,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "PIL",
    "name": "PIL",
    "isPopular": false,
    "chainId": "128",
    "address": "0x63c93714d4f7830aaa2d63bb7004da4edd1e24b0",
    "decimals": 18,
    "image": "https://rango.vip/i/6RvUuU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 1.0257798419533837,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "HBY",
    "name": "HBY",
    "isPopular": false,
    "chainId": "128",
    "address": "0x5c0a02ccbc5eb09eef6773a161c6ccbe746fd29b",
    "decimals": 18,
    "image": "https://rango.vip/i/9g9ryi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 4.97612818196513e-9,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "HECO",
    "symbol": "NAGA",
    "name": "NAGA",
    "isPopular": false,
    "chainId": "128",
    "address": "0x15e50e6d2a7dfa05c38afe60d1d0c43351d55aec",
    "decimals": 18,
    "image": "https://rango.vip/i/NK5ktW",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/HECO/icon.svg",
    "usdPrice": 3.289528579866529e-11,
    "supportedSwappers": [
      "MDexHeco"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "aCRV",
    "name": "Aladdin cvxCRV",
    "isPopular": false,
    "chainId": "1",
    "address": "0x2b95a1dcc3d405535f9ed33c219ab38e8d7e0884",
    "decimals": 18,
    "image": "https://rango.vip/i/nxhc9O",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.277424,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BAXA",
    "name": "BAXagent Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x91b08f4a7c1251dfccf5440f8894f8daa10c8de5",
    "decimals": 18,
    "image": "https://rango.vip/i/jYdIVH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BitANT",
    "name": "BitANT",
    "isPopular": false,
    "chainId": "1",
    "address": "0x15ee120fd69bec86c1d38502299af7366a41d1a6",
    "decimals": 18,
    "image": "https://rango.vip/i/Z4u93A",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "BitBTC",
    "name": "BitBTC",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3c513db8bdc3806e4489d62c3d549a5aaf6a4e97",
    "decimals": 18,
    "image": "https://rango.vip/i/Hw8jqo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "bwAJNA",
    "name": "Burn Wrapped AJNA",
    "isPopular": false,
    "chainId": "1",
    "address": "0x936ab482d6bd111910a42849d3a51ff80bb0a711",
    "decimals": 18,
    "image": "https://rango.vip/i/gLUPoL",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "EST",
    "name": "Erica Social Token",
    "isPopular": false,
    "chainId": "1",
    "address": "0x259ab9b9eab62b0fd98729b97be121073d5b3479",
    "decimals": 18,
    "image": "https://rango.vip/i/n1IkOq",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "IPT",
    "name": "Interest Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd909c5862cdb164adb949d92622082f0092efc3d",
    "decimals": 18,
    "image": "https://rango.vip/i/mAW47a",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LIZ",
    "name": "Theranos Coin",
    "isPopular": false,
    "chainId": "1",
    "address": "0x102e941b77bcaa7e35d368cafe51ef8f79c8d1ef",
    "decimals": 18,
    "image": "https://rango.vip/i/enp0hk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "LPF",
    "name": "Loopfi",
    "isPopular": false,
    "chainId": "1",
    "address": "0x3650b69f86cb593f116e276c30666834336c0647",
    "decimals": 18,
    "image": "https://rango.vip/i/R8cSwy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "MUGLOO",
    "name": "Mugloo",
    "isPopular": false,
    "chainId": "1",
    "address": "0x8e3c68a9c10d3bf2aaf14f2e569a8223f21bf2b6",
    "decimals": 4,
    "image": "https://rango.vip/i/Xe2zwU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "PHTK",
    "name": "PhunToken",
    "isPopular": false,
    "chainId": "1",
    "address": "0x1fee5588cb1de19c70b6ad5399152d8c643fae7b",
    "decimals": 18,
    "image": "https://rango.vip/i/Mu4qCo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": 0.00098414,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "RFWSTETH",
    "name": "Respawn Finance Wrapped Staked Ethereum",
    "isPopular": false,
    "chainId": "1",
    "address": "0x07fa101efde726e0956edd2c4d5c8d3d1a5e9c53",
    "decimals": 18,
    "image": "https://rango.vip/i/ZUrCax",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "SCRY",
    "name": "Scry Protocol",
    "isPopular": false,
    "chainId": "1",
    "address": "0x0000000000071821e8033345a7be174647be0706",
    "decimals": 18,
    "image": "https://rango.vip/i/K0smr8",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "stkLYRA",
    "name": "Staked Lyra",
    "isPopular": false,
    "chainId": "1",
    "address": "0xcb9f85730f57732fc899fb158164b9ed60c77d49",
    "decimals": 18,
    "image": "https://rango.vip/i/4G7JIY",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "TheDAO",
    "name": "TheDAO",
    "isPopular": false,
    "chainId": "1",
    "address": "0xbb9bc244d798123fde783fcc1c72d3bb8c189413",
    "decimals": 16,
    "image": "https://rango.vip/i/S2bFjA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "veKWENTA",
    "name": "veKwenta",
    "isPopular": false,
    "chainId": "1",
    "address": "0x6789d8a7a7871923fc6430432a602879ecb6520a",
    "decimals": 18,
    "image": "https://rango.vip/i/na75UT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "wOpenX",
    "name": "OpenX Optimism",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd17d5c95b9183354ef24866b61480fc53b729555",
    "decimals": 18,
    "image": "https://rango.vip/i/O5Z6On",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "ETH",
    "symbol": "wTBT",
    "name": "wTBT Pool",
    "isPopular": false,
    "chainId": "1",
    "address": "0xd38e031f4529a07996aab977d2b79f0e00656c56",
    "decimals": 18,
    "image": "https://rango.vip/i/E3q7LS",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/ETH/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "Optimism Bridge"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "UBE",
    "name": "Ubeswap",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x71e26d0e519d14591b9de9a0fe9513a398101490",
    "decimals": 18,
    "image": "https://rango.vip/i/fg7Bwu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.004978759928497624,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "MCUSD",
    "name": "Moola cUSD",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x918146359264c492bd6934071c6bd31c854edbc3",
    "decimals": 18,
    "image": "https://rango.vip/i/xbbB3l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 1.001,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "MCEUR",
    "name": "Moola cEUR",
    "isPopular": false,
    "chainId": "42220",
    "address": "0xe273ad7ee11dcfaa87383ad5977ee1504ac07568",
    "decimals": 18,
    "image": "https://rango.vip/i/14mK6I",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 1.11,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "SCELO",
    "name": "Savings CELO",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x2879bfd5e7c4ef331384e908aaa3bd3014b703fa",
    "decimals": 18,
    "image": "https://rango.vip/i/bPL1Qr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.000006591552863332499,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "MCELO",
    "name": "Moola CELO",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x7d00cd74ff385c955ea3d79e47bf06bd7386387d",
    "decimals": 18,
    "image": "https://rango.vip/i/s5i4x9",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.41361,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "STABILUSD",
    "name": "Stabilite USD",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x0a60c25ef6021fc3b479914e6bca7c03c18a97f1",
    "decimals": 18,
    "image": "https://rango.vip/i/JaaQOk",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.933375640785028,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "RCELO",
    "name": "Rewards CELO",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x1a8dbe5958c597a744ba51763abebd3355996c3e",
    "decimals": 18,
    "image": "https://rango.vip/i/uPEApO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.000006764123616053552,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "ARI",
    "name": "Ariswap",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x20677d4f3d0f08e735ab512393524a3cfceb250c",
    "decimals": 18,
    "image": "https://rango.vip/i/6p4hZ3",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.00001921718422409296,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "CSTAR",
    "name": "CeloStarter",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x452ef5a4bd00796e62e5e5758548e0da6e8ccdf3",
    "decimals": 18,
    "image": "https://rango.vip/i/nPSZMu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.00019818666672056704,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "MCREAL",
    "name": "Moola cREAL",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x9802d866fde4563d088a6619f7cef82c0b991a55",
    "decimals": 18,
    "image": "https://rango.vip/i/fI1hgo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.177766,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "CBTC",
    "name": "wrapped.com Bitcoin",
    "isPopular": true,
    "chainId": "42220",
    "address": "0xd629eb00deced2a080b7ec630ef6ac117e614f1b",
    "decimals": 18,
    "image": "https://rango.vip/i/HTs0R6",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 55210,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "CELO",
    "symbol": "OLD-UBE",
    "name": "Ubeswap Old",
    "isPopular": false,
    "chainId": "42220",
    "address": "0x00be915b9dcf56a3cbe739d9b9c202ca692409ec",
    "decimals": 18,
    "image": "https://rango.vip/i/wLkKq7",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/CELO/icon.svg",
    "usdPrice": 0.00096211,
    "supportedSwappers": [
      "UbeSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "BEANS",
    "name": "MoonBeans",
    "isPopular": false,
    "chainId": "1284",
    "address": "0x65b09ef8c5a096c5fd3a80f1f7369e56eb932412",
    "decimals": 18,
    "image": "https://rango.vip/i/TSVhNn",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 1.1560729864052823,
    "supportedSwappers": [
      "BeamSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "XCUSDT",
    "name": "xcUSDT",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xffffffffea09fb06d082fd1275cd48b191cbcd1d",
    "decimals": 6,
    "image": "https://rango.vip/i/lNVNRu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": 0.998824,
    "supportedSwappers": [
      "BeamSwap"
    ]
  },
  {
    "blockchain": "MOONBEAM",
    "symbol": "XCBNC",
    "name": "Bifrost Native Coin",
    "isPopular": false,
    "chainId": "1284",
    "address": "0xffffffff7cc06abdf7201b350a1265c62c8601d2",
    "decimals": 12,
    "image": "https://rango.vip/i/9qz2Lu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONBEAM/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "BeamSwap"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "YESv1",
    "name": "YES Money v1",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x20fe91f17ec9080e3cac2d688b4ecb48c5ac3a9c",
    "decimals": 18,
    "image": "https://rango.vip/i/gHaIkI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 3.5368861065066466,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "BAJA",
    "name": "Baja",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x5fe8534a6f96cb01261bd96e98c17c2c1cab3204",
    "decimals": 18,
    "image": "https://rango.vip/i/ZHsjwA",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "ThrusterV3",
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "YOLO",
    "name": "YOLO",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xf77dd21c5ce38ac08786be35ef1d1dec1a6a15f3",
    "decimals": 18,
    "image": "https://rango.vip/i/S6p5QT",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00237353,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "wrsETH",
    "name": "Wrapped Kelp DAO Restaked ETH",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xe7903b1f75c534dd8159b313d92cdcfbc62cb3cd",
    "decimals": 18,
    "image": "https://rango.vip/i/b4ax0l",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 2396.09,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "ezETH",
    "name": "Renzo Restaked ETH",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x2416092f143378750bb29b79ed961ab195cceea5",
    "decimals": 18,
    "image": "https://rango.vip/i/jQWvXV",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 2383.16,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "BTARD",
    "name": "Blastardio",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x12aa089d5425af22ffc34ff86987caaf1091824f",
    "decimals": 18,
    "image": "https://rango.vip/i/UqNmsi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00000735,
    "supportedSwappers": [
      "ThrusterV3",
      "ThrusterV2.03"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "BPEPE",
    "name": "Blastin Pepes",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xb6e0d8a730c6e5c85c637b1cf7ad6fd07927b965",
    "decimals": 18,
    "image": "https://rango.vip/i/rPksIE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.160909,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "ANKRETH",
    "name": "Ankr Staked ETH",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x049e6a52e2c9b7814c8178908f3630726c134c92",
    "decimals": 18,
    "image": "https://rango.vip/i/nJ36vb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 2733.7,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "ULTRAETHS",
    "name": "Affine ultraETHs 2 0",
    "isPopular": false,
    "chainId": "81457",
    "address": "0xbb4e01b8940e8e2b3a95ced7941969d033786ff7",
    "decimals": 18,
    "image": "https://rango.vip/i/qgoh4p",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 2752.56,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "INETH",
    "name": "Inception Restaked ETH",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x5a7a183b6b44dc4ec2e3d2ef43f98c5152b1d76d",
    "decimals": 18,
    "image": "https://rango.vip/i/8EVk58",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 2377.84,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "sfrxETH",
    "name": "Staked Frax Ether",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x1f55a02a049033e3419a8e2975cf3f572f4e6e9a",
    "decimals": 18,
    "image": "https://rango.vip/i/HDXNzr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 2604.0220487291704,
    "supportedSwappers": [
      "ThrusterV3"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "OHNO",
    "name": "Oh no",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x000000daa580e54635a043d2773f2c698593836a",
    "decimals": 18,
    "image": "https://rango.vip/i/s9JcVz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00012068,
    "supportedSwappers": [
      "ThrusterV2.1"
    ]
  },
  {
    "blockchain": "BLAST",
    "symbol": "HYPE",
    "name": "HyperBlast",
    "isPopular": false,
    "chainId": "81457",
    "address": "0x9fe9991daf6b9a5d79280f48cbb6827d46de2ea4",
    "decimals": 9,
    "image": "https://rango.vip/i/wWtGJE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/BLAST/icon.svg",
    "usdPrice": 0.00740391,
    "supportedSwappers": [
      "ThrusterV2.1"
    ]
  },
  {
    "blockchain": "IOTA",
    "symbol": "WIOTA",
    "name": "wIOTA",
    "isPopular": true,
    "chainId": "8822",
    "address": "0x6e47f8d48a01b44df3fff35d258a10a3aedc114c",
    "decimals": 18,
    "image": "https://rango.vip/i/hM0hFr",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/IOTA/icon.svg",
    "usdPrice": 0.11987506787934138,
    "supportedSwappers": [
      "IOTAMagicSeaSwap"
    ]
  },
  {
    "blockchain": "IOTA",
    "symbol": "USDC.E",
    "name": "Bridged USDC (Stargate)",
    "isPopular": true,
    "chainId": "8822",
    "address": "0xfbda5f676cb37624f28265a144a48b0d6e87d3b6",
    "decimals": 6,
    "image": "https://rango.vip/i/w22Kdd",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/IOTA/icon.svg",
    "usdPrice": 1.0060641394566985,
    "supportedSwappers": [
      "IOTAMagicSeaSwap"
    ]
  },
  {
    "blockchain": "IOTA",
    "symbol": "MLUM",
    "name": "MagicLum",
    "isPopular": false,
    "chainId": "8822",
    "address": "0xa87666b0cb3631c49f96cbf1e6a52ebac79a6143",
    "decimals": 18,
    "image": "https://rango.vip/i/aONOkf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/IOTA/icon.svg",
    "usdPrice": 10.39027388876923,
    "supportedSwappers": [
      "IOTAMagicSeaSwap"
    ]
  },
  {
    "blockchain": "IOTA",
    "symbol": "RUST",
    "name": "Rusty Robot Country Club",
    "isPopular": false,
    "chainId": "8822",
    "address": "0x3915b5a673ee6cf126d1042f637bd10b9931ad08",
    "decimals": 18,
    "image": "https://rango.vip/i/12rtQB",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/IOTA/icon.svg",
    "usdPrice": 0.00022371286026791,
    "supportedSwappers": [
      "IOTAMagicSeaSwap"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "SUNOLD",
    "name": "SUNOLD",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TKkeiboTkxXKJpbmVFbv4a8ov5rAfRDMf9",
    "decimals": 18,
    "image": "https://rango.vip/i/KyaIUz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "ETHOLD",
    "name": "EthereumOLD",
    "isPopular": false,
    "chainId": "728126428",
    "address": "THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF",
    "decimals": 18,
    "image": "https://rango.vip/i/9QHuSO",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 2343.73,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "WBTT",
    "name": "Wrapped BitTorrent",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TKfjV9RNKJJCqPvBtK8L7Knykh7DNWvnYt",
    "decimals": 6,
    "image": "https://rango.vip/i/09p0wf",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.00000124,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "sTRX",
    "name": "staked TRX",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TU3kjFuhtEo42tsCBtfYUAZxoqQ4yuSLQ5",
    "decimals": 18,
    "image": "https://rango.vip/i/RXzGVN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.168399,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "stUSDT",
    "name": "Staked USDT",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TThzxNRLrW2Brp9DcTQU8i4Wd9udCWEdZ3",
    "decimals": 18,
    "image": "https://rango.vip/i/J5yjb5",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 1.007,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "MCS-P",
    "name": "MCS-P",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TUjwNG28iEa18s8WVnbxChu7g6SN2VCxUP",
    "decimals": 14,
    "image": "https://rango.vip/i/WLgHFu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "888",
    "name": "888Tron",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TLvDJcvKJDi3QuHgFbJC6SeTj3UacmtQU3",
    "decimals": 6,
    "image": "https://rango.vip/i/bns4OE",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.00469921,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "TRON",
    "symbol": "Sundog",
    "name": "SUNDOG",
    "isPopular": false,
    "chainId": "728126428",
    "address": "TXL6rJbvmjD46zeN1JssfgxvSo99qC8MRT",
    "decimals": 18,
    "image": "https://rango.vip/i/eSRJof",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/TRON/icon.svg",
    "usdPrice": 0.214112,
    "supportedSwappers": [
      "SunSwap"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "XCKSM",
    "name": "xcKSM",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xffffffff1fcacbd218edc0eba20fc2308c778080",
    "decimals": 12,
    "image": "https://rango.vip/i/jdFvUN",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 17.25,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "XCRMRK",
    "name": "xcRMRK",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xffffffff893264794d9d57e1e0e21e0042af5a0a",
    "decimals": 10,
    "image": "https://rango.vip/i/80asWC",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 5.327926364359964,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "MARA",
    "name": "MARA Token",
    "isPopular": false,
    "chainId": "1285",
    "address": "0x0d2faa2064129ecbfaccd45be33dea3bcd3f8863",
    "decimals": 18,
    "image": "https://rango.vip/i/vLpJbI",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.0001600419878609526,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "XCKBTC",
    "name": "Kintsugi Wrapped BTC",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xfffffffff6e528ad57184579beee00c5d5e646f0",
    "decimals": 8,
    "image": "https://rango.vip/i/pQl2zh",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 700088.5833131851,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "XCKINT",
    "name": "Kintsugi Native Token",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xffffffff83f4f317d3cbf6ec6250aec3697b3ff2",
    "decimals": 12,
    "image": "https://rango.vip/i/Zs8coH",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 3.7130698018968276,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "XCKMA",
    "name": "Calamari Native Token",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xffffffffa083189f870640b141ae1e882c2b5bad",
    "decimals": 12,
    "image": "https://rango.vip/i/tuivCo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 0.0034935807082657686,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "MOONRIVER",
    "symbol": "XCBNC",
    "name": "Bifrost Native Token",
    "isPopular": false,
    "chainId": "1285",
    "address": "0xfffffffff075423be54811ecb478e911f22dde7d",
    "decimals": 12,
    "image": "https://rango.vip/i/zaWxSU",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/MOONRIVER/icon.svg",
    "usdPrice": 2.2554754094676026,
    "supportedSwappers": [
      "SolarbeamSwap"
    ]
  },
  {
    "blockchain": "COMDEX",
    "symbol": "CMST",
    "name": null,
    "isPopular": true,
    "chainId": "comdex-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/SXMnd1",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/COMDEX/icon.svg",
    "usdPrice": 0.085260093241191,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "STARNAME",
    "symbol": "IOV",
    "name": null,
    "isPopular": true,
    "chainId": "iov-mainnet-ibc",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/DJJLoz",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARNAME/icon.svg",
    "usdPrice": 0.000575150285916855,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "UMEE",
    "symbol": "UMEE",
    "name": null,
    "isPopular": true,
    "chainId": "umee-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/mhQrb4",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/UMEE/icon.svg",
    "usdPrice": 0.000868681762950375,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "STARGAZE",
    "symbol": "STRDST",
    "name": null,
    "isPopular": true,
    "chainId": "stargaze-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/iFCNCi",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STARGAZE/icon.svg",
    "usdPrice": 0.014221569310524828,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "DESMOS",
    "symbol": "DSM",
    "name": null,
    "isPopular": true,
    "chainId": "desmos-mainnet",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/5rTkZy",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/DESMOS/icon.svg",
    "usdPrice": 0.0047554870287408,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "NOBLE",
    "symbol": "FRNZ",
    "name": null,
    "isPopular": true,
    "chainId": "noble-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/mSlWuo",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/NOBLE/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "NEUTRON",
    "symbol": "wstETH",
    "name": null,
    "isPopular": true,
    "chainId": "neutron-1",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/674h8N",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/NEUTRON/icon.svg",
    "usdPrice": 2779.2153173899433,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "PERSISTENCE",
    "symbol": "stkOSMO",
    "name": null,
    "isPopular": true,
    "chainId": "core-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/sJqb3G",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/PERSISTENCE/icon.svg",
    "usdPrice": 0.3949526054659721,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "NEUTRON",
    "symbol": "NEWT",
    "name": null,
    "isPopular": true,
    "chainId": "neutron-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/8ia4Wa",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/NEUTRON/icon.svg",
    "usdPrice": 0.010083047637249713,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "NEUTRON",
    "symbol": "APOLLO",
    "name": null,
    "isPopular": true,
    "chainId": "neutron-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/X7c3Pu",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/NEUTRON/icon.svg",
    "usdPrice": 0.104771,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "INJECTIVE",
    "symbol": "HAVA",
    "name": null,
    "isPopular": true,
    "chainId": "injective-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/tw8C14",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/INJECTIVE/icon.svg",
    "usdPrice": 4.135977725088551e-8,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "STRIDE",
    "symbol": "stDYM",
    "name": null,
    "isPopular": true,
    "chainId": "stride-1",
    "address": null,
    "decimals": 18,
    "image": "https://rango.vip/i/cSGCqj",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STRIDE/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "IBC"
    ]
  },
  {
    "blockchain": "STRIDE",
    "symbol": "stSAGA",
    "name": null,
    "isPopular": true,
    "chainId": "stride-1",
    "address": null,
    "decimals": 6,
    "image": "https://rango.vip/i/HGmcZb",
    "blockchainImage": "https://raw.githubusercontent.com/rango-exchange/assets/main/blockchains/STRIDE/icon.svg",
    "usdPrice": null,
    "supportedSwappers": [
      "IBC"
    ]
  }
];