<template>
  <ModalBgWrapper :open="props.open" body-class="border-none" @close="onClose">
    <div class="flex w-full max-w-[720px] flex-col-reverse text-sm text-white md:flex-row">
      <div
        class="flex flex-[4] flex-col justify-between gap-6 rounded-bl-3xl rounded-br-3xl border-2 border-solid border-[#50494580] bg-[#141414] p-[24px] backdrop-blur-0 max-md:!rounded-b-[0px] max-md:border-t-0 md:rounded-br-none md:rounded-tl-3xl md:p-[40px]"
      >
        <p class="larken text-2xl">
          <template v-if="statusDisplay?.status === 'success'"
            >Swapped {{ fromSymbol }} with {{ toSymbol }}!</template
          >
          <template v-else-if="statusDisplay?.status === 'failed'"
            >Couldn't swap {{ fromSymbol }} with {{ toSymbol }}</template
          >
          <span v-else data-testid="status-swap-status">
            Swapping {{ fromSymbol }} with {{ toSymbol }}...</span
          >
        </p>
        <div class="[& *]:box-border box-border w-full">
          <div
            class="relative flex min-h-[70px] w-full items-center justify-center overflow-hidden rounded-2xl px-3.5 py-3"
            :class="{
              'bg-tx-pending': !isCompleted,
              'bg-tx-success': isCompleted && !isFailed,
              'bg-tx-failed': isCompleted && isFailed,
            }"
          >
            <div
              class="estimatedTime-300 absolute left-0 top-0 h-full transition-all"
              :class="{
                'bg-tx-pending': !isCompleted,
                'bg-tx-success': isCompleted && !isFailed,
                'bg-tx-failed': isCompleted && isFailed,
              }"
              :style="{ width: `${progressPercentOrSuccess}%` }"
            />
            <div class="relative flex flex-col items-center justify-center gap-1">
              <template v-if="isCompleted">
                <template v-if="!isFailed">
                  <div
                    class="flex items-center justify-center gap-[0.625em] font-bold text-tx-success"
                  >
                    <img src="/images/checked.svg" class="aspect-square w-[1em]" />
                    Transaction Successful
                  </div>
                </template>
                <template v-else>
                  <div
                    class="flex items-center justify-center gap-[0.625em] font-bold text-tx-failed"
                  >
                    <img src="/images/failed.svg" class="aspect-square w-[1em]" />
                    Transaction Failed
                  </div>
                  <div>
                    <span class="opacity-70">{{ statusDisplay.errorMessage }}</span>
                  </div>
                </template>
              </template>
              <template v-else>
                <div
                  class="flex items-center justify-center gap-[0.625em] font-bold text-tx-pending"
                >
                  <Icon icon="line-md:loading-loop" class="text-white" />
                  Transaction Pending
                </div>
                <div class="flex items-center gap-1">
                  ~ {{ timeRemaining }} <Icon icon="lucide:clock-2" />
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-y-3">
          <div class="flex flex-row justify-between">
            <h4 class="text-[14px] font-normal text-white/70">Rate</h4>
            <h4 class="text-[14px] font-normal text-white/70">
              1 {{ fromSymbol }} = {{ statusDisplay.swapRate }} {{ toSymbol }}
            </h4>
          </div>
          <ExpansionPanel v-if="params.feesTotalUsd">
            <template #title>
              <span class="text-sm">Swap Fees</span
              ><span class="ml-auto text-base text-white"
                >${{ toPrice(params.feesTotalUsd, 'USD') }}</span
              >
            </template>
            <ExpansionPanelItem v-if="params.feesAffiliateUsd">
              <div class="flex justify-between gap-1">
                <span>Affiliate Fee</span
                ><span class="text-white">${{ toPrice(params.feesAffiliateUsd, 'USD') }}</span>
              </div>
            </ExpansionPanelItem>
            <ExpansionPanelItem v-if="params.feesProtocolUsd">
              <div class="flex justify-between gap-1">
                <span>Protocol Fee</span
                ><span class="text-white">${{ toPrice(params.feesProtocolUsd, 'USD') }}</span>
              </div>
            </ExpansionPanelItem>
          </ExpansionPanel>
          <div class="flex flex-row justify-between">
            <h4 class="text-[14px] font-normal text-white/70">Estimated Time</h4>
            <h4
              class="flex flex-row items-center justify-center gap-x-1 text-[16px] font-medium text-white"
            >
              <Icon icon="lucide:clock-2" />
              <span class="font-bold">
                <template v-for="(character, index) in timeRemaining" :key="index">
                  <span
                    :class="character === ':' ? '' : 'inline-block min-w-[0.65em] text-center'"
                    >{{ character }}</span
                  >
                </template>
              </span>
            </h4>
          </div>
          <!-- <div class="flex flex-row justify-between">
              <h4 class="text-white opacity-70 text-[14px] font-normal">Price Impact</h4>
              <h4
                class="text-white text-[16px] font-medium flex flex-row gap-x-1 items-center justify-center"
              >
                0.33%
              </h4>
            </div> -->
        </div>
        <div class="w-full border-b-2 border-[#3d3d3d]"></div>
        <div class="flex flex-col gap-y-3">
          <div v-if="params.toAddress" class="flex flex-row justify-between">
            <h4 class="text-[14px] font-normal text-white/50">Receiving Address</h4>
            <h4
              class="flex flex-row items-center justify-center gap-x-2 text-[14px] font-normal text-white"
            >
              <span class="opacity-70">
                {{ shortString(params.toAddress, [13, 3]) }}
              </span>
              <CopyButton class="opacity-100" :text="params.toAddress" />
            </h4>
          </div>
          <div v-if="params.hash" class="flex flex-row justify-between">
            <h4 class="text-[14px] font-normal text-white/50">In Hash</h4>
            <h4
              class="flex flex-row items-center justify-center gap-x-2 text-[14px] font-normal text-white"
            >
              <NuxtLink
                v-if="statusDisplay.inHashLink && statusDisplay.inHashLink.length > 1"
                :to="statusDisplay.inHashLink"
                target="_blank"
                class="opacity-70"
                >{{ shortString(params.hash, [15, 4]) }}</NuxtLink
              >
              <div v-else class="opacity-70">{{ shortString(params.hash, [15, 4]) }}</div>
              <CopyButton class="opacity-100" :text="params.hash" />
            </h4>
          </div>
          <div v-if="params.startMs" class="flex flex-row justify-between">
            <h4 class="text-[14px] font-normal text-white opacity-70">In Time</h4>
            <h4
              class="flex flex-row items-center justify-center gap-x-3 text-[14px] font-normal text-white opacity-70"
            >
              {{ formatMsToDate(params.startMs) }}
            </h4>
          </div>
        </div>
        <div class="w-full border-b-2 border-[#3d3d3d]" />
        <div>
          <h4 class="text-[14px] font-normal text-white/50">
            Output is estimated. You will receive at least
            {{ estimatedOutputMin }} {{ toSymbol }} or the transaction will refund.
          </h4>
        </div>
        <template v-if="isCompleted && isFailed">
          <NuxtLink
            :to="tryAgainLink"
            class="cursor-pointer rounded-lg bg-[#C99D29] px-4 py-4 text-center text-[1em] font-bold text-[#282828]"
            >Try Again</NuxtLink
          >
        </template>
      </div>
      <div
        class="relative flex flex-[3] flex-col items-center justify-center gap-[10px] overflow-hidden rounded-tl-3xl rounded-tr-3xl border-l-2 border-r-2 border-t-2 border-solid border-[#50494580] bg-[#1e1e1e] bg-cover bg-center p-[33px] bg-blend-overlay sm:border-b-0 md:gap-5 md:rounded-br-3xl md:rounded-tl-none md:border-b-2 md:border-l-0"
        :class="isCompleted ? '' : 'background_falling_stars'"
      >
        <div
          class="absolute right-6 top-6 z-10 flex cursor-pointer items-center justify-center rounded-md bg-[#FFFFFF14] p-1.5 text-base"
          role="button"
          tabindex="0"
          @click="onClose"
        >
          <Icon icon="iconamoon:sign-times-duotone" />
        </div>
        <img
          :src="statusDisplay.fromAsset?.image || '/tokens/unknown.png'"
          class="absolute h-20 w-20 rounded-full transition-all duration-700 md:h-32 md:w-32"
          :class="
            !(isCompleted && !isFailed)
              ? 'left-20 top-3 -translate-x-1/2 md:left-1/2 md:top-16'
              : '-left-4 -top-4 -rotate-45 md:-top-8 md:left-1/2 md:-translate-x-1/2'
          "
          @error="(e) => ((e.target as HTMLImageElement).src = '/tokens/unknown.png')"
        />
        <img
          :src="statusDisplay.toAsset?.image || '/tokens/unknown.png'"
          class="absolute h-20 w-20 rounded-full transition-all duration-700 md:h-32 md:w-32"
          :class="
            !(isCompleted && !isFailed)
              ? 'bottom-3 right-20 translate-x-1/2 md:bottom-16 md:right-1/2'
              : '-bottom-3 -right-4 rotate-45 md:-bottom-8 md:right-1/2 md:translate-x-1/2'
          "
          @error="(e) => ((e.target as HTMLImageElement).src = '/tokens/unknown.png')"
        />
        <div
          class="relative z-10 flex gap-4 rounded-3xl border-[1px] border-r-0 border-[#ffffff14] bg-[#313131] px-6 py-3 backdrop-blur-sm"
        >
          <span class="text-white">
            <span class="font-bold">{{ statusDisplay.inAmount }} </span>
            &nbsp;
            <span class="text-white/50">{{ fromSymbol }}</span>
          </span>
          <!-- <span class="text-white/50 font-light">Native</span> -->
        </div>
        <div class="relative z-10 flex gap-1">
          <!-- <FlipProperty1Default
              class="relative bg-[#42424270] backdrop-blur-sm"
              tabindex="0"
              property1="small"
              aria-label="Flip the in and out assets"
            /> -->
          <div class="rounded-lg bg-[#313131] p-2 backdrop-blur-sm">
            <Icon class="rotate-90" icon="mi:switch" />
          </div>
          <div
            class="relative flex items-center gap-2 rounded-lg bg-[#313131] px-3.5 py-1 backdrop-blur-sm"
          >
            via
            <img
              :src="params.swapperIcon ?? '/tokens/unknown.png'"
              class="h-5 w-5 rounded-full border border-solid border-white"
              @error="(e) => ((e.target as HTMLImageElement).src = '/tokens/unknown.png')"
            />
            <span data-testid="status-swapper" class="whitespace-nowrap">{{ params.swapper }}</span>
          </div>
        </div>
        <div
          class="main-token relative flex gap-4 rounded-3xl border-l border-[#ffffff14] bg-[#313131] px-6 py-3 backdrop-blur-sm"
          :style="`opacity: ${0.1 + (progressPercent * 0.9) / 100}`"
        >
          <span class="text-white">
            <span class="font-bold" data-testid="status-output">
              {{ statusDisplay.outputAmount }}
            </span>
            &nbsp;
            <span class="text-white/50">{{ toSymbol }}</span>
          </span>
          <!-- <span class="text-white/50 font-light">Native</span> -->
        </div>
      </div>
    </div>
  </ModalBgWrapper>
</template>

<script setup lang="ts">
import type { StatusResponse } from 'rango-types/lib/api/basic/transactions'
import { Icon } from '@iconify/vue'
import { Chain } from '@swapkit/helpers'
import { useRoute, useRouter } from 'nuxt/app'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { type TransactionSchema, useSwapkitRouteStore } from '~/store/swapkitRouteStore'
import { useRangoRouteStore } from '~/store/rangoRouteStore'
import { formatMsToDate, secondsToTime, shortString } from '~/utils/helpers'
import { allTokensRango } from '~/scripts/output/allTokensRango'
import { getAssetId, hasMessage } from '~/utils/main'
import { swapkitClient } from '~/clients/swapkit'
import { useSnackbarMessage } from '~/store/snackbar'
import { intToFloat, toPrice } from '~/utils/numbers'
import { allRoutesSwapkit } from '~/utils/swapkit'
import { parseStatusQueryParams } from '~/utils/status'

const props = defineProps<{
  open: boolean
}>()
const emits = defineEmits<{ close: [] }>()

const router = useRouter()
const route = useRoute()
const snackbar = useSnackbarMessage()
const params = computed(() => {
  return parseStatusQueryParams(route.query)
})

const onClose = () => {
  emits('close')
}

const fromSymbol = computed(() => params.value.fromAsset?.split('.')[1].split('-')[0])
const toSymbol = computed(() => params.value.toAsset?.split('.')[1].split('-')[0])
const fromBlockchain = computed(() => params.value.fromAsset?.split('.')[0])
const toBlockchain = computed(() => params.value.toAsset?.split('.')[0])
const fromAssetAddress = computed(() => params.value.fromAsset?.split('.')[1].split('-')[1])
const toAssetAddress = computed(() => params.value.toAsset?.split('.')[1].split('-')[1])

// const feesArray = computed(() => {
//   if (fees.value) {
//     return JSON.parse(fees.value)
//   }
//   return []
// })
const estimatedOutputMin = computed(() => params.value.outputAmountMin)
const estimatedTimeSec = params.value.estimatedTimeS ?? 500

const isCompleted = computed(
  () => statusDisplay.value?.status === 'success' || statusDisplay.value?.status === 'failed',
)
const isFailed = computed(() => statusDisplay.value?.status === 'failed')

const statusRaw = ref<StatusResponse | TransactionSchema | null>(null)

const swapkitRouteStore = useSwapkitRouteStore()
const { fetchStatus: fetchStatusSwapkit } = swapkitRouteStore

const rangoRouteStore = useRangoRouteStore()
const { fetchStatus: fetchStatusRango } = rangoRouteStore

const getIntegration = () => {
  if (params.value.hash && params.value.chainId) return 'swapkit'
  else if (params.value.hash && params.value.statusId) return 'rango'
  else router.push('/swap')
}

let fetchStatusTimeoutId: ReturnType<typeof setTimeout>

const fetchStatus = async () => {
  const integration = getIntegration()
  try {
    // Swapkit
    if (integration === 'swapkit' && params.value.hash && params.value.chainId) {
      try {
        statusRaw.value = (
          await fetchStatusSwapkit({
            hash: params.value.hash,
            chainId: params.value.chainId,
          })
        ).data
      } catch (error: any) {
        const errorMessage = typeof console.error === 'object' ? JSON.stringify(error) : error
        window.newrelic?.noticeError('non-ui error: ' + errorMessage)
      }
    } else if (integration === 'rango' && params.value.hash && params.value.statusId) {
      // Rango
      statusRaw.value = await fetchStatusRango({
        hash: params.value.hash,
        statusId: params.value.statusId,
      })
    }
  } catch (error: unknown) {
    const errorMessage =
      (typeof error === 'string' && error) ||
      (hasMessage(error) && error.message) ||
      'Could not fetch status'
    snackbar.addError({ title: errorMessage })
  } finally {
    if (!isCompleted.value) {
      fetchStatusTimeoutId = setTimeout(fetchStatus, 5000)
    }
  }
}

interface Asset {
  blockchain: string
  symbol: string
  decimals: number
  image: string
  address: string | null
}
interface StatusDisplay {
  inHash: string
  inHashLink: string | null
  fromAsset: Asset | null
  inAmount: string
  toAsset: Asset | null
  swapRate: number
  status: 'running' | 'success' | 'failed'
  outputAmount: string
  errorMessage: string | null
  swapper: string
  swapperIcon: string
}
const statusDisplay = computed<StatusDisplay>(() => {
  const integration = getIntegration()
  let result: StatusDisplay = {
    inAmount: '0',
    inHash: '',
    inHashLink: '',
    fromAsset: null,
    toAsset: null,
    swapRate: 0,
    status: 'running',
    outputAmount: '0',
    errorMessage: null,
    swapper: '',
    swapperIcon: '',
  }

  const getRangoTokenInfo = (chain: string, ticker: string, address?: string) => {
    const token = allTokensRango.find((t) => {
      return (
        t.blockchain.toUpperCase() === chain.toUpperCase() &&
        t.symbol.toUpperCase() === ticker.toUpperCase() &&
        (address && t.address ? t.address.toUpperCase() === address.toUpperCase() : true)
      )
    })

    if (token) {
      return {
        blockchain: token.blockchain,
        symbol: token.symbol,
        decimals: token.decimals,
        image: token.image,
        address: token.address,
      }
    }

    return null
  }
  const getSwapkitTokenInfo = (chain: string, symbol: string, address?: string) => {
    const token = allRoutesSwapkit().find((t) => {
      const searchToken = address
        ? `${chain}.${symbol}-${address}`.toUpperCase()
        : `${chain}.${symbol}`.toUpperCase()

      return t.assetId.toUpperCase() === searchToken
    })
    if (token) {
      return {
        blockchain: token.chain,
        symbol: token.ticker,
        decimals: token.decimals,
        image: token.logoURI,
        address: token.assetAddress,
      }
    }
    return null
  }

  const tokenInfo = integration === 'swapkit' ? getSwapkitTokenInfo : getRangoTokenInfo

  const fromAsset =
    fromBlockchain.value === undefined || fromSymbol.value === undefined
      ? null
      : tokenInfo(fromBlockchain.value, fromSymbol.value, fromAssetAddress.value)
  const toAsset =
    toBlockchain.value === undefined || toSymbol.value === undefined
      ? null
      : tokenInfo(toBlockchain.value, toSymbol.value, toAssetAddress.value)

  if (fromAsset === null || toAsset === null) {
    window.newrelic?.noticeError(`fromAsset ${fromAsset} or toAsset ${toAsset} is null`)
    snackbar.addError({ title: `fromAsset ${fromAsset} or toAsset ${toAsset} is null` })
    return result
  }

  const inAmount = toPrice(params.value.inAmount ?? 0, params.value.fromAsset ?? 'USD')
  const swapRate = Number(
    (Number(params.value.outAmount) / Number(params.value.inAmount)).toFixed(7),
  )

  // Handle deep linked values
  result.status = 'running'
  const outputAmount = toPrice(params.value.outputAmountMin ?? '0', params.value.toAsset ?? 'USD')
  if (integration === 'swapkit') {
    result.inHashLink = swapkitClient.getExplorerTxUrl({
      chain: fromBlockchain.value as Chain,
      txHash: params.value.hash as string,
    })

    result.outputAmount = `~${outputAmount}`
  } else if (integration === 'rango') {
    result.outputAmount = `~${outputAmount}`
  }

  // * toAmount used as discriminator for swapkit
  if (
    integration === 'swapkit' &&
    statusRaw.value !== null &&
    'toAmount' in statusRaw.value &&
    statusRaw.value.toAmount !== '0'
  ) {
    const swapkitErrorMap: { [key: string]: TransactionSchema['status'] } = {
      completed: 'success',
      refunded: 'failed',
      partially_refunded: 'failed',
      dropped: 'failed',
      reverted: 'failed',
      replaced: 'failed',
      retries_exceeded: 'failed',
      parsing_error: 'failed',
    }
    result.status = swapkitErrorMap[statusRaw.value.status] ?? 'running'

    result.outputAmount = statusRaw.value?.toAmount
    result.errorMessage = statusRaw.value?.status
    // * output used as discriminator for rango
  } else if (integration === 'rango' && statusRaw.value !== null && 'output' in statusRaw.value) {
    const finalOutputAmount = statusRaw.value?.output?.amount

    result.inHashLink =
      statusRaw.value.explorerUrl?.find(
        (link) => link.description === 'Swap' || link.description === 'Inbound',
      )?.url ?? null

    result.status = statusRaw.value?.status ?? 'running'
    result.outputAmount = finalOutputAmount
      ? // Output from API
        toPrice(
          intToFloat(finalOutputAmount, toAsset.decimals),
          getAssetId(toAsset.blockchain, toAsset.symbol, toAsset.address),
        )
      : // Estimated output from query string
        `~${toPrice(
          params.value.outputAmountMin ?? '0',
          getAssetId(toAsset.blockchain, toAsset.symbol, toAsset.address),
        )}`
    result.errorMessage = statusRaw.value?.error
  }

  result = { ...result, fromAsset, toAsset, swapRate, inAmount }
  return result
})

const progressPercentOrSuccess = computed(() => {
  if (isCompleted.value) {
    return 100
  }
  return progressPercent.value
})

const progressTimeSec = ref(0)
const progressPercent = ref(0)
const timeRemaining = computed(() =>
  secondsToTime(isCompleted.value ? 0 : estimatedTimeSec - progressTimeSec.value),
)

let timer: ReturnType<typeof setInterval>

const startProgress = () => {
  const startTime = Date.now()
  timer = setInterval(() => {
    const elapsedTime = Date.now() - startTime
    const elapsedTimeSec = elapsedTime / 1000
    if (progressPercent.value >= 100 || elapsedTimeSec >= estimatedTimeSec || isCompleted.value) {
      clearInterval(timer)
      progressPercent.value = 100
      progressTimeSec.value = estimatedTimeSec
    } else {
      progressPercent.value = (elapsedTimeSec / estimatedTimeSec) * 100
      progressTimeSec.value += 0.1
    }
  }, 100)
}

const tryAgainLink = computed(() => {
  const fromAsset = statusDisplay.value.fromAsset
  const toAsset = statusDisplay.value.toAsset
  const query =
    fromAsset && toAsset
      ? {
          in: getAssetId(fromAsset.blockchain, fromAsset.symbol, fromAsset.address),
          out: getAssetId(toAsset.blockchain, toAsset.symbol, toAsset.address),
          // in: getSynthSaveAssetId({chain: fromAsset.blockchain, symbol:fromAsset.symbol, fromAsset.address}),
          // out: getSynthSaveAssetId({chain: toAsset.blockchain, symbol:toAsset.symbol, toAsset.address}),
          amount: params.value.inAmount,
        }
      : undefined
  return {
    path: '/swap',
    query,
  }
})

onMounted(() => {
  startProgress()
  fetchStatus()
})

onUnmounted(() => {
  if (timer) clearInterval(timer)
  if (fetchStatusTimeoutId) clearTimeout(fetchStatusTimeoutId)
})
</script>

<style scoped>
.background_falling_stars {
  background-image: url('/images/stars.gif');
}
</style>
