<template>
  <v-expansion-panels>
    <v-expansion-panel class="bg-transparent">
      <v-expansion-panel-title class="!min-h-0 !p-0 !text-white/70">
        <slot name="title" />
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-list
          class="flex-column mt-3 flex gap-3 !border-l-4 border-solid border-gray-100/50 bg-transparent !py-0 pl-3"
        >
          <slot />
        </v-list>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script setup lang="ts"></script>
<style>
.v-expansion-panel__shadow {
  box-shadow: none !important;
}
.v-expansion-panel-title__overlay {
  background-color: transparent !important;
}
.v-expansion-panel-text__wrapper {
  padding: 0 !important;
}
.v-expansion-panel-title__icon {
  margin-left: 0.25rem !important;
}
</style>
