<template>
  <div
    class="grid grid-cols-[1fr_auto] items-center rounded-2xl bg-[#282828] p-5"
    :class="isChartOpen ? 'pr-0' : ''"
  >
    <div class="flex flex-1 flex-col pr-2.5">
      <input
        v-if="value !== undefined"
        :class="[inputClass, value === '0' ? '!text-white/30' : '!text-white']"
        :value="value"
        placeholder="0"
        :aria-label="amountLabel"
        readonly
      />
      <input
        v-else
        :ref="onAmountRef"
        :class="[inputClass, _value === 0 ? '!text-white/30' : '!text-white']"
        placeholder="0"
        :aria-label="amountLabel"
        @input="onAmountChange"
      />
      <div class="text-xs text-white/50">
        $
        <span :data-testid="priceTestId">{{ priceDisplay }}</span>
      </div>
    </div>
    <div class="grid grid-cols-[auto_auto] gap-2.5 md:col-span-1">
      <div
        class="flex items-center gap-2.5 text-white"
        :aria-label="assetSelectLabel"
        role="combobox"
        :aria-expanded="selectOpen"
        aria-haspopup="tree"
        aria-controls="asset-select"
        :aria-activedescendant="selectedAssetId"
        @click="onOpenAssetSelect"
      >
        <img class="block aspect-square w-7 rounded-full" :src="assetLogoUri" />
        <span class="text-3xl font-bold leading-none">{{ assetName }}</span>
        <div class="rounded-full bg-white/10 px-1 py-2 text-[10px] text-white">
          <Icon icon="mingcute:down-line" />
        </div>
      </div>
      <div
        v-if="hasChart"
        class="rounded-full p-2 text-xs"
        :class="isChartOpen ? '-mr-2.5 bg-[#ECBA33] !text-[#1D2021]' : 'bg-white/10 !text-white'"
        role="button"
        tabindex="0"
        aria-label="Toggle trading chart"
        @click="onToggleChart"
      >
        <Icon icon="lucide:chart-area" />
      </div>
      <div
        class="col-span-2 text-right text-sm leading-tight text-white/70"
        :class="isChartOpen ? 'pr-5' : ''"
      >
        {{ assetChain }}
      </div>
    </div>
    <div
      v-if="isWalletConnected"
      class="col-span-2 mt-2.5 flex w-max items-center gap-1 text-white"
    >
      <Icon icon="uit:wallet" class="block text-white/50" />
      <span class="whitespace-nowrap text-xs text-[#ECBA33]">
        {{ balanceDisplay }}
      </span>
      <button
        v-if="!value"
        role="button"
        class="rounded-lg bg-white/5 p-1.5 text-xs leading-none !text-white/50 hover:!text-white"
        tabindex="0"
        @click="onSelectMax"
      >
        MAX
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { type ComponentPublicInstance, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'

const { isWalletConnected } = storeToRefs(useSwapkitWalletStore())

defineProps<{
  assetName: string
  assetChain: string
  assetLogoUri: string
  assetSelectLabel: string
  amountLabel: string
  hasChart: boolean
  isChartOpen: boolean
  priceDisplay: string
  priceTestId: string
  balanceDisplay: string
  selectOpen: boolean
  selectedAssetId: string
  value?: string
}>()
const emits = defineEmits<{
  amountRef: [Element | ComponentPublicInstance | null]
  amountChange: [Event]
  openAssetSelect: []
  toggleChart: []
  selectMax: []
}>()

const _value = ref(0)

const inputClass =
  'col-span-2 md:col-span-1 w-full text-[40px] font-bold bg-white/0 border-none outline-none placeholder:text-white/30 leading-none'

const onAmountRef = (maybeElement: Element | ComponentPublicInstance | null) => {
  emits('amountRef', maybeElement)
  if (maybeElement instanceof HTMLInputElement) {
    _value.value = Number(maybeElement.value)
  }
}
const onAmountChange = (e: Event) => {
  emits('amountChange', e)
  _value.value = Number((e.target as any).value)
}
const onOpenAssetSelect = () => {
  emits('openAssetSelect')
}
const onToggleChart = () => {
  emits('toggleChart')
}
const onSelectMax = () => {
  emits('selectMax')
}
</script>
