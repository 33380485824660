<template>
  <div class="toba-container-fluid">
    <Head>
      <Title>{{ title }}</Title>
    </Head>

    <div class="toba-container overflow-auto">
      <div
        class="m-auto grid max-md:pb-5"
        :class="isChartOpen ? 'max-md:grid-rows-2 md:grid-cols-2' : 'grid-cols-1 grid-rows-1'"
      >
        <div
          id="swap-container"
          class="grid w-full max-w-xl gap-5 rounded-3xl border border-solid border-[#504945] bg-[#1D2021] p-6 max-md:p-4 md:translate-y-[-42px]"
          :class="isChartOpen ? 'max-md:rounded-b-none md:rounded-r-none' : ''"
        >
          <div
            v-if="quoteDisplay?.error || feeExceedsBalance || amountInExceedsMax"
            class="grid grid-cols-[auto_1fr] items-center gap-2.5 rounded-2xl bg-[#ffc107]/10 px-5 py-2.5 text-xs leading-tight"
          >
            <Icon class="text-[#ffc107]" icon="gg:info" />
            <span class="text-[#ffc107]">
              {{
                (quoteDisplay?.error ===
                'Rango prohibits you from performing this swap. It may pose a huge money loss on you!'
                  ? 'Rango prohibits you from performing this swap due to high slippage'
                  : quoteDisplay?.error) ||
                (feeExceedsBalance &&
                  'The estimated network fee is greater than your current balance') ||
                (amountInExceedsMax
                  ? 'Amount in plus estimated network fee might exceed balance'
                  : null)
              }}
            </span>
          </div>
          <div class="grid w-full grid-rows-[1fr_auto_1fr]">
            <PagesSwapAssetInput
              :asset-name="poolIn.assetName"
              :asset-logo-uri="poolIn.logoURI"
              :asset-chain="poolIn.chainName"
              asset-select-label="Select input asset"
              amount-label="Swap input amount"
              :price-display="valueInDisplay"
              price-test-id="swap-input-dollar-value"
              :balance-display="balanceInDisplay"
              :has-chart="assetHasChart(poolIn.assetName)"
              :is-chart-open="isChartOpen && poolForChart === 'poolIn'"
              :select-open="poolToUpdate === 'poolIn' && showSelectPoolDialog"
              :selected-asset-id="poolIn.assetId"
              @amount-ref="setAmountRef"
              @amount-change="onAmountChange"
              @open-asset-select="() => openPoolDialogFor('poolIn')"
              @toggle-chart="() => toggleChartOpen('poolIn')"
              @select-max="onSelectMax"
            />
            <div class="flex items-center justify-center">
              <FlipProperty1Default
                class="-my-4 rounded-md bg-[#3e3e3e] p-3 shadow-sm [&:hover>*]:rotate-180 [&>*]:duration-1000"
                role="button"
                tabindex="0"
                aria-label="Flip the in and out assets"
                @click.stop="switchAssets"
              />
            </div>
            <PagesSwapAssetInput
              :asset-name="poolOut.assetName"
              :asset-logo-uri="poolOut.logoURI"
              :asset-chain="poolOut.chainName"
              asset-select-label="Select output asset"
              amount-label="Swap output amount"
              :price-display="quoteDisplay?.quote?.expectedOutputUsdDisplay ?? toPrice(0, 'USD')"
              price-test-id="swap-output-dollar-value"
              :balance-display="balanceOutDisplay"
              :has-chart="assetHasChart(poolOut.assetName)"
              :is-chart-open="isChartOpen && poolForChart === 'poolOut'"
              :value="quoteDisplay?.quote?.expectedOutputDisplay ?? '0'"
              :select-open="poolToUpdate === 'poolOut' && showSelectPoolDialog"
              :selected-asset-id="poolOut.assetId"
              @open-asset-select="() => openPoolDialogFor('poolOut')"
              @toggle-chart="() => toggleChartOpen('poolOut')"
            />
            <div
              v-if="false"
              class="mt-2.5 grid grid-cols-[auto_1fr] items-center gap-2.5 rounded-2xl bg-[#E84142]/10 px-5 py-2.5 text-xs leading-tight"
            >
              <!-- TODO should be yellow not red -->
              <Icon class="text-[#E84142]" icon="gg:info" />
              <span class="text-[#E84142]">No Routes available</span>
              <span class="col-start-2 text-white/50">
                No route available, your input amount amount is too small, please try again.
              </span>
            </div>
          </div>
          <button
            v-if="isWalletConnected"
            :class="[
              'larken box-border w-full rounded-2xl bg-[#ECBA33] px-5 py-4 text-xl text-[#1D2021] md:text-2xl',
              {
                'toba-disabled': isDisabled,
              },
            ]"
            data-testid="swap-submit"
            :aria-disabled="isDisabled"
            @click="onSwap"
          >
            {{ swapActionName }}
          </button>
          <button
            v-else
            :class="[
              'larken box-border w-full rounded-2xl bg-[#ECBA33] px-5 py-4 text-xl text-[#1D2021] md:text-2xl',
              {
                'toba-disabled': swapStatus === 'fetchingQuote' || isLoadingBalances,
              },
            ]"
            :aria-disabled="swapStatus === 'fetchingQuote' || isLoadingBalances"
            data-testid="swap-submit"
            @click="showConnectWalletModal"
          >
            {{
              swapStatus === 'fetchingQuote'
                ? 'Loading quote...'
                : isLoadingBalances
                  ? 'Connecting wallet...'
                  : quoteRaw
                    ? 'Connect to Swap'
                    : 'Connect Wallet'
            }}
          </button>
          <PagesSwapRecipientInput
            v-if="isWalletConnected"
            :address="destination"
            :editing="recipientValue !== null"
            :value="recipientValue"
            :error="'error' in recipient ? recipient.error : undefined"
            @edit="onRecipientEdit"
            @input="onRecipientInput"
            @cancel="onRecipientCancel"
          />
        </div>
        {{ setChartContainerStyle() }}
        <div
          v-if="isChartOpen"
          id="tradingview-chart"
          class="[&_iframe]:border-red overflow-hidden max-md:rounded-b-2xl max-md:rounded-t-none md:translate-y-[-42px] md:rounded-l-none md:rounded-r-2xl"
          :style="chartContainerStyle"
        ></div>
      </div>
    </div>
    <Teleport to="body">
      <SubAssetSelect
        :key="subAssetSelectKey"
        :open="showSelectPoolDialog"
        :pool-to-update="poolToUpdate"
        :cur-selected-asset="poolToUpdate == 'poolIn' ? poolIn : poolOut"
        :curated-routes="conditionalCuratedAssetSelectorData"
        :searchable-routes="conditionalSearchableAssetSelectorData"
        @select="handleSelectPool"
        @close="closePoolDialog()"
      />
      <SubTxTracker
        v-if="openTxTrackerModal"
        :open="openTxTrackerModal"
        @close="onCloseTxTracker"
      />
    </Teleport>
    <SubConfirm
      v-if="!!quoteDisplay?.quote"
      :open="swapStatus === 'confirmingSwap' || swapStatus === 'swapping'"
      :is-confirming="swapStatus === 'swapping'"
      :amount-in="amountIn"
      :expected-amount-in="amountInExceedsMax ? maxAmountIn : null"
      :in-asset-name="poolIn.assetName"
      :in-icon="inIcon"
      :expected-output="quoteDisplay.quote.expectedOutputDisplay"
      :out-asset-name="poolOut.assetName"
      :out-icon="outIcon"
      :provider="quoteDisplay.quote.provider"
      :swapper-logo="quoteDisplay.quote.swapperLogo"
      :fiat-price-ratio="fiatPriceRatio"
      :fees="quoteDisplay.quote.fees"
      :estimated-time-in-seconds="quoteDisplay.quote.estimatedTimeInSeconds"
      :amount-out-min="quoteDisplay.quote.amountOutMin"
      :price-impact-percentage="quoteDisplay.quote.priceImpactPercentage.toNumber()"
      @close="onCloseConfirm"
      @confirm="submitSwap"
    />
    <ModalSubApproveAsset
      :open="swapStatus === 'confirmingApproval' || swapStatus === 'approving'"
      :loading="swapStatus === 'approving'"
      :fee="approvalFeeUsd"
      @close="onCloseConfirmApproval"
      @confirm="onApproveAsset"
    />
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { MAX_APPROVAL } from '@swapkit/toolbox-evm'
import { AssetValue, Chain, FeeOption, FeeTypeEnum, WalletOption } from '@swapkit/helpers'
import { BigNumber } from 'bignumber.js'
import { type QuoteResponse, RoutingResultType } from 'rango-types/lib/api/basic/routing'
import type { SwapResponse } from 'rango-types/lib/api/basic/transactions'
import type { SwapFee } from 'rango-types/lib/api/basic/common'
import { useRoute, useRouter, useRuntimeConfig } from 'nuxt/app'
import { storeToRefs } from 'pinia'
import {
  type ComponentPublicInstance,
  type ComputedRef,
  computed,
  onMounted,
  ref,
  toRaw,
  watch,
} from 'vue'
import type { QuoteResponseRoute } from '@swapkit/api'
import { useConnectWalletModalStore } from '@/store/connectWalletModalStore'
import { useRangoRouteStore } from '@/store/rangoRouteStore'
import { useRouteStore } from '@/store/routeStore'
import { useSwapkitRouteStore } from '@/store/swapkitRouteStore'
import type { RouteEndPoint } from '@/types/'
import { swapkitClient } from '~/clients/swapkit'
import { useFiatPriceStore } from '~/store/fiatPriceStore'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import { type ConnectedChainWithAssets, RangoChain } from '~/types/rango'
import type { AssetSelectorData } from '~/types/route'
import { useAnalytics } from '~/composables/analytics'
import { debounce, generateNonce, getAssetId, getSynthSaveAssetId, hasMessage } from '~/utils/main'
import type { ChainWallet, WalletAsset } from '~/wallets/swapkit'
import { groupReduce } from '~/utils/route'
import { DISPLAY_DECIMALS, formatNumber, isValidSolanaAddress } from '~/utils/utils'
import { type State, stateTypes } from '~/types/state'
import type { FiatPrice } from '~/types/fiatPrice'
import { useSnackbarMessage } from '~/store/snackbar'
import { floatToInt, intToBigNumber, intToFloat, toPrice } from '~/utils/numbers'
import { isEvmChain } from '~/utils/swapkit'
import { createAmountSchema } from '~/utils/swap'

import { rangoSwapkitMap } from '~/utils/rango'
import SubAssetSelect from '~/components/Modal/SubAssetSelect.vue'
import SubConfirm from '~/components/Pages/Swap/SubConfirm.vue'
import { manualRoutes } from '~/config/manualRoutes'
import { getHumanError } from '~/utils/humanErrors'
import { type StatusFees } from '~/utils/status'
import SubTxTracker from '~/components/Modal/SubTxTracker.vue'
import { useAlchemy } from '~/composables/alchemy'
import {
  type ApproveTokenAllowanceProps,
  type TokenAllowanceProps,
  useViem,
} from '~/composables/viem'
import { isHexAddress } from '~/utils/wallet'

// #region definitions
const title = 'Swap - El Dorado'
const runtimeConfig = useRuntimeConfig()
const analytics = useAnalytics()
const router = useRouter()
const route = useRoute()
const snackbar = useSnackbarMessage()
const alchemy = useAlchemy()
const viem = useViem()

const openTxTrackerModal = computed(() => {
  return route.query.openTxTrackerModal === 'true'
})

const connectWalletModalStore = useConnectWalletModalStore()
const { showConnectWalletModal } = connectWalletModalStore

const fiatPriceStore = useFiatPriceStore()
const { fiatPrices } = storeToRefs(fiatPriceStore)
const swapkitWalletStore = useSwapkitWalletStore()
const { isWalletConnected, wallets, isLoadingBalances, hasBalances } =
  storeToRefs(swapkitWalletStore)

const routeStore = useRouteStore()
const { routes: pools } = storeToRefs(routeStore)

const swapkitRouteStore = useSwapkitRouteStore()
const { fetchQuote: fetchQuoteSwapkit, submitSwap: submitSwapSwapkit } = swapkitRouteStore
const {
  getProviderLogo,
  referrerFeeRatio,
  getOutputRoutes: getOutputRoutesSwapkit,
} = storeToRefs(swapkitRouteStore)

const rangoRouteStore = useRangoRouteStore()
const {
  fetchQuote: fetchQuoteRango,
  submitSwap: submitSwapRango,
  fetchOutputRoutes: fetchOutputRoutesRango,
} = rangoRouteStore

const poolToUpdate = ref<PoolForDialog>('poolIn')

const slippagePercentage = 5.5
const quoteRaw = ref<Quote | null>(null)

// TODO check if these two can be deduplicated into one
const showSelectPoolDialog = ref(false)
const isRoutePickerVisible = ref(false)

const initialPoolIn = {
  asset: 'ETH.ETH',
  assetName: 'ETH',
  assetId: 'ETH.ETH',
  assetAddress: null,
  chain: Chain.Ethereum as const,
  chainName: 'Ethereum',
  integrations: ['swapkit', 'rango'],
  decimals: 18,
  logoURI: 'https://crispy.sfo3.cdn.digitaloceanspaces.com/eth.eth.png',
}
const initialPoolOut = {
  asset: 'BTC.BTC',
  assetName: 'BTC',
  assetId: 'BTC.BTC',
  assetAddress: null,
  chain: Chain.Bitcoin as const,
  chainName: 'Bitcoin',
  decimals: 8,
  logoURI: 'https://crispy.sfo3.cdn.digitaloceanspaces.com/btc.btc.png',
  integrations: ['swapkit'],
}

const poolIn = computed<RouteEndPoint>(() => {
  const assetId = Array.isArray(route.query.in) ? route.query.in[0] : route.query.in
  if (!assetId) {
    return initialPoolIn
  }
  return pools.value.find((p) => p.asset === assetId) ?? pools.value[0] ?? initialPoolIn
})
const inIcon = computed(() => {
  return poolIn.value.logoURI
})
const poolOut = computed<RouteEndPoint>(() => {
  const assetId = Array.isArray(route.query.out) ? route.query.out[0] : route.query.out
  if (!assetId) {
    return initialPoolOut
  }
  return pools.value.find((p) => p.asset === route.query.out) ?? pools.value[1] ?? initialPoolOut
})
const outIcon = computed(() => {
  return poolOut.value.logoURI
})
const initialAmount = '0'
const queryAmount = parseFloat(
  (Array.isArray(route.query.amount) ? route.query.amount.at(0) : route.query.amount) ??
    initialAmount,
)
const amountIn = ref<string>(Number.isNaN(queryAmount) ? initialAmount : queryAmount.toString())
type SwapStatus =
  | 'fetchingQuote'
  | 'quoteError'
  | 'canSwap'
  | 'estimatingNetworkFee'
  | 'confirmingSwap'
  | 'swapping'
  | 'checkingApproval'
  | 'checkApproval'
  | 'estimatingApprovalFee'
  | 'shouldApprove'
  | 'confirmingApproval'
  | 'approving'
const swapStatus = ref<SwapStatus>('canSwap')
const isLoading = computed(() => {
  const loadingStatues: SwapStatus[] = [
    'fetchingQuote',
    'swapping',
    'checkingApproval',
    'approving',
    'estimatingNetworkFee',
    'estimatingApprovalFee',
  ]
  return loadingStatues.includes(swapStatus.value)
})
const isDisabled = computed(() => {
  return isLoading.value || swapStatus.value === 'quoteError'
})
const swapkitNetworkFee = ref<WalletAsset | null>(null)
const approvalFeeUsd = ref<number | null>(null)
const poolForChart = ref<PoolForDialog>('poolIn')
const windowWidth = ref(0)
const isChartOpen = ref(false)
const chartContainerStyle = ref('height: 0px; width: 0px')
const symbolsWithoutChart = [
  'CACAO',
  'GLD',
  'TGT',
  'USK',
  'FLIP',
  'ZYN',
  'TRUMP',
  'APU',
  'BODEN',
  'KENIDY',
  'TREN',
] as const
const assetNameToSymbol = (assetName: string) => {
  return assetName.replace('s', '')
}
const assetHasChart = (assetName: string) => {
  const blacklist: readonly string[] = symbolsWithoutChart
  return !blacklist.includes(assetNameToSymbol(assetName))
}

const recipientValue = ref<string | null>(null)
const onRecipientEdit = () => {
  recipientValue.value = wallets.value[poolOut.value.chain]?.address ?? ''
}
const onRecipientInput = (value: string) => {
  recipientValue.value = value
  const recipient = addressToRecipient(value)
  if ('error' in recipient) {
    return
  }
  fetchQuotes({ poolIn, poolOut })
}
const onRecipientCancel = () => {
  recipientValue.value = null
}
type Recipient = {} | { address: string } | { error: string }
const addressToRecipient = (address: string | null) => {
  const chain = poolOut.value.chain

  if (address === null) {
    return {}
  }
  if (!address) {
    return { error: 'Missing address' }
  }
  if (chain === RangoChain.Solana) {
    return isValidSolanaAddress(address) ? { address } : { error: 'Invalid Solana address' }
  }
  return swapkitClient.validateAddress({ address, chain })
    ? { address }
    : { error: `Invalid address for chain ${chain}` }
}
const recipient = computed<Recipient>(() => {
  return addressToRecipient(recipientValue.value)
})

const destination = computed<string | undefined>(() => {
  if ('address' in recipient.value) {
    return recipient.value.address
  }
  const wallet = wallets.value[poolOut.value.chain]
  if (!wallet && isEvmChain(poolOut.value.chain)) {
    const walletEntries: Array<[string, ChainWallet]> = Object.entries(wallets.value)
    const { metamask, evmWallet } = walletEntries.reduce<{
      metamask: string | undefined
      evmWallet: string | undefined
    }>(
      (addresses, [chain, wallet]) => {
        if (wallet.walletType === WalletOption.METAMASK) {
          return { ...addresses, metamask: wallet.address }
        }
        if (isEvmChain(chain)) {
          return {
            ...addresses,
            evmWallet: wallet.address,
          }
        }
        return addresses
      },
      { metamask: undefined, evmWallet: undefined },
    )
    return metamask ?? evmWallet
  }
  return wallet?.address
})
// #endregion

// #region computed, mostly for template
const swapActionNames: { [K in SwapStatus]: string } = {
  fetchingQuote: 'Loading quote...',
  quoteError: 'No route available',
  canSwap: 'Swap',
  estimatingNetworkFee: 'Estimating network fee...',
  swapping: 'Signing & sending...',
  confirmingSwap: 'Confirming swap...',
  checkApproval: 'Check approval',
  checkingApproval: 'Checking approval...',
  estimatingApprovalFee: 'Estimating approval fee...',
  shouldApprove: 'Approve',
  confirmingApproval: 'Approve',
  approving: 'Approving...',
}
const swapActionName = computed<string>(() => {
  return (
    (isLoadingBalances.value && !hasBalances.value && 'Loading balances...') ||
    swapActionNames[swapStatus.value]
  )
})

type WithNetworkFee = { networkUsd: number }
type Fees = Pick<StatusFees, 'affiliateUsd' | 'protocolUsd'> & WithNetworkFee
type FeesDisplay = StatusFees & WithNetworkFee
type QuoteDisplay = {
  quote: {
    provider: string
    expectedOutput: BigNumber
    expectedOutputUsd: BigNumber
    expectedOutputDisplay: string
    expectedOutputUsdDisplay: string
    swapperLogo: string
    estimatedTimeInSeconds: number
    fees: FeesDisplay
    amountOutMin: string
    priceImpactPercentage: BigNumber
  } | null
  error: string | null
} | null

const captureContext = () => {
  return JSON.stringify({
    poolIn: toRaw(poolIn.value),
    poolOut: toRaw(poolOut.value),
    amountIn: toRaw(amountIn.value),
    quoteRaw: toRaw(quoteRaw.value),
  })
}

const quoteDisplay = computed((): QuoteDisplay => {
  if (!quoteRaw.value) return null
  const affiliateFeeUsd = valueIn.value * referrerFeeRatio.value
  const getExpectedOutputUsd = ({
    expectedOutput,
    expectedOutputUsd,
  }: {
    expectedOutput: BigNumber
    expectedOutputUsd: number | null
  }) => {
    // TODO don't show zero, show an error
    return expectedOutputUsd === null
      ? expectedOutput.multipliedBy(fiatPriceOut.value)
      : BigNumber(expectedOutputUsd)
  }
  const getPriceImpactPercentage = (outValueUsd: BigNumber) => {
    const decimalMultiplier = 10 ** 2
    return valueIn.value === 0
      ? BigNumber(0)
      : BigNumber(100)
          .minus(outValueUsd.multipliedBy(100).div(valueIn.value))
          .multipliedBy(decimalMultiplier)
          .integerValue(BigNumber.ROUND_FLOOR)
          .div(decimalMultiplier)
  }
  /**
   * @returns 0 if fiat price is missing
   */
  const assetIdToUsd = (assetId: string): number => {
    const price: State<FiatPrice> | undefined = fiatPrices.value[assetId]
    if (price?.state !== 'loaded') {
      return 0
    }
    return price.data.usd
  }
  if (quoteRaw.value.integration === 'rango') {
    const feeToFeeUsd = (fee: SwapFee): Fees[keyof Fees] => {
      const usdValue =
        fee.token.usdPrice ??
        assetIdToUsd(getAssetId(fee.token.blockchain, fee.token.symbol, fee.token.address))
      return intToBigNumber(fee.amount, fee.token.decimals).multipliedBy(usdValue).toNumber()
    }
    const noRouteError = `Rango failed to load a route: from ${poolIn.value.assetId} to ${poolOut.value.assetId}`
    switch (quoteRaw.value.quote.resultType) {
      case RoutingResultType.NO_ROUTE: {
        const errorMessage = quoteRaw.value.quote.error ?? noRouteError
        return {
          quote: null,
          error: errorMessage,
        }
      }
      case RoutingResultType.INPUT_LIMIT_ISSUE: {
        const errorMessage =
          quoteRaw.value.quote.error ??
          (() => {
            const min = quoteRaw.value.quote.route?.amountRestriction?.min
            const max = quoteRaw.value.quote.route?.amountRestriction?.max
            const title = 'Try a different input amount'
            if (!min || !max) {
              return title
            }
            return `${title}: Min: ${toPrice(intToFloat(min, poolIn.value.decimals), poolIn.value.assetId)}, Max: ${toPrice(intToFloat(max, poolIn.value.decimals), poolIn.value.assetId)}`
          })()
        return {
          quote: null,
          error: errorMessage,
        }
      }
      case RoutingResultType.HIGH_IMPACT: {
        const errorMessage =
          quoteRaw.value.quote.error ?? 'Swapping may not be possible due to the high price impact'
        return {
          quote: null,
          error: errorMessage,
        }
      }
    }
    // * "Swap is not valid!"
    if ('errorCode' in quoteRaw.value.quote && quoteRaw.value.quote.errorCode === 112) {
      return {
        quote: null,
        error: 'Your input amount amount is too small, please try again',
      }
    }
    if (!quoteRaw.value.quote.route) {
      return {
        quote: null,
        error: noRouteError,
      }
    }
    const decimals = quoteRaw.value.quote.route.to.decimals
    const rangoAmountOut = quoteRaw.value.quote.route.outputAmount
    const expectedOutput = intToBigNumber(rangoAmountOut, decimals)

    const expectedOutputUSD = getExpectedOutputUsd({
      expectedOutput,
      expectedOutputUsd: quoteRaw.value.quote.route.outputAmountUsd,
    })
    const protocolFeeUsd = valueIn.value - affiliateFeeUsd - expectedOutputUSD.toNumber()
    const initialFees: Fees = {
      affiliateUsd: affiliateFeeUsd,
      protocolUsd: Math.max(protocolFeeUsd, 0),
      networkUsd: 0,
    }
    const fees = quoteRaw.value.quote.route.fee.reduce<Fees>((currentFees, fee) => {
      switch (fee.name) {
        case 'Affiliate Fee': {
          return {
            ...currentFees,
            affiliateUsd: currentFees.affiliateUsd + feeToFeeUsd(fee),
          }
        }
        case 'Swapper Fee': {
          return {
            ...currentFees,
            protocolUsd: currentFees.protocolUsd + feeToFeeUsd(fee),
          }
        }
        case 'Network Fee': {
          return {
            ...currentFees,
            networkUsd: currentFees.networkUsd + feeToFeeUsd(fee),
          }
        }
        default:
          return currentFees
      }
    }, initialFees)
    const totalUsd = fees.affiliateUsd + fees.protocolUsd
    return {
      quote: {
        provider: quoteRaw.value.quote.route.swapper.title,
        expectedOutput,
        expectedOutputDisplay: toPrice(expectedOutput.toString(), poolOut.value.assetId),
        expectedOutputUsd: expectedOutputUSD,
        expectedOutputUsdDisplay: toPrice(expectedOutputUSD.toString(), 'USD'),
        swapperLogo: quoteRaw.value.quote.route.swapper.logo,
        estimatedTimeInSeconds: quoteRaw.value.quote.route.estimatedTimeInSeconds,
        fees: {
          affiliateUsd: fees.affiliateUsd,
          protocolUsd: fees.protocolUsd,
          networkUsd: fees.networkUsd,
          totalUsd,
        },
        amountOutMin: intToBigNumber(
          quoteRaw.value.quote.route.outputAmountMin,
          decimals,
        ).toString(),
        priceImpactPercentage: getPriceImpactPercentage(expectedOutputUSD),
      },
      error: null,
    }
  }
  // TODO handle error 400 and 503 once swapkit makes these available to us
  const expectedOutput = BigNumber(quoteRaw.value.quote.expectedBuyAmount)
  const buyAsset = quoteRaw.value.quote.buyAsset
  const outputPriceUsd = quoteRaw.value.quote.meta.assets?.find(
    (asset) => asset.asset === buyAsset,
  )?.price
  const expectedOutputUSD = getExpectedOutputUsd({
    expectedOutput,
    expectedOutputUsd: outputPriceUsd
      ? expectedOutput.multipliedBy(outputPriceUsd).toNumber()
      : null,
  })
  const protocolFeeUsd = valueIn.value - affiliateFeeUsd - expectedOutputUSD.toNumber()
  const initialFees: Fees = {
    affiliateUsd: affiliateFeeUsd,
    protocolUsd: Math.max(protocolFeeUsd, 0),
    networkUsd: swapkitNetworkFeeUsd.value,
  }
  const providerFees = quoteRaw.value.quote.legs[0].fees
  const fees = providerFees
    ? providerFees.reduce<Fees>((currentFees, fee) => {
        switch (fee.type) {
          case FeeTypeEnum.AFFILIATE: {
            const usdPrice = assetIdToUsd(fee.asset)
            return {
              ...currentFees,
              affiliateUsd: BigNumber(fee.amount)
                .multipliedBy(usdPrice)
                .plus(currentFees.affiliateUsd)
                .toNumber(),
            }
          }
          case FeeTypeEnum.NETWORK: {
            const usdPrice = assetIdToUsd(fee.asset)
            return {
              ...currentFees,
              networkUsd: BigNumber(fee.amount)
                .multipliedBy(usdPrice)
                .plus(currentFees.networkUsd)
                .toNumber(),
            }
          }
          default: {
            const usdPrice = assetIdToUsd(fee.asset)
            return {
              ...currentFees,
              protocolUsd: BigNumber(fee.amount)
                .multipliedBy(usdPrice)
                .plus(currentFees.protocolUsd)
                .toNumber(),
            }
          }
        }
      }, initialFees)
    : initialFees
  const totalUsd = fees.affiliateUsd + fees.protocolUsd
  const provider = quoteRaw.value.quote.providers[0]
  const swapperLogo = getProviderLogo.value(provider) ?? '/tokens/unknown.png'
  const priceImpact = quoteRaw.value.quote.meta.priceImpact
  return {
    quote: {
      provider: quoteRaw.value?.quote.providers[0],
      expectedOutput,
      expectedOutputDisplay: toPrice(expectedOutput.toString(), poolOut.value.assetId),
      expectedOutputUsd: expectedOutputUSD,
      expectedOutputUsdDisplay: toPrice(expectedOutputUSD.toString(), 'USD'),
      swapperLogo,
      estimatedTimeInSeconds: quoteRaw.value.quote.estimatedTime?.total ?? 0,
      fees: {
        affiliateUsd: fees.affiliateUsd,
        protocolUsd: fees.protocolUsd,
        networkUsd: fees.networkUsd,
        totalUsd,
      },
      amountOutMin: quoteRaw.value.quote.expectedBuyAmountMaxSlippage,
      priceImpactPercentage:
        (priceImpact && BigNumber(priceImpact)) || getPriceImpactPercentage(expectedOutputUSD),
    },
    error: null,
  }
})
// if swapkit, logouri:                             :src="`/swapper-logos/${quoteDisplay?.providers[0]?.toLowerCase()}.webp`"
// if swapkit, swapper logos:                             :src="`/swapper-logos/${quoteDisplay?.providers[0]?.toLowerCase()}.webp`"

// or return null)

// const routeHint = computed(() => {
//   if (quoteDisplay.value?.error) return quoteDisplay.value?.error
//   else return 'Enter sufficient input amount to get quotes...'
// })

const fiatPriceIn = computed<number>(() => {
  const fiatPrice: State<FiatPrice> | undefined = fiatPrices.value[poolIn.value.assetId]
  return fiatPrice?.state === stateTypes.Loaded ? fiatPrice.data.usd : 0
})
const fiatPriceOut = computed<number>(() => {
  const fiatPrice: State<FiatPrice> | undefined = fiatPrices.value[poolOut.value.assetId]
  return fiatPrice?.state === stateTypes.Loaded ? fiatPrice.data.usd : 0
})
const fiatPriceRatio = computed(() => {
  return fiatPriceOut.value === 0 ? 0 : fiatPriceIn.value / fiatPriceOut.value
})
const amount = computed<number>(() => {
  return amountIn.value === '' ? 0 : parseFloat(amountIn.value)
})
const valueIn = computed(() => {
  return Number.isNaN(amount.value) ? 0 : amount.value * fiatPriceIn.value
})

const valueInDisplay = computed(() => {
  return toPrice(valueIn.value, 'USD')
})

const assetIn = computed<WalletAsset | undefined>(() => {
  return wallets.value[poolIn.value.chain]?.balance.find(
    ({ chain, symbol, ticker, address }) =>
      getSynthSaveAssetId({ chain, symbol, ticker, address }) === poolIn.value.assetId,
  )
})
const balanceIn = computed(() => {
  const balance = assetIn.value?.getValue('number') ?? 0
  return balance
})
const balanceInDisplay = computed(() => {
  return formatNumber(balanceIn.value, DISPLAY_DECIMALS[poolIn.value.assetId])
})
const maxAmountIn = computed<number>(() => {
  return balanceIn.value - (swapkitNetworkFee.value?.getValue('number') ?? 0)
})
const isGasAssetIn = computed<boolean>(() => {
  return AssetValue.from({ asset: poolIn.value.assetId, value: amountIn.value }).isGasAsset
})
const feeExceedsBalance = computed<boolean>(() => {
  return (!isWalletConnected.value || !isGasAssetIn.value) && maxAmountIn.value < 0
})
const amountInExceedsMax = computed<boolean>(() => {
  if (!isWalletConnected.value) {
    return false
  }
  if (!isGasAssetIn.value) {
    return false
  }
  if (Number.isNaN(amount.value)) {
    return false
  }
  return amount.value > maxAmountIn.value
})
const swapkitNetworkFeeUsd = computed<number>(() => {
  const fee = swapkitNetworkFee.value
  if (!fee) {
    return 0
  }
  const assetId = getAssetId(fee.chain, fee.ticker, fee.address)
  const fiatPrice = fiatPrices.value[assetId]
  if (fiatPrice.state !== stateTypes.Loaded) {
    return 0
  }
  return fee.getValue('number') * fiatPrice.data.usd
})

const balanceOutDisplay = computed(() => {
  const balance =
    wallets.value[poolOut.value.chain]?.balance
      .find((b) => getAssetId(b.chain, b.ticker, b.address) === poolOut.value.assetId)
      ?.getValue('number') ?? 0
  return formatNumber(balance, DISPLAY_DECIMALS[poolOut.value.assetId])
})

const sourceAddress = computed<string | undefined>(() => {
  return wallets.value[poolIn.value.chain]?.address
})
// #endregion

// #region lifecycle hooks
onMounted(async () => {
  if (amountRef.value) {
    amountRef.value.value = amountIn.value
  }
  // eslint-disable-next-line no-console
  console.log('pools.value :>> ', pools.value)
  windowWidth.value = window.innerWidth
  // window.addEventListener('resize', handleWindowResize)

  fiatPriceStore.fetchNewFiatPricesNow([poolIn.value.assetId, poolOut.value.assetId])

  await handleOutputRoutes({
    poolToUpdate: 'poolIn',
    newSelectedAsset: poolIn.value as RouteEndPoint,
    otherPool: poolOut.value as RouteEndPoint,
  })
  // * when navigating back to the swap page, with the amount query param specified
  // * the quote needs to be fetched because it is initial null
  fetchQuotes({ poolIn, poolOut })
})
// #endregion

// #region watchers
watch([poolIn, poolOut], () => {
  hideRoutePicker()
})

// const handleWindowResize = () => {
//   if (isChartOpen.value) {
//     if (window.innerWidth <= 767 && window.innerWidth !== windowWidth.value) {
//       handleTradingViewChart()
//     } else if (window.innerWidth > 767) {
//       handleTradingViewChart()
//     }
//   }
//   windowWidth.value = window.innerWidth
// }

const handleTradingViewChart = () => {
  let SymbolName = ''
  if (poolForChart.value === 'poolIn') {
    SymbolName = assetNameToSymbol(poolIn.value.assetName) + 'USD'
  } else {
    SymbolName = assetNameToSymbol(poolOut.value.assetName) + 'USD'
  }

  setChartContainerStyle()

  const defaultOptions = {
    container_id: 'tradingview-chart',
    width: 724,
    height: 445,
    symbol: SymbolName,
    interval: 'D',
    timezone: 'Etc/UTC',
    theme: 'dark',
    style: '1',
    locale: 'en',
    toolbar_bg: '#f1f3f6',
    enable_publishing: false,
    allow_symbol_change: false,
    range: 'YTD',
    withdateranges: true,
  }
  const initWidget = () => {
    setTimeout((): undefined => {
      if (
        !('TradingView' in window) ||
        !window.TradingView ||
        typeof window.TradingView !== 'object' ||
        !('widget' in window.TradingView) ||
        typeof window.TradingView.widget !== 'function'
      ) {
        const title = 'Unable to initialize TradingView chart widget'
        window.newrelic?.noticeError(title)
        snackbar.addError({ title })
        return
      }
      Reflect.construct(window.TradingView.widget, [defaultOptions])
    }, 300)
  }

  if (document.getElementById('tradingview-chart-script') === null) {
    const script = document.createElement('script')
    script.id = 'tradingview-chart-script'
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://s3.tradingview.com/tv.js'
    // script.onload = onload
    if (isChartOpen.value) {
      script.onload = initWidget
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  } else if (isChartOpen.value) {
    initWidget()
  }
}

const setChartContainerStyle = () => {
  const swapContainer = document.getElementById('swap-container')
  if (!swapContainer) {
    return
  }
  let chartContainerWidth = Math.floor((window.innerWidth - swapContainer.offsetWidth) * (2 / 3))
  const chartContainerHeight = Math.floor(swapContainer.offsetHeight)
  if (window.innerWidth <= 767) {
    chartContainerWidth = Math.floor(swapContainer.offsetWidth)
  }
  chartContainerStyle.value =
    'height: ' + chartContainerHeight + 'px; width: ' + chartContainerWidth + 'px'
}

watch([poolIn, poolOut, isChartOpen, poolForChart], () => {
  if (isChartOpen.value) {
    handleTradingViewChart()
  }
})

const estimateNetworkFee = async ({
  asset,
  route,
}: {
  asset: WalletAsset
  route: QuoteResponseRoute
}) => {
  swapStatus.value = 'estimatingNetworkFee'
  try {
    if (route.tx && (asset.chain === Chain.Arbitrum || asset.chain === Chain.Ethereum)) {
      const assetValue = await alchemy.estimateGas({
        asset: { address: asset.address, chain: asset.chain, ticker: asset.ticker },
        tx: route.tx,
      })
      /**
       * associated with "exceeds block gas limit" error
       * const tx = { ...route.tx, gasLimit: assetValue.getValue('bigint') }
       * quoteRaw.value = {
       *   integration: 'swapkit',
       *   quote: { ...route, tx },
       * }
       */
      swapkitNetworkFee.value = assetValue
      swapStatus.value = 'canSwap'
      return assetValue
    }
    const assetValue = await swapkitClient.estimateTransactionFee({
      type: 'swap',
      feeOptionKey: FeeOption.Average,
      params: { assetValue: asset as AssetValue, route },
    })
    if (!assetValue) {
      throw new Error('Cannot estimate network fee at this time')
    }
    swapkitNetworkFee.value = assetValue
    swapStatus.value = 'canSwap'
    return assetValue
  } catch (error) {
    const errorMessage =
      (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
    window.newrelic?.noticeError(`Failed to estimate network fee: ${errorMessage}`)
    swapStatus.value = 'canSwap'
  }
}
const estimateApprovalFee = async ({
  asset,
  contractAddress,
}: {
  asset: WalletAsset
  contractAddress: string
}) => {
  swapStatus.value = 'estimatingApprovalFee'
  try {
    const assetValue = await swapkitClient.estimateTransactionFee({
      type: 'approve',
      feeOptionKey: FeeOption.Average,
      params: {
        assetValue: asset as AssetValue,
        contractAddress,
      },
    })
    if (!assetValue) {
      throw new Error('Cannot approve token at this time')
    }
    const assetId = getAssetId(assetValue.chain, assetValue.ticker, assetValue.address)
    const fiatPrice = fiatPrices.value[assetId]
    if (fiatPrice.state !== stateTypes.Loaded) {
      throw new Error(`Missing fiat price for ${assetId}`)
    }
    approvalFeeUsd.value = assetValue.getValue('number') * fiatPrice.data.usd
  } catch (error) {
    const errorMessage =
      (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
    snackbar.addError({
      title: 'Failed to estimate approval fee',
      text: getHumanError(errorMessage),
    })
    window.newrelic?.noticeError(errorMessage)
  }
  swapStatus.value = 'shouldApprove'
}
const onConfirmingApproval = () => {
  swapStatus.value = 'confirmingApproval'
}
const onCloseConfirmApproval = () => {
  swapStatus.value = 'shouldApprove'
}
const onApproveAsset = async () => {
  // TODO handle approval in the context of rango
  const route = quoteRaw.value?.integration === 'swapkit' ? quoteRaw.value.quote : null
  const errorTitle = 'Failed to approve token'
  if (!route) {
    snackbar.addWarning({
      title: errorTitle,
      text: 'No route available at this time',
    })
    return
  }
  const spenderAddress = route.meta.approvalAddress
  if (!spenderAddress || !isHexAddress(spenderAddress)) {
    snackbar.addWarning({
      title: errorTitle,
      text: spenderAddress
        ? `Spender address must start with '0x', received '${spenderAddress}'`
        : 'Missing spender address',
    })
    return
  }
  const tokenAddress = poolIn.value.assetAddress
  if (!tokenAddress || !isHexAddress(tokenAddress)) {
    snackbar.addWarning({
      title: errorTitle,
      text: tokenAddress
        ? `Token address must start with '0x', received '${tokenAddress}'`
        : 'Missing token address',
    })
    return
  }
  const wallet = wallets.value[poolIn.value.chain]
  if (!wallet) {
    snackbar.addWarning({
      title: errorTitle,
      text: `Wallet not found for chain ${poolIn.value.chain}`,
    })
    return
  }
  const ownerAddress = wallet.address
  if (!isHexAddress(ownerAddress)) {
    snackbar.addWarning({
      title: errorTitle,
      text: `Wallet address must start with '0x', received '${ownerAddress}'`,
    })
    return
  }
  const chain = poolIn.value.chain
  if (chain !== Chain.Arbitrum && chain !== Chain.Ethereum) {
    snackbar.addWarning({
      title: errorTitle,
      text: `Please select an asset on Ethereum or Arbitrum chains`,
    })
    return
  }
  swapStatus.value = 'approving'
  const tokenAllowanceProps = {
    chain,
    spenderAddress,
    tokenAddress,
  } satisfies TokenAllowanceProps
  const props: ApproveTokenAllowanceProps | null = (() => {
    if ('eip1193Provider' in wallet) {
      return {
        ...tokenAllowanceProps,
        provider: wallet.eip1193Provider,
        ownerAddress,
      }
    }
    if ('account' in wallet) {
      return {
        ...tokenAllowanceProps,
        account: wallet.account,
      }
    }
    return null
  })()
  if (!props) {
    snackbar.addWarning({
      title: errorTitle,
      text: 'Please connect via Metamask, Keystore or Xdefi',
    })
    return
  }
  try {
    await viem.approveMaxTokenAllowance(props)
    await estimateNetworkFee({
      asset: AssetValue.from({ asset: poolIn.value.assetId, value: amountIn.value }),
      route,
    })
  } catch (error) {
    swapStatus.value = 'shouldApprove'
    const errorMessage =
      (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
    snackbar.addError({ title: errorTitle, text: getHumanError(errorMessage) })
    window.newrelic?.noticeError(errorMessage)
  }
}

let nonce = generateNonce()
type FetchQuotesProps = {
  poolIn: { value: RouteEndPoint }
  poolOut: { value: RouteEndPoint }
}
const fetchQuotes = async ({ poolIn, poolOut }: FetchQuotesProps) => {
  // Reset the quote
  quoteRaw.value = null

  // * if amount is 0 or NaN do not fetch quotes
  if (!amount.value) {
    return
  }

  nonce = generateNonce()

  swapStatus.value = 'fetchingQuote'
  const fromAddress = wallets.value[poolIn.value.chain]?.address
  const toAddress =
    ('address' in recipient.value && recipient.value.address) ||
    wallets.value[poolOut.value.chain]?.address
  if (poolIn.value.chain === RangoChain.Solana || poolOut.value.chain === RangoChain.Solana) {
    const currentNonce = nonce
    try {
      // route via rango
      const rangoAmount = floatToInt(amount.value, poolIn.value.decimals)
      const blockchainIn = rangoSwapkitMap[poolIn.value.chain] ?? poolIn.value.chain
      const blockchainOut = rangoSwapkitMap[poolOut.value.chain] ?? poolOut.value.chain
      const quote: IntegrationQuote<'rango'> = await (fromAddress && toAddress
        ? rangoRouteStore
            .swap({
              amount: rangoAmount,
              from: {
                blockchain: blockchainIn,
                symbol: poolIn.value.assetName,
                address: poolIn.value.assetAddress,
              },
              to: {
                blockchain: blockchainOut,
                symbol: poolOut.value.assetName,
                address: poolOut.value.assetAddress,
              },
              fromAddress,
              toAddress,
              slippage: slippagePercentage.toString(),
            })
            .then<{
              integration: 'rango'
              quote: SwapResponse
            }>((swapResponse) => {
              return {
                integration: 'rango',
                quote: swapResponse,
              }
            })
        : fetchQuoteRango(poolIn.value, poolOut.value, rangoAmount).then<{
            integration: 'rango'
            quote: QuoteResponse
          }>((quote) => {
            return {
              integration: 'rango',
              quote,
            }
          }))
      // Only update the displayed quotes if this is the most recent api call,
      // otherwise disregard the returned data
      if (nonce !== currentNonce) {
        return
      }
      quoteRaw.value = quote
      swapStatus.value =
        quote.quote.resultType === RoutingResultType.OK && quote.quote.route
          ? 'canSwap'
          : 'quoteError'
      return
    } catch (error) {
      swapStatus.value = 'quoteError'
      const title = 'Failed to load Rango quote'
      const errorMessage =
        (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
      window.newrelic?.noticeError(errorMessage || title)
      snackbar.addError({
        title,
        text: getHumanError(errorMessage),
      })
      return
    }
  }
  try {
    // For small swaps remove slippage to guard against refunds that run out of gas
    const guardedSlippage = valueIn.value < 100 ? 100 : slippagePercentage
    const noncedQuote = await fetchQuoteSwapkit(nonce, {
      sellAsset: poolIn.value.assetId,
      sellAmount: amountIn.value,
      buyAsset: poolOut.value.assetId,
      slippage: guardedSlippage,
      sourceAddress: fromAddress,
      destinationAddress: toAddress,
    })
    // Only update the displayed quotes if this is the most recent api call,
    // otherwise disregard the returned data
    if (nonce !== noncedQuote.nonce) {
      return
    }
    quoteRaw.value = noncedQuote.quote
    // * wallet is required for fee estimation
    const wallet = wallets.value[poolIn.value.chain]
    if (!wallet) {
      swapStatus.value = 'canSwap'
      return
    }
    if (!isEvmChain(poolIn.value.chain)) {
      await estimateNetworkFee({
        asset: AssetValue.from({ asset: poolIn.value.assetId, value: amountIn.value }),
        route: noncedQuote.quote.quote,
      })
      return
    }
    const approvalAsset = AssetValue.from({ asset: poolIn.value.assetId, value: MAX_APPROVAL })
    if (approvalAsset.isGasAsset) {
      await estimateNetworkFee({
        asset: AssetValue.from({ asset: poolIn.value.assetId, value: amountIn.value }),
        route: noncedQuote.quote.quote,
      })
      return
    }
    const contractAddress = noncedQuote.quote.quote.meta.approvalAddress
    if (
      !contractAddress ||
      !poolIn.value.assetAddress ||
      (poolIn.value.chain !== Chain.Arbitrum && poolIn.value.chain !== Chain.Ethereum)
    ) {
      snackbar.addWarning({
        title: 'Token may be pending approval',
        text: 'Proceed with caution',
      })
      await estimateNetworkFee({
        asset: AssetValue.from({ asset: poolIn.value.assetId, value: amountIn.value }),
        route: noncedQuote.quote.quote,
      })
      return
    }
    swapStatus.value = 'checkingApproval'
    try {
      const asset = AssetValue.from({ asset: poolIn.value.assetId, value: amountIn.value })
      const approved = await swapkitClient.isAssetValueApproved(asset, contractAddress)
      if (approved) {
        await estimateNetworkFee({
          asset,
          route: noncedQuote.quote.quote,
        })
        return
      }
      await estimateApprovalFee({ asset: approvalAsset, contractAddress })
    } catch (error) {
      swapStatus.value = 'checkApproval'
      const title = 'Failed to check asset approval'
      const errorMessage =
        (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
      snackbar.addError({ title, text: getHumanError(errorMessage || title) })
      window.newrelic?.noticeError(errorMessage || title)
    }
  } catch (error) {
    swapStatus.value = 'quoteError'
    const title = 'Failed to load Swapkit quote'
    const errorMessage =
      (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
    console.error(`${title}: ${errorMessage}`)
    window.newrelic?.noticeError(errorMessage || title)
    // snackbar.addError({
    //   title,
    //   text: getHumanError(errorMessage),
    // })
  }
}

const onWalletConnect = () => {
  // ! quote depends on sourceAddress and destinationAddress
  fetchQuotes({ poolIn, poolOut })
}
swapkitWalletStore.onConnect(onWalletConnect)

const debouncedFetchQuotes = debounce(() => {
  if (!amount.value) {
    const { amount: _, ...query } = route.query
    router.push({
      query,
    })
    quoteRaw.value = null
    return
  }
  router.push({
    query: {
      ...route.query,
      amount: amountIn.value,
    },
  })
  fetchQuotes({ poolIn, poolOut })
}, 350)
// #endregion

// #region functions, consider moving these to ~/utils
const hideRoutePicker = () => {
  isRoutePickerVisible.value = false
}
const subAssetSelectKey = ref(0)
const openPoolDialogFor = (pool: PoolForDialog) => {
  poolToUpdate.value = pool // This is used to update the correct pool when the dialog is closed
  subAssetSelectKey.value += 1
  showSelectPoolDialog.value = true
}
const closePoolDialog = () => {
  showSelectPoolDialog.value = false
}

const outputAssetSelectorRangoAll = ref<State<AssetSelectorData[]>>({ state: 'loading' })
const outputAssetSelectorRangoCurated = ref<State<AssetSelectorData[]>>({ state: 'loading' })

const outputAssetSelectorSwapkitAll = ref<State<AssetSelectorData[]>>({ state: 'loading' })
const outputAssetSelectorSwapkitCurated = ref<State<AssetSelectorData[]>>({ state: 'loading' })

const conditionalCuratedAssetSelectorData: ComputedRef<State<AssetSelectorData[]>> = computed(
  () => {
    if (poolToUpdate.value === 'poolIn')
      return {
        state: 'loaded',
        data: [...routeStore.getCuratedAssetSelectorData].sort((a, b) =>
          a.chain.localeCompare(b.chain),
        ),
      }
    // If input token is on Solana (Rango):
    // Populate the output asset selector with the assets that are available on Rango
    else if (poolIn.value.chain === RangoChain.Solana && poolToUpdate.value === 'poolOut') {
      return outputAssetSelectorRangoCurated.value
    } else {
      return outputAssetSelectorSwapkitCurated.value
    }
  },
)

const conditionalSearchableAssetSelectorData: ComputedRef<State<AssetSelectorData[]>> = computed(
  () => {
    if (poolToUpdate.value === 'poolIn')
      return { state: 'loaded', data: routeStore.getAssetSelectorData }
    // If input token is on Solana (Rango):
    // Populate the output asset selector with the assets that are available on Rango
    else if (poolIn.value.chain === RangoChain.Solana && poolToUpdate.value === 'poolOut') {
      return outputAssetSelectorRangoAll.value
    } else {
      return outputAssetSelectorSwapkitAll.value
    }
  },
)

const handleOutputRoutes = async ({
  poolToUpdate,
  newSelectedAsset,
  otherPool,
}: {
  poolToUpdate: PoolForDialog
  newSelectedAsset: RouteEndPoint
  otherPool: RouteEndPoint
}) => {
  if (poolToUpdate === 'poolIn' && newSelectedAsset.chain === RangoChain.Solana) {
    outputAssetSelectorRangoCurated.value = { state: 'loading' }
    outputAssetSelectorRangoAll.value = { state: 'loading' }

    // Default rango output assetIds if connectedApi fails
    // All solana assets are always connected and mixed in later
    let outputRoutesRangoAssetIds = [
      'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48',
      'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7',
      'ARB.USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831',
      'ARB.USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9',
      'AVAX.USDC-0XB97EF9EF8734C71904D8002F8B6BC66DD9C48A6E',
      'AVAX.USDT-0X9702230A8EA53601F5CD2DC00FDBC13D4DF4A8C7',
      'BSC.USDC-0X8AC76A51CC950D9822D68B83FE1AD97B32CD580D',
      'BSC.USDT-0X55D398326F99059FF775485246999027B3197955',
    ]

    try {
      const outputRoutesRango = await fetchOutputRoutesRango(newSelectedAsset.asset)
      outputRoutesRangoAssetIds = outputRoutesRango.flatMap((item) =>
        item.assets.map((asset) => {
          const chain = rangoSwapkitMap[item.blockchain] ?? item.blockchain

          return asset.address
            ? `${chain}.${asset.symbol}-${asset.address}`.toUpperCase()
            : `${chain}.${asset.symbol}`.toUpperCase()
        }),
      )
    } catch (error: any) {
      window.newrelic?.noticeError('Failed to fetch Rango output routes', error)
    }

    const filteredRoutesAll = pools.value.filter((route) => {
      return (
        outputRoutesRangoAssetIds.includes(route.assetId) &&
        (runtimeConfig.public.ENABLE_SYNTHS === 'true' || !route.assetId.includes('/'))
      )
    })

    const filteredRoutesCurated = filteredRoutesAll.filter((route) => {
      return manualRoutes.includes(route.assetId)
    })

    type FilteredRoute = (typeof filteredRoutesAll)[number]

    // All assets connected to input route except Solana
    const outputAssetSelectorDataNoSolanaAll = groupReduce<
      FilteredRoute,
      { chain: FilteredRoute['chain']; assets: FilteredRoute[] }
    >(
      filteredRoutesAll,
      (route) => route.chain,
      (route, assetSelectorData) => {
        return {
          chain: route.chain,
          assets: (assetSelectorData?.assets ?? []).concat(route),
        }
      },
    )

    // Only curated assets connected to input route except Solana
    const outputAssetSelectorDataNoSolanaCurated = groupReduce<
      FilteredRoute,
      { chain: FilteredRoute['chain']; assets: FilteredRoute[] }
    >(
      filteredRoutesCurated,
      (route) => route.chain,
      (route, assetSelectorData) => {
        return {
          chain: route.chain,
          assets: (assetSelectorData?.assets ?? []).concat(route),
        }
      },
    )

    outputAssetSelectorRangoAll.value = {
      state: 'loaded',
      data: [
        ...outputAssetSelectorDataNoSolanaAll,
        ...routeStore.getAssetSelectorData.filter((item) => item.chain === 'SOLANA'),
      ] as AssetSelectorData[],
    }

    outputAssetSelectorRangoCurated.value = {
      state: 'loaded',
      data: [
        ...outputAssetSelectorDataNoSolanaCurated,
        ...routeStore.getCuratedAssetSelectorData.filter((item) => item.chain === 'SOLANA'),
      ].sort((a, b) => a.chain.localeCompare(b.chain)) as AssetSelectorData[],
    }

    // If the currently selected PoolOut is not in the connected list,
    // switch to the first available PoolOut of the curated connected list.
    const isCurPoolOutValid = outputAssetSelectorRangoCurated.value.data.some((chain) =>
      chain.assets.some((asset) => asset.assetId === poolOut.value.assetId),
    )

    const validPoolOut = outputAssetSelectorRangoCurated.value.data[0]?.assets[0]
    const validPoolOutAssetId = validPoolOut?.assetId

    if (!isCurPoolOutValid && validPoolOut) {
      router.push({
        query: { ...route.query, in: newSelectedAsset.asset, out: validPoolOutAssetId },
      })
      return validPoolOut
    }
    return otherPool
  } else if (poolToUpdate === 'poolIn' && newSelectedAsset.chain !== RangoChain.Solana) {
    outputAssetSelectorSwapkitCurated.value = { state: 'loading' }
    outputAssetSelectorSwapkitAll.value = { state: 'loading' }
    const outputRoutesSwapkitAssets = getOutputRoutesSwapkit.value?.get(newSelectedAsset.asset)
    const outputRoutesSwapkitAssetIds =
      outputRoutesSwapkitAssets?.tokens.map((token) => token.identifier.toUpperCase()) ?? []

    let outputRoutesRango: ConnectedChainWithAssets[] | [] = []
    try {
      outputRoutesRango = await fetchOutputRoutesRango(newSelectedAsset.asset)
    } catch (error: any) {
      window.newrelic?.noticeError('Failed to fetch Rango output routes', error)
    }

    const outputRoutesRangoSolanaAssetIds = outputRoutesRango
      .flatMap((item) =>
        item.assets.map((asset) => {
          const chain = rangoSwapkitMap[item.blockchain] ?? item.blockchain

          return asset.address
            ? `${chain}.${asset.symbol}-${asset.address}`.toUpperCase()
            : `${chain}.${asset.symbol}`.toUpperCase()
        }),
      )
      .filter((assetId) => assetId.includes('SOLANA.'))

    const swapkitAndSolanaAssetIds = [
      ...outputRoutesSwapkitAssetIds,
      ...outputRoutesRangoSolanaAssetIds,
    ]

    const filteredRoutesAll = pools.value.filter((route) => {
      return swapkitAndSolanaAssetIds.includes(route.assetId)
    })

    const filteredRoutesCurated = filteredRoutesAll.filter((route) => {
      return manualRoutes.includes(route.assetId)
    })

    type FilteredRoute = (typeof filteredRoutesAll)[number]

    const outputAssetSelectorDataAll = groupReduce<
      FilteredRoute,
      { chain: FilteredRoute['chain']; assets: FilteredRoute[] }
    >(
      filteredRoutesAll,
      (route) => route.chain,
      (route, assetSelectorData) => {
        return {
          chain: route.chain,
          assets: (assetSelectorData?.assets ?? []).concat(route),
        }
      },
    )

    const outputAssetSelectorDataCurated = groupReduce<
      FilteredRoute,
      { chain: FilteredRoute['chain']; assets: FilteredRoute[] }
    >(
      filteredRoutesCurated,
      (route) => route.chain,
      (route, assetSelectorData) => {
        return {
          chain: route.chain,
          assets: (assetSelectorData?.assets ?? []).concat(route),
        }
      },
    )

    outputAssetSelectorSwapkitCurated.value = {
      state: 'loaded',
      data: [...outputAssetSelectorDataCurated] as AssetSelectorData[],
    }
    outputAssetSelectorSwapkitAll.value = {
      state: 'loaded',
      data: [...outputAssetSelectorDataAll] as AssetSelectorData[],
    }

    // If the currently selected PoolOut is not in the connected list,
    // switch to the first available PoolOut of the curated connected list.
    const isCurPoolOutValid = outputAssetSelectorSwapkitCurated.value.data.some((chain) =>
      chain.assets.some((asset) => asset.assetId === poolOut.value.assetId),
    )

    const validPoolOut = outputAssetSelectorSwapkitCurated.value.data[0]?.assets[0]
    const validPoolOutAssetId = validPoolOut?.assetId

    if (!isCurPoolOutValid && validPoolOut) {
      router.push({
        query: { ...route.query, in: newSelectedAsset.asset, out: validPoolOutAssetId },
      })
      return validPoolOut
    }
    return otherPool
  } else {
    return otherPool
  }
}
const handleSelectPool = async ({
  poolToUpdate,
  newSelectedAsset,
}: {
  poolToUpdate: PoolForDialog
  newSelectedAsset: RouteEndPoint
}) => {
  setTimeout(() => {
    fiatPriceStore.fetchNewFiatPricesNow([newSelectedAsset.assetId])
  }, 150)

  const otherPool = poolToUpdate === 'poolIn' ? poolOut : poolIn

  if (otherPool.value.assetId === newSelectedAsset.assetId) {
    return switchAssets()
  }

  router.push({
    query: { ...route.query, [poolToUpdate === 'poolIn' ? 'in' : 'out']: newSelectedAsset.asset },
  })

  const otherPoolToFetchQueryFor: RouteEndPoint = await handleOutputRoutes({
    poolToUpdate,
    newSelectedAsset,
    otherPool: otherPool.value,
  })

  fetchQuotes(
    poolToUpdate === 'poolIn'
      ? { poolIn: { value: newSelectedAsset }, poolOut: { value: otherPoolToFetchQueryFor } }
      : { poolIn: { value: otherPoolToFetchQueryFor }, poolOut: { value: newSelectedAsset } },
  )
  poolForChart.value = poolToUpdate
  const assetName = (poolToUpdate === 'poolIn' ? poolIn : poolOut).value.assetName
  isChartOpen.value = isChartOpen.value && assetHasChart(assetName) // * closes chart if newly selected asset doesn't have chart
}

const switchAssets = () => {
  const quoteParams = { poolIn: { value: poolOut.value }, poolOut: { value: poolIn.value } }
  if (fiatPriceOut.value === 0) {
    router.push({
      query: {
        ...route.query,
        in: poolOut.value.assetId,
        out: poolIn.value.assetId,
      },
    })
    fetchQuotes(quoteParams)
    return
  }
  const decimalMultiplier = 10 ** poolOut.value.decimals
  const amountEquivalent = (
    Math.round((valueIn.value / fiatPriceOut.value) * decimalMultiplier) / decimalMultiplier
  ).toString()
  router.push({
    query: {
      ...route.query,
      in: poolOut.value.assetId,
      out: poolIn.value.assetId,
      amount: amountEquivalent,
    },
  })
  amountIn.value = amountEquivalent
  if (amountRef.value === null) {
    return
  }
  amountRef.value.value = amountEquivalent
  fetchQuotes(quoteParams)
}

const onConfirmSwap = () => {
  if (!amount.value) {
    return snackbar.addError({
      title: 'Failed to submit swap',
      text: 'Amount must be greater than 0',
    })
  }
  if (!sourceAddress.value) {
    showConnectWalletModal()
    snackbar.addInfo({
      title: 'Missing source address',
      text: 'Please connect the associated wallet',
    })
    return
  }
  if (!destination.value) {
    snackbar.addInfo({
      title: 'Missing destination address',
      text: 'Please enter a destination address or connect the associated wallet',
    })
    return
  }
  if (!quoteRaw.value) {
    return snackbar.addError({
      title: 'Failed to submit swap',
      text: 'Quote is missing',
    })
  }
  if (quoteRaw.value.integration === 'rango') {
    if (
      !('tx' in quoteRaw.value.quote) ||
      !quoteRaw.value.quote.tx ||
      !quoteRaw.value.quote.route
    ) {
      const errorMessage = 'Missing Rango quote or transaction'
      window.newrelic?.noticeError(errorMessage)
      return snackbar.addError({
        title: 'Failed to submit swap',
        text: getHumanError(errorMessage),
      })
    }
  }
  swapStatus.value = 'confirmingSwap'
}
const onCloseConfirm = () => {
  swapStatus.value = 'canSwap'
}

const onSwap = () => {
  switch (swapStatus.value) {
    case 'shouldApprove':
      onConfirmingApproval()
      break
    default:
      onConfirmSwap()
      break
  }
}
const onCloseTxTracker = () => {
  quoteRaw.value = null
  amountIn.value = initialAmount
  if (!amountRef.value) {
    return
  }
  amountRef.value.value = initialAmount
  router.push({ query: {} })
}

/**
 * if chart is closed, open it
 * else close the chart if the asset category matches and set the asset category
 */
const toggleChartOpen = (poolInOrOut: PoolForDialog) => {
  isChartOpen.value = !isChartOpen.value || poolForChart.value !== poolInOrOut

  poolForChart.value = poolInOrOut
}

const submitSwap = async () => {
  if (!quoteRaw.value || !quoteDisplay.value?.quote) {
    window.newrelic?.noticeError('Quote is missing')
    return snackbar.addError({
      title: 'Quote is missing',
    })
  }
  const swapData = {
    amount: amountIn.value,
    fromChain: poolIn.value.chain,
    fromAsset: poolIn.value.assetName,
    toChain: poolOut.value.chain,
    toAsset: poolOut.value.assetName,
    quoteAmount: quoteDisplay.value.quote.expectedOutput,
  }
  if (quoteRaw.value.integration === 'rango') {
    if (!destination.value) {
      window.newrelic?.noticeError('Missing destination address')
      return snackbar.addError({
        title: 'Failed to submit swap',
        text: 'Missing destination address',
      })
    }
    if (
      !('tx' in quoteRaw.value.quote) ||
      !quoteRaw.value.quote.tx ||
      !quoteRaw.value.quote.route
    ) {
      const title = 'Missing Rango quote or tx'
      window.newrelic?.noticeError(title, { data: captureContext() })
      return snackbar.addError({
        title: getHumanError(title),
      })
    }
    swapStatus.value = 'swapping'
    analytics.track('swap-signing', swapData)
    try {
      await submitSwapRango({
        requestId: quoteRaw.value.quote.requestId,
        tx: quoteRaw.value.quote.tx,
        quote: quoteRaw.value.quote.route,
        amountIn: amountIn.value,
        expectedOutputUsd: quoteDisplay.value.quote.expectedOutputUsd.toNumber(),
        fees: quoteDisplay.value.quote.fees,
        toAddress: destination.value,
      })
      swapStatus.value = 'canSwap'
      analytics.track('swap-signed', swapData)
      return
    } catch (error) {
      swapStatus.value = 'confirmingSwap'
      const title = 'Failed to submit Rango swap'
      const errorMessage =
        (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
      const e = title + (errorMessage && `: ${errorMessage}`)
      window.newrelic?.noticeError(e)
      console.error(e)
      snackbar.addError({
        title,
        text: getHumanError(errorMessage),
      })
      return
    }
  }
  swapStatus.value = 'swapping'
  analytics.track('swap-signing', swapData)
  try {
    await submitSwapSwapkit({
      route: quoteRaw.value.quote,
      expectedOutputUsd: quoteDisplay.value.quote.expectedOutputUsd.toNumber(),
      fees: quoteDisplay.value.quote.fees,
    })
    swapStatus.value = 'canSwap'
    analytics.track('swap-signed', swapData)
  } catch (error) {
    swapStatus.value = 'confirmingSwap'
    const title = 'Failed to submit Swapkit swap'
    const errorMessage =
      (typeof error === 'string' && error) ||
      (hasMessage(error) && error.message) ||
      'An unexpected error occurred.'
    window.newrelic?.noticeError(errorMessage || title)
    snackbar.addError({
      title,
      text: getHumanError(errorMessage),
    })
  }
}

const amountRef = ref<HTMLInputElement | null>(null)
const setAmountRef = (maybeElement: Element | ComponentPublicInstance | null) => {
  if (!(maybeElement instanceof HTMLInputElement)) {
    return
  }
  amountRef.value = maybeElement
}
const onSelectMax = () => {
  if (!amountRef.value) {
    return
  }
  const max = balanceIn.value.toString()
  amountIn.value = max
  amountRef.value.value = max
  router.push({
    query: {
      ...route.query,
      amount: amountIn.value,
    },
  })
  fetchQuotes({ poolIn, poolOut })
}
const onAmountChange = (e: Event) => {
  if (!(e.target instanceof HTMLInputElement)) {
    return
  }
  if (e.target.value === '.') {
    amountIn.value = '0.'
    if (amountRef.value) {
      amountRef.value.value = '0.'
    }
    return
  }
  const value = e.target.value.replaceAll(',', '')
  const amountSchema = createAmountSchema(poolIn.value.decimals)
  const amountResult = amountSchema.safeParse(value)
  if (amountResult.error) {
    // * if new value is invalid revert to previous value
    if (!amountRef.value) {
      return
    }
    amountRef.value.value = amountIn.value
    return
  }
  amountIn.value = amountResult.data
  debouncedFetchQuotes()
}
// #endregion
</script>

<style>
#tradingview-chart {
  width: auto !important;
  height: auto !important;
}
#tradingview-chart > div {
  width: 100% !important;
  height: 100% !important;
}
</style>
