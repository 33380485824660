<template>
  <div
    :id="asset?.assetId"
    class="group/asset-selector-asset-item box-border flex min-h-[75px] cursor-pointer items-center gap-4 border-b border-solid border-[#363636] px-5 py-3.5 text-sm leading-none"
    :class="selected ? 'bg-[#423922]' : 'bg-[#202020] hover:bg-[#423922]'"
    :aria-label="asset?.assetId"
    role="treeitem"
  >
    <div v-if="!skeleton" class="relative w-[34px] min-w-[34px]">
      <img
        class="aspect-square h-full w-full rounded-full"
        :src="asset?.logoURI"
        :alt="`Icon for ${asset?.assetName}`"
        @error="(e) => ((e.target as HTMLImageElement).src = '/tokens/unknown.png')"
      />
    </div>
    <div v-else class="toba-skeleton aspect-square w-[34px] rounded-full" />
    <div class="flex flex-1 max-md:flex-col md:items-center">
      <div class="grid flex-1 items-center gap-1 overflow-auto">
        <template v-if="!skeleton">
          <div class="flex gap-1 overflow-auto">
            <span class="font-bold">{{ asset?.assetName }}</span>
            <span class="flex-1 overflow-hidden text-ellipsis text-white/70">{{
              asset?.chainName
            }}</span>
          </div>
          <div v-if="balance" class="flex items-center gap-1 text-xs">
            <Icon icon="uit:wallet" class="text-white/50" />
            <span lass="text-[#C99D29] font-medium">{{ balanceDisplay }}</span>
            <span v-if="balanceDisplayUsd?.state === 'loaded'" class="text-white/50">
              ${{ balanceDisplayUsd?.data }}
            </span>
          </div>
        </template>
        <template v-else>
          <div class="flex gap-1">
            <span class="toba-skeleton w-10" />
            <span class="toba-skeleton w-10" />
          </div>
          <div class="flex items-center gap-1 text-xs">
            <div class="toba-skeleton aspect-square w-[1em] rounded-full" />
            <span class="toba-skeleton w-10" />
            <span class="toba-skeleton w-10" />
          </div>
        </template>
      </div>
      <div
        class="hidden flex-col gap-1 overflow-auto leading-tight group-hover/asset-selector-asset-item:flex max-md:mr-auto max-md:mt-1 max-md:flex md:items-end"
      >
        <template v-if="asset?.assetAddress">
          <template v-if="!skeleton">
            <div class="flex items-center gap-1 text-xs text-white/50 md:flex-col">
              <span>
                {{ shortString(asset.assetAddress) }}
              </span>
              <a
                v-if="explorerUrl"
                :href="explorerUrl"
                target="_blank"
                class="flex flex-1 items-center gap-1 overflow-hidden text-ellipsis text-[10px] text-[#C99D29]"
              >
                <span class="max-md:hidden">Block Explorer</span>
                <Icon icon="lucide:square-arrow-out-up-right" />
              </a>
            </div>
          </template>
          <template v-else>
            <span class="toba-skeleton w-10" />
            <span class="toba-skeleton w-10" />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { type ComputedRef, computed, toRefs } from 'vue'
import { storeToRefs } from 'pinia'
import { shortString } from '~/utils/helpers'
import { swapkitClient } from '~/clients/swapkit'
import { getSynthSaveAssetId } from '~/utils/main'
import { type WalletAsset } from '~/wallets/swapkit'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import { toPrice } from '~/utils/numbers'
import { useFiatPriceStore } from '~/store/fiatPriceStore'
import type { State } from '~/types/state'

const { fiatPrices } = storeToRefs(useFiatPriceStore())

const swapkitWalletStore = useSwapkitWalletStore()
const { wallets } = storeToRefs(swapkitWalletStore)

const props = defineProps<{
  asset?: RouteEndPoint
  selected?: boolean
  skeleton?: boolean
}>()

const { asset, selected, skeleton } = toRefs(props)
const assetValue = computed<WalletAsset | undefined>(() => {
  return asset.value
    ? wallets.value[asset.value.chain]?.balance.find(
        ({ chain, symbol, ticker, address }) =>
          getSynthSaveAssetId({ chain, symbol, ticker, address }) === asset.value?.assetId,
      )
    : undefined
})

const balance = computed(() => {
  const balance = assetValue.value?.getValue('number') ?? 0
  return balance
})
const balanceDisplay = computed(() => {
  if (!assetValue.value) return '-'
  return toPrice(assetValue.value?.getValue('number'), assetValue.value.toString())
})
const balanceUsd: ComputedRef<State<number> | undefined> = computed(() => {
  if (!asset.value) return
  if (assetValue.value === undefined) throw new Error('Asset value is undefined.')
  const maybeFiatPrice = fiatPrices.value[asset.value.assetId]
  if (!maybeFiatPrice) return { state: 'loading' }
  if (maybeFiatPrice.state === 'loaded')
    return { state: 'loaded', data: maybeFiatPrice.data.usd * assetValue.value.getValue('number') }
  else return maybeFiatPrice
})
const balanceDisplayUsd: ComputedRef<State<string> | undefined> = computed(() => {
  if (balanceUsd.value?.state === 'loaded')
    return { state: 'loaded', data: toPrice(balanceUsd.value.data as number, 'USD') }
  else return balanceUsd.value
})

const explorerUrl = computed(() => {
  if (!asset.value || !asset.value.assetAddress) return null

  if (asset.value.chain === 'SOLANA') return `https://solscan.io/token/${asset.value.assetAddress}`
  else
    return swapkitClient.getExplorerAddressUrl({
      chain: asset.value.chain,
      address: asset.value.assetAddress,
    })
})
</script>
