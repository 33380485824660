import type { LocationQuery } from 'vue-router'
import { parseParam } from '~/utils/main'

export type StatusFees = { affiliateUsd: number; protocolUsd: number; totalUsd: number }
export type StatusParams = {
  estimatedTimeS: number
  feesAffiliateUsd: number
  feesProtocolUsd: number
  feesTotalUsd: number
  fromAsset: string
  inAmount: string
  outAmount: string
  outputAmountMin: string
  outputAmountUsd: number
  startMs: number
  swapper: string
  swapperIcon: string
  toAddress: string
  toAsset: string
}
export type RangoStatusParams = StatusParams & {
  statusId: string
  hash: string | null
  openTxTrackerModal?: 'true' | 'false' | null
}
export type SwapkitStatusParams = StatusParams & {
  chainId: string | null
  hash: string
  openTxTrackerModal?: 'true' | 'false' | null
}

const parseAmount = (amountString: string | null) => {
  if (amountString === null) {
    return amountString
  }
  const amount = parseFloat(amountString)
  if (Number.isNaN(amount)) {
    return null
  }
  return amount
}
const parseAsset = (asset: string | null) => {
  if (asset === null) {
    return asset
  }
  /**
   * symbol consists of ticker and optionally a '-' separated address
   */
  const [chain, symbol] = asset.split('.')
  if (!chain || !symbol) {
    return null
  }
  return asset
}
const parseTime = (timeString: string | null) => {
  if (timeString === null) {
    return timeString
  }
  const time = parseInt(timeString)
  if (Number.isNaN(time)) {
    return null
  }
  return time
}
export const parseStatusQueryParams = (params: LocationQuery) => {
  return {
    chainId: parseParam(params.chainId),
    estimatedTimeS: parseTime(parseParam(params.estimatedTimeS)),
    feesAffiliateUsd: parseAmount(parseParam(params.feesAffiliateUsd)),
    feesProtocolUsd: parseAmount(parseParam(params.feesProtocolUsd)),
    feesTotalUsd: parseAmount(parseParam(params.feesProtocolUsd)),
    fromAsset: parseAsset(parseParam(params.fromAsset)),
    hash: parseParam(params.hash),
    inAmount: parseAmount(parseParam(params.inAmount)),
    outAmount: parseAmount(parseParam(params.outAmount)),
    outputAmountMin: parseAmount(parseParam(params.outputAmountMin)),
    outputAmountUsd: parseAmount(parseParam(params.outputAmountUsd)),
    startMs: parseTime(parseParam(params.startMs)),
    statusId: parseParam(params.statusId),
    swapper: parseParam(params.swapper),
    swapperIcon: parseParam(params.swapperIcon),
    toAddress: parseParam(params.toAddress),
    toAsset: parseAsset(parseParam(params.toAsset)),
  }
}
export type StatusQueryParams = ReturnType<typeof parseStatusQueryParams>
