import { Chain } from '@swapkit/helpers'
import { Network } from 'alchemy-sdk'
import { useRuntimeConfig } from 'nuxt/app'

export type EvmProviderConfigs = {
  [K in Chain]?: { apiKey: string; network: string; alchemyNetwork: Network; url: string }
}
export const useEvm = () => {
  const runtimeConfig = useRuntimeConfig()

  const providerConfigs = {
    [Chain.Arbitrum]: {
      apiKey: runtimeConfig.public.ARB_PROVIDER_API_KEY,
      network: 'arbitrum',
      alchemyNetwork: Network.ARB_MAINNET,
      url: runtimeConfig.public.ARB_PROVIDER_URL,
    },
    [Chain.Ethereum]: {
      apiKey: runtimeConfig.public.ETH_PROVIDER_API_KEY,
      network: 'mainnet',
      alchemyNetwork: Network.ETH_MAINNET,
      url: runtimeConfig.public.ETH_PROVIDER_URL,
    },
  } satisfies EvmProviderConfigs

  const getProviderUrl = (chain: Chain.Ethereum | Chain.Arbitrum) => {
    const config = providerConfigs[chain]
    return config.url + '/' + config.apiKey
  }

  return {
    providerConfigs,
    getProviderUrl,
  }
}
