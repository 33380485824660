import { type Account, createWalletClient, custom, erc20Abi, http, maxUint256 } from 'viem'
import { mainnet } from 'viem/chains'
import type { Chain } from '@swapkit/helpers'
import type { Eip1193Provider } from 'ethers'
import { useEvm } from './evm'

export type TokenAllowanceProps = {
  chain: Chain.Arbitrum | Chain.Ethereum
  spenderAddress: `0x${string}`
  tokenAddress: `0x${string}`
}
export type ApproveTokenAllowanceProps = TokenAllowanceProps &
  ({ provider: Eip1193Provider; ownerAddress: `0x${string}` } | { account: Account })
export const useViem = () => {
  const { getProviderUrl } = useEvm()

  const approveTokenAllowance = ({
    amount,
    chain,
    spenderAddress,
    tokenAddress,
    ...props
  }: ApproveTokenAllowanceProps & { amount: bigint }) => {
    const url = getProviderUrl(chain)
    const transport = 'provider' in props ? custom(props.provider) : http(url)
    const walletClient = createWalletClient({ chain: mainnet, transport })
    const account: Account | `0x${string}` =
      'ownerAddress' in props ? props.ownerAddress : props.account
    return walletClient.writeContract({
      abi: erc20Abi,
      account,
      address: tokenAddress,
      args: [spenderAddress, amount],
      functionName: 'approve',
    })
  }
  const approveMaxTokenAllowance = (props: ApproveTokenAllowanceProps) => {
    return approveTokenAllowance({ ...props, amount: maxUint256 })
  }
  return {
    approveTokenAllowance,
    approveMaxTokenAllowance,
  }
}
