import { Chain, type WalletChain as SwapkitWalletChain } from '@swapkit/helpers'
import { Contract } from 'ethers/contract'
import type { JsonRpcProvider } from 'ethers/providers'
import { formatUnits } from 'ethers/utils'
import { RangoChain } from '~/types/rango'
import type { WalletChain } from '~/wallets/swapkit'

export const supportedChainsByWallet: Record<string, WalletChain[]> = {
  xdefi: [Chain.Maya, Chain.THORChain, Chain.Ethereum, Chain.Arbitrum, Chain.Bitcoin, Chain.Kujira],
  talisman: [Chain.Polkadot],
  keystore: [
    Chain.Maya,
    Chain.THORChain,
    Chain.Ethereum,
    Chain.Arbitrum,
    Chain.Bitcoin,
    Chain.Dash,
    Chain.Kujira,
  ],
  leap: [Chain.Maya, Chain.Kujira],
  phantom: [RangoChain.Solana],
}

export const xDefiAddressIndexes: Record<XDefiChain, number> = {
  THOR: 0,
  ETH: 1,
  BTC: 2,
  KUJI: 3,
  MAYA: 4,
  ARB: 5,
}

export function getAddressIndexForXDefi(chain: SwapkitWalletChain): number | null {
  if (supportedChainsByWallet.xdefi.includes(chain)) {
    const indexes: Record<string, number> = xDefiAddressIndexes
    return indexes[chain]
  }
  return null
}

export function isChainSupportedByWallet(wallet: string, chain: WalletChain): boolean {
  return supportedChainsByWallet[wallet]?.includes(chain)
}

export async function fetchEVMTokenBalance(
  provider: JsonRpcProvider,
  address: string,
  asset: string,
): Promise<AssetBalance> {
  const delim = asset.includes('--') ? '--' : '-'
  const token = '0x' + asset.split(delim)[1].slice(2).toLowerCase()
  const contract = new Contract(
    token,
    ['function decimals() view returns (uint8)', 'function balanceOf(address) view returns (uint)'],
    provider,
  )
  const decimals = await contract.decimals()
  const balance = await contract.balanceOf(address)
  return {
    asset: asset.split('--')[0],
    balance: parseFloat(formatUnits(balance, decimals)),
  }
}

export const isHexAddress = (address: string): address is `0x${string}` => address.startsWith('0x')
