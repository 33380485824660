<template>
  <ModalBgWrapper
    :open="props.open"
    :body-class="'md:!max-w-[400px] bg-[#282828]'"
    @close="onCloseDialog"
  >
    <div class="flex w-full flex-col gap-6 p-6 !leading-[normal]">
      <img
        class="pointer-events-none absolute left-[-45px] top-[135px] aspect-square w-36 rotate-[14.40deg] opacity-10"
        role="presentation"
        :src="props.inIcon"
      />
      <img
        class="pointer-events-none absolute right-[-45px] top-[300px] aspect-square w-36 rotate-[14.40deg] opacity-10"
        role="presentation"
        :src="props.outIcon"
      />
      <div class="flex items-center justify-between gap-2.5">
        <span class="larken text-2xl">Confirm Token Swap</span>
        <a
          class="flex cursor-pointer rounded-lg bg-[#FFFFFF14] p-1.5 hover:bg-[#FFFFFF25]"
          aria-label="Close dialog"
          @click="onCloseDialog"
        >
          <Icon icon="iconamoon:sign-times" />
        </a>
      </div>
      <div class="flex flex-col gap-2.5">
        <div class="flex items-center justify-between gap-6">
          <span class="text-sm text-white/70">Sending Token</span>
          <div class="flex items-center gap-[5px] text-base">
            {{ props.amountIn }} {{ props.inAssetName }}
            <img
              class="aspect-square h-[18px] rounded-full"
              alt="Sending token"
              :src="props.inIcon"
            />
          </div>
        </div>
        <div
          v-if="props.expectedAmountIn && props.expectedAmountIn <= 0"
          class="flex items-center justify-between gap-6"
        >
          <span class="flex items-center gap-1 text-sm text-white/70">
            The estimated network fee is greater than your current balance
            <Icon icon="material-symbols:warning" />
          </span>
        </div>
        <div v-else-if="props.expectedAmountIn" class="flex items-center justify-between gap-6">
          <span
            class="flex items-center gap-1 text-sm text-white/70"
            title="Amount in adjusted for balance and network fee"
            >Expected
            <Icon icon="material-symbols-light:info-outline" />
          </span>
          <div class="flex items-center gap-[5px] text-base">
            {{ props.expectedAmountIn }} {{ props.inAssetName }}
            <img
              class="aspect-square h-[18px] rounded-full"
              alt="Sending token"
              :src="props.inIcon"
            />
          </div>
        </div>
        <div class="flex items-center justify-between gap-6">
          <span class="text-sm text-white/70">Receiving Token</span>
          <div class="flex items-center gap-[5px] text-base">
            {{ props.expectedOutput }} {{ props.outAssetName }}
            <img
              class="aspect-square h-[18px] rounded-full"
              alt="Receiving Token"
              :src="props.outIcon"
            />
          </div>
        </div>
        <div class="flex items-center justify-between gap-6">
          <span class="text-sm text-white/70">Route via</span>
          <div class="flex items-center gap-[5px] text-base" data-testid="swap-confirm-provider">
            {{ getNormalizedProviderName(props.provider) }}
            <img
              class="aspect-square h-[18px] rounded-full"
              :alt="props.provider"
              :src="props.swapperLogo"
            />
          </div>
        </div>
      </div>
      <div class="border-t border-solid border-t-white/10" />
      <div class="flex flex-col gap-2.5">
        <div class="flex items-center justify-between gap-6 text-sm text-white/70">
          <span>Rate</span>
          <span
            >1 {{ props.inAssetName }} = {{ props.fiatPriceRatio }} {{ props.outAssetName }}</span
          >
        </div>
        <ExpansionPanel>
          <template #title>
            <span class="text-sm">Swap Fees</span
            ><span class="ml-auto text-base text-white">{{ toPriceUsd(fees.totalUsd) }}</span>
          </template>
          <ExpansionPanelItem>
            <div class="flex justify-between gap-1">
              <span>Affiliate Fee</span
              ><span class="text-white">{{ toPriceUsd(fees.affiliateUsd) }}</span>
            </div>
          </ExpansionPanelItem>
          <ExpansionPanelItem>
            <div class="flex justify-between gap-1">
              <span>Protocol Fee</span
              ><span class="text-white">{{ toPriceUsd(fees.protocolUsd) }}</span>
            </div>
          </ExpansionPanelItem>
        </ExpansionPanel>
        <div class="flex items-center justify-between gap-6 whitespace-nowrap">
          <span class="text-sm text-white/70">Network Fee</span>
          <span v-if="fees.networkUsd" class="flex items-center gap-[5px] text-base">{{
            toPriceUsd(fees.networkUsd)
          }}</span>
          <span v-else class="flex items-center gap-2 text-sm">
            <Icon icon="material-symbols:warning" /> Missing estimation
          </span>
        </div>
        <div class="flex items-center justify-between gap-6">
          <span class="text-sm text-white/70">Estimated Time</span>
          <div class="flex items-center gap-[5px] text-base">
            <Icon icon="mynaui:clock-two" />
            {{ estimatedTime }}
          </div>
        </div>
        <div class="flex items-center justify-between gap-6">
          <span class="text-sm text-white/70">Price Impact</span>
          <span class="flex items-center gap-[5px] text-base"
            >{{ props.priceImpactPercentage }}%</span
          >
        </div>
      </div>
      <div class="border-t border-solid border-t-white/10" />
      <div :class="{ 'text-xs': true, 'text-white/50': true }">
        Output is estimated. You will receive at least
        <span data-testid="swap-confirm-min-out">{{ props.amountOutMin }}</span>
        {{ props.outAssetName }} or the transaction will revert
      </div>
      <button
        :class="[
          'box-border w-full cursor-pointer rounded-xl bg-[#C99D29] px-5 py-3 text-center text-sm font-bold text-[#1D2021] hover:bg-opacity-90',
          {
            'opacity-60': props.isConfirming,
          },
        ]"
        :disabled="props.isConfirming"
        @click="onConfirm"
      >
        {{ props.isConfirming ? 'Confirming...' : 'Confirm' }}
      </button>
    </div>
  </ModalBgWrapper>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { computed } from 'vue'
import ExpansionPanel from '~/components/ExpansionPanel/ExpansionPanel.vue'
import ExpansionPanelItem from '~/components/ExpansionPanel/ExpansionPanelItem.vue'
import { toPriceUsd } from '~/utils/numbers'
import { getNormalizedProviderName } from '~/utils/swapkit'
type SubConfirmProps = {
  open: boolean
  isConfirming: boolean
  amountIn: string
  expectedAmountIn: number | null
  inAssetName: string
  inIcon: string
  expectedOutput: string
  outAssetName: string
  outIcon: string
  provider: string
  swapperLogo: string
  fiatPriceRatio: number
  fees: {
    [K in 'affiliateUsd' | 'protocolUsd' | 'networkUsd' | 'totalUsd']: number
  }
  estimatedTimeInSeconds: number
  amountOutMin: string
  priceImpactPercentage: number
}
const props = defineProps<SubConfirmProps>()

const emits = defineEmits<{
  close: []
  confirm: []
}>()

const onCloseDialog = () => {
  emits('close')
}

const onConfirm = () => {
  emits('confirm')
}

const estimatedTime = computed(() => {
  const hours = Math.floor(props.estimatedTimeInSeconds / 3600)
  const minutesAndSeconds = props.estimatedTimeInSeconds % 3600
  const minutes = Math.floor(minutesAndSeconds / 60)
  const seconds = Math.floor(minutesAndSeconds % 60)
  return [...(hours ? [hours] : []), minutes, seconds]
    .map((value) => value.toString().padStart(2, '0'))
    .join(':')
})
</script>
